import { ArtifactConstants } from './_constants'
import { removeKeyFromObject } from '../_utils/objectUtils'
import { ReducerAction, reducerCrud } from '../_utils/reduxUtils'
import { getArtifactId } from '../_schema/_schemas'
import { IssueConstants } from '../Issue/_constants'

/*
 * ARTIFACT REDUCER
 */
interface Artifact {
  CreatedUTC: string
  ArtifactID: number
  ArtifactType: string
  ArtifactName: string
  ArtifactDescription: string
  size: number
  UploadType: string
  ExternalWebUrl?: string
  ExternalStorage?: string
  ExternalParentId?: string | number
  ExternalSubParentId?: string | number
  IsContentLoaded?: any
  ThumbnailHeight?: number
  ThumbnailWidth?: number
  Thumbnail?: any
}
interface ArtifactState {
  [artifactKey: string]: Artifact
}
export default function artifactReducer(
  state: { [artifactKey: string]: Artifact } = {},
  action: ReducerAction
) {
  switch (action.type) {
    case ArtifactConstants.INIT.SUCCESS:
    case ArtifactConstants.FETCH.SUCCESS:
      let newState = {
        ...state,
        ...action.payload.result,
      }
      // Loop through and pickup the keys that were left out

      for (let entityId in action.payload.result) {
        let curArt = state[entityId]

        if (curArt) {
          newState[entityId].ExternalWebUrl = curArt.ExternalWebUrl
          newState[entityId].ExternalStorage = curArt.ExternalStorage
          newState[entityId].ExternalParentId = curArt.ExternalParentId
          newState[entityId].ExternalSubParentId = curArt.ExternalSubParentId
        }
      }

      return newState
    case ArtifactConstants.FETCH.LOADING:
      return {
        ...state,
        ...action.payload.result,
      }
    case ArtifactConstants.UPDATE.SUCCESS:
      const artifactId = getArtifactIdFromAction(action)
      const artifactToUpdate = state[artifactId]
      if (!artifactToUpdate) return state

      return {
        ...state,
        [artifactId]: {
          ...artifactToUpdate,
          ...action.payload.result,
        },
      }
    case ArtifactConstants.ADD.SUCCESS:
      return {
        ...state,
        [getArtifactIdFromAction(action)]: action.payload.result,
      }
    case ArtifactConstants.UPDATE_RANGE.SUCCESS:
      return reducerCrud.updateRange(state, action, getArtifactId)
    case ArtifactConstants.DELETE.SUCCESS:
      return removeKeyFromObject(state, getArtifactIdFromAction(action))
    case ArtifactConstants.DELETE_RANGE.SUCCESS:
      return reducerCrud.deleteIdRange(state, action.payload.result)
    case IssueConstants.FETCH.REQUEST:
      return {}
    default:
      return state
  }
}

// NOTE: Reselect createSelector will always pass in state and props
/*
 * ARTIFACT SELECTORS
 */
export const selectArtifactsIdMap = (state: ArtifactState) => state
export const selectArtifactId = (state: ArtifactState, artifactId: string) =>
  'Artifact' + (selectArtifactsIdMap(state)[artifactId] || {}).ArtifactID
export const selectArtifactById = (state: ArtifactState, artifactId: string) =>
  (selectArtifactsIdMap(state) || {})[artifactId]
export const selectArtifactMSTeamsChannelId = (
  state: ArtifactState,
  artifactId: string
) => {
  const artifact = selectArtifactById(state, artifactId) || {}
  return artifact.ExternalSubParentId
  /*const artifact = selectArtifactById(state, artifactId) || {};
    const url = artifact.ExternalWebUrl || '';
    const tokens = url.split('/');
    let channelId = '';
    if (tokens && tokens.length > 0) {
        channelId = tokens[tokens.length - 1];
    }

    return channelId;*/
}
export const selectArtifactMSTeamsTeamId = (
  state: ArtifactState,
  artifactId: string
) => {
  const artifact = selectArtifactById(state, artifactId) || {}
  return artifact.ExternalParentId
}
export const selectArtifactExternalStorage = (
  state: ArtifactState,
  artifactId: string
) => {
  const artifact = selectArtifactById(state, artifactId) || {}
  return artifact.ExternalStorage
}
export const isArtifactContentLoaded = (
  state: ArtifactState,
  artifactId: string
) => (selectArtifactById(state, artifactId) || {}).IsContentLoaded
export const selectArtifactLink = (
  state: ArtifactState,
  artifactId: string
) => {
  const artifact = selectArtifactById(state, artifactId)
  return getArtifactLink(artifact)
}
export const selectArtifactThumbnailHeight = (
  state: ArtifactState,
  artifactId: string
) => (selectArtifactById(state, artifactId) || {}).ThumbnailHeight || 0
export const selectArtifactThumbnailWidth = (
  state: ArtifactState,
  artifactId: string
) => (selectArtifactById(state, artifactId) || {}).ThumbnailWidth || 0

export function getArtifactLink(artifact: Artifact) {
  if (!artifact) return null

  if (artifact.ExternalStorage) return artifact.ExternalWebUrl

  return `api/upload/${artifact.UploadType}/${artifact.ArtifactID}`
}

function getArtifactIdFromAction(action: ReducerAction) {
  const { payload } = action || {}
  const { result } = payload || {}
  const { upload, UploadType, ArtifactID } = result || {}
  return upload || UploadType + ArtifactID
}
