// @ts-nocheck
// TODO: Typescript
import { icoFetch } from '../_utils/fetchUtils'
import { arrayBufferToBase64 } from '../_utils/fileUtils'
import { Client, LargeFileUploadTask } from '@microsoft/microsoft-graph-client'
import { getStore } from '../_storeManager'
import { logout } from '../Auth/_actions'
import { Promise } from 'es6-promise'

//import store from '../_store';
const kBInMB = 1024
const bytesInMB = kBInMB * kBInMB
const fileSizeLimit = 4
//const smallMBCap = 3.5;
const driveItemDownloadUrlKey = '@microsoft.graph.downloadUrl'

function getAuthenticatedClient(customAccessToken) {
  const client = Client.initWithMiddleware({
    authProvider: {
      getAccessToken: function () {
        var prom = new Promise((resolve, reject) => {
          if (customAccessToken) resolve(customAccessToken.accessToken)
          else {
            icoFetch('/api/MicrosoftTeams/AccessToken')
              .then((response) => {
                var json = {}
                if (response.status === 401) {
                  json = response.json()
                } else json.accessToken = response.text()

                return json
              })
              .then((json) => {
                if (json) {
                  if (json.accessToken) resolve(json.accessToken)
                  else if (json.status === 401) {
                    const store = getStore()
                    /*const error = createGraphError({
                                            code: json.title,
                                            message: "Only an MS Teams user can view this file"
                                        });*/

                    /*// Logout if the access token fails on an azure account
                                        const isAzure = isAzureADLogin(store.getState());
                                        if (isAzure)
                                            store.dispatch(logout());*/

                    // Always logout if the access token fails
                    store.dispatch(logout())

                    //reject(error);
                  }
                }
              })
          }
        })

        return prom
      },
    },
  })

  return client
}

export function isFileInMicrosoftSizeLimit(file) {
  return file.size / bytesInMB < fileSizeLimit
}

export async function getUserDetails(accessToken) {
  const client = getAuthenticatedClient(accessToken)
  const user = await client.api('/me').get()
  return user
}

export async function uploadFile({ groupId, driveId, channelFolderId, file }) {
  const client = getAuthenticatedClient()
  const { name } = file
  return await client
    .api(getFileUploadUrl({ name, driveId, groupId, channelFolderId }))
    .put(file)

  /*const uploadFileRequest = new Request(getFileUploadUrl({ name, groupId, channelFolderId }), {
        method: "PUT",
        headers: {
            "Content-type": file.type
        },
        body: file
    });
    const uploadFileStep = {
        id: "1",
        request: uploadFileRequest
    };

    // Update the returned drive item with meta data
    const updateItemRequest = new Request(getDriveItemUrl({ groupId, itemId: item.Id }), {
        method: "UPDATE",
        headers: {
            "Content-type": "application/json"
        },
        body: JSON.stringify({
            searchResult: {
                IssueTeamWorkspaceID: 1
            }
        })
    });
    
    const updateItemStep = {
        id: "2",
        request: updateItemRequest,
        dependsOn: ["1"],
        
    };

    //Create instance by passing a batch request step
    let batchRequestContent = new BatchRequestContent([uploadFileStep, updateItemStep]);*/
}

export async function updateDriveItem(
  { driveItem, groupId, driveId, itemId },
  onFailure
) {
  const client = getAuthenticatedClient(onFailure)
  try {
    const updateRequest = client.api(
      getDriveItemUrl({
        groupId,
        driveId,
        itemId: itemId || driveItem.id,
      })
    )
    return await updateRequest.update(driveItem)
  } catch (ex) {
    if (onFailure) onFailure(ex)
  }

  return null
}

export async function largeFileUpload(
  client,
  { file, driveId, groupId, channelFolderId }
) {
  const { name, size } = file
  try {
    const requestUrl = getFileUploadUrl({
      name,
      driveId,
      groupId,
      channelFolderId,
    })

    const payload = {
      item: {
        '@microsoft.graph.conflictBehavior': 'fail',
        name,
      },
    }

    const fileObject = {
      size,
      content: file,
      name,
    }

    const uploadSession = await LargeFileUploadTask.createUploadSession(
      client,
      requestUrl,
      payload
    )
    const uploadTask = await new LargeFileUploadTask(
      client,
      fileObject,
      uploadSession
    )
    const response = await uploadTask.upload()
    return response
  } catch (err) {
    throw err
  }
}

export function getDriveItemContent(optionsOrUrl) {
  let groupId
  let itemId
  let url

  if (typeof optionsOrUrl === 'string') url = optionsOrUrl
  else if (optionsOrUrl) {
    groupId = optionsOrUrl.groupId
    itemId = optionsOrUrl.itemId
    url = optionsOrUrl.url
  }

  return new Promise((resolve, reject) => {
    const driveItemProm = getDriveItem({ groupId, itemId, url }, [
      driveItemDownloadUrlKey,
    ])
    driveItemProm
      .then((driveItem) => {
        const link = driveItem[driveItemDownloadUrlKey]

        var oReq = new XMLHttpRequest()
        oReq.open('GET', link, true)
        oReq.responseType = 'arraybuffer'

        oReq.onload = function (oEvent) {
          if (oReq.responseType === 'arraybuffer') {
            var arrayBuffer = oReq.response // Note: not oReq.responseText
            resolve(arrayBufferToBase64(arrayBuffer))
          } else if (oReq.responseType === 'text') resolve(oReq.responseText)
        }

        oReq.onerror = function () {
          reject('Request error')
        }

        oReq.send(null)
      })
      .catch(reject)
  })
}

/*export async function getDriveItemContentAsync({ groupId, itemId }) {
    return await getDriveItemContent({ groupId, itemId });
}*/

export function getDriveItem({ groupId, driveId, itemId, url }, select) {
  const client = getAuthenticatedClient()
  try {
    const request = url
      ? getTeamsGetRequest(url, select)
      : client.api(getDriveItemUrl({ groupId, driveId, itemId }, select))
    return request.get()
  } catch (err) {
    throw err
  }
}

export function getDriveItemDownloadLink({
  groupId,
  driveId,
  itemId,
  url,
}: {
  groupId?: any
  driveId?: any
  itemId?: any
  url?: any
}) {
  return new Promise((resolve, reject) => {
    var driveItemProm = getDriveItem({ groupId, driveId, itemId, url }, [
      driveItemDownloadUrlKey,
    ])
    driveItemProm
      .then((driveItem) => {
        resolve(driveItem[driveItemDownloadUrlKey])
      })
      .catch(() => {
        reject("You don't have access to this file.")
      })
  })
}

export async function getDriveItemAsync({ groupId, driveId, itemId }, select) {
  return await getDriveItem({ groupId, driveId, itemId }, select)
}

function getFileUploadUrl({
  name,
  groupId,
  driveId,
  channelFolderId,
  sessionType = 'content',
}) {
  if (driveId)
    return `/drives/${driveId}/items/${channelFolderId}:/${name}:/${sessionType}`

  return `/groups/${groupId}/drive/items/${channelFolderId}:/${name}:/${sessionType}`
}

function getDriveItemUrl({ groupId, driveId, itemId, contentName }, select) {
  let baseUrl = ''
  if (driveId)
    baseUrl = `/drives/${driveId}/items/${itemId}${
      contentName ? '/contentName' : ''
    }`
  else
    baseUrl = `/groups/${groupId}/drive/items/${itemId}${
      contentName ? '/contentName' : ''
    }`

  return select ? baseUrl + '?select=' + select.join(',') : baseUrl
}

export function getTeamsGetRequest(url, select) {
  const finalUrl = select ? url + '?select=' + select.join(',') : url
  const client = getAuthenticatedClient()

  return client.api(finalUrl)
}

export function createGraphError(errorJson) {
  let { code, message } = errorJson

  if (!message) message = 'Graph error'

  if (!code) code = 'GraphError'

  var error = new Error(message)

  error.name = code

  // Using to string to inject the body because of how the GraphHandler file handles the building of the error
  error.toString = () => {
    return JSON.stringify(errorJson)
  }

  return error
}
