// @ts-nocheck
// TODO: Typescript
import classes from './SwipeableListItem.module.scss'
import React from 'react'
import PropTypes from 'prop-types'
import { cx } from '../_utils/objectUtils'

class SwipeableListItem extends React.Component {
  // DOM Refs
  listElement
  wrapper
  background

  // Drag & Drop
  dragStartX = 0
  left = 0
  dragged = false

  // FPS Limit
  startTime
  fpsInterval = 1000 / 60

  constructor(props) {
    super(props)

    this.listElement = null
    this.wrapper = null
    this.background = null

    this.onMouseMove = this.onMouseMove.bind(this)
    this.onTouchMove = this.onTouchMove.bind(this)
    this.onDragStartMouse = this.onDragStartMouse.bind(this)
    this.onDragStartTouch = this.onDragStartTouch.bind(this)
    this.onDragEndMouse = this.onDragEndMouse.bind(this)
    this.onDragEndTouch = this.onDragEndTouch.bind(this)
    this.onDragEnd = this.onDragEnd.bind(this)
    this.updatePosition = this.updatePosition.bind(this)
    this.onClicked = this.onClicked.bind(this)

    this.onSwiped = this.onSwiped.bind(this)
  }

  componentDidMount() {
    window.addEventListener('mouseup', this.onDragEndMouse)
    window.addEventListener('touchend', this.onDragEndTouch)
  }

  componentWillUnmount() {
    window.removeEventListener('mouseup', this.onDragEndMouse)
    window.removeEventListener('touchend', this.onDragEndTouch)
  }

  onDragStartMouse(evt) {
    if (this.props.disabled) return

    this.onDragStart(evt.clientX)
    window.addEventListener('mousemove', this.onMouseMove)
  }

  onDragStartTouch(evt) {
    if (this.props.disabled) return

    const touch = evt.targetTouches[0]
    this.onDragStart(touch.clientX)
    window.addEventListener('touchmove', this.onTouchMove)
  }

  onDragStart(clientX) {
    if (this.props.disabled) return

    this.dragged = true
    this.dragStartX = clientX
    this.listElement.className = cx(classes.ListItem, this.props.className)
    this.startTime = Date.now()
    requestAnimationFrame(this.updatePosition)
  }

  onDragEndMouse() {
    window.removeEventListener('mousemove', this.onMouseMove)
    this.onDragEnd()
  }

  onDragEndTouch() {
    window.removeEventListener('touchmove', this.onTouchMove)
    this.onDragEnd()
  }

  onDragEnd() {
    if (this.props.disabled) return

    if (this.dragged) {
      this.dragged = false

      const threshold = this.props.threshold || 0.3

      if (this.left < this.listElement.offsetWidth * threshold * -1) {
        if (this.props.hideswiped) {
          // NM: below two lines hide list item
          this.left = -this.listElement.offsetWidth * 2
          this.wrapper.style.maxHeight = 0
        } else {
          this.left = 0
        }

        this.onSwiped()
      } else {
        this.left = 0
      }

      this.listElement.className = cx(
        classes.BouncingListItem,
        this.props.className
      )
      this.listElement.style.transform = `translateX(${this.left}px)`
    }
  }

  onMouseMove(evt) {
    if (this.props.disabled) return
    const left = evt.clientX - this.dragStartX
    if (left < 0) {
      this.left = left
    }
  }

  onTouchMove(evt) {
    if (this.props.disabled) return
    const touch = evt.targetTouches[0]
    const left = touch.clientX - this.dragStartX
    if (left < 0) {
      this.left = left
    }
  }

  updatePosition() {
    if (this.props.disabled) return
    if (this.dragged) requestAnimationFrame(this.updatePosition)

    const now = Date.now()
    const elapsed = now - this.startTime

    if (this.dragged && elapsed > this.fpsInterval) {
      this.listElement.style.transform = `translateX(${this.left}px)`

      const opacity = (Math.abs(this.left) / 100).toFixed(2)
      if (opacity < 1 && opacity.toString() !== this.background.style.opacity) {
        this.background.style.opacity = opacity.toString()
      }
      if (opacity >= 1) {
        this.background.style.opacity = '1'
      }

      this.startTime = Date.now()
    }
  }

  onClicked() {
    if (this.props.disabled) return

    if (this.props.onClick) {
      this.props.onClick()
    }
  }

  onSwiped() {
    if (this.props.disabled) return

    if (this.props.onSwipe) {
      this.props.onSwipe()
    }
  }

  render() {
    return (
      <>
        <div className={classes.Wrapper} ref={(div) => (this.wrapper = div)}>
          <div
            ref={(div) => (this.background = div)}
            className={classes.Background}
          >
            {this.props.background ? (
              this.props.background
            ) : (
              <span>Delete</span>
            )}
          </div>
          <div
            onClick={this.onClicked}
            ref={(div) => (this.listElement = div)}
            onMouseDown={this.onDragStartMouse}
            onTouchStart={this.onDragStartTouch}
            className={cx(classes.BouncingListItem, this.props.className)}
          >
            {this.props.children}
          </div>
        </div>
      </>
    )
  }
}

SwipeableListItem.propTypes = {
  disabled: PropTypes.bool,
}

export default SwipeableListItem
