import React, { useEffect, useState } from 'react'
import { ConferenceActions } from './_actions'
import { addNotification } from '../_actions'
import DateFnsUtils from '@date-io/moment'
import moment from 'moment-timezone'
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers'
import classes from './ConferenceAdd.module.scss'
import {
  diff_minutes,
  addMinutesToDate,
  getLocalDate,
  getTodayUTC,
  getUTCDate,
} from '../_utils/dateUtils'

import '../IcoTipTap.scss'
import { Button } from 'reactstrap'
import { cx } from '../_utils/objectUtils'
import {
  FormControl,
  Input,
  TextField,
  Select,
  InputLabel,
  MenuItem,
  InputAdornment,
} from '@material-ui/core'
import {
  StretchLayout,
  StretchBody,
  StretchFooter,
} from '../Layouts/StretchLayout'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faClock,
  faCopy,
  faUsers,
  faVideo,
  faHandshake,
  faDownload,
} from '@fortawesome/pro-regular-svg-icons'
import { useAppDispatch, useAppSelector } from '../_utils/reactReduxHooks'
import { faEdit } from '@fortawesome/pro-light-svg-icons'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import { selectIssueParticipantArray } from '../_rootConfigs/rootSelectors'
import dayjs from 'dayjs'
import { Autocomplete, ToggleButton, ToggleButtonGroup } from '@material-ui/lab'
import * as ics from 'ics'
import { saveAs } from 'file-saver'
import { stripHtml } from '../_utils/domUtils'
import AccessTimeIcon from '@material-ui/icons/AccessTime'
import { timeOptions } from '../Task/_constants'
import { MeetingLink, MeetingLinkSelectDialog } from './MeetingLinkSelectDialog'
import { selectIssueOrgID } from '../Issue/_selectors'
import { Spacer } from '../Shared/Spacer'
import { TiptapEditor } from '../Shared/IcoTipTapEditor'

export interface Conference {
  ConferenceID: number
  Instructions: string // Must state with a value or else a warning will appear
  Scheduled: Date | null
  ScheduledEnd: Date | null
  isAllDay: boolean
  Link: string
  isEditable: boolean
  RepeatType: number
  Participants?: string
  Title: string
  isMsTeams: boolean
  RepeatWeekdays?: string
  Description: string
  IcsFile?: string
}

interface ConferenceToDB {
  IsAllDay: number
  Instructions?: string
  Scheduled: number | null | string
  ScheduledEnd: number | null | string
  WeekStart: number | null | string
  RepeatType: number | null
  Link?: string
  TimeZone: string
  IssueTeamWorkspaceID: number
  isMsTeams: boolean
  ConferenceID?: number
  IssueID: number
  Title?: string
  Participants?: string
  RepeatWeekdays?: string
  Description?: string
  IcsFile?: string
}

interface ConferenceAddProps {
  IssueTeamWorkspaceID: number
  IssueID: number
  disabled: boolean
  ConferenceToEdit?: Conference
  onSubmit: () => any
  mobile: boolean
  isMsTeamsApp: boolean
}
const initialConference: Conference = {
  ConferenceID: 0,
  Instructions: '', // Must state with a value or else a warning will appear
  Scheduled: null,
  ScheduledEnd: null,
  isAllDay: false,
  Link: '',
  isEditable: true,
  RepeatType: 0,
  Title: '',
  isMsTeams: false,
  Participants: undefined,
  RepeatWeekdays: undefined,
  Description: '',
  IcsFile: undefined,
}

export const ConferenceAdd = ({
  onSubmit,
  mobile = false,
  isMsTeamsApp = false,
  IssueTeamWorkspaceID,
  IssueID,
  disabled,
  ConferenceToEdit,
}: ConferenceAddProps) => {
  const [conference, setConference] = useState(
    (ConferenceToEdit && {
      ...ConferenceToEdit,
      isAllDay: !!ConferenceToEdit.isAllDay,
    }) || {
      ...initialConference,
      isMsTeams: isMsTeamsApp,
      Title: isMsTeamsApp ? 'Meeting from Issues Management' : '',
    }
  )

  const [selectMeetingLinkDialogOpen, setSelectMeetingLinkDialogOpen] =
    React.useState(false)

  const [selectedParticipants, setSelectedParticipants] =
    useState<Array<string>>()

  const [selectedRepeatWeekdays, setSelectedRepeatWeekdays] =
    useState<Array<string>>()

  const participants = useAppSelector((state) =>
    selectIssueParticipantArray(state, IssueTeamWorkspaceID)
  )

  const dispatch = useAppDispatch()

  const addConference = (conference: ConferenceToDB) => {
    dispatch(ConferenceActions.add(conference))
  }
  const updateConference = (conference: ConferenceToDB) => {
    dispatch(ConferenceActions.update(conference))
  }

  const [repeatType, setRepeatType] = useState<number>(conference.RepeatType)

  const [displayStartTime, setDisplayStartTime] = useState('')
  const [displayEndTime, setDisplayEndTime] = useState('')

  const orgId = useAppSelector((state) => selectIssueOrgID(state, IssueID))

  const [isCalOpen, setIsCalOpen] = useState(false)

  //const [icalFile, setIcalFile] = useState<Blob>()

  const onAddConferenceError = (message: string) => {
    dispatch(
      addNotification({
        message,
        type: 'error',
        duration: 3000,
        hasCloseBtn: true,
      })
    )
  }
  useEffect(() => {
    if (!conference.Participants) {
      setConference((prev) => {
        return {
          ...prev,
          Participants: participants
            .map((participant) => participant.AppAcctID)
            .join(', '),
        }
      })
    }
  }, [conference.Participants, participants, ConferenceToEdit])

  useEffect(() => {
    if (!selectedParticipants && conference.Participants) {
      setSelectedParticipants(conference.Participants?.split(', '))
    }
  }, [conference.Participants, selectedParticipants, ConferenceToEdit])

  useEffect(() => {
    if (!selectedRepeatWeekdays && conference.RepeatWeekdays) {
      setSelectedRepeatWeekdays(conference.RepeatWeekdays?.split(','))
    }
  }, [conference.RepeatWeekdays, selectedRepeatWeekdays, ConferenceToEdit])

  useEffect(() => {
    if (ConferenceToEdit) {
      setConference(ConferenceToEdit)
    }
  }, [ConferenceToEdit])

  useEffect(() => {
    if (conference.Scheduled) {
      let localTime = getLocalDate(conference.Scheduled).toString()
      let newTime = dayjs(localTime).format('h:mm a')
      setDisplayStartTime(newTime)
    }
  }, [conference.Scheduled])

  useEffect(() => {
    if (conference.ScheduledEnd) {
      let localTime = getLocalDate(conference.ScheduledEnd).toString()
      let newTime = dayjs(localTime).format('h:mm a')
      setDisplayEndTime(newTime)
    }
  }, [conference.ScheduledEnd])

  const handleChangeRepeat = (evt: any) => {
    setConference((prev) => {
      return { ...prev, RepeatType: evt.target.value }
    })
    setRepeatType(evt.target.value)
  }

  const handleChangeInstructions = (newInstructions: string) => {
    setConference((prev) => {
      return { ...prev, Instructions: newInstructions }
    })
  }

  const handleChangeTitle = (
    evt: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setConference((prev) => {
      return { ...prev, Title: evt.target.value }
    })
  }

  const handleChangeLink = (evt: string) => {
    setConference((prev) => {
      return { ...prev, Link: evt }
    })
  }

  const handleChangeDescription = (evt: string) => {
    setConference((prev) => {
      return { ...prev, mtgDesc: evt }
    })
  }

  const handleScheduledDateChange = (evt: MaterialUiPickersDate) => {
    if (evt === null) {
      return
    }
    const utcDate = getUTCDate(evt.toDate())
    if (conference.Scheduled) {
      setConference((prev) => ({ ...prev, Scheduled: utcDate }))
      let splitStartDate = getLocalDate(utcDate).toString().split(' ')
      const ampm2military = (ampm: string) =>
        ampm ? dayjs(`1/1/1 ${ampm}`).format('HH:mm:00') : null
      let endDt = ampm2military(displayEndTime)
      const newEndDate =
        splitStartDate[0] +
        ' ' +
        splitStartDate[1] +
        ' ' +
        splitStartDate[2] +
        ' ' +
        splitStartDate[3] +
        ' ' +
        endDt +
        ' ' +
        splitStartDate[5] +
        ' ' +
        splitStartDate[6] +
        ' ' +
        splitStartDate[7] +
        ' ' +
        splitStartDate[8]
      const utcEndDate = getUTCDate(newEndDate)
      setConference((prev) => ({ ...prev, ScheduledEnd: utcEndDate }))
    } else {
      let coeff = 1000 * 60 * 15
      let schDate = evt.toDate()
      let schTime = new Date(Math.round(schDate.getTime() / coeff) * coeff)
      let utcNewSchDate = getUTCDate(schTime)
      setConference((prev) => ({ ...prev, Scheduled: utcNewSchDate }))
      const utcDatePlusThirty = addMinutesToDate(utcNewSchDate, 30)
      setConference((prev) => ({ ...prev, ScheduledEnd: utcDatePlusThirty }))
    }
    setIsCalOpen(false)
  }

  const handleACScheduledTimeStartChange = (evt: string) => {
    if (evt === null) {
      return
    }
    if (conference.Scheduled != null) {
      let schDate = getLocalDate(conference.Scheduled).toString()
      let splitDate = schDate.split(' ')
      const ampm2military = (ampm: string) =>
        ampm ? dayjs(`1/1/1 ${ampm}`).format('HH:mm:00') : null
      const dt = ampm2military(evt)
      const newDate =
        splitDate[0] +
        ' ' +
        splitDate[1] +
        ' ' +
        splitDate[2] +
        ' ' +
        splitDate[3] +
        ' ' +
        dt +
        ' ' +
        splitDate[5] +
        ' ' +
        splitDate[6] +
        ' ' +
        splitDate[7] +
        ' ' +
        splitDate[8]
      const utcDate = getUTCDate(newDate)
      setConference((prev) => ({ ...prev, Scheduled: utcDate }))
    }
  }

  const handleACScheduledTimeEndChange = (evt: string) => {
    if (evt === null) {
      return
    }
    if (conference.ScheduledEnd != null) {
      let schDate = getLocalDate(conference.ScheduledEnd).toString()
      let splitDate = schDate.split(' ')
      const ampm2military = (ampm: string) =>
        ampm ? dayjs(`1/1/1 ${ampm}`).format('HH:mm:00') : null
      const dt = ampm2military(evt)
      const newDate =
        splitDate[0] +
        ' ' +
        splitDate[1] +
        ' ' +
        splitDate[2] +
        ' ' +
        splitDate[3] +
        ' ' +
        dt +
        ' ' +
        splitDate[5] +
        ' ' +
        splitDate[6] +
        ' ' +
        splitDate[7] +
        ' ' +
        splitDate[8]
      const utcDate = getUTCDate(newDate)
      setConference((prev) => ({ ...prev, ScheduledEnd: utcDate }))
    }
  }
  const openInNewTab = (url: string) => {
    url = url.toString().replace('http://', 'https://')
    if (!url.includes('https://')) url = 'https://' + url
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
  }

  const copyToClipboard = (url: string) => {
    url = url.toString().replace('http://', 'https://')
    if (!url.includes('https://')) url = 'https://' + url
    navigator.clipboard.writeText(url)
    dispatch(
      addNotification({
        type: 'success',
        message: 'Meeting link copied to clipboard',
      })
    )
  }

  const reset = () => {
    setConference(initialConference)
    setSelectedRepeatWeekdays([])
    setDisplayStartTime('')
    setDisplayEndTime('')
  }

  function createICalFile(whereFrom: string) {
    const meetingFile = conference.Title + '-invite.ics'
    let date = dayjs(getLocalDate(conference.Scheduled)).format(
      'YYYYMMDDTHHmmss'
    ) // 19980118T230000
    const dateDiff = diff_minutes(conference.ScheduledEnd, conference.Scheduled)
    let icsUrl = conference.Link.toLowerCase().replace('http://', 'https://')
    if (!icsUrl.includes('https://')) icsUrl = 'https://' + icsUrl
    //let icsMonth = dayjs(date).format('M')
    //let icsDay = dayjs(date).format('D')
    let icsRecur = 'FREQ='
    let icsFreq = ''
    if (conference.RepeatType != null || conference.RepeatType !== 0) {
      if (conference.RepeatType === 1) {
        icsFreq = 'WEEKLY'
      }
      if (conference.RepeatType === 2) {
        icsFreq = 'DAILY'
      }
      if (conference.RepeatType === 3) {
        icsFreq = 'HOURLY'
      }
      if (conference.RepeatType === 4) {
        icsFreq = 'MONTHLY'
      }
      if (conference.RepeatType === 5) {
        icsFreq = 'YEARLY'
      }
    }
    icsRecur = icsRecur + icsFreq + ';'
    let icsWeekDays = ''
    if (selectedRepeatWeekdays != null && conference.RepeatType === 1) {
      icsWeekDays = 'BYDAY='
      if (selectedRepeatWeekdays.includes('0')) {
        icsWeekDays += 'SU,'
      }
      if (selectedRepeatWeekdays.includes('1')) {
        icsWeekDays += 'MO,'
      }
      if (selectedRepeatWeekdays.includes('2')) {
        icsWeekDays += 'TU,'
      }
      if (selectedRepeatWeekdays.includes('3')) {
        icsWeekDays += 'WE,'
      }
      if (selectedRepeatWeekdays.includes('4')) {
        icsWeekDays += 'TH,'
      }
      if (selectedRepeatWeekdays.includes('5')) {
        icsWeekDays += 'FR,'
      }
      if (selectedRepeatWeekdays.includes('6')) {
        icsWeekDays += 'SA,'
      }
      icsWeekDays = icsWeekDays.slice(0, -1)
      icsRecur = icsRecur + icsWeekDays + ';'
    }

    if (
      repeatType === 1 &&
      (selectedRepeatWeekdays === undefined ||
        selectedRepeatWeekdays.length === 0)
    ) {
      onAddConferenceError(
        'iCal creation error: please choose a weekday to repeat on'
      )
      return
    }
    //return
    ics.createEvent(
      {
        title: conference.Title,
        description: blankIfNull(stripHtml(conference.Instructions, false)),
        location:
          'https://' +
          window.location.host.toString() +
          '/IssueDetail/' +
          IssueID.toString() +
          '/' +
          IssueTeamWorkspaceID.toString(),
        busyStatus: 'BUSY',
        start: date,
        duration: { minutes: dateDiff },
        url: icsUrl,
        recurrenceRule: icsRecur,
      },
      (error: any, value: any) => {
        if (error) {
          onAddConferenceError('iCal creation error: ' + error)
        }
        const blob = new Blob([value], { type: 'text/plain;charset=utf-8' })
        conference.IcsFile = value
        if (whereFrom === 'button') {
          saveAs(blob, meetingFile)
        }
      }
    )
  }
  function blankIfNull(val: any) {
    if (val) {
      return val
    } else {
      return ''
    }
  }
  const formatDateForServer = (d?: Date) => {
    if (!d) return null
    return d ? diff_minutes(d, getTodayUTC()) : null
  }
  const checkForErrors = () => {
    const localSchStart = getLocalDate(conference.Scheduled)
    const localSchEnd = getLocalDate(conference.ScheduledEnd)
    if (
      isMsTeamsApp &&
      (conference.Scheduled == null || conference.ScheduledEnd == null)
    ) {
      onAddConferenceError(
        'Microsoft Teams requires all meetings to have start and end dates and times.'
      )
      return true
    }
    if (
      conference.Scheduled != null &&
      (addMinutesToDate(localSchStart, 60) < new Date() ||
        addMinutesToDate(localSchEnd, 60) < new Date())
    ) {
      onAddConferenceError('Meeting times cannot be in the past.')
      return true
    }
    if (
      // dayjs(conference.Scheduled).hour() * 60 +
      //   dayjs(conference.Scheduled).minute() >
      // dayjs(conference.ScheduledEnd).hour() * 60 +
      //   dayjs(conference.ScheduledEnd).minute()
      dayjs(conference.Scheduled) > dayjs(conference.ScheduledEnd)
    ) {
      onAddConferenceError(
        'Please select a Meeting end time that comes after your Meeting start time.'
      )
      return true
    }
    if (conference.isMsTeams && !conference.Instructions) {
      onAddConferenceError('Meeting instructions are required.')
      return true
    }
    if (!conference.Instructions && !conference.Link) {
      onAddConferenceError('Meeting link or instructions are required.')
      return true
    }
    if (
      conference.RepeatType === 1 &&
      (selectedRepeatWeekdays?.length === 0 ||
        selectedRepeatWeekdays === undefined)
    ) {
      onAddConferenceError(
        'Please choose day of week to repeat this meeting on.'
      )
      return true
    }
    return false
  }

  const submit = () => {
    if (checkForErrors()) {
      return
    }

    if (conference.Scheduled && conference.ScheduledEnd && !isMsTeamsApp) {
      createICalFile('submit')
    }

    let submitFields: ConferenceToDB = {
      TimeZone: moment.tz.guess(),
      Instructions: conference.Instructions,
      Scheduled: conference.Scheduled
        ? formatDateForServer(conference.Scheduled)
        : null,
      RepeatType: conference.RepeatType,
      isMsTeams: conference.isMsTeams,
      ScheduledEnd: conference.ScheduledEnd
        ? formatDateForServer(conference.ScheduledEnd)
        : null,
      Link: conference.Link,
      ConferenceID: conference.ConferenceID,
      IssueTeamWorkspaceID,
      IssueID,
      WeekStart: 1, //I don't understand what this is used for,
      IsAllDay: conference.isAllDay ? 1 : 0,
      Title: conference.Title ? conference.Title : undefined,
      Participants: selectedParticipants?.join(', '),
      RepeatWeekdays: selectedRepeatWeekdays?.sort().join(','),
      IcsFile: conference.IcsFile,
    }

    if (conference.ConferenceID > 0) updateConference(submitFields)
    else {
      if (!submitFields.Instructions) submitFields.Instructions = ''
      addConference(submitFields)
    }
    onSubmit()
  }
  return (
    <StretchLayout
      className={mobile ? cx(classes.root, classes.mobile) : classes.root}
    >
      <StretchBody>
        <div className={cx(classes.row, 'mb-2')}>
          <div className={'col-12'}>
            <FontAwesomeIcon className={classes.mtgIcon} icon={faHandshake} />
            <Input
              className={classes.titleLink}
              placeholder={'Meeting Title'}
              disabled={disabled}
              onChange={handleChangeTitle}
              title="Meeting Title"
              value={conference.Title}
            />
          </div>
        </div>
        {!conference.isMsTeams && (
          <>
            <div className={cx(classes.row, 'mb-2')}>
              <div
                className={'col-12'}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <FontAwesomeIcon className={classes.videoIcon} icon={faVideo} />
                <Input
                  className={classes.mtgLink}
                  placeholder={'Meeting Link'}
                  disabled={disabled}
                  onChange={(evt) => {
                    handleChangeLink(evt.target.value)
                  }}
                  title="Meeting Link"
                  value={conference.Link}
                />
                <Spacer />
                <div style={{ cursor: 'pointer' }}>
                  <FontAwesomeIcon
                    size={'xl'}
                    icon={faEdit}
                    onClick={() => setSelectMeetingLinkDialogOpen(true)}
                  />
                </div>
              </div>
            </div>
            <div className={cx(classes.row, 'mb-2', 'd-none')}>
              <div className={'col-12'}>
                <div
                  style={{
                    width: '1.5rem',
                    height: '1.25rem',
                    marginRight: '1rem',
                    float: 'left',
                  }}
                >
                  &nbsp;
                </div>
                <Input
                  className={classes.descLink}
                  placeholder={'Meeting Link Description'}
                  disabled={disabled}
                  onChange={(evt) => handleChangeDescription(evt.target.value)}
                  title="Meeting Link Description"
                  value={conference.Description}
                />
              </div>
            </div>
            {conference.Link.length > 0 && (
              <div className={cx(classes.row, 'mb-2')}>
                <div className={'col-12'}>
                  <p className={classes.floatEnd}>
                    <FontAwesomeIcon
                      icon={faVideo}
                      className={classes.copyIcon}
                      title={'Join Meeting'}
                      style={{ fontSize: '3rem', cursor: 'pointer' }}
                      onClick={() => openInNewTab(conference.Link)}
                    />
                    <FontAwesomeIcon
                      icon={faCopy}
                      className={classes.copyIcon}
                      title={'Copy Meeting Link'}
                      style={{ fontSize: '3rem', cursor: 'pointer' }}
                      onClick={() => {
                        copyToClipboard(conference.Link)
                      }}
                    />
                    {conference.Scheduled && conference.Link && (
                      <FontAwesomeIcon
                        icon={faDownload}
                        className={classes.iCalIcon}
                        onClick={() => {
                          createICalFile('button')
                        }}
                        title={'Download iCal'}
                        style={{ fontSize: '3rem', cursor: 'pointer' }}
                      />
                    )}
                  </p>
                </div>
              </div>
            )}
          </>
        )}
        {conference.isMsTeams && <div className="p-3" />}
        <div className={cx(classes.row, 'mb-1')}>
          <div className={'col'}>
            <FontAwesomeIcon className={classes.clockIcon} icon={faClock} />
            Meeting Date/Time:
          </div>
        </div>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <div className={cx(classes.row, 'mb-3')}>
            <div className={'col'}>
              <KeyboardDatePicker
                fullWidth={mobile}
                className={classes.dateField}
                variant="dialog"
                disablePast={true}
                minDate={
                  initialConference.Scheduled &&
                  initialConference.Scheduled < new Date()
                    ? initialConference.Scheduled
                    : new Date()
                }
                format="MM/DD/YYYY"
                label="Start date"
                onChange={handleScheduledDateChange}
                onClick={() => {
                  setIsCalOpen(true)
                }}
                onClose={() => {
                  setIsCalOpen(false)
                }}
                open={isCalOpen}
                value={
                  conference.Scheduled
                    ? getLocalDate(conference.Scheduled)
                    : null
                }
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
                onKeyDown={(e) => {
                  e.preventDefault()
                }}
              />
              <FormControl
                className={cx(classes.timeField, classes.firstTimeField)}
              >
                <Autocomplete
                  options={timeOptions}
                  disableClearable
                  popupIcon={false}
                  autoComplete
                  includeInputInList
                  freeSolo
                  ListboxProps={{
                    style: { maxHeight: `180px` },
                  }}
                  onInputChange={(_, data) => {
                    if (data) handleACScheduledTimeStartChange(data)
                  }}
                  onChange={(event, value) =>
                    value ? setDisplayStartTime(value) : null
                  }
                  value={displayStartTime || ''}
                  onKeyDown={(e) => {
                    e.preventDefault()
                  }}
                  renderInput={(params) => (
                    <TextField
                      variant="standard"
                      {...params}
                      label={'Start time'}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <InputAdornment position="end">
                            <AccessTimeIcon />
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                />
              </FormControl>
              <FormControl className={cx(classes.timeField)}>
                <Autocomplete
                  options={timeOptions}
                  disableClearable
                  popupIcon={false}
                  autoComplete
                  includeInputInList
                  freeSolo
                  ListboxProps={{
                    style: { maxHeight: `180px` },
                  }}
                  onInputChange={(_, data) => {
                    if (data) handleACScheduledTimeEndChange(data)
                  }}
                  onChange={(event, value) =>
                    value ? setDisplayEndTime(value) : null
                  }
                  value={displayEndTime || ''}
                  onKeyDown={(e) => {
                    e.preventDefault()
                  }}
                  renderInput={(params) => (
                    <TextField
                      variant="standard"
                      {...params}
                      label={'End time'}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <InputAdornment position="end">
                            <AccessTimeIcon />
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                />
              </FormControl>
            </div>
          </div>
          <div className={cx(classes.row, 'mb-3')}>
            <div className={'col'}>
              <FormControl fullWidth={true} className={classes.recurrence}>
                <InputLabel id="lblDdlRepeatType">Frequency</InputLabel>
                <Select
                  id="ddlRepeatType"
                  value={conference.RepeatType}
                  label="Frequency"
                  onChange={handleChangeRepeat}
                >
                  <MenuItem value={0}>Does Not Repeat</MenuItem>
                  <MenuItem value={2}>Daily</MenuItem>
                  <MenuItem value={1}>Weekly</MenuItem>
                  <MenuItem value={4}>Monthly</MenuItem>
                  <MenuItem value={5}>Annually</MenuItem>
                </Select>
                {repeatType === 1 && (
                  <FormControl fullWidth={true}>
                    <ToggleButtonGroup
                      value={selectedRepeatWeekdays || []}
                      onChange={(event: any, newValue: any | null) => {
                        setSelectedRepeatWeekdays(newValue)
                      }}
                      className={'mb-3 mt-3'}
                    >
                      <span className={classes.lblRecur}>Repeat on: </span>
                      <ToggleButton
                        value={'0'}
                        aria-label={'Sunday'}
                        className={classes.tbWeekdaysButton}
                      >
                        S
                      </ToggleButton>
                      <ToggleButton
                        value={'1'}
                        aria-label={'Monday'}
                        className={classes.tbWeekdaysButton}
                      >
                        M
                      </ToggleButton>
                      <ToggleButton
                        value={'2'}
                        aria-label={'Tuesday'}
                        className={classes.tbWeekdaysButton}
                      >
                        T
                      </ToggleButton>
                      <ToggleButton
                        value={'3'}
                        aria-label={'Wednesday'}
                        className={classes.tbWeekdaysButton}
                      >
                        W
                      </ToggleButton>
                      <ToggleButton
                        value={'4'}
                        aria-label={'Thursday'}
                        className={classes.tbWeekdaysButton}
                      >
                        T
                      </ToggleButton>
                      <ToggleButton
                        value={'5'}
                        aria-label={'Friday'}
                        className={classes.tbWeekdaysButton}
                      >
                        F
                      </ToggleButton>
                      <ToggleButton
                        value={'6'}
                        aria-label={'Saturday'}
                        className={classes.tbWeekdaysButton}
                      >
                        S
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </FormControl>
                )}
              </FormControl>
            </div>
          </div>
        </MuiPickersUtilsProvider>
        <div className={cx(classes.row, 'mb-3')}>
          <div className={'col-12'}>
            <FontAwesomeIcon className={classes.peopleIcon} icon={faUsers} />
            <FormControl className={classes.peopleList}>
              <Autocomplete
                options={participants
                  .map((participant) => participant.AppAcctID)
                  .join(', ')
                  .split(', ')}
                multiple
                value={selectedParticipants || []}
                onChange={(event: any, newValue: any | null) => {
                  setSelectedParticipants(newValue)
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Participants"
                    variant="standard"
                  />
                )}
                getOptionLabel={(option) =>
                  participants.find(
                    (participant) =>
                      participant.AppAcctID?.toString() === option
                  )?.FullName ||
                  participants.find(
                    (participant) =>
                      participant.AppAcctID?.toString() === option
                  )?.email_address ||
                  'no name or email provided'
                }
                autoComplete
                openOnFocus
              />
            </FormControl>
          </div>
        </div>

        <div className={cx(classes.row, 'mb-3')}>
          <div className={'col-12'}>
            <div className={classes.groupStyle}>
              {/* got this from lines 252-258 IssueTemplateListing.tsx */}
              <TiptapEditor
                placeholder="Instructions..."
                onChange={handleChangeInstructions}
                value={conference.Instructions}
              />
            </div>
          </div>
        </div>

        <MeetingLinkSelectDialog
          onSelect={(meeting: MeetingLink) => {
            handleChangeLink(meeting.link)
          }}
          open={selectMeetingLinkDialogOpen}
          onCancel={() => setSelectMeetingLinkDialogOpen(false)}
          orgId={orgId}
        />
      </StretchBody>
      <StretchFooter>
        <div className={cx('input-group p-1 mt-3 mb-3', classes.footerStyle)}>
          <div className={cx('input-group-prepend', classes.footerItem)}>
            <Button
              color="primary"
              onClick={submit}
              className={classes.btn}
              disabled={disabled}
            >
              {ConferenceToEdit ? 'Update' : 'Add'}
            </Button>
          </div>
          {!ConferenceToEdit && (
            <div className={cx('input-group-prepend', classes.footerItem)}>
              <Button
                color="primary"
                onClick={reset}
                className={classes.btn}
                disabled={disabled}
              >
                Reset
              </Button>
            </div>
          )}
        </div>
      </StretchFooter>
    </StretchLayout>
  )
}
