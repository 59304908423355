import React, { Fragment, useState } from 'react'
import { IssueDynamicFormsList } from './IssueDynamicFormList'
import { IssueDynamicForm } from './IssueDynamicForm'

interface Props {
  IssueID: number
  dfName: string
  //OrgID: number
}

export const IssueProvideMoreInfoFormsViewer = ({
  IssueID,
  dfName,
}: //OrgID,
Props) => {
  const [lastSaveSubmit, setLastSaveSubmit] = useState('')

  return (
    <>
      <IssueDynamicForm
        IssueID={IssueID}
        dfName={dfName}
        // OrgID={OrgID}
        setLastSaveSubmit={setLastSaveSubmit}
        startOpen={false}
      />
      <IssueDynamicFormsList
        IssueID={IssueID}
        dfName={dfName}
        lastSaveSubmit={lastSaveSubmit}
      />
    </>
  )
}
