import * as React from 'react'

import { Layout, Layouts, Responsive, WidthProvider } from 'react-grid-layout'
import { DashboardSection } from '../Shared/DashboardSection'
import { deepEqual } from 'fast-equals'
import { http } from '../../_utils/http'

import {
  DashboardUserInfoModel,
  DashboardView,
  SelectionOption,
} from '../Shared/DashboardModels'
import { CircularProgress } from '../../Shared/LoadingBackdrop'

import '../Shared/DashboardPage.css'

import { Organization } from '../../Issue/_reducer'

import { DashboardIssuesSection } from '../Shared/DashboardIssuesSection'
import { DashboardResolvedIssuesSection } from '../Shared/DashboardResolvedIssuesSection'
import { DashboardPlaybooksSection } from '../Shared/DashboardPlaybooksSection'
import { DashboardPlaybookCollaborationSection } from '../Shared/DashboardPlaybookCollaborationSection'
import { DashboardAlertsSection } from '../Shared/DashboardAlertsSection'
import {
  convertToSmallLayout,
  generateStateLayout,
  checkAdminFooter,
  getAdminSectionFooterName,
  IssueTaskList,
} from '../Shared/dashboardUtils'
import { DashboardIssueLocationSection } from '../Shared/DashboardIssueLocationSection'
import { DashboardIssueModes } from '../Shared/DashboardIssueModes'
import { DashboardReportsAndActivity } from '../Shared/DashboardReportsAndActivity'

import {
  adminDashboardLayout,
  saveAdminDashboardLayout,
} from '../../_utils/apiUtils'
import { useIsMounted } from '../../_utils/hooks'
import { AdminDashboardIssueTasks } from './AdminDashboardIssueTasks'

export const AdminDashboard = ({
  selectedOrgList,
  sectionList,
  headerAction,
  reorderMode,
  setCurrentView,
}: {
  selectedOrgList: Organization[]
  sectionList: SelectionOption[]
  reorderMode: boolean
  headerAction: string
  setCurrentView: (view: DashboardView) => void
}) => {
  const ReactGridLayout = WidthProvider(Responsive)

  const [adminData, setAdminData] = React.useState<
    DashboardUserInfoModel | undefined
  >()

  const [isFetchingAdminData, setIsFetchingAdminData] = React.useState(false)

  const [largeLayout, setLargeLayout] = React.useState<Layout[]>(
    generateStateLayout('lg', sectionList, 'Admin')
  )
  const [smallLayout, setSmallLayout] = React.useState<Layout[]>(
    generateStateLayout('sm', sectionList, 'Admin')
  )

  const [snapshotLargeLayout, setSnapshotLargeLayout] = React.useState<
    Layout[]
  >(generateStateLayout('lg', sectionList, 'Admin'))

  const [snapshotSmallLayout, setSnapshotSmallLayout] = React.useState<
    Layout[]
  >(generateStateLayout('sm', sectionList, 'Admin'))

  const isMounted = useIsMounted()

  const taskList = React.useMemo(() => {
    let issueTaskList: IssueTaskList = []
    adminData?.Issues?.forEach((issue) => {
      const taskList: any = []
      if (issue.IssueResolved) {
        // console.log('DRH DEBUG skipping resolved task')
      } else if (issue.Tasks) {
        issue.Tasks.forEach((task) => {
          task?.TaskItems?.forEach((taskItem) => {
            if (
              taskItem.TaskItemHeaderYN &&
              taskItem.TaskItemHeaderYN === 'Y'
            ) {
              // dont do nothing
              //console.log('skipping header')
            } else {
              taskList.push(taskItem)
            }
          })
        })
        if (taskList.length > 0) {
          issueTaskList.push({
            issueName: issue.IssueName!,
            taskItems: taskList,
            taskOrgName: issue.OrgName,
          })
        }
      }
    })
    return issueTaskList
  }, [adminData])

  React.useEffect(() => {
    if (reorderMode === true) {
      setSnapshotLargeLayout(largeLayout)
      setSnapshotSmallLayout(smallLayout)
    }
  }, [largeLayout, reorderMode, smallLayout])

  React.useEffect(() => {
    if (headerAction === 'Cancel') {
      //console.log(snapshotLargeLayout, 'snapshot large')
      setLargeLayout(snapshotLargeLayout)
      setSmallLayout(snapshotSmallLayout)
    }
  }, [headerAction, snapshotLargeLayout, snapshotSmallLayout])

  function useDeepMemo(value: any) {
    const ref = React.useRef(value)

    if (!deepEqual(ref.current, value)) {
      ref.current = value
    }

    return React.useMemo(() => ref.current, [])
  }

  const memoizedOrgList = useDeepMemo(selectedOrgList)

  React.useEffect(() => {
    const getPickListValues = async (): Promise<any> => {
      setIsFetchingAdminData(true)

      let orgListRequest = ''

      memoizedOrgList.forEach((org: Organization) => {
        orgListRequest += `${org.OrgID}, `
      })

      const customHeaders: any = [
        {
          name: 'OrgIDList',
          value: `${orgListRequest ? orgListRequest : -1}`,
        },
      ]

      const result = await http<any>({
        path: '/Issue/IssueQueryPanelPicklist',
        headers: customHeaders,
      })
      if (result.ok && result.body && isMounted()) {
        setAdminData(result.body)
      } else {
        setAdminData(undefined)
      }
      setIsFetchingAdminData(false)
    }
    getPickListValues()
  }, [isMounted, memoizedOrgList])

  const [width, setWidth] = React.useState<number>(window.innerWidth)

  function handleWindowSizeChange() {
    setWidth(window.innerWidth)
  }

  React.useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange)
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange)
    }
  }, [])

  React.useEffect(() => {
    const getDashboardLayouts = () => {
      adminDashboardLayout().then((data) => {
        if (!isMounted()) return
        if (data?.largeLayout) setLargeLayout(data.largeLayout)

        if (data?.smallLayout) setSmallLayout(data.smallLayout)
      })
    }
    getDashboardLayouts()
  }, [isMounted])

  React.useEffect(() => {
    if (headerAction !== 'Save') return
    const saveDashboardLayouts = () => {
      saveAdminDashboardLayout(
        JSON.stringify({
          largeLayout: largeLayout,
          smallLayout: smallLayout,
        })
      )
    }

    //console.log(headerAction)
    if (headerAction !== 'Save') return

    saveDashboardLayouts()
  }, [headerAction])

  const isSmall = width <= 996

  const resolvedIssues = adminData?.Issues?.filter((issue: any) => {
    return issue?.IssueResolved
  })

  const handleLayoutChange = (currentLayout: Layout[], layouts: Layouts) => {
    if (isSmall) {
      if (!deepEqual(currentLayout, smallLayout)) {
        setSmallLayout(currentLayout)
      }
    } else {
      if (!deepEqual(currentLayout, largeLayout)) {
        setSmallLayout(convertToSmallLayout(currentLayout))

        setLargeLayout(currentLayout)
      }
    }
  }

  const navigateToFooter = (footerTitle: string) => {
    if (footerTitle === 'Issues') {
      setCurrentView('IssuePanel')
    } else if (footerTitle === 'Tasks') {
      setCurrentView('TaskPanel')
    }
  }

  if (!adminData || isFetchingAdminData) return <CircularProgress />

  const getLayout = (title: string) => {
    if (title === 'Marketing' || title === 'Tools and Resources') {
      return (
        <div
          style={{
            display: 'flex',
            border: '1px solid black',
            justifyContent: 'center',
            alignItems: 'center',
            height: '50%',
          }}
        >
          {title}
        </div>
      )
    } else {
      return (
        <DashboardSection
          title={title}
          reorderMode={reorderMode}
          dashboardType={'Admin'}
          setCurrentView={() =>
            navigateToFooter(getAdminSectionFooterName(title))
          }
          hasFooter={checkAdminFooter(title)}
        >
          {title === 'All Active Issues' && (
            <DashboardIssuesSection
              issues={adminData.Issues}
              severityPicklist={adminData.pickSeverity}
            />
          )}
          {title === 'Issues By Location' && (
            <DashboardIssueLocationSection
              issues={adminData.Issues}
              assetList={adminData.Assets}
            />
          )}
          {title === 'Resolved Issues' && (
            <DashboardResolvedIssuesSection
              resolvedIssues={resolvedIssues}
              severityPicklist={adminData.pickSeverity}
            />
          )}
          {title === 'Playbooks' && (
            <DashboardPlaybooksSection
              playbooks={adminData.Playbooks}
              isOrgView
            />
          )}
          {title === 'Playbook Collaboration' && (
            <DashboardPlaybookCollaborationSection
              playbooks={adminData.Playbooks}
            />
          )}
          {title === 'Issues by Mode' && (
            <DashboardIssueModes
              issues={adminData.Issues}
              modesPicklist={adminData.pickMode}
            />
          )}
          {title === 'Alerts' && (
            <DashboardAlertsSection alertInboxes={adminData.Inboxes} />
          )}
          {title === 'Reports' && <DashboardReportsAndActivity />}

          {title === 'Task Status by Issue' && (
            <AdminDashboardIssueTasks tasks={taskList} />
          )}
        </DashboardSection>
      )
    }
  }

  return (
    <div
      style={{
        backgroundColor: 'white',
        margin: '0px',
      }}
    >
      <ReactGridLayout
        layouts={{ sm: smallLayout, lg: largeLayout }}
        onLayoutChange={handleLayoutChange}
        rowHeight={275}
        width={6}
        breakpoints={{ lg: 960, sm: 0 }}
        cols={{ lg: 3, sm: 1 }}
        style={{ position: 'relative' }}
        margin={[20, 20]}
        isDraggable={reorderMode}
        isResizable={reorderMode}
      >
        {sectionList.map((section, index) => {
          return (
            <div key={section.title} style={{ position: 'relative' }}>
              {getLayout(section.title)}
            </div>
          )
        })}
      </ReactGridLayout>
    </div>
  )
}
