import * as React from 'react'
import { useHistory } from 'react-router'
import { DashboardType } from './DashboardModels'
import {
  getAdminSectionFooterName,
  getUserSectionFooterName,
} from './dashboardUtils'

export const DashboardSection = ({
  children,
  title,
  reorderMode,
  dashboardType,
  setCurrentView,
  hasFooter = true,
}: {
  children: React.ReactNode
  title: string
  reorderMode: boolean
  dashboardType: DashboardType
  setCurrentView: any
  hasFooter?: boolean
}) => {
  const history = useHistory()

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        border: reorderMode ? '2px dotted black' : '1px solid lightgrey',
        cursor: reorderMode ? 'pointer' : 'auto',
        height: '100%', // Set the container div to take up the full height
        backgroundColor: 'white',
        boxShadow: '0 3px 10px rgba(0, 0, 0, 0.2)',
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '40px',
          borderBottom: '1px solid lightgrey',
          fontSize: '16px',
          paddingTop: '5px',
          flexShrink: 0,
        }}
      >
        {title}
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
          overflowY: 'auto',
        }}
      >
        {children}
      </div>
      {hasFooter && (
        <div
          style={{
            display: 'flex',
            height: '40px',
            justifyContent: 'center',
            alignItems: 'center',
            borderTop: '1px solid lightgrey',
            color: '#3e91c5',
            fontWeight: '500',
            cursor: 'pointer',
            flexShrink: 0,
          }}
          onClick={() => {
            if (reorderMode) return
            dashboardType === 'Admin'
              ? setCurrentView()
              : setCurrentView(title, history)
          }}
        >
          {dashboardType === 'Admin'
            ? `Review ${getAdminSectionFooterName(title)}`
            : `View ${getUserSectionFooterName(title)}`}
        </div>
      )}
    </div>
  )
}
