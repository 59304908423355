import { Layout } from 'react-grid-layout'
import { DashboardTaskItemsModel, SelectionOption } from './DashboardModels'

export const getUserSectionFooterName = (title: string) => {
  switch (title) {
    case 'All Active Issues':
      return 'Issues'
    case 'My Resolved Issues':
      return 'Issues'

    case 'Issues by Mode':
      return 'Issues'
    case 'My Playbooks':
      return 'Playbooks'
    case 'Playbook Collaboration':
      return 'Playbooks'
    case 'Team Inboxes':
      return 'Alerts'
    case 'My Issue Tasks':
      return 'Issue Tasks'
    case 'Playbook Collaboration (Polls, Chats, Checklists)':
      return 'Playbooks'
    case 'Incident Reports Youve Submitted':
      return 'Incident Reports'
  }
}

export const getAdminSectionFooterName = (title: string) => {
  switch (title) {
    case 'All Active Issues':
      return 'Issues'
    case 'Issues By Location':
      return 'Issues'
    case 'Resolved Issues':
      return 'Issues'
    case 'Playbooks':
      return 'Playbooks'
    case 'Task Status by Issue':
      return 'Tasks'
    case 'Issues by Mode':
      return 'Issues'
    case 'Playbook Collaboration':
      return 'Playbooks'
    case 'Alerts':
      return 'Alerts'
    case 'Reports':
      return 'Issues'
    default:
      return 'Issues'
  }
}

export const navigateToSection = (title: string, history: any) => {
  switch (title) {
    case 'All Active Issues':
      history.push('/Issues')
      break
    case 'My Resolved Issues':
      history.push('/Issues')
      break
    case 'Issues by Mode':
      history.push('/Issues')
      break

    case 'Playbook Collaboration':
      history.push('/Playbook')
      break
    case 'My Playbooks':
      history.push('/Playbook')
      break
    case 'Team Inboxes':
      history.push('/Alert/Inbox/AllAlerts')
      break
  }
}

export const checkAdminFooter = (title: string) => {
  switch (title) {
    case 'My Recent Activity':
      return false
    case 'Playbook Collaboration':
      return false

    case 'Playbooks':
      return false

    case 'Alerts':
      return false
    case 'Reports':
      return false

    default:
      return true
  }
}

export const checkUserFooter = (title: string) => {
  switch (title) {
    case 'My Recent Activity':
      return false

    case 'Reports':
      return false
    case 'My Teams':
      return false
    case 'Upcoming Meetings':
      return false
    case 'Issues By Location':
      return false
    default:
      return true
  }
}

export const getModeColor = (title: string) => {
  switch (title) {
    case 'Active':
      return '#AEDEA2'
    case 'Design':
      return '#67C2EA'
    case 'Training':
      return '#3189BE'
  }
}

interface SectionLayout {
  x: number
  y: number
  width: number
  height: number
}

export type IssueTaskList = {
  issueName: string
  taskItems: any[]
  taskOrgName: string
}[]

export const getTaskStatus = (task: DashboardTaskItemsModel) => {
  if (task.TaskItemHeaderYN === 'Y') return

  if (
    !task?.CompletedUTC &&
    task?.DueByUTC &&
    new Date() > new Date(task?.DueByUTC)
  ) {
    return 'Overdue'
  }

  if (task.TaskItemStatus === 'C') {
    return 'Closed'
  }

  if (!task.AssignedTo) {
    return 'Unassigned'
  }

  if (task.AssignedTo) {
    return 'OpenAssigned'
  }

  return 'Other'
}

export const getDefaultLayoutOfAdminSection = (
  title: string
): SectionLayout => {
  switch (title) {
    case 'All Active Issues':
      return { x: 0, y: 0, width: 1, height: 2 }
    case 'Resolved Issues':
      return { x: 1, y: 0, width: 1, height: 1 }
    case 'Issues by Mode':
      return { x: 2, y: 0, width: 1, height: 1 }
    case 'Alerts':
      return { x: 0, y: 3, width: 1, height: 1 }
    case 'Issues By Location':
      return { x: 1, y: 2, width: 2, height: 2 }
    case 'Playbooks':
      return { x: 1, y: 1, width: 1, height: 1 }
    case 'Task Status by Issue':
      return { x: 0, y: 2, width: 1, height: 1 }
    case 'Playbook Collaboration':
      return { x: 2, y: 1, width: 1, height: 1 }
    case 'Reports':
      return { x: 0, y: 4, width: 1, height: 1 }
    default:
      return { x: 0, y: 0, width: 1, height: 1 }
  }
}

export const getDefaultLayoutOfUserSection = (title: string): SectionLayout => {
  switch (title) {
    case 'All Active Issues':
      return { x: 0, y: 0, width: 1, height: 1 }
    case 'My Resolved Issues':
      return { x: 1, y: 2, width: 1, height: 1 }
    case 'Issues by Mode':
      return { x: 2, y: 2, width: 1, height: 1 }
    case 'Playbook Collaboration':
      return { x: 2, y: 3, width: 1, height: 1 }
    case 'My Playbooks':
      return { x: 1, y: 3, width: 1, height: 1 }
    case 'My Issue Tasks':
      return { x: 0, y: 1, width: 1, height: 1 }
    case 'My Teams':
      return { x: 2, y: 4, width: 1, height: 1 }
    case 'Team Inboxes':
      return { x: 0, y: 2, width: 1, height: 1 }
    case 'Reports':
      return { x: 1, y: 4, width: 1, height: 1 }
    case 'Upcoming Meetings':
      return { x: 0, y: 3, width: 1, height: 2 }
    case 'Issues By Location':
      return { x: 1, y: 0, width: 2, height: 2 }
    default:
      return { x: 0, y: 0, width: 1, height: 1 }
  }
}

type DashboardType = 'Admin' | 'User'

export const generateStateLayout = (
  size: string,
  sectionList: SelectionOption[],
  dashboard: DashboardType
): Layout[] => {
  const sectionLayout: Layout[] = []

  switch (size) {
    case 'lg':
      sectionList.forEach((section, index) => {
        const defaultSize =
          dashboard === 'Admin'
            ? getDefaultLayoutOfAdminSection(section.title)
            : getDefaultLayoutOfUserSection(section.title)

        sectionLayout.push({
          i: section.title,
          x: defaultSize.x,
          y: defaultSize.y,
          w: defaultSize.width,
          h: defaultSize.height,
        })
      })
      return sectionLayout
    case 'sm':
      sectionList.forEach((section, index) => {
        sectionLayout.push({
          i: section.title,
          x: 0,
          y: index,
          w: 1,
          h: 1,
        })
      })

      return sectionLayout
  }
  return sectionLayout
}

export const convertToSmallLayout = (layout: Layout[]) => {
  const compare = (layout: Layout, layoutTwo: Layout) => {
    if (layout.y < layoutTwo.y) {
      return -1
    }
    if (layout.y < layoutTwo.y) {
      return 1
    }
    return 0
  }
  const orderedLayouts: Layout[] = []
  const sectionLayout: Layout[] = []
  const firstRow: Layout[] = []
  const secondRow: Layout[] = []
  const thirdRow: Layout[] = []

  layout.forEach((layoutItem, index) => {
    if (layoutItem.x === 0) {
      firstRow.push(layoutItem)
    } else if (layoutItem.x === 1) {
      secondRow.push(layoutItem)
    } else {
      thirdRow.push(layoutItem)
    }
  })
  firstRow.sort(compare)
  secondRow.sort(compare)
  thirdRow.sort(compare)

  firstRow.forEach((layoutItem) => orderedLayouts.push(layoutItem))
  secondRow.forEach((layoutItem) => orderedLayouts.push(layoutItem))
  thirdRow.forEach((layoutItem) => orderedLayouts.push(layoutItem))

  orderedLayouts.forEach((section, index) => {
    sectionLayout.push({
      i: section.i,
      x: 0,
      y: index,
      w: 1,
      h: 1,
    })
  })

  return sectionLayout
}
