// @ts-nocheck
// TODO: Typescript
import React, { Component, Fragment } from 'react'
import { TableContainer, TableHead, TableBody, Table } from '@material-ui/core'
import PropTypes from 'prop-types'
import classes from '../Shared/TableFixed.module.scss'
import { cx } from '../_utils/objectUtils'
import { MobileLayout, DesktopLayout } from '../Layouts/ResponsiveHidden'
import SwipeableListItem from '../Shared/SwipeableListItem'
import ParticipantTableRow, {
  ParticipantTableHeaderRow,
} from './ParticipantTableRow'
import ParticipantDeleteConfirmation from './ParticipantDeleteConfirmation'

/**
 * @extends {Component<Props, {}>}}
 * Renders a table for participants
 */
export class ParticipantTable extends Component {
  constructor(props) {
    super(props)
    this.state = {
      participantToDelete: undefined,
    }
    this.handleDeleteClick = this.handleDeleteClick.bind(this)
    this.handleCancelDeleteClick = this.handleCancelDeleteClick.bind(this)
    this.openDeleteConfirm = this.openDeleteConfirm.bind(this)
  }

  handleDeleteClick(participantToDelete) {
    const { disabled } = this.props
    if (!disabled) {
      this.setState({
        participantToDelete,
      })
    }
  }

  handleCancelDeleteClick() {
    const { disabled } = this.props
    if (disabled) return

    this.setState({
      participantToDelete: null,
    })
  }

  openDeleteConfirm(ParticipantID) {
    const { disabled } = this.props

    if (!disabled) {
      this.setState({
        participantToDelete: ParticipantID,
      })
    }
  }

  render() {
    const { participantIds, className, disabled, IssueTeamWorkspaceID } =
      this.props
    return (
      <Fragment>
        <TableContainer className={cx(classes.root, classes.wrap, className)}>
          <Table component="div">
            <TableHead component="div">
              <ParticipantTableHeaderRow />
            </TableHead>
            <DesktopLayout>
              <TableBody component="div">
                {participantIds.map((id) => (
                  <ParticipantTableRow
                    onDeleteConfirm={this.openDeleteConfirm}
                    key={id}
                    ParticipantID={id}
                    IssueTeamWorkspaceID={IssueTeamWorkspaceID}
                  />
                ))}
              </TableBody>
            </DesktopLayout>
          </Table>
        </TableContainer>
        <MobileLayout>
          {participantIds.map((id) => (
            <SwipeableListItem
              className={cx(classes.root, classes.wrap)}
              key={id}
              onSwipe={() => this.openDeleteConfirm(id)}
              threshold={0.5}
              disabled={disabled}
            >
              <Table component="div">
                <TableBody component="div">
                  <ParticipantTableRow
                    disableHover={true}
                    ParticipantID={id}
                    IssueTeamWorkspaceID={IssueTeamWorkspaceID}
                  />
                </TableBody>
              </Table>
            </SwipeableListItem>
          ))}
        </MobileLayout>
        <ParticipantDeleteConfirmation
          open={this.state.participantToDelete ? true : false}
          ParticipantID={this.state.participantToDelete}
          IssueTeamWorkspaceID={IssueTeamWorkspaceID}
          cancel={this.handleCancelDeleteClick}
        />
      </Fragment>
    )
  }
}

ParticipantTable.propTypes = {
  /**
   * The list of paticipants emails
   */
  participantIds: PropTypes.any,
  /**
   * ????
   */
  classes: PropTypes.any,
  className: PropTypes.string,
  onDelete: PropTypes.func,
  fillHeight: PropTypes.bool,
  disabled: PropTypes.bool,
  IssueTeamWorkspaceID: PropTypes.number,
}

ParticipantTable.defaultProps = {
  participantIds: [],
  classes: {},
  fillHeight: false,
  disabled: false,
}
