import React from 'react'
import { http } from '../_utils/http'
import { CircularProgress } from '../Shared/LoadingBackdrop'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from '@material-ui/core'
import { Button } from '../Shared/Buttons'
import { PbEvDetail } from '../Playbook/PbEvDetail'
import { useAppDispatch, useAppSelector } from '../_utils/reactReduxHooks'
import { isUserIssueApprover } from '../_rootConfigs/rootSelectors'
import {
  faChevronLeft,
  faCircleXmark,
  faPlusCircle,
} from '@fortawesome/pro-solid-svg-icons'
import { faTrash } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classes from './IssuePolicyViewer.module.scss'
import { cx } from '../_utils/objectUtils'
import { ConfirmDialog } from '../Shared/ConfirmDialog'
import { addNotification } from '../_actions'

interface Props {
  IssueID: number
}

export const IssuePolicyViewer = ({ IssueID }: Props) => {
  const [eventID, setEventID] = React.useState<any>(-1)
  const [linkedEvents, setLinkedEvents] = React.useState<any>('')
  const [unlinkedEvents, setUnlinkedEvents] = React.useState<any>('')
  const [linkedEventsLoading, setLinkedEventsLoading] =
    React.useState<boolean>(false)
  const [unlinkedEventsLoading, setUnlinkedEventsLoading] =
    React.useState<boolean>(false)
  const [showAddLinks, setShowAddLinks] = React.useState<boolean>(false)
  const isApprover = useAppSelector((state) =>
    isUserIssueApprover(state, IssueID)
  )
  const [addFilter, setAddFilter] = React.useState<string>('')
  const [deleteConfirmEventID, setDeleteConfirmEventID] =
    React.useState<number>(-1)

  const getLinkedEvents = async (): Promise<any> => {
    var customHeaders: any = []
    if (IssueID) {
      customHeaders.push({ name: 'issueid', value: `${IssueID}` })
    }
    const result = await http<any>({
      path: '/Issue/IssueEventsLinked/',
      headers: customHeaders,
    })
    if (result.ok && result.body) {
      return result.body
    } else {
      return ''
    }
  }
  const dispatch = useAppDispatch()

  const getUnlinkedEvents = async (): Promise<any> => {
    var customHeaders: any = []
    if (IssueID) {
      customHeaders.push({ name: 'issueid', value: `${IssueID}` })
    }
    const result = await http<any>({
      path: '/Issue/IssueEventsNotLinked/',
      headers: customHeaders,
    })
    if (result.ok && result.body) {
      return result.body
    } else {
      return ''
    }
  }

  const addLinkedEvent = async (eventID: number): Promise<any> => {
    var customHeaders: any = []
    if (IssueID) {
      customHeaders.push({ name: 'issueid', value: `${IssueID}` })
      customHeaders.push({ name: 'eventid', value: `${eventID}` })
    }
    const result = await http<any>({
      path: '/Issue/IssueEventsLinkedAdd/',
      headers: customHeaders,
    })
    if (result.ok && result.body) {
      return result.body
    } else {
      return ''
    }
  }

  const deleteLinkedEvent = async (eventID: number): Promise<any> => {
    var customHeaders: any = []
    if (IssueID) {
      customHeaders.push({ name: 'issueid', value: `${IssueID}` })
      customHeaders.push({ name: 'eventid', value: `${eventID}` })
    }
    const result = await http<any>({
      path: '/Issue/IssueEventsLinkedDelete/',
      headers: customHeaders,
    })
    if (result.ok && result.body) {
      return result.body
    } else {
      return ''
    }
  }

  React.useEffect(() => {
    const doGetLinkedEvents = async () => {
      setLinkedEventsLoading(true)
      const eventList = await getLinkedEvents()
      setLinkedEvents(eventList)
      setLinkedEventsLoading(false)
    }
    doGetLinkedEvents()
  }, [IssueID])

  React.useEffect(() => {
    const doGetUnlinkedEvents = async () => {
      setUnlinkedEventsLoading(true)
      const eventList = await getUnlinkedEvents()
      setUnlinkedEvents(eventList)
      setUnlinkedEventsLoading(false)
    }
    doGetUnlinkedEvents()
  }, [IssueID, linkedEvents])

  function renderLinked() {
    //if (linkedEvents) {
    if (!linkedEvents || linkedEvents.length === 0) {
      if (unlinkedEvents && unlinkedEvents.length === 0) {
        return (
          <div style={{ padding: '1rem' }}>
            <p>
              <i>There have not yet been any Policies added.</i>
            </p>
          </div>
        )
      }
      return (
        <div style={{ padding: '1rem' }}>
          <p>
            <i>There have not yet been any Policies added.</i>
          </p>
        </div>
      )
    }

    return (
      <>
        {/* <pre>{JSON.stringify(linkedEvents, null, 2)}</pre> */}
        <Table>
          <TableBody>
            {linkedEvents.map((evt: any, evtIdx: number) => (
              <TableRow key={evt.EventID}>
                <TableCell
                  className={cx(classes.textLink)}
                  style={{
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    showEvent(evt.EventID)
                  }}
                >
                  <b>{evt.EventName}</b>
                  <br />
                  <span className={cx(classes.smaller)}>{evt.PubTitle}</span>
                </TableCell>
                <TableCell>
                  {isApprover && (
                    <FontAwesomeIcon
                      className={cx(classes.styleDelete)}
                      onClick={() => {
                        setDeleteConfirmEventID(evt.EventID)
                      }}
                      icon={faTrash}
                    />
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        {deleteConfirmEventID > 0 && (
          <ConfirmDialog
            open={deleteConfirmEventID > 0}
            title="Remove Policy"
            content={`You are about to remove the linked Policy, do you wish to continue?`}
            confirm={() => {
              removeEvent(deleteConfirmEventID)
              setDeleteConfirmEventID(-1)
            }}
            cancel={() => setDeleteConfirmEventID(-1)}
          />
        )}
      </>
    )
    // }
  }

  function showEvent(EventID: number) {
    setEventID(EventID)
  }

  async function addEvent(EventID: number) {
    if (!linkedEventsLoading) {
      setLinkedEventsLoading(true)
      const eventList = await addLinkedEvent(EventID)
      setLinkedEvents(eventList)
      setLinkedEventsLoading(false)
      dispatch(
        addNotification({
          type: 'success',
          message: 'Policy Added',
          duration: 1000,
          hasCloseBtn: false,
        })
      )
    }
  }

  async function removeEvent(EventID: number) {
    if (!linkedEventsLoading) {
      setLinkedEventsLoading(true)
      const eventList = await deleteLinkedEvent(EventID)
      setLinkedEvents(eventList)
      setLinkedEventsLoading(false)
      dispatch(
        addNotification({
          type: 'success',
          message: 'Policy Removed',
          duration: 1000,
          hasCloseBtn: false,
        })
      )
    }
  }

  const filteredUnlinkedEvents =
    addFilter.length === 0
      ? unlinkedEvents
      : unlinkedEvents.filter(
          (evt: any) =>
            evt.PubTitle.toLowerCase().includes(addFilter.toLowerCase()) ||
            evt.EventName.toLowerCase().includes(addFilter.toLowerCase())
        )

  function renderUnlinked() {
    //if (unlinkedEvents) {
    if (!unlinkedEvents || unlinkedEvents.length === 0) {
      return (
        <div style={{ padding: '1rem' }}>
          <p>No Content Available to Link</p>
        </div>
      )
    }
    return (
      <>
        <TextField
          label="Filter:"
          value={addFilter}
          className={cx(classes.textfield, classes.halfWidth)}
          onChange={(evt: any) => {
            setAddFilter(evt.target.value)
          }}
        />
        <FontAwesomeIcon
          icon={faCircleXmark}
          style={{
            cursor: 'pointer',
          }}
          onClick={() => {
            setAddFilter('')
          }}
        />
        <br />
        {/* <pre>{JSON.stringify(unlinkedEvents, null, 2)}</pre> */}
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Playbook</TableCell>
              <TableCell>Event</TableCell>
              <TableCell> </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredUnlinkedEvents.map((evt: any, evtIdx: number) => (
              <TableRow key={evt.EventID}>
                <TableCell>{evt.PubTitle}</TableCell>
                <TableCell>{evt.EventName}</TableCell>
                <TableCell>
                  <FontAwesomeIcon
                    icon={faPlusCircle}
                    style={{
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      addEvent(evt.EventID)
                    }}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </>
    )
    //}
  }

  function renderEventDetail() {
    if (eventID > 0) {
      return (
        <>
          <FontAwesomeIcon
            style={{
              cursor: 'pointer',
            }}
            onClick={() => {
              setEventID(-1)
            }}
            icon={faChevronLeft}
          />

          <PbEvDetail EventID={eventID} />
        </>
      )
    }
  }

  function renderList() {
    if (linkedEventsLoading) return <CircularProgress />
    if (unlinkedEventsLoading) return <CircularProgress />
    if (eventID > 0) return <>{renderEventDetail()}</>
    if (showAddLinks)
      return (
        <>
          <FontAwesomeIcon
            style={{
              cursor: 'pointer',
            }}
            onClick={() => {
              setShowAddLinks(false)
            }}
            icon={faChevronLeft}
          />
          <br />
          Add Policy Content from Playbooks
          <br /> {renderUnlinked()}
        </>
      )
    return (
      <>
        {renderLinked()}
        {isApprover && (
          <>
            <div className={cx('d-flex justify-content-end p-3')}>
              <Button
                style={{
                  cursor: 'pointer',
                }}
                onClick={() => {
                  setShowAddLinks(true)
                }}
              >
                Add Policy
              </Button>
            </div>
          </>
        )}
      </>
    )
  }

  return <>{renderList()}</>
}
