import {
  callAction,
  ReducerAction,
  ReducerActionMap,
} from '../_utils/reduxUtils'
import { FETCH_PORTAL_USER } from './_constants'
import { PortalUser } from './_models'

// The state might be extended in the future. For now just store and return the portal user that matches
// the current logged in app account
interface PortalUserState extends PortalUser {}

export default function portalUserReducer(
  state: PortalUserState = {},
  action: ReducerAction
) {
  const mapped: ReducerActionMap = {
    [FETCH_PORTAL_USER.SUCCESS]: () => action.payload.result,
  }

  return callAction(mapped, state, action)
}

export const selectPortalUser = (state: PortalUserState) => state as PortalUser
