import * as React from 'react'
import { HPanelLayout, HPanel } from '../Layouts/HPanelLayout'
import { MasterPageLayout } from './MasterPageLayout'
import { DesktopLayout, MobileLayout } from '../Layouts/ResponsiveHidden'
import MobileViewLayout from '../Layouts/MobileViewLayout'
import { RouteComponentProps } from 'react-router'
import { PbChatRouteParams } from '../Routing/RouteProps'
import { cx } from '../_utils/objectUtils'
import classes from '../Playbook/Playbooks.module.scss'
import { PbChatList } from '../PbChat/PbChatList'
import { PbChatProps } from '../PbChat/PbChat'
import { PbChatDetail } from '../PbChat/PbChatDetail'

interface Props extends RouteComponentProps<PbChatRouteParams> {}

export const PbChatPage = ({ history, location, match }: Props) => {
  function handlePbChatClick(newChat: PbChatProps, isMobile: boolean = false) {
    if (newChat.ChatGUID !== match.params.ChatGUID)
      navigate({ ChatGUID: newChat.ChatGUID })
  }

  function handleNavigateBack() {
    history.push({
      pathname: '/PbChat',
    })
  }

  function navigate(params: PbChatRouteParams = {}) {
    if (params.ChatGUID === undefined) {
      history.push({ pathname: '/PbChat/PbChat' })
      return
    }

    history.push({
      pathname: `/PbChat/PbChat/${params?.ChatGUID}`,
    })
  }

  function handlePbChatClickMobile(newPub: PbChatProps) {
    handlePbChatClick(newPub, true)
  }

  return (
    <MasterPageLayout
      history={history}
      match={match as any}
      location={location}
    >
      <DesktopLayout>
        <HPanelLayout>
          <HPanel
            header="Secure Chats Listing"
            hasPaper={false}
            overflowY="hidden"
          >
            <PbChatList
              className={classes.pbList}
              onItemClick={handlePbChatClick}
              selected={match.params.ChatGUID}
            />
          </HPanel>

          <HPanel header="" fillWidth={true}>
            {match.params.ChatGUID && (
              <PbChatDetail
                className={classes.pbEvDetail}
                ChatGUID={match.params.ChatGUID}
              />
            )}
          </HPanel>
        </HPanelLayout>
      </DesktopLayout>
      <MobileLayout>
        {match.params.ChatGUID ? (
          <MobileViewLayout title="Secure Chat" onNavClick={handleNavigateBack}>
            <PbChatDetail
              className={classes.pbEvDetail}
              ChatGUID={match.params.ChatGUID}
            />
          </MobileViewLayout>
        ) : (
          <div className={cx(classes.pbListContainer, classes.mobile)}>
            <PbChatList
              hasPaper={false}
              className={classes.pbList}
              onItemClick={handlePbChatClickMobile}
            />
          </div>
        )}
      </MobileLayout>
    </MasterPageLayout>
  )
}
