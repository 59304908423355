import React from 'react'
import classes from './IssueTemplateListing.module.scss'
import { Spacer } from '../Shared/Spacer'
import {
  CircularProgress,
  Dialog,
  DialogContent,
  FormControlLabel,
  Radio,
  RadioGroup,
  withMobileDialog,
} from '@material-ui/core'
import { cx } from '../_utils/objectUtils'
import { Toolbar } from '@material-ui/core'
import { SlideLeft } from '../Shared/Transition'
import { HeaderNavigation } from '../Shared/HeaderNavigation'
import { useAppSelector } from '../_utils/reactReduxHooks'
import { selectIssueById } from './_selectors'
import { http } from '../_utils/http'
import { Button } from '../Shared/Buttons'

interface Props {
  IssueID: number
  open: boolean
  onClose: (
    event: any,
    reason: 'backdropClick' | 'escapeKeyDown' | 'userSaved'
  ) => void
}

interface Issue {
  IssueName: string
  IssueCreated: string
  IssueType: string
  Description: string
  lastActivityDate: string
}

const ResponsiveDialog = withMobileDialog()(Dialog)

export const IssueChangeModeDialog = ({ IssueID, open, onClose }: Props) => {
  const [picklist, setPicklist] = React.useState<any>('')
  const [picklistLoading, setPicklistLoading] = React.useState(true)
  const [newIssueType, setNewIssueType] = React.useState('')

  const getPicklist = React.useCallback(async (): Promise<any> => {
    var customHeaders: any = []
    if (IssueID) {
      customHeaders.push({ name: 'issueid', value: `${IssueID}` })
    }
    const result = await http<any>({
      path: '/Issue/ClonePicklist/',
      headers: customHeaders,
    })
    if (result.ok && result.body) {
      return result.body
    } else {
      return ''
    }
  }, [IssueID])

  const changeMode = async (): Promise<any> => {
    var customHeaders: any = []
    if (IssueID) {
      customHeaders.push({ name: 'issueid', value: `${IssueID}` })
    }
    if (newIssueType) {
      customHeaders.push({ name: 'newissuetype', value: `${newIssueType}` })
    }
    const result = await http<any>({
      path: '/Issue/ChangeMode/',
      headers: customHeaders,
    })
    if (result.ok && result.body) {
      return result.body
    } else {
      return ''
    }
  }

  const issue: Issue = useAppSelector((state) =>
    selectIssueById(state, IssueID)
  )

  React.useEffect(() => {
    setNewIssueType(picklist.IssueType)
  }, [picklist])

  React.useEffect(() => {
    const doGetPicklist = async () => {
      const picklist = await getPicklist()
      setPicklist(picklist)
      setPicklistLoading(false)
    }
    doGetPicklist()
  }, [IssueID, getPicklist])

  function handleNewIssueType(evt: any) {
    setNewIssueType(evt.target.value)
  }

  function handleSaveButton() {
    const doChangeMode = async () => {
      const cloneResult = await changeMode()
      if (cloneResult) {
        onClose({}, 'userSaved')
      }
    }
    doChangeMode()
  }

  function renderPickList() {
    if (picklistLoading) {
      return (
        <>
          <CircularProgress />
        </>
      )
    }

    if (picklist.Error) {
      return (
        <div>
          <label>{picklist.Error}</label>
        </div>
      )
    }

    return (
      <div>
        <span className={classes.title}>Change Mode</span>
        <Spacer spaceParam={'large'} />
        <div>
          <label className={classes.inputLabel}>Issue Name</label>
          <input
            className={classes.enabledInputs}
            style={{ marginBottom: 'none' }}
            type="text"
            maxLength={255}
            name="IssueName"
            id="IssueName"
            value={issue.IssueName}
            disabled={true}
          />
        </div>

        <Spacer spaceParam={'large'} />
        <div>
          <label id="lblIssueType" className={classes.inputLabel}>
            New Issue Mode
          </label>

          <RadioGroup
            aria-labelledby="lblIssueType"
            name="newIssueType"
            id="newIssueType"
            value={newIssueType}
            onChange={handleNewIssueType}
            style={{ marginLeft: '1rem' }}
          >
            {picklist.IssueTypePicklist.map((prov: any) => (
              <FormControlLabel
                key={prov.IssueType}
                value={prov.IssueType}
                control={
                  <Radio
                    className={classes.MuiIconButtonColorSecondary}
                    style={{
                      paddingTop: 0,
                      paddingBottom: 0,
                      paddingLeft: '20px',
                    }}
                  />
                }
                label={prov.IssueTypeDescr}
              />
            ))}
          </RadioGroup>
        </div>
        <Spacer spaceParam={'large'} />
        <div>
          <Button
            className={cx('p-2', classes.btnIcc)}
            variant={'primary' as any}
            onClick={handleSaveButton}
          >
            Change Mode
          </Button>
        </div>
        <Spacer spaceParam={'large'} />
        {/* <pre>{JSON.stringify(picklist, null, 2)}</pre> */}
      </div>
    )
  }

  return (
    <ResponsiveDialog
      open={open}
      onClose={onClose}
      TransitionComponent={SlideLeft as any}
    >
      <Toolbar>
        <HeaderNavigation
          title=""
          onNavClick={onClose as any}
          canGoBack={false}
        />
      </Toolbar>
      <DialogContent>{renderPickList()}</DialogContent>
    </ResponsiveDialog>
  )
}
