// @ts-nocheck
// TODO: Typescript
import { generateCrudActions } from '../_utils/reduxUtils'
import {
  FETCH_PARTICIPANTS_STATUS,
  MARK_PARTICIPANT_AS_ONLINE,
  MARK_PARTICIPANT_AS_OFFLINE,
  OrgIssueTeamConstants,
  ParticipantTeamConstants,
} from './_constants'

export const ParticipantActions = generateCrudActions('PARTICIPANT')
export const OrgIssueTeamActions = generateCrudActions(
  OrgIssueTeamConstants.PREFIX
)
export const ParticipantTeamActions = generateCrudActions(
  ParticipantTeamConstants.PREFIX
)

export function fetchParticipantsStatusSuccess(result) {
  return {
    type: FETCH_PARTICIPANTS_STATUS.SUCCESS,
    payload: {
      result,
    },
  }
}

export function markParticipantAsOnline(result) {
  return {
    type: MARK_PARTICIPANT_AS_ONLINE,
    payload: {
      result,
    },
  }
}

export function markParticipantAsOffline(result) {
  return {
    type: MARK_PARTICIPANT_AS_OFFLINE,
    payload: {
      result,
    },
  }
}
