import { bindSel, getArtifact } from '../_rootConfigs/rootReducerSlicers'
import * as priv from './_reducer'

export const selectArtifactById = bindSel(getArtifact, priv.selectArtifactById)
export const selectArtifactId = bindSel(getArtifact, priv.selectArtifactId)
export const selectArtifactLink = bindSel(getArtifact, priv.selectArtifactLink)
export const selectArtifactThumbnailHeight = bindSel(
  getArtifact,
  priv.selectArtifactThumbnailHeight
)
export const selectArtifactThumbnailWidth = bindSel(
  getArtifact,
  priv.selectArtifactThumbnailWidth
)
export const selectArtifactsIdMap = bindSel(
  getArtifact,
  priv.selectArtifactsIdMap
)
export const selectArtifactMSTeamsChannelId = bindSel(
  getArtifact,
  priv.selectArtifactMSTeamsChannelId
)
export const selectArtifactMSTeamsTeamId = bindSel(
  getArtifact,
  priv.selectArtifactMSTeamsTeamId
)
export const selectArtifactExternalStorage = bindSel(
  getArtifact,
  priv.selectArtifactExternalStorage
)
