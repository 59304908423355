// @ts-nocheck
// TODO: Typescript
import { getFetchListener } from '../_utils/sagaUtils'
import { FETCH_PLAYBOOK_LIST, FETCH_PLAYBOOK_UPDATES } from './_constants'
import { takeLatest, takeEvery } from 'redux-saga/effects'
import { fetchPlaybookList, fetchPlaybookUpdates } from './_actions'

//The saga that does the actual work...
/*
 * /playbook/
 * /api/playbook
 *
 * */
const fetchPlaybookListSaga = getFetchListener('api/playbook', {
  formatData: false,
  onLoading: fetchPlaybookList.loading,
  onSuccess: (result, params, source) => {
    //console.log("DRH DEBUG in fetchPlaybookListSaga, success");
    //console.log("DRH DEBUG result is " + result);
    let jsonResult = JSON.parse(result)
    return {
      type: FETCH_PLAYBOOK_LIST.SUCCESS,
      payload: jsonResult,
    }
  },
  onFailure: fetchPlaybookList.failure,
})

const fetchPlaybookUpdatesSaga = getFetchListener('api/playbook', {
  formatData: false,
  getHeaders: (action) => ({
    PbUpdates: 'Y',
  }),
  onLoading: fetchPlaybookUpdates.loading,
  onSuccess: (result, params, source) => {
    //console.log('DRH DEBUG in fetchPlaybookUpdatesSaga, success')
    let jsonResult = JSON.parse(result)
    return {
      type: FETCH_PLAYBOOK_UPDATES.SUCCESS,
      payload: jsonResult.PbUpdates,
    }
  },
  onFailure: fetchPlaybookUpdates.failure,
})

export const playbookSagas = [
  takeLatest(FETCH_PLAYBOOK_LIST.REQUEST, fetchPlaybookListSaga),
  takeEvery(FETCH_PLAYBOOK_UPDATES.REQUEST, fetchPlaybookUpdatesSaga),
]
