// @ts-nocheck
// TODO: Typescript
import React, { useEffect, useRef, useState, createContext } from 'react'
import './ThemeProvider.scss'
import { cx } from '../_utils/objectUtils'

const rootStyle = { height: 'inherit', width: 'inherit' }
const defaultTheme = 'light'
export const ThemeContext = createContext(defaultTheme)
export const ThemeProvider = ({
  theme = defaultTheme,
  children,
  className = '',
}) => {
  const themeClass = useState()
  const prevProps = useRef({})
  const body = document.body
  useEffect(() => {
    changeTheme(theme)
    return function cleanup() {
      removeCurrentTheme()
    }
  }, [theme, className])

  function changeTheme(newTheme) {
    removeCurrentTheme()
    addTheme(newTheme)
    updatePrevProps()
  }

  function addTheme(newTheme) {
    const newThemeClassName = getThemeClassName(newTheme)
    themeClass.current = newThemeClassName
    const newProps = {
      theme: newTheme,
      className,
      themeClass: newThemeClassName,
    }

    ;(newProps.themeClass || []).forEach((name) => body.classList.add(name))

    if (className) body.classList.add(className)
  }

  function removeCurrentTheme() {
    if (prevProps.current.className)
      body.classList.remove(prevProps.current.className)
    ;(prevProps.current.themeClass || []).forEach((name) =>
      body.classList.remove(name)
    )
  }

  function updatePrevProps() {
    prevProps.current = {
      theme,
      className,
      themeClass: themeClass.current,
    }
  }

  function getThemeClassName(theme) {
    return ['Ico_Issue_ThemeProvider', 'Ico_Issue_ThemeProvider_' + theme]
  }

  return (
    <ThemeContext.Provider value={theme}>
      <div
        className={cx(getThemeClassName(theme).join(' '), className)}
        style={rootStyle}
      >
        {children}
      </div>
    </ThemeContext.Provider>
  )
}
export default ThemeProvider
