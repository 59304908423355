// @ts-nocheck
// TODO: Typescript
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Chip } from '@material-ui/core'
import { Fragment } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const nameStyles = {
  marginRight: 4,
}

/**
 * @extends {Component<Props, {}>}}
 */
export class Tag extends Component {
  constructor(props) {
    super(props)
    this.handleTagDelete = this.handleTagDelete.bind(this)
  }

  handleTagDelete() {
    const { onDelete, TagID } = this.props
    onDelete({ TagID })
  }

  renderIcon() {
    const { iconDef, icon, TagName } = this.props

    if (icon)
      return (
        <Fragment>
          <span style={nameStyles}>{TagName}</span>
          {icon}
        </Fragment>
      )

    if (iconDef)
      return (
        <Fragment>
          <span style={nameStyles}>{TagName}</span>
          <FontAwesomeIcon icon={iconDef} />
        </Fragment>
      )

    return TagName
  }

  render() {
    // TagID and dispatch get rid of warnings, prevent icon from being added to the chip
    const {
      disabled,
      TagName,
      className,
      onDelete,
      color,
      TagID,
      dispatch,
      iconDef,
      icon,
      ...other
    } = this.props

    return (
      <Chip
        color={color}
        className={className}
        label={this.renderIcon()}
        onDelete={onDelete ? this.handleTagDelete : undefined}
        disabled={disabled}
        {...other}
      />
    )
  }
}

Tag.propTypes = {
  TagName: PropTypes.string,
  TagID: PropTypes.number,
  disabled: PropTypes.bool,
  onDelete: PropTypes.func,
  className: PropTypes.string,
  iconDef: PropTypes.any,
  icon: PropTypes.any,
}

Tag.defaultProps = {
  TagName: '',
  disabled: false,
}
