import React, { useCallback, useEffect, useState, useRef } from 'react'
import { http } from '../_utils/http'
import { ResponsiveDialog } from '../Shared/ResponsiveDialog'
import { SlideLeft } from '../Shared/Transition'
import { DialogContent, Toolbar, Input } from '@material-ui/core'
import { HeaderNavigation } from '../Shared/HeaderNavigation'
import { Button } from '../Shared/Buttons'
import Checkbox from '../CustomMaterial/Checkbox'
import { Spacer } from '../Shared/Spacer'
import { addNotification } from '../_actions'
import { useAppDispatch } from '../_utils/reactReduxHooks'

//export const IconChooser = () => {
interface Props {
  inboxGUID: any
  isOpen: boolean
  onClose: () => void
  onSubmit: (url: string, token: string) => void
  existingAdminToken: string
  existingDomain: string
}

export const AMSetting = ({
  onClose,
  isOpen,
  inboxGUID,
  onSubmit,
  existingAdminToken,
  existingDomain,
}: Props) => {
  const [adminToken, setAdminToken] = useState(existingAdminToken)
  const [editAdminInfo, setEditAdminInfo] = useState(
    existingAdminToken ? false : true
  )
  const [domain, setDomain] = useState(existingDomain)
  const [categories, setCategories] = useState<string[]>([])
  const [selectedCategories, setSelectedCategories] = useState<string[]>([])
  const [isLoadingCategories, setIsLoadingCategories] = useState(false)
  const dispatch = useAppDispatch()
  const isMounted = useRef<boolean>(true)

  useEffect(() => {
    return () => {
      isMounted.current = false
    }
  }, [])

  const getCategories = useCallback(
    async (withToken: boolean): Promise<any> => {
      setIsLoadingCategories(true)
      var customHeaders: any = []
      if (withToken) {
        customHeaders.push({ name: 'admintoken', value: adminToken })
      }

      if (domain.startsWith('https://') || domain.startsWith('http://')) {
        customHeaders.push({
          name: 'apiurl',
          value: domain + '/api/global_intel/',
        })
      } else {
        customHeaders.push({
          name: 'apiurl',
          value: 'https://' + domain + '/api/global_intel/',
        })
      }
      customHeaders.push({ name: 'inboxguid', value: inboxGUID })
      const result = await http<any>({
        path: '/AlertMedia/GetCategories',
        headers: customHeaders,
      })
      if (result.ok && result.body && isMounted.current && result.body.error) {
        dispatch(addNotification({ type: 'error', message: result.body.error }))
      } else if (result.ok && result.body && isMounted.current) {
        setCategories(result.body['categories'])
        setSelectedCategories(result.body['preSelectedCats'])
      }
      setIsLoadingCategories(false)
    },
    [adminToken, inboxGUID, dispatch, domain]
  )

  useEffect(() => {
    if (adminToken) {
      getCategories(false)
    }
  }, [adminToken, getCategories])

  const handleClick = useCallback(
    (category: string) => {
      if (selectedCategories.includes(category)) {
        setSelectedCategories((prev) => {
          let newArray = [...prev]
          newArray.splice(prev.indexOf(category), 1)
          return newArray
        })
      } else {
        setSelectedCategories((prev) => {
          let newArray = [...prev]
          newArray.push(category)
          return newArray
        })
      }
    },
    [selectedCategories]
  )

  const renderAdminToken = useCallback(() => {
    if (editAdminInfo) {
      return (
        <div>
          <span style={{ display: 'block', alignItems: 'center' }}>
            Please input your admin token:
          </span>
          <Input
            style={{
              display: 'block',
              paddingLeft: '4px',
              paddingRight: '4px',
            }}
            value={adminToken}
            onChange={(e) => setAdminToken(e.currentTarget.value)}
          />
          <Spacer />
          <span style={{ display: 'block', alignItems: 'center' }}>
            Please input your API url:
          </span>
          <Input
            style={{
              display: 'block',
              paddingLeft: '4px',
              paddingRight: '4px',
            }}
            value={domain}
            onChange={(e) => {
              setDomain(e.currentTarget.value)
            }}
          />
          <Spacer />
          <Button
            onClick={() => {
              if (domain && adminToken) {
                setEditAdminInfo(false)

                setDomain((prev) => {
                  let newDomain = prev
                  newDomain = newDomain.replace('http://', '')
                  newDomain = newDomain.replace('https://', '')
                  if (
                    newDomain.substring(
                      newDomain.length - 1,
                      newDomain.length
                    ) === '/'
                  ) {
                    newDomain = newDomain.substring(0, newDomain.length - 1)
                  }
                  return newDomain
                })

                getCategories(true)
              } else {
                dispatch(
                  addNotification({
                    message: 'Must provide admin token and api url',
                    type: 'error',
                  })
                )
              }
            }}
            disabled={isLoadingCategories}
          >
            Submit
          </Button>
        </div>
      )
    } else {
      return (
        <div>
          <span style={{ display: 'block', alignItems: 'center' }}>
            Admin token:
          </span>
          <label style={{ display: 'block' }}>{adminToken}</label>
          <Spacer />
          <span style={{ display: 'block', alignItems: 'center' }}>
            API url:
          </span>
          <label style={{ display: 'block' }}>{domain}</label>
          <Button
            style={{ width: 'none' }}
            onClick={() => setEditAdminInfo(true)}
          >
            Edit
          </Button>
        </div>
      )
    }
  }, [
    adminToken,
    editAdminInfo,
    getCategories,
    isLoadingCategories,
    domain,
    dispatch,
  ])

  const renderCategories = useCallback(() => {
    return (
      <div>
        {categories.map((category: string, index) => {
          return (
            <div onClick={() => handleClick(category)}>
              <Checkbox checked={selectedCategories.includes(category)} />
              &nbsp;&nbsp;
              {category}
            </div>
          )
        })}
      </div>
    )
  }, [categories, selectedCategories, handleClick])

  const renderContent = useCallback(() => {
    return (
      <div style={{ minWidth: '350px' }}>
        {renderAdminToken()}
        {categories.length > 0 && (
          <>
            <Spacer spaceParam={'large'} />
            <div style={{ borderBottom: 'solid 1px lightgrey' }} />
            <Spacer spaceParam={'large'} />
            <Button
              onClick={() => {
                onSubmit(
                  adminToken,
                  'https://' +
                    domain +
                    '/api/global_intel/threats?categories=' +
                    encodeURIComponent(selectedCategories.join(','))
                )
              }}
            >
              Submit Categories
            </Button>
          </>
        )}
        {categories.length > 0 && renderCategories()}
      </div>
    )
  }, [
    renderAdminToken,
    adminToken,
    selectedCategories,
    onSubmit,
    categories.length,
    renderCategories,
    domain,
  ])

  return (
    <ResponsiveDialog
      open={isOpen}
      onClose={onClose}
      TransitionComponent={SlideLeft as any}
    >
      <Toolbar>
        <HeaderNavigation
          title={'Alert Media'}
          onNavClick={onClose as any}
          canGoBack={false}
        />
      </Toolbar>
      <DialogContent>{renderContent()}</DialogContent>
    </ResponsiveDialog>
  )
}
