import React from 'react'
import { http } from '../_utils/http'
import { Button } from 'reactstrap'

import apbpop from './AddPubPasswordPopup.module.css'

interface Props {
  OrgName: string
  PubGUID: string
  PubTitle: string
  Version: number
  AuthType: string
  Updated: Date
  IsPrivate: string
  Status: string
  ShowDeleteBtn: boolean
  Password?: string
  handlePasswordSubmit(password: string): void
  handleClose: () => void
  handlePlaybookRemove: (pubGUID: string) => void
}

export interface AddPubPassProps extends Props {}

export const AddPubPasswordPopup = (props: AddPubPassProps) => {
  const [userpass, setUserPass] = React.useState<string>('')
  const [errorMsg, setErrorMsg] = React.useState<string>('')

  function handlePasswordChange(evt: any) {
    setUserPass(evt.target.value)
  }

  async function doAddPub(PubGUID: string, Password: string): Promise<any> {
    if (PubGUID) {
      var customHeaders: any = []
      customHeaders.push({ name: 'pubguid', value: `${PubGUID}` })
      customHeaders.push({ name: 'password', value: `${userpass}` })
      const result = await http<any>({
        path: '/playbook/PbAddPlaybook',
        headers: customHeaders,
      })
      if (result.ok && result.body! === 'success') {
        props.handlePasswordSubmit(userpass)
      } else {
        // we had an error, tell the user
        setErrorMsg('Password entered was incorrect')
      }
      return result
    }
  }

  function handleAddPubPasswordPopupSubmit() {
    // need to call to get the return here so we know if we should close or show error message
    //    alert("ready to submit the password");
    // props.handlePasswordSubmit(password);
    doAddPub(props.PubGUID, userpass)
  }

  function handleDeletePasswordPopupSubmit() {
    props.handlePlaybookRemove(props.PubGUID)
  }

  function renderDeleteBtn() {
    //console.log("on popup ShowDeleteBtn is " + props.ShowDeleteBtn);
    if (props.ShowDeleteBtn === true) {
      return (
        <>
          <br />
          <p style={{ textAlign: 'center' }}>
            <Button
              color="standard"
              className="btn-lg btn-primaryDark"
              name="submit"
              onClick={handleDeletePasswordPopupSubmit}
            >
              <span className="btn-primaryDark">Remove this Playbook</span>
            </Button>
          </p>
        </>
      )
    }
  }

  return (
    <div className={apbpop.popupBox}>
      <div className={apbpop.box} style={{ minWidth: '400px' }}>
        <span className={apbpop.closeIcon} onClick={props.handleClose}>
          x
        </span>
        <div style={{ minWidth: '400px' }}>
          <h2 style={{ textAlign: 'center' }}>
            A password is needed to include
          </h2>
          <div>
            <div className="form-group">
              <label className="form-label">Playbook:</label>
              <label className="form-label">{props.PubTitle}</label>
            </div>
            <div className="form-group">
              <label className="form-label">Organization:</label>
              <label className="form-label">{props.OrgName}</label>
            </div>
            <div className="form-group">
              <label className="form-label">Version:</label>
              <label className="form-label">{props.Version}</label>
            </div>
            <div className="form-group">
              <label className="form-label">Password:</label>
              <input
                className="form-control"
                type="password"
                name="userpass"
                id="userpass"
                autoComplete="off"
                onChange={handlePasswordChange}
              />
            </div>

            <br />
            <p style={{ textAlign: 'center' }}>
              <Button
                color="standard"
                className="btn-lg btn-primaryDark"
                name="submit"
                onClick={handleAddPubPasswordPopupSubmit}
              >
                <span className="btn-primaryDark">
                  Use Password to Include Playbook
                </span>
              </Button>
            </p>

            {renderDeleteBtn()}
          </div>
          <h2
            style={{
              textAlign: 'center',
              color: '#ff0000',
            }}
          >
            {errorMsg}
          </h2>
        </div>
      </div>
    </div>
  )
}

export default AddPubPasswordPopup
