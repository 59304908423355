import React, { useCallback, useEffect, useMemo, useState } from 'react'
import {
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core'
import {
  StretchBody,
  StretchHeader,
  StretchLayout,
} from '../Layouts/StretchLayout'
import classes from './StakeholderNotificationResults.module.scss'
import { Spacer } from '../Shared/Spacer'
import { icoFetch } from '../_utils/fetchUtils'
import { useIsMounted } from 'usehooks-ts'
import { useAppSelector } from '../_utils/reactReduxHooks'
import { selectStakeholderById } from '../_rootConfigs/rootSelectors'
import { CircularProgress } from '../Shared/LoadingBackdrop'
import { selectIssueAllStakeholderIds } from '../Issue/_selectors'
import { useStore } from 'react-redux'
import { StakeholderNotificationResultsDetails } from './StakeholderNotificationResultsDetails'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPeopleGroup } from '@fortawesome/pro-solid-svg-icons'
import {
  faBell,
  faEnvelope,
  faMessageDots,
  faPhoneVolume,
} from '@fortawesome/pro-light-svg-icons'
import { appDateTimeFormat } from '../_constants'
import dayjs from 'dayjs'
import { getLocalDate } from '../_utils/dateUtils'
import { isMobileApp } from '../_selectors'

interface Message {
  CreatedBy: string
  CreatedUTC: string
  ExtChannel_list?: string
  StakeholderID: number
  audience: {
    NetworkService: string
    Audience: {
      ExtChannels?: string
      Recipients: 'string'
    }
    DeliveryMethods: string
  }
  message_subject: string
  message_txt: string
  mq_id: number
  sendEmailYN: 'Y' | 'N'
  sendPushYN: 'Y' | 'N'
  sendSmsYN: 'Y' | 'N'
}

interface Props {
  isOpen: boolean
  IssueID: number
  StakeholderIDList?: Array<number>
  mq_id: number
  setMq_id: (mq_id: number) => void
}

export const StakeholderNotificationResults = ({
  isOpen,
  IssueID,
  StakeholderIDList,
  mq_id,
  setMq_id,
}: Props) => {
  const [filterValue, setFilterValue] = useState<Array<number>>(
    StakeholderIDList || []
  )
  const [messageList, setMessageList] = useState<Array<Message>>()
  const [isLoadingList, setIsLoadingList] = useState<boolean>(false)

  const filteredMessageList = useMemo(
    () =>
      messageList?.filter((message) =>
        filterValue.includes(message.StakeholderID)
      ),
    [messageList, filterValue]
  )
  const isMobile = useAppSelector(isMobileApp)

  const stakeholderIDs = useAppSelector((state) =>
    selectIssueAllStakeholderIds(state, IssueID)
  )
  const state = useStore().getState()

  const isMounted = useIsMounted()

  const getMessageLists = useCallback(() => {
    setIsLoadingList(true)

    icoFetch(`api/Stakeholders/NotificationList/${IssueID}`)
      .then((res) => res.json())
      .then((data) => {
        if (data && data.Status !== 'Failure' && isMounted()) {
          setMessageList(data.returnData)
        }
      })
      .finally(() => {
        if (isMounted()) setIsLoadingList(false)
      })
  }, [IssueID, isMounted])

  useEffect(() => {
    getMessageLists()
  }, [getMessageLists])

  const renderHeader = (
    <FormControl className="form-control h-100">
      <InputLabel shrink>Stakeholder Filter</InputLabel>
      <Select
        label="Stakeholder Filter"
        input={<OutlinedInput label="Name" />}
        multiple={true}
        displayEmpty={true}
        renderValue={() => {
          if (!filterValue || filterValue.length === 0) return 'None Selected'

          return filterValue
            ?.map((Id: number) => {
              let itemName = selectStakeholderById(state, Id).Audience
              if (itemName.length > 10) {
                itemName = itemName.slice(0, 10) + '...'
              }
              return itemName
            })
            .join(', ')
        }}
        value={filterValue || []}
        onChange={(evt: any) => {
          evt.preventDefault()
          setFilterValue(evt.target.value)
        }}
        style={{ width: '100%' }}
      >
        {stakeholderIDs.map((Id: number) => {
          return (
            <MenuItem value={Id} key={Id} selected={filterValue?.includes(Id)}>
              <Checkbox color="primary" checked={filterValue?.includes(Id)} />
              <ListItemText
                primary={selectStakeholderById(state, Id).Audience}
              />
            </MenuItem>
          )
        })}
      </Select>
    </FormControl>
  )

  const renderContent = isLoadingList ? (
    <CircularProgress />
  ) : (
    <TableContainer style={{ maxHeight: '100vh' }}>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell className={classes.nameColumn}>Stakeholder</TableCell>
            <TableCell className={classes.messageColumn}>Message</TableCell>
            <TableCell className={classes.typeColumn}>Methods</TableCell>
            <TableCell className={classes.createdColumn}>Sent</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {filteredMessageList?.map((message) => {
            let stakeholderName = selectStakeholderById(
              state,
              message.StakeholderID
            ).Audience

            return (
              <TableRow
                onClick={() => setMq_id(message?.mq_id)}
                style={{ cursor: 'pointer' }}
                key={message?.mq_id}
              >
                <TableCell className={classes.nameColumn}>
                  {stakeholderName}
                </TableCell>
                <TableCell className={classes.messageColumn} height={'16px'}>
                  {message.message_subject || message.message_txt}
                </TableCell>
                <TableCell className={classes.typeColumn}>
                  {DetermineChannels(message)}
                </TableCell>
                <TableCell className={classes.createdColumn}>
                  {dayjs(getLocalDate(message.CreatedUTC))
                    .local()
                    .format(isMobile ? 'M/D/YY' : appDateTimeFormat)}
                </TableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    </TableContainer>
  )

  return mq_id ? (
    <StakeholderNotificationResultsDetails
      isOpen={!!mq_id}
      onCancel={() => setMq_id(0)}
      mq_id={mq_id}
    />
  ) : (
    <StretchLayout>
      <div
        className="position-relative h-100"
        style={{ flex: '1 1 auto', padding: 1 }}
      >
        <StretchHeader>{renderHeader}</StretchHeader>
        <Spacer />
        <StretchBody>{renderContent}</StretchBody>
      </div>
    </StretchLayout>
  )
}

interface MessageChannelInfo {
  ExtChannel_list?: string
  sendEmailYN?: 'Y' | 'N'
  sendPushYN?: 'Y' | 'N'
  sendSmsYN?: 'Y' | 'N'
  sendTTSYN?: 'Y' | 'N'
}
export function DetermineChannels(message: MessageChannelInfo) {
  const msTeams =
    message.ExtChannel_list?.length && message.ExtChannel_list?.length > 0
      ? true
      : false
  const email = message.sendEmailYN === 'Y'
  const push = message.sendPushYN === 'Y'
  const sms = message.sendSmsYN === 'Y'
  const tts = message.sendTTSYN === 'Y'

  return (
    <span>
      {email && (
        <span className={classes.iconWrapper} style={{ padding: '0 0.2rem' }}>
          <FontAwesomeIcon icon={faEnvelope} />
          &nbsp;
        </span>
      )}
      {sms && (
        <span
          className={classes.iconWrapper}
          style={{ padding: '0 0.2rem 0 0' }}
        >
          <FontAwesomeIcon icon={faMessageDots} style={{ color: '#18882b' }} />
        </span>
      )}
      {tts && (
        <span
          className={classes.iconWrapper}
          style={{ padding: '0 0.2rem 0 0' }}
        >
          <FontAwesomeIcon icon={faPhoneVolume} style={{ color: '#18882b' }} />
        </span>
      )}
      {push && (
        <span style={{ padding: '0 0.2rem' }}>
          <FontAwesomeIcon icon={faBell} />
          &nbsp;
        </span>
      )}
      {msTeams && (
        <span style={{ padding: '0 0.2rem' }}>
          <FontAwesomeIcon icon={faPeopleGroup} style={{ color: '#7B83EB' }} />
          &nbsp;
        </span>
      )}
    </span>
  )
}
