// @ts-nocheck
// TODO: Typescript
import { normalize, schema } from 'normalizr'
import { TaskCommentState, TaskItemState } from '../Task/_models'
import { objMapDatePropsToUTC } from '../_utils/dateUtils'

export const schemaDateFields = {
  HoldingStmt: ['Created', 'Approved'],
  IssueLevel: ['Created'],
  Issue: ['IssueCreated', 'IssueResolved', 'lastActivityDate'],
  Stakeholders: ['Created'],
  evidenceLockerLinks: ['createdUTC'],
  Geolocations: ['Created'],
  ConfSched: ['Scheduled', 'ScheduledEnd', 'WeekStart', 'Created'],
  Participants: [],
  Artifacts: ['CreatedUTC'],
  CollabChat: ['Created'],
  CollabTask: ['Created'],
  wksp: ['Assigned'],
  TeamReports: ['SubmittedGMT'],
  IssueActivityLog: ['HappenedGMT'],
}

export interface NormalizedReturn {
  /**Ids of the main object***/
  result: any[]
  entities: {
    TaskItem: TaskItemState
    comment: TaskCommentState
    Artifacts: any
  }
}

export const holdingStmtSchema = new schema.Entity(
  'HoldingStmt',
  {},
  { idAttribute: 'HoldingStatementID' }
)
export const holdingStmtListSchema = new schema.Array(holdingStmtSchema)

export const OrgTagSchema = new schema.Entity(
  'OrgTag',
  {},
  { idAttribute: 'TagID' }
)
export const OrgTagListSchema = new schema.Array(OrgTagSchema)

export const issueLevelSchema = new schema.Entity(
  'IssueLevel',
  {},
  { idAttribute: 'IssueLevelID' }
)
export const issueLevelListSchema = new schema.Array(issueLevelSchema)

export const issueTypeSchema = new schema.Entity(
  'IssueType',
  {},
  { idAttribute: 'IssueTypeID' }
)
export const issueTypeListSchema = new schema.Array(issueTypeSchema)

export const stakeholderSchema = new schema.Entity(
  'Stakeholders',
  {},
  { idAttribute: 'IssueStakeholderID' }
)
export const linksSchema = new schema.Entity(
  'EvidenceLockerLinks',
  {},
  { idAttribute: 'linkID' }
)
export const geolocation_Schema = new schema.Entity(
  'Geolocations',
  {},
  { idAttribute: 'LocationID' }
)
export const stakeholderListSchema = new schema.Array(stakeholderSchema)
export const evidenceLockerLinksSchema = new schema.Array(linksSchema)
export const geolocationSchema = new schema.Array(geolocation_Schema)

export const participantSchema = new schema.Entity(
  'Participants',
  {},
  { idAttribute: 'ParticipantID' }
)
export const participantListSchema = new schema.Array(participantSchema)

export const orgIssueTeamSchema = new schema.Entity(
  'OrgIssueTeams',
  {},
  { idAttribute: 'TeamID' }
)
export const orgIssueTeamListSchema = new schema.Array(orgIssueTeamSchema)

export const conferenceSchema = new schema.Entity(
  'ConfSched',
  {},
  { idAttribute: 'ConferenceID' }
)
export const conferenceSchemaList = new schema.Array(conferenceSchema)

export const incidentReportSchema = new schema.Entity(
  'IncidReports',
  {},
  { idAttribute: 'IssueReportID' }
)
export const incidentReportListSchema = new schema.Array(incidentReportSchema)

export const formReportSchema = new schema.Entity(
  'FormReports',
  {},
  { idAttribute: 'IssueFormID' }
)
export const formReportListSchema = new schema.Array(formReportSchema)

export const teamReportSchema = new schema.Entity(
  'TeamReports',
  {},
  { idAttribute: 'TeamReportID' }
)
export const teamReportListSchema = new schema.Array(teamReportSchema)

export const artifactSchema = new schema.Entity(
  'Artifacts',
  {},
  { idAttribute: getArtifactId }
)
export const artifactListSchema = new schema.Array(artifactSchema)

export const issueActivityLogSchema = new schema.Entity(
  'IssueActivityLog',
  {},
  { idAttribute: 'Id' }
)
export const issueActivityLogListSchema = new schema.Array(
  issueActivityLogSchema
)

export const alertSchema = new schema.Entity(
  'alerts',
  {},
  { idAttribute: 'Id' }
)
export const alertListSchema = new schema.Array(alertSchema)

export const issueResponsibleSchema = new schema.Entity(
  'Responsible',
  {},
  { idAttribute: 'RespAppAcctID' }
)

export const collabChatEntrySchema = new schema.Entity(
  'chatentry',
  {
    upload: artifactSchema,
  },
  { idAttribute: 'ChatEntryID' }
)
export const collabChatEntryListSchema = new schema.Array(collabChatEntrySchema)

export const collabChatSchema = new schema.Entity(
  'CollabChat',
  {
    entry: collabChatEntryListSchema,
  },
  { idAttribute: 'ChatID' }
)
export const collabChatListSchema = new schema.Array(collabChatSchema)

export const collabTaskItemCommentSchema = new schema.Entity(
  'comment',
  {
    upload: artifactSchema,
  },
  { idAttribute: 'CommentID' }
)
export const collabTaskItemCommentListSchema = new schema.Array(
  collabTaskItemCommentSchema
)

export const collabTaskItemSchema = new schema.Entity(
  'TaskItem',
  {
    comment: collabTaskItemCommentListSchema,
  },
  { idAttribute: 'TaskItemID' }
)
export const collabTaskItemListSchema = new schema.Array(collabTaskItemSchema)

export const collabTaskSchema = new schema.Entity(
  'CollabTask',
  { TaskItem: collabTaskItemListSchema },
  { idAttribute: 'TaskID' }
)
export const collabTaskListSchema = new schema.Array(collabTaskSchema)

export const inboxSchema = new schema.Entity(
  'inboxes',
  {
    alerts: alertListSchema,
  },
  { idAttribute: 'inboxGUID' }
)
export const inboxListSchema = new schema.Array(inboxSchema)

export const workspaceSchema = new schema.Entity(
  'wksp',
  {
    Participants: participantListSchema,
    AssignToTeams: orgIssueTeamListSchema,
    ConfSched: conferenceSchemaList,
    IncidReports: incidentReportListSchema,
    FormReports: formReportListSchema,
    CollabChat: collabChatListSchema,
    CollabTask: collabTaskListSchema,
    Artifacts: artifactListSchema,
  },
  { idAttribute: 'IssueTeamWorkspaceID' }
)
export const workspaceListSchema = new schema.Array(workspaceSchema)

export const issueSchema = new schema.Entity(
  'returnData',
  {
    HoldingStmt: holdingStmtListSchema,
    IssueTag: OrgTagListSchema,
    IssueLevel: issueLevelListSchema,
    IssueType: issueTypeListSchema,
    EvidenceLockerLinks: evidenceLockerLinksSchema,
    Geolocations: geolocationSchema,
    Stakeholders: stakeholderListSchema,
    wksp: workspaceListSchema,
    TeamReports: teamReportListSchema,
    ActivityLog: issueActivityLogListSchema,
    Responsible: issueResponsibleSchema,
  },
  { idAttribute: 'IssueID' }
)
export const issueListSchema = new schema.Array(issueSchema)

export function transformEntities(entities, ...dateKeys) {
  if (!entities) return []

  if (entities.hasOwnProperty('undefined')) entities[undefined] = undefined

  objMapDatePropsToUTC(entities, ...dateKeys)

  return entities
}

export function transformMappedEntities(mappedEntities) {
  for (var entityName in mappedEntities) {
    const dateFields = schemaDateFields[entityName]
    const entityMap = mappedEntities[entityName]
    if (dateFields && entityMap) {
      transformEntities(entityMap, ...dateFields)
    }
  }
}

export function getArtifactId(artifactOrType: any, id?: any) {
  if (typeof artifactOrType == 'object')
    return (artifactOrType.UploadType || '') + (artifactOrType.ArtifactID || '')

  return (artifactOrType || '') + (id || '')
}

export function icoNormalize(
  entity: any,
  schema: schema.Entity | schema.Array
) {
  return normalize(entity, schema) as NormalizedReturn
}
