// @ts-nocheck
// TODO: Typescript
import { StakeholderActions } from './_actions'
import { WebSocketConstants } from '../_webSocket/_constants'
import { StakeholderConstants } from './_constants'
import { JsonParseSafe } from '../_utils/objectUtils'
import { objDatePropsToUTC } from '../_utils/dateUtils'
import { addNotification } from '../_actions'

export default function stakeholderHub(storeAPI, action, hubConnection) {
  switch (action.type) {
    case WebSocketConstants.CONNECTION_RETRY_SUCCESSFUL:
    case WebSocketConstants.CONNECTION_SUCCESSFUL:
      hubConnection.on('BroadcastStakeholderAdd', (data, source) => {
        data = JsonParseSafe(data)
        if (data.ErrorMessage) {
          storeAPI.dispatch(
            StakeholderActions.addFailure(
              data.ErrorMessage,
              data.returnData,
              source
            )
          )
        } else {
          const { returnData } = data
          objDatePropsToUTC(returnData, 'Created')
          storeAPI.dispatch(StakeholderActions.addSuccess(returnData, source))
          storeAPI.dispatch(
            addNotification({
              message: 'Stakeholder Added Successfully',
              type: 'success',
            })
          )
        }
      })

      hubConnection.on('BroadcastStakeholderUpdate', (data, source) => {
        data = JsonParseSafe(data)
        if (data.ErrorMessage) {
          storeAPI.dispatch(
            StakeholderActions.updateFailure(
              data.ErrorMessage,
              data.returnData,
              source
            )
          )
        } else {
          const { returnData } = data

          objDatePropsToUTC(returnData, 'Created')
          storeAPI.dispatch(
            StakeholderActions.updateSuccess(returnData, source)
          )
          storeAPI.dispatch(
            addNotification({
              message: 'Stakeholder Updated Successfully',
              type: 'success',
            })
          )
        }
      })

      hubConnection.on('BroadcastStakeholderRemove', (data, source) => {
        data = JsonParseSafe(data)
        if (!data.ErrorMessage) {
          storeAPI.dispatch(
            StakeholderActions.deleteSuccess(data.returnData, source)
          )
        }
      })

      break
    case StakeholderConstants.ADD.REQUEST:
      hubConnection.invoke('SendStakeholderAdd', action.payload.result)
      break
    case StakeholderConstants.UPDATE.REQUEST:
      hubConnection.invoke('SendStakeholderUpdate', action.payload.result)
      break
    case StakeholderConstants.DELETE.REQUEST:
      hubConnection.invoke('SendStakeholderRemove', action.payload.result)
      break
    case StakeholderConstants.ADD.FAILURE:
    case StakeholderConstants.UPDATE.FAILURE:
      storeAPI.dispatch(
        addNotification({
          type: 'error',
          duration: 3000,
          message: action.payload.ErrorMessage,
        })
      )
      break
    default:
      break
  }
}
