import {
  CircularProgress,
  FormControlLabel,
  ListItem,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
} from '@material-ui/core'
import React from 'react'
import classes from '../Issue/IssueTemplateListing.module.scss'
import { Spacer } from '../Shared/Spacer'
import { cx } from '../_utils/objectUtils'

import { Button } from 'reactstrap'
import { icoPost, useIcoFetch } from '../_utils/fetchUtils'
import { useAppDispatch } from '../_utils/reactReduxHooks'
import { addNotification } from '../_actions'
import { LoadingBackDrop } from '../Shared/LoadingBackdrop'
import dayjs from 'dayjs'
import { MeetingDialog, ScheduledMeeting } from '../Conference/MeetingDialog'
import { TiptapEditor } from '../Shared/IcoTipTapEditor'

interface Props {
  orgName: string
  orgId: number
  alertName: string
  alertDescription: string
  handleDialogClose: () => void
  toggleTemplateDisplay: (show: boolean) => void
  navigatedBack: boolean
  updateAlert: (action: string, clonedIssueID?: number) => void
  newIssueName: string | undefined
  setNewIssueName: (value: string) => void
  newDescription: string | undefined
  setNewDescription: (value: string) => void
}

const rootStyle = {
  display: 'list-item',
  listStyle: 'none',
}
const hrStyle = {
  border: '4px solid var(--icc-blue)',
  borderRadius: '5px',
}

interface Template {
  TemplateIssueID: number
  TemplateName: string
}
interface OrgTemplates {
  OrgName: string
  itmpl: Array<Template>
}

export const AlertIssueTemplateList = ({
  orgName,
  orgId,
  handleDialogClose,
  toggleTemplateDisplay,
  navigatedBack,
  updateAlert,
  newIssueName,
  setNewIssueName,
  newDescription,
  setNewDescription,
}: Props) => {
  const [templateIssueID, setTemplateIssueID] = React.useState<any>() // which template user selected
  const [newLevelID, setNewLevelID] = React.useState<string>('')
  const [issueCreating, setIssueCreating] = React.useState(false)

  const [meetingOpen, setMeetingOpen] = React.useState(false)
  const [currentMeeting, setCurrentMeeting] = React.useState<
    ScheduledMeeting | undefined
  >()

  const closeMeeting = () => {
    setMeetingOpen(false)
  }

  const dispatch = useAppDispatch()

  React.useEffect(() => {
    if (templateIssueID) {
      toggleTemplateDisplay(true)
    } else {
      toggleTemplateDisplay(false)
    }
  }, [templateIssueID, toggleTemplateDisplay])

  React.useEffect(() => {
    setTemplateIssueID(undefined)
  }, [navigatedBack])

  const templateList = useIcoFetch('api/Issue/IssueTemplates/')
  const templates = templateList?.data?.find(
    (orgTemplates: OrgTemplates) => orgTemplates.OrgName === orgName
  )?.itmpl

  const templateValues = useIcoFetch('api/Issue/ClonePicklist/', {
    required: [templateIssueID !== undefined && templateIssueID !== null],
    request: { headers: { IssueID: templateIssueID?.toString() } },
  })

  function handleCreateNewIssue() {
    setIssueCreating(true)
    const cloneIssueAndUpdateAlert = async () => {
      if (issueCreating) return
      const cloneResult = await cloneIssue()
      if (cloneResult) {
        updateAlert('clonedIssue', cloneResult?.IssueID)
        handleDialogClose()
      }
      setIssueCreating(false)
    }
    cloneIssueAndUpdateAlert()
  }
  const cloneIssue = async (): Promise<any> => {
    if (!newLevelID) {
      dispatch(
        addNotification({
          message: 'Severity for Issue was not selected.',
          type: 'error',
        })
      )
      return
    }
    const pdata = {
      fromIssueID: templateIssueID.toString(),
      newIssueName: newIssueName,
      newIssueType: 'A',
      newLevelID: newLevelID,
      newDescription: newDescription,
      copyStakeholdersYN: 'Y',
      copyTaskListYN: 'Y',
      copyTaskAssignmentsYN: 'Y',
      copyHoldingStatementYN: 'Y',
      copyIssueTagsYN: 'Y',
      copyParticipantsYN: 'Y',
      copyArtifactsYN: 'Y',
      copyConferenceYN: 'Y',
      copyPoliciesYN: 'Y',
      initialConference: currentMeeting ? JSON.stringify(currentMeeting) : null,
    }
    return await icoPost('/api/Issue/IssueClone', pdata)
      .then((res) => {
        return res.json()
      })
      .then((data) => {
        if (data.Status === 'Failure') return false
        else {
          return data
        }
      })
  }

  function includedIssueElements() {
    var retVal: string[] = []

    if (templateValues.data?.HasHoldingStatementYN === 'Y') {
      retVal.push('Holding Statement')
    }
    if (templateValues.data?.HasTasklistYN === 'Y') {
      retVal.push('Task Lists')
    }
    if (templateValues.data?.HasTasklistAssignmentYN === 'Y') {
      retVal.push('Task List Assignments')
    }
    if (templateValues.data?.HasIssueTagsYN === 'Y') {
      retVal.push('Tags')
    }
    if (templateValues.data?.HasArtifactYN === 'Y') {
      retVal.push('Attachments')
    }
    if (templateValues.data?.HasConferenceYN === 'Y') {
      retVal.push('Conference')
    }
    if (templateValues.data?.HasIssueStakeholdersYN === 'Y') {
      retVal.push('Stakeholders')
    }
    if (templateValues.data?.HasParticipantsYN === 'Y') {
      retVal.push('Participants')
    }
    if (templateValues.data?.HasPoliciesYN === 'Y') {
      retVal.push('Policies')
    }
    if (retVal.length > 0) {
      return 'Include from Template: ' + retVal.join(', ')
    } else {
      return ''
    }
  }

  function renderInputForm() {
    return (
      <div>
        <LoadingBackDrop
          text="Creating Issue..."
          open={issueCreating}
          className="position-absolute h-100 w-100"
          zIndex={99}
        />
        <span className={classes.title}>Create From Template</span>
        <span className={classes.titleSub}>
          <b>Selected Template:</b> {templateValues.data?.IssueName}
          <br />
          <b>Organization:</b> {templateValues.data?.OrgName}
        </span>
        <Spacer spaceParam={'large'} />
        <div>
          <label className={classes.inputLabel}>New Issue Name</label>
          <input
            className={classes.enabledInputs}
            style={{ marginBottom: 'none' }}
            type="text"
            maxLength={255}
            name="NewIssueName"
            id="NewIssueName"
            value={newIssueName}
            onChange={(evt) => setNewIssueName(evt.target.value)}
          />
        </div>
        <div>
          <label className={classes.inputLabel}>New Issue Description</label>
          {/* <input
              className={classes.enabledInputs}
              style={{ marginBottom: 'none' }}
              type="text"
              maxLength={255}
              name="newDescription"
              id="newDescription"
              value={newDescription}
              onChange={handleNewDescriptionChange}
            />
          </div>
          <div> */}
          <TiptapEditor
            placeholder="Description..."
            value={newDescription}
            onChange={setNewDescription}
          />
        </div>
        {/*  <span className={classes.helpText}>
            This will be the new Issue Description.
          </span> */}
        <Spacer spaceParam={'large'} />
        <div>
          <label id="lblLevelID" className={classes.inputLabel}>
            New Issue Severity
          </label>
          <RadioGroup
            aria-labelledby="lblLevelID"
            name="newLevelID"
            id="newLevelID"
            value={newLevelID}
            onChange={(evt) => setNewLevelID(evt.target.value)}
            style={{ marginLeft: '1rem' }}
          >
            {templateValues.data?.IssueLevelPicklist.map(
              (severityLevel: any, index: number) => (
                <FormControlLabel
                  value={severityLevel.LevelID}
                  control={
                    <Radio
                      className={classes.MuiIconButtonColorSecondary}
                      style={{
                        paddingTop: 0,
                        paddingBottom: 0,
                        paddingLeft: '20px',
                      }}
                    />
                  }
                  label={severityLevel.LevelName}
                  key={index}
                />
              )
            )}
          </RadioGroup>
        </div>
        <Spacer />
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button
            onClick={() => setMeetingOpen(true)}
            disabled={issueCreating}
            color="link"
          >
            Schedule Meeting
          </Button>
          {currentMeeting && (
            <div style={{ fontSize: '12px', color: 'grey' }}>
              <div> {currentMeeting.Link} </div>
              <div style={{ display: 'flex' }}>
                <div>
                  {dayjs(currentMeeting.startDate).format('MMM-DD-YYYY')}
                </div>
                <Spacer />
                <div>
                  {dayjs.utc(currentMeeting.startDate).local().format('h:mmA')}
                  &nbsp;-&nbsp;
                  {dayjs.utc(currentMeeting.endDate).local().format('h:mmA')}
                </div>
              </div>
            </div>
          )}
        </div>
        <Spacer spaceParam={'large'} />
        <div style={{ fontSize: '12px' }}>{includedIssueElements()}</div>
        <Spacer spaceParam={'large'} />
        <div>
          <Button
            className={cx('p-2', classes.btnIcc)}
            variant={'primary' as any}
            onClick={handleCreateNewIssue}
            disabled={issueCreating}
          >
            Create New Issue
          </Button>
        </div>
        <MeetingDialog
          open={meetingOpen}
          currentMeeting={currentMeeting}
          onCancel={closeMeeting}
          onSubmit={(meeting: ScheduledMeeting) => setCurrentMeeting(meeting)}
          orgId={orgId}
          orgName={orgName}
        />
      </div>
    )
  }

  function render() {
    if (templateList.loading) {
      return (
        <>
          <CircularProgress />
        </>
      )
    }
    if (!templates || !templates.length || templates.length === 0) {
      return null
    }
    if (templateIssueID && templateValues.data) {
      return renderInputForm()
    }

    if (!templates) return <></>

    return (
      <>
        <LoadingBackDrop
          text="Creating Issue..."
          open={issueCreating}
          className="position-absolute h-100 w-100"
          zIndex={99}
        />
        {templates?.length > 0 && (
          <div>
            <span className={classes.title}>Create From Template</span>

            {templates?.length > 3 ? (
              <Select
                value={templateIssueID}
                name="template"
                onChange={(evt) => setTemplateIssueID(evt.target.value)}
                style={{ width: '100%' }}
              >
                {templates.map(
                  (template: {
                    TemplateIssueID: number
                    TemplateName: string
                  }) => {
                    return (
                      <MenuItem
                        key={template.TemplateIssueID}
                        value={template.TemplateIssueID}
                      >
                        {template.TemplateName}
                      </MenuItem>
                    )
                  }
                )}
              </Select>
            ) : (
              templates?.map((template: Template, index: number) => (
                <ListItem style={rootStyle} key={index}>
                  <Button
                    color="link"
                    onClick={() => {
                      setTemplateIssueID(template.TemplateIssueID)
                    }}
                  >
                    {template.TemplateName}
                  </Button>
                </ListItem>
              ))
            )}

            <hr style={hrStyle} />
          </div>
        )}
      </>
    )
  }

  return render()
}
