import * as React from 'react'
import classes from './PbTask.module.scss'
import Moment from 'react-moment'
import { cx } from '../_utils/objectUtils'
import { AutoFocus } from '../Shared/AutoFocus'

interface Props {
  PubGUID: string
  RefPubGUID: string
  TaskGUID: string
  TaskName: string
  PubTitle: string
  hostURL: string
  EventID: number
  LatestEntryUTC: Date
  LatestActivity: string
  UnreadCount: number
  onClick?(Task: PbTaskProps): void
  selected?: boolean
}
export interface PbTaskProps extends Props {}

export const PbTask = ({
  PubGUID,
  RefPubGUID,
  TaskGUID,
  TaskName,
  PubTitle,
  hostURL,
  EventID,
  LatestEntryUTC,
  LatestActivity,
  UnreadCount,
  onClick = () => {},
  selected,
}: Props) => (
  <div
    className={cx(
      classes.icoItem,
      'Ico-Item-root',
      selected ? 'Ico-selected' : ''
    )}
  >
    <div
      className={cx(classes.clickBox, 'clearfix position-relative')}
      onClick={() =>
        onClick({
          PubGUID,
          RefPubGUID,
          TaskGUID,
          TaskName,
          PubTitle,
          hostURL,
          EventID,
          LatestEntryUTC,
          LatestActivity,
          UnreadCount,
        })
      }
    >
      {selected && <AutoFocus />}
      <div className={cx('float-left', classes.planSpec)}>
        <span style={{ fontWeight: 'bold' }}>{TaskName}</span>
        <br />
        <span style={{ fontSize: '87.5%' }}>{PubTitle}</span>
        <br />
        <span style={{ fontSize: '87.5%' }}>
          {LatestActivity}
          &nbsp;-&nbsp;
          {UnreadCount} unread &nbsp;-&nbsp;
          <Moment format="DD MMM YYYY" utc local>
            {LatestEntryUTC}
          </Moment>
        </span>
      </div>
    </div>
  </div>
)
