import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers'
import React from 'react'
import DateFnsUtils from '@date-io/moment'
import { Autocomplete } from '@material-ui/lab'
import { timeOptions } from '../Task/_constants'
import {
  Button,
  Dialog,
  FormControl,
  Input,
  InputLabel,
  Popper,
  TextField,
} from '@material-ui/core'
import { Spacer } from '../Shared/Spacer'
import dayjs from 'dayjs'
import moment from 'moment'
import { MeetingLinkDialog } from './MeetingLinkDialog'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit } from '@fortawesome/pro-light-svg-icons'
import { MeetingLink, MeetingLinkSelectDialog } from './MeetingLinkSelectDialog'

const utc = require('dayjs/plugin/utc')
export type ScheduledMeeting = {
  Link: string
  Title: string
  startDate: string
  endDate: string
  timezone: string
}

const PopperMy = (props: any) => {
  return <Popper {...props} style={{ width: 250 }} placement="bottom-start" />
}

export const MeetingDialog = ({
  onSubmit,
  currentMeeting,
  onCancel,
  open,
  orgId,
  orgName,
}: {
  onSubmit: (meeting: ScheduledMeeting) => void
  currentMeeting?: ScheduledMeeting
  onCancel: () => void
  open: boolean
  orgId: number
  orgName: string
}) => {
  dayjs.extend(utc)

  var customParseFormat = require('dayjs/plugin/customParseFormat')
  dayjs.extend(customParseFormat)

  const convertTimeTo24HourUTC = (timeString: string) => {
    const normalizedTimeString = timeString.toLowerCase().replace(/\s+/g, '')
    const time24HourFormat = normalizedTimeString.replace(
      /([0-9]+):([0-9]+)(am|pm)/,
      (match, hour, minute, period) => {
        let hours = parseInt(hour)
        const minutes = parseInt(minute)

        // Adjust hours for PM time
        if (period === 'pm' && hours !== 12) {
          hours += 12
        }

        // Convert to UTC
        const time = dayjs().set('hour', hours).set('minute', minutes).utc()

        // Format the time in 24-hour format
        return time.format('HH:mm:ss')
      }
    )
    return time24HourFormat + '.000'
  }

  const [link, setLink] = React.useState<string | undefined>()

  const [createMeetingLinkDialogOpen, setCreateMeetingLinkDialogOpen] =
    React.useState(false)

  const [selectMeetingLinkDialogOpen, setSelectMeetingLinkDialogOpen] =
    React.useState(false)
  const [description, setDescription] = React.useState<string | undefined>(
    currentMeeting?.Title
  )
  const [currentDate, setCurrentDate] = React.useState<string | undefined>(
    currentMeeting?.startDate
  )

  const [startTime, setStartTime] = React.useState<string | undefined>(
    currentMeeting?.startDate
  )
  const [endTime, setEndTime] = React.useState<string | undefined>(
    currentMeeting?.endDate
  )

  const [combinedStartDateTime, setCombinedStartDateTime] = React.useState<
    string | undefined
  >()
  const [combinedEndDateTime, setCombinedEndDateTime] = React.useState<
    string | undefined
  >()

  const [errors, setErrors] = React.useState<{ [key: string]: any }>({})

  const checkForErrors = () => {
    if (!description) {
      setErrors((prev) => ({
        ...prev,
        Description: 'Title cannot be blank',
      }))
      return true
    }
    if (!link) {
      setErrors((prev) => ({
        ...prev,
        Link: 'Link cannot be blank',
      }))
      return true
    }

    if (
      !combinedStartDateTime ||
      !combinedEndDateTime ||
      !startTime ||
      !endTime
    ) {
      setErrors((prev) => ({
        ...prev,
        DateTimeEmpty: 'Meeting must have a Date, Start, and End Time',
      }))
      return true
    }

    if (!dayjs(startTime, 'h:mm a', true).isValid()) {
      setErrors((prev) => ({
        ...prev,
        StartTime: 'Invalid Start Time',
      }))
      return true
    }

    if (!dayjs(endTime, 'h:mm a', true).isValid()) {
      setErrors((prev) => ({
        ...prev,
        EndTime: 'Invalid End Time',
      }))
      return true
    }

    if (
      dayjs.utc(combinedStartDateTime).local().isBefore(dayjs()) ||
      dayjs.utc(combinedEndDateTime).local().isBefore(dayjs())
    ) {
      setErrors((prev) => ({
        ...prev,
        StartTimeInPast: 'Meeting times cannot be in the past.',
      }))
      return true
    }
    if (
      // dayjs(conference.Scheduled).hour() * 60 +
      //   dayjs(conference.Scheduled).minute() >
      // dayjs(conference.ScheduledEnd).hour() * 60 +
      //   dayjs(conference.ScheduledEnd).minute()
      dayjs(combinedStartDateTime) > dayjs(combinedEndDateTime)
    ) {
      setErrors((prev) => ({
        ...prev,
        EndTimeBeforeStart: 'End time cannot be before start.',
      }))
      return true
    }
    return false
  }

  React.useEffect(() => {
    if (startTime)
      setCombinedStartDateTime(
        currentDate + ' ' + convertTimeTo24HourUTC(startTime)
      )
    if (endTime)
      setCombinedEndDateTime(
        currentDate + ' ' + convertTimeTo24HourUTC(endTime)
      )
  }, [currentDate, startTime, endTime])

  React.useEffect(() => {
    if (startTime || endTime) return
    if (!currentDate) return
    const currentTime = dayjs()

    setStartTime(currentTime.format('h:mm a'))

    setEndTime(currentTime.add(30, 'minute').format('h:mm a'))
  }, [currentDate])

  return (
    <div>
      <Dialog
        open={open}
        onClose={(_, reason) => reason === 'backdropClick' && onCancel()}
      >
        <div>
          <div
            style={{
              width: '500px',
              height: '300px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
            }}
          >
            <div> Schedule Meeting </div>
            <FormControl required={true} style={{ width: '90%' }}>
              <InputLabel shrink>Meeting Title</InputLabel>
              <Input
                type="text"
                required={true}
                name="Title"
                inputProps={{
                  maxLength: 255,
                }}
                value={description}
                onChange={(evt) => {
                  if (errors['Description']) {
                    delete errors['Description']
                  }
                  setDescription(evt.target.value)
                }}
              />
            </FormControl>
            <Spacer />
            <div
              style={{ display: 'flex', width: '90%', alignItems: 'center' }}
            >
              <FormControl required={true} style={{ flexGrow: '1' }}>
                <InputLabel shrink>Link</InputLabel>
                <Input
                  type="text"
                  required={true}
                  name="Audience"
                  inputProps={{
                    maxLength: 255,
                  }}
                  value={link}
                  onChange={(evt) => {
                    if (errors['Link']) {
                      delete errors['Link']
                    }
                    setLink(evt.target.value)
                  }}
                />
              </FormControl>
              <Spacer />
              <div style={{ cursor: 'pointer' }}>
                <FontAwesomeIcon
                  style={{ marginTop: '1rem' }}
                  size={'xl'}
                  icon={faEdit}
                  onClick={() => setSelectMeetingLinkDialogOpen(true)}
                />
              </div>
            </div>

            <Button onClick={() => setCreateMeetingLinkDialogOpen(true)}>
              Create Organization Meeting Link
            </Button>

            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <div style={{ width: '90%' }}>
                <KeyboardDatePicker
                  className="w-100"
                  clearable
                  fullWidth
                  format="yyyy-MM-DD"
                  margin="normal"
                  label="Date"
                  onChange={(dateUtil, value) => {
                    if (
                      errors['DateTimeEmpty'] ||
                      errors['StartTime'] ||
                      errors['EndTime'] ||
                      errors['StartTimeInPast'] ||
                      errors['EndTimeBeforeStart']
                    ) {
                      delete errors['DateTimeEmpty']
                      delete errors['StartTime']
                      delete errors['EndTime']
                      delete errors['StartTimeInPast']
                      delete errors['EndTimeBeforeStart']
                    }
                    setCurrentDate(value ? value : undefined)
                  }}
                  value={currentDate ? currentDate : null}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                />
              </div>
            </MuiPickersUtilsProvider>

            {currentDate && (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  width: '90%',
                }}
              >
                {' '}
                <Autocomplete
                  options={timeOptions}
                  style={{ flexGrow: '1' }}
                  disableClearable
                  popupIcon={false}
                  autoComplete
                  includeInputInList
                  freeSolo
                  ListboxProps={{
                    style: { maxHeight: `180px` },
                  }}
                  onInputChange={(_, data) => {
                    if (
                      errors['DateTimeEmpty'] ||
                      errors['StartTime'] ||
                      errors['EndTime'] ||
                      errors['StartTimeInPast'] ||
                      errors['EndTimeBeforeStart']
                    ) {
                      delete errors['DateTimeEmpty']
                      delete errors['StartTime']
                      delete errors['EndTime']
                      delete errors['StartTimeInPast']
                      delete errors['EndTimeBeforeStart']
                    }
                    setStartTime(data)
                  }}
                  value={startTime ? startTime : ''}
                  PopperComponent={PopperMy}
                  renderInput={(params) => (
                    <TextField
                      variant="standard"
                      {...params}
                      label={'Start Time'}
                      fullWidth
                      InputProps={{
                        ...params.InputProps,
                      }}
                    />
                  )}
                />
                <Spacer />
                <Autocomplete
                  options={timeOptions}
                  style={{ flexGrow: '1' }}
                  disableClearable
                  popupIcon={false}
                  autoComplete
                  includeInputInList
                  freeSolo
                  ListboxProps={{
                    style: { maxHeight: `180px` },
                  }}
                  onInputChange={(_, data) => {
                    if (
                      errors['DateTimeEmpty'] ||
                      errors['StartTime'] ||
                      errors['EndTime'] ||
                      errors['StartTimeInPast'] ||
                      errors['EndTimeBeforeStart']
                    ) {
                      delete errors['DateTimeEmpty']
                      delete errors['StartTime']
                      delete errors['EndTime']
                      delete errors['StartTimeInPast']
                      delete errors['EndTimeBeforeStart']
                    }
                    setEndTime(data)
                  }}
                  value={endTime ? endTime : ''}
                  PopperComponent={PopperMy}
                  renderInput={(params) => (
                    <TextField
                      variant="standard"
                      {...params}
                      label={'End Time'}
                      fullWidth
                      InputProps={{
                        ...params.InputProps,
                      }}
                    />
                  )}
                />
              </div>
            )}
          </div>

          <div
            style={{
              display: 'flex',
              justifyContent:
                Object.keys(errors).length > 0 ? 'space-between' : 'flex-end',
              alignItems: 'center',
              padding: '0px 20px  20px 20px',
            }}
          >
            {Object.keys(errors).length > 0 && (
              <div style={{ color: 'red' }}>
                {errors[`${Object.keys(errors)[0]}`]}
              </div>
            )}

            <div
              style={{
                display: 'flex',
              }}
            >
              <Button
                onClick={() => {
                  onCancel()
                  setErrors({})
                }}
              >
                Cancel
              </Button>
              <Spacer />
              <Button
                onClick={() => {
                  if (checkForErrors()) {
                    return
                  }
                  onSubmit({
                    Link: link ? link : '',
                    Title: description ? description : '',
                    startDate: combinedStartDateTime
                      ? combinedStartDateTime
                      : '',
                    endDate: combinedEndDateTime ? combinedEndDateTime : '',
                    timezone: moment.tz.guess(),
                  })
                  setErrors({})
                  onCancel()
                }}
              >
                {' '}
                Submit
              </Button>
            </div>
          </div>
        </div>
      </Dialog>
      <MeetingLinkDialog
        open={createMeetingLinkDialogOpen}
        onCancel={() => setCreateMeetingLinkDialogOpen(false)}
        orgId={orgId}
        orgName={orgName}
      />
      <MeetingLinkSelectDialog
        onSelect={(meeting: MeetingLink) => {
          setLink(meeting.link)
        }}
        open={selectMeetingLinkDialogOpen}
        onCancel={() => setSelectMeetingLinkDialogOpen(false)}
        orgId={orgId}
      />
    </div>
  )
}
