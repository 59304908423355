import React, { useCallback } from 'react'
import Moment from 'react-moment'
import { useAppSelector } from '../_utils/reactReduxHooks'
import { cx } from '../_utils/objectUtils'
import { MultiLineText } from '../Shared/MultiLineText'
import { appDateTimeFormat } from '../_constants'
import { sanitizeHTML } from '../_utils/domUtils'
import { getLocalDate } from '../_utils/dateUtils'
import classes from './IssueDetailMenuHeader.module.scss'
import {
  selectIssueCurrentIssueLevel,
  selectIssueTrendColor,
  selectIssueTrendName,
} from '../_rootConfigs/rootSelectors'
import { selectIssueById, selectOrgIssueTypeById } from './_selectors'
import { Spacer } from '../Shared/Spacer'
import { faFilePlus } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

interface Issue {
  IssueName: string
  IssueCreated: string
  IssueType: string
  Description: string
  lastActivityDate: string
  issueOrgName: string
  TemplateFlagYN: string
}

export const IssueDetailMenuHeader = ({ IssueID }: { IssueID: number }) => {
  const issue: Issue = useAppSelector((state) =>
    selectIssueById(state, IssueID)
  )
  const { IssueTypeRGB, Descr } = useAppSelector((state) =>
    selectOrgIssueTypeById(state, issue.IssueType)
  )
  const issueLevel = useAppSelector((state) =>
    selectIssueCurrentIssueLevel(state, IssueID)
  )

  const IssueTrendName = useAppSelector((state) =>
    selectIssueTrendName(state, IssueID)
  )
  const IssueTrendColor = useAppSelector((state) =>
    selectIssueTrendColor(state, IssueID)
  )
  const renderDateAndLevel = useCallback(() => {
    return (
      <div className={cx(classes.dateSegment, classes.flexRow)}>
        <div className={cx(classes.flexColumn)}>
          <span>
            <span style={{ fontSize: '.7rem' }}>Issue Created:</span>&nbsp;
            <Moment
              local
              format={appDateTimeFormat}
              className={classes.dateTime}
              date={getLocalDate(issue.IssueCreated)}
            />
          </span>
          <div>
            <span style={{ fontSize: '.7rem' }}>Last Activity:</span>&nbsp;
            <Moment className={classes.dateTime} local fromNow>
              {getLocalDate(issue.lastActivityDate)}
            </Moment>
          </div>
        </div>
        <Spacer spaceParam={'medium'} />
        <div className={cx(classes.flexColumn)}>
          <span
            className={cx(classes.level)}
            style={{
              borderColor: issueLevel?.LevelRGB,
            }}
          >
            {issueLevel?.LevelName}
          </span>
          <div className={classes.trend} style={{ color: IssueTrendColor }}>
            {IssueTrendName}
          </div>
        </div>
      </div>
    )
  }, [
    issue.lastActivityDate,
    issue.IssueCreated,
    issueLevel?.LevelName,
    issueLevel?.LevelRGB,
    IssueTrendColor,
    IssueTrendName,
  ])

  const renderDescription = useCallback(() => {
    return (
      issue.Description && (
        <MultiLineText
          className={classes.description}
          lines={8}
          minLines={3}
          dangerouslySetInnerHTML={sanitizeHTML(issue.Description, {
            allowedTags: ['p', 'br', 'u', 's', 'ol', 'ul', 'li'],
          })}
        />
      )
    )
  }, [issue.Description])

  return (
    <div className={cx(classes.pane, classes.root)}>
      <span className={classes.label}>Description</span>
      <Spacer />
      {renderDescription()}
      <Spacer spaceParam="large" />

      {renderDateAndLevel()}

      <div className={classes.flexRow}>
        <span className={classes.orgName}>{issue.issueOrgName}</span>
        <span className={classes.typeDescr} style={{ color: IssueTypeRGB }}>
          {issue.TemplateFlagYN === 'Y' && (
            <>
              <FontAwesomeIcon icon={faFilePlus} style={{ filter: 'none' }} />
              &nbsp;&nbsp;
            </>
          )}
          {issue.IssueType !== 'A' ? Descr : ''}
        </span>
      </div>
    </div>
  )
}
