// @ts-nocheck
// TODO: Typescript
import React, { Component } from 'react'
import TaskListItemConnected, { TaskListItem } from './TaskListItem'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Draggable, DragDropContext, Droppable } from 'react-beautiful-dnd'
import classes from './TaskList.module.scss'
import { setIsViewingTask, TaskDetailActions, updateTaskRead } from './_actions'
import { CircularProgress } from '../Shared/LoadingBackdrop'
import { isFetchTaskItemsPreviouslyLoaded } from '../Shared/_requestStatusReducer'
import {
  selectTaskAllItemIds,
  selectTaskFilteredItemIds,
} from '../_rootConfigs/rootSelectors'
import { isTaskRefreshReadRequired, selectTaskItemById } from './_selectors'
import { isFetchTaskItemsLoading } from '../Shared/_requestStatusSelectors'
import { selectOrgRoles } from '../_utils/apiUtils'

//const grid = 8;
const getItemStyle = (isDragging, draggableStyle) => ({
  listStyle: 'none',
  paddingTop: '5px',

  // some basic styles to make the items look a bit nicer
  /*userSelect: "none",
    padding: grid * 2,
    margin: `0 0 ${grid}px 0`,

    // change background colour if dragging
    background: isDragging ? "lightgreen" : "grey",*/

  // styles we need to apply on draggables
  ...draggableStyle,
})

const getListStyle = (isDraggingOver) => ({
  /*background: isDraggingOver ? "lightblue" : "lightgrey",
    padding: grid,
    width: 250*/
})

//const unreadDelayMins = 1;
//const unreadDelay = 1000 * 60 * unreadDelayMins;
const unreadDelaySecs = 30
const unreadDelay = 1000 * unreadDelaySecs

/**
 * @extends {Component<Props, {}>}}
 * Will render a list of task items
 */
export class TaskList extends Component {
  constructor(props) {
    super(props)
    this.onDragEnd = this.onDragEnd.bind(this)

    this.state = { roleList: undefined }

    this._unreadTimer = undefined
    this._isMounted = false
  }

  componentDidMount() {
    this._isMounted = true
    const { TaskID, updateRead, setIsViewing } = this.props

    setIsViewing(TaskID, true)
    updateRead(TaskID)

    if (!this.state.roleList) {
      selectOrgRoles(this.props.OrgID).then((data) => {
        this.setState({
          roleList: data,
        })
      })
    }

    this._unreadTimer = setInterval(() => {
      if (!this._isMounted) return
      const { isRefreshReadRequired } = this.props
      if (isRefreshReadRequired) updateRead(TaskID)
    }, unreadDelay)
  }

  componentWillUnmount() {
    this._isMounted = false
    const { updateRead, TaskID, setIsViewing } = this.props
    setIsViewing(TaskID, false)
    updateRead(TaskID)
    clearTimeout(this._unreadTimer)
  }

  render() {
    const {
      displayTaskItemIds,
      taskItems,
      reorderEnabled,
      isFetchTaskItemsLoading,
    } = this.props
    if (!displayTaskItemIds && !taskItems) {
      return (
        <div>
          There are currently no tasks in this Issue. Add a new task item below
          or use the actions in top right if Administator/Approver
        </div>
      )
    }
    /*if (isFetchTaskItemsLoading)
            return <LoadingBackDrop zIndex={9} className="position-absolute" text="...Loading Task Items" open={isFetchTaskItemsLoading} />;*/
    if (isFetchTaskItemsLoading) return <CircularProgress />
    // Use a regular list worry about virtualize later
    if (!reorderEnabled) {
      return (
        <div className={classes.root}>
          {(displayTaskItemIds || taskItems).map((idOrItem, index) =>
            this.renderTaskItem(idOrItem, index)
          )}
        </div>
      )
    } else {
      return (
        <DragDropContext onDragEnd={this.onDragEnd}>
          <Droppable droppableId="taskListDroppable">
            {(provided, snapshot) => (
              <ul
                className={classes.root}
                {...provided.droppableProps}
                ref={provided.innerRef}
                style={getListStyle(snapshot.isDraggingOver)}
              >
                {(displayTaskItemIds || taskItems).map((idOrItem, index) =>
                  this.renderTaskItem(idOrItem, index)
                )}
                {provided.placeholder}
              </ul>
            )}
          </Droppable>
        </DragDropContext>
      )
    }
  }

  onDragEnd(result) {
    // dropped outside the list
    if (!result.destination) {
      return
    }

    const {
      onReorder,
      onReorderSubmit,
      TaskID,
      taskItemIds,
      displayTaskItemIds,
    } = this.props
    const TaskItemID = parseInt(result.draggableId)

    if (onReorder) {
      const destIndex = result.destination.index
      const sourceIndex = result.source.index

      // Get the true index by finding the item id in the full list
      const afterItemIndex = taskItemIds.indexOf(displayTaskItemIds[destIndex])
      const entity = {
        TaskID,
        TaskItemID,
        Sequence: afterItemIndex,
      }

      // Pass the id of the next item. The item must order itself relative to
      // the next item
      const source = {
        TaskID,
        destIndex,
        sourceIndex,
        itemIds: taskItemIds,
      }
      onReorder(entity, source)

      if (onReorderSubmit) onReorderSubmit(entity, source)
    }
  }

  renderTaskItem(taskItemOrID, index) {
    let draggableID

    if (typeof taskItemOrID === 'number') draggableID = taskItemOrID.toString()
    else draggableID = taskItemOrID.TaskItemID.toString()

    const { reorderEnabled, readOnly } = this.props

    // Wrap the task item in a draggable
    if (reorderEnabled && !readOnly) {
      return (
        <Draggable key={draggableID} draggableId={draggableID} index={index}>
          {(provided, snapshot) => (
            <li
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              style={getItemStyle(
                snapshot.isDragging,
                provided.draggableProps.style
              )}
            >
              {this.getTaskItemJsx(taskItemOrID, {
                snapshot,
                provided,
              })}
            </li>
          )}
        </Draggable>
      )
    }

    // Return a non draggable
    return this.getTaskItemJsx(taskItemOrID, reorderEnabled)
  }

  getTaskItemJsx(taskItemOrID, dragProps) {
    const { onTaskItemClick, TaskID, style, selected, readOnly, OrgID } =
      this.props
    let classes = undefined
    let taskItemJSX = undefined
    let componentTag = dragProps ? 'div' : 'li'

    // add special classes for dragging
    if (dragProps && (dragProps.snapshot || {}).isDragging) {
      classes = {
        root: 'border shadow-sm',
      }
    }

    if (typeof taskItemOrID === 'number') {
      // Connected
      const TaskItemID = taskItemOrID
      taskItemJSX = (
        <TaskListItemConnected
          TaskID={TaskID}
          key={TaskItemID}
          component={componentTag}
          TaskItemID={TaskItemID}
          selected={selected === TaskItemID ? true : false}
          onClick={onTaskItemClick}
          classes={classes}
          style={style}
          readOnly={readOnly}
          OrgID={OrgID}
          roleList={this.state.roleList}
        />
      )
    } else {
      // Non connected
      const { TaskItemID, ...other } = taskItemOrID
      taskItemJSX = (
        <TaskListItem
          TaskID={TaskID}
          key={TaskItemID}
          {...other}
          component={componentTag}
          TaskItemID={TaskItemID}
          selected={selected === TaskItemID ? true : false}
          onClick={onTaskItemClick}
          classes={classes}
          style={style}
          readOnly={readOnly}
          OrgID={OrgID}
          roleList={this.state.roleList}
        />
      )
    }

    return taskItemJSX
  }
}

TaskList.propTypes = {
  /**
   * The id of the task. This is useful for connected mode.
   */
  TaskID: PropTypes.number,
  /**
   * The full list (sorted list) of item ids that belong to a task.
   */
  taskItemIds: PropTypes.arrayOf(PropTypes.number),
  /**
   * The display list (filtered list) of item ids that belong to a task.
   */
  displayTaskItemIds: PropTypes.arrayOf(PropTypes.number),
  /**
   * Any array of task items
   */
  taskItems: PropTypes.arrayOf(TaskListItem.propTypes),
  /**
   * The selected task item by id
   */
  selected: PropTypes.number,
  /**
   * event for when a task item is clicked
   */
  onTaskItemClick: PropTypes.func,
  /**
   * Determines if a task list can reorder the task items
   */
  reorderEnabled: PropTypes.bool,
  /**
   * A callback for the task item that gets reordered
   */
  onReorder: PropTypes.func,
  readOnly: PropTypes.bool,
  OrgID: PropTypes.any,
}

TaskList.defaultProps = {
  reorderEnabled: true,
}

const mapStateToProps = (state, ownProps) => ({
  taskItemIds: selectTaskAllItemIds(state, ownProps.TaskID),
  displayTaskItemIds: selectTaskFilteredItemIds(state, ownProps),
  selectTaskItemById: (taskItemID) => selectTaskItemById(state, taskItemID),
  isFetchTaskItemsLoading:
    isFetchTaskItemsLoading(state) &&
    !isFetchTaskItemsPreviouslyLoaded(state, ownProps.TaskID),
  isRefreshReadRequired: isTaskRefreshReadRequired(state, ownProps.TaskID),
})

const mapDispatchToProps = {
  onReorder: TaskDetailActions.reorderSuccess,
  onReorderSubmit: TaskDetailActions.reorder,
  updateRead: (TaskID) => updateTaskRead.request({ TaskID }),
  setIsViewing: setIsViewingTask,
}

export default connect(mapStateToProps, mapDispatchToProps)(TaskList)
