// @ts-nocheck
// TODO: Typescript
import { all } from 'redux-saga/effects'
import { chatSagas } from '../Chat/_sagas'
import { authSagas } from '../Auth/_sagas'
import { issueSagas } from '../Issue/_sagas'
import { appSagas } from '../_sagas'
import { taskSagas } from '../Task/_sagas'
import { uploadSagas } from '../Upload/_sagas'
import { formSagas } from '../Form/_sagas'
import { orgTagSagas } from '../Org/_sagas'
import { graphSagas } from '../MicrosoftTeams/_sagas'
import { alertSagas } from '../Alert/_sagas'
import { portalUserSagas } from '../PortalUser/_sagas'
import { playbookSagas } from '../Playbook/_sagas'
import { activePollSagas } from '../ActivePoll/_sagas'

// Saga that yields all other sagas
export default function* rootSaga() {
  yield all([
    ...authSagas,
    ...chatSagas,
    ...issueSagas,
    ...taskSagas,
    ...uploadSagas,
    ...appSagas,
    ...formSagas,
    ...orgTagSagas,
    ...graphSagas,
    ...alertSagas,
    ...portalUserSagas,
    ...playbookSagas,
    ...activePollSagas,
  ])
}
