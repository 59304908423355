// @ts-nocheck
// TODO: Typescript
import React, { Component } from 'react'
import TaskList from './TaskList'
import PropTypes from 'prop-types'

/**
 * @extends {Component<Props, {}>}}
 */
export default class TaskListEditor extends Component {
  render() {
    const { TaskID, onTaskItemClick, selected, filter, readOnly, OrgID } =
      this.props

    if (!TaskID) return null

    return (
      <React.Fragment>
        <TaskList
          TaskID={TaskID}
          onTaskItemClick={onTaskItemClick}
          selected={selected}
          filter={filter}
          readOnly={readOnly}
          OrgID={OrgID}
        />
      </React.Fragment>
    )
  }
}

TaskListEditor.propTypes = {
  TaskID: PropTypes.any,
  onTaskItemClick: PropTypes.any,
  selected: PropTypes.any,
  filter: PropTypes.any,
  readOnly: PropTypes.bool,
  OrgID: PropTypes.any,
}
