import {
  bindSel,
  getAferSubmitIssueForms,
  getCreateIssueForms,
  getCurrentFormContent,
  getTeamReport,
} from '../_rootConfigs/rootReducerSlicers'
import * as priv from '../Form/_reducer'

export const selectCreateIssueForms = bindSel(
  getCreateIssueForms,
  priv.selectCreateIssueForms
)
export const selectCurrentFormContent = bindSel(
  getCurrentFormContent,
  priv.selectCurrentFormContent
)
export const selectCurrentChangeLevelContent = bindSel(
  getCurrentFormContent,
  priv.selectCurrentChangeLevelContent
)
export const selectCurrentOpenIssueContent = bindSel(
  getCurrentFormContent,
  priv.selectCurrentOpenIssueContent
)
export const selectCurrentResolveIssueContent = bindSel(
  getCurrentFormContent,
  priv.selectCurrentResolveIssueContent
)
export const selectAfterSubmitIssueForms = bindSel(
  getAferSubmitIssueForms,
  priv.selectAfterSubmitIssueForms
)
export const selectTeamReportById = bindSel(
  getTeamReport,
  priv.selectTeamReportById
)
export const selectTeamReportIDMap = bindSel(
  getTeamReport,
  priv.selectTeamReportIDMap
)
export const selectTeamReports = bindSel(getTeamReport, priv.selectTeamReports)
export const selectTeamReportIdByIssueReport = bindSel(
  getTeamReport,
  priv.selectTeamReportIdByIssueReport
)
