import * as React from 'react'
import { HPanelLayout, HPanel } from '../Layouts/HPanelLayout'
import { MasterPageLayout } from './MasterPageLayout'
import { DesktopLayout, MobileLayout } from '../Layouts/ResponsiveHidden'
import MobileViewLayout from '../Layouts/MobileViewLayout'
import { RouteComponentProps } from 'react-router'
import { PbChatRouteParams } from '../Routing/RouteProps'
import classes from '../Playbook/Playbooks.module.scss'
import addClasses from '../AddPlaybook/AddPlaybook.module.scss'
import { PbAddPubPlan } from '../AddPlaybook/PbAddPubPlan'
import { PbEvList } from '../Playbook/PbEvList'

interface Props extends RouteComponentProps<PbChatRouteParams> {}

export const AddPlaybookPage = ({ history, location, match }: Props) => {
  const [previewPubGUID, setPreviewPubGUID] = React.useState<any>()
  const [previewPubTitle, setPreviewPubTitle] = React.useState<string>('')
  const previewPane = () => {
    if (previewPubGUID) {
      if (previewPubGUID.length > 0) {
        return (
          <div className={addClasses.paddingIncludePlaybook}>
            <h2 className="alignCenter">{previewPubTitle}</h2>
            <PbEvList pubGUID={previewPubGUID} />
          </div>
        )
      }
    }
    if (previewPubTitle) {
      return (
        <div className={addClasses.paddingIncludePlaybook}>
          <h2 className="alignCenter">{previewPubTitle}</h2>
          <p>
            <i>Playbook is protected</i>
          </p>
        </div>
      )
    }
    return (
      <div className={addClasses.paddingIncludePlaybook}>
        <h2 className="alignCenter"> </h2>
        <p>
          <i>Select Public Playbook to Preview Here</i>
        </p>
      </div>
    )
  }

  return (
    <MasterPageLayout
      history={history}
      match={match as any}
      location={location}
    >
      <DesktopLayout>
        <HPanelLayout>
          <HPanel header="Public Playbooks" className={addClasses.panel50}>
            <PbAddPubPlan
              className={classes.pbList}
              setPreviewPubGUID={setPreviewPubGUID}
              setPreviewPubTitle={setPreviewPubTitle}
            />
          </HPanel>
          <HPanel className={addClasses.panel50}>{previewPane()}</HPanel>
        </HPanelLayout>
      </DesktopLayout>
      <MobileLayout>
        <MobileViewLayout title="Add Playbooks">
          <PbAddPubPlan
            className={classes.pbEvDetail}
            setPreviewPubGUID={setPreviewPubGUID}
            setPreviewPubTitle={setPreviewPubTitle}
          />
        </MobileViewLayout>
      </MobileLayout>
    </MasterPageLayout>
  )
}
