import React, { ReactChild } from 'react'
import classes from './IconChooser.module.css'

//export const IconChooser = () => {
interface Props {
  content: ReactChild
  handleClose: () => void
}

const IconChooser = ({ content, handleClose }: Props) => {
  return (
    <div className={classes.popupBox}>
      <div className={classes.box}>
        <span className={classes.closeIcon} onClick={handleClose}>
          x
        </span>
        {content}
      </div>
    </div>
  )
}

export default IconChooser
