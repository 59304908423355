// @ts-nocheck
// TODO: Typescript
import React, { Component } from 'react'
import { TableRow, TableCell, Typography, Button } from '@material-ui/core'
import PropTypes from 'prop-types'
import classes from './ParticipantTableRow.module.scss'
import { connect } from 'react-redux'
import { ParticipantActions } from './_actions'
import { generateInputChangeSubmit } from '../_utils/reduxUtils'
import { cx } from '../_utils/objectUtils'
import {
  canUserAdministerWorkspace,
  isParticipantOnline,
  selectParticipantById,
  selectParticipantJoinedTeamNames,
} from '../_rootConfigs/rootSelectors'
import { selectPartRoleNameById, selectPartRoles } from '../_selectors'

const deleteBtnStyle = {
  width: 'auto',
}

/**
 * @extends {Component<Props, {}>}}
 * Renders a table row for a participant table
 */
export class ParticipantTableRow extends Component {
  constructor(props) {
    super(props)
    this.handleDeleteClick = this.handleDeleteClick.bind(this)
    this.handleInputChangeSubmit = generateInputChangeSubmit(this, 2000)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleMouseHover = this.handleMouseHover.bind(this)

    this.state = {
      participantToDelete: undefined,
      isHovering: false,
      IssuePartRoleID: props.IssuePartRoleID,
    }
  }

  componentDidUpdate(prevProps) {
    const { IssuePartRoleID, canChangeRole } = this.props

    // The role has been updated from the outside
    if (
      IssuePartRoleID !== this.state.IssuePartRoleID &&
      prevProps.IssuePartRoleID !== IssuePartRoleID &&
      canChangeRole
    ) {
      this.setState({
        IssuePartRoleID,
      })
    }
  }

  handleMouseHover(evt) {
    if (this.props.disableHover) return
    this.setState({
      isHovering: evt.type === 'mouseenter',
    })
  }

  handleDeleteClick() {
    const { ParticipantID, onDeleteConfirm } = this.props
    if (onDeleteConfirm) onDeleteConfirm(ParticipantID)
  }

  handleInputChange(evt) {
    let { name, value } = evt.target
    value = parseInt(value)
    this.setState({
      [name]: value,
    })
    this.handleInputChangeSubmit(name, value, this.props)
  }

  render() {
    const {
      disabled,
      disableHover,
      canChangeRole,
      FullName,
      email_address,
      partRoles,
      Role,
      isOnline,
      teamNames,
      onDeleteConfirm,
    } = this.props

    return (
      <TableRow
        className={classes.root}
        component="div"
        onMouseEnter={this.handleMouseHover}
        onMouseLeave={this.handleMouseHover}
        onClick={this.handleClick}
        style={{ cursor: 'pointer' }}
        selected={this.state.isHovering}
      >
        <TableCell component="div">
          {isOnline ? (
            <span className={cx(classes.statusBubble, 'bg-success')}></span>
          ) : null}
          <div>{FullName}</div>
          <Typography color="textSecondary">{email_address}</Typography>
          {!disableHover && this.state.isHovering && onDeleteConfirm && (
            <Button
              onClick={this.handleDeleteClick}
              style={deleteBtnStyle}
              disabled={disabled}
            >
              Remove
            </Button>
          )}
        </TableCell>
        <TableCell component="div">
          {canChangeRole ? (
            <span>
              {
                <select
                  className={classes.roleSelect}
                  name="IssuePartRoleID"
                  value={this.state.IssuePartRoleID}
                  onChange={this.handleInputChange}
                >
                  {partRoles.map((role) => (
                    <option key={role.Value} value={role.Value}>
                      {role.Descr}
                    </option>
                  ))}
                </select>
              }
            </span>
          ) : (
            <span className={classes.roleName}>{Role}</span>
          )}
        </TableCell>
        <TableCell component="div">{teamNames}</TableCell>
      </TableRow>
    )
  }
}

export const ParticipantTableHeaderRow = () => (
  <TableRow component="div">
    <TableCell component="div">Participant</TableCell>
    <TableCell component="div">Privilege</TableCell>
    <TableCell component="div">Team</TableCell>
  </TableRow>
)

ParticipantTableRow.propTypes = {
  email_address: PropTypes.string,
  AppAcctID: PropTypes.number,
  ParticipantID: PropTypes.number,
  Role: PropTypes.string,
  IssuePartRoleID: PropTypes.number,
  FullName: PropTypes.string,
  isOnline: PropTypes.bool,
  partRoles: PropTypes.array,
  canChangeRole: PropTypes.bool,
  IssueTeamWorkspaceID: PropTypes.number,
  teamNames: PropTypes.string,
}

ParticipantTableRow.defaultProps = {
  email_address: '',
  Role: '',
  FullName: '',
  isOnline: false,
  partRoles: [],
  canChangeRole: true,
  disabled: false,
  teamNames: '',
}

function mapStatetoProps(state, ownProps) {
  const { email_address, IssuePartRoleID, AppAcctID, ParticipantID, FullName } =
    selectParticipantById(state, ownProps.ParticipantID) || {}
  const teamNames = selectParticipantJoinedTeamNames(state, ParticipantID)
  return {
    email_address,
    AppAcctID,
    ParticipantID,
    Role: selectPartRoleNameById(state, IssuePartRoleID),
    IssuePartRoleID,
    FullName,
    isOnline: isParticipantOnline(state, email_address),
    partRoles: selectPartRoles(state),
    teamNames,
    canChangeRole: canUserAdministerWorkspace(
      state,
      ownProps.IssueTeamWorkspaceID
    ),
  }
}

const mapDispatchToProps = {
  onSubmit: function (
    name,
    value,
    { IssueTeamWorkspaceID, email_address, ParticipantID }
  ) {
    if (name === 'IssuePartRoleID')
      return ParticipantActions.update(
        {
          IssueTeamWorkspaceID,
          email_address,
          ParticipantID,
          [name]: value,
        },
        { action: 'set part type' }
      )
  },
}
export default connect(mapStatetoProps, mapDispatchToProps)(ParticipantTableRow)
