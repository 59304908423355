import React from 'react'
import { Button, Dialog, Input } from '@material-ui/core'
import { Spacer } from '../Shared/Spacer'
import { createOrgMeetingLink } from '../_utils/apiUtils'

export const MeetingLinkDialog = ({
  onCancel,
  open,
  orgId,
  orgName,
}: {
  onCancel: () => void
  open: boolean
  orgId: number
  orgName: string
}) => {
  const [linkDescription, setLinkDescription] = React.useState('')
  const [link, setLink] = React.useState('')

  const [errors, setErrors] = React.useState<{ [key: string]: any }>({})

  const checkForErrors = () => {
    if (!linkDescription) {
      setErrors((prev) => ({
        ...prev,
        Description: 'Link Description cannot be blank',
      }))
      return true
    }
    if (!link) {
      setErrors((prev) => ({
        ...prev,
        Link: 'Link cannot be blank',
      }))
      return true
    }
    return
  }

  return (
    <Dialog
      open={open}
      onClose={(_, reason) => reason === 'backdropClick' && onCancel()}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          padding: '20px',
          width: '450px',
          height: '200px',
        }}
      >
        Link Description
        <Input
          value={linkDescription}
          onChange={(e) => {
            if (errors['Description']) {
              delete errors['Description']
            }
            setLinkDescription(e.target.value)
          }}
        />
        <Spacer />
        Link
        <Input
          value={link}
          onChange={(e) => {
            if (errors['Link']) {
              delete errors['Link']
            }
            setLink(e.target.value)
          }}
        />
        <Spacer />
        <div>Organization: {orgName}</div>
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent:
            Object.keys(errors).length > 0 ? 'space-between' : 'flex-end',
          alignItems: 'center',
          padding: '0px 20px  20px 20px',
        }}
      >
        {Object.keys(errors).length > 0 && (
          <div style={{ color: 'red' }}>
            {errors[`${Object.keys(errors)[0]}`]}
          </div>
        )}
        <div>
          <Button
            onClick={() => {
              onCancel()
              setLink('')
              setLinkDescription('')
              setErrors({})
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              if (checkForErrors()) {
                return
              }
              createOrgMeetingLink(orgId, link, linkDescription)
              setLink('')
              setLinkDescription('')
              setErrors({})
              onCancel()
            }}
          >
            Save
          </Button>
        </div>
      </div>
    </Dialog>
  )
}
