import React from 'react'
import fileSaver from 'file-saver'
import XLSX from 'sheetjs-style'
import { faFileExcel } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { cx } from '../_utils/objectUtils'
import forms from './AdminExportExcel.module.scss'

interface Props {
  excelLegend: any
  excelData: any
  fileName: string
}

export const AdminExportExcel = ({
  excelLegend,
  excelData,
  fileName,
}: Props) => {
  const fileType =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
  const fileExtension = '.xlsx'

  const exportToExcel = async () => {
    const ws = XLSX.utils.json_to_sheet(excelData)
    const legend = XLSX.utils.json_to_sheet(excelLegend)
    const wb = {
      Sheets: { Issues: ws, Legend: legend },
      SheetNames: ['Issues', 'Legend'],
    }

    const excelBuffer = XLSX.write(wb, { type: 'array' })
    const data = new Blob([excelBuffer], { type: fileType })
    fileSaver.saveAs(data, fileName + fileExtension)
  }
  return (
    <>
      <div
        className={cx(forms.buttonStyledAsLink)}
        onClick={(e) => {
          exportToExcel()
        }}
      >
        <FontAwesomeIcon icon={faFileExcel} /> Export to Excel
      </div>
    </>
  )
}
