// @ts-nocheck
// TODO: Typescript

import '../IcoTipTap.scss'

import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { Button } from 'reactstrap'
import classes from './ChatMessageEditor.module.scss'
import { cx } from '../_utils/objectUtils'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPaperclip } from '@fortawesome/free-solid-svg-icons'
import { FilePicker } from '../Upload/FilePicker'
import { TiptapEditor } from '../Shared/IcoTipTapEditor'
import { removeHtml } from '../_utils/domUtils'

/**
 * @extends {Component<Props, {}>}}
 * Editor component with custom toolbar and content containers
 */
export class ChatMessageEditor extends Component {
  constructor(props) {
    super(props)
    this.state = { editorHtml: '', modules: undefined }
    this.handleChange = this.handleChange.bind(this)
    this.handleSend = this.handleSend.bind(this)
    this.handleKeyDown = this.handleKeyDown.bind(this)
    this.handleHeightChange = this.handleHeightChange.bind(this)
    this.handleFilePicker = this.handleFilePicker.bind(this)
    this.tiptapRef = null
    this.reactTiptapRef = null
    this.previousRootHeight = 0
  }

  componentDidMount() {
    setTimeout(() => this.handleHeightChange())
  }

  handleChange(html) {
    this.setState({ editorHtml: html })
  }

  handleKeyDown() {
    this.handleHeightChange()
  }

  handleFilePicker(file) {
    this.props.onFileUploadChange(file)
  }

  handleSend() {
    const { onSend, onValidate } = this.props
    var value = (this.state.editorHtml || '').trim()

    var error = ''

    if (onValidate) error = onValidate(value)
    else if (removeHtml(value) === '') error = 'Message cannot be blank.'

    if (!error) {
      this.setState({
        editorHtml: '',
      })
      onSend({ value })
    } else {
      this.setState({
        editorHtml: '',
      })
      onSend({ value, error })
    }

    setTimeout(this.handleHeightChange)
  }

  handleHeightChange() {
    if (this._rootEl) {
      const rootHeight = Math.round(this._rootEl.offsetHeight)
      if (this.previousRootHeight !== rootHeight) {
        this.previousRootHeight = rootHeight
      }

      if (this.props.onHeightChanged) this.props.onHeightChanged(rootHeight)
    }
  }

  render() {
    const { sendBtnLabel, border, disabled } = this.props
    const ownClasses = this.props.classes || {}
    const rootClasses = cx(
      classes.root,
      ownClasses.root,
      border ? classes.border : ''
    )
    return (
      <div ref={(el) => (this._rootEl = el)} className={rootClasses}>
        <TiptapEditor
          className="ql-editor-max100"
          onChange={this.handleChange}
          readOnly={disabled}
          placeholder={this.props.placeholder}
          modules={this.state.modules}
          value={this.state.editorHtml}
          onKeyDown={this.handleKeyDown}
        />

        <FilePicker onChange={this.handleFilePicker} disabled={disabled}>
          <FontAwesomeIcon icon={faPaperclip} size="lg" />
        </FilePicker>
        <Button
          className={cx(classes.sendBtn, ownClasses.sendBtn)}
          color="primary"
          onClick={this.handleSend}
          disabled={disabled}
        >
          {sendBtnLabel || 'Send'}
        </Button>
      </div>
    )
  }
}

/*
 * PropType validation
 */
ChatMessageEditor.propTypes = {
  placeholder: PropTypes.string,
  onSend: PropTypes.func,
  classes: PropTypes.shape({
    root: PropTypes.string,
    sendBtn: PropTypes.string,
    sendActions: PropTypes.string,
    toolbar: PropTypes.string,
  }),
  sendBtnLabel: PropTypes.string,
  /**
   * Notify user when the height of the editor has changed.
   * Will be called on first render.
   */
  onHeightChanged: PropTypes.func,
  /**
   * Places a border around the editor
   */
  border: PropTypes.func,
  onFileUploadChange: PropTypes.func,
  disabled: PropTypes.bool,
}

ChatMessageEditor.defaultProps = {
  classes: {},
  onSend: () => {},
  onFileUploadChange: () => {},
}
