// @ts-nocheck
// TODO: Typescript

import PropTypes from 'prop-types'
import React, { useState, useEffect } from 'react'
import classes from './IcoTipTapEditor.module.scss'
import { cx } from '../_utils/objectUtils'
import { EditorContent, useEditor } from '@tiptap/react'
import { StarterKit } from '@tiptap/starter-kit'
import { Paragraph } from '@tiptap/extension-paragraph'
import { Placeholder } from '@tiptap/extension-placeholder'
import Bold from '@tiptap/extension-bold'
import Italic from '@tiptap/extension-italic'
import Underline from '@tiptap/extension-underline'
import Strike from '@tiptap/extension-strike'
import Link from '@tiptap/extension-link'
import BulletList from '@tiptap/extension-bullet-list'
import OrderedList from '@tiptap/extension-ordered-list'
import Heading from '@tiptap/extension-heading'

//Configure Tiptap

const CustomParagraph = Paragraph.extend({
  renderHTML({ HTMLAttributes }) {
    return [
      'p',
      { ...HTMLAttributes, class: 'ico-issuemanagement-tiptap-paragraph' },
      0,
    ]
  },
})

export const TiptapEditor = ({
  className,
  value,
  onChange,
  placeholder,
  useToolbar = true,
  readOnly = false,
}: {
  className?: string
  value: any
  onChange: any
  placeholder: string
  useToolbar?: boolean
  readOnly?: boolean
}) => {
  const editor = useEditor({
    extensions: [
      StarterKit.configure({
        keepMarks: true,
        keepAttributes: true,
      }),

      Placeholder.configure({
        placeholder: placeholder || '',
      }),
      Heading.configure({
        levels: [1, 2, 3], // For heading levels H1, H2, H3
      }),
      CustomParagraph,
      Bold,
      Link,
      Italic,
      Underline,
      Strike,
      BulletList,
      OrderedList,
    ],
    content: value,
    onUpdate: ({ editor }) => {
      const html = editor.getHTML()
      onChange(html)
    },
  })

  useEffect(() => {
    if (editor) {
      editor.setEditable(!readOnly)
    }
  }, [readOnly, editor])

  useEffect(() => {
    if (editor && value !== editor.getHTML()) {
      editor.commands.setContent(value)
    }
  }, [value, editor])

  const handleLinkInput = () => {
    const url = prompt('Enter the URL')

    if (url) {
      let trimmedURL = url.trim()

      if (
        !trimmedURL.startsWith('http://') &&
        !trimmedURL.startsWith('https://')
      ) {
        trimmedURL = `http://${trimmedURL}`
      }

      editor
        .chain()
        .focus()
        .extendMarkRange('link')
        .setLink({ href: trimmedURL })
        .run()
    }
  }

  if (!editor) {
    return null
  }

  return (
    <div style={{ border: '1px solid lightgrey' }}>
      {useToolbar && !readOnly && (
        <div
          className="toolbar"
          style={{ borderBottom: '1px solid lightgrey', padding: '5px' }}
        >
          <select
            onChange={(e) => {
              const level = e.target.value
              if (level === 'normal') {
                editor.chain().focus().setParagraph().run()
              } else {
                editor
                  .chain()
                  .focus()
                  .toggleHeading({ level: parseInt(level) })
                  .run()
              }
            }}
          >
            <option value="normal">Normal</option>
            <option value="1">H1</option>
            <option value="2">H2</option>
            <option value="3">H3</option>
          </select>

          <button
            onClick={() => editor.chain().focus().toggleBold().run()}
            className={editor.isActive('bold') ? 'active' : ''}
          >
            <b>B</b>
          </button>
          <button
            onClick={() => editor.chain().focus().toggleItalic().run()}
            className={editor.isActive('italic') ? 'active' : ''}
          >
            <i>I</i>
          </button>
          <button
            onClick={() => editor.chain().focus().toggleUnderline().run()}
            className={editor.isActive('underline') ? 'active' : ''}
          >
            <u>U</u>
          </button>
          <button
            onClick={() => editor.chain().focus().toggleStrike().run()}
            className={editor.isActive('strike') ? 'active' : ''}
          >
            <s>T</s>
          </button>
          <button onClick={handleLinkInput}>
            <u>A</u>
          </button>
          <button
            onClick={() => editor.chain().focus().toggleBulletList().run()}
            className={editor.isActive('bulletList') ? 'active' : ''}
          >
            •
          </button>
          <button
            onClick={() => editor.chain().focus().toggleOrderedList().run()}
            className={editor.isActive('orderedList') ? 'active' : ''}
          >
            1.
          </button>
        </div>
      )}
      <EditorContent
        editor={editor}
        className={classes.editor}
        onClick={() => editor?.chain().focus().run()}
      />
    </div>
  )
}

interface IcoTipTapContainerProps {
  className?: any
  children?: any
  hideControls?: boolean
  autoHideControls?: boolean
  mobile?: boolean
  fullscreen?: boolean
  onClick?: any
  disabled?: boolean
}
export const IcoTipTapContainer = ({
  className,
  children,
  hideControls,
  autoHideControls,
  mobile,
  fullscreen,
  onClick,
  disabled,
}: IcoTipTapContainerProps) => {
  const [hideControlsState, setHideControls] = useState(true)
  const hideControlsHover = hideControls ? classes.hideControlsHover : ''
  let mobileClassName = mobile ? classes.mobile : ''
  if (fullscreen) {
    mobileClassName = cx(mobileClassName, classes.focusedMobile)
  }

  const handleOnClick = disabled ? undefined : onClick
  const disabledClass = disabled ? classes.disabled : ''

  // The user controls if the toolbar should be hidden
  if (!autoHideControls)
    return (
      <div
        onClick={handleOnClick}
        className={cx(
          className,
          disabledClass,
          classes.container,
          mobileClassName,
          hideControls ? cx(classes.hideControls, hideControlsHover) : ''
        )}
      >
        {children}
      </div>
    )

  return (
    <div
      onClick={handleOnClick}
      onFocus={() => setHideControls(false)}
      onBlur={() => setHideControls(true)}
      className={cx(
        className,
        disabledClass,
        classes.container,
        mobileClassName,
        hideControls || hideControlsState ? classes.hideControls : '',
        hideControlsHover
      )}
    >
      {' '}
      {children}
    </div>
  )
}

IcoTipTapContainer.propTypes = {
  autoHideControls: PropTypes.bool,
  mobile: PropTypes.bool,
  className: PropTypes.string,
  disabled: PropTypes.bool,
}

IcoTipTapContainer.defaultProps = {
  autoHideControls: true,
  mobile: false,
}
