// @ts-nocheck
// TODO: Typescript
import { JsonParseSafe } from '../_utils/objectUtils'

// Uses localstorage to persist actions across multiple tabs and windows
export default function createSyncMiddleware(allowedActions) {
  var allowedActionsSet = new Set()
  const actionKey = 'currentReduxAction'
  var curStoreApi

  // The actions that should be shared with other windows and tabs
  allowedActions.forEach(function (action) {
    if (!allowedActionsSet.has(action)) allowedActionsSet.add(action)
  })

  // Listen for changes in localstorage
  window.addEventListener('storage', function (e) {
    if (curStoreApi) {
      // Dispatch the action to the other windows if a change occurs
      if (e.key === actionKey && e.newValue) {
        var actionJson = JsonParseSafe(e.newValue)
        if (actionJson.syncMiddleware !== true) {
          actionJson.syncMiddleware = true
          curStoreApi.dispatch(actionJson)
          this.localStorage.removeItem(actionKey)
        }
      }
    }
  })

  return (storeAPI) => (next) => (action) => {
    curStoreApi = storeAPI
    if (allowedActionsSet.has(action.type))
      localStorage.setItem(actionKey, JSON.stringify(action))
    return next(action)
  }
}
