import React, { useState } from 'react'
import { icoPost } from '../_utils/fetchUtils'
import { EmailPwForm, SubmitFields } from './EmailPwForm'
import { History } from 'history'
import { Redirect } from 'react-router'
import { useAppDispatch } from '../_utils/reactReduxHooks'
import { addNotification } from '../_actions'

interface Props {
  id: any
  history: History
}

export function CreateAccount(props: Props) {
  const [response, setResponse] = useState<{
    ErrorMessage?: string
    status?: string
    email?: string
  }>({ ErrorMessage: '', status: '', email: '' })
  const [email, setEmail] = useState(
    (props?.history?.location?.state as any)?.userEmailAddress || ''
  )
  const [submitted, setSubmitted] = useState(false)
  const [submitting, setSubmitting] = useState(false)

  const dispatch = useAppDispatch()

  function handleSubmit({
    password,
    confirmPassword,
    phone,
    countrycode,
    name,
  }: SubmitFields) {
    setSubmitted(false)
    setSubmitting(true)
    setResponse({})
    icoPost('/api/AppAccount', {
      email,
      confirmPassword,
      password,
      phone,
      countrycode,
      name,
    })
      .then((response) => response.json())
      .then((data) => {
        setSubmitted(true)
        setResponse(data)
      })
      .catch((error) =>
        dispatch(
          addNotification({
            type: 'error',
            message: error.message,
          })
        )
      )
      .finally(() => setSubmitting(false))
  }

  if (submitted && response.status === 'SUCCESS')
    return (
      <Redirect
        to={{
          pathname: '/CreateAccountEmailSent',
          state: { email: response.email },
        }}
      />
    )

  return (
    <EmailPwForm
      {...props}
      email={email}
      onEmailChange={setEmail}
      enableEmail={true}
      submitted={submitted}
      error={response.ErrorMessage}
      onSubmit={handleSubmit}
      submitEnabled={response.status !== 'SUCCESS'}
      submitBtnText="Create"
      loading={submitting}
      loadingText="Creating account..."
    />
  )
}
export default CreateAccount
