// @ts-nocheck
// TODO: Typescript
import { getFetchListener } from '../_utils/sagaUtils'
import {
  FETCH_CREATE_ISSUE_FORMS,
  FETCH_UPDATE_ISSUE_FORMS,
  FETCH_UPDATE_ISSUE_FORMS_COUNT,
  FETCH_FORM_CONTENT,
  SUBMIT_ISSUE_FORM,
  FETCH_CHANGE_LEVEL_FORM_CONTENT,
  FETCH_RESOLVE_ISSUE_FORM_CONTENT,
  FETCH_OPEN_ISSUE_FORM_CONTENT,
} from './_constants'
import { takeLatest, call, put, take } from 'redux-saga/effects'
import {
  fetchCreateIssueFormsSuccess,
  fetchUpdateIssueFormsSuccess,
  fetchFormContentSuccess,
  submitIssueFormSuccess,
  submitIssueFormFailure,
  submitIssueFormLoading,
  fetchFormContentLoading,
  fetchCreateIssueFormsLoading,
  fetchUpdateIssueFormsLoading,
  fetchChangeLevelFormContentSuccess,
  fetchChangeLevelFormContentLoading,
  fetchChangeLevelFormContentFailure,
  fetchUpdateIssueFormsCountSuccess,
  fetchResolveIssueFormContentSuccess,
  fetchResolveIssueFormContentLoading,
  fetchResolveIssueFormContentFailure,
  fetchOpenIssueFormContentLoading,
  fetchOpenIssueFormContentFailure,
  fetchOpenIssueFormContentSuccess,
} from './_actions'
import createSubmitFormChannel from './_createSubmitFormChannel'

// The Saga that does the actual work
const fetchCreateIssueForms = getFetchListener('/api/FormReport', {
  onSuccess: (result, params, source) =>
    fetchCreateIssueFormsSuccess(result, source),
  onLoading: (result, params, source) =>
    fetchCreateIssueFormsLoading(result, source),
})

export const submitIssueForm = function* (action) {
  const { formData, source } = action.payload
  const channel = yield call(
    createSubmitFormChannel,
    '/api/FormReport',
    formData
  )

  let finalSource = source || {}
  finalSource = {
    icoRequestId: finalSource.submitType || finalSource.icoRequestId,
    ...finalSource,
  }
  while (true) {
    const { progress = 0, err, success, response } = yield take(channel)
    if (err) {
      yield put(
        submitIssueFormFailure(err, undefined, {
          ...finalSource,
        })
      )
      return
    }
    if (success && response) {
      const { error, returnData } = response
      if (error) {
        yield put(submitIssueFormFailure(error, returnData || {}, finalSource))
      } else yield put(submitIssueFormSuccess(returnData || {}, finalSource))
      return
    }
    yield put(submitIssueFormLoading({ progress, ...finalSource }))
  }
}

const fetchUpdateIssueForms = getFetchListener(
  '/api/FormReport/IssueTeamWorkspace',
  {
    onSuccess: (result, params, source) =>
      fetchUpdateIssueFormsSuccess(result, source),
    onLoading: (result, params, source) =>
      fetchUpdateIssueFormsLoading(result, source),
    appendPath: function (action) {
      const { IssueTeamWorkspaceID } = action.payload.result
      return IssueTeamWorkspaceID
    },
  }
)

const fetchUpdateIssueFormsCount = getFetchListener(
  '/api/FormReport/Count/IssueTeamWorkspace',
  {
    onSuccess: (result, params, source) =>
      fetchUpdateIssueFormsCountSuccess(result, source),
    appendPath: function (action) {
      const { IssueTeamWorkspaceID } = action.payload.result
      return IssueTeamWorkspaceID
    },
  }
)

const fetchChangeLevelForm = getFetchListener(
  '/api/FormReport/ChangeLevel/IssueTeamWorkspace',
  {
    onSuccess: fetchChangeLevelFormContentSuccess,
    onLoading: fetchChangeLevelFormContentLoading,
    onFailure: (error, result, params, source) =>
      fetchChangeLevelFormContentFailure(error, result, source),
    appendPath: function (action) {
      const { IssueTeamWorkspaceID } = action.payload.result
      return IssueTeamWorkspaceID
    },
  }
)

const fetchResolveIssueForm = getFetchListener(
  '/api/FormReport/ResolveIssue/IssueTeamWorkspace',
  {
    onSuccess: fetchResolveIssueFormContentSuccess,
    onLoading: fetchResolveIssueFormContentLoading,
    onFailure: (error, result, params, source) =>
      fetchResolveIssueFormContentFailure(error, result, source),
    appendPath: function (action) {
      const { IssueTeamWorkspaceID } = action.payload.result
      return IssueTeamWorkspaceID
    },
  }
)

const fetchOpenIssueForm = getFetchListener(
  '/api/FormReport/OpenIssue/IssueTeamWorkspace',
  {
    onSuccess: fetchOpenIssueFormContentSuccess,
    onLoading: fetchOpenIssueFormContentLoading,
    onFailure: (error, result, params, source) =>
      fetchOpenIssueFormContentFailure(error, result, source),
    appendPath: function (action) {
      const { IssueTeamWorkspaceID } = action.payload.result
      return IssueTeamWorkspaceID
    },
  }
)

const fetchFormContent = getFetchListener('/api/FormReport/Spec', {
  onSuccess: fetchFormContentSuccess,
  onLoading: fetchFormContentLoading,
  appendPath: function (action) {
    const { TeamReportSpecID, TeamReportContextGUID } = action.payload.result
    return `${TeamReportSpecID}?trcguid=${TeamReportContextGUID}`
  },
})

// Watcher sagas
export const formSagas = [
  takeLatest(FETCH_CREATE_ISSUE_FORMS.REQUEST, fetchCreateIssueForms),
  takeLatest(FETCH_UPDATE_ISSUE_FORMS.REQUEST, fetchUpdateIssueForms),
  takeLatest(
    FETCH_UPDATE_ISSUE_FORMS_COUNT.REQUEST,
    fetchUpdateIssueFormsCount
  ),
  takeLatest(FETCH_CHANGE_LEVEL_FORM_CONTENT.REQUEST, fetchChangeLevelForm),
  takeLatest(FETCH_RESOLVE_ISSUE_FORM_CONTENT.REQUEST, fetchResolveIssueForm),
  takeLatest(FETCH_OPEN_ISSUE_FORM_CONTENT.REQUEST, fetchOpenIssueForm),
  takeLatest(FETCH_FORM_CONTENT.REQUEST, fetchFormContent),
  takeLatest(SUBMIT_ISSUE_FORM.REQUEST, submitIssueForm),
]
