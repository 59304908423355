import { DialogContent, Select } from '@material-ui/core'
import React, { useState } from 'react'
import { Button } from '../Shared/Buttons'
import { ResponsiveDialog } from '../Shared/ResponsiveDialog'
import { Spacer } from '../Shared/Spacer'
import { SlideLeft } from '../Shared/Transition'
import { selectWorkspaceParticipantIds } from '../Workspace/_selectors'
import { addNotification } from '../_actions'
import adminConsoleStyle from '../Shared/AdminConsoleDialog.module.scss'
import {
  selectParticipantsIdMap,
  selectTaskAllItemIds,
} from '../_rootConfigs/rootSelectors'
import { useAppDispatch, useAppSelector } from '../_utils/reactReduxHooks'
import classes from './TaskTemplateUploadDialog.module.scss'
import { selectTaskItemById } from './_selectors'
import { TaskDetailActions } from './_actions'

interface Assignment {
  email: string
  id: number
}

const TaskListReassignDialog = ({
  open,
  issueTeamWorkspaceId,
  taskId,
  onClose,
}: {
  open: boolean
  taskId: number
  issueTeamWorkspaceId: number
  onClose: () => void
}) => {
  const closeDialog = () => {
    onClose()
    setSelectedChangeAssignment(undefined)
    setSelectedOriginalAssignment(undefined)
  }

  const [selectedOriginalAssignment, setSelectedOriginalAssignment] =
    useState<string>()

  const [selectedChangeAssignment, setSelectedChangeAssignment] =
    useState<number>()

  const participantsMap = useAppSelector((state) =>
    selectParticipantsIdMap(state)
  )

  const participantIds = useAppSelector((state) =>
    selectWorkspaceParticipantIds(state, issueTeamWorkspaceId)
  )

  const taskListItemIds =
    useAppSelector((state) => selectTaskAllItemIds(state, taskId as any)) || []

  const taskListItems = useAppSelector((state) =>
    taskListItemIds?.map((id: number) => selectTaskItemById(state, id))
  )

  const assignedTaskParticipants = taskListItems?.map(
    (listItem) => listItem?.AssignedTo?.trim().split(/\s+/)[0]
  )

  const participantEmails = participantIds?.map(
    (id: number) => participantsMap[id]?.email_address
  )

  const participantEmailAndId: Assignment[] = participantIds?.map(
    (id: number) => {
      return {
        email: participantsMap[id]?.email_address,
        id: participantsMap[id]?.AppAcctID,
      }
    }
  )

  const lowerParticipantEmails = participantEmails?.map(
    (participantEmail: string) => participantEmail?.toLowerCase()
  )

  const lowerAssignedTaskParticipants = assignedTaskParticipants?.map(
    (taskParticipant: string) => taskParticipant?.toLowerCase()
  )

  const availableFilterParticipants = lowerParticipantEmails?.filter(
    (participantEmail: string) =>
      lowerAssignedTaskParticipants.includes(participantEmail)
  )

  const dispatch = useAppDispatch()

  const submitChange = () => {
    const originalEmail = participantEmailAndId.filter(
      (emailAndId) =>
        emailAndId.id && emailAndId.email === selectedOriginalAssignment
    )

    taskListItems.forEach((taskItem) => {
      if (
        taskItem.AssignedTo !== undefined &&
        taskItem.AssignedTo?.split(' ')[0] === originalEmail[0].email &&
        !taskItem.CompletedBy
      ) {
        dispatch(
          TaskDetailActions.update(
            {
              TaskID: taskId,
              TaskItemID: taskItem.TaskItemID,
              //@ts-ignore
              AssignedToAppAcctID: parseInt(selectedChangeAssignment),
            },
            { action: 'ASSIGN' }
          )
        )

        dispatch(
          TaskDetailActions.updateSuccess({
            TaskID: taskId,
            TaskItemID: taskItem.TaskItemID,
            //@ts-ignore
            AssignedToAppAcctID: parseInt(selectedChangeAssignment),
          })
        )
      }
    })
    closeDialog()
  }

  return (
    <ResponsiveDialog
      onClose={(event, reason) => {
        if ((reason = 'backdropClick')) {
          closeDialog()
        }
      }}
      open={open}
      TransitionComponent={SlideLeft as any}
    >
      <div style={{ padding: '25px' }}>
        <div
          style={{
            marginBottom: '20px',
            paddingBottom: '10px',
            borderBottom: '1px solid black',
            display: 'flex',
          }}
        >
          Reassign All Assignments{' '}
        </div>
        <div>Reassign:</div>
        <div
          style={{
            alignItems: 'center',
            justifyContent: 'space-around',
          }}
        >
          <Select
            id={classes.assignSelect}
            native
            value={selectedOriginalAssignment}
            name="AssignedToAppAcctID"
            onChange={(e) =>
              setSelectedOriginalAssignment(e.target.value as string)
            }
            className="w-100"
          >
            <option key={-1} value={undefined}>
              --Not Assigned--
            </option>

            {availableFilterParticipants?.map(
              (email: string, index: number) => {
                return <option key={index}>{email}</option>
              }
            )}
          </Select>
          <Spacer />
          To:
          <Spacer />
          <Select
            id={classes.assignSelect}
            native
            value={selectedChangeAssignment}
            onChange={(e) =>
              setSelectedChangeAssignment(e.target.value as number)
            }
            name="AssignedToAppAcctID"
            className="w-100"
          >
            <option key={-1} value={undefined}>
              --Not Assigned--
            </option>
            {participantIds?.map((id: number) => {
              const participant = participantsMap[id]
              return participant?.AppAcctID ? (
                <option
                  key={participant?.ParticipantID}
                  value={parseInt(participant?.AppAcctID)}
                >
                  {participant?.email_address}
                </option>
              ) : null
            })}
          </Select>
        </div>
      </div>
      <div className={adminConsoleStyle.AdminConsoleDialogButtonContainer}>
        <Button
          style={{
            textTransform: `none`,
            color: `rgb(64, 148, 195)`,
            backgroundColor: 'white',
            borderRadius: 'none',
          }}
          onClick={() => closeDialog()}
        >
          Cancel
        </Button>
        <Spacer />
        <Button
          style={{
            backgroundColor: `rgb(64, 148, 195)`,
            color: 'white',
            textTransform: `none`,
            borderRadius: 'none',
          }}
          onClick={() => {
            if (selectedOriginalAssignment && selectedChangeAssignment) {
              submitChange()
            } else {
              dispatch(
                addNotification({
                  message: 'Cannot assign to empty values',
                  type: 'error',
                })
              )
            }
          }}
        >
          Save
        </Button>
      </div>

      <DialogContent className={classes.dialogContent}></DialogContent>
    </ResponsiveDialog>
  )
}

export default TaskListReassignDialog
