import {
  bindSel,
  bindSelRet,
  getTask,
  getTaskComment,
  getTaskItem,
  getTaskRead,
} from '../_rootConfigs/rootReducerSlicers'
import * as priv from './_reducer'
import * as privLog from '../ActivityLog/_reducer'
import { selectEntityByNumId, selectEntityIdMap } from '../_schema/_reducer'
import { TaskItemModel, TaskItemState } from './_models'

// TASK SELECTORS
export const selectTaskById = bindSel(getTask, priv.selectTaskById)
export const selectTaskIdByIssueCollab = bindSel(
  getTask,
  priv.selectTaskIdByIssueCollab
)

// TASK ITEM SELECTORS
export const selectTaskItemById = bindSelRet(
  getTaskItem,
  selectEntityByNumId
)<TaskItemModel>()
export const selectTaskItemIdMap = bindSelRet(
  getTaskItem,
  selectEntityIdMap
)<TaskItemState>()
export const selectTaskItemCommentIds = bindSel(
  getTaskItem,
  priv.selectTaskItemCommentIds
)
export const selectTaskItemArtifactId = bindSel(
  getTaskItem,
  priv.selectTaskItemArtifactId
)

// TASK ITEM COMMENT SELECTORS
export const selectTaskItemCommentById = bindSel(
  getTaskComment,
  selectEntityByNumId
)
export const selectTaskItemCommentIdMap = bindSel(
  getTaskComment,
  selectEntityIdMap
)

// TASK UNREAD SELECTORS
export const isTaskRefreshReadRequired = bindSel(
  getTaskRead,
  privLog.isLogRefreshReadRequired
)
export const selectIsTaskUnread = bindSel(getTaskRead, privLog.isLogUnread)
