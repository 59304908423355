// this is a component which will check regularly to see if there is one or more active polls
// for the user, and present a Dialog to the user to let them answer the poll.
// It was moved here from the Playbook section so it works even if the user does not visit
// the Playbooks page
import * as React from 'react'
import { ConfirmDialog } from '../Shared/ConfirmDialog'
import { useInterval } from '../_utils/hooks'
import { useAppDispatch, useAppSelector } from '../_utils/reactReduxHooks'
import { fetchActivePollList } from './_actions'
import { selectActivePollList } from './_selectors'

interface activePoll {
  PUR_GUID: string
  PollName: string
  ExpirationUTC: Date
  PollURL: string
}

interface activePollList {
  Polls: [activePoll]
}

export const ActivePollDialog = () => {
  const [activePolls, setActivePolls] = React.useState<
    activePollList | undefined
  >(undefined)
  const [pollAlertDialogOpened, setPollAlertDialogOpened] =
    React.useState(false)
  const [pollPUR_GUID, setPollPUR_GUID] = React.useState<string>('')
  const [pollDialogDismissedAt, setPollDialogDismissedAt] =
    React.useState<any>('')

  // const getActivePolls = async (): Promise<any> => {
  //   const result = await http<any>({
  //     path: '/playbook/ActivePolls',
  //   })
  //   if (result.ok && result.body) {
  //     return result.body
  //   } else {
  //     return ''
  //   }
  // }

  const activePollListFromStore = useAppSelector(selectActivePollList)
  const dispatch = useAppDispatch()

  async function refreshActivePollList_in_store() {
    dispatch(fetchActivePollList.request())
    // processActivePollListFromStore()
  }

  /*
  React.useEffect(() => {
    if (!activePollListFromStore) {
      refreshActivePollList_in_store()
    }
  }, [])
*/
  React.useEffect(() => {
    setTimeout(refreshActivePollList_in_store, 5 * 1000) // wait 5 seconds
  }, []) // when this component is mounted process active polls

  useInterval(refreshActivePollList_in_store, 30 * 1000)

  React.useEffect(() => {
    processActivePollListFromStore()
  }, [activePollListFromStore]) // when this component is mounted process active polls

  function processActivePollListFromStore() {
    if (activePollListFromStore) {
      if (
        !(
          JSON.stringify(activePollListFromStore) ===
          JSON.stringify(activePolls)
        )
      ) {
        // if the new value is different from the saved one, save it
        setActivePolls(activePollListFromStore)
      }
      if (activePollListFromStore.Polls?.length > 0) {
        var shouldOpen: boolean = true
        if (pollAlertDialogOpened === false) {
          if (pollPUR_GUID) {
            if (activePollListFromStore.Polls[0].PUR_GUID === pollPUR_GUID) {
              if (pollDialogDismissedAt) {
                var rightNow: Date = new Date()
                var secondsSince: number =
                  (rightNow.getTime() - pollDialogDismissedAt.getTime()) / 1000
                if (secondsSince < 120) {
                  shouldOpen = false
                }
              }
            }
          }
        }
        setPollAlertDialogOpened(shouldOpen)
        setPollPUR_GUID(activePollListFromStore.Polls[0].PUR_GUID) // keep track of the Poll GUID
      } else {
        setPollAlertDialogOpened(false)
      }
    } else {
      setPollAlertDialogOpened(false)
    }
  }
  /*
  React.useEffect(() => {
    setTimeout(processActivePolls, 5 * 1000) // wait 5 seconds
  }, []) // when this component is mounted process active polls


  useInterval(processActivePolls, 60 * 1000) // get updates every minute

  async function processActivePolls() {
    const new_activePolls = await getActivePolls()
    if (!(JSON.stringify(new_activePolls) === JSON.stringify(activePolls))) {
      // if the new value is different from the saved one, save it
      setActivePolls(new_activePolls)
    }
    if (new_activePolls?.Polls?.length > 0) {
      var shouldOpen: boolean = true
      if (pollAlertDialogOpened === false) {
        if (pollPUR_GUID) {
          if (new_activePolls.Polls[0].PUR_GUID === pollPUR_GUID) {
            if (pollDialogDismissedAt) {
              var rightNow: Date = new Date()
              var secondsSince: number =
                (rightNow.getTime() - pollDialogDismissedAt.getTime()) / 1000
              if (secondsSince < 120) {
                shouldOpen = false
              }
            }
          }
        }
      }
      setPollAlertDialogOpened(shouldOpen)
      setPollPUR_GUID(new_activePolls.Polls[0].PUR_GUID) // keep track of the Poll GUID
    } else {
      setPollAlertDialogOpened(false)
    }
  }
  */

  function activePollNameIfDefined() {
    if (activePolls?.Polls?.length) {
      if (activePolls.Polls[0].PollName) {
        return 'Please answer the active poll: ' + activePolls.Polls[0].PollName
      }
    }
    return 'Please answer the active poll'
  }

  function handlePollDialogAnswer() {
    openInNewTab(activePolls!.Polls[0].PollURL)
    setPollAlertDialogOpened(false)
  }
  function handlePollDialogCancel() {
    setPollAlertDialogOpened(false)
    setPollDialogDismissedAt(new Date())
  }

  const openInNewTab = (url: string) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
  }

  return (
    <ConfirmDialog
      open={pollAlertDialogOpened}
      title="Answer Active Poll"
      confirmText="Yes"
      cancelText="Cancel"
      content={activePollNameIfDefined()}
      confirm={handlePollDialogAnswer}
      cancel={handlePollDialogCancel}
    />
  )
}
