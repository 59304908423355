// @ts-nocheck
// TODO: Typescript
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import {
  withMobileDialog,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@material-ui/core'
import { Button } from 'reactstrap'
import { generateInputChangeSubmit } from '../_utils/reduxUtils'
import { connect } from 'react-redux'
import {
  toggleTaskItemCompletedUtc,
  updateTaskItemCompletedUtc,
  TaskDetailActions,
} from './_actions'
import { SlideLeft } from '../Shared/Transition'
import { selectTaskItemById } from './_selectors'

const ResponsiveDialog = withMobileDialog()(Dialog)
const deleteDisabledStyle = {
  cursor: 'default',
  color: 'lightgray',
}
const deleteStyle = {
  cursor: 'pointer',
}
/**
 * @extends {Component<Props, {}>}}
 * Actions related to a task item
 */
export class TaskItemToolbar extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showDelete: false,
    }

    this.handleDeleteClose = this.handleDeleteClose.bind(this)
    this.handleDeleteClick = this.handleDeleteClick.bind(this)
    this.handleDelete = this.handleDelete.bind(this)

    this.handleCompletedChange = generateInputChangeSubmit(this, 1000, {
      onChange: 'updateTaskItem',
      onSubmit: 'submitTaskItemUpdate',
    })
  }

  handleDeleteClose() {
    this.setState({
      showDelete: false,
    })
  }

  handleDeleteClick() {
    if (this.props.readOnly) return

    this.setState({
      showDelete: true,
    })
  }

  handleDelete() {
    this.handleDeleteClose()
    const { onDelete, TaskID, TaskItemID } = this.props
    if (onDelete) onDelete({ TaskID, TaskItemID }, { TaskID })
  }

  render() {
    const { style, TaskItemID, doesExist, CompletedUTC, readOnly } = this.props

    if (TaskItemID === undefined || !doesExist) return null

    const rootStyle = {
      display: 'inline-block',
      ...style,
    }

    return (
      <div style={rootStyle}>
        {!CompletedUTC ? (
          <Button
            onClick={this.handleCompletedChange}
            style={{ marginRight: 15 }}
            outline
            color="secondary"
            size="sm"
            disabled={readOnly}
          >
            {' '}
            Mark Complete{' '}
          </Button>
        ) : (
          <Button
            onClick={this.handleCompletedChange}
            style={{ marginRight: 15 }}
            color="secondary"
            size="sm"
            disabled={readOnly}
          >
            Completed
          </Button>
        )}
        <FontAwesomeIcon
          onClick={this.handleDeleteClick}
          icon={faTrashAlt}
          style={readOnly ? deleteDisabledStyle : deleteStyle}
        />
        <ResponsiveDialog
          open={this.state.showDelete}
          onClose={this.handleDeleteClose}
          TransitionComponent={SlideLeft}
        >
          <DialogTitle>Are you sure you want to delete?</DialogTitle>
          <DialogContent>
            <DialogContentText>
              You are about to delete this task item. A deletion cannot be
              undone. Do you wish to continue?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button autoFocus onClick={this.handleDeleteClose} color="primary">
              No
            </Button>
            <Button onClick={this.handleDelete} color="primary">
              Yes
            </Button>
          </DialogActions>
        </ResponsiveDialog>
      </div>
    )
  }
}

TaskItemToolbar.propTypes = {
  CompletedUTC: PropTypes.instanceOf(Date),
  readOnly: PropTypes.bool,
}

TaskItemToolbar.defaultProps = {
  style: {},
}

const mapStateToProps = (state, ownProps) => {
  let taskItem = selectTaskItemById(state, ownProps.TaskItemID)
  const doesExist = taskItem ? true : false
  taskItem = taskItem || {}
  const { CompletedUTC } = taskItem
  return {
    CompletedUTC,
    doesExist,
  }
}

const mapDispatchToProps = {
  updateTaskItem: (_, __, ownProps) => {
    return toggleTaskItemCompletedUtc(ownProps)
  },
  submitTaskItemUpdate: (_, __, ownProps) => {
    // Submit the completed state that already exist
    return updateTaskItemCompletedUtc(ownProps)
  },
  onDelete: TaskDetailActions.delete,
}

export default connect(mapStateToProps, mapDispatchToProps)(TaskItemToolbar)
