import * as React from 'react'
import classes from './Playbooks.module.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faPaperclip,
  faFileWord,
  faFileExcel,
  faFilePdf,
} from '@fortawesome/free-solid-svg-icons'

interface Props {
  DiagramFormat: string
}

export const DiagramButton = ({ DiagramFormat }: Props) => {
  var icon = faPaperclip
  if (DiagramFormat === 'xls') icon = faFileExcel
  if (DiagramFormat === 'xlsx') icon = faFileExcel
  if (DiagramFormat === 'doc') icon = faFileWord
  if (DiagramFormat === 'docx') icon = faFileWord
  if (DiagramFormat === 'pdf') icon = faFilePdf

  return (
    <>
      <FontAwesomeIcon className={classes.faDia} icon={icon} />
    </>
  )
}
