// the following functions build the names for Dynamic Forms for use in the inputs and the displays

import dayjs from 'dayjs'
import { getUTCDate } from '../_utils/dateUtils'
import { ReactElement } from 'react'
import colorDefinitions from '../ThemeProvider/ColorDefinitions.module.scss'

export function dfRadioGroupName(
  sectionIDX: number,
  secFldIDX: number
): string {
  return (
    'radiogroup_section' +
    sectionIDX.toString() +
    '_sectionfield' +
    secFldIDX.toString()
  )
}

export function dfRadioButtonName(
  sectionIDX: number,
  secFldIDX: number,
  selOneIDX: number
): string {
  return (
    'radiobutton_section' +
    sectionIDX.toString() +
    '_sectionfield' +
    secFldIDX.toString() +
    '_option' +
    selOneIDX.toString()
  )
}

export function dfRadioButtonValue(
  sectionIDX: number,
  secFldIDX: number,
  selOneIDX: number
): string {
  return (
    'radiogroup_section' +
    sectionIDX.toString() +
    '_sectionfield' +
    secFldIDX.toString() +
    '_option' +
    selOneIDX.toString()
  )
}

export function dfCheckBoxName(
  sectionIDX: number,
  secFldIDX: number,
  selManyIDX: number
): string {
  return (
    'checkbox_section' +
    sectionIDX.toString() +
    '_sectionfield' +
    secFldIDX.toString() +
    '_option' +
    selManyIDX.toString()
  )
}

export function dfLabelName(sectionIDX: number, secFldIDX: number): string {
  return (
    'label_section' +
    sectionIDX.toString() +
    '_sectionfield' +
    secFldIDX.toString()
  )
}

export function dfTextFieldName(sectionIDX: number, secFldIDX: number): string {
  return (
    'textfield_section' +
    sectionIDX.toString() +
    '_sectionfield' +
    secFldIDX.toString()
  )
}
export function dfDataTabledName(
  sectionIDX: number,
  secFldIDX: number
): string {
  return (
    'datatable_section' +
    sectionIDX.toString() +
    '_sectionfield' +
    secFldIDX.toString()
  )
}

export function dfSelectListName(
  sectionIDX: number,
  secFldIDX: number
): string {
  return (
    'selectlist_section' +
    sectionIDX.toString() +
    '_sectionfield' +
    secFldIDX.toString()
  )
}

export function dfSelectLisValsName(
  sectionIDX: number,
  secFldIDX: number
): string {
  return (
    'selectlistvalues_section' +
    sectionIDX.toString() +
    '_sectionfield' +
    secFldIDX.toString()
  )
}

export function dfSelectBoxName(
  sectionIDX: number,
  secFldIDX: number,
  partIDX: number
): string {
  return (
    'selectbox_section' +
    sectionIDX.toString() +
    '_sectionfield' +
    secFldIDX.toString() +
    '_participant' +
    partIDX.toString()
  )
}

export function dfArtifactFieldName(
  sectionIDX: number,
  secFldIDX: number
): string {
  return (
    'artifactfield_section' +
    sectionIDX.toString() +
    '_sectionfield' +
    secFldIDX.toString()
  )
}

export function dfArtifactFieldFormatName(
  sectionIDX: number,
  secFldIDX: number
): string {
  return (
    'artifactfieldformat_section' +
    sectionIDX.toString() +
    '_sectionfield' +
    secFldIDX.toString()
  )
}

export function dfDataTableFieldName(
  sectionIDX: number,
  secFldIDX: number
): string {
  return (
    'datatablefield_section' +
    sectionIDX.toString() +
    '_sectionfield' +
    secFldIDX.toString()
  )
}
export function dfDateString(value?: any): string {
  /*
  console.log('formatting: ', typeof value, value)
  if (typeof value === 'object')
    console.log('object constructor', value.constructor.name)
*/
  if (!value) return ''
  if (value === '') {
    return ''
  }
  if (typeof value === 'string') {
    return dayjs(getUTCDate(Date.parse(value)))
      .local()
      .format('MM/DD/YYYY')
  }
  if (value.constructor.name === 'Date') {
    return dayjs(getUTCDate(value)).local().format('MM/DD/YYYY')
  }
  return ''
}

export function dfMasterDetail(
  summary: ReactElement,
  details: ReactElement,
  canCollapse: boolean,
  startOpen: boolean
): ReactElement {
  if (canCollapse) {
    return (
      <div>
        <details open={startOpen}>
          <summary
            style={{
              color: colorDefinitions.primaryControlDarker,
            }}
          >
            {summary}
          </summary>
          {details}
        </details>
      </div>
    )
  }
  return (
    <div>
      <div
        style={{
          color: colorDefinitions.primaryControlDarker,
        }}
      >
        {summary}
      </div>
      <div>{details}</div>
    </div>
  )
}
