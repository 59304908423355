import { Location } from 'history'
import * as React from 'react'
import { LinkProps, Link } from 'react-router-dom'
import { prefixSelectorWithApp } from '../_utils/domUtils'
import { isSelected, LocationFunc, Matcher } from './_routeMatching'
import classes from './Link.module.scss'

interface Props extends LinkProps {
  to: Location | string | LocationFunc
  location?: Location
  exact?: boolean
  matchers?: Matcher[]
}

const IcoLink = ({
  children,
  to,
  location,
  exact,
  matchers = [],
  onClick,
  ...other
}: Props) => {
  if (isSelected([{ to, exact }, ...matchers], location))
    return (
      <span className={prefixSelectorWithApp('link-selected')} {...other}>
        {children}
      </span>
    )

  return (
    <Link
      to={to as any}
      {...other}
      onClick={onClick}
      className={classes.greyLink}
    >
      {children}
    </Link>
  )
}

export { IcoLink as Link }
