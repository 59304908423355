import React, { useCallback, useEffect, useRef, useState } from 'react'
import {
  FormControl,
  Select,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Checkbox,
  Input,
} from '@material-ui/core'
import classes from './IssueDetailSummary.module.scss'
import { GeolocationActions, IssueActions, IssueTagActions } from './_actions'
import { cx } from '../_utils/objectUtils'

import '../IcoTipTap.scss'
import { StretchLayout, StretchBody } from '../Layouts/StretchLayout'
import { TextareaAutoSizeFormControl } from '../Shared/TextareaAutosizeFormControl'
import { IcoTipTapContainer, TiptapEditor } from '../Shared/IcoTipTapEditor'
import OrgTagAutocomplete from '../Org/OrgTagAutocomplete'
import OrgTagContainer from '../Org/OrgTagContainer'
import {
  selectGeolocationsArray,
  selectIssueById,
  selectIssueOrgID,
  selectIssueTagIds,
  selectIssueTrendId,
} from './_selectors'
import { isMobileApp, selectTrends } from '../_selectors'
import {
  selectIssueDefaultWorkspaceId,
  selectWorkspaceActiveParticipants,
} from '../_rootConfigs/rootSelectors'
import ConnectedItem from '../Shared/ConnectedItem'
import { selectAppAccountDisplayName } from '../AppAccount/_selectors'
import { ampersandCleanup } from '../_utils/stringUtils'
import { Button } from '../Shared/Buttons'
import { useAppDispatch, useAppSelector } from '../_utils/reactReduxHooks'
import { RootState } from '../_store'
import { LocationSelector } from '../Shared/LocationSelector'
import { IssueGeolocation } from './_reducer'
import { Spacer } from '../Shared/Spacer'
import {
  GoogleMap,
  useLoadScript,
  Marker,
  Libraries,
  InfoWindow,
} from '@react-google-maps/api'
import { googleApiKey } from '../_utils/AppSettings'
import { appDateTimeFormat } from '../_constants'
import dayjs from 'dayjs'
import { getLocalDate } from '../_utils/dateUtils'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/pro-light-svg-icons'
import { AdminConsoleDialog } from '../Shared/AdminConsoleDialog'
import { UnsavedChangesPrompt } from '../Shared/UnsavedChangesPrompt'
import DOMPurify from 'dompurify'
import { orgAssets } from '../_utils/apiUtils'
import { OrgAsset } from '../Admin/AdminConsoleAssets'
import faBuilding from '../_images/blue_house_marker.png'

const mapNameToAction = {
  Description: 'set issue descr',
  IssueName: 'set issue Name',
  OurPosition: 'set issue position',
  WhatsDone: 'set issue whats done',
  WhatsNext: 'set issue whats next',
  IssueTrendID: 'set issue trend',
  RespAppAcctID: 'set responsible',
}

interface Props {
  IssueID: number
  IssueTeamWorkspaceID: number
  disabled: boolean
}

export const IssueDetailSummary = ({
  IssueID,
  disabled,
  IssueTeamWorkspaceID,
}: Props) => {
  let {
    IssueName,
    Description,
    OurPosition,
    WhatsDone,
    WhatsNext,
    Responsible,
  } = useAppSelector((state) => selectIssueById(state, IssueID) || {})
  const [addLocationOpen, setAddLocationOpen] = useState(false)
  const [newIssueName, setNewIssueName] = useState<string>(IssueName)
  const [newDescription, setNewDescription] = useState<string>(
    Description || ''
  )
  const [newOurPosition, setNewOurPosition] = useState<string>(
    OurPosition || ''
  )

  const [assets, setAssets] = useState<any>()
  const [newWhatsDone, setNewWhatsDone] = useState<string>(WhatsDone || '')
  const [newWhatsNext, setNewWhatsNext] = useState<string>(WhatsNext || '')
  const [newIssueTrendID, setNewIssueTrendID] = useState<number>()
  const [newRespAppAcctID, setNewRespAppAcctID] = useState<number>()
  const [libraries] = useState(['places'])

  const issueNameChanged = (newIssueName || '') !== (IssueName || '')
  const descriptionChanged = !(
    (newDescription || '') === (Description || '') ||
    (DOMPurify.sanitize(newDescription) || '') === (Description || '')
  )

  const orgId = useAppSelector((state) => selectIssueOrgID(state, IssueID))

  const ourPositionChanged = (newOurPosition || '') !== (OurPosition || '')
  const whatsDoneChanged = (newWhatsDone || '') !== (WhatsDone || '')
  const whatsNextChanged = (newWhatsNext || '') !== (WhatsNext || '')

  const isMobile = useAppSelector(isMobileApp)

  const [map, setMap] = useState<google.maps.Map | null>(null)
  const [selectedLocation, setSelectedLocation] =
    useState<IssueGeolocation | null>(null)
  const [locationToDelete, setLocationToDelete] =
    useState<IssueGeolocation | null>(null)

  const toolbar = useRef(null)

  const mapLoaded = useRef(false)

  IssueName = ampersandCleanup(IssueName)
  const workspaceID = useAppSelector((state) =>
    selectIssueDefaultWorkspaceId(state, IssueID)
  )

  const issueTagIds = useAppSelector((state) =>
    selectIssueTagIds(state, IssueID)
  )
  const participants = useAppSelector((state) =>
    selectWorkspaceActiveParticipants(state, workspaceID)
  )
  const trends = useAppSelector(selectTrends)
  const IssueTrendID = useAppSelector((state) =>
    selectIssueTrendId(state, IssueID)
  )

  const locations = useAppSelector((state) =>
    selectGeolocationsArray(state, IssueID)
  )
  const dispatch = useAppDispatch()

  const onSubmit = useCallback(
    (name: keyof typeof mapNameToAction, value: any) => {
      dispatch(
        IssueActions.update(
          { IssueID, [name]: value },
          { action: mapNameToAction[name] }
        )
      )
    },
    [IssueID, dispatch]
  )

  const onTagRemoved = (result: any, source?: any) =>
    dispatch(IssueTagActions.delete(result, source))
  const onTagSelected = (result: any, source?: any) =>
    dispatch(IssueTagActions.add(result, source))
  const handleAddLocation = (location: IssueGeolocation) => {
    dispatch(
      GeolocationActions.add({
        IssueID: IssueID,
        IssueTeamWorkspaceID: IssueTeamWorkspaceID,
        Title: location.Title,
        LocationDescription: location.LocationDescription,
        Latitude: location.Latitude,
        Longitude: location.Longitude,
        ShouldDisplayYN: location.ShouldDisplayYN,
      })
    )
  }

  useEffect(() => {
    setNewRespAppAcctID(Responsible)
  }, [Responsible])

  useEffect(() => {
    setNewIssueTrendID(IssueTrendID)
  }, [IssueTrendID])

  //This useEffect is to set the new values when we change issues, and must be placed after the debounce useffects
  useEffect(() => {
    setNewIssueName(IssueName)
    setNewDescription(Description)
    setNewOurPosition(OurPosition)
    setNewWhatsDone(WhatsDone)
    setNewWhatsNext(WhatsNext)
  }, [
    IssueName,
    Description,
    OurPosition,
    WhatsDone,
    WhatsNext,
    Responsible,
    IssueID,
  ])

  useEffect(() => {
    orgAssets(orgId)
      .then((data) => {
        setAssets(data)
      })
      .catch((e) => {
        setAssets(undefined)
      })
  }, [IssueID, orgId])

  useEffect(() => {
    const listener = (e: any) => {
      if (e.key === 'Escape') {
        setSelectedLocation(null)
      }
    }
    window.addEventListener('keydown', listener)
    return () => {
      window.removeEventListener('keydown', listener)
    }
  }, [])

  useEffect(() => {
    if (map) {
      const bounds = new window.google.maps.LatLngBounds()
      locations
        ?.filter((location) => location.ShouldDisplayYN === 'Y')
        .forEach((location: IssueGeolocation) => {
          bounds.extend({ lat: location.Latitude, lng: location.Longitude })
        })
      map.fitBounds(bounds)
      const listener = google.maps.event.addListener(map, 'idle', function () {
        let zoom = map.getZoom()
        if (zoom && zoom > 16) {
          map.setZoom(16)
        }
        google.maps.event.removeListener(listener)
      })
    }
  }, [map, locations])

  const onUnmount = useCallback(function callback(map) {
    setMap(null)
  }, [])

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: googleApiKey,
    libraries: libraries as Libraries,
    preventGoogleFontsLoading: false,
  })

  const onLoad = useCallback(
    (loadedMap: google.maps.Map) => {
      setMap(loadedMap)
      if (!locations || locations.length === 0) {
        const center = {
          lat: 0,
          lng: 0,
        }
        const arbitraryPoint = new google.maps.LatLngBounds(center)
        loadedMap.fitBounds(arbitraryPoint)
      } else {
        const bounds = new google.maps.LatLngBounds()
        locations
          .filter((location) => location.ShouldDisplayYN === 'Y')
          .forEach((location: IssueGeolocation) => {
            const point = new google.maps.LatLng(
              location.Latitude,
              location.Longitude
            )
            bounds.extend(point)
          })
        loadedMap.fitBounds(bounds)
      }
      mapLoaded.current = true
    },
    [locations]
  )

  const GoogleMapSection = useCallback(() => {
    return !isLoaded || loadError ? (
      <div>Maps not loaded...</div>
    ) : (
      <div style={{ width: '100%', height: '400px' }}>
        <GoogleMap
          mapContainerStyle={{
            width: '100%',
            height: '100%',
          }}
          onLoad={onLoad}
          options={{
            fullscreenControl: false,
            streetViewControl: false,
            mapTypeControl: false,
            gestureHandling: 'cooperative',
          }}
          onUnmount={onUnmount}
          onBoundsChanged={() => {
            let zoom = map?.getZoom()
            if (!mapLoaded.current && zoom && zoom > 10) map?.setZoom(10)
          }}
        >
          {locations?.map((location, index) =>
            location.ShouldDisplayYN === 'N' ? null : (
              <Marker
                key={index}
                position={{
                  lat: location.Latitude,
                  lng: location.Longitude,
                }}
                title={
                  location.Title ||
                  location.LocationDescription ||
                  'lat: ' + location.Latitude + ', lng: ' + location.Longitude
                }
                onClick={() => {
                  setSelectedLocation({
                    Title: location.Title,
                    LocationDescription: location.LocationDescription,
                    Latitude: location.Latitude,
                    Longitude: location.Longitude,
                    ShouldDisplayYN: location.ShouldDisplayYN || 'Y',
                  })
                }}
              />
            )
          )}

          {assets?.length >= 1 &&
            assets?.map((asset: OrgAsset, index: number) => (
              <Marker
                key={index}
                position={{
                  lat: asset.Latitude,
                  lng: asset.Longitude,
                }}
                icon={{
                  url: faBuilding,
                  scaledSize: new window.google.maps.Size(40, 40),
                }}
                zIndex={0}
                title={asset.Name}
                onClick={() => {
                  setSelectedLocation({
                    Title: asset.Name,
                    LocationDescription: asset.Description,
                    Latitude: asset.Latitude,
                    Longitude: asset.Longitude,
                    ShouldDisplayYN: 'Y',
                  })
                }}
              />
            ))}
          {selectedLocation && (
            <InfoWindow
              onCloseClick={() => {
                setSelectedLocation(null)
              }}
              position={{
                lat: selectedLocation.Latitude,
                lng: selectedLocation.Longitude,
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <div style={{ fontSize: '16px', fontWeight: '500' }}>
                  {selectedLocation.Title}
                </div>
                {selectedLocation.LocationDescription && (
                  <div>{selectedLocation.LocationDescription}</div>
                )}
                <div>
                  {selectedLocation.Latitude +
                    ', ' +
                    selectedLocation.Longitude}
                </div>
              </div>
            </InfoWindow>
          )}
        </GoogleMap>
      </div>
    )
  }, [
    isLoaded,
    loadError,
    locations,
    assets,
    map,
    onLoad,
    onUnmount,
    selectedLocation,
  ])

  const handleTagAdd = (tag: any) => {
    onTagSelected({ ...tag, IssueID })
  }

  const handleTagDelete = (tag: any) => {
    onTagRemoved({ ...tag, IssueID })
  }

  const LocationSection = useCallback(() => {
    return (
      <div>
        {locations?.length > 0 && (
          <>
            {GoogleMapSection()}
            <Spacer />
            <TableContainer className={classes.root}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.showOnMapColumn}>
                      Show on Map
                    </TableCell>
                    <TableCell className={classes.titleColumn}>Title</TableCell>
                    <TableCell className={classes.createdColumn}>
                      Created
                    </TableCell>
                    <TableCell className={classes.sourceColumn}>
                      Source
                    </TableCell>
                    <TableCell className={classes.deleteColumn}>
                      &nbsp;
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {locations.map((location, index) => {
                    return (
                      <TableRow key={index}>
                        <TableCell>
                          <Checkbox
                            color="primary"
                            checked={
                              location.ShouldDisplayYN === 'N' ? false : true
                            }
                            onClick={() =>
                              dispatch(
                                GeolocationActions.update({
                                  IssueID: location.IssueID,
                                  LocationID: location.LocationID,
                                  Title: location.Title,
                                  LocationDescription:
                                    location.LocationDescription,
                                  Latitude: location.Latitude,
                                  Longitude: location.Longitude,
                                  RadiusInMiles: location.RadiusInMiles
                                    ? location.RadiusInMiles
                                    : undefined,
                                  GeoJSON: location.GeoJSON
                                    ? location.GeoJSON
                                    : undefined,
                                  ShouldDisplayYN:
                                    location.ShouldDisplayYN === 'N'
                                      ? 'Y'
                                      : 'N',
                                  IssueTeamWorkspaceID: IssueTeamWorkspaceID,
                                })
                              )
                            }
                          />
                        </TableCell>
                        <TableCell>
                          {location.Title || 'No Title Provided'}
                        </TableCell>
                        <TableCell>
                          {location.Created
                            ? dayjs(getLocalDate(location.Created))
                                .local()
                                .format(
                                  isMobile
                                    ? 'MM/DD/YY hh:mm a'
                                    : appDateTimeFormat
                                )
                            : ''}
                        </TableCell>
                        <TableCell>{location.Source}</TableCell>
                        <TableCell className={classes.iconWrapper}>
                          {location.Source !== 'Alert_Inbox' && (
                            <FontAwesomeIcon
                              icon={faTrash}
                              size={'lg'}
                              onClick={() => setLocationToDelete(location)}
                            />
                          )}
                        </TableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        )}
        <Spacer />

        <div
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: locations.length > 0 ? 'flex-end' : 'flex-start',
          }}
        >
          <Button onClick={() => setAddLocationOpen(true)}>Add Location</Button>
        </div>
      </div>
    )
  }, [GoogleMapSection, locations, IssueTeamWorkspaceID, dispatch, isMobile])

  return (
    <div className={cx('p-2', classes.root)} key={IssueID}>
      <div className={classes.titleAndDescriptionSectionHeader}>
        Title and Description
      </div>
      <Spacer />
      <Input
        name="IssueName"
        onChange={(e: any) => {
          setNewIssueName(e.target.value)
        }}
        style={{ width: '100%' }}
        value={ampersandCleanup(newIssueName)}
        disabled={disabled}
      />
      <Spacer spaceParam={'large'} />
      <div>
        <StretchLayout>
          <StretchBody>
            <IcoTipTapContainer disabled={disabled} autoHideControls={false}>
              <TiptapEditor
                placeholder="Description..."
                value={newDescription}
                onChange={setNewDescription}
              />
            </IcoTipTapContainer>
          </StretchBody>
        </StretchLayout>
      </div>
      {(issueNameChanged || descriptionChanged) && (
        <>
          <Spacer />
          <Button
            onClick={() => {
              if (issueNameChanged) onSubmit('IssueName', newIssueName)
              if (descriptionChanged) {
                onSubmit('Description', DOMPurify.sanitize(newDescription))
                setNewDescription((prev) => DOMPurify.sanitize(prev))
              }
            }}
          >
            Save
          </Button>
        </>
      )}
      <Spacer spaceParam={'large'} />
      <details open={true}>
        <summary className={classes.sectionHeader}>
          &nbsp;&nbsp;Set Issue Lead
        </summary>
        <Spacer spaceParam={'small'} />
        <FormControl className="form-control">
          <Select
            name="RespAppAcctID"
            disabled={disabled}
            native={true}
            value={newRespAppAcctID || -1}
            onChange={(e: any) => {
              setNewRespAppAcctID(parseInt(e.target.value))
              onSubmit(
                'RespAppAcctID',
                parseInt(e.target.value) === -1
                  ? 'NaN'
                  : parseInt(e.target.value)
              )
            }}
          >
            {!(newRespAppAcctID && newRespAppAcctID > 0) && (
              <option key={-1} value={-1}>
                --Not Assigned--
              </option>
            )}
            {participants.map(
              ({
                ParticipantID,
                AppAcctID,
              }: {
                ParticipantID: number
                AppAcctID: number
              }) => (
                <ConnectedItem
                  selectItemFromStore={(state: RootState) =>
                    selectAppAccountDisplayName(state, AppAcctID)
                  }
                  key={ParticipantID}
                >
                  {(displayName: string | undefined) => (
                    <option value={AppAcctID} key={ParticipantID}>
                      {displayName}
                    </option>
                  )}
                </ConnectedItem>
              )
            )}
          </Select>
        </FormControl>
      </details>
      <Spacer spaceParam={'large'} />
      <details open={issueTagIds && issueTagIds.length > 0}>
        <summary className={classes.sectionHeader}>
          &nbsp;&nbsp;Add Issue Tag(s)
        </summary>
        <Spacer spaceParam={'small'} />
        <div>
          {!disabled && (
            <OrgTagAutocomplete
              className="mb-2"
              fullWidth={true}
              createTag={true}
              IssueID={IssueID}
              onTagSelected={handleTagAdd}
            />
          )}
          <OrgTagContainer tagIds={issueTagIds} onDelete={handleTagDelete} />
        </div>
      </details>
      <Spacer spaceParam={'large'} />
      <details open={locations.length > 0}>
        <summary className={classes.sectionHeader}>
          &nbsp;&nbsp;Location(s)
        </summary>
        <Spacer spaceParam={'large'} />
        {LocationSection()}
      </details>
      <Spacer spaceParam={'large'} />
      <details open={true}>
        <summary className={classes.sectionHeader}>
          &nbsp;&nbsp;Set Issue Trend
        </summary>
        <Spacer spaceParam={'medium'} />
        <FormControl className="form-control">
          <Select
            name="IssueTrendID"
            disabled={disabled}
            native={true}
            value={
              newIssueTrendID || newIssueTrendID === 0 ? newIssueTrendID : -1
            }
            onChange={(e: any) => {
              setNewIssueTrendID(parseInt(e.target.value))
              onSubmit('IssueTrendID', parseInt(e.target.value))
            }}
          >
            {trends.map(
              ({
                TrendID,
                TrendName,
              }: {
                TrendID: number
                TrendName: string
              }) => (
                <option value={TrendID} key={TrendID}>
                  {TrendName}
                </option>
              )
            )}
          </Select>
        </FormControl>
      </details>
      <Spacer spaceParam={'large'} />
      <details open={true}>
        <summary className={classes.sectionHeader}>
          &nbsp;&nbsp;Executive Tracker Issue Updates
        </summary>
        <Spacer spaceParam={'large'} />{' '}
        <TextareaAutoSizeFormControl
          border={true}
          classes={{ textArea: 'w-100' }}
          maxRows={3}
          minRows={3}
          label="Our Position"
          staticLabel={true}
          onChange={(e: any) => {
            setNewOurPosition(e.target.value)
          }}
          value={newOurPosition}
          disabled={disabled}
        />
        <Spacer />
        <TextareaAutoSizeFormControl
          border={true}
          classes={{ textArea: 'w-100' }}
          maxRows={3}
          minRows={3}
          label="Actions Taken"
          staticLabel={true}
          onChange={(e: any) => {
            setNewWhatsDone(e.target.value)
          }}
          value={newWhatsDone}
          disabled={disabled}
        />
        <Spacer />
        <TextareaAutoSizeFormControl
          border={true}
          classes={{ textArea: 'w-100' }}
          maxRows={3}
          minRows={3}
          label="Next Steps"
          staticLabel={true}
          onChange={(e: any) => {
            setNewWhatsNext(e.target.value)
          }}
          value={newWhatsNext}
          disabled={disabled}
        />
        {(ourPositionChanged || whatsDoneChanged || whatsNextChanged) && (
          <>
            <Spacer />
            <Button
              onClick={() => {
                if (ourPositionChanged) onSubmit('OurPosition', newOurPosition)
                if (whatsDoneChanged) onSubmit('WhatsDone', newWhatsDone)
                if (whatsNextChanged) onSubmit('WhatsNext', newWhatsNext)
              }}
            >
              Save
            </Button>
          </>
        )}
      </details>
      <LocationSelector
        isOpen={addLocationOpen}
        onClose={() => setAddLocationOpen(false)}
        onSave={handleAddLocation}
        existingLocations={locations}
      />
      <AdminConsoleDialog
        open={!!locationToDelete}
        onCancel={() => setLocationToDelete(null)}
        onConfirm={() => {
          if (locationToDelete)
            dispatch(
              GeolocationActions.delete({
                IssueID: locationToDelete.IssueID,
                LocationID: locationToDelete.LocationID,
                IssueTeamWorkspaceID: IssueTeamWorkspaceID,
                Longitude: locationToDelete.Longitude,
                Latitude: locationToDelete.Latitude,
                ShouldDisplayYN: locationToDelete.ShouldDisplayYN,
              })
            )
          setLocationToDelete(null)
        }}
        consoleRole="destructive"
        header={<h2>Delete Location</h2>}
        content="Are you sure you want to delete this location?"
      />
      <UnsavedChangesPrompt
        hasUnsavedChanges={
          issueNameChanged ||
          descriptionChanged ||
          ourPositionChanged ||
          whatsDoneChanged ||
          whatsNextChanged
        }
      />
    </div>
  )
}
