import * as React from 'react'
import PDFViewer from '../Shared/PDFViewer'
import { http } from '../_utils/http'
import { useAppSelector } from '../_utils/reactReduxHooks'
import { selectIsMicrosoftTeams } from '../MicrosoftTeams/_selectors'
import { base64ToArrayBuffer } from '../_utils/fileUtils'

interface Props {
  Playbook?: any
}

export const DiagramView = ({ Playbook }: Props) => {
  const [dgmLoading, setdgmLoading] = React.useState(false)
  const isMsTeams = useAppSelector(selectIsMicrosoftTeams)

  React.useEffect(() => {
    if (Playbook.EvDiagram) {
      Playbook.Diagram = Playbook.EvDiagram
    } else if (
      Playbook.IncRpt == null &&
      Playbook.Event &&
      Playbook.Event.EventID
    ) {
      setdgmLoading(true)
      const getdgm = async (): Promise<any> => {
        var customHeaders: any = []
        customHeaders.push({
          name: 'diagrameventid',
          value: `${Playbook.Event.EventID}`,
        })
        customHeaders.push({
          name: 'useragent',
          value: `${navigator.userAgent}`,
        })
        const result = await http<any>({
          path: '/playbook/',
          headers: customHeaders,
        })
        if (result.ok && result.body) {
          return result.body
        } else {
          return ''
        }
      }
      const doGetdgm = async () => {
        const dgm = await getdgm()
        if (dgm) {
          Playbook.Diagram = dgm.EvDiagram
        }
        setdgmLoading(false)
      }
      doGetdgm()
    }
  }, [Playbook])

  function renderDiagram() {
    if (Playbook.Diagram) {
      let ph = ''
      ph = ''
      ph += "<div class='col'>"
      ph += "<div class='row'>"
      let dgm = Playbook.Diagram
      /*
      if (dgm.EventName) {
        ph += '<h3>' + dgm.EventName + '</h3>'
      }
      */
      if (dgm.DiagramLabel) {
        ph += '<p>' + dgm.DiagramLabel + '</p>'
      }
      ph += '</div>'
      const filemimes: { [key: string]: string } = {
        //"pdf": "application/pdf"
        doc: 'application/msword',
        dot: 'application/msword',
        docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        dotx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.template',
        docm: 'application/vnd.ms-word.document.macroEnabled.12',
        dotm: 'application/vnd.ms-word.template.macroEnabled.12',
        xls: 'application/vnd.ms-excel',
        xlt: 'application/vnd.ms-excel',
        xla: 'application/vnd.ms-excel',
        xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        xltx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.template',
        xlsm: 'application/vnd.ms-excel.sheet.macroEnabled.12',
        xltm: 'application/vnd.ms-excel.template.macroEnabled.12',
        xlam: 'application/vnd.ms-excel.addin.macroEnabled.12',
        xlsb: 'application/vnd.ms-excel.sheet.binary.macroEnabled.12',
        ppt: 'application/vnd.ms-powerpoint',
        pot: 'application/vnd.ms-powerpoint',
        pps: 'application/vnd.ms-powerpoint',
        ppa: 'application/vnd.ms-powerpoint',
        pptx: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
        potx: 'application/vnd.openxmlformats-officedocument.presentationml.template',
        ppsx: 'application/vnd.openxmlformats-officedocument.presentationml.slideshow',
        ppam: 'application/vnd.ms-powerpoint.addin.macroEnabled.12',
        pptm: 'application/vnd.ms-powerpoint.presentation.macroEnabled.12',
        potm: 'application/vnd.ms-powerpoint.template.macroEnabled.12',
        ppsm: 'application/vnd.ms-powerpoint.slideshow.macroEnabled.12',
      }
      if (dgm.Diagram) {
        let pimg = dgm.Diagram
        let filemime = filemimes[pimg.Format]
        if (filemime) {
          // the binary data stored up on the database seems to have one extra null byte at the end
          // the slice statement will remove the last byte
          let dname = 'Diagram'
          if (dgm.EventName) {
            dname = dgm.EventName
          }
          if (dgm.DiagramLabel) {
            dname = dgm.DiagramLabel
          }
          let fname = dname + '.' + pimg.Format
          let newhref1 = '/api/playbook/Diagram/' + dgm.EventID.toString()
          if (isMsTeams) {
            newhref1 = 'data:' + filemime + ';base64,' + dgm.Diagram.Graphic
          }
          return (
            <>
              <p>&nbsp;</p>
              {/* {<pre>{JSON.stringify(dgm, null, 2)}</pre>} */}
              <p>
                <a download={fname} target="_blank" href={newhref1}>
                  Download {dname}
                </a>
              </p>
            </>
          )
        } else if (pimg.Format === 'pdf') {
          let newhref = '/api/playbook/Diagram/' + dgm.EventID.toString()
          if (isMsTeams) {
            newhref = 'data:application/pdf;base64,' + pimg.Graphic
          }
          let dname = 'Diagram'
          if (dgm.EventName) {
            dname = dgm.EventName
          }
          if (dgm.DiagramLabel) {
            dname = dgm.DiagramLabel
          }
          let fname = dname + '.' + pimg.Format

          return (
            <>
              <p className="mt-5">
                <a download={fname} target="_blank" href={newhref}>
                  Download {dname}
                </a>
              </p>
              <PDFViewer
                data={base64ToArrayBuffer(pimg.Graphic)}
                className=""
              />
            </>
          )
          /*
           */
        } else {
          ph +=
            "<div class='row'><img src='data:image/" +
            pimg.Format +
            ';base64,' +
            pimg.Graphic +
            "'></div>"
        }
      } else {
        ph += "<img src='https://via.placeholder.com/24'>"
        ph += '</div>'
        ph += '</div>'
      }
      return (
        <div
          id="diagram"
          className="mt-5"
          dangerouslySetInnerHTML={{
            __html: ph,
          }}
        />
      )
    }
  }

  function render() {
    if (Playbook) {
      if (Playbook.EvDiagram) {
        Playbook.Diagram = Playbook.EvDiagram
      }
    }

    if (Playbook.Diagram) {
      return renderDiagram()
    }
    return <div className="mt-5">Loading Attachment . . .</div>
  }

  return <>{render()}</>
}
