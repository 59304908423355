// @ts-nocheck
// TODO: Typescript
import { WorkspaceConstants } from './_constants'
import {
  parseIntIfPresent,
  removeKeyFromObject,
  arrayConcat,
} from '../_utils/objectUtils'
import {
  ChatConstants,
  FETCH_DIRECT_MESSAGES,
  CREATE_DIRECT_MESSAGE,
  DELETE_DIRECT_MESSAGE,
} from '../Chat/_constants'
import { ParticipantConstants } from '../Participant/_constants'
import { reducerCrud } from '../_utils/reduxUtils'
import { ArtifactConstants } from '../Upload/_constants'
import { getArtifactId } from '../_schema/_schemas'
import { TaskConstants } from '../Task/_constants'
import { ConferenceConstants } from '../Conference/_constants'
import {
  FETCH_UPDATE_ISSUE_FORMS,
  FETCH_UPDATE_ISSUE_FORMS_COUNT,
} from '../Form/_constants'
import { FETCH_GRAPH_CALENDAR_EVENTS } from '../MicrosoftTeams/_constants'

export default function workspaceReducer(state = {}, action) {
  const payload = action.payload || {}
  let { IssueTeamWorkspaceID } = payload.source || {}
  if (!IssueTeamWorkspaceID)
    IssueTeamWorkspaceID = (payload.params || {}).IssueTeamWorkspaceID
  const workspace = state[IssueTeamWorkspaceID] || {}
  const parentKey = 'IssueTeamWorkspaceID'

  switch (action.type) {
    case WorkspaceConstants.INIT.SUCCESS:
      return action.payload.result || {}
    case WorkspaceConstants.ADD.SUCCESS:
      return {
        ...state,
        [action.payload.result.IssueTeamWorkspaceID]: action.payload.result,
      }
    case WorkspaceConstants.DELETE.SUCCESS:
      return removeKeyFromObject(
        state,
        action.payload.result.IssueTeamWorkspaceID
      )
    case WorkspaceConstants.DELETE_RANGE.SUCCESS:
      return reducerCrud.deleteIdRange(state, action.payload.result)
    case WorkspaceConstants.UPDATE_RANGE.SUCCESS:
      return reducerCrud.updateRange(state, action, parentKey)

    // Participants
    case ParticipantConstants.ADD.SUCCESS:
      return reducerCrud.addChild(
        state,
        action,
        parentKey,
        'ParticipantID',
        'Participants'
      )
    case ParticipantConstants.ADD_RANGE.SUCCESS:
      return reducerCrud.addChildIdRange(
        state,
        action,
        parentKey,
        'Participants',
        action.payload.result.map((part) => part.ParticipantID)
      )
    case ParticipantConstants.DELETE.SUCCESS:
      return reducerCrud.deleteChild(
        state,
        action,
        parentKey,
        'ParticipantID',
        'Participants'
      )
    case ParticipantConstants.DELETE_RANGE.SUCCESS:
      return reducerCrud.deleteChildRange(
        state,
        action,
        'IssueTeamWorkspaceID',
        action.payload.result,
        'Participants'
      )
    case ParticipantConstants.ADD_RELATED.SUCCESS:
      return reducerCrud.addChildIdRange(
        state,
        action,
        parentKey,
        'Participants'
      )

    // Conferences
    case ConferenceConstants.ADD.SUCCESS:
      return reducerCrud.addChild(
        state,
        action,
        parentKey,
        'ConferenceID',
        'ConfSched'
      )
    case ConferenceConstants.DELETE_RANGE.SUCCESS:
      return reducerCrud.deleteChildRange(
        state,
        action,
        'IssueTeamWorkspaceID',
        action.payload.result,
        'ConfSched'
      )
    case ConferenceConstants.DELETE.SUCCESS:
      return reducerCrud.deleteChild(
        state,
        action,
        parentKey,
        'ConferenceID',
        'ConfSched'
      )
    // Artifacts
    case ArtifactConstants.ADD_RELATED.SUCCESS:
      return reducerCrud.addChildIdRange(state, action, parentKey, 'Artifacts')
    case ArtifactConstants.DELETE_RANGE.SUCCESS:
      return reducerCrud.deleteChildRange(
        state,
        action,
        'IssueTeamWorkspaceID',
        action.payload.result,
        'Artifacts'
      )
    case ArtifactConstants.ADD.SUCCESS: {
      // Only attach upload type of artifacts to to workspace (This effectively excludes task and chat upload types)
      if (action.payload.result.UploadType !== 'Artifact') return state

      return {
        ...state,
        [IssueTeamWorkspaceID]: {
          ...workspace,
          Artifacts: [
            getArtifactId(action.payload.result),
            ...(workspace.Artifacts || []),
          ],
        },
      }
    }

    // Chats
    case ChatConstants.ADD_RELATED.SUCCESS:
      return reducerCrud.addChildIdRange(state, action, parentKey, 'CollabChat')
    case ChatConstants.ADD.SUCCESS: {
      return {
        ...state,
        [IssueTeamWorkspaceID]: {
          ...workspace,
          CollabChat: workspace.CollabChat.concat(action.payload.result.ChatID),
        },
      }
    }
    case ChatConstants.DELETE_RANGE.SUCCESS:
      return reducerCrud.deleteChildRange(
        state,
        action,
        'IssueTeamWorkspaceID',
        action.payload.result,
        'CollabChat'
      )

    // Direct Messages
    case FETCH_DIRECT_MESSAGES.SUCCESS:
      return {
        ...state,
        [IssueTeamWorkspaceID]: {
          ...workspace,
          DirectMessages: Object.keys(payload.result).map((id) => parseInt(id)),
        },
      }

    case CREATE_DIRECT_MESSAGE.SUCCESS:
      return {
        ...state,
        [IssueTeamWorkspaceID]: {
          ...workspace,
          DirectMessages: arrayConcat(
            workspace.DirectMessages || [],
            payload.result.ChatID
          ),
        },
      }

    case DELETE_DIRECT_MESSAGE.SUCCESS:
      return {
        ...state,
        [IssueTeamWorkspaceID]: {
          ...workspace,
          DirectMessages: (
            state[IssueTeamWorkspaceID].DirectMessages || []
          ).filter((chatId) => chatId !== payload.result.ChatID),
        },
      }

    // Tasks
    case TaskConstants.ADD_RELATED.SUCCESS:
      return reducerCrud.addChildIdRange(state, action, parentKey, 'CollabTask')
    case TaskConstants.ADD.SUCCESS: {
      return {
        ...state,
        [IssueTeamWorkspaceID]: {
          ...workspace,
          CollabTask: workspace.CollabTask.concat(action.payload.result.TaskID),
        },
      }
    }
    case TaskConstants.DELETE_RANGE.SUCCESS:
      return reducerCrud.deleteChildRange(
        state,
        action,
        'IssueTeamWorkspaceID',
        action.payload.result,
        'CollabTask'
      )

    // Forms
    case FETCH_UPDATE_ISSUE_FORMS.SUCCESS:
      return {
        ...state,
        [IssueTeamWorkspaceID]: {
          ...workspace,
          BlankReports: action.payload.result,
        },
      }
    case FETCH_UPDATE_ISSUE_FORMS_COUNT.SUCCESS:
      return {
        ...state,
        [IssueTeamWorkspaceID]: {
          ...workspace,
          BlankReportsCount: action.payload.count,
        },
      }

    // MS Teams events
    case FETCH_GRAPH_CALENDAR_EVENTS.SUCCESS:
      return {
        ...state,
        [IssueTeamWorkspaceID]: {
          ...workspace,
          msTeamsEventsCount: action.payload.result.count,
        },
      }
    default:
      return state
  }
}

export const selectWorkspacePathId = (_, props) =>
  parseIntIfPresent(props, 'match.params.IssueTeamWorkspaceID')

export const selectWorkspacesByIdMap = (state) => state
export const selectWorkspaceById = (state, id) =>
  (selectWorkspacesByIdMap(state) || {})[id]
export const selectWorkspaceChatIds = (state, id) =>
  (selectWorkspaceById(state, id) || {}).CollabChat
export const selectWorkspaceTaskIds = (state, id) =>
  (selectWorkspaceById(state, id) || {}).CollabTask
export const selectWorkspaceParticipantIds = (state, id) =>
  (selectWorkspaceById(state, id) || {}).Participants
export const selectWorkspaceOrgIssueTeamIds = (state, id) =>
  (selectWorkspaceById(state, id) || {}).AssignToTeams
export const selectWorkspaceArtifactIds = (state, id) =>
  (selectWorkspaceById(state, id) || {}).Artifacts
export const selectWorkspaceChatCount = (state, id) =>
  (selectWorkspaceChatIds(state, id) || []).length
export const selectWorkspaceTaskCount = (state, id) =>
  (selectWorkspaceTaskIds(state, id) || []).length
export const selectWorkspaceParticipantCount = (state, id) =>
  (selectWorkspaceParticipantIds(state, id) || []).length
export const selectWorkspaceArtifactCount = (state, workspaceID) =>
  (selectWorkspaceArtifactIds(state, workspaceID) || []).length
export const selectWorkspaceConferenceIds = (state, workspaceID) =>
  selectWorkspaceById(state, workspaceID)?.ConfSched
export const selectWorkspaceUpdateForms = (state, workspaceID) =>
  (selectWorkspaceById(state, workspaceID) || {}).BlankReports
export const selectWorkspaceUpdateFormCount = (state, workspaceID) => {
  const workspace = selectWorkspaceById(state, workspaceID) || {}
  let count = workspace.BlankReportsCount
  if (count === undefined || count === null)
    return (workspace.BlankReports || []).length

  return count
}

export const selectWorkspaceDirectMessageIds = (state, workspaceId) =>
  (selectWorkspaceById(state, workspaceId) || {}).DirectMessages
export const selectWorkspaceDirectMessagesCount = (state, workspaceId) =>
  (selectWorkspaceDirectMessageIds(state, workspaceId) || []).length
export const selectWorkspaceAllChatCounts = (state, workspaceId) => {
  return selectWorkspaceDirectMessagesCount(state, workspaceId) + 1
}
