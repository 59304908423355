import * as React from 'react'
import { MasterPageLayout } from '../Pages/MasterPageLayout'
import { PlaybookRouteParams } from '../Routing/RouteProps'
import { RouteComponentProps } from 'react-router'
import { useAppSelector } from '../_utils/reactReduxHooks'
import { selectEnvironment, selectUserOrgInfo } from '../Issue/_selectors'
import classes from './AdminConsolePage.module.scss'
import { AccountOrganization, Organization } from '../Issue/_reducer'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faArrowLeft,
  faFile,
  faFilePlus,
  faTag,
  faUser,
  faTrash,
  faAlienMonster,
  faDesktop,
  faSlidersHSquare,
  faEdit,
  faVideo,
  faBuilding,
} from '@fortawesome/pro-solid-svg-icons'
import { Dialog, styled, TableRow } from '@material-ui/core'
import { Spacer } from '../Shared/Spacer'
import AdminConsoleOrgSelection from './AdminConsoleOrgSelection'
import { AdminConsoleTag } from './AdminConsoleTag'
import { AdminConsoleHoldingStatements } from './AdminConsoleHoldingStatement'
import { AdminConsoleStakeholderTemplates } from './AdminConsoleStakeholderTemplates'
import { AdminConsoleOrphanedIssue } from './AdminConsoleOrphanedIssue'
import { AdminConsoleTaskListTemplate } from './AdminConsoleTaskListTemplate'
import { AdminConsoleAlertInboxUsers } from './AdminConsoleAlertInboxUsers'
import { AdminConsoleDeleteInbox } from './AdminConsoleDeleteInbox'
import { AdminConsoleIssueTeamsUsers } from './AdminConsoleIssueTeamsUsers'
import { AdminConsoleDeleteIssues } from './AdminConsoleDeleteIssues'
import { OrgDataReport } from './OrgDataReport'
import { cx } from '../_utils/objectUtils'
//import { IssueDynamicFormTemplateEditor } from '../Issue/IssueDynamicFormTemplateEditor'
import { icoFetch } from '../_utils/fetchUtils'
import { useIsMounted } from '../_utils/hooks'
import { AdminConsoleMeetingLinks } from './AdminConsoleMeetingLinks'
import { AdminConsoleAssets } from './AdminConsoleAssets'
import { IssueDynamicFormEditorTool } from '../Issue/IssueDynamicFormEditorTool'

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
  '&:hover': {
    cursor: 'pointer',
  },
}))

enum AdminConsoleOptions {
  Tags = 'tags',
  HoldingStatements = 'holdingStatements',
  TaskListTemplates = 'taskListTemplates',
  OrphanedIssues = 'orphanedIssues',
  IssueTeamsUsers = 'issueTeamsUsers',
  AlertInboxUsers = 'alertInboxUsers',
  AlertInboxDelete = 'alertInboxDelete',
  DeleteIssues = 'deleteIssues',
  OrgDataReport = 'orgDataReport',
  OrgMeetingLinks = 'orgMeetingLinks',
  OrgAssets = 'orgAssets',

  StakeholderNotificationTemplates = 'stakeholderNotificationTemplates',
  // DynamicFormsDefiniton = 'dynamicFormsDefinition',
  DynamicFormsEditorTool = 'dynamicFormsEditorTool',
}

interface Props extends RouteComponentProps<PlaybookRouteParams> {}

export const AdminConsolePage = ({ history, match, location }: Props) => {
  const [selectedOrg, setSelectedOrg] = React.useState({} as Organization)

  const [selectedAdminOption, setSelectedAdminOption] = React.useState<
    AdminConsoleOptions | undefined
  >()

  const [userSelectingOrg, setUserSelectingOrg] = React.useState(false)

  const userOrgInfo: AccountOrganization =
    useAppSelector((state) => selectUserOrgInfo(state)) || []

  const adminOrgs = userOrgInfo.Orgs?.filter((org: Organization) => {
    const administratorTeams = org.IssueTeams.filter(
      (team) => team.TeamRole === 'Administrator'
    )
    return administratorTeams?.length > 0
  })

  const clearState = () => {
    if (selectedAdminOption) {
      setSelectedAdminOption(undefined)
    } else {
      setSelectedOrg({} as Organization)
      localStorage.setItem('orgId', 'undefined')
    }
  }
  const env = useAppSelector((state) => {
    return selectEnvironment(state).toLocaleLowerCase()
  })

  // const env = selectEnvironment(state).toLocaleLowerCase()
  const moreThanOneAdminOrg = adminOrgs?.length > 1
  const firstOrg = React.useMemo(
    () => (adminOrgs ? adminOrgs[0] : ({} as Organization)),
    [adminOrgs]
  )
  const [userDeleteAllPartIssue, setUserDeleteAllPartIssue] =
    React.useState(false)
  const isMounted = useIsMounted()

  React.useEffect(() => {
    const refreshOrgId = localStorage.getItem('orgId')
    if (refreshOrgId && !selectedOrg?.OrgID) {
      const selectedRefreshOrg = adminOrgs?.find((org) => {
        return org.OrgID.toString() === refreshOrgId
      })
      if (selectedRefreshOrg) {
        setSelectedOrg(selectedRefreshOrg)
      }
    }
  }, [adminOrgs, selectedOrg?.OrgID])

  React.useEffect(() => {
    if (selectedOrg?.OrgID) {
      localStorage.setItem('orgId', selectedOrg?.OrgID)
    }
  }, [selectedOrg, moreThanOneAdminOrg])

  React.useEffect(() => {
    if (
      selectedOrg &&
      Object.keys(selectedOrg).length === 0 &&
      !moreThanOneAdminOrg
    )
      setSelectedOrg(firstOrg)
  }, [firstOrg, moreThanOneAdminOrg, selectedOrg])

  // see if this user is allowed to delte all/part of issue
  React.useEffect(() => {
    const getPickListValues = async (): Promise<any> => {
      icoFetch('/api/User/UserJson')
        .then((response) => {
          if (response.ok && isMounted()) return response.json()
        })
        .then((value) => {
          const permissionList = value.UserPermissions.split(',')

          const deleteAllPartOfIssue = permissionList.find(
            (permission: string) => {
              return permission.trim() === 'Delete All/Part of Issue'
            }
          )
          setUserDeleteAllPartIssue(!!deleteAllPartOfIssue)
        })
        .catch((e) => console.log(e))
    }
    getPickListValues()
  }, [isMounted])

  const AdminConsoleHeader = ({
    backButton,
    hideOrgChanger,
  }: {
    backButton: boolean
    hideOrgChanger?: boolean
  }) => {
    return (
      <div className={classes.OrganizationHeader}>
        <div
          className={classes.OrganizationManagementHeaderBackButtonContainer}
        >
          {backButton ? (
            <FontAwesomeIcon
              className={classes.OrganizationManagementHeaderBackButton}
              icon={faArrowLeft}
              size="1x"
              onClick={() => clearState()}
            />
          ) : null}
        </div>
        <div
          className={classes.OrganizationManagementHeaderText}
          style={{ marginLeft: '-50px', display: 'flex' }}
        >
          <div>Organization: </div>

          <div
            className={classes.OrganizationManagementHeaderSelectedOrg}
            onClick={() => setUserSelectingOrg(true)}
          >
            &nbsp;{selectedOrg.OrgName} {}
            {!hideOrgChanger && <FontAwesomeIcon icon={faEdit} size={'sm'} />}
          </div>
        </div>
      </div>
    )
  }

  const renderOrgManagement = () => {
    if (selectedAdminOption) {
      switch (selectedAdminOption) {
        case AdminConsoleOptions.HoldingStatements: {
          return (
            <>
              <AdminConsoleHeader backButton={!!selectedAdminOption} />
              <AdminConsoleHoldingStatements selectedOrg={selectedOrg} />
            </>
          )
        }
        case AdminConsoleOptions.OrphanedIssues: {
          return (
            <>
              <AdminConsoleHeader backButton={!!selectedAdminOption} />
              <AdminConsoleOrphanedIssue OrgID={selectedOrg.OrgID} />
            </>
          )
        }
        case AdminConsoleOptions.Tags: {
          return (
            <>
              <AdminConsoleHeader backButton={!!selectedAdminOption} />
              <AdminConsoleTag selectedOrg={selectedOrg} />
            </>
          )
        }
        case AdminConsoleOptions.TaskListTemplates: {
          return (
            <>
              <AdminConsoleHeader backButton={!!selectedAdminOption} />
              <AdminConsoleTaskListTemplate selectedOrg={selectedOrg} />
            </>
          )
        }
        case AdminConsoleOptions.AlertInboxUsers: {
          return (
            <>
              <AdminConsoleHeader backButton={!!selectedAdminOption} />
              <AdminConsoleAlertInboxUsers OrgID={selectedOrg.OrgID} />
            </>
          )
        }
        case AdminConsoleOptions.AlertInboxDelete: {
          return (
            <>
              <AdminConsoleHeader backButton={!!selectedAdminOption} />
              <AdminConsoleDeleteInbox OrgID={selectedOrg.OrgID} />
            </>
          )
        }
        case AdminConsoleOptions.OrgMeetingLinks: {
          return (
            <>
              <AdminConsoleHeader backButton={!!selectedAdminOption} />
              <AdminConsoleMeetingLinks selectedOrg={selectedOrg} />
            </>
          )
        }
        case AdminConsoleOptions.IssueTeamsUsers: {
          return (
            <>
              <AdminConsoleHeader backButton={!!selectedAdminOption} />
              <AdminConsoleIssueTeamsUsers OrgID={selectedOrg.OrgID} />
            </>
          )
        }
        case AdminConsoleOptions.DeleteIssues: {
          return (
            <>
              <AdminConsoleHeader backButton={!!selectedAdminOption} />
              <AdminConsoleDeleteIssues OrgID={selectedOrg.OrgID} />
            </>
          )
        }
        case AdminConsoleOptions.OrgDataReport: {
          return (
            <>
              <AdminConsoleHeader backButton={!!selectedAdminOption} />
              <OrgDataReport OrgID={selectedOrg.OrgID} />
            </>
          )
        }
        case AdminConsoleOptions.StakeholderNotificationTemplates: {
          return (
            <>
              <AdminConsoleHeader backButton={!!selectedAdminOption} />
              <AdminConsoleStakeholderTemplates selectedOrg={selectedOrg} />
            </>
          )
        }

        // case AdminConsoleOptions.DynamicFormsDefiniton: {
        //   return (
        //     <>
        //       <AdminConsoleHeader
        //         backButton={!!selectedAdminOption}
        //         hideOrgChanger
        //       />
        //       <IssueDynamicFormTemplateEditor OrgID={selectedOrg.OrgID} />
        //     </>
        //   )
        // }
        case AdminConsoleOptions.DynamicFormsEditorTool: {
          return (
            <>
              <AdminConsoleHeader
                backButton={!!selectedAdminOption}
                hideOrgChanger
              />
              <IssueDynamicFormEditorTool OrgID={selectedOrg.OrgID} />
            </>
          )
        }
        case AdminConsoleOptions.OrgAssets: {
          return (
            <>
              <AdminConsoleHeader backButton={!!selectedAdminOption} />
              <AdminConsoleAssets selectedOrg={selectedOrg} />
            </>
          )
        }
      }
    }

    return (
      <>
        <AdminConsoleHeader
          backButton={!!moreThanOneAdminOrg || !!selectedAdminOption}
        />
        <div className={classes.OrganizationManagementDisclaimer}>
          {' '}
          The following Data Elements can be Administered for the entire
          Organization and will affect all issues.
        </div>
        <div className={classes.OrganizationManagementSelectionSection}>
          <div
            className={cx(
              classes.OrganizationManagementSelectionItem,
              classes.iconWrapper
            )}
            onClick={() =>
              setSelectedAdminOption(AdminConsoleOptions.DynamicFormsEditorTool)
            }
          >
            <FontAwesomeIcon icon={faSlidersHSquare} />
            <Spacer spaceParam={'large'} />
            <div>Smart Forms</div>
          </div>
          <div
            className={cx(
              classes.OrganizationManagementSelectionItem,
              classes.iconWrapper
            )}
            onClick={() =>
              setSelectedAdminOption(AdminConsoleOptions.OrgAssets)
            }
          >
            <FontAwesomeIcon icon={faBuilding} />
            <Spacer spaceParam={'large'} />
            <div>Assets</div>
          </div>
          <div
            className={cx(
              classes.OrganizationManagementSelectionItem,
              classes.iconWrapper
            )}
            onClick={() =>
              setSelectedAdminOption(AdminConsoleOptions.OrgMeetingLinks)
            }
          >
            <FontAwesomeIcon className={classes.videoIcon} icon={faVideo} />
            <Spacer />
            Meeting Links
          </div>
          <div
            className={cx(
              classes.OrganizationManagementSelectionItem,
              classes.iconWrapper
            )}
            onClick={() =>
              setSelectedAdminOption(AdminConsoleOptions.TaskListTemplates)
            }
          >
            <FontAwesomeIcon icon={faFilePlus} />
            <Spacer spaceParam={'large'} />
            Task List Templates
          </div>
          <div
            className={cx(
              classes.OrganizationManagementSelectionItem,
              classes.iconWrapper
            )}
            onClick={() =>
              setSelectedAdminOption(AdminConsoleOptions.HoldingStatements)
            }
          >
            <FontAwesomeIcon icon={faFile} />
            <Spacer spaceParam={'large'} />
            Holding Statements
          </div>
          <div
            className={cx(
              classes.OrganizationManagementSelectionItem,
              classes.iconWrapper
            )}
            onClick={() =>
              setSelectedAdminOption(
                AdminConsoleOptions.StakeholderNotificationTemplates
              )
            }
          >
            <FontAwesomeIcon icon={faFile} />
            <Spacer spaceParam={'large'} />
            Stakeholder Notification Templates
          </div>
          <div
            className={cx(
              classes.OrganizationManagementSelectionItem,
              classes.iconWrapper
            )}
            onClick={() => setSelectedAdminOption(AdminConsoleOptions.Tags)}
          >
            <FontAwesomeIcon icon={faTag} />
            <Spacer spaceParam={'large'} />
            Tags
          </div>

          <div
            className={cx(
              classes.OrganizationManagementSelectionItem,
              classes.iconWrapper
            )}
            onClick={() =>
              setSelectedAdminOption(AdminConsoleOptions.IssueTeamsUsers)
            }
          >
            <FontAwesomeIcon icon={faUser} />
            <Spacer spaceParam={'large'} />
            Users in Issue Teams
          </div>
          <div
            className={cx(
              classes.OrganizationManagementSelectionItem,
              classes.iconWrapper
            )}
            onClick={() =>
              setSelectedAdminOption(AdminConsoleOptions.AlertInboxUsers)
            }
          >
            <FontAwesomeIcon icon={faUser} />
            <Spacer spaceParam={'large'} />
            Users in Alert Inboxes
          </div>

          {userDeleteAllPartIssue && (
            <div
              className={cx(
                classes.OrganizationManagementSelectionItem,
                classes.iconWrapper
              )}
              onClick={() =>
                setSelectedAdminOption(AdminConsoleOptions.DeleteIssues)
              }
            >
              <FontAwesomeIcon icon={faTrash} />
              <Spacer spaceParam={'large'} />
              <div>Delete Issue or Part of Issue</div>
            </div>
          )}

          <div
            className={cx(
              classes.OrganizationManagementSelectionItem,
              classes.iconWrapper
            )}
            onClick={() =>
              setSelectedAdminOption(AdminConsoleOptions.AlertInboxDelete)
            }
          >
            <FontAwesomeIcon icon={faDesktop} />
            <Spacer />
            Delete Alert Inboxes
          </div>

          <div
            className={cx(
              classes.OrganizationManagementSelectionItem,
              classes.iconWrapper
            )}
            onClick={() =>
              setSelectedAdminOption(AdminConsoleOptions.OrphanedIssues)
            }
          >
            <FontAwesomeIcon icon={faUser} />
            <Spacer spaceParam={'large'} />
            Orphaned Issues
          </div>

          {env === 'development' && (
            <div
              className={cx(
                classes.OrganizationManagementSelectionItem,
                classes.iconWrapper
              )}
              onClick={() =>
                setSelectedAdminOption(AdminConsoleOptions.OrgDataReport)
              }
            >
              <FontAwesomeIcon icon={faAlienMonster} />
              <Spacer spaceParam={'large'} />
              <div>
                Organization Data Reports
                <br />
                <sub>(only in {env} )</sub>
              </div>
            </div>
          )}

          {/* <div
            className={cx(
              classes.OrganizationManagementSelectionItem,
              classes.iconWrapper
            )}
            onClick={() =>
              setSelectedAdminOption(AdminConsoleOptions.DynamicFormsDefiniton)
            }
          >
            <FontAwesomeIcon icon={faSlidersHSquare} />
            <Spacer spaceParam={'large'} />
            <div>Dynamic Forms Definition</div>
          </div> */}
        </div>
      </>
    )
  }

  if (!firstOrg?.OrgID) return <div> this page does not exist</div>

  return (
    <MasterPageLayout
      history={history}
      match={match as any}
      location={location}
    >
      <div className={classes.AdminConsolePage}>
        <div className={classes.AdminConsole}>
          {selectedOrg?.OrgID ? (
            renderOrgManagement()
          ) : (
            <AdminConsoleOrgSelection
              onSelection={setSelectedOrg}
              adminOrgs={adminOrgs}
            />
          )}
          <Dialog
            open={userSelectingOrg}
            onClose={() => setUserSelectingOrg(false)}
          >
            <div>
              <AdminConsoleOrgSelection
                onSelection={(org: Organization) => {
                  setSelectedOrg(org)
                  setUserSelectingOrg(false)
                }}
                adminOrgs={adminOrgs}
              />
            </div>
          </Dialog>
        </div>
      </div>
    </MasterPageLayout>
  )
}
