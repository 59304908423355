import { WebSocketConstants } from '../_webSocket/_constants'
import { objDatePropsToUTC } from '../_utils/dateUtils'
import { AlertFromDBModel, InboxModel } from '../Alert/_models'
import {
  addInbox,
  pinAlert,
  unPinAlert,
  addAlertToIssue,
  createNewAlertIssue,
  createClonedAlertIssue,
  deleteInbox,
  markReviewedAlert,
  markNotReviewedAlert,
  setInboxActive,
  updateInbox,
  shareAlert,
  alertFilterFetch,
  alertSeverityColorsFetch,
} from '../Alert/_actions'
import { ErrorModel } from '../_models'
import { Store } from 'redux'
import { HubConnection } from '@microsoft/signalr'
import {
  ADD_INBOX,
  PIN_ALERT,
  UNPIN_ALERT,
  ADD_ALERT_TO_ISSUE,
  CREATE_NEW_ALERT_ISSUE,
  CREATE_CLONED_ALERT_ISSUE,
  DELETE_INBOX,
  MARK_REVIEWED_ALERT,
  MARK_NOT_REVIEWED_ALERT,
  UPDATE_INBOX,
  SHARE_ALERT,
  ALERT_ASSESSMENT,
} from '../Alert/_constants'
import { AsyncTypeActions } from '../_utils/reduxUtils'
import { getCleanArray } from '../_utils/objectUtils'
import { inboxSchema } from '../_schema/_schemas'
import { normalize } from 'normalizr'
import { batch } from 'react-redux'
interface Result<T> {
  returnData?: T
  ErrorMessage?: ErrorModel
  StatusDescr?: string
  source?: any
}

export default function alertHub(
  storeAPI: Store,
  action: any,
  hubConnection: HubConnection
) {
  switch (action.type) {
    case WebSocketConstants.CONNECTION_RETRY_SUCCESSFUL:
    case WebSocketConstants.CONNECTION_SUCCESSFUL:
      storeAPI.dispatch(alertFilterFetch())
      storeAPI.dispatch(alertSeverityColorsFetch())

      hubConnection.on('BroadcastInboxAdd', (data: Result<InboxModel>) =>
        doInboxUpdate(data, addInbox)
      )
      hubConnection.on('BroadcastInboxUpdate', (data: Result<InboxModel>) => {
        return doInboxUpdate(data, updateInbox)
      })

      hubConnection.on('BroadcastInboxDelete', (data: Result<string>) => {
        if (!data.ErrorMessage) {
          if (!data.returnData) return

          batch(() => {
            // Delete the inbox
            storeAPI.dispatch(deleteInbox.success(data.returnData))
          })
        } else {
          storeAPI.dispatch(
            deleteInbox.failure(
              data.ErrorMessage,
              data.source,
              data.source,
              data.source
            )
          )
        }
      })

      hubConnection.on(
        'BroadcastInboxSetActive',
        (data: Result<InboxModel>) => {
          storeAPI.dispatch(setInboxActive.success(data.returnData))
        }
      )

      hubConnection.on('BroadcastAlert_pin', (data: Result<AlertFromDBModel>) =>
        updateAlert(data, pinAlert)
      )
      hubConnection.on(
        'BroadcastAlert_unPin',
        (data: Result<AlertFromDBModel>) => updateAlert(data, unPinAlert)
      )
      //todo adjust the constant below for delete
      hubConnection.on(
        'BroadcastAlert_markReviewed',
        (data: Result<AlertFromDBModel>) => updateAlert(data, markReviewedAlert)
      )
      hubConnection.on(
        'BroadcastAlert_markNotReviewed',
        (data: Result<AlertFromDBModel>) =>
          updateAlert(data, markNotReviewedAlert)
      )
      hubConnection.on(
        'BroadcastAlert_addToIssue',
        (data: Result<AlertFromDBModel>) => updateAlert(data, addAlertToIssue)
      )
      hubConnection.on(
        'BroadcastAlert_newIssue',
        (data: Result<AlertFromDBModel>) =>
          updateAlert(data, createNewAlertIssue)
      )
      hubConnection.on(
        'BroadcastAlert_clonedIssue',
        (data: Result<AlertFromDBModel>) =>
          updateAlert(data, createClonedAlertIssue)
      )
      hubConnection.on(
        'BroadcastAlert_share',
        (data: Result<AlertFromDBModel>) => updateAlert(data, shareAlert)
      )
      break

    // Inbox
    case ADD_INBOX.REQUEST:
      hubConnection
        .invoke('SendInboxAdd', action.payload.params)
        .catch((err) => console.error(err.toString()))
      break
    case UPDATE_INBOX.REQUEST:
      hubConnection
        .invoke('SendInboxUpdate', action.payload.params)
        .catch((err) => console.error(err.toString()))
      break
    case DELETE_INBOX.REQUEST:
      hubConnection
        .invoke('SendInboxDelete', action.payload.params)
        .catch((err) => console.error(err.toString()))
      break

    // Alert

    case PIN_ALERT.REQUEST:
    case UNPIN_ALERT.REQUEST:
    case MARK_REVIEWED_ALERT.REQUEST:
    case MARK_NOT_REVIEWED_ALERT.REQUEST:
    case CREATE_NEW_ALERT_ISSUE.REQUEST:
    case ADD_ALERT_TO_ISSUE.REQUEST:
    case CREATE_CLONED_ALERT_ISSUE.REQUEST:
      hubConnection
        .invoke('SendAlertUpdate', action.payload.params)
        .catch((err) => console.error(err.toString()))
      break
    case SHARE_ALERT.REQUEST:
      hubConnection
        .invoke('SendShareAlert', action.payload.params)
        .catch((err) => console.error(err.toString()))
      break
    case ALERT_ASSESSMENT.REQUEST:
      hubConnection
        .invoke('SaveSubmitAlertAssessment', action.payload.params)
        .catch((err) => console.error(err.toString()))
      break

    default:
      break
  }

  function doInboxUpdate(
    data: Result<InboxModel>,
    actionCreators: AsyncTypeActions
  ) {
    if (!data.ErrorMessage) {
      if (!data.returnData) return
      data.returnData.feeds = getCleanArray(data.returnData?.feeds)
      let normalizedData = normalize(data.returnData, inboxSchema)
      const inbox: InboxModel = (normalizedData.entities?.inboxes || {})[
        normalizedData.result
      ]

      objDatePropsToUTC(
        inbox,
        'created',
        'lastAlertCreated',
        'searchScheduleTime',
        'searchLastDate'
      )
      batch(() => {
        storeAPI.dispatch(actionCreators.success(inbox))
      })
    } else {
      storeAPI.dispatch(
        actionCreators.failure(data.ErrorMessage, data.source, data.source)
      )
    }
  }

  function updateAlert(
    data: Result<AlertFromDBModel>,
    actionCreators: AsyncTypeActions
  ) {
    if (!data.ErrorMessage) {
      const result = data.returnData
      if (!result) return
      objDatePropsToUTC(result, 'created', 'alert_Updated')
      storeAPI.dispatch(
        actionCreators.success(result, data.source, data.source)
      )
    } else
      storeAPI.dispatch(
        actionCreators.failure(
          data.ErrorMessage,
          data.source,
          data.source,
          data.source
        )
      )
  }
}

//chatHub.connectedOnly = true;
