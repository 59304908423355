// @ts-nocheck
// TODO: Typescript
import {
  generateResetTimeoutMap,
  removeKeyFromObject,
  getAppName,
} from './objectUtils'
import PropTypes from 'prop-types'

export type ReducerActionMap = { [type: string]: Function }
export type DefaultPayload = {
  params?: any
  source?: any
  result?: any
  error?: any
}
export interface ReducerAction<PAYLOAD = DefaultPayload> {
  type: string
  payload: PAYLOAD
}

export function callAction(mapped, state, action) {
  return (
    mapped[action.type] ||
    function () {
      return state
    }
  )()
}

export const MS_GRAPH_REQUEST_FAILURE = 'MS_GRAPH_REQUEST_FAILURE'
export function msGraphRequestFailure(error, source) {
  return {
    type: MS_GRAPH_REQUEST_FAILURE,
    payload: {
      error,
      source,
    },
  }
}

const appAsyncPrefix = 'ASYNC_ACTION' // marks as an async action
const appName = getAppName()
export const requestStatus = {
  REQUEST: 'STATUS_REQUEST',
  LOADING: 'STATUS_LOADING',
  SUCCESS: 'STATUS_SUCCESS',
  FAILURE: 'STATUS_FAILURE',
  COMPLETE: 'STATUS_COMPLETE',
}

export interface AsyncActionType {
  ACTION: string
  REQUEST: string
  SUCCESS: string
  FAILURE: string
  LOADING: string
  COMPLETE: string
}
/**
 * @param {string} suffixOrAction - The action namespace
 * @param {string} action - The action verb
 * @returns MyNewType
 */
export function generateTypes(suffixOrAction, action?) {
  if (!suffixOrAction)
    throw new Error('A suffixOrAction is required for generateTypes()')

  if (!action) action = ''
  else action += '_'

  const ACTION = `${appName}/${appAsyncPrefix}/${action}/${suffixOrAction}`

  return {
    ACTION,
    REQUEST: `${ACTION}/${requestStatus.REQUEST}`,
    SUCCESS: `${ACTION}/${requestStatus.SUCCESS}`,
    FAILURE: `${ACTION}/${requestStatus.FAILURE}`,
    LOADING: `${ACTION}/${requestStatus.LOADING}`,
    COMPLETE: `${ACTION}/${requestStatus.COMPLETE}`,
  } as AsyncActionType
}

//export type AsyncTypes = ReturnType<typeof generateTypes>;

export interface MapWithIds<MAP = any> {
  idMap: MAP
  ids: (keyof MAP)[]
}

/**
 * @param {any} actionTypes - The action namespace
 * @param {any} icoRequestIDKey - The key name or a function to get the request ID for uniquely identifing request
 */
export function generateActions<RESULT = any>(
  actionTypes: AsyncActionType,
  icoRequestIDKey?: ((options: { params: any; source: any }) => any) | string
) {
  function getSource(result: RESULT, params: any, source: any) {
    if (!icoRequestIDKey) return source

    result = result || {}
    params = params || {}
    source = source || {}

    if (typeof icoRequestIDKey === 'string') {
      return {
        ...source,
        icoRequestId:
          result[icoRequestIDKey] ||
          params[icoRequestIDKey] ||
          source[icoRequestIDKey],
      }
    }

    return {
      ...source,
      icoRequestId: icoRequestIDKey({ params, source }),
    }
  }

  return {
    request: function (params?: any, source?: any) {
      return {
        type: actionTypes.REQUEST,
        payload: {
          params,
          source: getSource(undefined, params, source),
        },
      }
    },
    loading: function (result?: RESULT, params?: any, source?: any) {
      return {
        type: actionTypes.LOADING,
        payload: {
          result,
          params,
          source: getSource(result, params, source),
        },
      }
    },
    success: function (result?: RESULT, params?: any, source?: any) {
      return {
        type: actionTypes.SUCCESS,
        payload: {
          result,
          params,
          source: getSource(result, params, source),
        },
      }
    },
    failure: function (
      error?: any,
      result?: RESULT,
      params?: any,
      source?: any
    ) {
      return {
        type: actionTypes.FAILURE,
        payload: {
          error,
          result,
          params,
          source: getSource(undefined, params, source),
        },
      }
    },
    withGraphFailure: function (error?: any, params?: any, source?: any) {
      return [
        {
          type: actionTypes.FAILURE,
          payload: {
            error,
            params,
            source: getSource(undefined, params, source),
          },
        },
        msGraphRequestFailure(error, source),
      ]
    },
    complete: function (params?: any, source?: any) {
      return {
        type: actionTypes.COMPLETE,
        payload: {
          params,
          source: getSource(undefined, params, source),
        },
      }
    },
  }
}

export type AsyncTypeActions = ReturnType<typeof generateActions>

export function prefixWithApp(name) {
  return `${appName}/${name}`
}

/**
 *
 * @param {string} asyncAction
 */
export function parseAsyncAction(asyncAction) {
  if (asyncAction) {
    const lastSlash = asyncAction.lastIndexOf('/')
    const action = asyncAction.substr(0, lastSlash)
    const status = asyncAction.substr(lastSlash + 1)

    if (status.startsWith('STATUS_')) {
      return {
        action,
        status,
      }
    }
  }

  return {
    action: asyncAction,
  }
}

/**
 * Determines if an action's type follows
 * our standard for being async. This helps
 * with determining the state of the application
 * @param {string} actionType
 */
export function isAsyncAction(actionType) {
  return actionType.startsWith(`${appName}/${appAsyncPrefix}`)
}

export interface CrudTypeOptions {
  PREFIX?: string
  ADD?: AsyncActionType
  ADD_RANGE?: AsyncActionType
  ADD_RELATED?: AsyncActionType
  DELETE?: AsyncActionType
  FETCH?: AsyncActionType
  INIT?: AsyncActionType
  UPDATE?: AsyncActionType
  UPDATE_RANGE?: AsyncActionType
  DELETE_RANGE?: AsyncActionType
  REORDER?: AsyncActionType
}

export function generateCrudTypes(prefix) {
  return {
    PREFIX: prefix,
    ADD: generateTypes(prefix, 'ADD'),
    ADD_RANGE: generateTypes(prefix, 'ADD_RANGE'),
    ADD_RELATED: generateTypes(prefix, 'ADD_RELATED'),
    DELETE: generateTypes(prefix, 'DELETE'),
    FETCH: generateTypes(prefix, 'FETCH'),
    INIT: generateTypes(prefix, 'INIT'), // The initial state when the app is first loaded
    UPDATE: generateTypes(prefix, 'UPDATE'),
    UPDATE_RANGE: generateTypes(prefix, 'UPDATE_RANGE'),
    DELETE_RANGE: generateTypes(prefix, 'DELETE_RANGE'),
    REORDER: generateTypes(prefix, 'REORDER'),
  }
}

export function generateCrudActions<RESULT = any>(prefix) {
  const types = generateCrudTypes(prefix)
  type RESULT_MAP = { [key: any]: RESULT }

  return {
    // Create/Add
    /**
     * @param {any} result - Input entity
     * @param {any} source - Input source that has relational information or input information like an icoRequestId
     */
    add: function (result: RESULT, source?: any) {
      return {
        type: types.ADD.REQUEST,
        payload: {
          result,
          source,
        },
      }
    },
    /**
     * @param {any} result - Result entity
     * @param {any} source - Result source that has relational information or information like an icoRequestId
     *                      to map back to the initial request
     */
    addSuccess: function (result: RESULT, source?: any) {
      return {
        type: types.ADD.SUCCESS,
        payload: {
          result,
          source,
        },
      }
    },
    /**
     * @param {string} ErrorMessage
     * @param {any} result - Input entity
     * @param {any} source - Input source that has relational information or input information like an icoRequestId
     */
    addFailure: function (ErrorMessage, result: RESULT, source?: any) {
      return {
        type: types.ADD.FAILURE,
        payload: {
          ErrorMessage,
          result,
          source,
        },
      }
    },

    /**
     * @param {any} result - Input entity
     * @param {any} source - Source has information such as relational information, icoRequestId for mapping the initial request,
     *                      or progress information
     */
    addLoading: function (result: RESULT, source?: any) {
      return {
        type: types.ADD.LOADING,
        payload: {
          result,
          source,
        },
      }
    },
    /**
     * @param {any} result - Input entity
     * @param {any} source - Input source that has relational information or input information like an icoRequestId
     */
    addComplete: function (result: RESULT, source?: any) {
      return {
        type: types.ADD.COMPLETE,
        payload: {
          result,
          source,
        },
      }
    },

    /**
     * @param {any} result - Result entities
     * @param {any} source - Result source that has relational information or information like an icoRequestId
     *                      to map back to the initial request
     */
    addRange: function (result: RESULT_MAP, source?: any) {
      return {
        type: types.ADD_RANGE.REQUEST,
        payload: {
          result,
          source,
        },
      }
    },

    /**
     * @param {any} result - Result entities
     * @param {any} source - Result source that has relational information or information like an icoRequestId
     *                      to map back to the initial request
     */
    addRangeSuccess: function (result: RESULT_MAP, source?: any) {
      return {
        type: types.ADD_RANGE.SUCCESS,
        payload: {
          result,
          source,
        },
      }
    },

    /**
     * @param {string} ErrorMessage
     * @param {any} result - Input entities
     * @param {any} source - Input source that has relational information or input information like an icoRequestId
     */
    addRangeFailure: function (result: RESULT_MAP, source?: any) {
      return {
        type: types.ADD_RANGE.FAILURE,
        payload: {
          result,
          source,
        },
      }
    },

    /**
     * @param {any} result - Input entities
     * @param {any} source - Source has information such as relational information, icoRequestId for mapping the initial request,
     *                      or progress information
     */
    addRangeLoading: function (result: RESULT_MAP, source?: any) {
      return {
        type: types.ADD_RANGE.LOADING,
        payload: {
          result,
          source,
        },
      }
    },

    /**
     * @param {any} result - Input entities
     * @param {any} source - Input source that has relational information or input information like an icoRequestId
     */
    addRangeComplete: function (result: RESULT_MAP, source?: any) {
      return {
        type: types.ADD_RANGE.COMPLETE,
        payload: {
          result,
          source,
        },
      }
    },

    addRelatedIdsSuccess: function (children, source?: any) {
      return {
        type: types.ADD_RELATED.SUCCESS,
        payload: {
          children,
          source,
        },
      }
    },

    // Delete/Remove
    /**
     * @param {any} result - Input entity
     * @param {any} source - Input source that has relational information or input information like an icoRequestId
     */
    delete: function (result: RESULT, source?: any) {
      return {
        type: types.DELETE.REQUEST,
        payload: {
          result,
          source,
        },
      }
    },
    /**
     * @param {any} result - Result entity
     * @param {any} source - Result source that has relational information or information like an icoRequestId
     *                      to map back to the initial request
     */
    deleteSuccess: function (result: RESULT, source?: any) {
      return {
        type: types.DELETE.SUCCESS,
        payload: {
          result,
          source,
        },
      }
    },
    /**
     * @param {any} result - Result entities (ids usually)
     * @param {any} source - Result source that has relational information or information like an icoRequestId
     *                      to map back to the initial request
     */
    deleteRangeSuccess: function (result: RESULT_MAP, source?: any) {
      return {
        type: types.DELETE_RANGE.SUCCESS,
        payload: {
          result,
          source,
        },
      }
    },
    /**
     * @param {string} ErrorMessage
     * @param {any} result - Input entity
     * @param {any} source - Input source that has relational information or input information like an icoRequestId
     */
    deleteFailure: function (ErrorMessage, result: RESULT, source?: any) {
      return {
        type: types.DELETE.FAILURE,
        payload: {
          ErrorMessage,
          result,
          source,
        },
      }
    },
    /**
     * @param {any} result - Input entity
     * @param {any} source - Source has information such as relational information, icoRequestId for mapping the initial request,
     *                      or progress information
     */
    deleteLoading: function (result: RESULT, source?: any) {
      return {
        type: types.DELETE.LOADING,
        payload: {
          result,
          source,
        },
      }
    },
    /**
     * @param {any} result - Input entity
     * @param {any} source - Input source that has relational information or input information like an icoRequestId
     */
    deleteComplete: function (result: RESULT, source?: any) {
      return {
        type: types.DELETE.COMPLETE,
        payload: {
          result,
          source,
        },
      }
    },

    // init/Get
    /**
     * @param {any} result - Input entity - This is typically used to specify path variables as an entity
     * @param {any} source - Input source that has relational information or input information like an icoRequestId
     */
    init: function (result: RESULT, source?: any) {
      return {
        type: types.INIT.REQUEST,
        payload: {
          result,
          source,
        },
      }
    },

    // init/success
    /**
     * @param {any} result - Result entities
     * @param {any} source - Result source that has relational information or information like an icoRequestId
     *                      to map back to the initial request
     */
    initSuccess: function (result: RESULT, source?: any) {
      return {
        type: types.INIT.SUCCESS,
        payload: {
          result,
          source,
        },
      }
    },
    /**
     * @param {string} ErrorMessage
     * @param {any} result - Input entity, the entity that was used for the initial request
     * @param {any} source - Input source that has relational information or input information like an icoRequestId
     */
    initFailure: function (ErrorMessage, result: RESULT, source?: any) {
      return {
        type: types.INIT.FAILURE,
        payload: {
          ErrorMessage,
          result,
          source,
        },
      }
    },
    /**
     * @param {any} result - Input entity - This is typically used to specify path variables as an entity
     * @param {any} source - Source has information such as relational information, icoRequestId for mapping the initial request,
     *                      or progress information
     */
    initLoading: function (result: RESULT, source?: any) {
      return {
        type: types.INIT.LOADING,
        payload: {
          result,
          source,
        },
      }
    },

    // fetch/Get
    /**
     * @param {any} result - Input entity - This is typically used to specify path variables as an entity
     * @param {any} source - Input source that has relational information or input information like an icoRequestId
     */
    fetch: function (result: RESULT, source?: any) {
      /*let payload = undefined;
            if (typeof pathParamsOrId === 'object') {
                payload = {
                    pathParams: pathParamsOrId,
                    source
                };
            }
            else {
                payload = {
                    id: pathParamsOrId,
                    source
                };
            }
            */
      return {
        type: types.FETCH.REQUEST,
        payload: {
          result,
          source,
        },
      }
    },

    /**
     * @param {any} result - Result entities
     * @param {any} source - Result source that has relational information or information like an icoRequestId
     *                      to map back to the initial request
     */
    fetchSuccess: function (result: RESULT_MAP, source?: any) {
      return {
        type: types.FETCH.SUCCESS,
        payload: {
          result,
          source,
        },
      }
    },
    /**
     * @param {string} ErrorMessage
     * @param {any} result - Input entity, the entity that was used for the initial request
     * @param {any} source - Input source that has relational information or input information like an icoRequestId
     */
    fetchFailure: function (ErrorMessage, result: RESULT, source?: any) {
      return {
        type: types.FETCH.FAILURE,
        payload: {
          ErrorMessage,
          result,
          source,
        },
      }
    },
    /**
     * @param {any} result - Input entity - This is typically used to specify path variables as an entity
     * @param {any} source - Source has information such as relational information, icoRequestId for mapping the initial request,
     *                      or progress information
     */
    fetchLoading: function (result: RESULT, source?: any) {
      return {
        type: types.FETCH.LOADING,
        payload: {
          result,
          source,
        },
      }
    },
    /**
     * @param {any} result - Input entity
     * @param {any} source - Input source that has relational information or input information like an icoRequestId
     */
    fetchComplete: function (result: RESULT, source?: any) {
      return {
        type: types.FETCH.COMPLETE,
        payload: {
          result,
          source,
        },
      }
    },

    // Update
    /**
     * @param {any} result - Input entity
     * @param {any} source - Input source that has relational information or input information like an icoRequestId
     */
    update: function (result: RESULT, source?: any) {
      return {
        type: types.UPDATE.REQUEST,
        payload: {
          result,
          source,
        },
      }
    },
    /**
     * @param {any} result - Result entity
     * @param {any} source - Result source that has relational information or information like an icoRequestId
     *                      to map back to the initial request
     */
    updateSuccess: function (result: RESULT, source?: any) {
      return {
        type: types.UPDATE.SUCCESS,
        payload: {
          result,
          source,
        },
      }
    },
    /**
     * @param {string} ErrorMessage
     * @param {any} result - Input entity
     * @param {any} source - Input source that has relational information or input information like an icoRequestId
     */
    updateFailure: function (ErrorMessage, result: RESULT, source?: any) {
      return {
        type: types.UPDATE.FAILURE,
        payload: {
          ErrorMessage,
          result,
          source,
        },
      }
    },
    /**
     * @param {any} result - Input entity - This is typically used to specify path variables as an entity
     * @param {any} source - Source has information such as relational information, icoRequestId for mapping the initial request,
     *                      or progress information
     */
    updateLoading: function (result: RESULT, source?: any) {
      return {
        type: types.UPDATE.LOADING,
        payload: {
          result,
          source,
        },
      }
    },
    /**
     * @param {any} result - Input entity
     * @param {any} source - Input source that has relational information or input information like an icoRequestId
     */
    updateComplete: function (result: RESULT, source?: any) {
      return {
        type: types.UPDATE.COMPLETE,
        payload: {
          result,
          source,
        },
      }
    },

    /**
     * @param {any} result - Result entities
     * @param {any} source - Result source that has relational information or information like an icoRequestId
     *                      to map back to the initial request
     */
    updateRangeSuccess: function (result: RESULT_MAP, source?: any) {
      return {
        type: types.UPDATE_RANGE.SUCCESS,
        payload: {
          result,
          source,
        },
      }
    },

    // Reorder
    /**
     * @param {any} result - Input entity
     * @param {any} source - Input source that has relational information or input information like an icoRequestId
     */
    reorder: function (result: RESULT, source?: any) {
      return {
        type: types.REORDER.REQUEST,
        payload: {
          result,
          source,
        },
      }
    },
    /**
     * @param {any} result - Result entity
     * @param {any} source - Result source that has relational information or information like an icoRequestId
     *                      to map back to the initial request
     */
    reorderSuccess: function (result: RESULT, source?: any) {
      return {
        type: types.REORDER.SUCCESS,
        payload: {
          result,
          source,
        },
      }
    },
    /**
     * @param {string} ErrorMessage
     * @param {any} result - Input entity
     * @param {any} source - Input source that has relational information or input information like an icoRequestId
     */
    reorderFailure: function (ErrorMessage, result: RESULT, source?: any) {
      return {
        type: types.REORDER.FAILURE,
        payload: {
          ErrorMessage,
          result,
          source,
        },
      }
    },
    /**
     * @param {any} result - Input entity - This is typically used to specify path variables as an entity
     * @param {any} source - Source has information such as relational information, icoRequestId for mapping the initial request,
     *                      or progress information
     */
    reorderLoading: function (result: RESULT, source?: any) {
      return {
        type: types.REORDER.LOADING,
        payload: {
          result,
          source,
        },
      }
    },
    /**
     * @param {any} result - Input entity
     * @param {any} source - Input source that has relational information or input information like an icoRequestId
     */
    reorderComplete: function (result: RESULT, source?: any) {
      return {
        type: types.REORDER.COMPLETE,
        payload: {
          result,
          source,
        },
      }
    },
  }
}

/**
 * @description Creates an action creator function
 * @param {string} type The action type.
 * @param {array} argNames the keys to attach to the returned action object
 * @return {Function}
 */
export function makeActionCreator(type, ...argNames) {
  return function (...args) {
    const action = { type }
    argNames.forEach((arg, index) => {
      action[argNames[index]] = args[index]
    })
    return action
  }
}

export function prependAppName(obj, freeze) {
  if (typeof obj === 'string') return `${appName}_${obj}`

  for (var key in obj) {
    if (typeof obj[key] === 'object') {
      prependAppName(obj[key], freeze)
    } else {
      // Only prepend the app name if it is not already prepended
      // exclude readonly properties. This is needed for Docz
      if (obj[key].startsWith(appName) || key.startsWith('__')) continue

      obj[key] = appName + '/' + obj[key]
    }
  }

  if (freeze !== false) Object.freeze(obj)

  return obj
}

export function goToIssue(history, params, location = '') {
  var pathname = getIssuePath(params)
  history.push({
    pathname: `${pathname}/${location}`,
  })
}

export function navigateIssueBack(history, params) {
  let path = getIssuePath(params, false)

  // Mark the deepest path part for removal
  let deepIndex = optionalParamKeys.length - 1
  while (deepIndex >= 0) {
    if (params[optionalParamKeys[deepIndex]] !== undefined) break
    deepIndex--
  }

  let optionalPath = []

  // Remove the current optional path
  if (deepIndex > -1) {
    for (let i = 0; i < deepIndex - 1; i++)
      optionalPath.push('/' + params[optionalParamKeys[i]])

    history.push(path + optionalPath.join(''))
  } else if (params.IssueID !== undefined)
    // Go back to the Issue SummaryFeed if the user has an issue selected
    history.push('/Issues')
}

export const RouteParameters = {
  IssueID: PropTypes.string,
  IssueTeamWorkspaceID: PropTypes.string,
  domain: PropTypes.string,
  domainId: PropTypes.string,
  domainItem: PropTypes.string,
  domainItemId: PropTypes.string,
}

const optionalParamKeys = ['domain', 'domainId', 'domainItem', 'domainItemId']
export function getIssuePath(params, includeOptional) {
  const { IssueID, IssueTeamWorkspaceID } = params

  let pathname = `/IssueDetail/${IssueID}/${IssueTeamWorkspaceID || ''}`

  // Return a path without the optional parameters
  if (includeOptional === false) return pathname

  // Keep joining the optional parameters until the list is exhausted or undefined occurs
  let optionalPath = []
  for (let i = 0; i < optionalParamKeys.length; i++) {
    const paramValue = params[optionalParamKeys[i]]
    if (paramValue === undefined) break

    optionalPath.push('/' + paramValue)
  }

  return pathname + optionalPath.join('')
}

export let PendingSubmits = { length: 0 }

/**
 * Will generate a function that will
 * call onChange and onSubmit. This
 * will work for all inputs that get their
 * name and value from the event object.
 * @param {object} component - The react component that the events should be mapped to.
 * @param {number|object} action - The amount of time to wait before calling onSubmit.
 * @param {object} callbackNames - A name map so the user can replace onSubmit and/or onChange with a different name.
 */
export function generateInputChangeSubmit(
  component,
  delay,
  callbackNames = {},
  exclude = {}
) {
  let timedCallbackMap = generateResetTimeoutMap(delay, PendingSubmits)
  return function (eventOrName, value, props) {
    const onChange = (props || component.props)[
      callbackNames.onChange || 'onChange'
    ]
    const onSubmit = (props || component.props)[
      callbackNames.onSubmit || 'onSubmit'
    ]
    let name = undefined

    if (typeof eventOrName === 'object') {
      name = eventOrName.target.name
      value = eventOrName.target.value
    } else if (typeof eventOrName === 'string') {
      name = eventOrName
    }

    if (onSubmit && !exclude.onSubmit) {
      if (!delay) onSubmit(name, value, props || component.props)
      else {
        timedCallbackMap(name)(function () {
          onSubmit(name, value, props || component.props)
        })
      }
    }

    if (onChange && !exclude.onChange)
      onChange(name, value, props || component.props)
  }
}

/**
 * Will generate a function that will
 * call onChange and onSubmit. This
 * will work for all inputs that get their
 * name and value from the event object.
 * @param {object} component - The react component that the events should be mapped to.
 * @param {number|object} action - The amount of time to wait before calling onSubmit.
 * @param {object} callbackName - A name map so the user can replace onSubmit a different name.
 */
export function generateInputSubmit(component, delay, callbackName) {
  return generateInputChangeSubmit(
    component,
    delay,
    { onSubmit: callbackName },
    { onChange: true }
  )
}

/**
 * Will generate a function that will
 * call onChange and onSubmit. This
 * will work for Material UI date pickers.
 * @param {object} component - The react component that the events should be mapped to.
 * @param {number|object} action - The amount of time to wait before calling onSubmit.
 * @param {object} callbackNames - A name map so the user can replace onSubmit and/or onChange with a different name.
 */
export function generateDatePickerChangeSubmit(
  component,
  delay,
  callbackNames = {}
) {
  let timedCallbackMap = generateResetTimeoutMap(delay, PendingSubmits)

  return function (dateUtil, name, dateValue, timeValue) {
    const onChange = component.props[callbackNames.onChange || 'onChange']
    const onSubmit = component.props[callbackNames.onSubmit || 'onSubmit']

    if (dateUtil && dateUtil.isValid()) {
      if (onChange) onChange(name, new Date(dateValue), component.props)

      if (onSubmit) {
        timedCallbackMap(name)(function () {
          onSubmit(name, new Date(dateValue), component.props)
        })
      }
    } else if (!dateUtil) {
      if (onChange) onChange(name, null, component.props)

      timedCallbackMap(name)(function () {
        onSubmit(name, null, component.props)
      })
    }
  }
}

export const reducerCrud = {
  create: function (state, action, key, entity?, replace?) {
    var newRecord = entity || action.payload.result
    var oldRecord = state[newRecord[key]]

    if (!replace) {
      return {
        ...state,
        [newRecord[key]]: { ...oldRecord, ...newRecord },
      }
    }

    return {
      ...state,
      [newRecord[key]]: newRecord,
    }
  },
  createRange: function (state, action, key, entities) {
    return this.updateRange(state, action, key, entities, true)
  },
  read: function (state, action, addedDateKey?) {
    const entities = action.payload.result
    if (!addedDateKey) return entities

    /* Merge the new list with the current list.
     * does a date check to prevent the new list from
     * overwriting newly added entries
     */
    let latestDate = 0
    for (let key in entities) {
      let curObj = entities[key]
      if (curObj[addedDateKey] & (curObj[addedDateKey].getTime() > latestDate))
        latestDate = curObj[addedDateKey].getTime()
    }

    // Add the appropriate new rows when a new load is performed. This prevents old data from being overridden.
    for (let key in state) {
      let curObj = state[key]
      if (
        curObj[addedDateKey] &&
        curObj[addedDateKey] >= latestDate &&
        !entities[key]
      )
        entities[key] = curObj
    }

    return entities
  },
  reset: function (state, id) {
    var curEntity = state[id]
    if (curEntity) {
      return {
        ...state,
        [id]: curEntity,
      }
    }

    return state
  },
  update: function (state, action, key, entity?, replace?) {
    return this.create(state, action, key, entity, replace)
  },
  updateRange: function (state, action, key, entities?, mergeOld?) {
    if (mergeOld === undefined) mergeOld = true

    let getKey = key
    if (typeof getKey !== 'function') getKey = (record) => record[key]

    entities = action.payload.result
    if (!entities) return state

    var newState = {}

    if (entities instanceof Array) {
      entities.forEach((newRecord) => {
        addEntity(newRecord)
      })
    } else {
      for (var entityId in entities) {
        addEntity(entities[entityId])
      }
    }

    function addEntity(newRecord) {
      const id = getKey(newRecord)
      if (id !== undefined) {
        const oldRecord = state[id]
        newState[id] = { ...oldRecord, ...newRecord }
      }
    }

    if (mergeOld) {
      return {
        ...state,
        ...newState,
      }
    }

    return newState
  },
  delete: function (state, action, key) {
    return removeKeyFromObject(state, action.payload.result[key])
  },
  deleteIdRange: function (state, ids, key?) {
    if (!ids) return state

    if (!state) return state

    var newState = {}
    let idMap = ids
    var getId = function (row) {
      return row
    }

    if (key) {
      getId = function (row) {
        return row[key]
      }
    }

    if (ids instanceof Array) {
      idMap = {}
      ids.forEach((id) => (idMap[getId(id)] = true))
    }

    for (var entityId in state) {
      if (!idMap[entityId]) {
        newState[entityId] = state[entityId]
      }
    }

    return newState
  },
  // Delete from a supporting state based on the source state
  deleteIdRangeFromExtMap: function (
    sourceState,
    targetState,
    targetKey,
    sourceIds,
    sourceKey?
  ) {
    if (!sourceIds) return targetState

    if (!sourceState) return targetState

    var newState = {}
    let sourceIdsToDelete = {}

    let getSourceId = function (row) {
      return row
    }

    if (sourceKey) {
      getSourceId = function (row) {
        return row[sourceKey]
      }
    }

    if (sourceIds instanceof Array) {
      sourceIds.forEach((id) => {
        const sourceRow = sourceState[getSourceId(id)]
        sourceIdsToDelete[sourceRow[targetKey]] = true
      })
    } else {
      for (var key in sourceIds) {
        const sourceRow = sourceState[getSourceId(key)]
        sourceIdsToDelete[sourceRow[targetKey]] = true
      }
    }

    for (var entityId in targetState) {
      if (!sourceIdsToDelete[entityId]) {
        newState[entityId] = targetState[entityId]
      }
    }

    return newState
  },
  replaceChildren: function (state, action, parentKey, childIdsKey) {
    var { source } = action.payload
    const parentId = source[parentKey]
    const childIds = source[childIdsKey]
    var parent = state[parentId]
    return {
      ...state,
      [parentId]: {
        ...parent,
        [childIdsKey]: childIds,
      },
    }
  },
  addChild: function (state, action, parentKey, childKey, arrayKey) {
    const childId = action.payload.result[childKey]
    try {
      const parentId =
        (action.payload.source && action.payload.source[parentKey]) ||
        (action.payload.result && action.payload.result[parentKey])
      let parent = state[parentId] || {}
      let childArray = parent[arrayKey] || []
      if (childArray.indexOf(childId) > -1) return state

      return {
        ...state,
        [parentId]: {
          ...parent,
          [arrayKey]: childArray.concat(childId),
        },
      }
    } catch (error) {
      console.log(error)
    }
  },
  addChildIdRange: function (state, action, parentKey, arrayKey, childIds?) {
    try {
      const parentId =
        (action.payload.source && action.payload.source[parentKey]) ||
        (action.payload.result && action.payload.result[parentKey])
      childIds = childIds || action.payload.children
      var parent = state[parentId]
      const parentArray = parent[arrayKey] || []
      let hasId = {}
      parentArray.forEach((id) => (hasId[id] = true))
      childIds = childIds.filter((id) => !hasId[id])

      return {
        ...state,
        [parentId]: {
          ...parent,
          [arrayKey]: [...parentArray, ...childIds],
        },
      }
    } catch (error) {
      console.log(error)
    }
  },
  prependChildIdRange: function (state, action, parentKey, arrayKey) {
    try {
      const parentId =
        (action.payload.source && action.payload.source[parentKey]) ||
        (action.payload.result && action.payload.result[parentKey])

      var parent = state[parentId]

      return {
        ...state,
        [parentId]: {
          ...parent,
          [arrayKey]: [...action.payload.children, ...(parent[arrayKey] || [])],
        },
      }
    } catch (error) {
      console.log(error)
    }
  },
  prependChild: function (state, action, parentKey, childKey, arrayKey) {
    try {
      const childId = action.payload.result[childKey]
      const parentId =
        (action.payload.source && action.payload.source[parentKey]) ||
        (action.payload.result && action.payload.result[parentKey])

      var parent = state[parentId]

      return {
        ...state,
        [parentId]: {
          ...parent,
          [arrayKey]: [childId, ...parent[arrayKey]],
        },
      }
    } catch (error) {
      console.log(error)
    }
  },
  deleteChild: function (state, action, parentKey, childKey, arrayKey) {
    try {
      let parentId =
        (action.payload.source && action.payload.source[parentKey]) ||
        (action.payload.result && action.payload.result[parentKey])
      const childId = action.payload.result && action.payload.result[childKey]

      var removeMap = {}
      if (!(parentId instanceof Array)) parentId = [parentId]
      parentId.forEach((id) => {
        const parent = state[id]
        if (!parent) return
        removeMap[id] = {
          ...parent,
          [arrayKey]: (parent[arrayKey] || []).filter((id) => id !== childId),
        }
      })

      return {
        ...state,
        ...removeMap,
      }
    } catch {
      console.log(error)
    }
  },
  deleteChildRange: function (state, action, parentKey, childIds, arrayKey) {
    if (!action.payload.source)
      throw new Error(
        "deleteChildRange: Trying to delete from from '" +
          arrayKey +
          "' action.payload.source is undefined unable to source parentKey: '" +
          parentKey +
          "'."
      )

    const parentId = action.payload.source[parentKey]
    var parent = state[parentId]
    var childIdMap = {}
    childIds.forEach((id) => {
      childIdMap[id] = true
    })
    return {
      ...state,
      [parentId]: {
        ...parent,
        [arrayKey]: (parent[arrayKey] || []).filter((id) => !childIdMap[id]),
      },
    }
  },
  mapChildrenToParents: function (
    parentEntities,
    childIdKey,
    parentCallback,
    storeParentIdOnly = true
  ) {
    if (!childIdKey)
      throw new Error('childIdKey is required for mapChildrenToParents')

    parentEntities = parentEntities || {}

    let childToParentMap = {}
    for (let parentId in parentEntities) {
      const curParent = parentEntities[parentId]
      const children = curParent[childIdKey] || []
      for (let i = 0; i < children.length; i++)
        childToParentMap[children[i]] = parentCallback
          ? parentCallback(curParent)
          : storeParentIdOnly
          ? parentId
          : curParent
    }
    return childToParentMap
  },
  /**
   * Will update a sort field in each object in an entity map
   * based on the specified field.
   * @param {object} entityMap - The entity map
   * @param {object} updatedEntity - The entity that has been updated
   * @param {string} primaryKey - The name of the key for the id of the entity
   * @param {string} sequenceKey - The name of the key that is used for maintaining the order
   */
  reorderMapField: function (
    entityMap,
    updatedEntity,
    primaryKey,
    sequenceKey
  ) {
    const entityKey = updatedEntity[primaryKey].toString()

    // The new entity map
    let newMap = {}

    // Place the updated entity in the new map
    newMap[entityKey] = { ...entityMap[entityKey], ...updatedEntity }

    // Loop through the map of existing entities and update the sequene
    // based on the updatedEntity
    for (var key in entityMap) {
      if (key !== entityKey) {
        newMap[key] = { ...entityMap[key] }
        let curEntity = newMap[key]

        if (curEntity[sequenceKey] >= updatedEntity[sequenceKey]) {
          curEntity[sequenceKey] = curEntity[sequenceKey] + 1
        }
      }
    }

    return newMap
  },
  /**
   * Will update a sort field in each object in an entity map
   * based on the specified field.
   * @param {object} state - Reducer state should be a map of entities
   * @param {object} action - The reducer action
   * @param {string} primaryKey - The name of the key for the id of the entity
   * @param {string} sequenceKey - The name of the key that is used for maintaining the order
   */
  reorderState: function (state, action, primaryKey, sequenceKey) {
    const entity = {
      ...state[action.payload.result[primaryKey]],
      ...action.payload.result,
    }
    const entityKey = entity[primaryKey]
    const { itemIds, dir, destIndex } = action.payload.source || {}

    // more than 1 items must be present for a reorder to make sense
    if (!itemIds || itemIds.length <= 1) return state

    // Get the sequence by index only if it is not present in the payload
    if (action.payload.result[sequenceKey] === undefined) {
      const destEntity = state[itemIds[destIndex]] || {}
      entity[sequenceKey] = destEntity[sequenceKey]
    }

    // Set the rest of the sequences
    let newState = {
      ...state,
    }
    newState[entity[primaryKey]] = entity

    //let entityStartSeq = entity[sequenceKey];
    for (let i = 0; i < itemIds.length; i++) {
      // Increment all sequences that come after the updated entity (same logic as database)
      if (entityKey !== itemIds[i]) {
        let curEntity = state[itemIds[i]]
        if (dir === 'bottom' && curEntity[sequenceKey] <= entity[sequenceKey])
          curEntity = {
            ...curEntity,
            [sequenceKey]: curEntity[sequenceKey] - 1,
          }
        else if (dir === 'top' && curEntity[sequenceKey] >= entity[sequenceKey])
          curEntity = {
            ...curEntity,
            [sequenceKey]: curEntity[sequenceKey] + 1,
          }

        // Store the final curEntity result
        newState[curEntity[primaryKey]] = curEntity
      }
    }

    return newState
  },
}
