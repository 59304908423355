// @ts-nocheck
// TODO: Typescript
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import MsChannelOption from './MsChannelOption'
import { selectMSChannelIdsByTeamId, selectMSTeamNameById } from './_selectors'

/**
 * @extends {Component<Props, {}>}}
 * Renders an OptGroup for a MS Team
 */
export class MsTeamOptionGroup extends Component {
  render() {
    const { channelIds, teamName } = this.props
    return (
      <optgroup label={teamName}>
        {channelIds.map((id) => (
          <MsChannelOption key={id} channelId={id} />
        ))}
      </optgroup>
    )
  }
}

MsTeamOptionGroup.propTypes = {
  teamId: PropTypes.string,
  teamName: PropTypes.string,
  channelIds: PropTypes.arrayOf(PropTypes.string),
}

MsTeamOptionGroup.defaultProps = {
  channelIds: [],
}

const mapStateToProps = (state, ownProps) => {
  const { teamId } = ownProps

  return {
    teamId,
    teamName: selectMSTeamNameById(state, teamId),
    channelIds: selectMSChannelIdsByTeamId(state, teamId),
  }
}

export default connect(mapStateToProps, undefined)(MsTeamOptionGroup)
