import React from 'react'
import { TableRow, TableCell } from '@material-ui/core'
import classes from './StakeholderList.module.scss'
import { selectStakeholderById } from '../_rootConfigs/rootSelectors'
import dayjs from 'dayjs'
import dayjsPluginUTC from 'dayjs/plugin/utc'
import { useAppDispatch, useAppSelector } from '../_utils/reactReduxHooks'
import { isMobileApp } from '../_selectors'
import { Button } from '../Shared/Buttons'
import { Spacer } from '../Shared/Spacer'
import { StakeholderActions } from './_actions'
import { cx } from '../_utils/objectUtils'

dayjs.extend(dayjsPluginUTC)

interface Props {
  disabled?: boolean
  StakeholderID: number
  IssueID: number
  onDeleteClick: (StakeholderID: number) => void
  onEditClick: (StakeholderID: number) => void
  openNotifications: (stakeholderID: number) => void
}

export const StakeholderListItem = ({
  StakeholderID,
  IssueID,
  disabled,
  onDeleteClick,
  onEditClick,
  openNotifications,
}: Props) => {
  const isMobile = useAppSelector(isMobileApp)
  const stakeholder = useAppSelector((state) =>
    selectStakeholderById(state, StakeholderID)
  )

  const dispatch = useAppDispatch()

  return (
    <>
      <TableRow
        onClick={() =>
          dispatch(StakeholderActions.setCurrentStakeholderID(StakeholderID))
        }
        className={classes.listItem}
      >
        <TableCell className={cx(classes.nameColumn, classes.linkColor)}>
          {stakeholder.Audience}
        </TableCell>
        <TableCell className={classes.responsibleColumn}>
          {stakeholder.responsibleParticipant ? (
            stakeholder.responsibleParticipant
          ) : (
            <Spacer />
          )}
        </TableCell>
        <TableCell
          onClick={(e) => e.stopPropagation()}
          className={classes.lastNotifiedColumn}
        >
          {stakeholder.lastNotified ? (
            <Button
              color="link"
              onClick={() => openNotifications(StakeholderID)}
              style={{ fontSize: '14px' }}
            >
              {dayjs
                .utc(stakeholder.lastNotified)
                .local()
                .format(isMobile ? 'M/DD/YY' : 'M/DD/YY hh:mm a')}
            </Button>
          ) : (
            <Spacer />
          )}
        </TableCell>
        <TableCell className={classes.actionsColumn}>
          <Button
            color="standard"
            onClick={(e) => {
              e.stopPropagation()
              onEditClick(StakeholderID)
            }}
            className={classes.editButton}
          >
            Edit
          </Button>
          <Button
            color="standard"
            onClick={(e) => {
              e.stopPropagation()
              onDeleteClick(StakeholderID)
            }}
            className={classes.deleteButton}
          >
            Delete
          </Button>
        </TableCell>
      </TableRow>
    </>
  )
}
