import React from 'react'
import { CSSProperties } from 'react'

const style: CSSProperties = {
  top: 0,
  left: 0,
  width: 1,
  height: 1,
  zIndex: -1,
  //visibility: 'hidden',
  position: 'absolute',
}

export const AutoFocus = () => (
  <input type="text" style={style} autoFocus={true} />
)
