import { faTrashAlt as faTrash } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Input } from '@material-ui/core'
import React, { ChangeEvent, useState, Fragment } from 'react'
import { ConfirmDialog } from '../Shared/ConfirmDialog'
import { CircularProgress, LoadingBackDrop } from '../Shared/LoadingBackdrop'
import { addNotification } from '../_actions'
import { icoDelete, icoFetch, icoPost, icoPut } from '../_utils/fetchUtils'
import { useAppDispatch } from '../_utils/reactReduxHooks'
import { HoldingStatementTemplateList } from './HoldingStatementTemplateList'
import { useFetchTemplate } from './_Hooks'
import {
  HoldingStatementTemplateModel,
  HoldingStatementTemplateRequest,
} from './_models'
import classes from './HoldingStatementTemplateDialog.module.scss'
import { cx } from '../_utils/objectUtils'

interface Props {
  OrgID: number
  Content: string
  open: boolean
  onClose: () => void
  type: 'load' | 'save'
  onLoadTemplate: (template: HoldingStatementTemplateModel) => void
}

const restEndpoint = 'api/HoldingStatementTemplate'
const rowSize = 81
export const HoldingStatementTemplateDialog = ({
  OrgID,
  Content,
  onClose,
  open,
  type,
  onLoadTemplate,
}: Props) => {
  const [name, setName] = useState('')
  const [existingTemplate, setExistingTemplate] =
    useState<HoldingStatementTemplateModel>()
  const [selectedTemplate, setSelectedTemplate] =
    useState<HoldingStatementTemplateModel>()
  const [processingText, setProcessingText] = useState('')
  const [isDeleteOpen, setIsDeleteOpen] = useState(false)

  // Only fetch if the list is opened
  const fetchRes = useFetchTemplate(OrgID, {
    tracking: [open],
    shouldFetch: (tracking) => tracking[0],
  })

  const dispatch = useAppDispatch()

  function handleChangeName(evt: ChangeEvent<HTMLInputElement>) {
    setName(evt.target.value)
  }

  function handleItemClick(template: HoldingStatementTemplateModel) {
    setSelectedTemplate(template)
    setName(template.Title)
  }

  function handleCreate() {
    handleSave()
  }

  function handleDeleteClick() {
    if (selectedTemplate) setIsDeleteOpen(true)
  }

  function handleClose() {
    setName('')
    setExistingTemplate(undefined)
    setSelectedTemplate(undefined)
    onClose()
  }

  function handleSave(overwrite: boolean = false) {
    let data: HoldingStatementTemplateRequest = {
      Title: name,
      OrgID,
      Content: Content,
    }

    const returnData = fetchRes.data?.returnData

    // See if the template aleady exists
    if (!overwrite && returnData) {
      setExistingTemplate(
        returnData.find(
          (template) =>
            template.Title.toLowerCase() === name.trim().toLocaleLowerCase()
        )
      )
    }

    // overwite existing template
    if (overwrite && existingTemplate) {
      data = {
        HoldingStatementTemplateID: existingTemplate.HoldingStatementTemplateID,
        Content,
      }

      setExistingTemplate(undefined)
      setProcessingText('Updating Template')
      icoPut(restEndpoint, data)
        .then((res) => res.json())
        .then((data) => {
          if (data.Status === 'Failure')
            dispatch(
              addNotification({
                message: data.StatusDescr,
              })
            )
          else {
            dispatch(
              addNotification({
                message: data.StatusDescr,
                type: 'success',
              })
            )
            handleClose()
          }
        })
        .catch((ex) => {
          dispatch(addNotification({ message: ex }))
        })
        .finally(() => {
          setProcessingText('')
        })
    } else if (!existingTemplate) {
      // Try to create new
      setProcessingText('Creating Template')
      setExistingTemplate(undefined)
      icoPost(restEndpoint, data)
        .then((res) => res.json())
        .then((data) => {
          if (data.Status === 'Failure')
            dispatch(
              addNotification({
                message: data.StatusDescr,
              })
            )
          else if (data.Status === 'Warning')
            // Prompt the user with overwrite warning
            setExistingTemplate({
              OrgID,
              Title: name,
              Content,
              HoldingStatementTemplateID: data.HoldingStatementTemplateID,
            } as any)
          else {
            dispatch(
              addNotification({
                message: data.StatusDescr,
                type: 'success',
              })
            )
            handleClose()
          }
        })
        .catch((ex) => {
          dispatch(addNotification({ message: ex }))
        })
        .finally(() => {
          setProcessingText('')
        })
    }
  }

  function handleOverwrite() {
    handleSave(true)
  }

  function handleDelete() {
    if (selectedTemplate) {
      setProcessingText('Deleting Template')
      icoDelete(
        `${restEndpoint}/${selectedTemplate.HoldingStatementTemplateID}`
      )
        .then((res) => res.json())
        .then((data) => {
          if (data.Status === 'Failure')
            dispatch(
              addNotification({
                message: data.StatusDescr,
              })
            )
          else
            dispatch(
              addNotification({
                message: data.StatusDescr,
                type: 'success',
              })
            )

          removeTemplate(selectedTemplate.HoldingStatementTemplateID)
        })
        .catch((ex) => {
          dispatch(addNotification({ message: ex }))
        })
        .finally(() => {
          setProcessingText('')
        })
    } else dispatch(addNotification({ message: 'No template selected' }))
  }

  function handleLoad() {
    if (!selectedTemplate) return

    setProcessingText('Loading Template')
    icoFetch(`${restEndpoint}/${selectedTemplate.HoldingStatementTemplateID}`)
      .then((res) => res.json())
      .then((data) => {
        if (data.Status === 'Failure')
          dispatch(
            addNotification({
              message: data.StatusDescr,
            })
          )
        else if (onLoadTemplate) {
          onLoadTemplate(data.returnData)
          dispatch(
            addNotification({
              message: data.StatusDescr,
              type: 'success',
            })
          )
          handleClose()
        }
      })
      .catch((ex) => {
        dispatch(addNotification({ message: ex }))
      })
      .finally(() => {
        setProcessingText('')
      })
  }

  function removeTemplate(templateID: number) {
    const returnData = fetchRes.data?.returnData
    if (!returnData) return

    fetchRes.setData({
      returnData: returnData.filter(
        (template) => template.HoldingStatementTemplateID !== templateID
      ),
    })

    setSelectedTemplate(undefined)
  }

  return (
    <Fragment>
      <LoadingBackDrop
        open={processingText ? true : false}
        text={processingText}
      />
      {type === 'save' && (
        <ConfirmDialog
          open={existingTemplate ? true : false}
          content="A template with this name already exists. Would you like to overwrite it?"
          confirm={handleOverwrite}
          cancel={() => setExistingTemplate(undefined)}
        />
      )}
      <ConfirmDialog
        open={isDeleteOpen}
        content={
          <span>
            Are you <strong>Sure</strong> you wish to <strong>Delete</strong>{' '}
            this <strong>Template</strong>?
          </span>
        }
        confirm={handleDelete}
        confirmText="Yes"
        cancelText="Cancel"
        cancel={() => setIsDeleteOpen(false)}
      />
      <ConfirmDialog
        open={open}
        className={classes.templateDialog}
        title={type === 'save' ? 'Save Template' : 'Load Template'}
        maxWidth="lg"
        content={
          <div className={classes.listCon}>
            {fetchRes.loading ? (
              <CircularProgress />
            ) : (
              <HoldingStatementTemplateList
                OrgID={OrgID}
                selected={selectedTemplate?.HoldingStatementTemplateID}
                onItemClick={handleItemClick}
                rowSize={rowSize}
                items={fetchRes.data?.returnData}
              />
            )}
          </div>
        }
        cancel={handleClose}
        confirm={type === 'save' ? handleCreate : handleLoad}
        autoCloseConfirm={false}
        cancelText="Cancel"
        confirmText={type === 'save' ? 'Save' : 'Load'}
        leftActions={
          <Input
            type="text"
            onChange={handleChangeName}
            placeholder={
              type === 'save' ? 'Enter Template Name' : 'Select a Template'
            }
            className={classes.nameInput}
            disabled={type === 'load'}
            value={name}
          />
        }
        rightActions={
          <FontAwesomeIcon
            className={cx(
              classes.trash,
              !selectedTemplate ? classes.disabled : ''
            )}
            icon={faTrash}
            onClick={handleDeleteClick}
          />
        }
      />
    </Fragment>
  )
}
