import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import CheckboxMUI from '@material-ui/core/Checkbox'
import { cx } from '../_utils/objectUtils'
import { CheckboxProps } from '@material-ui/core'

const useStyles = makeStyles({
  root: {
    color: 'gray',
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '&.MuiCheckbox-colorPrimary.Mui-checked': {
      color: '#3f91c4',
    },
  },
  icon: {
    borderRadius: 3,
    width: 12,
    height: 12,
    backgroundColor: '#f5f8fa',
    '$root.Mui-focusVisible &': {
      outline: '2px auto rgba(19,124,189,.6)',
      outlineOffset: 2,
    },
    'input:hover ~ &': {
      backgroundColor: '#ebf1f5',
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: 'rgba(206,217,224,.5)',
    },
  },
})

interface Props extends CheckboxProps {}

export default function Checkbox(props: Props) {
  const classes = useStyles()
  const { className, checked, ...other } = props

  return (
    <CheckboxMUI
      className={cx(classes.root, className)}
      checked={checked}
      color={props.color ? props.color : 'primary'}
      {...other}
    />
  )
}
