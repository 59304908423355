// @ts-nocheck
// TODO: Typescript
import {
  IssueActions,
  fetchOrgIssueTypesRequest,
  resolveIssueSuccess,
  resolveIssueFailure,
  issueFilterFetch,
  fetchOrgIssueLevelsRequest,
  addIssueActivityLog,
  fetchAppUserOrgs,
  markReadIssueActivityLog,
  fetchLatestActivityLogDates,
  EvidenceLockerLinkActions,
  GeolocationActions,
} from './_actions'
import { WebSocketConstants } from '../_webSocket/_constants'
import {
  IssueConstants,
  RESOLVE_ISSUE,
  IssueTagConstants,
  MARK_READ_ISSUE_ACTIVITY_LOG,
  EvidenceLockerLinkConstants,
  GeolocationConstants,
} from './_constants'
import { JsonParseSafe, containsValues } from '../_utils/objectUtils'
import {
  objDatePropsToUTC,
  dateUTCbuilder,
  objMapDatePropsToUTC,
} from '../_utils/dateUtils'
import { batch } from 'react-redux'
import { addNotification } from '../_actions'
import { IssueTagActions } from '../Issue/_actions'
import { OrgTagActions } from '../Org/_actions'
import { logout, refreshAuthenticationSuccessful } from '../Auth/_actions'
import { AuthConstants } from '../Auth/_constants'
import Push from 'push.js'
import { selectIssueActivityLogChangeDate, selectIssueById } from './_selectors'
import { selectOrgTagById } from '../Org/_selectors'
import {
  doesCurrentRouteRequireIssueData,
  selectUserEmail,
} from '../_rootConfigs/rootSelectors'
import { fetchInboxes } from '../Alert/_actions'
import { updateAppAccount } from '../AppAccount/_actions'
import { fetchIssueDetailsSync } from '../Shared/_requestStatusReducer'
let submitLogItemsDelaySecs = 10
let submitLogItemsDelay = 1000 * submitLogItemsDelaySecs // How long to wait before submitting workspaces
let queuedLogItems = {}
let queuedLogItemIds = []
let logItemsSubmitTimeout = 0

export default function issueHub(storeAPI, action, hubConnection) {
  const { payload } = action
  let { ErrorMessage } = payload || {}
  ErrorMessage = ErrorMessage || ''
  switch (action.type) {
    // Data required on initial connection
    case WebSocketConstants.CONNECTION_RETRY_SUCCESSFUL:
    case WebSocketConstants.CONNECTION_SUCCESSFUL:
    case AuthConstants.REDIRECT_FROM_UNAUTHORIZED:
      // Only fetch data if the current page needs it (Certain pages are only simple error messages or for redirect Azure AD)
      if (
        !doesCurrentRouteRequireIssueData(storeAPI.getState()) &&
        action.type !== AuthConstants.REDIRECT_FROM_UNAUTHORIZED
      )
        return

      let typesAction = fetchOrgIssueTypesRequest()
      typesAction.dontRenewAuth = true

      let orgTagAction = OrgTagActions.fetch()
      orgTagAction.dontRenewAuth = true

      let orgsAction = fetchAppUserOrgs.request()
      orgsAction.dontRenewAuth = true

      let fetchLatestActivityDates = fetchLatestActivityLogDates.request()
      fetchLatestActivityDates.dontRenewAuth = true

      let fetchIssuesAction
      let fetchInboxesAction
      if (
        action.type === WebSocketConstants.CONNECTION_SUCCESSFUL ||
        action.type === AuthConstants.REDIRECT_FROM_UNAUTHORIZED
      ) {
        fetchIssuesAction = IssueActions.init()
        fetchInboxesAction = fetchInboxes.request()
        storeAPI.dispatch(refreshAuthenticationSuccessful())
      } else {
        fetchIssuesAction = IssueActions.fetch()
        fetchInboxesAction = fetchInboxes.request()
        fetchIssuesAction.dontRenewAuth = true
        fetchInboxesAction.dontRenewAuth = true
      }

      if (
        action.type === WebSocketConstants.CONNECTION_RETRY_SUCCESSFUL ||
        action.type === WebSocketConstants.CONNECTION_SUCCESSFUL
      ) {
        storeAPI.dispatch(
          addIssueActivityLog.request({
            lastRequested: selectIssueActivityLogChangeDate(
              storeAPI.getState()
            ),
          })
        )
      }

      batch(() => {
        storeAPI.dispatch(fetchOrgIssueLevelsRequest(1))
        storeAPI.dispatch(issueFilterFetch())
        storeAPI.dispatch(fetchIssuesAction)
        storeAPI.dispatch(typesAction)
        storeAPI.dispatch(orgTagAction)
        storeAPI.dispatch(orgsAction)
        storeAPI.dispatch(fetchInboxesAction)
        storeAPI.dispatch(fetchLatestActivityDates)
      })

      hubConnection.on('BroadcastPollStart', (data) => {
        /// DRH did this for signal R
        // somehow turn on the active poll icon
        storeAPI.dispatch(
          addNotification({
            message: data,
            type: 'success',
            duration: 5000,
          })
        )
        // start checking to see when we turn off the active poll icon
      })

      hubConnection.on('BroadcastIssueUpdate', (data, source, procAction) => {
        data = JsonParseSafe(data)
        let { returnData } = data
        returnData = returnData || {}

        if (!data.ErrorMessage) {
          if (returnData.IssueResolved)
            objDatePropsToUTC(returnData, 'IssueResolved')
          else {
            returnData.IssueResolved = null
            objDatePropsToUTC(returnData, 'IssueResolved', 'lastActivityDate')
          }

          // Test to see if the issue has to be updated
          const oldIssue =
            selectIssueById(
              storeAPI.getState(),
              returnData.IssueID || data.IssueID
            ) || {}
          if (!containsValues(oldIssue, returnData)) {
            if (procAction === 'set resolved')
              storeAPI.dispatch(resolveIssueSuccess(returnData, source))
            else {
              if (procAction === 'set responsible') {
                storeAPI.dispatch(
                  updateAppAccount.success({
                    AppAcctID: returnData.RespAppAcctID,
                    Fullname: returnData.Fullname,
                    email_address: returnData.email_address,
                  })
                )
                returnData = {
                  Responsible: returnData.RespAppAcctID,
                  IssueID: data.IssueID,
                }
              }
              storeAPI.dispatch(IssueActions.updateSuccess(returnData, source))
            }
          }
        } else {
          if (procAction === 'set resolved')
            storeAPI.dispatch(
              resolveIssueFailure(data.ErrorMessage, returnData, source)
            )
          else
            storeAPI.dispatch(
              IssueActions.updateFailure(data.ErrorMessage, returnData, source)
            )
        }
      })

      hubConnection.on('BroadcastIssueTagAdd', (data, source) => {
        data = JsonParseSafe(data)
        let { returnData } = data
        returnData = returnData || {}

        if (!data.ErrorMessage) {
          const { TagID } = returnData
          const currentTag = selectOrgTagById(storeAPI.getState(), TagID)

          // Update the list of all org tags if this is the first time the tag has been created
          if (!currentTag)
            storeAPI.dispatch(OrgTagActions.addSuccess(returnData, source))

          storeAPI.dispatch(IssueTagActions.addSuccess(returnData, source))
        } else {
          storeAPI.dispatch(
            IssueTagActions.addFailure(data.ErrorMessage, returnData)
          )
        }
      })

      hubConnection.on('BroadcastIssueTagUpdate', (data, source) => {
        data = JsonParseSafe(data)
        let { returnData } = data
        returnData = returnData || {}

        if (!data.ErrorMessage) {
          storeAPI.dispatch(IssueTagActions.updateSuccess(returnData, source))
        } else {
          storeAPI.dispatch(
            IssueTagActions.updateFailure(data.ErrorMessage, returnData)
          )
        }
      })

      hubConnection.on('BroadcastIssueTagDelete', (data, source) => {
        data = JsonParseSafe(data)
        let { returnData } = data
        returnData = returnData || {}

        if (!data.ErrorMessage) {
          storeAPI.dispatch(IssueTagActions.deleteSuccess(returnData, source))
        } else {
          storeAPI.dispatch(
            IssueTagActions.deleteFailure(data.ErrorMessage, returnData)
          )
        }
      })

      hubConnection.on('BroadcastIssueDelete', (data, source) => {
        data = JsonParseSafe(data)
        if (!data.ErrorMessage) {
          storeAPI.dispatch(IssueActions.deleteSuccess(source, source))
        } else {
          storeAPI.dispatch(
            IssueActions.deleteFailure(data.ErrorMessage, source)
          )
        }
      })
      hubConnection.on('BroadcastIssuesActivityLogRead', (returnData) => {
        objDatePropsToUTC(returnData, 'ReadDate')
        storeAPI.dispatch(markReadIssueActivityLog.success(returnData))
      })

      hubConnection.on(
        'BroadcastNewActivityLogItems',
        (data, lastRequested) => {
          // Reset the timer if new items arrive
          clearTimeout(logItemsSubmitTimeout)

          // Call setTimeout once per delay
          logItemsSubmitTimeout = setTimeout(() => {
            // Send to redux only if there are log items
            if (queuedLogItemIds.length) {
              objMapDatePropsToUTC(queuedLogItems, 'HappenedGMT')
              const currentUser = selectUserEmail(storeAPI.getState())
                ?.trim()
                .toLocaleLowerCase()
              storeAPI.dispatch(
                addIssueActivityLog.success(
                  {
                    idMap: queuedLogItems,
                    ids: queuedLogItemIds,
                    currentUser,
                  },
                  {
                    lastRequested: dateUTCbuilder(lastRequested),
                  }
                )
              )

              if (Push.Permission.has()) {
                const issueSet = new Set()
                let numOfIssues = 0

                // Count the number of issues that have activity log changes that are not created by the user
                queuedLogItemIds.forEach((id) => {
                  const item = queuedLogItems[id]

                  if (!item) return

                  if (
                    !issueSet.has(item.IssueID) &&
                    item.PerformedBy?.trim().toLocaleLowerCase() !== currentUser
                  ) {
                    issueSet.add(item.IssueID)
                    numOfIssues++
                  }
                })

                if (numOfIssues > 0) {
                  Push.create('New Changes', {
                    body: `${numOfIssues} ${
                      numOfIssues > 1 ? 'issues' : 'issue'
                    } changed.`,
                    //icon: '/icon.png',
                    timeout: 4000,
                    onClick: function () {
                      window.focus()
                      this.close()
                    },
                  })
                }
              }

              queuedLogItemIds = []
              queuedLogItems = {}
            }
          }, submitLogItemsDelay)

          // Build data to send to redux later
          for (var logId in data) {
            const logItem = data[logId]
            if (!queuedLogItems[logItem.Id]) {
              queuedLogItemIds.push(logItem.Id)
              queuedLogItems[logItem.Id] = logItem
            }
          }
        }
      )
      hubConnection.on('BroadcastEvidenceLockerLinkCreate', (data, source) => {
        data = JsonParseSafe(data)
        const { returnData } = data
        objDatePropsToUTC(returnData, 'createdUTC')
        storeAPI.dispatch(
          EvidenceLockerLinkActions.addSuccess(returnData, source)
        )
      })
      hubConnection.on('BroadcastEvidenceLockerLinkUpdate', (data, source) => {
        data = JsonParseSafe(data)
        const { returnData } = data
        objDatePropsToUTC(returnData, 'createdUTC')
        storeAPI.dispatch(
          EvidenceLockerLinkActions.updateSuccess(returnData, source)
        )
      })
      hubConnection.on('BroadcastEvidenceLockerLinkDelete', (data, source) => {
        data = JsonParseSafe(data)
        storeAPI.dispatch(
          EvidenceLockerLinkActions.deleteSuccess(data.returnData, source)
        )
      })
      hubConnection.on('BroadcastGeolocationCreate', (data, source) => {
        data = JsonParseSafe(data)
        const { returnData } = data
        storeAPI.dispatch(GeolocationActions.addSuccess(returnData, source))
      })
      hubConnection.on('BroadcastGeolocationUpdate', (data, source) => {
        data = JsonParseSafe(data)
        const { returnData } = data
        storeAPI.dispatch(GeolocationActions.updateSuccess(returnData, source))
      })

      hubConnection.on('BroadcastGeolocationDelete', (data, source) => {
        data = JsonParseSafe(data)
        storeAPI.dispatch(
          GeolocationActions.deleteSuccess(data.returnData, source)
        )
      })
      break
    case IssueConstants.UPDATE.REQUEST:
    case RESOLVE_ISSUE.LOADING:
      hubConnection.invoke(
        'SendIssueUpdate',
        payload.result,
        payload.source.action
      )
      break
    /*case SUBMIT_ISSUE_FORM.FAILURE:
            if (payload.source.submitType === 'create')
                storeAPI.dispatch(addNotification({ message: "Issue Create Failed"}));
            break;*/
    case IssueConstants.FETCH.FAILURE:
      if (ErrorMessage)
        alert(ErrorMessage + ' Refresh recommended to resync application.')
      break
    case IssueConstants.INIT.FAILURE:
      if (ErrorMessage) {
        if (ErrorMessage === 'User Account Not Found')
          storeAPI.dispatch(logout())
        else
          alert(
            ErrorMessage +
              ' Application not initialized properly, please refresh.'
          )
      }
      break
    case RESOLVE_ISSUE.FAILURE:
      storeAPI.dispatch(
        addNotification({
          message: action.payload.ErrorMessage || 'Could not resolve issue',
        })
      )
      break

    // IssueTag
    case IssueTagConstants.ADD.REQUEST:
      hubConnection.invoke('SendIssueTagAdd', action.payload.result)
      break
    case IssueTagConstants.UPDATE.REQUEST:
      hubConnection.invoke('SendIssueTagUpdate', action.payload.result)
      break
    case IssueTagConstants.DELETE.REQUEST:
      hubConnection.invoke('SendIssueTagDelete', action.payload.result)
      break
    case IssueConstants.DELETE.REQUEST:
      hubConnection.invoke('SendIssueDelete', action.payload.result)
      break
    case MARK_READ_ISSUE_ACTIVITY_LOG.REQUEST:
      hubConnection.invoke(
        'SendActivityLogRead',
        action.payload.params?.issueID
      )
      break
    case IssueConstants.FETCH.SUCCESS:
      storeAPI.dispatch(fetchIssueDetailsSync.clearSync())
      break
    case EvidenceLockerLinkConstants.ADD.REQUEST:
      hubConnection.invoke('EvidenceLockerLinkCreate', action.payload.result)
      break
    case EvidenceLockerLinkConstants.UPDATE.REQUEST:
      hubConnection.invoke('EvidenceLockerLinkUpdate', action.payload.result)
      break
    case EvidenceLockerLinkConstants.DELETE.REQUEST:
      hubConnection.invoke('EvidenceLockerLinkDelete', action.payload.result)
      break
    case GeolocationConstants.ADD.REQUEST:
      hubConnection.invoke('GeolocationCreate', action.payload.result)
      break
    case GeolocationConstants.UPDATE.REQUEST:
      hubConnection.invoke('GeolocationUpdate', action.payload.result)
      break
    case GeolocationConstants.DELETE.REQUEST:
      hubConnection.invoke('GeolocationDelete', action.payload.result)
      break
    default:
      break
  }

  return
}
