// @ts-nocheck
// TODO: Typescript
import { RootState } from '../_store'
import {
  selectUserEmail as selectUserAuthEmail,
  selectUserAppAcctId,
  selectUser,
  isAzureADLogin,
  isUserAuthenticated,
  selectInitialView as selectAuthInitialView,
} from '../Auth/_selectors'
import {
  isOrgIssueLevelSelected,
  selectAllUnresolvedIssues,
  selectCurrentHoldingStatementIds,
  selectCurrentIssueId,
  selectCurrentIssueLevelIds,
  selectCurrentWorkspaceIds,
  selectIssueAllStakeholderIds,
  selectIssueById,
  selectIssueFilter,
  selectIssueFilterField,
  selectIssueFilterKeys,
  selectIssuesAllLevelIds,
  selectIssuesByIdMap,
  selectIssuesSortBy,
  selectIssuesSortDir,
  selectIssueWorkspaceIds,
  selectUserEmail as selectUserIssueEmail,
} from '../Issue/_selectors'
import {
  selectWorkspaceConferenceIds as privSelectWorkspaceConferenceIds,
  selectWorkspaceById,
  selectWorkspaceChatIds,
  selectWorkspaceDirectMessageIds,
  selectWorkspaceParticipantIds,
  selectWorkspacePathId,
  selectWorkspacesByIdMap,
  selectWorkspaceTaskCount,
  selectWorkspaceChatCount,
} from '../Workspace/_selectors'
import {
  selectConferenceById,
  selectConferenceEnd,
  selectConferencesIdMap,
  selectConferenceStart,
} from '../Conference/_selectors'
import {
  isApplicationExtension,
  isAudioExtension,
  isImageExtension,
  isMobileApp,
  isTextExtension,
  isVideoExtension,
  selectTrendColorById,
  selectTrendNameById,
} from '../_selectors'
import { selectIssueTrendId } from '../Issue/_selectors'
import { createSelector } from 'reselect'
import { ConferenceState } from '../Conference/_models'
import { compareDates, getLocalDate, getTodayUTC } from '../_utils/dateUtils'
import {
  selectArtifactById,
  selectArtifactExternalStorage,
  selectArtifactMSTeamsChannelId,
  selectArtifactsIdMap,
} from '../Upload/_selectors'
import {
  selectIsMicrosoftTeams,
  selectJoinedMSChannelById,
  selectJoinedMSTeamById,
  selectMSTeamsLoginHint,
} from '../MicrosoftTeams/_selectors'
import { selectArtifactMSTeamsTeamId } from '../Upload/_reducer'
import { getFilenameExt } from '../_utils/fileUtils'
import {
  selectChatEntryByIdMap,
  selectChatsByIdMap,
  selectDirectMessageByIdMap,
} from '../Chat/_selectors'
import {
  selectTaskItemCommentIdMap,
  selectTaskItemIdMap,
} from '../Task/_selectors'
import { selectHoldingStatementIdMap } from '../HoldingStatement/_selectors'
import {
  selectIssueLevelById,
  selectIssueLevelsByIdMap,
} from '../IssueLevel/_selectors'
import * as fromStakeholder from '../Stakeholder/_reducer'
import * as fromParticipant from '../Participant/_reducer'
import { isEqualIgnoreCaseTrim } from '../_utils/objectUtils'
import * as fromTask from '../Task/_reducer'
import { selectInboxByGUID, selectInboxIdMap } from '../Alert/_selectors'
import { InboxModel } from '../Alert/_models'
import { selectOrgTagIdMap } from '../Org/_selectors'
import { DenormalizedOrgTag, OrgTag } from '../Org/_models'
import { InboxState } from '../Alert/_reducer'
import { TaskListFilterStatus } from '../Task/TaskListFilter'

// REDUX ROUTER SELECTORS
/*const routesWithAppData = [
    //'IssueManagement',
    'Reports',
    'IssueDetail',
    'Playbook',
    'Alert',
    'Issues',
    'PbChat',
    'PbTask'
];*/

const routesWithoutAppData = [
  'AzureAD',
  'ForgotPassword',
  'CreateAccount',
  'ResetAccountPassword',
  'Home/ErrorWithMessage',
  'ConfirmEmail',
  'CancelEmail',
  'MicrosoftTeams/Configure',
]

export const selectCurrentRoute = (state) =>
  ((state.router || {}).location || {}).pathname
export const doesCurrentRouteRequireIssueData = (state) => {
  let current = (selectCurrentRoute(state) || '').toLocaleLowerCase()
  if (current[0] === '/') current = current.substr(1)

  if (!current) return true

  /*for (let i = 0; i < routesWithAppData.length; i++) {
        const item = routesWithAppData[i].toLocaleLowerCase();
        if (current.startsWith(item))
            return true;
    }

    return false;*/

  for (let i = 0; i < routesWithoutAppData.length; i++) {
    const item = routesWithoutAppData[i].toLocaleLowerCase()
    if (current.startsWith(item)) return false
  }

  return true
}

// ARTIFACT/MICROSOFT SELECTORS
export const selectMSTeamsChannelNameByArtifactId = (state, artifactId) => {
  const channelId = selectArtifactMSTeamsChannelId(state, artifactId)
  const channel = selectJoinedMSChannelById(state, channelId) || {}
  return channel.DisplayName || ''
}

export const selectMSTeamsTeamNameByArtifactId = (state, artifactId) => {
  const teamId = selectArtifactMSTeamsTeamId(state, artifactId)
  const team = selectJoinedMSTeamById(state, teamId) || {}
  return team.DisplayName || ''
}

export const selectArtifactStorageLocationName = (state, artifactId) => {
  const externalStorage = selectArtifactExternalStorage(state, artifactId)
  if (externalStorage === 'MicrosoftTeams') {
    const team = selectMSTeamsTeamNameByArtifactId(state, artifactId)
    const channel = selectMSTeamsChannelNameByArtifactId(state, artifactId)

    if (team && channel) return team + ' - ' + channel
  }

  return ''
}

// ARTIFACT/APP SELECTORS
export const selectArtifactMediaType = (state, artifactId) => {
  const artifact = selectArtifactById(state, artifactId)

  if (!artifact) return ''
  const ext = getFilenameExt(artifact.ArtifactName)

  if (isAudioExtension(state, ext)) return 'audio'
  if (isVideoExtension(state, ext)) return 'video'
  if (isImageExtension(state, ext)) return 'image'
  if (isApplicationExtension(state, ext)) return 'application'
  if (isTextExtension(state, ext)) return 'text'
}

// Stakeholder SELECTORS
export const selectStakeholderById = (state, stakeholderId) =>
  fromStakeholder.selectStakeholderById(state.stakeholder, stakeholderId)
export const selectStakeholderIdMap = (state) =>
  fromStakeholder.selectStakeholderIdMap(state.stakeholder)

// PARTICIPANT SELECTORS
const participantReducerName = 'participant'
export const selectParticipantsIdMap = (state) =>
  fromParticipant.selectParticipantsIdMap(state[participantReducerName])
export const selectParticipantById = (state, participantId) =>
  fromParticipant.selectParticipantById(
    state[participantReducerName],
    participantId
  )
export const isParticipantOnline = (state, email) =>
  fromParticipant.isParticipantOnline(state.participantStatus, email)
export const isParticipantOffline = (state, email) =>
  fromParticipant.isParticipantOffline(state.participantStatus, email)
export const selectParticipantJoinedTeamNames = (state, participantId) =>
  fromParticipant.selectParticipantJoinedTeamNames(
    state[participantReducerName],
    participantId
  )

// ORG ISSUE TEAM SELECTORS
export const selectOrgIssueTeamsIdMap = (state) =>
  fromParticipant.selectOrgIssueTeamsIdMap(state.orgIssue)
export const selectOrgIssueTeamById = (state, teamId) =>
  fromParticipant.selectOrgIssueTeamById(state.orgIssueTeam, teamId)

export const selectArtifactsByChatEntryIds = createSelector(
  (_, propsOrIds) =>
    propsOrIds instanceof Array ? propsOrIds : (propsOrIds || {}).chatEntryIds,
  selectArtifactsIdMap,
  selectChatEntryByIdMap,
  (entryIds, artifactsMap, chatEntryMap) => {
    let artifactIds = []
    ;(entryIds || []).forEach((id) => {
      const artifactId = (chatEntryMap[id] || {}).upload
      const artifact = artifactsMap[artifactId]
      if (artifact) {
        artifactIds.push(artifact)
      }
    })

    return artifactIds
  }
)

// TASK ITEM COMMENT/ARTIFACT SELECTORS
export const selectArtifactsByCommentIds = createSelector(
  (_, propsOrIds) =>
    propsOrIds instanceof Array ? propsOrIds : (propsOrIds || {}).commentIds,
  selectArtifactsIdMap,
  selectTaskItemCommentIdMap,
  (commentIds, artifactsMap, commentsMap) => {
    let artifactIds = []
    ;(commentIds || []).forEach((id) => {
      const artifactId = (commentsMap[id] || {}).upload
      const artifact = artifactsMap[artifactId]
      if (artifact) {
        artifactIds.push(artifact)
      }
    })

    return artifactIds
  }
)

// TASK/TASK ITEM SELECTORS
// select  ids with sort and filter (when needed)
export const taskReducerName = 'task'
export const selectTaskAllItemIds = (state, taskId) =>
  fromTask.selectTaskAllItemIds(state[taskReducerName], taskId)
export const selectTaskFilteredItemIds = createSelector(
  selectTaskItemIdMap,
  (state, props) => selectTaskAllItemIds(state, props.TaskID),
  (_, props) => props.filter,
  selectUserAppAcctId,
  selectUser,
  (idMap, allIds, filter, appAcctId) => {
    const result = (allIds || []).filter((taskItemId) => {
      const taskItem = idMap[taskItemId]
      let isValidItem = true

      switch (filter.status) {
        case TaskListFilterStatus.Completed: // Completed
          isValidItem = taskItem.CompletedAppAcctID > 0
          break
        case TaskListFilterStatus.Incompleted: // Incompleted
          isValidItem = !(taskItem.CompletedAppAcctID > 0)
          break
        case TaskListFilterStatus.All: // 0 - All Tasks
          isValidItem = true
          break
      }

      if (
        filter.orgRole &&
        parseInt(filter?.orgRole?.id) !== parseInt(taskItem?.OrgRoleID)
      ) {
        isValidItem = false
      }
      // if (!filter.assignedTo && !filter.orgRole) {
      //   return isValidItem
      // }

      const trimmedEmail = taskItem.AssignedTo?.trim().split(/\s+/)[0]

      if (filter.assignedTo && filter.assignedTo !== trimmedEmail) {
        isValidItem = false
      }

      if (filter.timeFilterChecked) {
        const dueDate = new Date(taskItem.DueByUTC)
        const localDueDate = getLocalDate(dueDate)

        if (
          !(localDueDate > filter.dateStart && localDueDate < filter.dateEnd)
        ) {
          isValidItem = false
        }
      }

      return isValidItem
    })

    return result
  }
)

// UPLOAD SELECTORS
export const selectUploadsInProgress = (state) =>
  fromRequestStatus.selectUploadsInProgress(state.requestStatus)

/*****
 * JOIN SELECTORS
 */

// Auth/IssueApp
export const selectUserEmail = (state: RootState) =>
  selectUserAuthEmail(state) || selectUserIssueEmail(state)

// APP ACCOUNT/MICROSOFT TEAMS SELECTORS
// Determine if the current user that is logged in is equal to the MS Teams login hint
export const isMsTeamsContextUser = createSelector(
  selectMSTeamsLoginHint,
  selectUserEmail,
  isAzureADLogin,
  isUserAuthenticated,
  (msTeamsLoginHint, userEmail, isAzureAccount, isAuthenticated) =>
    isAuthenticated &&
    userEmail &&
    msTeamsLoginHint &&
    msTeamsLoginHint === userEmail &&
    isAzureAccount
)

export const hasInvalidMsTeamsContext = createSelector(
  isMsTeamsContextUser,
  selectIsMicrosoftTeams,
  (isTeamsContextUser, isInTeams) => isInTeams && !isTeamsContextUser
)

export const isIssueAdvisoryCreated = createSelector(
  selectIssueAllStakeholderIds,
  selectStakeholderIdMap,
  (stakeHolderIds, idMap) => {
    idMap = idMap || {}
    let stakeholderWithAdvisory = (stakeHolderIds || []).find((id) =>
      (idMap[id] || {}).PubID ? true : false
    )
    return stakeholderWithAdvisory ? true : false
  }
) as (state: RootState, issueID: number) => boolean

export const selectIssueStakeholderIds = selectIssueAllStakeholderIds

export const selectInitialView = createSelector(
  selectAuthInitialView,
  selectIsMicrosoftTeams,
  isMobileApp,
  (initialView, isTeams, isMobileApp) => {
    return initialView
  }
)

// ISSUE/HOLDINGSTATEMENT SELECTORS
export const selectCurrentHoldingStatement = createSelector(
  selectHoldingStatementIdMap,
  selectCurrentHoldingStatementIds,
  (idMap, allIds) => idMap[(allIds || [])[0]]
)

// ISSUE/ISSUELEVEL SELECTORS /// WRONG???
export const selectCurrentIssueLevel = createSelector(
  selectCurrentIssueLevelIds,
  selectIssueLevelsByIdMap,
  (levelIds, levelIdMap) => levelIdMap[(levelIds || [])[0]]
)

export const selectIssueCurrentIssueLevel = (state, issueId) => {
  const issueLevels = selectIssuesAllLevelIds(state, issueId) || []

  return selectIssueLevelById(state, issueLevels[0])
}

// ISSUE/WORKSPACE SELECTORS
export const selectIssueTaskCount = (state, issueId) =>
  (selectIssueWorkspaceIds(state, issueId) || []).reduce(
    (total, workspaceId) =>
      total + selectWorkspaceTaskCount(state, workspaceId),
    0
  )
export const selectIssueChatCount = (state, issueId) =>
  (selectIssueWorkspaceIds(state, issueId) || []).reduce(
    (total, workspaceId) =>
      total + selectWorkspaceChatCount(state, workspaceId),
    0
  )

export const selectIssueParticipantCount = (state, issueId) => {
  const workspaceIds = selectIssueWorkspaceIds(state, issueId) || []
  const emailSet = new Set() // Don't repeat participants if the equal has already been counted
  let count = 0
  workspaceIds.forEach((id) => {
    const participantIds = selectWorkspaceParticipantIds(state, id) || []
    participantIds.forEach((partId) => {
      const participant = selectParticipantById(state, partId) || {}
      const partEmail = (participant.email_address || '')
        .toLocaleLowerCase()
        .trim()
      if (!emailSet.has(partEmail)) {
        emailSet.add(partEmail)
        count++
      }
    })
  })

  return count
}

export const selectIssueOrgIds = (state, issueId) => {
  const workspaceIds = selectIssueWorkspaceIds(state, issueId) || []
  const orgIdSet = new Set()
  let allOrgIds = []
  workspaceIds.forEach((id) => {
    const workspace = selectWorkspaceById(state, id)
    const { OrgID } = workspace
    if (!orgIdSet.has(OrgID)) {
      orgIdSet.add(OrgID)
      allOrgIds.push(OrgID)
    }
  })

  return allOrgIds
}

export const selectIssueOrgName = (state, issueId) => {
  const workspaceIds = selectIssueWorkspaceIds(state, issueId) || []
  return (selectWorkspaceById(state, workspaceIds[0]) || {}).OrgName
}

export const selectIssuesByInboxGUID: (
  state: RootState,
  inboxGUID: string
) => any = createSelector(
  selectInboxByGUID,
  selectAllUnresolvedIssues,
  selectWorkspacesByIdMap,
  (inbox: InboxModel, issues, workspaceIdMap) => {
    workspaceIdMap = workspaceIdMap || {}
    return issues.filter((curIssue) => {
      const workspaceWithOrg = curIssue.wksp.find(
        (workspaceID) => workspaceIdMap[workspaceID]?.OrgID === inbox?.OrgID
      )

      return workspaceWithOrg ? true : false
    })
  }
)

// ISSUE/PARTICIPANT SELECTORS
// (state, issueId)
export const isIssueParticipantsLoaded = createSelector(
  selectIssueWorkspaceIds,
  selectWorkspacesByIdMap,
  (workspaceIDs, idMap) => {
    idMap = idMap || {}
    return (workspaceIDs || []).find((id) => idMap[id]?.Participants)
      ? true
      : false
  }
)

export const isUserIssueApproverWorkspace = createSelector(
  selectIssueWorkspaceIds,
  selectWorkspacesByIdMap,
  selectParticipantsIdMap,
  selectUserEmail,
  containsUserIssueParticipant(
    (part, userEmail) =>
      isEqualIgnoreCaseTrim(part.email_address, userEmail) &&
      part.IsActiveYN === 'Y' &&
      (part.IsApproverYN === 'Y' || part.IsAdminYN === 'Y')
  )
)

export const isUserIssueApprover = createSelector(
  selectIssueById,
  isIssueParticipantsLoaded,
  isUserIssueApproverWorkspace,
  (issue, participantsLoaded, isWorkspaceApprover) => {
    // If the participants have not been loaded yet check the user's role by the initial state of the issue
    if (!participantsLoaded)
      return (
        issue?.UserRole === 'Approver' || issue?.UserRole === 'Administrator'
      )

    return isWorkspaceApprover
  }
)

export const isUserIssueAdministerWorkspace = createSelector(
  selectIssueWorkspaceIds,
  selectWorkspacesByIdMap,
  selectParticipantsIdMap,
  selectUserEmail,
  containsUserIssueParticipant(
    (part, userEmail) =>
      isEqualIgnoreCaseTrim(part.email_address, userEmail) &&
      part.IsActiveYN === 'Y' &&
      part.IsAdminYN === 'Y'
  )
)

export const isUserIssueAdminister = createSelector(
  selectIssueById,
  isIssueParticipantsLoaded,
  isUserIssueAdministerWorkspace,
  (issue, participantsLoaded, isWorkspaceAdministrator) => {
    // If the participants have not been loaded yet check the user's role by the initial state of the issue
    if (!participantsLoaded) return issue?.UserRole === 'Administrator'

    return isWorkspaceAdministrator
  }
)

export const isUserIssueActiveWorkspace = createSelector(
  selectIssueWorkspaceIds,
  selectWorkspacesByIdMap,
  selectParticipantsIdMap,
  selectUserEmail,
  containsUserIssueParticipant(
    (part, userEmail) =>
      isEqualIgnoreCaseTrim(part.email_address, userEmail) &&
      part.IsActiveYN === 'Y'
  )
)

export const canUserAccessIssue = createSelector(
  selectIssueById,
  isIssueParticipantsLoaded,
  isUserIssueActiveWorkspace,
  (issue, participantsLoaded, isUserWorkspaceMember) => {
    // If the participants have not been loaded yet check the user's role by the initial state of the issue
    if (!participantsLoaded) return issue?.UserIsActiveYN === 'Y'

    return isUserWorkspaceMember
  }
)

/**
 * Issue/Participant helper: Returns all the participants that match
 */

function containsUserIssueParticipant(compFunc) {
  return filterUserIssueParticipantHelper(compFunc, 'bool')
}

/**
 * Issue/Participant helper: Helper function for
 * findUserIssueParticipant and filterUserIssueParticipant
 */
function filterUserIssueParticipantHelper(compFunc, resultType) {
  if (!compFunc)
    throw new Error('compFunc required for filterUserIssueParticipantHelper')

  return (workspaceIds, workspaceIdMap, participantIdMap, userEmail) => {
    let validParticipants = []
    workspaceIds = workspaceIds || []
    workspaceIdMap = workspaceIdMap || {}
    participantIdMap = participantIdMap || {}
    let validateMore = () => validParticipants.length === 0
    if (!resultType) validateMore = () => true

    for (let i = 0; i < workspaceIds.length && validateMore(); i++) {
      const workspace = workspaceIdMap[workspaceIds[i]]
      if (workspace) {
        const participantIds = workspace.Participants || []
        for (let j = 0; j < participantIds.length && validateMore(); j++) {
          const participant = participantIdMap[participantIds[j]]
          if (participant && compFunc(participant, userEmail)) {
            validParticipants.push(participant)
          }
        }
      }
    }

    if (resultType === 'single') return validParticipants[0]
    else if (resultType === 'bool') return validParticipants[0] ? true : false

    return validParticipants
  }
}

export const selectIssueDefaultWorkspaceId = (state, issueId) => {
  const workspaceIds = selectIssueWorkspaceIds(state, issueId) || []
  const issue = selectIssueById(state, issueId)

  if (workspaceIds.length === 1) return workspaceIds[0]

  if (issue?.IssueTeamWorkspaceID) return issue.IssueTeamWorkspaceID

  return workspaceIds.find((id, index) => {
    const { TeamName } = selectWorkspaceById(state, id) || {}
    if (TeamName !== 'Submitter' && index === 0) return true

    return index > 0
  })
}

export const selectCurrentIssueWorkspace = (state, ownProps) => {
  const workspaceIds = selectCurrentWorkspaceIds(state, ownProps) || []
  const workspacePathId = selectWorkspacePathId(state, ownProps)

  if (
    workspacePathId !== undefined &&
    workspaceIds.indexOf(workspacePathId) === -1
  )
    return undefined
  return selectWorkspaceById(
    state,
    workspacePathId ||
      selectIssueDefaultWorkspaceId(
        state,
        selectCurrentIssueId(state, ownProps)
      )
  )
}
export const selectCurrentIssueWorkspaceId = (state, ownProps) => {
  return (selectCurrentIssueWorkspace(state, ownProps) || {})
    .IssueTeamWorkspaceID
}

// WORKSPACE/CONFERENCE SELECTORS
export const selectWorkspaceNextConference = (state, workspaceId) => {
  const allConferences = selectWorkspaceConferenceIds(state, workspaceId) || []
  const today = getTodayUTC()
  let min = {}

  for (let i = 0; i < allConferences.length; i++) {
    const curConf = selectConferenceById(state, allConferences[i]) || {}
    const start = selectConferenceStart(state, curConf.ConferenceID)

    if (start && start >= today && (!min.conf || start < min.start))
      min = { curConf, start }
  }

  return min.curConf
}

export const selectWorkspaceRecentPastDueConference = (state, workspaceId) => {
  const allConferences = selectWorkspaceConferenceIds(state, workspaceId) || []
  const today = getTodayUTC()
  let max = {}

  for (let i = 0; i < allConferences.length; i++) {
    const curConf = selectConferenceById(state, allConferences[i]) || {}
    const start = selectConferenceStart(state, curConf.ConferenceID)
    const end = selectConferenceEnd(state, curConf.ConferenceID)

    if (start < today && today <= end && (!max.curConf || start > max.start))
      max = { curConf, start }
  }

  return max.curConf
}

export const selectWorkspaceRecentlyCreatedConference = (
  state,
  workspaceId
) => {
  const allConferences = selectWorkspaceConferenceIds(state, workspaceId) || []
  let mostRecent

  for (let i = 0; i < allConferences.length; i++) {
    const curConf = selectConferenceById(state, allConferences[i]) || {}
    const { Created } = curConf
    if (!mostRecent || Created > mostRecent.Created) mostRecent = curConf
  }

  return mostRecent
}

export const selectWorkspaceFutureConferenceIds = (state, workspaceId) => {
  const allConferences = selectWorkspaceConferenceIds(state, workspaceId) || []

  // 2020.04.08 NM: remove conferences started more than one hour ago and select first Conference Id

  let futureConferences = []
  let today = new Date()

  today.setHours(today.getHours() - 1)
  for (let i = 0; i < allConferences.length; i++) {
    const conf = selectConferenceById(state, allConferences[i])
    if (conf != null && conf.Scheduled != null) {
      const confDate = new Date(conf.Scheduled)

      if (conf != null && confDate > today) {
        futureConferences.push(conf)
      }
    }
  }

  futureConferences.sort(function (a, b) {
    const key1 = new Date(a.Scheduled)
    const key2 = new Date(b.Scheduled)

    if (key1 < key2) {
      return -1
    } else if (key1 === key2) {
      return 0
    } else {
      return 1
    }
  })

  return futureConferences
}

// ISSUE/ISSUE APP SELECTORS
function isIssueWithDesc(issue, fieldValue) {
  if (fieldValue === undefined) fieldValue = ''

  if (issue.isArchived) return false

  return (
    fieldValue === '' ||
    (issue.Description || '').toLowerCase().indexOf(fieldValue.toLowerCase()) >
      -1 ||
    fieldValue === '' ||
    (issue.IssueName || '').toLowerCase().indexOf(fieldValue.toLowerCase()) > -1
  )
}

export const selectReportIssueIds = createIssueIdsBySearchSelector(
  (state, issue) =>
    isUserIssueApprover(state, issue.IssueID) && !issue.IssueResolved, // filter
  sortIssuesByLastActivityDate(true) // sort
)
export const selectReportResolvedIssueIds = createIssueIdsBySearchSelector(
  (state, issue) =>
    isUserIssueApprover(state, issue.IssueID) && issue.IssueResolved
      ? true
      : false, // filter
  sortIssuesByLastActivityDate(true) // sort
)

// Helper function for the issueIds selector above
function sortIssuesByDate(dateField, desc) {
  return (a, b, issuesById) => {
    // sort
    const issueA = issuesById[a] || {}
    const issueB = issuesById[b] || {}

    if (desc) return compareDates(issueB[dateField], issueA[dateField])

    return compareDates(issueA[dateField], issueB[dateField])
  }
}

function sortIssuesByLastActivityDate(desc) {
  return sortIssuesByDate('lastActivityDate', desc)
}

function createIssueIdsBySearchSelector(isIssueValid = () => true, issueSort) {
  return createSelector(
    selectIssueFilter,
    selectIssuesByIdMap,
    (state) => (issue) => isIssueValid(state, issue),
    (issueFilter, issuesById, isValid) => {
      issueFilter = issueFilter || {}
      let visibleIssues = Object.keys(issuesById).map((id) => parseInt(id))
      visibleIssues = visibleIssues.filter(function (id) {
        const issue = issuesById[id]
        return (
          isIssueWithDesc(issue, issueFilter.Description || '') &&
          isValid(issue)
        )
      })

      if (issueSort)
        visibleIssues.sort((a, b) => {
          return issueSort(a, b, issuesById)
        })

      return visibleIssues
    }
  )
}

// Solution: Make a function that acts as a factory that wraps all required functions
export const selectVisibleIssueIds = createSelector(
  selectIssuesByIdMap,
  selectIssueFilterKeys,
  selectOrgTagIdMap,
  selectInboxIdMap,
  (state) => state, // This will always recreate because the state is always new
  //selectIssuesSortBy,
  //selectIssuesSortDir,
  (issuesById, fields, tagIdMap, inboxIdMap, state /*by, dir*/) => {
    let visibleIssues = Object.keys(issuesById).map((id) => parseInt(id))
    visibleIssues = visibleIssues.filter(function (id) {
      const issue = issuesById[id]
      let issueTagIds = []

      // All fields must match or the field must be empty

      const issueLevel = selectIssueCurrentIssueLevel(state, id) || {} // IssueID
      let isValidField = true
      const issueDate = issue?.IssueCreated

      // User cannot access this issue
      if (!canUserAccessIssue(state, id)) return false

      if (!fields) return false

      for (let i = 0; i < fields.length && isValidField; i++) {
        const fieldName = fields[i]
        let fieldValue = selectIssueFilterField(state, fieldName)

        switch (fieldName) {
          case 'orgIdList': {
            isValidField = fieldValue?.indexOf(issue.OrgID) !== -1
            break
          }
          case 'IssueResolved':
            isValidField =
              !issue.IssueResolved ||
              (fieldValue === true && issue.IssueResolved)
            break
          /*case "Severity":
                        isValidField = fieldValue.indexOf(issueLevel.LevelID) !== -1
                        break;*/
          case 'HideSeverity':
            isValidField = isOrgIssueLevelSelected(state, issueLevel.LevelID)
            break
          case 'Type':
            isValidField = fieldValue.indexOf(issue.IssueType) !== -1
            break
          case 'startDate':
            if (fieldValue !== null && fieldValue.length > 0) {
              const FstartDate = new Date(fieldValue)
              isValidField = issueDate?.getTime() > FstartDate.getTime()
            }
            break
          case 'endDate':
            if (fieldValue !== null && fieldValue.length > 0) {
              const FendDate = new Date(fieldValue)
              // add 24 hours to endDate to include issues for this day also
              isValidField =
                issueDate?.getTime() < FendDate.getTime() + 24 * 3600 * 1000
            }
            break
          case 'tags':
            issueTagIds = issue.IssueTag || []
            let tagValues = []
            if (fieldValue && fieldValue.length > 0) {
              tagValues = fieldValue
              //tagValues = fieldValue.map(t => t.key);
              //tagValues = fieldValue?.trim().split(",");
              isValidField = false

              // Does the issue have a tag that belongs to the list of selected tags in the fitler field
              for (let i = 0; i < issueTagIds.length && !isValidField; i++) {
                const tagID = issueTagIds[i]
                const issueTag = getDenormalizedOrgTag(
                  (tagIdMap || {})[tagID] || {},
                  inboxIdMap
                )
                isValidField = tagValues.find(
                  (value) =>
                    value === issueTag.TagKey ||
                    (issueTag.TagType === 'inbox' && value === '_tagType_alert')
                )
              }
            } else isValidField = true

            break
          case 'Description':
            issueTagIds = issue.IssueTag || []
            isValidField = isIssueWithDesc(issue, fieldValue)

            // Check the tags if the description field doesn't work
            if (!isValidField && issueTagIds.length > 0) {
              isValidField = issueTagIds.find((tagID: number) => {
                const issueTag = (tagIdMap || {})[tagID] || {}
                const issueTagName =
                  issueTag.TagName?.trim().toLocaleLowerCase()
                const fieldValueLower = fieldValue?.trim().toLocaleLowerCase()

                /// See if the issue has a tag name that matches the search field
                return (
                  issueTagName.startsWith(fieldValueLower) ||
                  (issueTag.TagType === 'inbox' &&
                    'alert'.startsWith(fieldValueLower))
                )
                /*if (issueTagName.startsWith(fieldValueLower)) {
                                    // 
                                    return binarySearch(tagNames, issueTagName, uniqueTag => uniqueTag.tagNameLower) ? true : false;
                                }

                                return false;*/
              })
                ? true
                : false
            }
            break
          default:
            break
        }
      }

      return isValidField
    })

    const by = selectIssuesSortBy(state)
    const dir = selectIssuesSortDir(state)

    let idToEntity
    switch (by) {
      case 'LevelSequence':
        idToEntity = (id) => selectIssueCurrentIssueLevel(state, id) || {}
        break
      default:
        idToEntity = (id) => issuesById[id]
    }

    visibleIssues.sort(function (a, b) {
      a = idToEntity(a)
      b = idToEntity(b)
      const aValue =
        typeof a[by] === 'string' || a[by] === undefined
          ? (a[by] || '').toLowerCase()
          : a[by]
      const bValue =
        typeof b[by] === 'string' || b[by] === undefined
          ? (b[by] || '').toLowerCase()
          : b[by]
      if (bValue < aValue) return dir === 'DESC' ? -1 : 1
      else if (bValue > aValue) return dir === 'DESC' ? 1 : -1
      return 0
    })

    return visibleIssues
  }
)

export const selectVisibleAssignedIssueIds = createSelector(
  selectVisibleIssueIds,
  selectIssuesByIdMap,
  (issueIds, issuesById) =>
    (issueIds || []).filter((id) => {
      const issue = issuesById[id] || {}
      return (
        (!issue.StagingEnteredGMT || issue.StagingExitedGMT) &&
        !issue.isArchived
      )
    })
)

export const selectVisibleStagingIssueIds = createSelector(
  selectVisibleIssueIds,
  selectIssuesByIdMap,
  (issueIds, issuesById) =>
    (issueIds || []).filter((id) => {
      const issue = issuesById[id] || {}
      return (
        issue.StagingEnteredGMT && !issue.StagingExitedGMT && !issue.isArchived
      )
    })
)
export const selectAssignedFilteredFromTotalIssues = createSelector(
  selectIssuesByIdMap,
  selectVisibleAssignedIssueIds,
  (state) => state,
  (totalIssues, visibleAssignedIssues, state) => {
    let allAssignedIssues = Object.keys(totalIssues).map((id) => parseInt(id))
    allAssignedIssues = allAssignedIssues.filter((id) => {
      const issue = totalIssues[id]

      // User cannot access this issue
      if (!canUserAccessIssue(state, id)) return false
      return (
        (!issue.StagingEnteredGMT || issue.StagingExitedGMT) &&
        !issue.isArchived
      )
    })
    return `(Showing ${visibleAssignedIssues.length}/${allAssignedIssues.length})`
  }
)
export const selectStagingFilteredFromTotalIssues = createSelector(
  selectIssuesByIdMap,
  selectVisibleStagingIssueIds,
  (state) => state,
  (totalIssues, visibleStagingIssues, state) => {
    let allStagingIssues = Object.keys(totalIssues).map((id) => parseInt(id))
    allStagingIssues = allStagingIssues.filter((id) => {
      const issue = totalIssues[id]

      // User cannot access this issue
      if (!canUserAccessIssue(state, id)) return false
      return (
        issue.StagingEnteredGMT && !issue.StagingExitedGMT && !issue.isArchived
      )
    })
    return `(Showing ${visibleStagingIssues.length}/${allStagingIssues.length})`
  }
)

export const selectWorkspaceChats = createSelector(
  (state, issueTeamWorkspaceId) =>
    selectWorkspaceChatIds(state, issueTeamWorkspaceId),
  selectChatsByIdMap,
  (chatIds, chatIdMap) => {
    if (!chatIdMap || !chatIds) return

    return chatIds
      .map((id) => chatIdMap[id])
      .filter((item) => (item ? true : false))
  }
)

export const selectWorkspaceDirectMessages = createSelector(
  selectWorkspaceDirectMessageIds,
  selectDirectMessageByIdMap,
  (dmIds, dmIdMap) => {
    if (!dmIdMap || !dmIds) return

    return dmIds.map((id) => dmIdMap[id])
  }
)

//WORKSPACE/PARTICIPANT SELECTORS
export const selectUserWorkspaceParticipant = createSelector(
  selectWorkspaceParticipantIds,
  selectUserEmail,
  selectParticipantsIdMap,
  (partIds, email, partIdMap) => {
    email = (email || '').toLocaleLowerCase().trim()
    partIdMap = partIdMap || {}
    const foundId = (partIds || []).find(
      (id) =>
        ((partIdMap[id] || {}).email_address || '')
          .toLocaleLowerCase()
          .trim() === email
    )
    return partIdMap[foundId]
  }
)
export const isUserWorkspaceParticipant = (state, workspaceId) =>
  selectUserWorkspaceParticipant(state, workspaceId) !== undefined
export const canUserApproveWorkspace = (state, workspaceId) => {
  const part = selectUserWorkspaceParticipant(state, workspaceId) || {}
  return part.IsApproverYN === 'Y' || part.IsAdminYN === 'Y'
}
export const canUserAdministerWorkspace = (state, workspaceId) =>
  (selectUserWorkspaceParticipant(state, workspaceId) || {}).IsAdminYN === 'Y'
export const canUserAccessWorkspace = (state, workspaceId) =>
  (selectUserWorkspaceParticipant(state, workspaceId) || {}).IsActiveYN === 'Y'
export const isParticipantWorkspaceTeamMember = (
  state,
  participantId,
  workspaceId
) => {
  const workspaceTeamId = (selectWorkspaceById(state, workspaceId) || {}).TeamID
  const participantTeamId = (selectParticipantById(state, participantId) || {})
    .TeamID

  if (!workspaceTeamId && !participantTeamId) return false

  return workspaceTeamId === participantTeamId
}

function hasAccessRole(part) {
  const { AppAcctID, IsActiveYN } = part || {}
  return AppAcctID && IsActiveYN === 'Y' ? true : false
}

function hasAccessRoleAwaitingAcct(part) {
  const { AppAcctID, IsActiveYN } = part || {}
  return !AppAcctID && IsActiveYN === 'Y' ? true : false
}

function sortPartIds(partIds, partIdMap, selectTeamNames) {
  partIds.sort((a, b) => {
    const aPart = partIdMap[a] || {}
    const bPart = partIdMap[b] || {}

    if (selectTeamNames) {
      const aNames = selectTeamNames(aPart.ParticipantID)
      const bNames = selectTeamNames(bPart.ParticipantID)

      if (aNames < bNames) return -1
      else if (aNames > bNames) return 1
    }

    if (aPart.FullName < bPart.FullName) return -1
    else if (aPart.FullName > bPart.FullName) return 1

    if (aPart.email_address < bPart.email_address) return -1
    else if (aPart.email_address > bPart.email_address) return 1

    return 0
  })

  return partIds
}

export const selectWorkspaceChatParticipants = createSelector(
  selectWorkspaceParticipantIds,
  selectParticipantsIdMap,
  selectUserEmail,
  (partIds, partIdMap, userEmail) => {
    partIds = partIds || []
    partIdMap = partIdMap || {}
    userEmail = userEmail.toLocaleLowerCase()
    const filteredIds = partIds.filter((id) => {
      const part = partIdMap[id]
      return !hasAccessRoleAwaitingAcct(part) &&
        userEmail !== part?.email_address.toLocaleLowerCase()
        ? true
        : false
    })

    const sortedIds = sortPartIds(filteredIds, partIdMap)

    return sortedIds.map((id) => partIdMap[id])
  }
)

export const selectWorkspaceTeamParticipantIds = createSelector(
  selectWorkspaceParticipantIds,
  selectParticipantsIdMap,
  (state, workspaceId) => (participantId) =>
    isParticipantWorkspaceTeamMember(state, participantId, workspaceId),
  (state) => (participantId) =>
    selectParticipantJoinedTeamNames(state, participantId),
  (partIds, partIdMap, isTeamMember, selectTeamNames) => {
    partIds = partIds || []
    partIdMap = partIdMap || {}

    const filteredIds = partIds.filter((id) =>
      hasAccessRole(partIdMap[id]) && isTeamMember(id) ? true : false
    )
    return sortPartIds(filteredIds, partIdMap, selectTeamNames)
  }
)

export const selectWorkspaceNonTeamParticipantIds = createSelector(
  selectWorkspaceParticipantIds,
  selectParticipantsIdMap,
  (state, workspaceId) => (participantId) =>
    isParticipantWorkspaceTeamMember(state, participantId, workspaceId),
  (state) => (participantId) =>
    selectParticipantJoinedTeamNames(state, participantId),
  (partIds, partIdMap, isTeamMember, selectTeamNames) => {
    partIds = partIds || []
    partIdMap = partIdMap || {}
    const filteredIds = partIds.filter((id) =>
      hasAccessRole(partIdMap[id]) && !isTeamMember(id) ? true : false
    )
    return sortPartIds(filteredIds, partIdMap, selectTeamNames)
  }
)

export const selectWorkspaceAwaitingAcctParticipantIds = createSelector(
  selectWorkspaceParticipantIds,
  selectParticipantsIdMap,
  (state) => (participantId) =>
    selectParticipantJoinedTeamNames(state, participantId),
  (partIds, partIdMap, selectTeamNames) => {
    partIds = partIds || []
    partIdMap = partIdMap || {}
    const filteredIds = partIds.filter((id) =>
      hasAccessRoleAwaitingAcct(partIdMap[id] || {})
    )
    return sortPartIds(filteredIds, partIdMap, selectTeamNames)
  }
)

export const selectIssueParticipantArray = createSelector(
  selectWorkspaceTeamParticipantIds,
  selectWorkspaceNonTeamParticipantIds,
  selectWorkspaceAwaitingAcctParticipantIds,
  selectParticipantsIdMap,
  (
    workspaceTeamParticipantIds,
    workspaceNonTeamParticipantIds,
    workspaceAwaitingAcctParticipantIds,
    participantReducer
  ) => {
    return [
      ...workspaceTeamParticipantIds,
      ...workspaceNonTeamParticipantIds,
      ...workspaceAwaitingAcctParticipantIds,
    ].map((participantID) => participantReducer[participantID])
  }
)

export const selectWorkspaceActiveParticipantIds = createSelector(
  selectWorkspaceParticipantIds,
  selectParticipantsIdMap,
  (state) => (participantId) =>
    selectParticipantJoinedTeamNames(state, participantId),
  (partIds, partIdMap, selectTeamNames) => {
    partIds = partIds || []
    partIdMap = partIdMap || {}
    const filteredIds = partIds.filter((id) =>
      hasAccessRole(partIdMap[id] || {})
    )
    const finalIds = sortPartIds(filteredIds, partIdMap, selectTeamNames)
    return finalIds
  }
)

export const selectWorkspaceActiveParticipants = createSelector(
  selectWorkspaceActiveParticipantIds,
  selectParticipantsIdMap,
  (ids, idMap) => ids?.map((id) => idMap[id])
)

export const selectWorkspaceParticipantCount = (state, workspaceId) =>
  selectWorkspaceTeamParticipantIds(state, workspaceId).length +
  selectWorkspaceNonTeamParticipantIds(state, workspaceId).length +
  selectWorkspaceAwaitingAcctParticipantIds(state, workspaceId).length

// Issue/App
export const selectIssueTrendName = (state: RootState, issueId: number) =>
  selectTrendNameById(state, selectIssueTrendId(state, issueId))
export const selectIssueTrendColor = (state: RootState, issueId: number) =>
  selectTrendColorById(state, selectIssueTrendId(state, issueId))

// Workspace/Conference
export const selectWorkspaceConferenceIds = createSelector(
  privSelectWorkspaceConferenceIds,
  selectConferencesIdMap,
  (conferenceIds: number[], idMap: ConferenceState) => {
    const result = (conferenceIds || []).slice()
    result.sort((a: number, b: number) => {
      const aConf = idMap[a] || {}
      const bConf = idMap[b] || {}

      const aScheduled = aConf.Scheduled || 0
      const bScheduled = bConf.Scheduled || 0

      if (!aScheduled && !bScheduled) {
        if (aConf.ConferenceID > bConf.ConferenceID) return 1
        else if (aConf.ConferenceID < bConf.ConferenceID) return -1
      } else if (bScheduled > aScheduled) return 1
      else if (bScheduled < aScheduled) return -1

      return 0
    })

    return result
  }
)

export const selectUniqueOrgTagNames = createSelector(
  selectInboxIdMap,
  selectOrgTagIdMap,
  (inboxes, orgTags) => {
    const nameSet = new Set()
    const tagNames: DenormalizedOrgTag[] = []
    for (let tagId in orgTags) {
      let tag = orgTags[tagId]
      const denormalized = getDenormalizedOrgTag(tag, inboxes)
      const { TagKey } = denormalized

      if (!nameSet.has(TagKey)) {
        nameSet.add(TagKey)
        tagNames.push(denormalized)
      }
    }

    return tagNames.sort((a, b) => {
      if (a.TagSortKey > b.TagSortKey) return 1
      if (a.TagSortKey < b.TagSortKey) return -1

      return 0
    })
  }
)

export function getDenormalizedOrgTag(
  tag: OrgTag,
  inboxes: InboxState
): DenormalizedOrgTag {
  tag = tag || {}

  let TagName: string
  let tagValue: string

  // Try to get the tag name from the inbox name
  if (tag.TagType === 'inbox' && tag.TagTypePkGuid) {
    TagName = inboxes[tag.TagTypePkGuid]?.inboxName?.trim()
    tagValue = tag.TagTypePkGuid
  }

  if (!TagName) TagName = tag.TagName?.trim() || ''

  if (!tagValue) tagValue = TagName.toLocaleLowerCase()

  const TagType = tag.TagType || ''
  const TagKey = `${TagType !== 'alert' ? tagValue : ''}_tagType_${TagType}`
  const TagSortKey = `${TagName.toLocaleLowerCase()}_tagType_${TagType}`

  return {
    TagKey,
    TagSortKey,
    TagName,
    TagType,
    TagTypePkGuid: tag.TagTypePkGuid,
  }
}
