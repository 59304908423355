// @ts-nocheck
// TODO: Typescript
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classes from './ParticipantOnlineStatus.module.scss'
import { cx } from '../_utils/objectUtils'
import { connect } from 'react-redux'
import { isParticipantOnline } from '../_rootConfigs/rootSelectors'

export class ParticipantOnlineStatus extends Component {
  render() {
    const { isOnline, className } = this.props
    return (
      <span
        className={cx(classes.root, className, isOnline ? 'bg-success' : '')}
      ></span>
    )
  }
}

ParticipantOnlineStatus.propTypes = {
  isOnline: PropTypes.bool,
  EmailAddress: PropTypes.string,
  className: PropTypes.string,
}

ParticipantOnlineStatus.defaultProps = {
  isOnline: false,
  EmailAddress: '',
}

function mapStatetoProps(state, ownProps) {
  return {
    isOnline: isParticipantOnline(state, ownProps.EmailAddress),
  }
}

export default connect(mapStatetoProps)(ParticipantOnlineStatus)
