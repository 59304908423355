// @ts-nocheck
// TODO: Typescript
import { generateCrudTypes, generateTypes } from '../_utils/reduxUtils'

export const TaskConstants = generateCrudTypes('TASK')
export const TaskDetailConstants = generateCrudTypes('TASK_DETAIL')
export const TaskCommentConstants = generateCrudTypes('TASK_COMMENT')
export const TASK_ITEM_SORT = 'TASK_ITEM_SORT'

// Task Read
export const UPDATE_TASK_READ = generateTypes('UPDATE_TASK_READ')
export const FETCH_TASK_UNREAD_COUNT = generateTypes('FETCH_TASK_UNREAD_COUNT')
export const INCREMENT_TASK_UNREAD_COUNT = 'INCREMENT_UNREAD_TASK_COUNT'
export const FETCH_LATEST_TASK_DATES = generateTypes('FETCH_LATEST_TASK_DATES')
export const SET_IS_VIEWING_TASK = 'SET_IS_VIEWING_TASK'
export const EXPORT_TASK = generateTypes('EXPORT_TASK')

export function getTaskCsvUploadID(TaskID: number) {
  return 'Task_List_CSV' + TaskID
}

export const timeOptions = [
  '12:00 am',
  '12:15 am',
  '12:30 am',
  '12:45 am',
  '1:00 am',
  '1:15 am',
  '1:30 am',
  '1:45 am',
  '2:00 am',
  '2:15 am',
  '2:30 am',
  '2:45 am',
  '3:00 am',
  '3:15 am',
  '3:30 am',
  '3:45 am',
  '4:00 am',
  '4:15 am',
  '4:30 am',
  '4:45 am',
  '5:00 am',
  '5:15 am',
  '5:30 am',
  '5:45 am',
  '6:00 am',
  '6:15 am',
  '6:30 am',
  '6:45 am',
  '7:00 am',
  '7:15 am',
  '7:30 am',
  '7:45 am',
  '8:00 am',
  '8:15 am',
  '8:30 am',
  '8:45 am',
  '9:00 am',
  '9:15 am',
  '9:30 am',
  '9:45 am',
  '10:00 am',
  '10:15 am',
  '10:30 am',
  '10:45 am',
  '11:00 am',
  '11:15 am',
  '11:30 am',
  '11:45 am',
  '12:00 pm',
  '12:15 pm',
  '12:30 pm',
  '12:45 pm',
  '1:00 pm',
  '1:15 pm',
  '1:30 pm',
  '1:45 pm',
  '2:00 pm',
  '2:15 pm',
  '2:30 pm',
  '2:45 pm',
  '3:00 pm',
  '3:15 pm',
  '3:30 pm',
  '3:45 pm',
  '4:00 pm',
  '4:15 pm',
  '4:30 pm',
  '4:45 pm',
  '5:00 pm',
  '5:15 pm',
  '5:30 pm',
  '5:45 pm',
  '6:00 pm',
  '6:15 pm',
  '6:30 pm',
  '6:45 pm',
  '7:00 pm',
  '7:15 pm',
  '7:30 pm',
  '7:45 pm',
  '8:00 pm',
  '8:15 pm',
  '8:30 pm',
  '8:45 pm',
  '9:00 pm',
  '9:15 pm',
  '9:30 pm',
  '9:45 pm',
  '10:00 pm',
  '10:15 pm',
  '10:30 pm',
  '10:45 pm',
  '11:00 pm',
  '11:15 pm',
  '11:30 pm',
  '11:45 pm',
]
