import React from 'react'
import { Card, CardBody, CardTitle, CardText } from 'reactstrap'
import { IconDefinition as IconDefinitionSolid } from '@fortawesome/pro-solid-svg-icons'
import { IconDefinition as IconDefinitionLight } from '@fortawesome/pro-light-svg-icons'
import { IconDefinition as IconDefinitionDuotone } from '@fortawesome/pro-duotone-svg-icons'
import { IconDefinition as IconDefinitionRegular } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import dayjs from 'dayjs'
import dayjsPluginUTC from 'dayjs/plugin/utc'
import DOMPurify from 'dompurify'

dayjs.extend(dayjsPluginUTC)
interface Props {
  itemTitle: string
  itemText: string
  itemContent: string
  itemDate: string
  itemContentAsHTML?: boolean
  icon?: {
    IconDefinition:
      | IconDefinitionSolid
      | IconDefinitionLight
      | IconDefinitionDuotone
      | IconDefinitionRegular
    onClick?: () => {}
  }
  actionButton1?: () => {}
  actionButton2?: () => {}
  dateAlign: string
  incidentID: string
}

export const UserReportMenuItem = (menuDetails: Props) => {
  return (
    <>
      <Card className={'mb-2'} id={'card' + menuDetails.incidentID}>
        <CardBody>
          <CardTitle>
            {menuDetails.icon && (
              <>
                <FontAwesomeIcon
                  icon={menuDetails.icon.IconDefinition}
                  onClick={() => {
                    if (menuDetails.icon?.onClick) {
                      menuDetails.icon?.onClick()
                    }
                  }}
                />
              </>
            )}
            <h4
              style={{
                color: '#3E91C5',
                fontWeight: 'bold',
              }}
            >
              {menuDetails.itemTitle}
            </h4>
          </CardTitle>
          <CardText className="mt-3 clearfix">
            <span>{menuDetails.itemText}</span>
          </CardText>
          <CardText>
            <span className={menuDetails.dateAlign}>
              {dayjs
                .utc(menuDetails.itemDate)
                .local()
                .format('M/DD/YYYY, h:mm:ss A')}
            </span>
          </CardText>
          <CardText className="d-none">
            {!menuDetails.itemContentAsHTML && (
              <span>{menuDetails.itemContent}</span>
            )}
            {menuDetails.itemContentAsHTML && (
              <span
                className="mt-4 d-inline-flex"
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(menuDetails.itemContent),
                }}
              />
            )}
          </CardText>
        </CardBody>
      </Card>
    </>
  )
}
