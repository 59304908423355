import React from 'react'
import {
  TextField as MUITextField,
  StandardTextFieldProps,
} from '@material-ui/core'
import classes from './TextField.module.scss'

interface Props extends StandardTextFieldProps {
  required?: boolean
  id: string
  name: string
  defaultValue?: string
  placeholder?: string
  onChange: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>
  multiline?: boolean
  minRows?: number
  maxRows?: number
}
export const TextField = ({
  required,
  id,
  name,
  defaultValue,
  placeholder,
  onChange,
  multiline,
  minRows,
  maxRows,
  ...rest
}: Props) => {
  return (
    <MUITextField
      {...rest}
      className={classes.textfield}
      required={!!required}
      id={id}
      name={name}
      defaultValue={defaultValue}
      placeholder={placeholder}
      variant="standard"
      onChange={onChange}
      multiline={!!multiline}
      minRows={minRows}
      maxRows={maxRows}
    />
  )
}
