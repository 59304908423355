import * as React from 'react'
import { http } from '../_utils/http'
import { CircularProgress } from '../Shared/LoadingBackdrop'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser } from '@fortawesome/pro-solid-svg-icons'
import classes from './AdminConsolePage.module.scss'

interface Props {
  OrgID: string
}

export const AdminConsoleAlertInboxUsers = ({ OrgID }: Props) => {
  const [alertInboxUsersList, setAlertInboxUsersList] = React.useState<any>('')

  const getAlertInboxUsersList = React.useCallback(async (): Promise<any> => {
    var customHeaders: any = []

    customHeaders.push({ name: 'orgid', value: `${OrgID}` })

    const result = await http<any>({
      path: '/Issue/AlertInboxUsersList',
      headers: customHeaders,
    })
    if (result.ok && result.body) {
      setAlertInboxUsersList(result.body)
    } else {
      setAlertInboxUsersList('')
    }
  }, [OrgID])

  React.useEffect(() => {
    getAlertInboxUsersList()
  }, [getAlertInboxUsersList])

  function renderAdminConsoleAlertInboxUsers() {
    if (!alertInboxUsersList) return <CircularProgress />

    if (!alertInboxUsersList.status) return <CircularProgress />

    if (alertInboxUsersList.status !== 'success')
      return <>{JSON.stringify(alertInboxUsersList, null, 2)}</>

    return (
      <>
        <div className={classes.OrganizationManagementSection}>
          <div
            className={classes.OrganizationManagementSectionHeader}
            style={{ borderBottom: '1px solid #000000' }}
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                marginBottom: '10px',
              }}
            >
              <FontAwesomeIcon icon={faUser} size={'2x'} />{' '}
              &nbsp;&nbsp;&nbsp;&nbsp; <div>ALERT INBOX TEAMS AND USERS</div>
            </div>
          </div>
          <div>{renderAlertInboxTableRows()}</div>
        </div>
      </>
    )
  }

  function renderAlertInboxTableRows() {
    if (!alertInboxUsersList.alertInboxUsers) {
      return <i>no Alert Inboxes found</i>
    }
    return (
      <>
        {alertInboxUsersList.alertInboxUsers.map(
          (alertInboxTeam: any, orpIdx: number) => {
            return (
              <div
                className={classes.majorSectionDiv}
                key={alertInboxTeam.inboxName}
              >
                <b> {alertInboxTeam.inboxName}</b>
                {renderAlertInboxTeams(alertInboxTeam.team)}
              </div>
            )
          }
        )}
      </>
    )
  }
  function renderAlertInboxTeams(alertInboxTeams: any) {
    return (
      <>
        {alertInboxTeams.map((alertInboxTeam: any, orpIdx: number) => {
          return (
            <div key={alertInboxTeam.TeamName}>
              Team: {alertInboxTeam.TeamName}
              {renderIssueTeamTableRoles(alertInboxTeam.roles)}
            </div>
          )
        })}
      </>
    )
  }
  function renderIssueTeamTableRoles(roles: any) {
    return (
      <div>
        {roles.map((role: any) => {
          return (
            <div className={classes.minorSectionDiv} key={role.RoleDescription}>
              {role.RoleDescription}
              {renderIssueTeamTableRoleMembers(role.member)}
            </div>
          )
        })}
      </div>
    )
  }
  function renderIssueTeamTableRoleMembers(members: any) {
    return (
      <div className={classes.minorSectionDiv}>
        {members.map((member: any) => {
          return (
            <React.Fragment key={member.email_address}>
              {member.email_address}
              <br />
            </React.Fragment>
          )
        })}
      </div>
    )
  }

  return <>{renderAdminConsoleAlertInboxUsers()}</>
}
