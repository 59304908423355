// @ts-nocheck
// TODO: Typescript
import React from 'react'
import Configure from './Configure'
import { prefixSelectorWithApp } from '../_utils/domUtils'

const ConfigurePageExport = (props) => (
  <Configure id={prefixSelectorWithApp('ConfigureTeamsPage')} {...props} />
)

export default ConfigurePageExport
