import React from 'react'
import { http } from '../_utils/http'
import { CircularProgress } from '../Shared/LoadingBackdrop'
import { useIsMounted } from '../_utils/hooks'
import classes from './IssueDynamicForm.module.scss'
import { HiddenFormWithValues } from '../Playbook/HiddenFormWithValues'
import { selectFormEditorSite } from './_selectors'
import { useAppSelector } from '../_utils/reactReduxHooks'

interface Props {
  OrgID: string
}

export const IssueDynamicFormEditorTool = ({ OrgID }: Props) => {
  const [isFetchingToolAuth, setIsFetchingToolAuth] =
    React.useState<boolean>(false)

  const [toolAuth, setToolAuth] = React.useState<any>()
  const isMounted = useIsMounted()
  const formEditorSite = useAppSelector(selectFormEditorSite)

  React.useEffect(() => {
    const getDynamicForm = async (): Promise<any> => {
      setIsFetchingToolAuth(true)
      let customHeaders: any = []
      customHeaders.push({ name: 'orgid', value: OrgID })

      const result = await http<any>({
        path: '/Issue/DynamicFormsToolAuth',
        headers: customHeaders,
      })
      if (result.ok && result.body && isMounted()) {
        setToolAuth(result.body)
      } else {
        if (isMounted()) {
          setToolAuth(undefined)
        }
      }
      if (isMounted()) {
        setIsFetchingToolAuth(false)
      }
    }
    getDynamicForm()
  }, [OrgID, isMounted])

  if (!toolAuth) {
    return (
      <div className={classes.loadingContainer}>
        <CircularProgress position="center" />
      </div>
    )
  }
  if (isFetchingToolAuth)
    return (
      <div className={classes.loadingContainer}>
        <CircularProgress position="center" />
      </div>
    )

  let HostURL = formEditorSite // 'https://formeditor-dev.incaseofcrisis.com'
  let hfvb: any = {}
  hfvb['guid'] = toolAuth.login_guid
  return (
    <div style={{ width: '100%', height: '100%' }}>
      {/* Here is where the tool will go with the GUID
      <pre>{JSON.stringify(toolAuth, null, 2)}</pre>
      Here is hfvb
      <pre>{JSON.stringify(hfvb, null, 2)}</pre> */}
      <HiddenFormWithValues
        HostURL={HostURL}
        HostPage="Home/LoginGUID"
        HdnVals={hfvb}
      />
    </div>
  )
}
