// @ts-nocheck
// TODO: Typescript
import React, { Component } from 'react'
import { ContentViewer } from '../Shared/ContentViewer'
import { connect } from 'react-redux'
import { ConferenceActions } from './_actions'
import PropTypes from 'prop-types'
import { getLocalDate } from '../_utils/dateUtils'
import { sanitizeHTML } from '../_utils/domUtils'
import { appDateTimeFormatConf } from '../_constants'
import moment from 'moment-timezone'
import { selectConferenceById } from './_selectors'

interface Props {
  ConferenceID: number
  open: boolean
  confirm?: any
  cancel: any
  sanitizeInstructions: boolean
  Instructions?: string
  Scheduled?: Date
}
export class ConferenceInstructionDialog extends Component<Props> {
  constructor(props) {
    super(props)
    this.state = {
      Scheduled: undefined,
    }
    this.handleConfirmation = this.handleConfirmation.bind(this)
  }

  handleConfirmation() {
    const { confirm, Scheduled, ConferenceID } = this.props

    if (confirm) confirm({ Scheduled, ConferenceID })
  }

  render() {
    const { open, cancel, Scheduled, Instructions, sanitizeInstructions } =
      this.props

    return (
      <ContentViewer
        open={open}
        onClose={cancel}
        headerTitle={
          Scheduled
            ? moment(getLocalDate(Scheduled))
                .format(appDateTimeFormatConf)
                .toString()
            : ''
        }
        isDialog={true}
      >
        {sanitizeInstructions ? (
          <div dangerouslySetInnerHTML={sanitizeHTML(Instructions)}></div>
        ) : (
          <div
            dangerouslySetInnerHTML={{
              __html: Instructions,
            }}
          ></div>
        )}
      </ContentViewer>
    )
  }
}

ConferenceInstructionDialog.propTypes = {
  ConferenceID: PropTypes.number,
  Conference: PropTypes.string,
  Instructions: PropTypes.string,
  Scheduled: PropTypes.any,
  open: PropTypes.bool,
  confirm: PropTypes.func,
  cancel: PropTypes.func,
  sanitizeInstructions: PropTypes.bool,
}

ConferenceInstructionDialog.defaultProps = {
  sanitizeInstructions: true,
}

const mapStateToProps = (state, ownProps) => {
  const conference = selectConferenceById(state, ownProps.ConferenceID) || {}
  const { Scheduled, Instructions } = conference
  return {
    Scheduled,
    Instructions,
  }
}

const mapDispatchToProps = {
  confirm: ConferenceActions.delete,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConferenceInstructionDialog)
