// @ts-nocheck
// TODO: Typescript
import React, { Component, Fragment } from 'react'
import { ListItem, Button } from '@material-ui/core'
import { Card, CardContent, CardActions, IconButton } from '@material-ui/core'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import Moment from 'react-moment'
import { appDateTimeFormatConf, appDateFormatConf } from '../_constants'
import { extractURLfromString } from '../_utils/urlUtils'
import { addNotification, closeNotification } from '../_actions'
import {
  getLocalDate,
  getDayNameFromDate,
  addDaysToDate,
  getClearedDateTime,
  getNumericalDayFromDate,
} from '../_utils/dateUtils'
import classes from './ConferenceListItem.module.scss'
import { MobileLayout, DesktopLayout } from '../Layouts/ResponsiveHidden'
import SwipeableListItem from '../Shared/SwipeableListItem'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faPhoneAlt,
  faCommentAlt,
  faVideo,
  faPencilAlt,
  faDownload,
} from '@fortawesome/pro-regular-svg-icons'
import { isTarget } from '../_utils/domUtils'
import { cx } from '../_utils/objectUtils'
import { selectConferenceById } from './_selectors'
import { isMobileApp } from '../_selectors'

interface Props {
  ConferenceID: any
  IssueTeamWorkspaceID: any
  active?: boolean
  localizeDates: boolean
  onError?: any
  onClick: any
  onDeleteConfirm?: any
  onOpenDialog: any
  isMobileApp: boolean
  disabled: boolean
  onEditInfo?: any
  Scheduled?: Date
  ScheduledEnd?: Date
  Instructions?: string
  Link?: string
  msTeamsInfo?: string
  isMsTeams?: boolean
  Title?: string
}

export class ConferenceListItem extends Component<Props> {
  constructor(props: any) {
    super(props)
    this.state = {
      formDialog: '',
    }
    this.handleOnClick = this.handleOnClick.bind(this)
    this.handleNothing = this.handleNothing.bind(this)
    this.formatLink = this.formatLink.bind(this)
    this.handleDeleteClick = this.handleDeleteClick.bind(this)
    this.handleMouseHover = this.handleMouseHover.bind(this)
    this.openDeleteConfirm = this.openDeleteConfirm.bind(this)
    this.openInstructionDialog = this.openInstructionDialog.bind(this)
    this.goToDetail = this.goToDetail.bind(this)
    this.callPhone = this.callPhone.bind(this)
    this.openLink = this.openLink.bind(this)
    this.editInfo = this.editInfo.bind(this)
    this.downloadIcsFile = this.downloadIcsFile.bind(this)
  }

  renderItem(confLink) {
    const { Instructions, active, Phone, Link, Title, IcsFile } = this.props

    const className = active ? classes.rootactive : classes.root
    const iconClassName = active ? classes.iconactive : classes.icon

    /*if (stripedInstructions.length > 35)
            stripedInstructions = stripedInstructions.substring(0, 35) + ' ...';*/

    return (
      <Card
        onClick={this.goToDetail}
        className={cx(className, classes.listItemBorder)}
        raised={false}
      >
        <CardContent className={classes.content}>
          <div>
            <span
              className={cx(
                active ? classes.active : '',
                'single-line-text w-100 d-inline-block'
              )}
            >
              {Title}
            </span>
          </div>
          {this.renderPhoneLink()}
        </CardContent>
        <div className="clearfix">
          <CardActions className={classes.actions}>
            {Phone ? (
              <IconButton ref={(el) => (this._phoneBtn = el)}>
                <FontAwesomeIcon
                  className={cx(classes.iconColor, iconClassName)}
                  icon={faPhoneAlt}
                  title={'Call Meeting Number'}
                />
              </IconButton>
            ) : (
              ''
            )}
            {Link ? (
              <IconButton ref={(el) => (this._linkBtn = el)}>
                <FontAwesomeIcon
                  className={cx(iconClassName, classes.iconColor)}
                  icon={faVideo}
                  title={'Connect To Meeting'}
                />
              </IconButton>
            ) : (
              ''
            )}
            {Instructions ? (
              <IconButton ref={(el) => (this._instBtn = el)}>
                <FontAwesomeIcon
                  className={cx(iconClassName, classes.iconColor)}
                  icon={faCommentAlt}
                  title={'Meeting Instructions'}
                />
              </IconButton>
            ) : (
              ''
            )}
            {IcsFile ? (
              <IconButton ref={(el) => (this._icsBtn = el)}>
                <FontAwesomeIcon
                  className={cx(iconClassName, classes.iconColor)}
                  icon={faDownload}
                  title={'Download iCal file'}
                />
              </IconButton>
            ) : (
              ''
            )}
            {this.props.onEditInfo && (
              <IconButton ref={(el) => (this._editBtn = el)}>
                <FontAwesomeIcon
                  className={cx(iconClassName, classes.iconColor)}
                  icon={faPencilAlt}
                  title={'Edit Meeting Info'}
                />
              </IconButton>
            )}
          </CardActions>
        </div>
      </Card>
    )
  }

  renderPhoneLink() {
    const {
      Scheduled,
      Phone,
      PhoneCountryCode,
      Link,
      RepeatType,
      localizeDates,
      ScheduledEnd,
      IsAllDay,
      RepeatWeekdays,
      msTeamsInfo,
      isMsTeams,
    } = this.props
    let repeatHtml = null
    let repeatCalDays = null
    if (RepeatWeekdays) {
      const repeatTheseDays = RepeatWeekdays?.split(',')
      const daysOfWeek = [
        'Sunday',
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday',
      ]
      let repeatDays = repeatTheseDays?.map((el) => {
        return daysOfWeek[el]
      })
      repeatCalDays = repeatDays?.join(', ')
    }

    if (Scheduled) {
      if (RepeatType === 1)
        if (repeatCalDays && repeatCalDays?.length > 0) {
          repeatHtml = <div>Repeats every {repeatCalDays}</div>
        } else {
          repeatHtml = <div>Repeats every {getDayNameFromDate(Scheduled)}</div>
        }
      else if (RepeatType === 2) repeatHtml = <div>Repeats every weekday</div>
      else if (RepeatType === 3)
        repeatHtml = <div>Repeats every hour, every weekday</div>
      else if (RepeatType === 4)
        repeatHtml = (
          <div>
            Repeats every month on the {getNumericalDayFromDate(Scheduled)}
          </div>
        )
      else if (RepeatType === 5) repeatHtml = <div>Repeats each year</div>
    }

    let scheduledFormatted = Scheduled
    let scheduledEndFormatted = ScheduledEnd
    let dateFormat = appDateTimeFormatConf

    if (IsAllDay) {
      if (scheduledFormatted) scheduledFormatted = getClearedDateTime(Scheduled)
      if (scheduledEndFormatted)
        scheduledEndFormatted = addDaysToDate(
          getClearedDateTime(ScheduledEnd),
          -1
        )

      dateFormat = appDateFormatConf
    }

    return (
      <div className={classes.divsecondary}>
        <div>
          {scheduledFormatted ? (
            <Moment
              local
              format={dateFormat}
              date={
                localizeDates
                  ? getLocalDate(scheduledFormatted)
                  : scheduledFormatted
              }
              style={isMsTeams ? { fontSize: '1rem' } : {}}
            />
          ) : (
            <span></span>
          )}

          {scheduledEndFormatted &&
          (!IsAllDay ||
            scheduledEndFormatted?.getTime() !==
              scheduledFormatted?.getTime()) ? (
            <Fragment>
              <span>&nbsp;-&nbsp;</span>
              <Moment
                local
                format={dateFormat}
                date={
                  localizeDates
                    ? getLocalDate(scheduledEndFormatted)
                    : scheduledEndFormatted
                }
                style={isMsTeams ? { fontSize: '1rem' } : {}}
              />
            </Fragment>
          ) : (
            <span></span>
          )}
          {IsAllDay && <span> (All Day)</span>}
        </div>
        {repeatHtml}
        {Phone ? (
          <p className={classes.psecondary}>
            {'Phone: (' + PhoneCountryCode + ') ' + Phone}
          </p>
        ) : (
          ''
        )}
        {msTeamsInfo && <p className={classes.psecondary}>{msTeamsInfo}</p>}
        {!isMsTeams && Link && (
          <p className={classes.psecondary}>{'Link: ' + Link}</p>
        )}
      </div>
    )
  }

  render() {
    const { Instructions, disabled } = this.props
    const confLink = this.formatLink(Instructions) || ''
    return (
      <Fragment>
        <DesktopLayout>
          <ListItem
            onMouseEnter={this.handleMouseHover}
            onMouseLeave={this.handleMouseHover}
          >
            {this.renderItem(confLink)}
            {this.state.isHovering && !!this.props.onDeleteConfirm && (
              <Button onClick={this.handleDeleteClick} disabled={disabled}>
                Remove
              </Button>
            )}
          </ListItem>
        </DesktopLayout>
        <MobileLayout>
          <SwipeableListItem
            onSwipe={this.openDeleteConfirm}
            threshold={0.5}
            background="Remove"
            onClick={this.handleClick}
            disabled={disabled}
          >
            {this.renderItem(confLink)}
          </SwipeableListItem>
        </MobileLayout>
      </Fragment>
    )
  }

  handleCloseFormDialog() {
    if (this.state.formDialog) {
      this.setState({
        formDialog: '',
      })
    }
  }

  goToDetail(evt) {
    const { Phone_E164, Link, IcsFile, Title } = this.props
    const { _phoneBtn, _linkBtn, _instBtn, _editBtn, _icsBtn } = this

    if (isTarget(evt, _phoneBtn)) this.callPhone(Phone_E164)
    else if (isTarget(evt, _linkBtn)) this.openLink(Link)
    else if (isTarget(evt, _instBtn)) this.openInstructionDialog()
    else if (isTarget(evt, _editBtn)) this.editInfo()
    else if (isTarget(evt, _icsBtn)) this.downloadIcsFile(IcsFile, Title)
  }

  downloadIcsFile(icalFile, fileTitle) {
    let meetingFile = fileTitle + '-invite.ics'
    const blob = new Blob([icalFile], { type: 'text/plain;charset=utf-8' })
    saveAs(blob, meetingFile)
  }

  editInfo() {
    //{ ConferenceID, Scheduled, Instructions, Phone, Link } = this.props;
    const { onEditInfo } = this.props

    if (onEditInfo) onEditInfo(this.props)
  }

  callPhone(phone) {
    if (!phone.startsWith('+')) phone = '+' + phone
    phone = phone.replace(new RegExp(' ', 'g'), '')
    phone = phone.replace(new RegExp('-', 'g'), '')
    phone = phone.replace(/\)/g, '')
    phone = phone.replace(/\(/g, '')
    //alert(phone);
    window.open('tel:' + phone)
  }

  openLink(url) {
    // iOs accespts url with https only
    url = url.toString().replace('http://', 'https://')
    if (!url.includes('https://')) url = 'https://' + url
    localStorage.setItem('pageData', 'Data Retrieved from axios request')
    //alert(url);
    window.open(url, '_blank')
  }

  handleNothing(evt) {
    evt.preventDefault()
  }

  handleOnClick() {
    // check if conference link exists in instruction
    var instructions = this.props.Instructions || ''

    if (instructions.trim().length > 0) {
      instructions = instructions
        .toString()
        .replace('meet.google.com', 'https://meet.google.com')

      var url = extractURLfromString(instructions)
      if (url !== null) {
        this.openLink(url)
      } else {
        // check if phone number exists in instruction
        var tel = parseInt(this.props.Instructions.replace(/[^0-9]/g, ''))

        if (!isNaN(tel)) this.callPhone(tel)
        else
          this.props.onError({
            message: 'Neither link or phone number to join meeting were found.',
            type: 'error',
            duration: 3000,
          })
      }
    }
    if (this.props.onClick) this.props.onClick(this.props.ConferenceID)
  }

  replaceLinkOnAnchorTag(text) {
    const changedText = text.replace(
      /(https?:\/\/)([^ ]+)/g,
      '<a target="_blank" href="$&">$2</a>'
    )
    return changedText
  }

  formatLink(Instructions) {
    var link = ''
    if (typeof Instructions !== 'undefined' || Instructions != null) {
      Instructions = Instructions.toString().replace(
        'meet.google.com',
        'https://meet.google.com'
      )
      var url = extractURLfromString(Instructions)
      if (url !== null) {
        // iOs accespts url with https only
        link = url.toString().replace('http://', 'https://')
        if (!link.includes('https://')) link = 'https://' + link
      } else {
        // check if phone number exists in instruction
        var tel = parseInt(Instructions.replace(/[^0-9]/g, ''))

        if (!isNaN(tel)) link = 'tel:+' + tel
      }
    }
    return link
  }

  handleDeleteClick() {
    this.openDeleteConfirm()
  }

  handleMouseHover(evt) {
    this.setState({
      isHovering: evt.type === 'mouseenter',
    })
  }

  openDeleteConfirm() {
    const { onDeleteConfirm } = this.props
    if (onDeleteConfirm) onDeleteConfirm(this.getConference())
  }

  openInstructionDialog() {
    const { onOpenDialog } = this.props
    if (onOpenDialog) onOpenDialog(this.getConference())
  }

  getConference() {
    const {
      ConferenceID,
      Scheduled,
      ScheduledEnd,
      RepeatType,
      WeekStart,
      Instructions,
      Phone,
      Link,
      PhoneCountryCode,
      Phone_E164,
      Title,
      Participants,
      RepeatWeekdays,
      IcsFile,
    } = this.props

    return {
      ConferenceID,
      Scheduled,
      ScheduledEnd,
      RepeatType,
      WeekStart,
      Instructions,
      Phone,
      Link,
      PhoneCountryCode,
      Phone_E164,
      Title,
      Participants,
      RepeatWeekdays,
      IcsFile,
    }
  }
}

var mapDispatchToProps = {
  onSuccess: closeNotification,
  onError: (notification) => addNotification(notification),
}

const mapStateToProps = (state, ownProps) => {
  const conference = selectConferenceById(state, ownProps.ConferenceID) || {}
  const {
    Scheduled,
    ScheduledEnd,
    RepeatType,
    WeekStart,
    Instructions,
    Phone,
    Link,
    PhoneCountryCode,
    Phone_E164,
    IsAllDay,
    TimeZone,
    Title,
    Participants,
    RepeatWeekdays,
    IcsFile,
  } = conference
  return {
    Scheduled,
    ScheduledEnd,
    RepeatType,
    WeekStart,
    Instructions,
    isMobileApp: isMobileApp(state),
    Phone,
    Link,
    PhoneCountryCode,
    Phone_E164,
    IsAllDay,
    TimeZone,
    localizeDates: !IsAllDay,
    Title,
    Participants,
    RepeatWeekdays,
    IcsFile,
  }
}

ConferenceListItem.propTypes = {
  Scheduled: PropTypes.any,
  Instructions: PropTypes.string,
  ConferenceID: PropTypes.any,
  IssueTeamWorkspaceID: PropTypes.any,
  active: PropTypes.bool,
  localizeDates: PropTypes.bool,
  IcsFile: PropTypes.string,
  msTeamsInfo: PropTypes.string,
  isMsTeams: PropTypes.bool,
  Title: PropTypes.string,
}

ConferenceListItem.defaultProps = {
  localizeDates: true,
}

export default connect(mapStateToProps, mapDispatchToProps)(ConferenceListItem)
