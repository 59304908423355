import * as React from 'react'

import { IssueTaskList } from '../Shared/dashboardUtils'
import { useAppSelector } from '../../_utils/reactReduxHooks'
import { selectUserEmail } from '../../Auth/_selectors'
import dayjs from 'dayjs'

export const UserDashboardIssueTasks = ({
  tasks,
}: {
  tasks?: IssueTaskList
}) => {
  let tasksAssignedOverdue: number = 0
  let tasksAssignedDue: number = 0
  let tasksAssignedNoDueDate: number = 0
  let tasksAssignedCompleted: number = 0
  let totalTasks: number = 0
  const userEmail = useAppSelector(selectUserEmail)
  const rightNow = new Date()

  if (!tasks) {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
        }}
      >
        No Tasks
      </div>
    )
  }

  tasks?.forEach((task) => {
    task.taskItems.forEach((taskItem) => {
      if (taskItem.AssignedTo && taskItem.AssignedTo === userEmail) {
        addTaskCounts(taskItem)
      }
    })
  })

  function addTaskCounts(taskItem: any) {
    totalTasks += 1
    if (taskItem.CompletedUTC) {
      tasksAssignedCompleted += 1
    } else {
      if (!taskItem.DueByUTC) {
        tasksAssignedNoDueDate += 1
      } else {
        if (dayjs(rightNow).isAfter(dayjs(taskItem.DueByUTC))) {
          tasksAssignedOverdue += 1
        } else {
          tasksAssignedDue += 1
        }
      }
    }
  }

  return (
    <>
      <div
        style={{
          display: 'flex',
          width: '100%',
          height: '100%',
          alignItems: 'center',
          justifyContent: 'center',
          padding: '20px',
        }}
      >
        <div
          style={{
            display: 'flex',
            width: '100%',
            height: '100%',
            justifyContent: 'space-around',
            flexDirection: 'column',
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
            }}
          >
            <div>Completed</div>
            <div>{tasksAssignedCompleted}</div>
          </div>

          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
            }}
          >
            <div>Within Due Date</div>
            <div>{tasksAssignedDue}</div>
          </div>

          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
            }}
          >
            <div>No Due Date</div>
            <div>{tasksAssignedNoDueDate}</div>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
            }}
          >
            <div>Overdue</div>
            <div>{tasksAssignedOverdue}</div>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
              borderTop: '1px solid #000',
            }}
          >
            <div>TOTAL</div>
            <div>{totalTasks}</div>
          </div>
        </div>
      </div>
    </>
  )
}
