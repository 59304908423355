import * as React from 'react'
import { http } from '../../_utils/http'
import { CircularProgress } from '../../Shared/LoadingBackdrop'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faArrowDown,
  faArrowUp,
  faColumns,
} from '@fortawesome/pro-solid-svg-icons'
import classes from '../../Admin/AdminConsolePage.module.scss'
import Moment from 'react-moment'
import { addMinutesToDate } from '../../_utils/dateUtils'
import { Input, InputGroup, Table } from 'reactstrap'
import {
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  FormControl,
  InputLabel,
  Select,
  TextField,
  TableContainer,
  makeStyles,
} from '@material-ui/core'
import { isMobileApp } from '../../_selectors'

import moment from 'moment'
import Autocomplete from '@material-ui/lab/Autocomplete'
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers'
import DateFnsUtils from '@date-io/moment'
import { useAppSelector } from '../../_utils/reactReduxHooks'
import { AdminExportExcel } from '../../Admin/AdminExportExcel'
import CheckBox from '../../CustomMaterial/Checkbox'
import { AdminTaskItemQueryPanel } from './AdminTaskQueryTableSelector'

import {
  queryPanelTaskSettings,
  saveQueryPanelTaskSettings,
} from '../../_utils/apiUtils'
import { Spacer } from '../../Shared/Spacer'
import { useIsMounted } from '../../_utils/hooks'
import { Organization } from '../../Issue/_reducer'

interface Props {
  OrgIDList: Organization[]
}

interface QTaskItem {
  IssueID: number
  IssueName: string
  IssueType: string
  IssueMode: string
  OrgName: string
  TaskID: number
  OrgID: number
  TaskIsActiveYN: string
  TaskName: string
  LastActivityUTC: Date
  TaskItemID: number
  TaskItemStatus: string
  TaskItemStatusDescr: string
  TaskItemName: string
  Description: string
  Sequence: number
  CreatedUTC: Date
  CreatedBy: string
  CreatedAppAcctID: number
  AssignedTo: string
  DueByUTC: Date
  CompletedBy: string
  CompletedUTC: Date
  TaskItemHeaderYN: string
  OrgRoleID: number
  RoleName: string
}

interface QPickIssue {
  IssueID: number
  IssueName: string
}

interface QPickStatus {
  Status: string
  StatusDescr: string
}

interface QPickAssigned {
  AssignedTo: string
}

interface QPickRole {
  RoleName: string
}
interface QPickMode {
  IssueType: string
  IssueTypeDescr: string
}

interface QPicklist {
  OrgID: number
  pickIssue: [QPickIssue]
  pickStatus: [QPickStatus]
  pickAssigned: [QPickAssigned]
  pickRole: [QPickRole]
  pickMode: [QPickMode]
  IssueTaskItems: [QTaskItem]
}

type TableHeader =
  | 'Issue'
  | 'Task'
  | 'Responsible'
  | 'DueByUTC'
  | 'CompletedUTC'
  | 'Role'
type SortDirection = 'asc' | 'dec' | 'none'

export interface TaskSort {
  header: TableHeader
  sortDirection: SortDirection
}

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiInput-underline:after': {
      borderBottom: 'none', // Remove the underline when focused
    },
    '& .MuiInput-underline:before': {
      borderBottom: 'none', // Remove the underline when not focused
    },
  },
}))

export type AdminQueryTaskTableOption =
  | 'Responsible'
  | 'Role'
  | 'Due Date'
  | 'Completed'

export const AdminIssueTaskItemQueryPanel = ({ OrgIDList }: Props) => {
  const muiClasses = useStyles()

  const [tableSelectorOpen, setTableSelectorOpen] = React.useState(false)

  const adminQueryTableOptions: AdminQueryTaskTableOption[] = [
    'Responsible',
    'Role',
    'Due Date',
    'Completed',
  ]

  const [adminQueryTableValues, setAdminQueryTableValues] = React.useState<
    AdminQueryTaskTableOption[]
  >(adminQueryTableOptions)

  const [adminQueryTableEditedValues, setAdminQueryTableEditedValues] =
    React.useState<AdminQueryTaskTableOption[]>(adminQueryTableOptions)

  const [isFetchingPicklist, setIsFetchingPickList] =
    React.useState<boolean>(false)
  const [pickListValues, setPickListValues] = React.useState<QPicklist>()
  const [issueSelected, setIssueSelected] = React.useState<QPickIssue>()
  const [itemStatus, setItemStatus] = React.useState<string>('--')
  const [isAssigned, setIsAssigned] = React.useState<string>('--')
  const [assignedTo, setAssignedTo] = React.useState<string>('--')
  const [roleName, setRoleName] = React.useState<string>('--')
  const [issueType, setIssueType] = React.useState<string>('--')
  const [taskNameIncludes, setTaskNameIncludes] = React.useState<string>('')
  const [showOnly, setShowOnly] = React.useState<string>('')
  //const [severityID, setSeverityID] = React.useState<number>(-1)
  //const [issueType, setIssueType] = React.useState<string>('--')
  //const [responsible, setResponsible] = React.useState<string>('--')
  //const [tags, setTags] = React.useState<string>('--')
  //const [titleIncludes, setTitleIncludes] = React.useState<string>('')
  const [itemDueDateEarliest, setItemDueDateEarliest] = React.useState<Date>()
  const [itemDueDateLatest, setItemDueDateLatest] = React.useState<Date>()
  const [taskSort, setTaskSort] = React.useState<TaskSort>({
    header: 'Issue',
    sortDirection: 'asc',
  })
  const isMobile = useAppSelector((state) => isMobileApp(state))

  React.useEffect(() => {
    const getPickListValues = async (): Promise<any> => {
      setIsFetchingPickList(true)
      var customHeaders: any = []

      let orgListRequest = ''

      OrgIDList.forEach((org) => (orgListRequest += `${org.OrgID}, `))

      customHeaders.push({ name: 'OrgIDlist', value: `${orgListRequest}` })

      const result = await http<any>({
        path: '/Issue/IssueTaskItemQueryPanelPicklist',
        headers: customHeaders,
      })
      if (result.ok && result.body) {
        setPickListValues(result.body)
      } else {
        setPickListValues(undefined)
      }
      setIsFetchingPickList(false)
    }
    getPickListValues()
  }, [OrgIDList])

  const isMounted = useIsMounted()

  React.useEffect(() => {
    queryPanelTaskSettings()
      .then((data) => {
        if (
          !isMounted() ||
          Object.keys(data.QueryPanelTasksSettings).length < 1
        )
          return

        setAdminQueryTableValues(JSON.parse(data.QueryPanelTasksSettings))
        setAdminQueryTableEditedValues(JSON.parse(data.QueryPanelTasksSettings))
      })
      .catch((e) => e)
  }, [isMounted])

  const addOrRemoveFromList = (selectionOption: AdminQueryTaskTableOption) => {
    const removeIndex = adminQueryTableEditedValues.findIndex((section) => {
      return section === selectionOption
    })

    if (removeIndex > -1) {
      removeQueryTableValue(removeIndex)
    } else {
      addQueryTableValue(selectionOption)
    }
  }

  const addQueryTableValue = (value: AdminQueryTaskTableOption) => {
    setAdminQueryTableEditedValues((values) => [...values, value])
  }

  const removeQueryTableValue = (removeIndex: number) => {
    const copyList = [...adminQueryTableEditedValues]

    copyList.splice(removeIndex, 1)
    setAdminQueryTableEditedValues(copyList)
  }

  const shouldDisplayColumn = (columnName: string) => {
    return adminQueryTableValues.find((column: string) => column === columnName)
  }

  const setSortDirection = (header: TableHeader) => {
    if (header === taskSort.header) {
      if (taskSort.sortDirection === 'none') {
        setTaskSort({ header: header, sortDirection: 'dec' })
      } else if (taskSort.sortDirection === 'asc') {
        setTaskSort({ header: header, sortDirection: 'dec' })
      } else {
        setTaskSort({ header: header, sortDirection: 'asc' })
      }
    } else {
      setTaskSort({ header: header, sortDirection: 'asc' })
    }
  }

  const sortedTaskList = (issueList: QTaskItem[] | undefined) => {
    if (taskSort.sortDirection === 'none') return

    switch (taskSort.header) {
      case 'Issue':
        if (taskSort.sortDirection === 'asc') {
          return issueList?.sort((a, b) =>
            a.IssueName === b.IssueName
              ? a.Sequence < b.Sequence
                ? -1
                : 1
              : a.IssueName.localeCompare(b.IssueName)
          )
        } else if (taskSort.sortDirection === 'dec') {
          return issueList?.sort((a, b) =>
            a.IssueName === b.IssueName
              ? a.Sequence < b.Sequence
                ? -1
                : 1
              : b.IssueName.localeCompare(a.IssueName)
          )
        }
        break

      case 'Task':
        if (taskSort.sortDirection === 'asc') {
          return issueList?.sort((a, b) =>
            a.TaskItemName && b.TaskItemName
              ? a.TaskItemName.localeCompare(b.TaskItemName)
              : 0
          )
        } else if (taskSort.sortDirection === 'dec') {
          return issueList?.sort((a, b) =>
            a.TaskItemName && b.TaskItemName
              ? b.TaskItemName.localeCompare(a.TaskItemName)
              : 0
          )
        }
        break

      case 'Responsible':
        if (taskSort.sortDirection === 'dec') {
          return issueList?.sort((a, b) =>
            a.AssignedTo && b.AssignedTo
              ? b.AssignedTo.localeCompare(a.AssignedTo)
              : a.AssignedTo
              ? -1
              : 1
          )
        } else if (taskSort.sortDirection === 'asc') {
          return issueList?.sort((a, b) =>
            a.AssignedTo && b.AssignedTo
              ? a.AssignedTo.localeCompare(b.AssignedTo)
              : a.AssignedTo
              ? -1
              : 1
          )
        }
        break

      case 'DueByUTC':
        if (taskSort.sortDirection === 'dec') {
          return issueList?.sort((a, b) => {
            if (a.DueByUTC === b.DueByUTC) {
              return 0
            }
            return a.DueByUTC && b.DueByUTC
              ? a.DueByUTC < b.DueByUTC
                ? 1
                : -1
              : a.DueByUTC
              ? -1
              : 1
          })
        } else if (taskSort.sortDirection === 'asc') {
          return issueList?.sort((a, b) => {
            if (a.DueByUTC === b.DueByUTC) {
              return 0
            }
            return a.DueByUTC && b.DueByUTC
              ? a.DueByUTC > b.DueByUTC
                ? 1
                : -1
              : a.DueByUTC
              ? -1
              : 1
          })
        }
        break

      case 'CompletedUTC':
        if (taskSort.sortDirection === 'dec') {
          return issueList?.sort((a, b) => {
            if (a.CompletedUTC === b.CompletedUTC) {
              return 0
            }
            return a.CompletedUTC && b.CompletedUTC
              ? a.CompletedUTC < b.CompletedUTC
                ? 1
                : -1
              : a.CompletedUTC
              ? -1
              : 1
          })
        } else if (taskSort.sortDirection === 'asc') {
          return issueList?.sort((a, b) => {
            if (a.CompletedUTC === b.CompletedUTC) {
              return 0
            }
            return a.CompletedUTC && b.CompletedUTC
              ? a.CompletedUTC > b.CompletedUTC
                ? 1
                : -1
              : a.CompletedUTC
              ? -1
              : 1
          })
        }
        break

      case 'Role':
        if (taskSort.sortDirection === 'dec') {
          return issueList?.sort((a, b) =>
            a.RoleName && b.RoleName
              ? a.RoleName.localeCompare(b.RoleName)
              : a.RoleName
              ? -1
              : 1
          )
        } else if (taskSort.sortDirection === 'asc') {
          return issueList?.sort((a, b) =>
            a.RoleName && b.RoleName
              ? b.RoleName.localeCompare(a.RoleName)
              : a.RoleName
              ? -1
              : 1
          )
        }
        break
    }
  }

  const filterTaskItemList = (taskItemList: QTaskItem[] | undefined) => {
    var filtered: QTaskItem[] = []
    if (taskItemList) {
      for (var i = 0; i < taskItemList.length; i++) {
        let taskItem = taskItemList[i]
        var selected: boolean = true
        if (issueSelected && issueSelected.IssueID) {
          if (issueSelected.IssueID !== taskItem.IssueID) selected = false
        }
        if (itemStatus !== '--') {
          if (itemStatus !== taskItem.TaskItemStatus) selected = false
        }
        if (isAssigned !== '--') {
          if (isAssigned === 'Y' && !taskItem.AssignedTo) selected = false
          if (isAssigned === 'N' && taskItem.AssignedTo) selected = false
        }
        if (itemDueDateEarliest || itemDueDateLatest) {
          if (!taskItem.DueByUTC) {
            selected = false
          } else {
            var icd = new Date(taskItem.DueByUTC)
            var icdb = addMinutesToDate(icd, 0 - 24 * 60)
            if (itemDueDateLatest)
              if (icdb > itemDueDateLatest) selected = false
            if (itemDueDateEarliest)
              if (icd < itemDueDateEarliest) selected = false
          }
        }
        if (showOnly !== '') {
          if (showOnly === 'overdue') {
            if (!taskItem.DueByUTC) selected = false
            if (taskItem.CompletedUTC) selected = false // it has already been completed
            var idd = new Date(taskItem.DueByUTC)
            var rightNow = new Date()
            if (idd > rightNow) selected = false
          }
          if (showOnly === 'nodue') {
            if (taskItem.DueByUTC) selected = false
          }
        }
        if (assignedTo !== '--') {
          if (assignedTo === '--null--') {
            if (taskItem.AssignedTo) selected = false
          } else {
            if (!taskItem.AssignedTo) selected = false
            if (taskItem.AssignedTo !== assignedTo) selected = false
          }
        }
        if (roleName !== '--') {
          if (!taskItem.RoleName) selected = false
          if (taskItem.RoleName !== roleName) selected = false
        }
        if (issueType !== '--') {
          if (!taskItem.IssueType) selected = false
          if (taskItem.IssueType !== issueType) selected = false
        }
        if (taskNameIncludes !== '') {
          if (
            !(
              taskItem.TaskItemName &&
              taskItem.TaskItemName.toLowerCase().includes(
                taskNameIncludes.toLowerCase()
              )
            )
          )
            selected = false
        }
        if (selected) {
          filtered.push(taskItem)
        }
      }
    }
    return filtered
  }
  const taskItemList =
    taskSort.sortDirection === 'none'
      ? pickListValues?.IssueTaskItems
      : sortedTaskList(pickListValues?.IssueTaskItems)

  const filteredTaskItemList = filterTaskItemList(taskItemList)

  function niceDateFormat(theDate: Date | undefined) {
    let retDateStr = ''
    if (theDate) {
      retDateStr = moment.utc(theDate).local().format('YYYY/MM/DD')
    }
    return retDateStr
  }
  function niceDateTimeFormat(theDate: Date | undefined) {
    let retDateStr = ''
    if (theDate) {
      retDateStr = moment.utc(theDate).local().format('YYYY/MM/DD h:mm:ss A')
    }
    return retDateStr
  }

  function niceStatusLookup(theValue: string) {
    let retStatusLookup = ''
    if (theValue && pickListValues && pickListValues.pickStatus) {
      pickListValues.pickStatus.forEach(
        (plstat: QPickStatus, plIDX: number) => {
          if (plstat.Status === theValue) {
            retStatusLookup = plstat.StatusDescr
          }
        }
      )
    }
    return retStatusLookup
  }

  function niceModeLookup(theValue: string) {
    let retModeLookup = ''
    if (theValue && pickListValues && pickListValues.pickMode) {
      pickListValues.pickMode.forEach((pmstat: QPickMode, plIDX: number) => {
        if (pmstat.IssueType === theValue) {
          retModeLookup = pmstat.IssueTypeDescr
        }
      })
    }
    return retModeLookup
  }

  function niceAssignedLookup(theValue: string) {
    let retAssignedLookup = ''
    if (theValue) {
      if (theValue === 'Y') retAssignedLookup = 'Assigned'
      if (theValue === 'N') retAssignedLookup = 'Not Assigned'
    }
    return retAssignedLookup
  }

  function excelExport() {
    if (!isMobile) {
      var excelFileName: string = 'TaskListing'
      if (excelFileName.length > 25) {
        excelFileName = excelFileName.substring(0, 25)
      }

      var excelData: any[] = []
      const excelLegend: any[] = [
        {
          CreatedOn: niceDateTimeFormat(new Date()),
          'Issue Name': issueSelected?.IssueName,
          'Issue Mode': niceModeLookup(issueType),
          Status: niceStatusLookup(itemStatus),
          'Is Assigned': niceAssignedLookup(isAssigned),
          'Earliest Due Date': niceDateFormat(itemDueDateEarliest),
          'Latest Due Date': niceDateFormat(itemDueDateLatest),
          'Only Show': showOnly,
          Responsible: assignedTo && assignedTo === '--' ? '' : assignedTo,
          Role: roleName && roleName === '--' ? '' : roleName,
          'Task Name Includes': taskNameIncludes,
        },
      ]
      if (filteredTaskItemList) {
        filteredTaskItemList.forEach(
          (taskItemList: QTaskItem, taskIDX: number) => {
            var dataRow: any = {}
            dataRow['Issue'] = taskItemList.IssueName
            dataRow['Task'] = taskItemList.TaskItemName
            if (shouldDisplayColumn('Responsible'))
              dataRow['Responsible'] = taskItemList.AssignedTo
            if (shouldDisplayColumn('Responsible'))
              dataRow['Role'] = taskItemList.RoleName
            if (shouldDisplayColumn('Due Date'))
              dataRow['Due Date'] = niceDateTimeFormat(taskItemList.DueByUTC)
            if (shouldDisplayColumn('Completed'))
              dataRow['Completed'] = niceDateTimeFormat(
                taskItemList.CompletedUTC
              )
            excelData.push(dataRow)
          }
        )
      }
      return (
        <>
          {!isMobile && excelData.length > 0 && (
            <AdminExportExcel
              excelLegend={excelLegend}
              excelData={excelData}
              fileName={excelFileName}
            />
          )}
        </>
      )
    }
  }

  function renderPanel() {
    if (isFetchingPicklist) return <CircularProgress />
    if (!pickListValues)
      return <div>There are no tasks in this Organization</div>
    if (!pickListValues.IssueTaskItems)
      return <div>There are no tasks in this Organization</div>
    return (
      <>
        <div
          style={{
            display: 'flex',
            gridAutoRows: '1fr',
            gridTemplateColumns: '1fr 1fr 1fr',
            width: '100%',
            marginTop: '15px',
          }}
        >
          <div className={'col-12 col-lg-4 mb-0'}>
            <div className={classes.queryControlItem}>
              <InputLabel style={{ fontSize: '12px' }}>Issue:</InputLabel>
              <FormControl className="form-control">
                <Autocomplete
                  className="form-control"
                  options={
                    pickListValues?.pickIssue || [
                      {
                        IssueID: -999,
                        IssueName: 'no issues found',
                      } as QPickIssue,
                    ]
                  }
                  value={
                    issueSelected ||
                    ({
                      IssueID: -999,
                      IssueName: '-- All Issues --',
                    } as QTaskItem)
                  }
                  getOptionLabel={(issue) => {
                    return issue.IssueName
                  }}
                  getOptionSelected={(option) => !!option}
                  // getOptionSelected={(option: QIssue, value: QIssue) =>
                  //   option.IssueID === value.IssueID
                  // }
                  onChange={(event: any, newValue: any | null) => {
                    if (newValue && newValue.IssueName !== '-- All Issues --') {
                      setIssueSelected(newValue)
                    } else {
                      setIssueSelected(undefined)
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      key={params.id}
                      className={muiClasses.root}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          e.preventDefault()
                          e.stopPropagation()
                        }
                      }}
                      inputProps={{
                        ...params.inputProps,
                        onKeyDown: (e) => {
                          if (e.key === 'Enter') {
                            e.preventDefault()
                            e.stopPropagation()
                          }
                        },
                      }}
                    />
                  )}
                  autoComplete
                />
              </FormControl>
            </div>
            <div className={classes.queryControlItem}>
              <FormControl className="form-control">
                <InputLabel>Issue Mode:</InputLabel>
                <Select
                  name="IssueType"
                  native={true}
                  value={issueType}
                  onChange={(evt: any) => {
                    let { value } = evt.target
                    setIssueType(value)
                  }}
                >
                  <option value="--" key="statusUndef">
                    -- All Modes --
                  </option>
                  {pickListValues.pickMode &&
                    pickListValues.pickMode.map(
                      ({ IssueType, IssueTypeDescr }) => (
                        <option value={IssueType} key={IssueType}>
                          {IssueTypeDescr}
                        </option>
                      )
                    )}
                </Select>
              </FormControl>
            </div>
            <div className={classes.queryControlItem}>
              <FormControl className="form-control">
                <InputLabel>Status:</InputLabel>
                <Select
                  name="Status"
                  native={true}
                  value={itemStatus}
                  onChange={(evt: any) => {
                    let { value } = evt.target
                    setItemStatus(value)
                  }}
                >
                  <option value="--" key="statusUndef">
                    -- All Tasks --
                  </option>
                  {pickListValues.pickStatus &&
                    pickListValues.pickStatus.map(({ Status, StatusDescr }) => (
                      <option value={Status} key={Status}>
                        {StatusDescr}
                      </option>
                    ))}
                </Select>
              </FormControl>
            </div>
            <div className={classes.queryControlItem}>
              <FormControl className="form-control">
                <InputLabel>Assignment:</InputLabel>
                <Select
                  name="IssueAssigned"
                  native={true}
                  value={isAssigned}
                  onChange={(evt: any) => {
                    let { value } = evt.target
                    setIsAssigned(value)
                  }}
                >
                  <option value="--">-- All --</option>
                  <option value="Y">Assigned</option>
                  <option value="N">Not Assigned</option>
                </Select>
              </FormControl>
            </div>
          </div>
          <div className={'col-12 col-lg-4 mb-0'}>
            <div className={classes.queryControlItem}>
              <FormControl className="form-control">
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    clearable
                    style={{ color: 'black !important' }}
                    format="MM/DD/YYYY"
                    label="Earliest Due Date"
                    maxDate={itemDueDateLatest}
                    onChange={(event: any, newValue: any | null) => {
                      if (newValue) {
                        let newDate = new Date(newValue)
                        setItemDueDateEarliest(newDate)
                      } else {
                        setItemDueDateEarliest(undefined)
                      }
                    }}
                    value={itemDueDateEarliest ? itemDueDateEarliest : null}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                  />
                </MuiPickersUtilsProvider>
              </FormControl>
            </div>
            <div className={classes.queryControlItem}>
              <FormControl className="form-control">
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    clearable
                    format="MM/DD/YYYY"
                    label="Latest Due Date"
                    minDate={itemDueDateEarliest}
                    onChange={(event: any, newValue: any | null) => {
                      if (newValue) {
                        let newDate = new Date(newValue)
                        setItemDueDateLatest(newDate)
                      } else {
                        setItemDueDateLatest(undefined)
                      }
                    }}
                    value={itemDueDateLatest ? itemDueDateLatest : null}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                  />
                </MuiPickersUtilsProvider>
              </FormControl>
            </div>
            <div
              className={classes.queryControlItem}
              style={{ paddingTop: 20 }}
            >
              <InputLabel style={{ fontSize: '12px' }}>Only Show:</InputLabel>
              <FormControl className="form-control">
                <InputGroup component="fieldset">
                  <CheckBox
                    checked={showOnly === 'overdue' ? true : false}
                    onChange={(event: any) => {
                      if (event.target.checked) {
                        setShowOnly('overdue')
                      } else {
                        setShowOnly('')
                      }
                    }}
                  />
                  <span
                    style={{
                      position: 'relative',
                      top: 12,
                      cursor: 'pointer',
                      height: 5,
                      marginLeft: '-2px',
                      fontSize: '75%',
                    }}
                  >
                    Overdue Tasks
                  </span>
                </InputGroup>
                <InputGroup component="fieldset">
                  <CheckBox
                    checked={showOnly === 'nodue' ? true : false}
                    onChange={(event: any) => {
                      if (event.target.checked) {
                        setShowOnly('nodue')
                      } else {
                        setShowOnly('')
                      }
                    }}
                  />
                  <span
                    style={{
                      position: 'relative',
                      top: 12,
                      cursor: 'pointer',
                      height: 5,
                      marginLeft: '-2px',
                      fontSize: '75%',
                    }}
                  >
                    Tasks with No Due Dates
                  </span>
                </InputGroup>
              </FormControl>
            </div>
          </div>
          <div className={'col-12 col-lg-4 mb-0'}>
            <div className={classes.queryControlItem}>
              <FormControl className="form-control">
                <InputLabel>Responsible:</InputLabel>
                <Select
                  name="assignedTo"
                  native={true}
                  value={assignedTo}
                  onChange={(event: any) => {
                    let { value } = event.target
                    if (value) {
                      setAssignedTo(value)
                    } else {
                      setAssignedTo('--')
                    }
                  }}
                >
                  <option value="--" key="issueRespAll">
                    -- All --
                  </option>
                  {pickListValues.pickAssigned &&
                    pickListValues.pickAssigned.map(({ AssignedTo }) => (
                      <option value={AssignedTo} key={AssignedTo}>
                        {AssignedTo}
                      </option>
                    ))}
                  <option value="--null--" key="issueRespUndef">
                    -- Not Assigned --
                  </option>
                </Select>
              </FormControl>
            </div>
            <div className={classes.queryControlItem}>
              <FormControl className="form-control">
                <InputLabel>Role:</InputLabel>
                <Select
                  name="roleName"
                  native={true}
                  value={roleName}
                  onChange={(event: any) => {
                    let { value } = event.target
                    if (value) {
                      setRoleName(value)
                    } else {
                      setRoleName('--')
                    }
                  }}
                >
                  <option value="--" key="pickRoleAll">
                    -- All --
                  </option>
                  {pickListValues.pickRole &&
                    pickListValues.pickRole.map(({ RoleName }) => (
                      <option value={RoleName} key={RoleName}>
                        {RoleName}
                      </option>
                    ))}
                </Select>
              </FormControl>
            </div>
            <div className={classes.queryControlItem}>
              <InputLabel style={{ fontSize: '12px' }}>
                Task Name Includes:
              </InputLabel>
              <FormControl className="form-control">
                <Input
                  type="text"
                  name="TaskNameIncludes"
                  value={taskNameIncludes}
                  onChange={(event: any) => {
                    let { value } = event.target
                    if (value) {
                      setTaskNameIncludes(value)
                    } else {
                      setTaskNameIncludes('')
                    }
                  }}
                  placeholder="-- All --"
                />
              </FormControl>
            </div>
          </div>
        </div>
        <hr />
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            cursor: 'pointer',
            paddingRight: '10px',
          }}
        >
          <div>
            {filteredTaskItemList.length === 1
              ? `${filteredTaskItemList.length} task matches`
              : `${filteredTaskItemList.length} tasks match`}
            your query
          </div>
          <div style={{ display: 'flex' }}>
            <div
              onClick={() => setTableSelectorOpen(true)}
              style={{ color: '#3e91c5' }}
            >
              {' '}
              <FontAwesomeIcon icon={faColumns} /> Select Columns
            </div>
            <Spacer />
            <div>{excelExport()}</div>
          </div>
        </div>
        {filteredTaskItemList.length > 0 ? (
          <TableContainer
            style={{
              width: '100%',
              marginTop: '10px',
            }}
          >
            <Table
              style={{ borderTop: '2px solid #000000', marginBottom: '100px' }}
            >
              <colgroup>
                <col style={{ width: '25%' }} />
                <col style={{ width: '25%' }} />
                {shouldDisplayColumn('Responsible') && (
                  <col style={{ width: '10%' }} />
                )}
                {shouldDisplayColumn('Role') && (
                  <col style={{ width: '10%' }} />
                )}
                {shouldDisplayColumn('Due Date') && (
                  <col style={{ width: '15%' }} />
                )}
                {shouldDisplayColumn('Completed') && (
                  <col style={{ width: '15%' }} />
                )}
              </colgroup>
              <TableHead>
                <TableRow>
                  <TableCell
                    align="left"
                    onClick={() => setSortDirection('Issue')}
                  >
                    Issue
                    {taskSort.header === 'Issue' &&
                    taskSort.sortDirection === 'dec' ? (
                      <FontAwesomeIcon icon={faArrowDown} />
                    ) : (
                      taskSort.header === 'Issue' && (
                        <FontAwesomeIcon icon={faArrowUp} />
                      )
                    )}
                  </TableCell>
                  <TableCell
                    align="left"
                    onClick={() => setSortDirection('Task')}
                  >
                    Task
                    {taskSort.header === 'Task' &&
                    taskSort.sortDirection === 'dec' ? (
                      <FontAwesomeIcon icon={faArrowDown} />
                    ) : (
                      taskSort.header === 'Task' && (
                        <FontAwesomeIcon icon={faArrowUp} />
                      )
                    )}
                  </TableCell>

                  {shouldDisplayColumn('Responsible') && (
                    <TableCell
                      align="left"
                      onClick={() => setSortDirection('Responsible')}
                      style={{ lineBreak: 'auto' }}
                    >
                      Responsible
                      {taskSort.header === 'Responsible' &&
                      taskSort.sortDirection === 'dec' ? (
                        <FontAwesomeIcon icon={faArrowDown} />
                      ) : (
                        taskSort.header === 'Responsible' && (
                          <FontAwesomeIcon icon={faArrowUp} />
                        )
                      )}
                    </TableCell>
                  )}
                  {shouldDisplayColumn('Role') && (
                    <TableCell
                      align="left"
                      onClick={() => setSortDirection('Role')}
                    >
                      Role
                      {taskSort.header === 'Role' &&
                      taskSort.sortDirection === 'dec' ? (
                        <FontAwesomeIcon icon={faArrowDown} />
                      ) : (
                        taskSort.header === 'Role' && (
                          <FontAwesomeIcon icon={faArrowUp} />
                        )
                      )}
                    </TableCell>
                  )}
                  {shouldDisplayColumn('Due Date') && (
                    <TableCell
                      align="left"
                      onClick={() => setSortDirection('DueByUTC')}
                    >
                      Due Date
                      {taskSort.header === 'DueByUTC' &&
                      taskSort.sortDirection === 'dec' ? (
                        <FontAwesomeIcon icon={faArrowDown} />
                      ) : (
                        taskSort.header === 'DueByUTC' && (
                          <FontAwesomeIcon icon={faArrowUp} />
                        )
                      )}
                    </TableCell>
                  )}
                  {shouldDisplayColumn('Completed') && (
                    <TableCell
                      align="left"
                      onClick={() => setSortDirection('CompletedUTC')}
                    >
                      Completed
                      {taskSort.header === 'CompletedUTC' &&
                      taskSort.sortDirection === 'dec' ? (
                        <FontAwesomeIcon icon={faArrowDown} />
                      ) : (
                        taskSort.header === 'CompletedUTC' && (
                          <FontAwesomeIcon icon={faArrowUp} />
                        )
                      )}
                    </TableCell>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredTaskItemList &&
                  filteredTaskItemList.map(
                    (taskItem: QTaskItem, iqIdx: number) => {
                      return (
                        <TableRow
                          key={iqIdx}
                          style={{
                            position: 'relative',
                            minHeight: '100px',
                            height: 'auto',
                          }}
                        >
                          <TableCell>{taskItem.IssueName}</TableCell>
                          <TableCell>{taskItem.TaskItemName}</TableCell>
                          {shouldDisplayColumn('Responsible') && (
                            <TableCell style={{ lineBreak: 'anywhere' }}>
                              {taskItem.AssignedTo}
                            </TableCell>
                          )}
                          {shouldDisplayColumn('Role') && (
                            <TableCell>{taskItem.RoleName}</TableCell>
                          )}
                          {shouldDisplayColumn('Due Date') && (
                            <TableCell>
                              {taskItem.DueByUTC && (
                                <>
                                  <Moment format="DD MMM YYYY" utc local>
                                    {taskItem.DueByUTC}
                                  </Moment>
                                  <br />
                                  <Moment utc local fromNow>
                                    {taskItem.DueByUTC}
                                  </Moment>
                                </>
                              )}
                            </TableCell>
                          )}
                          {shouldDisplayColumn('Completed') && (
                            <TableCell style={{ paddingBottom: '40px' }}>
                              {taskItem.CompletedUTC && (
                                <>
                                  <Moment format="DD MMM YYYY" utc local>
                                    {taskItem.CompletedUTC}
                                  </Moment>
                                  <br />
                                  <Moment utc local fromNow>
                                    {taskItem.CompletedUTC}
                                  </Moment>
                                </>
                              )}
                            </TableCell>
                          )}
                          <div
                            style={{
                              position: 'absolute',
                              bottom: 0,
                              right: 0,
                              paddingRight: '10px',

                              fontSize: '12px',
                            }}
                          >
                            Organization: {taskItem.OrgName}
                          </div>
                        </TableRow>
                      )
                    }
                  )}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              fontSize: '20px',
            }}
          >
            {' '}
            There are no tasks that match your query
          </div>
        )}
      </>
    )
  }
  return (
    <>
      {renderPanel()}
      <AdminTaskItemQueryPanel
        open={tableSelectorOpen}
        onClose={() => {
          setTableSelectorOpen(false)
          setAdminQueryTableEditedValues(adminQueryTableOptions)
        }}
        onSave={() => {
          setTableSelectorOpen(false)
          setAdminQueryTableValues(adminQueryTableEditedValues)
          saveQueryPanelTaskSettings(
            JSON.stringify(adminQueryTableEditedValues)
          )
        }}
        onSelection={(selectionOption: AdminQueryTaskTableOption) => {
          addOrRemoveFromList(selectionOption)
        }}
        selectedOptions={adminQueryTableEditedValues}
        selectionList={adminQueryTableOptions}
      />
    </>
  )
}
