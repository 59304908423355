import * as React from 'react'
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts'
import { getTaskStatus, IssueTaskList } from '../Shared/dashboardUtils'

interface CustomXAxisTickProps {
  x?: number
  y?: number
  payload?: { value: string }
}

const CustomXAxisTick: React.FC<CustomXAxisTickProps> = ({
  x = 0,
  y = 0,
  payload = { value: '' },
}) => {
  const truncatedName =
    payload.value.length > 10
      ? payload.value.substring(0, 10) + '...'
      : payload.value

  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={0}
        dy={16}
        textAnchor="end"
        fill="#666"
        transform="rotate(-45)"
        style={{ fontSize: '14px' }}
      >
        {truncatedName}
      </text>
    </g>
  )
}

interface CustomTooltipProps {
  active?: boolean
  payload?: any
  label?: string
}
const CustomTooltip: React.FC<CustomTooltipProps> = ({
  active,
  payload,
  label,
}) => {
  if (active && payload && payload.length) {
    const taskOrg = payload[0].payload.taskOrg
    return (
      <div
        className="custom-tooltip"
        style={{
          backgroundColor: '#fff',
          border: '1px solid #ccc',
          padding: '5px',
          fontSize: '10px',
          maxWidth: '100%',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
        }}
      >
        <p className="label" style={{ margin: 0 }}>{`${label}`}</p>
        {payload.map((data: any) => (
          <p
            key={data.name}
            style={{ color: data.fill, margin: 0 }}
          >{`${data.name}: ${data.value}`}</p>
        ))}
        <p
          style={{ color: '#666', marginTop: '5px', marginBottom: 0 }}
        >{`Organization: ${taskOrg}`}</p>
      </div>
    )
  }
  return null
}

export const AdminDashboardIssueTasks = ({
  tasks,
}: {
  tasks?: IssueTaskList
}) => {
  if (!tasks || tasks.length < 1) {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
        }}
      >
        No Tasks
      </div>
    )
  }

  const issueList = tasks?.map((task) => {
    let Overdue = 0
    let Closed = 0
    let Unassigned = 0
    let Open = 0

    task.taskItems.forEach((taskItem) => {
      const taskStatus = getTaskStatus(taskItem)

      if (taskStatus === 'Overdue') {
        Overdue += 1
      } else if (taskStatus === 'Closed') {
        Closed += 1
      } else if (taskStatus === 'Unassigned') {
        Unassigned += 1
      } else if (taskStatus === 'OpenAssigned') {
        Open += 1
      }
    })

    return {
      issueName: task.issueName,
      taskOrg: task.taskOrgName,
      Overdue,
      Closed,
      Unassigned,
      Open,
    }
  })

  return (
    <div
      style={{
        display: 'flex',
        width: '100%',
        height: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '20px',
      }}
    >
      <ResponsiveContainer
        style={{ marginRight: '20px', overflow: 'hidden' }}
        width="100%"
        height="100%"
      >
        <BarChart data={issueList}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="issueName"
            tick={<CustomXAxisTick />}
            height={70}
            interval={0}
          />
          <YAxis interval={0} allowDecimals={false} />
          <Tooltip
            content={<CustomTooltip />}
            allowEscapeViewBox={{ x: false, y: true }}
            labelStyle={{ fontSize: '11px' }}
            itemStyle={{ fontSize: '11px', lineHeight: '4px' }}
            position={{ x: 30 }}
          />
          <Bar
            dataKey="Overdue"
            stackId="a"
            fill="#d53e4f"
            isAnimationActive={false}
          />
          <Bar
            dataKey="Closed"
            stackId="a"
            fill="#FCAE61"
            isAnimationActive={false}
          />
          <Bar
            dataKey="Unassigned"
            stackId="a"
            fill="#ffe0ac"
            isAnimationActive={false}
          />
          <Bar
            dataKey="Open"
            stackId="a"
            fill="#acdea2"
            isAnimationActive={false}
          />
        </BarChart>
      </ResponsiveContainer>
    </div>
  )
}
