// @ts-nocheck
// TODO: Typescript
import React, { Component } from 'react'
import classes from './ScrollList.module.scss'

/**
 * @extends {Component<Props, {}>}}
 * Reusable scroll list
 */
export class ScrollList extends Component {
  constructor(props) {
    super(props)
    this.state = {
      curPage: 1,
      direction: undefined,
    }
  }

  /*componentDidMount() {
        this.viewportEl.addEventListener(() => {

        });
    }*/

  render() {
    const { children, pageSize } = this.props
    return (
      <div className={classes.viewport} ref={(el) => (this.viewportEl = el)}>
        <ul className={classes.list}>
          {pageSize ? this.renderItemsOnPage() : children}
        </ul>
      </div>
    )
  }

  renderItemsOnPage() {
    const { children, pageSize, numOfItems } = this.props
    var curPage = 1
    let items = []

    for (var i = (curPage - 1) * numOfItems; i < curPage * pageSize; i++) {
      items.push(children(i))
    }

    return items
  }
}
