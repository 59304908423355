// @ts-nocheck
// TODO: Typescript
import React, { Component } from 'react'
import { ErrorMessageBase } from '../ErrorMessages/ErrorMessageBase'
import { getQuery } from '../_utils/urlUtils'
import { Button } from 'reactstrap'
import { loginADFailure } from '../MicrosoftTeams/_actions'
import { connect } from 'react-redux'
import {
  AD_SILIENT_FAILURE,
  AD_MSG_SILENT_FAILURE,
} from '../MicrosoftTeams/_constants'
import { selectAzureADLoginCode } from '../MicrosoftTeams/_selectors'

const errorPageSecondaryStyle = {
  textAlign: 'left',
  margin: 'auto',
  width: '80%',
  maxWidth: '900px',
}

/**
 * @extends {Component<Props, {}>}}
 */
export class MicrosoftErrorPage extends Component {
  constructor(props) {
    super(props)
    this.handleLogin = this.handleLogin.bind(this)
    this.handleWindowClose = this.handleWindowClose.bind(this)
    this.params = getQuery()
    this.debug = this.params.get('debug') || ''
  }

  componentDidMount() {
    if (this.isSlientAuthFailure()) {
      this.props.loginFailure(AD_SILIENT_FAILURE)
      this.handleWindowClose()
    }
  }

  isSlientAuthFailure() {
    return this.debug.indexOf(AD_SILIENT_FAILURE) > -1
  }

  handleWindowClose() {
    if (window && window.close) window.close()
  }

  handleLogin() {
    // This is no longer needed the sync middleware will propagate the
    // login status to all tabs in the browser
    /*const { azureADLoginCode, loginFailure } = this.props;
        teamsAuthenticate({
            failureCallback: loginFailure
        }, {
            prompt: azureADLoginCode === AD_SILIENT_FAILURE ? true : false
        });

        if (window.close)
            window.close();*/
  }

  render() {
    const params = getQuery()
    let message = null
    const { debug } = this

    if (this.isSlientAuthFailure()) {
      message = (
        <div>
          <div>{AD_MSG_SILENT_FAILURE}</div>
          <div>
            <Button onClick={this.handleWindowClose} color="primary">
              Close Window
            </Button>
          </div>
        </div>
      )
    } else {
      message = debug
    }

    if (params)
      return (
        <ErrorMessageBase
          primary={params.get('message')}
          secondary={<div style={errorPageSecondaryStyle}>{message}</div>}
        />
      )
  }
}

const mapDispatchToProps = {
  loginFailure: loginADFailure,
}

const mapStateToProps = (state) => ({
  azureADLoginCode: selectAzureADLoginCode(state),
})

export default connect(mapStateToProps, mapDispatchToProps)(MicrosoftErrorPage)
