import {
  faArrowDown,
  faArrowUp,
  faBuilding,
  faPlus,
  faTimes,
} from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import {
  Button,
  CircularProgress,
  Input,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core'
import React, { useCallback, useMemo } from 'react'

import { useDispatch } from 'react-redux'
import { Table } from 'reactstrap'

import { Organization } from '../Issue/_reducer'
import { AdminConsoleDialog } from '../Shared/AdminConsoleDialog'
import { SearchInput } from '../Shared/SearchInput'
import { Spacer } from '../Shared/Spacer'
import { addNotification } from '../_actions'
import { appDateTimeFormat } from '../_constants'
import {
  createOrgAsset,
  deleteOrgAsset,
  orgAssets,
  updateOrgAsset,
} from '../_utils/apiUtils'
import { getLocalDate } from '../_utils/dateUtils'
import { StyledTableRow } from './AdminConsolePage'
import classes from './AdminConsolePage.module.scss'
import colorDefinitions from '../ThemeProvider/ColorDefinitions.module.scss'
import dayjs from 'dayjs'
import { useAppSelector } from '../_utils/reactReduxHooks'
import { isMobileApp } from '../_selectors'
import { useDebounce } from 'usehooks-ts'
import {
  GoogleMap,
  InfoWindow,
  Libraries,
  Marker,
  useLoadScript,
} from '@react-google-maps/api'
import { googleApiKey } from '../_utils/AppSettings'
import { Button as SharedButton } from '../Shared/Buttons'
import { AssetImportDialog } from './AssetImportDialog'

const newYorkLatLong = {
  lat: 40.741895,
  lng: -73.989308,
}

type TableHeader = 'Name' | 'Created'
type SortDirection = 'asc' | 'dec' | 'none'
export interface TableSort {
  header: TableHeader
  sortDirection: SortDirection
}

export interface OrgAsset {
  AssetID: number
  AssetTypeID: number
  AssetTypeName: string
  Created: string
  CreatedBy: number
  Description: string
  Latitude: number
  Longitude: number
  Name: string
  OrgID: number
  WKT: string
}

function createData(
  title: string,
  description: string,
  createdOn: string,
  latitude: number,
  longitude: number
) {
  return { title, description, createdOn, latitude, longitude }
}

const libraries = ['places']

export const AdminConsoleAssets = ({
  selectedOrg,
}: {
  selectedOrg: Organization
}) => {
  const [assetSearch, setAssetSearch] = React.useState('')
  const [assetInputDescription, setAssetInputDescription] = React.useState('')
  const [assetInputName, setAssetInputName] = React.useState('')
  const [assetInputLocation, setAssetInputLocation] = React.useState('')
  const [newAssetLatitude, setNewAssetLatitude] = React.useState<
    number | undefined
  >()
  const [newAssetLongitude, setNewAssetLongitude] = React.useState<
    number | undefined
  >()
  const [mapOrgAssetSelection, setMapOrgAssetSelection] = React.useState<
    OrgAsset | undefined
  >()
  const debouncedLocation = useDebounce(assetInputLocation, 2000)
  const [selectedAsset, setSelectedAsset] = React.useState({} as OrgAsset)
  const [assetSelectedLocation, setAssetSelectedLocation] = React.useState('')
  const [addingAssetTemplate, setAddingAssetTemplate] = React.useState(false)
  const [assetSaveDialogOpen, setAssetSaveDialogOpen] = React.useState(false)
  const [assetDeleteDialogOpen, setAssetDeleteDialogOpen] =
    React.useState(false)

  const [assetImportDialogOpen, setAssetImportDialogOpen] =
    React.useState(false)

  const [assetSort, setAssetSort] = React.useState<TableSort>({
    header: 'Created',
    sortDirection: 'dec',
  })

  const [map, setMap] = React.useState<google.maps.Map | null>(null)

  const [assets, setAssets] = React.useState<OrgAsset[] | undefined>()

  const [fetchingAssets, setFetchingAssets] = React.useState(false)

  const [showMap, setShowMap] = React.useState(false)

  const [needsRefresh, setNeedsRefresh] = React.useState(false)

  const isMobile = useAppSelector((state) => isMobileApp(state))

  const dispatch = useDispatch()

  const mapContainerStyle = {
    width: '100%',
    height: '100%',
  }

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: googleApiKey,
    libraries: libraries as Libraries,
    preventGoogleFontsLoading: false,
  })

  const setSortDirection = useCallback(
    (header: TableHeader) => {
      if (header === assetSort.header) {
        if (assetSort.sortDirection === 'none') {
          setAssetSort({ header: header, sortDirection: 'dec' })
        } else if (assetSort.sortDirection === 'asc') {
          setAssetSort({ header: header, sortDirection: 'dec' })
        } else {
          setAssetSort({ header: header, sortDirection: 'asc' })
        }
      } else {
        setAssetSort({ header: header, sortDirection: 'dec' })
      }
    },
    [assetSort.header, assetSort.sortDirection]
  )

  const clearStateAndRefresh = () => {
    setAddingAssetTemplate(false)
    setAssetInputDescription('')
    setAssetInputName('')
    setAssetInputLocation('')
    setAssetSaveDialogOpen(false)
    setAssetDeleteDialogOpen(false)
    setSelectedAsset({} as OrgAsset)
    setShowMap(false)
    setNeedsRefresh(true)
  }

  const sortAssets = (assetList: OrgAsset[] | undefined) => {
    if (assetSort.sortDirection === 'none') return

    switch (assetSort.header) {
      case 'Name':
        if (assetSort.sortDirection === 'asc') {
          return assetList?.sort((a, b) => (a.Name > b.Name ? 1 : -1))
        } else if (assetSort.sortDirection === 'dec') {
          return assetList?.sort((a, b) => (a.Name > b.Name ? -1 : 1))
        }
        break
      case 'Created':
        if (assetSort.sortDirection === 'asc') {
          return assetList?.sort((a, b) => (a.Created > b.Created ? 1 : -1))
        } else if (assetSort.sortDirection === 'dec') {
          return assetList?.sort((a, b) => (a.Created > b.Created ? -1 : 1))
        }
        break
    }
  }

  React.useEffect(() => {
    if (map) {
      const bounds = new window.google.maps.LatLngBounds()
      if (
        isValidLatLng(newAssetLatitude, newAssetLongitude) &&
        newAssetLatitude &&
        newAssetLongitude
      ) {
        bounds.extend({ lat: newAssetLatitude, lng: newAssetLongitude })
      }

      if (
        isValidLatLng(selectedAsset.Latitude, selectedAsset.Longitude) &&
        selectedAsset.Latitude &&
        selectedAsset.Longitude
      ) {
        bounds.extend({
          lat: selectedAsset.Latitude,
          lng: selectedAsset.Longitude,
        })
      }

      if (!selectedAsset.Latitude && !selectedAsset.Longitude) {
        bounds.extend(newYorkLatLong)
      }

      map.fitBounds(bounds)
    }
  }, [
    map,
    newAssetLatitude,
    newAssetLongitude,
    selectedAsset.Latitude,
    selectedAsset.Longitude,
  ])

  const handleLocationAPI = React.useCallback(
    (latlng?: google.maps.LatLng) => {
      let request: google.maps.GeocoderRequest = latlng
        ? {
            location: latlng,
          }
        : { address: assetInputLocation }
      let geocoder: google.maps.Geocoder = new google.maps.Geocoder()
      geocoder.geocode(request).then((result) => {
        const { results } = result

        setNewAssetLatitude(results[0].geometry.location.lat())
        setNewAssetLongitude(results[0].geometry.location.lng())
        setAssetSelectedLocation(
          latlng
            ? 'Pin on Map: ' + latlng.lat() + ', ' + latlng.lng()
            : results[0].formatted_address +
                ' - Accuracy: ' +
                results[0].geometry.location_type +
                ' - Lat: ' +
                results[0].geometry.location.lat() +
                ', Lng: ' +
                results[0].geometry.location.lng()
        )

        if (
          assetInputDescription === '' ||
          assetInputDescription === assetSelectedLocation
        ) {
          setAssetInputDescription(
            latlng
              ? 'Pin on Map: ' + latlng.lat() + ', ' + latlng.lng()
              : results[0].formatted_address +
                  ' - Accuracy: ' +
                  results[0].geometry.location_type +
                  ' - Lat: ' +
                  results[0].geometry.location.lat() +
                  ', Lng: ' +
                  results[0].geometry.location.lng()
          )
        }
      })

      // .catch((errors: any) => {
      //   setNewLocationDetails(null)
      //   if (errors.code === 'ZERO_RESULTS') {
      //     setErrors((prev) => ({
      //       ...prev,
      //       Address:
      //         'There were no results for your search, please try again',
      //     }))
      //   } else {
      //     setErrors((prev) => ({
      //       ...prev,
      //       Address: 'There was an issue searching for that location',
      //     }))
      //   }
      // })
    },
    [assetInputLocation, assetInputDescription, assetSelectedLocation]
  )

  const handleAssetChange = React.useCallback(() => {
    const contentEmpty =
      assetInputDescription.replace(/<(.|\n)*?>/g, '').trim().length === 0

    let errorMessage = ''

    if (contentEmpty || !assetInputName) {
      errorMessage = 'Assets must both have a name and description to be saved'
    }

    if (!newAssetLongitude && !selectedAsset.Longitude) {
      errorMessage =
        'Assets must have a location, type an address into the location section or select it on the map'
    }

    if (errorMessage) {
      dispatch(
        addNotification({
          message: errorMessage,
          type: 'error',
        })
      )
      return
    }
    setAssetSaveDialogOpen(true)
  }, [
    dispatch,
    assetInputDescription,
    assetInputName,
    newAssetLongitude,
    selectedAsset.Longitude,
  ])

  React.useEffect(() => {
    setAssetInputName(selectedAsset.Name)
    setAssetInputDescription(selectedAsset.Description || '')
    setAssetInputLocation('')
    setNewAssetLatitude(undefined)
    setNewAssetLongitude(undefined)
  }, [selectedAsset])

  React.useEffect(() => {
    if (needsRefresh) {
      setNeedsRefresh(false)
    }
  }, [needsRefresh])

  React.useEffect(() => {
    setFetchingAssets(true)

    orgAssets(parseInt(selectedOrg.OrgID))
      .then((data) => {
        setAssets(data)
        setFetchingAssets(false)
      })
      .catch((e) => {
        setAssets(undefined)
      })
  }, [selectedOrg.OrgID, needsRefresh])

  React.useEffect(() => {
    if (
      debouncedLocation &&
      assetInputLocation &&
      debouncedLocation === assetInputLocation
    ) {
      handleLocationAPI()
    }
  }, [debouncedLocation, assetInputLocation, handleLocationAPI])

  const onUnmount = useCallback(function callback(map) {
    setMap(null)
  }, [])

  const onLoad = useCallback(
    (loadedMap: google.maps.Map) => {
      setMap(loadedMap)

      const bounds = new google.maps.LatLngBounds()

      if (isValidLatLng(selectedAsset.Latitude, selectedAsset.Longitude)) {
        const point = new google.maps.LatLng(
          selectedAsset.Latitude,
          selectedAsset.Longitude
        )

        bounds.extend(point)
      } else {
        const point = new google.maps.LatLng(
          newYorkLatLong.lat,
          newYorkLatLong.lng
        )
        bounds.extend(point)
      }

      if (
        isValidLatLng(newAssetLatitude, newAssetLongitude) &&
        newAssetLatitude &&
        newAssetLongitude
      ) {
        const newPoint = new google.maps.LatLng(
          newAssetLatitude,
          newAssetLongitude
        )
        bounds.extend(newPoint)
      }

      loadedMap.fitBounds(bounds)
    },
    [
      newAssetLatitude,
      newAssetLongitude,
      selectedAsset.Latitude,
      selectedAsset.Longitude,
    ]
  )

  const jsonToCSV = (jsonArray: OrgAsset[]): string => {
    const headers = Object.keys(jsonArray[0]).join(',')
    const rows = jsonArray
      .map((obj) => {
        return Object.values(obj)
          .map((value) => {
            if (typeof value === 'string' && value.includes(',')) {
              return `"${value}"`
            }
            return value
          })
          .join(',')
      })
      .join('\n')
    return `${headers}\n${rows}`
  }

  const downloadCSV = (csv: string, filename: string) => {
    const blob = new Blob([csv], { type: 'text/csv' })
    const url = window.URL.createObjectURL(blob)
    const a = document.createElement('a')
    a.setAttribute('href', url)
    a.setAttribute('download', filename)
    a.click()
    window.URL.revokeObjectURL(url)
  }

  const isValidLatLng = (lat?: number, lng?: number) => {
    if (!lat || !lng) return false

    return !isNaN(lat) && !isNaN(lng) && lat !== undefined && lng !== undefined
  }

  const asset =
    assetInputDescription || assetInputDescription === ''
      ? assetInputDescription
      : selectedAsset.Description

  const assetPageSearch =
    assetSearch === ''
      ? assets?.map((asset) => asset)
      : assets?.filter((asset) => {
          if (asset.AssetID === selectedAsset.AssetID) {
            return asset
          }

          return asset.Name.toLowerCase().includes(assetSearch.toLowerCase())
        })

  const assetList =
    assetSort.sortDirection === 'none'
      ? assetPageSearch
      : sortAssets(assetPageSearch)

  const renderTable = useMemo(() => {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <Table sx={{ minWidth: 300, width: '100%' }} aria-label="custom table">
          <colgroup>
            <col style={{ width: '20%' }} />
            <col style={{ width: '45%' }} />
            <col style={{ width: '15%' }} />
            <col style={{ width: '20%' }} />
          </colgroup>
          <TableHead>
            <TableRow style={{ width: '100%' }}>
              <TableCell
                align="left"
                style={{
                  cursor: 'pointer',
                  display: 'flex',
                  alignItems: 'center',
                  borderBottom: '0',
                }}
                onClick={() => setSortDirection('Name')}
              >
                Name
                <Spacer />
                {assetSort.header === 'Name' &&
                assetSort.sortDirection === 'dec' ? (
                  <FontAwesomeIcon icon={faArrowDown} />
                ) : (
                  assetSort.header === 'Name' && (
                    <FontAwesomeIcon icon={faArrowUp} />
                  )
                )}
              </TableCell>
              <TableCell
                align="left"
                style={{
                  cursor: 'pointer',
                  borderBottom: '0',
                }}
              >
                Description
              </TableCell>
              <TableCell
                align="left"
                style={{
                  cursor: 'pointer',
                  display: 'flex',
                  alignItems: 'center',
                  borderBottom: '0',
                }}
              >
                Location
              </TableCell>
              <TableCell
                align="left"
                onClick={() => setSortDirection('Created')}
              >
                <div
                  style={{
                    cursor: 'pointer',
                    display: 'flex',
                    alignItems: 'center',
                    borderBottom: '0',
                  }}
                >
                  Created On
                  <Spacer />
                  {assetSort.header === 'Created' &&
                  assetSort.sortDirection === 'asc' ? (
                    <FontAwesomeIcon icon={faArrowUp} />
                  ) : (
                    assetSort.header === 'Created' && (
                      <FontAwesomeIcon icon={faArrowDown} />
                    )
                  )}
                </div>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {assetList?.map((asset) => {
              const assetRow = createData(
                asset.Name,
                asset.Description,
                asset.Created,
                asset.Latitude,
                asset.Longitude
              )

              const isSelected = selectedAsset.AssetID === asset.AssetID

              return (
                <StyledTableRow
                  key={asset.AssetID}
                  onClick={() => {
                    setSelectedAsset(asset)
                    setAddingAssetTemplate(false)
                  }}
                  style={{
                    backgroundColor: isSelected
                      ? colorDefinitions.backgroundBlue
                      : '',
                  }}
                >
                  <TableCell
                    component="th"
                    scope="row"
                    style={{
                      color: isSelected ? 'white' : '',
                    }}
                  >
                    {assetRow.title}
                  </TableCell>

                  <TableCell
                    style={{
                      color: isSelected ? 'white' : '',
                      wordBreak: 'break-all',
                    }}
                  >
                    {assetRow.description}
                  </TableCell>
                  <TableCell
                    component="th"
                    scope="row"
                    style={{
                      color: isSelected ? 'white' : '',
                    }}
                  >
                    {assetRow.latitude} , {assetRow.longitude}
                  </TableCell>
                  <TableCell
                    style={{
                      color: isSelected ? 'white' : '',
                      minWidth: '100px',
                    }}
                  >
                    {dayjs(getLocalDate(assetRow.createdOn))
                      .local()
                      .format(appDateTimeFormat)}
                  </TableCell>
                </StyledTableRow>
              )
            })}
          </TableBody>
        </Table>
      </div>
    )
  }, [
    assetList,
    assetSort,
    selectedAsset,
    setSelectedAsset,
    setAddingAssetTemplate,
    setSortDirection,
  ])

  const GoogleMapSection = (
    <GoogleMap
      mapContainerStyle={mapContainerStyle}
      onLoad={onLoad}
      options={{
        fullscreenControl: false,
        streetViewControl: false,
        mapTypeControl: false,
        gestureHandling: 'cooperative',
      }}
      onUnmount={onUnmount}
      onBoundsChanged={() => {
        let zoom = map?.getZoom()
        if (zoom && zoom > 10) map?.setZoom(10)
      }}
      onClick={(e) => {
        if (mapOrgAssetSelection) {
          setMapOrgAssetSelection(undefined)
          return
        }
        if (e.latLng?.lat() && e.latLng?.lng()) {
          setAssetInputLocation('')
          handleLocationAPI(
            new google.maps.LatLng(e.latLng?.lat(), e.latLng?.lng())
          )
        }
      }}
    >
      {isValidLatLng(selectedAsset.Latitude, selectedAsset.Longitude) && (
        <Marker
          position={{
            lat: selectedAsset.Latitude,
            lng: selectedAsset.Longitude,
          }}
          title={
            selectedAsset.Name ||
            selectedAsset.Description ||
            'lat: ' +
              selectedAsset.Latitude +
              ', lng: ' +
              selectedAsset.Longitude
          }
          onClick={() => {
            setMapOrgAssetSelection(selectedAsset)
          }}
        />
      )}

      {mapOrgAssetSelection && (
        <InfoWindow
          onCloseClick={() => {
            setMapOrgAssetSelection(undefined)
          }}
          position={{
            lat: mapOrgAssetSelection.Latitude,
            lng: mapOrgAssetSelection.Longitude,
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <div style={{ fontSize: '16px', fontWeight: '500' }}>
              {mapOrgAssetSelection.Name}
            </div>
            {mapOrgAssetSelection.Description && (
              <div>{mapOrgAssetSelection.Description}</div>
            )}
            <div>
              {mapOrgAssetSelection.Latitude +
                ', ' +
                mapOrgAssetSelection.Longitude}
            </div>
          </div>
        </InfoWindow>
      )}
      {newAssetLatitude && newAssetLongitude && (
        <Marker
          key={0}
          position={{
            lat: newAssetLatitude,
            lng: newAssetLongitude,
          }}
          onClick={() => {
            setMapOrgAssetSelection({
              ...selectedAsset,
              Name: `New location: ${selectedAsset.Name}` || 'New Location',
              Description: selectedAsset.Description,
              Latitude: newAssetLatitude,
              Longitude: newAssetLongitude,
            })
          }}
        />
      )}
    </GoogleMap>
  )

  const assetEditContainer = (
    <div className={classes.OrganizationHoldingStatementEditContainer}>
      <div>
        <div className={classes.OrganizationInnerContainerEditHeader}>
          Name
          <FontAwesomeIcon
            icon={faTimes}
            style={{ cursor: 'pointer' }}
            onClick={() => {
              setSelectedAsset({} as OrgAsset)
              setAddingAssetTemplate(false)
            }}
          />
        </div>
        <Input
          type="text"
          required={true}
          name="Name"
          inputProps={{
            maxLength: 255,
          }}
          style={{ minWidth: '200px', maxWidth: '400px' }}
          fullWidth
          className={classes.TagInput}
          value={assetInputName}
          onChange={(e) => setAssetInputName(e.target.value)}
        />
      </div>
      <Spacer />
      <div className={classes.OrganizationInnerContainerEditHeader}>
        Description
      </div>

      <Input
        type="text"
        required={true}
        name="Link"
        inputProps={{
          maxLength: 255,
        }}
        style={{ width: '100%' }}
        value={asset}
        onChange={(e) => setAssetInputDescription(e.target.value)}
      />
      <Spacer />

      <div className={classes.OrganizationInnerContainerEditHeader}>
        Location
      </div>

      <Input
        type="text"
        name="Address"
        inputProps={{
          maxLength: 255,
        }}
        style={{ width: '100%' }}
        value={assetInputLocation}
        onChange={(evt) => {
          //   if (errors.Address) {
          //     delete errors.Address
          //   }
          setAssetInputLocation(evt.target.value)
        }}
        onKeyDown={(e) => {
          if (e.code === 'Enter') handleLocationAPI()
        }}
      />
      <Spacer />
      <div>{assetSelectedLocation}</div>
      {showMap && (
        <div
          style={
            isMobile
              ? { height: '250px', width: '100%' }
              : { height: '450px', width: '100%' }
          }
        >
          {GoogleMapSection}
        </div>
      )}

      <div className={classes.OrganizationTagButtonContainer}>
        <Button onClick={() => setShowMap((prev) => !prev)}>
          {showMap ? 'Hide Map' : 'Show Map'}
        </Button>
        {addingAssetTemplate ? null : (
          <Button
            onClick={() => setAssetDeleteDialogOpen(true)}
            className={classes.OrganizationDeleteButton}
            style={{ color: 'red' }}
          >
            {' '}
            Delete{' '}
          </Button>
        )}
        <Button
          onClick={() => {
            handleAssetChange()
          }}
          className={classes.OrganizationSaveButton}
          style={{ color: colorDefinitions.backgroundBlue }}
        >
          {' '}
          Save{' '}
        </Button>
      </div>
    </div>
  )

  const AssetConfirmDialogs = () => {
    return (
      <>
        <AdminConsoleDialog
          open={assetDeleteDialogOpen}
          content={'Are you sure you would like to delete this asset?'}
          onConfirm={() => {
            deleteOrgAsset(parseInt(selectedOrg.OrgID), selectedAsset.AssetID)

            clearStateAndRefresh()
          }}
          consoleRole="destructive"
          onCancel={() => setAssetDeleteDialogOpen(false)}
        />
        <AdminConsoleDialog
          open={assetSaveDialogOpen}
          content={'Are you sure you would like to save this asset?'}
          onConfirm={() => {
            addingAssetTemplate
              ? createOrgAsset(
                  parseInt(selectedOrg.OrgID),
                  assetInputName,
                  assetInputDescription,
                  newAssetLatitude ? newAssetLatitude : selectedAsset.Latitude,
                  newAssetLongitude
                    ? newAssetLongitude
                    : selectedAsset.Longitude,
                  1
                ).then((data) => {
                  clearStateAndRefresh()
                })
              : updateOrgAsset(
                  parseInt(selectedOrg.OrgID),
                  selectedAsset.AssetID,
                  assetInputName,
                  assetInputDescription,
                  newAssetLatitude ? newAssetLatitude : selectedAsset.Latitude,
                  newAssetLongitude
                    ? newAssetLongitude
                    : selectedAsset.Longitude,
                  1
                ).then(() => clearStateAndRefresh())
          }}
          onCancel={() => setAssetSaveDialogOpen(false)}
        />
      </>
    )
  }

  if (loadError) {
    return (
      <div style={{ display: showMap ? '' : 'hidden' }}>Error loading maps</div>
    )
  }

  if (!isLoaded) {
    return <div style={{ display: showMap ? '' : 'hidden' }}>Loading maps</div>
  }

  return (
    <>
      <div className={classes.OrganizationManagementSection}>
        <div className={classes.OrganizationManagementSectionHeader}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              marginBottom: '10px',
            }}
          >
            <FontAwesomeIcon className={classes.videoIcon} icon={faBuilding} />
            &nbsp;&nbsp;&nbsp;&nbsp; <div>ASSETS</div>
            <Spacer spaceParam={'large'} />
            <SearchInput
              id="assetSearchInput"
              name="SearchInput"
              placeholder="Name Search"
              value={assetSearch}
              applySearch={(value: any) => {
                setAssetSearch(value)
              }}
            />
            <Spacer />
          </div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <SharedButton
              onClick={() => {
                setAssetImportDialogOpen(true)
              }}
            >
              {' '}
              Import Assets
            </SharedButton>
            <Spacer />
            <SharedButton
              onClick={() => {
                if (assets) {
                  const csvData = jsonToCSV(assets)
                  downloadCSV(csvData, 'assets.csv')
                }
              }}
            >
              {' '}
              Export Assets
            </SharedButton>
            <Spacer />
            <FontAwesomeIcon
              icon={faPlus}
              style={{
                cursor: 'pointer',
                float: 'right',
              }}
              onClick={() => {
                setAddingAssetTemplate(true)
                setAssetInputDescription('')
                setAssetInputName('')
                setAssetInputLocation('')
                setSelectedAsset({} as OrgAsset)
              }}
            />
          </div>
        </div>

        {assetList && assetList?.length > 0 ? (
          renderTable
        ) : fetchingAssets === undefined ? (
          <CircularProgress />
        ) : (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            There are no assets for this organization
            <Button
              onClick={() => {
                setAddingAssetTemplate(true)
                setAssetInputDescription('')
                setAssetInputName('')
                setAssetInputLocation('')
                setSelectedAsset({} as OrgAsset)
              }}
            >
              Add an asset
            </Button>{' '}
          </div>
        )}

        {selectedAsset.AssetID || addingAssetTemplate
          ? assetEditContainer
          : null}

        <AssetImportDialog
          open={assetImportDialogOpen}
          orgId={parseInt(selectedOrg.OrgID)}
          onSave={() => {
            clearStateAndRefresh()
            setAssetImportDialogOpen(false)
          }}
          onClose={() => {
            setAssetImportDialogOpen(false)
          }}
        />

        <AssetConfirmDialogs />
      </div>
    </>
  )
}
