import * as React from 'react'
import { http } from '../_utils/http'

// Fixed typings for css and scss modules
// https://stackoverflow.com/questions/41336858/how-to-import-css-modules-with-typescript-react-and-webpack
// From: Matt Crinklaw-Vogt
import { Paper } from '@material-ui/core'
import { cx } from '../_utils/objectUtils'
import { CircularProgress } from '../Shared/LoadingBackdrop'
import { PbChat, PbChatProps } from './PbChat'

interface Props {
  onItemClick?(pub: PbChatProps): void
  selected?: string
  className?: string
  hasPaper?: boolean
  onDataLoaded?: (playbooks: any) => void
}

export const PbChatList = ({
  onItemClick,
  selected,
  className,
  onDataLoaded,
  hasPaper = true,
}: Props) => {
  const [playbooks, setPlaybooks] = React.useState<any>('')
  const [chatlistLoading, setChatlistLoading] = React.useState(true)

  const getPlaybooks = async (): Promise<any> => {
    const result = await http<any>({
      path: '/pbchat/',
    })
    if (result.ok && result.body) {
      return result.body
    } else {
      return ''
    }
  }

  React.useEffect(() => {
    let cancelled = false
    const doGetPlaybooks = async () => {
      const playbooks = await getPlaybooks()
      if (!cancelled) {
        setPlaybooks(playbooks)
        setChatlistLoading(false)

        if (onDataLoaded) onDataLoaded(playbooks)
      }
    }
    doGetPlaybooks()
    return () => {
      cancelled = true
    }
  }, [])

  function renderList() {
    if (chatlistLoading) return <CircularProgress />

    return playbooks?.Chats?.map((pb: PbChatProps) => (
      <PbChat
        selected={pb.ChatGUID === selected}
        key={pb.ChatGUID}
        {...pb}
        onClick={onItemClick}
      />
    ))
  }

  if (!hasPaper)
    return (
      <div className={cx('container-fluid', className, 'p-2')}>
        {renderList()}
      </div>
    )

  return (
    <Paper className={cx('container-fluid', className, 'p-2')}>
      {renderList()}
    </Paper>
  )
}
