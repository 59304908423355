import { IssueGeolocation } from '../../Issue/_reducer'

export interface DashboardIssueModel {
  Description?: string
  IssueCreated?: string
  IssueID?: number
  IssueTeamWorkspaceID: number
  IssueName?: string
  IssueType?: string
  IssueMode?: string
  Responsible?: string
  SeverityLevelID?: number
  SeverityLevelName?: string
  SeverityLevelRGB?: string
  SeveritySequence?: number
  IssueTypeDescr?: string
  IssueResolved?: string
  TrendID?: number
  TrendName?: string
  TrendRGB?: string
  tags?: string
  OrgName: string
  OrgID: number
  Tasks: DashboardTaskListModel[]
  GeoLocation: IssueGeolocation[]
  Meetings: DashboardMeetingModel[]
}

export interface DashboardMeetingModel {
  ConferenceID: number
  Created: string
  Creator: number
  Creator_Email: string
  Creator_Name: string
  Instructions: string
  IsAllDay: boolean
  Link: string
  Participants: string
  RepeatType: number
  RepeatWeekdays: string
  RestrictedViewYN: string
  Scheduled: string
  ScheduledEnd: string
  TimeZone: string
  Title: string
  WeekStart: string
}

export interface DashboardAlertInboxModel {
  InboxID: number
  InboxName: string
  InboxType?: string
  IsActiveYN: string
  OrgName: string
  SavedAlerts: number
  IssuesCreatedFromAlerts: number
}

export interface DashboardPlaybookModel {
  PubID: number
  OrgID: number
  OrgName: string
  Version: number
  PubTitle: string
  AuthType: string
  Updated: string
  SharedChecklistCount: number
  SecureChatCount: number
}

export interface DashboardTaskItemsModel {
  TaskItemID: number
  TaskItemStatus: string
  Sequence?: number
  CreatedUTC: string
  AssignedTo?: string
  CreatedBy?: string
  CompletedUTC?: string
  Content: string
  Description?: string
  TaskItemHeaderYN?: string
  OrgRoleID?: number
  DueByUTC?: string
}

export interface DashboardTaskListModel {
  Created: string
  TaskID: number
  TaskName: string
  TaskItems: DashboardTaskItemsModel[]
}

export interface DashboardModeModel {
  IssueType: string
  IssueTypeDescr: string
}

export interface DashboardResponsibleModel {
  Responsible: string
}

export interface DashboardOrgTeamModel {
  OrgName: String
  TeamName: String
}

export interface DashboardSeverityModel {
  SeverityLevelID: number
  SeverityLevelName: string
  SeverityLevelRGB: string
  SeveritySequence: number
}

export interface DashboardTagModel {
  Tag: string
}

export interface DashboardAsset {
  AssetID: number
  City?: string
  Created: string
  CreatedBy: number
  Description?: string
  ExternalID: string
  ImportedFrom?: string
  Latitude: number
  Longitude: number
  Name: string
  State?: string
  StreetAddress?: string
  WKT?: string
}

export interface DashboardAssetType {
  Asset: DashboardAsset[]
  AssetTypeID: number
  AssetTypeName: string
}

export interface DashboardAssetList {
  AssetType: DashboardAssetType[]
  OrgID: number
  OrgName: string
}

export interface DashboardUserInfoModel {
  AsOfUTC: string
  Issues: DashboardIssueModel[]
  Assets: DashboardAssetList[]
  Inboxes: DashboardAlertInboxModel[]
  Playbooks: DashboardPlaybookModel[]
  OrgID: number
  env: string
  pickMode: DashboardModeModel[]
  pickResponsible: DashboardResponsibleModel[]
  pickSeverity: DashboardSeverityModel[]
  pickTags: DashboardTagModel[]
  OrgTeams: DashboardOrgTeamModel[]
}

export interface SelectionOption {
  title: string
  icon?: React.ReactNode
}

export interface BarData {
  name?: string
  issueCount: number
  color?: string
}

export type DashboardType = 'Admin' | 'User'

export type DashboardView =
  | 'Dashboard'
  | 'TaskPanel'
  | 'IssuePanel'
  | 'UserTaskPanel'

//D3: Icons currently not used but here for future implementation potentially
export const userSelectionOptions: SelectionOption[] = [
  // {
  //   title: 'My Recent Activity',
  //   icon: <FontAwesomeIcon icon={faSack} />,
  // },
  { title: 'All Active Issues' },
  { title: 'My Resolved Issues' },

  {
    title: 'Issues by Mode',
  },
  { title: 'My Issue Tasks' },
  {
    title: 'Playbook Collaboration',
  },
  { title: 'My Playbooks' },

  {
    title: 'Reports',
  },

  { title: 'Team Inboxes' },
  {
    title: 'My Teams',
  },
  {
    title: 'Upcoming Meetings',
  },
  {
    title: 'Issues By Location',
  },
  // {
  //   title: 'My Teams',
  //   icon: <FontAwesomeIcon icon={faSack} />,
  // },
  // {
  //   title: 'Marketing',
  //   icon: <FontAwesomeIcon icon={faSack} />,
  // },

  // {
  //   title: 'Tools and Resources',
  //   icon: <FontAwesomeIcon icon={faSack} />,
  // },
]

export const adminSelectionOptions: SelectionOption[] = [
  {
    title: 'All Active Issues',
  },
  { title: 'Resolved Issues' },
  { title: 'Issues by Mode' },

  {
    title: 'Alerts',
  },
  { title: 'Issues By Location' },

  {
    title: 'Playbooks',
  },

  { title: 'Task Status by Issue' },
  { title: 'Playbook Collaboration' },

  {
    title: 'Reports',
  },
]
