// @ts-nocheck
// TODO: Typescript
import React, { useEffect, useRef } from 'react'
import { setFormValue, disableForm } from '../_utils/domUtils'
import { cx } from '../_utils/objectUtils'
export const FormContent = ({
  content,
  className,
  fldval,
  onSubmitClick,
  readonly,
}) => {
  const formRef = useRef(null)
  const currentSubmitHandler = useRef(null)

  useEffect(() => {
    fldval.forEach((field) =>
      setFormValue(formRef.current, field.FieldName, field.Value)
    )

    if (readonly) disableForm(formRef.current)

    const submitBtn = formRef.current.querySelector('[type="submit"]')
    if (submitBtn) {
      // Remove the handler if it already exist
      if (currentSubmitHandler.current)
        submitBtn.removeEventListener('click', currentSubmitHandler.current)

      submitBtn.addEventListener('click', handleSubmitBtnClick)
      currentSubmitHandler.current = handleSubmitBtnClick
    }
  })

  function handleSubmitBtnClick(evt) {
    evt.preventDefault()
    const files = (formRef.current.querySelector('[type="file"]') || {}).files
    const formData = new FormData(formRef.current)
    if (files && files.length > 0) formData.append('file', files[0])

    onSubmitClick(formData)
  }

  return (
    <form ref={formRef}>
      <div
        className={cx(className, 'ico_team_report_form_content')}
        dangerouslySetInnerHTML={{ __html: content }}
      />
    </form>
  )
}

FormContent.defaultProps = {
  onUpdate: () => {},
  fldval: [],
  onSubmitClick: () => {},
  readonly: false,
}
