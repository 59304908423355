import {
  bindSel,
  getChat,
  getChatEntry,
  getChatRead,
} from '../_rootConfigs/rootReducerSlicers'
import { RootState } from '../_store'
import { ChatEntryModel } from './_models'
import * as priv from './_reducer'
import * as privLog from '../ActivityLog/_reducer'

// Chat Selectors
export const selectChatById = bindSel(getChat, priv.selectChatById)
export const selectChatsByIdMap = bindSel(getChat, priv.selectChatsByIdMap)
export const doesChatExist = bindSel(getChat, priv.doesChatExist)
export const selectChatEntryIds = bindSel(getChat, priv.selectChatEntryIds)
export const selectChatEntryIdsFromProps = bindSel(
  getChat,
  priv.selectChatEntryIdsFromProps
)
export const selectChatIdByIssueCollab = bindSel(
  getChat,
  priv.selectChatIdByIssueCollab
)
export const selectDirectMessageByIdMap = bindSel(
  getChat,
  priv.selectDirectMessageByIdMap
)
export const selectDirectMessageById = bindSel(
  getChat,
  priv.selectDirectMessageById
)
export const selectCreatedDirectMessageId = bindSel(
  getChat,
  priv.selectCreatedDirectMessageId
)
export const selectCreatedDirectMessage = bindSel(
  getChat,
  priv.selectCreatedDirectMessage
)

export const isChatRefreshReadRequired = bindSel(
  getChatRead,
  privLog.isLogRefreshReadRequired
)

export const isViewingChat = bindSel(getChatRead, privLog.isViewingLog)
export const selectIsChatUnread = bindSel(getChatRead, privLog.isLogUnread)

// Chat Entry Selectors
export const selectChatEntryById = bindSel(
  getChatEntry,
  priv.selectChatEntryById
)
export const selectChatEntryByIdMap = bindSel(
  getChatEntry,
  priv.selectChatEntryByIdMap
)
export const selectChatEntries = (state: RootState, chatId: number) => {
  const entryIds = selectChatEntryIds(state, chatId) || []
  let result: ChatEntryModel[] = []
  entryIds.forEach((id) => {
    const entry = selectChatEntryById(state, id)
    if (entry) result.push(entry)
  })

  return result
}
