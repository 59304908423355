import React, { useEffect, useState, useCallback } from 'react'
import { useLocation, RouteComponentProps } from 'react-router'
import { useIcoFetch } from '../_utils/fetchUtils'
import { Spacer } from '../Shared/Spacer'
import { PollBanner, PollBannerProps } from '../Playbook/PollBanner'
import { CircularProgress } from '@material-ui/core'
import { StretchBody } from '../Layouts/StretchLayout'
import { MasterPageLayout } from './MasterPageLayout'
import { DesktopLayout, MobileLayout } from '../Layouts/ResponsiveHidden'
import MobileViewLayout from '../Layouts/MobileViewLayout'
import { HPanelLayout, HPanel } from '../Layouts/HPanelLayout'
import { Container, Col, Row } from 'reactstrap'
import classes from '../Shared/UserReportMenuItem.module.scss'
import { cx } from '../_utils/objectUtils'

interface ActivePolls {
  PUR_GUID: string
  PollName: string
  ExpirationUTC: Date
  PollURL: string
  onClick?(poll: PollBannerProps): void
  selected?: boolean
}

interface Props extends RouteComponentProps {
  selected?: string
}

const openInNewTab = (url: string) => {
  const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
  if (newWindow) newWindow.opener = null
}

export const ActivePollsPage = ({
  history,
  match,
  location,
  selected,
}: Props) => {
  const [loading, setLoading] = useState(false)
  const activePoll = useLocation().pathname.split('/')[2]
  const pollData = useIcoFetch('api/playbook/ActivePolls', {
    formatData: (data: any) => (data ? JSON.parse(data) : ''),
    tracking: [activePoll],
    onData: () => setLoading(false),
  })

  useEffect(() => {
    setLoading(true)
  }, [activePoll])

  useEffect(() => {}, [pollData])
  useEffect(() => {}, [loading])
  const RenderActivePoll = useCallback(() => {
    return (
      <StretchBody>
        <h2 className="mt-4" style={{ textAlign: 'center' }}>
          Active Polls
        </h2>
        <Spacer />
        {pollData &&
          pollData.data &&
          Array.isArray(pollData.data.Polls) &&
          pollData.data.Polls.map((activePolls: ActivePolls) => {
            return (
              <PollBanner
                selected={activePolls.PUR_GUID === selected}
                key={activePolls.PUR_GUID}
                {...activePolls}
                onClick={() => openInNewTab(activePolls.PollURL)}
              />
            )
          })}
        {pollData &&
          pollData.data &&
          (!pollData ||
            !pollData.data ||
            !pollData.data.Polls ||
            pollData.data.Polls.length === 0) && (
            <h3 className="mt-4 text-center">*** No Active Polls ***</h3>
          )}
      </StretchBody>
    )
  }, [pollData, selected])
  if (loading)
    return (
      <MasterPageLayout
        history={history}
        match={match as any}
        location={location}
      >
        <DesktopLayout>
          <HPanelLayout>
            <HPanel fillWidth={true}>
              <CircularProgress className={cx(classes.spinnerPosition)} />
            </HPanel>
          </HPanelLayout>
        </DesktopLayout>
        <MobileLayout>
          <MobileViewLayout>
            <CircularProgress className={cx(classes.spinnerPosition)} />
          </MobileViewLayout>
        </MobileLayout>
      </MasterPageLayout>
    )

  return (
    <MasterPageLayout
      history={history}
      match={match as any}
      location={location}
    >
      <DesktopLayout>
        <HPanelLayout>
          <HPanel fillWidth={true}>
            <Container>
              <Col>
                <Row>{RenderActivePoll()}</Row>
              </Col>
            </Container>
          </HPanel>
        </HPanelLayout>
      </DesktopLayout>
      <MobileLayout>
        <MobileViewLayout>{RenderActivePoll()}</MobileViewLayout>
      </MobileLayout>
    </MasterPageLayout>
  )
}
