import * as React from 'react'
import { DialogContent, DialogProps, Toolbar } from '@material-ui/core'
import { MouseEvent } from 'react'
import { HeaderNavigation } from '../Shared/HeaderNavigation'
import { ResponsiveDialog } from '../Shared/ResponsiveDialog'
import { InputAndSubmitButton } from '../Shared/InputAndSubmitButton'
import classes from './TaskCsvDialog.module.scss'
import { useAppDispatch, useAppSelector } from '../_utils/reactReduxHooks'
import { selectTaskById } from './_selectors'
import { selectRequestToken } from '../Auth/_selectors'
import { addNotification } from '../_actions'
import { getTimezoneOffset } from '../_utils/dateUtils'
import { SlideLeft } from '../Shared/Transition'

interface Props extends DialogProps {
  TaskID: number
  onClose: (event: MouseEvent<Element>) => void
}

const timezoneOffset = getTimezoneOffset()

export const TaskExportCsvDialog = ({ TaskID, onClose, ...other }: Props) => {
  const form = React.useRef<HTMLFormElement>(null)
  const filename = React.useRef<HTMLInputElement>(null)
  const includeDate = React.useRef<HTMLInputElement>(null)
  const task = useAppSelector((state) => selectTaskById(state, TaskID))
  const dispatch = useAppDispatch()
  const requestToken = useAppSelector(selectRequestToken)
  const filenameLimit = 80

  function handleSubmit(value: string) {
    if (!filename.current) return

    if (value) {
      if (value.length > filenameLimit)
        dispatch(
          addNotification({
            message: 'Filename cannot exceed ' + filenameLimit + ' characters',
          })
        )
      else {
        if (includeDate.current) includeDate.current.value = 'false'
        filename.current.value = value
        form.current?.submit()
        onClose({} as any)
      }
    } else {
      if (includeDate.current) includeDate.current.value = 'true'

      filename.current.value = task.TaskName as string
      form.current?.submit()
      onClose({} as any)
    }
  }

  return (
    <ResponsiveDialog TransitionComponent={SlideLeft as any} {...other}>
      <Toolbar>
        <HeaderNavigation
          title="Export Task List"
          onNavClick={onClose}
          canGoBack={false}
        />
      </Toolbar>
      <DialogContent className={classes.content}>
        <InputAndSubmitButton
          placeholder="Filename"
          btnColor="secondary"
          variant="material"
          submitLabel="Export CSV"
          onSubmit={handleSubmit}
        />
        <form
          method="post"
          action={`api/Task/${TaskID}/Export`}
          encType="multipart/form-data"
          ref={form}
        >
          {/*<input type="hidden" name="SelectedColumns" />*/}
          <input
            ref={filename}
            type="hidden"
            name="Filename"
            value=""
            maxLength={filenameLimit}
          />
          <input
            ref={includeDate}
            type="hidden"
            name="IncludeDateInFilename"
            value="true"
          />
          <input type="hidden" name="OffsetMinutes" value={timezoneOffset} />
          <input type="hidden" name="X-CSRF-TOKEN" value={requestToken} />
        </form>
      </DialogContent>
    </ResponsiveDialog>
  )
}
