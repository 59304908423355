// @ts-nocheck
// TODO: Typescript
import { generateCrudTypes } from '../_utils/reduxUtils'
import { getAppName } from '../_utils/objectUtils'

export const StakeholderConstants = {
  ...generateCrudTypes('STAKEHOLDER'),
  setCurrentStakeholderID: `${getAppName()}/_setCurrentStakeholderID/STAKEHOLDER}`,
  setCurrentStakeholderLastNotified: `${getAppName()}/_setCurrentStakeholderLastNotified/STAKEHOLDER}`,
}
