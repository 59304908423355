// @ts-nocheck
// TODO: Typescript
import React, { Component } from 'react'
import { connect } from 'react-redux'
import {
  TaskCommentActions,
  TaskDetailActions,
  toggleTaskItemCompletedUtc,
  updateTaskItemCompletedUtc,
} from './_actions'
import Checkbox from '../CustomMaterial/Checkbox'
import { generateInputChangeSubmit } from '../_utils/reduxUtils'
import PropTypes from 'prop-types'
import classes from './TaskListItem.module.scss'
import Moment from 'react-moment'
import { cx } from '../_utils/objectUtils'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faComment } from '@fortawesome/free-solid-svg-icons'
import { getLocalDate } from '../_utils/dateUtils'
import { appDateTimeFormat } from '../_constants'
import { selectTaskItemById, selectTaskItemCommentIds } from './_selectors'
import { selectUserEmail } from '../_rootConfigs/rootSelectors'
import { selectUserFullName } from '../Auth/_selectors'
import { AutoFocus } from '../Shared/AutoFocus'
import {
  faCalendar,
  faFileAlt,
  faTimes,
} from '@fortawesome/pro-solid-svg-icons'
import { Spacer } from '../Shared/Spacer'

import { AdminConsoleDialog } from '../Shared/AdminConsoleDialog'
import { selectOrgRoles } from '../_utils/apiUtils'

/**
 * @extends {Component<Props, {}>}}
 */
export class TaskListItem extends Component {
  constructor(props) {
    super(props)

    this.state = {
      deleteHeaderDialogOpen: false,
    }
    this.handleCompletedChange = generateInputChangeSubmit(this, 1000, {
      onChange: 'updateTaskItem',
      onSubmit: 'submitTaskItemUpdate',
    })
    this.handleOnClick = this.handleOnClick.bind(this)
    this.setDeleteHeaderDialogOpen = this.setDeleteHeaderDialogOpen.bind(this)
  }

  render() {
    const {
      Content,
      CompletedUTC,
      TaskItemID,
      selected,
      Description,
      totalComments,
      readOnly,
      DueByUTC,
      TaskItemHeaderYN,
    } = this.props
    const ownClasses = this.props.classes
    const showCommentIcon = totalComments > 0
    const regex = /<.*?>/g
    const strippedDescription = Description
      ? Description.replaceAll(regex, '')
      : ''

    const showTimeFrame = CompletedUTC || DueByUTC

    const isHeader = TaskItemHeaderYN === 'Y'

    return (
      <div
        className={cx(isHeader ? '' : classes.root, ownClasses.root)}
        key={TaskItemID}
        style={
          isHeader
            ? {
                borderBottom: '2px solid black',
                paddingLeft: '0px',
                height: '40px',
                fontWeight: '700',
              }
            : {}
        }
        selected={selected}
        onClick={isHeader ? null : this.handleOnClick}
      >
        {isHeader ? (
          <div
            style={{
              pointerEvents: 'none',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            {' '}
            {Content}{' '}
            <div
              style={{
                padding: '5px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <FontAwesomeIcon
                style={{ pointerEvents: 'all' }}
                icon={faTimes}
                onClick={() => this.setDeleteHeaderDialogOpen(true)}
              />
            </div>
          </div>
        ) : (
          <div style={{ display: 'flex', height: '100%' }}>
            <div className={cx(classes.checkboxContainer)}>
              <Checkbox
                className={classes.checkbox}
                checked={CompletedUTC ? true : false}
                onChange={this.handleCompletedChange}
                disabled={readOnly}
              />
              {selected && <AutoFocus />}
            </div>
            <div className={cx(classes.taskContentContainer)}>
              <div className={cx(classes.taskContentTitle)}>
                <span className={classes.content}>{Content}</span>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'end',
                  width: '100%',
                }}
              >
                {this.renderAssignedTo()}
                {showTimeFrame && (
                  <div className={classes.taskTimeFrame}>
                    {CompletedUTC ? this.renderCompleted() : this.renderDueBy()}
                  </div>
                )}
                {this.renderTaskRole()}
              </div>
            </div>
            <Spacer />
            <div className={cx(classes.taskCommentsAndDescription)}>
              {strippedDescription !== '' ? (
                <FontAwesomeIcon icon={faFileAlt} />
              ) : null}
              {showCommentIcon ? (
                <div className={classes.taskComment}>
                  <FontAwesomeIcon icon={faComment} />
                  <div className={classes.taskCommentsCount}>
                    {totalComments}
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        )}
        <AdminConsoleDialog
          open={this.state.deleteHeaderDialogOpen}
          content={'Are you sure you would like to delete this header?'}
          onConfirm={() => {
            const { onDelete, TaskID, TaskItemID } = this.props
            if (onDelete) onDelete({ TaskID, TaskItemID }, { TaskID })
            this.setDeleteHeaderDialogOpen(false)
          }}
          consoleRole="destructive"
          onCancel={() => this.setDeleteHeaderDialogOpen(false)}
        />
      </div>
    )
  }

  handleOnClick(evt) {
    if (evt.target.type !== 'checkbox') {
      if (this.props.onClick) this.props.onClick(this.props.TaskItemID)
    }
  }

  setDeleteHeaderDialogOpen(open: boolean) {
    if (open !== this.state.deleteHeaderDialogOpen)
      this.setState({ deleteHeaderDialogOpen: open })
  }

  renderCompleted() {
    const { CompletedBy, CompletedUTC } = this.props

    return (
      <span>
        Completed{' '}
        <Moment
          local
          format={appDateTimeFormat}
          date={getLocalDate(CompletedUTC)}
        />
        {CompletedBy ? ` by ${CompletedBy}` : null}
      </span>
    )
  }

  renderDueBy() {
    const { DueByUTC } = this.props
    if (!DueByUTC) return
    const overdue = new Date() > getLocalDate(DueByUTC)
    return (
      <span
        className={cx(
          classes.taskTimeFrameText,
          overdue ? classes.taskTimeFrameOverdue : ''
        )}
      >
        <FontAwesomeIcon icon={faCalendar} style={{ marginTop: '-3px' }} />
        <Spacer spaceParam={'small'} />
        <Moment format={appDateTimeFormat} date={getLocalDate(DueByUTC)} />
      </span>
    )
  }

  renderTaskRole() {
    let itemRole = ''

    if (this.props.roleList) {
      itemRole = this.props.roleList.find((role) => {
        return role.OrgRoleID === parseInt(this.props.OrgRoleID)
      })
    }

    return (
      <div
        style={{
          textAlign: 'right',
          width: '100%',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        {itemRole && (
          <span className={classes.taskContentAssignedTo}>
            Role: {itemRole.RoleName}
          </span>
        )}
      </div>
    )
  }

  renderAssignedTo() {
    const { AssignedTo } = this.props

    return (
      <div
        style={{
          textAlign: 'right',
          width: '100%',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        {AssignedTo && (
          <span className={classes.taskContentAssignedTo}>
            Assigned to: {AssignedTo}
          </span>
        )}
      </div>
    )
  }
}

TaskListItem.propTypes = {
  /**
   * The name of the task
   */
  Content: PropTypes.string,
  /**
   * The id of the task item. This is used for connected mode.
   */
  TaskItemID: PropTypes.number,
  /**
   * The id of the task the task item belongs to. Used for making
   * api calls to the server.
   */
  TaskID: PropTypes.number,
  /**
   * The date when the task item was completed.
   */
  CompletedUTC: PropTypes.instanceOf(Date),
  CompletedBy: PropTypes.string,
  updateTaskItem: PropTypes.func,
  submitTaskItemUpdate: PropTypes.func,
  /**
   * whether the item is marked as selected
   */
  selected: PropTypes.bool,
  DueByUTC: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  /**
   * The name of the person assigned to the task.
   */
  AssignedTo: PropTypes.string,
  /**
   * Determines whether to display an icon signifying
   * whether the task has comments.
   */
  hasComments: PropTypes.bool,
  /**
   * The parent tag name
   */
  component: PropTypes.string,
  /**
   * The id of the app account that is assigned to the task
   */
  AssignedToAppAcctID: PropTypes.number,

  readOnly: PropTypes.bool,
  totalComments: PropTypes.number,
  TaskItemHeaderYN: PropTypes.string,
  OrgRoleId: PropTypes.number,
}

TaskListItem.defaultProps = {
  component: 'li',
  classes: {},
}

const mapStateToProps = function (state, ownProps) {
  const { TaskID } = ownProps
  const taskItem = selectTaskItemById(state, ownProps.TaskItemID) || {}

  const {
    Content,
    EntryCreatedBy,
    CreatedUTC,
    TaskItemID,
    CompletedUTC,
    DueByUTC,
    CompletedBy,
    CommentsTotal,
    AssignedTo,
    Description,
    TaskItemHeaderYN,
    OrgRoleID,
  } = taskItem || {}

  let userCompletedBy = selectUserEmail(state)
  const userFullName = selectUserFullName(state)

  if (userFullName) userCompletedBy += ` (${userFullName})`

  return {
    Content,
    EntryCreatedBy,
    CreatedUTC,
    TaskItemID,
    TaskID,
    DueByUTC: DueByUTC as Date,
    CompletedUTC,
    Description,
    totalComments: CommentsTotal,
    CompletedBy: CompletedUTC && !CompletedBy ? userCompletedBy : CompletedBy,
    AssignedTo,
    userCompletedBy,
    TaskItemHeaderYN,
    OrgRoleID,
  }
}

const mapDispatchToProps = {
  updateTaskItem: (_, __, ownProps) => {
    return toggleTaskItemCompletedUtc({
      ...ownProps,
      CompletedBy: ownProps.userCompletedBy,
    })
  },
  submitTaskItemUpdate: (_, __, ownProps) => {
    // Submit the completed state that already exist
    return updateTaskItemCompletedUtc(ownProps)
  },
  onDelete: TaskDetailActions.delete,
}

export default connect(mapStateToProps, mapDispatchToProps)(TaskListItem)
