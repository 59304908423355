import * as React from 'react'

// Fixed typings for css and scss modules
// https://stackoverflow.com/questions/41336858/how-to-import-css-modules-with-typescript-react-and-webpack
// From: Matt Crinklaw-Vogt
import { PbItem, PbItemProps } from './PbItem'
import { PollBanner, PollBannerProps } from './PollBanner'
import { Paper } from '@material-ui/core'
import { cx } from '../_utils/objectUtils'
import { CircularProgress } from '../Shared/LoadingBackdrop'
import { useAppSelector } from '../_utils/reactReduxHooks'
import { selectPlaybookList, selectPlaybookSearchValue } from './_selectors'
import { selectActivePollList } from '../ActivePoll/_selectors'

interface Props {
  onItemClick?(pub: PbItemProps): void
  onPollClick?(poll: PollBannerProps): void
  selected?: string
  className?: string
  hasPaper?: boolean
}

export const PbList = ({
  onItemClick,
  onPollClick,
  selected,
  className,
  hasPaper = true,
}: Props) => {
  //const [activePolls, setActivePolls] = React.useState<activePollList | undefined>(undefined)
  const [pushsubscription, setPushsubscription] = React.useState<any>('')
  const [vapidRegistering, setVapidRegistering] = React.useState(false)

  const searchvalue = useAppSelector(selectPlaybookSearchValue)?.toLowerCase()

  const pbListFromStore = useAppSelector(selectPlaybookList)
  const activePollListFromStore = useAppSelector(selectActivePollList)

  async function VAPIDsubscribe(topic: string, pushKey: string) {
    //console.log("called VAPIDsubscribe");
    if (pushsubscription) {
      return
    }
    if (!navigator.serviceWorker) {
      //console.log("DRH DEBUG: failed to find serviceWorker, giving up");
      return
    }

    if (vapidRegistering) {
      //console.log("already in VAPIDsubscribe, skipping this one");
      return
    }
    setVapidRegistering(true)

    try {
      var swReg = await navigator.serviceWorker.register('/swpush.js')
      if (!swReg) {
        //console.log("DRH DEBUG: FAILED to register serviceWorker");
        return
      }
      if (!swReg.active) {
        //console.log("DRH DEBUG:  swReg is not yet active, try again in 2.5 seconds");
        // let us call this again in 2.5 seconds
        setTimeout(function () {
          VAPIDsubscribe(topic, pushKey)
        }, 2500)

        return
      }
      //console.log("DRH DEBUG: successfully registered serviceWorker");
      var srpm = swReg.pushManager
      if (!srpm) {
        //console.log("DRH DEBUG: failed to get swReg.pushManager");
        return
      }

      const subscription = await swReg.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey: pushKey,
      })
      if (subscription) {
        //console.log("DRH DEBUG: successfully subscribed to serviceWorker");
        //console.log("DRH DEBUG: recording subscription in backend database");
        var subjson = JSON.stringify(subscription)
        var pushsubloc = window.location.href
        var useragent = navigator.userAgent
        var customHeaders: any = []
        customHeaders.push({
          name: 'pushsubscription',
          value: `${subjson}`,
        })
        customHeaders.push({
          name: 'pushsubloc',
          value: `${pushsubloc}`,
        })
        customHeaders.push({
          name: 'useragent',
          value: `${useragent}`,
        })
        setPushsubscription(subjson)
        //console.log("DRH DEBUG: back from recording subscription in backend database");
      } else {
        //console.log("DRH DEBUG: tried to subscribe but did not get subscription");
      }
    } catch (error) {
      console.log('got error trying to subscribe to serviceWorker')
      console.log(error)
      //console.log("DRH DEBUG: got error trying to subscribe to serviceWorker: " + error);
    }
  }
  function VAPIDpushRegister(pushKey: string) {
    if (pushsubscription) {
      return
    }
    // console.log('VAPIDpushRegister for push notifications ' + pushKey)
    if (!('Notification' in window)) {
      return
    } else if (Notification.permission === 'granted') {
      VAPIDsubscribe('news', pushKey)
    } else if (Notification.permission !== 'denied') {
      Notification.requestPermission(function (permission) {
        // If the user accepts, let's create a notification
        if (permission === 'granted') {
          //var notification = new Notification("You just gave us permission to send this:  Hi there!");
          //console.log('gonna VAPIDsubscribe for push notifications')
          VAPIDsubscribe('news', pushKey)
        }
      })
    }
  }

  function renderList() {
    if (!pbListFromStore) return <CircularProgress />

    // if we got this far, then we have some playbooks.
    // first, let us return something back to parent

    if (!pushsubscription) {
      if ('serviceWorker' in navigator && 'PushManager' in window) {
        if (!pushsubscription) {
          if (pbListFromStore!.VAPID_publicKey) {
            VAPIDpushRegister(pbListFromStore.VAPID_publicKey)
          }
        }
      } else {
        console.log('Push messaging is not suported')
      }
    }

    // then, let us go ahead with our rendering
    return (
      <>
        {renderBannerList()}
        {renderPlaybookList()}
      </>
    )
  }

  const openInNewTab = (url: string) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
  }

  function renderBannerList() {
    return activePollListFromStore?.Polls?.map((pb: PollBannerProps) => (
      <PollBanner
        selected={pb.PUR_GUID === selected}
        key={pb.PUR_GUID}
        {...pb}
        onClick={() => openInNewTab(pb.PollURL)}
      />
    ))
  }

  function renderPlaybookList() {
    //console.log("DRH DEBUG Pblist renderPlaybookList");
    //let pbListObj = JSON.parse(pbListFromStore);
    return pbListFromStore?.PbList?.filter((pb: PbItemProps) => {
      //    return playbooks?.PbList?.filter((pb: PbItemProps) => {
      if (!searchvalue) return true
      return (
        pb.PubTitle.toLowerCase().indexOf(searchvalue) > -1 ||
        pb.OrgName.toLowerCase().indexOf(searchvalue) > -1 ||
        pb.PublicationTag?.toLowerCase().indexOf(searchvalue) > -1 ||
        pb.Keywords?.toLowerCase().indexOf(searchvalue) > -1
      )
    }).map((pb: PbItemProps) => (
      <PbItem
        selected={pb.PubGUID === selected}
        key={pb.PubGUID}
        {...pb}
        onClick={onItemClick}
      />
    ))
  }

  if (!hasPaper)
    return (
      <div className={cx('container-fluid', className, 'p-2')}>
        {renderList()}
      </div>
    )

  return (
    <Paper className={cx('container-fluid', className, 'p-2')}>
      {renderList()}
    </Paper>
  )
}
