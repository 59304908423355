// @ts-nocheck
// TODO: Typescript
import {
  FormControl,
  MenuItem,
  Select,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  ListItemText,
  InputLabel,
  OutlinedInput,
} from '@material-ui/core'
import React, {
  ChangeEvent,
  CSSProperties,
  FC,
  Fragment,
  useEffect,
  useRef,
  useState,
} from 'react'
import Moment from 'react-moment'
// @ts-ignore
import {
  AutoSizer,
  CellMeasurer,
  InfiniteLoader,
  List,
} from 'react-virtualized'
import {
  StretchBody,
  StretchHeader,
  StretchLayout,
} from '../Layouts/StretchLayout'
import { appDateTimeFormat } from '../_constants'
import { addDaysToDate, getLocalDate } from '../_utils/dateUtils'
import { cx } from '../_utils/objectUtils'
import tableClasses from './TableFixed.module.scss'
import iconClasses from './ActivityLog.module.scss'
import Checkbox from '../CustomMaterial/Checkbox'
import { useCellMeasureCache } from '../List/_hooks'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Switch from '@material-ui/core/Switch'

import { useHistory } from 'react-router'
import {
  faFileChartLine,
  faPhoneAlt,
  faFileAlt,
  faClipboardList,
  faCommentDots,
  faBell,
  faUsers,
  faMinus,
  faUserPlus,
  faClipboard,
  faFileImport,
  faShopLock,
  faFileCheck,
  faCalendarLinesPen,
  faLocationDot,
} from '@fortawesome/pro-light-svg-icons'
import { useAppSelector } from '../_utils/reactReduxHooks'
import { selectIssueById } from '../Issue/_selectors'

const classes: any = tableClasses

const issueLogItemsCompiled = [
  'Issue Created',
  'Severity Changed',
  'Issue Resolved',
  'Issue Reopened',
  'Issue Type Changed',
  'Issue Created from Template',
  'Issue Description Changed',
  'Issue Name Changed',
  'Issue Trend Changed',
  'Issue Position Changed',
  'Issue Action Changed',
  'Issue Next Steps Changed',
  'Issue Mode Changed',
  'Issue Added As Template',
  'Issue Removed As Template',
  'Issue Copied from Another Issue',
]
const holdingStatementLogItemsCompiled = [
  'Holding Statement Edited',
  'Holding Statement Approved',
  'Holding Statement Copied',
]
const artifactLogItemsCompiled = [
  'Attachment Added',
  'Attachment Edited',
  'Attachment Deleted',
  'Incident Report Added To Issue',
  'Link Added',
  'Link Deleted',
  'Link Updated',
]
const stakeholderLogItemsCompiled = [
  'Stakeholder Added',
  'Stakeholder Edited',
  'Stakeholder Deleted',
  'Stakeholder Messaging',
]
const locationLogItemsCompiled = [
  'Location Added',
  'Location Updated',
  'Location Deleted',
]
const participantLogItemsCompiled = [
  'Participant Added',
  'Participant Team Added',
  'Participant Deleted',
  'Participant Team Deleted',
  'Participant Level Changed',
]
const conferenceLogItemsCompiled = [
  'Meeting Added',
  'Meeting Changed',
  'Meting Deleted',
]
const tasklistLogItemsCompiled = [
  'Tasklist Item Assigned',
  'Tasklist Item Completed',
  'Tasklist Item Un-Assigned',
  'Tasklist Item Added',
  'Tasklist Item Changed',
  'Tasklist Item Deleted',
  'Tasklist Items Uploaded',
  'Tasklist Item Role Assigned',
  'Tasklist Item Role Un-Assigned',
]

const millisecondsInAWeek =
  addDaysToDate(new Date(), 7).getTime() - new Date().getTime()
const happenedStyle: CSSProperties = {
  flexGrow: 1,
  minWidth: 150,
  maxWidth: 200,
}
const activityStyle: CSSProperties = {
  width: '60%',
}
const performedByStyle: CSSProperties = {
  width: '40%',
}
const listStyle: CSSProperties = {
  overflowY: 'scroll',
}
const headerStyle: CSSProperties = {
  paddingRight: 20,
}

export interface FetchLogProps {
  lastLoadedRow?: number
  offset: number
  pageSize: number
}

export interface ActivityLogApi {
  refresh: Function
}

let activityLogTimeline = true

interface Props {
  items: {
    Id: number
    Activity: String
    Details: String
    PerformedBy: String
    HappenedGMT: Date
  }[]
  defaultItemHeight?: number
  minItemHeight?: number
  rowCount?: number
  loadingDone: boolean
  fetchData(params: FetchLogProps): void
  loadingBuffer: number
  lastLoadedRow?: number
  pageSize: number
  onLastLoadedRowChange(lastRowIndex: number): void
  api(api: ActivityLogApi): void
  filterLabel?: string
  filterOptions?: any[]
  filterItemLabel?: (item: any) => string
  filterItemValue?: (item: any) => string
  filterValue?: string[]
  onFilterChange?: (value: string[]) => void
  filterEmptyLabel?: string
  filterShowEmpty?: boolean
  IssueID: number
}

export function ActivityLog({
  items = [],
  defaultItemHeight = 100,
  minItemHeight = 54,
  rowCount,
  loadingDone,
  fetchData = () => {},
  lastLoadedRow = 0,
  loadingBuffer = 20,
  pageSize,
  onLastLoadedRowChange = () => {},
  api = () => {},
  onFilterChange,
  filterValue,
  filterEmptyLabel = 'All',
  filterShowEmpty = false,
  filterLabel = 'Category',
  filterOptions,
  filterItemValue = (item) => item.value,
  filterItemLabel = (item) => item.label,
  IssueID,
}: Props) {
  const prevLastLoadedRow = useRef(0)
  const virtualizedList = useRef<any>(null)

  const history = useHistory()

  const [timeline, setTimeline] = useState(activityLogTimeline)

  const issue = useAppSelector((state) =>
    selectIssueById(state, IssueID as any)
  )

  const evidenceLockerCustomName =
    issue?.Settings?.find((isv: any) => isv.SettingType === 'L')?.SettingYN ===
    'Y'

  useEffect(() => {
    return () => {
      activityLogTimeline = timeline
    }
  }, [timeline])

  const cache = useCellMeasureCache(items, {
    defaultHeight: defaultItemHeight,
    minHeight: minItemHeight,
    listRef: virtualizedList,
    api,
  })
  const cache2 = useCellMeasureCache(items, {
    defaultHeight: defaultItemHeight,
    minHeight: minItemHeight,
    listRef: virtualizedList,
    api,
  })

  useEffect(() => {
    const newLastLoadedRow = items.length - 1
    if (prevLastLoadedRow.current !== newLastLoadedRow) {
      prevLastLoadedRow.current = newLastLoadedRow
      onLastLoadedRowChange(prevLastLoadedRow.current)
    }
  }, [items, onLastLoadedRowChange])

  useEffect(() => {
    fetchData({ offset: 0, pageSize })
  }, [fetchData, pageSize])

  /*********Logic*******/
  function loadMoreRows({ startIndex }: { startIndex: number }) {
    if (lastLoadedRow === 0 || !isRowLoaded(startIndex)) {
      const newOffset = lastLoadedRow + 1
      fetchData({ offset: newOffset, pageSize })
      const newLastLoadedRow = newOffset + pageSize - 1
      prevLastLoadedRow.current = newLastLoadedRow
      onLastLoadedRowChange(newLastLoadedRow)
    }
  }

  function isRowLoaded(index: any) {
    if (index?.index !== undefined) index = index.index

    if (loadingDone) return true

    const lastOffsetBuffered = lastLoadedRow - loadingBuffer

    return index <= lastOffsetBuffered || lastOffsetBuffered <= 0
  }

  const navigateToAction = (logItemType: string, details: string) => {
    if (issueLogItemsCompiled.includes(logItemType)) {
      history.push('./summary')
    }
    if (locationLogItemsCompiled.includes(logItemType)) {
      history.push('./summary')
    }
    if (holdingStatementLogItemsCompiled.includes(logItemType)) {
      history.push('./holdingstatement')
    }
    if (artifactLogItemsCompiled.includes(logItemType)) {
      history.push(
        evidenceLockerCustomName ? './filesformslinks' : './evidencelocker'
      )
    }
    if (stakeholderLogItemsCompiled.includes(logItemType)) {
      history.push('./stakeholders')
    }
    if (participantLogItemsCompiled.includes(logItemType)) {
      history.push('./participants')
    }
    if (conferenceLogItemsCompiled.includes(logItemType)) {
      history.push('./meetings')
    }
    if (tasklistLogItemsCompiled.includes(logItemType)) {
      history.push('./tasks')
    }
    if (logItemType === 'Chat Entry Added') {
      history.push('./chats')
    }
    if (logItemType === 'Form Added To Issue') {
      //history.push('./teamForms')
      history.push('./filloutforms')
    }
    if (logItemType === 'Executive Action') {
      history.push('./execbrief')
    }
    if (logItemType === 'Tasklist Items Uploaded') history.push('./tasks')
    if (logItemType === 'Assessment Added') history.push('./assessments')
    if (logItemType === 'Scribe Notes Added') history.push('./scribe')
  }

  function handleFilterChange(evt: ChangeEvent<HTMLInputElement>) {
    if (evt.target.value.includes('SelectAll')) {
      onFilterChange?.call(undefined, [] as any)
    } else {
      onFilterChange?.call(undefined, evt.target.value as any)
    }
  }
  const timelineChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTimeline(event.target.checked)
  }
  /****Components*****/
  const When: FC<{ children: Date; classNames: Array<string> }> = ({
    children,
    classNames = '',
  }) => {
    if (!children) return null

    let dateHappened = getLocalDate(children)
    let format = appDateTimeFormat
    return (
      <Moment
        className={classNames}
        fromNow={true}
        fromNowDuring={millisecondsInAWeek}
        local
        format={format}
      >
        {dateHappened}
      </Moment>
    )
  }

  /*********Renderers********/
  function renderLogItem({
    style,
    index,
    parent,
    key,
  }: {
    style: CSSProperties
    index: number
    parent: any
    key: any
  }) {
    const { Id, Activity, Details, PerformedBy, HappenedGMT } =
      items[index] || {}

    return (
      <CellMeasurer
        cache={cache.current}
        columnIndex={0}
        key={key}
        parent={parent}
        rowIndex={index}
      >
        <div
          onClick={() => navigateToAction(Activity, Details)}
          className={classes.row}
          style={{ ...style, cursor: 'pointer' }}
          key={Id}
        >
          <div
            className={cx(classes.cell, classes.multiLine)}
            style={activityStyle}
          >
            <span className="font-weight-bold">{Activity}</span>
            <br />
            <div style={{ fontSize: '12px' }}>{Details}</div>
          </div>
          <div
            className={cx(classes.cell, classes.multiLine)}
            style={performedByStyle}
          >
            {PerformedBy}
          </div>
          <div
            className={cx(classes.cell, classes.multiLine)}
            style={happenedStyle}
          >
            {HappenedGMT ? <When>{HappenedGMT}</When> : ''}
          </div>
        </div>
      </CellMeasurer>
    )
  }
  function renderTimelineItem({
    style,
    index,
    parent,
    key,
  }: {
    style: CSSProperties
    index: number
    parent: any
    key: any
  }) {
    const { Id, Activity, Details, HappenedGMT, PerformedBy } =
      items[index] || {}
    return (
      <CellMeasurer
        cache={cache2.current}
        columnIndex={0}
        key={key}
        parent={parent}
        rowIndex={index}
      >
        <div
          className={classes.row}
          style={{ ...style, width: '100%' }}
          key={Id}
        >
          {index % 2 === 0 ? (
            <div
              className={cx(classes.multiLine, iconClasses.flexColumnStandard)}
              style={{
                width: '100%',
                alignItems: 'flex-end',
                cursor: 'pointer',
              }}
              onClick={() => navigateToAction(Activity, Details)}
            >
              <div className={cx(iconClasses.spacerLarge)} />
              <div className={cx(iconClasses.spacerLarge)} />

              <h6 style={{ textAlign: 'right', fontSize: '18px' }}>
                {Activity}
              </h6>
              <span style={{ textAlign: 'right', fontSize: '14px' }}>
                {Details}
              </span>
              <div className={cx(iconClasses.spacerLarge)} />
              <div className={cx(iconClasses.spacerLarge)} />
            </div>
          ) : (
            <div
              className={cx(classes.multiLine, iconClasses.flexColumnStandard)}
              style={{
                width: '100%',
                alignItems: 'flex-end',
                fontSize: '14px',
              }}
            >
              {PerformedBy && <div>{PerformedBy}</div>}
              {HappenedGMT ? (
                <When
                  classNames={cx(iconClasses.fadedFont)}
                  style={{
                    textAlign: 'right',
                  }}
                >
                  {HappenedGMT}
                </When>
              ) : (
                ''
              )}
            </div>
          )}
          <div
            className={iconClasses.flexColumnStandard}
            style={{ width: '72px', minWidth: '72px' }}
          >
            <div
              className={cx(
                index === 0
                  ? iconClasses.verticalLineInvisible
                  : iconClasses.verticalLine
              )}
            />
            <div style={{ padding: '8px 0' }}>{determineIcon(Activity)}</div>
            <div
              className={cx(
                index + 1 === rowCount
                  ? iconClasses.verticalLineInvisible
                  : iconClasses.verticalLine
              )}
            />
          </div>
          {index % 2 === 1 ? (
            <div
              className={cx(classes.multiLine, iconClasses.flexColumnStandard)}
              style={{
                width: '100%',
                alignItems: 'flex-start',
                cursor: 'pointer',
              }}
              onClick={() => navigateToAction(Activity, Details)}
            >
              <div className={cx(iconClasses.spacerLarge)} />
              <div className={cx(iconClasses.spacerLarge)} />

              <h6 style={{ textAlign: 'left', fontSize: '18px' }}>
                {Activity}
              </h6>
              <span style={{ textAlign: 'left', fontSize: '14px' }}>
                {Details}
              </span>
              <div className={cx(iconClasses.spacerLarge)} />
              <div className={cx(iconClasses.spacerLarge)} />
            </div>
          ) : (
            <div
              className={cx(classes.multiLine, iconClasses.flexColumnStandard)}
              style={{
                width: '100%',
                alignItems: 'flex-start',
                fontSize: '14px',
              }}
            >
              {PerformedBy && <div>{PerformedBy}</div>}
              {HappenedGMT ? (
                <When
                  classNames={cx(iconClasses.fadedFont)}
                  style={{
                    textAlign: 'left',
                  }}
                >
                  {HappenedGMT}
                </When>
              ) : (
                ''
              )}
            </div>
          )}
        </div>
      </CellMeasurer>
    )
  }

  if (!rowCount) rowCount = items.length

  return (
    <Fragment>
      <StretchLayout>
        <StretchHeader style={headerStyle}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <label style={{ padding: '0', margin: '0' }}>Log View</label>
            <Switch
              checked={timeline}
              color="primary"
              onChange={timelineChange}
              inputProps={{
                'aria-label': 'controlled',
              }}
            />
            <label style={{ padding: '0', margin: '0' }}>Timeline View</label>
          </div>
          <FormControl className="form-control mt-1 mb-2">
            <InputLabel shrink={filterShowEmpty}>{filterLabel}</InputLabel>
            {filterOptions && (
              <Select
                input={<OutlinedInput label="Name" />}
                multiple={true}
                displayEmpty={filterShowEmpty}
                renderValue={() => {
                  if (!filterValue || filterValue.length === 0)
                    return filterEmptyLabel

                  return filterValue?.map((item) => item).join(', ')
                }}
                value={filterValue}
                onChange={handleFilterChange as any}
              >
                {/*filterShowEmpty && <MenuItem
                                    disabled={true}
                                    value={filterEmptyLabel}
                                >
                                    {filterEmptyLabel}
                                </MenuItem>
                            */}
                <MenuItem
                  label="Select"
                  value={'SelectAll'}
                  key={100}
                  selected={!(filterValue && filterValue.length > 0)}
                >
                  <Checkbox
                    checked={!(filterValue && filterValue.length > 0)}
                  />
                  <ListItemText primary={'Select All'} />
                </MenuItem>
                {filterOptions.map((item) => {
                  const isSelected =
                    filterValue &&
                    filterValue.indexOf(filterItemValue(item)) > -1
                  return (
                    <MenuItem
                      label="Select"
                      value={filterItemValue(item)}
                      key={filterItemValue(item)}
                      selected={isSelected}
                    >
                      <Checkbox checked={isSelected} />
                      <ListItemText primary={filterItemLabel(item)} />
                    </MenuItem>
                  )
                })}
              </Select>
            )}
          </FormControl>
          {timeline ? (
            <>
              <div className={cx(iconClasses.spacerLarge)} />
              <div className={cx(iconClasses.spacerMedium)} />
            </>
          ) : (
            <TableContainer className={cx(classes.root, 'overflow-hidden')}>
              <Table>
                <TableHead>
                  <TableRow className={classes.row}>
                    <TableCell style={activityStyle}>Activity</TableCell>
                    <TableCell style={performedByStyle}>By Whom</TableCell>
                    <TableCell style={happenedStyle}>When</TableCell>
                  </TableRow>
                </TableHead>
              </Table>
            </TableContainer>
          )}
        </StretchHeader>
        <StretchBody className="overflow-hidden">
          <AutoSizer>
            {({ height, width }: { height: number; width: number }) => (
              <InfiniteLoader
                isRowLoaded={isRowLoaded}
                loadMoreRows={loadMoreRows}
                rowCount={rowCount}
                minimumBatchSize={pageSize}
              >
                {({
                  onRowsRendered,
                  registerChild,
                }: {
                  onRowsRendered: any
                  registerChild: any
                }) => (
                  <List
                    deferredMeasurementCache={
                      timeline ? cache2.current : cache.current
                    }
                    className={classes.root}
                    ref={(ref) => {
                      registerChild(ref)
                      virtualizedList.current = ref
                    }}
                    onRowsRendered={onRowsRendered}
                    width={width}
                    height={height}
                    style={listStyle}
                    rowGetter={({ index }) => items[index]}
                    rowCount={rowCount}
                    rowHeight={
                      timeline
                        ? cache2.current.rowHeight
                        : cache.current.rowHeight
                    }
                    rowRenderer={timeline ? renderTimelineItem : renderLogItem}
                  />
                )}
              </InfiniteLoader>
            )}
          </AutoSizer>
        </StretchBody>
      </StretchLayout>
    </Fragment>
  )
}

/*let testItems = [
    { Id: 0, PerformedBy: "davrhill@gmail.com", Activity: "Severity Changed", Details: "Changed to Medium because ** no reason given **", HappenedGMT: new Date() },
    { Id: 1, PerformedBy: "davrhill@gmail.com", Activity: "Participant Added", Details: "ray.baldwin@rockdovesolutions.com added as Participant", HappenedGMT: new Date("2021-06-11T00:58:12.616Z") },
    { Id: 2, PerformedBy: "davrhill@gmail.com", Activity: "Participant Added Long Category Name", Details: "ray.baldwin@rockdovesolutionsReallyLongDomainName.com added as Participant. Testing text length", HappenedGMT: new Date("2021-06-11T03:58:12.616Z") },
    { Id: 3, PerformedBy: "davrhill@gmail.comReallyLongByWhomText", Activity: "Participant Added Long Category Name", Details: "ray.baldwin@rockdovesolutionsReallyLongDomainName.com added as Participant. Testing text length", HappenedGMT: new Date("2021-06-10T18:58:12.616Z") },
    { Id: 4, PerformedBy: "davrhill@gmail.comReallyLongByWhomText", Activity: "Participant Added Long Category Name", Details: "ray.baldwin@rockdovesolutionsReallyLongDomainName.com added as Participant. Testing text length", HappenedGMT: new Date("2021-05-10T18:58:12.616Z") },
    { Id: 5, PerformedBy: "davrhill@gmail.comReallyLongByWhomText", Activity: "Participant Added Long Category Name", Details: "ray.baldwin@rockdovesolutionsReallyLongDomainName.com added as Participant. Testing text length", HappenedGMT: new Date("2021-06-04T18:58:12.616Z") },
    { Id: 6, PerformedBy: "davrhill@gmail.comReallyLongByWhomText", Activity: "Participant Added Long Category Name", Details: "ray.baldwin@rockdovesolutionsReallyLongDomainName.com added as Participant. Testing text length", HappenedGMT: new Date("2021-06-03T18:58:12.616Z") }
];

const start = testItems.length;
for (var i = 0; i < 100; i++) {
    const index = i % testItems.length;
    testItems.push({ ...testItems[index], Id: i + start });
}

IssueActivityLog.propTypes = {
    items: PropTypes.array
};*/

/*IssueActivityLog.defaultProps = {
    items: testItems
};*/

function determineIcon(logItemType: string) {
  if (issueLogItemsCompiled.includes(logItemType)) {
    return (
      <FontAwesomeIcon
        icon={faMinus}
        size="lg"
        className={cx(iconClasses.icon, iconClasses.flexColumnStandard)}
        style={undefined}
      />
    )
  }

  if (holdingStatementLogItemsCompiled.includes(logItemType)) {
    return (
      <FontAwesomeIcon
        icon={faFileAlt}
        size="lg"
        className={cx(iconClasses.icon, iconClasses.flexColumnStandard)}
        style={undefined}
      />
    )
  }
  if (artifactLogItemsCompiled.includes(logItemType)) {
    return (
      <FontAwesomeIcon
        icon={faShopLock}
        size="lg"
        className={cx(iconClasses.icon, iconClasses.flexColumnStandard)}
        style={undefined}
      />
    )
  }
  if (stakeholderLogItemsCompiled.includes(logItemType)) {
    return (
      <FontAwesomeIcon
        icon={faUsers}
        size="lg"
        className={cx(iconClasses.icon, iconClasses.flexColumnStandard)}
        style={undefined}
      />
    )
  }
  if (locationLogItemsCompiled.includes(logItemType)) {
    return (
      <FontAwesomeIcon
        icon={faLocationDot}
        size="lg"
        className={cx(iconClasses.icon, iconClasses.flexColumnStandard)}
        style={undefined}
      />
    )
  }
  if (participantLogItemsCompiled.includes(logItemType)) {
    return (
      <FontAwesomeIcon
        icon={faUserPlus}
        size="lg"
        className={cx(iconClasses.icon, iconClasses.flexColumnStandard)}
        style={undefined}
      />
    )
  }
  if (conferenceLogItemsCompiled.includes(logItemType)) {
    return (
      <FontAwesomeIcon
        icon={faPhoneAlt}
        size="lg"
        className={cx(iconClasses.icon, iconClasses.flexColumnStandard)}
        style={undefined}
      />
    )
  }
  if (tasklistLogItemsCompiled.includes(logItemType)) {
    return (
      <FontAwesomeIcon
        icon={faClipboardList}
        size="lg"
        className={cx(iconClasses.icon, iconClasses.flexColumnStandard)}
        style={undefined}
      />
    )
  }
  if (logItemType === 'Form Added To Issue') {
    return (
      <FontAwesomeIcon
        icon={faClipboard}
        size="lg"
        className={cx(iconClasses.icon, iconClasses.flexColumnStandard)}
        style={undefined}
      />
    )
  }
  if (logItemType === 'Executive Action') {
    return (
      <FontAwesomeIcon
        icon={faFileChartLine}
        size="lg"
        className={cx(iconClasses.icon, iconClasses.flexColumnStandard)}
        style={undefined}
      />
    )
  }
  if (logItemType === 'Chat Entry Added') {
    return (
      <FontAwesomeIcon
        icon={faCommentDots}
        size="lg"
        className={cx(iconClasses.icon, iconClasses.flexColumnStandard)}
        style={undefined}
      />
    )
  }
  if (logItemType === 'Stakeholder Messaging') {
    return (
      <FontAwesomeIcon
        icon={faBell}
        size="lg"
        className={cx(iconClasses.icon, iconClasses.flexColumnStandard)}
        style={undefined}
      />
    )
  }
  if (logItemType === 'Tasklist Items Uploaded') {
    return (
      <FontAwesomeIcon
        icon={faFileImport}
        size="lg"
        className={cx(iconClasses.icon, iconClasses.flexColumnStandard)}
        style={undefined}
      />
    )
  }
  if (logItemType === 'Assessment Added') {
    return (
      <FontAwesomeIcon
        icon={faFileCheck}
        size="lg"
        className={cx(iconClasses.icon, iconClasses.flexColumnStandard)}
        style={undefined}
      />
    )
  }
  if (logItemType === 'Scribe Notes Added') {
    return (
      <FontAwesomeIcon
        icon={faCalendarLinesPen}
        size="lg"
        className={cx(iconClasses.icon, iconClasses.flexColumnStandard)}
        style={undefined}
      />
    )
  }
}

export default ActivityLog
