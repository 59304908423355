// @ts-nocheck
// TODO: Typescript
import * as React from 'react'
import { ReactNode, CSSProperties } from 'react'
import * as layoutClasses from './MobileViewLayout.module.scss'
import { Paper } from '@material-ui/core'
import { cx } from '../_utils/objectUtils'
import {
  StretchLayout,
  StretchHeader,
  StretchBody,
} from '../Layouts/StretchLayout'
import { ViewHeader } from '../Shared/ViewHeader'
import { HeaderNavigation } from '../Shared/HeaderNavigation'
import { isMobileApp as isMobileAppSel } from '../_selectors'
import { useAppSelector } from '../_utils/reactReduxHooks'

const classes: any = layoutClasses

interface Props {
  title?: string
  children: ReactNode
  onNavClick?(event: React.MouseEvent): void
  actions?: ReactNode
  overflowY?: 'hidden' | 'auto' | 'visible'
  classes?: { content?: string }
  styles?: { content?: CSSProperties }
}

const headerNavClasses = { navIconClickArea: classes.navClickArea }

export const MobileViewLayout = ({
  title,
  onNavClick,
  children,
  actions,
  overflowY = 'auto',
  classes: ownClasses = {},
  styles = {},
}: Props) => {
  const isMobileApp = useAppSelector(isMobileAppSel)

  let rootClasses = classes.root
  if (overflowY) rootClasses += ' ' + classes['overflowY' + overflowY]

  return (
    <div
      className={cx(
        rootClasses,
        ownClasses.root,
        !isMobileApp ? classes.standardApp : ''
      )}
    >
      <Paper
        className="paperBack"
        style={{ height: '100%', position: 'relative' }}
      >
        <StretchLayout>
          <StretchHeader>
            <ViewHeader className={classes.viewHeader}>
              <HeaderNavigation
                onNavClick={onNavClick}
                title={title}
                actions={actions}
                classes={!isMobileApp && headerNavClasses}
              />
            </ViewHeader>
          </StretchHeader>
          <StretchBody
            className={cx(ownClasses.issueDetail, ownClasses.content)}
            style={styles.content}
          >
            {children}
          </StretchBody>
        </StretchLayout>
      </Paper>
    </div>
  )
}

export default MobileViewLayout
