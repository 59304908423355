import React from 'react'
import { SearchInput } from '../Shared/SearchInput'
import { prefixSelectorWithApp } from '../_utils/domUtils'
import { useAppDispatch, useAppSelector } from '../_utils/reactReduxHooks'
import { SavePlaybookSearch } from './_actions'
import { selectPlaybookSearchValue } from './_selectors'
interface props {
  className: string
}
export function PlaybookSearch({ className }: props) {
  const value = useAppSelector(selectPlaybookSearchValue)
  const dispatch = useAppDispatch()

  function applyFilter(value: string) {
    dispatch(SavePlaybookSearch(value))
  }

  function removeDescription() {
    dispatch(SavePlaybookSearch(''))
  }

  return (
    <SearchInput
      className={className}
      id={prefixSelectorWithApp('global-playbBookSearch')}
      name="descriptionFilter"
      placeholder="Search keyword in Title, Organization, or Tag"
      value={value}
      applySearch={applyFilter}
      changeDelay={200}
      onClear={removeDescription}
      includeSearchIcon={true}
    />
  )
}
