import React from 'react'
import { CircularProgress } from '../Shared/LoadingBackdrop'
//import moment from 'moment';
import classes from '../Privacy/Privacy.module.scss'
import { Row, Col, Button, Label } from 'reactstrap'
import { http } from '../_utils/http'
import { cx } from '../_utils/objectUtils'
import { HiddenFormWithValues } from '../Playbook/HiddenFormWithValues'
import Checkbox from '../CustomMaterial/Checkbox'
import { Redirect } from 'react-router'

interface Props {
  onDataLoaded?: (privacyStuff: any) => void
}

interface AppReqJSON {
  email_address?: string // used for links
  appDevGUID?: string
  password?: string
}

interface privhfv {
  AppReqJSON?: string
}

export const Privacy = ({ onDataLoaded }: Props) => {
  const [privacyStuff, setPrivacyStuff] = React.useState<any>('')
  const [privacyAccepted, setPrivacyAccepted] = React.useState<any>(false)
  const [initialView, setInitialView] = React.useState<any>('')
  const [privacyStuffLoading, setPrivacyStuffLoading] = React.useState(true)
  const [isChecked, setIsChecked] = React.useState(false)

  const getprivacyStuff = async (): Promise<any> => {
    const result = await http<any>({
      path: '/playbook/PrivacyStatement',
    })
    if (result.ok && result.body) {
      //pbStr = result.body;
      return result.body
    } else {
      return ''
    }
  }

  const acceptPrivacy = async (): Promise<any> => {
    const result = await http<any>({
      path: '/playbook/PrivacyAccept',
    })
    if (result.ok && result.body) {
      //pbStr = result.body;
      setPrivacyAccepted(true)
      if (result.body.initialView) {
        setInitialView(result.body.initialView)
      }
      return result.body
    } else {
      return ''
    }
    // redirect to proper startup screen
  }

  React.useEffect(() => {
    let cancelled = false
    const doGetprivacyStuff = async () => {
      const privacyStuff = await getprivacyStuff()
      if (!cancelled) {
        setPrivacyStuff(privacyStuff)
        setPrivacyStuffLoading(false)

        if (onDataLoaded) onDataLoaded(privacyStuff)
      }
    }
    doGetprivacyStuff()

    return () => {
      cancelled = true
    }
  }, [])

  function toggleIsChecked(evt: any) {
    setIsChecked(!isChecked)
  }
  function handleButtonClick(evt: any) {
    if (isChecked) {
      acceptPrivacy()
      //alert('thank you');
    } else {
      alert('You must check the agree box to continue.')
    }
  }

  if (privacyAccepted) {
    if (initialView) {
      //return <Redirect to="/Issues" />;
      return <Redirect to={initialView} />
    }
  }

  if (privacyStuffLoading) return <CircularProgress />

  return (
    <>
      <div className={classes.privacyContainer}>
        <div
          dangerouslySetInnerHTML={{
            __html: privacyStuff.SummaryContentHTML || '',
          }}
        />
        <div>
          <a target="_blank" href={privacyStuff.DetailURL}>
            Privacy Details
          </a>
        </div>
        <Label>
          <Checkbox checked={isChecked} onChange={toggleIsChecked} />I Agree
        </Label>
        <br />
        <Button onClick={handleButtonClick}>Continue</Button>
      </div>
    </>
  )
}
