import React from 'react'
import {
  Badge as MUIBadge,
  BadgeProps as MUIBadgeProps,
} from '@material-ui/core'
import classes from './Badge.module.scss'
import { cx } from '../_utils/objectUtils'
interface BadgeProps extends MUIBadgeProps {}

export const Badge = ({
  children,
  classes: ownClasses,
  color,
  className,
  ...other
}: BadgeProps) => (
  <MUIBadge
    className={className}
    classes={ownClasses || { dot: cx(classes[color as string]) }}
    {...other}
    color={color}
  >
    {!other.dangerouslySetInnerHTML ? children : undefined}
  </MUIBadge>
)
export default Badge
