import * as React from 'react'
import './Playbooks.ContactView.css'

interface Props {
  Playbook?: any
}

export const ContactsView = ({ Playbook }: Props) => {
  return (
    <>
      <div className="mt-5">
        <h3>Contacts</h3>
        {/*
          DRH of RDS 22-May-2023
          When we call the default dangerouslySetInnerHTML
          the system will not execute the embedded SCRIPT tags
          This was causing a problem, so we put the javascript handling
          tags for collabsable contacts into a root-level javascript file
          named contacts.js which is called from the root-level index.html file.
        */}
        <div
          id="iccContacts"
          dangerouslySetInnerHTML={{
            __html: Playbook.Event.HTMLresponders,
          }}
        />
      </div>
    </>
  )
}
