/* // Abstract logging implementation

import { useRollbar } from '@rollbar/react'

interface Logger {

}

function useLogging() {
    const rollbar = useRollbar();
    rollbar.critical()
}*/

import Rollbar from 'rollbar'
import { isLocalhost } from '../registerServiceWorker'
import { getCookie } from './fetchUtils'

export function getLoggerConfig(): Rollbar.Configuration {
  let environment = getCookie('environment')

  const rollbarConfig: Rollbar.Configuration = {
    accessToken: '903e83fe36184c8392b07d6cd9d267d0',
    environment,
    captureUncaught: true,
    captureUnhandledRejections: true,
    payload: {
      client: {
        javascript: {
          code_version: '1.0.0',
          source_map_enabled: true,
        },
      },
    },
    enabled: !isLocalhost,
  }

  return rollbarConfig
}

export const logger = new Rollbar(getLoggerConfig())
