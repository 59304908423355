import 'es6-promise/auto'
import 'bootstrap/dist/css/bootstrap.css'
/*import classes from './ThemeProvider/bootstrap.override.module.css';*/
//import 'react-image-lightbox/style.css';
import './Form/Form.css'
import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router'
import App from './App'
import { unregister } from './registerServiceWorker'
import Startup from './Startup'
import { PendingSubmits } from './_utils/reduxUtils'
import LogRocket from 'logrocket'
import setupLogRocketReact from 'logrocket-react'
import store, { history } from './_store'
import ThemeProvider from './ThemeProvider/ThemeProvider'
import { Provider as RollBarProvider } from '@rollbar/react'
import { getCookie } from './_utils/fetchUtils'
import { getLoggerConfig } from './_utils/logging'

setupLogRocketReact(LogRocket)

// Only use as a last ditch effort (They are really expensive)
// https://app.asana.com/0/1186909008546219/1189088804818123
//LogRocket.init('7j47lo/icoissue');

const rootElement = document.getElementById('Ico_Issue_root')

const rollbarConfig = getLoggerConfig()

ReactDOM.render(
  <RollBarProvider config={rollbarConfig}>
    <Provider id="Ico_Issue_storeProvider" store={store}>
      <ThemeProvider>
        <Startup id="Ico_Issue_startUp" history={history}>
          <ConnectedRouter id="Ico_Issue_connectedRouter" history={history}>
            <App id="Ico_Issue_startUp_appRoot" />
          </ConnectedRouter>
        </Startup>
      </ThemeProvider>
    </Provider>
  </RollBarProvider>,
  rootElement
)

//registerServiceWorker();
unregister()

// Most browsers want some sort of user interaction before showing this prompt
window.onbeforeunload = function (e) {
  if (PendingSubmits.length > 0) {
    e.preventDefault()
    ;(e || window.event).returnValue =
      'Changes still being saved. Do you wish to quit now?'
  }
}
