import React, { useEffect, useState } from 'react'
import { useLocation, RouteComponentProps } from 'react-router'
import { useIcoFetch } from '../_utils/fetchUtils'
import { Spacer } from '../Shared/Spacer'
import { CircularProgress } from '@material-ui/core'
import { UserReportMenuItem } from '../Shared/UserReportMenuItem'
import { UserReportFormsMenuItem } from '../Shared/UserReportFormsMenuItem'
import { StretchBody } from '../Layouts/StretchLayout'
import { MasterPageLayout } from './MasterPageLayout'
import { DesktopLayout, MobileLayout } from '../Layouts/ResponsiveHidden'
import MobileViewLayout from '../Layouts/MobileViewLayout'
import { HPanelLayout, HPanel } from '../Layouts/HPanelLayout'
import { Container, Col, Row } from 'reactstrap'
import classes from '../Shared/UserReportMenuItem.module.scss'
import '../Shared/UserReportMenuItem.css'
import { cx } from '../_utils/objectUtils'
import { faPlusCircle } from '@fortawesome/pro-solid-svg-icons'

interface PushNotification {
  Content: string
  OrgID: number
  OrgName: string
  PubGUID: string
  PubTitle: string
  SentAt: string
  SentMessageID: string
  PlanName: string
  EventName: string
  CreatedGMT: string
  IncidentID: string
  ReportHTML: string
}
interface Props extends RouteComponentProps {}

export const UserReportsPage = ({ history, match, location }: Props) => {
  const [loading, setLoading] = useState(false)
  const activeReport = useLocation().pathname.split('/')[2]
  const reportData = useIcoFetch(`/api/UserReports/${activeReport}`, {
    tracking: [activeReport],
    onData: () => setLoading(false),
  })

  useEffect(() => {
    setLoading(true)
  }, [activeReport])

  useEffect(() => {
    //console.log(reportData);
  }, [reportData])

  if (loading)
    // (playbooksLoading)
    return (
      <MasterPageLayout
        history={history}
        match={match as any}
        location={location}
      >
        <DesktopLayout>
          <HPanelLayout>
            <HPanel fillWidth={true}>
              <CircularProgress className={cx(classes.spinnerPosition)} />
            </HPanel>
          </HPanelLayout>
        </DesktopLayout>
        <MobileLayout>
          <MobileViewLayout>
            <CircularProgress className={cx(classes.spinnerPosition)} />
          </MobileViewLayout>
        </MobileLayout>
      </MasterPageLayout>
    )

  const RenderActiveReport = () => {
    switch (activeReport) {
      case 'PushNotificationsReceived':
        return (
          <StretchBody>
            <h2 className="mt-4" style={{ textAlign: 'center' }}>
              Push Notifications Received
            </h2>
            <Spacer />
            {reportData &&
              reportData.data &&
              Array.isArray(reportData.data) &&
              reportData.data.map((pushNotification: PushNotification) => {
                return (
                  <UserReportMenuItem
                    itemTitle={pushNotification.PubTitle}
                    itemText={pushNotification.Content}
                    itemDate={pushNotification.SentAt}
                    key={pushNotification.SentMessageID}
                    dateAlign="float-right"
                    itemContent=""
                    incidentID={pushNotification.IncidentID}
                  />
                )
              })}
          </StretchBody>
        )
      case 'PollNotificationsReceived':
        return (
          <StretchBody>
            <h2 className="mt-4" style={{ textAlign: 'center' }}>
              Poll Notifications Received
            </h2>
            <Spacer />
            {reportData &&
              reportData.data &&
              Array.isArray(reportData.data) &&
              reportData.data.map((pushNotification: PushNotification) => {
                return (
                  <UserReportMenuItem
                    itemTitle={pushNotification.PubTitle}
                    itemText={pushNotification.Content}
                    itemDate={pushNotification.SentAt}
                    key={pushNotification.SentMessageID}
                    dateAlign="float-right"
                    itemContent=""
                    incidentID={pushNotification.IncidentID}
                  />
                )
              })}
          </StretchBody>
        )
      case 'ReportsFormsSent':
        return (
          <StretchBody>
            <h2 className="mt-4" style={{ textAlign: 'center' }}>
              Reports &amp; Forms Sent
            </h2>
            <Spacer />
            {reportData &&
              reportData.data &&
              Array.isArray(reportData.data) &&
              reportData.data.map((pushNotification: PushNotification) => {
                return (
                  <UserReportFormsMenuItem
                    itemTitle={pushNotification.PlanName}
                    itemText={pushNotification.EventName}
                    itemDate={pushNotification.CreatedGMT}
                    key={pushNotification.IncidentID}
                    dateAlign="float-left"
                    icon={{
                      IconDefinition: faPlusCircle,
                    }}
                    itemContent={pushNotification.ReportHTML}
                    itemContentAsHTML={true}
                    incidentID={pushNotification.IncidentID}
                  />
                )
              })}
            {/*<pre>{JSON.stringify(reportData.data, null, 2)}</pre>*/}
          </StretchBody>
        )
      default:
        return <></>
    }
  }

  return (
    <MasterPageLayout
      history={history}
      match={match as any}
      location={location}
    >
      <DesktopLayout>
        <HPanelLayout>
          <HPanel fillWidth={true}>
            <Container>
              <Col>
                <Row>{RenderActiveReport()}</Row>
              </Col>
            </Container>
          </HPanel>
        </HPanelLayout>
      </DesktopLayout>
      <MobileLayout>
        <MobileViewLayout>{RenderActiveReport()}</MobileViewLayout>
      </MobileLayout>
    </MasterPageLayout>
  )
}
