import {
  generateCrudActions,
  generateActions,
  ReducerAction,
} from '../_utils/reduxUtils'
import {
  CREATE_DIRECT_MESSAGE,
  FETCH_DIRECT_MESSAGES,
  CREATE_OWNER_DIRECT_MESSAGE,
  DELETE_DIRECT_MESSAGE,
  UPDATE_CHAT_READ,
  FETCH_CHAT_UNREAD_COUNT,
  INCREMENT_CHAT_UNREAD_COUNT,
  FETCH_LATEST_CHAT_DATES,
  SET_IS_VIEWING_CHAT,
} from './_constants'

export const ChatActions = generateCrudActions('CHAT')
export const ChatDetailActions = generateCrudActions('CHAT_DETAIL')
export const createDirectMessage = generateActions(CREATE_DIRECT_MESSAGE)
export const createOwnerDirectMessage = generateActions(
  CREATE_OWNER_DIRECT_MESSAGE
)
export const deleteDirectMessage = generateActions(DELETE_DIRECT_MESSAGE)
export const fetchDirectMessages = generateActions(FETCH_DIRECT_MESSAGES)

// Chat Unread
export const updateChatRead = generateActions(UPDATE_CHAT_READ)
export const fetchChatUnreadCount = generateActions(FETCH_CHAT_UNREAD_COUNT)
export const fetchLatestChatDates = generateActions(FETCH_LATEST_CHAT_DATES)
export function incrementChatUnreadCount(
  ChatID: number,
  lastActivityDate: Date
): ReducerAction {
  return {
    type: INCREMENT_CHAT_UNREAD_COUNT,
    payload: {
      result: {
        ChatID,
        lastActivityDate,
      },
      params: {
        ChatID,
      },
    },
  }
}

export function setIsViewingChat(
  ChatID: number,
  isViewing: boolean
): ReducerAction {
  return {
    type: SET_IS_VIEWING_CHAT,
    payload: {
      result: isViewing,
      params: {
        ChatID,
      },
    },
  }
}
