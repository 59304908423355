import React, { ReactElement } from 'react'
import { Slide } from '@material-ui/core'
import { TransitionProps } from '@material-ui/core/transitions'
export const SlideLeft = React.forwardRef(function Transition(
  props: TransitionProps & {
    children?: ReactElement
    className?: string
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="left" ref={ref} {...props} />
})
