import * as React from 'react'
import { Component, ReactNode } from 'react'
import { Button } from 'reactstrap'
//import iccLogo from './_images/ICC_RDS_Logo_New_Blue-SM.png';
import iccLogo from './_images/ICC_Lato.svg'
import classes from './AppDialog.module.scss'
import { cx } from './_utils/objectUtils'
import { Spacer } from './Shared/Spacer'

interface Props {
  showHeader?: boolean
  fixedHeader?: boolean
  header?: ReactNode
  headerInner?: ReactNode
  children?: ReactNode | ReactNode[]
  onCloseClicked: () => void // Don't make defaulted props optional or elese Typescript will think it can be possibly undefined
  hasPadding?: boolean
  id?: string
}

export class AppDialog extends Component<Props> {
  static defaultProps: Props = {
    showHeader: true,
    onCloseClicked: () => {},
  }

  createDomId(selector: string) {
    const { id } = this.props
    return id ? id + '-' + selector : undefined
  }

  render() {
    const {
      onCloseClicked,
      showHeader,
      headerInner,
      header,
      children,
      id,
      hasPadding = true,
      fixedHeader,
    } = this.props

    return (
      <div className={classes.root} id={id}>
        <div
          className={(classes.header, fixedHeader ? classes.fixedHeader : '')}
        >
          <Button
            id={this.createDomId('closeBtn')}
            color="standard"
            className={classes.close}
            aria-label="Close"
            onClick={onCloseClicked}
          >
            <span>×</span>
          </Button>

          <Spacer spaceParam={'large'} />
          {!header && (
            <h5
              className={cx(
                classes.title,
                headerInner ? classes.withHeaderInner : ''
              )}
            >
              {headerInner ? (
                headerInner
              ) : (
                <img
                  className={classes.iccLogo}
                  src={iccLogo}
                  alt="In Case of Crisis"
                />
              )}
            </h5>
          )}
          {showHeader ? header : undefined}
        </div>
        <div className={cx(classes.body, hasPadding ? classes.padding : '')}>
          {children}
        </div>
      </div>
    )
  }
}

interface SectionProps {
  children?: ReactNode | ReactNode[]
  header?: ReactNode
}

export const AppDialogSection = ({ children, header }: SectionProps) => (
  <div className={classes.section}>
    <h4>{header}</h4>
    <div className={classes.list}>{children}</div>
    <hr />
  </div>
)

export default AppDialog
