// @ts-nocheck
// TODO: Typescript
import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { FormContentDialog } from './FormContentDialog'
import { connect } from 'react-redux'
import { submitIssueForm } from './_actions'
import { selectCurrentChangeLevelContent } from './_selectors'
import {
  isChangeLevelFormContentFailure,
  isChangeLevelFormContentLoading,
} from '../Shared/_requestStatusSelectors'

interface Props {
  content: any
  isLoadingContent: boolean
  isFetchFormContentFailure: boolean
  submitForm: any
  open: any
  onClose: any
}

/**
 * @extends {Component<Props, {}>}}
 */
export class ChangeLevelFormContentDialog extends Component<Props> {
  render() {
    return (
      <Fragment>
        <FormContentDialog {...this.props} />
      </Fragment>
    )
  }
}

ChangeLevelFormContentDialog.propTypes = {
  content: PropTypes.any,
  isLoadingContent: PropTypes.bool,
  isFetchFormContentFailure: PropTypes.bool,
  /***
   * @description - The submit callback that passes back the form data
   ***/
  submitForm: PropTypes.func,
}

const mapStateToProps = (state) => ({
  content: selectCurrentChangeLevelContent(state),
  isLoadingContent: isChangeLevelFormContentLoading(state),
  isFetchFormContentFailure: isChangeLevelFormContentFailure(state),
})

const mapDispatchToProps = {
  submitForm: (formData) => submitIssueForm(formData, { submitType: 'update' }),
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChangeLevelFormContentDialog)
