// @ts-nocheck
// TODO: Typescript
import { getFetchListener } from '../_utils/sagaUtils'
import {
  IssueConstants,
  FETCH_ORG_ISSUE_LEVELS,
  FETCH_ORG_ISSUE_TYPES,
  FilterConstants,
  FilterSaveConstants,
  FETCH_ISSUE_ACTIVITY_LOG,
  ADD_ISSUE_ACTIVITY_LOG,
  FETCH_APP_USER_ORGS,
  FETCH_LATEST_ISSUE_ACTIVIY_DATES,
  FETCH_ISSUE_DETAILS,
} from './_constants'
import { takeLatest, call, put, select, takeEvery } from 'redux-saga/effects'
import {
  IssueActions,
  IssueLevelActions,
  fetchOrgIssueLevelsSuccess,
  fetchOrgIssueTypesSuccess,
  fetchAppUserInfoSuccess,
  issueFilterSuccess,
  issueFilterFailure,
  issueFilterSaveFailure,
  issueFilterSaveSuccess,
  issueFilterSaving,
  fetchIssueActivityLog,
  addIssueActivityLog,
  fetchAppUserOrgs,
  fetchLatestActivityLogDates,
  fetchIssueDetails,
} from './_actions'
import { normalize } from 'normalizr'
import {
  issueListSchema,
  transformEntities,
  schemaDateFields,
  issueActivityLogListSchema,
} from '../_schema/_schemas'
import { WorkspaceActions } from '../Workspace/_actions'
import { ChatActions } from '../Chat/_actions'
import {
  ParticipantActions,
  fetchParticipantsStatusSuccess,
  OrgIssueTeamActions,
} from '../Participant/_actions'
import { ConferenceActions } from '../Conference/_actions'
import { ArtifactActions } from '../Upload/_actions'
import { fetchHoldingStatements } from '../HoldingStatement/_actions'
import { TaskActions } from '../Task/_actions'
import { StakeholderActions } from '../Stakeholder/_actions'
import { initTeamReportsSuccess } from '../Form/_actions'
import { arrayToKeyValues, getCleanArray } from '../_utils/objectUtils'
import { icoPost } from '../_utils/fetchUtils'
import { selectIssueActivityLogIdMap } from './_selectors'
import {
  dateUTCbuilder,
  addMinutesToDate,
  objMapDatePropsToUTC,
} from '../_utils/dateUtils'
import {
  doesCurrentRouteRequireIssueData,
  selectUserEmail,
} from '../_rootConfigs/rootSelectors'
import { fetchAppAccounts } from '../AppAccount/_actions'

// The Saga that does the actual work
const initIssues = getFetchListener('/api/Issue', {
  onLoading: (result, params, source) =>
    IssueActions.initLoading(result, source),
  onFailure: (error, result, params, source) =>
    IssueActions.initFailure(error, result, source),
  onSuccess: (json) => issueFetchSuccessHelper(json, IssueActions.initSuccess),
})

const fetchIssuesHelper = getFetchListener('/api/Issue', {
  onLoading: (result, params, source) =>
    IssueActions.fetchLoading(result, source),
  onFailure: (error, result, params, source) =>
    IssueActions.fetchFailure(error, result, source),
  onSuccess: (json) => issueFetchSuccessHelper(json, IssueActions.fetchSuccess),
})

function* fetchIssues() {
  if (yield select(doesCurrentRouteRequireIssueData))
    yield call(fetchIssuesHelper)
}

function issueFetchSuccessHelper(json, issueSuccess) {
  json = json || {}
  json.Issues = getCleanArray(json.Issues)
  json.OrgName = getCleanArray(json.OrgName)
  json.UserInfo = getCleanArray(json.UserInfo) || []
  json.UserInfo = json.UserInfo[0]
  const { OrgName, UserInfo } = json
  const normalizedData = normalize(json.Issues, issueListSchema)
  const mappedEntities = (normalizedData || {}).entities || {}

  return [
    issueSuccess(
      transformEntities(
        mappedEntities.returnData || {},
        ...schemaDateFields.Issue
      )
    ),
    fetchAppUserInfoSuccess({ OrgName, UserInfo }),
    WorkspaceActions.initSuccess(
      transformEntities(mappedEntities.wksp || {}, ...schemaDateFields.wksp)
    ),
    ChatActions.initSuccess(
      transformEntities(
        mappedEntities.CollabChat || {},
        ...schemaDateFields.CollabChat
      )
    ),
    TaskActions.initSuccess(
      transformEntities(
        mappedEntities.CollabTask || {},
        ...schemaDateFields.CollabTask
      )
    ),
    ParticipantActions.initSuccess(
      mappedEntities.Participants || {},
      ...schemaDateFields.Participants
    ),
    IssueLevelActions.initSuccess(
      transformEntities(
        mappedEntities.IssueLevel || {},
        ...schemaDateFields.IssueLevel
      )
    ),
    fetchHoldingStatements(
      transformEntities(
        mappedEntities.HoldingStmt || {},
        ...schemaDateFields.HoldingStmt
      )
    ),
    ConferenceActions.initSuccess(
      transformEntities(
        mappedEntities.ConfSched || {},
        ...schemaDateFields.ConfSched
      )
    ),
    ArtifactActions.initSuccess(
      transformEntities(
        mappedEntities.Artifacts || {},
        ...schemaDateFields.Artifacts
      )
    ),
    StakeholderActions.initSuccess(
      transformEntities(
        mappedEntities.Stakeholders || {},
        ...schemaDateFields.Stakeholders
      )
    ),
    initTeamReportsSuccess(
      transformEntities(
        mappedEntities.TeamReports || {},
        ...schemaDateFields.TeamReports
      )
    ),
    fetchParticipantsStatusSuccess(json.ActiveParticipants),
    OrgIssueTeamActions.initSuccess(mappedEntities.OrgIssueTeams),
    fetchAppAccounts.success(mappedEntities.Responsible),
  ]
}

const fetchIssueActivityLogDates = getFetchListener(
  '/api/Issue/LatestActivityLogDates',
  {
    formatData: false,
    onLoading: fetchLatestActivityLogDates.loading,
    onFailure: fetchLatestActivityLogDates.failure,
    onSuccess: (json) => {
      let result = getCleanArray(json)

      if (result.length === 0) return fetchLatestActivityLogDates.success({})

      result = arrayToKeyValues(result, 'IssueID').keyValues
      objMapDatePropsToUTC(result, 'lastActivityDate', 'LastRead')
      return fetchLatestActivityLogDates.success(result)
    },
  }
)

const fetchOrgIssueLevels = getFetchListener('/api/Issue/OrgLevel', {
  onSuccess: (json) => fetchOrgIssueLevelsSuccess(json),
  appendPath: function (action) {
    return '?id=' + action.OrgId
  },
})

const fetchOrgIssueTypes = getFetchListener('/api/Issue/OrgType', {
  onSuccess: (json) => fetchOrgIssueTypesSuccess(json),
})

const issueFilterSave = function* (action) {
  try {
    const url = '/api/Issue/FilterSave'
    yield put(issueFilterSaving())
    const response = yield call(icoPost, url, action.data)
    const json = yield call([response, 'json']) || []
    if (json.errorMessage) {
      yield put(issueFilterSaveFailure(json.errorMessage))
    } else {
      var returnData = json.returnData || {}
      yield put(issueFilterSaveSuccess(returnData))
    }
  } catch (ex) {
    yield put(issueFilterFailure(ex.error))
  }
}

const fetchIssueFilters = getFetchListener('/api/Issue/IssueFilters', {
  onSuccess: (json) => issueFilterSuccess(json),
})

const fetchLog = getFetchListener('/api/Issue', {
  onLoading: fetchIssueActivityLog.loading,
  onFailure: fetchIssueActivityLog.failure,
  select: {
    activityLogIdMap: [selectIssueActivityLogIdMap],
  },
  onSuccess: (returnData, params, source, { activityLogIdMap }) => {
    const normalizedData = normalize(
      returnData || {},
      issueActivityLogListSchema
    )
    const mappedEntities = (normalizedData || {}).entities || {}
    const logItems = transformEntities(
      mappedEntities.IssueActivityLog || {},
      ...schemaDateFields.IssueActivityLog
    )
    // Remove duplicates
    const newIds = normalizedData.result.filter((id) =>
      !(activityLogIdMap || {})[id] ? true : false
    )

    return fetchIssueActivityLog.success(
      { ids: newIds, idMap: logItems },
      params,
      source
    )
  },
  appendPath: function (action) {
    const { pageSize, offset, IssueID, category } = action.payload.params
    let path = `${IssueID}/ActivityLog?pageSize=${pageSize}&offset=${offset}`

    if (category) path += '&category=' + category

    return path
  },
})

// Get the newest changes since the last requested date. This is useful for resyncing the activity log
// if the user is disconnnected
const fetchNewLogChanges = getFetchListener('/api/ActivityLog/NewChanges', {
  onLoading: fetchIssueActivityLog.loading,
  onFailure: fetchIssueActivityLog.failure,
  select: {
    activityLogIdMap: [selectIssueActivityLogIdMap],
    currentUser: [selectUserEmail],
  },
  onSuccess: (json, params, source, { activityLogIdMap, currentUser }) => {
    const { returnData } = json
    const normalizedData = normalize(
      returnData || {},
      issueActivityLogListSchema
    )
    const mappedEntities = (normalizedData || {}).entities || {}
    const logItems = transformEntities(
      mappedEntities.IssueActivityLog || {},
      ...schemaDateFields.IssueActivityLog
    )

    // Remove duplicates
    const newIds = normalizedData.result.filter((id) =>
      !(activityLogIdMap || {})[id] ? true : false
    )
    return addIssueActivityLog.success(
      { ids: newIds, idMap: logItems, currentUser },
      { lastRequested: dateUTCbuilder(json.RequestedUTC) }
    )
  },
  formatData: (json) => json,
  appendPath: function (action) {
    let { lastRequested } = action.payload.params
    if (!lastRequested) return ''

    lastRequested = addMinutesToDate(
      lastRequested,
      -lastRequested.getTimezoneOffset()
    )
    return `?lastRequested=${lastRequested.toISOString().replace('Z', '')}`
  },
})

const fetchUserOrgs = getFetchListener('/api/User/Orgs', {
  formatData: false,
  onLoading: fetchAppUserOrgs.loading,
  onFailure: fetchAppUserOrgs.failure,
  onSuccess: fetchAppUserOrgs.success,
})

const fetchIssueDetailsSaga = getFetchListener('/api/IssueTeamWorkspace', {
  formatData: false,
  onLoading: fetchIssueDetails.loading,
  onFailure: fetchIssueDetails.failure,
  onSuccess: fetchIssueDetails.success,
  appendPath: function (action) {
    return `/${action.payload.params.IssueTeamWorkspaceID}`
  },
})

// Watcher sagas
export const issueSagas = [
  takeLatest(IssueConstants.INIT.REQUEST, initIssues),
  takeLatest(IssueConstants.FETCH.REQUEST, fetchIssues),
  takeLatest(FETCH_ORG_ISSUE_LEVELS.REQUEST, fetchOrgIssueLevels),
  takeLatest(FETCH_ORG_ISSUE_TYPES.REQUEST, fetchOrgIssueTypes),
  takeLatest(FilterSaveConstants.REQUEST, issueFilterSave),
  takeLatest(FilterConstants.FETCH, fetchIssueFilters),
  // TODO (Obsolete?): I think this is handled on the server using a background servie now
  takeLatest(ADD_ISSUE_ACTIVITY_LOG.REQUEST, fetchNewLogChanges),
  takeEvery(FETCH_ISSUE_ACTIVITY_LOG.REQUEST, fetchLog),
  takeLatest(FETCH_APP_USER_ORGS.REQUEST, fetchUserOrgs),
  takeLatest(
    FETCH_LATEST_ISSUE_ACTIVIY_DATES.REQUEST,
    fetchIssueActivityLogDates
  ),
  takeLatest(FETCH_ISSUE_DETAILS.REQUEST, fetchIssueDetailsSaga),
]
