import React, {
  Component,
  ReactNode,
  ReactNodeArray,
  SyntheticEvent,
} from 'react'
import {
  withMobileDialog,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@material-ui/core'
import { Button } from 'reactstrap'
import { SlideLeft } from './Transition'

const ResponsiveDialog = withMobileDialog()(Dialog)

interface Props {
  title: string
  confirmText?: string
  cancelText?: string
  content?: ReactNode | ReactNodeArray
  open: boolean
  removeContentOnClose?: boolean
  confirm: (mouseEvent?: SyntheticEvent<HTMLButtonElement>) => void
  cancel: (mouseEvent?: SyntheticEvent<HTMLButtonElement>) => void
  leftActions?: ReactNode | ReactNodeArray
  rightActions?: ReactNode | ReactNodeArray
  autoCloseConfirm?: boolean
  className?: string
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl'
}

/**
 * @extends {Component<Props, {}>}}
 * Reusable confirm dialog
 */
export class ConfirmDialog extends Component<Props> {
  constructor(props: Props) {
    super(props)
    this.handleConfirm = this.handleConfirm.bind(this)
  }

  static defaultProps = {
    title: '',
    confirmText: 'Yes',
    cancelText: 'No',
    autoCloseConfirm: true,
    content: '',
    open: false,
    confirm: function () {},
    cancel: function () {},
    removeContentOnClose: false,
  }

  handleConfirm(mouseEvent: SyntheticEvent<HTMLButtonElement>) {
    const { confirm, cancel } = this.props
    confirm(mouseEvent)
    if (this.props.autoCloseConfirm) cancel(mouseEvent)
  }

  render() {
    const {
      open,
      cancel,
      title,
      content,
      cancelText,
      confirmText,
      leftActions,
      rightActions,
      className,
      maxWidth,
    } = this.props
    return (
      <ResponsiveDialog
        open={open}
        onClose={cancel as any}
        TransitionComponent={SlideLeft as any}
        className={className}
        maxWidth={maxWidth}
      >
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>{content}</DialogContent>
        <DialogActions>
          {leftActions}
          <Button autoFocus onClick={cancel} color="primary">
            {cancelText}
          </Button>
          <Button onClick={this.handleConfirm} color="primary">
            {confirmText}
          </Button>
          {rightActions}
        </DialogActions>
      </ResponsiveDialog>
    )
  }
}
