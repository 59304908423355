import React, { useEffect, useState, useCallback } from 'react'
import { InputGroup } from 'reactstrap'
import { Button } from '../Shared/Buttons'
import CheckBox from '../CustomMaterial/Checkbox'
import {
  FormLabel,
  Select,
  ListItem,
  ListItemText,
  MenuItem,
} from '@material-ui/core'
import classes from './Alerts.module.scss'
import { cx } from '../_utils/objectUtils'
import { http } from '../_utils/http'
import IconChooser from './IconChooser'
import { AMSetting } from './AMSetting'
import { faClone } from '@fortawesome/free-solid-svg-icons'
import { useAppDispatch, useAppSelector } from '../_utils/reactReduxHooks'
import { selectInboxByGUID } from './_selectors'
import { addInbox, deleteInbox, updateInbox } from './_actions'
import { ResponsiveDialog } from '../Shared/ResponsiveDialog'
import { SlideLeft } from '../Shared/Transition'
import { DialogContent, Toolbar } from '@material-ui/core'
import { HeaderNavigation } from '../Shared/HeaderNavigation'
import { addNotification } from '../_actions'
import { Spacer } from '../Shared/Spacer'
import { useHistory } from 'react-router'
import { InboxFeedModel } from './_models'
import { LoadingBackDrop } from '../Shared/LoadingBackdrop'
import { AdminConsoleDialog } from '../Shared/AdminConsoleDialog'
import { Tooltip } from '../Shared/Tooltip'
import { TextField } from '../Shared/TextField'

interface Props {
  inboxGUID: string
  onClose?: () => void
  isDialogOpen: boolean
}
interface LogoInfo {
  id: number
  filename: string
  graphic: string
}
interface UpdateInbox {
  inboxID: number
  inboxGUID: string
  inboxName: string
  inboxType: string
  process_type: string
  IsActiveYN: string
  teamID?: number
  alrt_filter?: string
  logoFileID?: number
  feeds: Array<InboxFeedModel>
  OrgID?: number
  IncidentReps?: Array<number>
  defaultSeverity?: 'Low' | 'Medium' | 'High'
  allowed_Senders?: string
}
const defaultInbox: UpdateInbox = {
  inboxGUID: '0',
  inboxName: '',
  inboxID: 0,
  inboxType: 'RSS',
  OrgID: 0,
  process_type: 'I',
  teamID: 0,
  IsActiveYN: 'Y',
  logoFileID: 45,
  IncidentReps: [],
  alrt_filter: '',
  feeds: [],
  defaultSeverity: undefined,
  allowed_Senders: '',
}
interface Incident {
  EventID: number
  IncidentReportPathName: string
  assignedYN: 'N' | 'Y'
  seq: number
}
interface InboxTypesLookup {
  inboxType: 'RSS' | 'EMAIL' | 'IR' | 'ZAP' | 'AM' | 'TM'
  inboxName: string
  tooltip: string
}
const inboxTypesLookup: Array<InboxTypesLookup> = [
  {
    inboxType: 'TM',
    inboxName: 'Online Threat Monitoring',
    tooltip: `Select this Inbox to use the In Case of Crisis Threat
Monitoring API.`,
  },
  {
    inboxType: 'RSS',
    inboxName: 'RSS Feed',
    tooltip: `Select this Inbox if you already have a URL from which you
would like to receive alerts.`,
  },
  {
    inboxType: 'IR',
    inboxName: 'Incident Report from a Playbook',
    tooltip: `Select this Inbox to receive alerts or create an issue each
time a specific incident report is received. You may select
your incident report after the Inbox is created.`,
  },
  {
    inboxType: 'ZAP',
    inboxName: 'ICC Zapier',
    tooltip: `Select this Inbox to use the In Case of Crisis Zapier API.`,
  },
  {
    inboxType: 'EMAIL',
    inboxName: 'Email',
    tooltip: `Select this Inbox to use an email address from which you would
like to receive alerts.`,
  },
  {
    inboxType: 'AM',
    inboxName: 'Alert Media',
    tooltip: `Select this Inbox to use the AlertMedia API.`,
  },
]

export const ManageInbox = ({ inboxGUID, onClose, isDialogOpen }: Props) => {
  const [typeItems, setTypeItems] = useState<
    Array<{ Process_Type: string; Process_Descr: string }>
  >([{ Process_Type: 'I', Process_Descr: 'Add to Inbox' }])
  const [myTeams, setMyTeams] = useState<any[]>([])
  const [myIncidents, setMyIncidents] = useState<Incident[]>([])
  const inboxInStore = useAppSelector((state) =>
    selectInboxByGUID(state, inboxGUID)
  )
  const [selectedInbox, setSelectedInbox] = useState<UpdateInbox>({
    ...defaultInbox,
    inboxGUID: inboxGUID,
  })
  const history = useHistory()
  const [isIconChooserOpen, setIsIconChooserOpen] = useState(false)
  const [isDeleteConfirmOpen, setIsDeleteConfirmOpen] = useState(false)
  const [icons, setIcons] = useState<Array<LogoInfo>>([])
  const [selectedlogo, setselectedlogo] = useState<LogoInfo>()
  const dispatch = useAppDispatch()
  const [automaticProcessToggled, setAutomaticProcessToggled] = useState(false)
  const [schedule, setSchedule] = useState('')
  const [isOpenAMPopup, setisOpenAMPopup] = useState(false)
  const [help, setHelp] = useState(false)
  const [dataLoading, setDataLoading] = useState(false)

  const getData4Edit = useCallback(async (): Promise<any> => {
    setDataLoading(true)
    var customHeaders: any = []
    customHeaders.push({ name: 'getaction', value: `data4Edit` })
    customHeaders.push({ name: 'inboxid', value: selectedInbox.inboxID })
    const result = await http<any>({
      path: '/alert',
      headers: customHeaders,
    })
    setDataLoading(false)
    if (result.ok && result.body) {
      return result.body
    } else {
      return ''
    }
  }, [selectedInbox.inboxID])

  const setSearchSchedule = useCallback(() => {
    if (selectedInbox.feeds[0] != null) {
      setSchedule(
        selectedInbox.feeds[0].searchSchedule
          ? selectedInbox.feeds[0].searchSchedule
          : ''
      )
    }
  }, [selectedInbox.feeds])

  useEffect(() => {
    if (inboxInStore && inboxInStore.inboxID) {
      let inboxUpdate = {
        inboxID: inboxInStore.inboxID,
        inboxGUID: inboxInStore.inboxGUID,
        inboxName: inboxInStore.inboxName,
        inboxType: inboxInStore.inboxType,
        process_type: inboxInStore.process_type,
        IsActiveYN: inboxInStore.IsActiveYN,
        teamID: inboxInStore.teamID,
        alrt_filter: inboxInStore.alrt_filter,
        logoFileID: inboxInStore.logoFileID,
        feeds: inboxInStore.feeds,
        OrgID: inboxInStore.OrgID,
        IncidentReps: inboxInStore.IncidentReps,
        defaultSeverity: inboxInStore.defaultSeverity,
        allowed_Senders: inboxInStore.allowed_Senders,
      }

      setSelectedInbox((prev) => ({ ...prev, ...inboxUpdate }))

      // 2023.07.13 NM: Restore the ability to automatically create Issues from new Alerts in IR, Zapier, and Email Alert inboxes
      if (inboxInStore.feedsTotal && inboxInStore.feedsTotal > 0) {
        let processShouldToggle =
          inboxInStore.feeds &&
          inboxInStore.feeds[0] &&
          inboxInStore.feeds[0].searchScheduleTime === '00:15:00'
            ? true
            : false

        setAutomaticProcessToggled(processShouldToggle)
      } else setAutomaticProcessToggled(true)
    }
  }, [inboxInStore])

  useEffect(() => {
    let cancelled = false

    const doGetData4Edit = async () => {
      const data4Edit = await getData4Edit()
      if (!cancelled) {
        // set action types
        setTypeItems(data4Edit.process_types)

        // set action teams
        const myTeams = data4Edit.teams

        setMyTeams(myTeams)

        // set incidents
        const myIncidents: Incident[] = data4Edit.incidents
        setMyIncidents(myIncidents || [])
        if (inboxGUID !== '0') {
          let selectedIncidents = myIncidents.filter(
            (incident) => incident.assignedYN === 'Y'
          )
          let selectedIncidentIDs = selectedIncidents.map(
            (incident) => incident.EventID
          )
          setSelectedInbox((prev) => ({
            ...prev,
            IncidentReps: selectedIncidentIDs,
          }))
        }
        // set inbox icons
        setIcons(
          data4Edit.inbox_icons
            ? data4Edit.inbox_icons
            : [{ id: 0, filename: '', graphic: '' }]
        )
      }
    }

    doGetData4Edit()

    setSearchSchedule()

    return () => {
      cancelled = true
    }
  }, [getData4Edit, setSearchSchedule, inboxGUID])

  const getRSSFeedValue = useCallback(() => {
    let RSSFeedValue =
      selectedInbox.feeds && selectedInbox.feeds[0]
        ? selectedInbox.feeds[0].feedURL
        : ''

    return RSSFeedValue
  }, [selectedInbox.feeds])

  const inboxEmailAddress = useCallback(() => {
    var emailPrefix = 'alert'
    if (
      window.location.hostname === 'localhost' ||
      window.location.hostname === 'icoissue-dev.incaseofcrisis.com'
    ) {
      emailPrefix += 'dev'
    }
    if (window.location.hostname === 'icoissue-stage.incaseofcrisis.com') {
      emailPrefix += 'stage'
    }
    return inboxGUID.length > 10
      ? emailPrefix +
          '+' +
          inboxGUID.replaceAll('-', '_') +
          '@incaseofcrisis.com'
      : ''
  }, [inboxGUID])

  const copyEmailAddress = useCallback(() => {
    let emailAddress = inboxEmailAddress()
    navigator.clipboard.writeText(emailAddress)
    dispatch(
      addNotification({
        type: 'info',
        message: 'Email Copied.',
        duration: 1000,
        hasCloseBtn: false,
      })
    )
  }, [inboxEmailAddress, dispatch])

  const handleSelectChange = useCallback((event: any) => {
    let { name, value } = event.target
    let newChanges: {
      process_type?: string
      teamID?: number
      IncidentReps?: number[]
      defaultSeverity?: 'Low' | 'Medium' | 'High'
    } = {}
    if (name === 'TYPE') {
      newChanges.process_type = value
    }

    if (name === 'TEAM') {
      newChanges.teamID = value
    }

    if (name === 'Severity') {
      newChanges.defaultSeverity = value === 'None' ? undefined : value
    }

    if (name === 'IR') {
      newChanges.IncidentReps = value
    }

    setSelectedInbox((prev) => ({ ...prev, ...newChanges }))
  }, [])

  const renderIRValue = useCallback(() => {
    let selectedIRnames = myIncidents
      .filter((item: any) => selectedInbox.IncidentReps?.includes(item.EventID))
      .map((item: any) => item.IncidentReportPathName)
      .join(', ')
    return selectedIRnames
  }, [myIncidents, selectedInbox.IncidentReps])

  const handleIsActiveChange = useCallback(() => {
    setSelectedInbox((prev) => ({
      ...prev,
      IsActiveYN: prev.IsActiveYN === 'Y' ? 'N' : 'Y',
    }))
  }, [])

  const handleIsAutoCheckChange = useCallback(
    (event: any) => {
      if (
        selectedInbox &&
        (selectedInbox.inboxType === 'IR' ||
          selectedInbox.inboxType === 'ZAP' ||
          selectedInbox.inboxType === 'EMAIL')
      ) {
        return
      }

      setAutomaticProcessToggled((prev) => !prev)
      setSelectedInbox((prev) => ({ ...prev, process_type: 'I' }))
    },
    [selectedInbox]
  )

  const handleTextFieldChange = useCallback(
    (event: any) => {
      let { name, value } = event.target
      let selectedInboxUpdate: UpdateInbox = { ...selectedInbox }
      if (name === 'editName') selectedInboxUpdate.inboxName = value
      if (name === 'editFilter') selectedInboxUpdate.alrt_filter = value
      if (name === 'editSenders') selectedInboxUpdate.allowed_Senders = value
      if (name === 'rssFeed_0') {
        selectedInboxUpdate.feeds = [
          {
            OrgID: selectedInbox.OrgID,
            feedGUID: '',
            feedName: selectedInbox.inboxName,
            feedURL: value,
            feedLogo: '',
            inboxID: 0,
          },
        ]
      }     
      setSelectedInbox(selectedInboxUpdate)
    },
    [selectedInbox]
  )

  const toggleIconChooserPopup = useCallback(() => {
    setIsIconChooserOpen((prev) => !prev)
  }, [])

  const toggleAMPopup = useCallback(() => {
    setisOpenAMPopup((prev) => !prev)
  }, [])

  const toggleConfirm = useCallback(() => {
    setIsDeleteConfirmOpen((prev) => !prev)
  }, [])

  const handleAMSetting = useCallback(
    (token: string, url: string) => {
      toggleAMPopup()
      setSelectedInbox((prev) => {
        if (!prev.feeds || prev.feeds[0] === undefined) {
          return {
            ...prev,
            feeds: [
              {
                OrgID: selectedInbox.OrgID,
                feedGUID: '',
                feedURL: url,
                feedToken: token,
                feedName: 'AlertMedia',
                inboxID: selectedInbox.inboxID,
                feedLogo: '',
              },
            ],
          }
        } else {
          return {
            ...prev,
            feeds: [
              {
                ...prev.feeds[0],
                OrgID: selectedInbox.OrgID,
                feedURL: url,
                feedToken: token,
                feedName: 'AlertMedia',
                inboxID: selectedInbox.inboxID,
              },
            ],
          }
        }
      })
    },
    [selectedInbox.OrgID, selectedInbox.inboxID, toggleAMPopup]
  )

  const handleIconSelected = useCallback(
    (event: any) => {
      let { id, src, alt } = event.target
      setselectedlogo({
        id: id,
        graphic: src.replace('data:image/png;base64,', ''),
        filename: alt,
      })
      setSelectedInbox((prev) => ({ ...prev, logoFileID: parseInt(id) }))
      toggleIconChooserPopup()
      return false
    },
    [toggleIconChooserPopup]
  )

  const renderLogo = useCallback(() => {
    if (!selectedlogo && selectedInbox.logoFileID && icons.length > 0) {
      let foundSelectedLogo = icons.find(
        (m: any) => m.id === selectedInbox.logoFileID
      )
      if (foundSelectedLogo) setselectedlogo(foundSelectedLogo)
    }

    var feedLogo =
      selectedInbox.feeds && selectedInbox.feeds[0]
        ? selectedInbox.feeds[0].feedLogo
        : ''
    return feedLogo || selectedlogo ? (
      <>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <img
          id="inboxLogo"
          className={classes.feedLogo}
          src={
            selectedlogo
              ? 'data:image/png;base64,' + selectedlogo.graphic
              : feedLogo
          }
          alt={selectedlogo ? selectedlogo.filename : 'Inbox logo'}
        />{' '}
        <br />
      </>
    ) : (
      ''
    )
  }, [selectedInbox.feeds, icons, selectedInbox.logoFileID, selectedlogo])

  const onSubmit = useCallback(
    (event: any) => {
      let { id } = event.target
      let selectedInboxUpdate = { ...selectedInbox }
      let verifiedScheduleTime = automaticProcessToggled
        ? '00:15:00'
        : '00:00:00'
      if (
        !selectedInbox.feeds ||
        selectedInbox.feeds.length === 0 ||
        !selectedInbox.feeds[0]
      ) {
        selectedInboxUpdate.feeds = [
          {
            OrgID: selectedInbox.OrgID,
            feedGUID: '',
            feedName: selectedInbox.inboxName,
            feedURL: '',
            feedLogo: '',
            inboxID: 0,
            searchScheduleTime: verifiedScheduleTime,
            searchSchedule: schedule,
          },
        ]
      } else {
        selectedInboxUpdate.feeds[0].searchScheduleTime = verifiedScheduleTime
        selectedInboxUpdate.feeds[0].searchSchedule = schedule
      }
     
      setSelectedInbox(selectedInboxUpdate)

      let isReady = true

      if (id === 'inbox_delete') {
        toggleConfirm()
        isReady = false
      }

      if (selectedInbox.inboxName === '') {
        isReady = false
        dispatch(
          addNotification({
            message: 'Inbox  Name is required.',
            type: 'warning',
          })
        )
      }

      if (!selectedInbox.teamID) {
        isReady = false
        dispatch(
          addNotification({ message: 'Team is required.', type: 'warning' })
        )
      }

      if (isReady) {
        if (!selectedInbox.inboxID)
          dispatch(
            addInbox.request({
              ...selectedInbox,
              logoID: selectedInbox.logoFileID,
            })
          )
        else
          dispatch(
            updateInbox.request({
              ...selectedInbox,
              logoID: selectedInbox.logoFileID,
            })
          )

        onClose?.call(undefined)
      }
    },
    [
      dispatch,
      onClose,
      schedule,
      automaticProcessToggled,
      selectedInbox,
      toggleConfirm,
    ]
  )

  const handleDeleteInboxConfirmation = useCallback(() => {
    dispatch(
      deleteInbox.request({
        ...selectedInbox,
        logoID: selectedInbox.logoFileID,
      })
    )
    history.push('/Alert/Inbox/AllAlerts')
    onClose?.call(undefined)
  }, [dispatch, onClose, selectedInbox, history])

  const getIconLogo4InboxType = useCallback(
    (inboxType: any) => {
      let logoID: number | undefined = 0
      if (inboxType === 'TM' && icons)
        logoID = icons.find(
          (icon) => icon.filename === 'ICC Threat Monitoring (Solid Symbol).png'
        )?.id
      //ICC Threat Monitoring (Solid Symbol).png
      else if (inboxType === 'IR' && icons)
        logoID = icons.find(
          (icon: any) => icon.filename === 'Incident Report (Wide).png'
        )?.id
      //Incident Report (Wide).png
      else if (inboxType === 'ZAP' && icons)
        logoID = icons.find(
          (icon: any) => icon.filename === 'Zapier (Wide).png'
        )?.id
      //Zapier (Wide).png
      else if (inboxType === 'RSS' && icons)
        logoID = icons.find(
          (icon: any) => icon.filename === 'RSS (Wide).png'
        )?.id
      //RSS (Wide).png
      else if (inboxType === 'EMAIL' && icons)
        logoID = icons.find(
          (icon: any) => icon.filename === 'Email (Wide).png'
        )?.id
      //Email (Wide).png
      else if (inboxType === 'AM' && icons)
        logoID = icons.find(
          (icon: any) => icon.filename === 'AlertMedia (Wide).png'
        )?.id //AlertMedia (Wide).png

      return icons.find((icon: any) => icon.id === logoID)
    },
    [icons]
  )

  const handleInboxTypeChange = useCallback(
    (evt: any) => {
      var iType = evt.target && evt.target.value
      var logoIcon = getIconLogo4InboxType(iType)
      if (logoIcon) setselectedlogo(logoIcon)

      setSelectedInbox((prev) => ({
        ...prev,
        logoFileID: logoIcon?.id ? logoIcon.id : 0,
        inboxType: iType,
      }))

      if (
        !automaticProcessToggled &&
        (iType === 'EMAIL' || iType === 'ZAP' || iType === 'IR')
      ) {
        setAutomaticProcessToggled((prev) => !prev)
        setSelectedInbox((prev) => ({ ...prev, process_type: 'I' }))
      }

      return false
    },
    [getIconLogo4InboxType, automaticProcessToggled]
  )

  const openInNewTab = useCallback((url: any) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
  }, [])

  const createTX360Clicked = useCallback(() => {
    //evt.preventDefault();
    var hostname = window.location.hostname
    //alert("... under construction. " + hostname);

    let qStr =
      selectedInbox.feeds.length > 0
        ? '?guid=' + selectedInbox.feeds[0].feedGUID
        : ''
    var popup = window.open(
      '/TX360/getChannels' + qStr,
      'name',
      'height=700,width=600'
    )
    popup?.focus()

    window.addEventListener(
      'message',
      (event) => {
        if (event.origin.indexOf(hostname) > -1) {
          if (typeof event.data === 'string') {
            var obj = JSON.parse(event.data)
            //@ts-ignore
            document.getElementById('feedName').innerHTML = obj.feedName
            let updatedInbox = selectedInbox
            if (selectedInbox.feeds[0] === undefined) {
              updatedInbox.feeds = []
              updatedInbox.feeds.push({
                OrgID: selectedInbox.OrgID,
                feedGUID: '',
                feedURL: obj.feedURL,
                feedToken: obj.feedToken,
                feedName: obj.feedName,
                inboxID: selectedInbox.inboxID,
                feedLogo: '',
              })
            } else {
              updatedInbox.feeds[0].feedURL = obj.feedURL
              updatedInbox.feeds[0].feedToken = obj.feedToken
              updatedInbox.feeds[0].feedName = obj.feedName
            }

            setSelectedInbox(updatedInbox)
            popup?.close()
            window.removeEventListener('message', (event) => {}, true)
          }
        }
      },
      true
    )
  }, [selectedInbox])

  const renderInboxConfigurationDetails = useCallback(() => {
    if (selectedInbox.inboxType === 'TM') {
      /*Threat Monitoring type*/
      return (
        <div>
          <FormLabel className={classes.formLabel}>
            Smart Alerts Name:
          </FormLabel>
          <FormLabel
            id="feedName"
            style={{
              color: 'black',
              fontWeight: 900,
              marginBottom: '0px',
              marginLeft: '0px',
            }}
          >
            {selectedInbox.feeds && selectedInbox.feeds[0]
              ? selectedInbox.feeds[0].feedName
              : ' Not Configured'}
          </FormLabel>
          <Spacer />
          <Button color="primary" onClick={createTX360Clicked}>
            Threat Monitoring
          </Button>
        </div>
      )
    } else if (selectedInbox.inboxType === 'IR') {
      /*Incident Report type*/
      return (
        <>
          <div
            className={classes.myDropDownContainer}
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <FormLabel
              className={classes.formLabel}
              style={{ whiteSpace: 'nowrap' }}
            >
              Select Incident Report(s)
            </FormLabel>
            <Spacer />
            {help && (
              <Tooltip
                placement="right"
                title={`After this inbox is created, you must edit it to choose your
                  incident report. Once you have selected your report, it will
                  display here.`}
              />
            )}
          </div>
          {myIncidents.length > 0 ? (
            <Select
              name="IR"
              onChange={handleSelectChange}
              multiple={true}
              renderValue={renderIRValue}
              value={selectedInbox.IncidentReps || []}
              style={{ width: '100%', minWidth: '260px' }}
            >
              {myIncidents?.map((item: any) => {
                return (
                  <ListItem key={item.EventID} value={item.EventID}>
                    <ListItemText primary={item.IncidentReportPathName} />
                  </ListItem>
                )
              })}
            </Select>
          ) : (
            <FormLabel
              style={{
                fontWeight: 900,
                marginBottom: '0px',
                marginLeft: '0px',
                display: 'flex',
                alignItems: 'center',
                fontSize: '75%',
                fontStyle: 'italic',
              }}
            >
              {inboxGUID === '0'
                ? 'After this Inbox is created, you must edit it to choose your incident report.'
                : 'There are no incident reports currently configured for this Inbox. '}
            </FormLabel>
          )}
        </>
      )
    } else if (selectedInbox.inboxType === 'RSS') {
      return (
        <>
          {/*RSS Feed type*/}
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <FormLabel className={classes.formLabel}>
                URL to RSS Feed:
              </FormLabel>
              <Spacer />
              {help && (
                <Tooltip
                  placement="top"
                  title={`Enter the URL for the Inbox's source that will generate your alerts. The
                  CAP (Common Alert Protocol) can be included in feed.`}
                />
              )}
            </div>

            <TextField
              id="rssFeed_0"
              name="rssFeed_0"
              defaultValue={getRSSFeedValue()}
              placeholder="https://..."
              onChange={handleTextFieldChange}
              fullWidth
            />
            {help && (
              <>
                <FormLabel
                  style={{
                    fontWeight: 900,
                    height: 5,
                    marginLeft: '-2px',
                    fontSize: '75%',
                    fontStyle: 'italic',
                  }}
                >
                  Supports Atom or RSS 2.0 formatted feed, including CAP
                  embedded in feed.
                </FormLabel>

                <Button
                  color="link"
                  onClick={() => openInNewTab('https://validator.w3.org/feed/')}
                >
                  {' '}
                  Feed Validation Service
                </Button>
              </>
            )}
          </div>
        </>
      )
    } else if (selectedInbox.inboxType === 'AM') {
      return (
        <Button color="primary" onClick={toggleAMPopup}>
          AlertMedia Settings
        </Button>
      )
    } else if (selectedInbox.inboxType === 'EMAIL') {
      return (
        <div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                <FormLabel className={classes.formLabel}>
                  Inbox Email Address:
                </FormLabel>
                <Spacer />
                {help && (
                  <Tooltip
                    placement="top"
                    title={`Use this email address to send alert.`}
                  />
                )}
              </div>
              <Spacer />
              <FormLabel className={classes.formLabel}>
                <span onClick={() => copyEmailAddress()}>
                  <Tooltip
                    placement="top"
                    title={`Copy Email.`}
                    icon={faClone}
                    style={{ cursor: 'pointer' }}
                  />
                </span>
                &nbsp;
                {inboxEmailAddress() || ''}
              </FormLabel>

          <Spacer spaceParam={'large'} />
        
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                }}
            >
                <FormLabel className={classes.formLabel}>Allowed Email Senders:</FormLabel>
                {help && (
                    <>
                        <Spacer />
                        <Tooltip
                            placement="top"
                              title={`Optionally, to help ensure emails are received from expected senders, 
                              add one or more valid sender email addresses in a comma separated list;
                              you can also list an entire email domain as follows: *@domain.com`}
                        />
                    </>
                )}
            </div>
            <Spacer spaceParam={'small'} />

            <TextField
                id="editSenders"
                name="editSenders"
                defaultValue={selectedInbox.allowed_Senders}
                multiline
                minRows={2}
                onChange={handleTextFieldChange}
                fullWidth
            />
            <FormLabel
                className={classes.formLabel}
                style={{ fontSize: '10px', fontStyle: 'italic', marginTop: '2px' }}
            >
                Comma separated allowed email senders.
            </FormLabel>
              
        </div>
      )
    } else {
      return ''
    }
  }, [
    createTX360Clicked,
    selectedInbox.feeds,
    getRSSFeedValue,
    handleTextFieldChange,
    inboxGUID,
    myIncidents,
    handleSelectChange,
    openInNewTab,
    renderIRValue,
    selectedInbox.inboxType,
    selectedInbox.IncidentReps,
    toggleAMPopup,
    help,
    copyEmailAddress,
    inboxEmailAddress,
    selectedInbox.allowed_Senders
  ])

  const renderDeleteButton = useCallback(() => {
    if (inboxGUID === '0') {
      return
    } else {
      return (
        <Button
          className={classes.danger}
          id="inbox_delete"
          onClick={onSubmit}
          style={{
            backgroundColor: `rgb(188, 32, 46)`,
            color: 'white',
            textTransform: `none`,
            borderRadius: 'none',
          }}
        >
          Delete
        </Button>
      )
    }
  }, [onSubmit, inboxGUID])

  const renderContent = useCallback(
    () => (
      <>
        <div style={{ minWidth: '500px' }}>
          <FormLabel className={classes.formLabel}>
            Setup Inbox from the following:
          </FormLabel>
          <Select
            name="inboxTypes"
            value={selectedInbox.inboxType || ''}
            onChange={handleInboxTypeChange}
            style={{ width: '100%' }}
            renderValue={() =>
              inboxTypesLookup.find(
                (inbox) => inbox.inboxType === selectedInbox.inboxType
              )?.inboxName
            }
          >
            {inboxTypesLookup.map((inboxType) => {
              return (
                <MenuItem id={inboxType.inboxType} value={inboxType.inboxType}>
                  <div style={{ display: 'flex', flexDirection: 'row' }}>
                    {inboxType.inboxName}
                    {help && (
                      <>
                        <Spacer />
                        <Tooltip placement="top" title={inboxType.tooltip} />
                      </>
                    )}
                  </div>
                </MenuItem>
              )
            })}
          </Select>
        </div>
        <Spacer spaceParam={'large'} />
        {renderInboxConfigurationDetails()}
        <hr />
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <FormLabel
            className={classes.formLabel}
            style={{ whiteSpace: 'nowrap' }}
          >
            Inbox Name*:
          </FormLabel>
          {help && (
            <>
              <Spacer />
              <Tooltip
                placement="top"
                title={`Provide a detailed name for your Inbox. If the Inbox is specific to a
                  type of scenario, department, location, or keyword, include that in
                  the name.`}
              />
            </>
          )}
        </div>
        <TextField
          required
          id="editName"
          name="editName"
          defaultValue={selectedInbox.inboxName}
          placeholder="Name"
          onChange={handleTextFieldChange}
          fullWidth
        />
        <Spacer spaceParam={'large'} />
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <FormLabel className={classes.formLabel}>Team*:</FormLabel>
          {help && (
            <>
              <Spacer />
              <Tooltip
                placement="top"
                title={`Select an Issues Management Team of which you are a member and have
                  the Administrator Privilege. This will be the team responsible for
                  monitoring these alerts.`}
              />
            </>
          )}
        </div>
        {
          <Select
            onChange={handleSelectChange}
            multiple={false}
            value={selectedInbox.teamID || ''}
            name="TEAM"
            required
            style={{ width: '100%' }}
          >
            {myTeams
              .filter((team) => team.IsAdminYN === 'Y')
              .map((team) => (
                <MenuItem key={team.teamID} value={team.teamID}>
                  {team.TeamOrg}
                </MenuItem>
              ))}
          </Select>
        }
        <Spacer spaceParam={'large'} />
        {(selectedInbox.inboxType === 'IR' ||
          selectedInbox.inboxType === 'EMAIL') && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-start',
            }}
          >
            <FormLabel
              className={classes.formLabel}
              style={{ whiteSpace: 'nowrap' }}
            >
              Default Severity:
            </FormLabel>
            {help && (
              <>
                <Spacer />
                <Tooltip
                  placement="top"
                  title={`Select a default Severity for your alerts.`}
                />
              </>
            )}
            <Spacer />
            <Select
              onChange={handleSelectChange}
              multiple={false}
              value={selectedInbox.defaultSeverity || 'None'}
              name="Severity"
              required
            >
              <MenuItem value={'None'}>None</MenuItem>
              <MenuItem value={'Low'}>Low</MenuItem>
              <MenuItem value={'Medium'}>Medium</MenuItem>
              <MenuItem value={'High'}>High</MenuItem>
            </Select>
          </div>
        )}
        <Spacer spaceParam={'large'} />
        <div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <FormLabel className={classes.formLabel}>Filter:</FormLabel>
            {help && (
              <>
                <Spacer />
                <Tooltip
                  placement="top"
                  title={`Many alerts may be generated from a designated feed. Use terms
                  specific to your inbox or company to filter alerts. Separate terms
                  with a comma.`}
                />
              </>
            )}
          </div>
          <Spacer spaceParam={'small'} />

          <TextField
            id="editFilter"
            name="editFilter"
            defaultValue={selectedInbox.alrt_filter}
            multiline
            minRows={2}
            onChange={handleTextFieldChange}
            fullWidth
          />
          <FormLabel
            className={classes.formLabel}
            style={{ fontSize: '10px', fontStyle: 'italic', marginTop: '2px' }}
          >
            Comma separated words and phrases.
          </FormLabel>
        </div>
        <Spacer spaceParam={'large'} />
        <div>
          <Button onClick={toggleIconChooserPopup} color="primary">
            Choose Icon
          </Button>
          {renderLogo()}
        </div>
        <Spacer />
        <div>
          <InputGroup component="fieldset" onClick={handleIsActiveChange}>
            <CheckBox
              style={{ paddingLeft: 0 }}
              name="isActive"
              checked={selectedInbox.IsActiveYN === 'Y' ? true : false}
            />
            <span
              style={{
                position: 'relative',
                top: 12,
                cursor: 'pointer',
                fontWeight: 900,
                height: 5,
                marginLeft: '-2px',
                fontSize: '75%',
                fontStyle: 'italic',
              }}
            >
              {' '}
              Active
            </span>
          </InputGroup>
          <InputGroup component="fieldset">
            <CheckBox
              style={{ paddingLeft: 0 }}
              name="isAutoCheck"
              checked={automaticProcessToggled}
              onChange={handleIsAutoCheckChange}
            />
            <span
              style={{
                position: 'relative',
                top: 12,
                cursor: 'pointer',
                fontWeight: 900,
                height: 5,
                marginLeft: '-2px',
                fontSize: '75%',
                fontStyle: 'italic',
              }}
            >
              {selectedInbox.inboxType === 'RSS' ||
              selectedInbox.inboxType === 'TM' ||
              selectedInbox.inboxType === 'AM'
                ? ` Automatically Process Alerts and notify users.`
                : ' At least one of the below automatic processes is required.'}
            </span>
          </InputGroup>
          {automaticProcessToggled && (
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <Spacer spaceParam={'large'} />
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-start',
                }}
              >
                <label
                  style={{
                    fontWeight: 900,
                    fontSize: '12px',
                    display: 'flex',
                    alignItems: 'center',
                    marginBottom: '0px',
                    marginLeft: '0px',
                    whiteSpace: 'nowrap',
                  }}
                >
                  Automatic Process:
                </label>
                {help && (
                  <>
                    <Spacer />
                    <Tooltip
                      placement="top"
                      title={
                        selectedInbox.inboxType === 'RSS' ||
                        selectedInbox.inboxType === 'TM' ||
                        selectedInbox.inboxType === 'AM'
                          ? `This is the automatic process that is run every 15 minutes.`
                          : 'This is the automatic process for received alerts.'
                      }
                    />
                  </>
                )}
              </div>
              <Spacer spaceParam={'large'} />
              <Select
                onChange={handleSelectChange}
                multiple={false}
                style={{
                  minWidth: '150px',
                  maxWidth: '350px',
                  cursor: 'pointer',
                }}
                value={selectedInbox.process_type || ''}
                name="TYPE"
                required
              >
                {['EMAIL', 'ZAP', 'IR'].includes(selectedInbox.inboxType)
                  ? typeItems
                      .filter((item: any) =>
                        ['I', 'C'].includes(item.Process_Type)
                      )
                      .map((item: any) => {
                        return (
                          <MenuItem
                            key={item.Process_Type}
                            value={item.Process_Type}
                            style={{ cursor: 'pointer' }}
                          >
                            {item.Process_Type === 'I'
                              ? 'Pin Alerts'
                              : 'Create Issues'}
                          </MenuItem>
                        )
                      })
                  : typeItems.map((item: any) => {
                      return (
                        <MenuItem
                          key={item.Process_Type}
                          value={item.Process_Type}
                          style={{ cursor: 'pointer' }}
                        >
                          {item.Process_Type === 'I'
                            ? 'Pin Alerts'
                            : item.Process_Type === 'C'
                            ? 'Create Issues (All Severity Levels)'
                            : 'Create Issues (Highest Severity Level)'}
                        </MenuItem>
                      )
                    })}
              </Select>
            </div>
          )}
          <Spacer spaceParam={'large'} />
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button color="link" onClick={() => setHelp((prev) => !prev)}>
              <i>{help ? 'Hide Help' : 'Show Help'}</i>
            </Button>
            <Spacer />

            {renderDeleteButton()}
            <Spacer />
            <Button className={classes.btnIcc} id="inbox" onClick={onSubmit}>
              Save
            </Button>
          </div>
        </div>
        <div>
          {isIconChooserOpen && (
            <IconChooser
              content={
                <>
                  {icons.length > 0 ? (
                    icons.map((ic: any) => (
                      <img
                        id={ic.id}
                        className={cx(
                          classes.feedLogo,
                          classes.iconChooserSpacing
                        )}
                        key={ic.id}
                        src={'data:image/png;base64,' + ic.graphic}
                        alt={ic.filename}
                        onClick={handleIconSelected}
                      />
                    ))
                  ) : (
                    <>
                      <p>
                        <b>Design your Popup</b>
                        <br />
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod tempor incididunt ut labore et dolore
                        magna aliqua. Ut enim ad minim veniam, quis nostrud
                        exercitation ullamco laboris nisi ut aliquip ex ea
                        commodo consequat. Duis aute irure dolor in
                        reprehenderit in voluptate velit esse cillum dolore eu
                        fugiat nulla pariatur. Excepteur sint occaecat cupidatat
                        non proident, sunt in culpa qui officia deserunt mollit
                        anim id est laborum.
                      </p>
                    </>
                  )}
                </>
              }
              handleClose={toggleIconChooserPopup}
            />
          )}
        </div>
        {<LoadingBackDrop open={dataLoading} />}
        {isOpenAMPopup && (
          <AMSetting
            inboxGUID={selectedInbox.inboxGUID}
            onClose={toggleAMPopup}
            onSubmit={handleAMSetting}
            isOpen={isOpenAMPopup}
            existingAdminToken={
              selectedInbox.feeds[0] && selectedInbox.feeds[0].feedToken
                ? selectedInbox.feeds[0].feedToken
                : ''
            }
            existingDomain={
              selectedInbox.feeds[0] && selectedInbox.feeds[0].feedURL
                ? new URL(selectedInbox.feeds[0].feedURL).hostname
                : ''
            }
          />
        )}

        {isDeleteConfirmOpen && (
          <AdminConsoleDialog
            header={<h2>Permanently Delete</h2>}
            open={true}
            content={
              'Are you sure you would like to delete this Inbox?  This will also delete all Alerts within this Inbox.  This is permanant and CAN NOT be undone.'
            }
            onConfirm={handleDeleteInboxConfirmation}
            consoleRole="destructive"
            onCancel={toggleConfirm}
          />

          // <ConfirmDialog
          //   open={true}
          //   title="Alert Inbox Delete"
          //   content={`You are about to delete "${selectedInbox.inboxName}", do you wish to continue?`}
          //   confirm={handleDeleteInboxConfirmation}
          //   cancel={toggleConfirm}
          // />
        )}
      </>
    ),
    [
      selectedInbox.feeds,
      isOpenAMPopup,
      handleAMSetting,
      selectedInbox.teamID,
      handleDeleteInboxConfirmation,
      handleIconSelected,
      handleInboxTypeChange,
      handleIsActiveChange,
      handleIsAutoCheckChange,
      handleTextFieldChange,
      icons,
      isIconChooserOpen,
      isDeleteConfirmOpen,
      selectedInbox.inboxGUID,
      myTeams,
      onSubmit,
      renderInboxConfigurationDetails,
      renderLogo,
      selectedInbox.inboxType,
      toggleAMPopup,
      selectedInbox.alrt_filter,
      selectedInbox.inboxName,
      selectedInbox.process_type,
      typeItems,
      toggleConfirm,
      toggleIconChooserPopup,
      handleSelectChange,
      selectedInbox.IsActiveYN,
      automaticProcessToggled,
      renderDeleteButton,
      dataLoading,
      selectedInbox.defaultSeverity,
      help,
    ]
  )

  return (
    <ResponsiveDialog
      open={isDialogOpen}
      onClose={onClose}
      TransitionComponent={SlideLeft as any}
    >
      <Toolbar>
        <HeaderNavigation
          title={
            selectedInbox.inboxName ? selectedInbox.inboxName : 'New Inbox'
          }
          onNavClick={onClose as any}
          canGoBack={false}
        />
      </Toolbar>
      <DialogContent>{renderContent()}</DialogContent>
    </ResponsiveDialog>
  )
}
