import { webAPIUrl } from './AppSettings'
import { getRequestToken } from './fetchUtils'

export interface nvp {
  name: string
  value: string
}

export interface HttpRequest<REQB> {
  path: string
  method?: string
  body?: REQB
  accessToken?: string
  headers?: [nvp]
}

export interface HttpResponse<RESB> {
  ok: boolean
  body?: RESB
}

export const http = async <RESB, REQB = undefined>(
  config: HttpRequest<REQB>
): Promise<HttpResponse<RESB>> => {
  const request = new Request(`${webAPIUrl}${config.path}`, {
    method: config.method || 'get',
    headers: {
      'Content-Type': 'application/json',
    },
    body: config.body ? JSON.stringify(config.body) : undefined,
  })
  if (config.accessToken) {
    request.headers.set('authorization', `bearer ${config.accessToken}`)
  }

  if ((config.method || 'get').toLowerCase() !== 'get')
    request.headers.set('X-CSRF-TOKEN', getRequestToken())

  /// add stuf from headers in request buffer
  if (config.headers) {
    config.headers.forEach(function (hdr) {
      request.headers.set(hdr.name, hdr.value)
    })
  }
  const response = await fetch(request)

  if (response.ok) {
    let body
    try {
      body = await response.json()
    } catch (error) {
      console.error(error)
      return { ok: false, body: body as any }
    }
    return { ok: response.ok, body: body as any }
  } else {
    logError(request, response)
    return { ok: response.ok }
  }
}

const logError = async (request: Request, response: Response) => {
  const contentType = response.headers.get('content-type')
  let body: any
  if (contentType && contentType.indexOf('application/json') !== -1) {
    try {
      body = await response.json()
    } catch (ex) {
      body = ex
    }
  } else {
    try {
      body = await response.text()
    } catch (ex) {
      body = ex
    }
  }
  console.error(`Error requesting ${request.method} ${request.url}`, body)
}
