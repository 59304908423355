// @ts-nocheck
// TODO: Typescript
import React, { Component } from 'react'
import { ConfirmDialog } from '../Shared/ConfirmDialog'
import { connect } from 'react-redux'
import { ParticipantActions } from './_actions'
import PropTypes from 'prop-types'
import { selectParticipantById } from '../_rootConfigs/rootSelectors'

/**
 * @extends {Component<Props, {}>}}
 * Reusable confirm dialog
 */
export class ParticipantDeleteConfirmation extends Component {
  constructor(props) {
    super(props)
    this.handleConfirmation = this.handleConfirmation.bind(this)
  }

  handleConfirmation() {
    const { confirm, email_address, IssueTeamWorkspaceID, ParticipantID } =
      this.props

    if (confirm)
      confirm({
        email_address,
        IssueTeamWorkspaceID,
        ParticipantID,
      })
  }

  render() {
    const { open, cancel, email_address } = this.props
    return (
      <ConfirmDialog
        open={open}
        title="Participant Delete"
        content={`You are about to delete ${email_address} do you wish to continue?`}
        confirm={this.handleConfirmation}
        cancel={cancel}
      />
    )
  }
}

ParticipantDeleteConfirmation.propTypes = {
  ParticipantID: PropTypes.number,
  IssueTeamworkSpaceID: PropTypes.number,
  email_address: PropTypes.string,
  open: PropTypes.bool,
  confirm: PropTypes.func,
  cancel: PropTypes.func,
}

const mapStateToProps = (state, ownProps) => {
  const participant = selectParticipantById(state, ownProps.ParticipantID) || {}
  const { email_address } = participant
  return {
    email_address,
  }
}

const mapDispatchToProps = {
  confirm: ParticipantActions.delete,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ParticipantDeleteConfirmation)
