// @ts-nocheck
// TODO: Typescript
import React, { Component } from 'react'
import Autocomplete, {
  createFilterOptions,
} from '@material-ui/lab/Autocomplete'
import PropTypes from 'prop-types'
import { TextField } from '@material-ui/core'

const defaultFilter = createFilterOptions()

/**
 * @extends {Component<Props, {}>}}
 */
export class TagAutocomplete extends Component {
  constructor(props) {
    super(props)
    this.handleTagChange = this.handleTagChange.bind(this)
    this.selectedMap = {}
    this.loadMap = {}
    this.uniqueDelimitedSet = new Set()
    this.uniqueDelimitedCount = 0

    this.state = {
      selected: [], // Used for display,
      tags: props.tags,
    }
  }

  componentDidMount() {
    const { defaultSelected, tags, getKey } = this.props

    if (this.props.splitDelimited) this.handleDelimitedTagInit()
    else this.handleTagInit()

    // Set the initial tags
    if (defaultSelected && tags && tags.length > 0) {
      let selectedSet = new Set()
      defaultSelected.forEach((key) => {
        if (!selectedSet.has(key)) selectedSet.add(key)
      })

      this.handleTagChange(
        undefined,
        tags
          .map((t, index) => ({ key: getKey(t), index, t }))
          .filter((t) => selectedSet.has(t.key)),
        'init'
      )
    }
  }

  componentDidUpdate(prevProps) {
    const { tags } = this.props
    if (prevProps.tags !== tags) {
      if (this.props.splitDelimited) this.handleDelimitedTagInit()
      else this.handleTagInit()
    }
  }

  addTagForInit(outputTags, key, index, t) {
    if (!this.loadMap[key]) {
      const newTag = { key, index, t }
      outputTags.push(newTag)
      this.loadMap[key] = newTag
    }
  }

  handleTagInit() {
    const { tags, getKey } = this.props

    let resultTags = []
    tags.forEach((t, index) => {
      const key = getKey(t).trim()
      this.addTagForInit(resultTags, key, index, t)
    })

    this.setState({ tags: resultTags })
  }

  handleDelimitedTagInit() {
    //this.selectedMap = {};
    this.loadMap = {}
    this.uniqueDelimitedSet.clear()
    this.uniqueDelimitedCount = 0
    const { tags, getKey, includeDelimitedTokens } = this.props
    let resultTags = []

    if (tags) {
      tags.forEach((t, index) => {
        const baseKey = getKey(t).trim()
        const allKeys = baseKey.split(',')

        if (allKeys.length > 0)
          this.addTagForInit(resultTags, baseKey, index, t)

        if (allKeys.length > 1 && includeDelimitedTokens)
          allKeys.forEach((key) =>
            this.addTagForInit(resultTags, key.trim(), index, t)
          )

        allKeys.forEach((key) => {
          key = key.trim()
          if (!this.uniqueDelimitedSet.has(key)) {
            this.uniqueDelimitedSet.add(key)
            this.uniqueDelimitedCount++
          }
        })
      })

      this.setState({ tags: resultTags })
    }
  }

  handleTagChange(_, tag, reason) {
    const { onTagRemoved, isTagObject, splitDelimited } = this.props
    let { onTagSelected, onTagChanged } = this.props

    // Ignore the onTagSelected and onTagChanged call
    if (reason === 'init') {
      reason = 'select-option'
      onTagSelected = undefined
      onTagChanged = undefined
    }

    if (reason === 'select-option' || reason === 'create-option') {
      if (isTagObject) {
        if (typeof tag === 'string')
          onTagSelected?.call(undefined, { TagName: tag })
        else onTagSelected?.call(undefined, { ...tag })
      } else {
        let resultTag = []
        let isEmpty = false // Determines if there are anymore tags to select in the list
        let uniqueCount = 0
        if (splitDelimited) {
          tag.forEach((t) => {
            let keys = t.key.split(',')
            this.selectedMap[t.key.trim()] = t

            keys.forEach((tagKey) => {
              tagKey = tagKey.trim()

              if (this.uniqueDelimitedSet.has(tagKey)) uniqueCount++

              if (!this.selectedMap[tagKey]) this.selectedMap[tagKey] = t
              resultTag.push(this.loadMap[tagKey])
            })
          })
        } else {
          resultTag = tag
          tag.forEach((t) => {
            let tagKey = t.key
            if (!this.selectedMap[tagKey]) {
              this.selectedMap[tagKey] = t
            }
          })
        }
        //allTags = tag.map(t => getKey(t));

        if (uniqueCount === this.uniqueDelimitedCount) isEmpty = true
        const selectedMapReturn = { ...this.selectedMap }
        onTagSelected?.call(undefined, resultTag, selectedMapReturn, isEmpty)
        onTagChanged?.call(undefined, resultTag, selectedMapReturn, isEmpty)
        this.setState({
          selected: resultTag,
        })
      }
    } else if (reason === 'remove-option') {
      let newSelectedMap = {}
      tag.forEach((t) => {
        const tagKey = t.key
        if (this.selectedMap[tagKey])
          newSelectedMap[tagKey] = this.selectedMap[tagKey]
      })
      this.selectedMap = newSelectedMap
      const selectedMapReturn = { ...this.selectedMap }
      onTagRemoved?.call(undefined, tag, selectedMapReturn)
      onTagChanged?.call(undefined, tag, selectedMapReturn)
      this.setState({
        selected: tag,
      })
    } else if (reason === 'clear') {
      this.selectedMap = {}
      onTagRemoved?.call(undefined, [], {})
      onTagChanged?.call(undefined, [], {})
      this.setState({
        selected: [],
      })
    }
  }

  render() {
    const {
      disabled,
      readOnly,
      autoFocus,
      open,
      onClose,
      onOpen,
      openOnFocus,
      fullWidth,
      className,
      onBlur,
      classes,
      createTag,
      disableCloseOnSelect,
      splitDelimited,
      multiple,
      isTagObject,
      placeholder,
      renderOption,
      renderTags,
    } = this.props
    let { getFilterKey, filter } = this.props
    filter = filter || defaultFilter
    getFilterKey = getFilterKey || ((opt) => opt.key)
    const { tags } = this.state
    let filterOptions
    let freeSolo = false
    if (createTag) {
      filterOptions = (options, params) => {
        const filtered = filter(options, params)

        // Suggest the creation of a new value
        if (params.inputValue !== '') {
          filtered.push({
            TagName: params.inputValue,
            inputValue: params.inputValue,
            title: `Add "${params.inputValue}"`,
          })
        }

        return filtered
      }

      freeSolo = true
    } else if (splitDelimited) {
      filterOptions = (options, params) => {
        const filtered = filter(options, params)
        return filtered.filter((opt) => {
          /*const values = opt.split(',')
                    let isDup = false;
                    for (let i = 0; i < values.length; i++) {
                        if (this.state.selected.indexOf(values[i]) > -1)
                            isDup = true;
                    }

                    return !isDup;*/
          // Check if the tag represents multiple values
          const optKey = getFilterKey(opt)
          if (optKey.indexOf(',') > -1) return this.state.selected.length === 0

          return !this.selectedMap[optKey]
        })
      }
    } else {
      filterOptions = (options, params) => {
        const filtered = filter(options, params)
        const result = filtered.filter((opt) => {
          const optKey = getFilterKey(opt)
          return !this.selectedMap[optKey]
        })

        return result
      }
    }

    const getOptionLabel =
      this.props.getOptionLabel ||
      ((option) => {
        let tagName
        let title
        if (isTagObject) {
          option = option || {}
          tagName = option.TagName || ''
          title =
            !option.TagID && tagName && createTag ? `Add "${tagName}"` : tagName
        } else title = option.key

        return title || ''
      })

    return (
      <Autocomplete
        style={{
          maxHeight: 100,
          overflowY: 'auto',
          overflowX: 'hidden',
        }}
        autoHighlight={true}
        multiple={multiple}
        placeholder={placeholder}
        className={className}
        classes={classes}
        onBlur={onBlur}
        open={open}
        onOpen={onOpen}
        onClose={onClose}
        disableCloseOnSelect={disableCloseOnSelect}
        openOnFocus={openOnFocus}
        options={tags}
        getOptionLabel={getOptionLabel}
        disabled={disabled || readOnly}
        onChange={this.handleTagChange}
        freeSolo={freeSolo}
        filterOptions={filterOptions}
        value={this.state.selected}
        renderInput={(params) => (
          <TextField
            style={{ maxHeight: 200 }}
            focused={true}
            autoFocus={autoFocus}
            {...params}
            fullWidth={fullWidth}
            placeholder={this.state.selected.length === 0 ? placeholder : null}
          />
        )}
        renderOption={renderOption}
        renderTags={renderTags}
      />
    )
  }
}

TagAutocomplete.propTypes = {
  tags: PropTypes.array,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  fullWidth: PropTypes.bool,
  onTagSelected: PropTypes.func,
  onTagRemoved: PropTypes.func,
  onTagChanged: PropTypes.func,
  selectedTagIds: PropTypes.arrayOf(PropTypes.number),
  IssueID: PropTypes.number,
  autoFocus: PropTypes.bool,
  splitDelimited: PropTypes.bool,
  includeDelimitedTokens: PropTypes.bool,
  multiple: PropTypes.bool,
  isTagObject: PropTypes.bool,
  getKey: PropTypes.func,
  placeholder: PropTypes.string,
  onBlur: PropTypes.func,
  openOnFocus: PropTypes.bool,
  renderOption: PropTypes.func,
  renderTags: PropTypes.func,
  disableCloseOnSelect: PropTypes.bool,
  onClose: PropTypes.func,
  onOpen: PropTypes.func,
}

TagAutocomplete.defaultProps = {
  tags: [],
  disabled: false,
  readOnly: false,
  fullWidth: false,
  onTagSelected: () => {},
  onTagRemoved: () => {},
  selectedTagIds: [],
  autoFocus: false,
  splitDelimited: false,
  multiple: false,
  isTagObject: false,
  getKey: (opt) => opt,
  onBlur: () => {},
  includeDelimitedTokens: false,
  openOnFocus: false,
  disableCloseOnSelect: false,
  onClose: () => {},
  onOpen: () => {},
}
