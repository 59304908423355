// @ts-nocheck
// TODO: Typescript
import {
  FETCH_CREATE_ISSUE_FORMS,
  FETCH_UPDATE_ISSUE_FORMS,
  FETCH_FORM_CONTENT,
  SUBMIT_ISSUE_FORM,
  TeamReportConstants,
  FETCH_CHANGE_LEVEL_FORM_CONTENT,
  FETCH_UPDATE_ISSUE_FORMS_COUNT,
  FETCH_RESOLVE_ISSUE_FORM_CONTENT,
  FETCH_OPEN_ISSUE_FORM_CONTENT,
} from './_constants'
import { generateCrudActions } from '../_utils/reduxUtils'

// Fetch change level form
export function fetchChangeLevelFormContent(result) {
  return {
    type: FETCH_CHANGE_LEVEL_FORM_CONTENT.REQUEST,
    payload: {
      result,
    },
  }
}

export function fetchChangeLevelFormContentSuccess(content) {
  return {
    type: FETCH_CHANGE_LEVEL_FORM_CONTENT.SUCCESS,
    payload: {
      content,
    },
  }
}

export function fetchChangeLevelFormContentLoading(content) {
  return {
    type: FETCH_CHANGE_LEVEL_FORM_CONTENT.LOADING,
    payload: {
      content,
    },
  }
}

export function fetchChangeLevelFormContentFailure(
  ErrorMessage,
  result,
  source
) {
  return {
    type: FETCH_CHANGE_LEVEL_FORM_CONTENT.FAILURE,
    payload: {
      ErrorMessage,
      result,
      source,
    },
  }
}

// Fetch resolve issue form
export function fetchResolveIssueFormContent(result) {
  return {
    type: FETCH_RESOLVE_ISSUE_FORM_CONTENT.REQUEST,
    payload: {
      result,
    },
  }
}

export function fetchResolveIssueFormContentSuccess(content) {
  return {
    type: FETCH_RESOLVE_ISSUE_FORM_CONTENT.SUCCESS,
    payload: {
      content,
    },
  }
}

export function fetchResolveIssueFormContentLoading(content) {
  return {
    type: FETCH_RESOLVE_ISSUE_FORM_CONTENT.LOADING,
    payload: {
      content,
    },
  }
}

export function fetchResolveIssueFormContentFailure(
  ErrorMessage,
  result,
  source
) {
  return {
    type: FETCH_RESOLVE_ISSUE_FORM_CONTENT.FAILURE,
    payload: {
      ErrorMessage,
      result,
      source,
    },
  }
}

// Fetch open issue form
export function fetchOpenIssueFormContent(result) {
  return {
    type: FETCH_OPEN_ISSUE_FORM_CONTENT.REQUEST,
    payload: {
      result,
    },
  }
}

export function fetchOpenIssueFormContentSuccess(content) {
  return {
    type: FETCH_OPEN_ISSUE_FORM_CONTENT.SUCCESS,
    payload: {
      content,
    },
  }
}

export function fetchOpenIssueFormContentLoading(content) {
  return {
    type: FETCH_OPEN_ISSUE_FORM_CONTENT.LOADING,
    payload: {
      content,
    },
  }
}

export function fetchOpenIssueFormContentFailure(ErrorMessage, result, source) {
  return {
    type: FETCH_OPEN_ISSUE_FORM_CONTENT.FAILURE,
    payload: {
      ErrorMessage,
      result,
      source,
    },
  }
}

export function fetchCreateIssueForms() {
  return {
    type: FETCH_CREATE_ISSUE_FORMS.REQUEST,
  }
}

export function submitIssueForm(formData, source) {
  return {
    type: SUBMIT_ISSUE_FORM.REQUEST,
    payload: {
      formData,
      source,
    },
  }
}

export function submitIssueFormLoading(source) {
  return {
    type: SUBMIT_ISSUE_FORM.LOADING,
    payload: {
      source,
    },
  }
}

export function submitIssueFormFailure(error, result, source) {
  return {
    type: SUBMIT_ISSUE_FORM.FAILURE,
    payload: {
      error,
      result,
      source,
    },
  }
}

export function submitIssueFormSuccess(result, source) {
  return {
    type: SUBMIT_ISSUE_FORM.SUCCESS,
    payload: {
      result,
      source,
    },
  }
}

export function fetchCreateIssueFormsLoading(result, source) {
  return {
    type: FETCH_CREATE_ISSUE_FORMS.LOADING,
    payload: {
      result,
      source,
    },
  }
}

export function fetchCreateIssueFormsSuccess(result, source) {
  return {
    type: FETCH_CREATE_ISSUE_FORMS.SUCCESS,
    payload: {
      result,
      source,
    },
  }
}

export function fetchUpdateIssueForms(result, source) {
  return {
    type: FETCH_UPDATE_ISSUE_FORMS.REQUEST,
    payload: {
      result,
      source,
    },
  }
}

export function fetchUpdateIssueFormsLoading(result, source) {
  return {
    type: FETCH_UPDATE_ISSUE_FORMS.LOADING,
    payload: {
      result,
      source,
    },
  }
}

export function fetchUpdateIssueFormsSuccess(result, source) {
  return {
    type: FETCH_UPDATE_ISSUE_FORMS.SUCCESS,
    payload: {
      result,
      source,
    },
  }
}

export function fetchUpdateIssueFormsCount(result, source) {
  return {
    type: FETCH_UPDATE_ISSUE_FORMS_COUNT.REQUEST,
    payload: {
      result,
      source,
    },
  }
}

export function fetchUpdateIssueFormsCountSuccess(count, source) {
  return {
    type: FETCH_UPDATE_ISSUE_FORMS_COUNT.SUCCESS,
    payload: {
      count,
      source,
    },
  }
}

export function fetchFormContent(result) {
  return {
    type: FETCH_FORM_CONTENT.REQUEST,
    payload: {
      result,
    },
  }
}

export function fetchFormContentLoading(content) {
  return {
    type: FETCH_FORM_CONTENT.LOADING,
    payload: {
      content,
    },
  }
}

export function fetchFormContentSuccess(content) {
  return {
    type: FETCH_FORM_CONTENT.SUCCESS,
    payload: {
      content,
    },
  }
}

export const TeamReportActions = generateCrudActions(TeamReportConstants.PREFIX)

export function initTeamReportsSuccess(result) {
  return {
    type: TeamReportConstants.INIT.SUCCESS,
    payload: {
      result,
    },
  }
}
