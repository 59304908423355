import * as React from 'react'
import classes from './CountLabel.module.scss'
import { cx } from '../_utils/objectUtils'

export function CountLabel({
  max,
  count,
  className = '',
  ...rest
}: {
  max: number
  count: number
  className: string
}) {
  return (
    <span className={cx(classes.root, className)}>
      {max && count > max ? count + '+' : count}
    </span>
  )
}
