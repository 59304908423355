// @ts-nocheck
// TODO: Typescript
import React, { Component } from 'react'
import {
  withMobileDialog,
  Dialog,
  DialogContent,
  Toolbar,
} from '@material-ui/core'
import PropTypes from 'prop-types'
import { SlideLeft } from './Transition'
import { HeaderNavigation } from './HeaderNavigation'

const ResponsiveDialog = withMobileDialog()(Dialog)

/**
 * @extends {Component<Props, {}>}}
 * Reusable view dialog
 */
export class ViewDialog extends Component {
  render() {
    const { open, onClose, title, content } = this.props
    return (
      <ResponsiveDialog
        open={open}
        onClose={onClose}
        TransitionComponent={SlideLeft}
      >
        <Toolbar>
          <HeaderNavigation
            title={title}
            onNavClick={onClose}
            canGoBack={false}
          />
        </Toolbar>
        <DialogContent>{content}</DialogContent>
      </ResponsiveDialog>
    )
  }
}

ViewDialog.propTypes = {
  title: PropTypes.string,
  content: PropTypes.any,
  onClose: PropTypes.func,
  open: PropTypes.bool,
}

ViewDialog.defaultProps = {
  title: '',
  content: '',
  open: false,
}
