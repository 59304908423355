// @ts-nocheck
// TODO: Typescript
import React, { Component, Fragment } from 'react'
import { ErrorMessageBase } from './ErrorMessageBase'
import PropTypes from 'prop-types'

/**
 * @extends {Component<Props, {}>}}
 */
export class InvalidAccountMessage extends Component {
  render() {
    const { className, page } = this.props

    return (
      <ErrorMessageBase
        className={className}
        center={page}
        primary={
          <Fragment>
            Your organization is not currently configured for Issues Management.
          </Fragment>
        }
        secondary={
          <Fragment>
            <p>
              If you are not yet a client of In Case of Crisis, and would like
              to learn more, you can request information by
              contacting salesteam@rockdovesolutions.com
            </p>
            <p>
              If your organization is a client of In Case of Crisis, and
              needs access or support, you can reach our client success team by
              contacting support@incaseofcrisis.com.
            </p>
          </Fragment>
        }
      />
    )
  }
}

InvalidAccountMessage.propTypes = {
  page: PropTypes.bool,
}
