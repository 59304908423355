// @ts-nocheck
// TODO: Typescript
import React from 'react'
import CreateAccount from './CreateAccount'
import { prefixSelectorWithApp } from '../_utils/domUtils'

/**
 * @extends {Component<Props, {}>}}
 */
const createAccountExport = (props) => (
  <CreateAccount id={prefixSelectorWithApp('CreateAccountPage')} {...props} />
)
export default createAccountExport
