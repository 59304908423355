// @ts-nocheck
// TODO: Typescript
import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { Row, Col, Container } from 'reactstrap'
import {
  ChatDetailActions,
  createDirectMessage,
  fetchDirectMessages,
} from '../Chat/_actions'
import ChatDetailEditor from '../Chat/ChatDetailEditor'
import PropTypes from 'prop-types'
import { WindowResize } from '../Shared/WindowResize'
import { StretchLayout, StretchBody } from '../Layouts/StretchLayout'
import { MobileLayout, DesktopLayout } from '../Layouts/ResponsiveHidden'
import { cx } from '../_utils/objectUtils'
import { selectWorkspaceById, selectWorkspaceChatIds } from './_selectors'
import {
  canUserAdministerWorkspace,
  canUserApproveWorkspace,
  selectWorkspaceChatParticipants,
  selectWorkspaceChats,
  selectWorkspaceDirectMessages,
} from '../_rootConfigs/rootSelectors'
import { selectCreatedDirectMessage } from '../Chat/_selectors'
import {
  isCreatingDirectMessage,
  isCreatingOwnerDirectMessageSuccess,
  isFetchChatEntriesRequired,
  isFetchDirectMessagesRequired,
  isFetchingChatEntries,
  isFetchingIssueDetails,
  isFetchIssuesLoading,
} from '../Shared/_requestStatusSelectors'
import { useFetchIssueDetails } from './_hooks'
import { selectWorkspaceIssueId, selectIssueById } from '../Issue/_selectors'

// Should probably be moved to issue
class WorkspaceChatViewer extends Component {
  constructor(props) {
    super(props)

    this.state = {
      directMessages: undefined,
      selectedChat: 0,
      lastValidChat: 0,
      lastValidChatName: '',
      isDirect: false,
      selectedChatName: undefined,
      recipients: {
        selected: [],
        selectedMap: {},
      },
      creatingDirectMessage: false, // Determines if the user currently elected to create a direct message for this instance of the component
    }

    this.handleChatClick = this.handleChatClick.bind(this)
    this.handleRecipientsChanged = this.handleRecipientsChanged.bind(this)
    this.handleChatSelectedFromMenu = this.handleChatSelectedFromMenu.bind(this)
    this.handleOnCreateChatClicked = this.handleOnCreateChatClicked.bind(this)
    this.renderChatDetailEditor = this.renderChatDetailEditor.bind(this)
    this.goToPreviousValidChat = this.goToPreviousValidChat.bind(this)
    this.fetchChatEntriesIfRequired = this.fetchChatEntriesIfRequired.bind(this)
  }

  componentDidMount() {
    // Maybe only do a pull if the data hasn't been pulled before.
    // The websocket should be able to handle the rest.
    // On reconnect make all details stale.
    const {
      IssueTeamWorkspaceID,
      fetchDirectMessages,
      isFetchDirectMessagesRequired,
    } = this.props
    // Always reset the selected chat when mounted

    if (this.props.publicChat?.length > 0) {
      const firstChat = this.props.publicChat[0].ChatID

      this.setState(this.getCurrentSelectedState(firstChat))

      if (firstChat !== undefined) this.fetchChatEntriesIfRequired(firstChat)
    }

    if (isFetchDirectMessagesRequired(IssueTeamWorkspaceID))
      fetchDirectMessages({ IssueTeamWorkspaceID })
  }

  componentDidUpdate(prevProps) {
    const {
      IssueTeamWorkspaceID,
      isCreatingDirectMessageSuccess,
      recentlyCreatedDirectMessage,
      isFetchDirectMessagesRequired,
      fetchDirectMessages,
    } = this.props

    if (
      this.props.publicChat?.length > 0 &&
      this.props.publicChat?.length !== prevProps.publicChat?.length
    ) {
      const firstChat = this.props.publicChat[0].ChatID

      this.setState(this.getCurrentSelectedState(firstChat))

      if (firstChat !== undefined) {
        this.fetchChatEntriesIfRequired(firstChat)
      }
    }

    if (isFetchDirectMessagesRequired(IssueTeamWorkspaceID))
      fetchDirectMessages({ IssueTeamWorkspaceID })

    if (
      recentlyCreatedDirectMessage &&
      !prevProps.isCreatingDirectMessageSuccess &&
      isCreatingDirectMessageSuccess /*&& this.state.creatingDirectMessage*/
    ) {
      const directMessage = recentlyCreatedDirectMessage
      const selectedChatName = this.getDirectMessageName(directMessage)
      if (directMessage) {
        this.setState({
          ...this.getCurrentSelectedState(
            directMessage.ChatID,
            selectedChatName
          ),
          creatingDirectMessage: false,
        })
        if (this.searchApi) this.searchApi.clearSearch()
      }
    }
  }

  fetchChatEntriesIfRequired(ChatID) {
    const { fetchChatDetail, isFetchChatEntriesRequired } = this.props
    if (isFetchChatEntriesRequired(ChatID)) fetchChatDetail({ ChatID })
  }

  getCurrentSelectedState(
    selectedChat,
    selectedChatName,
    keepPreviousChat = false
  ) {
    selectedChatName = !selectedChatName ? null : selectedChatName
    return {
      lastValidChat:
        selectedChat && !keepPreviousChat
          ? selectedChat
          : this.state.lastValidChat,
      lastValidChatName:
        selectedChat && !keepPreviousChat
          ? selectedChatName
          : this.state.lastValidChatName,
      selectedChat,
      selectedChatName,
    }
  }

  goToPreviousValidChat() {
    this.setState({
      selectedChat: this.state.lastValidChat,
      selectedChatName: this.state.lastValidChatName,
    })
  }

  handleOnCreateChatClicked() {
    const { IssueTeamWorkspaceID } = this.props
    const newChatUsers = this.state.recipients.selected
    const newChatEmails = newChatUsers.map((user) => user.t.ChatName)
    this.props.sendChatItem(newChatEmails.join(','), IssueTeamWorkspaceID)

    this.setState({
      creatingDirectMessage: true,
      recipients: {
        selected: [],
        selectedMap: {},
      },
    })
  }

  handleRecipientsChanged(selected, selectedMap) {
    this.setState({
      recipients: {
        selected,
        selectedMap,
      },
    })

    /*const p = [{ EmailAddress: 'vaughnshaun@gmail.com' }, { EmailAddress: 'ray.baldwin@rockdovesolutions.com' }];
        const directMessages = [
            { ChatID: 132, Participants: p }
        ]*/

    let partCount = 0
    let selectedDMId = 0
    const { directMessages } = this.props
    let selectedChatName = undefined
    for (let i = 0; i < directMessages.length && !selectedDMId; i++) {
      partCount = 0
      const participants = directMessages[i].Participants || []
      for (let j = 0; j < participants.length; j++) {
        const curPart = participants[j]
        if (selectedMap[curPart.EmailAddress]) partCount++
      }

      if (partCount === selected.length && partCount === participants.length) {
        selectedChatName = this.getDirectMessageName(directMessages[i])
        selectedDMId = directMessages[i].ChatID
      }
    }

    if (selectedDMId) {
      this.handleChatClick(selectedDMId, {
        selectedChatName,
        keepRecipients: false,
        keepPreviousChat: true,
      })
    } else
      this.setState({
        selectedChat: selectedDMId,
      })
  }

  getChatNameById(id) {
    const { chatIds } = this.props
    // If a normal team chat don't return a name beacuse ChatDetailEditor will get the name
    let isTeamChat = false
    for (let i = 0; i < chatIds.length && !isTeamChat; i++)
      isTeamChat = id === chatIds[i]

    if (isTeamChat) return

    // Get the combined participant names for direct messages
    const { directMessages } = this.props
    let directMessageIndex = (directMessages || []).findIndex((d) => d.ChatID)
    if (directMessageIndex > -1)
      return this.getDirectMessageName(directMessages[directMessageIndex])
  }

  getDirectMessageName(chat) {
    const participants = chat.Participants

    if (!participants) return

    return participants.map((p) => p.EmailAddress).join(', ')
  }

  renderChatDetailEditor(height) {
    const props = this.props
    const {
      disabled,
      chats,
      participants,
      directMessages,
      isFetchingChatEntries,
      IsPrivateChatAll,
      canUserAdministerWorkspace,
      canUserApproveWorkspace,
      isCreatingDirectMessage,
      isFetchingIssues,
      isFetchingIssueDetails,
      IssueName,
      issueWorkspaceID,
    } = props
    const {
      selectedChatName,
      selectedChat,
      recipients,
      isDirect /*, creatingDirectMessage*/,
    } = this.state

    const creatingDirectMessage = isCreatingDirectMessage

    return (
      <ChatDetailEditor
        ChatID={selectedChat}
        ChatName={
          !isDirect && chats?.length === 1 ? IssueName : selectedChatName
        }
        IssueName={IssueName}
        onChatSelected={this.handleChatSelectedFromMenu}
        teamChats={chats}
        height={height}
        disabled={disabled}
        canModifyChats={IsPrivateChatAll}
        disableCreate={creatingDirectMessage}
        isCreating={creatingDirectMessage}
        isFetchingChatEntries={
          isFetchingChatEntries || isFetchingIssues || isFetchingIssueDetails
        }
        directMessages={directMessages}
        participants={participants}
        onCreateChatClicked={this.handleOnCreateChatClicked}
        onRecipientsChanged={this.handleRecipientsChanged}
        hasRecipents={recipients.selected.length === 0 ? false : true}
        canModifyDirectMessages={
          IsPrivateChatAll ||
          canUserAdministerWorkspace ||
          canUserApproveWorkspace
        }
        onCancelDirectMessage={this.goToPreviousValidChat}
        onSearchApi={(api) => (this.searchApi = api)}
        onReturnToChatList={this.goToPreviousValidChat}
        issueWorkspaceID={issueWorkspaceID}
      />
    )
  }

  render() {
    const props = this.props
    const { chatIds, className, isFetchingIssues, isFetchingIssueDetails } =
      props

    if (chatIds.length === 0 && isFetchingIssues && isFetchingIssueDetails)
      return <span>No chats</span>

    return (
      <React.Fragment>
        <DesktopLayout>
          <Container fluid={true} className={cx('h-100', className)}>
            <Row className="h-100">
              {/*<Col className="h-100" sm={4}>
                                <WorkspaceChatCreator {...workspace} />
                                <SelectionList
                                    ids={chatIds}
                                    labelName="ChatName"
                                    style={{height: '100%', overflow: 'auto'}}
                                    selectItemFromStore={(state, props) => selectChatById(state, props.id)}
                                    onSelect={this.handleChatClick}
                                    selected={selectedChat} />
                            </Col>*/}
              <Col className="h-100 position-relative" sm={12}>
                <WindowResize>{this.renderChatDetailEditor}</WindowResize>
              </Col>
            </Row>
          </Container>
        </DesktopLayout>
        <MobileLayout>
          <div className={cx('h-100 position-relative', className)}>
            <StretchLayout>
              {/*<StretchHeader className={classes.selectionListCompact}>
                                <Container fluid={true}>
                                    <SelectionList
                                        ids={chatIds}
                                        compact={true}
                                        labelName="ChatName"
                                        selectItemFromStore={(state, props) => selectChatById(state, props.id)}
                                        onSelect={this.handleChatClick}
                                        selected={selectedChat}
                                        disabled={disabled}
                                    />
                                </Container>
                            </StretchHeader>*/}
              <StretchBody>
                <WindowResize>{this.renderChatDetailEditor}</WindowResize>
              </StretchBody>
            </StretchLayout>
          </div>
        </MobileLayout>
      </React.Fragment>
    )
  }

  handleChatSelectedFromMenu(chat, isDirect) {
    this.handleChatClick(chat.ChatID, {
      isDirect,
      selectedChatName: chat.ChatName,
    })
  }

  handleChatClick(
    selectedChat,
    {
      isDirect = false,
      keepRecipients = false,
      keepPreviousChat = false,
      selectedChatName = undefined,
    }
  ) {
    if (selectedChat) {
      if (this.state.selectedChat !== selectedChat) {
        this.fetchChatEntriesIfRequired(selectedChat)
        let newState = {
          ...this.getCurrentSelectedState(
            selectedChat,
            selectedChatName,
            keepPreviousChat
          ),
          isDirect,
        }

        if (keepRecipients === true) {
          newState.recipients = {
            selected: [],
            selectedMap: {},
          }
        }

        this.setState(newState)

        if (this.searchApi) this.searchApi.clearSearch()
      }
    } else {
      // If already selected clear the previous chat
      this.setState(
        this.getCurrentSelectedState(selectedChat, selectedChatName, false)
      )
    }
  }
}

WorkspaceChatViewer.propTypes = {
  chatIds: PropTypes.arrayOf(PropTypes.number),
  chats: PropTypes.array,
  OrgID: PropTypes.number,
  disabled: PropTypes.bool,
  fetchDirectMessages: PropTypes.func,
  participants: PropTypes.array,
  canUserApproveWorkspace: PropTypes.bool,
  canUserAdministerWorkspace: PropTypes.bool,
  IsPrivateChatAll: PropTypes.bool,
}

WorkspaceChatViewer.defaultProps = {
  chatIds: [],
  chats: [],
  participants: [],
  IsPrivateChatAll: false,
  canUserApproveWorkspace: false,
  canUserAdministerWorkspace: false,
}

const mapStateToProps = (state, ownProps) => {
  const { OrgID, IsPrivateChatAll } = selectWorkspaceById(
    state,
    ownProps.IssueTeamWorkspaceID
  )
  const issueID = selectWorkspaceIssueId(state, ownProps.IssueTeamWorkspaceID)
  const issue = selectIssueById(state, issueID)
  const IssueName = issue?.IssueName
  const publicChat = selectWorkspaceChats(
    state,
    ownProps.IssueTeamWorkspaceID
  )?.filter((chat) => chat.ChatGUID)

  return {
    chatIds: selectWorkspaceChatIds(state, ownProps.IssueTeamWorkspaceID),
    chats: selectWorkspaceChats(state, ownProps.IssueTeamWorkspaceID),
    publicChat: publicChat,
    issueWorkspace: ownProps.IssueTeamWorkspaceID,
    OrgID,
    //UserEmail: selectUserEmail(state),
    directMessages: selectWorkspaceDirectMessages(
      state,
      ownProps.IssueTeamWorkspaceID
    ),
    participants: selectWorkspaceChatParticipants(
      state,
      ownProps.IssueTeamWorkspaceID
    ),
    isCreatingDirectMessage: isCreatingDirectMessage(state),
    isCreatingDirectMessageSuccess: isCreatingOwnerDirectMessageSuccess(state),
    recentlyCreatedDirectMessage: selectCreatedDirectMessage(state),
    isFetchingChatEntries: isFetchingChatEntries(state),
    canUserApproveWorkspace: canUserApproveWorkspace(
      state,
      ownProps.IssueTeamWorkspaceID
    ),
    canUserAdministerWorkspace: canUserAdministerWorkspace(
      state,
      ownProps.IssueTeamWorkspaceID
    ),
    IsPrivateChatAll: IsPrivateChatAll,
    isFetchDirectMessagesRequired: (IssueTeamWorkspaceID) =>
      isFetchDirectMessagesRequired(state, IssueTeamWorkspaceID),
    isFetchChatEntriesRequired: (chatID) =>
      isFetchChatEntriesRequired(state, chatID),
    isFetchingIssueDetails: isFetchingIssueDetails(
      state,
      ownProps.IssueTeamWorkspaceID
    ),
    isFetchingIssues: isFetchIssuesLoading(state),
    IssueName,
    issueWorkspaceID: ownProps.IssueTeamWorkspaceID,
  }
}

const mapDispatchToProps = {
  fetchChatDetail: (params) =>
    ChatDetailActions.fetch(params, { icoRequestId: params.ChatID }),
  sendChatItem: (EmailList, IssueTeamWorkspaceID) =>
    createDirectMessage.request({ EmailList, IssueTeamWorkspaceID }),
  fetchDirectMessages: (params) =>
    fetchDirectMessages.request(params, {
      icoRequestId: params.IssueTeamWorkspaceID,
    }),
}

const ConnectedWorkpaceChatViewer = connect(
  mapStateToProps,
  mapDispatchToProps
)(WorkspaceChatViewer)

const WorkspaceChatViewerExport = ({ IssueTeamWorkspaceID, ...other }) => {
  useFetchIssueDetails(IssueTeamWorkspaceID)
  return (
    <Fragment>
      <ConnectedWorkpaceChatViewer
        IssueTeamWorkspaceID={IssueTeamWorkspaceID}
        {...other}
      />
    </Fragment>
  )
}

export default WorkspaceChatViewerExport
