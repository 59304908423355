import {
  bindSel,
  getOrgTag,
  getOrgUgisMembers,
} from '../_rootConfigs/rootReducerSlicers'
import * as fromOrg from './_reducer'

export const selectOrgTagIdMap = bindSel(getOrgTag, fromOrg.selectOrgTagIdMap)
export const selectOrgTagById = bindSel(getOrgTag, fromOrg.selectOrgTagById)
export const selectOrgTags = bindSel(getOrgTag, fromOrg.selectOrgTags)
export const selectOrgTagsByOrgIds = bindSel(
  getOrgTag,
  fromOrg.selectOrgTagsByOrgIds
)

export const selectUgisMembersByOrg = bindSel(
  getOrgUgisMembers,
  fromOrg.selectUgisMembersByOrg
)
