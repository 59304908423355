// @ts-nocheck
// TODO: Typescript

// NOTE: Might work in the public folder
/// This doesn't work when I build to the main server. I might need to look into com link
/*const workercode = () => {
    let onmessage = e => {
        const conferenceIds = e.data[0];
        const idMap = e.data[1];
        const today = e.data[2];
        const tomorrow = e.data[3];

        const result = (conferenceIds || []).filter(id => {
            let conference = idMap[id];
            if (!conference)
                return false;

            const { Scheduled, ScheduledEnd, RepeatType } = conference;
            return !(!Scheduled || RepeatType || (
                // If no end date, show for the whole day
                (!ScheduledEnd && today < tomorrow) ||
                // Else has end date, show until the end
                (ScheduledEnd && today >= Scheduled && today <= ScheduledEnd)
            ) ? true : false);
        });

        postMessage(result);
    };
};

let code = workercode.toString();
code = code.substring(code.indexOf("{") + 1, code.lastIndexOf("}"));

const blob = new Blob([code], { type: "application/javascript" });
const ConferenceExpiredWorker = URL.createObjectURL(blob);

module.exports = ConferenceExpiredWorker;*/
const conferenceWorkerExport = {
  createFakeWorker: function () {
    return {
      postMessage: function (e) {
        e = { data: e }
        const conferenceIds = e.data[0]
        const idMap = e.data[1]
        const today = e.data[2]

        const result = (conferenceIds || []).filter((id) => {
          let conference = idMap[id]
          if (!conference) return false

          const { Scheduled, ScheduledEnd, RepeatType, isAllDay } = conference
          return !(!Scheduled ||
          RepeatType ||
          // If not all day, show for the whole day
          (isAllDay &&
            ScheduledEnd &&
            today >= Scheduled &&
            today <= ScheduledEnd) ||
          // Else all day, show until the end
          (!isAllDay && ScheduledEnd && today <= ScheduledEnd)
            ? true
            : false)
        })

        if (this.onmessage) this.onmessage({ data: result })
      },
    }
  },
}

export default conferenceWorkerExport
