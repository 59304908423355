import {
  bindSel,
  getPublicPlaybookApp,
} from '../_rootConfigs/rootReducerSlicers'
import * as priv from './_reducer'

export const selectPublicPlaybookSearchValue = bindSel(
  getPublicPlaybookApp,
  priv.selectPublicPlaybookSearchValue
)
