import React, { useEffect, useState } from 'react'
import { http } from '../_utils/http'
import { CircularProgress } from '../Shared/LoadingBackdrop'
import { IssueDynamicFormViewer } from './IssueDynamicFormViewer'
import { DFSubmission, DynamicForm } from './IssueDynamicFormInterface'
import Moment from 'react-moment'
import { faCaretDown, faCaretRight } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import classes from './IssueDynamicForm.module.scss'
import { cx } from '../_utils/objectUtils'

import { useIsMounted } from '../_utils/hooks'
import { Spacer } from '../Shared/Spacer'

interface Props {
  IssueID: number
  dfName: string
  lastSaveSubmit: string
}

export const IssueDynamicFormsList = ({
  IssueID,
  dfName,
  lastSaveSubmit,
}: Props) => {
  const [dynamicForm, setDynamicForm] = useState<DynamicForm>()
  const [isFetchingDynamicForm, setIsFetchingDynamicForm] =
    useState<boolean>(false)
  const [priorExpanded, setPriorExpanded] = useState<number[]>([])
  const isMounted = useIsMounted()

  useEffect(() => {
    const getDynamicForm = async (): Promise<any> => {
      setIsFetchingDynamicForm(true)
      let customHeaders: any = []
      customHeaders.push({ name: 'issueid', value: IssueID.toString() })
      customHeaders.push({ name: 'dfname', value: dfName })

      const result = await http<any>({
        path: '/Issue/DynamicForms',
        headers: customHeaders,
      })
      if (result.ok && result.body && isMounted()) {
        setDynamicForm(result.body)
        // retrieve the setting from the Dynamic Form
      } else {
        if (isMounted()) {
          setDynamicForm(undefined)
        }
      }
      if (isMounted()) {
        setIsFetchingDynamicForm(false)
      }
    }
    getDynamicForm()
  }, [IssueID, dfName, isMounted, lastSaveSubmit])

  if (isFetchingDynamicForm)
    return (
      <>
        <CircularProgress />
      </>
    )
  if (!dynamicForm) return <></>
  if (dynamicForm?.df_Status === 'N' && !dynamicForm.Submissions) {
    return <></>
  }
  return (
    <div style={{ padding: '1rem' }}>
      {dynamicForm?.df_Status === 'N' && dynamicForm.Submissions && (
        <div
          className={classes.styleDynamicFormSectionLeft}
          style={{ fontWeight: 'bold' }}
        >
          {dfName}
          <br />
          <br />
        </div>
      )}

      {!dynamicForm.Submissions && dynamicForm?.df_Status === 'P' && (
        <div
          className={cx(classes.styleIndented)}
          style={{ fontSize: 'smaller' }}
        >
          <i>
            There have not yet been any{' '}
            {dfName === 'Scribe Notes' ? 'Scribe Note' : dfName} forms
            submitted.
          </i>
        </div>
      )}
      {dynamicForm.Submissions && dynamicForm.Submissions.length > 0 && (
        <div>
          {dynamicForm.Submissions.map(
            (submission: DFSubmission, submissionIDX: number) => {
              return (
                <div key={'subm' + submissionIDX.toString()}>
                  <div
                    onClick={() => {
                      let newPriorExpanded: number[] = [...priorExpanded]
                      if (newPriorExpanded.includes(submissionIDX)) {
                        newPriorExpanded = newPriorExpanded.filter(
                          (val) => val !== submissionIDX
                        )
                      } else {
                        newPriorExpanded.push(submissionIDX)
                      }
                      setPriorExpanded(newPriorExpanded)
                    }}
                    className={cx(
                      classes.buttonStyledAsLink,
                      classes.styleIndented
                    )}
                    style={{ cursor: 'pointer', fontSize: 'smaller' }}
                  >
                    {priorExpanded.includes(submissionIDX) ? (
                      <FontAwesomeIcon icon={faCaretRight} />
                    ) : (
                      <FontAwesomeIcon icon={faCaretDown} />
                    )}{' '}
                    &nbsp; Submission from &nbsp;
                    {submission.Submitter},&nbsp;
                    <Moment format={'DD MMM YYYY, h:mm A'} utc local>
                      {submission.SubmittedGMT}
                    </Moment>
                  </div>
                  <Spacer spaceParam={'large'} />
                  {priorExpanded.includes(submissionIDX) && (
                    <div className={cx(classes.styleIndented)}>
                      <IssueDynamicFormViewer
                        IssueID={IssueID}
                        submissionNumber={submission.dfvals_id}
                      />
                    </div>
                  )}
                </div>
              )
            }
          )}{' '}
        </div>
      )}
    </div>
  )
}
