import * as React from 'react'
import { Component, ReactNode } from 'react'
import { cx } from '../_utils/objectUtils'
import classes from './ErrorMessageBase.module.scss'

interface Props {
  primary: ReactNode | ReactNode[]
  secondary?: ReactNode | ReactNode[]
  defaultSecondary?: boolean
  center?: boolean
  className?: string
}

export class ErrorMessageBase extends Component<Props> {
  static defaultProps: {
    defaultSecondary: boolean
    primary: string
    secondary: JSX.Element
    center: boolean
  }
  render() {
    const { className, primary, secondary, defaultSecondary, center } =
      this.props
    return (
      <div
        className={cx(classes.root, className, center ? classes.center : '')}
      >
        <div className={classes.body}>
          <div className={classes.primary}>{primary}</div>
          <div className={classes.secondary}>
            {!secondary && !defaultSecondary ? '' : secondary}
          </div>
        </div>
      </div>
    )
  }
}

ErrorMessageBase.defaultProps = {
  defaultSecondary: true,
  primary: '',
  secondary: (
    <p>
      <span>
        If you are not using the Issues Management module today, please contact{' '}
      </span>
      <span>support@incaseofcrisis.com</span> to learn more.
    </p>
  ),
  center: false,
}
