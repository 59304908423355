import * as React from 'react'
import {
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from 'reactstrap'
import { http } from '../_utils/http'
// Fixed typings for css and scss modules
// https://stackoverflow.com/questions/41336858/how-to-import-css-modules-with-typescript-react-and-webpack
// From: Matt Crinklaw-Vogt
import classes from './Playbooks.module.scss'
import { Button } from '../Shared/Buttons'
import { CircularProgress } from '../Shared/LoadingBackdrop'
import { useAppDispatch } from '../_utils/reactReduxHooks'
import { addNotification } from '../_actions'

interface Props {
  pollstart: any
  eventID: any
}

export const PollStart = ({ pollstart, eventID }: Props) => {
  const [pollStarted, setPollStarted] = React.useState(false)
  const [pollStartResult, setPollStartResult] = React.useState('')

  const [pubTargets, setPubTargets] = React.useState([])
  const [ugisTargets, setUGISTargets] = React.useState([])

  // State for current active Tab
  const [activeTab, setActiveTab] = React.useState('1')

  const [pollTargetsLoading, setPollTargetsLoading] = React.useState(false)

  const [pollTemplate, setPollTemplate] = React.useState<any>({})
  const [selectedPlaybookIds, setSelectedPlaybookIds] = React.useState<any>([])
  const [selectedGroupIds, setSelectedGroupIds] = React.useState<any>([])
  const [selectedHour, setSelectedHour] = React.useState(2)

  const dispatch = useAppDispatch()

  React.useEffect(() => {
    const getPollTargets = async (): Promise<any> => {
      setPollTargetsLoading(true)
      var customHeaders: any = []
      customHeaders.push({ name: 'eventid', value: eventID })
      const result = await http<any>({
        path: '/playbook/PollTargets/',
        headers: customHeaders,
      })

      if (result.ok && result.body) {
        setPollTargetsLoading(false)
        if (result.body.PubTarget) {
          setPubTargets(result.body.PubTarget)
        } else {
          setPubTargets([])
        }
        if (result.body.UGISTarget) {
          setUGISTargets(result.body.UGISTarget)
        } else {
          setUGISTargets([])
        }
      } else {
        setPollTargetsLoading(false)
        setPubTargets([])
        setUGISTargets([])
      }
    }
    getPollTargets()
  }, [eventID])

  const handlePollStartSubmit = (event: any) => {
    event?.preventDefault()
    var customHeaders: any = []
    if (!pollTemplate?.PT_id) {
      dispatch(
        addNotification({
          type: 'error',
          message: 'Please select a poll template',
        })
      )
      return
    }
    if (selectedPlaybookIds.length === 0 && selectedGroupIds.length === 0) {
      dispatch(
        addNotification({
          type: 'error',
          message: 'Please choose either a playbook or group to send poll to',
        })
      )
      return
    }
    if (pollTemplate) {
      customHeaders.push({
        name: 'ptid',
        value: pollTemplate.PT_id,
      })
    }
    if (selectedHour) {
      customHeaders.push({
        name: 'hourslive',
        value: selectedHour.toString(),
      })
    }
    customHeaders.push({
      name: 'guidlist',
      value: selectedPlaybookIds.join(),
    })
    customHeaders.push({
      name: 'grouplist',
      value: selectedGroupIds.join(),
    })

    const pollStartResult = async (): Promise<any> => {
      const result = await http<any>({
        path: '/playbook/PollStart',
        headers: customHeaders,
      })
      if (result.ok && result.body) {
        return result.body
      } else {
        return ' should not see this '
      }
    }

    const doGetPSR = async () => {
      var gpsr = await pollStartResult()
      if (gpsr) {
        if (gpsr.PollStartResponse) setPollStartResult(gpsr.PollStartResponse)
        setPollStarted(true)
      }
    }
    doGetPSR()
  }

  if (pollTargetsLoading) {
    return <CircularProgress />
  }
  if (pollStarted) {
    return (
      <div>
        <h2>Poll Started</h2>
        <p
          style={{
            display: 'inline-block',
            marginLeft: '.5rem',
          }}
          dangerouslySetInnerHTML={{
            __html: pollStartResult || '',
          }}
        />
      </div>
    )
  } else if (!pollstart?.Templates) {
    //return <CircularProgress />
    return (
      <div className={classes.textCenter}>
        <h2>There are no poll templates created.</h2>
        <h3>
          Please contact your portal administrator and have them create a poll
          template.
        </h3>
      </div>
    )
  } else
    return (
      <div>
        <form onSubmit={handlePollStartSubmit}>
          <Row>
            <Col xs="12" md="6" lg="12" xl="6">
              <p>
                <b>Select Poll Template:</b>
              </p>
              {(pollstart.Templates || []).map((pst: any, pstidx: number) => (
                <div key={pst.PT_id} className={classes.pollSpacing}>
                  <input
                    type="radio"
                    id={`rbt${pstidx}`}
                    name="PTID"
                    value={pst.PT_id}
                    onChange={(e) => {
                      const TemplateId = e.currentTarget.value
                      const template = pollstart.Templates.find(
                        (template: any) => {
                          return template.PT_id === parseInt(TemplateId)
                        }
                      )
                      setPollTemplate(template)
                    }}
                  />
                  <label>{pst.PT_Name}</label>
                </div>
              ))}
            </Col>
            <Col xs="12" md="6" lg="12" xl="6">
              <Nav pills>
                <NavItem>
                  <NavLink
                    className={activeTab === '1' ? 'active' : ''}
                    onClick={() => setActiveTab('1')}
                    type="button"
                  >
                    Playbooks
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={activeTab === '2' ? 'active' : ''}
                    onClick={() => setActiveTab('2')}
                    type="button"
                  >
                    Groups
                  </NavLink>
                </NavItem>
              </Nav>
              <TabContent activeTab={activeTab}>
                <TabPane tabId="1">
                  <p className="mt-3 mb-3">
                    <b>Select Playbooks:</b>
                  </p>
                  {(pubTargets || []).map((pb: any, pbidx: number) => (
                    <div key={pb.PubGUID} className={classes.pollSpacing}>
                      <input
                        type="checkbox"
                        id={`cbp${pbidx}`}
                        name="PubGUID"
                        value={pb.PubGUID}
                        onChange={(e) => {
                          const pgID = e.currentTarget.value
                          const pgIndex = selectedPlaybookIds.findIndex(
                            (playbookId: any) => playbookId === pgID
                          )
                          if (pgIndex > -1) {
                            const playbookIds = selectedPlaybookIds.filter(
                              (playbooks: any, i: number) => i !== pgIndex
                            )
                            setSelectedPlaybookIds(playbookIds)
                          } else {
                            setSelectedPlaybookIds((playbookIds: any) => [
                              ...playbookIds,
                              pgID,
                            ])
                          }
                        }}
                      />
                      <label>{pb.PubTitle}</label>
                    </div>
                  ))}
                </TabPane>
                <TabPane tabId="2">
                  <p className="mt-3 mb-3">
                    <b>Select Groups:</b>
                  </p>
                  {(ugisTargets || []).map((pb: any, pbidx: number) => (
                    <div key={pb.GroupID} className={classes.pollSpacing}>
                      <input
                        type="checkbox"
                        id={`cbp${pbidx}`}
                        name="GroupID"
                        value={pb.GroupID}
                        onChange={(e) => {
                          const groupId = e.currentTarget.value
                          const groupIndex = selectedGroupIds.findIndex(
                            (selectedGroupId: any) =>
                              selectedGroupId === groupId
                          )
                          if (groupIndex > -1) {
                            const groupIds = selectedGroupIds.filter(
                              (playbooks: any, i: number) => i !== groupIndex
                            )
                            setSelectedGroupIds(groupIds)
                          } else {
                            setSelectedGroupIds((groupIds: any) => [
                              ...groupIds,
                              groupId,
                            ])
                          }
                        }}
                      />
                      <label>{pb.group_lcase}</label>
                    </div>
                  ))}
                </TabPane>
              </TabContent>
            </Col>
          </Row>
          <Row>
            <Col xs="12">
              <span className={classes.pollTimeLead}>
                <b>Hours until Poll is Finished:</b>
              </span>
              {(pollstart.HoursLive || []).map((hl: any, hlidx: number) => (
                <div key={hl.hr} className={classes.pollTimeSpacing}>
                  <input
                    type="radio"
                    id={`rbh${hlidx}`}
                    name="HoursLive"
                    value={hl.hr}
                    checked={selectedHour === hl.hr}
                    onChange={(e) => {
                      const selectedHours = e.currentTarget.value

                      setSelectedHour(parseInt(selectedHours))
                    }}
                  ></input>
                  <label>{hl.hr.toString()}</label>
                </div>
              ))}
            </Col>
          </Row>
          <Row>
            <Col>
              <div>
                <Button
                  className={classes.btnIcc}
                  type="submit"
                  value="Start Poll"
                >
                  Start Poll
                </Button>
              </div>
            </Col>
          </Row>
          <div className="clearfix">&nbsp;</div>
        </form>
      </div>
    )
}
