import {
  faEllipsisV,
  faFileExcel,
  faFileExport,
  faFileImport,
  faFilePlus,
  faFileUpload,
} from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  ClickAwayListener,
  MenuItem,
  MenuList,
  Paper,
  Popper,
} from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { Spacer } from '../Shared/Spacer'
import {
  canUserApproveWorkspace,
  selectTaskAllItemIds,
} from '../_rootConfigs/rootSelectors'
import { useAppSelector } from '../_utils/reactReduxHooks'
import { TaskListFilter, TaskListFilterType } from './TaskListFilter'
import classes from './TaskListToolbar.module.scss'
import { selectTaskItemById } from './_selectors'
import { TaskExportCsvDialog } from './TaskExportCsvDialog'
import { TaskUploadCsvDialog } from './TaskUploadCsvDialog'
import { taskTemplatesForOrg } from '../_utils/apiUtils'
import TaskTemplateSelectionDialog from './TaskTemplateSelectionDialog'
import TaskTemplateUploadDialog from './TaskTemplateUploadDialog'
import TaskListReassignDialog from './TaskListReassignDialog'

interface Props {
  TaskID: number
  IssueTeamWorkspaceID: number
  onFilterChange: (type: TaskListFilterType) => void
  filterValue: TaskListFilterType
  filterClassName?: string
  participants: string[]
  OrgID: number
}

export interface TaskTemplate {
  id: number
  template_name: string
  createdUTC: string
  creatorName: string
  template_json: string
}

export const TaskListToolbar = ({
  TaskID,
  onFilterChange,
  filterValue,
  filterClassName,
  IssueTeamWorkspaceID,
  participants,
  OrgID,
}: Props) => {
  const isApprover = useAppSelector((state) =>
    canUserApproveWorkspace(state, IssueTeamWorkspaceID)
  )

  const taskListItemIds =
    useAppSelector((state) => selectTaskAllItemIds(state, TaskID as any)) || []

  const taskListItems = useAppSelector((state) =>
    taskListItemIds.map((id: number) => selectTaskItemById(state, id))
  )

  const [taskToolbarDialogOpen, setTaskToolbarDialogOpen] = useState(false)

  const [taskTemplateList, setTaskTemplateList] = useState<{
    status: string
    templates: TaskTemplate[]
  }>({
    status: 'FAILED',
    templates: [],
  })

  const [taskListExportDialogOpen, setTaskListExportDialogOpen] =
    useState(false)

  const [taskListUploadDialogOpen, setTaskListUploadDialogOpen] =
    useState(false)

  const [taskTemplateUploadDialogOpen, setTaskTemplateUploadDialogOpen] =
    useState(false)

  const [taskTemplateSelectionDialogOpen, setTaskTemplateSelectionDialogOpen] =
    useState(false)

  const [taskListMassReassignDialogOpen, setTaskListMassReassignDialogOpen] =
    useState(false)

  const anchorRef = React.useRef(null)

  const openToolbarDialog = (event: any) => {
    anchorRef.current = event?.target
    setTaskToolbarDialogOpen(true)
  }

  const handleClose = () => {
    setTaskToolbarDialogOpen(false)
  }

  useEffect(() => {
    if (OrgID) {
      taskTemplatesForOrg(OrgID).then((data) => {
        setTaskTemplateList({
          status: data.status,
          templates: data.templates,
        })
      })
    }
  }, [TaskID, OrgID, taskTemplateSelectionDialogOpen])

  const isOrgAdmin = taskTemplateList.status === 'SUCCESS'

  return (
    <div className={classes.root}>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'right',
          width: '100%',
        }}
      >
        <TaskListFilter
          taskListItems={taskListItems}
          className={filterClassName}
          onChange={onFilterChange}
          taskFilter={filterValue}
          participants={participants}
          orgId={OrgID}
        />
        <Spacer spaceParam={'large'} />
        {isApprover && (
          <FontAwesomeIcon
            icon={faEllipsisV}
            onClick={openToolbarDialog}
            cursor={'pointer'}
            size="xl"
            style={{ color: '#186FAF' }}
          />
        )}
        <Popper
          open={taskToolbarDialogOpen}
          anchorEl={anchorRef.current}
          placement={'bottom-end'}
          transition
        >
          <Paper style={{ padding: '10px' }}>
            <ClickAwayListener onClickAway={handleClose}>
              <MenuList
                id="composition-menu"
                aria-labelledby="composition-button"
              >
                <MenuItem
                  onClick={(event) => {
                    setTaskListUploadDialogOpen(true)
                    handleClose()
                  }}
                  className={classes.popperMenuItem}
                >
                  <div
                    style={{ paddingRight: '6px' }}
                    className={classes.popperMenuIconContainer}
                  >
                    <FontAwesomeIcon
                      icon={faFileImport}
                      className={classes.toolbarDialogIcon}
                    />
                  </div>

                  <Spacer />
                  <div>Upload a Task List</div>
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    setTaskListExportDialogOpen(true)
                    handleClose()
                  }}
                >
                  <div
                    style={{ paddingLeft: '4px' }}
                    className={classes.popperMenuIconContainer}
                  >
                    <FontAwesomeIcon
                      icon={faFileExport}
                      className={classes.toolbarDialogIcon}
                    />
                  </div>
                  <Spacer />
                  Export a Task List as CSV
                </MenuItem>
                {isOrgAdmin && (
                  <MenuItem
                    onClick={() => {
                      setTaskTemplateUploadDialogOpen(true)
                      handleClose()
                    }}
                  >
                    <div className={classes.popperMenuIconContainer}>
                      <FontAwesomeIcon
                        icon={faFilePlus}
                        className={classes.toolbarDialogIcon}
                      />
                    </div>
                    <Spacer />
                    Save Task List as Template
                  </MenuItem>
                )}
                {isOrgAdmin && (
                  <MenuItem
                    onClick={() => {
                      setTaskTemplateSelectionDialogOpen(true)
                      handleClose()
                    }}
                  >
                    <div className={classes.popperMenuIconContainer}>
                      <FontAwesomeIcon
                        icon={faFileUpload}
                        className={classes.toolbarDialogIcon}
                      />
                    </div>
                    <Spacer />
                    Load Task List From Template
                  </MenuItem>
                )}
                <MenuItem
                  onClick={() => {
                    setTaskListMassReassignDialogOpen(true)
                    handleClose()
                  }}
                >
                  <div className={classes.popperMenuIconContainer}>
                    <FontAwesomeIcon
                      icon={faFileExcel}
                      className={classes.toolbarDialogIcon}
                    />
                  </div>
                  <Spacer />
                  Reassign Task Items
                </MenuItem>
              </MenuList>
            </ClickAwayListener>
          </Paper>
        </Popper>
        <TaskListReassignDialog
          open={taskListMassReassignDialogOpen}
          issueTeamWorkspaceId={IssueTeamWorkspaceID}
          taskId={TaskID}
          onClose={() => setTaskListMassReassignDialogOpen(false)}
        />
        <TaskExportCsvDialog
          open={taskListExportDialogOpen}
          TaskID={TaskID}
          onClose={() => setTaskListExportDialogOpen(false)}
        />
        <TaskUploadCsvDialog
          open={taskListUploadDialogOpen}
          TaskID={TaskID}
          onClose={() => setTaskListUploadDialogOpen(false)}
        />
        <TaskTemplateSelectionDialog
          open={taskTemplateSelectionDialogOpen}
          taskTemplateList={taskTemplateList}
          taskId={TaskID}
          orgId={OrgID}
          onClose={() => setTaskTemplateSelectionDialogOpen(false)}
        />
        <TaskTemplateUploadDialog
          open={taskTemplateUploadDialogOpen}
          taskId={TaskID}
          orgId={OrgID}
          onClose={() => setTaskTemplateUploadDialogOpen(false)}
        />
      </div>
    </div>
  )
}
