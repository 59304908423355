import * as React from 'react'
import { Bar, BarChart, ResponsiveContainer, XAxis, YAxis } from 'recharts'
import { Spacer } from '../../Shared/Spacer'
import {
  BarData,
  DashboardIssueModel,
  DashboardSeverityModel,
} from './DashboardModels'
import classes from './DashboardIssuesSection.module.scss'

export const DashboardResolvedIssuesSection = ({
  resolvedIssues,
  severityPicklist,
}: {
  resolvedIssues?: DashboardIssueModel[]
  severityPicklist: DashboardSeverityModel[]
}) => {
  if (!resolvedIssues) {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
        }}
      >
        No resolved issues
      </div>
    )
  }

  let barData: BarData[] = severityPicklist.map((severity) => {
    const combinedIssuesBySeverity = resolvedIssues.filter(
      (issue) => issue.SeverityLevelName === severity.SeverityLevelName
    )

    return {
      name: combinedIssuesBySeverity[0]?.SeverityLevelName,
      issueCount: combinedIssuesBySeverity.length,
      color: combinedIssuesBySeverity[0]?.SeverityLevelRGB,
    }
  })

  const severityCountMap: any = {}

  // Iterate through the array and accumulate counts for each severity name
  barData.forEach((item) => {
    const { name, issueCount } = item

    // If the severity name is not already a key, initialize it with the current count
    // Otherwise, add the count to the existing value
    if (name)
      severityCountMap[name] = (severityCountMap[name] || 0) + issueCount
  })

  barData = barData.filter((data) => data.name)

  const resolvedIssuesSection = (
    <>
      <div style={{ fontSize: '32px', fontWeight: '500' }}>
        {resolvedIssues?.length}
      </div>
      <Spacer />
      <ResponsiveContainer width="70%" height={'20%'}>
        <BarChart
          data={[severityCountMap]}
          stackOffset={'expand'}
          layout="vertical"
        >
          <XAxis type="number" hide />
          <YAxis type="category" hide />
          {barData.map((data, index) => {
            return (
              <Bar
                isAnimationActive={false}
                key={index}
                dataKey={`${data.name}`}
                stackId="a"
                fill={data.color}
              />
            )
          })}
        </BarChart>
      </ResponsiveContainer>
      <Spacer />
      <div style={{ display: 'flex' }}>
        {severityPicklist.map((picklistItem, index) => {
          const picklistItemExists = barData.find((data) => {
            return data.name === picklistItem.SeverityLevelName
          })

          if (!picklistItemExists) return false

          return (
            <div className={classes.severity} key={index}>
              <span
                className={classes.severityColor}
                style={{ backgroundColor: picklistItem.SeverityLevelRGB }}
              ></span>
              <span style={{ display: 'flex' }}>
                {picklistItem.SeverityLevelName}{' '}
                {severityCountMap[picklistItem.SeverityLevelName]}
              </span>
            </div>
          )
        })}
      </div>
    </>
  )

  return (
    <div
      style={{
        position: 'relative',
        display: 'flex',
        width: '100%',
        height: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
      }}
    >
      {resolvedIssues.length > 0 ? (
        resolvedIssuesSection
      ) : (
        <div>There are no resolved issues</div>
      )}
    </div>
  )
}
