// @ts-nocheck
// TODO: Typescript
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { selectMSChannelNameById } from './_selectors'

/**
 * @extends {Component<Props, {}>}}
 * Renders an option for a MS Channel
 */
export class MsChannelOption extends Component {
  render() {
    const { channelId, channelName } = this.props
    return <option value={channelId}>{channelName}</option>
  }
}

MsChannelOption.propTypes = {
  channelId: PropTypes.string,
  channelName: PropTypes.string,
}

MsChannelOption.defaultProps = {
  channelIds: [],
}

const mapStateToProps = (state, ownProps) => {
  const { channelId } = ownProps

  return {
    channelId,
    channelName: selectMSChannelNameById(state, channelId),
  }
}

export default connect(mapStateToProps, undefined)(MsChannelOption)
