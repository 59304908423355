// @ts-nocheck
// TODO: Typescript
import React, { Component } from 'react'

var delay = 0
var allResizes = []
var timeoutId = 0

function resizeHandler() {
  clearTimeout(timeoutId)
  timeoutId = setTimeout(() => {
    allResizes.forEach((component) => {
      component.setState({
        height: component._parentEl.offsetHeight,
        width: component._parentEl.offsetWidth,
      })
    })
  }, delay)
}

export class WindowResize extends Component {
  constructor(props) {
    super(props)
    this._parentEl = undefined
    this.state = {
      height: 0,
      width: 0,
    }
  }

  componentDidMount() {
    this._parentEl = this._rootEl.parentElement
    if (!allResizes.length) {
      window.addEventListener('resize', resizeHandler)
    }

    this.setState({
      height: this._parentEl.offsetHeight,
      width: this._parentEl.offsetWidth,
    })

    setTimeout(() => {
      allResizes.push(this)
    }, 100)
  }

  render() {
    return (
      <div ref={(el) => (this._rootEl = el)} style={{ position: 'relative' }}>
        {this.props.children(this.state.height, this.state.width)}
      </div>
    )
  }

  componentWillUnmount() {
    var index = allResizes.indexOf(this)
    allResizes.splice(index, 1)

    if (!allResizes.length) {
      window.removeEventListener('resize', resizeHandler)
      clearTimeout(timeoutId)
    }
  }
}
