import { createSelector } from 'reselect'
import {
  createFilteredListSelector,
  FilterHashOrParams,
  isAllListItemsLoaded,
  selectListByIdMap,
  selectListFilterParams,
} from '../ActivityLog/_reducer'
import {
  bindSel,
  getIssueLatestLog,
  getIssue,
  getIssueApp,
  getWorkspaceIssue,
  getIssueActivityLog,
  getEvidenceLockerLinks,
  getGeolocations,
} from '../_rootConfigs/rootReducerSlicers'
import { RootState } from '../_store'
import * as priv from './_reducer'

// Issue
export const selectIssuePathId = bindSel(getIssue, priv.selectIssuePathId)
export const selectCurrentIssueId = (state: RootState, ownProps: any) =>
  selectIssuePathId(state, ownProps) || selectDefaultIssueId(state)
export const selectCurrentIssue = (state: RootState, ownProps: any) =>
  selectIssueById(state, selectCurrentIssueId(state, ownProps))
export const selectIssuesByIdMap = bindSel(getIssue, priv.selectIssuesByIdMap)
export const selectIssueById = bindSel(getIssue, priv.selectIssueById)
export const selectIssueOrgID = bindSel(getIssue, priv.selectIssueOrgID)
export const selectIssueWorkspaceIds = bindSel(
  getIssue,
  priv.selectIssueWorkspaceIds
)
export const selectIssueWorkspaceCount = bindSel(
  getIssue,
  priv.selectIssueWorkspaceCount
)
export const selectCurrentHoldingStatementIds = (
  state: RootState,
  ownProps: any
) =>
  selectIssueHoldingStatementIds(state, selectCurrentIssueId(state, ownProps))
export const selectCurrentStakeHolderIds = (state: RootState, ownProps: any) =>
  selectIssueAllStakeholderIds(state, selectCurrentIssueId(state, ownProps))
export const selectCurrentWorkspaceIds = (state: RootState, ownProps: any) =>
  selectIssueWorkspaceIds(state, selectCurrentIssueId(state, ownProps))
export const selectCurrentIssueLevelIds = (state: RootState, ownProps: any) =>
  selectIssuesAllLevelIds(state, selectCurrentIssueId(state, ownProps))
export const selectIssuesAllLevelIds = bindSel(
  getIssue,
  priv.selectIssuesAllLevelIds
)
export const selectIssuesAllTypeIds = bindSel(
  getIssue,
  priv.selectIssuesAllTypeIds
)
export const selectIssueAllStakeholderIds = bindSel(
  getIssue,
  priv.selectIssueStakeholderIds
)
export const selectIssueHoldingStatementIds = bindSel(
  getIssue,
  priv.selectIssueHoldingStatementIds
)
export const selectIssueAllStakeholderCount = bindSel(
  getIssue,
  priv.selectIssueStakeholderCount
)
export const selectIssueTeamReportIds = bindSel(
  getIssue,
  priv.selectIssueTeamReportIds
)
export const selectIssueTeamReportCount = bindSel(
  getIssue,
  priv.selectIssueTeamReportCount
)
export const selectIssueEvidenceLockerLinkIds = bindSel(
  getIssue,
  priv.selectIssueEvidenceLockerLinkIds
)
export const selectIssueEvidenceLockerLinksCount = bindSel(
  getIssue,
  priv.selectIssueEvidenceLockerLinksCount
)
export const selectEvidenceLockerLinkIDMap = bindSel(
  getEvidenceLockerLinks,
  priv.selectEvidenceLockerLinkIDMap
)
export const selectEvidenceLockerLinkById = bindSel(
  getEvidenceLockerLinks,
  priv.selectEvidenceLockerLinkById
)
export const selectGeolocationIds = bindSel(
  getIssue,
  priv.selectIssueGeolocationIds
)
export const selectGeolocationIDMap = bindSel(
  getGeolocations,
  priv.selectGeolocationIDMap
)
export const selectGeolocationById = bindSel(
  getGeolocations,
  priv.selectGeolocationById
)
export const selectGeolocationsArray = createSelector(
  (state: RootState, IssueID: number) => selectGeolocationIds(state, IssueID),
  selectGeolocationIDMap,
  (ids: Array<number>, idMap: priv.GeolocationState) => {
    return ids?.map((id: any) => idMap[id]) || []
  }
)
export const selectIssueTrendId = bindSel(getIssue, priv.selectIssueTrendId)
export const selectIssueTagIds = bindSel(getIssue, priv.selectIssueTagIds)
export const selectIssuesCount = bindSel(getIssue, priv.selectIssuesCount)
export const selectAllUnresolvedIssues = bindSel(
  getIssue,
  priv.selectAllUnresolvedIssues
)

// Issue Activity Log
export const selectIssueActivityLogRead = bindSel(
  getIssueLatestLog,
  priv.selectIssueActivityLogRead
)
export const selectIssueActivityLogLatest = bindSel(
  getIssueLatestLog,
  priv.selectIssueActivityLogLatest
)
export const hasNewIssueActivityLog = bindSel(
  getIssueLatestLog,
  priv.hasNewIssueActivityLog
)

export const selectIssueActivityLogIdMap = (
  state: RootState,
  issueID: number,
  params?: FilterHashOrParams
) => selectListByIdMap(getIssueActivityLog(state), issueID, params)
export const selectIssueActivityLog = bindSel(
  getIssueActivityLog,
  createFilteredListSelector((item: any, params: any) => {
    return (
      !params?.category ||
      !params?.category?.length ||
      params?.category.indexOf(item.CategoryName) > -1
    )
  })
)
export const isIssueActivityLogLoaded = (
  state: RootState,
  issueID: number,
  params?: FilterHashOrParams
) => isAllListItemsLoaded(getIssueActivityLog(state), issueID, params)
export const selectIssueActivityLogCategory = bindSel(
  getIssueActivityLog,
  selectListFilterParams
)

// Issue App
export const selectIssueFilter = bindSel(getIssueApp, priv.selectIssueFilter)
export const selectIssueFilterField = bindSel(
  getIssueApp,
  priv.selectIssueFilterField
)
export const selectIssueFilterKeys = bindSel(
  getIssueApp,
  priv.selectIssueFilterKeys
)
export const selectFilterIssueModeType = bindSel(
  getIssueApp,
  priv.selectFilterIssueModeType
)
export const selectIssuesSortBy = bindSel(getIssueApp, priv.selectIssuesSortBy)
export const selectIssuesSortDir = bindSel(
  getIssueApp,
  priv.selectIssuesSortDir
)
export const selectOrgIssueLevels = bindSel(
  getIssueApp,
  priv.selectOrgIssueLevels
)
export const selectOrgIssueLevelById = bindSel(
  getIssueApp,
  priv.selectOrgIssueLevelById
)
export const doesOrgIssueLevelExist = bindSel(
  getIssueApp,
  priv.doesOrgIssueLevelExist
)
export const selectOrgIssueLevelNameById = bindSel(
  getIssueApp,
  priv.selectOrgIssueLevelNameById
)
export const isOrgIssueLevelSelected = bindSel(
  getIssueApp,
  priv.isOrgIssueLevelSelected
)
export const selectOrgIssueTypes = bindSel(
  getIssueApp,
  priv.selectOrgIssueTypes
)
export const selectOrgIssueTypeById = bindSel(
  getIssueApp,
  priv.selectOrgIssueTypeById
)
export const selectLocationWorkspaceId = bindSel(
  getIssueApp,
  priv.selectLocationWorkspaceId
)
export const selectLocationIssueId = bindSel(
  getIssueApp,
  priv.selectLocationIssueId
)
const isIssueBeingCreatedPriv = bindSel(getIssueApp, priv.isIssueBeingCreated)
export const isIssueBeingCreated = (state: RootState, issueId: number) =>
  isIssueBeingCreatedPriv(state, issueId) && !selectIssueById(state, issueId)
export const selectUserFullName = bindSel(getIssueApp, priv.selectUserFullName)
export const selectUserOrgInfo = bindSel(getIssueApp, priv.selectUserOrgInfo)
export const selectUserEmail = bindSel(getIssueApp, priv.selectUserEmail)
export const selectIssueStageTab = bindSel(
  getIssueApp,
  priv.selectIssueStageTab
)
export const selectDnrEmail = bindSel(getIssueApp, priv.selectDnrEmail)
export const selectSiteName = bindSel(getIssueApp, priv.selectSiteName)
export const selectPortalSite = bindSel(getIssueApp, priv.selectPortalSite)
export const selectFormEditorSite = bindSel(
  getIssueApp,
  priv.selectFormEditorSite
)
export const selectPbAppSite = bindSel(getIssueApp, priv.selectPbAppSite)
export const selectSendNotificationsSite = bindSel(
  getIssueApp,
  priv.selectSendNotificationsSite
)

export const selectEnvironment = bindSel(getIssueApp, priv.selectEnvironment)
export const selectBuildInformation = bindSel(
  getIssueApp,
  priv.selectBuildInformation
)
export const selectBuildCreated = bindSel(getIssueApp, priv.selectBuildCreated)
export const selectAppVersion = bindSel(getIssueApp, priv.selectAppVersion)
export const selectGitBranch = bindSel(getIssueApp, priv.selectGitBranch)
export const selectGitName = bindSel(getIssueApp, priv.selectGitName)
export const selectGitEmail = bindSel(getIssueApp, priv.selectGitEmail)
export const selectSMSPromptNeeded = bindSel(
  getIssueApp,
  priv.selectSMSPromptNeeded
)
export const selectMobilePromptDelay = bindSel(
  getIssueApp,
  priv.selectMobilePromptDelay
)
export const selectIsDevelopment = bindSel(
  getIssueApp,
  priv.selectIsDevelopment
)
export const selectDefaultIssueId = bindSel(
  getIssueApp,
  priv.selectDefaultIssueId
)
export const isStartDateFilterApplied = bindSel(
  getIssueApp,
  priv.isStartDateFilterApplied
)
export const isEndDateFilterApplied = bindSel(
  getIssueApp,
  priv.isEndDateFilterApplied
)
export const isTagsFilterApplied = bindSel(
  getIssueApp,
  priv.isTagsFilterApplied
)

export const isOrgFilterApplied = bindSel(getIssueApp, priv.isOrgFilterApplied)

export const isOrgIssueLevelFilterApplied = bindSel(
  getIssueApp,
  priv.isOrgIssueLevelFilterApplied
)
export const isIssueModeTypeFilterApplied = bindSel(
  getIssueApp,
  priv.isIssueModeTypeFilterApplied
)
export const isIncludeResolvedFilterApplied = bindSel(
  getIssueApp,
  priv.isIncludeResolvedFilterApplied
)
export const selectIssueActivityLogChangeDate = bindSel(
  getIssueApp,
  priv.selectIssueActivityLogChangeDate
)
export const selectFilterDefaultIncludeResolved = bindSel(
  getIssueApp,
  priv.selectFilterDefaultIncludeResolved
)
export const selectAlertSeverityColors = bindSel(
  getIssueApp,
  priv.selectAlertSeverityColors
)

// IssueWorkspace
export const selectWorkspaceIssueId = bindSel(
  getWorkspaceIssue,
  priv.selectWorkspaceIssueId
)
export const selectWorkspaceIssue = (state: RootState, workspaceId: number) =>
  selectIssueById(state, selectWorkspaceIssueId(state, workspaceId))
