// @ts-nocheck
// TODO: Typescript
import React, { Component } from 'react'
import * as microsoftTeams from '@microsoft/teams-js'
import { loginADSuccess } from '../MicrosoftTeams/_actions'
import { connect } from 'react-redux'

/**
 * @extends {Component<Props, {}>}}
 * Close the AD Popup
 */
export class AzureADSuccess extends Component {
  componentDidMount() {
    try {
      if (this.props.loginADSuccess) this.props.loginADSuccess()

      microsoftTeams.authentication.notifySuccess({
        message: 'Success tokens are saved on the server',
      })
    } catch (ex) {
      console.log(ex)
    }
  }

  render() {
    return <div>Pop up closing</div>
  }
}

const mapDispatchToProps = {
  loginADSuccess: loginADSuccess,
}

export default connect(undefined, mapDispatchToProps)(AzureADSuccess)
