import { Tooltip as MUITooltip, TooltipProps } from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  IconDefinition,
  faQuestionCircle,
} from '@fortawesome/pro-light-svg-icons'
import classes from './Tooltip.module.scss'
import { ReactElement } from 'react'

interface Props extends Omit<TooltipProps, 'children'> {
  icon?: IconDefinition
  hideIcon?: boolean
  children?: ReactElement<any, any>
  tooltipDisabled?: boolean
}
export const Tooltip = (props: Props) => {
  if (props.tooltipDisabled && props.hideIcon && props.children)
    return props.children
  return (
    <MUITooltip placement="right" title={props.title}>
      {props.hideIcon && props.children ? (
        <span>{props.children}</span>
      ) : (
        <span
          className={classes.tooltipIconWrapper}
          style={{ ...props.style, display: 'flex', alignItems: 'center' }}
        >
          <FontAwesomeIcon
            style={{
              verticalAlign: 'baseline',
            }}
            icon={props.icon || faQuestionCircle}
          />
        </span>
      )}
    </MUITooltip>
  )
}
