// @ts-nocheck
// TODO: Typescript
import { AppConstants, SAVE_APP_HEADER_TYPE_PATHS } from './_constants'
import { NotyMessage } from './_models'
import { AppHeaderType } from './_types'

export function saveAppHeaderTypePath(headerType: AppHeaderType) {
  headerType = headerType || {}
  return {
    type: SAVE_APP_HEADER_TYPE_PATHS,
    payload: {
      type: headerType.type,
      path: headerType.path,
      state: headerType.state,
    },
  }
}

export function fetchInitialState() {
  return { type: AppConstants.FETCH_INIT.REQUEST }
}

export function appLoading() {
  return { type: AppConstants.FETCH_INIT.LOADING }
}

export function appLoaded(initialState, isAuthenticated) {
  return {
    type: AppConstants.FETCH_INIT.COMPLETE,
    initialState,
    isAuthenticated,
  }
}

export function appLoadedDelay(initialState, isAuthenticated, delay) {
  return {
    type: AppConstants.FETCH_INIT_DELAY,
    initialState,
    isAuthenticated,
    delay,
  }
}

export function appDataLoadedFailure() {
  return {
    type: AppConstants.FETCH_INIT.FAILURE,
  }
}

export function displayCurrentNotification() {
  return { type: AppConstants.DISPLAY_CURRENT_NOTIFICATION }
}

const defaultNotyOpts = { duration: 3000, hasCloseBtn: true, type: 'error' }

export function addNotification(notification: NotyMessage) {
  return {
    type: AppConstants.ADD_NOTIFICATION,
    notification: { ...defaultNotyOpts, ...notification },
  }
}

export function closeNotification() {
  return { type: AppConstants.CLOSE_NOTIFICATION }
}
