import { bindSelRet, getIssueLevel } from '../_rootConfigs/rootReducerSlicers'
import { selectEntityByNumId, selectEntityIdMap } from '../_schema/_reducer'
import { IssueLevelModel, IssueLevelState } from './_models'

// ISSUELEVEL SELECTORS
export const selectIssueLevelById = bindSelRet(
  getIssueLevel,
  selectEntityByNumId
)<IssueLevelModel>()
export const selectIssueLevelsByIdMap = bindSelRet(
  getIssueLevel,
  selectEntityIdMap
)<IssueLevelState>()
