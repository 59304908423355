// @ts-nocheck
// TODO: Typescript
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
  FormControl,
  Input,
  InputLabel,
  FormGroup,
  Select,
  FormControlLabel,
} from '@material-ui/core'
import {
  filesToBase64,
  getBase64Src,
  getFilenameExt,
} from '../_utils/fileUtils'
import { UploadThumbnail } from './UploadThumbnail'
import { connect } from 'react-redux'
import MsTeamOptionGroup from '../MicrosoftTeams/MsTeamOptionGroup'
import Checkbox from '../CustomMaterial/Checkbox'
import { selectMSTeamsGroupId } from '../MicrosoftTeams/_reducer'
import { selectMediaTypeByExtension } from '../_selectors'
import {
  selectJoinedMSTeamsIds,
  selectMSChannelIdsByTeamId,
  selectMSTeamsChannelId,
} from '../MicrosoftTeams/_selectors'
import { isAzureADLogin } from '../Auth/_selectors'

/**
 * @extends {Component<Props, {}>}}
 * The form to use for uploading
 */
export class UploadForm extends Component {
  constructor(props) {
    super(props)

    const { isAzureADLogin, defaultMsTeamsChannelId } = props

    this.state = {
      Description: '',
      previewBase64: '',
      channelId: isAzureADLogin ? defaultMsTeamsChannelId : '',
      isMicrosoftStorage: false,
    }
    this.handleChange = this.handleChange.bind(this)
    this.onChangeAfterUpdate = () =>
      this.props.onChange({
        Description: this.state.Description,
        isMicrosoftStorage: this.state.isMicrosoftStorage,
        channelId: this.state.channelId,
      })
    this.handleChannelChange = this.handleChannelChange.bind(this)
    this.toggleStorageProvider = this.toggleStorageProvider.bind(this)
  }

  componentDidMount() {
    this.onChangeAfterUpdate()
    this.updatePreview()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.file !== this.props.file) this.updatePreview()
  }

  handleChannelChange(evt) {
    this.setState(
      {
        channelId: evt.target.value,
      },
      this.onChangeAfterUpdate
    )
  }

  toggleStorageProvider() {
    const isMicrosoftStorage = !this.state.isMicrosoftStorage
    const channelId = isMicrosoftStorage ? this.state.channelId : ''
    this.setState({ isMicrosoftStorage, channelId }, this.onChangeAfterUpdate)
  }

  handleChange(evt) {
    const { name, value } = evt.target
    this.setState(
      {
        [name]: value,
      },
      this.onChangeAfterUpdate
    )
  }

  updatePreview() {
    const { file } = this.props
    if (file) {
      filesToBase64(file, (loadedFiles) => {
        this.setState({
          previewBase64: !loadedFiles[0] ? '' : getBase64Src(loadedFiles[0]),
        })
      })
    }
  }

  render() {
    const file = this.props.file || {}
    const { ext, mediaType, msTeamIds, isAzureADLogin } = this.props
    return (
      <div>
        <FormGroup className="p-1">
          <FormControl>
            <InputLabel>Name</InputLabel>
            <Input
              name="Description"
              type="text"
              onChange={this.handleChange}
              value={this.state.Description}
            />
          </FormControl>
        </FormGroup>

        {isAzureADLogin && (
          <FormGroup className="p-1">
            <FormControlLabel
              control={
                <Checkbox
                  onChange={this.toggleStorageProvider}
                  checked={this.state.isMicrosoftStorage}
                />
              }
              label="Upload to Microsoft Teams"
            />
            {this.state.isMicrosoftStorage && (
              <FormControl>
                <InputLabel>Destination Channel</InputLabel>
                <Select
                  native={true}
                  value={this.state.channelId}
                  onChange={this.handleChannelChange}
                >
                  {msTeamIds.map((id) => (
                    <MsTeamOptionGroup key={id} teamId={id} />
                  ))}
                </Select>
              </FormControl>
            )}
          </FormGroup>
        )}

        <FormGroup className="p-2 text-center">
          <div
            style={{
              maxWidth: '100%',
              textAlign: 'center',
            }}
          >
            <div>{file.name}</div>
            <UploadThumbnail
              content={this.state.previewBase64}
              type={mediaType}
              ArtifactType={file.type}
              ext={ext}
              alt="Preview Here"
              style={{ maxWidth: '100%' }}
            />
          </div>
        </FormGroup>
      </div>
    )
  }
}

UploadForm.propTypes = {
  onChange: PropTypes.func,
  file: PropTypes.instanceOf(File),
  msTeamIds: PropTypes.arrayOf(PropTypes.string),
}

UploadForm.defaultProps = {
  onChange: () => {},
  msTeamIds: [],
}

const mapStateToProps = (state, ownProps) => {
  const ext = getFilenameExt((ownProps.file || '').name)
  const mediaType = selectMediaTypeByExtension(state, ext)
  const msTeamIds = selectJoinedMSTeamsIds(state) || []
  const firstTeamsChannels =
    selectMSChannelIdsByTeamId(state, msTeamIds[0] || '') || []
  return {
    ext,
    mediaType,
    msTeamIds,
    isAzureADLogin: isAzureADLogin(state),
    defaultMsTeamsGroupId: selectMSTeamsGroupId(state),
    defaultMsTeamsChannelId:
      selectMSTeamsChannelId(state) || firstTeamsChannels[0] || '',
  }
}

export default connect(mapStateToProps, undefined)(UploadForm)
