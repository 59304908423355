// @ts-nocheck
// TODO: Typescript
import React, { Component } from 'react'
import { CircularProgress } from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faFileWord,
  faFile,
  faFileExcel,
  faFilePowerpoint,
  faFileVideo,
  faFileAudio,
  faFilePdf,
  faFileCsv,
  faImage,
} from '@fortawesome/free-solid-svg-icons'

import PropTypes from 'prop-types'
import classes from './UploadThumbnail.module.scss'
import { cx } from '../_utils/objectUtils'

function getMimeThumbnail(mimeType, props) {
  const ext = (props.ext || '').toLocaleLowerCase()
  let { onClick, style, width, height, className } = props
  style = {
    ...style,
  }
  if (width) style.width = width
  if (height) style.height = height
  const excelIcon = (
    <FontAwesomeIcon
      className={className}
      onClick={onClick}
      size="6x"
      icon={faFileExcel}
      style={style}
    />
  )
  const wordDocIcon = (
    <FontAwesomeIcon
      size="6x"
      className={className}
      onClick={onClick}
      icon={faFileWord}
      style={style}
    />
  )
  const pdfIcon = (
    <FontAwesomeIcon
      className={cx(classes.pdf, className)}
      size="6x"
      onClick={onClick}
      icon={faFilePdf}
      style={style}
    />
  )
  const powerPointIcon = (
    <FontAwesomeIcon
      className={className}
      size="6x"
      onClick={onClick}
      icon={faFilePowerpoint}
      style={style}
    />
  )
  const fileThumbnail = {
    svg: (
      <FontAwesomeIcon
        className={className}
        size="6x"
        onClick={onClick}
        icon={faImage}
        style={style}
      />
    ),
    doc: wordDocIcon,
    docx: wordDocIcon,
    'text/html': null,
    'application/vnd.rar': null,
    'application/zip': null,
    xls: excelIcon,
    xlsx: excelIcon,
    pptx: powerPointIcon,
    ppt: powerPointIcon,
    pdf: pdfIcon,
    csv: (
      <FontAwesomeIcon
        className={className}
        size="6x"
        onClick={onClick}
        icon={faFileCsv}
        style={style}
      />
    ),
  }

  var result = fileThumbnail[ext] || null

  if (!result) {
    if (mimeType === 'audio') {
      result = (
        <FontAwesomeIcon
          className={className}
          size="6x"
          onClick={onClick}
          icon={faFileAudio}
          style={style}
        />
      )
    } else if (mimeType === 'video') {
      result = (
        <FontAwesomeIcon
          className={className}
          size="6x"
          onClick={onClick}
          icon={faFileVideo}
          style={style}
        />
      )
    } else
      result = (
        <FontAwesomeIcon
          className={className}
          size="6x"
          onClick={onClick}
          icon={faFile}
          style={style}
        />
      )
  }

  return result
}

/**
 * @extends {Component<Props, {}>}}
 * Renders a thumbnail based on the type of upload
 */
export class UploadThumbnail extends Component {
  render() {
    const {
      content,
      width,
      height,
      style,
      alt,
      type,
      ext,
      onClick,
      isContentLoading,
      ArtifactType,
    } = this.props
    let result = null
    if (type === 'image') {
      if (isContentLoading) {
        const holderWidth = width || 100
        const holderHeight = height || 100
        result = (
          <div
            className={classes.contentHolder}
            onClick={onClick}
            style={{
              width: holderWidth,
              height: holderHeight,
              ...style,
            }}
          >
            <CircularProgress color="primary" disableShrink={true} />
          </div>
        )
      } else if (content) {
        let mimeType = ArtifactType
        if (!mimeType) {
          if (['ico', 'cur'].indexOf(ext) > -1) mimeType = 'image/x-icon'
          else if (['jpg', 'jpeg', 'jfif', 'pjpeg', 'pjp'].indexOf(ext) > -1)
            mimeType = 'image/jpeg'
          else if (['tif', 'tiff'].indexOf(ext) > -1) mimeType = 'image/tiff'
          else if (ext === 'svg') mimeType = 'image/svg+xml'
          else {
            mimeType = `${type}/${ext}`
          }
        }
        const typePrefix = `data:${mimeType};base64,`
        result = (
          <img
            src={
              content.startsWith(typePrefix) ? content : typePrefix + content
            }
            alt={alt}
            onClick={onClick}
            width={width || 'auto'}
            height={height || 'auto'}
            style={style}
          />
        )
      } else result = getMimeThumbnail(type, this.props)
    } else result = getMimeThumbnail(type, this.props)

    return result
  }
}

UploadThumbnail.propTypes = {
  content: PropTypes.any,
  width: PropTypes.number,
  height: PropTypes.number,
  alt: PropTypes.string,
  type: PropTypes.string,
  ext: PropTypes.string,
  ArtifactType: PropTypes.string,
  isContentLoading: PropTypes.bool,
  style: PropTypes.any,
  onClick: PropTypes.func,
}

UploadThumbnail.defaultProps = {
  content: '',
  type: '',
}
