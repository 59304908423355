import * as React from 'react'
import { ReactNode } from 'react'

interface Props {
  children?: ReactNode
  color?: 'primary' | 'secondary'
  className?: string
}

/**
 * Displays the current view the user is in
 * @param {any} props
 */
export const ViewHeader = ({ children, color, className }: Props) => {
  let style: any = {
    width: '100%',
    padding: 10,
    fontWeight: 'bold',
    fontSize: 20,
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    //borderBottomStyle: 'solid',
    //borderWidth: 1,
    //borderColor: 'lightgray',
    position: 'relative',
  }

  if (color === 'primary') {
    style.background = '#3e91c5'
    style.color = 'white'
    style.borderBottomStyle = 'none'
  }

  return (
    <div className={className} style={style}>
      {children}
    </div>
  )
}
