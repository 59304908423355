import {
  faArrowDown,
  faArrowUp,
  faPlus,
  faTimes,
  faVideo,
} from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  Button,
  Input,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core'
import React from 'react'
import { useDispatch } from 'react-redux'
import { Table } from 'reactstrap'

import { Organization } from '../Issue/_reducer'
import { AdminConsoleDialog } from '../Shared/AdminConsoleDialog'
import { SearchInput } from '../Shared/SearchInput'
import { Spacer } from '../Shared/Spacer'
import { addNotification } from '../_actions'
import { appDateTimeFormat } from '../_constants'
import {
  createOrgMeetingLink,
  deleteOrgMeetingLink,
  orgMeetingLinks,
  updateOrgMeetingLink,
} from '../_utils/apiUtils'
import { getLocalDate } from '../_utils/dateUtils'
import { StyledTableRow } from './AdminConsolePage'
import classes from './AdminConsolePage.module.scss'
import colorDefinitions from '../ThemeProvider/ColorDefinitions.module.scss'
import dayjs from 'dayjs'
import { useAppSelector } from '../_utils/reactReduxHooks'
import { isMobileApp } from '../_selectors'

type TableHeader = 'Description' | 'Link'
type SortDirection = 'asc' | 'dec' | 'none'
export interface TableSort {
  header: TableHeader
  sortDirection: SortDirection
}

export interface OrgMeetingLink {
  id: number
  OrgID: number
  Description: string
  Link: string
  CreatorName: string
  CreatorEmail: string
  CreatedUTC: string
}

function createData(
  title: string,
  description: string,
  creator: string,
  createdOn: string
) {
  return { title, description, creator, createdOn }
}

export const AdminConsoleMeetingLinks = ({
  selectedOrg,
}: {
  selectedOrg: Organization
}) => {
  const [meetingLinkSearch, setMeetingLinkSearch] = React.useState('')
  const [meetingLinkInputContent, setMeetingLinkInputContent] =
    React.useState('')
  const [meetingLinkInputName, setMeetingLinkInputName] = React.useState('')
  const [selectedMeetingLink, setSelectedMeetingLink] = React.useState(
    {} as OrgMeetingLink
  )
  const [addingMeetingLinkTemplate, setAddingMeetingLinkTemplate] =
    React.useState(false)
  const [meetingLinkSaveDialogOpen, setMeetingLinkSaveDialogOpen] =
    React.useState(false)
  const [meetingLinkDeleteDialogOpen, setMeetingLinkDeleteDialogOpen] =
    React.useState(false)
  const [meetingLinkSort, setMeetingLinkSort] = React.useState<TableSort>({
    header: 'Description',
    sortDirection: 'dec',
  })

  const [meetingLinks, setMeetingLinks] = React.useState<
    OrgMeetingLink[] | undefined
  >()

  const [needsRefresh, setNeedsRefresh] = React.useState(false)

  const isMobile = useAppSelector((state) => isMobileApp(state))

  const dispatch = useDispatch()

  const setSortDirection = (header: TableHeader) => {
    if (header === meetingLinkSort.header) {
      if (meetingLinkSort.sortDirection === 'none') {
        setMeetingLinkSort({ header: header, sortDirection: 'dec' })
      } else if (meetingLinkSort.sortDirection === 'asc') {
        setMeetingLinkSort({ header: header, sortDirection: 'dec' })
      } else {
        setMeetingLinkSort({ header: header, sortDirection: 'asc' })
      }
    } else {
      setMeetingLinkSort({ header: header, sortDirection: 'dec' })
    }
  }

  const clearStateAndRefresh = () => {
    setAddingMeetingLinkTemplate(false)
    setMeetingLinkInputContent('')
    setMeetingLinkInputName('')
    setMeetingLinkSaveDialogOpen(false)
    setMeetingLinkDeleteDialogOpen(false)
    setSelectedMeetingLink({} as OrgMeetingLink)
    setNeedsRefresh(true)
  }

  const sortMeetingLinks = (
    meetingLinkTemplates: OrgMeetingLink[] | undefined
  ) => {
    if (meetingLinkSort.sortDirection === 'none') return

    switch (meetingLinkSort.header) {
      case 'Description':
        if (meetingLinkSort.sortDirection === 'asc') {
          return meetingLinkTemplates?.sort((a, b) =>
            a.Description > b.Description ? 1 : -1
          )
        } else if (meetingLinkSort.sortDirection === 'dec') {
          return meetingLinkTemplates?.sort((a, b) =>
            a.Description > b.Description ? -1 : 1
          )
        }
        break
    }
  }

  const handleMeetingLinkChange = React.useCallback(() => {
    const contentEmpty =
      meetingLinkInputContent.replace(/<(.|\n)*?>/g, '').trim().length === 0

    let errorMessage = ''

    if (contentEmpty || !meetingLinkInputName) {
      return 'Holding statements must both have a title and content to be saved'
    }
    if (
      (meetingLinkInputContent !== selectedMeetingLink.Link && !contentEmpty) ||
      (meetingLinkInputName !== selectedMeetingLink.Description &&
        meetingLinkInputName !== '')
    ) {
      errorMessage = ''
    } else {
      errorMessage = 'Must change title or content to save holding statement'
    }

    if (errorMessage) {
      dispatch(
        addNotification({
          message: errorMessage,
          type: 'error',
        })
      )
      return
    }
    setMeetingLinkSaveDialogOpen(true)
  }, [
    dispatch,
    meetingLinkInputContent,
    meetingLinkInputName,
    selectedMeetingLink.Link,
    selectedMeetingLink.Description,
  ])

  React.useEffect(() => {
    setMeetingLinkInputName(selectedMeetingLink.Description)
    setMeetingLinkInputContent(selectedMeetingLink.Link || '')
  }, [selectedMeetingLink])

  React.useEffect(() => {
    if (needsRefresh) {
      setNeedsRefresh(false)
    }
  }, [needsRefresh])

  React.useEffect(() => {
    orgMeetingLinks(parseInt(selectedOrg.OrgID)).then((data) => {
      setMeetingLinks(data)
    })
  }, [selectedOrg.OrgID, needsRefresh])

  const meetingLink =
    meetingLinkInputContent || meetingLinkInputContent === ''
      ? meetingLinkInputContent
      : selectedMeetingLink.Description

  const meetingLinkTemplatesSearch =
    meetingLinkSearch === ''
      ? meetingLinks?.map((template) => template)
      : meetingLinks?.filter((meetingLink) => {
          if (meetingLink.id === selectedMeetingLink.id) {
            return meetingLink
          }
          return meetingLink.Description.toLowerCase().includes(
            meetingLinkSearch.toLowerCase()
          )
        })

  const meetingLinkList =
    meetingLinkSort.sortDirection === 'none'
      ? meetingLinkTemplatesSearch
      : sortMeetingLinks(meetingLinkTemplatesSearch)

  const meetingLinkEditContainer = (
    <div className={classes.OrganizationHoldingStatementEditContainer}>
      <div>
        <div className={classes.OrganizationInnerContainerEditHeader}>
          Description
          <FontAwesomeIcon
            icon={faTimes}
            style={{ cursor: 'pointer' }}
            onClick={() => {
              setSelectedMeetingLink({} as OrgMeetingLink)
              setAddingMeetingLinkTemplate(false)
            }}
          />
        </div>
        <Input
          type="text"
          required={true}
          name="Meeting Link"
          inputProps={{
            maxLength: 255,
          }}
          style={{ minWidth: '200px', maxWidth: '400px' }}
          fullWidth
          className={classes.TagInput}
          value={meetingLinkInputName}
          onChange={(e) => setMeetingLinkInputName(e.target.value)}
        />
      </div>
      <Spacer />
      <div className={classes.OrganizationInnerContainerEditHeader}>Link</div>

      <Input
        type="text"
        required={true}
        name="Link"
        inputProps={{
          maxLength: 255,
        }}
        style={{ width: '100%' }}
        value={meetingLink}
        onChange={(e) => setMeetingLinkInputContent(e.target.value)}
      />
      <div className={classes.OrganizationTagButtonContainer}>
        {addingMeetingLinkTemplate ? null : (
          <Button
            onClick={() => setMeetingLinkDeleteDialogOpen(true)}
            className={classes.OrganizationDeleteButton}
            style={{ color: 'red' }}
          >
            {' '}
            Delete{' '}
          </Button>
        )}
        <Button
          onClick={() => {
            handleMeetingLinkChange()
          }}
          className={classes.OrganizationSaveButton}
          style={{ color: colorDefinitions.backgroundBlue }}
        >
          {' '}
          Save{' '}
        </Button>
      </div>
    </div>
  )

  const MeetingLinkConfirmDialogs = () => {
    return (
      <>
        <AdminConsoleDialog
          open={meetingLinkDeleteDialogOpen}
          content={'Are you sure you would like to delete this meeting link?'}
          onConfirm={() => {
            deleteOrgMeetingLink(
              selectedMeetingLink.id,
              parseInt(selectedOrg.OrgID)
            )

            clearStateAndRefresh()
          }}
          consoleRole="destructive"
          onCancel={() => setMeetingLinkDeleteDialogOpen(false)}
        />
        <AdminConsoleDialog
          open={meetingLinkSaveDialogOpen}
          content={'Are you sure you would like to save this meeting link?'}
          onConfirm={() => {
            addingMeetingLinkTemplate
              ? createOrgMeetingLink(
                  parseInt(selectedOrg.OrgID),
                  meetingLinkInputContent,
                  meetingLinkInputName
                )
              : updateOrgMeetingLink(
                  selectedMeetingLink.id,
                  parseInt(selectedOrg.OrgID),
                  meetingLinkInputContent,
                  meetingLinkInputName
                )

            clearStateAndRefresh()
          }}
          onCancel={() => setMeetingLinkSaveDialogOpen(false)}
        />
      </>
    )
  }

  const mobileLinkRender = (link: string) => {
    return link.length > 20 ? `${link.slice(0, 20)}...` : link
  }

  if (!meetingLinkList) return <></>

  return (
    <>
      <MeetingLinkConfirmDialogs />
      <div className={classes.OrganizationManagementSection}>
        <div className={classes.OrganizationManagementSectionHeader}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              marginBottom: '10px',
            }}
          >
            <FontAwesomeIcon className={classes.videoIcon} icon={faVideo} />
            &nbsp;&nbsp;&nbsp;&nbsp; <div>MEETING LINKS</div>
            <Spacer spaceParam={'large'} />
            <SearchInput
              id="meetingLinkSearchInput"
              name="SearchInput"
              placeholder="Description Search"
              value={meetingLinkSearch}
              applySearch={(value: any) => {
                setMeetingLinkSearch(value)
              }}
            />
            <Spacer />
          </div>

          <FontAwesomeIcon
            icon={faPlus}
            style={{
              cursor: 'pointer',
              float: 'right',
            }}
            onClick={() => {
              setAddingMeetingLinkTemplate(true)
              setMeetingLinkInputContent('')
              setMeetingLinkInputName('')
              setSelectedMeetingLink({} as OrgMeetingLink)
            }}
          />
        </div>
        {meetingLinkList.length > 0 ? (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column',
            }}
          >
            <Table
              sx={{ minWidth: 300, width: '100%' }}
              aria-label="custom table"
            >
              <colgroup>
                <col style={{ width: '20%' }} />
                <col style={{ width: '10%' }} />
                <col style={{ width: '50%' }} />
                <col style={{ width: '20%' }} />
              </colgroup>
              <TableHead>
                <TableRow style={{ width: '100%' }}>
                  <TableCell
                    align="left"
                    style={{
                      cursor: 'pointer',
                      display: 'flex',
                      alignItems: 'center',
                      borderBottom: '0',
                    }}
                    onClick={() => setSortDirection('Description')}
                  >
                    Description
                    <Spacer />
                    {meetingLinkSort.header === 'Description' &&
                    meetingLinkSort.sortDirection === 'dec' ? (
                      <FontAwesomeIcon icon={faArrowDown} />
                    ) : (
                      meetingLinkSort.header === 'Description' && (
                        <FontAwesomeIcon icon={faArrowUp} />
                      )
                    )}
                  </TableCell>
                  <TableCell
                    align="left"
                    style={{
                      position: 'relative',
                      borderBottom: '0',
                      top: '0px',
                    }}
                  >
                    Creator
                  </TableCell>
                  <TableCell
                    align="left"
                    style={{
                      cursor: 'pointer',
                      display: 'flex',
                      alignItems: 'center',
                      borderBottom: '0',
                    }}
                    onClick={() => setSortDirection('Description')}
                  >
                    Link
                    <Spacer />
                    {meetingLinkSort.header === 'Link' &&
                    meetingLinkSort.sortDirection === 'asc' ? (
                      <FontAwesomeIcon icon={faArrowUp} />
                    ) : (
                      meetingLinkSort.header === 'Link' && (
                        <FontAwesomeIcon icon={faArrowDown} />
                      )
                    )}
                  </TableCell>
                  <TableCell align="left" style={{ borderBottom: '0' }}>
                    Created On
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {meetingLinkList?.map((meetingLink) => {
                  const createdNameOrEmail = meetingLink.CreatorName
                    ? meetingLink.CreatorName
                    : meetingLink.CreatorEmail

                  const meetingLinkRow = createData(
                    meetingLink.Description,
                    meetingLink.Link,
                    createdNameOrEmail,
                    meetingLink.CreatedUTC.toString()
                  )

                  const isSelected = selectedMeetingLink.id === meetingLink.id

                  return (
                    <StyledTableRow
                      key={meetingLink.id}
                      onClick={() => {
                        setSelectedMeetingLink(meetingLink)
                        setAddingMeetingLinkTemplate(false)
                      }}
                      style={{
                        backgroundColor: isSelected
                          ? colorDefinitions.backgroundBlue
                          : '',
                      }}
                    >
                      <TableCell
                        component="th"
                        scope="row"
                        style={{
                          color: isSelected ? 'white' : '',
                        }}
                      >
                        {meetingLinkRow.title}
                      </TableCell>

                      <TableCell
                        component="th"
                        scope="row"
                        style={{
                          color: isSelected ? 'white' : '',
                        }}
                      >
                        {meetingLinkRow.creator}
                      </TableCell>
                      <TableCell
                        style={{
                          color: isSelected ? 'white' : '',
                          wordBreak: 'break-all',
                        }}
                      >
                        {isMobile
                          ? mobileLinkRender(meetingLinkRow.description)
                          : meetingLinkRow.description}
                      </TableCell>
                      <TableCell
                        style={{
                          color: isSelected ? 'white' : '',
                          minWidth: '100px',
                        }}
                      >
                        {dayjs(getLocalDate(meetingLinkRow.createdOn))
                          .local()
                          .format(appDateTimeFormat)}
                      </TableCell>
                    </StyledTableRow>
                  )
                })}
              </TableBody>
            </Table>
          </div>
        ) : (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            There are no meeting links for this organization
            <Button
              onClick={() => {
                setAddingMeetingLinkTemplate(true)
                setMeetingLinkInputContent('')
                setMeetingLinkInputName('')
                setSelectedMeetingLink({} as OrgMeetingLink)
              }}
            >
              Add a meeting link
            </Button>{' '}
          </div>
        )}

        {selectedMeetingLink.id || addingMeetingLinkTemplate
          ? meetingLinkEditContainer
          : null}
      </div>
    </>
  )
}
