// @ts-nocheck
// TODO: Typescript
import React from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import ErrorIcon from '@material-ui/icons/Error'
import InfoIcon from '@material-ui/icons/Info'
import WarningIcon from '@material-ui/icons/Warning'
import { amber, green } from '@material-ui/core/colors'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import { SnackbarContent as SnackbarContentMUI } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

// The icon to use based on the type of snackbar content
const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
}

const contentStyles = makeStyles((theme) => ({
  success: {
    backgroundColor: green[600],
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  info: {
    backgroundColor: theme.palette.primary.main,
  },
  warning: {
    backgroundColor: amber[700],
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  unblockUI: {
    position: 'absolute',
    top: '10px',
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
}))

export default function SnackbarContent(props) {
  const classes = contentStyles()
  const { className, message, onClose, variant, ...other } = props
  const Icon = variantIcon[variant]

  return (
    <SnackbarContentMUI
      className={clsx(classes[variant], classes.unblockUI, className)}
      aria-describedby="client-snackbar"
      message={
        <span id="client-snackbar" className={classes.message}>
          <Icon className={clsx(classes.icon, classes.iconVariant)} />
          {message}
        </span>
      }
      action={renderCloseButton(props, classes)}
      {...other}
    />
  )
}

SnackbarContent.propTypes = {
  className: PropTypes.string,
  message: PropTypes.string,
  onClose: PropTypes.func,
  variant: PropTypes.oneOf(['error', 'info', 'success', 'warning']).isRequired,
}

function renderCloseButton(props, classes) {
  if (!props.onClose) return []
  return [
    <IconButton
      key="close"
      aria-label="close"
      color="inherit"
      onClick={props.onClose}
    >
      <CloseIcon className={classes.icon} />
    </IconButton>,
  ]
}
