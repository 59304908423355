import * as React from 'react'
import { useState, useCallback, CSSProperties, ReactNode } from 'react'
import classes from './MultiLineText.module.scss'
import { cx } from '../_utils/objectUtils'

interface Props {
  style?: CSSProperties
  lines: number
  minLines?: number
  lineHeight: number
  children?: ReactNode
  className?: string
  dangerouslySetInnerHTML?: { __html: string }
}

export const MultiLineText = ({
  style,
  lines,
  minLines,
  lineHeight,
  children,
  className,
  dangerouslySetInnerHTML,
}: Props) => {
  const [height, setHeight] = useState(0)
  const measuredRef = useCallback((node) => {
    if (node !== null) {
      setHeight(node.getBoundingClientRect().height)
    }
  }, [])

  style = {
    lineHeight: lineHeight + 'px',
    maxHeight: lineHeight * lines,
    minHeight: minLines ? lineHeight * minLines : lineHeight + 'px',
    overflow: 'hidden',
    ...style,
  }

  const rootClasses =
    height < lineHeight * lines
      ? cx(classes.root, classes.hidden)
      : classes.root

  if (lines <= 1)
    return (
      <span
        className={cx(className, 'single-line-text')}
        dangerouslySetInnerHTML={dangerouslySetInnerHTML}
      >
        {!dangerouslySetInnerHTML ? children : null}
      </span>
    )

  return (
    <div
      ref={measuredRef}
      className={cx(rootClasses, className)}
      style={style}
      dangerouslySetInnerHTML={dangerouslySetInnerHTML}
    >
      {!dangerouslySetInnerHTML ? children : null}
    </div>
  )
}

MultiLineText.defaultProps = {
  lineHeight: 18.2,
}
