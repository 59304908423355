import * as React from 'react'
import { Component } from 'react'
import Moment from 'react-moment'
import { appDateTimeExactFormat } from './_constants'
import { connect } from 'react-redux'
import classes from './AppInfo.module.scss'
import { cx } from './_utils/objectUtils'
import {
  selectAppVersion,
  selectBuildCreated,
  selectBuildInformation,
} from './Issue/_selectors'

interface Props {
  BuildInformation: string
  // Moment is expecting a string so I can't do date
  BuildCreated: any
  AppVersion: string
  className?: string
}

export class AppInfo extends Component<Props> {
  render() {
    const { BuildInformation, BuildCreated, AppVersion, className } = this.props
    return (
      <div className={cx(classes.root, className, 'card border-iccBlue mb-3')}>
        <div className="card-body">
          <div>{BuildInformation}</div>
          {AppVersion && <div id="divAppVersion">Version {AppVersion}</div>}
          <div id="divDebugCreated">
            {
              <Moment
                local
                format={appDateTimeExactFormat}
                date={BuildCreated}
              />
            }
            <span>&nbsp;</span>(
            {
              <Moment local fromNow>
                {BuildCreated}
              </Moment>
            }
            )
          </div>
        </div>
      </div>
    )
  }
}

// TODO: Typescript fix any
const mapStateToProps = (state: any) => ({
  BuildInformation: selectBuildInformation(state),
  BuildCreated: selectBuildCreated(state),
  AppVersion: selectAppVersion(state),
})

export default connect(mapStateToProps)(AppInfo)
