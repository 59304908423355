import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { selectInboxByGUID } from '../Alert/_selectors'
import { useAppSelector } from '../_utils/reactReduxHooks'
import { alertIcon } from './_constants'
import classes from './OrgTagShared.module.scss'
import * as React from 'react'
import { InboxModel } from '../Alert/_models'
import { cx } from '../_utils/objectUtils'

interface Props {
  inboxGUID: string
}

const iconTypes: { [iconType: string]: string } = {
  email: classes.email,
  'incident report': classes.incidentReport,
  'n for noaa': classes.nForNoaa,
  news: classes.news,
  noaa: classes.noaa,
  police: classes.police,
  rss: classes.rss,
  slack: classes.slack,
  't for tx360+noaa': classes.tForTx360Noaa,
  teams: classes.teams,
  tx360: classes.tx360,
  weather: classes.weather,
  zapier: classes.zapier,
  'icc threat monitoring': classes.iccThreat,
  alertmedia: classes.alertMedia,
}

function getIconClass(inbox: InboxModel) {
  for (let key in iconTypes) {
    const fileName = (inbox.fileName || '').trim().toLocaleLowerCase()
    if (fileName.startsWith(key)) return iconTypes[key]
  }

  return ''
}

export const OrgTagInboxIcon = ({ inboxGUID }: Props) => {
  const inbox = useAppSelector((state) => selectInboxByGUID(state, inboxGUID))

  if (!inbox) return <FontAwesomeIcon icon={alertIcon} />

  return (
    <img
      alt=""
      src={`data:image/png;base64,${inbox.graphic}`}
      className={cx(classes.AlertIconCustom, getIconClass(inbox))}
    />
  )
}
