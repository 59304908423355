import { callAction, ReducerActionMap } from '../_utils/reduxUtils'
import { SAVE_PUBLIC_PLAYBOOK_SEARCH } from './_constants'

export interface publicPlaybookAppState {
  ppSearchValue?: string
}
export default function publicPlaybookAppReducer(
  state: publicPlaybookAppState = {},
  action: any
) {
  const mapped: ReducerActionMap = {
    [SAVE_PUBLIC_PLAYBOOK_SEARCH]: () => {
      return {
        ...state,
        ppSearchValue: action.payload.ppSearchValue,
      }
    },
  }
  return callAction(mapped, state, action)
}
export const selectPublicPlaybookSearchValue = (
  state: publicPlaybookAppState
) => state.ppSearchValue
