import { takeLatest } from '@redux-saga/core/effects'
import { getFetchListener } from '../_utils/sagaUtils'
import { fetchPortalUser } from './_actions'
import { FETCH_PORTAL_USER } from './_constants'

const fetchPortalUserSaga = getFetchListener('api/User', {
  formatData: false,
  onLoading: fetchPortalUser.loading,
  onFailure: fetchPortalUser.failure,
  onSuccess: fetchPortalUser.success,
})

// Watcher sagas
export const portalUserSagas = [
  takeLatest(FETCH_PORTAL_USER.REQUEST, fetchPortalUserSaga),
]
