import * as React from 'react'
import classes from './Playbooks.module.scss'
import Moment from 'react-moment'
import { cx } from '../_utils/objectUtils'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPoll } from '@fortawesome/free-solid-svg-icons'

interface Props {
  PUR_GUID: string
  PollName: string
  ExpirationUTC: Date
  PollURL: string
  onClick?(poll: PollBannerProps): void
  selected?: boolean
}
export interface PollBannerProps extends Props {}

export const PollBanner = ({
  PUR_GUID,
  PollName,
  ExpirationUTC,
  PollURL,
  onClick = () => {},
  selected,
}: Props) => (
  <div className={cx(classes.icoPollList)}>
    <div
      className={cx(classes.clickBox, 'clearfix')}
      onClick={() => onClick({ PUR_GUID, PollName, ExpirationUTC, PollURL })}
    >
      <div className={cx('float-left', classes.icoPollItem)}>
        <FontAwesomeIcon className={classes.icoPollItemIcon} icon={faPoll} />
      </div>
      <div className={cx('float-left', classes.planSpec)}>
        <span style={{ fontWeight: 'bold' }}>{PollName}</span>
        <br />
        <span style={{ fontSize: '87.5%' }}>
          active until &nbsp;&nbsp;
          <Moment format="DD MMM YYYY hh:mm a" utc local>
            {ExpirationUTC}
          </Moment>
        </span>
      </div>
    </div>
  </div>
)
