// @ts-nocheck
// TODO: Typescript
import { UserAgentApplication } from 'msal'
import { AD_FAILED_TO_OPEN_WINDOW, AD_CANCELED_BY_USER } from './_constants'
import * as microsoftTeams from '@microsoft/teams-js'

// Azure AD and Graph auth and token initialization
export const scopes = [
  'https://graph.microsoft.com/User.Read',
  'openid',
  //"https://graph.microsoft.com/Calendars.Read",
  'https://graph.microsoft.com/Calendars.Read.Shared',
  //"https://graph.microsoft.com/Calendars.ReadWrite",
  //"https://graph.microsoft.com/Channel.Create",
  //"https://graph.microsoft.com/Channel.Delete.All",
  'https://graph.microsoft.com/Channel.ReadBasic.All',
  'https://graph.microsoft.com/Team.Create',
  'https://graph.microsoft.com/Team.ReadBasic.All',
  'https://graph.microsoft.com/Files.ReadWrite',
  'https://graph.microsoft.com/Files.ReadWrite.All',
]

const tenantId = '7e1c3c93-e90f-48cc-871c-fc94a261899d'
const authorizeEndpoint = 'https://login.microsoftonline.com/' + tenantId
export const teamsUserAgentApp = new UserAgentApplication({
  auth: {
    clientId: 'ca850033-c021-47b1-9510-749f387a08e7',
    authority: authorizeEndpoint, // Only for single tenant apps
    redirectUri: window.location.origin,
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: true,
  },
})

export function teamsAuthenticate(options, requestParams) {
  const { loginHint, prompt, isTeamsApp } = requestParams || {}

  let baseUrl = window.origin + '/AzureAD/Login'
  if (loginHint && !prompt) {
    baseUrl += '?login_hint=' + loginHint
    if (isTeamsApp) baseUrl += '&isTeamsApp=' + isTeamsApp
  } else if (isTeamsApp) baseUrl += '?isTeamsApp=' + isTeamsApp

  options = options || {}
  options = { ...options }
  const { successCallback, failureCallback } = options
  delete options.successCallback
  delete options.failureCallback
  microsoftTeams.authentication.authenticate({
    url: baseUrl,
    width: 600,
    height: 535,
    successCallback: handleSuccess,
    failureCallback: handleFailure,
    ...options,
  })

  function handleFailure(reason) {
    if (!failureCallback) return

    // Possible popup blocker
    // other reasons for failure are if a user canceled the request, but that isn't really a failure
    if ([AD_FAILED_TO_OPEN_WINDOW].indexOf(reason) > -1) failureCallback(reason)
  }

  function handleSuccess(result) {
    // Configuration should only be ran if the user is on the configure page
    if (
      (window.location.href || '')
        .toLocaleLowerCase()
        .indexOf('microsoftteams/configure') > -1
    ) {
      //This command enables the "Save" button (disabled by default)
      microsoftTeams.settings.setValidityState(true)

      //Specify what happens when the user clicks on "Save"
      microsoftTeams.settings.registerOnSaveHandler((saveEvent) => {
        microsoftTeams.settings.setSettings({
          //Important when referencing your connector from somewhere else within Teams
          entityId: 'IcoIssueWeb',
          //This name will be shown to channel admins under "configured connectors" - Use something that helps admins to identify this specific connection
          configName: 'IcoIssueWebConfig',
          //The URL to the configuration page for further config changes
          contentUrl: window.location.origin,
        })

        //You have to notify Teams that the saving process was successful, otherwise the save operation will time out and display an error
        saveEvent.notifySuccess()
      })
    }

    if (successCallback) successCallback(result)
  }
}

/*********************************************************************************
NOTE: The functions below are no longer useful. They are based on the msal JavaScript
implementation. There are two downsides to using this library: 1. The loginPopup does
not work for the teams application. This is because Microsoft restricts redirects.
2. It doesn't save the id and access token on the server. I want the tokens saved
on the server so that I can sync everything with the application cookie.
*********************************************************************************/
/*
export function loginPopup() {
    return teamsUserAgentApp.loginPopup({
        scopes,
        prompt: "select_account"
    });
}

export async function loginPopupAsync() {
    return await loginPopup();
}

export function loginRedirect() {
    return teamsUserAgentApp.loginRedirect({
        scopes,
        prompt: "select_account"
    });
}

export async function loginRedirectAsync() {
    return await loginRedirect();
}

export function logout() {
    teamsUserAgentApp.logout();
}

export function getAccessToken() {
    var accessToken = teamsUserAgentApp.acquireTokenSilent({
        scopes
    });

    return accessToken;
}

export async function getAccessTokenAsync() {
    return await getAccessToken();
}

export function isUserAuthenticated(resultFn) {
    resultFn = resultFn || (() => { });
    return getAccessToken().then(() => {
        resultFn(true);
    })
    .catch(error => {
        const errorCode = error.errorCode;
        if (errorCode === 'user_login_error')
            resultFn(false);
    });
}*/
