import {
  faArrowDown,
  faArrowUp,
  faFile,
  faPlus,
} from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  Input,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  FormGroup,
  FormControl,
  FormLabel,
  FormHelperText,
  TextField,
} from '@material-ui/core'
import { Button } from '../Shared/Buttons'
import React from 'react'
import Moment from 'react-moment'
import { useDispatch } from 'react-redux'
import { Table } from 'reactstrap'
import { Organization } from '../Issue/_reducer'
import { AdminConsoleDialog } from '../Shared/AdminConsoleDialog'
import { SearchInput } from '../Shared/SearchInput'
import { Spacer } from '../Shared/Spacer'
import { addNotification } from '../_actions'
import { appDateTimeFormat } from '../_constants'

import { getLocalDate } from '../_utils/dateUtils'
import { StyledTableRow } from './AdminConsolePage'
import classes from './AdminConsolePage.module.scss'
import { icoPost, useIcoFetch } from '../_utils/fetchUtils'
import Checkbox from '../CustomMaterial/Checkbox'

type TableHeader =
  | 'Name'
  | 'Creator'
  | 'Created'
  | 'Updated'
  | 'Updated By'
  | 'Allow Custom'

type SortDirection = 'asc' | 'dec' | 'none'
export interface TableSort {
  header: TableHeader
  sortDirection: SortDirection
}
interface Template {
  StakeholderTemplateID?: number
  Name?: string
  Content?: string
  Created?: string
  Creator?: string
  Updated?: string
  Updater?: string
  ConfirmBeforeSendingYN?: string
  AllowCustomInput?: string
  Subject?: string
}

interface TemplateRowModel {
  name: string
  creator: string
  created: string
  updated: string
  updator: string
  allowCustom: string
}

export const AdminConsoleStakeholderTemplates = ({
  selectedOrg,
}: {
  selectedOrg: Organization
}) => {
  const [templateSearch, setTemplateSearch] = React.useState('')
  const [templateInputContent, setTemplateInputContent] = React.useState('')
  const [templateInputSubject, setTemplateInputSubject] = React.useState('')
  const [templateInputName, setTemplateInputName] = React.useState('')
  const [allowCustomInputYN, setAllowCustomInputYN] = React.useState('N')
  const [selectedTemplate, setSelectedTemplate] = React.useState<Template>()
  const [templateSaveDialogOpen, setTemplateSaveDialogOpen] =
    React.useState(false)
  const [templateDeleteDialogOpen, setTemplateDeleteDialogOpen] =
    React.useState(false)
  const [templateSort, setTemplateSort] = React.useState<TableSort>({
    header: 'Updated',
    sortDirection: 'dec',
  })
  const [creatingTemplate, setCreatingTemplate] = React.useState(false)

  const [addingTemplate, setAddingTemplate] = React.useState(false)

  const [needsRefresh, setNeedsRefresh] = React.useState(false)

  const dispatch = useDispatch()

  const templateList = useIcoFetch(
    `/api/Stakeholders/GetTemplates/${selectedOrg.OrgID}`,
    {
      required: [selectedOrg.OrgID, !addingTemplate],
      tracking: [addingTemplate],
    }
  )

  const setSortDirection = (header: TableHeader) => {
    if (header === templateSort.header) {
      if (templateSort.sortDirection === 'none') {
        setTemplateSort({ header: header, sortDirection: 'dec' })
      } else if (templateSort.sortDirection === 'asc') {
        setTemplateSort({ header: header, sortDirection: 'dec' })
      } else {
        setTemplateSort({ header: header, sortDirection: 'asc' })
      }
    } else {
      setTemplateSort({ header: header, sortDirection: 'dec' })
    }
  }

  const clearStateAndRefresh = () => {
    setCreatingTemplate(false)
    setTemplateInputContent('')
    setTemplateInputSubject('')
    setTemplateInputName('')
    setAllowCustomInputYN('N')
    setTemplateSaveDialogOpen(false)
    setTemplateDeleteDialogOpen(false)
    setSelectedTemplate({} as Template)
    setNeedsRefresh(true)
  }

  const templateCRUD = (action: string) => {
    setAddingTemplate(true)
    icoPost('/api/Stakeholders/templatecrud', {
      OrgID: selectedOrg.OrgID,
      StakeholderTemplateID: selectedTemplate?.StakeholderTemplateID || 0,
      Name: templateInputName,
      Content: templateInputContent,
      ConfirmBeforeSendingYN: 'N',
      allowCustomInputYN: allowCustomInputYN,
      Subject: templateInputSubject,
      action: action,
    }).then((_) => setAddingTemplate(false))
  }

  const sortTemplates = (templates: Template[] | undefined) => {
    if (templateSort.sortDirection === 'none') return

    switch (templateSort.header) {
      case 'Name':
        if (templateSort.sortDirection === 'dec') {
          return templates?.sort((a, b) =>
            a.Name && b.Name ? a.Name.localeCompare(b.Name) : 0
          )
        } else if (templateSort.sortDirection === 'asc') {
          return templates?.sort((a, b) =>
            a.Name && b.Name ? -1 * a.Name.localeCompare(b.Name) : 0
          )
        }
        break

      case 'Updated':
        if (templateSort.sortDirection === 'asc') {
          return templates?.sort((a, b) =>
            a.Updated && b.Updated ? (a.Updated > b.Updated ? 1 : -1) : 0
          )
        } else if (templateSort.sortDirection === 'dec') {
          return templates?.sort((a, b) =>
            a.Updated && b.Updated ? (a.Updated > b.Updated ? -1 : 1) : 0
          )
        }
        break

      case 'Created':
        if (templateSort.sortDirection === 'asc') {
          return templates?.sort((a, b) =>
            a.Created && b.Created ? (a.Created > b.Created ? 1 : -1) : 0
          )
        } else if (templateSort.sortDirection === 'dec') {
          return templates?.sort((a, b) =>
            a.Created && b.Created ? (a.Created > b.Created ? -1 : 1) : 0
          )
        }
        break
    }
  }

  const handleTemplateSave = React.useCallback(() => {
    const contentEmpty =
      templateInputContent.replace(/<(.|\n)*?>/g, '').trim().length === 0

    let errorMessage = ''

    if (contentEmpty || !templateInputName) {
      errorMessage = 'Templates must both have a title and content to be saved'
    }

    if (errorMessage) {
      dispatch(
        addNotification({
          message: errorMessage,
          type: 'error',
        })
      )
      return
    }
    setTemplateSaveDialogOpen(true)
  }, [dispatch, templateInputContent, templateInputName])

  React.useEffect(() => {
    setTemplateInputName(selectedTemplate?.Name || '')
    setTemplateInputContent(selectedTemplate?.Content || '')
    setTemplateInputSubject(selectedTemplate?.Subject || '')
    setAllowCustomInputYN(selectedTemplate?.AllowCustomInput || 'N')
  }, [selectedTemplate])

  React.useEffect(() => {
    if (needsRefresh) {
      setNeedsRefresh(false)
    }
  }, [needsRefresh])

  const templatesSearch =
    templateSearch === ''
      ? templateList.data?.map((template: Template) => template)
      : templateList.data?.filter((template: Template) => {
          if (
            template.StakeholderTemplateID ===
            selectedTemplate?.StakeholderTemplateID
          ) {
            return template
          }
          return template.Name?.toLowerCase().includes(
            templateSearch.toLowerCase()
          )
        })

  const templates =
    templateSort.sortDirection === 'none'
      ? templatesSearch
      : sortTemplates(templatesSearch)

  const templateEditContainer = (
    <div className={classes.OrganizationHoldingStatementEditContainer}>
      <FormControl id="formMessage">
        <FormLabel style={{ marginBottom: 0 }}>
          <h6>Template Name</h6>
        </FormLabel>
        <Input
          type="text"
          required
          name="Stakeholder Notification"
          inputProps={{
            maxLength: 255,
          }}
          style={{ minWidth: '200px', maxWidth: '400px', marginTop: '0' }}
          fullWidth
          className={classes.TagInput}
          value={templateInputName}
          onChange={(e) => setTemplateInputName(e.target.value)}
        />
      </FormControl>
      <Spacer spaceParam={'large'} />
      <FormControl id="formMessage">
        <FormLabel>
          <h6>Template Details</h6>
        </FormLabel>
        <div
          onClick={() =>
            setAllowCustomInputYN((prev) => (prev === 'Y' ? 'N' : 'Y'))
          }
          style={{
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'row',
          }}
        >
          <Checkbox checked={allowCustomInputYN === 'Y'} />
          &nbsp;&nbsp; Allow users to edit message Subject and Content
        </div>
      </FormControl>
      <Spacer spaceParam={'large'} />
      <FormControl id="formMessage">
        <FormLabel>
          <h6>Message to Send</h6>
        </FormLabel>
        <FormGroup>
          <TextField
            id="txtMessageSubject"
            className={classes.iac_snt_msgSubject}
            label="Subject:"
            multiline
            value={templateInputSubject}
            onChange={(e) => {
              if (e.target.value.length > 60) {
                setTemplateInputSubject(e.target.value.substring(0, 60))
              } else {
                setTemplateInputSubject(e.target.value)
              }
            }}
          />
          <FormHelperText>
            You have used {templateInputSubject.length} of 60 characters...
          </FormHelperText>
          <TextField
            id="txtMessageContent"
            label="Content:"
            multiline
            rows={4}
            value={templateInputContent}
            onChange={(e) => {
              setTemplateInputContent(e.target.value)
            }}
          />
          {templateInputContent.length > 178 && (
            <FormHelperText>
              Your message exceeds 178 characters; push and text notifications
              may not be delivered in their entirety
            </FormHelperText>
          )}
        </FormGroup>
      </FormControl>

      <div className={classes.OrganizationTagButtonContainer}>
        <Button
          color="standard"
          onClick={() => {
            setSelectedTemplate({} as Template)
            setCreatingTemplate(false)
          }}
          className={classes.OrganizationSaveButton}
        >
          {' '}
          Cancel{' '}
        </Button>
        {!creatingTemplate && (
          <>
            <Spacer />
            <Button
              color="danger"
              onClick={() => setTemplateDeleteDialogOpen(true)}
            >
              {' '}
              Delete{' '}
            </Button>
          </>
        )}
        <Spacer />
        <Button
          color="primary"
          onClick={() => {
            handleTemplateSave()
          }}
          className={classes.OrganizationSaveButton}
        >
          {' '}
          Save{' '}
        </Button>
      </div>
    </div>
  )

  const templateDialogs = () => {
    return (
      <>
        <AdminConsoleDialog
          open={templateDeleteDialogOpen}
          content={'Are you sure you would like to delete this template?'}
          onConfirm={() => {
            templateCRUD('delete')
            clearStateAndRefresh()
          }}
          consoleRole="destructive"
          onCancel={() => setTemplateDeleteDialogOpen(false)}
        />
        <AdminConsoleDialog
          open={templateSaveDialogOpen}
          content={'Are you sure you would like to save this template?'}
          onConfirm={() => {
            templateCRUD(
              selectedTemplate?.StakeholderTemplateID ? 'update' : 'create'
            )

            clearStateAndRefresh()
          }}
          onCancel={() => setTemplateSaveDialogOpen(false)}
        />
      </>
    )
  }

  return (
    <>
      {templateDialogs()}
      <div className={classes.OrganizationManagementSection}>
        <div className={classes.OrganizationManagementSectionHeader}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              marginBottom: '10px',
            }}
          >
            <FontAwesomeIcon icon={faFile} size={'2x'} />{' '}
            &nbsp;&nbsp;&nbsp;&nbsp;{' '}
            <div>STAKEHOLDER NOTIFICATION TEMPLATES</div>
            <Spacer spaceParam={'large'} />
            <SearchInput
              id="templateSearchInput"
              name="SearchInput"
              placeholder="Name Search"
              value={templateSearch}
              applySearch={(value: string) => {
                setTemplateSearch(value)
              }}
            />
            <Spacer />
          </div>

          <FontAwesomeIcon
            icon={faPlus}
            style={{
              cursor: 'pointer',
              float: 'right',
            }}
            onClick={() => {
              setCreatingTemplate(true)
              setTemplateInputContent('')
              setTemplateInputSubject('')
              setTemplateInputName('')
              setSelectedTemplate({} as Template)
            }}
          />
        </div>
        {templates ? (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column',
            }}
          >
            <Table
              sx={{ minWidth: 400 }}
              style={{ display: 'table', tableLayout: 'fixed' }}
              aria-label="custom table"
            >
              <colgroup>
                <col style={{ width: '20%' }} />
                <col style={{ width: '20%' }} />
                <col style={{ width: '15%' }} />
                <col style={{ width: '20%' }} />
                <col style={{ width: '15%' }} />
                <col style={{ width: '10%' }} />
              </colgroup>
              <TableHead>
                <TableRow>
                  <TableCell
                    align="left"
                    style={{
                      cursor: 'pointer',
                      display: 'flex',
                      alignItems: 'center',
                      verticalAlign: 'top',
                      borderBottom: '0',
                    }}
                    onClick={() => setSortDirection('Name')}
                  >
                    Name
                    <Spacer />
                    {templateSort.header === 'Name' &&
                    templateSort.sortDirection === 'dec' ? (
                      <FontAwesomeIcon icon={faArrowDown} />
                    ) : (
                      templateSort.header === 'Name' && (
                        <FontAwesomeIcon icon={faArrowUp} />
                      )
                    )}
                  </TableCell>
                  <TableCell
                    align="left"
                    style={{ borderBottom: '0', verticalAlign: 'top' }}
                  >
                    Creator
                  </TableCell>
                  <TableCell
                    align="left"
                    style={{
                      cursor: 'pointer',
                      display: 'flex',
                      alignItems: 'center',
                      verticalAlign: 'top',
                      borderBottom: '0',
                    }}
                    onClick={() => setSortDirection('Created')}
                  >
                    Created
                    <Spacer />
                    {templateSort.header === 'Created' &&
                    templateSort.sortDirection === 'asc' ? (
                      <FontAwesomeIcon icon={faArrowUp} />
                    ) : (
                      templateSort.header === 'Created' && (
                        <FontAwesomeIcon icon={faArrowDown} />
                      )
                    )}
                  </TableCell>
                  <TableCell
                    align="left"
                    style={{ borderBottom: '0', verticalAlign: 'top' }}
                  >
                    Updated By
                  </TableCell>
                  <TableCell
                    align="left"
                    style={{
                      cursor: 'pointer',
                      display: 'flex',
                      alignItems: 'center',
                      verticalAlign: 'top',
                      borderBottom: '0',
                    }}
                    onClick={() => setSortDirection('Updated')}
                  >
                    Updated
                    <Spacer />
                    {templateSort.header === 'Updated' &&
                    templateSort.sortDirection === 'asc' ? (
                      <FontAwesomeIcon icon={faArrowUp} />
                    ) : (
                      templateSort.header === 'Updated' && (
                        <FontAwesomeIcon icon={faArrowDown} />
                      )
                    )}
                  </TableCell>
                  <TableCell
                    align="left"
                    style={{ borderBottom: '0', verticalAlign: 'top' }}
                  >
                    Allow Custom
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {templates.map((template: Template) => {
                  const templateRow: TemplateRowModel = {
                    name: template.Name || '',
                    creator: template.Creator || '',
                    created: template.Created || '',
                    updated: template.Updated?.toString() || '',
                    updator: template.Updater || '',
                    allowCustom: template.AllowCustomInput || 'N',
                  }

                  const isSelected =
                    selectedTemplate?.StakeholderTemplateID ===
                    template.StakeholderTemplateID
                  return (
                    <StyledTableRow
                      key={template.StakeholderTemplateID}
                      onClick={() => {
                        setSelectedTemplate(template)
                        setCreatingTemplate(false)
                      }}
                      style={{
                        backgroundColor: isSelected ? 'rgb(64, 148, 195)' : '',
                      }}
                    >
                      <TableCell
                        component="th"
                        scope="row"
                        style={{
                          color: isSelected ? 'white' : '',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                        }}
                      >
                        {templateRow.name}
                      </TableCell>
                      <TableCell
                        style={{
                          color: isSelected ? 'white' : '',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                        }}
                      >
                        {templateRow.creator}
                      </TableCell>
                      <TableCell
                        style={{
                          color: isSelected ? 'white' : '',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                        }}
                      >
                        {
                          <Moment
                            local
                            format={appDateTimeFormat}
                            date={getLocalDate(templateRow.created)}
                          />
                        }
                      </TableCell>
                      <TableCell
                        style={{
                          color: isSelected ? 'white' : '',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                        }}
                      >
                        {templateRow.updator}
                      </TableCell>
                      <TableCell
                        style={{
                          color: isSelected ? 'white' : '',
                        }}
                      >
                        {
                          <Moment
                            local
                            format={appDateTimeFormat}
                            date={getLocalDate(templateRow.updated)}
                          />
                        }
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        style={{
                          color: isSelected ? 'white' : '',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                        }}
                      >
                        {templateRow.allowCustom}
                      </TableCell>
                    </StyledTableRow>
                  )
                })}
              </TableBody>
            </Table>
          </div>
        ) : (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            There are no stakeholder notification templates for this
            organization
            <Button
              onClick={() => {
                setCreatingTemplate(true)
                setTemplateInputContent('')
                setTemplateInputSubject('')
                setTemplateInputName('')
                setSelectedTemplate({} as Template)
              }}
            >
              Add a template
            </Button>{' '}
          </div>
        )}

        {selectedTemplate?.StakeholderTemplateID || creatingTemplate
          ? templateEditContainer
          : null}
      </div>
    </>
  )
}
