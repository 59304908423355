import React, { useState } from 'react'
import { FilePicker } from './FilePicker'
import { Button } from 'reactstrap'
import UploadDialog from './UploadDialog'

interface Props {
  IssueTeamWorkspaceID: number
  disabled?: boolean
}
export const ArtifactUpload = ({ IssueTeamWorkspaceID, disabled }: Props) => {
  const [file, setFile] = useState(null)
  const [entity, setEntity] = useState<any>()

  function handleFilePickerOnChange(file: any) {
    setFile(file)
    setEntity({ IssueTeamWorkspaceID })
  }

  function handleUploadDialogClose() {
    setFile(null)
  }

  return (
    <>
      <FilePicker onChange={handleFilePickerOnChange} disabled={disabled}>
        <Button color="primary" disabled={disabled}>
          Upload Attachment
        </Button>
      </FilePicker>
      <UploadDialog
        open={file && !disabled ? true : false}
        file={file}
        entity={entity}
        onCancel={handleUploadDialogClose}
      />
    </>
  )
}
