import React, { Fragment, useState } from 'react'
import { IssueDynamicFormsList } from './IssueDynamicFormList'
import { IssueDynamicForm } from './IssueDynamicForm'

interface Props {
  IssueID: number
}

export const IssueAssessmentFormsViewer = ({ IssueID }: Props) => {
  const [lastSaveSubmit, setLastSaveSubmit] = useState('')

  return (
    <>
      <IssueDynamicForm
        IssueID={IssueID}
        dfName="Assessment"
        setLastSaveSubmit={setLastSaveSubmit}
      />
      <IssueDynamicFormsList
        IssueID={IssueID}
        dfName="Assessment"
        lastSaveSubmit={lastSaveSubmit}
      />
    </>
  )
}
