// @ts-nocheck
// TODO: Typescript
import { getFetchListener } from '../_utils/sagaUtils'
import {
  ChatDetailConstants,
  FETCH_CHAT_UNREAD_COUNT,
  FETCH_DIRECT_MESSAGES,
  FETCH_LATEST_CHAT_DATES,
} from './_constants'
import { takeEvery, takeLatest } from 'redux-saga/effects'
import {
  ChatDetailActions,
  fetchChatUnreadCount,
  fetchDirectMessages,
  fetchLatestChatDates,
} from './_actions'
import { getCleanArray } from '../_utils/objectUtils'
import { normalize } from 'normalizr'
import {
  collabChatEntryListSchema,
  transformEntities,
  getArtifactId,
  workspaceListSchema,
} from '../_schema/_schemas'
import { ArtifactActions } from '../Upload/_actions'
import { selectUserEmail } from '../_rootConfigs/rootSelectors'
import { objMapDatePropsToUTC } from '../_utils/dateUtils'
import { WorkspaceActions } from '../Workspace/_actions'

// The Saga that does the actual work
const fetchChatDetail = getFetchListener('api/Chats', {
  onLoading: (result, params, source) =>
    ChatDetailActions.fetchLoading(result, source),
  onFailure: (error, result, params, source) =>
    ChatDetailActions.fetchFailure(error, result, source),
  onSuccess: (json) => {
    json = json || []
    const chat = json[0]
    chat.chatentry = getCleanArray(chat.chatentry)
    transformEntities(chat.chatentry, 'CreatedUTC')

    // Convert upload to a single object
    let artifactIds = []
    for (var i = 0; i < chat.chatentry.length; i++) {
      var curEntry = chat.chatentry[i]
      curEntry.upload = getCleanArray(curEntry.upload)
      if (curEntry.upload && curEntry.upload[0]) {
        curEntry.upload = curEntry.upload[0]
        curEntry.upload.CreatedUTC = curEntry.CreatedUTC
        artifactIds.push(getArtifactId(curEntry.upload))
      } else curEntry.upload = undefined
    }
    let normalizedData = normalize(chat.chatentry, collabChatEntryListSchema)

    // return action with entities(lookup table) and results(id array) as the payload
    return [
      ChatDetailActions.fetchSuccess(normalizedData.entities.chatentry || {}, {
        ChatID: chat.ChatID,
        chatentry: normalizedData.result,
        icoRequestId: chat.ChatID,
      }),
      ArtifactActions.fetchSuccess(normalizedData.entities.Artifacts || {}, {
        icoRequestId: artifactIds,
      }),
    ]
  },
  appendPath: function (action) {
    return action.payload.result.ChatID
  },
})

const requestDirectMessages = getFetchListener('/api/IssueTeamWorkspaces', {
  onLoading: (result, _, source) =>
    fetchDirectMessages.loading(result, undefined, source),
  onFailure: fetchDirectMessages.failure,
  select: {
    userEmail: [selectUserEmail],
  },
  onSuccess: (directMessages, params, source, { userEmail }) => {
    let dmMap = {}
    directMessages.forEach((m) => {
      dmMap[m.ChatID] = m
      if (m.Participants)
        m.Participants = m.Participants.filter(
          (p) => p.EmailAddress.toLocaleLowerCase() !== userEmail
        )
    })

    return fetchDirectMessages.success(dmMap, params, source)
  },
  appendPath: function (action) {
    return action.payload.params.IssueTeamWorkspaceID + '/Chats'
  },
})

const requestLatestChatDates = getFetchListener('/api/Chats/LatestDates', {
  formatData: false,
  onLoading: (result, _, source) =>
    fetchLatestChatDates.loading(result, undefined, source),
  onFailure: fetchLatestChatDates.failure,
  onSuccess: (result, params) => {
    result = getCleanArray(result)

    let normalizedData = normalize(result, workspaceListSchema)

    // Fix the dates for the chats
    objMapDatePropsToUTC(
      normalizedData.entities?.CollabChat,
      'lastActivityDate',
      'LastRead'
    )

    return [
      fetchLatestChatDates.success(normalizedData.entities?.CollabChat, params),
      WorkspaceActions.updateRangeSuccess(normalizedData.entities?.wksp),
    ]
  },
})

const requestChatUnreadCount = getFetchListener('/api/Chats/', {
  onLoading: (result, _, source) =>
    fetchChatUnreadCount.loading(result, undefined, source),
  onFailure: fetchChatUnreadCount.failure,
  onSuccess: fetchChatUnreadCount.success,
  appendPath: function (action) {
    return action.payload.params.ChatID + '/UnreadCount'
  },
})

// Watcher sagas
export const chatSagas = [
  // takeLatest - will cancel previous fetch request and only run the latest one.
  // This is a listener. the yield all is called in the store. The logic is similar to reducers combine logic
  takeLatest(ChatDetailConstants.FETCH.REQUEST, fetchChatDetail),
  takeEvery(FETCH_DIRECT_MESSAGES.REQUEST, requestDirectMessages),
  takeLatest(FETCH_LATEST_CHAT_DATES.REQUEST, requestLatestChatDates),
  takeLatest(FETCH_CHAT_UNREAD_COUNT.REQUEST, requestChatUnreadCount),
]
