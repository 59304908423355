import React, { useCallback, useState, useEffect } from 'react'
import { ResponsiveDialog } from '../Shared/ResponsiveDialog'
import { SlideLeft } from '../Shared/Transition'
import { DialogContent, ListItem, Select, Toolbar } from '@material-ui/core'
import { HeaderNavigation } from '../Shared/HeaderNavigation'
import { Spacer } from '../Shared/Spacer'
import { Button } from '../Shared/Buttons'
import { useAppDispatch, useAppSelector } from '../_utils/reactReduxHooks'
import { FormControl, InputLabel, Input } from '@material-ui/core'
import { addNotification } from '../_actions'
import { StakeholderActions } from './_actions'
import DOMPurify from 'dompurify'
import {
  selectIssueParticipantArray,
  selectStakeholderById,
} from '../_rootConfigs/rootSelectors'
import classes from './StakeholderPlaybooksAndMembers.module.scss'

interface Props {
  isOpen: boolean
  onCancel: () => void
  autoOpenNew?: () => void
  IssueID: number
  StakeholderID?: number
  workspaceID: number
}

export const StakeholderCreateAndEditDialog = ({
  isOpen,
  onCancel,
  IssueID,
  StakeholderID,
  autoOpenNew,
  workspaceID,
}: Props) => {
  const stakeholderDetails = useAppSelector((state) =>
    selectStakeholderById(state, StakeholderID)
  )
  const [stakeholderName, setStakeholderName] = useState<string>(
    stakeholderDetails?.Audience || ''
  )
  const [responsibleParticipant, setResponsibleParticipant] = useState<string>(
    stakeholderDetails?.responsibleParticipant || ''
  )
  const [errors, setErrors] = useState<{ [key: string]: any }>({})
  const participants = useAppSelector((state) =>
    selectIssueParticipantArray(state, workspaceID)
  )
  const dispatch = useAppDispatch()

  const handleCancel = useCallback(() => {
    setStakeholderName('')
    onCancel()
  }, [onCancel])

  const handleSave = useCallback(() => {
    DOMPurify.sanitize(stakeholderName)

    if (!stakeholderName || stakeholderName?.length === 0) {
      setErrors((prev) => ({
        ...prev,
        Audience: 'A stakeholder name cannot be blank',
      }))
      dispatch(
        addNotification({
          message: 'A stakeholder name cannot be blank',
          type: 'error',
        })
      )
      return
    } else if (DOMPurify.removed.length > 0) {
      setErrors((prev) => ({
        ...prev,
        Audience: 'A stakeholder name cannot be a security risk',
      }))
      dispatch(
        addNotification({
          message: 'An audience name cannot be a security risk',
          type: 'error',
        })
      )
      return
    }

    if (StakeholderID) {
      dispatch(
        StakeholderActions.update({
          IssueID,
          IssueStakeholderID: StakeholderID,
          Audience: stakeholderName,
          responsibleParticipant: responsibleParticipant,
        })
      )
      handleCancel()
    } else {
      dispatch(StakeholderActions.setCurrentStakeholderID(null))
      dispatch(
        StakeholderActions.add({
          IssueID,
          Audience: stakeholderName,
          responsibleParticipant: responsibleParticipant,
        })
      )
      handleCancel()
      autoOpenNew && autoOpenNew()
    }
  }, [
    dispatch,
    IssueID,
    stakeholderName,
    autoOpenNew,
    StakeholderID,
    handleCancel,
    responsibleParticipant,
  ])

  useEffect(() => {
    if (stakeholderDetails?.Audience) {
      setStakeholderName(stakeholderDetails.Audience)
    }
    if (stakeholderDetails?.responsibleParticipant) {
      setResponsibleParticipant(stakeholderDetails.responsibleParticipant)
    }
  }, [stakeholderDetails?.Audience, stakeholderDetails?.responsibleParticipant])

  const renderContent = useCallback(() => {
    return (
      <div>
        <FormControl className="w-100" required={true}>
          <InputLabel>Name or Group</InputLabel>
          <Input
            type="text"
            required={true}
            error={errors['Audience'] ? true : false}
            name="Audience"
            inputProps={{
              maxLength: 255,
            }}
            value={stakeholderName}
            onChange={(evt) => {
              if (errors.Audience) {
                delete errors.Audience
              }
              setStakeholderName(evt.target.value)
            }}
          />
        </FormControl>
        <Spacer spaceParam={'large'} />
        <div style={{ width: '100%', minWidth: '200px', maxWidth: '400px' }}>
          <InputLabel>Responsible Participant</InputLabel>
          <Select
            label={'Responsible Participant'}
            style={{ height: '32px', width: '100%' }}
            onChange={(evt) => {
              if (errors.responsibleParticipant) {
                setErrors((prev) => ({
                  ...prev,
                  responsibleParticipant: false,
                }))
              }

              setResponsibleParticipant(
                typeof evt.target.value === 'string' &&
                  evt.target.value !== 'clear'
                  ? evt.target.value
                  : ''
              )
            }}
            value={responsibleParticipant}
          >
            {responsibleParticipant !== '' && (
              <ListItem
                key={0}
                value={'clear'}
                style={{ width: '95%', margin: '0 auto' }}
              >
                <div className={classes.selectItem} style={{ color: 'grey' }}>
                  {'No Responsible Participant'}
                </div>
              </ListItem>
            )}
            {participants
              .filter(
                (participant) =>
                  participant.IsAdminYN === 'Y' ||
                  participant.IsApproverYN === 'Y'
              )
              .map((participant, index) => {
                return (
                  <ListItem
                    key={index}
                    value={participant.FullName || participant.email_address}
                    style={{ width: '95%', margin: '0 auto' }}
                  >
                    <div className={classes.selectItem}>
                      {participant.FullName || participant.email_address}
                    </div>
                  </ListItem>
                )
              })}
          </Select>
        </div>
        <Spacer spaceParam={'large'} />

        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
          }}
        >
          <Button color="secondary" onClick={handleCancel}>
            Cancel
          </Button>
          <Spacer spaceParam={'large'} />
          <Button color="primary" onClick={handleSave}>
            Save
          </Button>
        </div>
      </div>
    )
  }, [
    stakeholderName,
    handleCancel,
    handleSave,
    errors,
    responsibleParticipant,
    participants,
  ])

  return (
    <ResponsiveDialog
      open={isOpen}
      onClose={handleCancel}
      TransitionComponent={SlideLeft as any}
    >
      <Toolbar>
        <HeaderNavigation
          title={StakeholderID ? 'Edit Stakeholder' : 'Create New Stakeholder'}
          onNavClick={handleCancel as any}
          canGoBack={false}
        />
      </Toolbar>
      <DialogContent>{renderContent()}</DialogContent>
    </ResponsiveDialog>
  )
}
