import { faCheck, faPlus, faTimes } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  DialogContent,
  Button,
  FormControl,
  InputLabel,
  Select,
} from '@material-ui/core'
import React from 'react'
import { ResponsiveDialog } from '../Shared/ResponsiveDialog'
import { SlideLeft } from '../Shared/Transition'
import { TaskTemplate } from '../Task/TaskListToolbar'
//@ts-ignore
import { Draggable, DragDropContext, Droppable } from 'react-beautiful-dnd'
import { Spacer } from '../Shared/Spacer'
import { useAppDispatch } from '../_utils/reactReduxHooks'
import { TextareaAutoSizeFormControl } from '../Shared/TextareaAutosizeFormControl'
import { selectOrgRoles, updateTaskListTemplate } from '../_utils/apiUtils'
import { AdminConsoleDialog } from '../Shared/AdminConsoleDialog'
import { addNotification } from '../_actions'
import { removeHtml } from '../_utils/domUtils'

interface TemplateItem {
  draggableId: string
  id?: number
  Sequence: number
  AssignedToAppAcctID?: number
  OrgRoleID?: number
  Content: string
  Description?: string
  TaskItemHeaderYN?: string
}

export const AdminConsoleTaskTemplateEditDialog = ({
  open,
  closeDialog,
  template,
  orgId,
}: {
  open: boolean
  closeDialog: () => void
  template?: TaskTemplate
  orgId: string
}) => {
  const [templateList, setTemplateList] = React.useState<TemplateItem[]>()

  const [editingTemplateItem, setEditingTemplateItem] =
    React.useState<TemplateItem>()

  const [width, setWidth] = React.useState<number>(window.innerWidth)

  const [editItemName, setEditItemName] = React.useState<string>()

  const [editItemDescription, setEditItemDescription] = React.useState<string>()

  const [editItemRole, setEditItemRole] = React.useState<number>()

  const [roleList, setRoleList] = React.useState<any[]>()

  const [deleteTaskTemplateItem, setDeleteTaskTemplateItem] = React.useState<
    TemplateItem | undefined
  >()

  const [editedItemIdList, setEditedItemIdList] = React.useState<string[]>()

  const dispatch = useAppDispatch()

  const updateTaskTemplateItem = (id: string) => {
    const templateIndex = templateList?.findIndex(
      (template) => template.draggableId === id
    )

    if (
      templateList &&
      (templateIndex || templateIndex === 0) &&
      templateList[templateIndex] &&
      editItemName
    ) {
      templateList[templateIndex].Content = editItemName
    }

    if (
      templateList &&
      (templateIndex || templateIndex === 0) &&
      templateList[templateIndex] &&
      editItemDescription
    ) {
      templateList[templateIndex].Description = editItemDescription
    }

    if (
      templateList &&
      (templateIndex || templateIndex === 0) &&
      templateList[templateIndex] &&
      editItemRole
    ) {
      templateList[templateIndex].OrgRoleID = editItemRole
    }
    setTemplateList(templateList)
    setEditItemDescription(undefined)
    setEditItemName(undefined)
    setEditItemRole(undefined)
  }

  function handleWindowSizeChange() {
    setWidth(window.innerWidth)
  }

  React.useEffect(() => {
    selectOrgRoles(parseInt(orgId)).then((data) => {
      setRoleList(data)
    })

    window.addEventListener('resize', handleWindowSizeChange)
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange)
    }
  }, [orgId])

  React.useEffect(() => {
    if (!template) return
    const parsedTemplateJson = JSON.parse(template?.template_json)
    parsedTemplateJson.forEach((template: TemplateItem, index: number) => {
      template.draggableId = (index + 1).toString()
    })

    setTemplateList(parsedTemplateJson)
  }, [template, open])

  if (!template) return <div></div>

  const reorderList = (
    list: TemplateItem[],
    startIndex: number,
    endIndex: number
  ) => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)

    return result
  }

  const onDragEnd = (result: any) => {
    if (!result.destination || !templateList) {
      return
    }
    const newList = reorderList(
      templateList,
      result.source.index,
      result.destination.index
    )

    setTemplateList(newList)
  }

  const resetState = () => {
    setEditItemDescription(undefined)
    setEditItemName(undefined)
    setEditItemRole(undefined)
    setEditingTemplateItem(undefined)
    setEditedItemIdList(undefined)
  }

  const saveTemplate = () => {
    const tempTaskList = templateList

    tempTaskList?.forEach((templateItem: any, index: number) => {
      templateItem.Sequence = index
    })

    updateTaskListTemplate(
      orgId,
      template.id,
      JSON.stringify(tempTaskList)
    ).then((result) => {
      if (result) {
        dispatch(
          addNotification({
            type: 'success',
            message: 'Template Saved Successfully',
          })
        )
      } else {
        dispatch(
          addNotification({
            type: 'error',
            message: 'Template was unable to be saved',
          })
        )
      }
    })
  }

  //I know this is somewhat strange because I should be able to pass in the template as a
  //parameter instead of using state. It was not working with React beautiful DND and I believe
  //Is a known problem of react not knowing what was changed between rerenders on the drag and drop
  //so I did it this way.
  const deleteTemplateItem = () => {
    const newList = templateList?.filter(
      (originalTemplate) =>
        originalTemplate.draggableId !== deleteTaskTemplateItem?.draggableId
    )

    setTemplateList(newList)
  }

  const addTemplateItem = () => {
    if (templateList) {
      setTemplateList((currentList) => {
        if (currentList) {
          return [
            ...currentList,
            {
              draggableId: (templateList?.length + 1).toString(),
              Content: '',
              Sequence: templateList?.length + 1,
            },
          ]
        } else {
          return [
            {
              draggableId: (templateList?.length + 1).toString(),
              Content: '',
              Sequence: templateList?.length + 1,
            },
          ]
        }
      })
    }
  }

  const isMobile = width <= 960

  return (
    <ResponsiveDialog open={open} TransitionComponent={SlideLeft as any}>
      <DialogContent style={{ padding: '0' }}>
        <div
          style={{
            position: 'sticky',
            top: '0',
            backgroundColor: 'white',
            height: '125px',

            display: 'flex',
            alignItems: 'center',
            borderBottom: '1px solid black',
            justifyContent: 'center',
            zIndex: 1,
            fontSize: '32px',
            padding: '30px',
          }}
        >
          <div>TEMPLATE: {template.template_name}</div>

          <Spacer spaceParam={'large'} />
          <FontAwesomeIcon
            icon={faPlus}
            style={{
              color: `rgb(64, 148, 195)`,
              padding: '5px',
              cursor: 'pointer',
            }}
            onClick={() => addTemplateItem()}
          />
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            marginBottom: '15px',
            padding: '10px',
          }}
        >
          {templateList && templateList.length > 0 ? (
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="droppable">
                {(provided: any, snapshot: any) => (
                  <div {...provided.droppableProps} ref={provided.innerRef}>
                    {templateList?.map((template, index) => {
                      const isCurrentEdit =
                        editingTemplateItem?.draggableId ===
                        template.draggableId

                      const wasEdited = editedItemIdList?.includes(
                        template.draggableId
                      )

                      const isHeader = template.TaskItemHeaderYN === 'Y'

                      const orgRole = roleList?.find(
                        (role) => role.OrgRoleID === Number(template.OrgRoleID)
                      )

                      return (
                        <Draggable
                          key={template.draggableId}
                          draggableId={template.draggableId}
                          index={index}
                          isDragDisabled={isCurrentEdit}
                        >
                          {(provided: any, snapshot: any) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                minHeight: isHeader ? '50px' : '100px',
                                borderBottom: isHeader
                                  ? '1px solid black'
                                  : '1px solid lightgray',
                                width: isMobile ? '100%' : '550px',
                                ...provided.draggableProps.style,
                                backgroundColor: isHeader
                                  ? 'lightgrey'
                                  : 'white',
                                padding: '10px',
                                borderTop: wasEdited && '5px solid green',
                              }}
                            >
                              <div style={{ width: '80%' }}>
                                {isCurrentEdit ? (
                                  <div>
                                    <div>Task Item Title: </div>
                                    <TextareaAutoSizeFormControl
                                      //@ts-ignore
                                      value={
                                        editItemName !== undefined
                                          ? editItemName
                                          : template.Content
                                      }
                                      onChange={(e: any) =>
                                        setEditItemName(e.target.value)
                                      }
                                      // style={{ height: '30px' }}
                                      //onExitFullScreen={this.handleCloseName}
                                    />
                                    <Spacer spaceParam={'large'} />
                                    {!isHeader && (
                                      <div>
                                        <div>Description: </div>
                                        <TextareaAutoSizeFormControl
                                          //@ts-ignore
                                          value={
                                            editItemDescription !== undefined ||
                                            !template.Description
                                              ? editItemDescription
                                              : removeHtml(
                                                  template?.Description
                                                )
                                          }
                                          onChange={(e: any) =>
                                            setEditItemDescription(
                                              e.target.value
                                            )
                                          }
                                          //onExitFullScreen={this.handleCloseName}
                                        />
                                      </div>
                                    )}
                                    <FormControl>
                                      <InputLabel>Role</InputLabel>
                                      <Select
                                        native
                                        value={
                                          editItemRole
                                            ? editItemRole
                                            : template?.OrgRoleID
                                        }
                                        onChange={(e: any) => {
                                          setEditItemRole(e.target.value)
                                        }}
                                        className="w-100"
                                      >
                                        {
                                          <option key={-1} value={-1}>
                                            --No Role--
                                          </option>
                                        }
                                        {roleList?.map((role) => (
                                          <option
                                            key={role.OrgRoleID}
                                            value={role.OrgRoleID}
                                          >
                                            {role.RoleName}
                                          </option>
                                        ))}
                                      </Select>
                                    </FormControl>
                                  </div>
                                ) : (
                                  <div>
                                    <div
                                      style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        fontWeight: 600,
                                        wordBreak: 'break-word',
                                      }}
                                    >
                                      {template.Content}
                                    </div>
                                    {template.Description && (
                                      <div>
                                        <div style={{ fontSize: '14px' }}>
                                          {removeHtml(template.Description)}
                                        </div>
                                      </div>
                                    )}
                                    {orgRole && (
                                      <>
                                        <div style={{ fontSize: '14px' }}>
                                          Role: {orgRole.RoleName}
                                        </div>
                                      </>
                                    )}
                                  </div>
                                )}
                              </div>

                              {isCurrentEdit ? (
                                <div
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                  }}
                                >
                                  <FontAwesomeIcon
                                    icon={faCheck}
                                    style={{
                                      cursor: 'pointer',
                                    }}
                                    onClick={() => {
                                      updateTaskTemplateItem(
                                        template.draggableId
                                      )
                                      setEditedItemIdList(
                                        editedItemIdList
                                          ? [
                                              ...editedItemIdList,
                                              template.draggableId,
                                            ]
                                          : [template.draggableId]
                                      )
                                      setEditingTemplateItem(undefined)
                                    }}
                                    size={'2x'}
                                  />
                                  <Spacer />
                                  <FontAwesomeIcon
                                    icon={faTimes}
                                    style={{
                                      cursor: 'pointer',
                                    }}
                                    onClick={() => {
                                      setEditingTemplateItem(undefined)
                                    }}
                                    size={'2x'}
                                  />
                                </div>
                              ) : (
                                <div
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                    flexDirection: 'column',
                                  }}
                                >
                                  <Button
                                    onClick={() =>
                                      setDeleteTaskTemplateItem(template)
                                    }
                                  >
                                    Delete
                                  </Button>{' '}
                                  <AdminConsoleDialog
                                    open={!!deleteTaskTemplateItem}
                                    content={
                                      'Are you sure you would like to delete this task template item?'
                                    }
                                    onConfirm={() => {
                                      deleteTemplateItem()
                                      setDeleteTaskTemplateItem(undefined)
                                    }}
                                    consoleRole="destructive"
                                    onCancel={() =>
                                      setDeleteTaskTemplateItem(undefined)
                                    }
                                  />
                                  <Button
                                    onClick={() => {
                                      setEditingTemplateItem(template)
                                      setEditItemName(undefined)
                                      setEditItemDescription(undefined)
                                    }}
                                  >
                                    Edit
                                  </Button>{' '}
                                </div>
                              )}
                            </div>
                          )}
                        </Draggable>
                      )
                    })}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          ) : (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {' '}
              There are no template items, you can add one using the + in the
              top right{' '}
            </div>
          )}
        </div>
      </DialogContent>
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          position: 'sticky',
          bottom: '0',
          padding: '20px',
          borderTop: '1px solid black',
        }}
      >
        <Button
          style={{
            textTransform: `none`,
            color: `rgb(64, 148, 195)`,
            borderRadius: 'none',
          }}
          onClick={() => {
            resetState()
            closeDialog()
          }}
        >
          {' '}
          Cancel
        </Button>
        <Button
          style={{
            backgroundColor: `rgb(64, 148, 195)`,
            color: 'white',
            textTransform: `none`,
            borderRadius: 'none',
          }}
          onClick={() => {
            if (editingTemplateItem) return

            saveTemplate()
            resetState()
            closeDialog()
          }}
        >
          Save
        </Button>
      </div>
    </ResponsiveDialog>
  )
}
