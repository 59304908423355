// @ts-nocheck
// TODO: Typescript
import { Hidden } from '@material-ui/core'
import React from 'react'

export const DesktopLayout = ({ children }) => (
  <Hidden smDown>{children}</Hidden>
)
export const MobileLayout = ({ children }) => <Hidden mdUp>{children}</Hidden>
export const MobileSmallLayout = ({ children }) => (
  <Hidden smUp>{children}</Hidden>
)
export const MobileMdOnlyLayout = ({ children }) => (
  <Hidden only={['lg', 'xl', 'xs']}>{children}</Hidden>
)
