// @ts-nocheck
// TODO: Typescript
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
  StretchLayout,
  StretchHeader,
  StretchBody,
} from '../Layouts/StretchLayout'
import { ViewHeader } from './ViewHeader'
import { HeaderNavigation } from './HeaderNavigation'
import { FormControl, InputLabel, TextareaAutosize } from '@material-ui/core'
import classes from './TextareaAutosizeFormControl.module.scss'
import { cx } from '../_utils/objectUtils'
import { txtElementStart } from '../_utils/domUtils'

interface Props {
  onClick?: (input?: any) => any
  fullScreen?: boolean
  fullScreenTitle?: string
  label?: string
  value?: string
  onExitFullScreen?: (input?: any) => any
  onChange?: (input?: any) => any
  name?: string
  readOnly?: boolean
  disabled?: boolean
  maxLength?: number
  border?: any
  resizable?: boolean
  maxRows?: number
  minRows?: number
  classes?: any
  staticLabel?: boolean
}
/**
 * @extends {Component<Props, {}>}}
 * Styles a text with a label based on material ui form control
 */
export class TextareaAutoSizeFormControl extends Component<Props, any> {
  constructor(props) {
    super(props)
    this.handleClick = this.handleClick.bind(this)
    this.handleFocus = this.handleFocus.bind(this)
    this.handleBlur = this.handleBlur.bind(this)
    this.handleFullScreenExit = this.handleFullScreenExit.bind(this)
    this.state = {}
  }

  componentDidUpdate(prevProps) {
    // Reset the cursor so that the user does not get lost
    if (prevProps.fullScreen !== this.props.fullScreen) {
      if (this.props.fullScreen) txtElementStart(this.textEl)
      else this.textEl.blur()
    }
  }

  handleClick() {
    const { onClick } = this.props
    this.textEl.focus()
    onClick()
  }

  handleFocus() {
    this.setState({
      focused: true,
    })
  }

  handleBlur() {
    this.setState({
      focused: false,
    })
  }

  handleFullScreenExit() {
    const { onExitFullScreen } = this.props
    onExitFullScreen()
  }

  getBorderClass() {
    const { border } = this.props
    switch (border) {
      case true:
        return 'borderFull'
      case false:
        return 'borderNone'
      default:
        return 'border' + border
    }
  }

  render() {
    const {
      value,
      className,
      fullScreen,
      fullScreenTitle,
      onChange,
      name,
      label,
      staticLabel,
      readOnly,
      disabled,
      maxLength,
      maxRows,
      minRows,
      resizable,
    } = this.props
    const hasValue = value ? true : false
    const ownClasses = this.props.classes

    return (
      <StretchLayout
        onClick={this.handleClick}
        fullScreen={fullScreen}
        className={cx(
          classes.root,
          className,
          fullScreen ? classes.fullScreen : '',
          classes[this.getBorderClass()],
          resizable ? classes.resize : ''
        )}
      >
        {fullScreenTitle ? (
          <StretchHeader>
            {fullScreen ? (
              <ViewHeader>
                <HeaderNavigation
                  canGoBack={false}
                  onNavClick={this.handleFullScreenExit}
                  title={fullScreenTitle}
                />
              </ViewHeader>
            ) : null}
          </StretchHeader>
        ) : null}
        <StretchBody>
          {
            <FormControl className={cx(classes.formControl)}>
              <InputLabel
                className="position-relative"
                shrink={staticLabel || hasValue || this.state.focused}
                filled={staticLabel || hasValue}
                hidden={fullScreen}
              >
                {label}
              </InputLabel>
              <TextareaAutosize
                readOnly={readOnly}
                disabled={disabled}
                name={name}
                onFocus={this.handleFocus}
                onBlur={this.handleBlur}
                ref={(el) => (this.textEl = el)}
                className={cx(
                  classes.textArea,
                  classes.disabled,
                  ownClasses.textArea
                )}
                onChange={onChange}
                value={value}
                maxLength={maxLength}
                maxRows={maxRows}
                minRows={minRows}
              />
            </FormControl>
          }
        </StretchBody>
      </StretchLayout>
    )
  }
}

TextareaAutoSizeFormControl.propTypes = {
  onClick: PropTypes.func,
  fullScreen: PropTypes.bool,
  fullScreenTitle: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
  onExitFullScreen: PropTypes.func,
  onChange: PropTypes.func,
  name: PropTypes.string,
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  maxLength: PropTypes.number,
  border: PropTypes.any,
  resizable: PropTypes.bool,
  maxRows: PropTypes.number,
  minRows: PropTypes.number,
  classes: PropTypes.object,
  staticLabel: PropTypes.bool,
}

TextareaAutoSizeFormControl.defaultProps = {
  onClick: () => {},
  onExitFullScreen: () => {},
  fullScreen: false,
  fullScreenTitle: '',
  label: '',
  staticLabel: false,
  value: '',
  border: 'Bottom',
  resizable: false,
  classes: {},
}
