interface ChildData {
  data: any
  parent: TreeNode
}

export interface TreeNode {
  parentKey: any
  parent?: TreeNode // Parent of all childParents
  data: any
  children: ChildData[]
  childParents: TreeNode[]
  prev?: TreeNode
  next?: TreeNode
  numOfSelected: number
  numOfChildren: number
}

interface TreeViewCacheModel {
  top?: TreeNode
  bottom?: TreeNode
}

type ParentSelectedType = 'all' | 'partial' | 'none'

export interface TreeViewApi {
  getChildParent: (key: string) => TreeNode
  setSelectedParent: (parent: TreeNode, selected: boolean) => void
  setSelected: (key: string, selected: boolean) => void
  getSelected: () => string[]
  isChildSelected: (key: string) => boolean
  getTop: () => TreeNode | undefined
}

export interface TreeViewCacheOptions {
  childKey: string
  parentKey: string
  isChildSelected: (child: any) => boolean
  parentsPath: string
  childrenPath: string
}

type TreeNodeMap = {
  [key: string]: TreeNode
}

export function TreeViewCache(parent: any, options: TreeViewCacheOptions) {
  let selectedSet = new Set<string>()
  let childToParent: TreeNodeMap = {}
  let tree: TreeViewCacheModel = {}
  TreeViewCacheBuilder(parent, tree, childToParent, selectedSet, options)
  let treeApi: TreeViewApi = {
    getChildParent: (key: string) => childToParent[key],
    setSelectedParent: (parent: TreeNode, selected: boolean) => {
      const changeCount = setSelectedParentHelper(parent, selected)
      let curParent: TreeNode | undefined = parent?.parent
      while (curParent) {
        curParent.numOfSelected += changeCount
        curParent = curParent.parent
      }
    },
    setSelected: (key: string, selected: boolean) => {
      const isCurSelected = selectedSet.has(key)
      const parent = childToParent[key]
      if (!parent) return

      if (isCurSelected && !selected) {
        selectedSet.delete(key)
        let curParent: TreeNode | undefined = parent
        while (curParent) {
          curParent.numOfSelected--
          curParent = curParent.parent
        }
      } else if (!isCurSelected && selected) {
        selectedSet.add(key)
        let curParent: TreeNode | undefined = parent
        while (curParent) {
          curParent.numOfSelected++
          curParent = curParent.parent
        }
      }
    },
    getSelected: () => Array.from(selectedSet),
    isChildSelected: (key: string) => selectedSet.has(key),
    getTop: () => tree.top,
  }

  function setSelectedParentHelper(
    parent: TreeNode,
    selected: boolean
  ): number {
    if (!parent.children) return 0

    let changeCount = 0

    if (parent.childParents) {
      parent.childParents.forEach((c) => {
        changeCount += setSelectedParentHelper(c, selected)
      })
    }

    parent.children.forEach((c) => {
      const childKey = c.data[options.childKey]
      const isSelected = selectedSet.has(childKey)
      if (isSelected && !selected) {
        changeCount--
        selectedSet.delete(childKey)
      } else if (!isSelected && selected) {
        changeCount++
        selectedSet.add(childKey)
      }
    })

    parent.numOfSelected += changeCount

    return changeCount
  }

  return treeApi
}

export function getSelectedType(tree: TreeNode): ParentSelectedType {
  if (tree.numOfSelected === 0) return 'none'
  else if (tree.numOfSelected === tree.numOfChildren) return 'all'

  return 'partial'
}

function TreeViewCacheBuilder(
  parent: any,
  tree: TreeViewCacheModel,
  childToParent: TreeNodeMap,
  selectedSet: Set<string>,
  options: TreeViewCacheOptions
) {
  const { childrenPath, parentsPath, isChildSelected, childKey, parentKey } =
    options
  if (!parent || !parent[parentKey]) return tree

  const childrenData: any[] = parent[childrenPath] || []
  let numOfSelected = 0
  let numOfChildren = 0

  let newNode: TreeNode = {
    parentKey: parent[parentKey],
    data: parent,
    children: [],
    numOfSelected: 0,
    numOfChildren,
    childParents: [],
  }

  childrenData.forEach((c) => {
    if (!c[childKey]) return

    newNode.children.push({
      parent: newNode,
      data: c,
    })

    childToParent[c[childKey]] = newNode

    if (!isChildSelected(c)) return

    selectedSet.add(c[childKey])
    numOfSelected++
  })

  newNode.numOfSelected = numOfSelected
  newNode.numOfChildren = newNode.children.length

  // The current parent (newNode) has parents in it
  if (parent[parentsPath] && parent[parentsPath].length) {
    newNode.childParents = []
    const parentsTree: TreeViewCacheModel = {}
    parent[parentsPath].forEach((p: any) => {
      if (p[parentKey]) {
        const newParent = TreeViewCacheBuilder(
          p,
          parentsTree,
          childToParent,
          selectedSet,
          options
        )
        if (newParent.top) {
          newNode.numOfSelected += newParent.top.numOfSelected
          newNode.numOfChildren += newParent.top.numOfChildren
          newParent.top.parent = newNode
          newNode.childParents.push(newParent.top)
        }
      }
    })
  }

  // These can be mostly ignored at the api level
  // These are required for proper iteration of the childParents and to get the proper nested count
  if (!tree.top || !tree.bottom) tree.bottom = tree.top = newNode
  else {
    newNode.prev = tree.top
    tree.top.next = newNode
    tree.top = newNode
  }

  return tree
}
