import * as React from 'react'
import { http } from '../_utils/http'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faFileAlt,
  faClipboardList,
  faUsers,
  faHome,
  faUserPlus,
  faListUl,
  faRetweet,
  faLock,
  IconDefinition,
  faFileChartLine,
} from '@fortawesome/pro-solid-svg-icons'
import { faGripLines } from '@fortawesome/pro-light-svg-icons'
import classes from './ExecutiveReportBuilder.module.scss'
import { Switch } from '@material-ui/core'
import { Button } from '../Shared/Buttons'
import { Spacer } from '../Shared/Spacer'
//@ts-ignore
import { Draggable, DragDropContext, Droppable } from 'react-beautiful-dnd'
import Moment from 'react-moment'
import iccLogo from '../_images/ICC_Lato_white.png'
import { appDateTimeFormat } from '../_constants'
import { useElementSize } from 'usehooks-ts'
import { formElements } from './IssueExecBriefing'

const getItemStyle = (isDragging: any, draggableStyle: any) => ({
  listStyle: 'none',
  paddingTop: '5px',
  ...draggableStyle,
})

interface Props {
  IssueID: number
  onClose: () => void
  setShowFormSubmit: () => void
  formSubmitText: string
}
interface Section {
  selected: boolean
  section: string
  title: string
  icon: IconDefinition
}

const initialSections = [
  {
    section: 'activity',
    selected: false,
    icon: faListUl,
    title: 'Activity Log for past 30 days',
  },
  {
    section: 'participants',
    selected: false,
    icon: faUserPlus,
    title: 'Participants',
  },
  {
    section: 'holdingstatement',
    selected: false,
    icon: faFileAlt,
    title: 'Approved Holding Statement',
  },
  {
    section: 'stakeholders',
    selected: false,
    icon: faUsers,
    title: 'Stakeholders',
  },
  { section: 'tasks', selected: false, icon: faClipboardList, title: 'Tasks' },
  {
    section: 'executivetracker',
    selected: false,
    icon: faFileChartLine,
    title: 'Executive Tracker Updates',
  },
]

export const ExecutiveReportBuilder = ({
  IssueID,
  onClose,
  setShowFormSubmit,
  formSubmitText,
}: Props) => {
  const [sections, setSections] =
    React.useState<Array<Section>>(initialSections)
  const [ERBbody, setERBbody] = React.useState<any>('')
  const [componentRef, { width }] = useElementSize()
  const headerRef = React.useRef<HTMLHeadingElement | null>(null)

  const fetchERBbody = React.useCallback(() => {
    const getERBbody = async (): Promise<any> => {
      var customHeaders: any = []

      customHeaders.push({ name: 'issueid', value: IssueID })

      const result = await http<any>({
        path: '/Issue/ERBbody',
        headers: customHeaders,
      })
      if (result.ok && result.body) {
        setERBbody(result.body)
      } else {
        setERBbody('')
      }
    }
    getERBbody()
  }, [IssueID])

  const fetchSelectedERBsections = React.useCallback(() => {
    const getSelectedERBsections = async (): Promise<any> => {
      var customHeaders: any = []

      customHeaders.push({ name: 'issueid', value: IssueID })

      const result = await http<any>({
        path: '/Issue/ERBsectionsList',
        headers: customHeaders,
      })
      if (result.ok && result.body) {
        setSections((prev) => {
          let savedSections: Array<string> = result.body
          let newSections: Array<Section> = []
          savedSections.forEach((savedSection) => {
            let found = prev.find((section) => section.section === savedSection)
            if (found) {
              newSections.push({ ...found, selected: true })
            }
          })
          prev.forEach((section) => {
            if (!savedSections.includes(section.section))
              newSections.push(section)
          })

          return newSections
        })
      }
    }
    getSelectedERBsections()
  }, [IssueID])

  React.useEffect(() => {
    fetchSelectedERBsections()
    fetchERBbody()
    headerRef.current?.scrollIntoView()
  }, [IssueID, fetchERBbody, fetchSelectedERBsections])

  function saveSelectedERBsections() {
    const putSelectedERBsections = async (): Promise<any> => {
      var customHeaders: any = []

      customHeaders.push({ name: 'issueid', value: IssueID })
      customHeaders.push({
        name: 'selectedsections',
        value: JSON.stringify(
          sections
            .filter((section) => section.selected)
            .map((section) => section.section)
        ),
      })

      const result = await http<any>({
        path: '/Issue/ERBsectionsSave',
        headers: customHeaders,
      })
      if (result.ok) {
        fetchERBbody()
      }
    }
    putSelectedERBsections()
  }

  function handleToggle(section: string) {
    setSections((prev) => {
      let sectionIndex = prev.findIndex(
        (element) => element.section === section
      )
      prev[sectionIndex].selected = !prev[sectionIndex].selected
      return prev
    })
    saveSelectedERBsections()
  }

  function handleSwap(payload: any) {
    if (payload.destination && payload.destination.index !== null) {
      setSections((prev) => {
        let extractedSection = prev.splice(payload.source.index, 1)
        prev.splice(payload.destination.index, 0, extractedSection[0])
        return prev
      })
    }
    saveSelectedERBsections()
  }

  function renderERBsections() {
    return (
      <div style={{ minWidth: '350px' }}>
        <h2>Manage Briefing Template</h2>
        <Spacer spaceParam={'medium'} />
        <div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-start',
            }}
          >
            <div
              className={classes.iconWrapper}
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-start',
                width: '260px',
              }}
            >
              <FontAwesomeIcon
                className={classes.icon}
                icon={faHome}
                size="lg"
              />
              <Spacer />
              <span>Title & Description</span>
            </div>
            <div
              style={{
                paddingLeft: '1rem',
                paddingBottom: '.5rem',
              }}
              className={classes.iconWrapper}
            >
              <FontAwesomeIcon
                className={classes.icon}
                icon={faLock}
                size="lg"
              />
            </div>
          </div>
          <div
            className={classes.iconWrapper}
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-start',
            }}
          >
            <div
              className={classes.iconWrapper}
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-start',
                width: '260px',
              }}
            >
              <FontAwesomeIcon
                className={classes.icon}
                icon={faRetweet}
                size="lg"
              />
              <Spacer />
              <span>Severity, Trend and Mode</span>
            </div>
            <div
              style={{ paddingLeft: '1rem', paddingBottom: '.5rem' }}
              className={classes.iconWrapper}
            >
              <FontAwesomeIcon
                className={classes.icon}
                icon={faLock}
                size="lg"
              />
            </div>
          </div>
          <DragDropContext onDragEnd={handleSwap}>
            <Droppable droppableId="taskListDroppable">
              {(provided: any, snapshot: any) => (
                <div
                  className={classes.root}
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                >
                  {sections.map((section, index) => {
                    return (
                      <DraggableWrapper
                        key={section.section}
                        draggableID={section.section}
                        index={index}
                      >
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'flex-start',
                            alignItems: 'center',
                          }}
                          className={classes.iconWrapper}
                        >
                          <div
                            className={classes.iconWrapper}
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'flex-start',
                              width: '260px',
                            }}
                          >
                            <FontAwesomeIcon
                              className={classes.icon}
                              icon={section.icon}
                              size="lg"
                            />
                            <Spacer />
                            <span>{section.title}</span>
                          </div>
                          <Switch
                            checked={section.selected}
                            color="primary"
                            onChange={() => handleToggle(section.section)}
                            inputProps={{
                              'aria-label': 'controlled',
                            }}
                          />
                          <FontAwesomeIcon icon={faGripLines} />
                        </div>
                      </DraggableWrapper>
                    )
                  })}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
          <Spacer spaceParam={'large'} />
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <Button color="standard" onClick={() => setShowFormSubmit()}>
              {formSubmitText}
            </Button>
            <Spacer spaceParam={'large'} />
            <Button color="standard" onClick={() => onClose()}>
              Close
            </Button>
          </div>
          <Spacer spaceParam={'large'} />
        </div>
      </div>
    )
  }

  function renderERBbody() {
    return (
      <div style={{ width: '100%' }}>
        <h2>Preview</h2>
        <Spacer spaceParam={'medium'} />
        <div style={{ border: 'solid 1px lightgrey' }}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
              backgroundColor: '#003e6b',
              color: 'white',
              padding: '1rem 1rem',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                justifyContent: 'flex-start',
              }}
            >
              <div style={{ fontSize: '24px' }}>Executive Briefing</div>
              <Moment format={appDateTimeFormat} date={new Date()} />
            </div>
            <Spacer spaceParam={'large'} />
            <img
              height={50}
              src={iccLogo}
              alt="In Case of Crisis"
              style={{ paddingBottom: '0.7rem' }}
            />
          </div>
          <div
            dangerouslySetInnerHTML={{
              __html: ERBbody.html,
            }}
            style={{ padding: '0.5rem 0.5rem 0 0.5rem' }}
          />

          <div style={{ padding: '0 0.5rem 0.5rem 0.5rem' }}>
            {formElements.map((section, index) => (
              <div key={section.id}>
                <details open>
                  <summary style={{ color: '#003e6b' }}>
                    &nbsp;{section.title}
                  </summary>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'flex-start',
                    }}
                  >
                    <div
                      style={{
                        width: '19px',
                        minWidth: '19px',
                        display: 'block',
                      }}
                    ></div>
                  </div>
                </details>
                {index !== 5 && (
                  <div style={{ padding: '12px 0px 8px' }}>
                    <div style={{ border: 'solid 1px #003e6b' }}></div>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    )
  }

  return (
    <div ref={headerRef}>
      <div
        style={{
          display: 'flex',
          flexDirection: width < 800 ? 'column' : 'row',
        }}
        ref={componentRef}
      >
        {renderERBsections()}
        <Spacer spaceParam={'medium'} />
        {renderERBbody()}
      </div>
    </div>
  )
}

type DraggableWrapperProps = {
  key: string
  draggableID: string
  index: number
}

const DraggableWrapper = ({
  key,
  draggableID,
  index,
  children,
}: React.PropsWithChildren<DraggableWrapperProps>) => (
  <Draggable key={key} draggableId={draggableID} index={index}>
    {(provided: any, snapshot: any) => (
      <div
        className={classes.iconWrapper}
        style={{
          ...getItemStyle(snapshot.isDragging, provided.draggableProps.style),
        }}
        ref={provided.innerRef}
        {...provided.draggableProps}
        {...provided.dragHandleProps}
      >
        {children}
      </div>
    )}
  </Draggable>
)
