// @ts-nocheck
// TODO: Typescript
import React, { Component } from 'react'
import classes from './TaskItemDetailHeader.module.scss'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import DateFnsUtils from '@date-io/moment'
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers'
import Select from '@material-ui/core/Select'
import { InputLabel, FormControl, TextField, Popper } from '@material-ui/core'
import { cx } from '../_utils/objectUtils'
import {
  generateInputChangeSubmit,
  generateDatePickerChangeSubmit,
  generateInputSubmit,
} from '../_utils/reduxUtils'
import { TaskDetailActions } from './_actions'
import { TextareaAutoSizeFormControl } from '../Shared/TextareaAutosizeFormControl'
import { selectTaskItemById } from './_selectors'
import { getLocalDate } from '../_utils/dateUtils'
import { Autocomplete } from '@material-ui/lab'
import { timeOptions } from './_constants'
import { selectOrgRoles } from '../_utils/apiUtils'

const inputStyles = { marginTop: 16, width: 100 }

const getTimeFromDate = (date: Date) => {
  const localDate = getLocalDate(new Date(date))
  var hours = localDate.getHours()
  var minutes = localDate.getMinutes()
  var ampm = hours >= 12 ? 'pm' : 'am'
  hours = hours % 12
  hours = hours ? hours : 12 // the hour '0' should be '12'
  minutes = minutes < 10 ? '0' + minutes : minutes
  var strTime = hours + ':' + minutes + ' ' + ampm
  return strTime
}

const PopperMy = (props) => {
  return <Popper {...props} style={{ width: 250 }} placement="bottom-start" />
}

/**
 * @extends {Component<Props, {}>}}
 */
export class TaskItemDetailHeader extends Component {
  constructor(props) {
    super(props)
    this.handleInputChange = generateInputChangeSubmit(this, props.submitDelay)
    this.handleDateChange = generateDatePickerChangeSubmit(
      this,
      props.submitDelay
    )
    this.handleTitleChange = this.handleTitleChange.bind(this)
    this.handleTitleSubmit = generateInputSubmit(
      this,
      props.submitDelay.Content
    )
    this.handleNameClick = this.handleNameClick.bind(this)
    this.handleCloseName = this.handleCloseName.bind(this)
    this.handleAssignedChange = this.handleAssignedChange.bind(this)
    this.handleRoleChange = this.handleRoleChange.bind(this)
    this.handleAssignedFocus = this.handleAssignedFocus.bind(this)
    this.handleAssignedBlur = this.handleAssignedBlur.bind(this)
    this.handleAssignedTimeChange = this.handleAssignedTimeChange.bind(this)
    this.dueDateEl = undefined
    this.state = {}
  }

  componentDidMount() {
    selectOrgRoles(this.props.OrgID).then((data) => {
      this.setState({
        roleList: data,
      })
    })

    this.setState({
      Content: this.props.Content,
      AssignedToAppAcctID: this.props.AssignedToAppAcctID, // Use state for faster ui update
      OrgRoleID: this.props.OrgRoleID,
      currentDate: this.props.DueByUTC
        ? getLocalDate(this.props.DueByUTC)
        : undefined,
      currentTime: this.props.DueByUTC
        ? getTimeFromDate(this.props.DueByUTC)
        : '12:00 pm',
      orgId: this.props.OrgID,
    })
  }

  componentDidUpdate(prevProps) {
    const { Content } = this.props
    if (prevProps.Content !== this.props.Content) {
      this.setState({
        Content,
      })
    }

    if (
      prevProps.mobile !== this.props.mobile &&
      !this.props.mobile &&
      this.state.editName
    ) {
      this.setState({
        editName: false,
      })
    }

    if (this.props.AssignedToAppAcctID !== this.state.AssignedToAppAcctID) {
      this.setState({
        AssignedToAppAcctID: this.props.AssignedToAppAcctID,
      })
    }

    if (this.props.OrgID !== prevProps.OrgID) {
      selectOrgRoles(this.props.OrgID).then((data) => {
        this.setState({
          roleList: data,
        })
      })
    }
  }

  handleAssignedFocus() {
    this.setState({
      assignedFocused: true,
    })
  }

  handleAssignedTimeChange() {
    if (!this.state.currentDate) {
      this.props.onSubmit('DueByUTC', null, this.props)
      return
    }
    const date = new Date(this.state.currentDate)

    if (!this.state.currentTime) {
      this.props.onSubmit('DueByUTC', new Date(date.toUTCString()), this.props)
    } else {
      const splitTime = this.state.currentTime.split(' ')
      let hour = splitTime[0].split(':')[0]
      let minute = splitTime[0].split(':')[1]
      if (splitTime[1] === 'am') {
        hour = parseInt(hour) % 12
      } else if (parseInt(hour) === 12) {
        hour = 12
      } else {
        hour = parseInt(hour) + 12
      }
      date.setHours(hour)
      date.setMinutes(minute)

      this.props.onSubmit('DueByUTC', new Date(date.toUTCString()), this.props)
    }
  }

  handleAssignedBlur() {
    this.setState({
      assignedFocused: false,
    })
  }

  handleTitleChange(evt) {
    const { value } = evt.target
    this.setState({
      Content: value,
    })

    // Handle the submit
    this.handleTitleSubmit('Content', value, this.props)
  }

  handleAssignedChange(evt) {
    const { value } = evt.target
    const AssignedToAppAcctID = value ? parseInt(value) : -1
    this.setState({
      AssignedToAppAcctID,
    })
    // Handle the submit
    this.handleInputChange(
      'AssignedToAppAcctID',
      AssignedToAppAcctID,
      this.props
    )
  }

  handleRoleChange(evt) {
    const { value } = evt.target
    const roleId = value ? value : -1
    this.setState({
      OrgRoleID: roleId,
    })
    this.handleInputChange('OrgRoleID', roleId, this.props)
  }

  handleNameClick() {
    const { mobile, disabled } = this.props
    if (disabled) return

    if (!this.state.editName && mobile) {
      this.setState({
        editName: true,
      })
    }
  }

  handleCloseName() {
    if (this.state.editName) {
      this.setState({
        editName: false,
      })
    }
  }

  render() {
    const { participants, disabled } = this.props
    const { AssignedToAppAcctID, OrgRoleID } = this.state

    return (
      <div className={classes.root}>
        <TextareaAutoSizeFormControl
          name="Content"
          className={classes.content}
          //readOnly={(mobile && !this.state.editName) || disabled}
          //fullScreen={this.state.editName}
          //fullScreenTitle="Edit Name"
          onClick={this.handleNameClick}
          label="Name"
          value={this.state.Content}
          onChange={this.handleTitleChange}
          //onExitFullScreen={this.handleCloseName}
        />
        <div className={cx(classes.actions, 'clearfix')}>
          <div className={classes.dueDateContainer}>
            <FormControl style={{ marginBottom: '8px', width: '100%' }}>
              <InputLabel
                shrink={
                  AssignedToAppAcctID !== -1 || this.state.assignedFocused
                }
                filled={AssignedToAppAcctID !== -1}
              >
                Assign
              </InputLabel>
              <Select
                id={classes.assignSelect}
                native
                value={AssignedToAppAcctID}
                name="AssignedToAppAcctID"
                onFocus={this.handleAssignedFocus}
                onBlur={this.handleAssignedBlur}
                onChange={this.handleAssignedChange}
                className="w-100"
                disabled={disabled}
                style={inputStyles}
              >
                {
                  <option key={-1} value={-1}>
                    {AssignedToAppAcctID !== -1 ? '--Not Assigned--' : ''}
                  </option>
                }
                {participants.map((p) =>
                  p.AppAcctID ? (
                    <option key={p.ParticipantID} value={p.AppAcctID}>
                      {p.email_address}
                    </option>
                  ) : null
                )}
              </Select>
            </FormControl>
            <FormControl style={{ width: '100%' }}>
              <InputLabel>Role</InputLabel>
              <Select
                id={classes.assignSelect}
                native
                value={OrgRoleID}
                onChange={this.handleRoleChange}
                className="w-100"
                disabled={disabled}
                style={inputStyles}
              >
                {
                  <option key={-1} value={-1}>
                    -- No Role --
                  </option>
                }
                {this.state.roleList?.map((role) => (
                  <option key={role.OrgRoleID} value={role.OrgRoleID}>
                    {role.RoleName}
                  </option>
                ))}
              </Select>
            </FormControl>
          </div>
          <div className={classes.dueDateContainer}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                disabled={disabled}
                className="w-100"
                clearable
                fullWidth
                format="MM/DD/YYYY"
                margin="normal"
                label="Due Date"
                onChange={(dateUtil, value) => {
                  this.setState({ currentDate: value }, () =>
                    this.handleAssignedTimeChange()
                  )
                }}
                value={
                  this.props.DueByUTC ? getLocalDate(this.props.DueByUTC) : null
                }
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
              />
            </MuiPickersUtilsProvider>
            {this.state.currentDate && (
              <Autocomplete
                options={timeOptions}
                disableClearable
                popupIcon={false}
                autoComplete
                includeInputInList
                freeSolo
                ListboxProps={{
                  style: { maxHeight: `180px` },
                }}
                onInputChange={(event, data, reason) => {
                  if (data && event) {
                    this.setState({ currentTime: data }, () =>
                      this.handleAssignedTimeChange()
                    )
                  }
                }}
                value={this.state.currentTime}
                PopperComponent={PopperMy}
                renderInput={(params) => (
                  <TextField
                    variant="standard"
                    {...params}
                    label={'Time Due'}
                    fullWidth
                    InputProps={{
                      ...params.InputProps,

                      sx: {
                        borderBottom: 'none',
                      },
                    }}
                  />
                )}
              />
            )}
          </div>
        </div>
      </div>
    )
  }
}

TaskItemDetailHeader.propTypes = {
  TaskID: PropTypes.number,
  /**
   * The unique id. It can be used for connecting to redux
   */
  TaskItemID: PropTypes.number.isRequired,
  /**
   * The workspace that the task item belongs to
   */
  IssueTeamWorkspaceID: PropTypes.number,
  /**
   * The participants that will appear in assign dropdown.
   * When connected the participants will be filled automatically
   * {email_address, AppAcctID}
   */
  participants: PropTypes.array,
  participantIds: PropTypes.arrayOf(PropTypes.number),
  /**
   * The title
   */
  Content: PropTypes.string,
  Description: PropTypes.string,
  CreatedUTC: PropTypes.instanceOf(Date),
  EntryCreatedBy: PropTypes.string,
  DueByUTC: PropTypes.instanceOf(Date),
  AssignedToAppAcctID: PropTypes.number,
  /**
   * A callback for values that should be submitted to
   * the server. This is called only when there is a valid value
   * to submit. Use submitDelay to change the frequency of submissions.
   */
  onSubmit: PropTypes.func,
  /**
   * The amount of time in milliseconds before the
   * onSubmit method will be called. The delay will restart
   * after each change. The default delay is DueByUTC 1000 milliseconds, AssignedTo 1000 milliseconds, and Content 3000 milliseconds.
   */
  submitDelay: PropTypes.any,
  mobile: PropTypes.bool,
  disabled: PropTypes.bool,
}

TaskItemDetailHeader.defaultProps = {
  participants: [],
  submitDelay: {
    DueByUTC: 1000,
    AssignedToAppAcctID: 1000,
    Content: 3000,
  },
  AssignedToAppAcctID: -1,
  OrgRoleID: -1,
  Content: '',
}

const mapStateToProps = (state, ownProps) => {
  const taskItem = selectTaskItemById(state, ownProps.TaskItemID)
  const {
    Content,
    Description,
    CreatedUTC,
    DueByUTC,
    EntryCreatedBy,
    OrgRoleID,
  } = taskItem || {}
  const { participants } = ownProps

  const DueByDate = DueByUTC ? new Date(DueByUTC) : undefined

  return {
    Content,
    Description,
    EntryCreatedBy,
    DueByUTC: DueByDate,
    CreatedUTC,
    participants,
    OrgRoleID,
  }
}

const changeValueTypeMap = {
  AssignedToAppAcctID: 'number',
}

const mapDispatchToProps = {
  onChange: function (name, value, ownProps) {
    const { TaskID, TaskItemID } = ownProps

    // Value data type conversion
    if ([name] === 'number') {
      value = value ? parseInt(value) : undefined
    }

    switch (name) {
      default:
        return TaskDetailActions.updateSuccess({
          TaskID,
          TaskItemID,
          [name]: value,
        })
    }

    //return { type: 'UNIMPLEMENTED' };
  },
  onSubmit: function (name, value, ownProps) {
    const { TaskID, TaskItemID } = ownProps

    // Value data type conversion
    if (changeValueTypeMap[name] === 'number') {
      value = value ? parseInt(value) : undefined
    }

    switch (name) {
      case 'DueByUTC':
        return TaskDetailActions.update(
          { TaskID, TaskItemID, DueDateUTC: new Date(value) },
          { action: !value ? 'DUECLEAR' : 'DUESET' }
        )
      case 'Content':
        return TaskDetailActions.update(
          { TaskID, TaskItemID, TaskItemText: value },
          { action: name }
        )
      case 'AssignedToAppAcctID':
        return TaskDetailActions.update(
          {
            TaskID,
            TaskItemID,
            AssignedToAppAcctID: value,
          },
          { action: value !== -1 ? 'ASSIGN' : 'UNASSIGN' }
        )

      case 'OrgRoleID':
        return TaskDetailActions.update(
          {
            TaskID,
            TaskItemID,
            OrgRoleID: value,
          },
          { action: value !== -1 ? 'ASSIGNROLE' : 'UNASSIGNROLE' }
        )
      default:
        return TaskDetailActions.update(
          { TaskID, TaskItemID, [name]: value },
          { action: name }
        )
    }

    //return { type: 'UNIMPLEMENTED' };
  },
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TaskItemDetailHeader)
