// @ts-nocheck
// TODO: Typescript
import {
  FETCH_GRAPH_USER_DETAILS,
  FETCH_GRAPH_ACCESS_TOKEN,
  LOGIN_AD,
  LOGOUT_AD,
  INIT_MS_TEAMS,
  FETCH_MS_TEAMS_CONTEXT,
  FETCH_MS_TEAMS_CHANNEL_FOLDERIDS,
  DISABLE_MS_TEAMS_AUTO_LOGIN,
  FETCH_GRAPH_JOINED_TEAM_CHANNELS,
  FETCH_GRAPH_CALENDAR_EVENTS,
  SET_GRAPH_CALENDAR_EVENTS_WORKSPACE,
  ADD_GRAPH_CALENDAR_EVENT,
  REMOVE_GRAPH_CALENDAR_EVENT,
} from './_constants'
import { generateActions } from '../_utils/reduxUtils'

// Microsoft Teams SDK actions
export function initMSTeamsSuccess() {
  return {
    type: INIT_MS_TEAMS.SUCCESS,
  }
}

export function FetchMSTeamsContext(context) {
  return {
    type: FETCH_MS_TEAMS_CONTEXT.REQUEST,
    payload: {
      context,
    },
  }
}

export function FetchMSTeamsContextSuccess(context) {
  return {
    type: FETCH_MS_TEAMS_CONTEXT.SUCCESS,
    payload: {
      context,
    },
  }
}

export const fetchMSTeamsChannelFolderIds = generateActions(
  FETCH_MS_TEAMS_CHANNEL_FOLDERIDS
)
export const fetchGraphJoinedTeamChannels = generateActions(
  FETCH_GRAPH_JOINED_TEAM_CHANNELS
)
export const fetchGraphCalendarEvents = generateActions(
  FETCH_GRAPH_CALENDAR_EVENTS
)
export const addGraphCalendarEvent = generateActions(ADD_GRAPH_CALENDAR_EVENT)
export const removeGraphCalendarEvent = generateActions(
  REMOVE_GRAPH_CALENDAR_EVENT
)
export function setGraphCalendarEventsWorkspace(IssueTeamWorkspaceID) {
  return {
    type: SET_GRAPH_CALENDAR_EVENTS_WORKSPACE,
    payload: {
      IssueTeamWorkspaceID,
    },
  }
}

// AD actions
export function loginAD(parameters) {
  return {
    type: LOGIN_AD.REQUEST,
    payload: {
      parameters,
    },
  }
}

export function loginADSuccess(result) {
  return {
    type: LOGIN_AD.SUCCESS,
    payload: {
      result,
    },
  }
}

export function loginADFailure(error, source) {
  return {
    type: LOGIN_AD.FAILURE,
    payload: {
      error,
      source,
    },
  }
}

export function logoutAD() {
  return {
    type: LOGOUT_AD.REQUEST,
  }
}

export function logoutADSuccess(result) {
  return {
    type: LOGOUT_AD.SUCCESS,
    payload: {
      result,
    },
  }
}

export function logoutADFailure(error, source) {
  return {
    type: LOGOUT_AD.FAILURE,
    payload: {
      error,
      source,
    },
  }
}

export function disableMSTeamsAutoLogin() {
  return {
    type: DISABLE_MS_TEAMS_AUTO_LOGIN,
  }
}

export function fetchGraphAccessToken(result) {
  return {
    type: FETCH_GRAPH_ACCESS_TOKEN.REQUEST,
  }
}

export function fetchGraphAccessTokenSuccess(result) {
  return {
    type: FETCH_GRAPH_ACCESS_TOKEN.SUCCESS,
    payload: {
      result,
    },
  }
}

export function fetchGraphAccessTokenFailure(error, source) {
  return {
    type: FETCH_GRAPH_ACCESS_TOKEN.FAILURE,
    payload: {
      error,
      source,
    },
  }
}

// Graph actions
export function fetchGraphUserDetails() {
  return {
    type: FETCH_GRAPH_USER_DETAILS.REQUEST,
  }
}

export function fetchGraphUserDetailsSuccess(result, source) {
  return {
    type: FETCH_GRAPH_USER_DETAILS.SUCCESS,
    payload: {
      result,
      source,
    },
  }
}
