// @ts-nocheck
// TODO: Typescript
import React from 'react'
import CreateAccountEmailSent from './CreateAccountEmailSent'
import { prefixSelectorWithApp } from '../_utils/domUtils'

/**
 * @extends {Component<Props, {}>}}
 */
const createAccountEmailSentExport = (props) => (
  <CreateAccountEmailSent
    id={prefixSelectorWithApp('CreateAccountEmailSentPage')}
    {...props}
  />
)
export default createAccountEmailSentExport
