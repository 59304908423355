import React, { Component } from 'react'
import classes from './FilePicker.module.scss'
import { cx } from '../_utils/objectUtils'

const defaultAccept = '*'

interface Props {
  disabled?: boolean
  className?: string
  onChange: (file: File) => void
  accept?: string
}

interface State {
  value: string
}

/**
 * @extends {Component<Props, {}>}}
 * Overrides the normal input type="file" with an icon.
 * This stores the file the user selects
 */
export class FilePicker extends Component<Props, State> {
  inputEl: HTMLElement = {} as HTMLElement

  static defaultProps = {
    accept: defaultAccept,
  }

  constructor(props: Props) {
    super(props)
    this.handleChange = this.handleChange.bind(this)
    this.handleClick = this.handleClick.bind(this)
    this.state = {
      value: '',
    }
  }

  handleChange(evt: any) {
    const { disabled } = this.props
    if (disabled) return

    const [file] = evt.target.files || evt.dataTransfer.files
    this.props.onChange(file)
  }

  handleClick() {
    const { disabled, children } = this.props
    if (disabled) return

    this.setState({
      value: '',
    })

    if (children) this.inputEl.click()
  }

  render() {
    const { className, children, disabled, accept } = this.props
    const disabledClass = disabled ? classes.disabled : ''

    // Use the standard input
    if (!children)
      return (
        <input
          className={cx(
            classes.uploadInput,
            classes.standard,
            disabledClass,
            className
          )}
          onClick={this.handleClick}
          onChange={this.handleChange}
          type="file"
          accept={accept}
          disabled={disabled}
        />
      )

    // Use a custom icon
    return (
      <div
        className={cx(classes.root, disabledClass, className)}
        onClick={this.handleClick}
      >
        {children}
        <input
          className={cx(classes.uploadInput, disabledClass)}
          ref={(el) => (this.inputEl = el as any)}
          onChange={this.handleChange}
          type="file"
          accept={accept}
          value={this.state.value}
          disabled={disabled}
        />
      </div>
    )
  }
}
