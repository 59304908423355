// @ts-nocheck
// TODO: Typescript
import { WebSocketConstants } from './_constants'
import { addNotification, closeNotification } from '../_actions'

const shortDuration = 2000
const milliInSeconds = 1000

export default function webSocketHub(storeAPI, action) {
  // Send an action to display snackbar notifications
  var msg = undefined
  switch (action.type) {
    // Show snackbar based on the signalr status
    case WebSocketConstants.CONNECTION_RETRY_FAILURE:
    case WebSocketConstants.CONNECTION_FAILURE:
      msg = {
        message:
          'Connection failed, will retry in ' +
          action.retryTime / milliInSeconds +
          ' seconds.',
        type: 'error',
      }
      break
    case WebSocketConstants.CONNECTING:
      msg = {
        message: 'Establishing connection',
        type: 'info',
      }
      break
    case WebSocketConstants.CONNECTION_SUCCESSFUL:
      /*msg = {
                message: 'Connection Successful',
                type: 'success',
                hasCloseBtn: true,
                duration: shortDuration
            };*/
      storeAPI.dispatch(closeNotification())
      break
    case WebSocketConstants.CONNECTION_RETRY:
      msg = {
        message: 'Reestablishing connection',
        type: 'warning',
      }
      break
    case WebSocketConstants.CONNECTION_RETRY_SUCCESSFUL:
      msg = {
        message: 'Reconnection Successful',
        type: 'success',
        duration: shortDuration,
        hasCloseBtn: true,
      }
      break
    case WebSocketConstants.CONNECTION_DISCONNECTED:
      msg = {
        message:
          'Network failure, will retry in ' +
          action.retryTime / milliInSeconds +
          ' seconds.',
        type: 'error',
      }
      break
    case WebSocketConstants.FORCE_DROP_CONNECTION_SUCCESSFUL:
      msg = {
        message: 'Successful disconnect',
        type: 'success',
        hasCloseBtn: true,
        duration: shortDuration,
      }
      break
    default:
      break
  }

  if (msg) {
    storeAPI.dispatch(addNotification(msg))
  }
}

// connectedOnly false signifies that this hub middleware should be ran even when the hub is disconnected
webSocketHub.connectedOnly = false
