// @ts-nocheck
// TODO: Typescript
import React, { Component, Fragment } from 'react'
import { AuthMasterLayout } from './AuthMasterLayout'
import { connect } from 'react-redux'
import { selectDnrEmail } from '../Issue/_selectors'
import { Spacer } from '../Shared/Spacer'
import { cx } from '../_utils/objectUtils'
import classes from './LoginForm.module.scss'

const addressStyle = { color: '#0033CC' }

/**
 * @extends {Component<Props, {}>}}
 */
class ForgotPasswordEmailSent extends Component {
  render() {
    const { location, dnrEmail } = this.props
    const { userEmailAddress } = (location || {}).state || {}
    return (
      <AuthMasterLayout {...this.props}>
        <div
          className={cx(
            classes.form,
            classes.formBackground,
            classes.formContent
          )}
        >
          {!userEmailAddress ? (
            <span>
              No user email address available for sending a password reset.
            </span>
          ) : (
            <>
              <h5>Check Your Email Account</h5>
              <Spacer spaceParam={'large'} />
              <Spacer spaceParam={'large'} />

              <p>
                A confirmation email was sent to
                <span style={addressStyle}> {userEmailAddress} </span>
                with instructions on how to complete changing your password.
              </p>
              <p>
                If you do not see it, please check your account, and please add
                <span style={addressStyle}> {dnrEmail} </span>
                to your expected senders list.
              </p>
            </>
          )}
        </div>
      </AuthMasterLayout>
    )
  }
}

const mapStateToProps = (state) => ({
  dnrEmail: selectDnrEmail(state),
})

export default connect(mapStateToProps)(ForgotPasswordEmailSent)
