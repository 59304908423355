import React from 'react'
import { PostAdd, SvgIconComponent } from '@material-ui/icons'
import { SvgIconProps } from '@material-ui/core'
import { cx } from '../_utils/objectUtils'
import classes from './Icons.module.scss'

export const AdvisoryMUI: SvgIconComponent = ({
  className,
  ...other
}: SvgIconProps) => {
  return (
    <PostAdd className={cx(classes.advisoryCreate, className)} {...other} />
  )
}
