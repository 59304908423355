import * as React from 'react'
import { useAppDispatch, useAppSelector } from '../_utils/reactReduxHooks'
import { selectIssueById } from './_selectors'
import { submitIssueForm } from '../Form/_actions'
//import { selectWorkspaceUpdateForms } from '../Workspace/_selectors'
import forms from './IssueExecBriefing.module.scss'
import { http } from '../_utils/http'
import { CircularProgress } from '../Shared/LoadingBackdrop'
//import { exec } from 'child_process'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Moment from 'react-moment'
import { icoPut, useIcoFetch } from '../_utils/fetchUtils'
import { faCaretDown, faCaretRight } from '@fortawesome/free-solid-svg-icons'
import { ExecutiveReportBuilder } from './ExecutiveReportBuilder'
import { appDateTimeFormat } from '../_constants'
import { getLocalDate } from '../_utils/dateUtils'
import { Button } from '../Shared/Buttons'
import { Spacer } from '../Shared/Spacer'
import { isIssueFormSubmitting } from '../Shared/_requestStatusSelectors'
import { isMobileApp } from '../_selectors'
import dayjs from 'dayjs'
import dayjsPluginUTC from 'dayjs/plugin/utc'
import { addNotification } from '../_actions'

dayjs.extend(dayjsPluginUTC)

interface Props {
  IssueID: number
  IssueTeamWorkspaceID: number
  isMobile: boolean
  reportContentDialog: boolean
}
export const formElements = [
  { title: 'Overview & Impacts', id: 'overview_and_impacts' },
  { title: 'Key Actions', id: 'key_actions' },
  {
    title: 'Communications & Strategy',
    id: 'communication_strategy',
  },
  {
    title: 'What is needed from Executive Management',
    id: 'requested_executive_action',
  },
  { title: 'Next Scheduled Meeting', id: 'next_meeting' },
]

export const IssueExecBriefing = ({
  IssueID,
  IssueTeamWorkspaceID,
  reportContentDialog,
}: Props) => {
  const [currentTeamFormContent, setCurrentTeamFormContent] =
    React.useState<any>()

  const [expandedTeamReports, setExpandedTeamReports] = React.useState<any>([])
  const [showReportBuilder, setShowReportBuilder] = React.useState(false)
  const [showFormSubmit, setShowFormSubmit] = React.useState(false)
  const [showPreview, setShowPreview] = React.useState(false)
  const [pdfLoading, setPDFLoading] = React.useState(false)

  const issue = useAppSelector((state) =>
    selectIssueById(state, IssueID as any)
  )

  const isFormLoading = useAppSelector(isIssueFormSubmitting)

  const formRef = React.useRef(null)
  const formButton = React.useRef<HTMLDivElement | null>(null)
  const justSubmitted = React.useRef(false)
  const dispatch = useAppDispatch()
  const isMobile = useAppSelector(isMobileApp)

  const [executiveBriefing, setExecutiveBriefing] = React.useState<any>()
  const [selectedForm, setSelectedForm] = React.useState<any>()

  const hasSpecIDandGUID = React.useMemo(
    () => selectedForm?.TeamReportSpecID && selectedForm?.TeamReportContextGUID,
    [selectedForm?.TeamReportSpecID, selectedForm?.TeamReportContextGUID]
  )

  const getFormReportSpec = React.useCallback(async (): Promise<any> => {
    const result = await http<any>({
      path: `/FormReport/Spec/${selectedForm?.TeamReportSpecID}?trcguid=${selectedForm?.TeamReportContextGUID}`,
    })
    if (result.ok && result.body) {
      setCurrentTeamFormContent(result.body)
    } else {
      setCurrentTeamFormContent('')
    }
  }, [selectedForm?.TeamReportContextGUID, selectedForm?.TeamReportSpecID])

  React.useEffect(() => {
    if (hasSpecIDandGUID) {
      getFormReportSpec()
    }
  }, [selectedForm, getFormReportSpec, hasSpecIDandGUID])

  const getExecutiveBriefing = React.useCallback(async (): Promise<any> => {
    var customHeaders: any = []

    customHeaders.push({ name: 'issueid', value: IssueID })

    const result = await http<any>({
      path: '/Issue/ExecBriefing',
      headers: customHeaders,
    })
    if (result.ok && result.body) {
      setExecutiveBriefing(result.body)
    } else {
      setExecutiveBriefing('')
    }
  }, [IssueID])

  const reportPreview = useIcoFetch('api/Issue/ERBbody', {
    request: { headers: { IssueID: IssueID.toString() } },
    tracking: [IssueID, setShowFormSubmit],
    required: [showFormSubmit],
  })

  React.useEffect(() => {
    if (executiveBriefing) {
      if (executiveBriefing.ExecBriefingForm) {
        if (executiveBriefing.ExecBriefingForm.length > 0) {
          setSelectedForm(executiveBriefing.ExecBriefingForm[0])
        }
      }
    }
  }, [executiveBriefing])

  React.useEffect(() => {
    if (
      !isFormLoading &&
      !justSubmitted.current &&
      !(
        executiveBriefing &&
        executiveBriefing.status &&
        executiveBriefing.status === 'success'
      )
    ) {
      getExecutiveBriefing()
    }
    if (isFormLoading && !justSubmitted.current && hasSpecIDandGUID) {
      justSubmitted.current = true
      getFormReportSpec()
    } else if (!isFormLoading && justSubmitted.current) {
      getExecutiveBriefing()
      justSubmitted.current = false
    }
  }, [
    isFormLoading,
    getFormReportSpec,
    getExecutiveBriefing,
    hasSpecIDandGUID,
    executiveBriefing,
  ])
  React.useEffect(() => {
    if (!isFormLoading) {
      formButton.current?.scrollIntoView()
    }
  }, [showFormSubmit, isFormLoading])

  const handleTeamReportSubmit = async (event: any) => {
    setShowFormSubmit(false)
    event.preventDefault()
    if (!formRef || !formRef.current) {
      return
    }
    //@ts-ignore
    const files = (formRef.current.querySelector('[type="file"]') || {}).files
    const formData = new FormData(formRef.current)
    if (files && files.length > 0) formData.append('file', files[0])

    dispatch(submitIssueForm(formData, { submitType: 'update' }))
  }

  function toggleTeamReportDisplay(TeamReportID: number) {
    var tarr = expandedTeamReports.slice()
    var ndx = tarr.indexOf(TeamReportID)
    if (ndx < 0) {
      tarr.push(TeamReportID)
      setExpandedTeamReports(tarr)
    } else {
      tarr.splice(ndx, 1)
      setExpandedTeamReports(tarr)
    }
  }

  function arrowIconTeamReportDisplay(TeamReportID: number) {
    var ndx = expandedTeamReports.indexOf(TeamReportID)
    if (ndx < 0) {
      return faCaretRight
    } else {
      return faCaretDown
    }
  }

  function shouldHideTeamReportDisplay(TeamReportID: number) {
    var ndx = expandedTeamReports.indexOf(TeamReportID)
    return ndx < 0
  }
  const ConfigureWarning = (
    <div>
      <h2>Configuration Needed</h2>
      <p>
        This Organization has not been completely configured for Executive
        Briefing
      </p>
      {issue.UserIsAdminYN === 'Y' && (
        <>
          <p>
            Please have an administrator go into the Adminstrator Login to
            access the In Case of Crisis Online Portal
          </p>
          <p>
            Please make sure the Organization is set to{' '}
            <b>{issue.issueOrgName}</b>{' '}
          </p>
          <p> Navigate to the Issues menu and go to the Choose Forms screen</p>
          <p>Add the Executive Briefing form </p>
          <p>Add the Executive Response form </p>
        </>
      )}
    </div>
  )
  const BriefingList = (
    <>
      <p>
        {!executiveBriefing?.Briefings && (
          <i>There have not yet been any Executive Briefings prepared.</i>
        )}
      </p>
      {executiveBriefing?.Briefings?.map((briefing: any, index: number) => {
        let briefingTimeAgo = dayjs
          .utc(briefing.SubmittedGMT)
          .local()
          .diff(dayjs(), 'days')
        let last6DaysAndNotToday =
          dayjs.utc(briefing.SubmittedGMT).local().day() !== dayjs().day() &&
          briefingTimeAgo > -7
        return (
          <div key={briefing.TeamReportID}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-start',
                width: '100%',
              }}
            >
              {briefing.ReportFormName !== 'Executive Briefing' && (
                <Spacer spaceParam={'large'} />
              )}

              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  width: '100%',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    flexDirection: 'row',
                    alignItems: 'flex-start',
                    padding: '0 1rem 1rem 0',
                    width: '100%',
                  }}
                >
                  <div
                    onClick={() => {
                      toggleTeamReportDisplay(briefing.TeamReportID)
                    }}
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      cursor: 'pointer',
                    }}
                  >
                    <FontAwesomeIcon
                      icon={arrowIconTeamReportDisplay(briefing.TeamReportID)}
                      style={{ paddingTop: '4px' }}
                    />
                    <Spacer />

                    <div>
                      <b>
                        {briefing.ReportFormName === 'Executive Briefing'
                          ? 'Executive Briefing'
                          : 'Response'}
                      </b>
                      ,&nbsp;
                      {last6DaysAndNotToday ? (
                        <span>
                          {isMobile
                            ? dayjs
                                .utc(briefing.SubmittedGMT)
                                .local()
                                .format('ddd')
                            : dayjs
                                .utc(briefing.SubmittedGMT)
                                .local()
                                .format('dddd h:mm a')}
                        </span>
                      ) : (
                        <Moment utc local fromNow>
                          {briefing.SubmittedGMT}
                        </Moment>
                      )}
                      <br />
                      <div className={forms.lightText}>
                        {briefing.SubmittedBy},&nbsp;
                        <Moment
                          local
                          format={appDateTimeFormat}
                          date={getLocalDate(briefing.SubmittedGMT)}
                        />
                      </div>
                    </div>
                  </div>
                  <Spacer spaceParam={'large'} />
                  {!shouldHideTeamReportDisplay(briefing.TeamReportID) &&
                    !isMobile &&
                    briefing.ReportFormName === 'Executive Briefing' && (
                      <Button
                        disabled={pdfLoading}
                        color="secondary"
                        onClick={() => {
                          setPDFLoading(true)
                          icoPut('/api/Export/executiveReport', {
                            IssueID,
                            TeamReportID: briefing.TeamReportID,
                          })
                            .then((value) => value.blob())
                            .then((value) => {
                              const newBlob = new Blob([value])
                              const blobUrl =
                                window.URL.createObjectURL(newBlob)

                              const link = document.createElement('a')
                              link.href = blobUrl
                              link.setAttribute(
                                'download',
                                `${'Brief'}-${issue.IssueName.slice(
                                  0,
                                  15
                                )}-${dayjs
                                  .utc(briefing.SubmittedGMT)
                                  .local()
                                  .format('YYMMDD')}.pdf`
                              )
                              document.body.appendChild(link)
                              link.click()
                              link.parentNode?.removeChild(link)

                              // clean up Url
                              window.URL.revokeObjectURL(blobUrl)
                              setPDFLoading(false)
                            })
                            .catch((error) => {
                              dispatch(
                                addNotification({
                                  type: 'error',
                                  message: 'PDF failed to Generate.',
                                })
                              )
                              setPDFLoading(false)
                            })
                        }}
                      >
                        Export PDF
                      </Button>
                    )}
                </div>

                <div
                  hidden={shouldHideTeamReportDisplay(briefing.TeamReportID)}
                  style={{ border: 'solid 1px lightgrey', width: '100%' }}
                >
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      backgroundColor: '#003e6b',
                      color: 'white',
                      padding: '1rem 1rem',
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        justifyContent: 'flex-start',
                      }}
                    >
                      <div style={{ fontSize: '24px' }}>
                        {briefing.ReportFormName === 'Executive Briefing'
                          ? 'Executive Briefing'
                          : 'Response'}
                      </div>
                      <Moment
                        local
                        format={appDateTimeFormat}
                        date={getLocalDate(briefing.SubmittedGMT)}
                      />
                    </div>
                  </div>

                  <div
                    dangerouslySetInnerHTML={{
                      __html: briefing.ReportHTML,
                    }}
                    id={`briefing_${briefing.SubmittedGMT + index}`}
                    style={{ padding: '0.5rem' }}
                  />
                </div>
              </div>
            </div>
            <Spacer spaceParam={'large'} />
          </div>
        )
      })}
      <div style={{ border: 'solid 2px darkGrey' }} />
      <Spacer spaceParam={'large'} />
      <Spacer spaceParam={'large'} />
      {
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
          }}
          ref={formButton}
        >
          <Button color="primary" onClick={() => setShowFormSubmit(true)}>
            {issue.UserIsAdminYN === 'Y'
              ? 'Create Briefing'
              : issue.UserIsExecutiveYN === 'Y'
              ? 'Submit Response'
              : ''}
          </Button>
          {issue.UserIsAdminYN === 'Y' && (
            <>
              <Spacer />
              <Button
                color="primary"
                onClick={() => setShowReportBuilder(true)}
              >
                {isMobile ? 'Manage' : 'Manage Template'}
              </Button>
            </>
          )}
        </div>
      }
    </>
  )

  const RenderPreview = React.useCallback(() => {
    return (
      <div style={{ width: '100%' }}>
        <h2>Preview</h2>
        <Spacer spaceParam={'medium'} />
        <div style={{ border: 'solid 1px lightgrey' }}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
              backgroundColor: '#003e6b',
              color: 'white',
              padding: '1rem 1rem',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                justifyContent: 'flex-start',
              }}
            >
              <div style={{ fontSize: '24px' }}>Executive Briefing</div>
              <Moment format={appDateTimeFormat} date={new Date()} />
            </div>
          </div>

          <div
            dangerouslySetInnerHTML={{
              __html: reportPreview?.data?.html,
            }}
            style={{ padding: '0.5rem 0.5rem 0 0.5rem' }}
          />

          <div style={{ padding: '0 0.5rem 0.5rem 0.5rem' }}>
            {formElements.map((section, index) => (
              <div key={section.id}>
                <details open>
                  <summary style={{ color: '#003e6b' }}>
                    &nbsp;{section.title}
                  </summary>

                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'flex-start',
                    }}
                  >
                    <div
                      style={{
                        width: '19px',
                        minWidth: '19px',
                        display: 'block',
                      }}
                    ></div>
                    {
                      (
                        document.getElementsByName(
                          section.id
                        )[0] as HTMLInputElement | null
                      )?.value
                    }
                  </div>
                </details>
                {index !== 5 && (
                  <div style={{ padding: '12px 0px 8px' }}>
                    <div style={{ border: 'solid 1px #003e6b' }}></div>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    )
  }, [reportPreview])

  const FormSubmission = (
    <>
      <h2>
        {issue.UserIsAdminYN === 'Y'
          ? 'Create New Briefing'
          : 'Submit Response'}
      </h2>
      <form
        ref={formRef}
        encType="multipart/form-data"
        onSubmit={handleTeamReportSubmit}
      >
        <div
          id="teamFormReport"
          className={forms.addFormClass}
          dangerouslySetInnerHTML={{
            __html: currentTeamFormContent?.returnData,
          }}
        />
        <Spacer />
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <Button
            color="standard"
            onClick={(e) => {
              e.preventDefault()
              setShowFormSubmit(false)
              setShowReportBuilder(false)
            }}
          >
            Cancel
          </Button>
          {issue.UserIsAdminYN === 'Y' && (
            <>
              <Spacer />
              <Button
                color="standard"
                onClick={(e) => {
                  e.preventDefault()
                  setShowFormSubmit(false)
                  setShowReportBuilder(true)
                }}
              >
                Manage Template
              </Button>
              <Spacer />
              <Button
                color="standard"
                onClick={(e) => {
                  e.preventDefault()
                  setShowPreview((prev) => !prev)
                }}
              >
                {showPreview ? 'Hide Preview' : 'Show Preview'}
              </Button>
            </>
          )}
        </div>
      </form>
      <Spacer spaceParam={'large'} />
      {showPreview && RenderPreview()}
    </>
  )

  function renderPrepareExecutiveBriefing() {
    return (
      <div style={{ padding: '1rem' }}>
        {!currentTeamFormContent ? (
          ConfigureWarning
        ) : !showFormSubmit && !showReportBuilder ? (
          BriefingList
        ) : issue.UserIsAdminYN === 'Y' && showReportBuilder ? (
          <>
            <ExecutiveReportBuilder
              IssueID={IssueID}
              onClose={() => setShowReportBuilder(false)}
              setShowFormSubmit={() => {
                setShowReportBuilder(false)
                setShowFormSubmit(true)
              }}
              formSubmitText={
                issue.UserIsAdminYN === 'Y'
                  ? 'Create New Briefing'
                  : 'Submit Response'
              }
            />
          </>
        ) : (
          showFormSubmit && FormSubmission
        )}
      </div>
    )
  }

  if (!executiveBriefing) return <CircularProgress />
  if (!executiveBriefing.status) return <CircularProgress />
  if (executiveBriefing.status !== 'success')
    return <>{JSON.stringify(executiveBriefing, null, 2)}</>
  if (issue.UserIsExecutiveYN === 'Y' || issue.UserIsAdminYN === 'Y')
    return renderPrepareExecutiveBriefing()
  return <>You are not authorized to view this page.</>
}
