// @ts-nocheck
// TODO: Typescript
import {
  prependAppName,
  generateTypes,
  prefixWithApp,
} from './_utils/reduxUtils'

export const AppConstants = {
  FETCH_INIT: generateTypes('APP', 'FETCH_INIT'),
  FETCH_INIT_DELAY: 'FETCH_INIT_DELAY', // Wait before saying the app data as been load a special fix for MS Teams login decision logic
  DISPLAY_CURRENT_NOTIFICATION: 'APP_DISPLAY_CURRENT_NOTIFICATION',
  ADD_NOTIFICATION: 'APP_ADD_NOTIFICATION',
  CLOSE_NOTIFICATION: 'APP_CLOSE_NOTIFICATION',
  DATA_NOT_FOUND: 'DATA_NOT_FOUND',
}

export const SAVE_APP_HEADER_TYPE_PATHS = prefixWithApp(
  'SAVE_APP_HEADER_TYPE_PATHS'
)

prependAppName(AppConstants)

export const appDateFormat = 'DD MMM YYYY'
export const appDateTimeFormat = `${appDateFormat}, h:mm A`
export const appDateTimeExactFormat = 'DD MMM YYYY h:mm:ss a'
//export const appDateTimeFormatConf = `ddd, MMM D, YYYY h:mm A`;
export const appDateTimeFormatConf = `MMM D, YYYY h:mm A`
export const appDateFormatConf = `MMM D, YYYY`
