import * as React from 'react'
import { MasterPageLayout } from './MasterPageLayout'
import { DesktopLayout, MobileLayout } from '../Layouts/ResponsiveHidden'
import { PlaybookRouteParams } from '../Routing/RouteProps'
import { RouteComponentProps } from 'react-router'
import { Privacy } from '../Privacy/Privacy'

interface Props extends RouteComponentProps<PlaybookRouteParams> {}

export const PrivacyPage = ({ history, match, location }: Props) => {
  return (
    <MasterPageLayout
      history={history}
      match={match as any}
      location={location}
    >
      <DesktopLayout>
        <Privacy />
      </DesktopLayout>
      <MobileLayout>
        <Privacy />
      </MobileLayout>
    </MasterPageLayout>
  )
}
