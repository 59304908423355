import React, { Component } from 'react'
import { connect } from 'react-redux'
import { isLoggingOut } from '../Auth/_selectors'
import { LoadingBackDrop } from '../Shared/LoadingBackdrop'
import {
  selectIsAppInitialDataLoaded,
  isAppLoading,
  isInitIssuesComplete,
} from './_requestStatusSelectors'

interface Props {
  isAppLoading?: boolean
  open?: boolean
  isInitIssuesComplete?: boolean
  isAppInitialDataLoaded?: boolean
  awaitIssues?: boolean
  isLoggingOut?: boolean
  className?: string
  zIndex?: number
}

class GlobalLoadingIndicator extends Component<Props> {
  render() {
    const loadingText = this.getLoadingText()
    const { isAppLoading, open, className, zIndex } = this.props
    return (
      <LoadingBackDrop
        zIndex={zIndex}
        className={className}
        text={loadingText}
        open={isAppLoading || open}
      />
    )
  }

  getLoadingText() {
    const { isAppInitialDataLoaded, isLoggingOut, open } = this.props

    if (isLoggingOut) return 'Logging out of account...'
    if (!isAppInitialDataLoaded || open) return 'Loading App...'

    return ''
  }
}

const mapStateToProps = (state: any) => {
  return {
    isInitIssuesComplete: isInitIssuesComplete(state),
    isAppInitialDataLoaded: selectIsAppInitialDataLoaded(state),
    // Does the loading check for issues and app loading
    isAppLoading: isAppLoading(state),
    isLoggingOut: isLoggingOut(state),
  }
}

export default connect(mapStateToProps, undefined)(GlobalLoadingIndicator)
