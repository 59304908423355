import {
  IssueConstants,
  SET_DEFAULT_ISSUE,
  FETCH_ORG_ISSUE_LEVELS,
  FETCH_ORG_ISSUE_TYPES,
  IssueLevelConstants,
  FETCH_APP_USER_INFO,
  RESOLVE_ISSUE,
  FilterConstants,
  IssueTagConstants,
  CHANGE_ISSUE_STAGE_TAB,
  FilterSaveConstants,
  FETCH_ISSUE_ACTIVITY_LOG,
  ADD_ISSUE_ACTIVITY_LOG,
  FETCH_APP_USER_ORGS,
  MARK_READ_ISSUE_ACTIVITY_LOG,
  FETCH_LATEST_ISSUE_ACTIVIY_DATES,
  CHANGE_ISSUE_ACTIVITY_LOG_FILTER,
  EvidenceLockerLinkConstants,
  GeolocationConstants,
} from './_constants'
import { AppConstants } from '../_constants'
import { createSelector } from 'reselect'
import {
  parseIntIfPresent,
  getValueFromKey,
  keyValuesToArray,
} from '../_utils/objectUtils'
import {
  HoldingStatementConstants,
  ADD_RELATED_HOLDING_STATEMENTS,
  DELETE_RANGE_HOLDING_STATEMENTS,
} from '../HoldingStatement/_constants'
import { StakeholderConstants } from '../Stakeholder/_constants'
import { reducerCrud } from '../_utils/reduxUtils'
import { TeamReportConstants, SUBMIT_ISSUE_FORM } from '../Form/_constants'
import { WorkspaceConstants } from '../Workspace/_constants'
import { ParticipantConstants } from '../Participant/_constants'
import { LoginConstants } from '../Auth/_constants'
import { createItemListReducer } from '../ActivityLog/_reducer'
import {
  ALERT_FILTER,
  ALERT_FILTER_SAVE,
  ALERT_SEVERITY_COLORS,
} from '../Alert/_constants'
import { AlertFilter, SeverityColor } from '../Alert/_models'
import { Reducer } from 'redux'

interface IssueState {
  [issueID: number]: any
}

/*
 * ISSUE REDUCER
 */
export default function issueReducer(state: IssueState = {}, action: any) {
  let { result, source } = action.payload || {}
  source = source || {}
  switch (action.type) {
    case IssueConstants.INIT.SUCCESS:
      return action.payload.result || {}
    case RESOLVE_ISSUE.SUCCESS:
    case IssueConstants.UPDATE.SUCCESS:
    case IssueConstants.ADD.SUCCESS: {
      var newIssue = action.payload.result
      var oldIssue = state[newIssue.IssueID]
      return {
        ...state,
        [newIssue.IssueID]: { ...oldIssue, ...newIssue },
      }
    }

    case IssueConstants.UPDATE_RANGE.SUCCESS:
      return reducerCrud.updateRange(state, action, 'IssueID')
    case IssueConstants.DELETE.SUCCESS:
      return {
        ...state,
        [action.payload.result.IssueID]: {
          ...state[action.payload.result.IssueID],
          isArchived:
            source.isArchived === undefined ? true : source.isArchived,
        },
      }
    case IssueConstants.DELETE_RANGE.SUCCESS:
      return reducerCrud.deleteIdRange(state, action.payload.result)

    case ADD_ISSUE_ACTIVITY_LOG.SUCCESS:
      let newIssueState: IssueState = {}

      for (const logItemId in result.idMap) {
        const logItem = result.idMap[logItemId]

        if (!newIssueState[logItem.IssueID])
          newIssueState[logItem.IssueID] = state[logItem.IssueID] || {}

        if (
          !newIssueState[logItem.IssueID].lastActivityDate ||
          newIssueState[logItem.IssueID].lastActivityDate?.getTime() <
            logItem.HappenedGMT?.getTime()
        )
          newIssueState[logItem.IssueID].lastActivityDate = logItem.HappenedGMT
      }

      // Do the final merge
      return {
        ...state,
        ...newIssueState,
      }

    // Holding Statements
    case DELETE_RANGE_HOLDING_STATEMENTS.SUCCESS:
      return reducerCrud.deleteChildRange(
        state,
        action,
        'IssueID',
        action.payload.result,
        'HoldingStmt'
      )
    //case HoldingStatementConstants.APPROVE.SUCCESS:
    case HoldingStatementConstants.UPDATE.SUCCESS: {
      const issueID = action.statement.IssueID
      const issue = state[issueID]
      if (!issue) {
        return state
      }
      return {
        ...state,
        [issueID]: {
          ...issue,
          //lastActivityDate: dateUTCbuilder(action.source.UpdatedUTC),
          HoldingStmt: [
            action.statement.HoldingStatementID,
            ...(issue.HoldingStmt || []),
          ],
        },
      }
    }
    case ADD_RELATED_HOLDING_STATEMENTS.SUCCESS: {
      const issueID = action.source.IssueID
      const issue = state[issueID]
      if (!issue) {
        return state
      }
      return {
        ...state,
        [issueID]: {
          ...issue,
          HoldingStmt: [...action.children, ...(issue.HoldingStmt || [])],
        },
      }
    }

    // Handle the issue recently updated date
    case ParticipantConstants.ADD.SUCCESS: {
      const issue = state[action.payload.source.IssueID]
      if (!issue || !action.payload.source.UpdatedUTC) {
        return state
      }

      return {
        ...state,
        [action.payload.source.IssueID]: {
          ...issue,
          //lastActivityDate: dateUTCbuilder(action.payload.source.UpdatedUTC)
        },
      }
    }

    // Workspace
    case WorkspaceConstants.DELETE.SUCCESS:
      return reducerCrud.deleteChild(
        state,
        action,
        'IssueID',
        'IssueTeamWorkspaceID',
        'wksp'
      )
    case WorkspaceConstants.ADD.SUCCESS:
      return reducerCrud.addChild(
        state,
        action,
        'IssueID',
        'IssueTeamWorkspaceID',
        'wksp'
      )
    case WorkspaceConstants.ADD_RELATED.SUCCESS:
      return reducerCrud.addChildIdRange(state, action, 'IssueID', 'wksp')
    case WorkspaceConstants.DELETE_RANGE.SUCCESS:
      return reducerCrud.deleteChildRange(
        state,
        action,
        'IssueID',
        action.payload.result,
        'wksp'
      )

    // Issue Level
    case IssueLevelConstants.ADD.SUCCESS:
      return reducerCrud.prependChild(
        state,
        action,
        'IssueID',
        'IssueLevelID',
        'IssueLevel'
      )
    case IssueLevelConstants.ADD_RELATED.SUCCESS:
      return reducerCrud.prependChildIdRange(
        state,
        action,
        'IssueID',
        'IssueLevel'
      )
    case IssueLevelConstants.DELETE_RANGE.SUCCESS:
      return reducerCrud.deleteChildRange(
        state,
        action,
        'IssueID',
        action.payload.result,
        'IssueLevel'
      )

    // Stakeholder
    case StakeholderConstants.ADD.SUCCESS:
      return reducerCrud.addChild(
        state,
        action,
        'IssueID',
        'IssueStakeholderID',
        'Stakeholders'
      )
    case StakeholderConstants.DELETE.SUCCESS:
      return reducerCrud.deleteChild(
        state,
        action,
        'IssueID',
        'IssueStakeholderID',
        'Stakeholders'
      )
    case StakeholderConstants.ADD_RELATED.SUCCESS:
      return reducerCrud.addChildIdRange(
        state,
        action,
        'IssueID',
        'StakeHolders'
      )
    case StakeholderConstants.DELETE_RANGE.SUCCESS:
      return reducerCrud.deleteChildRange(
        state,
        action,
        'IssueID',
        action.payload.result,
        'Stakeholders'
      )

    // TeamReports
    case TeamReportConstants.ADD.SUCCESS:
      return reducerCrud.addChild(
        state,
        action,
        'IssueID',
        'TeamReportID',
        'TeamReports'
      )
    case TeamReportConstants.DELETE.SUCCESS:
      return reducerCrud.deleteChild(
        state,
        action,
        'IssueID',
        'TeamReportID',
        'TeamReports'
      )
    case TeamReportConstants.ADD_RELATED.SUCCESS:
      return reducerCrud.addChildIdRange(
        state,
        action,
        'IssueID',
        'TeamReports'
      )
    case TeamReportConstants.DELETE_RANGE.SUCCESS:
      return reducerCrud.deleteChildRange(
        state,
        action,
        'IssueID',
        action.payload.result,
        'TeamReports'
      )
    case EvidenceLockerLinkConstants.ADD.SUCCESS:
      return reducerCrud.addChild(
        state,
        action,
        'IssueID',
        'linkID',
        'EvidenceLockerLinks'
      )

    case EvidenceLockerLinkConstants.DELETE.SUCCESS:
      return reducerCrud.deleteChild(
        state,
        action,
        'IssueID',
        'linkID',
        'EvidenceLockerLinks'
      )
    case GeolocationConstants.ADD.SUCCESS:
      return reducerCrud.addChild(
        state,
        action,
        'IssueID',
        'LocationID',
        'Geolocations'
      )
    case GeolocationConstants.DELETE.SUCCESS:
      return reducerCrud.deleteChild(
        state,
        action,
        'IssueID',
        'LocationID',
        'Geolocations'
      )

    case IssueConstants.APPLY_FILTER:
      return state

    // IssueTag
    case IssueTagConstants.ADD.SUCCESS:
      return reducerCrud.addChild(state, action, 'IssueID', 'TagID', 'IssueTag')
    case IssueTagConstants.DELETE.SUCCESS:
      return reducerCrud.deleteChild(
        state,
        action,
        'IssueID',
        'TagID',
        'IssueTag'
      )

    default:
      return state
  }
}

// NOTE: Reselect createSelector will always pass in state and props
/*
 * ISSUE SELECTORS
 */
// Selectors based on routing
// props.match is only available if the routing is avaiable
export const selectIssuePathId = (state: any, props: any) =>
  getValueFromKey(props, 'match.params.IssueID')
export const selectTaskItemPathId = (state: any, props: any) => {
  if (props.match && props.match.params) {
    const params = props.match.params
    if (params.domain === 'tasks' && params.domainItem === 'taskItem')
      return parseIntIfPresent(params, 'domainItemId')
  }
}

export const selectTaskPathId = (state: any, props: any) => {
  if (props.match && props.match.params) {
    const params = props.match.params
    if (params.domain === 'tasks') return parseIntIfPresent(params, 'domainId')
  }
}

// All other selectors
export const selectIssuesByIdMap = (state: IssueState) => state
export const selectAllUnresolvedIssues = createSelector(
  selectIssuesByIdMap,
  (idMap) => {
    idMap = idMap || {}
    let issues = []
    for (var id in idMap) {
      const cur = idMap[id]
      if (cur && !cur.IssueResolved) issues.push(cur)
    }

    return issues.sort((a: any, b: any) => {
      const aName = a?.IssueName?.trim().toLocaleLowerCase()
      const bName = b?.IssueName?.trim().toLocaleLowerCase()

      if (aName > bName) return 1
      else if (aName < bName) return -1

      return 0
    })
  }
)

export const selectIssueById = (state: IssueState, id: number) =>
  (selectIssuesByIdMap(state) || {})[id]
export const selectIssueOrgID = (state: IssueState, issueID: number) =>
  selectIssueById(state, issueID).OrgID
export const selectIssueWorkspaceIds = (state: IssueState, id: number) =>
  (selectIssueById(state, id) || {}).wksp
export const selectIssueWorkspaceCount = (state: IssueState, id: number) =>
  (selectIssueWorkspaceIds(state, id) || []).length
export const selectIssueStakeholderIds = (state: IssueState, id: number) =>
  (selectIssueById(state, id) || {}).Stakeholders
export const selectIssueStakeholderCount = (state: IssueState, id: number) =>
  (selectIssueStakeholderIds(state, id) || []).length
export const selectIssueHoldingStatementIds = (state: IssueState, id: number) =>
  (selectIssueById(state, id) || {}).HoldingStmt
export const selectIssueTeamReportIds = (state: IssueState, issueID: number) =>
  (selectIssueById(state, issueID) || {}).TeamReports
export const selectIssueEvidenceLockerLinkIds = (
  state: IssueState,
  issueID: number
) => (selectIssueById(state, issueID) || {}).EvidenceLockerLinks
export const selectIssueEvidenceLockerLinksCount = (
  state: IssueState,
  issueID: number
) => (selectIssueEvidenceLockerLinkIds(state, issueID) || []).length
export const selectIssueGeolocationIds = (state: IssueState, issueID: number) =>
  (selectIssueById(state, issueID) || {}).Geolocations
export const selectIssueTeamReportCount = (
  state: IssueState,
  issueID: number
) => (selectIssueTeamReportIds(state, issueID) || []).length
export const selectIssueTrendId = (state: IssueState, issueID: number) =>
  (selectIssueById(state, issueID) || {}).IssueTrendID || 0
export const selectIssueTagIds = (state: IssueState, issueID: number) =>
  (selectIssueById(state, issueID) || {}).IssueTag

export const selectIssuesArray = createSelector(
  selectIssuesByIdMap,
  (issuesMap) => keyValuesToArray(issuesMap)
)

export const selectIssuesCount = (state: IssueState) =>
  (selectIssuesArray(state) || []).length

export const selectCurrentIssue = createSelector(
  selectIssuesByIdMap,
  selectIssuePathId,
  (idMap, pathId) => idMap[pathId]
)

export const selectCurrentWorkspaceIds = createSelector(
  selectCurrentIssue,
  (issue) => (issue || {}).wksp || []
)

export const selectCurrentIssueLevelIds = createSelector(
  selectCurrentIssue,
  (issue) => (issue || {}).IssueLevel || []
)

export const selectCurrentStakeHolderIds = createSelector(
  selectCurrentIssue,
  (issue) => (issue || {}).StakeHolders || []
)

// Issue Level Selectors
export const selectIssuesAllLevelIds = (state: IssueState, id: number) =>
  (selectIssueById(state, id) || {}).IssueLevel

// Issue Type Selectors
export const selectIssuesAllTypeIds = (state: IssueState, id: number) =>
  (selectIssueById(state, id) || {}).IssueType

// Holding Statment selectors
export const selectCurrentHoldingStatementIds = createSelector(
  selectCurrentIssue,
  (issue) => (issue || {}).HoldingStmt
)

// IssueActivityLogReducer
export const issueActivityLogReducer = createItemListReducer(
  {
    fetch: FETCH_ISSUE_ACTIVITY_LOG,
    add: ADD_ISSUE_ACTIVITY_LOG,
    changeFilter: CHANGE_ISSUE_ACTIVITY_LOG_FILTER.REQUEST,
  },
  {
    primaryID: (action) => action.payload.params.IssueID,
    sortKeys: ['HappenedGMT', 'Id'],
    filterKeys: ['category'],
    onItemAddParentID: (item) => item.IssueID,
    //filterHash: action => issueActivityLogParmsToString(action.payload.params)
    //itemID: 'LogItemID'
  }
)

/*export const issueActivityLogParmsToString = (params: any) => {
    return params.category?.toLocaleLowerCase().toString();
};*/

// IssueActivityLogReducer - Selectors
/*export const selectIssueActivityLogIdMap = (state: IssueState, issueID: number) => {
    return (selectIssueById(state, issueID) || {}).ActivityLog;
}
export const selectIssueActivityLog = createSelector(
    selectIssueActivityLogIdMap,
    (idMap) => {
        let arr = keyValuesToArray(idMap) || [];

        return arr.sort((a, b) => sortDateIdDesc(a, b, 'HappenedGMT', 'Id'));
    }
);

export const isIssueActivityLogLoaded = (state: IssueState, issueId: number) => (selectIssueById(state, issueId) || {}).ActivityLogLoaded;
*/

// IssueLatestLogReducer
interface IssueLatestLogState {
  [issueID: number]: any
}
export function issueLatestLogReducer(
  state: IssueLatestLogState = {},
  action: any
) {
  const mapped: { [key: string]: Function } = {
    [FETCH_LATEST_ISSUE_ACTIVIY_DATES.SUCCESS]: () => action.payload.result,
    [ADD_ISSUE_ACTIVITY_LOG.SUCCESS]: () => {
      const { result } = action.payload
      const currentUser = result.currentUser?.toLocaleLowerCase().trim()
      const newLatest: IssueLatestLogState = {}
      for (var logItemId in result.idMap) {
        const logItem = result.idMap[logItemId]
        const currentLatest = state[logItem.IssueID]?.lastActivityDate
        if (
          currentUser !== logItem.PerformedBy?.toLocaleLowerCase().trim() &&
          (!newLatest[logItem.IssueID]?.lastActivityDate ||
            !currentLatest ||
            logItem.HappenedGMT > currentLatest)
        ) {
          newLatest[logItem.IssueID] = {
            LastRead: state[logItem.IssueID]?.LastRead,
            lastActivityDate: logItem.HappenedGMT,
          }
        }
      }

      return {
        ...state,
        ...newLatest,
      }
    },
    [MARK_READ_ISSUE_ACTIVITY_LOG.SUCCESS]: () => {
      const { result } = action.payload
      return {
        ...state,
        [result.IssueID]: {
          ...state[result.IssueID],
          LastRead: result.ReadDate,
        },
      }
    },
  }

  return (
    mapped[action.type] ||
    function () {
      return state
    }
  )()
}

export const selectIssueActivityLogLatest = (
  state: IssueLatestLogState,
  issueID: number
) => (state[issueID] || {}).lastActivityDate
export const selectIssueActivityLogRead = (
  state: IssueLatestLogState,
  issueID: number
) => (state[issueID] || {}).LastRead
export const hasNewIssueActivityLog = (
  state: IssueLatestLogState,
  issueID: number
) => {
  if (!selectIssueActivityLogLatest(state, issueID)) return false

  return (
    !selectIssueActivityLogRead(state, issueID) ||
    selectIssueActivityLogLatest(state, issueID) >
      selectIssueActivityLogRead(state, issueID)
  )
}

interface PortalAdminPermission {
  UserLevelID: string
  PermissionList: string
}

interface IssueTeams {
  TeamName: string
  TeamRole: string
}

interface OrgIssueTag {
  TagID: number
  OrgId: number
  TagName: string
  isActive: boolean
}
export interface Organization {
  OrgID: string
  OrgName: string
  PortalAdminPermission: PortalAdminPermission
  IssueTeams: IssueTeams[]
  OrgIssueTags: OrgIssueTag[]
}
export interface AccountOrganization {
  AppAcctID: string
  email_address: string
  Orgs: Organization[]
}

interface IssueAppState {
  defaultIssueId?: number
  lastActivityLogNewChangesDate?: Date
  issueFilter: {
    IssueResolved?: boolean
    Severity?: number[]
    Type: ('A' | 'D' | 'T')[]
    startDate: string
    endDate: string
    sortBy: string
    sortDir: string
    Description: string
    IssueName: string
    tags?: string
    orgIdList?: Array<any>
  }
  issueFilterKeys?: string[]
  filters?: any
  alertFilter: AlertFilter
  orgIssueLevels: any
  orgIssueLevelsMap: any
  issueModeType?: string
  issueStageType: string
  DnrEmail: string
  SiteName: string
  Environment: string
  BuildInformation: string
  gitBranch: string
  gitName: string
  gitEmail: string
  AppVersion: string
  BuildCreated: Date
  locationWorkspaceID: number
  locationIssueID: number
  UserInfo: {
    FullName: string
    EmailAddress: string
  }
  orgIssueTypes: any
  orgIssueTypesOrder: any
  pendingCreatedIssue: number
  orgInfo: AccountOrganization
  orgNamesByDomain: any
  SMSPromptNeeded: 'Y' | 'N'
  mobilePromptDelay: number
  alertSeverityColors: Array<SeverityColor>
}

export const issueAppInitState: IssueAppState = {
  //issuesSortBy: 'LevelSequence',
  //issuesSortDir: 'DESC',
  issueFilter: {
    IssueResolved: true,
    Severity: [1, 2, 3, 4],
    Type: ['A', 'D', 'T'],
    startDate: '',
    endDate: '',
    sortBy: 'lastActivityDate', // LevelSequence
    sortDir: 'DESC',
    Description: '',
    IssueName: '',
    orgIdList: [],
  },
  issueModeType: undefined,
  issueStageType: 'assigned',
  DnrEmail: '',
  SiteName: '',
  Environment: '',
  BuildInformation: '',
  AppVersion: '',
  gitBranch: 'origin/main',
  gitName: '',
  gitEmail: '',
  SMSPromptNeeded: 'N',
  mobilePromptDelay: 90,
  alertFilter: {
    statuses: ['New', 'Reviewed, No Action Taken', 'Pinned', 'Shared'],
    InboxesHidden: [],
    inactiveFilter: false,
  },
  alertSeverityColors: [
    {
      LevelSequence: 1,
      LevelName: 'Low',
      LevelRGB: '#00A47B',
    },
    {
      LevelSequence: 2,
      LevelName: 'Medium',
      LevelRGB: '#CEA705',
    },
    {
      LevelSequence: 3,
      LevelName: 'High',
      LevelRGB: '#E64500',
    },
  ],
  orgIssueLevels: [],
  orgIssueLevelsMap: {},
  BuildCreated: new Date(),
  locationWorkspaceID: 0,
  locationIssueID: 0,
  UserInfo: {
    FullName: '',
    EmailAddress: '',
  },
  orgIssueTypes: {},
  orgIssueTypesOrder: [],
  pendingCreatedIssue: 0,
  orgInfo: {
    AppAcctID: '',
    email_address: '',
    Orgs: [],
  },
  orgNamesByDomain: [],
}

export const issueAppDefaultSortDir: { [fieldName: string]: string } = {
  issuename: 'ASC',
  lastactivitydate: 'DESC',
}

export const issueAppReducer: Reducer<IssueAppState> = (
  state = issueAppInitState,
  action: any
) => {
  const initialState = action.initialState || {}
  switch (action.type) {
    case SET_DEFAULT_ISSUE:
      return {
        ...state,
        defaultIssueId: action.payload?.issue || null,
      }
    case ADD_ISSUE_ACTIVITY_LOG.SUCCESS:
      return {
        ...state,
        lastActivityLogNewChangesDate: action.payload.params.lastRequested,
      }
    case IssueConstants.APPLY_FILTER: {
      const issueFilter = {
        ...state.issueFilter,
        ...action.filter,
      }
      const issueFilterKeys = Object.keys(issueFilter || {})

      return {
        ...state,
        issueFilter,
        issueFilterKeys,
      }
    }
    case FETCH_ORG_ISSUE_LEVELS.SUCCESS:
      let orgIssueLevelsMap: any = {}
      const orgIssueLevels = action.payload.result || []
      orgIssueLevels.forEach(
        (level: any) => (orgIssueLevelsMap[level.Value] = level)
      )
      return {
        ...state,
        orgIssueLevels,
        orgIssueLevelsMap,
      }
    case FETCH_ORG_ISSUE_TYPES.SUCCESS:
      if (!action.payload.result) return state

      let orgIssueTypes: any = {}
      let orgIssueTypesOrder: any = []
      action.payload.result.forEach((type: any) => {
        const { Value } = type
        orgIssueTypes[Value] = type
        orgIssueTypesOrder.push(Value)
      })
      return {
        ...state,
        orgIssueTypes,
        orgIssueTypesOrder,
      }
    case FETCH_APP_USER_INFO.SUCCESS:
      return {
        ...state,
        //OrgName: (action.payload.result.OrgName || []).map(org => org.OrgName),
        UserInfo: action.payload.result.UserInfo,
      }
    case FETCH_APP_USER_ORGS.SUCCESS:
      const orgs = action.payload.result

      return {
        ...state,
        orgInfo: orgs,
      }
    case SUBMIT_ISSUE_FORM.SUCCESS:
      if (action.payload.source.submitType === 'create') {
        return {
          ...state,
          pendingCreatedIssue: action.payload.result.IssueID,
        }
      }

      return state
    case '@@router/LOCATION_CHANGE':
      var detailPath = '/IssueDetail/'
      var pathname =
        action.payload.pathname || (action.payload.location || {}).pathname
      if (!pathname.startsWith(detailPath)) return state

      var pathVars = pathname.substr(detailPath.length).split('/')
      return {
        ...state,
        locationIssueID: parseInt(pathVars[0]),
        // Workspace should be the second element other use the issue
        locationWorkspaceID: pathVars[1]
          ? parseInt(pathVars[1])
          : parseInt(pathVars[0] || '0'),
      }
    case FilterConstants.FAILURE:
      return {
        ...state,
      }
    case FilterConstants.SUCCESSFUL:
    case FilterSaveConstants.SUCCESS: {
      const issueFilter = addHideFilterMap(action.data)
      const issueFilterKeys = Object.keys(issueFilter || {})
      return {
        ...state,
        issueFilter,
        issueFilterKeys,
      }
    }
    case ALERT_FILTER.SUCCESSFUL:
    case ALERT_FILTER_SAVE.SUCCESS:
      if (
        action.payload.result &&
        Object.keys(action.payload.result).length > 0
      ) {
        return {
          ...state,
          alertFilter: action.payload.result,
        }
      } else {
        return {
          ...state,
          alertFilter: issueAppInitState.alertFilter,
        }
      }
    case ALERT_FILTER.FAILURE:
    case ALERT_FILTER_SAVE.FAILURE:
      return {
        ...state,
      }
    case ALERT_SEVERITY_COLORS.SUCCESSFUL:
      if (action.payload.result && action.payload.result.length > 0) {
        return {
          ...state,
          alertSeverityColors: action.payload.result,
        }
      } else {
        return {
          ...state,
        }
      }
    case LoginConstants.SUCCESSFUL:
    case AppConstants.FETCH_INIT.COMPLETE:
      return {
        ...state,
        DnrEmail: initialState.DnrEmail || issueAppInitState.DnrEmail,
        SiteName: initialState.SiteName || issueAppInitState.SiteName,
        Environment: initialState.Environment || issueAppInitState.Environment,
        BuildInformation:
          initialState.BuildInformation || issueAppInitState.BuildInformation,
        BuildCreated: initialState.BuildCreated,
        AppVersion: initialState.AppVersion || issueAppInitState.AppVersion,
        gitBranch: initialState.gitBranch || issueAppInitState.gitBranch,
        gitName: initialState.gitName || issueAppInitState.gitName,
        gitEmail: initialState.gitEmail || issueAppInitState.gitEmail,
        SMSPromptNeeded:
          initialState.SMSPromptNeeded || issueAppInitState.SMSPromptNeeded,
        mobilePromptDelay:
          initialState.mobilePromptDelay || issueAppInitState.mobilePromptDelay,
      }
    case CHANGE_ISSUE_STAGE_TAB:
      return {
        ...state,
        issueStageType: action.payload.tab,
      }
    case 'issueApp/toggleSMSPromptNeeded':
      return {
        ...state,
        SMSPromptNeeded: 'N',
      }
    default:
      return state
  }
}

function addHideFilterMap(issueFilter: any) {
  if (!issueFilter) return issueFilter

  let newIssueFilter = {
    ...issueFilter,
  }

  newIssueFilter.HideSeveritySet = new Set()
  ;(newIssueFilter.HideSeverity || []).forEach((hideId: any) => {
    newIssueFilter.HideSeveritySet.add(hideId)
  })

  return newIssueFilter
}

// ISSUE APP SELECTORS
export const selectIssueFieldSortDir = (field: string) =>
  issueAppDefaultSortDir[(field || '').toLocaleLowerCase()] || 'DESC'
export const selectDefaultIssueId = (state: IssueAppState) =>
  state.defaultIssueId
export const selectFilterDefaultIncludeResolved = () =>
  issueAppInitState.issueFilter.IssueResolved
export const selectIssueFilter = (state: IssueAppState) =>
  state.issueFilter as any
export const selectIssueFilterKeys = (state: IssueAppState) =>
  state.issueFilterKeys
export const selectIssueFilterField = (state: IssueAppState, field: any) => {
  // Return mutliple keys
  if (field instanceof Array) {
    let keyValues: any = {}
    field.forEach((f) => (keyValues[f] = selectIssueFilterField(state, f)))
    return keyValues
  }
  // single keys only
  let value = (selectIssueFilter(state) || {})[field]

  if (
    value === undefined ||
    value === null ||
    value === '' ||
    (value instanceof Array && value.length === 0)
  ) {
    // @ts-expect-error
    value = issueAppInitState.issueFilter[field] as any
  }

  return value
}
export const selectFilterIssueModeType = (state: IssueAppState) =>
  selectIssueFilterField(state, 'Type')
export const selectFilterIncludeResolved = (state: IssueAppState) =>
  selectIssueFilterField(state, 'IssueResolved')
export const selectFilterStartDate = (state: IssueAppState) =>
  selectIssueFilterField(state, 'startDate')
export const selectFilterEndDate = (state: IssueAppState) =>
  selectIssueFilterField(state, 'endDate')
export const selectFilterTags = (state: IssueAppState) =>
  selectIssueFilterField(state, 'tags')
export const selectIssuesSortBy = (state: IssueAppState) =>
  selectIssueFilterField(state, 'sortBy')
export const selectIssuesOrgIdList = (state: IssueAppState) =>
  selectIssueFilterField(state, 'orgIdList')

export const selectIssuesSortDir = (state: IssueAppState) =>
  selectIssueFieldSortDir(selectIssueFilterField(state, 'sortBy')) ||
  selectIssueFilterField(state, 'sortDir')
export const selectUserFilters = (state: IssueAppState) => state.filters
export const selectUserInitialState = (state: IssueAppState) => state
export const selectOrgIssueLevels = (state: IssueAppState) =>
  state.orgIssueLevels
export const selectOrgIssueLevelIdMap = (state: IssueAppState) =>
  state.orgIssueLevelsMap
export const selectOrgIssueLevelById = (
  state: IssueAppState,
  levelID: number
) => (state.orgIssueLevelsMap || {})[levelID]
export const doesOrgIssueLevelExist = (state: IssueAppState, levelID: number) =>
  selectOrgIssueLevelById(state, levelID) ? true : false
export const selectOrgIssueTypes = createSelector(
  (state: IssueAppState) => state.orgIssueTypes,
  (state: IssueAppState) => state.orgIssueTypesOrder,
  (map, ids) => {
    if (!ids) return undefined

    return ids.map((id: number) => map[id])
  }
)
export const selectOrgIssueLevelNameById = (
  state: IssueAppState,
  levelID: number
) => (selectOrgIssueLevelById(state, levelID) || {}).Descr
export const isOrgIssueLevelSelected = (
  state: IssueAppState,
  levelID: number
) =>
  selectOrgIssueLevelById(state, levelID) &&
  isOrgIssueLevelSelectedHelper(state.issueFilter, levelID)
function isOrgIssueLevelSelectedHelper(issueFilter: any, levelID: number) {
  issueFilter = issueFilter || {}
  return (
    !issueFilter.HideSeverity ||
    issueFilter.HideSeverity.length === 0 ||
    !issueFilter.HideSeveritySet ||
    !issueFilter.HideSeveritySet.has(levelID)
  )
}
export const isOrgIssueLevelFilterApplied = createSelector(
  selectIssueFilter,
  selectOrgIssueLevels,
  selectOrgIssueLevelIdMap,
  (filters, allLevels, idMap) => {
    allLevels = allLevels || []
    let selectedCount = 0
    for (let i = 0; i < allLevels.length; i++) {
      const levelID = allLevels[i].Value
      if (idMap[levelID] && isOrgIssueLevelSelectedHelper(filters, levelID))
        selectedCount++
    }

    if (selectedCount > 0 && selectedCount !== allLevels.length) return true

    return false
  }
)
export const isIssueModeTypeFilterApplied = (state: IssueAppState) =>
  (selectFilterIssueModeType(state) || []).length !==
  issueAppInitState.issueFilter.Type.length
export const isStartDateFilterApplied = (state: IssueAppState) =>
  selectFilterStartDate(state) !== issueAppInitState.issueFilter.startDate
export const isEndDateFilterApplied = (state: IssueAppState) =>
  selectFilterEndDate(state) !== issueAppInitState.issueFilter.endDate
export const isTagsFilterApplied = (state: IssueAppState) =>
  selectFilterTags(state) ? true : false
export const isOrgFilterApplied = (state: IssueAppState) => {
  const userOrgInfo: AccountOrganization = selectUserOrgInfo(state)
  const selectedOrgFilter = selectIssuesOrgIdList(state)
  const isFilterEmpty = selectedOrgFilter.length === 0
  return (
    !(userOrgInfo?.Orgs?.length === selectedOrgFilter?.length) && !isFilterEmpty
  )
}

export const isIncludeResolvedFilterApplied = (state: IssueAppState) =>
  selectFilterIncludeResolved(state) !==
  issueAppInitState.issueFilter.IssueResolved
export const selectDnrEmail = (state: IssueAppState) => (state || {}).DnrEmail // The password reset from email
export const selectSiteName = (state: IssueAppState) => (state || {}).SiteName
export const selectEnvironment = (state: IssueAppState) =>
  (state || {}).Environment
export const selectGitBranch = (state: IssueAppState) => (state || {}).gitBranch
export const selectGitName = (state: IssueAppState) => (state || {}).gitName
export const selectGitEmail = (state: IssueAppState) => (state || {}).gitEmail
export const selectSMSPromptNeeded = (state: IssueAppState) =>
  (state || {}).SMSPromptNeeded
export const selectMobilePromptDelay = (state: IssueAppState) =>
  (state || {}).mobilePromptDelay

export const selectBuildInformation = (state: IssueAppState) =>
  (state || {}).BuildInformation
export const selectBuildCreated = (state: IssueAppState) =>
  (state || {}).BuildCreated
export const selectAppVersion = (state: IssueAppState) =>
  (state || {}).AppVersion
export const selectIsDevelopment = (state: IssueAppState) =>
  (selectEnvironment(state) || '').toLocaleLowerCase() === 'development'
export const selectPortalSite = (state: IssueAppState): string => {
  const env = (selectEnvironment(state) || '').toLocaleLowerCase()

  switch (env) {
    case 'stage':
      return 'https://stage.incaseofcrisis.com'
    case 'production':
      return 'https://incaseofcrisis.com'
    default:
      return 'https://iccdev.incaseofcrisis.com'
  }
}

export const selectPbAppSite = (state: IssueAppState): string => {
  const env = (selectEnvironment(state) || '').toLocaleLowerCase()

  switch (env) {
    case 'stage':
      return 'https://pbapp-stage.incaseofcrisis.com'
    case 'production':
      return 'https://pbapp.incaseofcrisis.com'
    default:
      return 'https://pbapp-dev.incaseofcrisis.com'
  }
}

export const selectSendNotificationsSite = (state: IssueAppState): string => {
  const env = (selectEnvironment(state) || '').toLocaleLowerCase()

  switch (env) {
    case 'stage':
      return 'https://sendnotify-stage.incaseofcrisis.com'
    case 'production':
      return 'https://sendnotify.incaseofcrisis.com'
    default:
      return 'https://sendnotify-dev.incaseofcrisis.com'
  }
}

export const selectFormEditorSite = (state: IssueAppState): string => {
  const env = (selectEnvironment(state) || '').toLocaleLowerCase()

  switch (env) {
    case 'stage':
      return 'https://formeditor-stage.incaseofcrisis.com'
    case 'production':
      return 'https://formeditor.incaseofcrisis.com'
    default:
      return 'https://formeditor-dev.incaseofcrisis.com'
  }
}

export const selectOrgIssueTypeById = (state: IssueAppState, typeID: string) =>
  (state.orgIssueTypes || {})[typeID]
export const selectLocationWorkspaceId = (state: IssueAppState) =>
  state.locationWorkspaceID
export const selectLocationIssueId = (state: IssueAppState) =>
  (state || {}).locationIssueID
export const isIssueBeingCreated = (state: IssueAppState, issueID: number) =>
  issueID !== undefined &&
  issueID !== null &&
  state.pendingCreatedIssue === issueID
export const selectUserOrgInfo = (state: IssueAppState) =>
  (state || {}).orgInfo || {}
export const selectUserFullName = (state: IssueAppState) =>
  ((state || {}).UserInfo || {}).FullName
export const selectUserEmail = (state: IssueAppState) =>
  ((state || {}).UserInfo || {}).EmailAddress
export const selectIssueStageTab = (state: IssueAppState) =>
  (state || {}).issueStageType || 'assigned'
export const selectIssueActivityLogChangeDate = (state: IssueAppState) =>
  (state || {}).lastActivityLogNewChangesDate
export const selectAlertSeverityColors = (state: IssueAppState) =>
  state.alertSeverityColors

// This reducer maps a workspace id to its parent issue.
// This allows the workspace to have easy access to its parent

interface WorkspaceIssueState {
  [workspaceID: number]: any
}
export function workspaceIssueReducer(
  state: WorkspaceIssueState = {},
  action: any
) {
  switch (action.type) {
    case IssueConstants.INIT.SUCCESS:
      return reducerCrud.mapChildrenToParents(
        action.payload.result,
        'wksp',
        (issue: any) => issue.IssueID
      )
    case WorkspaceConstants.ADD_RELATED.SUCCESS:
      const newMap: WorkspaceIssueState = {}
      action.payload.children.forEach(
        (workspaceID: number) =>
          (newMap[workspaceID] = parseInt(action.payload.source.IssueID))
      )
      return {
        ...state,
        ...newMap,
      }
    case WorkspaceConstants.ADD.SUCCESS:
      return {
        ...state,
        [action.payload.result.IssueTeamWorkspaceID]:
          action.payload.source.IssueID,
      }
    case WorkspaceConstants.DELETE.SUCCESS:
      return {
        ...state,
        [action.payload.result.IssueTeamWorkspaceID]: null,
      }
    case WorkspaceConstants.DELETE_RANGE.SUCCESS:
      return reducerCrud.deleteIdRange(state, action.payload.result)

    case ParticipantConstants.ADD.SUCCESS:
      return {
        ...state,
        [action.payload.source.IssueTeamWorkspaceID]:
          action.payload.source.IssueID,
      }
    default:
      return state
  }
}

export const selectWorkspaceIssueIdMap = (state: WorkspaceIssueState) => state
export const selectWorkspaceIssueId = (
  state: WorkspaceIssueState,
  workspaceID: number
) => (selectWorkspaceIssueIdMap(state) || {})[workspaceID]

export interface EvidenceLockerLink {
  linkID?: number | string
  createdBy?: string
  createdUTC?: string
  linkURL: string
  linkName: string
  IssueID?: number
  IssueTeamWorkspaceID?: number
}
interface evidenceLockerLinkState {
  [linkID: number | string]: EvidenceLockerLink
}
export function evidenceLockerLinkReducer(
  state: evidenceLockerLinkState = {},
  action: any
) {
  switch (action.type) {
    case EvidenceLockerLinkConstants.INIT.SUCCESS:
      return action.payload.result
    case EvidenceLockerLinkConstants.ADD.SUCCESS:
      return {
        ...state,
        [action.payload.result['linkID']]: action.payload.result,
      }
    case EvidenceLockerLinkConstants.DELETE.SUCCESS:
      let newState = { ...state }
      delete newState[action.payload.result['linkID']]
      return newState
    case EvidenceLockerLinkConstants.UPDATE.SUCCESS:
      return {
        ...state,
        [action.payload.result['linkID']]: action.payload.result,
      }
    case EvidenceLockerLinkConstants.UPDATE_RANGE.SUCCESS:
      return reducerCrud.updateRange(state, action, 'linkID')
    default:
      return state
  }
}

export const selectEvidenceLockerLinkById = (
  state: evidenceLockerLinkState,
  linkID: number
) => (state || {})[linkID]
export const selectEvidenceLockerLinkIDMap = (state: evidenceLockerLinkState) =>
  state

export interface IssueGeolocation {
  LocationID?: number | string
  IssueID?: number
  WKT?: string
  Longitude: number
  Latitude: number
  RadiusInMiles?: number
  Source?: string
  Title?: string
  LocationDescription?: string
  ShouldDisplayYN: 'Y' | 'N'
  IssueTeamWorkspaceID?: number
  CreatedBy?: string
  Created?: string
  GeoJSON?: string
}
export interface GeolocationState {
  [LocationID: number | string]: IssueGeolocation
}
export function GeolocationReducer(state: GeolocationState = {}, action: any) {
  switch (action.type) {
    case GeolocationConstants.INIT.SUCCESS:
      return action.payload.result
    case GeolocationConstants.ADD.SUCCESS:
      return {
        ...state,
        [action.payload.result['LocationID']]: action.payload.result,
      }
    case GeolocationConstants.UPDATE.SUCCESS:
      return {
        ...state,
        [action.payload.result['LocationID']]: action.payload.result,
      }
    case GeolocationConstants.DELETE.SUCCESS:
      let newState = { ...state }
      delete newState[action.payload.result['LocationID']]
      return newState
    case GeolocationConstants.UPDATE_RANGE.SUCCESS:
      return reducerCrud.updateRange(state, action, 'LocationID')
    default:
      return state
  }
}

export const selectGeolocationById = (
  state: GeolocationState,
  LocationID: number
) => (state || {})[LocationID]
export const selectGeolocationIDMap = (state: GeolocationState) => state
export const selectGeolocationsArray = (state: GeolocationState) => {
  const locations: Array<IssueGeolocation> = []

  Object.entries(state).forEach((locationKeyValue) =>
    locations.push(locationKeyValue[1])
  )

  return locations
}
