import * as React from 'react'
import { DashboardOrgTeamModel } from './DashboardModels'

export const DashboardOrganizationSection = ({
  orgTeams,
}: {
  orgTeams?: DashboardOrgTeamModel[]
}) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        padding: '10px',
      }}
    >
      {orgTeams &&
        orgTeams.map((orgTeam: DashboardOrgTeamModel, otIDX: number) => (
          <div key={'orgTeam' + otIDX.toString()}>
            <div style={{ fontSize: 'small' }}>
              {orgTeam.OrgName} / {orgTeam.TeamName}
            </div>
            {/* <Spacer spaceParam={'small'} /> */}
          </div>
        ))}
    </div>
  )
}
