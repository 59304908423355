import * as React from 'react'
import { useState, CSSProperties, ReactNode, ReactElement } from 'react'
import * as layoutClasses from './HPanelLayout.module.scss'
import { Paper } from '@material-ui/core'
import { ArrowBack, ArrowForward } from '@material-ui/icons'
import { cx } from '../_utils/objectUtils'

const classes: any = layoutClasses

interface Props {
  children: ReactElement<HPanelProps> | ReactElement<HPanelProps>[]
  className?: string
  stacked?: boolean
}

export const HPanelLayout = ({
  children,
  className,
  stacked = false,
  ...other
}: Props) => {
  return (
    <div
      {...other}
      className={cx(classes.root, stacked ? classes.stacked : '', className)}
    >
      {children}
    </div>
  )
}

interface HPanelProps {
  hasPaper?: boolean
  children?: ReactNode
  isCollapsible?: boolean
  onCollapseToggle?: Function
  header?: ReactNode
  headerExtras?: ReactNode | ReactNode[]
  fillWidth?: boolean
  overflowY?: 'hidden' | 'auto' | 'visible'
  className?: string
  classes?: {
    content?: string
    header?: string
    contentHeader?: string
    headerExtras?: string
  }
  styles?: { content?: CSSProperties }
  contentHeader?: ReactNode | ReactNode[]
}

export const HPanel = ({
  hasPaper = true,
  children,
  isCollapsible,
  onCollapseToggle,
  header,
  fillWidth = false,
  overflowY = 'auto',
  className,
  classes: ownClasses = {},
  styles = {},
  contentHeader,
  headerExtras,
}: HPanelProps) => {
  const [isExpanded, setExpanded] = useState(true)
  const [closing, setClosing] = useState(false)
  const [opening, setOpening] = useState(false)

  function toggle() {
    if (closing || opening) return

    if (isExpanded) {
      // Close
      setOpening(false)
      setClosing(true)
    } else {
      // Open
      setOpening(true)
      setClosing(false)
      setExpanded(true)
    }
  }

  function toggleDone() {
    const newIsMenuExpanded = (opening || isExpanded) && !closing ? true : false
    setOpening(false)
    setClosing(false)
    setExpanded(newIsMenuExpanded)

    if (onCollapseToggle) onCollapseToggle(newIsMenuExpanded)
  }

  function renderContent(children: any) {
    let contentContainerClasses = classes.contentContainer

    if (overflowY)
      contentContainerClasses += ' ' + classes['overflowY' + overflowY]

    const content = (
      <>
        {contentHeader ? (
          <div className={cx(classes.header, ownClasses.contentHeader, 'pb-3')}>
            {contentHeader}
          </div>
        ) : null}
        <div className={classes.body}>
          <PanelContent
            className={ownClasses.content}
            isCollapsible={isCollapsible}
            isExpanded={isExpanded}
            onToggleClicked={toggle}
            style={styles.content}
          >
            {children || null}
          </PanelContent>
        </div>
      </>
    )
    if (hasPaper)
      return <Paper className={contentContainerClasses}>{content}</Paper>

    return <div className={contentContainerClasses}>{content}</div>
  }

  let panelClasses = classes.panel
  if (closing || !isExpanded) {
    panelClasses += ' ' + classes.collapsed
  }

  if (closing || opening) panelClasses += ' ' + classes.collapsibleAnimating

  if (fillWidth) panelClasses += ' ' + classes.fillWidth

  return (
    <div
      className={cx(
        panelClasses,
        isCollapsible ? classes.collapsible : '',
        className
      )}
      onTransitionEnd={toggleDone}
    >
      <div className={cx(classes.panelHeader, ownClasses.header)}>
        <h2 className={classes.panelHeaderName}>{header}</h2>
        {headerExtras && (
          <div
            className={cx(classes.panelHeaderExtras, ownClasses.headerExtras)}
          >
            {headerExtras}
          </div>
        )}
      </div>
      {renderContent(children)}
    </div>
  )
}

interface HPanelContentProps /*extends HtmlHTMLAttributes<HTMLDivElement>*/ {
  isCollapsible?: boolean
  isExpanded?: boolean
  onToggleClicked?(event: React.MouseEvent<HTMLDivElement, MouseEvent>): void
  style?: CSSProperties
  className?: string
  children: ReactElement<any>
}

const PanelContent: React.FC<HPanelContentProps> = ({
  isCollapsible = false,
  children,
  className,
  style,
  isExpanded = true,
  onToggleClicked = function () {},
}) => {
  if (!isCollapsible)
    return (
      <div className={cx(classes.panelContent, className)}>
        {children || null}
      </div>
    )

  return (
    <div
      className={cx(classes.panelContent, classes.collapsibleTogglerContainer)}
    >
      <div className={classes.collapsibleToggler} onClick={onToggleClicked}>
        {isExpanded ? 'Collapse' : null}
        {isExpanded ? (
          <ArrowBack className={classes.collapsibleTogglerIcon} />
        ) : (
          <ArrowForward className={classes.collapsibleTogglerIcon} />
        )}
      </div>
      <div className={cx(classes.collapsibleContent, className)} style={style}>
        {children || null}
      </div>
    </div>
  )
}
