// @ts-nocheck
// TODO: Typescript
import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

/**
 * @extends {Component<Props, {}>}}
 * Allows developers to connected a component
 * dynamically by using an inline call
 */
export class ConnectedItem extends Component {
  render() {
    const { children, item, key } = this.props

    return <React.Fragment key={key}>{children(item)}</React.Fragment>
  }
}

const mapStateToProps = (state, ownProps) => {
  const { selectItemFromStore } = ownProps
  const item = selectItemFromStore(state, ownProps) || {}
  return {
    item,
  }
}

ConnectedItem.propTypes = {
  /**
   * The callback to use to get the
   * item from the store
   * parameters are (storeState, ownProps)
   */
  selectItemFromStore: PropTypes.func.isRequired,
}

export default connect(mapStateToProps, undefined)(ConnectedItem)
