import * as React from 'react'
import { Component, ReactNode } from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Toolbar,
} from '@material-ui/core'
import { Button } from 'reactstrap'
import { SlideLeft } from './Transition'
import { HeaderNavigation } from './HeaderNavigation'

interface Props {
  open: boolean
  ok(): void
  title: ReactNode
  content: ReactNode
  okText: string
  includeOk: boolean
  includeClose: boolean
}

/**
 * @extends {Component<Props, {}>}}
 * Reusable alert dialog
 */
export class AlertDialog extends Component<Props> {
  static defaultProps: Props
  render() {
    const { open, ok, title, content, okText, includeOk, includeClose } =
      this.props
    return (
      <Dialog open={open} onClose={ok} TransitionComponent={SlideLeft as any}>
        <DialogTitle>
          {includeClose ? (
            <Toolbar>
              <HeaderNavigation
                title={title}
                onNavClick={ok}
                canGoBack={false}
              />
            </Toolbar>
          ) : (
            title
          )}
        </DialogTitle>
        <DialogContent>{content}</DialogContent>
        {includeOk && (
          <DialogActions>
            <Button autoFocus onClick={ok} color="primary">
              {okText}
            </Button>
          </DialogActions>
        )}
      </Dialog>
    )
  }
}

AlertDialog.defaultProps = {
  title: '',
  okText: 'Ok',
  content: '',
  open: false,
  ok: function () {},
  includeClose: false,
  includeOk: true,
}
