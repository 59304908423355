import * as React from 'react'
import { MouseEvent, useEffect } from 'react'
import { HPanelLayout, HPanel } from '../Layouts/HPanelLayout'
import { MasterPageLayout } from './MasterPageLayout'
import { PbItemProps } from '../Playbook/PbItem'
import { PbList } from '../Playbook/PbList'
import { PbEventItemProps } from '../Playbook/PbEvItem'
import { PbEvList } from '../Playbook/PbEvList'
import { PbEvDetail } from '../Playbook/PbEvDetail'
import { DesktopLayout, MobileLayout } from '../Layouts/ResponsiveHidden'
import MobileViewLayout from '../Layouts/MobileViewLayout'
import { PlaybookRouteParams } from '../Routing/RouteProps'
import { RouteComponentProps } from 'react-router'
import { cx, tryParseInt } from '../_utils/objectUtils'
import { MainPageBackBtn } from '../Shared/Buttons'
import classes from '../Playbook/Playbooks.module.scss'
import { useAppDispatch, useAppSelector } from '../_utils/reactReduxHooks'
import { selectPlaybookList } from '../Playbook/_selectors'
import { fetchPlaybookList, fetchPlaybookUpdates } from '../Playbook/_actions'
import { CircularProgress } from '../Shared/LoadingBackdrop'
import { useInterval } from '../_utils/hooks'

interface Props extends RouteComponentProps<PlaybookRouteParams> {}

export const PlaybookPage = ({ history, match, location }: Props) => {
  const [selectedEvent, setEvent] = React.useState<PbEventItemProps>()
  const [defaultPlaybook, setDefaultPlaybook] = React.useState<any>()

  const pbListFromStore = useAppSelector(selectPlaybookList)
  const dispatch = useAppDispatch()

  const navigate = React.useCallback(
    (params: PlaybookRouteParams = {}) => {
      if (params.PubGUID === undefined) {
        history.push({ pathname: '/Playbook' })
        return
      }

      history.push({
        pathname: `/Playbook/Playbooks/${params?.PubGUID}/${
          params?.EventID || ''
        }`,
      })
    },
    [history]
  )
  useEffect(() => {
    if (pbListFromStore) {
      if (pbListFromStore.PbList) {
        if (pbListFromStore.PbList.length > 0) {
          if (!defaultPlaybook) {
            //console.log("DRH DEBUG in useEffect to set default playbook");
            setDefaultPlaybook(pbListFromStore.PbList[0]) // console.log("<DR></DR>H DEBUG back from calling dispatch in PbList.tsx");
          }
        }
      }
    }
    const curGuid = match.params.PubGUID || defaultPlaybook?.PubGUID
    const list = pbListFromStore?.PbList || []

    if (list.length > 0) {
      //console.log("pbListFromStore is ", pbListFromStore);
      //console.log("curList is ", list);
      // console.log(
      //   "filtering list ",
      //   list.find((pub: any) => pub.PubGUID === curGuid)
      // );
      if (!list.find((pub: any) => pub.PubGUID === curGuid)) {
        const navPubGUID = list[0]?.PubGUID
        if (navPubGUID) {
          navigate({ PubGUID: list[0]?.PubGUID })
        }
      }
    }
  }, [defaultPlaybook, match.params.PubGUID, navigate, pbListFromStore])

  useEffect(() => {
    //console.log("DRH DEBUG calling dispatch in PbList.tsx");
    //console.log("DRH DEBUG in useEffect PbList.tsx pbListFromStore is ", pbListFromStore);
    if (!pbListFromStore) {
      //console.log("DRH DEBUG calling dispatch in PlaybookPage.tsx");
      dispatch(fetchPlaybookList.request())
      //console.log("DRH DEBUG back from calling dispatch in PlaybookPage.tsx");
    }
    // console.log("DRH DEBUG back from calling dispatch in PbList.tsx");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // go to get updates every so often
  useInterval(doGetPlaybookUpdates, 15 * 1000)
  async function doGetPlaybookUpdates() {
    //console.log("DRH DEBUG calling for playbook updates in PlaybookPage.tsx");
    dispatch(fetchPlaybookUpdates.request())
  }
  // end of getting updates every so often

  function handlePubClick(newPub: PbItemProps, isMobile: boolean = false) {
    if (newPub.PubGUID !== getSelectedPubGUID(isMobile))
      navigate({ PubGUID: newPub.PubGUID })
  }

  function handlePubClickMobile(newPub: PbItemProps) {
    handlePubClick(newPub, true)
  }

  function handleEventClick(
    newEvent: PbEventItemProps,
    isMobile: boolean = false
  ) {
    const newEventID = newEvent.EventID.toString()
    if (newEventID !== match.params.EventID)
      navigate({
        PubGUID: getSelectedPubGUID(isMobile),
        EventID: newEventID,
      })
  }

  function handleEventClickMobile(newEvent: PbEventItemProps) {
    handleEventClick(newEvent, true)
  }

  function handleBackClick(evt: MouseEvent) {
    evt.preventDefault()
    history.push({
      pathname: '/Playbook/Playbooks',
      state: {},
    })
  }

  function handleNavigateBack() {
    if (match.params.EventID) navigate({ PubGUID: match.params.PubGUID })
    else if (match.params.PubGUID) navigate()
  }

  function getSelectedPubGUID(isMobile: boolean = false) {
    if (
      isMobile ||
      (history.location.pathname
        ?.toLocaleLowerCase()
        .startsWith('/playbook/playbooks') &&
        !match.params.PubGUID)
    )
      return match.params.PubGUID

    return match.params.PubGUID || defaultPlaybook?.PubGUID
  }

  function renderEventPlaceholder() {
    return (
      <p
        style={{
          fontStyle: 'italic',
          marginTop: '1em',
          textAlign: 'right',
          paddingRight: 10,
          position: 'relative',
        }}
      >
        Choose an Event...
      </p>
    )
  }

  // Handle the mobile rendering by current url
  function renderByPath(isMobile: boolean = true) {
    const PubGUID = getSelectedPubGUID(true)
    const onEventClick = isMobile ? handleEventClickMobile : handleEventClick
    if (match.params.EventID)
      return (
        <PbEvDetail
          className="p-2 h-100"
          EventID={parseInt(match.params.EventID)}
        />
      )
    else if (PubGUID)
      return <PbEvList onItemClick={onEventClick} pubGUID={PubGUID} />
    else return renderEventPlaceholder()
  }

  // Get the mobile title by the current url
  function renderTitleByPath(isMobile: boolean = true) {
    if (match.params.EventID) return 'Response Protocol'
    else if (getSelectedPubGUID(isMobile)) return 'Playbook Detail'
    else return ''
  }

  if (!pbListFromStore)
    // (playbooksLoading)
    return (
      <MasterPageLayout
        history={history}
        match={match as any}
        location={location}
      >
        <DesktopLayout>
          <CircularProgress />
        </DesktopLayout>
        <MobileLayout>
          <CircularProgress />
        </MobileLayout>
      </MasterPageLayout>
    )
  else {
    if (!defaultPlaybook) {
      //console.log("DRH DEBUG in final return to set default playbook");
      if (pbListFromStore.PbList) setDefaultPlaybook(pbListFromStore.PbList[0]) // console.log("<DR></DR>H DEBUG back from calling dispatch in PbList.tsx");
      //var dbgjson = JSON.stringify(pbListFromStore.PbList[0]).substring(0, 500);
      //console.log("DRH DEBUG in final return defaultPlaybook ");
    }

    return (
      <MasterPageLayout
        history={history}
        match={match as any}
        location={location}
      >
        <DesktopLayout>
          {!getSelectedPubGUID() ? (
            <div className={classes.pbListContainer}>
              <PbList className={classes.pbList} onItemClick={handlePubClick} />
            </div>
          ) : (
            <HPanelLayout>
              <HPanel
                header="Playbook Listing"
                hasPaper={false}
                contentHeader={<MainPageBackBtn onClick={handleBackClick} />}
                overflowY="hidden"
              >
                <PbList
                  className={classes.pbList}
                  onItemClick={handlePubClick}
                  selected={getSelectedPubGUID()}
                />
              </HPanel>
              <HPanel header="Playbook Detail">
                <PbEvList
                  onItemClick={handleEventClick}
                  onItemSelected={setEvent}
                  pubGUID={getSelectedPubGUID()}
                  selected={tryParseInt(match.params.EventID)}
                />
              </HPanel>
              <HPanel header="Response Protocol" fillWidth={true}>
                {match.params.EventID ? (
                  <PbEvDetail
                    className={classes.pbEvDetail}
                    EventID={selectedEvent?.EventID}
                    EventName={selectedEvent?.EventName}
                  />
                ) : (
                  renderEventPlaceholder()
                )}
              </HPanel>
            </HPanelLayout>
          )}
        </DesktopLayout>
        <MobileLayout>
          {!getSelectedPubGUID(true) ? (
            <div className={cx(classes.pbListContainer, classes.mobile)}>
              <PbList
                hasPaper={false}
                className={classes.pbList}
                onItemClick={handlePubClickMobile}
              />
            </div>
          ) : (
            <MobileViewLayout
              title={renderTitleByPath()}
              onNavClick={handleNavigateBack}
            >
              {renderByPath()}
            </MobileViewLayout>
          )}
        </MobileLayout>
      </MasterPageLayout>
    )
  }
}
