// Reducer handles data manipulation and complex selecting based on specific slice
import { createSelector } from 'reselect'
import { removeKeyFromObject } from '../_utils/objectUtils'
import {
  callAction,
  ReducerAction,
  ReducerActionMap,
  reducerCrud,
} from '../_utils/reduxUtils'
import {
  ADD_INBOX,
  PIN_ALERT,
  UNPIN_ALERT,
  ADD_ALERT_TO_ISSUE,
  CREATE_NEW_ALERT_ISSUE,
  CREATE_CLONED_ALERT_ISSUE,
  DELETE_INBOX,
  MARK_REVIEWED_ALERT,
  FETCH_INBOXES,
  FETCH_ALERTS,
  SET_INBOX_ACTIVE,
  UPDATE_INBOX,
  SHARE_ALERT,
  MARK_NOT_REVIEWED_ALERT,
  ALERT_SEARCH_VALUE,
} from './_constants'
import { AlertModel, InboxModel } from './_models'

// Inbox
export type InboxState = { [inboxGUID: string]: InboxModel }
export function inboxReducer(state: InboxState = {}, action: ReducerAction) {
  const mapped: ReducerActionMap = {
    [FETCH_INBOXES.SUCCESS]: () => ({
      ...state,
      ...action.payload.result.idMap,
    }),
    [ADD_INBOX.SUCCESS]: () =>
      reducerCrud.create(state, undefined, 'inboxGUID', action.payload.result),
    [UPDATE_INBOX.SUCCESS]: () => {
      const newState: InboxState = reducerCrud.update(
        state,
        undefined,
        'inboxGUID',
        action.payload.result,
        true
      )
      const newInbox = newState[action.payload.result.inboxGUID]
      const oldInbox = state[action.payload.result.inboxGUID] || {}
      newInbox.alerts = oldInbox.alerts
      newInbox.alertsTotal = oldInbox.alertsTotal

      return newState
    },
    [DELETE_INBOX.SUCCESS]: () =>
      removeKeyFromObject(state, action.payload.result),
    [SET_INBOX_ACTIVE.SUCCESS]: () => {
      const inbox = state[action.payload.result.inboxGUID]

      if (!inbox) return state

      return {
        ...state,
        [inbox.inboxGUID]: {
          ...inbox,
          IsActiveYN: action.payload.result.IsActiveYN,
        },
      }
    },

    [FETCH_ALERTS.SUCCESS]: () => {
      const inbox = state[action.payload.source?.inboxGUID]

      if (!inbox) return state

      return {
        ...state,
        [inbox.inboxGUID]: {
          ...inbox,
          alertsTotal: action.payload.result.count,
          alerts: action.payload.result.ids,
        },
      }
    },
  }

  return callAction(mapped, state, action)
}

export const selectInboxes = createSelector(
  (state: InboxState) => state,
  (state: InboxState) => {
    let inboxes: InboxModel[] = []
    for (let inboxGUID in state) {
      const cur = state[inboxGUID]
      if (cur) inboxes.push(cur)
    }

    return inboxes.sort((a, b) => {
      const aCreated = a?.lastAlertCreated?.getTime() || 0
      const bCreated = b?.lastAlertCreated?.getTime() || 0
      if (a?.IsActiveYN > b?.IsActiveYN) return -1
      else if (a?.IsActiveYN < b?.IsActiveYN) return 1
      else if (aCreated > bCreated) return -1
      else if (aCreated < bCreated) return 1

      return 0
    })
  }
)

export const selectInboxIdMap = (state: InboxState) => state
export const selectInboxByID = (state: InboxState, inboxID: number) => {
  let inboxDetails: {
    inboxName: string
    inboxGUID: string
    process_type: string
  } = {
    inboxName: '',
    inboxGUID: '',
    process_type: '',
  }
  Object.entries(state).forEach((inbox: [string, InboxModel]) => {
    if (inbox[1].inboxID === inboxID) {
      inboxDetails.inboxName = inbox[1].inboxName
      inboxDetails.inboxGUID = inbox[0]
      inboxDetails.process_type = inbox[1].process_type
    }
  })
  return inboxDetails
}
export const selectInboxByGUID = (state: InboxState, inboxGUID: string) =>
  state[inboxGUID]
export const selectInboxIDtoGUIDMap = (state: InboxState) => {
  let mappedGUIDsToIDs: { [inboxID: number]: string } = {}
  Object.entries(state).forEach(
    ([key, inbox]) => (mappedGUIDsToIDs[inbox.inboxID] = key)
  )
  return mappedGUIDsToIDs
}

export const selectInboxNamesWithGUIDs = (state: InboxState) => {
  let inboxList: Array<{ inboxName: string; inboxGUID: string }> = []
  Object.entries(state).forEach((inboxItem) =>
    inboxList.push({
      inboxName: inboxItem[1].inboxName,
      inboxGUID: inboxItem[0],
    })
  )
  inboxList.sort((a, b) => a.inboxName.localeCompare(b.inboxName))
  return inboxList
}

export const selectInboxIDsWithDefaultSeverity = (state: InboxState) => {
  let inboxIDstoSeverityMap: { [inboxID: number]: 'Low' | 'Medium' | 'High' } =
    {}
  Object.entries(state).forEach((inboxItem) => {
    inboxIDstoSeverityMap[inboxItem[1].inboxID] = inboxItem[1].defaultSeverity
  })

  return inboxIDstoSeverityMap
}

export const selectInboxCurrentFeedGUID = (
  state: InboxState,
  inboxGUID: string
) => {
  const feeds = state[inboxGUID]?.feeds || []

  return feeds[feeds.length - 1]
}

// Alert
type AlertState = { [alert_ExtId: string]: AlertModel }
export function alertReducer(
  state: AlertState = {},
  action: ReducerAction
): AlertState {
  const mapped: ReducerActionMap = {
    // Only save one feed at a time
    [FETCH_ALERTS.SUCCESS]: () => ({ ...action.payload.result.idMap }),
    [MARK_REVIEWED_ALERT.SUCCESS]: () => {
      const {
        alert_ExtId,
        alert_Updated,
        alertStatus,
        alertStatusID,
        alertStatusHistory,
      } = action.payload.result
      const existingAlert = state[alert_ExtId]
      if (!existingAlert) return state

      return {
        ...state,
        [existingAlert.Id]: {
          ...existingAlert,
          alertID: null,
          created: null,
          updated: alert_Updated,
          alertStatus,
          alertStatusID,
          alertStatusHistory,
        },
      }
    },
    [MARK_NOT_REVIEWED_ALERT.SUCCESS]: () => {
      const {
        alert_ExtId,
        alert_Updated,
        alertStatus,
        alertStatusID,
        alertStatusHistory,
      } = action.payload.result
      const existingAlert = state[alert_ExtId]
      if (!existingAlert) return state

      return {
        ...state,
        [existingAlert.Id]: {
          ...existingAlert,
          alertID: null,
          created: null,
          updated: alert_Updated,
          alertStatus,
          alertStatusID,
          alertStatusHistory,
        },
      }
    },
    [PIN_ALERT.SUCCESS]: () => {
      const {
        alert_ExtId,
        alertID,
        created,
        alert_Updated,
        issueID,
        alertStatus,
        alertStatusID,
        alertStatusHistory,
      } = action.payload.result
      const existingAlert = state[alert_ExtId]
      if (!existingAlert) return state

      return {
        ...state,
        [alert_ExtId]: {
          ...existingAlert,
          alertID,
          created: created,
          updated: alert_Updated,
          issueID: issueID ? issueID : state[alert_ExtId].issueID,
          alertStatus,
          alertStatusHistory,
          alertStatusID,
        },
      }
    },
    [UNPIN_ALERT.SUCCESS]: () => {
      const {
        alert_ExtId,
        alert_Updated,
        alertStatus,
        alertStatusID,
        alertStatusHistory,
      } = action.payload.result
      const existingAlert = state[alert_ExtId]
      if (!existingAlert) return state

      return {
        ...state,
        [existingAlert.Id]: {
          ...existingAlert,
          alertID: null,
          created: null,
          updated: alert_Updated,
          alertStatus,
          alertStatusID,
          alertStatusHistory,
        },
      }
    },
    [SHARE_ALERT.SUCCESS]: () => {
      const {
        alert_ExtId,
        alertID,
        created,
        alert_Updated,
        alertStatus,
        alertStatusID,
        alertStatusHistory,
        issueID,
      } = action.payload.result
      const existingAlert = state[alert_ExtId]
      if (!existingAlert) return state

      return {
        ...state,
        [existingAlert.Id]: {
          ...existingAlert,
          alertID,
          created: created,
          updated: alert_Updated,
          alertStatus,
          alertStatusID,
          alertStatusHistory,
          issueID: issueID || existingAlert.issueID,
        },
      }
    },
    [CREATE_NEW_ALERT_ISSUE.SUCCESS]: () => {
      const {
        alert_ExtId,
        alertID,
        created,
        alert_Updated,
        issueID,
        incidentID,
        alertStatus,
        alertStatusID,
        alertStatusHistory,
      } = action.payload.result
      const existingAlert = state[alert_ExtId]
      if (!existingAlert) return state

      return {
        ...state,
        [existingAlert.Id]: {
          ...existingAlert,
          issueID,
          incidentID,
          alertID,
          created: created,
          updated: alert_Updated,
          alertStatus,
          alertStatusID,
          alertStatusHistory,
        },
      }
    },
    [CREATE_CLONED_ALERT_ISSUE.SUCCESS]: () => {
      const {
        alert_ExtId,
        alertID,
        created,
        alert_Updated,
        issueID,
        incidentID,
        alertStatus,
        alertStatusID,
        alertStatusHistory,
      } = action.payload.result
      const existingAlert = state[alert_ExtId]
      if (!existingAlert) return state

      return {
        ...state,
        [existingAlert.Id]: {
          ...existingAlert,
          issueID,
          incidentID,
          alertID,
          created: created,
          updated: alert_Updated,
          alertStatus,
          alertStatusID,
          alertStatusHistory,
        },
      }
    },
    [ADD_ALERT_TO_ISSUE.SUCCESS]: () => {
      const {
        alert_ExtId,
        alertID,
        created,
        alert_Updated,
        issueID,
        incidentID,
        alertStatus,
        alertStatusID,
        alertStatusHistory,
      } = action.payload.result
      const existingAlert = state[alert_ExtId]
      if (!existingAlert) return state

      return {
        ...state,
        [existingAlert.Id]: {
          ...existingAlert,
          alertID,
          created: created,
          updated: alert_Updated,
          issueID,
          incidentID,
          alertStatus,
          alertStatusID,
          alertStatusHistory,
        },
      }
    },
  }

  return callAction(mapped, state, action)
}

export function alertsSortedIDsReducer(
  state: Array<string> = [],
  action: ReducerAction
) {
  if (action.type === FETCH_ALERTS.SUCCESS) {
    let sortedAlerts: Array<string> = []
    action.payload.result.ids.forEach((alertID: string) => {
      if (!sortedAlerts.includes(alertID)) {
        sortedAlerts.push(alertID)
      }
    })
    return sortedAlerts
  } else {
    return state
  }
}

export const selectAlertByID = (state: AlertState, ID: string) => state[ID]

export interface AlertSearch {
  searchValue?: string
}
export function alertSearchReducer(state: AlertSearch = {}, action: any) {
  const mapped: ReducerActionMap = {
    [ALERT_SEARCH_VALUE]: () => {
      return {
        searchValue: action.payload.searchValue,
      }
    },
  }
  return callAction(mapped, state, action)
}
export const selectAlertSearch = (state: AlertSearch) => state.searchValue
