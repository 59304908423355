import { faTimes } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  CircularProgress,
  ClickAwayListener,
  DialogContent,
  FormControlLabel,
  Input,
  Paper,
  Popper,
  Radio,
  RadioGroup,
} from '@material-ui/core'
import React, { ChangeEvent, useEffect, useRef, useState } from 'react'
import { Button } from '../Shared/Buttons'
import { ResponsiveDialog } from '../Shared/ResponsiveDialog'
import { Spacer } from '../Shared/Spacer'
import { SlideLeft } from '../Shared/Transition'
import { addNotification } from '../_actions'
import Papa from 'papaparse'
import classes from '../Task/TaskTemplateUploadDialog.module.scss'
import { useAppDispatch } from '../_utils/reactReduxHooks'
import { importOrgAsset } from '../_utils/apiUtils'
import { faInfoCircle } from '@fortawesome/pro-light-svg-icons'

export const AssetImportDialog = ({
  open,
  orgId,
  onSave,
  onClose,
}: {
  open: boolean
  orgId: number
  onSave: () => void
  onClose: () => void
}) => {
  const dispatch = useAppDispatch()
  const [csvData, setCsvData] = useState<any[]>([])
  const [importLoading, setImportLoading] = useState(false)
  const [uploadAction, setUploadAction] = useState('N')
  const [infoWindowOpen, setInfoWindowOpen] = useState(false)

  const infoRef = useRef(null)

  const closeDialog = () => {
    if (infoWindowOpen) return
    onClose()
  }

  useEffect(() => {
    setCsvData([])
  }, [open])

  const validateCSVData = (data: any) => {
    const requiredFields = ['Name', 'Latitude', 'Longitude']
    for (const [i, row] of data.entries()) {
      for (const field of requiredFields) {
        if (!row.hasOwnProperty(field) || !row[field]) {
          return { valid: false, line: i, row: row, failureField: field }
        }
      }
    }
    return { valid: true, line: '', row: '', failureField: '' }
  }

  const openInfoDialog = (event: any) => {
    infoRef.current = event?.target
    setInfoWindowOpen(true)
  }
  const handleInfoClose = () => {
    setInfoWindowOpen(false)
  }

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]
    if (file) {
      Papa.parse(file, {
        header: true,
        complete: (results) => {
          const filteredData = results.data.filter((row: any) => {
            return Object.values(row).some(
              (value) => value !== null && value !== undefined && value !== ''
            )
          })
          const validated = validateCSVData(filteredData)

          if (validated.valid) {
            setCsvData(filteredData)
          } else {
            dispatch(
              addNotification({
                type: 'error',
                message: `CSV file is not properly formatted. Please check ${
                  validated.failureField
                } on line ${validated.line + 1} `,
              })
            )
            setCsvData([])
          }
        },
        error: (error) => {
          dispatch(
            addNotification({
              type: 'error',
              message:
                'An error occurred while parsing the CSV file: ' +
                error.message,
            })
          )
          setCsvData([])
        },
      })
    }
  }

  function handleUploadActionChange(event: ChangeEvent<HTMLInputElement>) {
    setUploadAction(event.target.value)
  }

  const handleSubmit = async () => {
    console.log(csvData)
    if (!validateCSVData(csvData).valid || csvData.length === 0) {
      dispatch(
        addNotification({
          type: 'error',
          message:
            'CSV file is not properly formatted. Please check the required columns.',
        })
      )
      return
    }

    const jsonData = csvData.map((row) => ({
      ExternalId: row['ExternalId'],
      Name: row['Name'],
      Description: row['Description'],
      Collection: row['Collection'],
      StreetAddress: row['StreetAddress'],
      City: row['City'],
      State: row['State'],
      SingleLineAddress: row['SingleLineAddress'],
      Latitude: parseFloat(row['Latitude']),
      Longitude: parseFloat(row['Longitude']),
      CollectionId: parseInt(row['CollectionId'], 10),
      AssetTypeID: 1,
    }))

    setImportLoading(true)
    try {
      const returnedImport = await importOrgAsset(
        orgId,
        JSON.stringify(jsonData),
        uploadAction
      )

      if (returnedImport) {
        dispatch(
          addNotification({
            type: 'success',
            message:
              returnedImport.duplicatedDeleted < 1
                ? 'CSV data has been processed'
                : `CSV Data has been processed, ${returnedImport.duplicatedDeleted} total assets were duplicates and not imported`,
          })
        )
        onSave()
        closeDialog()
      } else {
        dispatch(
          addNotification({
            type: 'error',
            message: 'Failed to process CSV data',
          })
        )
      }
    } catch (error) {
      dispatch(
        addNotification({
          type: 'error',
          message: 'An error occurred during the import process',
        })
      )
    } finally {
      setImportLoading(false)
    }
  }

  return (
    <ResponsiveDialog
      onClose={(event, reason) => {
        if (reason === 'backdropClick') {
          closeDialog()
        }
      }}
      open={open}
      TransitionComponent={SlideLeft as any}
    >
      <DialogContent className={classes.dialogContent}>
        <div className={classes.header}>
          <FontAwesomeIcon
            icon={faTimes}
            style={{ cursor: 'pointer' }}
            onClick={closeDialog}
          />
          <Spacer />
          Select a TX360 or ICC Assets File
          <Spacer />
        </div>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <Popper
            open={infoWindowOpen}
            anchorEl={infoRef.current}
            placement={'top'}
            style={{ zIndex: '10000' }}
            transition
          >
            <ClickAwayListener onClickAway={handleInfoClose}>
              <Paper
                style={{
                  width: '400px',
                  padding: '10px',
                  whiteSpace: 'pre-line',
                  fontSize: '12px',
                }}
              >
                {`At a minimum, the format of this file should be as follows:
                Name, Description, Latitude, Longitude 
                
                Example:
                Name, Description, Latitude, Longitude,
                LA, Los Angeles Office, 34.05491, -118.24265
                CHI, Chicago Office, 41.87811, -87.6298
                NY, New York Office, 40.71278, -74.00597
                
                The format may include additional fields as desired, though any additional values will not be displayed within In Case of Crisis. For example, to accommodate exported asset files from the Online Threat Monitoring interface, the format could be as follows:
                ExternalId, Name, Description, Collection, StreetAddress, City, State, SingleLineAddress, Latitude, Longitude, CollectionId
                Note: if you choose to export assets from In Case of Crisis, the
                export file will include additional fields provided by In Case
                of Crisis in the following format:
                AssetID, AssetTypeID, AssetTypeName, OrgID, Name, Description, Latitude, Longitude, Created, CreatedBy`}
              </Paper>
            </ClickAwayListener>
          </Popper>
          <div
            style={{ display: 'flex', width: '100%', justifyContent: 'right' }}
          >
            <FontAwesomeIcon
              onClick={openInfoDialog}
              icon={faInfoCircle}
              style={{ cursor: 'pointer' }}
            />
          </div>

          <Input
            type="file"
            inputProps={{ accept: '.csv' }}
            onChange={handleFileUpload}
          />
          <Spacer />
          <RadioGroup
            className={classes.appendOverwriteSection}
            onChange={handleUploadActionChange}
            value={uploadAction}
          >
            <FormControlLabel
              value={'Y'}
              control={<Radio color="primary" />}
              label="Overwrite current list"
            />
            <FormControlLabel
              value={'N'}
              control={<Radio color="primary" />}
              label="Append to current list"
            />
          </RadioGroup>
          <div>
            <Button onClick={handleSubmit} disabled={importLoading}>
              {'Submit'}
            </Button>
          </div>

          {importLoading && <CircularProgress size={24} />}
        </div>
      </DialogContent>
    </ResponsiveDialog>
  )
}
