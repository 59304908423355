// @ts-nocheck
// TODO: Typescript
import {
  TaskConstants,
  TaskDetailConstants,
  TaskCommentConstants,
  UPDATE_TASK_READ,
} from './_constants'
import {
  TaskDetailActions,
  TaskActions,
  TaskCommentActions,
  updateTaskRead,
  fetchLatestTaskDates,
  incrementTaskUnreadCount,
} from './_actions'
import { WebSocketConstants } from '../_webSocket/_constants'
import { objDatePropsToUTC } from '../_utils/dateUtils'
import { getValueFromKey, JsonParseSafe } from '../_utils/objectUtils'
import { getArtifactId } from '../_schema/_schemas'
import { ArtifactActions } from '../Upload/_actions'
import { batch } from 'react-redux'
import {
  selectTaskItemById,
  selectTaskItemCommentById,
  selectTaskItemCommentIds,
} from './_selectors'
import {
  selectTaskAllItemIds,
  selectUserEmail,
} from '../_rootConfigs/rootSelectors'
import { WorkspaceConstants } from '../Workspace/_constants'
import { fetchTaskItemsSync } from '../Shared/_requestStatusReducer'
import TaskItemDetail from './TaskItemDetail'

export default function taskHub(storeAPI, action, hubConnection) {
  const state = storeAPI.getState()
  switch (action.type) {
    case WebSocketConstants.CONNECTION_RETRY_SUCCESSFUL:
    case WebSocketConstants.CONNECTION_SUCCESSFUL:
      // Task
      hubConnection.on('BroadcastTaskAdd', (data, source) => {
        data = JsonParseSafe(data)
        if (!data.ErrorMessage) {
          const task = data.returnData
          objDatePropsToUTC(task, 'CreatedUTC')
          storeAPI.dispatch(TaskActions.addSuccess(task, source))
        }
      })

      // Task Detail items
      hubConnection.on('BroadcastTaskItemAdd', (data, source) => {
        data = JsonParseSafe(data)
        if (!data.ErrorMessage && data.returnData) {
          const task = data.returnData[0]
          const TaskID = getValueFromKey(task, 'TaskID')
          const entry = getValueFromKey(task, 'TaskItem.0')
          objDatePropsToUTC(entry, 'CreatedUTC')
          entry.TaskID = TaskID

          storeAPI.dispatch(
            TaskDetailActions.addSuccess(entry, {
              TaskID,
              ...source,
            })
          )
        }
      })

      hubConnection.on('BroadcastTaskItemUpdate', (data) => {
        data = JsonParseSafe(data)
        const action = (data.Action || '').toUpperCase()
        if (!data.ErrorMessage && data.returnData) {
          const returnData = data.returnData

          if (returnData.DueByUTC) {
            objDatePropsToUTC(returnData, 'DueByUTC')
          }

          if (returnData.CompletedUTC)
            objDatePropsToUTC(returnData, 'CompletedUTC')
          else if (action === 'UNCOMPLETE') {
            returnData.CompletedAppAcctID = null
            returnData.CompletedUTC = null
          }

          if (action === 'UNASSIGN') {
            returnData.AssignedToAppAcctID = -1
            returnData.AssignedTo = ''
          }

          // Handle Due date clear
          if (action === 'DUECLEAR') {
            returnData.DueByUTC = undefined
          }
          // Handle Content
          if (returnData.TaskItemText !== undefined) {
            returnData.Content = returnData.TaskItemText
            returnData.TaskItemText = undefined
          }

          // Handle due by

          storeAPI.dispatch(TaskDetailActions.updateSuccess(returnData))
        }
      })

      hubConnection.on('BroadcastTaskItemCommentAdd', (data, source) => {
        data = JsonParseSafe(data)
        if (!data.ErrorMessage && data.returnData) {
          var comment = data.returnData
          objDatePropsToUTC(comment, 'CreatedUTC')
          var upload = comment.upload
          comment.upload = undefined

          batch(() => {
            if (upload) {
              comment.upload = getArtifactId(upload)
              objDatePropsToUTC(upload, 'CreatedUTC')
              storeAPI.dispatch(
                ArtifactActions.addSuccess(upload, {
                  ...source,
                  icoRequestId: comment.upload,
                })
              )
            }
            let taskItemUpdate = storeAPI.getState().taskItem[source.TaskItemID]
            let taskItemUpdateComments = taskItemUpdate.CommentsTotal + 1
            storeAPI.dispatch(
              TaskDetailActions.updateSuccess({
                TaskID: source.TaskID,
                TaskItemID: source.TaskItemID,
                CommentsTotal: taskItemUpdateComments,
              })
            )
            storeAPI.dispatch(TaskCommentActions.addSuccess(comment, source))
          })
        }
      })

      hubConnection.on('BroadcastTaskItemCommentDelete', (data, source) => {
        data = JsonParseSafe(data)
        if (!data.ErrorMessage && data.returnData) {
          let taskItemUpdate = storeAPI.getState().taskItem[source.TaskItemID]
          let taskItemUpdateComments = taskItemUpdate.CommentsTotal - 1
          storeAPI.dispatch(
            TaskDetailActions.updateSuccess({
              TaskID: source.TaskID,
              TaskItemID: source.TaskItemID,
              CommentsTotal: taskItemUpdateComments,
            })
          )
          storeAPI.dispatch(
            TaskCommentActions.deleteSuccess(data.returnData, source)
          )
        }
      })

      hubConnection.on('BroadcastTaskItemDelete', (data, source) => {
        data = JsonParseSafe(data)
        if (!data.ErrorMessage && data.returnData) {
          storeAPI.dispatch(
            TaskDetailActions.deleteSuccess(data.returnData, source)
          )
        }
      })

      hubConnection.on('BroadcastTaskItemReorder', (data, source) => {
        data = JsonParseSafe(data)
        if (!data.ErrorMessage && data.returnData) {
          storeAPI.dispatch(
            TaskDetailActions.reorderSuccess(data.returnData, source)
          )
        }
      })

      hubConnection.on('BroadcastTaskReadUpdate', (data) => {
        const fromAction = data.fromAction
        //objDatePropsToUTC(data.returnData, 'LastRead');
        if (!data.returnData) data.returnData = {}
        data.returnData.LastRead = new Date()
        objDatePropsToUTC(data.returnData, 'LastRead')
        let updateRead = true
        if (fromAction) {
          const appUserEmail = selectUserEmail(
            storeAPI.getState()
          ).toLocaleLowerCase()
          const email = data.updater

          const isCaller = email.toLocaleLowerCase() === appUserEmail

          // If he user trigger a task list action that updates the latest activity they side be mark as read
          // Otherwise increment their unread count (This forces the task list to refresh the read record if the user is currently viewing the task list)
          if (!isCaller) {
            updateRead = false
            storeAPI.dispatch(
              incrementTaskUnreadCount(data.TaskID, data.returnData.LastRead)
            )
          }
        }

        if (updateRead) {
          storeAPI.dispatch(
            updateTaskRead.success(data.returnData.LastRead, {
              TaskID: data.TaskID,
            })
          )
        }
      })

      hubConnection.on('BroadcastTaskItemsUploaded', (taskID) => {
        const curItemIds = selectTaskAllItemIds(storeAPI.getState(), taskID)
        // Remove the old task items and prepare the UI to be refreshed
        storeAPI.dispatch(TaskDetailActions.deleteRangeSuccess(curItemIds))
        storeAPI.dispatch(fetchTaskItemsSync.clearSync(taskID))
      })

      break
    case UPDATE_TASK_READ.REQUEST:
      hubConnection
        .invoke('SendTaskReadUpdate', action.payload.params)
        .catch((err) => console.error(err.toString()))
      break
    case TaskConstants.ADD.REQUEST:
      hubConnection.invoke('SendTaskAdd', action.payload.result)
      break
    case TaskDetailConstants.ADD.REQUEST:
      hubConnection.invoke('SendTaskItemAdd', action.payload.result)
      break
    case TaskDetailConstants.UPDATE.REQUEST:
      hubConnection.invoke(
        'SendTaskItemUpdate',
        action.payload.result,
        action.payload.source.action
      )
      break
    case TaskDetailConstants.REORDER.REQUEST:
      hubConnection.invoke('SendTaskItemReorder', action.payload.result)
      break
    case TaskDetailConstants.DELETE.REQUEST:
      hubConnection.invoke('SendTaskItemDelete', action.payload.result)
      break
    case TaskCommentConstants.ADD.REQUEST:
      hubConnection.invoke('SendTaskItemCommentAdd', action.payload.result)
      break

    case TaskCommentConstants.DELETE.REQUEST:
      hubConnection.invoke('SendTaskItemCommentDelete', action.payload.result)
      break
    case TaskDetailConstants.DELETE.SUCCESS:
      const taskItem = selectTaskItemById(
        state,
        action.payload.result.TaskItemID
      )
      if (taskItem) {
        const comments =
          selectTaskItemCommentIds(state, taskItem.TaskItemID) || []
        batch(() => {
          storeAPI.dispatch(TaskCommentActions.deleteRangeSuccess(comments))
          const artifactIds = []
          comments.forEach((comId) => {
            const com = selectTaskItemCommentById(state, comId)
            if (com) artifactIds.push(com.upload)
          })

          if (artifactIds.length) {
            storeAPI.dispatch(
              ArtifactActions.deleteRangeSuccess(
                artifactIds,
                action.payload.source
              )
            )
          }
        })
      }
      break
    case WorkspaceConstants.INIT.SUCCESS: // Moved here by the init was happening after the dates were received
      const fetchLatestDates = fetchLatestTaskDates.request()
      fetchLatestDates.dontRenewAuth = true
      storeAPI.dispatch(fetchLatestDates)
      break
    default:
      break
  }
}
