// @ts-nocheck
// TODO: Typescript
import { upload } from '../Upload/_actions'
import { getTimezoneOffset, getUTCDate } from '../_utils/dateUtils'
import {
  generateActions,
  generateCrudActions,
  ReducerAction,
} from '../_utils/reduxUtils'
import {
  FETCH_LATEST_TASK_DATES,
  FETCH_TASK_UNREAD_COUNT,
  getTaskCsvUploadID,
  INCREMENT_TASK_UNREAD_COUNT,
  SET_IS_VIEWING_TASK,
  UPDATE_TASK_READ,
} from './_constants'
import { TaskCommentModel, TaskItemModel } from './_models'

export const TaskActions = generateCrudActions('TASK')
export const TaskDetailActions =
  generateCrudActions<TaskItemModel>('TASK_DETAIL')
export const TaskCommentActions =
  generateCrudActions<TaskCommentModel>('TASK_COMMENT')

export function toggleTaskItemCompletedUtc(entity) {
  const { CompletedUTC, CompletedAppAcctID, CompletedBy, TaskID, TaskItemID } =
    entity
  let source = { action: CompletedUTC ? 'UnComplete' : 'Complete' }
  let newCompletedUTC = undefined
  if (CompletedUTC) {
    newCompletedUTC = null
    source.action = 'UnComplete'
  } else {
    newCompletedUTC = getUTCDate(new Date(Date.now()))
    source.action = 'Complete'
  }

  return TaskDetailActions.updateSuccess(
    {
      TaskID,
      TaskItemID,
      CompletedUTC: newCompletedUTC,
      CompletedAppAcctID,
      CompletedBy,
    },
    source
  )
}

export function updateTaskItemCompletedUtc(entity) {
  const { CompletedUTC, TaskID, TaskItemID } = entity
  const source = { action: CompletedUTC ? 'Complete' : 'UnComplete' }
  return TaskDetailActions.update({ TaskID, TaskItemID }, source)
}

// Task Unread
export const updateTaskRead = generateActions(UPDATE_TASK_READ)
export const fetchTaskUnreadCount = generateActions(FETCH_TASK_UNREAD_COUNT)
export const fetchLatestTaskDates = generateActions(FETCH_LATEST_TASK_DATES)
export function incrementTaskUnreadCount(
  TaskID: number,
  lastActivityDate: Date
): ReducerAction {
  return {
    type: INCREMENT_TASK_UNREAD_COUNT,
    payload: {
      result: {
        TaskID,
        lastActivityDate,
      },
      params: {
        TaskID,
      },
    },
  }
}

export function setIsViewingTask(
  TaskID: number,
  isViewing: boolean
): ReducerAction {
  return {
    type: SET_IS_VIEWING_TASK,
    payload: {
      result: isViewing,
      params: {
        TaskID,
      },
    },
  }
}

interface TaskUploadCsvParams {
  ReplaceRows?: boolean
  IncludeAssignments?: boolean
}

export function uploadCsvTaskItems(
  taskID: number,
  file: File,
  importParams?: TaskUploadCsvParams
) {
  return upload(
    { OffsetMinutes: getTimezoneOffset(), ...importParams },
    {
      file,
      uploadUrl: `api/Task/${taskID}/Upload`,
      icoRequestId: getTaskCsvUploadID(taskID),
    }
  )
}
