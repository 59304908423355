// @ts-nocheck
// TODO: Typescript
import React, { Component } from 'react'
import { MasterPageLayout } from '../Pages/MasterPageLayout'
import ReportsView from './ReportsView'
import { goToIssue } from '../_utils/reduxUtils'

export class ReportsPage extends Component {
  constructor(props) {
    super(props)
    this.handleRequestReport = this.handleRequestReport.bind(this)
    this.handleRedirect = this.handleRedirect.bind(this)
  }

  handleRequestReport(params) {
    this.props.history.push({
      pathname: `/Reports/${params.ReportName}/${params.IssueID || ''}`,
    })
  }

  handleRedirect(params) {
    goToIssue(this.props.history, params)
  }

  render() {
    const { history } = this.props
    const { params } = this.props.match
    const reportName = params.ReportName
    let issueID = params.IssueID
    if (!isNaN(issueID)) issueID = parseInt(issueID)
    else issueID = undefined
    return (
      <MasterPageLayout history={history}>
        <ReportsView
          history={history}
          ReportName={reportName}
          IssueID={issueID}
          useLocalState={false}
          onParamsChanged={this.handleRequestReport}
          onRedirect={this.handleRedirect}
        />
      </MasterPageLayout>
    )
  }
}
