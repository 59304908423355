// @ts-nocheck
// TODO: Typescript
import React, { Component } from 'react'
import { ScrollList } from '../Shared/ScrollList'
import PropTypes from 'prop-types'
import HoldingStatementListItem from './HoldingStatementListItem'

/**
 * @extends {Component<Props, {}>}}
 * Renders a list of holding Statements
 */
export class HoldingStatementList extends Component {
  render() {
    const { holdingStatementIds, currentHoldingStatementID } = this.props
    const filteredItems = holdingStatementIds.filter(
      (item) => item !== currentHoldingStatementID
    )

    return (
      <ScrollList>
        {filteredItems.map((id) => (
          <HoldingStatementListItem key={id} HoldingStatementID={id} />
        ))}
      </ScrollList>
    )
  }
}

HoldingStatementList.propTypes = {
  holdingStatementIds: PropTypes.arrayOf(PropTypes.number),
}

HoldingStatementList.defaultProps = {
  holdingStatementIds: [],
}
