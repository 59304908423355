import { bindSel, getAuth } from '../_rootConfigs/rootReducerSlicers'
import * as priv from './_reducer'

export const selectUser = bindSel(getAuth, priv.selectUser)
export const selectUserEmail = bindSel(getAuth, priv.selectUserEmail)
export const selectUserFullName = bindSel(getAuth, priv.selectUserFullName)
export const selectUserAppAcctId = bindSel(getAuth, priv.selectUserAppAcctId)
export const isUserAuthenticated = bindSel(getAuth, priv.isUserAuthenticated)
export const isUserAuthorized = bindSel(getAuth, priv.isUserAuthorized)
export const selectIsLoginFailure = bindSel(getAuth, priv.selectIsLoginFailure)
export const selectLoginFailureMessage = bindSel(
  getAuth,
  priv.selectLoginFailureMessage
)
export const selectLoginProvider = bindSel(getAuth, priv.selectLoginProvider)
export const isAzureADLogin = bindSel(getAuth, priv.isAzureADLogin)
export const selectAuthRefreshDate = bindSel(
  getAuth,
  priv.selectAuthRefreshDate
)
export const selectAuthExpiration = bindSel(getAuth, priv.selectAuthExpiration)
export const isLoggingOut = bindSel(getAuth, priv.isLoggingOut)
export const selectRedirectToReferrer = bindSel(
  getAuth,
  priv.selectRedirectToReferrer
)
export const selectTokenManagement = bindSel(
  getAuth,
  priv.selectTokenManagement
)
export const isSessionWarningOpen = bindSel(getAuth, priv.isSessionWarningOpen)
export const selectAuthPage = bindSel(getAuth, priv.selectAuthPage)
export const selectRequestToken = bindSel(getAuth, priv.selectRequestToken)
export const selectInitialView = bindSel(getAuth, priv.selectInitialView)
export const selectIsLoggingOn = bindSel(getAuth, priv.selectIsLoggingOn)
