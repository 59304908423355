import * as React from 'react'
import classes from './Playbooks.module.scss'
import { PbEvDetail } from './PbEvDetail'
import { cx } from '../_utils/objectUtils'

interface Props {
  submenu: any
  onSubEventLoaded?(playbook: any): void
  // ParentID // Only do breadcrumb if a parent id is found
  // onSubEventLoaded // append list of events, Always show latest event
}

export const PbSubmenu = ({ submenu, onSubEventLoaded }: Props) => {
  const [EventID, setEventID] = React.useState<number>()

  React.useEffect(() => {
    setEventID(undefined)
  }, [submenu])

  function handleOnPlaybookLoaded(playbook: any) {
    if (onSubEventLoaded)
      onSubEventLoaded({
        menuEvent: submenu.subevt.find((e: any) => e.EventID === EventID),
        ...playbook,
      })
  }

  return (
    <div>
      {submenu.subevt.map((pbe: any, pbeidx: number) => {
        let evtimg
        if (pbe.Image) {
          if (pbe.Image.Format) {
            evtimg = (
              <img
                alt={pbe.EventName}
                src={
                  'data:image/' +
                  pbe.Image.Format +
                  ';base64,' +
                  pbe.Image.Graphic
                }
              />
            )
          }
        }
        return (
          // Hide this when the event id is clicked
          <div key={pbeidx} className={classes.eventPadding}>
            <div
              className={cx('clearfix', classes.clickBox)}
              onClick={() => {
                if (pbe.EventID !== EventID) {
                  setEventID(pbe.EventID)
                }
              }}
            >
              <div className={cx('float-left', classes.planImg)}>{evtimg}</div>
              <div className={cx('float-left font-bold', classes.planSpec)}>
                {pbe.EventName}
              </div>
            </div>
          </div>
        )
      })}
      {EventID && (
        <div className={classes.eventDetails}>
          {/* EventID is {EventID} */}
          {/*onLoadDone=(ParentEventID, EventID)*/}
          <PbEvDetail
            EventID={EventID}
            onPlaybookLoaded={handleOnPlaybookLoaded}
            isChild={true}
          />
        </div>
      )}
    </div>
  )
}
