import * as React from 'react'
import { http } from '../_utils/http'
import { CircularProgress } from '../Shared/LoadingBackdrop'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser } from '@fortawesome/pro-solid-svg-icons'
import classes from './AdminConsolePage.module.scss'

interface Props {
  OrgID: string
}

// interface IssueTeam {
//   TeamName: string
//   Description: string
//   RoleSeq: number
//   users: string
// }

export const AdminConsoleIssueTeamsUsers = ({ OrgID }: Props) => {
  const [issueTeamList, setIssueTeamList] = React.useState<any>('')

  const getIssueTeamList = React.useCallback(async (): Promise<any> => {
    var customHeaders: any = []

    customHeaders.push({ name: 'orgid', value: `${OrgID}` })

    const result = await http<any>({
      path: '/Issue/IssueTeamList',
      headers: customHeaders,
    })
    if (result.ok && result.body) {
      setIssueTeamList(result.body)
    } else {
      setIssueTeamList('')
    }
  }, [OrgID])

  React.useEffect(() => {
    getIssueTeamList()
  }, [getIssueTeamList])

  function renderList() {
    if (!issueTeamList) return <CircularProgress />

    if (!issueTeamList.status) return <CircularProgress />

    if (issueTeamList.status !== 'success')
      return <>{JSON.stringify(issueTeamList, null, 2)}</>

    return (
      <>
        <div className={classes.OrganizationManagementSection}>
          <div
            className={classes.OrganizationManagementSectionHeader}
            style={{ borderBottom: '1px solid #000000' }}
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                marginBottom: '10px',
              }}
            >
              <FontAwesomeIcon icon={faUser} size={'2x'} />{' '}
              &nbsp;&nbsp;&nbsp;&nbsp; <div>ISSUE TEAMS AND USERS</div>
            </div>
          </div>
          <div>{issueTeamTableRows()}</div>
        </div>
        {/* IssueTeamList = {JSON.stringify(issueTeamList, null, 2)} */}
      </>
    )
  }

  function issueTeamTableRows() {
    if (!issueTeamList.IssueTeamsUsers) {
      return <i>no Issue Teams found</i>
    }
    return (
      <>
        {issueTeamList.IssueTeamsUsers.map(
          (alertInboxTeam: any, orpIdx: number) => {
            return (
              <div
                className={classes.majorSectionDiv}
                key={alertInboxTeam.TeamName}
              >
                <b> {alertInboxTeam.TeamName}</b>
                {issueTeamTableRoles(alertInboxTeam.roles)}
              </div>
            )
          }
        )}
      </>
    )
  }

  function issueTeamTableRoles(roles: any) {
    return (
      <div>
        {roles.map((role: any, index: number) => {
          return (
            <React.Fragment key={index}>
              <div className={classes.minorSectionDiv}>
                {role.RoleDescription}
                {issueTeamTableRoleMembers(role.member)}
              </div>
            </React.Fragment>
          )
        })}
      </div>
    )
  }
  function issueTeamTableRoleMembers(members: any) {
    return (
      <div className={classes.minorSectionDiv}>
        {members.map((member: any) => {
          return (
            <React.Fragment key={member.email_address}>
              {member.email_address}
              <br />
            </React.Fragment>
          )
        })}
      </div>
    )
  }

  return <>{renderList()}</>
}
