import { Store } from 'redux'

// Workaround to circumvent circular dependency when calling service methods from sagas.
// Service methods are the only methods that aren't automatically connected to the store
let store: Store

export function setStore(s: Store) {
  store = s
}

export function getStore() {
  return store
}
