import React, { useEffect } from 'react'
import { SearchInput } from '../Shared/SearchInput'
import { prefixSelectorWithApp } from '../_utils/domUtils'
import { useAppDispatch, useAppSelector } from '../_utils/reactReduxHooks'
import { SavePublicPlaybookSearch } from './_actions'
import { selectPublicPlaybookSearchValue } from './_selectors'
interface props {
  className: string
}
export function PublicPlaybookSearch({ className }: props) {
  const value = useAppSelector(selectPublicPlaybookSearchValue)
  const dispatch = useAppDispatch()

  useEffect(() => {}, [])

  function applyFilter(value: any) {
    dispatch(SavePublicPlaybookSearch(value))
  }

  return (
    <SearchInput
      className={className}
      id={prefixSelectorWithApp('global-playbBookSearch')}
      name="descriptionFilter"
      placeholder="Search Playbooks by Keyword, Tag, Title or Organization"
      value={value}
      applySearch={applyFilter}
      changeDelay={1000}
      includeSearchIcon={true}
    />
  )
}
