import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { CircularProgress } from '../Shared/LoadingBackdrop'
import { isAlertsLoading } from '../Shared/_requestStatusSelectors'
import { useParams } from 'react-router'
import { useAppDispatch, useAppSelector } from '../_utils/reactReduxHooks'
import { AlertItem } from './AlertItem'
import { fetchAlerts } from './_actions'
import {
  selectInboxByGUID,
  selectInboxCurrentFeedGUID,
  selectAlerts,
  selectAlertFilters,
  selectInboxIDtoGUIDMap,
  selectAlertSearch,
} from './_selectors'
import { AlertsRouteParams } from '../Routing/RouteProps'
import { ManageInbox } from './ManageInbox'
import { faEdit } from '@fortawesome/pro-solid-svg-icons'
import { faListUl } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { inboxStatuses } from './_constants'
import classes from './Alerts.module.scss'
import { AlertFilter } from './AlertFilter'
import { Tooltip } from '@material-ui/core'
import {
  AlertFilter as AlertFilterModel,
  AlertModel,
  Statuses,
} from './_models'

/***
 * This first loads the inboxFeeds (called inbox Alerts? need to rename)
 * and then checks if the first item has a url - if url, and the inbox says it is active,
 * then we can grab the live feeds. Will need to merge the live feeds
 * and the saved alerts together. Really need to improve the naming
 *
 * need to refactor to just referencing the inboxGUID to grab a combined array of
 * saved alerts and alerts from a feed,
 * ***/

export const AlertList = () => {
  const dispatch = useAppDispatch()
  const [filterToggle, setFilterToggle] = useState(false)
  const { inboxGUID } = useParams<AlertsRouteParams>()
  const inboxGUIDCleaned = useMemo(
    () => (inboxGUID ? inboxGUID : ''),
    [inboxGUID]
  )
  const inbox = useAppSelector((state) =>
    selectInboxByGUID(state, interpretInboxGUID(inboxGUID))
  )
  const inboxID = inbox && inbox.inboxID ? inbox.inboxID : 0
  const alertsLoading = useAppSelector(isAlertsLoading)
  const alerts = useAppSelector(selectAlerts)
  const alertSearchValue = useAppSelector(selectAlertSearch)

  const alertFilters = useAppSelector(selectAlertFilters)
  const inboxIDMappedGUIDs = useAppSelector(selectInboxIDtoGUIDMap)
  const filteredAlerts = useMemo(
    () =>
      filterAlertsByStatus(
        alerts,
        alertFilters,
        inboxGUIDCleaned,
        inboxIDMappedGUIDs
      ),
    [alerts, alertFilters, inboxGUIDCleaned, inboxIDMappedGUIDs]
  )
  const searchedAlerts = useMemo(
    () =>
      filteredAlerts.filter((alert) => {
        if (!alertSearchValue) {
          return true
        }
        if (
          alert.title.toLowerCase().includes(alertSearchValue?.toLowerCase())
        ) {
          return true
        }
        if (
          alert.summary.toLowerCase().includes(alertSearchValue?.toLowerCase())
        ) {
          return true
        }
        return false
      }),
    [alertSearchValue, filteredAlerts]
  )
  const feedGUID = useAppSelector((state) =>
    selectInboxCurrentFeedGUID(state, inboxGUIDCleaned)
  )

  const [manageInboxOpen, setManageInboxOpen] = useState(false)

  const filterApplied = useMemo(() => {
    return (
      (alertFilters?.InboxesHidden?.length > 0 && inboxGUID === 'AllAlerts') ||
      alertFilters?.statuses?.length < 5
    )
  }, [
    alertFilters?.InboxesHidden?.length,
    alertFilters?.statuses?.length,
    inboxGUID,
  ])

  useEffect(() => {
    dispatch(
      fetchAlerts.request({
        inboxID: inboxID,
        feedGUID: feedGUID || null,
        statusID: determineStatus(inboxGUIDCleaned),
      })
    )
  }, [inboxGUIDCleaned, inboxID, dispatch, feedGUID])

  const renderHeader = useCallback(() => {
    let inboxTitle: string =
      inboxGUID === 'AllAlerts' ? 'All Alerts' : inbox?.inboxName

    return (
      <div
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          padding: '1rem',
          paddingBottom: '0',
          position: 'sticky',
          top: '0',
          backgroundColor: 'white',
          opacity: '100%',
          zIndex: '1',
        }}
      >
        <div
          className={classes.iconWrapper}
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <h4>
            {inboxTitle}
            {inbox?.inboxType ? ' - ' + inbox.inboxType : ''}
          </h4>
          {!inboxStatuses.includes(inboxGUIDCleaned) ? (
            <Tooltip key={'manageInbox'} placement="top" title={'Manage Inbox'}>
              <span>
                <FontAwesomeIcon
                  style={{ cursor: 'pointer' }}
                  icon={faEdit}
                  size="lg"
                  onClick={() => setManageInboxOpen((prev) => !prev)}
                />
              </span>
            </Tooltip>
          ) : (
            <Tooltip
              key={'displayOptions'}
              placement="top"
              title={'Display Options'}
            >
              <span className={filterApplied ? '' : classes.noFilterApplied}>
                <FontAwesomeIcon
                  style={{ cursor: 'pointer' }}
                  icon={faListUl}
                  size="lg"
                  onClick={() => setFilterToggle((prev) => !prev)}
                />
              </span>
            </Tooltip>
          )}
        </div>
        <span>{inbox?.OrgName}</span>
        <div
          className={classes.iconWrapper}
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            borderBottom: 'solid 1px lightgray',
            paddingBottom: '0.5rem',
          }}
        >
          {inboxGUID !== 'AllAlerts' && !alertsLoading && (
            <>
              <span>
                {alerts.length} alert{alerts.length > 1 && 's'}
              </span>
              <Tooltip
                key={'displayOptions'}
                placement="top"
                title={'Display Options'}
              >
                <span className={filterApplied ? '' : classes.noFilterApplied}>
                  <FontAwesomeIcon
                    style={{ cursor: 'pointer' }}
                    icon={faListUl}
                    size="lg"
                    onClick={() => setFilterToggle((prev) => !prev)}
                  />
                </span>
              </Tooltip>
            </>
          )}
        </div>
      </div>
    )
  }, [
    inbox?.inboxName,
    inboxGUID,
    inboxGUIDCleaned,
    alerts.length,
    inbox?.inboxType,
    inbox?.OrgName,
    alertsLoading,
    filterApplied,
  ])

  const renderList = useCallback(() => {
    return alertsLoading ? (
      <CircularProgress zIndex={0} />
    ) : alerts.length > 0 ? (
      <div className="p-2" style={{ paddingTop: '0' }}>
        {searchedAlerts.map((alert, index) => (
          <AlertItem externalID={alert.Id} index={index} key={index} />
        ))}
      </div>
    ) : (
      <div>No alerts exist.</div>
    )
  }, [alerts, alertsLoading, searchedAlerts])

  return (
    <>
      {renderHeader()}
      {manageInboxOpen && (
        <ManageInbox
          onClose={() => setManageInboxOpen(false)}
          inboxGUID={inboxGUIDCleaned}
          isDialogOpen={manageInboxOpen}
        />
      )}
      {filterToggle && (
        <AlertFilter
          onCancel={() => setFilterToggle((prev) => !prev)}
          isOpen={filterToggle}
        />
      )}
      {renderList()}
    </>
  )
}

export const interpretInboxGUID = (inboxGUID: string | undefined) => {
  switch (inboxGUID) {
    case 'AllAlerts':
    case undefined:
      return ''
    default:
      return inboxGUID
  }
}
const determineStatus = (inboxguid: string) => {
  switch (inboxguid) {
    case 'AllAlerts':
      return '0,1,2,3,4,5,6'
    default:
      return '0'
  }
}

const filterAlertsByStatus = (
  alerts: AlertModel[],
  alertFilters: AlertFilterModel,
  inboxGUID: string,
  inboxIDMappedGUIDs: { [inboxID: number]: string }
) => {
  let statusesIncluded: Statuses[] = alertFilters?.statuses
  let inboxesHidden: String[] = alertFilters?.InboxesHidden
  let filteredAlerts = alerts.filter((alert) => {
    if (
      inboxGUID === 'AllAlerts' &&
      inboxesHidden.includes(inboxIDMappedGUIDs[alert.inboxID])
    ) {
      return false
    }

    let workingAlertStatus = 'New'
    let alertHasBeenShared = alert.alertStatusID === 4
    let alertConnectedToIssue =
      alert.alertStatusID === 5 || alert.alertStatusID === 6 || !!alert.issueID
    if (alert.incidentID) {
      workingAlertStatus = 'Pinned'
    }

    if (
      alert.alertStatus !== null &&
      alert.alertStatus !== undefined &&
      alert.alertStatus.length > 0
    ) {
      workingAlertStatus = alert.alertStatus
    }
    if (alertHasBeenShared) {
      workingAlertStatus = 'Shared'
    }
    if (alertConnectedToIssue) {
      workingAlertStatus = 'Connected To Issue'
    }

    if (alert.alertStatusHistory) {
      alertHasBeenShared =
        !!alert.alertStatusHistory.find(
          (statusElement) => statusElement.StatusID === 4
        ) || alert.alertStatusID === 4
      alertConnectedToIssue =
        alertConnectedToIssue ||
        !!alert.alertStatusHistory.find(
          (statusElement) => statusElement.StatusID === 5
        ) ||
        !!alert.alertStatusHistory.find(
          (statusElement) => statusElement.StatusID === 6
        )
    }

    if (statusesIncluded.includes('Shared') && alertHasBeenShared) {
      return true
    }
    if (
      statusesIncluded.includes('Connected To Issue') &&
      alertConnectedToIssue
    ) {
      return true
    }
    if (!statusesIncluded.includes(workingAlertStatus)) {
      return false
    }

    return statusesIncluded.includes(workingAlertStatus)
  })

  return filteredAlerts
}
