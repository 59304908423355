import React, { useCallback, useState } from 'react'
import { ResponsiveDialog } from '../Shared/ResponsiveDialog'
import { SlideLeft } from '../Shared/Transition'
import { DialogContent, Toolbar } from '@material-ui/core'
import { HeaderNavigation } from '../Shared/HeaderNavigation'
import { Spacer } from '../Shared/Spacer'
import { Button } from '../Shared/Buttons'
import { useAppDispatch } from '../_utils/reactReduxHooks'
import { FormControl, InputLabel, Input } from '@material-ui/core'
import { addNotification } from '../_actions'
import { EvidenceLockerLinkActions } from './_actions'
import DOMPurify from 'dompurify'

interface Props {
  isOpen: boolean
  onCancel: (saved?: boolean) => void
  IssueID: number
  IssueTeamWorkspaceID: number
}

export const EvidenceLockerAddLink = ({
  isOpen,
  onCancel,
  IssueID,
  IssueTeamWorkspaceID,
}: Props) => {
  const [linkURL, setLinkURL] = useState<string>('')
  const [linkName, setLinkName] = useState<string>('')

  const [errors, setErrors] = useState<{ [key: string]: any }>({})
  const dispatch = useAppDispatch()

  const handleSave = useCallback(() => {
    let securedURL = secureURL(linkURL)
    DOMPurify.sanitize(securedURL)

    if (!securedURL || securedURL?.length === 0) {
      setErrors((prev) => ({
        ...prev,
        Audience: 'A link cannot be blank',
      }))
      dispatch(
        addNotification({
          message: 'A link cannot be blank',
          type: 'error',
        })
      )
      return
    } else if (DOMPurify.removed.length > 0) {
      setErrors((prev) => ({
        ...prev,
        Audience: 'A link cannot be a security risk',
      }))
      dispatch(
        addNotification({
          message: 'A link cannot be a security risk',
          type: 'error',
        })
      )
      return
    }

    DOMPurify.sanitize(linkName)

    if (!linkName || linkName?.length === 0) {
      setErrors((prev) => ({
        ...prev,
        Audience: 'A link name cannot be blank',
      }))
      dispatch(
        addNotification({
          message: 'A link name cannot be blank',
          type: 'error',
        })
      )
      return
    } else if (DOMPurify.removed.length > 0) {
      setErrors((prev) => ({
        ...prev,
        Audience: 'A link name cannot be a security risk',
      }))
      dispatch(
        addNotification({
          message: 'A link name cannot be a security risk',
          type: 'error',
        })
      )
      return
    }
    if (securedURL && linkName) {
      dispatch(
        EvidenceLockerLinkActions.add({
          IssueID,
          IssueTeamWorkspaceID,
          linkURL: securedURL,
          linkName,
        })
      )
    }
    setLinkName('')
    setLinkURL('')
    onCancel(true)
  }, [dispatch, onCancel, IssueID, linkURL, linkName, IssueTeamWorkspaceID])

  const handleCancel = useCallback(() => {
    setLinkName('')
    setLinkURL('')
    onCancel()
  }, [onCancel])

  const secureURL = (url: string) => {
    if (url.startsWith('http://')) {
      url.toLowerCase().replace('http://', 'https://')
    }
    if (!url.toLowerCase().startsWith('https://')) {
      setLinkURL('https://' + url)
      return 'https://' + url
    }
    setLinkURL(url)
    return url
  }

  const renderContent = useCallback(() => {
    return (
      <div>
        <FormControl className="w-100" required={true}>
          <InputLabel>Link Name</InputLabel>
          <Input
            type="text"
            required={true}
            error={errors['Link Name'] ? true : false}
            name="Link Name"
            inputProps={{
              maxLength: 255,
            }}
            value={linkName}
            onChange={(evt) => {
              if (errors['Link Name']) {
                delete errors['Link Name']
              }
              setLinkName(evt.target.value)
            }}
          />
        </FormControl>
        <Spacer spaceParam={'large'} />
        <FormControl className="w-100" required={true}>
          <InputLabel>Link URL</InputLabel>
          <Input
            type="text"
            required={true}
            error={errors['Link URL'] ? true : false}
            name="Link URL"
            inputProps={{
              maxLength: 255,
            }}
            value={linkURL}
            onChange={(evt) => {
              if (errors['Link URL']) {
                delete errors['Link URL']
              }
              setLinkURL(evt.target.value)
            }}
          />
        </FormControl>
        <Spacer spaceParam={'large'} />

        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
          }}
        >
          <Button color="secondary" onClick={handleCancel}>
            Cancel
          </Button>
          <Spacer spaceParam={'large'} />
          <Button color="primary" onClick={handleSave}>
            Save
          </Button>
        </div>
      </div>
    )
  }, [linkName, linkURL, handleCancel, handleSave, errors])

  return (
    <ResponsiveDialog
      open={isOpen}
      onClose={handleCancel}
      TransitionComponent={SlideLeft as any}
    >
      <Toolbar>
        <HeaderNavigation
          title={'Add Link'}
          onNavClick={handleCancel as any}
          canGoBack={false}
        />
      </Toolbar>
      <DialogContent>{renderContent()}</DialogContent>
    </ResponsiveDialog>
  )
}
