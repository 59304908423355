import { faTimes } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { DialogContent, Input } from '@material-ui/core'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { Button } from '../Shared/Buttons'
import { ResponsiveDialog } from '../Shared/ResponsiveDialog'
import { Spacer } from '../Shared/Spacer'
import { SlideLeft } from '../Shared/Transition'
import { addNotification } from '../_actions'
import { uploadTaskListTemplate } from '../_utils/apiUtils'
import classes from './TaskTemplateUploadDialog.module.scss'

const TaskTemplateUploadDialog = ({
  open,
  taskId,
  orgId,
  onClose,
}: {
  open: boolean
  taskId: number
  orgId: number
  onClose: () => void
}) => {
  const closeDialog = () => {
    onClose()
    setTemplateTitle('')
  }
  const [templateTitle, setTemplateTitle] = useState('')

  const dispatch = useDispatch()
  return (
    <ResponsiveDialog
      onClose={(event, reason) => {
        if ((reason = 'backdropClick')) {
          closeDialog()
        }
      }}
      open={open}
      TransitionComponent={SlideLeft as any}
    >
      <DialogContent className={classes.dialogContent}>
        <div className={classes.header}>
          <FontAwesomeIcon
            icon={faTimes}
            style={{ cursor: 'pointer' }}
            onClick={() => {
              closeDialog()
            }}
          />
          <Spacer />
          Save Task List as Template
          <Spacer />
        </div>
        <div className={classes.inputAndSubmit}>
          <Input
            value={templateTitle}
            placeholder="Template Title"
            onChange={(event) => setTemplateTitle(event.target.value)}
          />
          <Spacer />

          <Button
            onClick={() => {
              uploadTaskListTemplate(taskId, orgId, templateTitle).then(
                (status) => {
                  if (status.status === 'FAILED') {
                    dispatch(
                      addNotification({
                        type: 'error',
                        message: status.message,
                      })
                    )
                  } else if (status.status === 'SUCCESS') {
                    dispatch(
                      addNotification({
                        type: 'success',
                        message: 'Task List was successfully saved',
                      })
                    )
                  }
                }
              )
              closeDialog()
            }}
          >
            Submit
          </Button>
        </div>
      </DialogContent>
    </ResponsiveDialog>
  )
}

export default TaskTemplateUploadDialog
