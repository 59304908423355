// @ts-nocheck
// TODO: Typescript
import { generateTypes, generateCrudTypes } from '../_utils/reduxUtils'
import { valuesFromObject } from '../_utils/objectUtils'

export const UploadConstants = generateTypes('UPLOAD', 'UPLOAD')
export const UploadEntityTypes = {
  chat: 'chat',
  taskItem: 'taskItem',
  artifact: 'artifact',
}
export const UploadEntityTypeValues = valuesFromObject(UploadEntityTypes)
export const ArtifactConstants = generateCrudTypes('ARTIFACT')
