import { bindSel, getWebSocket } from '../_rootConfigs/rootReducerSlicers'
import * as fromWebSocket from './_reducer'

export const isWebsocketConnecting = bindSel(
  getWebSocket,
  fromWebSocket.isWebsocketConnecting
)
export const isWebsocketConnected = bindSel(
  getWebSocket,
  fromWebSocket.isWebsocketConnected
)
export const isWebsocketDisconnected = bindSel(
  getWebSocket,
  fromWebSocket.isWebsocketDisconnected
)
export const isWebsocketReconnected = bindSel(
  getWebSocket,
  fromWebSocket.isWebsocketReconnected
)
