// @ts-nocheck
// TODO: Typescript
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import {
  isIncludeResolvedFilterApplied,
  isTagsFilterApplied,
  selectAllUnresolvedIssues,
  selectFilterDefaultIncludeResolved,
  selectIssueFilterField,
  selectIssueStageTab,
  selectUserOrgInfo,
} from './_selectors'
import {
  isEndDateFilterApplied,
  isIssueModeTypeFilterApplied,
  isOrgIssueLevelFilterApplied,
  isStartDateFilterApplied,
} from './_selectors'
import {
  selectAssignedFilteredFromTotalIssues,
  selectStagingFilteredFromTotalIssues,
} from '../_rootConfigs/rootSelectors'
import colorDefinitions from '../ThemeProvider/ColorDefinitions.module.scss'
import { Spacer } from '../Shared/Spacer'

export class IssueFilterApplied extends Component {
  render() {
    const {
      isDateApplied,
      isSeverityApplied,
      isIncludeResolvedApplied,
      isTagsApplied,
      isIssueModeTypeApplied,
      isOrgFilterApplied,
      defaultIncludeResolved,
      style,
      proportionAssignedIssueIDs,
      proportionStagingIssueIDs,
      selectedTabType,
    } = this.props

    const filteredItems = []
    if (isSeverityApplied) {
      filteredItems.push('Severity')
    }
    if (isIssueModeTypeApplied) {
      filteredItems.push('Mode')
    }
    if (isDateApplied) {
      filteredItems.push('Date')
    }
    if (isIncludeResolvedApplied && defaultIncludeResolved) {
      filteredItems.push('Exclude Resolved')
    }
    if (isIncludeResolvedApplied && !defaultIncludeResolved) {
      filteredItems.push('Include Resolved')
    }
    if (isTagsApplied) {
      filteredItems.push('Tags')
    }
    if (isOrgFilterApplied) {
      filteredItems.push('Org')
    }

    if (filteredItems.length > 0) {
      return (
        <div
          style={{
            ...style,
            fontSize: '0.8rem',
            color: 'grey',
            paddingRight: '0.5rem',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
          }}
        >
          <div className="d-flex flex-column align-items-start">
            <div
              style={{
                color: colorDefinitions['primary-blue'],
                whiteSpace: 'nowrap',
              }}
            >
              Filtered On:
            </div>
            <div style={{ fontSize: '0.6rem', whiteSpace: 'nowrap' }}>
              {selectedTabType === 'assigned'
                ? proportionAssignedIssueIDs
                : proportionStagingIssueIDs}
            </div>
          </div>
          <Spacer />
          <div style={{ textAlign: 'left', whiteSpace: 'wrap' }}>
            {filteredItems.join(', ')}
          </div>
        </div>
      )
    }

    return null
  }
}

IssueFilterApplied.propTypes = {
  isDateApplied: PropTypes.bool,
  isSeverityApplied: PropTypes.bool,
  isIncludeResolvedApplied: PropTypes.bool,
  isIssueModeTypeApplied: PropTypes.bool,
  defaultIncludeResolved: PropTypes.bool,
  isTagsApplied: PropTypes.bool,
  isOrgFilterApplied: PropTypes.bool,
}

IssueFilterApplied.defaultProps = {
  isDateApplied: false,
  isSeverityApplied: false,
  isIncludeResolvedApplied: false,
  isIssueModeTypeApplied: false,
  defaultIncludeResolved: true,
  isTagsApplied: false,
  isOrgFilterApplied: false,
}

const mapStateToProps = (state, _) => {
  const availableUnresolvedIssues = selectAllUnresolvedIssues(state)

  const filter = selectIssueFilterField(state, ['orgIdList'])

  const userOrgInfo: AccountOrganization = selectUserOrgInfo(state)

  const availableUnresolvedIssuesList = availableUnresolvedIssues?.map(
    (issue) => {
      return { OrgID: issue.OrgID, OrgName: issue.issueOrgName }
    }
  )

  const uniqueIssuesUnresolvedList = [
    ...new Map(
      availableUnresolvedIssuesList?.map((issue) => [issue['OrgID'], issue])
    ).values(),
  ]

  if (!userOrgInfo.Orgs) return

  const userOrgList = [...userOrgInfo.Orgs]

  for (const unresolvedIssue of uniqueIssuesUnresolvedList) {
    if (
      !userOrgList.find((orgItem) => orgItem.OrgID === unresolvedIssue.OrgID)
    ) {
      userOrgList.push(unresolvedIssue)
    }
  }

  const isOrgFilterApplied =
    filter.orgIdList?.length > 0 &&
    !(userOrgList?.length <= filter.orgIdList?.length)

  return {
    isDateApplied:
      isStartDateFilterApplied(state) || isEndDateFilterApplied(state),
    isSeverityApplied: isOrgIssueLevelFilterApplied(state),
    isIncludeResolvedApplied: isIncludeResolvedFilterApplied(state),
    isIssueModeTypeApplied: isIssueModeTypeFilterApplied(state),
    defaultIncludeResolved: selectFilterDefaultIncludeResolved(state),
    isTagsApplied: isTagsFilterApplied(state),
    isOrgFilterApplied,
    proportionAssignedIssueIDs: selectAssignedFilteredFromTotalIssues(state),
    proportionStagingIssueIDs: selectStagingFilteredFromTotalIssues(state),
    selectedTabType: selectIssueStageTab(state),
  }
}

export default connect(mapStateToProps)(IssueFilterApplied)
