import {
  bindSel,
  getMSTeamsChannel,
  getMSTeamsEvent,
  getMSTeams,
  getMSTeamsTeam,
} from '../_rootConfigs/rootReducerSlicers'
import * as fromMsTeams from './_reducer'

// Microsoft Teams
export const selectIsMicrosoftTeams = bindSel(
  getMSTeams,
  fromMsTeams.selectIsMicrosoftTeams
)
export const selectMSTeamsContext = bindSel(
  getMSTeams,
  fromMsTeams.selectMSTeamsContext
)
export const selectMSTeamsLoginHint = bindSel(
  getMSTeams,
  fromMsTeams.selectMSTeamsLoginHint
)
export const selectMSTeamsGroupId = bindSel(
  getMSTeams,
  fromMsTeams.selectMSTeamsGroupId
)
export const selectMSTeamsChannelName = bindSel(
  getMSTeams,
  fromMsTeams.selectMSTeamsChannelName
)
export const selectAzureADLoginCode = bindSel(
  getMSTeams,
  fromMsTeams.selectAzureADLoginCode
)
export const selectMSTeamsChannelFolderId = bindSel(
  getMSTeams,
  fromMsTeams.selectMSTeamsChannelFolderId
)
export const selectMSTeamsChannelId = bindSel(
  getMSTeams,
  fromMsTeams.selectMSTeamsChannelId
)
export const selectIsMSTeamsAutoLoginEnabled = bindSel(
  getMSTeams,
  fromMsTeams.selectIsMSTeamsAutoLoginEnabled
)
export const isMSTeamsAutoLoginDisabled = bindSel(
  getMSTeams,
  fromMsTeams.isMSTeamsAutoLoginDisabled
)
export const selectJoinedMSTeamsIds = bindSel(
  getMSTeams,
  fromMsTeams.selectJoinedMSTeamsIds
)

// MS Joined Teams SELECTORS
export const selectJoinedMSTeamByIdMap = bindSel(
  getMSTeamsTeam,
  fromMsTeams.selectJoinedMSTeamByIdMap
)
export const selectJoinedMSTeamById = bindSel(
  getMSTeamsTeam,
  fromMsTeams.selectJoinedMSTeamById
)
export const selectMSTeamNameById = bindSel(
  getMSTeamsTeam,
  fromMsTeams.selectMSTeamNameById
)
export const selectMSChannelIdsByTeamId = bindSel(
  getMSTeamsTeam,
  fromMsTeams.selectMSChannelIdsByTeamId
)

// MS Joined Channels SELECTORS
export const selectJoinedMSChannelByIdMap = bindSel(
  getMSTeamsChannel,
  fromMsTeams.selectJoinedMSChannelByIdMap
)
export const selectJoinedMSChannelById = bindSel(
  getMSTeamsChannel,
  fromMsTeams.selectJoinedMSChannelById
)
export const selectMSChannelNameById = bindSel(
  getMSTeamsChannel,
  fromMsTeams.selectMSChannelNameById
)
export const selectMSChannelGroupId = bindSel(
  getMSTeamsChannel,
  fromMsTeams.selectMSChannelGroupId
)
export const selectMSFolderIdByChannelId = bindSel(
  getMSTeamsChannel,
  fromMsTeams.selectMSFolderIdByChannelId
)
export const selectMSDriveIdByChannelId = bindSel(
  getMSTeamsChannel,
  fromMsTeams.selectMSDriveIdByChannelId
)

// MS Calendar Events
export const selectMSCalendarEventsByIdMap = bindSel(
  getMSTeamsEvent,
  fromMsTeams.selectMSCalendarEventsByIdMap
)
export const selectMSCalendarEventsWorkspaceId = bindSel(
  getMSTeamsEvent,
  fromMsTeams.selectMSCalendarEventsWorkspaceId
)
export const selectMSCalendarEvents = bindSel(
  getMSTeamsEvent,
  fromMsTeams.selectMSCalendarEvents
)
export const selectMSCalendarEventsCount = bindSel(
  getMSTeamsEvent,
  fromMsTeams.selectMSCalendarEventsCount
)
