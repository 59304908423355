import React, { Component, MouseEvent, ReactNode } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, IconDefinition } from '@fortawesome/free-solid-svg-icons'
import { Fab } from '@material-ui/core'
import classes from './Buttons.module.scss'
import { cx } from '../_utils/objectUtils'
import { Paper } from '@material-ui/core'
import { ArrowForward } from '@material-ui/icons'
import { ButtonProps, Button as ButtonStrap } from 'reactstrap'
import { Fragment } from 'react'
import { faSpinnerThird } from '@fortawesome/pro-duotone-svg-icons'

interface AddButtonProps {
  onClick?(event: MouseEvent): void
  size?: string
  className?: string
  id?: string
}

export class AddButton extends Component<AddButtonProps> {
  render() {
    const { onClick, size = '', className, ...other } = this.props
    let btnSize: 'small' | 'large' | 'normal' =
      size === 'small' ? 'small' : 'normal'
    return (
      <Fab
        onClick={onClick}
        color="primary"
        className={cx(className, classes.addButton, classes[btnSize] || '')}
        {...other}
      >
        <FontAwesomeIcon className={classes.addButtonPlus} icon={faPlus} />
      </Fab>
    )
  }
}

interface MainPageBackBtnProps {
  onClick?(event: MouseEvent): void
}

export const MainPageBackBtn = ({
  onClick = () => {},
}: MainPageBackBtnProps) => (
  <Paper className={classes.mainPageBackBtn} onClick={onClick}>
    Expand <ArrowForward />
  </Paper>
)

interface IconButtonProps extends ButtonProps {
  icon?: ReactNode
  iconDef?: IconDefinition
  rounded?: boolean
  color?:
    | 'standard'
    | 'primary'
    | 'secondary'
    | 'danger'
    | 'link'
    | 'darker'
    | 'primaryDark'
    | 'clear'
  isLoading?: boolean
  loadingText?: string
  capitalize?: boolean
}

export const Button = ({
  icon,
  iconDef,
  children,
  rounded,
  color = 'primary',
  className,
  isLoading,
  loadingText,
  disabled,
  capitalize,
  ...other
}: IconButtonProps) => (
  <ButtonStrap
    color={isLoading ? 'standard' : color}
    className={cx(
      className,
      classes.button,
      rounded ? classes.rounded : '',
      classes[isLoading ? 'standard' : color]
    )}
    style={capitalize ? { textTransform: 'capitalize' } : {}}
    disabled={disabled || isLoading}
    {...other}
  >
    {isLoading ? (
      <Fragment>
        <FontAwesomeIcon spin={true} icon={faSpinnerThird} /> {loadingText}
      </Fragment>
    ) : (
      <Fragment>
        {icon ? icon : iconDef && <FontAwesomeIcon icon={iconDef} />}
        {children}
        {(icon || iconDef) && <span className={classes.rightSpacer}></span>}
      </Fragment>
    )}
  </ButtonStrap>
)
