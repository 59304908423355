import React from 'react'
import { useRef } from 'react'
import classes from './Playbooks.module.scss'
import { CircularProgress } from '../Shared/LoadingBackdrop'

interface Props {
  HostURL: string
  HostPage: string
  HdnVals: any
}

export const HiddenFormWithValues = ({ HostURL, HostPage, HdnVals }: Props) => {
  const url = HostURL + '/' + HostPage ///{HostPage}';
  const formRef = useRef<HTMLFormElement>(null)
  const hfv = () => {
    const final: any[] = []
    for (var key in HdnVals) {
      final.push(
        <input key={key} type="hidden" name={key} value={HdnVals[key]}></input>
      )
    }
    return final
  }

  React.useEffect(() => {
    if (formRef) {
      formRef?.current?.submit()
    }
  }, [HostURL, HostPage, HdnVals]) // whenever HostPage or HdnVals change will rerun

  return (
    <div className={classes.iframeStyleContainer}>
      <iframe
        key={HostPage}
        title="my_iframe"
        id="my_iframe"
        name="my_iframe"
        src=""
        className={classes.iframeStyle}
        frameBorder="none"
        allowFullScreen
      ></iframe>
      <div className="clearfix"></div>
      <form target="my_iframe" method="post" action={url} ref={formRef}>
        {hfv()}
      </form>
      <CircularProgress zIndex={1} />
    </div>
  )
}
