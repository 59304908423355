// @ts-nocheck
// TODO: Typescript
import { call, put, select } from 'redux-saga/effects'
import { icoFetch, icoPost } from '../_utils/fetchUtils'
import { unAuthenticated, authenticated, unAuthorized } from '../Auth/_actions'
import { removeAtStart } from './objectUtils'
import { msGraphRequestFailure, ReducerAction } from './reduxUtils'
import { isAzureADLogin } from '../Auth/_selectors'

export function sagaWithAzureOnly(saga, onNotAzure) {
  return function* newSaga(action) {
    const isAzure = yield select(isAzureADLogin)
    const params = (action.payload || {}).params || {}
    if (!isAzure && !params.isAzure) {
      if (onNotAzure) yield put(onNotAzure())
      return
    }

    return yield call(saga, action)
  }
}

/**
 * Will dispatch user unauthorized if a status of 401 is returned
 * @param {any} request
 * @param {...any} args
 */
export function sagaWithAuthCheck(requestSaga, ...args) {
  return function* newSaga() {
    let result
    try {
      result = yield call(requestSaga, ...args)
    } catch (ex) {
      try {
        const body = JSON.parse(ex.body)
        if (
          ex.statusCode === 401 ||
          (body.code || '').toLocaleLowerCase() === 'unauthorized'
        )
          yield put(unAuthenticated())
      } catch {
        throw ex
      }
    }

    return result
  }
}

export interface FetchListenerOptions {
  request?: RequestInit
  onLoading?: (
    result?: any,
    params?: any,
    source?: any
  ) => ReducerAction | ReducerAction[]
  onFailure?: (
    error?: any,
    result?: any,
    params?: any,
    source?: any
  ) => ReducerAction | ReducerAction[]
  onSuccess?: (
    result?: any,
    params?: any,
    source?: any,
    selectData?: any
  ) => ReducerAction | ReducerAction[]
  onDone?: (result?: any) => ReducerAction | ReducerAction[]
  onUnAuthenticated?: () => ReducerAction | ReducerAction[]
  formatData?: boolean | ((json: any) => any)
  formatRequestBody?: () => any
  appendPath?: (action: ReducerAction) => string
  getHeaders?: (action: ReducerAction) => HeadersInit
  queryParamsFn?: (action: ReducerAction) => { [paramName: any]: any }
  allSelects?: { [selectFn: string]: Function }
  requireAuthentication?: boolean
}

export function getFetchListener(url, options: FetchListenerOptions) {
  return getRequestListener(icoFetch, url, options, 'get')
}

export function getPostListener(url, options: FetchListenerOptions) {
  return getRequestListener(icoPost, url, options, 'post')
}

export function getRequestListener(
  fetchCallback,
  url,
  options: FetchListenerOptions,
  method?: string
) {
  options = options || {}
  const onLoading = options.onLoading
  const onFailure = options.onFailure
  const onSuccess = options.onSuccess
  const onDone = options.onDone
  const onUnAuthenticated = options.onUnAuthenticated
  const formatData = options.formatData
  const formatRequestBody = options.formatRequestBody
  const appendPath =
    options.appendPath ||
    function () {
      return ''
    }
  const queryParamsFn = options.queryParams
  const allSelects = options.select || {}
  const selectData = {}
  const getHeaders = options?.getHeaders
  let request = options.request
  method = (request?.method || method)?.toLocaleUpperCase()

  let requireAuthentication = options.requireAuthentication

  if (requireAuthentication === undefined) requireAuthentication = true

  // The Saga that does the actual work
  return function* listener(action) {
    action = action || {}
    let json = []
    const { payload } = action
    let { result, params, source } = payload || {}
    let resultObj = result || params

    for (let selectKey in allSelects) {
      selectData[selectKey] = yield select(...allSelects[selectKey])
    }

    // Add Headers
    if (getHeaders) {
      request = {
        ...request,
        headers: getHeaders(action),
      }
    }

    // Append a url prefix - This is where path variables should go
    let urlSuffix = appendPath(action)
    urlSuffix = urlSuffix ? removeAtStart(urlSuffix, '/') : urlSuffix
    let finalUrl = url + '/' + urlSuffix

    if (queryParamsFn) {
      let paramsArray = []
      const queryParams = queryParamsFn(action) || {}
      for (let key in queryParams) {
        paramsArray.push(key + '=' + queryParams[key])
      }

      finalUrl += '?' + paramsArray.join('&')
    }

    source = { ...source, pathVariables: urlSuffix }

    try {
      // Calls to the server for the data.
      // This line of code is paused until the Saga middleware detects he promise has finished
      if (onLoading) yield put(onLoading(resultObj, params, source))

      let response = undefined
      if (method !== 'GET') {
        if (formatRequestBody)
          response = yield call(
            fetchCallback,
            finalUrl,
            formatRequestBody(action),
            request,
            action.dontRenewAuth
          )
        else
          response = yield call(
            fetchCallback,
            finalUrl,
            action.data,
            request,
            action.dontRenewAuth
          )
      } else
        response = yield call(
          fetchCallback,
          finalUrl,
          request,
          action.dontRenewAuth
        )
      // Notify that the user is unauthorized (401 for use means the user is not logged in. Authorization will be handled at the proc level)
      // Ideally the user should get all permissions at app start up
      if (response.status === 401) {
        if (onUnAuthenticated) yield put(onUnAuthenticated())
      } else if (response.status === 403 || response.status === 400)
        // If not authorized to use issues alert the system
        yield put(unAuthorized())
      else {
        // Do this if the user is authenticated
        try {
          json = yield call([response, 'json']) || []
        } catch {}
        const { msGraphFailure } = json
        if (
          json.isAuthenticated === false &&
          requireAuthentication &&
          !msGraphFailure
        ) {
          if (onUnAuthenticated) yield put(onUnAuthenticated())
        } else {
          if (json.isAuthenticated)
            yield put(
              action.dontRenewAuth
                ? dontRenewAuth(authenticated())
                : authenticated()
            )

          // trigger failure or success based on the error message
          let errorMessage = json.ErrorMessage

          if (json.Status === 'Failure') errorMessage = json.StatusDescr

          if (formatData !== false) {
            if (formatData) {
              json = formatData(json)
            } else {
              json = (json || {}).returnData
            }
          }

          if (msGraphFailure)
            yield put(msGraphRequestFailure(errorMessage, source))

          if (errorMessage) {
            if (onFailure) {
              const onfailureResult = onFailure(
                errorMessage,
                resultObj,
                params,
                source
              )
              if (onfailureResult instanceof Array) {
                for (let i = 0; i < onfailureResult.length; i++) {
                  yield put(onfailureResult[i])
                }
              } else {
                yield put(onfailureResult)
              }
            }
          } else {
            if (onSuccess) {
              const successResult = onSuccess(json, params, source, selectData)
              if (successResult instanceof Array) {
                for (let i = 0; i < successResult.length; i++) {
                  yield put(successResult[i])
                }
              } else {
                yield put(successResult)
              }
            }
          }
        }
      }

      if (onDone) yield put(onDone(json))
    } catch (ex) {
      // Dispatch a failure to redux
      if (onFailure) yield put(onFailure(ex.error, resultObj, params, source))

      if (onDone) yield put(onDone({}))

      throw ex
    }
  }
}

function dontRenewAuth(obj) {
  return Object.assign({}, obj, { dontRenewAuth: true })
}

const exportSagaUtils = {
  getFetchListener,
  getPostListener,
  getRequestListener,
  dontRenewAuth,
}
export default exportSagaUtils
