// @ts-nocheck
// TODO: Typescript
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { sanitizeHTML } from '../_utils/domUtils'
import classes from './HoldingStatementListItem.module.scss'
import Moment from 'react-moment'
import 'moment-timezone'
import { getLocalDate } from '../_utils/dateUtils'
import { appDateTimeFormat } from '../_constants'
import { selectHoldingStatementById } from './_selectors'

/**
 * @extends {Component<Props, {}>}}
 * Renders a holding statement
 */
export class HoldingStatementListItem extends Component {
  render() {
    const { Content, Created, CreatedBy, Approved, ApprovedBy } = this.props
    function DateBlock(props) {
      if (props.date) {
        return (
          <div className={props.className}>
            <Moment
              local
              format={appDateTimeFormat}
              date={getLocalDate(props.date)}
            />
          </div>
        )
      } else {
        return ''
      }
    }
    return (
      <li className={classes.root}>
        <div
          className={classes.message}
          dangerouslySetInnerHTML={sanitizeHTML(Content)}
        ></div>
        <div className={classes.header}>
          <div className={classes.user}>Created By: {CreatedBy}</div>
          <DateBlock className={classes.createdDate} date={Created} />
        </div>
        {Approved ? (
          <div className={classes.header}>
            <div className={classes.user}>Approved By: {ApprovedBy}</div>
            <DateBlock className={classes.createdDate} date={Approved} />
          </div>
        ) : (
          ''
        )}
      </li>
    )

    //function renderApproveInfo() {
    //    if (Approved)
    //        return (<div className={classes.header}>
    //            <div className={classes.user}>Approved By: {ApprovedBy}</div>
    //            <DateBlock className={classes.createdDate} date={Approved} />
    //        </div>
    //        )
    //    return null;
    //}
  }
}

HoldingStatementListItem.propTypes = {
  Created: PropTypes.any,
  Creator: PropTypes.number,
  Content: PropTypes.string,
  CreatedBy: PropTypes.string,
  ApprovedBy: PropTypes.string,
  HoldingStatementID: PropTypes.number,
}

const mapStateToProps = (state, ownProps) => {
  const holdingStatement =
    selectHoldingStatementById(state, ownProps.HoldingStatementID) || {}
  const {
    Created,
    Creator,
    Content,
    HoldingStatementID,
    CreatedBy,
    ApprovedBy,
    Approved,
  } = holdingStatement
  return {
    Created,
    Creator,
    Content,
    HoldingStatementID,
    CreatedBy,
    ApprovedBy,
    Approved,
  }
}

export default connect(mapStateToProps, undefined)(HoldingStatementListItem)
