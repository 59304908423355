import {
  faArrowDown,
  faArrowUp,
  faFile,
  faPlus,
  faTimes,
} from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  Button,
  Input,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core'
import React from 'react'
import Moment from 'react-moment'
import { useDispatch } from 'react-redux'
import { Table } from 'reactstrap'
import { Organization } from '../Issue/_reducer'
import { AdminConsoleDialog } from '../Shared/AdminConsoleDialog'
import { SearchInput } from '../Shared/SearchInput'
import { Spacer } from '../Shared/Spacer'
import { TaskTemplate } from '../Task/TaskListToolbar'
import { addNotification } from '../_actions'
import { appDateTimeExactFormat } from '../_constants'
import {
  deleteTaskListTemplate,
  taskTemplatesForOrg,
  updateTaskListTemplateTitle,
} from '../_utils/apiUtils'
import { StyledTableRow } from './AdminConsolePage'
import classes from './AdminConsolePage.module.scss'
import AdminConsoleTaskTemplateAddDialog from './AdminConsoleTaskTemplateAddDialog'
import { AdminConsoleTaskTemplateEditDialog } from './AdminConsoleTaskTemplateEditDialog'

type TableHeader = 'Title' | 'CreatedOn'
type SortDirection = 'asc' | 'dec' | 'none'

export interface TaskListTableSort {
  header: TableHeader
  sortDirection: SortDirection
}
export const AdminConsoleTaskListTemplate = ({
  selectedOrg,
}: {
  selectedOrg: Organization
}) => {
  const [updateTaskListDialogOpen, setUpdateTaskListDialogOpen] =
    React.useState(false)
  const [deleteTaskListDialogOpen, setDeleteTaskListDialogOpen] =
    React.useState(false)

  const [taskTemplateList, setTaskTemplateList] =
    React.useState<TaskTemplate[]>()
  const [selectedTasklistTemplate, setSelectTasklistTemplate] = React.useState<
    TaskTemplate | undefined
  >()
  const [updateTasklistTemplateInput, setUpdateTasklistTemplateInput] =
    React.useState('')
  const [taskListTemplateSearch, setTaskListTemplateSearch] = React.useState('')
  const [needsRefresh, setNeedsRefresh] = React.useState(false)
  const [taskListTemplateSort, setTaskListTemplateSort] =
    React.useState<TaskListTableSort>({
      header: 'Title',
      sortDirection: 'dec',
    })

  const [editTemplateDialogOpen, setEditTemplateDialogOpen] =
    React.useState(false)

  const [editingTemplate, setEditingTemplate] = React.useState<TaskTemplate>()

  const [addTemplateDialogOpen, setAddTemplateDialogOpen] =
    React.useState(false)

  const [width, setWidth] = React.useState<number>(window.innerWidth)

  function handleWindowSizeChange() {
    setWidth(window.innerWidth)
  }

  const isMobile = width <= 960

  React.useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange)
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange)
    }
  }, [])

  const dispatch = useDispatch()

  const setSortDirection = (header: TableHeader) => {
    if (header === taskListTemplateSort.header) {
      if (taskListTemplateSort.sortDirection === 'none') {
        setTaskListTemplateSort({ header: header, sortDirection: 'dec' })
      } else if (taskListTemplateSort.sortDirection === 'asc') {
        setTaskListTemplateSort({ header: header, sortDirection: 'dec' })
      } else {
        setTaskListTemplateSort({ header: header, sortDirection: 'asc' })
      }
    } else {
      setTaskListTemplateSort({ header: header, sortDirection: 'dec' })
    }
  }

  const sortTemplateList = (
    taskListTemplateList: TaskTemplate[] | undefined
  ) => {
    if (taskListTemplateSort.sortDirection === 'none') return

    switch (taskListTemplateSort.header) {
      case 'Title':
        if (taskListTemplateSort.sortDirection === 'dec') {
          return taskListTemplateList?.sort((a, b) =>
            a.template_name.localeCompare(b.template_name)
          )
        } else if (taskListTemplateSort.sortDirection === 'asc') {
          return taskListTemplateList?.sort(
            (a, b) => -1 * a.template_name.localeCompare(b.template_name)
          )
        }
        break

      case 'CreatedOn':
        if (taskListTemplateSort.sortDirection === 'asc') {
          return taskListTemplateList?.sort((a, b) =>
            a.createdUTC.localeCompare(b.createdUTC)
          )
        } else if (taskListTemplateSort.sortDirection === 'dec') {
          return taskListTemplateList?.sort(
            (a, b) => -1 * a.createdUTC.localeCompare(b.createdUTC)
          )
        }
        break
    }
  }

  React.useEffect(() => {
    setUpdateTasklistTemplateInput(
      selectedTasklistTemplate?.template_name || ''
    )
  }, [selectedTasklistTemplate])

  React.useEffect(() => {
    if (selectedOrg.OrgID)
      taskTemplatesForOrg(parseInt(selectedOrg.OrgID))
        .then((data) => setTaskTemplateList(data.templates))
        .then(() => {
          setNeedsRefresh(false)
        })
  }, [selectedOrg, needsRefresh])

  const handleTaskTemplateSubmit = React.useCallback(() => {
    const nameExists = taskTemplateList?.filter((template) => {
      return template.template_name === updateTasklistTemplateInput
    })
    let errorMessage = ''

    if (
      updateTasklistTemplateInput === selectedTasklistTemplate?.template_name ||
      updateTasklistTemplateInput === ''
    ) {
      errorMessage =
        'Template name has not changed or is empty and cannot be submitted'
    } else if (nameExists && nameExists?.length > 0) {
      errorMessage =
        'This template name already exists within this organization'
    }

    if (errorMessage) {
      dispatch(
        addNotification({
          message: errorMessage,
          type: 'error',
        })
      )
      return
    }
    setUpdateTaskListDialogOpen(true)
  }, [
    dispatch,
    selectedTasklistTemplate?.template_name,
    taskTemplateList,
    updateTasklistTemplateInput,
  ])

  const clearStateAndRefresh = () => {
    setUpdateTaskListDialogOpen(false)
    setDeleteTaskListDialogOpen(false)
    setUpdateTasklistTemplateInput('')
    setSelectTasklistTemplate(undefined)
    setAddTemplateDialogOpen(false)
    setNeedsRefresh(true)
  }

  const filteredTemplateList = taskTemplateList?.filter((template) => {
    if (template.id === selectedTasklistTemplate?.id) {
      return template
    }
    return template.template_name
      .toLowerCase()
      .includes(taskListTemplateSearch.toLowerCase())
  })

  const TaskListDialogs = () => {
    return (
      <>
        <AdminConsoleDialog
          open={updateTaskListDialogOpen}
          content={
            'Are you sure you would like to change the name of this Task List Template?'
          }
          onConfirm={() => {
            if (selectedOrg && selectedTasklistTemplate) {
              updateTaskListTemplateTitle(
                parseInt(selectedOrg?.OrgID),
                selectedTasklistTemplate?.id,
                updateTasklistTemplateInput
              )
            }
            clearStateAndRefresh()
          }}
          onCancel={() => setUpdateTaskListDialogOpen(false)}
        />
        <AdminConsoleDialog
          open={deleteTaskListDialogOpen}
          content={
            'Are you sure you would like to delete this Task List Template?'
          }
          onConfirm={() => {
            if (selectedOrg && selectedTasklistTemplate) {
              deleteTaskListTemplate(
                parseInt(selectedOrg?.OrgID),
                selectedTasklistTemplate?.id
              )
            }
            clearStateAndRefresh()
          }}
          consoleRole="destructive"
          onCancel={() => setDeleteTaskListDialogOpen(false)}
        />
      </>
    )
  }

  const renderTaskTemplateEditContainer = (
    <div className={classes.OrganizationHoldingStatementEditContainer}>
      <div>
        <div className={classes.OrganizationInnerContainerEditHeader}>
          Template Name:
          <FontAwesomeIcon
            icon={faTimes}
            style={{ cursor: 'pointer' }}
            onClick={() => {
              setSelectTasklistTemplate(undefined)
            }}
          />
        </div>
        <Input
          type="text"
          required={true}
          name="Template"
          inputProps={{
            maxLength: 255,
          }}
          style={{ minWidth: '200px', maxWidth: '400px' }}
          fullWidth
          className={classes.TagInput}
          value={updateTasklistTemplateInput}
          onChange={(e) => setUpdateTasklistTemplateInput(e.target.value)}
        />
      </div>
      <div className={classes.OrganizationTagButtonContainer}>
        <Button
          onClick={() => setDeleteTaskListDialogOpen(true)}
          className={classes.OrganizationDeleteButton}
          style={{ color: 'red' }}
        >
          {' '}
          Delete{' '}
        </Button>

        <Button
          onClick={() => {
            handleTaskTemplateSubmit()
          }}
          className={classes.OrganizationSaveButton}
          style={{ color: 'rgb(64, 148, 195)' }}
        >
          {' '}
          Save{' '}
        </Button>
      </div>
    </div>
  )

  const templateList =
    taskListTemplateSort.sortDirection === 'none'
      ? filteredTemplateList
      : sortTemplateList(filteredTemplateList)

  return (
    <>
      <TaskListDialogs />
      <div className={classes.OrganizationManagementSection}>
        <div className={classes.OrganizationManagementSectionHeader}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              marginBottom: '10px',
            }}
          >
            <FontAwesomeIcon icon={faFile} size={'2x'} />{' '}
            &nbsp;&nbsp;&nbsp;&nbsp; <div>TASK LIST TEMPLATES</div>
            <Spacer spaceParam={'large'} />
            <SearchInput
              id="templateSearchInput"
              name="SearchInput"
              placeholder="Template Search"
              value={taskListTemplateSearch}
              applySearch={(value: string) => {
                setTaskListTemplateSearch(value)
              }}
              onClear={() => setTaskListTemplateSearch('')}
            />
            <Spacer />
            <FontAwesomeIcon
              style={{ cursor: 'pointer' }}
              icon={faPlus}
              onClick={() => setAddTemplateDialogOpen(true)}
            />
          </div>
        </div>

        <Table aria-label="custom table">
          <colgroup>
            <col style={{ width: '30%' }} />
            <col style={{ width: '20%' }} />
            <col style={{ width: '30%' }} />
            <col style={{ width: '20%' }} />
          </colgroup>
          <TableHead>
            <TableRow>
              <TableCell
                align="left"
                style={{
                  cursor: 'pointer',
                  display: 'flex',
                  alignItems: 'center',
                  borderBottom: '0',
                }}
                onClick={() => setSortDirection('Title')}
              >
                Title
                <Spacer />
                {taskListTemplateSort.header === 'Title' &&
                taskListTemplateSort.sortDirection === 'asc' ? (
                  <FontAwesomeIcon icon={faArrowUp} />
                ) : (
                  taskListTemplateSort.header === 'Title' && (
                    <FontAwesomeIcon icon={faArrowDown} />
                  )
                )}
              </TableCell>
              {!isMobile && (
                <TableCell style={{ borderBottom: '0' }}>Created By</TableCell>
              )}
              <TableCell
                align="left"
                style={{
                  cursor: 'pointer',
                  display: 'flex',
                  alignItems: 'center',
                  borderBottom: '0',
                  height: '100%',
                }}
                onClick={() => setSortDirection('CreatedOn')}
              >
                {' '}
                Created
                <Spacer />
                {taskListTemplateSort.header === 'CreatedOn' &&
                taskListTemplateSort.sortDirection === 'asc' ? (
                  <FontAwesomeIcon icon={faArrowUp} />
                ) : (
                  taskListTemplateSort.header === 'CreatedOn' && (
                    <FontAwesomeIcon icon={faArrowDown} />
                  )
                )}
              </TableCell>
              {!isMobile && (
                <TableCell style={{ borderBottom: '0' }}>Items</TableCell>
              )}
            </TableRow>
          </TableHead>

          <TableBody>
            {templateList?.map((template) => {
              const taskTemplateRow = { title: template.template_name }

              const isSelected = selectedTasklistTemplate?.id === template.id

              const templateItemJson = JSON.parse(template.template_json)

              return (
                <StyledTableRow
                  key={template.id}
                  onClick={() => {
                    setSelectTasklistTemplate(template)
                  }}
                  style={{
                    backgroundColor: isSelected ? 'rgb(64, 148, 195)' : '',
                  }}
                >
                  <TableCell
                    component="th"
                    scope="row"
                    style={{
                      color: isSelected ? 'white' : '',
                    }}
                  >
                    {taskTemplateRow.title}
                  </TableCell>
                  {!isMobile && (
                    <TableCell
                      component="th"
                      scope="row"
                      style={{
                        color: isSelected ? 'white' : '',
                        whiteSpace: 'normal',
                        wordBreak: 'break-word',
                      }}
                    >
                      {template?.creatorName}
                    </TableCell>
                  )}
                  <TableCell
                    component="th"
                    scope="row"
                    style={{
                      color: isSelected ? 'white' : '',
                    }}
                  >
                    <Moment
                      local
                      format={appDateTimeExactFormat}
                      date={template.createdUTC}
                    />
                  </TableCell>
                  {!isMobile && (
                    <TableCell
                      component="th"
                      scope="row"
                      style={{
                        color: isSelected ? 'white' : '',
                      }}
                    >
                      {templateItemJson.length}
                    </TableCell>
                  )}
                  <TableCell>
                    <Button
                      onClick={(e) => {
                        setEditTemplateDialogOpen(true)
                        setEditingTemplate(template)
                        e.stopPropagation()
                      }}
                    >
                      {' '}
                      Edit{' '}
                    </Button>
                  </TableCell>
                </StyledTableRow>
              )
            })}
          </TableBody>
        </Table>
        {selectedTasklistTemplate && renderTaskTemplateEditContainer}
        <AdminConsoleTaskTemplateEditDialog
          open={editTemplateDialogOpen}
          closeDialog={() => {
            setEditTemplateDialogOpen(false)
            setNeedsRefresh(true)
          }}
          template={editingTemplate}
          orgId={selectedOrg?.OrgID}
        />

        <AdminConsoleTaskTemplateAddDialog
          open={addTemplateDialogOpen}
          orgId={parseInt(selectedOrg?.OrgID)}
          onClose={() => clearStateAndRefresh()}
        />
      </div>
    </>
  )
}
