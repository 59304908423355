import * as React from 'react'
import { http } from '../_utils/http'
import { CircularProgress } from '../Shared/LoadingBackdrop'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core'
import classes from './OrgDataRreport.module.scss'
import { selectEnvironment } from '../Issue/_selectors'
import { useAppSelector } from '../_utils/reactReduxHooks'

interface Props {
  OrgID: string
}

export interface selectedElements {
  elements: string[]
}

export const OrgDataReport = ({ OrgID }: Props) => {
  const [orgDataElements, setOrgDataElements] = React.useState<any>('')
  const [selectedElements, setSelectedElements] =
    React.useState<selectedElements>({ elements: [] })
  const [dataReport, setDataReport] = React.useState<any>('')
  const [isFetchingDataReport, setIsFetchingDataReport] =
    React.useState<boolean>(false)
  const env = useAppSelector((state) => {
    return selectEnvironment(state).toLocaleLowerCase()
  })

  const getDataReport = async (): Promise<any> => {
    var customHeaders: any = []
    if (isFetchingDataReport) {
      return
    }
    setIsFetchingDataReport(true)
    setDataReport('')
    var elementList = selectedElements.elements.join(',')
    customHeaders.push({ name: 'orgid', value: `${OrgID}` })
    customHeaders.push({
      name: 'elementlist',
      value: elementList,
    })
    var d = new Date()
    customHeaders.push({
      name: 'tzoffset',
      value: d.getTimezoneOffset().toString(),
    })

    const result = await http<any>({
      path: '/Issue/OrgDataReport',
      headers: customHeaders,
    })
    if (result.ok && result.body) {
      setDataReport(result.body)
    } else {
      setDataReport('')
    }
    setIsFetchingDataReport(false)
  }

  const getOrgDataElements = async (): Promise<any> => {
    var customHeaders: any = []
    customHeaders.push({ name: 'drdomain', value: 'Organization' })
    const result = await http<any>({
      path: '/Issue/DataElements',
      headers: customHeaders,
    })
    if (result.ok && result.body) {
      setOrgDataElements(result.body)
    } else {
      setOrgDataElements('')
    }
  }

  React.useEffect(() => {
    getOrgDataElements()
  }, [OrgID])

  function elementCheckboxChanged(evt: any) {
    const cval = evt.target.value
    var selElem = selectedElements
    if (evt.target.checked) {
      selElem.elements.push(cval)
    } else {
      const foundIndex = selectedElements.elements.findIndex((v) => v === cval)
      if (foundIndex > -1) {
        selElem.elements.splice(foundIndex, 1)
      }
    }
    setSelectedElements(selElem)
    getDataReport()
  }

  function renderResults() {
    if (!(env === 'development')) return <>Only available in DEV environment</>
    if (!dataReport) return <>Choose Data To Show</>
    if (!dataReport.results) return <>Choose Data To Show</>
    if (dataReport.results.count === 0) return <>Choose Data To Show</>
    return (
      <div className={classes.DataReport}>
        <h2>{dataReport.OrgName}</h2>
        {/* <div>Selected Elements:{selectedElements.elements.join(',')}</div> */}
        {dataReport.results.map((drr: any, drn: number) => {
          if (drr.resultCount > -1) {
            return (
              <div className={classes.DataReportSection}>
                <h2>{drr.drelement}</h2>
                <div className={classes.DataReportDetail}>
                  &nbsp;
                  {drr.resultCount}
                </div>
              </div>
            )
          }
          if (drr.resultJSON && drr.resultKeys) {
            return (
              <div className={classes.DataReportSection}>
                <h3>{drr.drelement}</h3>
                <Table className={classes.DataReportDetail}>
                  <TableHead>
                    <TableRow>
                      {drr.resultKeys
                        .split(',')
                        .map((kstr: string, knum: number) => {
                          return (
                            <TableCell>{kstr.split('_').join(' ')}</TableCell>
                          )
                        })}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {drr.resultJSON.map((drow: any, drnum: number) => {
                      return (
                        <TableRow>
                          {drr.resultKeys
                            .split(',')
                            .map((kstr: string, knum: number) => {
                              return <TableCell>{drow[kstr]}</TableCell>
                            })}
                        </TableRow>
                      )
                    })}
                  </TableBody>
                </Table>
              </div>
            )
          }
          return (
            <div className={classes.DataReportSection}>
              <h3>{drr.drelement}</h3>
              no data
            </div>
          )
        })}
      </div>
    )
  }

  function renderList() {
    if (!orgDataElements) return <CircularProgress />
    var curDrType = ''
    return (
      <div className={classes.DataScreen}>
        {/* <pre>{JSON.stringify(orgDataElements, null, 2)}</pre> */}
        <div className={classes.DataControls}>
          {orgDataElements.elements.map((elem: any, esn: number) => {
            var dispDrType = null
            if (curDrType !== elem.drtype) {
              dispDrType = elem.drtype
              curDrType = elem.drtype
            }
            return (
              <div key={'cbn' + esn.toString()}>
                {dispDrType && (
                  <div>
                    <b>{dispDrType}</b>
                  </div>
                )}
                <input
                  id={'cbn' + esn.toString()}
                  type="checkbox"
                  onChange={elementCheckboxChanged}
                  value={elem.drelement}
                />
                {elem.drelement}
              </div>
            )
          })}
          {/* <pre>{JSON.stringify(selectedElements, null, 2)}</pre> */}
        </div>
        {renderResults()}
      </div>
    )
  }
  return <>{renderList()}</>
}
