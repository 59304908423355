// Slicer functions (Good to use because I can change the root in one place)

import { RootState } from '../_store'

export const getAppAccount = (state: RootState) => state.appAccount

// Microsoft
export const getMSTeams = (state: RootState) => state.msTeams
export const getMSTeamsTeam = (state: RootState) => state.msTeamsTeam
export const getMSTeamsChannel = (state: RootState) => state.msTeamsChannel
export const getMSTeamsEvent = (state: RootState) => state.msTeamsEvent

// Inbox
export const getInbox = (state: RootState) => state.inbox
export const getAlert = (state: RootState) => state.alert
export const getAlertIDs = (state: RootState) => state.alertIDs
export const getAlertSearch = (state: RootState) => state.alertSearch

// OrgTag
export const getOrgTag = (state: RootState) => state.orgTag

// Org Ugis Members
export const getOrgUgisMembers = (state: RootState) => state.orgUgisMembers

// App
export const getApp = (state: RootState) => state.app

// WebSocket
export const getWebSocket = (state: RootState) => state.webSocket

// Forms and Reports
export const getAferSubmitIssueForms = (state: RootState) =>
  state.afterSubmitIssueForms
export const getCreateIssueForms = (state: RootState) => state.createIssueForms
export const getCurrentFormContent = (state: RootState) =>
  state.currentFormContent
export const getTeamReport = (state: RootState) => state.teamReport

// Auth
export const getAuth = (state: RootState) => state.auth

// Issue
export const getIssue = (state: RootState) => state.issue
export const getIssueLatestLog = (state: RootState) => state.issueLatestLog
export const getIssueApp = (state: RootState) => state.issueApp
export const getIssueActivityLog = (state: RootState) => state.issueActivityLog
// Issue Level
export const getIssueLevel = (state: RootState) => state.issueLevel

// Workspace
export const getWorkspace = (state: RootState) => state.workspace

// WorkspaceIssue
export const getWorkspaceIssue = (state: RootState) => state.workspaceIssue

// Conference
export const getConference = (state: RootState) => state.conference

// Holding Statement
export const getHoldingStatement = (state: RootState) => state.holdingStatement

// Chat
export const getChat = (state: RootState) => state.chat
export const getChatEntry = (state: RootState) => state.chatEntry
export const getChatRead = (state: RootState) => state.chatRead

// Task
export const getTask = (state: RootState) => state.task
export const getTaskItem = (state: RootState) => state.taskItem
export const getTaskComment = (state: RootState) => state.taskComment
export const getTaskRead = (state: RootState) => state.taskRead

// Request
export const getRequestStatus = (state: RootState) => state.requestStatus
export const getRequestSync = (state: RootState) => state.requestSync

// Artifact (Upload)
export const getArtifact = (state: RootState) => state.artifact

// PortalUser
export const getPortalUser = (state: RootState) => state.portalUser

// Playbooks
export const getPublicPlaybookApp = (state: RootState) =>
  state.publicPlaybookApp
export const getPlaybookApp = (state: RootState) => state.playbookApp
export const getPlaybookList = (state: RootState) => state.playbookList

export const getActivePollList = (state: RootState) => state.activePollList
// Stakeholder
export const getStakeholder = (state: RootState) => state.stakeholder
export const getEvidenceLockerLinks = (state: RootState) =>
  state.evidenceLockerLinks
export const getGeolocations = (state: RootState) => state.geolocations
// Routing
export const getBasePath = (state: RootState) => state.basePath

// Creates a new selector that will pass the state of the specified path using a slicer
export function bindSel<T extends (state: any, ...params: any) => any, R>(
  reducerName: (state: RootState) => R | string,
  sourceSelector: T
) {
  type returnType = ReturnType<typeof sourceSelector>
  return bindSelHelper<returnType, T, R>(reducerName, sourceSelector)
}

export function bindSelRet<T extends (state: any, ...params: any) => any, R>(
  reducerName: (state: RootState) => R | string,
  sourceSelector: T
) {
  return <RETURN>() => bindSelHelper<RETURN, T, R>(reducerName, sourceSelector)
}

export function bindSelHelper<
  RETURN,
  T extends (state: any, ...params: any) => any,
  R
>(reducerName: (state: RootState) => R | string, sourceSelector: T) {
  //type params = Omit<Parameters<typeof sourceSelector>, "state">;

  // Get the parameters from the selector, but remove the local state paramter
  type params = Tail<Parameters<typeof sourceSelector>>
  type returnType = ReturnType<typeof sourceSelector>
  return ((state: RootState, ...args) => {
    let localState
    if (typeof reducerName === 'function') localState = reducerName(state)
    else {
      localState = state[reducerName]

      if (!localState)
        throw new Error('The local state ' + reducerName + ' does not exist')
    }

    return sourceSelector(localState, ...args)
  }) as (
    state: RootState,
    ...params: params
  ) => RETURN extends undefined ? returnType : RETURN
}

type Concat<T> = T extends [infer A, ...infer Rest]
  ? A extends any[]
    ? [...A, ...Concat<Rest>]
    : A
  : T

type Tail<T extends any[]> = ((...x: T) => void) extends (
  h: infer A,
  ...t: infer R
) => void
  ? R
  : never
