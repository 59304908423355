// @ts-nocheck
// TODO: Typescript
import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import Moment from 'react-moment'
import 'moment-timezone'
import { appDateTimeFormat } from '../_constants'
import { getLocalDate } from '../_utils/dateUtils'
import {
  Typography,
  Card,
  CardActions,
  IconButton,
  withMobileDialog,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Input,
} from '@material-ui/core'
import { UploadThumbnail } from './UploadThumbnail'
import cardClasses from '../CustomMaterial/IcoCard.module.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons'
import { getArtifactId } from '../_schema/_schemas'
import { getFilenameExt } from '../_utils/fileUtils'
import { Button } from 'reactstrap'
import { cx } from '../_utils/objectUtils'
import classes from './ArtifactPreview.module.scss'
import { SlideLeft } from '../Shared/Transition'
import { getDriveItemDownloadLink } from '../MicrosoftTeams/GraphService'
import { addNotification } from '../_actions'
import { selectArtifactById, selectArtifactLink } from './_selectors'
import { isArtifactContentLoading } from '../Shared/_requestStatusSelectors'
import { isMobileApp } from '../_selectors'
import {
  selectArtifactMediaType,
  selectArtifactStorageLocationName,
} from '../_rootConfigs/rootSelectors'
import { selectIsMicrosoftTeams } from '../MicrosoftTeams/_selectors'

const ResponsiveDialog = withMobileDialog()(Dialog)
/**
 * @extends {Component<Props, {}>}}
 * Renders an artifact
 */
export class ArtifactPreview extends Component {
  constructor(props) {
    super(props)
    this.handleOnClickThumbnail = this.handleOnClickThumbnail.bind(this)
    this.handleUpdateClick = this.handleUpdateClick.bind(this)
    this.handleCancelClick = this.handleCancelClick.bind(this)
    this.handleEditClick = this.handleEditClick.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleDownloadClick = this.handleDownloadClick.bind(this)

    this.state = {
      showEdit: false,
      ArtifactDescription: '',
      driveItemDownloadLink: '',
      driveItemFailure: '',
    }
  }

  handleInputChange(evt) {
    const { name, value } = evt.target
    this.setState({
      [name]: value,
    })
  }

  handleEditClick() {
    const { ArtifactTitle } = this.props
    this.setState({
      showEdit: true,
      ArtifactDescription: ArtifactTitle,
    })
  }

  handleUpdateClick() {
    const { ArtifactDescription } = this.state
    const { ArtifactID, onUpdate } = this.props
    onUpdate({ ArtifactID, ArtifactDescription })
    this.setState({
      showEdit: false,
      ArtifactDescription: '',
    })
  }

  handleCancelClick() {
    this.setState({
      showEdit: false,
      ArtifactDescription: '',
    })
  }

  handleOnClickThumbnail() {
    const { ArtifactID, onClickThumbnail } = this.props
    if (onClickThumbnail) onClickThumbnail(ArtifactID)
  }

  renderDownloadLink() {
    const { downloadLink, activeDownloadLink, ArtifactName, isMicrosoftTeams } =
      this.props
    const { driveItemDownloadLink } = this.state
    const target = !isMicrosoftTeams ? '_blank' : null
    return downloadLink && activeDownloadLink ? (
      <a
        ref={(el) => (this.downloadEl = el)}
        href={driveItemDownloadLink || downloadLink}
        onClick={this.handleDownloadClick}
        target={target}
        rel="noopener noreferrer"
      >
        {ArtifactName}
      </a>
    ) : (
      <span>{ArtifactName}</span>
    )
  }

  handleDownloadClick(evt) {
    const { ExternalStorage, downloadLink, ArtifactName, onDownloadError } =
      this.props

    if (
      ExternalStorage === 'MicrosoftTeams' &&
      !this.state.driveItemDownloadLink &&
      !this.state.driveItemFailure
    ) {
      evt.preventDefault()
      this.downloadEl.download = ArtifactName
      getDriveItemDownloadLink({ url: downloadLink })
        .then((driveItemDownloadLink) => {
          this.setState({ driveItemDownloadLink }, () =>
            this.downloadEl.click()
          )
        })
        .catch((driveItemFailure) => {
          onDownloadError(driveItemFailure)
          this.setState({ driveItemFailure })
        })
    }
  }

  render() {
    const {
      showCreatedUTC,
      className,
      CreatedUTC,
      mediaType,
      ext,
      ArtifactName,
      ArtifactTitle,
      hideTitle,
      downloadLink,
      Thumbnail,
      ArtifactType,
      isContentLoading,
      canEdit,
      canDelete,
      border,
      ExternalStorage,
      StorageLocationName,
      ThumbnailWidth,
      ThumbnailHeight,
    } = this.props
    /*let { ThumbnailWidth, ThumbnailHeight } = this.props;
        if (ExternalStorage === 'MicrosoftTeams' && mediaType === 'image') {
            ThumbnailWidth = undefined;
            ThumbnailHeight = undefined;
        }*/
    const ownClasses = this.props.classes

    return (
      <Card
        className={cx(
          classes.root,
          !border ? classes.noBorder : '',
          cardClasses.root
        )}
      >
        <Typography
          className={cx(className, canEdit ? classes.bodyCanEdit : '')}
          component="div"
        >
          <div>
            {hideTitle ? null : (
              <div
                className={cx(
                  classes.artifactTitle,
                  ownClasses.artifactTile,
                  'single-line-text'
                )}
              >
                {ArtifactTitle}
              </div>
            )}
            <Typography variant="body2" color="textSecondary" component="div">
              {showCreatedUTC && CreatedUTC ? (
                <Moment
                  local
                  format={appDateTimeFormat}
                  date={getLocalDate(CreatedUTC)}
                />
              ) : null}
              {!ExternalStorage && (
                <div className="single-line-text">In Case of Crisis</div>
              )}
              {ExternalStorage === 'MicrosoftTeams' && (
                <div className="single-line-text">
                  Microsoft Teams{' '}
                  {StorageLocationName ? '(' + StorageLocationName + ')' : ''}
                </div>
              )}
            </Typography>
          </div>
          <div>
            <UploadThumbnail
              onClick={this.handleOnClickThumbnail}
              style={{
                cursor: this.props.onClickThumbnail ? 'pointer' : 'default',
              }}
              content={Thumbnail}
              downloadLink={downloadLink}
              width={ThumbnailWidth}
              height={ThumbnailHeight}
              alt={ArtifactName}
              type={mediaType}
              ext={ext}
              ArtifactType={ArtifactType}
              isContentLoading={isContentLoading}
            />
            <Typography
              className="single-line-text"
              variant="body2"
              color="textSecondary"
              component="div"
            >
              {/*downloadLink ? <Button color="link">{ArtifactName}</Button> : <span>{ArtifactName}</span>*/}
              {this.renderDownloadLink()}
            </Typography>
          </div>
        </Typography>
        {(canEdit || canDelete) && (
          <Fragment>
            <CardActions className={classes.actions}>
              <IconButton onClick={this.handleEditClick}>
                <FontAwesomeIcon icon={faPencilAlt} />
              </IconButton>
            </CardActions>
            <ResponsiveDialog
              open={this.state.showEdit}
              TransitionComponent={SlideLeft}
            >
              <DialogTitle>Update Artifact Description</DialogTitle>
              <DialogContent>
                <Input
                  fullWidth={true}
                  name="ArtifactDescription"
                  type="text"
                  value={this.state.ArtifactDescription}
                  placeholder="Description"
                  onChange={this.handleInputChange}
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={this.handleCancelClick} color="primary">
                  Cancel
                </Button>
                <Button
                  autoFocus
                  onClick={this.handleUpdateClick}
                  color="primary"
                >
                  Update
                </Button>
              </DialogActions>
            </ResponsiveDialog>
          </Fragment>
        )}
      </Card>
    )
  }
}

ArtifactPreview.propTypes = {
  ArtifactID: PropTypes.any,
  ArtifactTitle: PropTypes.string,
  ArtifactDescription: PropTypes.string,
  ArtifactName: PropTypes.string,
  ArtifactType: PropTypes.string,
  mediaType: PropTypes.string,
  ext: PropTypes.string,
  CreatedUTC: PropTypes.instanceOf(Date),
  isContentLoading: PropTypes.bool,
  Thumbnail: PropTypes.any,
  ThumbnailHeight: PropTypes.number,
  ThumbnailWidth: PropTypes.number,
  PxHeight: PropTypes.number,
  showCreatedUTC: PropTypes.bool,
  downloadLink: PropTypes.string,
  onDownloadError: PropTypes.func,
  className: PropTypes.string,
  onClickThumbnail: PropTypes.func,
  hideTitle: PropTypes.bool,
  activeDownloadLink: PropTypes.bool,
  canEdit: PropTypes.bool,
  canDelete: PropTypes.bool,
  border: PropTypes.bool,
  classes: PropTypes.any,
  StorageLocationName: PropTypes.string,
}

ArtifactPreview.defaultProps = {
  showCreatedUTC: true,
  ArtifactType: '',
  downloadLink: '',
  activeDownloadLink: false,
  ArtifactName: '',
  ArtifactTitle: '',
  ArtifactDescription: '',
  canEdit: false,
  canDelete: false,
  border: true,
  onUpdate: () => {},
  classes: {},
  onDownloadError: () => {},
}

const mapStateToProps = (state, ownProps) => {
  const artifact = selectArtifactById(state, ownProps.ArtifactID) || {}
  const {
    CreatedUTC,
    ArtifactTitle,
    ArtifactDescription,
    ArtifactName,
    ArtifactType,
    Thumbnail,
    ThumbnailWidth,
    ThumbnailHeight,
    ExternalStorage,
  } = artifact
  const mediaType = selectArtifactMediaType(state, getArtifactId(artifact))
  return {
    CreatedUTC,
    ArtifactTitle: ArtifactTitle || ArtifactDescription,
    ArtifactName,
    ArtifactType,
    mediaType,
    ext: getFilenameExt(ArtifactName),
    Thumbnail,
    ThumbnailWidth,
    ThumbnailHeight,
    isContentLoading: isArtifactContentLoading(state, ownProps.ArtifactID),
    downloadLink: selectArtifactLink(state, ownProps.ArtifactID),
    activeDownloadLink: !isMobileApp(state),
    isMicrosoftTeams: selectIsMicrosoftTeams(state),
    ExternalStorage,
    StorageLocationName: selectArtifactStorageLocationName(
      state,
      ownProps.ArtifactID
    ),
  }
}

const mapDispatchToProps = {
  onDownloadError: (message) => addNotification({ message, type: 'error' }),
}

const mergeProps = (stateProps, _, ownProps) => ({
  ...stateProps,
  ...ownProps,
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(ArtifactPreview)
