import { removeKeyFromObject } from '../_utils/objectUtils'
import {
  AsyncActionType,
  callAction,
  CrudTypeOptions,
  generateCrudTypes,
  ReducerAction,
  ReducerActionMap,
  reducerCrud,
} from '../_utils/reduxUtils'

//type CrudTypes = ReturnType<typeof generateCrudTypes>;

interface ChildReducer {
  crudTypes: ReturnType<typeof generateCrudTypes>
  foreignKey: string
  parentArray: string
  prepend?: boolean
}

interface EntityReducerOptions<STATE = any> {
  crudTypes: ReturnType<typeof generateCrudTypes>
  children?: ChildReducer[]
  reducer?: (state: STATE, action: any) => ReducerActionMap
  primaryFetchMerge?: boolean
  mergeAdd?: boolean
}

export function createEntityReducer<STATE = any>(
  primaryKey: any,
  options?: EntityReducerOptions<STATE>
) {
  const crudTypes = options?.crudTypes || ({} as CrudTypeOptions)
  const { reducer, children, mergeAdd, primaryFetchMerge } = options || {}

  return (state: STATE, action: ReducerAction) => {
    if (!state) state = {} as any

    let mapped: ReducerActionMap = {}

    // Safely add the crud action types
    addTypeSuccess(crudTypes.INIT, () => action.payload.result || {})
    if (primaryFetchMerge) {
      addTypeSuccess(crudTypes.FETCH, () => ({
        ...state,
        ...(action.payload.result || {}),
      }))
    } else {
      addTypeSuccess(crudTypes.FETCH, () => action.payload.result || {})
    }
    if (mergeAdd)
      addTypeSuccess(crudTypes.ADD, () =>
        reducerCrud.update(state, action, primaryKey)
      )
    else {
      addTypeSuccess(crudTypes.ADD, () => ({
        ...state,
        [action.payload.result[primaryKey]]: action.payload.result,
      }))
    }

    addTypeSuccess(crudTypes.ADD_RANGE, () =>
      reducerCrud.createRange(state, action, primaryKey, action.payload.result)
    )

    addTypeSuccess(crudTypes.UPDATE, () =>
      reducerCrud.update(state, action, primaryKey)
    )
    addTypeSuccess(
      crudTypes.DELETE,
      () =>
        removeKeyFromObject(state, action.payload.result[primaryKey]) as STATE
    )
    addTypeSuccess(crudTypes.DELETE_RANGE, () =>
      reducerCrud.deleteIdRange(state, action.payload.result)
    )
    addTypeSuccess(crudTypes.UPDATE_RANGE, () =>
      reducerCrud.updateRange(state, action, primaryKey)
    )
    addTypeFailure(crudTypes.UPDATE, () =>
      reducerCrud.reset(state, action.payload.result[primaryKey])
    )

    // Add the related entities
    if (children) {
      children.forEach((reducer) => {
        const crudTypes = reducer.crudTypes || ({} as CrudTypeOptions)
        if (reducer.prepend)
          addTypeSuccess(crudTypes.ADD, () =>
            reducerCrud.prependChild(
              state,
              action,
              primaryKey,
              reducer.foreignKey,
              reducer.parentArray
            )
          )
        else
          addTypeSuccess(crudTypes.ADD, () =>
            reducerCrud.addChild(
              state,
              action,
              primaryKey,
              reducer.foreignKey,
              reducer.parentArray
            )
          )

        addTypeSuccess(crudTypes.FETCH, () =>
          reducerCrud.replaceChildren(
            state,
            action,
            primaryKey,
            reducer.parentArray
          )
        )
      })
    }

    function addTypeSuccess(
      type: AsyncActionType | undefined,
      actionFunc: () => STATE
    ) {
      if (type && type.SUCCESS) {
        mapped[type.SUCCESS] = actionFunc
      }
    }

    function addTypeFailure(
      type: AsyncActionType | undefined,
      actionFunc: () => STATE
    ) {
      if (type && type.FAILURE) {
        mapped[type.FAILURE] = actionFunc
      }
    }

    if (reducer) {
      const actionsObj = reducer(state, action)

      for (let key in actionsObj) {
        mapped[key] = actionsObj[key]
      }
    }

    return callAction(mapped, state, action)
  }
}

export const selectEntityIdMap = (state: any) => state
// @ts-ignore
export const selectEntityByNumId = (state: any, entityID: number) =>
  selectEntityIdMap(state)[entityID]
