// @ts-nocheck
// TODO: Typescript
import React, { Component, Fragment } from 'react'
import { Drawer } from '@material-ui/core'
import classes from './IssueFilterAdvancedTrigger.module.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFilter } from '@fortawesome/free-solid-svg-icons'
import IssueFilterAdvanced from './IssueFilterAdvanced'
import { cx } from '../_utils/objectUtils'
import PropTypes from 'prop-types'

/**
 * @extends {Component<Props, {}>}}
 */
export class IssueFilterAdvancedTrigger extends Component {
  constructor(props) {
    super(props)
    this.state = {}
    this.setOpen = this.setOpen.bind(this)
  }

  setOpen(opened) {
    const { disabled } = this.props
    if (!disabled && this.state.opened !== opened) {
      this.setState({
        opened,
      })
    }
  }

  render() {
    const { style, className, disabled } = this.props
    return (
      <Fragment>
        <FontAwesomeIcon
          className={cx(classes.root, className)}
          onClick={() => this.setOpen(true)}
          style={style}
          icon={faFilter}
          title="Issue Filter"
        />
        {!disabled ? (
          <Drawer
            anchor="right"
            open={this.state.opened}
            onClose={() => {
              this.setOpen(false)
            }}
            className={classes.drawer}
          >
            <div className={classes.filterBody}>
              {this.state.opened ? (
                <IssueFilterAdvanced
                  onChange={() => {
                    this.setOpen(false)
                  }}
                />
              ) : null}
            </div>
          </Drawer>
        ) : null}
      </Fragment>
    )
  }
}

IssueFilterAdvanced.propTypes = {
  disabled: PropTypes.bool,
}

IssueFilterAdvancedTrigger.defaultProps = {
  disabled: false,
}
