// @ts-nocheck
// TODO: Typescript
import { WebSocketConstants } from '../_webSocket/_constants'
import { objDatePropsToUTC } from '../_utils/dateUtils'
import { JsonParseSafe } from '../_utils/objectUtils'
import { ArtifactActions } from '../Upload/_actions'
import { ArtifactConstants } from './_constants'

export default function artifactHub(storeAPI, action, hubConnection) {
  switch (action.type) {
    case WebSocketConstants.CONNECTION_RETRY_SUCCESSFUL:
    case WebSocketConstants.CONNECTION_SUCCESSFUL:
      hubConnection.on('BroadcastArtifactAdd', (data, source) => {
        data = JsonParseSafe(data)
        if (!data.ErrorMessage) {
          const artifact = data.returnData
          objDatePropsToUTC(artifact, 'CreatedUTC')
          storeAPI.dispatch(ArtifactActions.addSuccess(artifact, source))
        }
      })

      hubConnection.on('BroadcastArtifactUpdate', (data, source) => {
        data = JsonParseSafe(data)
        if (!data.ErrorMessage) {
          const artifact = data.returnData
          storeAPI.dispatch(ArtifactActions.updateSuccess(artifact, source))
        }
      })
      hubConnection.on('BroadcastArtifactDelete', (data, source) => {
        data = JsonParseSafe(data)
        if (!data.ErrorMessage) {
          const artifact = data.returnData
          storeAPI.dispatch(ArtifactActions.deleteSuccess(artifact, source))
        }
      })
      break
    case ArtifactConstants.UPDATE.REQUEST:
      hubConnection.invoke('SendArtifactUpdate', action.payload.result)
      break
    case ArtifactConstants.DELETE.REQUEST:
      hubConnection.invoke('SendArtifactDelete', action.payload.result)
      break
    default:
      break
  }
}
