// @ts-nocheck
// TODO: Typescript
import { getPostListener, getFetchListener } from '../_utils/sagaUtils'
import { takeLatest, call, put } from 'redux-saga/effects'
import {
  LogoutConstants,
  LoginConstants,
  AuthConstants,
  FETCH_REQUEST_TOKEN,
} from './_constants'
import { icoPost } from '../_utils/fetchUtils'
import {
  loginSuccess,
  loginFailure,
  loginLoading,
  loginSSO,
  authenticated,
  logoutSuccess,
  logoutLoading,
  refreshAuthenticationSuccessful,
  logoutSSO,
  unAuthenticated,
  fetchRequestToken,
} from './_actions'
import { disableMSTeamsAutoLogin } from '../MicrosoftTeams/_actions'

const logoutNonRedirect = getPostListener('/api/CookieAuth/SignOutJson', {
  onLoading: logoutLoading,
  onSuccess: (returnData) => {
    if (returnData && returnData.ssoUrl) return logoutSSO(returnData)
    else return logoutSuccess(returnData)
  },
})

const logout = function* () {
  yield put(disableMSTeamsAutoLogin())
  yield call(logoutNonRedirect)
}

const login = function* (action) {
  try {
    const url = '/api/CookieAuth/SignIn'
    yield put(loginLoading())
    const response = yield call(icoPost, url, action.credentials)
    const json = yield call([response, 'json']) || []
    if (json.Status === 'Success') {
      var returnData = json.returnData || {}
      var ssoUrl = returnData.ssoUrl
      // Do SSO postback
      if (ssoUrl) yield put(loginSSO(action.credentials))
      else if (json.isAuthenticated) {
        // Use normal app authentication
        yield put(loginSuccess(returnData))
        yield put(authenticated())
      } else {
        yield put(loginFailure(json.error))
      }
    } else {
      yield put(loginFailure(json.error))
    }
  } catch (ex) {
    yield put(loginFailure(ex.error))
  }
}

const refresh = getFetchListener('/api/CookieAuth/Refresh', {
  onSuccess: refreshAuthenticationSuccessful,
  onUnAuthenticated: unAuthenticated,
})

const fetchRequestTokenSaga = getFetchListener('/api/CookieAuth/RequestToken', {
  onSuccess: fetchRequestToken.success,
  onLoading: fetchRequestToken.loading,
  onDone: fetchRequestToken.complete,
  onFailure: fetchRequestToken.failure,
})

// Watcher sagas
export const authSagas = [
  takeLatest(LogoutConstants.LOGOUT, logout),
  takeLatest(LoginConstants.LOGIN, login),
  takeLatest(AuthConstants.REFRESH_AUTHENTICATION, refresh),
  takeLatest(FETCH_REQUEST_TOKEN.REQUEST, fetchRequestTokenSaga),
]
