// @ts-nocheck
// TODO: Typescript
import { WebSocketConstants } from '../_webSocket/_constants'
import {
  JsonParseSafe,
  appendNewValues,
  prependNewValues,
  binarySearch,
} from '../_utils/objectUtils'
import {
  EvidenceLockerLinkActions,
  GeolocationActions,
  IssueActions,
  issueFilterFetch,
} from '../Issue/_actions'
import { normalize } from 'normalizr'
import {
  schemaDateFields,
  transformEntities,
  issueListSchema,
  workspaceListSchema,
  transformMappedEntities,
} from '../_schema/_schemas'
import { IssueLevelActions } from '../IssueLevel/_actions'
import {
  ParticipantActions,
  OrgIssueTeamActions,
} from '../Participant/_actions'
import { ConferenceActions } from '../Conference/_actions'
import { ChatActions } from '../Chat/_actions'
import { TaskActions } from '../Task/_actions'
import { TeamReportActions } from '../Form/_actions'
import {
  udpateRangeHoldingStatementsSuccess,
  deleteRangeHoldingStatementsSuccess,
} from '../HoldingStatement/_actions'
import { StakeholderActions } from '../Stakeholder/_actions'
import { ArtifactActions } from '../Upload/_actions'
import { WorkspaceActions } from '../Workspace/_actions'
import { batch } from 'react-redux'
import { FETCH_ISSUE_DETAILS } from '../Issue/_constants'
import { OrgTagActions } from '../Org/_actions'
import {
  selectIssueById,
  selectIssueWorkspaceCount,
  selectWorkspaceIssueId,
  selectEvidenceLockerLinkById,
  selectGeolocationById,
} from '../Issue/_selectors'
import { selectIssueLevelById } from '../IssueLevel/_selectors'
import {
  selectParticipantById,
  selectStakeholderById,
} from '../_rootConfigs/rootSelectors'
import { selectConferenceById } from '../Conference/_selectors'
import { selectChatIdByIssueCollab } from '../Chat/_selectors'
import { selectTaskIdByIssueCollab } from '../Task/_selectors'
import {
  selectTeamReportById,
  selectTeamReportIdByIssueReport,
} from '../Form/_selectors'
import { selectArtifactById } from '../Upload/_selectors'
import { selectWorkspaceById } from '../Workspace/_selectors'
import { logout } from '../Auth/_actions'

// Issue updates that need to be rendered
let queuedIssues = []
let submitDelay = 1000 // How long to wait before submitting
let enableSubmit = true

// Workspaces that need to be rendered
let queuedWorkspaces = {
  byIssue: {},
  all: [],
}
let queuedWorkspaceDeletes = []
let submitWorkspacesDelay = 1000 // How long to wait before submitting workspaces
let enableWorkspacesSubmit = true

export default function externalChangesHub(storeAPI, action, hubConnection) {
  switch (action.type) {
    case WebSocketConstants.CONNECTION_RETRY_SUCCESSFUL:
    case WebSocketConstants.CONNECTION_SUCCESSFUL:
      hubConnection.on('BroadcastNewIssueChanges', (data) => {
        data = JsonParseSafe(data)
        // Call setTimeout once per delay
        if (enableSubmit) {
          enableSubmit = false
          setTimeout(() => {
            // Merge only if there are issues
            if (queuedIssues.length) {
              const issuesToSubmit = queuedIssues.slice(0)
              batch(() => {
                mergeAllChanges(issuesToSubmit)
              })
              queuedIssues = []
            }
            enableSubmit = true // Reenable submits
          }, submitDelay)
        }

        if (data.returnData) {
          // Always null an empty IssueResolved date. This allows redux to update the date when it gets cleared
          if (!data.returnData.IssueResolved)
            data.returnData.IssueResolved = null

          queuedIssues.push(data.returnData)
        }
      })

      hubConnection.on('BroadcastWorkspaceChanges', (data, source) => {
        data = JsonParseSafe(data)

        // Call setTimeout once per delay
        if (enableWorkspacesSubmit) {
          enableWorkspacesSubmit = false
          setTimeout(() => {
            processWorkspaceUpdateQueue()
            processWorkspaceDeleteQueue()
            // Handle the deletions of workspaces

            clearWorkspaceChangesQueue()
            enableWorkspacesSubmit = true // Reenable submits
          }, submitWorkspacesDelay)
        }

        // Queue up the deletions and updates
        if (data.returnData) {
          if (source.isDelete) queueWorkspaceForDelete(data.returnData)
          else queueWorkspaceForUpdate(data.returnData, source.IssueID)
        }
      })

      hubConnection.on('BroadcastFilterChanged', () => {
        storeAPI.dispatch(issueFilterFetch())
      })

      hubConnection.on('BroadcastLogout', () => {
        storeAPI.dispatch(logout())
      })

      break
    /*case IssueConstants.FETCH.SUCCESS:
            batch(() => {
                mergeAllChanges(action.payload.result, ['workspace'], true);
            });
            break;*/
    case FETCH_ISSUE_DETAILS.SUCCESS:
      batch(() => {
        //mergeAllChanges(action.payload.result);
        mergeAllChanges(
          action.payload.result.returnData.Issues,
          undefined,
          true
        )
      })
      break
    default:
      break
  }

  function clearWorkspaceChangesQueue() {
    queuedWorkspaces.byIssue = {}
    queuedWorkspaces.all = []
    queuedWorkspaceDeletes = []
  }

  function queueWorkspaceForUpdate(workspace, issueId) {
    queuedWorkspaces.all.push(workspace)

    if (!queuedWorkspaces.byIssue[issueId])
      queuedWorkspaces.byIssue[issueId] = []

    queuedWorkspaces.byIssue[issueId].push(workspace)
  }

  function processWorkspaceUpdateQueue() {
    // Handle the update of workspaces
    const state = storeAPI.getState()
    // Merge only if there are issue workspaces
    if (queuedWorkspaces.all.length) {
      // Add issues that haven't been added yet
      let issuesToAdd = []
      for (const IssueId in queuedWorkspaces.byIssue) {
        if (!selectIssueById(state, IssueId)) issuesToAdd.push({ IssueId })
      }
      mergeAllIssues(issuesToAdd)

      // Add workspaces to the issues
      batch(() => {
        // Push the workspace ids to the appropriate issues
        for (const IssueId in queuedWorkspaces.byIssue) {
          const workspacesByIssue = queuedWorkspaces.byIssue[IssueId] || []

          // Add workspaces that have not been added yet
          let workspacesToAdded = []
          workspacesByIssue.forEach((wksp) => {
            if (!selectWorkspaceById(state, wksp.IssueTeamWorkspaceID))
              workspacesToAdded.push(wksp.IssueTeamWorkspaceID)
          })

          if (workspacesToAdded.length > 0)
            storeAPI.dispatch(
              WorkspaceActions.addRelatedIdsSuccess(workspacesToAdded, {
                IssueID: IssueId,
              })
            )
        }

        // Update all of the workspaces
        if (queuedWorkspaces.all.length > 0) {
          const normalizedData = normalize(
            queuedWorkspaces.all,
            workspaceListSchema
          )
          const mappedEntities = normalizedData.entities

          transformMappedEntities(mappedEntities)

          normalizedData.result.forEach((workspaceId) => {
            mergeWorkspace(mappedEntities.wksp[workspaceId])
          })

          storeAPI.dispatch(
            WorkspaceActions.updateRangeSuccess(mappedEntities.wksp)
          )
          dispatchWorkspaceRelated(mappedEntities)
        }
      })
    }
  }

  function queueWorkspaceForDelete(workspaceDeleteInfo) {
    queuedWorkspaceDeletes.push(workspaceDeleteInfo)
  }

  let isDeleteByIssue = new Set()
  isDeleteByIssue.add('IssueLevel')
  isDeleteByIssue.add('IssueStakeholders')
  isDeleteByIssue.add('HoldingStatement')
  isDeleteByIssue.add('IssueTeamWorkspace')
  isDeleteByIssue.add('IssueIncidentReport')
  function processWorkspaceDeleteQueue() {
    const state = storeAPI.getState()
    let idCollectionByIssue = {}
    let idCollectionByWorkspace = {}

    // Group all ids by entity and issue
    queuedWorkspaceDeletes.forEach((curWorkspaceInfo) => {
      const deletedEntities = curWorkspaceInfo.deletedEntities || []
      const issueId = selectWorkspaceIssueId(
        state,
        curWorkspaceInfo.IssueTeamWorkspaceID
      )

      deletedEntities.forEach((curDelEntity) => {
        if (isDeleteByIssue.has(curDelEntity.entity)) {
          if (!idCollectionByIssue[issueId]) {
            // check for dups - Because multiple workspaces contain the same change
            let issueEntitySet = {
              IssueLevel: new Set(),
              IssueStakeholders: new Set(),
              HoldingStatement: new Set(),
              IssueTeamWorkspace: new Set(),
              IssueIncidentReport: new Set(),
            }

            let issueEntityIds = {
              IssueLevel: [],
              IssueStakeholders: [],
              HoldingStatement: [],
              IssueTeamWorkspace: [],
              IssueIncidentReport: [],
            }

            idCollectionByIssue[issueId] = {
              isDuplicateId: function (entityName, primaryKey) {
                if (!issueEntitySet[entityName]) return true
                return issueEntitySet[entityName].has(primaryKey)
              },
              addEntityId: function (entityName, primaryKey) {
                if (!this.isDuplicateId(entityName, primaryKey)) {
                  issueEntitySet[entityName].add(primaryKey)
                  issueEntityIds[entityName].push(primaryKey)
                }
              },
              dispatchDeletes: function () {
                const source = {
                  IssueID: issueId,
                }
                if (issueEntityIds.IssueLevel.length > 0)
                  storeAPI.dispatch(
                    IssueLevelActions.deleteRangeSuccess(
                      issueEntityIds.IssueLevel,
                      source
                    )
                  )
                if (issueEntityIds.IssueStakeholders.length > 0)
                  storeAPI.dispatch(
                    StakeholderActions.deleteRangeSuccess(
                      issueEntityIds.IssueStakeholders,
                      source
                    )
                  )
                if (issueEntityIds.IssueTeamWorkspace.length > 0)
                  storeAPI.dispatch(
                    WorkspaceActions.deleteRangeSuccess(
                      issueEntityIds.IssueTeamWorkspace,
                      source
                    )
                  )
                if (issueEntityIds.HoldingStatement.length > 0)
                  storeAPI.dispatch(
                    deleteRangeHoldingStatementsSuccess(
                      issueEntityIds.HoldingStatement,
                      source
                    )
                  )

                // Team Reports
                if (issueEntityIds.IssueIncidentReport.length > 0) {
                  // Transform the issue report ids to team report ids
                  let teamReportIds = issueEntityIds.IssueIncidentReport.map(
                    (reportId) =>
                      selectTeamReportIdByIssueReport(state, reportId)
                  )
                  storeAPI.dispatch(
                    TeamReportActions.deleteRangeSuccess(teamReportIds, source)
                  )
                }
              },
            }
          }

          idCollectionByIssue[issueId].addEntityId(
            curDelEntity.entity,
            curDelEntity.PkValue
          )
        } else {
          // Workspace is the parent
          if (!idCollectionByWorkspace[curWorkspaceInfo.IssueTeamWorkspaceID]) {
            let workspaceEntityIds = {
              IssueConference: [],
              IssueArtifact: [],
              IssueCollaboration: [],
              IssueParticipant: [],
            }

            idCollectionByWorkspace[curWorkspaceInfo.IssueTeamWorkspaceID] = {
              addEntityId: function (entityName, primaryKey) {
                if (workspaceEntityIds[entityName])
                  workspaceEntityIds[entityName].push(primaryKey)
              },
              dispatchDeletes: function () {
                const source = {
                  IssueTeamWorkspaceID: curWorkspaceInfo.IssueTeamWorkspaceID,
                }
                // Conference
                if (workspaceEntityIds.IssueConference.length > 0)
                  storeAPI.dispatch(
                    ConferenceActions.deleteRangeSuccess(
                      workspaceEntityIds.IssueConference,
                      source
                    )
                  )

                // Artifacts
                if (workspaceEntityIds.IssueArtifact.length > 0)
                  storeAPI.dispatch(
                    ArtifactActions.deleteRangeSuccess(
                      workspaceEntityIds.IssueArtifact.map(
                        (id) => 'Artifact' + id
                      ),
                      source
                    )
                  )

                // Chat and Task
                if (workspaceEntityIds.IssueCollaboration.length > 0) {
                  let allChatIds = []
                  let allTaskIds = []

                  workspaceEntityIds.IssueCollaboration.forEach((collabId) => {
                    const chatId = selectChatIdByIssueCollab(state, collabId)
                    if (chatId !== undefined) allChatIds.push(chatId)
                    else {
                      const taskId = selectTaskIdByIssueCollab(state, collabId)
                      if (taskId !== undefined) allTaskIds.push(taskId)
                    }
                  })

                  if (allChatIds.length > 0)
                    storeAPI.dispatch(
                      ChatActions.deleteRangeSuccess(allChatIds, source)
                    )
                  if (allTaskIds.length > 0)
                    storeAPI.dispatch(
                      TaskActions.deleteRangeSuccess(allTaskIds, source)
                    )
                }

                // Participant
                if (workspaceEntityIds.IssueParticipant.length > 0)
                  storeAPI.dispatch(
                    ParticipantActions.deleteRangeSuccess(
                      workspaceEntityIds.IssueParticipant,
                      source
                    )
                  )
              },
            }
          }

          idCollectionByWorkspace[
            curWorkspaceInfo.IssueTeamWorkspaceID
          ].addEntityId(curDelEntity.entity, curDelEntity.PkValue)
        }
      })
    })

    // Send all of the deletes
    batch(() => {
      for (let workspaceId in idCollectionByWorkspace)
        idCollectionByWorkspace[workspaceId].dispatchDeletes()
    })

    batch(() => {
      for (let issueId in idCollectionByIssue)
        idCollectionByIssue[issueId].dispatchDeletes()
    })

    // Remove the issue if it doesn't have anymore workspaces
    let issuesToDelete = []
    for (let issueId in idCollectionByIssue) {
      const workspaceCount = selectIssueWorkspaceCount(state, issueId)
      if (workspaceCount === 0) issuesToDelete.push(issueId)
    }

    if (issuesToDelete.length > 0) {
      batch(() => {
        storeAPI.dispatch(IssueActions.deleteRangeSuccess(issuesToDelete))
      })
    }
  }

  function mergeAllChanges(issues, excludeMerge, replaceAll: boolean = false) {
    excludeMerge = excludeMerge || []
    if (issues && issues.length) {
      const normalizedData = normalize(issues, issueListSchema)
      const mappedEntities = normalizedData.entities
      const state = storeAPI.getState()
      // Tranform the date field strings into date objects
      transformEntities(mappedEntities.returnData, ...schemaDateFields.Issue)
      transformMappedEntities(mappedEntities)

      // Merge all chilren of the issue
      normalizedData.result.forEach((updatedIssueId) => {
        const newIssue = mappedEntities.returnData[updatedIssueId]
        const oldIssue = selectIssueById(state, updatedIssueId) || {}

        // Add the new child ids to the existing list of child ids
        newIssue.IssueLevel = replaceAll
          ? newIssue.IssueLevel
          : prependNewValues(
              oldIssue.IssueLevel,
              newIssue.IssueLevel,
              (id) => !selectIssueLevelById(state, id)
            )
        newIssue.IssueLevel?.sort((a, b) => b - a)
        //newIssue.HoldingStmt = prependNewValues(oldIssue.HoldingStmt, newIssue.HoldingStmt, id => !selectHoldingStatementById(state, id));
        newIssue.Stakeholders = replaceAll
          ? newIssue.Stakeholders
          : appendNewValues(
              oldIssue.Stakeholders,
              newIssue.Stakeholders,
              (id) => !selectStakeholderById(state, id)
            )
        newIssue.TeamReports = replaceAll
          ? newIssue.TeamReports
          : prependNewValues(
              oldIssue.TeamReports,
              newIssue.TeamReports,
              (id) => !selectTeamReportById(state, id)
            )
        newIssue.EvidenceLockerLinks = replaceAll
          ? newIssue.EvidenceLockerLinks
          : appendNewValues(
              oldIssue.EvidenceLockerLinks,
              newIssue.EvidenceLockerLinks,
              (id) => !selectEvidenceLockerLinkById(state, id)
            )
        newIssue.Geolocations = replaceAll
          ? newIssue.Geolocations
          : appendNewValues(
              oldIssue.Geolocations,
              newIssue.Geolocations,
              (id) => !selectGeolocationById(state, id)
            )
        const newWorkspaceIds = newIssue.wksp // Save the workspaces before it is merged with the old
        if (excludeMerge.indexOf('workspace') === -1) {
          newIssue.wksp = appendNewValues(
            oldIssue.wksp,
            newIssue.wksp,
            (id) => (oldIssue.wksp || []).indexOf(id) === -1
          )

          // Merge all children for each workspace
          ;(newWorkspaceIds || []).forEach((workspaceId) => {
            mergeWorkspace(mappedEntities.wksp[workspaceId])
          })
        }
      })

      // Dispatch all of the new issues after the list of child ids have been updated from above
      mergeAllIssues(mappedEntities.returnData)

      // Update all related entities in bulk
      // Issue Related
      if (mappedEntities.IssueLevel)
        storeAPI.dispatch(
          IssueLevelActions.updateRangeSuccess(mappedEntities.IssueLevel)
        )
      if (mappedEntities.HoldingStmt)
        storeAPI.dispatch(
          udpateRangeHoldingStatementsSuccess(mappedEntities.HoldingStmt)
        )
      if (mappedEntities.Stakeholders)
        storeAPI.dispatch(
          StakeholderActions.updateRangeSuccess(mappedEntities.Stakeholders)
        )
      if (mappedEntities.EvidenceLockerLinks)
        storeAPI.dispatch(
          EvidenceLockerLinkActions.updateRangeSuccess(
            mappedEntities.EvidenceLockerLinks
          )
        )
      if (mappedEntities.Geolocations)
        storeAPI.dispatch(
          GeolocationActions.updateRangeSuccess(mappedEntities.Geolocations)
        )
      if (mappedEntities.TeamReports)
        storeAPI.dispatch(
          TeamReportActions.updateRangeSuccess(mappedEntities.TeamReports)
        )
      if (mappedEntities.wksp) {
        if (excludeMerge.indexOf('workspace') === -1)
          storeAPI.dispatch(
            WorkspaceActions.updateRangeSuccess(mappedEntities.wksp)
          )
        else
          storeAPI.dispatch(WorkspaceActions.initSuccess(mappedEntities.wksp))
      }
      if (mappedEntities.OrgTag)
        storeAPI.dispatch(
          OrgTagActions.updateRangeSuccess(mappedEntities.OrgTag)
        )

      // Workspace Related
      dispatchWorkspaceRelated(mappedEntities)
    }
  }

  function mergeAllIssues(issues) {
    storeAPI.dispatch(IssueActions.updateRangeSuccess(issues))
  }

  function dispatchWorkspaceRelated(mappedEntities) {
    if (mappedEntities.ConfSched)
      storeAPI.dispatch(
        ConferenceActions.updateRangeSuccess(mappedEntities.ConfSched)
      )
    if (mappedEntities.Artifacts)
      storeAPI.dispatch(
        ArtifactActions.updateRangeSuccess(mappedEntities.Artifacts)
      )
    if (mappedEntities.CollabChat)
      storeAPI.dispatch(
        ChatActions.updateRangeSuccess(mappedEntities.CollabChat)
      )
    if (mappedEntities.CollabTask)
      storeAPI.dispatch(
        TaskActions.updateRangeSuccess(mappedEntities.CollabTask)
      )
    if (mappedEntities.Participants)
      storeAPI.dispatch(
        ParticipantActions.updateRangeSuccess(mappedEntities.Participants)
      )
    if (mappedEntities.OrgIssueTeams)
      storeAPI.dispatch(
        OrgIssueTeamActions.addRangeSuccess(mappedEntities.OrgIssueTeams)
      )
  }

  function mergeWorkspace(newWorkspace, oldWorkspace) {
    const state = storeAPI.getState()
    const { IssueTeamWorkspaceID } = newWorkspace

    // use the state as a default oldWorkspace if an oldWorkspace isn't passed
    if (!oldWorkspace) {
      oldWorkspace = selectWorkspaceById(state, IssueTeamWorkspaceID) || {}
    }

    // Add the new child ids to the existing list of child ids
    /*newWorkspace.Artifacts = appendNewValues(oldWorkspace.Artifacts, newWorkspace.Artifacts, id => !selectArtifactById(state, id));
        newWorkspace.CollabChat = appendNewValues(oldWorkspace.CollabChat, newWorkspace.CollabChat, id => !selectChatById(state, id));
        newWorkspace.CollabTask = appendNewValues(oldWorkspace.CollabTask, newWorkspace.CollabTask, id => !selectTaskById(state, id));
        newWorkspace.Participants = appendNewValues(oldWorkspace.Participants, newWorkspace.Participants, id => !selectParticipantById(state, id));
        newWorkspace.ConfSched = appendNewValues(oldWorkspace.ConfSched, newWorkspace.ConfSched, id => !selectConferenceById(state, id));*/

    newWorkspace.Artifacts = appendNewValues(
      oldWorkspace.Artifacts,
      newWorkspace.Artifacts,
      (id) => !selectArtifactById(state, id)
    )
    newWorkspace.CollabChat = appendNewValues(
      oldWorkspace.CollabChat,
      newWorkspace.CollabChat,
      (id) => !binarySearch(oldWorkspace.CollabChat, id)
    )
    newWorkspace.CollabTask = appendNewValues(
      oldWorkspace.CollabTask,
      newWorkspace.CollabTask,
      (id) => !binarySearch(oldWorkspace.CollabTask, id)
    )
    newWorkspace.Participants = appendNewValues(
      oldWorkspace.Participants,
      newWorkspace.Participants,
      (id) => !selectParticipantById(state, id)
    )
    newWorkspace.ConfSched = appendNewValues(
      oldWorkspace.ConfSched,
      newWorkspace.ConfSched,
      (id) => !selectConferenceById(state, id)
    )

    // Merge the existing reports
    /* Can't properly track blank reports by workspace. Blank reports
     * should be by org issue team
     */
    /*if (oldWorkspace.IssueTeamWorkspaceID !== undefined) {
            const oldReportsMap = {};
            const oldReports = oldWorkspace.BlankReports || [];
            oldReports.forEach(report => {
                oldReportsMap[IssueTeamWorkspaceID + '-' + report.TeamReportSpecID] = report;
            });

            const resultReports = [];
            (newWorkspace.BlankReports || []).forEach(report => {
                const oldReport = oldReportsMap[IssueTeamWorkspaceID + '-' + report.TeamReportSpecID];
                resultReports.push({
                    ...oldReport,
                    ...report
                })
            });
            newWorkspace.BlankReports = resultReports;
        }*/
  }
}
