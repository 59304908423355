// @ts-nocheck
// TODO: Typescript
import { generateTypes } from '../_utils/reduxUtils'

export const HoldingStatementConstants = {
  APPROVE: generateTypes('HOLDING_STATEMENT', 'APPROVE'),
  UPDATE: generateTypes('HOLDING_STATEMENT', 'UPDATE'),
  FETCH: generateTypes('HOLDING_STATEMENT', 'FETCH'),
}

export const ADD_RELATED_HOLDING_STATEMENTS = generateTypes(
  'HOLDING_STATEMENT',
  'ADD_RELATED'
)
export const UPDATE_RANGE_HOLDING_STATEMENTS = generateTypes(
  'HOLDING_STATEMENT',
  'UPDATE_RANGE'
)
export const DELETE_RANGE_HOLDING_STATEMENTS = generateTypes(
  'HOLDING_STATEMENT',
  'DELETE_RANGE'
)
