// @ts-nocheck
// TODO: Typescript
import { getFetchListener } from '../_utils/sagaUtils'
import { FETCH_ACTIVE_POLL_LIST } from './_constants'
import { takeEvery } from 'redux-saga/effects'
import { fetchActivePollList } from './_actions'

const fetchActivePollListSaga = getFetchListener('api/playbook/ActivePolls', {
  formatData: false,
  onLoading: fetchActivePollList.loading,
  onSuccess: (result, params, source) => {
    let jsonResult = JSON.parse(result)
    return {
      type: FETCH_ACTIVE_POLL_LIST.SUCCESS,
      payload: jsonResult,
    }
  },
  onFailure: fetchActivePollList.failure,
})

export const activePollSagas = [
  takeEvery(FETCH_ACTIVE_POLL_LIST.REQUEST, fetchActivePollListSaga),
]
