// @ts-nocheck
// TODO: Typescript
import {
  generateCrudTypes,
  generateTypes,
  prependAppName,
} from '../_utils/reduxUtils'

export const ParticipantConstants = generateCrudTypes('PARTICIPANT')
export const FETCH_PARTICIPANTS_STATUS = generateTypes(
  'PARTICIPANT',
  'FETCH_STATUS'
)
export const MARK_PARTICIPANT_AS_ONLINE = prependAppName(
  'MARK_PARTICIPANT_AS_ONLINE'
)
export const MARK_PARTICIPANT_AS_OFFLINE = prependAppName(
  'MARK_PARTICIPANT_AS_OFFLINE'
)
export const OrgIssueTeamConstants = generateCrudTypes('ORG_ISSUE_TEAM')
export const ParticipantTeamConstants = generateCrudTypes('PARTICIPANT_TEAM')
