// @ts-nocheck
// TODO: Typescript
import { schema } from 'normalizr'

export const msChannelSchema = new schema.Entity(
  'MSChannels',
  {},
  { idAttribute: 'Id' }
)
export const msChannelListSchema = new schema.Array(msChannelSchema)

export const msTeamSchema = new schema.Entity(
  'MSTeams',
  {
    Channels: msChannelListSchema,
  },
  { idAttribute: 'Id' }
)
export const msTeamListSchema = new schema.Array(msTeamSchema)

export const msEventsSchema = new schema.Entity(
  'MSEvents',
  {},
  { idAttribute: 'id' }
)
export const msEventsListSchema = new schema.Array(msEventsSchema)
