import * as React from 'react'
import { Component, CSSProperties, MouseEvent } from 'react'
import { Typography } from '@material-ui/core'
import Moment from 'react-moment'
import { cx } from '../_utils/objectUtils'
import { MultiLineText } from '../Shared/MultiLineText'
import { appDateTimeFormat } from '../_constants'
import { sanitizeHTML } from '../_utils/domUtils'
import { getLocalDate } from '../_utils/dateUtils'
import classes from './IssueSummaryHeader.module.scss'
import OrgTagContainer from '../Org/OrgTagContainer'
import { Spacer } from '../Shared/Spacer'

const noRestrictionLineNameStyle: CSSProperties = { wordBreak: 'break-word' }
const multiLineNameStyle = { display: 'block' }

interface Props {
  IssueName: string
  issueNameLines: number
  showTags?: boolean
  tagIds?: number[]
  compact?: boolean
  IssueTypeDescr?: string
  IssueTypeRGB?: string
  showIssueLevel?: boolean
  titleSize?: number
  titleNoWrap?: boolean
  titleColor?: string
  Description?: string
  descriptionLines: number
  LevelName?: string
  LevelRGB?: string
  IssueCreated?: Date
  onClick?(event: MouseEvent): void
  className?: string
  lastActivityDate?: Date
  IssueTrendName?: string
  IssueTrendColor?: string
  showDescription?: boolean
  titleWeight?: string
  ResponsibleName?: string
  showResponsibleName?: boolean
}

/**
 * @extends {Component<Props, {}>}}
 */
class IssueHeaderSummary extends Component<Props> {
  static defaultProps = {
    showIssueLevel: true,
    descriptionLines: 3,
    issueNameLines: 0,
    IssueName: '',
    IssueTrendName: '',
    Description: '',
    showDescription: true,
    showTags: false,
    tagIds: [],
    ResponsibleName: '',
    showResponsibleName: false,
  }

  renderIssueTitle() {
    const { IssueName, issueNameLines } = this.props

    if (IssueName.trim().length === 0) return <br />

    if (!issueNameLines)
      return (
        <span className={classes.issueName} style={noRestrictionLineNameStyle}>
          {IssueName}
        </span>
      )

    return (
      <MultiLineText
        className={classes.issueName}
        style={multiLineNameStyle}
        lines={issueNameLines}
      >
        {IssueName}
      </MultiLineText>
    )
  }

  render() {
    const props = this.props
    const {
      showTags,
      tagIds,
      compact,
      IssueTypeDescr,
      IssueTypeRGB,
      showIssueLevel,
      titleSize,
      titleWeight,
      titleNoWrap,
      titleColor,
      showResponsibleName,
      Description,
      descriptionLines,
      showDescription,
      ResponsibleName,
      LevelName,
      LevelRGB,
      IssueCreated,
      onClick,
      className,
      lastActivityDate,
      IssueTrendName,
      IssueTrendColor,
    } = props

    const titleStyles: any = {
      fontSize: titleSize,
      fontWeight: titleWeight || 'bold',
      color: titleColor,
    }
    const noteStyles: any = {
      fontSize: '.8rem',
      fontWeight: titleWeight || 'bold',
      color: titleColor,
    }

    if (titleNoWrap) titleStyles.whiteSpace = 'nowrap'

    if (compact) {
      titleStyles.whiteSpace = 'nowrap'
      titleStyles.textOverflow = 'ellipsis'
      //titleStyles.paddingRight = 0;
    }

    /*if (!showIssueLevel)
            titleStyles.paddingRight = 0;*/

    return (
      <div onClick={onClick} className={className}>
        <Typography component="div" className={classes.header}>
          <div className={cx(classes.title)} style={titleStyles}>
            {this.renderIssueTitle()}
            <Spacer spaceParam={'small'} />
            {showIssueLevel ? (
              <span
                className={cx(classes.level, 'single-line-text')}
                style={{
                  borderColor: LevelRGB,
                }}
              >
                {LevelName || ''}
              </span>
            ) : null}
          </div>
          <Typography
            className={cx(classes.dateTime, 'd-flex flex-wrap')}
            variant="body2"
            color="textSecondary"
            component="p"
          >
            <Moment
              local
              format={appDateTimeFormat}
              date={getLocalDate(IssueCreated)}
              style={{ marginRight: 15 }}
            />
            <Moment local fromNow>
              {getLocalDate(IssueCreated)}
            </Moment>
          </Typography>
          {!Description || !showDescription ? null : (
            <MultiLineText
              className={classes.description}
              style={{ display: 'block' }}
              lines={descriptionLines}
              dangerouslySetInnerHTML={sanitizeHTML(Description, {
                allowedTags: ['p', 'br', 'u', 's', 'ol', 'ul', 'li'],
              })}
            />
          )}
          <div className="single-line-text" style={{ color: IssueTrendColor }}>
            {IssueTrendName}
          </div>
          <div className="clearfix">
            <div className={classes.lastActivity}>
              <span style={noteStyles}>Activity: </span>
              <Moment className={classes.dateTime} local fromNow>
                {getLocalDate(lastActivityDate)}
              </Moment>
            </div>
            <span className={classes.typeDescr} style={{ color: IssueTypeRGB }}>
              {IssueTypeDescr || ''}
            </span>
          </div>
          {showResponsibleName && (
            <div className={classes.responsible}>
              <label className="mr-1">Responsible:</label>
              <span>{ResponsibleName}</span>
            </div>
          )}
          {showTags && <OrgTagContainer className="mt-2" tagIds={tagIds} />}
        </Typography>
      </div>
    )
  }
}

export default IssueHeaderSummary
