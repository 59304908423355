import * as React from 'react'
import { http } from '../_utils/http'
import { CircularProgress } from '../Shared/LoadingBackdrop'
import classes from './AdminConsolePage.module.scss'
import { faDesktop } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Tooltip,
} from '@material-ui/core'
import { Table } from 'reactstrap'
import { StyledTableRow } from './AdminConsolePage'
import { AdminConsoleDialog } from '../Shared/AdminConsoleDialog'
import { Button } from '../Shared/Buttons'
import { useAppDispatch } from '../_utils/reactReduxHooks'
import { deleteInbox } from '../Alert/_actions'
import { faQuestionCircle } from '@fortawesome/pro-duotone-svg-icons'
import { cx } from '../_utils/objectUtils'

interface Props {
  OrgID: string
}

interface AlertInbox {
  InboxID: number
  inboxGUID: string
  IsActiveYN: string
  inboxName: string
  alert_count: number
  issue_count: number
}

export const AdminConsoleDeleteInbox = ({ OrgID }: Props) => {
  const [alertInboxListing, setAlertInboxListing] = React.useState<any>('')
  const [deleteConfirmDialogOpen, setDeleteConfirmDialogOpen] =
    React.useState(false)
  const [doomedInbox, setDoomedInbox] = React.useState<AlertInbox>()
  const dispatch = useAppDispatch()

  function resetState() {
    setAlertInboxListing('')
    setDeleteConfirmDialogOpen(false)
    setDoomedInbox(undefined)
  }
  const getAlertInboxListing = React.useCallback(async (): Promise<any> => {
    var customHeaders: any = []

    customHeaders.push({ name: 'orgid', value: `${OrgID}` })

    const result = await http<any>({
      path: '/Issue/AlertInboxListing',
      headers: customHeaders,
    })
    if (result.ok && result.body) {
      setAlertInboxListing(result.body)
    } else {
      setAlertInboxListing('')
    }
  }, [OrgID])

  React.useEffect(() => {
    getAlertInboxListing()
  }, [getAlertInboxListing])

  function deleteInboxClicked(doomedInbox: AlertInbox) {
    setDoomedInbox(doomedInbox)
    setDeleteConfirmDialogOpen(true)
  }

  const performDeleteAlertInbox = async (): Promise<any> => {
    if (!doomedInbox) return
    if (doomedInbox?.InboxID < 1) return
    const inboxGUID = doomedInbox?.inboxGUID

    var customHeaders: any = []

    customHeaders.push({ name: 'orgid', value: `${OrgID}` })
    customHeaders.push({ name: 'inboxid', value: `${doomedInbox?.InboxID}` })

    const result = await http<any>({
      path: '/Issue/AlertInboxDelete',
      headers: customHeaders,
    })

    if (!result.ok) {
      console.log('Error: ' + JSON.stringify(result))
    }
    setDoomedInbox(undefined)

    if (result.ok) {
      resetState()
      getAlertInboxListing()
      dispatch(deleteInbox.success(inboxGUID))
    }
  }

  function deleteThisInbox(doomedInbox: AlertInbox) {
    if (doomedInbox)
      if (doomedInbox?.InboxID > 0) {
        performDeleteAlertInbox()
      }
  }

  function renderAlertInboxTableRows() {
    if (!alertInboxListing.alertInboxListing) {
      return <i>no Alert Inboxes found</i>
    }
    return (
      <>
        <div className={classes.OrganizationManagementSection}>
          <div className={classes.OrganizationManagementSectionHeader}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                marginBottom: '10px',
              }}
            >
              <FontAwesomeIcon icon={faDesktop} size={'2x'} />{' '}
              &nbsp;&nbsp;&nbsp;&nbsp; <div>DELETE ALERT INBOXES</div>
            </div>
          </div>
          <Table sx={{ minWidth: 300 }} aria-label="custom table">
            <colgroup>
              <col style={{ width: '15%' }} />
              <col style={{ width: '40%' }} />
              <col style={{ width: '15%' }} />
              <col style={{ width: '15%' }} />
              <col style={{ width: '15%' }} />
            </colgroup>
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell>Inbox Name</TableCell>
                <TableCell align="center">Active</TableCell>
                <TableCell align="right">
                  <span style={{ position: 'relative' }}>
                    Stored Alerts
                    <Tooltip
                      placement="top"
                      title={`Alerts that have been pinned, shared, or used to create or update an Issue.`}
                    >
                      <span
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          position: 'absolute',
                          right: '-15px',
                          top: '1px',
                        }}
                      >
                        <FontAwesomeIcon
                          id="teamIcon"
                          className={cx(classes.rdsBlue)}
                          style={{
                            verticalAlign: 'textTop',
                          }}
                          icon={faQuestionCircle}
                        />
                      </span>
                    </Tooltip>
                  </span>
                </TableCell>
                <TableCell align="right">Issues</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {alertInboxListing.alertInboxListing?.map(
                (alertInbox: AlertInbox, orpIdx: number) => {
                  return (
                    <StyledTableRow key={alertInbox.InboxID}>
                      <TableCell>
                        {' '}
                        <div className={classes.OrganizationTagButtonContainer}>
                          <Button
                            color="danger"
                            onClick={() => deleteInboxClicked(alertInbox)}
                          >
                            Delete
                          </Button>
                        </div>
                      </TableCell>
                      <TableCell>{alertInbox.inboxName}</TableCell>
                      <TableCell align="center">
                        {alertInbox.IsActiveYN}
                      </TableCell>

                      <TableCell align="right">
                        {alertInbox.alert_count}
                      </TableCell>
                      <TableCell align="right">
                        {alertInbox.issue_count}
                      </TableCell>
                    </StyledTableRow>
                  )
                }
              )}
            </TableBody>
          </Table>
        </div>
      </>
    )
  }

  function renderInboxList() {
    if (!alertInboxListing) return <CircularProgress />

    if (!alertInboxListing.status) return <CircularProgress />

    if (alertInboxListing.status !== 'success')
      return <>{JSON.stringify(alertInboxListing, null, 2)}</>

    return (
      <>
        <hr />
        {renderAlertInboxTableRows()}
        <AdminConsoleDialog
          header={<h2>Permanently Delete</h2>}
          open={deleteConfirmDialogOpen}
          content={
            'Are you sure you would like to delete this Inbox?  This will also delete all Alerts within this Inbox.  This is permanant and CAN NOT be undone.'
          }
          onConfirm={() => {
            deleteThisInbox(doomedInbox!)
            setDeleteConfirmDialogOpen(false)
          }}
          consoleRole="destructive"
          onCancel={() => {
            setDoomedInbox(undefined)
            setDeleteConfirmDialogOpen(false)
          }}
        />
      </>
    )
  }
  return <>{renderInboxList()}</>
}
