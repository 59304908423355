import { ListItem, ListItemText } from '@material-ui/core'
import React, { CSSProperties } from 'react'
import Moment from 'react-moment'
import { appDateTimeFormat } from '../_constants'
import { HoldingStatementTemplateModel } from './_models'

// @ts-ignore
import { AutoSizer, List } from 'react-virtualized'

const itemStyle: CSSProperties = {
  cursor: 'pointer',
  marginTop: 2,
}

interface ListProps {
  OrgID: number
  onItemClick?: (template: HoldingStatementTemplateModel) => void
  items?: HoldingStatementTemplateModel[]
  selected?: number
  className?: string
  rowSize?: number
}

export const HoldingStatementTemplateList = ({
  OrgID,
  onItemClick,
  items = [],
  selected,
  className,
  rowSize = 79,
}: ListProps) => {
  /*const response = useFetchTemplate(OrgID);

    if (response.loading)
        return <div>loading</div>;*/

  /*return <List>
        {items.map(template => <HoldingStatementTemplateItem selected={selected === template.HoldingStatementTemplateID} key={template.HoldingStatementTemplateID} onClick={onItemClick} {...template} />)}
    </List>;*/

  function renderItem(context: any) {
    const { style, index } = context
    const template = items[index]

    return (
      <HoldingStatementTemplateItem
        selected={selected === template.HoldingStatementTemplateID}
        key={template.HoldingStatementTemplateID}
        onClick={onItemClick}
        style={style}
        {...template}
      />
    )
  }

  return (
    <AutoSizer className={className}>
      {({ height, width }: { height: number; width: number }) => (
        <List
          width={width}
          height={height}
          rowGetter={({ index }: { index: number }) => items[index]}
          rowCount={items.length}
          rowHeight={rowSize}
          rowRenderer={renderItem}
        />
      )}
    </AutoSizer>
  )
}

interface ItemProps extends HoldingStatementTemplateModel {
  onClick?: (template: HoldingStatementTemplateModel) => void
  selected?: boolean
  style?: CSSProperties
}

export const HoldingStatementTemplateItem = ({
  Title,
  Updated,
  Updater,
  Created,
  Creator,
  CreatorName,
  CreatorEmail,
  OrgID,
  HoldingStatementTemplateID,
  style,
  UpdaterName,
  UpdaterEmail,
  onClick,
  selected,
}: ItemProps) => {
  function handleClick() {
    if (!onClick) return

    onClick({
      HoldingStatementTemplateID,
      OrgID,
      Title,
      Updated,
      Created,
      Updater,
      UpdaterEmail,
      UpdaterName,
      Creator,
      CreatorName,
      CreatorEmail,
    })
  }

  return (
    <ListItem
      style={{ ...itemStyle, ...style }}
      onClick={handleClick}
      selected={selected}
    >
      <ListItemText
        primary={
          <span className="single-line-text mw-100 d-inline-block">
            {Title}
          </span>
        }
        secondary={
          <Moment local format={appDateTimeFormat}>
            {Updated}
          </Moment>
        }
      />
    </ListItem>
  )
}
