import React, { useState } from 'react'
import { icoFetch } from './_utils/fetchUtils'
import { useAppDispatch, useAppSelector } from './_utils/reactReduxHooks'
import { SnackbarContent, Snackbar } from '@material-ui/core'
import {
  selectMobilePromptDelay,
  selectSMSPromptNeeded,
} from './Issue/_selectors'
import { Spacer } from './Shared/Spacer'
import classes from './AppSMSPrompt.module.scss'
import { toggleSMSPromptOff } from './Issue/_actions'
import { isMobileApp } from './_selectors'

interface Props {
  openSettings: () => void
}

export const AppSMSPrompt = ({ openSettings }: Props) => {
  const [SMSPromptDisabled, setSMSPromptDisabled] = useState(false)
  const SMSpromptNeeded = useAppSelector(selectSMSPromptNeeded)
  const mobilePromptDelay = useAppSelector(selectMobilePromptDelay)
  const dispatch = useAppDispatch()
  const isMobile = useAppSelector(isMobileApp)

  const renderButtons = (
    <div style={{ display: 'flex' }}>
      <button
        className={classes.primaryButton}
        onClick={() => {
          openSettings()
          dispatch(toggleSMSPromptOff())
        }}
      >
        Enter Mobile Phone number
      </button>
      <Spacer />
      <button
        className={classes.secondaryButton}
        disabled={SMSPromptDisabled}
        onClick={() => {
          setSMSPromptDisabled(true)
          icoFetch('api/User/DeclineSMSPrompt')
            .then((response) => {
              if (response.status === 200) {
                setSMSPromptDisabled(false)
                dispatch(toggleSMSPromptOff())
              }
            })
            .catch((reason) => {
              setSMSPromptDisabled(false)
            })
        }}
      >
        Pause {mobilePromptDelay} Days
      </button>
    </div>
  )

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      open={SMSpromptNeeded === 'Y' && !isMobile}
      onClose={(event, reason) => {
        if (reason === 'clickaway') return
        dispatch(toggleSMSPromptOff())
      }}
      autoHideDuration={null}
      style={{ width: '100%', textAlign: 'center' }}
    >
      <SnackbarContent
        variant="elevation"
        message={`No Mobile Phone number found`}
        action={renderButtons}
        style={{
          position: 'absolute',
          top: '10px',
        }}
      />
    </Snackbar>
  )
}
