import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import { connect } from 'react-redux'
import { RootState } from '../_store'
import { refreshAuthentication } from './_actions'
import { isUserAuthenticated, selectTokenManagement } from './_selectors'

var canRefresh = true
var delay = 60000
var allKeepAlives: any = []
setInterval(function () {
  canRefresh = true
}, delay)
document.body.addEventListener('click', refreshOnEvent)

document.body.addEventListener('keydown', refreshOnEvent)

function refreshOnEvent(evt: MouseEvent | KeyboardEvent) {
  if (canRefresh) {
    var foundKeepAlive = allKeepAlives.find(
      (cur: any) =>
        cur._el === evt.srcElement || cur._el.contains(evt.srcElement)
    )
    if (foundKeepAlive) foundKeepAlive.refresh()
  }
}

interface Props {
  isAuthenticated: boolean
  refreshAuthentication: () => any
  tokenManagement: any
}

export default function KeepAlive(WrappedComponent: Component) {
  /**
   * @extends {Component<Props, {}>}}
   */
  class KeepAliveComponent extends Component<Props> {
    _el?: Element | Text | null
    constructor(props: Props) {
      super(props)
      this._el = undefined
    }

    componentDidMount() {
      this._el = ReactDOM.findDOMNode(this)
      allKeepAlives.push(this)
    }

    refresh() {
      if (
        canRefresh &&
        this.props.isAuthenticated &&
        this.props.tokenManagement?.ClientExpiration
      ) {
        this.props.refreshAuthentication()
        canRefresh = false
      }
    }

    render() {
      // @ts-expect-error
      return <WrappedComponent {...this.props} />
    }

    componentWillUnmount() {
      var index = allKeepAlives.indexOf(this)
      allKeepAlives.splice(index, 1)
    }
  }

  const mapStateToProps = (state: RootState) => ({
    isAuthenticated: isUserAuthenticated(state),
    tokenManagement: selectTokenManagement(state),
  })

  var mapDispatchToProps = {
    refreshAuthentication,
  }

  return connect(mapStateToProps, mapDispatchToProps)(KeepAliveComponent) // connect this component to the store;
}
