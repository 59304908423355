import React, { useState, useEffect, useMemo } from 'react'
import { useIcoPut, useIcoFetch } from '../_utils/fetchUtils'
import { CircularProgress } from '../Shared/LoadingBackdrop'
import { useIsMounted } from '../_utils/hooks'
import { isEmail, cx } from '../_utils/objectUtils'
import { useAppDispatch } from '../_utils/reactReduxHooks'
import { addNotification } from '../_actions'
import classes from './StakeholderPlaybooksAndMembers.module.scss'
import { StakeholderFields } from './_reducer'
import {
  FormControlLabel,
  FormLabel,
  Checkbox,
  Box,
  FormControl,
  TextField,
} from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAdd, faPeopleGroup } from '@fortawesome/pro-solid-svg-icons'
import { Spacer } from '../Shared/Spacer'
import { useAppSelector } from '../_utils/reactReduxHooks'
import { selectIssueOrgID } from '../Issue/_selectors'
import {
  selectIssueParticipantArray,
  selectStakeholderById,
} from '../_rootConfigs/rootSelectors'
import { Button } from '../Shared/Buttons'
import { StakeholderActions } from './_actions'
import { isUpdateStakeholderComplete } from '../Shared/_requestStatusSelectors'
import { faPeople, faPerson } from '@fortawesome/pro-light-svg-icons'
import { createFilterOptions } from '@material-ui/lab/Autocomplete'

const filter = createFilterOptions()

interface Props {
  StakeholderID: number | null
  mobile: boolean
  IssueID: number
  editMode: boolean
  workspaceID: number
  setEditMode: (arg0: boolean) => void
}

interface UGISgroup {
  ugis_group: string
  selectedYN: 'Y' | 'N'
}

export type StatusType = 'init' | 'groupmove' | 'groupfilter' | 'users'

export const StakeholderMembers = ({
  StakeholderID,
  mobile,
  IssueID,
  editMode,
  workspaceID,
  setEditMode,
}: Props) => {
  const [groups, setGroups] = useState<Array<string>>([])
  const [selectedGroups, setSelectedGroups] = useState<Array<string>>([])
  const [users, setUsers] = useState<Array<string>>([])

  const [memberType, setMemberType] = useState<string>('groups')

  const [newStakeholderDetails, setNewStakeholderDetails] =
    useState<StakeholderFields>({
      Audience: '',
      responsibleParticipant: '',
      msTeams_Channels: '',
    })

  const [msTeamsChannels, setMSTeamsChannels] = React.useState<
    Array<{
      ExtAppName: string
      id: number
      channelName: string
      isActive: string
    }>
  >([])
  const [selectedMSTeamsChannels, setSelectedMSTeamsChannels] = React.useState<
    Array<string>
  >([])

  const [saveEnabled, setSaveEnabled] = useState(false)

  const isMounted = useIsMounted()
  const dispatch = useAppDispatch()
  const OrgID = useAppSelector((state) => selectIssueOrgID(state, IssueID))
  const stakeholderDetails = useAppSelector((state) =>
    selectStakeholderById(state, StakeholderID)
  )

  const isStakeholderUpdated = useAppSelector(isUpdateStakeholderComplete)
  const issueParticipants = useAppSelector((state) =>
    selectIssueParticipantArray(state, workspaceID)
  )

  useEffect(() => {
    if (!stakeholderDetails) return
    setNewStakeholderDetails({
      Audience: stakeholderDetails.Audience,
      responsibleParticipant: stakeholderDetails.responsibleParticipant,
    })
  }, [stakeholderDetails, editMode])

  useEffect(() => {
    if (stakeholderDetails?.msTeams_Channels) {
      setSelectedMSTeamsChannels(
        stakeholderDetails?.msTeams_Channels
          ? stakeholderDetails?.msTeams_Channels.split(',')
          : []
      )
    }
  }, [stakeholderDetails?.msTeams_Channels])

  const initialGroupResult = useIcoFetch(
    `/api/Stakeholders/${StakeholderID ? StakeholderID : ''}/OrgGroups`,
    {
      defaultData: {},
      required: [StakeholderID],
      tracking: [StakeholderID, isStakeholderUpdated],
      formatData: (data) => {
        return data.groups
          ?.filter((group: UGISgroup) => group.selectedYN === 'Y')
          ?.map((group: UGISgroup) => group.ugis_group)
          ?.filter(
            (group: string, index: number, groups: Array<string>) =>
              groups.indexOf(group) === index
          )
      },
      onData: (result) => {
        const { data: fetchData } = result
        let groupOptions: Array<string> = []
        let selectedUGISGroups: Array<string> = []
        if (
          fetchData &&
          fetchData.groups &&
          fetchData.groups.length > 0 &&
          isMounted()
        ) {
          fetchData.groups.forEach((group: UGISgroup) => {
            if (!groupOptions.includes(group.ugis_group)) {
              groupOptions.push(group.ugis_group)
            } else {
              return
            }
            if (group.selectedYN === 'Y') {
              selectedUGISGroups.push(group.ugis_group)
            }
          })
        }
        setGroups(groupOptions)
        setSelectedGroups(selectedUGISGroups)
      },
    }
  )

  const initialUsersResult = useIcoFetch(
    `/api/Stakeholders/${StakeholderID}/Emails`,
    {
      required: [StakeholderID],
      tracking: [StakeholderID, isStakeholderUpdated],
      formatData: (data) => {
        return data?.email_list ? data?.email_list.split(',') : []
      },
      onData: (result) => {
        if (isMounted() && result.data) {
          setUsers(
            result.data?.email_list ? result.data?.email_list.split(',') : []
          )
        }
      },
    }
  )

  useIcoPut(
    `api/Stakeholders/MSTeams_Channels`,
    { OrgID: OrgID },
    {
      required: [OrgID],
      defaultData: [],
      onData: (data) => {
        if (isMounted() && data.data && !data.loading) {
          if (Object.keys(data.data).length > 0 && data.data.channels) {
            setMSTeamsChannels(data.data.channels)
          }
        }
      },
      tracking: [OrgID],
    }
  )

  const peopleSyncEmails = useIcoPut(
    `api/Stakeholders/peopleSync`,
    { OrgID: OrgID },
    {
      required: [OrgID],
      defaultData: [],
      formatData: (data) =>
        data?.map((email: { email_lcase: string }) => email.email_lcase),
      tracking: [OrgID, editMode],
    }
  )
  const emailOptions = useMemo(() => {
    return [
      ...peopleSyncEmails.data,
      ...issueParticipants
        .filter(
          (participant) =>
            !peopleSyncEmails.data.includes(participant.email_address)
        )
        .map((nonDupParticipant) => nonDupParticipant.email_address),
    ]
  }, [issueParticipants, peopleSyncEmails.data])

  useEffect(() => {
    if (!initialGroupResult.data || !initialUsersResult.data) {
      return
    }
    const MSTeamsChannelsNoChange =
      selectedMSTeamsChannels.join(',') === stakeholderDetails.msTeams_Channels
    const groupsSelectedNoChange =
      selectedGroups?.join(',') === initialGroupResult.data?.join(',')
    const usersListNoChange =
      users
        ?.filter((u) => isEmail(u))
        .join(',')
        .toLowerCase() === initialUsersResult.data?.join(',').toLowerCase()
    const usersListContainsInvalidEmail =
      users?.filter((u) => !isEmail(u)).length > 0
    if (
      (MSTeamsChannelsNoChange &&
        groupsSelectedNoChange &&
        usersListNoChange) ||
      usersListContainsInvalidEmail
    ) {
      setSaveEnabled(false)
    } else {
      setSaveEnabled(true)
    }
  }, [
    selectedMSTeamsChannels,
    selectedGroups,
    users,
    initialGroupResult,
    initialUsersResult,
    stakeholderDetails,
  ])

  const membersCountAndPhoneCheck = useIcoPut(
    `/api/Stakeholders/membersCountAndPhoneCheck`,
    { StakeholderID: StakeholderID, OrgID: OrgID },
    {
      required: [StakeholderID, OrgID],
      defaultData: [],
      tracking: [isStakeholderUpdated],
    }
  )

  function saveStakeholder() {
    dispatch(
      StakeholderActions.update({
        IssueID,
        IssueStakeholderID: StakeholderID,
        ...newStakeholderDetails,
        ugis_group_list: selectedGroups.join(','),
        email_list: users.filter((u) => isEmail(u)).join(','),
        msTeams_Channels: selectedMSTeamsChannels.join(','),
      })
    )
  }
  const handleChannelClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedMSTeamsChannels((prev) => {
      if (!selectedMSTeamsChannels.includes(event.target.name)) {
        return [...prev, event.target.name]
      } else {
        let newArrayReturn = [...prev]
        newArrayReturn.splice(prev.indexOf(event.target.name))
        return newArrayReturn
      }
    })
  }

  function setMemberTypeAndEdit(member: string, memberType: string) {
    setEditMode(true)
    setMemberType(memberType)
  }

  const membersPillBoxes = (
    <div
      style={{
        border: '1px lightgrey solid',
        padding: '.8rem',
        display: 'flex',
        flexWrap: 'wrap',
      }}
    >
      {!editMode && (
        <Button
          color={
            membersCountAndPhoneCheck.data.membersCount === 0 &&
            !stakeholderDetails.msTeams_Channels
              ? 'primary'
              : 'standard'
          }
          className={classes.pillBox}
          key={0}
          onClick={() => {
            setEditMode(true)
            setMemberType('')
          }}
          icon={<FontAwesomeIcon icon={faAdd} size="lg" />}
        >
          <span
            className={classes.pillContent}
            style={{ textTransform: 'capitalize' }}
          >
            Add Members
          </span>
        </Button>
      )}
      {selectedGroups.map((group, index) => (
        <PillButton
          member={group}
          key={'group_' + index}
          memberType="groups"
          setMemberTypeAndEdit={setMemberTypeAndEdit}
        />
      ))}
      {selectedMSTeamsChannels.map((channel, index) => (
        <PillButton
          member={channel}
          memberDisplay={
            msTeamsChannels
              .find(
                (channelInfo) => `Microsoft Teams_${channelInfo.id}` === channel
              )
              ?.channelName.split(' : ')[1] || ''
          }
          key={'channel_' + index}
          memberType="channels"
          setMemberTypeAndEdit={setMemberTypeAndEdit}
        />
      ))}
      {users.map((user, index) => (
        <PillButton
          member={user}
          key={'user_' + index}
          memberType="individual users"
          setMemberTypeAndEdit={setMemberTypeAndEdit}
        />
      ))}
    </div>
  )

  const SelectMemberType = (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        width: '100%',
      }}
    >
      <Button
        color={memberType === 'groups' ? 'primary' : 'standard'}
        onClick={() => {
          if (memberType !== 'groups') setMemberType('groups')
        }}
        style={{ fontSize: '.9rem' }}
      >
        Groups
      </Button>
      <Spacer spaceParam={'large'} />
      {msTeamsChannels.length > 0 && (
        <>
          <Button
            color={memberType === 'channels' ? 'primary' : 'standard'}
            onClick={() => {
              if (memberType !== 'channels') setMemberType('channels')
            }}
            style={{ fontSize: '.9rem' }}
          >
            MSTeams Channels
          </Button>
          <Spacer spaceParam={'large'} />
        </>
      )}
      <Button
        color={memberType === 'individual users' ? 'primary' : 'standard'}
        onClick={() => {
          if (memberType !== 'individual users')
            setMemberType('individual users')
        }}
        style={{ fontSize: '.9rem' }}
      >
        Individuals
      </Button>
    </div>
  )

  const SaveOrEditButton = (
    <div className="d-flex justify-content-start w-100 p-0">
      <Button
        onClick={() => {
          saveStakeholder()
          setEditMode(false)
        }}
        color="primary"
        disabled={!saveEnabled}
      >
        {'Save'}
      </Button>
      <Spacer />

      <Button
        onClick={() => {
          setSelectedGroups(initialGroupResult.data)
          setUsers(initialUsersResult.data)
          setSelectedMSTeamsChannels(
            stakeholderDetails.msTeams_Channels
              ? stakeholderDetails.msTeams_Channels.split(',')
              : []
          )
          setEditMode(false)
        }}
        color="standard"
      >
        Cancel
      </Button>
    </div>
  )

  const GroupSelection = (
    <FormControl className="w-100">
      <Autocomplete
        options={groups}
        multiple
        value={selectedGroups}
        onChange={(event: any, newValue: any | null) => {
          setSelectedGroups(newValue)
        }}
        renderInput={(params) => (
          <TextField {...params} label="Group Selection" variant="standard" />
        )}
        autoComplete
        openOnFocus
      />
    </FormControl>
  )

  const IndividualUsersSelection = peopleSyncEmails.loading ? (
    <div>
      Loading emails...
      <CircularProgress zIndex={0} position={undefined} />
    </div>
  ) : (
    <FormControl className="w-100">
      <Autocomplete
        options={emailOptions}
        multiple
        value={users}
        onChange={(event: any, newValue: any | null) => {
          let addedUser: number = newValue.findIndex(
            (item: string) => item.split('Add user: ').length > 1
          )
          let validEmails = true
          if (addedUser > -1) {
            let newUser = newValue[addedUser]
              .replaceAll('"', '')
              .replace('Add user: ', '')
            if (!isEmail(newUser)) {
              validEmails = false
            } else {
              newValue.splice(addedUser, 1, newUser)
            }
          }
          newValue.forEach((email: string) => {
            if (!isEmail(email)) {
              validEmails = false
            }
          })
          if (validEmails) {
            setUsers(newValue)
          } else {
            dispatch(
              addNotification({
                type: 'error',
                message: 'Must be a valid email',
              })
            )
          }
        }}
        renderInput={(params) => (
          <TextField {...params} label="Individual Users" variant="standard" />
        )}
        autoComplete
        openOnFocus
        //@ts-ignore
        filterOptions={(options, params) => {
          //@ts-ignore
          const filtered = filter(options, params).map((item) =>
            typeof item === 'string' ? item : ''
          )

          if (params.inputValue !== '') {
            filtered.push(`Add user: "${params.inputValue}"`)
          }

          return filtered
        }}
        freeSolo
      />
    </FormControl>
  )

  const MSTeamsChannelsSelection = (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
      }}
    >
      <FormLabel style={{ margin: '0', fontSize: '12px' }}>
        <span>Available MSTeams Channels:</span>
      </FormLabel>
      {msTeamsChannels.map((channel) => {
        return (
          <FormControlLabel
            id={`formControlMSTeamsChannel${channel.id}`}
            control={
              <Checkbox
                checked={selectedMSTeamsChannels.includes(
                  `${channel.ExtAppName}_${channel.id}`
                )}
                color="primary"
                onChange={handleChannelClick}
                id={`${channel.id}`}
                name={`${channel.ExtAppName}_${channel.id}`}
              />
            }
            label={<Box>{channel.channelName}</Box>}
            key={`${channel.id}`}
          />
        )
      })}
    </div>
  )

  const renderEditMembers = (
    <div className={cx(mobile ? classes.mobile : '')}>
      {memberType === 'groups' ? (
        GroupSelection
      ) : memberType === 'individual users' ? (
        IndividualUsersSelection
      ) : memberType === 'channels' && msTeamsChannels.length > 0 ? (
        MSTeamsChannelsSelection
      ) : (
        <></>
      )}
    </div>
  )

  return editMode ? (
    <>
      {membersPillBoxes}
      <Spacer spaceParam={'large'} />
      {SelectMemberType}
      <Spacer spaceParam={'large'} />
      {renderEditMembers}
      <Spacer spaceParam={'large'} />
      {SaveOrEditButton}
    </>
  ) : (
    <>{membersPillBoxes}</>
  )
}

const PillButton = ({
  member = '',
  memberDisplay = undefined,
  memberType,
  setMemberTypeAndEdit,
}: {
  member: string
  memberDisplay?: undefined | string
  memberType: string
  setMemberTypeAndEdit: (member: string, memberType: string) => void
}) => (
  <Button
    color="standard"
    className={cx(classes.pillBox, classes.iconWrapper)}
    onClick={() => setMemberTypeAndEdit(member, memberType)}
    icon={
      <FontAwesomeIcon
        icon={
          memberType === 'groups'
            ? faPeople
            : memberType === 'individual users'
            ? faPerson
            : faPeopleGroup
        }
        style={
          memberType === 'groups'
            ? { color: '#f58220' }
            : memberType === 'channels'
            ? { color: '#7B83EB' }
            : {}
        }
      />
    }
    style={
      memberType === 'individual users' && !isEmail(member)
        ? { border: '1px red solid' }
        : {}
    }
  >
    <span
      className={classes.pillContent}
      style={
        memberType === 'individual users' ? { textTransform: 'lowercase' } : {}
      }
    >
      {memberDisplay || member}
    </span>
  </Button>
)
