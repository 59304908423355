import {
  faPlusCircle,
  faLock,
  faCheckCircle,
  faChevronSquareRight,
  faChevronSquareDown,
} from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as React from 'react'
import { cx } from '../_utils/objectUtils'
import classes from './AddPlaybook.module.scss'
import Moment from 'react-moment'

interface Props {
  OrgID: number
  ParentOrgID: number
  OrgName: string
  pbks: PbTreePubProps[]
  subs: PbSubOrgProps[]
  startOpened?: boolean
  addedPubGUIDs?: string[]
  onTvItemClick?(tvp: PbTreePubProps, OrgName: string): void
  onTvItemView?(tvp: PbTreePubProps): void
}

export interface PbTreeOrgProps extends Props {}

export interface PbSubOrgProps {
  SubOrg: PbTreeOrgProps
}

export interface PbTreePubProps {
  PubID: number
  PubGUID: string
  PubTitle: string
  AuthType: string
  Version: number
  Updated: Date
}

export const PbTreeOrg = ({
  OrgID,
  ParentOrgID,
  OrgName,
  pbks,
  subs,
  startOpened,
  addedPubGUIDs,
  onTvItemClick = () => {},
  onTvItemView = () => {},
}: PbTreeOrgProps) => {
  const [isOpened, setIsOpened] = React.useState<boolean>(
    startOpened ? true : false
  )

  function renderSubs() {
    if (isOpened)
      if (subs)
        if (subs.length > 0) {
          return (
            <>
              {subs.map((pb: PbSubOrgProps) => (
                <>
                  <PbTreeOrg
                    key={pb.SubOrg.OrgID}
                    OrgID={pb.SubOrg.OrgID}
                    ParentOrgID={pb.SubOrg.ParentOrgID}
                    OrgName={pb.SubOrg.OrgName}
                    pbks={pb.SubOrg.pbks}
                    subs={pb.SubOrg.subs}
                    addedPubGUIDs={addedPubGUIDs}
                    onTvItemClick={onTvItemClick}
                    onTvItemView={onTvItemView}
                  />
                </>
              ))}
            </>
          )
        }
    return <></>
  }
  const SecurityIcon = (AuthType: string) => {
    if (AuthType === 'password') {
      return (
        <FontAwesomeIcon className={classes.rdsLockedColor} icon={faLock} />
      )
    }
    return '' // <FontAwesomeIcon className={classes.rdsLightGray} icon={faLockOpen} />;
  }

  const AddPlaybookIcon = () => {
    return (
      <FontAwesomeIcon
        className={classes.rdsGreen}
        icon={faPlusCircle}
        // size="lg"
      />
    )
  }
  const AddedPlaybookIcon = () => {
    return (
      <FontAwesomeIcon
        className={classes.rdsLightGray}
        icon={faCheckCircle}
        // size="lg"
      />
    )
  }

  function hasBeenAdded(pg: string) {
    //console.log("checking " + pg + " to see if it has been added");
    if (addedPubGUIDs) {
      if (addedPubGUIDs.length > 0) {
        if (addedPubGUIDs.indexOf(pg) > -1) {
          //console.log("FOUND " + pg + " in list");
          return true
        }
      }
    }
    return false
  }

  function addIconOrCheck(pb: PbTreePubProps) {
    if (hasBeenAdded(pb.PubGUID) === false) {
      return (
        <span
          onClick={() => {
            //alert("You clicked add " + pb.PubTitle);
            //console.log(JSON.stringify(pb));
            //console.log(addedPubGUIDs);
            //console.log(OrgName);
            onTvItemClick(
              {
                PubID: pb.PubID,
                PubGUID: pb.PubGUID,
                PubTitle: pb.PubTitle,
                AuthType: pb.AuthType,
                Version: pb.Version,
                Updated: pb.Updated,
              },
              OrgName
            )
          }}
        >
          {AddPlaybookIcon()}
        </span>
      )
    } else return <span>{AddedPlaybookIcon()}</span>
  }

  function renderPlaybooks() {
    if (isOpened)
      if (pbks)
        if (pbks.length > 0) {
          return (
            <>
              {pbks.map((pb: PbTreePubProps) => (
                <div>
                  {addIconOrCheck(pb)}
                  &nbsp;
                  <span
                    onClick={() => {
                      //alert("you clicked view " + pb.PubTitle);
                      onTvItemView({
                        PubID: pb.PubID,
                        PubGUID: pb.PubGUID,
                        PubTitle: pb.PubTitle,
                        AuthType: pb.AuthType,
                        Version: pb.Version,
                        Updated: pb.Updated,
                      })
                    }}
                  >
                    {pb.PubTitle}
                    &nbsp;
                    <small>
                      v. {pb.Version}
                      &nbsp;-&nbsp;
                      <Moment format="DD MMM YYYY" utc local>
                        {pb.Updated}
                      </Moment>
                    </small>
                  </span>
                  &nbsp;
                  {SecurityIcon(pb.AuthType)}
                </div>
              ))}
            </>
          )
        }
    return <></>
  }

  function toggleIsOpened() {
    setIsOpened(!isOpened)
  }

  const OpenCloseIcon = () => {
    if (isOpened) {
      return (
        <FontAwesomeIcon
          // onClick={toggleIsOpened}
          className={classes.rdsTreeControl}
          //icon={faCollapse}
          icon={faChevronSquareRight}
          size="lg"
        />
      )
    }
    return (
      <FontAwesomeIcon
        // onClick={toggleIsOpened}
        className={classes.rdsTreeControl}
        icon={faChevronSquareDown}
        size="lg"
      />
    )
  }

  function renderList() {
    if (OrgID > 0) {
      return (
        <div className={cx(classes.icoTreeSubOrgBorder)}>
          <span onClick={toggleIsOpened}>
            {OpenCloseIcon()}
            &nbsp;
            <span style={{ fontWeight: 'bold' }}>{OrgName}</span>
          </span>
          {renderPlaybooks()}
          {renderSubs()}
        </div>
      )
    }
    return <></>
  }
  return <div className={cx('container-fluid', 'p-2')}>{renderList()}</div>
}
