import * as React from 'react'
import { http } from '../_utils/http'
import { CircularProgress } from '../Shared/LoadingBackdrop'
import { HiddenFormWithValues } from '../Playbook/HiddenFormWithValues'

interface Props {
  ChatGUID: string
  className?: string
  onDataLoaded?: (playbooks: any) => void
}
//export interface PbChatDetailProps extends Props { };

interface EventParams {
  PubGUID?: string
  RefPubGUID?: string
  EventID?: number
  adg?: string
}

export const PbChatDetail = ({ ChatGUID, className, onDataLoaded }: Props) => {
  const [playbooks, setPlaybooks] = React.useState<any>('')
  const [chatDetailLoading, setChatDetailLoading] = React.useState(true)

  const getPlaybooks = async (): Promise<any> => {
    const result = await http<any>({
      path: '/pbchat/',
    })
    if (result.ok && result.body) {
      return result.body
    } else {
      return ''
    }
  }

  React.useEffect(() => {
    let cancelled = false
    const doGetPlaybooks = async () => {
      const playbooks = await getPlaybooks()
      if (!cancelled) {
        setPlaybooks(playbooks)
        setChatDetailLoading(false)

        if (onDataLoaded) onDataLoaded(playbooks)
      }
    }
    doGetPlaybooks()
    return () => {
      cancelled = true
    }
  }, [])

  function renderDetails() {
    //const Playbook = playbookList[playbookList.length - 1];
    if (chatDetailLoading) return <CircularProgress />
    if (ChatGUID == null) {
      return <h2>Select a chat</h2>
    }
    var myChat = null
    if (playbooks.Chats && playbooks.Chats.length > 0) {
      for (var i = 0; i < playbooks.Chats.length; i++) {
        if (playbooks.Chats[i].ChatGUID === ChatGUID) {
          myChat = playbooks.Chats[i]
        }
      }
    }
    if (myChat) {
      var hfvc: EventParams = {}
      hfvc['PubGUID'] = myChat.PubGUID.toLowerCase()
      hfvc['RefPubGUID'] = myChat.RefPubGUID.toLowerCase()
      hfvc['EventID'] = myChat.EventID
      hfvc['adg'] = playbooks.AppDevGUID
      //setBackButton(true);
      return (
        <HiddenFormWithValues
          HostURL={myChat.hostURL}
          HostPage="ChatPlan"
          HdnVals={hfvc}
        />
      )
    }
    return (
      <>
        <h2>details go here</h2>
        <div>
          for {ChatGUID}
          <br />
          adg {playbooks.AppDevGUID}
          <hr />
          <pre>{JSON.stringify(myChat, null, 2)}</pre>
          <hr />
          <pre>{JSON.stringify(playbooks.Chats, null, 2)}</pre>
          <hr />
        </div>
      </>
    )
  }

  return <>{renderDetails()}</>
}
