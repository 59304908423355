import { ConferenceConstants } from './_constants'
import { getTodayUTC, addDaysToDate, diff_weeks } from '../_utils/dateUtils'
import { ConferenceState } from './_models'
import { IssueConstants } from '../Issue/_constants'
import { createEntityReducer, selectEntityByNumId } from '../_schema/_reducer'

/*
 * CONFERENCE REDUCER
 */
export const ConferenceReducer = createEntityReducer<ConferenceState>(
  'ConferenceID',
  {
    crudTypes: ConferenceConstants,
    reducer: (state, action) => ({
      [IssueConstants.FETCH.REQUEST]: () => state,
    }),
  }
)
export default ConferenceReducer

// NOTE: Reselect createSelector will always pass in state and props
/*
 * CONFERENCE SELECTORS REDUCER
 */
export const selectConferenceStart = (
  state: ConferenceState,
  conferenceID: number
) => {
  const conf = selectEntityByNumId(state, conferenceID)
  if (!conf) return
  if (!conf.RepeatType) return conf.Scheduled
  if (!conf.Scheduled) return

  const today = getTodayUTC()
  const WeekStart = conf.WeekStart
  const saturday = addDaysToDate(WeekStart, 6)
  let start = conf.Scheduled
  let diffWeeks = 0

  if (today > conf.Scheduled) diffWeeks = diff_weeks(today, conf.Scheduled)

  if (conf.RepeatType === 1)
    // Weekly
    start = addDaysToDate(conf.Scheduled, diffWeeks * 7)
  else {
    // Every Weekday
    if (today.getDay() === WeekStart?.getDay())
      start = new Date(
        addDaysToDate(today, diffWeeks * 7 + 1).toDateString() +
          ' ' +
          conf.Scheduled.toTimeString()
      )
    else if (today.getDay() === saturday.getDay())
      start = new Date(
        addDaysToDate(today, diffWeeks * 7 + 2).toDateString() +
          ' ' +
          conf.Scheduled.toTimeString()
      )
    else start = addDaysToDate(conf.Scheduled, diffWeeks * 7)

    // Adjust the final date to the proper week days
    let adjDays = 0
    if (start.getDay() === WeekStart?.getDay()) adjDays = 1
    else if (start.getDay() === saturday.getDay()) adjDays = 2

    if (adjDays > 0) start = addDaysToDate(start, adjDays)
  }

  return start
}

export const selectConferenceEnd = (
  state: ConferenceState,
  conferenceID: number
) => {
  const conf = selectEntityByNumId(state, conferenceID)
  if (!conf) return
  if (!conf.Scheduled) return

  const start = selectConferenceStart(state, conferenceID)

  if (!conf.ScheduledEnd)
    return new Date(start?.toLocaleDateString() + ' 11:59 PM')

  return new Date(
    start?.toDateString() + ' ' + conf.ScheduledEnd.toTimeString()
  )
}
