import * as React from 'react'
import { ComponentType } from 'react'
import { Route, Redirect, RouteProps } from 'react-router'
import { connect } from 'react-redux'
import { redirectFromUnauthorized } from './_actions'
import { DesktopLayout, MobileLayout } from '../Layouts/ResponsiveHidden'
import GlobalLoadingIndicator from '../Shared/GlobalLoadingIndicator'
import { MasterPageLayout } from '../Pages/MasterPageLayout'
import { isUserAuthenticated, isUserAuthorized } from './_selectors'
import {
  selectIsAppInitialDataLoaded,
  isInitIssuesComplete,
  isRequestStatusCleared,
} from '../Shared/_requestStatusSelectors'

interface Props extends RouteProps {
  mobileComponent?: ComponentType
  isAuthenticated?: boolean
  isAuthorized?: boolean
  awaitAppData?: boolean
  awaitIssues?: boolean
  requireAuthorization?: boolean
  isInitIssuesComplete?: boolean
  isAppInitialDataLoaded?: boolean
  isRequestStatusCleared?: boolean
  redirectOnAuthorized?: string
  redirectFromUnauthorized?: () => string
  component: any
}

function AuthenticatedRoute({
  component: C,
  mobileComponent: CM,
  isAuthenticated,
  isAuthorized,
  awaitIssues,
  awaitAppData,
  requireAuthorization,
  isInitIssuesComplete,
  isAppInitialDataLoaded,
  redirectOnAuthorized,
  redirectFromUnauthorized,
  isRequestStatusCleared,
  ...rest
}: Props) {
  function renderPage(props: any) {
    if (isAuthenticated === false)
      return (
        <Redirect
          to={{
            pathname: '/login',
            state: { from: props.location },
          }}
        />
      )
    else if (!(isAppInitialDataLoaded || isRequestStatusCleared))
      return <GlobalLoadingIndicator open={true} />
    else if (awaitIssues && !isInitIssuesComplete)
      return (
        <MasterPageLayout {...props} {...rest}>
          <GlobalLoadingIndicator
            zIndex={999}
            className="position-relative h-100"
            open={true}
          />
        </MasterPageLayout>
      )
    else if (requireAuthorization !== false && !isAuthorized)
      return (
        <Redirect
          to={{
            pathname: '/InvalidAccount',
            state: { from: props.location },
          }}
        />
      )
    else if (redirectOnAuthorized && isAuthorized) {
      if (redirectFromUnauthorized) redirectFromUnauthorized()

      return (
        <Redirect
          to={{
            pathname: redirectOnAuthorized,
            state: { from: props.location },
          }}
        />
      )
    }

    if (!CM)
      return (
        <C
          {...props}
          isAuthenticated={isAuthenticated}
          isAuthorized={isAuthorized}
        />
      )

    return (
      <>
        <DesktopLayout>
          <C
            {...props}
            isAuthenticated={isAuthenticated}
            isAuthorized={isAuthorized}
          />
        </DesktopLayout>
        <MobileLayout>
          <CM
            {...props}
            isAuthenticated={isAuthenticated}
            isAuthorized={isAuthorized}
          />
        </MobileLayout>
      </>
    )
  }

  // The route component that takes a component or a render callback
  return <Route {...rest} render={renderPage} />
}

const mapStateToProps = (state: any) => ({
  isAuthenticated: isUserAuthenticated(state),
  isAuthorized: isUserAuthorized(state),
  isAppInitialDataLoaded: selectIsAppInitialDataLoaded(state),
  isInitIssuesComplete: isInitIssuesComplete(state),
  isRequestStatusCleared: isRequestStatusCleared(state),
})

const mapDispatchToProps = {
  redirectFromUnauthorized,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AuthenticatedRoute as any)
