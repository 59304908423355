import React, { Component, Fragment, KeyboardEvent } from 'react'
import { FormControl, Input, InputLabel } from '@material-ui/core'
import classes from './InputAndSubmitButton.module.scss'
import { Button } from '../Shared/Buttons'
import { cx } from '../_utils/objectUtils'
import { ChangeEvent } from 'react'

interface Props {
  placeholder?: string
  submitLabel?: string
  inputLabel?: string
  value?: string
  variant?: string
  className?: string
  disabled?: boolean
  name?: string
  allowInput?: (value: string) => boolean
  onChange?: (value: string) => void
  clearOnSubmit?: boolean
  onSubmit?: (value: string) => void
  btnColor?: 'standard' | 'primary' | 'secondary'
  input?: any
}

interface State {
  value: string
}

/**
 * @extends {Component<Props, {}>}}
 * Reuseable control for an input and button that work together.
 * Useful for one item adds
 */
export class InputAndSubmitButton extends Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.handleClick = this.handleClick.bind(this)
    this.handleInputKeyUp = this.handleInputKeyUp.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.state = {
      value: this.props.value || '',
    }
  }

  static defaultProps = {
    clearOnSubmit: undefined,
    onSubmit: function () {},
    submitLabel: 'Submit',
    allowInput: function () {
      return true
    },
    variant: 'normal',
    btnColor: 'primary',
  }

  render() {
    const {
      placeholder,
      submitLabel,
      inputLabel,
      value,
      variant,
      className,
      disabled,
      name,
      btnColor,
      input,
    } = this.props
    return (
      <Fragment>
        {variant === 'material' ? (
          <div className={cx(classes.root, className, 'input-group')}>
            <FormControl
              className={cx('form-control', classes.inputFormControl)}
            >
              {input ? (
                input
              ) : (
                <>
                  {inputLabel ? <InputLabel>{inputLabel}</InputLabel> : null}
                  <Input
                    type="text"
                    name={name}
                    disabled={disabled}
                    className={disabled ? classes.disabled : ''}
                    value={value !== undefined ? value : this.state.value}
                    onKeyUp={this.handleInputKeyUp}
                    onChange={this.handleInputChange}
                    placeholder={placeholder || inputLabel}
                  />
                </>
              )}
            </FormControl>
            <div className="input-group-append">
              <Button
                className={cx(
                  classes.submitBtn,
                  disabled ? classes.disabled : ''
                )}
                color={btnColor}
                onClick={this.handleClick}
                disabled={disabled}
              >
                {submitLabel}
              </Button>
            </div>
          </div>
        ) : (
          <div
            className={cx(
              classes.root,
              classes.normal,
              className,
              'input-group'
            )}
          >
            <div className="input-group-prepend">
              <span className={cx(classes.inputLabel, 'input-group-text')}>
                <b>{inputLabel}</b>
              </span>
            </div>
            {input ? (
              input
            ) : (
              <input
                type="text"
                name={name}
                className={cx('form-control', disabled ? classes.disabled : '')}
                placeholder={placeholder}
                value={value}
                onKeyUp={this.handleInputKeyUp}
                onChange={this.handleInputChange}
                disabled={disabled}
              />
            )}
            <div className="input-group-append">
              <Button
                className={cx(
                  classes.submitBtn,
                  disabled ? classes.disabled : ''
                )}
                color={btnColor}
                onClick={this.handleClick}
                disabled={disabled}
              >
                {submitLabel}
              </Button>
            </div>
          </div>
        )}
      </Fragment>
    )
  }

  handleInputChange(evt: ChangeEvent<HTMLInputElement>) {
    const { allowInput, onChange } = this.props
    const { value } = evt.target
    if (allowInput?.call(undefined, value)) {
      if (!onChange) {
        this.setState({
          value,
        })
      } else {
        onChange(value)
      }
    }
  }

  handleInputKeyUp(evt: KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) {
    var enter = 13
    if (evt.keyCode === enter) {
      this.handleClick()
    }
  }

  handleClick() {
    const { onSubmit, clearOnSubmit } = this.props
    const valueToSubmit =
      this.props.value !== undefined ? this.props.value : this.state.value
    onSubmit?.call(undefined, valueToSubmit)

    // Clear on submit only if the props.value is not available
    if (
      clearOnSubmit === true &&
      this.props.value === undefined &&
      this.state.value !== ''
    ) {
      this.setState({
        value: '',
      })
    }
  }
}
