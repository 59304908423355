import * as React from 'react'
import { Component } from 'react'
import AppHeader from '../AppHeader'
import {
  StretchLayout,
  StretchHeader,
  StretchBody,
} from '../Layouts/StretchLayout'
import { RouteComponentProps } from 'react-router-dom'
import { AllRouteParams } from '../Routing/RouteProps'

interface Props extends RouteComponentProps<AllRouteParams> {
  id?: string
}

/**
 * @extends {Component<Props, {}>}}
 */
export class MasterPageLayout extends Component<Props> {
  render() {
    return (
      <StretchLayout id={this.props.id}>
        <StretchHeader>
          <AppHeader
            routeProps={this.props as RouteComponentProps<AllRouteParams>}
          />
        </StretchHeader>
        <StretchBody>{this.props.children}</StretchBody>
      </StretchLayout>
    )
  }
}
