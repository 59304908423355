// @ts-nocheck
// TODO: Typescript
const appName = 'IcoIssue'
export function getAppName() {
  return appName
}

export function isNullOrUndefined<T>(value: T, defaultValue: T) {
  return value === undefined || value === null ? defaultValue : value
}

export interface BinarySearchOptions<T> {
  getItemValue?: (item: T) => string | number
}
export function binarySearch<T>(
  items: T[],
  searchValue: string | number,
  options?: BinarySearchOptions<T> = {}
) {
  items = items || []
  let first = 0
  let last = items.length - 1
  let mid = 0
  let found: { index: number; item: any }

  let { getItemValue } = options

  if (!getItemValue) getItemValue = (item) => item

  let doSearch

  // Determine if the list is in descending or ascending order
  let isDesc =
    items.length > 1 &&
    getItemValue(items[0]) > getItemValue(items[items.length - 1])

  if (isDesc) {
    doSearch = (midValue) => {
      if (searchValue < midValue) first = mid + 1
      else if (searchValue > midValue) last = mid - 1
      else found = { index: mid, item: midValue }
    }
  } else {
    doSearch = (midValue) => {
      if (searchValue > midValue) first = mid + 1
      else if (searchValue < midValue) last = mid - 1
      else found = { index: mid, item: midValue }
    }
  }

  while (first <= last && !found) {
    const distance = last - first
    mid = Math.floor(distance / 2) + first // offest to the proper position;
    const midValue = getItemValue(items[mid])
    doSearch(midValue)
  }

  return found
}

/**
 * @param {any} prefix
 * @param {any} keyValuePair
 * @param {boolean} freeze
 * @param {any} clone
 */
export function addKeyValuePrefix(prefix, keyValuePair, freeze, clone) {
  let target = clone ? {} : keyValuePair
  prefix = prefix + '_'
  Object.keys(keyValuePair).forEach((key) => {
    // exclude readonly properties. This is needed for Docz
    if (!key.startsWith('__')) target[key] = prefix + keyValuePair[key]
  })

  if (freeze !== false) Object.freeze(target)

  return target
}

export function parseIntIfPresent(obj, key?: string) {
  if (!key) return obj && !isNaN(obj) ? parseInt(obj) : undefined

  const result = getValueFromKey(obj, key)
  return result && !isNaN(result) ? parseInt(result) : undefined
}

export function tryParseInt(value?: string) {
  let result: number
  if (value && !isNaN(value)) result = parseInt(value)

  return result
}

export function getKeyValuePair<T = any>(
  obj?: object,
  key?: string,
  defaultValue?: T
): { normalizedKey: string; key: string; value: T } | undefined {
  const keyTokens = (key || '').split('.')
  const defaultObj = {
    normalizedKey: keyTokens[keyTokens.length - 1],
    key,
    value: defaultValue,
  }

  if (!key || !obj)
    return obj === undefined || obj === null ? undefined : defaultObj

  let normalizedKey: string = ''
  for (let i = 0; i < keyTokens.length; i++) {
    normalizedKey = keyTokens[i]
    obj = obj[keyTokens[i]] as any
    if (obj === undefined || obj === null) {
      if (defaultValue === undefined) return undefined
      else return defaultObj
    }
  }

  if (obj) return { normalizedKey, key, value: obj as T }
}

export function getValueFromKey<T = any>(
  obj?: object,
  key?: string,
  defaultValue?: T
) {
  const result = getKeyValuePair(obj, key, defaultValue)
  if (!result) return defaultValue

  return result.value
}

export function arrayToKeyValues(arr, key, saveKeys) {
  let keys = []
  let keyValues = {}
  arr = arr || []

  if (!saveKeys) {
    arr.forEach(function (row) {
      keyValues[row[key]] = row
    })
  } else {
    arr.forEach(function (row) {
      let curKeyValue = row[key]
      keys.push(curKeyValue)
      keyValues[curKeyValue] = row
    })
  }

  return {
    keys,
    keyValues,
  }
}

export function keyValuesToArray<T = any>(obj: { [key: any]: T }, isValid?) {
  let result: T[] = []
  obj = obj || {}
  isValid =
    isValid ||
    function () {
      return true
    }
  for (let key in obj) {
    let item = obj[key]
    if (obj.hasOwnProperty(key) && isValid(key, item)) {
      result.push(item)
    }
  }

  return result
}

export function valuesFromObject(object) {
  let result = []
  for (let key in object) {
    if (object.hasOwnProperty(key)) result.push(object[key])
  }

  return result
}

export function removeKeyFromObject(obj, keyToRemove) {
  let result = {}
  for (let key in obj) {
    if (key !== keyToRemove.toString()) result[key] = obj[key]
  }
  return result
}

export function mergeShallow(oldObj, newObj) {
  let finalObj = {}

  for (let id in oldObj) {
    if (!isNaN(id)) id = parseInt(id)
    addEntity(id, oldObj[id], newObj[id])
  }

  function addEntity(id, value, newValue) {
    if (typeof value === 'object' && typeof newValue === 'object')
      finalObj[id] = { ...value, ...newValue }
    else finalObj[id] = newValue
  }

  return finalObj
}

export function arrayConcat(arr, newValue, allowDup) {
  if (!arr) return arr

  let foundIndex = -1
  if (!allowDup) foundIndex = arr.findIndex((v) => v === newValue)

  if (foundIndex === -1) return arr.concat(newValue)

  return arr
}

// Sql fix for arrays that have an empty object  when it should just be an empty array
export function getCleanArray(data) {
  let isValid = true

  if (!data) return []

  if (data.length === 1) {
    // The array is valid as long as the first object is not empty
    isValid = !isEmpty(data[0])
  }

  return isValid ? data : []
}

export function isEmpty(obj) {
  if (!obj) return true

  if (obj) {
    // The object is empty if there are no keys
    for (let key in obj) {
      if (obj.hasOwnProperty(key)) {
        return false
      }
    }
  }

  return true
}

export function JsonParseSafe(json) {
  try {
    if (typeof json === 'string') return JSON.parse(json)
  } catch (ex) {
    console.log(ex)
    return ex
  }

  return json
}

export function parseBool(value: string) {
  if (!value) return false

  value = value.toLocaleLowerCase().trim()

  return value === 'true' ? true : false
}

/**
 *
 * @param {...any} tokens - The strings to join
 * joins strings and adds spaces between them
 */
export function cx(...tokens: (string | undefined)[]) {
  return (tokens || [])
    .filter((t) => t)
    .map((t) => t?.trim() || '')
    .join(' ')
}

export function generateResetTimeoutMap(delay, state) {
  let mappedCallbacks = {}
  let nonMappedCallback

  if (!delay)
    // No delay
    nonMappedCallback = (callback) => callback()

  function resetTimeout(name) {
    if (nonMappedCallback) return nonMappedCallback

    // Each key should always have a delay
    //if (typeof delay === 'object') {
    // If the reset timeout does not exist yet create it
    if (!mappedCallbacks[name]) {
      let finalDelay = delay
      if (typeof delay === 'object') finalDelay = delay[name]

      mappedCallbacks[name] = generateResetTimeout(finalDelay || 0, state)
    }

    return mappedCallbacks[name]
    //}
  }

  // The current number of timers
  resetTimeout.count = state.count

  return resetTimeout
}

function generateResetTimeout(delay, state = { length: 0 }) {
  let timerId = 0

  return function (callback) {
    clearTimeout(timerId)
    if (state.length > 0) state.length--

    timerId = setTimeout(function () {
      state.length--
      callback()
    }, delay)
    state.length++
  }
}

export function reorderItem(arr, item, toIndex, copy = true) {
  // Find the item O(n)
  const foundIndex = arr.indexOf(item)
  let resultArr = arr

  if (foundIndex < 0) return

  // Bounds check
  if (toIndex >= arr.length) toIndex = arr.length - 1
  else if (toIndex < 0) toIndex = 0

  // Do nothing if the indices are the same
  if (foundIndex === toIndex) return resultArr

  // reorder in place
  if (!copy) {
    // Swap until toIndex reached O(n)
    if (foundIndex < toIndex) {
      for (let i = foundIndex; i < toIndex; i++) {
        const temp = arr[i + 1]
        arr[i + 1] = arr[i]
        arr[i] = temp
      }
    } else {
      for (let i = foundIndex; i > toIndex; i--) {
        const temp = arr[i - 1]
        arr[i - 1] = arr[i]
        arr[i] = temp
      }
    }
  } else {
    // make a copy
    resultArr = []
    // Declare arr size?
    resultArr[arr.length - 1] = 0
    let added = 0

    if (toIndex < foundIndex) {
      for (let i = 0; i < arr.length; i++) {
        if (i === toIndex) {
          resultArr[added] = arr[foundIndex]
          added++
        }
        if (i !== foundIndex) {
          resultArr[added] = arr[i]
          added++
        }
      }
    } else {
      for (let i = 0; i < arr.length; i++) {
        if (i !== foundIndex) {
          resultArr[added] = arr[i]
          added++
        }

        if (i === toIndex) {
          resultArr[added] = arr[foundIndex]
          added++
        }
      }
    }
  }

  return resultArr
}

/**
 * @description - Removes a specified character at the start of the string
 * @param {string} str
 * @param {string} curToRemove
 */
export function removeAtStart(str, curToRemove) {
  let foundIndex = -1

  for (let i = 0; i < str.length; i++) {
    if (str[i] !== curToRemove) break
    else foundIndex = i
  }

  return foundIndex !== -1 ? str.substr(foundIndex + 1) : str
}

export function appendNewValues(oldValues, newValues, isPresent) {
  let result = (oldValues || []).slice(0)

  // Gather the values that need to be added
  ;(newValues || []).forEach((value) => {
    if (isPresent(value)) result.push(value)
  })

  return result
}

export function prependNewValues(oldValues, newValues, isPresent) {
  let result = (oldValues || []).slice(0)
  let validValues = []

  // Gather the values that need to be added
  ;(newValues || []).forEach((value) => {
    if (isPresent(value)) validValues.push(value)
  })

  if (validValues.length > 0) result = [...validValues, ...result]

  return result
}

export function isObjectEqualTo(a, b, keys) {
  if (keys) {
    for (let i = 0; i < keys.length; i++) {
      const k = keys[i]
      let aValue = a[k]
      let bValue = b[k]

      if (aValue instanceof Date) aValue = aValue.getTime()
      if (bValue instanceof Date) bValue = bValue.getTime()

      if (aValue !== bValue) return false
    }

    return true
  }

  return true
}

/**
 * Determines if an object contains values that are
 * equal to another object
 * @param {any} superset
 * @param {any} subset
 */
export function containsValues(superset, subset) {
  for (let key in subset) {
    let superValue = superset[key]
    let subValue = subset[key]

    if (superValue instanceof Date) superValue = superValue.getTime()
    if (subValue instanceof Date) subValue = subValue.getTime()

    if (superValue !== subValue) return false
  }

  return true
}

export function isEqualIgnoreCase(a, b) {
  return (a || '').toLocaleLowerCase() === (b || '').toLocaleLowerCase()
}

export function isEqualIgnoreCaseTrim(a, b) {
  return (
    (a || '').toLocaleLowerCase().trim() ===
    (b || '').toLocaleLowerCase().trim()
  )
}

export function isEmail(email) {
  const regex =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return regex.test(email)
}

export function addKeyValueIfNotPresent(
  curObj,
  sourceObj,
  searchKeyName,
  valueFn
) {
  if (!sourceObj) return curObj

  let isUpdated = false

  let newObj = {}

  // Get all new changes from source object
  for (let key in sourceObj) {
    const curSource = sourceObj[key]
    const searchKey = curSource[searchKeyName]
    if (!curObj[searchKey]) {
      isUpdated = true
      newObj[searchKey] = valueFn(curSource)
    }
  }

  // Return the old object if no updates exist
  if (!isUpdated) return curObj

  // Copy the old values over
  for (let key in curObj) newObj[key] = curObj[key]

  return newObj
}

export function remapKeyValue(obj, keyCallback, valueCallback) {
  if (!keyCallback) throw new Error('keyCallback() required')
  if (!valueCallback) throw new Error('valueCallback() required')

  let newObj = {}

  for (const key in obj) {
    newObj[keyCallback(obj[key], key)] = valueCallback(obj[key], key)
  }

  return newObj
}

export function sortMulti(a, b, sortKeys: string[] = []) {
  for (let i = 0; i < sortKeys.length; i++) {
    const curKey = sortKeys[i]
    const parts = curKey.split(',')
    if (parts.length > 0) {
      const key = parts[0]?.trim()
      let dir = parts[1]?.trim().toLowerCase()
      if (!dir) dir = 'desc'

      const flip = dir !== 'desc' ? -1 : 1

      let bValue = b[key]
      let aValue = a[key]

      // Extra check for dates
      if (aValue?.getTime) aValue = aValue.getTime()

      if (bValue?.getTime) bValue = bValue.getTime()

      if (bValue > aValue) return 1 * flip
      else if (bValue < aValue) return -1 * flip
    }
  }

  // They are equal
  return 0
}

export function sortDateId(a, b, dateKey, idKey, isDesc = true) {
  const bDate = b[dateKey]
  const aDate = a[dateKey]

  const bId = b[idKey]
  const aId = a[idKey]

  const flip = !isDesc ? -1 : 1

  if (!aDate && !bDate) {
    if (aId > bId) return 1 * flip
    else if (aId < bId) return -1 * flip
  } else if (bId > aId) return 1 * flip
  else if (bId < aId) return -1 * flip

  return 0
}

export function truncate(str: string, maxLength: number) {
  if (str && str.length > maxLength) return str.substr(0, maxLength) + '...'

  return str
}

export function sortDateIdDesc(a, b, dateKey, idKey) {
  return sortDateId(a, b, dateKey, idKey)
}

export function mapSort(list, compValueFn, isDesc?: boolean) {
  let mapped = list.map((item, index) => ({
    value: compValueFn(item),
    index,
  }))

  if (!isDesc) {
    mapped.sort(function (a, b) {
      if (a.value > b.value) return 1
      if (a.value < b.value) return -1

      return 0
    })
  } else {
    mapped.sort(function (a, b) {
      if (a.value < b.value) return 1
      if (a.value > b.value) return -1

      return 0
    })
  }

  return mapped.map((item) => list[item.index])
}

export function getDescComp(keyFn) {
  return function (a, b) {
    const aValue = keyFn(a)
    const bValue = keyFn(b)
    if (aValue < bValue) return 1
    if (aValue > bValue) return -1

    return 0
  }
}

const exportFunctions = {
  addKeyValuePrefix,
  getValueFromKey,
  parseIntIfPresent,
}
export default exportFunctions
