// @ts-nocheck
// TODO: Typescript
import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import ConferenceList from '../Conference/ConferenceList'
import PropTypes from 'prop-types'
import ConferenceDeleteConfirmation from '../Conference/ConferenceDeleteConfirmation'
import ConferenceInstructionDialog from '../Conference/ConferenceInstructionDialog'
import { ContentViewer } from '../Shared/ContentViewer'
import { ConferenceAdd } from '../Conference/ConferenceAdd'
import {
  StretchLayout,
  StretchBody,
  StretchFooter,
} from '../Layouts/StretchLayout'
import { Button } from 'reactstrap'
import { getTodayUTC, getTomrrowUTCDate } from '../_utils/dateUtils'
import { ConferenceActions } from '../Conference/_actions'
import ConferenceExpiredWorker from '../Conference/ConferenceExpiredWorker'
import { selectWorkspaceConferenceIds } from './_selectors'
import {
  selectWorkspaceFutureConferenceIds,
  selectWorkspaceNextConference,
  selectWorkspaceRecentlyCreatedConference,
  selectWorkspaceRecentPastDueConference,
} from '../_rootConfigs/rootSelectors'
import { selectConferencesIdMap } from '../Conference/_selectors'

const conferenceUpdateInterval = 10000

/**
 * @extends {Component<Props, {}>}}
 */
class WorkspaceConferenceViewer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      conferenceIds: [],
      ConferenceToDelete: undefined,
      InstructionDialog: undefined,
      ConferenceToEdit: undefined,
      conferenceDialog: false,
    }
    this.handleDeleteClick = this.handleDeleteClick.bind(this)
    this.handleCancelDeleteClick = this.handleCancelDeleteClick.bind(this)
    this.handleOpenDialog = this.handleOpenDialog.bind(this)
    this.handleInstructionDialog = this.handleInstructionDialog.bind(this)
    this.handleEditClick = this.handleEditClick.bind(this)
    this.handleCloseConferenceDialog =
      this.handleCloseConferenceDialog.bind(this)
    this.handleCreateClick = this.handleCreateClick.bind(this)
    this.removeOldConferences = this.removeOldConferences.bind(this)
    this.conferenceWorker = ConferenceExpiredWorker.createFakeWorker()
  }

  componentDidMount() {
    this._ismounted = true
    const { removeConferenceIds, IssueTeamWorkspaceID, isMsTeams } = this.props

    if (!isMsTeams) {
      this.conferenceTimer = setInterval(
        this.removeOldConferences,
        conferenceUpdateInterval
      )
      this.removeOldConferences()

      this.conferenceWorker.onmessage = ({ data }) => {
        if (data.length > 0)
          removeConferenceIds(data, {
            IssueTeamWorkspaceID,
          })
      }
    }
  }

  componentWillUnmount() {
    if (!this.props.isMsTeams) {
      this._ismounted = false
      clearInterval(this.conferenceTimer)
    }
  }

  removeOldConferences() {
    if (this._ismounted) {
      const { conferenceIds, conferenceIdMap } = this.props
      this.conferenceWorker.postMessage([
        conferenceIds,
        conferenceIdMap,
        getTodayUTC(),
        getTomrrowUTCDate(),
      ])
    }
  }

  handleDeleteClick(ConferenceToDelete) {
    this.setState({
      ConferenceToDelete,
    })
  }

  handleCancelDeleteClick() {
    const { disabled } = this.props
    if (disabled) return

    this.setState({
      ConferenceToDelete: null,
    })
  }

  handleOpenDialog(InstructionDialog) {
    this.setState({
      InstructionDialog,
    })
  }

  handleInstructionDialog() {
    const { disabled } = this.props
    if (disabled) return

    this.setState({
      InstructionDialog: null,
    })
  }

  handleEditClick(ConferenceToEdit) {
    this.setState({
      ConferenceToEdit,
      conferenceDialog: true,
    })
  }

  handleCreateClick() {
    this.setState({
      ConferenceToEdit: null,
      conferenceDialog: true,
    })
  }

  handleCloseConferenceDialog() {
    this.setState({
      ConferenceToEdit: null,
      conferenceDialog: false,
    })
  }

  render() {
    const {
      IssueTeamWorkspaceID,
      IssueID,
      futureConferences,
      nextConferenceID,
      recentPastDueID,
      mostRecentID,
      disabled,
      className,
      mobile,
      conferenceIds,
    } = this.props

    return (
      <Fragment>
        <ContentViewer
          largeAbsolute={true}
          largeClassName="bg-white"
          headerTitle={
            this.state.ConferenceToEdit ? 'Update Meeting' : 'Create Meeting'
          }
          open={this.state.conferenceDialog}
          onClose={this.handleCloseConferenceDialog}
          isDialog={mobile}
        >
          <ConferenceAdd
            IssueTeamWorkspaceID={IssueTeamWorkspaceID}
            IssueID={IssueID}
            disabled={disabled}
            ConferenceToEdit={this.state.ConferenceToEdit}
            onSubmit={this.handleCloseConferenceDialog}
            mobile={mobile}
          />
        </ContentViewer>
        <StretchLayout className={className}>
          <StretchBody>
            <ConferenceList
              active={recentPastDueID || nextConferenceID || mostRecentID}
              conferenceIds={conferenceIds}
              IssueTeamWorkspaceID={IssueTeamWorkspaceID}
              IssueID={IssueID}
              FutureConferences={futureConferences}
              disabled={disabled}
              onEditInfoClicked={this.handleEditClick}
              onDeleteClicked={this.handleDeleteClick}
              onOpenDialogClicked={this.handleOpenDialog}
            />
            <ConferenceDeleteConfirmation
              open={this.state.ConferenceToDelete ? true : false}
              ConferenceID={(this.state.ConferenceToDelete || {}).ConferenceID}
              cancel={this.handleCancelDeleteClick}
            />
            <ConferenceInstructionDialog
              open={this.state.InstructionDialog ? true : false}
              ConferenceID={(this.state.InstructionDialog || {}).ConferenceID}
              cancel={this.handleInstructionDialog}
            />
          </StretchBody>
          <StretchFooter className="p-3">
            <Button
              onClick={this.handleCreateClick}
              color="primary"
              disabled={disabled}
            >
              Add
            </Button>
          </StretchFooter>
        </StretchLayout>
      </Fragment>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  conferenceIds: selectWorkspaceConferenceIds(
    state,
    ownProps.IssueTeamWorkspaceID
  ),
  conferenceIdMap: selectConferencesIdMap(state),
  futureConferences: selectWorkspaceFutureConferenceIds(
    state,
    ownProps.IssueTeamWorkspaceID
  ),
  nextConferenceID: (
    selectWorkspaceNextConference(state, ownProps.IssueTeamWorkspaceID) || {}
  ).ConferenceID,
  recentPastDueID: (
    selectWorkspaceRecentPastDueConference(
      state,
      ownProps.IssueTeamWorkspaceID
    ) || {}
  ).ConferenceID,
  mostRecentID: (
    selectWorkspaceRecentlyCreatedConference(
      state,
      ownProps.IssueTeamWorkspaceID
    ) || {}
  ).ConferenceID,
})

const mapDispatchToProps = {
  removeConferenceIds: ConferenceActions.deleteRangeSuccess,
}

WorkspaceConferenceViewer.propTypes = {
  IssueTeamWorkspaceID: PropTypes.number.isRequired,
  conferenceIds: PropTypes.arrayOf(PropTypes.number),
  IssueID: PropTypes.number,
  disabled: PropTypes.bool,
  mobile: PropTypes.bool,
}

WorkspaceConferenceViewer.defaultProps = {
  conferenceIds: [],
  mobile: false,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WorkspaceConferenceViewer)
