// @ts-nocheck
// TODO: Typescript
import { connect } from 'react-redux'
import { selectInboxByGUID } from '../Alert/_selectors'
import { Tag } from '../Shared/Tag'
import { cx } from '../_utils/objectUtils'
import { selectOrgTagById } from './_selectors'
import classes from './OrgTagShared.module.scss'
import * as React from 'react'
import { alertIcon } from './_constants'
import { OrgTagInboxIcon } from './OrgTagInboxIcon'

const mapStateToProps = (state, ownProps) => {
  let {
    TagID,
    TagKey,
    TagName,
    TagType,
    TagTypePkGuid,
    allowDelete,
    onDelete,
    useTagName,
    ...other
  } = ownProps
  let icon
  let iconDef
  let className = ownProps.className
  className = cx(className, classes.OrgTag)

  // Gather the redux tag properties
  const tag = selectOrgTagById(state, TagID) || {}
  const TagNameRedux = tag.TagName
  const TagTypeRedux = tag.TagType
  const TagTypePkGuidRedux = tag.TagTypePkGuid

  // Set the main properties as redux if the user hasn't passed in their own props
  if (!TagName) TagName = TagNameRedux

  if (!TagTypePkGuid) TagTypePkGuid = TagTypePkGuidRedux

  if (!TagType) TagType = TagTypeRedux

  if (TagType === 'alert' || TagType === 'inbox') {
    iconDef = alertIcon
    if (!allowDelete) onDelete = null

    className = cx(className, classes.inbox)
  }

  if (TagType === 'inbox' && TagTypePkGuid) {
    const inbox = selectInboxByGUID(state, TagTypePkGuid)
    if (inbox) {
      if (!useTagName) TagName = inbox.inboxName
      icon = <OrgTagInboxIcon inboxGUID={TagTypePkGuid} />
    }
  }

  return {
    TagName,
    TagID,
    iconDef,
    icon,
    ...other,
    className,
    onDelete,
  }
}

export default connect(mapStateToProps, undefined)(Tag)
