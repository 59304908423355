import { createSelector } from 'reselect'
import {
  getKeyValuePair,
  isNullOrUndefined,
  mergeShallow,
  sortMulti,
} from '../_utils/objectUtils'
import {
  AsyncActionType,
  callAction,
  ReducerAction,
  ReducerActionMap,
} from '../_utils/reduxUtils'
import { LogReadModel } from './_models'

type IDCallback = (action: ReducerAction) => string | number
type IDCallbackOrKey = (string | number) | IDCallback

export interface ReducerOptions<STATE = any> {
  primaryID: IDCallbackOrKey
  reducer?: (state: STATE, action: any) => ReducerActionMap
  payload?: (action: any) => any
}

export interface ReadReducerActions {
  fetchLatestDates: AsyncActionType
  updateRead: AsyncActionType
  setIsViewing: string
  incrementUnreadCount: string
  fetchUnreadCount: AsyncActionType
}

export interface ActivityLogReducerOptions extends ReducerOptions {
  readReducer?: (state: LogReadState, action: any) => ReducerActionMap
  countReducer?: (state: LogUnreadCountState, action: any) => ReducerActionMap
}

export interface LogReadState {
  [PrimaryID: string]: LogReadModel
}

export interface LogUnreadCountState {
  [PrimaryID: string]: number
}

export function createReadReducers(
  udfActions: ReadReducerActions,
  options: ActivityLogReducerOptions
) {
  const {
    fetchLatestDates,
    updateRead,
    setIsViewing,
    incrementUnreadCount,
    fetchUnreadCount,
  } = udfActions
  const { readReducer, countReducer, primaryID } = options || {}

  const getPrimaryID = getPrimaryIDFunction(primaryID)

  return {
    read: (state: LogReadState = {}, action: ReducerAction) => {
      let mapped: ReducerActionMap = {
        [fetchLatestDates.SUCCESS]: () => ({
          ...state,
          ...action.payload.result,
        }),
        [updateRead.SUCCESS]: () => {
          const id = getPrimaryID(action)
          const oldRead = state[getPrimaryID(action)] || {}
          return {
            ...state,
            [id]: {
              ...oldRead,
              LastRead: action.payload.result,
              RefreshLastRead: false,
            },
          }
        },
        [setIsViewing]: () => {
          const id = getPrimaryID(action)
          const oldRead = state[id] || {}
          return {
            ...state,
            [id]: {
              ...oldRead,
              isViewing: action.payload.result,
            },
          }
        },
        [incrementUnreadCount]: () => {
          const id = getPrimaryID(action)
          const oldRead = state[getPrimaryID(action)] || {}
          return {
            ...state,
            [id]: {
              ...oldRead,
              lastActivityDate: action.payload.result?.lastActivityDate,
              RefreshLastRead: true,
            },
          }
        },
      }

      if (readReducer) {
        const actionsObj = readReducer(state, action)

        for (let key in actionsObj) {
          mapped[key] = actionsObj[key]
        }
      }

      return callAction(mapped, state, action)
    },

    unreadCount: (state: LogUnreadCountState = {}, action: ReducerAction) => {
      const mapped: ReducerActionMap = {
        [fetchUnreadCount.SUCCESS]: () => {
          return {
            ...state,
            [getPrimaryID(action)]: action.payload.result,
          }
        },
        [updateRead.SUCCESS]: () => {
          return {
            ...state,
            [getPrimaryID(action)]: 0,
          }
        },
        [incrementUnreadCount]: () => {
          const id = getPrimaryID(action)
          return {
            ...state,
            [id]: (state[id] || 0) + 1,
          }
        },
      }

      if (countReducer) {
        const actionsObj = countReducer(state, action)

        for (let key in actionsObj) {
          mapped[key] = actionsObj[key]
        }
      }

      return callAction(mapped, state, action)
    },
  }
}

// LOG READ SELECTORS
export const isLogRefreshReadRequired = (
  state: LogReadState,
  primaryID: number
) => isNullOrUndefined(state[primaryID]?.RefreshLastRead, true)

export const isLogUnread = (state: LogReadState, primaryID: number) => {
  if (isNullOrUndefined(state[primaryID]?.isViewing, false) === true)
    return false

  const lastRead = state[primaryID]?.LastRead
  const lastActivity = state[primaryID]?.lastActivityDate

  if (!lastRead && lastActivity) return true

  if (!lastRead || !lastActivity) return false

  return lastActivity > lastRead
}
export const isViewingLog = (state: LogReadState, primaryID: number) =>
  state[primaryID]?.isViewing

export type FilterHashOrParams = string | number | object

interface ItemListModel<T> {
  isAllItemsLoaded: boolean
  loadedItems: { [key: string]: T }
}

interface ItemListState<T> {
  sortKeys?: string[]
  filterKeys?: string[]
  filterParams?: any
  lists?: {
    [key: string]: {
      filtered?: {
        [key: string]: ItemListModel<T>
      }
      unfiltered: ItemListModel<T>
    }
  }
}

export interface ItemListReducerActions {
  fetch: AsyncActionType | string
  add?: AsyncActionType | string
  remove?: AsyncActionType | string
  update?: AsyncActionType | string
  changeFilter?: string
  changeSort?: string
}

interface ItemListReducerOptions extends ReducerOptions {
  filterHash?: string | ((action: ReducerAction) => string | number)
  filterKeys?: string[]
  isAllItemsLoaded?: (action: ReducerAction) => boolean
  /** Callback for getting the hash map of items for a given item list. */
  itemIdMap?: (action: ReducerAction) => { [key: string]: any }
  /** Typically used for deleteing an item. Takes a key name or callback to get the actual value */
  itemID?: IDCallbackOrKey
  sortKeys?: string[] // ["col1,desc", col,asc"]
  onItemAddParentID?: (item: any) => string | number
  onAddItemID?: (item: any) => string | number
}
export function createItemListReducer<T>(
  udfACtions: ItemListReducerActions,
  options: ItemListReducerOptions
) {
  const { fetch, add, remove, update, changeFilter, changeSort } = udfACtions
  const {
    primaryID,
    itemID,
    filterHash,
    reducer,
    sortKeys,
    filterKeys,
    onItemAddParentID,
  } = options || {}
  let { isAllItemsLoaded, itemIdMap } = options || {}

  // NOTE: Example of a primaryID could be a a list of items belonging to a particular IssueID
  const getPrimaryID = getPrimaryIDFunction(primaryID)
  const getItemID = getPrimaryIDFunction(itemID)
  const getFilterHash = defaultFilterItemListHashByAction(
    filterHash,
    filterKeys
  )

  // The default assumes if, less results are returned than the page size then there are no more rows to load
  if (!isAllItemsLoaded)
    isAllItemsLoaded = ({ payload }: ReducerAction) =>
      payload.result?.ids?.length < payload.params.pageSize ? true : false

  if (!itemIdMap)
    itemIdMap = (action) =>
      action?.payload?.result?.idMap || action?.payload?.result?.itemIdMap

  return (state: ItemListState<T> = { sortKeys }, action: ReducerAction) => {
    let mapped: ReducerActionMap = {
      [typeof fetch === 'string' ? fetch : fetch.SUCCESS]: () => {
        return updateItems()
      },
    }

    if (add) {
      mapped[typeof add === 'string' ? add : add.SUCCESS] = () => {
        const curLists = state.lists || {}
        const newItems = itemIdMap?.call(undefined, action)
        let newLists: { [key: string]: any } = {}
        for (let addItemKey in newItems) {
          const curItem = newItems[addItemKey]
          const parentID = onItemAddParentID?.call(undefined, curItem) || 0
          if (!newLists[parentID]) {
            if (curLists[parentID])
              newLists[parentID] = {
                ...curLists[parentID],
                unfiltered: {
                  ...curLists[parentID].unfiltered,
                  loadedItems: {
                    ...curLists[parentID].unfiltered.loadedItems,
                  },
                },
              }
            else
              newLists[parentID] = {
                unfiltered: { loadedItems: {} },
              }
          }

          newLists[parentID].unfiltered.loadedItems[addItemKey] = curItem
        }

        return {
          ...state,
          lists: {
            ...state.lists,
            ...newLists,
          },
        }
      }
    }

    if (remove) {
      mapped[typeof remove === 'string' ? remove : remove.SUCCESS] = () => {
        const id = getPrimaryID(action)
        if (!id) return state

        const filter = getFilterHash(action)

        const lists = state.lists || {}
        let loadedItems: { [key: string]: T } = {}
        if (filter)
          loadedItems = (lists[id].filtered || {})[filter]?.loadedItems
        else loadedItems = lists[id].unfiltered?.loadedItems

        let newItems: { [key: string]: T } = {}
        let strId = getItemID(action)?.toLocaleString()
        for (var key in loadedItems) {
          if (strId !== key.toString()) newItems[key] = loadedItems[key]
        }

        return updateItems({
          updateLoadedFlag: false,
          newItems,
        })
      }
    }

    if (update) {
      mapped[typeof update === 'string' ? update : update.SUCCESS] = () => {
        return updateItems({
          updateLoadedFlag: false,
          mergeItems: true,
        })
      }
    }

    if (changeSort)
      mapped[changeSort] = () => ({
        ...state,
        sortKeys: action.payload?.params || action.payload?.result,
      })

    if (changeFilter)
      mapped[changeFilter] = () => ({
        ...state,
        filterParams: action.payload?.params || action.payload?.result,
      })

    interface UpdateItemsOptions {
      updateLoadedFlag?: boolean
      includeOldItems?: boolean
      newItems?: { [key: string]: any }
      mergeItems?: boolean
    }

    function updateItems({
      updateLoadedFlag = true,
      includeOldItems = true,
      newItems,
      mergeItems = false,
    }: UpdateItemsOptions = {}) {
      const id = getPrimaryID(action)
      if (!id) return state

      const filter = getFilterHash(action)

      let isLoaded = isAllItemsLoaded?.call(undefined, action)
      const lists = state.lists || {}
      let loadedItems: { [key: string]: T } = {}
      let oldItems: { [key: string]: T } = {}

      if (includeOldItems) oldItems = (lists[id]?.unfiltered || {}).loadedItems

      if (mergeItems)
        loadedItems = mergeShallow(
          oldItems,
          newItems || itemIdMap?.call(undefined, action)
        )
      else
        loadedItems = {
          ...oldItems,
          ...(newItems || itemIdMap?.call(undefined, action)),
        }

      if (filter) {
        /*if (includeOldItems || mergeItems)
                    oldItems = (lists[id]?.filtered || {})[filter].loadedItems;

                if (mergeItems)
                    loadedItems = mergeShallow(oldItems, (newItems || itemIdMap?.call(undefined, action)));
                else
                    loadedItems = { ...oldItems, ...(newItems || itemIdMap?.call(undefined, action)) };*/

        // Only update the isAllItemsLoaded flag for filtered. unfiltered should store all items.
        // Filtering is done at the select level
        return {
          ...state,
          lists: {
            ...lists,
            [id]: {
              ...lists[id],
              filtered: {
                ...(lists[id]?.filtered || {}),
                [filter]: {
                  ...(lists[id]?.filtered || {})[filter],
                  isAllItemsLoaded: isLoaded /*,
                                    loadedItems*/,
                },
              },
              unfiltered: {
                ...lists[id]?.unfiltered,
                loadedItems,
              },
            },
          },
        }
      }

      /*if (includeOldItems)
                oldItems = (lists[id]?.unfiltered || {}).loadedItems;

            if (mergeItems)
                loadedItems = mergeShallow(oldItems, (newItems || itemIdMap?.call(undefined, action)));
            else
                loadedItems = { ...oldItems, ...(newItems || itemIdMap?.call(undefined, action)) };*/

      return {
        ...state,
        lists: {
          ...lists,
          [id]: {
            ...(state.lists || {})[id],
            unfiltered: {
              ...lists[id]?.unfiltered,
              isAllItemsLoaded: isLoaded,
              loadedItems,
            },
          },
        },
      }
    }

    if (reducer) {
      const actionsObj = reducer(state, action)

      for (let key in actionsObj) {
        mapped[key] = actionsObj[key]
      }
    }

    return callAction(mapped, state, action)
  }
}

// List Selectors NOTE: Example of a listID could be a a list of items belonging to a particular IssueID
export const selectListByIdMap = <T>(
  state: ItemListState<T>,
  listID: number | string,
  filterHashOrParams?: FilterHashOrParams
) => {
  let filterHash = ''
  if (typeof filterHashOrParams === 'object')
    filterHash = paramsToHash(filterHashOrParams)

  const lists = state.lists || {}
  if (filterHash) return (lists[listID]?.filtered || {})[filterHash]

  return lists[listID]?.unfiltered
}

export const selectListLoadedItems = <T>(
  state: ItemListState<T>,
  listID: number | string /*, filterHashOrParams?: FilterHashOrParams*/
) => {
  return selectListByIdMap<T>(state, listID /*, filterHashOrParams*/)
    ?.loadedItems
}

export const isAllListItemsLoaded = <T>(
  state: ItemListState<T>,
  listID: number | string,
  filterHashOrParams?: FilterHashOrParams
) => {
  return (
    selectListByIdMap<T>(state, listID, filterHashOrParams)?.isAllItemsLoaded ||
    selectListByIdMap<T>(state, listID)?.isAllItemsLoaded
  )
}

export const selectListFilterParams = <T = any>(state: ItemListState<T>) =>
  state.filterParams

export const selectListSortKeys = <T>(state: ItemListState<T>) => state.sortKeys

const selectParams = (_: any, params: any) => params
export const createFilteredListSelector = <T = any, P = any>(
  isValid: (item: T, params: P) => boolean,
  useStateParams: boolean = true
) =>
  createSelector(
    selectListLoadedItems as any,
    selectListSortKeys as any,
    useStateParams ? selectListFilterParams : (selectParams as any),
    (loadedItems: any, sortKeys: string[], params: P) => {
      loadedItems = loadedItems || {}
      let result: T[] = []

      for (let key in loadedItems) {
        const item = loadedItems[key]
        if (isValid(item, params)) result.push(item)
      }

      return result.sort((a, b) => sortMulti(a, b, sortKeys))
    }
  ) as (state: ItemListState<T>, listID: string | number) => T[]

function paramsToHash(params: object, filterKeys?: string[]) {
  let result: any[][] = []
  params = params || {}
  if (filterKeys) {
    filterKeys.forEach((key) => {
      const keyValue = getKeyValuePair(params, key)
      if (!keyValue) return

      let isValueArr = keyValue.value instanceof Array
      if (
        (isValueArr && keyValue.value?.length > 0) ||
        (!isValueArr && keyValue.value)
      )
        result.push([keyValue.normalizedKey, keyValue.value])
    })
  } else result = Object.entries(params)

  if (result.length === 0) return ''

  result = result.sort()
  result.forEach((kvPair, index) => {
    if (kvPair[1] instanceof Array)
      result[index] = [kvPair[0], kvPair[1].sort()]
  })

  return JSON.stringify(result)
}

function defaultFilterItemListHashByAction(
  filterHash?: string | ((action: ReducerAction) => string | number),
  filterKeys?: string[]
) {
  if (!filterHash)
    return (action: ReducerAction) =>
      paramsToHash({ ...action, ...action.payload?.params }, filterKeys)
  return typeof filterHash === 'function'
    ? filterHash
    : (action: ReducerAction) =>
        action.payload.params[filterHash as string] as string | number
}

function getPrimaryIDFunction(primaryID?: IDCallbackOrKey): IDCallback {
  if (!primaryID) return () => ''

  if (typeof primaryID === 'function') return primaryID

  return (action: ReducerAction) => {
    const payload = action.payload || {}
    const params = payload.params || {}
    const source = payload.source || {}
    const result = payload.result || {}
    return params[primaryID] || source[primaryID] || result[primaryID]
  }
}
