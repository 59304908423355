// @ts-nocheck
// TODO: Typescript
import React, { Fragment, Component } from 'react'
import IssueSummaryFeed from '../Issue/IssueSummaryFeed'
import { MasterPageLayout } from './MasterPageLayout'
import {
  DesktopLayout,
  MobileLayout,
  MobileMdOnlyLayout,
  MobileSmallLayout,
} from '../Layouts/ResponsiveHidden'
import { prefixSelectorWithApp } from '../_utils/domUtils'
import {
  StretchLayout,
  StretchHeader,
  StretchBody,
} from '../Layouts/StretchLayout'
import IssueTabFilter from '../Issue/IssueTabFilter'
import IssueFilterApplied from '../Issue/IssueFilterApplied'

const className = 'pb-2 pl-2 pr-2'

export class IssueSummaryFeedPage extends Component {
  constructor(props) {
    super(props)
    this.state = { teamsInfo: null }
  }

  render() {
    const props = this.props
    const { isAuthorized } = props
    return (
      <Fragment>
        <div>{!this.state.teamsInfo ? '' : this.state.teamsInfo}</div>
        <MasterPageLayout history={props.history}>
          {isAuthorized && (
            <DesktopLayout>
              <IssueSummaryFeed
                actionsBottom={
                  <IssueTabFilter className="w-100" scrollButtons="off" />
                }
                actions={<IssueFilterApplied />}
                id={prefixSelectorWithApp('IssueSummaryFeed')}
                className={className}
                {...props}
              />
            </DesktopLayout>
          )}
          {isAuthorized && (
            <StretchLayout>
              <StretchHeader>
                <IssueTabFilter
                  mobile={true}
                  className="w-100"
                  scrollButtons="off"
                />
              </StretchHeader>
              <StretchBody>
                <MobileLayout>
                  <MobileMdOnlyLayout>
                    <IssueSummaryFeed
                      id={prefixSelectorWithApp('IssueSummaryFeed')}
                      className={className}
                      hasFilter={true}
                      hasSearch={false}
                      {...props}
                      actions={<IssueFilterApplied />}
                    />
                  </MobileMdOnlyLayout>
                  <MobileSmallLayout>
                    <IssueSummaryFeed
                      actions={<IssueFilterApplied />}
                      id={prefixSelectorWithApp('IssueSummaryFeed')}
                      className={className}
                      hasFilter={true}
                      hasSearch={false}
                      {...props}
                    />
                  </MobileSmallLayout>
                </MobileLayout>
              </StretchBody>
            </StretchLayout>
          )}
        </MasterPageLayout>
      </Fragment>
    )
  }
}
