import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as React from 'react'
import { DashboardAssetList, DashboardIssueModel } from './DashboardModels'
import {
  GoogleMap,
  useLoadScript,
  Marker,
  Libraries,
  InfoWindow,
  MarkerProps,
} from '@react-google-maps/api'
import { googleApiKey } from '../../_utils/AppSettings'
import faBuilding from '../../_images/blue_house_marker.png'
import { useHistory } from 'react-router'
import { faReply } from '@fortawesome/pro-light-svg-icons'
import { Tooltip } from '@material-ui/core'
import { Spacer } from '../../Shared/Spacer'

interface IssueGeoInfo {
  issueName?: string
  issueDescription?: string
  issueId?: number
  orgName: string
  orgId: number
  locationData: {
    //Only these to start
    latitude: number
    longitude: number
  }
}

interface AssetGeoLocation {
  assetName: string
  assetDescription?: string
  orgId: number
  orgName: string
  assetTypeID: number
  assetTypeName: string
  locationData: {
    latitude: number
    longitude: number
  }
}

const options = {
  fullscreenControl: false, // This option removes the full-screen control button
}

const libraries = ['places']

type Location = {
  title?: string
  description?: string
  lat: number
  lng: number
  issueId?: number
  orgName: string
  orgId: number
  type: 'Asset' | 'Issue'
}
type LocationTooltip = {
  location: {
    latitude: number
    longitude: number
    assets?: AssetGeoLocation[]
    issues?: IssueGeoInfo[]
  }
}

const MyMarker = React.memo(
  ({ position, icon, title, onClick }: MarkerProps) => {
    return (
      <Marker position={position} icon={icon} title={title} onClick={onClick} />
    )
  }
)

export const DashboardIssueLocationSection = ({
  issues,
  assetList,
  userView,
}: {
  issues: DashboardIssueModel[]
  assetList?: DashboardAssetList[]
  userView?: boolean
}) => {
  const issuesGeoLocation: IssueGeoInfo[] = React.useMemo(() => [], [])
  const assetsGeoLocation: AssetGeoLocation[] = React.useMemo(() => [], [])

  const [issueGeolocationState, setIssueGeolocationState] = React.useState<
    IssueGeoInfo[] | undefined
  >()
  const [assetGeolocationState, setAssetGeolocationState] = React.useState<
    AssetGeoLocation[] | undefined
  >()

  const history = useHistory()
  const [displayIssues, setDisplayIssues] = React.useState(true)
  const [displayAssets, setDisplayAssets] = React.useState(true)
  const [availableTooltips, setAvailableTooltips] = React.useState<
    LocationTooltip[] | null
  >(null)
  const [selectedTooltip, setSelectedTooltip] =
    React.useState<LocationTooltip>()

  const toggleDisplayIssues = () => {
    setDisplayIssues(!displayIssues)
  }

  const toggleDisplayAssets = () => {
    setDisplayAssets(!displayAssets)
  }

  const [selectedLocation, setSelectedLocation] = React.useState<{
    latitude: number
    longitude: number
  } | null>(null)

  const mapContainerStyle = {
    width: '100%',
    height: '100%',
  }

  React.useEffect(() => {
    const listener = (e: any) => {
      if (e.key === 'Escape') {
        setSelectedLocation(null)
      }
    }
    window.addEventListener('keydown', listener)
    return () => {
      window.removeEventListener('keydown', listener)
    }
  }, [])

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: googleApiKey,
    libraries: libraries as Libraries,
    preventGoogleFontsLoading: false,
  })

  const [map, setMap] = React.useState(null)

  const combineGeoData = (
    assets: AssetGeoLocation[],
    issues: IssueGeoInfo[]
  ): LocationTooltip[] => {
    const combinedData: { [key: string]: LocationTooltip } = {}

    assets.forEach((asset) => {
      const key = `${asset.locationData.latitude},${asset.locationData.longitude}`
      if (!combinedData[key]) {
        combinedData[key] = {
          location: {
            latitude: asset.locationData.latitude,
            longitude: asset.locationData.longitude,
            assets: [],
            issues: [],
          },
        }
      }
      combinedData[key].location.assets?.push(asset)
    })

    issues.forEach((issue) => {
      const key = `${issue.locationData.latitude},${issue.locationData.longitude}`
      if (!combinedData[key]) {
        combinedData[key] = {
          location: {
            latitude: issue.locationData.latitude,
            longitude: issue.locationData.longitude,
            assets: [],
            issues: [],
          },
        }
      }
      combinedData[key].location.issues?.push(issue)
    })
    return Object.values(combinedData)
  }

  const onLoad = React.useCallback(
    (map) => {
      const allLocations = [...issuesGeoLocation, ...assetsGeoLocation]

      if (allLocations.length === 0) {
        return
      }
      const bounds = new google.maps.LatLngBounds()
      allLocations.forEach((location) => {
        if (location.locationData.latitude && location.locationData.longitude) {
          const point = new google.maps.LatLng(
            location.locationData.latitude,
            location.locationData.longitude
          )
          bounds.extend(point)
        }
      })

      map.fitBounds(bounds)

      setMap(map)
    },
    [issuesGeoLocation, assetsGeoLocation]
  )

  React.useEffect(() => {
    if (
      issueGeolocationState &&
      assetGeolocationState &&
      (issueGeolocationState?.length > 0 || assetGeolocationState?.length > 0)
    ) {
      setAvailableTooltips(combineGeoData(assetsGeoLocation, issuesGeoLocation))
    }
  }, [
    issueGeolocationState,
    assetGeolocationState,
    assetsGeoLocation,
    issuesGeoLocation,
  ])

  React.useEffect(() => {
    const selectedTooltip = availableTooltips?.find(
      (location) =>
        location.location.latitude === selectedLocation?.latitude &&
        location.location.longitude === selectedLocation?.longitude
    )

    setSelectedTooltip(selectedTooltip)
  }, [availableTooltips, selectedLocation])

  React.useEffect(() => {
    issues?.forEach((issues) => {
      issues.GeoLocation?.forEach((location) => {
        if (location.ShouldDisplayYN === 'Y') {
          issuesGeoLocation.push({
            issueName: issues.IssueName,
            issueDescription: issues.Description,
            issueId: issues.IssueID,
            orgName: issues.OrgName,
            orgId: issues.OrgID,
            locationData: {
              latitude: location.Latitude,
              longitude: location.Longitude,
            },
          })
        }
      })
    })

    setIssueGeolocationState(issuesGeoLocation)
  }, [issues, issuesGeoLocation])

  React.useEffect(() => {
    if (assetList) {
      assetList.forEach((assetTypeGrouping) => {
        assetTypeGrouping.AssetType.forEach((assetType) => {
          assetType.Asset.forEach((asset) => {
            assetsGeoLocation.push({
              assetName: asset.Name,
              assetDescription: asset.Description,
              orgId: assetTypeGrouping.OrgID,
              orgName: assetTypeGrouping.OrgName,
              assetTypeID: assetType.AssetTypeID,
              assetTypeName: assetType.AssetTypeName,
              locationData: {
                latitude: asset.Latitude,
                longitude: asset.Longitude,
              },
            })
          })
        })
      })
    }

    setAssetGeolocationState(assetsGeoLocation)
  }, [assetList, assetsGeoLocation])

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null)
  }, [])

  if (loadError) {
    return <div>Error loading maps</div>
  }

  if (!isLoaded) {
    return <div>Loading maps</div>
  }

  if (!issues) {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
        }}
      >
        No issues
      </div>
    )
  }

  const googleMapSection = (
    <GoogleMap
      mapContainerStyle={mapContainerStyle}
      onLoad={onLoad}
      options={options}
      onUnmount={onUnmount}
      onClick={() => {
        setSelectedLocation(null)
      }}
    >
      {displayIssues &&
        issuesGeoLocation.map((issue, index) => {
          if (issue.locationData.latitude && issue.locationData.longitude) {
            return (
              <MyMarker
                key={index}
                position={{
                  lat: issue.locationData.latitude,
                  lng: issue.locationData.longitude,
                }}
                title={issue.issueName}
                onClick={() => {
                  setSelectedLocation({
                    latitude: issue.locationData.latitude,
                    longitude: issue.locationData.longitude,
                  })
                }}
              />
            )
          } else {
            return false
          }
        })}

      {displayAssets &&
        assetsGeoLocation?.map((asset, index) => {
          if (asset.locationData.latitude && asset.locationData.longitude) {
            return (
              <Marker
                key={index}
                position={{
                  lat: asset.locationData.latitude,
                  lng: asset.locationData.longitude,
                }}
                zIndex={0}
                icon={{
                  url: faBuilding,
                  scaledSize: new window.google.maps.Size(40, 40),
                }}
                title={asset.assetName}
                onClick={() => {
                  setSelectedLocation({
                    latitude: asset.locationData.latitude,
                    longitude: asset.locationData.longitude,
                  })
                }}
              />
            )
          } else {
            return false
          }
        })}

      {selectedLocation && selectedTooltip && (
        <InfoWindow
          onCloseClick={() => {
            setSelectedLocation(null)
          }}
          position={{
            lat: selectedLocation.latitude,
            lng: selectedLocation.longitude,
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              maxHeight: '275px',
              overflowY: 'auto',
              position: 'relative',
            }}
          >
            <div>
              <div>
                {selectedTooltip.location.assets &&
                  selectedTooltip.location.assets.length > 0 && (
                    <div>
                      <h3
                        style={{
                          textDecoration: 'underline',
                          fontSize: '30px',
                        }}
                      >
                        Assets
                      </h3>{' '}
                      <Spacer /> <Spacer />
                    </div>
                  )}
                {selectedTooltip.location.assets &&
                selectedTooltip.location.assets.length > 0 ? (
                  selectedTooltip.location.assets.map((asset, index) => (
                    <div key={index}>
                      <h4>{asset.assetName}</h4>
                      <p>
                        Description:{' '}
                        {asset.assetDescription || 'No description available'}
                      </p>
                      <p>
                        Organization: {asset.orgName} (ID: {asset.orgId})
                      </p>
                    </div>
                  ))
                ) : (
                  <></>
                )}
              </div>
              <div>
                {selectedTooltip.location.issues &&
                  selectedTooltip.location.issues.length > 0 && (
                    <div>
                      <h3
                        style={{
                          textDecoration: 'underline',
                          fontSize: '30px',
                        }}
                      >
                        Issues
                      </h3>{' '}
                      <Spacer /> <Spacer />
                    </div>
                  )}
                {selectedTooltip.location.issues &&
                selectedTooltip.location.issues.length > 0 ? (
                  selectedTooltip.location.issues.map((issue, index) => (
                    <div
                      key={index}
                      style={{ borderBottom: '1px solid black' }}
                    >
                      <Spacer />
                      <h4>{issue.issueName || 'Unnamed Issue'}</h4>
                      <p>
                        Description:{' '}
                        {issue.issueDescription || 'No description available'}
                      </p>

                      <Tooltip title={'Go to issue'}>
                        <FontAwesomeIcon
                          icon={faReply}
                          size={'2x'}
                          style={{ cursor: 'pointer' }}
                          onClick={() =>
                            history.push({
                              pathname: '/IssueDetail/' + issue.issueId,
                            })
                          }
                        />
                      </Tooltip>
                      <Spacer />
                      <div>Organization: {issue.orgName}</div>
                    </div>
                  ))
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        </InfoWindow>
      )}
    </GoogleMap>
  )

  return (
    <div
      style={{
        height: '100%',
        width: '100%',
        display: 'flex',
        alignItems: ' center',
        justifyContent: 'center',
        flexDirection: 'column',
      }}
    >
      {' '}
      {issuesGeoLocation.length > 0 || assetsGeoLocation.length > 0 ? (
        <div
          style={{
            position: 'relative',
            height: '100vh',
            width: '100%',
          }}
        >
          {(issuesGeoLocation.length > 0 || assetsGeoLocation.length > 0) && (
            <>
              <div
                style={{
                  position: 'absolute',
                  bottom: '10px',
                  left: '10px',
                  zIndex: 1,
                  backgroundColor: 'white',
                  padding: '15px',
                }}
              >
                {issuesGeoLocation.length > 0 && (
                  <div>
                    <input
                      type="checkbox"
                      id="displayIssues"
                      checked={displayIssues}
                      onChange={toggleDisplayIssues}
                    />
                    <label>Display Issues</label>
                  </div>
                )}

                {assetsGeoLocation.length > 0 && (
                  <>
                    <Spacer />
                    <div>
                      <input
                        type="checkbox"
                        id="displayAssets"
                        checked={displayAssets}
                        onChange={toggleDisplayAssets}
                      />
                      <label>Display Assets</label>
                    </div>
                  </>
                )}
              </div>
            </>
          )}
          <div style={{ height: '100%', width: '100%' }}>
            {googleMapSection}
          </div>
        </div>
      ) : (
        <div>There are no issues with location data</div>
      )}
    </div>
  )
}
