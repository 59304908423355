import { generateActions } from '../_utils/reduxUtils'
import {
  FETCH_PLAYBOOK_LIST,
  FETCH_PLAYBOOK_UPDATES,
  SAVE_PLAYBOOK_SEARCH,
} from './_constants'

export const fetchPlaybookList = generateActions(FETCH_PLAYBOOK_LIST)
export const fetchPlaybookUpdates = generateActions(FETCH_PLAYBOOK_UPDATES)

export function SavePlaybookSearch(SearchValue: string) {
  return { type: SAVE_PLAYBOOK_SEARCH, payload: { SearchValue } }
}
