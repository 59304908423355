// @ts-nocheck
// TODO: Typescript
import { WebSocketConstants } from './_constants'

const initialState = {
  isReconnected: true,
  isConnected: false,
  isConnecting: true,
  isReconnecting: false,
}
export default function webSocketReducer(state = initialState, action) {
  switch (action.type) {
    case WebSocketConstants.CONNECTING:
      return Object.assign({}, state, {
        isConnected: false,
        isReconnected: false,
        isConnecting: true,
        isAwaitingRetry: false,
      })
    case WebSocketConstants.CONNECTION_DISCONNECTED:
    case WebSocketConstants.FORCE_DROP_CONNECTION_SUCCESSFUL:
    case WebSocketConstants.CONNECTION_RETRY_FAILURE:
    case WebSocketConstants.CONNECTION_FAILURE:
      // If the connection is forced to drop by the user, an auto retry will not be triggered
      var isAwaitingRetry =
        WebSocketConstants.FORCE_DROP_CONNECTION_SUCCESSFUL === action.type
          ? false
          : true
      return Object.assign({}, state, {
        isConnected: false,
        isConnecting: false,
        isReconnected: false,
        isAwaitingRetry,
        isReconnecting: false,
      })
    case WebSocketConstants.CONNECTION_RETRY:
      return {
        ...state,
        isReconnecting: true,
      }
    case WebSocketConstants.CONNECTION_SUCCESSFUL:
    case WebSocketConstants.CONNECTION_RETRY_SUCCESSFUL:
      return Object.assign({}, state, {
        isConnected: true,
        isReconnected:
          action.type === WebSocketConstants.CONNECTION_RETRY_SUCCESSFUL,
        isConnecting: false,
        isAwaitingRetry: false,
        isReconnecting: false,
      })
    default:
      return state
  }
}

export const isWebsocketConnecting = (state) => state.isConnecting
export const isWebsocketConnected = (state) => state.isConnected
export const isWebsocketDisconnected = (state) => !state.isConnected
export const isWebsocketReconnected = (state) => state.isReconnected
