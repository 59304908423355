// @ts-nocheck
// TODO: Typescript
import { buffers, eventChannel, END } from 'redux-saga'

export default function createSubmitFormChannel(url, formData) {
  return eventChannel((emitter) => {
    const xhr = new XMLHttpRequest()

    const onProgress = (event) => {
      if (event.lengthComputable) {
        const progress = Math.round(event.loaded / event.total)
        emitter({ progress })
      }
    }

    const onFailure = () => {
      emitter({ err: new Error('Upload failed') })
      emitter(END)
    }

    xhr.upload.addEventListener('progress', onProgress)
    xhr.upload.addEventListener('error', onFailure)
    xhr.upload.addEventListener('abort', onFailure)
    xhr.onreadystatechange = () => {
      const { readyState, status } = xhr
      if (readyState === 4) {
        if (status === 200) {
          emitter({
            success: true,
            response: xhr.response ? JSON.parse(xhr.response) : null,
          })
          emitter(END)
        } else {
          onFailure(null)
        }
      }
    }

    xhr.open('POST', url, true)
    xhr.send(formData)

    return () => {
      xhr.upload.removeEventListener('progress', onProgress)
      xhr.upload.removeEventListener('error', onFailure)
      xhr.upload.removeEventListener('abort', onFailure)
      xhr.onreadystatechange = null
      xhr.abort()
    }
  }, buffers.sliding(2))
}
