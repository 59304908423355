// @ts-nocheck
// TODO: Typescript
import React, { Component } from 'react'
import Moment from 'react-moment'
import 'moment-timezone'
import { getLocalDate } from '../_utils/dateUtils'
import classes from './ChatDetailItem.module.scss'
import { appDateTimeFormat } from '../_constants'
import ArtifactPreview from '../Upload/ArtifactPreview'
import PropTypes from 'prop-types'

const artifactPreviewClasses = {
  artifactTile: classes.message,
}

/**
 * @extends {Component<Props, {}>}}
 * Chat items that displays chat/comment entries
 */
export class ChatDetailItem extends Component {
  render() {
    const {
      ChatEntryID,
      CreatedUTC,
      EntryCreatedBy,
      Content,
      ArtifactID,
      onClickThumbnail,
      backGRDColor,
      isSearchResult,
    } = this.props
    const className = isSearchResult ? classes.rootSearch : classes.root
    function DateBlock(props) {
      if (props.date) {
        return (
          <div className={props.className}>
            <Moment
              local
              format={appDateTimeFormat}
              date={getLocalDate(props.date)}
            />
          </div>
        )
      } else {
        return ''
      }
    }

    return (
      <li
        data-private="lipsum"
        id={ChatEntryID}
        key={ChatEntryID}
        className={className}
        onClick={() => this.handleCheck(ChatEntryID)}
        style={{
          backgroundColor: backGRDColor,
          borderwidth: 5,
        }}
      >
        {!ArtifactID && (
          <div
            className={classes.message}
            dangerouslySetInnerHTML={{
              __html: Content || '',
            }}
          />
        )}
        {!ArtifactID ? null : (
          <ArtifactPreview
            className={classes.artifactPreview}
            ArtifactID={ArtifactID}
            showCreatedUTC={false}
            onClickThumbnail={onClickThumbnail}
            hideTitle={false}
            border={false}
            classes={artifactPreviewClasses}
          />
        )}
        <div className={classes.header}>
          <div className={classes.user}>{EntryCreatedBy}</div>
          <DateBlock className={classes.createdDate} date={CreatedUTC} />
        </div>
      </li>
    )
  }

  handleCheck(ChatEntryID) {
    if (typeof this.props.onChange === 'function') {
      this.props.onChange(ChatEntryID)
    }
  }
}

ChatDetailItem.propTypes = {
  ChatEntryID: PropTypes.number.isRequired,
  CreatedUTC: PropTypes.instanceOf(Date),
  Content: PropTypes.string,
  EntryCreatedBy: PropTypes.string,
  ArtifactID: PropTypes.any,
  onClickThumbnail: PropTypes.func,
}

ChatDetailItem.defaultProps = {
  Content: '',
}
