import React, { useCallback, useEffect } from 'react'
import { faEnvelopeOpen } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Select, MenuItem, Checkbox, FormControl } from '@material-ui/core'
import { Label, Button } from 'reactstrap'
import { cx } from '../_utils/objectUtils'
import classes from '../Alert/Alerts.module.scss'
import svepop from './ShareViaEmailPopup.module.css'
import { useAppDispatch } from '../_utils/reactReduxHooks'
import { shareAlert } from './_actions'
import { AlertToDBModel, ShareAlertInfo } from './_models'
import { addNotification } from '../_actions'
import { getLocalDate } from '../_utils/dateUtils'

export interface ShareViaEmailProps {
  alertToDB: AlertToDBModel
  handleClose: () => void
}

export const ShareViaEmailPopup = ({
  alertToDB,
  handleClose,
}: ShareViaEmailProps) => {
  const [groupList, setGroupList] = React.useState<Array<string>>([])
  const [emailList, setEmailList] = React.useState<Array<string>>([])
  const [targetEmail, setTargetEmail] = React.useState<any>('')
  const [emailPickList, setEmailPickList] = React.useState<Array<string>>([])
  const [groupPickList, setGroupPickList] = React.useState<Array<string>>([])
  const [emailComment, setEmailComment] = React.useState<any>('')

  const dispatch = useAppDispatch()

  const getShareList = useCallback(() => {
    // new 3-Jun-2022 by DRH of RDS
    const requestOptions: any = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        inboxID: alertToDB.inboxID,
        getAction: 'sharelist',
      },
    }
    fetch('/api/alert', requestOptions)
      .then(async (response) => {
        const data = await response.json()
        // check for error response
        if (!response.ok) {
          // get error message from body or default to response status
          const error = (data && data.message) || response.status
          return Promise.reject(error)
        }

        if (data.error) {
          dispatch(addNotification({ message: data.error, type: 'error' }))
        } else {
          setEmailList(data.email_list ? data.email_list.split(',') : [])
          setGroupList(data.group_list ? data.group_list.split(',') : [])
        }
      })
      .catch((error) => {
        console.error('There was an error!', error)
      })
  }, [alertToDB.inboxID, dispatch])

  useEffect(() => {
    getShareList()
  }, [getShareList])

  const isEmailChecked = useCallback(
    (email: string) => {
      return emailPickList.includes(email)
    },
    [emailPickList]
  )

  const isGroupChecked = useCallback(
    (group: string) => {
      return groupPickList.includes(group)
    },
    [groupPickList]
  )

  const handleTargetEmailChange = useCallback((evt: any) => {
    setTargetEmail(evt.target.value)
  }, [])

  const handleEmailClick = useCallback(
    (email: string) => {
      if (emailPickList.includes(email)) {
        let newPickList = [...emailPickList]
        newPickList.splice(newPickList.indexOf(email), 1)
        setEmailPickList(newPickList)
      } else {
        setEmailPickList((prev) => {
          let newPickList = [...prev]
          newPickList.push(email)
          return newPickList
        })
      }
    },
    [emailPickList]
  )

  const handleGroupClick = useCallback(
    (group: string) => {
      if (groupPickList.includes(group)) {
        let newPickList = [...groupPickList]
        newPickList.splice(newPickList.indexOf(group), 1)
        setGroupPickList(newPickList)
      } else {
        setGroupPickList((prev) => {
          let newPickList = [...prev]
          newPickList.push(group)
          return newPickList
        })
      }
    },
    [groupPickList]
  )

  const handleEmailCommentChange = useCallback((evt: any) => {
    setEmailComment(evt.target.value)
  }, [])

  //todo I need to step in here and direct the stored procedure to the correct place based on if the alert has been saved yet or not
  const handleEmailPopupSubmit = useCallback(() => {
    // this is where I call to the backend
    let infoForShare: ShareAlertInfo = {
      alert: {
        ...alertToDB,
        action: 'share',
        alert_Updated: getLocalDate(alertToDB.alert_Updated)
      },
      shareDetails: {},
    }
    let hasEmail: boolean = false
    if (targetEmail) {
      hasEmail = true
    }
    if (emailPickList.length > 0) {
      hasEmail = true
    }
    if (groupPickList.length > 0) {
      hasEmail = true
    }
    if (hasEmail) {
      infoForShare.shareDetails.targetEmail = encodeURIComponent(targetEmail)
      infoForShare.shareDetails.pickEmail = encodeURIComponent(
        emailPickList.join(',')
      )
      infoForShare.shareDetails.pickGroup = encodeURIComponent(
        groupPickList.join(',')
      )
      infoForShare.shareDetails.AlertID = alertToDB.alertID
      infoForShare.shareDetails.emailComment = encodeURIComponent(emailComment)
      infoForShare.shareDetails.inboxID = alertToDB.inboxID
        ? alertToDB.inboxID
        : -1
      infoForShare.shareDetails.alertTitle = alertToDB.alert_Title
        ? encodeURI(alertToDB.alert_Title)
        : undefined
      infoForShare.shareDetails.alertContent = alertToDB.alert_Content
        ? encodeURI(alertToDB.alert_Content)
        : undefined
      if (alertToDB.alert_URL) {
        let safeURL = alertToDB.alert_URL
        if (safeURL.startsWith('http://')) {
          safeURL = safeURL.replace('http://', 'https://')
        }
        infoForShare.shareDetails.alertURL = encodeURI(safeURL)
      }

      dispatch(shareAlert.request(infoForShare))

      handleClose()
    }
  }, [
    alertToDB,
    dispatch,
    emailComment,
    handleClose,
    emailPickList,
    groupPickList,
    targetEmail,
  ])

  const renderEmailSelections = useCallback(() => {
    return (
      <div className="form-group">
        <label className="form-label">Choose from Inbox Team:</label>
        <Select
          className="form-control"
          name="pick_email"
          id="pick_email"
          native={false}
        >
          {emailList.map((email: string) => {
            return (
              <MenuItem value={email} onClick={() => handleEmailClick(email)}>
                <Checkbox color="primary" checked={isEmailChecked(email)} />
                {email}
              </MenuItem>
            )
          })}
        </Select>
      </div>
    )
  }, [emailList, handleEmailClick, isEmailChecked])

  const renderGroupSelections = useCallback(() => {
    return (
      <div className="form-group">
        <label className="form-label">Choose from PeopleSync Groups:</label>
        <Select
          className="form-control"
          name="pick_group"
          id="pick_group"
          native={false}
        >
          {groupList.map((group: string) => {
            return (
              <MenuItem value={group} onClick={() => handleGroupClick(group)}>
                <Checkbox color="primary" checked={isGroupChecked(group)} />
                {group}
              </MenuItem>
            )
          })}
        </Select>
      </div>
    )
  }, [groupList, handleGroupClick, isGroupChecked])

  return (
    <div className={svepop.popupBox}>
      <div className={svepop.box} style={{ minWidth: '400px' }}>
        <span className={svepop.closeIcon} onClick={handleClose}>
          x
        </span>
        <div style={{ minWidth: '400px' }}>
          <h2 style={{ textAlign: 'center' }}>Share via Email</h2>
          {emailList.length > 0 && renderEmailSelections()}
          {groupList.length > 0 && renderGroupSelections()}
          <div className="form-group">
            <label className="form-label">Other Email:</label>
            <input
              className="form-control"
              type="text"
              name="Email"
              id="Email"
              value={targetEmail}
              onChange={handleTargetEmailChange}
            />
          </div>
          <div>
            <FormControl style={{ width: '100%' }}>
              <Label>Comment:</Label>
              <textarea
                rows={4}
                cols={50}
                onChange={handleEmailCommentChange}
                value={emailComment}
              />
            </FormControl>
          </div>
        </div>
        <br />
        <p style={{ textAlign: 'center' }}>
          <Button
            className={cx(classes.btnAlertDetail, classes.btnAlertMenu)}
            name="submit"
            onClick={handleEmailPopupSubmit}
          >
            <FontAwesomeIcon
              className={classes.rdsBlue}
              icon={faEnvelopeOpen}
            />
            <span className={classes.btnAlertDetSpan}>Send</span>
          </Button>
        </p>
      </div>
    </div>
  )
}
