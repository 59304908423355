// @ts-nocheck
// TODO: Typescript
import Moment from 'react-moment'
import { getLocalDate } from '../_utils/dateUtils'
import {
  Button,
  ButtonDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'reactstrap'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import {
  approveHoldingStatement,
  updateHoldingStatement,
} from '../HoldingStatement/_actions'
import { appDateTimeFormat } from '../_constants'
import { IcoTipTapContainer, TiptapEditor } from '../Shared/IcoTipTapEditor'

import '../IcoTipTap.scss'

import { HoldingStatementList } from '../HoldingStatement/HoldingStatementList'
import classes from './IssueHoldingStatement.module.scss'
import { addNotification, closeNotification } from '../_actions'
import PropTypes from 'prop-types'
import { Tooltip } from '@material-ui/core'
import { cx } from '../_utils/objectUtils'
import {
  selectCurrentHoldingStatementIds,
  selectIssueHoldingStatementIds,
} from './_selectors'
import {
  isUserIssueApprover,
  selectIssueOrgIds,
} from '../_rootConfigs/rootSelectors'
import { HoldingStatementTemplateDialog } from '../HoldingStatement/HoldingStatementTemplateDialog'

class IssueHoldingStatement extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isEditable: false,
      Content: this.props.Content || '',
      HoldingStatementID: this.props.HoldingStatementID || -1,
      HoldingStmt: this.props.HoldingStmt,
      openTemplate: false,
      templateAction: 'save',
      openTemplateDrop: false,
      _curValue: this.props.Content || '',
    }

    //this._curValue = this.props.Content || '';

    // Events
    this.onChange = this.onChange = this.onChange.bind(this)
    this.enableEdit = this.enableEdit.bind(this)
    this.save = this.save.bind(this)
    this.cancel = this.cancel.bind(this)
    this.approve = this.approve.bind(this)
    this.handleTemplateOpen = this.handleTemplateOpen.bind(this)
    this.handleTemplateClose = this.handleTemplateClose.bind(this)
    this.toggleTemplateDropdown = this.toggleTemplateDropdown.bind(this)
  }

  toggleTemplateDropdown() {
    this.setState({ openTemplateDrop: !this.state.openTemplateDrop })
  }

  handleTemplateOpen(templateAction: 'save' | 'load') {
    this.setState({ openTemplate: true, templateAction })
  }

  handleTemplateClose() {
    this.setState({ openTemplate: false, templateAction: 'save' })
  }

  renderWithTemplateActions(button: any, disabled?: boolean = false) {
    return (
      <ButtonDropdown
        isOpen={this.state.openTemplateDrop}
        disabled={disabled}
        toggle={this.toggleTemplateDropdown}
      >
        {button}
        <DropdownToggle caret color="primary" className={classes.btn} />
        <DropdownMenu>
          <DropdownItem onClick={() => this.handleTemplateOpen('save')}>
            Save as Template
          </DropdownItem>
        </DropdownMenu>
      </ButtonDropdown>
    )
  }

  render() {
    const { Content, disabled, className, isApprover, HoldingStmt, OrgID } =
      this.props

    function DateBlock(props) {
      if (props.date) {
        return (
          <div className={props.className}>
            <Moment
              local
              format={appDateTimeFormat}
              date={getLocalDate(props.date)}
            />
          </div>
        )
      } else {
        return ''
      }
    }

    let approverBtnTitle = ''
    if (!isApprover) approverBtnTitle = 'Must be an approver for this action'
    else if (HoldingStmt.length === 0)
      approverBtnTitle = 'Must enter a holding statement before approving'

    return (
      <div className={className}>
        <IcoTipTapContainer
          className={classes.content}
          hideControls={!this.state.isEditable}
          autoHideControls={false}
          disabled={disabled}
        >
          {
            <TiptapEditor
              readOnly={!this.state.isEditable || disabled}
              placeholder="Description..."
              value={
                this.state.isEditable ? this.state._curValue : Content || ''
              }
              onChange={this.onChange}
            />
          }
        </IcoTipTapContainer>
        <div className={classes.header}>
          <div className={classes.user}>Created By: {this.props.CreatedBy}</div>
          <DateBlock
            className={classes.createdDate}
            date={this.props.Created}
          />
        </div>
        {this.props.Approved ? (
          <div className={classes.header}>
            <div className={classes.user}>
              Approved By: {this.props.ApprovedBy}
            </div>
            <DateBlock
              className={classes.createdDate}
              date={this.props.Approved}
            />
          </div>
        ) : (
          ''
        )}
        {this.state.isEditable ? (
          <div>
            {' '}
            {/*Edit mode buttons*/}
            {this.renderWithTemplateActions(
              <Button
                color="primary"
                onClick={this.save}
                className={cx(classes.btn, classes.btnSave)}
                disabled={disabled}
              >
                Save
              </Button>,
              disabled
            )}
            <Button
              className={classes.btn}
              color="primary"
              onClick={() => this.handleTemplateOpen('load')}
              disabled={disabled}
            >
              Load Template
            </Button>
            <Button color="primary" onClick={this.cancel} disabled={disabled}>
              Cancel
            </Button>
          </div>
        ) : (
          <div>
            {' '}
            {/*read only mode buttons*/}
            <Button
              color="primary"
              onClick={this.enableEdit}
              className={classes.btn}
              disabled={disabled}
            >
              Edit
            </Button>
            <Tooltip
              title={approverBtnTitle}
              disableHoverListener={isApprover && HoldingStmt.length > 0}
            >
              <span className="d-inline-block">
                <Button
                  color="primary"
                  onClick={this.approve}
                  className={cx(
                    this.props.Approved ? classes.btn_hidden : classes.btn,
                    disabled || !isApprover ? classes.disabled : ''
                  )}
                  disabled={disabled || !isApprover || HoldingStmt.length === 0}
                >
                  Approve
                </Button>
              </span>
            </Tooltip>
          </div>
        )}
        {this.renderPreviousHoldingStatements()}
        <HoldingStatementTemplateDialog
          type={this.state.templateAction}
          onLoadTemplate={(template) => this.onChange(template.Content)}
          OrgID={OrgID}
          Content={this.state._curValue}
          open={this.state.openTemplate}
          onClose={this.handleTemplateClose}
        />
      </div>
    )
  }

  renderPreviousHoldingStatements() {
    const { disabled, HoldingStmt, HoldingStatementID } = this.props
    if (HoldingStmt.length > 0) {
      return (
        <div>
          <hr className={classes.line} />
          <div className={classes.header}> Previous Holding Statements: </div>
          <HoldingStatementList
            holdingStatementIds={HoldingStmt}
            currentHoldingStatementID={HoldingStatementID}
            disabled={disabled}
          />
        </div>
      )
    }
    return null
  }

  renderApprovedDate() {
    const { Approved } = this.props
    if (Approved)
      return (
        <div className={classes.createdDate}>
          Approved on :{' '}
          <Moment
            className={classes.createdDate}
            local
            format={appDateTimeFormat}
            date={getLocalDate(Approved)}
          />
        </div>
      )
    return null
  }

  onChange(newValue) {
    //this._curValue = newValue;
    this.setState({
      _curValue: newValue,
    })
  }

  save() {
    // filter out html tags from HTML content using regex and then trim whitespaces if present.
    var curValuelength = this.state._curValue
      .replace(/<(.|\n)*?>/g, '')
      .trim().length

    var isReady2Update = false
    var currentContent = this.props.Content || ''

    if (currentContent.length > 0) {
      if (this.state._curValue === currentContent || curValuelength === 0)
        isReady2Update = false
      else isReady2Update = true
    } else if (curValuelength > 0) isReady2Update = true

    if (isReady2Update) {
      this.props.updateHoldingStatement({
        Content: this.state._curValue,
        IssueID: this.props.IssueID,
      })
      this.setState({
        isEditable: false,
        Content: this.state._curValue,
      })
    } else {
      this.props.onError({
        message:
          'The Holding Statement is not changed or is blank. Please try again.',
        type: 'error',
        duration: 3000,
      })
    }
  }

  cancel() {
    this.setState({
      isEditable: false,
    })
  }

  approve() {
    this.props.approveHoldingStatement({
      IssueID: this.props.IssueID,
      HoldingStatementID: this.props.HoldingStatementID,
    })
  }

  enableEdit() {
    this.setState({
      isEditable: true,
    })
  }
}

IssueHoldingStatement.propTypes = {
  disabled: PropTypes.bool,
  isApprover: PropTypes.bool,
}

IssueHoldingStatement.defaultProps = {
  HoldingStmt: [],
}

const mapDispatchToProps = {
  updateHoldingStatement: (statement) => updateHoldingStatement(statement),
  approveHoldingStatement: (statement) => approveHoldingStatement(statement),
  onSuccess: closeNotification,
  onError: (notification) => addNotification(notification),
}

const mapStateToProps = (state, ownProps) => {
  const { IssueID } = ownProps
  return {
    OrgID: (selectIssueOrgIds(state, IssueID) || [])[0],
    listOfHS: selectCurrentHoldingStatementIds(state, IssueID),
    HoldingStmt: selectIssueHoldingStatementIds(state, IssueID),
    isApprover: isUserIssueApprover(state, IssueID),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(IssueHoldingStatement)
