import {
  HoldingStatementTemplateModel,
  HoldingStatementTemplateRequest,
} from '../HoldingStatement/_models'
import { OrgTag } from '../Org/_models'
import { icoDelete, icoPost, icoPut } from './fetchUtils'

export const deleteTag = (tag: OrgTag) => {
  icoPost('/api/Issue/OrgTagDelete', {
    OrgId: tag.OrgID?.toString(),
    TagId: tag.TagID?.toString(),
  })
    .then((res) => {
      if (res.ok) {
        return true
      } else {
        return false
      }
    })
    .catch((ex) => {
      throw ex
    })
    .finally(() => window.location.reload())
}

export const updateTag = (tag: OrgTag, tagInputName: string) => {
  if (tag.TagName === tagInputName || tagInputName === '') return
  icoPost('/api/Issue/OrgTagUpdate', {
    orgId: tag.OrgID?.toString(),
    tagId: tag.TagID?.toString(),
    name: tagInputName,
  })
    .then((res) => {
      if (res.ok) {
        return true
      } else {
        return false
      }
    })
    .catch((ex) => {
      throw ex
    })
    .finally(() => window.location.reload())
}

export const createTag = (tagName: string, orgId: string) => {
  if (tagName === '') return
  icoPost('/api/Issue/OrgTagAdd', {
    orgId: orgId.toString(),
    name: tagName,
  })
    .then((res) => {
      if (res.ok) {
        return true
      } else {
        return false
      }
    })
    .catch((ex) => {
      throw ex
    })
    .finally(() => window.location.reload())
}

export const deleteHoldingStatementTemplate = (
  holdingStatement: HoldingStatementTemplateModel
) => {
  icoDelete(
    `/api/HoldingStatementTemplate/${holdingStatement.HoldingStatementTemplateID}`
  )
    .then((res) => res.json())
    .then((data) => {
      if (data.Status === 'Failure') return false
      else return true
    })
    .catch((ex) => {
      return ex
    })
}

export const updateHoldingStatementTemplate = (
  holdingStatement: HoldingStatementTemplateModel,
  title: string,
  content: string
) => {
  if (content === holdingStatement.Content && title === holdingStatement.Title)
    return
  icoPut('/api/HoldingStatementTemplate', {
    HoldingStatementTemplateID: holdingStatement.HoldingStatementTemplateID,
    OrgID: holdingStatement.OrgID,
    Title: title,
    Content: content,
  })
    .then((res) => res.json())
    .then((data) => {
      if (data.Status === 'Failure') return false
      else {
        return true
      }
    })
    .catch((ex) => {
      return ex
    })
}

export const createHoldingStatementTemplate = (
  orgId: string,
  title: string,
  content: string
) => {
  let data: HoldingStatementTemplateRequest = {
    Title: title,
    OrgID: parseInt(orgId),
    Content: content,
  }
  if (content === '' || title === '') return

  icoPut('/api/HoldingStatementTemplate', data)
    .then((res) => res.json())
    .then((data) => {
      if (data.Status === 'Failure') return false
      else {
        return true
      }
    })
    .catch((ex) => {
      return ex
    })
}

export const uploadTaskListTemplate = async (
  taskId: number,
  orgId: number,
  templateName: string
) => {
  const data = {
    taskId: taskId.toString(),
    orgId: orgId.toString(),
    templateName: templateName,
  }
  return await icoPost(`/api/Task/UploadTaskTemplate`, data)
    .then((res) => {
      return res.json()
    })
    .then((status) => {
      return status
    })
}

export const createTaskListTemplate = async (
  orgId: number,
  templateName: string
) => {
  const data = {
    taskId: '-1',
    orgId: orgId.toString(),
    templateName: templateName,
  }
  return await icoPost(`/api/Task/UploadTaskTemplate`, data)
    .then((res) => res.json())
    .then((status) => {
      return status
    })
}

export const importTaskListTemplate = async (
  taskId: number,
  orgId: number,
  templateId: number,
  appendOverwriteFlag: string,
  includeAssignmentsFlag: string
) => {
  const data = {
    taskId: taskId.toString(),
    orgId: orgId.toString(),
    templateId: templateId.toString(),
    appendOverwriteFlag: appendOverwriteFlag,
    includeAssignmentsFlag: includeAssignmentsFlag,
  }
  return await icoPost(`/api/Task/ImportTaskTemplate`, data)
    .then((res) => res.json())
    .then((status) => {
      return status
    })
}

export const taskTemplatesForOrg = (orgId: number) => {
  const data = {
    orgId: orgId.toString(),
  }
  return icoPost(`/api/Task/OrgTaskTemplateList`, data)
    .then((res) => {
      return res.json()
    })
    .then((data) => {
      return data
    })
}

export const deleteTaskListTemplate = (orgId: number, templateId: number) => {
  const data = {
    orgId: orgId.toString(),
    templateId: templateId.toString(),
  }

  return icoPost('/api/Task/OrgTaskTemplateDelete', data)
    .then((res) => res.json())
    .then((data) => {
      if (data.Status === 'Failure') return false
      else {
        return true
      }
    })
    .catch((ex) => {
      return ex
    })
}

export const updateTaskListTemplateTitle = (
  orgId: number,
  templateId: number,
  templateName: string
) => {
  const data = {
    orgId: orgId.toString(),
    templateId: templateId.toString(),
    templateName,
  }
  return icoPost('/api/Task/OrgTaskTemplateUpdateTitle', data)
    .then((res) => res.json())
    .then((data) => {
      if (data.Status === 'Failure') return false
      else {
        return true
      }
    })
    .catch((ex) => {
      return ex
    })
}

export const updateTaskListTemplate = (
  orgId: string,
  templateId: number,
  template_json: string
) => {
  const data = {
    orgId: orgId.toString(),
    templateId: templateId.toString(),
    templateJson: template_json,
  }

  return icoPost('/api/Task/OrgTaskTemplateUpdate', data)
    .then((res) => {
      return res.json()
    })
    .then((data) => {
      if (data.Status === 'Failure') return false
      else {
        return true
      }
    })
    .catch((ex) => {
      return ex
    })
}

export const getTaskListForTask = (taskId: number) => {
  return fetch(`/api/Task/${taskId}`)
    .then((res) => {
      return res.json()
    })
    .then((data) => {
      if (data.Status === 'Failure') return false
      else {
        return data
      }
    })
    .catch((ex) => {
      return ex
    })
}

export const selectOrgRoles = (orgId: number) => {
  return icoPost('api/Task/TaskRoles', {
    orgId: orgId,
  })
    .then((res) => {
      return res.json()
    })
    .then((data) => {
      return data
    })
    .catch((ex) => {
      return ex
    })
}

export const dashboardRecentOrg = () => {
  return fetch(`api/Dashboard/RecentOrg`)
    .then((res) => {
      if (res.ok && res.body) return res.json()
    })
    .then((data) => {
      if (data.Status === 'Failure') return false
      else {
        return data
      }
    })
    .catch((ex) => {
      return ex
    })
}

export const saveDashboardRecentOrg = (orgIdList: string) => {
  icoPost('/api/Dashboard/DashboardSaveRecentOrg', {
    requestString: orgIdList,
  })
}

export const userDashboardSettings = async () => {
  return fetch(`api/Dashboard/UserDashboardSettings`)
    .then((res) => {
      if (res.ok && res.body) return res.json()
    })
    .then((data) => {
      if (data.Status === 'Failure') return false
      else {
        return data
      }
    })
    .catch((ex) => {
      return ex
    })
}

export const saveUserDashboardSettings = (stringJson: string) => {
  icoPost('/api/Dashboard/SaveUserDashboardSettings', {
    requestString: stringJson,
  })
}

export const adminDashboardSettings = () => {
  return fetch(`api/Dashboard/AdminDashboardSettings`)
    .then((res) => {
      if (res.ok && res.body) return res.json()
    })
    .then((data) => {
      if (data.Status === 'Failure') return false
      else {
        return data
      }
    })
    .catch((ex) => {
      return ex
    })
}

export const saveAdminDashboardSettings = (stringJson: string) => {
  icoPost('/api/Dashboard/SaveAdminDashboardSettings', {
    requestString: stringJson,
  })
}

export const adminDashboardLayout = () => {
  return fetch(`api/Dashboard/AdminDashboardLayout`)
    .then((res) => {
      if (res.ok && res.body) return res.json()
    })
    .then((data) => {
      if (data.Status === 'Failure') return false
      else {
        return data
      }
    })
    .catch((ex) => {
      return ex
    })
}

export const saveAdminDashboardLayout = (stringJson: string) => {
  icoPost('/api/Dashboard/SaveAdminDashboardLayout', {
    requestString: stringJson,
  })
}

export const userDashboardLayout = () => {
  return fetch(`api/Dashboard/UserDashboardLayout`)
    .then((res) => {
      if (res.ok && res.body) return res.json()
    })
    .then((data) => {
      if (data.Status === 'Failure') return false
      else {
        return data
      }
    })
    .catch((ex) => {
      return ex
    })
}

export const saveUserDashboardLayout = (stringJson: string) => {
  icoPost('/api/Dashboard/SaveUserDashboardLayout', {
    requestString: stringJson,
  })
}

export const queryPanelIssueSettings = () => {
  return fetch(`api/Dashboard/QueryPanelIssueSettings`)
    .then((res) => {
      if (res.ok && res.body) return res.json()
    })
    .then((data) => {
      if (data.Status === 'Failure') return false
      else {
        return data
      }
    })
    .catch((ex) => {
      return ex
    })
}

export const saveQueryPanelIssueSettings = (stringJson: string) => {
  icoPost('/api/Dashboard/SaveQueryPanelIssueSettings', {
    requestString: stringJson,
  })
}

export const queryPanelTaskSettings = () => {
  return fetch(`api/Dashboard/QueryPanelTaskSettings`)
    .then((res) => {
      if (res.ok && res.body) return res.json()
    })
    .then((data) => {
      if (data.Status === 'Failure') return false
      else {
        return data
      }
    })
    .catch((ex) => {
      return ex
    })
}

export const saveQueryPanelTaskSettings = (stringJson: string) => {
  icoPost('/api/Dashboard/SaveQueryPanelTaskSettings', {
    requestString: stringJson,
  })
}

export const userQueryPanelTaskSettings = () => {
  return fetch(`api/Dashboard/UserQueryPanelTaskSettings`)
    .then((res) => {
      if (res.ok && res.body) return res.json()
    })
    .then((data) => {
      if (data.Status === 'Failure') return false
      else {
        return data
      }
    })
    .catch((ex) => {
      return ex
    })
}

export const saveUserQueryPanelTaskSettings = (stringJson: string) => {
  icoPost('/api/Dashboard/SaveUserQueryPanelTaskSettings', {
    requestString: stringJson,
  })
}

export const orgMeetingLinks = (orgId: number) => {
  const data = {
    orgId: orgId.toString(),
  }

  return icoPost(`/api/Conference/ConferenceLinkList`, data)
    .then((res) => {
      return res.json()
    })
    .then((data) => {
      return data
    })
}

export const deleteOrgMeetingLink = (linkId: number, orgId: number) => {
  const data = {
    id: linkId,
    OrgID: orgId,
  }

  return icoPost('/api/Conference/ConferenceLinkListDelete', data)
    .then((res) => res.json())
    .then((data) => {
      if (data.Status === 'Failure') return false
      else {
        return true
      }
    })
    .catch((ex) => {
      return ex
    })
}

export const createOrgMeetingLink = (
  orgId: number,
  link: string,
  description: string
) => {
  const data = {
    OrgID: orgId.toString(),
    Link: link,
    Description: description,
  }

  return icoPost('/api/Conference/ConferenceLinkListInsert', data)
    .then((res) => res.json())
    .then((data) => {
      if (data.Status === 'Failure') return false
      else {
        return true
      }
    })
    .catch((ex) => {
      return ex
    })
}

export const updateOrgMeetingLink = (
  linkId: number,
  orgId: number,
  link: string,
  description: string
) => {
  const data = {
    id: linkId.toString(),
    OrgID: orgId.toString(),
    Link: link,
    Description: description,
  }

  return icoPost('/api/Conference/ConferenceLinkListUpdate', data)
    .then((res) => res.json())
    .then((data) => {
      if (data.Status === 'Failure') return false
      else {
        return true
      }
    })
    .catch((ex) => {
      return ex
    })
}

export const orgAssets = (orgId: number) => {
  const data = {
    OrgID: orgId,
  }

  return icoPost(`/api/Asset/AssetList`, data)
    .then((res) => {
      return res.json()
    })
    .then((data) => {
      return data
    })
}

export const createOrgAsset = (
  orgId: number,
  name: string,
  description: string,
  latitude: number,
  longitude: number,
  assetTypeId: number
) => {
  const data = {
    OrgID: orgId,
    Name: name,
    Description: description,
    Latitude: latitude,
    Longitude: longitude,
    AssetTypeID: assetTypeId,
  }

  return icoPost(`/api/Asset/AssetListInsert`, data)
    .then((res) => {
      return res.json()
    })
    .then((data) => {
      return data
    })
}

export const deleteOrgAsset = (orgId: number, assetId: number) => {
  const data = {
    OrgId: orgId,
    AssetID: assetId,
  }

  return icoPost(`/api/Asset/AssetListDelete`, data)
    .then((res) => {
      return res.json()
    })
    .then((data) => {
      return data
    })
}

export const updateOrgAsset = (
  orgId: number,
  assetId: number,
  name: string,
  description: string,
  latitude: number,
  longitude: number,
  assetTypeId: number
) => {
  const data = {
    OrgID: orgId,
    AssetID: assetId,
    Name: name,
    Description: description,
    Latitude: latitude,
    Longitude: longitude,
    AssetTypeID: assetTypeId,
  }

  return icoPost(`/api/Asset/AssetListUpdate`, data)
    .then((res) => {
      return res.json()
    })
    .then((data) => {
      return data
    })
}

export const importOrgAsset = (
  orgId: number,
  importAssetsJSON: string,
  overwriteYN: string
) => {
  const data = {
    OrgID: orgId,
    ImportAssetsJSON: importAssetsJSON,
    OverwriteYN: overwriteYN,
  }

  return icoPost(`/api/Asset/AssetListImport`, data)
    .then((res) => {
      return res.json()
    })
    .then((data) => {
      return data
    })
}
