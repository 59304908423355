import { callAction, ReducerActionMap } from '../_utils/reduxUtils'
import {
  FETCH_PLAYBOOK_LIST,
  FETCH_PLAYBOOK_UPDATES,
  SAVE_PLAYBOOK_SEARCH,
} from './_constants'

// ------ THESE ARE THE DATA TYPES COMING FROM THE CHECKIN -----------
export interface iccImage {
  format: string
  Description: string
  Graphic: string
}
export interface iccPlaybook {
  PubID: number
  OrgID: number
  PubGUID: string
  Version: string
  AuthType: string
  PubTitle: string
  IsLibraryFlag: string
  Updated: Date
  OrgName: string
  ImageID: number
  Image: iccImage
  Keywords: string
  Authtype: string
  PublicationTag: string
}
export interface iccChat {
  PubGUID: string
  RefPubGUID: string
  ChatGUID: string
  EventID: number
  OrgID: number
  ChatName: string
  hostURL: string
  PubTitle: string
  IsLibraryFlag: string
  LatestEntryUTC: Date
  LatestActivity: string
  UnreadCount: number
  rnum: number
}
export interface iccTasklist {
  PubGUID: string
  RefPubGUID: string
  TaskGUID: string
  EventID: number
  OrgID: number
  TaskName: string
  hostURL: string
  PubTitle: string
  IsLibraryFlag: string
  LatestActionUTC: Date
  LatestActivity: string
  UnreadCount: number
  rnum: number
}
export interface iccCheckin {
  generated: Date
  authenticated: string
  hasPasswordYN: string
  NeedsPrivacyAgreement: string
  PrefsPlayboooks: string
  AppAcctHostURL: string
  AppBackendHostURL: string
  ICOIssueHostURL: string
  SessionGUID: string
  HasActiveSMS: string
  VAPID_publicKey: string
  AppDevGUID: string
  EmailAddress: string
  Chats?: iccChat[]
  Tasklists?: iccTasklist[]
  PbList?: iccPlaybook[]
}

export interface playbookAppState {
  searchvalue?: string
}
export default function playbookAppReducer(
  state: playbookAppState = {},
  action: any
) {
  const mapped: ReducerActionMap = {
    [SAVE_PLAYBOOK_SEARCH]: () => {
      return {
        ...state,
        searchvalue: action.payload.SearchValue,
      }
    },
  }
  return callAction(mapped, state, action)
}
export const selectPlaybookSearchValue = (state: playbookAppState) =>
  state.searchvalue

function addNewPbListItems(
  state: any,
  //playbookList: iccCheckin,
  addPbList: any,
  delPbList: any,
  orderBy: string
) {
  const playbookList = state.playbookList as iccCheckin

  if (!addPbList && !delPbList && orderBy === state.prefsPlaybooks)
    return playbookList

  let newPlaybookList = { ...playbookList } as iccCheckin

  if (delPbList) {
    //console.log("DRH DEBUG first delPbList is ", JSON.stringify(delPbList[0]));
    delPbList.forEach((delPb: any) => {
      let foundIndex = -1

      newPlaybookList.PbList?.forEach(function (pb: any, i: number) {
        //(pb: any) => {
        if (pb.PubGUID) {
          if (pb.PubGUID.toLowerCase() === delPb.PubGUID.toLowerCase())
            foundIndex = i
        } else {
          // console.log('DRH DEBUG got a wierd playbook', JSON.stringify(pb))
        }
      })

      if (foundIndex > -1) {
        //console.log("DRH DEBUG need to remove", delPb.PubGUID);
        newPlaybookList.PbList?.splice(foundIndex, 1)
      }
    })
  }

  if (addPbList) {
    //console.log("DRH DEBUG first addPbList is ", JSON.stringify(addPbList[0]));
    addPbList.forEach((addPb: any) => {
      let alreadyThere = false

      newPlaybookList.PbList?.forEach((pb: any) => {
        if (pb.PubGUID) {
          if (pb.PubGUID.toLowerCase() === addPb.PubGUID.toLowerCase())
            alreadyThere = true
        } else {
          // console.log('DRH DEBUG got a wierd playbook', JSON.stringify(pb))
        }
      })

      if (!alreadyThere) {
        //console.log("DRH DEBUG need to add", addPb.PubGUID, addPb.PubTitle);
        newPlaybookList.PbList?.push(addPb)
      }
    })
  }

  if (orderBy) {
    if (newPlaybookList.PbList) {
      if (orderBy === 'Updated')
        newPlaybookList.PbList?.sort((a: any, b: any) =>
          a.Updated < b.Updated ? 1 : -1
        )
      if (orderBy === 'PubTitle')
        newPlaybookList.PbList?.sort((a: any, b: any) =>
          a.PubTitle > b.PubTitle ? 1 : -1
        )
      if (orderBy === 'OrgName')
        newPlaybookList.PbList?.sort((a: any, b: any) =>
          a.OrgName > b.OrgName ? 1 : -1
        )
    }
  }
  /*
  if (playbookList.length == newPlaybookList.length) {
    var isSame = true;
    for (var i = 0; i < playbookList.length; i++) {
      if (playbookList[i].PubGUID != newPlaybookList[i].PubGUID) isSame = false;
    }
    if (isSame) return playbookList;
  }
*/
  return newPlaybookList
}

export interface playbookState {
  playbookList?: iccCheckin
  prefsPlaybooks?: string
}
export function playbookReducer(state: playbookState = {}, action: any) {
  const mapped: { [key: string]: Function } = {
    [FETCH_PLAYBOOK_LIST.SUCCESS]: () => {
      return {
        ...state,
        playbookList: action.payload,
        prefsPlaybooks: action.payload.PrefsPlaybooks,
      }
    },

    [FETCH_PLAYBOOK_UPDATES.SUCCESS]: () => {
      //   if (!addPbList && !delPbList && orderBy != state.prefsPlaybooks)
      /*
      var needNew = false;
      if (action.payload.AddPbList) needNew = true;
      if (action.payload.DelPbList) needNew = true;
      console.log(action.payload.PrefsPlaybooks, state.prefsPlaybooks);
      if (action.payload.PrefsPlaybooks != state.prefsPlaybooks) needNew = true;
      console.log("needNew is ", needNew);
      if (!needNew) return { ...state };
      else
      */
      return {
        ...state,
        // take the current playbookList and add new playbooks from updates
        // the ...state.playbookList.PbList needs to have action.playload.AddPbList items appeneded
        playbookList: addNewPbListItems(
          state,
          //state.playbookList,
          action.payload.AddPbList,
          action.payload.DelPbList,
          action.payload.PrefsPlaybooks
        ),
        prefsPlaybooks: action.payload.PrefsPlaybooks,
      }
    },
  }

  return (
    mapped[action.type] ||
    function () {
      return state
    }
  )()
}

export const selectPlaybookList = (state: playbookState) => state.playbookList
