// @ts-nocheck
// TODO: Typescript
// NOTE: This saga is obsolete because of restrictions based on generators, callbacks, and msal popup restrictions

import {
  LOGOUT_AD,
  FETCH_MS_TEAMS_CHANNEL_FOLDERIDS,
  FETCH_GRAPH_JOINED_TEAM_CHANNELS,
  FETCH_GRAPH_CALENDAR_EVENTS,
} from './_constants'
import {
  fetchMSTeamsChannelFolderIds,
  fetchGraphJoinedTeamChannels,
  fetchGraphCalendarEvents,
} from './_actions'
import { takeLatest, takeEvery } from 'redux-saga/effects'
import { getFetchListener, sagaWithAzureOnly } from '../_utils/sagaUtils'
import { normalize } from 'normalizr'
import { msTeamListSchema, msEventsListSchema } from './_schemas'
import { dateUTCbuilder } from '../_utils/dateUtils'
import { selectMSCalendarEventsWorkspaceId } from './_selectors'

function* logoutOfAD() {
  /*try {
        const result = yield call(logout);
        yield put(logoutADSuccess(result));
    }
    catch(error) {
        yield put(logoutADFailure(error));
    }*/
}

/*const fetchUserDetails = getGraphListener({
    request: getUserDetails,
    onSuccess: fetchGraphUserDetailsSuccess
});*/

/********************************************************************
 * Ensures the user is authenticated before making a request to graph
 *******************************************************************/
/*function getGraphListener(options) {
    options = options || {};
    const { request, params, onSuccess, onFailure, onDone } = options;
    const source = {
        params,
        functionName: request.name
    };

    return function* listener() {
        try {
            yield put(fetchGraphAccessToken());
            const token = yield call(getAccessToken);
            yield put(fetchGraphAccessTokenSuccess(token));
            const result = yield call(request, token, source);

            if (onSuccess) {
                const successResult = onSuccess(result, source);
                if (successResult instanceof Array) {
                    for (var i = 0; i < successResult.length; i++) {
                        yield put(successResult[i]);
                    }
                }
                else {
                    yield put(successResult);
                }
            }
        }
        catch(error) {
            if (onFailure)
                yield put(onFailure(error, source));

            yield put(fetchGraphAccessTokenFailure(error, source));
        }

        if (onDone)
            yield put(onDone());
    }
    
}*/

const fetchChannelFolderIds = getFetchListener(
  '/api/MicrosoftTeams/ChannelFolderIds',
  {
    onFailure: fetchMSTeamsChannelFolderIds.failure,
    onSuccess: fetchMSTeamsChannelFolderIds.success,
    appendPath: function (action) {
      const { channelId, groupId } = action.payload.params || {}
      return `groups/${groupId}/channels/${channelId}`
    },
  }
)

const fetchJoinedTeamChannels = getFetchListener(
  '/api/MicrosoftTeams/JoinedTeamChannels',
  {
    onFailure: fetchGraphJoinedTeamChannels.failure,
    onSuccess: (json) => {
      const normalizedData = normalize(json, msTeamListSchema)
      return fetchGraphJoinedTeamChannels.success(normalizedData)
    },
  }
)

const eventsPageSize = 5
const fetchCalendarEvents = getFetchListener(
  '/api/MicrosoftTeams/IssueTeamWorkspace',
  {
    //onFailure: fetchGraphCalendarEvents.withGraphFailure,
    onFailure: fetchGraphCalendarEvents.failure,
    select: {
      currentWorkspace: [selectMSCalendarEventsWorkspaceId],
    },
    onSuccess: (result, params, source, { currentWorkspace }) => {
      if (currentWorkspace !== params.IssueTeamWorkspaceID) return

      const page = params.page || 1
      const normalizedData = normalize(result.events, msEventsListSchema)
      let events = normalizedData.entities.MSEvents || {}

      for (var eventId in events) {
        const curEvent = events[eventId]
        var start = curEvent.start || {}
        start.dateTime = dateUTCbuilder(start.dateTime)
        var end = curEvent.end || {}
        end.dateTime = dateUTCbuilder(end.dateTime)
      }

      let successActions = [
        fetchGraphCalendarEvents.success(events, params, source),
      ]

      // Keep fetching until all results are returned
      if (result.count > page * eventsPageSize)
        successActions.push(
          fetchGraphCalendarEvents.request({
            page: page + 1,
            pageSize: eventsPageSize,
            IssueTeamWorkspaceID: params.IssueTeamWorkspaceID,
          })
        )

      return successActions
    },
    queryParams: (action) => {
      const { params } = action.payload
      const { page } = params
      return {
        page: page || 1,
        pageSize: eventsPageSize,
      }
    },
    appendPath: function (action) {
      const { IssueTeamWorkspaceID } = action.payload.params || {}
      return `${IssueTeamWorkspaceID}/CalendarEvents`
    },
  }
)

// Watcher sagas
export const graphSagas = [
  //takeLatest(FETCH_GRAPH_USER_DETAILS.REQUEST, fetchUserDetails),
  // Login via saga is OBSOLETE because microsoftTeams.authentication.authenticate callback can't be handled properly in generator functions
  //takeLatest(LOGIN_AD.REQUEST, loginIntoAD),
  takeEvery(FETCH_GRAPH_CALENDAR_EVENTS.REQUEST, fetchCalendarEvents),
  takeLatest(LOGOUT_AD.REQUEST, logoutOfAD),
  takeLatest(
    FETCH_MS_TEAMS_CHANNEL_FOLDERIDS.REQUEST,
    sagaWithAzureOnly(
      fetchChannelFolderIds,
      fetchMSTeamsChannelFolderIds.complete
    )
  ),
  takeLatest(
    FETCH_GRAPH_JOINED_TEAM_CHANNELS.REQUEST,
    sagaWithAzureOnly(
      fetchJoinedTeamChannels,
      fetchGraphJoinedTeamChannels.complete
    )
  ),
]
