// @ts-nocheck
// TODO: Typescript
import React, { Component } from 'react'
import { applyIssueFilter } from './_actions'
import { connect } from 'react-redux'
import { PropTypes } from 'prop-types'
import { SearchInput } from '../Shared/SearchInput'
import { selectIssueFilter } from './_selectors'
import { prefixSelectorWithApp } from '../_utils/domUtils'

const filterApplyDelay = 30
export class IssueFilter extends Component {
  constructor(props) {
    super(props)
    this.applyFilter = this.applyFilter.bind(this)
    this.removeDescription = this.removeDescription.bind(this)
    const { filters } = this.props
    const { Description } = filters
    this.state = {
      Description: Description || '', // Prevent the uncontrolled warning
    }
    this.filterApplyTimer = 0
    this._ismounted = true
  }

  componentDidUpdate() {
    const { filters } = this.props
    if (this.state.Description !== filters.Description) {
      this.applyIssueFilterDelayed()
    }
  }

  componentWillUnmount() {
    this._ismounted = false
  }

  applyFilter(value: string) {
    // if (evt.target.name === 'descriptionFilter') {
    this.setState({
      Description: value,
    })
    // }
  }

  removeDescription() {
    if (this.state.Description) {
      this.setState({
        Description: '',
      })
    }
  }

  applyIssueFilterDelayed() {
    const { applyIssueFilter } = this.props
    clearTimeout(this.filterApplyTimer)
    this.filterApplyTimer = setTimeout(() => {
      if (this._ismounted) {
        applyIssueFilter({
          ...this.state,
        })
      }
    }, filterApplyDelay)
  }

  render() {
    const { style, className } = this.props
    return (
      <SearchInput
        id={prefixSelectorWithApp('global-issueSearch')}
        name="descriptionFilter"
        placeholder="Search keyword in Title, Description, or by Tag"
        className={className}
        style={style}
        value={this.state.Description}
        applySearch={this.applyFilter}
        onClear={this.removeDescription}
        includeSearchIcon={true}
      />
    )
  }
}

IssueFilter.propTypes = {
  applyIssueFilter: PropTypes.func,
  style: PropTypes.any,
}

IssueFilter.defaultProps = {
  filters: {},
}

const mapStateToProps = (state, ownProps) => ({
  filters: selectIssueFilter(state, ownProps),
})

const mapDispatchToProps = {
  applyIssueFilter,
}

export default connect(mapStateToProps, mapDispatchToProps)(IssueFilter)
