/* NOTE: This is a work in progress the scroll keeps jumping to the top for server side pagination.
 * Implement the local virtualization for now.s
 */

import React, {
  ChangeEvent,
  FocusEventHandler,
  KeyboardEvent,
  ReactEventHandler,
} from 'react'
import {
  Autocomplete,
  AutocompleteChangeReason,
  AutocompleteCloseReason,
  AutocompleteInputChangeReason,
} from '@material-ui/lab'
// @ts-ignore
import { List, AutoSizer } from 'react-virtualized'
import { TextField } from '@material-ui/core'
import { cx } from '../_utils/objectUtils'

const ListboxComponent = React.forwardRef(function ListboxComponent(
  props: any,
  ref: any
) {
  const { children, role, ...other } = props
  const itemCount = Array.isArray(children) ? children.length : 0
  const itemSize = 36

  const getHeight = () => {
    if (itemCount > 8) {
      return 8 * itemSize
    }
    return children
      .map(() => itemSize)
      .reduce((a: number, b: number) => a + b, 0)
  }

  return (
    <div ref={ref}>
      <div {...other} className="w-100 h-100">
        <AutoSizer className="w-100 h-100">
          {({ height, width }: { height: number; width: number }) => (
            <List
              height={getHeight()}
              width={width}
              rowHeight={itemSize}
              overscanCount={5}
              rowCount={itemCount}
              rowRenderer={(props: any) => {
                var curChild = children[props.index]
                return React.cloneElement(curChild, {
                  style: props.style,
                  className: cx(curChild.props.className, 'single-line-text'),
                })
              }}
              role={role}
            />
          )}
        </AutoSizer>
      </div>
    </div>
  )
})

interface Props {
  isLoading: boolean
  rows: any[]
  getValue: (row: any) => string
  className?: string
  placeholder?: string
  inputLabel?: string
  clearOnBlur?: boolean
  freeSolo?: boolean
  onChange?: (
    evt: ChangeEvent<{}>,
    newValue: string,
    reason: AutocompleteChangeReason
  ) => void
  onInputChange?: (
    evt: ChangeEvent<{}>,
    value: string,
    reason: AutocompleteInputChangeReason
  ) => void
  inputValue?: string
  onInputKeyup?: (evt: KeyboardEvent<HTMLInputElement>) => void
  open?: boolean
  onClose?: (evt: ChangeEvent<{}>, reason: AutocompleteCloseReason) => void
  onOpen?: (evt: ChangeEvent<{}>) => void
  value?: string
  onBlur?: FocusEventHandler<HTMLDivElement>
  onFocus?: FocusEventHandler<HTMLDivElement>
  disabled?: boolean
  onSelect?: ReactEventHandler<HTMLDivElement>
  autoComplete?: boolean
}

export const AutoCompleteVirtualized = ({
  isLoading,
  rows,
  getValue,
  className,
  placeholder,
  inputLabel,
  clearOnBlur,
  freeSolo,
  onChange,
  onInputChange,
  inputValue,
  onInputKeyup,
  open,
  onClose,
  onOpen,
  value,
  onBlur,
  onFocus,
  disabled,
  onSelect,
  autoComplete,
}: Props) => {
  /***** Renderers ********/

  return (
    <Autocomplete
      open={open}
      onClose={onClose}
      onOpen={onOpen}
      className={className}
      loading={isLoading}
      disableListWrap={true}
      renderInput={(params) => (
        <TextField {...params} label={inputLabel} onKeyUp={onInputKeyup} />
      )}
      placeholder={placeholder}
      options={rows}
      getOptionLabel={(option: any) => getValue(option) || ''}
      ListboxComponent={ListboxComponent as any}
      clearOnBlur={clearOnBlur}
      freeSolo={freeSolo}
      onChange={onChange}
      onInputChange={onInputChange}
      inputValue={inputValue}
      value={value}
      onBlur={onBlur}
      onFocus={onFocus}
      disabled={disabled}
      onSelect={onSelect}
      autoComplete={autoComplete}
    />
  )
}
