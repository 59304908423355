// @ts-nocheck
// TODO: Typescript
import { getFetchListener } from './_utils/sagaUtils'
import { AppConstants } from './_constants'
import { takeLatest } from 'redux-saga/effects'
import { appLoading, appLoadedDelay, appDataLoadedFailure } from './_actions'
import { LOGIN_AD } from './MicrosoftTeams/_constants'
import { LoginConstants } from './Auth/_constants'

// The Saga that does the actual work
const fetchInitialState = getFetchListener('/api/SampleData/InitialState', {
  onLoading: appLoading,
  onSuccess: (initialState) => {
    return appLoadedDelay(initialState, initialState.isAuthenticated, 400) // Wait for a little bit before saying the app has been fully load. Special MS Teams fix used for deciding which login the user should be presented with
  },
  requireAuthentication: false,
  onUnAuthenticated: appDataLoadedFailure,
})

// Watcher sagas
export const appSagas = [
  // takeLatest - will cancel previous fetch request and only run the latest one.
  // This is a listener. the yield all is called in the store. The logic is similar to reducers combine logic
  takeLatest(AppConstants.FETCH_INIT.REQUEST, fetchInitialState),
  takeLatest(LOGIN_AD.SUCCESS, fetchInitialState),
  takeLatest(LoginConstants.SUCCESSFUL, fetchInitialState),
]
