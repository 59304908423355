// @ts-nocheck
// TODO: Typescript
import {
  generateCrudTypes,
  generateTypes,
  prefixWithApp,
} from '../_utils/reduxUtils'
import { addKeyValuePrefix } from '../_utils/objectUtils'

export const IssueConstants = Object.assign({}, generateCrudTypes('ISSUE'), {
  APPLY_FILTER: 'ISSUE_APPLY_FILTER',
  // SAVE_FILTER: 'ISSUE_SAVE_FILTER',
  dateFields: ['IssueCreated', 'IssueResolved', 'LastActivityDate'],
})

export const FilterConstants = {
  LOADING: 'LOADING',
  SUCCESSFUL: 'SUCCESSFUL',
  FAILURE: 'FAILURE',
  FETCH: 'FETCH',
}
addKeyValuePrefix('FILTER', FilterConstants)

export const FilterSaveConstants = generateTypes('ISSUE_FILTER', 'SAVE')
export const IssueLevelConstants = generateCrudTypes('ISSUE_LEVEL')
export const FETCH_ORG_ISSUE_LEVELS = generateTypes('ORG_ISSUE_LEVELS', 'FETCH')
export const FETCH_ORG_ISSUE_TYPES = generateTypes('ORG_ISSUE_TYPES', 'FETCH')
export const FETCH_APP_USER_INFO = generateTypes('APP_USER_INFO', 'FETCH')
export const FETCH_APP_USER_ORGS = generateTypes('APP_USER_ORGS', 'FETCH')
export const RESOLVE_ISSUE = generateTypes('ISSUE', 'RESOLVE')
export const IssueTagConstants = generateCrudTypes('ISSUE_TAG')
export const CHANGE_ISSUE_STAGE_TAB = prefixWithApp('CHANGE_ISSUE_STAGE_TAB')
export const FETCH_ISSUE_ACTIVITY_LOG = generateTypes(
  'ISSUE_ACTIVITY_LOG',
  'FETCH'
)
export const ADD_ISSUE_ACTIVITY_LOG = generateTypes('ISSUE_ACTIVITY_LOG', 'ADD')
export const SET_DEFAULT_ISSUE = 'SET_DEFAULT_ISSUE'
export const MARK_READ_ISSUE_ACTIVITY_LOG = generateTypes(
  'ISSUE_ACTIVITY_LOG',
  'MARK_READ'
)
export const FETCH_LATEST_ISSUE_ACTIVIY_DATES = generateTypes(
  'LATEST_ISSUE_ACTIVITY_DATES',
  'FETCH'
)
export const FETCH_ISSUE_DETAILS = generateTypes('ISSUE_DETAILS', 'FETCH')
export const CHANGE_ISSUE_ACTIVITY_LOG_FILTER = generateTypes(
  'CHANGE_ISSUE_ACTIVITY_LOG_FILTER'
)
export const EvidenceLockerLinkConstants = generateCrudTypes(
  'ISSUE_EVIDENCE_LINKS'
)
export const GeolocationConstants = generateCrudTypes('ISSUE_GEOLOCATIONS')
