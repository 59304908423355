import * as React from 'react'
import { DashboardPlaybookModel } from './DashboardModels'
import dayjs from 'dayjs'

export const DashboardPlaybooksSection = ({
  playbooks,
  isOrgView,
}: {
  playbooks?: DashboardPlaybookModel[]
  isOrgView: boolean
}) => {
  const orgNameCounts: { orgName: string; count: number }[] = []
  let playbookTotal = 0
  const playbooksReversed = playbooks?.reverse()
  playbooksReversed?.forEach((item) => {
    const orgName = item.OrgName
    const existingItem = orgNameCounts.find(
      (countItem) => countItem.orgName === orgName
    )

    if (existingItem) {
      existingItem.count += 1
    } else {
      orgNameCounts.push({ orgName, count: 1 })
    }
  })

  orgNameCounts.sort((a, b) => {
    if (b.count !== a.count) {
      return b.count - a.count
    } else {
      return a.orgName.localeCompare(b.orgName)
    }
  })

  orgNameCounts.forEach((org) => {
    playbookTotal += org.count
  })

  if (playbooks && playbooks?.length < 1)
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: ' center',
          alignItems: 'center',
        }}
      >
        {' '}
        There are no Playbooks
      </div>
    )

  return isOrgView ? (
    <>
      <div
        style={{
          display: 'flex',
          padding: '10px',
          alignItems: 'center',
          justifyContent: 'center',
          flexGrow: 1,
        }}
      >
        <div
          style={{
            display: 'flex',
            width: '100%',
            height: '100%',
            justifyContent: 'space-around',
            flexDirection: 'column',
            fontSize: '12px',
          }}
        >
          <div
            key={-1}
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
              borderBottom: '1px solid black',
            }}
          >
            <div
              style={{
                width: '60%',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              Title
            </div>
            <div style={{ width: '120px' }}>Updated</div>
          </div>

          {playbooks?.map((playbook, ndx) => {
            return (
              <div
                key={'onc' + ndx.toString()}
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  width: '100%',
                }}
              >
                <div
                  style={{
                    width: '60%',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >
                  {playbook.PubTitle}
                </div>
                <div style={{ width: '120px' }}>
                  {dayjs(playbook.Updated).format('DD MMM YYYY, h:mm A')}
                </div>
              </div>
            )
          })}
        </div>
      </div>
      <div
        style={{
          position: 'sticky',
          bottom: 0,
          padding: '10px',
          backgroundColor: 'white',
          borderTop: '1px solid #ccc',
        }}
      >
        Total Playbooks: {playbookTotal}
      </div>
    </>
  ) : (
    <>
      <div
        style={{
          display: 'flex',
          padding: '10px',
          alignItems: 'center',
          justifyContent: 'center',
          flexGrow: 1,
        }}
      >
        <div
          style={{
            display: 'flex',
            width: '100%',
            height: '100%',
            justifyContent: 'space-around',
            flexDirection: 'column',
            fontSize: '12px',
          }}
        >
          {orgNameCounts.map((org, ndx) => (
            <div
              key={'onc' + ndx.toString()}
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                width: '100%',
              }}
            >
              <div>{org.orgName}</div>
              <div>{org.count}</div>
            </div>
          ))}
        </div>
      </div>
      <div
        style={{
          position: 'sticky',
          bottom: 0,
          padding: '10px',
          backgroundColor: 'white',
          borderTop: '1px solid #ccc',
        }}
      >
        Total Playbooks: {playbookTotal}
      </div>
    </>
  )
}
