import React, { useEffect, useState, useCallback, useRef } from 'react'
import {
  GoogleMap,
  useLoadScript,
  Marker,
  Libraries,
  InfoWindow,
} from '@react-google-maps/api'
import { googleApiKey } from '../_utils/AppSettings'
import { Button } from './Buttons'
import { ResponsiveDialog } from './ResponsiveDialog'
import { SlideLeft } from '../Shared/Transition'
import {
  DialogContent,
  FormControl,
  Input,
  InputLabel,
  Toolbar,
} from '@material-ui/core'
import { HeaderNavigation } from '../Shared/HeaderNavigation'
import { Spacer } from './Spacer'
import classes from './LocationSelector.module.scss'
import DOMPurify from 'dompurify'
import { useDebounce } from 'usehooks-ts'
import { IssueGeolocation } from '../Issue/_reducer'
import { useAppSelector } from '../_utils/reactReduxHooks'
import { isMobileApp } from '../_selectors'

const libraries = ['places']

interface Props {
  existingLocations?: Array<IssueGeolocation>
  onSave: (location: IssueGeolocation) => void
  onClose: () => void
  isOpen: boolean
}

export const LocationSelector = ({
  existingLocations,
  onSave,
  onClose,
  isOpen,
}: Props) => {
  const [selectedLocation, setSelectedLocation] =
    useState<IssueGeolocation | null>(null)
  const [title, setTitle] = useState<string>('')
  const [description, setDescription] = useState<string>('')
  const [newLocation, setNewLocation] = useState<IssueGeolocation>()
  const [newLocationDetails, setNewLocationDetails] = useState<any>()
  const [address, setAddress] = useState<string>('')
  const debouncedAddress = useDebounce(address, 2000)
  const [errors, setErrors] = useState<{ [key: string]: any }>({})
  const [showMap, setShowMap] = useState(false)
  const [map, setMap] = useState<google.maps.Map | null>(null)

  const descriptionShouldAutoAssign = useRef(true)

  const isMobile = useAppSelector(isMobileApp)

  const mapContainerStyle = {
    width: '100%',
    height: '100%',
  }

  useEffect(() => {
    const listener = (e: any) => {
      if (e.key === 'Escape') {
        setSelectedLocation(null)
      }
    }
    window.addEventListener('keydown', listener)
    return () => {
      window.removeEventListener('keydown', listener)
    }
  }, [])

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: googleApiKey,
    libraries: libraries as Libraries,
    preventGoogleFontsLoading: false,
  })

  const handleLocationAPI = useCallback(
    (latlng?: google.maps.LatLng) => {
      let request: google.maps.GeocoderRequest = latlng
        ? {
            location: latlng,
          }
        : { address: address }
      let geocoder: google.maps.Geocoder = new google.maps.Geocoder()
      geocoder
        .geocode(request)
        .then((result) => {
          const { results } = result
          setNewLocation({
            Latitude: results[0].geometry.location.lat(),
            Longitude: results[0].geometry.location.lng(),
            ShouldDisplayYN: 'Y',
          })
          setNewLocationDetails(
            latlng
              ? 'Pin on Map: ' + latlng.lat() + ', ' + latlng.lng()
              : results[0].formatted_address +
                  ' - Accuracy: ' +
                  results[0].geometry.location_type +
                  ' - Lat: ' +
                  results[0].geometry.location.lat() +
                  ', Lng: ' +
                  results[0].geometry.location.lng()
          )
        })
        .catch((errors: any) => {
          setNewLocationDetails(null)
          if (errors.code === 'ZERO_RESULTS') {
            setErrors((prev) => ({
              ...prev,
              Address:
                'There were no results for your search, please try again',
            }))
          } else {
            setErrors((prev) => ({
              ...prev,
              Address: 'There was an issue searching for that location',
            }))
          }
        })
    },
    [address]
  )

  const onLoad = useCallback(
    (loadedMap: google.maps.Map) => {
      setMap(loadedMap)
      if (
        !newLocation &&
        (!existingLocations || existingLocations.length === 0)
      ) {
        const center = {
          lat: 0,
          lng: 0,
        }
        const arbitraryPoint = new google.maps.LatLngBounds(center)
        loadedMap.fitBounds(arbitraryPoint)
      } else {
        const bounds = new google.maps.LatLngBounds()
        existingLocations?.forEach((location: IssueGeolocation) => {
          const point = new google.maps.LatLng(
            location.Latitude,
            location.Longitude
          )
          bounds.extend(point)
        })
        if (newLocation) {
          const newPoint = new google.maps.LatLng(
            newLocation.Latitude,
            newLocation.Longitude
          )
          bounds.extend(newPoint)
        }
        loadedMap.fitBounds(bounds)
      }
    },
    [existingLocations, newLocation]
  )

  useEffect(() => {
    if (!newLocationDetails) return

    if (descriptionShouldAutoAssign.current) {
      setDescription(newLocationDetails)
    }
    if (!description) {
      descriptionShouldAutoAssign.current = true
    }
  }, [newLocationDetails, description])

  useEffect(() => {
    if (debouncedAddress && address && debouncedAddress === address) {
      handleLocationAPI()
    }
  }, [debouncedAddress, address, handleLocationAPI])

  useEffect(() => {
    if (map && newLocation) {
      const bounds = new window.google.maps.LatLngBounds()
      existingLocations?.forEach((location: IssueGeolocation) => {
        bounds.extend({ lat: location.Latitude, lng: location.Longitude })
      })
      if (newLocation) {
        bounds.extend({ lat: newLocation.Latitude, lng: newLocation.Longitude })
      }
      map.fitBounds(bounds)
    }
  }, [map, newLocation, existingLocations])
  const onUnmount = useCallback(function callback(map) {
    setMap(null)
  }, [])

  if (loadError) {
    return (
      <div style={{ display: isOpen ? '' : 'hidden' }}>Error loading maps</div>
    )
  }

  if (!isLoaded) {
    return <div style={{ display: isOpen ? '' : 'hidden' }}>Loading maps</div>
  }

  const clearInputs = () => {
    setTitle('')
    setDescription('')
    setAddress('')
    setNewLocation(undefined)
    setNewLocationDetails(null)
    setSelectedLocation(null)
    setErrors({})
    setShowMap(false)
  }
  const handleClose = () => {
    clearInputs()
    onClose()
  }

  const handleSave = () => {
    DOMPurify.sanitize(title)

    if (!title || title?.length === 0) {
      setErrors((prev) => ({
        ...prev,
        Title: 'A location Title cannot be blank',
      }))
      return
    } else if (DOMPurify.removed.length > 0) {
      setErrors((prev) => ({
        ...prev,
        Title: 'A location Title cannot be a security risk',
      }))
      return
    }

    DOMPurify.sanitize(description)

    if (DOMPurify.removed.length > 0) {
      setErrors((prev) => ({
        ...prev,
        Description: 'A location Description cannot be a security risk',
      }))
      return
    }

    if (newLocation) {
      onSave({ ...newLocation, Title: title, LocationDescription: description })
      handleClose()
    }
  }

  const GoogleMapSection = (
    <GoogleMap
      mapContainerStyle={mapContainerStyle}
      onLoad={onLoad}
      options={{
        fullscreenControl: false,
        streetViewControl: false,
        mapTypeControl: false,
        gestureHandling: 'cooperative',
      }}
      onUnmount={onUnmount}
      onBoundsChanged={() => {
        let zoom = map?.getZoom()
        if (zoom && zoom > 10) map?.setZoom(10)
      }}
      onClick={(e) => {
        setErrors((prev) => ({ ...prev, Address: null }))
        if (e.latLng?.lat() && e.latLng?.lng()) {
          setAddress('')
          handleLocationAPI(
            new google.maps.LatLng(e.latLng?.lat(), e.latLng?.lng())
          )
        }
      }}
    >
      {existingLocations?.map((location, index) => (
        <Marker
          key={index}
          position={{
            lat: location.Latitude,
            lng: location.Longitude,
          }}
          title={
            location.Title ||
            location.LocationDescription ||
            'lat: ' + location.Latitude + ', lng: ' + location.Longitude
          }
          onClick={() => {
            setSelectedLocation({
              Title: location.Title,
              LocationDescription: location.LocationDescription,
              Latitude: location.Latitude,
              Longitude: location.Longitude,
              ShouldDisplayYN: location.ShouldDisplayYN,
            })
          }}
        />
      ))}
      {selectedLocation && (
        <InfoWindow
          onCloseClick={() => {
            setSelectedLocation(null)
          }}
          position={{
            lat: selectedLocation.Latitude,
            lng: selectedLocation.Longitude,
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <div style={{ fontSize: '16px', fontWeight: '500' }}>
              {selectedLocation.Title}
            </div>
            {selectedLocation.LocationDescription && (
              <div>{selectedLocation.LocationDescription}</div>
            )}
            <div>
              {selectedLocation.Latitude + ', ' + selectedLocation.Longitude}
            </div>
          </div>
        </InfoWindow>
      )}
      {newLocation && (
        <Marker
          key={0}
          position={{
            lat: newLocation.Latitude,
            lng: newLocation.Longitude,
          }}
          onClick={() => {
            setSelectedLocation({
              Title: title || 'New Location',
              LocationDescription: description,
              Latitude: newLocation.Latitude,
              Longitude: newLocation.Longitude,
              ShouldDisplayYN: 'Y',
            })
          }}
        />
      )}
    </GoogleMap>
  )
  const renderContent = () => (
    <div
      style={{
        height: showMap ? '700px' : '100%',
        width: isMobile ? '100%' : '500px',
        display: 'flex',
        alignItems: 'flexStart',
        justifyContent: 'flexStart',
        flexDirection: 'column',
      }}
    >
      <FormControl className="w-100" required={true}>
        <InputLabel>Title</InputLabel>
        <Input
          type="text"
          required={true}
          error={errors['Title'] ? true : false}
          name="Title"
          inputProps={{
            maxLength: 255,
          }}
          value={title}
          onChange={(evt) => {
            if (errors.Title) {
              delete errors.Title
            }
            setTitle(evt.target.value)
          }}
        />
        {errors.Title && (
          <p className={classes.warningMessage}>{errors.Title}</p>
        )}
      </FormControl>
      <Spacer spaceParam={'large'} />
      <FormControl className="w-100" required={true}>
        <InputLabel>Description</InputLabel>
        <Input
          type="text"
          required={true}
          error={errors['Description'] ? true : false}
          name="Description"
          inputProps={{
            maxLength: 255,
          }}
          value={description}
          onChange={(evt) => {
            if (errors.Description) {
              delete errors.Description
            }
            setDescription(evt.target.value)
          }}
          onKeyDown={() => {
            descriptionShouldAutoAssign.current = false
          }}
        />
        {errors.Description && (
          <p className={classes.warningMessage}>{errors.Description}</p>
        )}
      </FormControl>
      <Spacer spaceParam={'large'} />
      <FormControl className="w-100" required={true}>
        <InputLabel>Search For Location</InputLabel>
        <Input
          type="text"
          required={true}
          error={errors['Address'] ? true : false}
          name="Address"
          inputProps={{
            maxLength: 255,
          }}
          value={address}
          onChange={(evt) => {
            if (errors.Address) {
              delete errors.Address
            }
            setAddress(evt.target.value)
          }}
          onKeyDown={(e) => {
            if (e.code === 'Enter') handleLocationAPI()
          }}
        />
        {errors.Address && (
          <p className={classes.warningMessage}>{errors.Address}</p>
        )}
        {newLocationDetails && (
          <>
            <Spacer />
            <div>
              New Location:
              <br />
              {newLocationDetails}
            </div>
          </>
        )}
      </FormControl>
      <Spacer spaceParam={'large'} />
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-end',
          width: '100%',
        }}
      >
        <Button color="link" onClick={() => setShowMap((prev) => !prev)}>
          {showMap ? 'Hide Map' : 'Show Map'}
        </Button>
        <Spacer />
        <Button
          color="primary"
          onClick={handleSave}
          disabled={!newLocation || errors.Address || errors.zipcode}
          style={{ minWidth: '70px', justifyContent: 'center' }}
        >
          Save
        </Button>
      </div>
      <Spacer spaceParam={'large'} />
      <Spacer spaceParam={'large'} />

      {showMap && (
        <div
          style={
            isMobile
              ? { height: '250px', width: '100%' }
              : { height: '450px', width: '100%' }
          }
        >
          {GoogleMapSection}
        </div>
      )}
    </div>
  )

  return (
    <ResponsiveDialog
      open={isOpen}
      onClose={handleClose}
      TransitionComponent={SlideLeft as any}
      maxWidth={false}
    >
      <Toolbar>
        <HeaderNavigation
          title={'Add a Location'}
          onNavClick={handleClose as any}
          canGoBack={false}
        />
      </Toolbar>
      <DialogContent>{renderContent()}</DialogContent>
    </ResponsiveDialog>
  )
}
