import * as React from 'react'
import { DashboardIssueModel, DashboardSeverityModel } from './DashboardModels'
import classes from './DashboardIssuesSection.module.scss'
import { Spacer } from '../../Shared/Spacer'
import { Cell, Label, Pie, PieChart, ResponsiveContainer } from 'recharts'
import { Tooltip } from 'recharts'

interface PieData {
  severityName?: string
  issueCount: number
  color?: string
}

const isIssueActive = (issue: DashboardIssueModel) => {
  if (issue.IssueType === 'A') {
    return true
  }
  return false
}

export const DashboardIssuesSection = ({
  issues,
  severityPicklist,
}: {
  issues: DashboardIssueModel[]
  severityPicklist: DashboardSeverityModel[]
}) => {
  if (!issues) {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
        }}
      >
        No issues
      </div>
    )
  }

  const activeIssues = issues?.filter((issue) => isIssueActive(issue))

  const pieData: PieData[] = severityPicklist?.map((severity) => {
    const combinedIssuesBySeverity = activeIssues?.filter(
      (issue) => issue.SeverityLevelName === severity.SeverityLevelName
    )

    return {
      severityName: combinedIssuesBySeverity[0]?.SeverityLevelName,
      issueCount: combinedIssuesBySeverity.length,
      color: combinedIssuesBySeverity[0]?.SeverityLevelRGB,
    }
  })

  return (
    <div
      style={{
        height: '100%',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
      }}
    >
      <ResponsiveContainer height={'90%'} width={'90%'}>
        <PieChart>
          <Pie
            nameKey="severityName"
            dataKey="issueCount"
            data={pieData}
            cx={'50%'}
            cy={'50%'}
            fill="#82ca9d"
            innerRadius="50%"
          >
            <Label
              style={{
                fontWeight: issues.length > 99 ? '300' : '500',
                fontSize: issues.length > 99 ? '24px' : '36px',
              }}
              value={activeIssues.length}
              position="center"
              fill="black"
            />
            {pieData.map((data, index) => (
              <Cell key={index} fill={data.color} />
            ))}
          </Pie>
          <Tooltip />
        </PieChart>
      </ResponsiveContainer>
      <Spacer />
      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          paddingBottom: '20px',
        }}
      >
        {severityPicklist.map((picklistItem, index) => {
          return (
            <div className={classes.severity} key={index}>
              <span
                className={classes.severityColor}
                style={{ backgroundColor: picklistItem.SeverityLevelRGB }}
              ></span>
              <span>{picklistItem.SeverityLevelName}</span>
            </div>
          )
        })}
      </div>
    </div>
  )
}
