import React from 'react'
import { useState } from 'react'
import { Button } from '../Shared/Buttons'
import { addNotification } from '../_actions'
import { useAppDispatch } from '../_utils/reactReduxHooks'
import { FilePicker } from './FilePicker'

interface Props {
  submitText?: string
  onSubmit: (file: File) => void
  accept?: string
}

export const FilePickerAndSubmitButton = ({
  submitText = 'Submit',
  accept,
  onSubmit,
}: Props) => {
  const dispatch = useAppDispatch()
  const [file, setFile] = useState<File>()

  function onChange(file: File) {
    setFile(file)
  }

  function handleSubmit() {
    if (!file) dispatch(addNotification({ message: 'No file selected' }))
    else onSubmit(file)
  }

  /*function handleInputKeyUp(evt: KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) {
		var enter = 13;
		if(evt.keyCode === enter) {
		this.handleClick();
	}*/

  return (
    <div className="d-flex">
      <FilePicker onChange={onChange} className="mr-1" accept={accept} />
      <Button onClick={handleSubmit}>{submitText}</Button>
    </div>
  )
}
