// @ts-nocheck
// TODO: Typescript
import chatHub from '../Chat/_hub'
import issueHub from '../Issue/_hub'
import webSocketHub from '../_webSocket/_hub'
import holdingStatementHub from '../HoldingStatement/_hub'
import taskHub from '../Task/_hub'
import participantHub from '../Participant/_hub'
import stakeholderHub from '../Stakeholder/_hub'
import externalChangesHub from '../ExternalChanges/_hub'
import conferenceHub from '../Conference/_hub'
import artifactHub from '../Upload/_hub'
import formHub from '../Form/_hub'
import alertHub from '../Alert/_hub'
import authHub from '../Auth/_hub'
const hubs = [
  formHub,
  webSocketHub,
  externalChangesHub,
  issueHub,
  stakeholderHub,
  holdingStatementHub,
  artifactHub,
  chatHub,
  taskHub,
  participantHub,
  conferenceHub,
  alertHub,
  authHub,
]
export default hubs
