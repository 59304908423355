import { FETCH_ORG_UGIS_MEMBERS, OrgTagConstants } from './_constants'
import { mapSort } from '../_utils/objectUtils'
import { callAction } from '../_utils/reduxUtils'
import { createSelector } from 'reselect'
import { OrgTag, UgisMember } from './_models'
import { createEntityReducer } from '../_schema/_reducer'

interface OrgUgisState {
  [OrgID: number]: UgisMember[]
}

export const orgUgisMembers = (state: OrgUgisState = {}, action: any) => {
  const mapped = {
    [FETCH_ORG_UGIS_MEMBERS.SUCCESS]: () => ({
      ...state,
      [action.payload.params.OrgID]: action.payload.result,
    }),
  }

  return callAction(mapped, state, action)
}

export const selectUgisMembersByOrg = (state: OrgUgisState, orgID: number) =>
  state[orgID]

interface OrgTagState {
  [TagID: number]: OrgTag
}

export const orgTagReducer = createEntityReducer('TagID', {
  crudTypes: OrgTagConstants,
})

export default orgTagReducer
/*export default function orgTagReducer(state: OrgTagState = {}, action: any) {

    switch (action.type) {
        case OrgTagConstants.FETCH.SUCCESS:
            return action.payload.result || {};
        case OrgTagConstants.ADD.SUCCESS:
        case OrgTagConstants.UPDATE.SUCCESS:
            let newTag: OrgTag = action.payload.result || {};
            let oldTag = state[newTag.TagID || -1];
            return {
                ...state,
                [newTag.TagID || -1]: { ...oldTag, ...newTag }
            };
        case OrgTagConstants.DELETE.SUCCESS:
            return removeKeyFromObject(state, action.payload.TagID);
        case OrgTagConstants.UPDATE_RANGE.SUCCESS:
            return reducerCrud.updateRange(state, action, 'TagID');
        default:
            return state;
    }
}*/

export const selectOrgTagIdMap = (state: OrgTagState) => state
export const selectOrgTagById = (state: OrgTagState, tagID: number) =>
  (selectOrgTagIdMap(state) || {})[tagID]
export const selectOrgTags: (state: OrgTagState) => OrgTag[] = createSelector(
  selectOrgTagIdMap,
  (idMap) => {
    const list = Object.values(idMap) as OrgTag[]
    return mapSort(list, (item: OrgTag) =>
      (item.TagName || '').toLocaleLowerCase()
    )
  }
)

export const selectOrgTagsByOrgIds: (
  state: OrgTagState,
  orgIDs: number[]
) => OrgTag[] = createSelector(
  selectOrgTags,
  (_: any, orgIds: number[]) => orgIds,
  (list, orgIds) => {
    return (list || []).filter(
      (tag: OrgTag) =>
        (orgIds || []).indexOf(tag.OrgID || -1) > -1 && !tag.TagType
    )
  }
)
