import { generateCrudActions } from '../_utils/reduxUtils'
import { StakeholderConstants } from './_constants'

export const StakeholderActions = {
  ...generateCrudActions(StakeholderConstants.PREFIX),
  setCurrentStakeholderID: (result: number | null) => ({
    type: StakeholderConstants.setCurrentStakeholderID,
    payload: {
      result: result,
    },
  }),
  setCurrentStakeholderLastNotified: (result: string) => ({
    type: StakeholderConstants.setCurrentStakeholderLastNotified,
    payload: {
      result: result,
    },
  }),
}
