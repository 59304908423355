import * as React from 'react'
import { Bar, BarChart, ResponsiveContainer, XAxis, YAxis } from 'recharts'
import { Spacer } from '../../Shared/Spacer'
import {
  BarData,
  DashboardIssueModel,
  DashboardModeModel,
} from './DashboardModels'
import { getModeColor } from './dashboardUtils'
import classes from '../Shared/DashboardIssuesSection.module.scss'

export const DashboardIssueModes = ({
  issues,
  modesPicklist,
}: {
  issues?: DashboardIssueModel[]
  modesPicklist: DashboardModeModel[]
}) => {
  if (!issues) {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
        }}
      >
        No issues
      </div>
    )
  }

  let barData: BarData[] = modesPicklist.map((mode) => {
    const combinedIssuesByMode = issues.filter(
      (issue) => issue.IssueMode === mode.IssueTypeDescr
    )

    return {
      name: combinedIssuesByMode[0]?.IssueMode,
      issueCount: combinedIssuesByMode.length,
      color: getModeColor(mode.IssueTypeDescr),
    }
  })

  const modeMapCount: any = {}

  // Iterate through the array and accumulate counts for each severity name
  barData.forEach((item) => {
    const { name, issueCount } = item

    // If the severity name is not already a key, initialize it with the current count
    // Otherwise, add the count to the existing value
    if (name) modeMapCount[name] = (modeMapCount[name] || 0) + issueCount
  })

  barData = barData.filter((data) => data.name)

  const issueModeSection = (
    <>
      <div style={{ fontSize: '32px', fontWeight: '500' }}>
        {issues?.length}
      </div>
      <Spacer />
      <ResponsiveContainer width="70%" height={'20%'}>
        <BarChart
          data={[modeMapCount]}
          stackOffset={'expand'}
          layout="vertical"
        >
          <XAxis type="number" hide />
          <YAxis type="category" hide />
          {barData.map((data, index) => {
            return (
              <Bar
                key={index}
                isAnimationActive={false}
                dataKey={`${data.name}`}
                stackId="a"
                fill={data.color}
              />
            )
          })}
        </BarChart>
      </ResponsiveContainer>
      <Spacer />
      <div style={{ display: 'flex' }}>
        {modesPicklist.map((picklistItem, index) => {
          return (
            <div className={classes.severity} key={index}>
              <span
                className={classes.severityColor}
                style={{
                  backgroundColor: getModeColor(picklistItem.IssueTypeDescr),
                }}
              ></span>
              <span style={{ display: 'flex' }}>
                {picklistItem.IssueTypeDescr}{' '}
                {modeMapCount[picklistItem.IssueTypeDescr]}
                <Spacer />
              </span>
            </div>
          )
        })}
      </div>
    </>
  )
  return (
    <div
      style={{
        position: 'relative',
        display: 'flex',
        width: '100%',
        height: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
      }}
    >
      {issueModeSection}
    </div>
  )
}
