import React, { useCallback, useMemo, useEffect, useState, useRef } from 'react'
import { Button } from '../Shared/Buttons'
import { MouseEvent } from 'react'
import Moment from 'react-moment'
import { Grid } from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faExternalLinkAlt,
  faShareSquare,
} from '@fortawesome/free-solid-svg-icons'
import classes from './Alerts.module.scss'
import { cx } from '../_utils/objectUtils'
import { ReactComponent as IssuesNewIcon } from '../_images/issuesNew.svg'
import { ReactComponent as IssuesAppendIcon } from '../_images/issuesAppend.svg'
import { embedGoogleMapsSettings } from '../_utils/AppSettings'
import { useAppDispatch, useAppSelector } from '../_utils/reactReduxHooks'
import {
  pinAlert,
  unPinAlert,
  addAlertToIssue,
  createNewAlertIssue,
  createClonedAlertIssue,
  markReviewedAlert,
  markNotReviewedAlert,
} from './_actions'
import { selectAlertByID, selectInboxByID } from './_selectors'

import { appDateTimeFormat } from '../_constants'
import { getLocalDate } from '../_utils/dateUtils'
import { isCreatingAlert } from '../Shared/_requestStatusSelectors'
import { LoadingBackDrop } from '../Shared/LoadingBackdrop'
import { ShareViaEmailPopup } from './ShareViaEmailPopup'
import { useHistory, useLocation, useParams } from 'react-router'
import { AlertsRouteParams } from '../Routing/RouteProps'
import { getAlertIDFromURL, getStandardizedSeverity } from './AlertItem'
import { Spacer } from '../Shared/Spacer'
import { Tooltip } from '@material-ui/core'
import { faCheckSquare } from '@fortawesome/pro-solid-svg-icons'
import { faThumbtack as faSolidPinned } from '@fortawesome/pro-solid-svg-icons'
import { faSquare } from '@fortawesome/pro-regular-svg-icons'
import {
  faThumbtack as faOutlinedPinned,
  faReply,
} from '@fortawesome/pro-light-svg-icons'
import { http } from '../_utils/http'
import { CircularProgress } from '../Shared/LoadingBackdrop'
import { AlertUpdateType } from './_constants'
import { AlertToDBModel } from './_models'
import { addNotification } from '../_actions'
import { ConfirmDialog } from '../Shared/ConfirmDialog'
import { getColorFromSeverity } from './AlertItem'
import { selectAlertSeverityColors } from '../Issue/_selectors'
import { AlertIssueTemplateList } from './AlertIssueTemplateList'
import { ResponsiveDialog } from '../Shared/ResponsiveDialog'
import { SlideLeft } from '../Shared/Transition'
import { DialogContent, Toolbar } from '@material-ui/core'
import { HeaderNavigation } from '../Shared/HeaderNavigation'
import { selectInboxIDsWithDefaultSeverity } from './_selectors'
import { IssueDynamicForm } from '../Issue/IssueDynamicForm'
import colorDefinitions from '../ThemeProvider/ColorDefinitions.module.scss'
import { AlertAppendIssueDialog } from './AlertAppendIssueDialog'

export interface AutomaticProcessWarning {
  active: boolean
  confirmAction: null | (() => void)
}

export const AlertDetails = () => {
  const [severity, setSeverity] = useState<
    Array<{
      LevelID: number
      LevelSequence: number
      LevelName: string
      LevelRGB: string
    }>
  >([])
  const [selectedSeverity, setSelectedSeverity] = useState<string>()
  const [templateDisplay, setTemplateDisplay] = useState<boolean>(false)
  const [newIssueName, setNewIssueName] = useState<string>()
  const [newIssueDescription, setNewIssueDescription] = useState<string>()
  const [isIssueSelectorOpen, setIsIssueSelectorOpen] = useState(false)
  const [isEmailPopupOpen, setIsEmailPopupOpen] = useState(false)
  const [isCreateIssueOpen, setIsCreateIssueOpen] = useState(false)
  const [showIncidentReport, setShowIncidentReport] = useState(true)
  const [incidentReport, setIncidentReport] = useState<
    undefined | { report: string }
  >(undefined)
  const [automaticIssueWarning, setAutomaticIssueWarning] =
    useState<AutomaticProcessWarning>({
      active: false,
      confirmAction: null,
    })
  const [navigatedBack, setNavigatedBack] = useState(false)
  const [showEntireHistory, setShowEntireHistory] = useState(false)

  const topOfPageRef = useRef<HTMLDivElement>(null)
  const scrollToTop = () => {
    topOfPageRef.current?.scrollIntoView({ behavior: 'smooth' })
  }

  const dispatch = useAppDispatch()
  const history = useHistory()
  const urlParams = useParams<AlertsRouteParams>()
  const { pathname, search } = useLocation()

  const isCreating = useAppSelector((state) =>
    isCreatingAlert(state, urlParams.inboxGUID)
  )
  const alert = useAppSelector((state) =>
    selectAlertByID(state, getAlertIDFromURL(pathname, search))
  )
  const inboxIDtoSeverityMap = useAppSelector(selectInboxIDsWithDefaultSeverity)

  const alertSeverity = useMemo(() => {
    return alert?.alertSeverity || inboxIDtoSeverityMap[alert?.inboxID]
  }, [alert, inboxIDtoSeverityMap])

  const inboxInfo = useAppSelector((state) =>
    selectInboxByID(state, alert?.inboxID)
  )

  const severityName = useMemo(
    () => getStandardizedSeverity(alert?.alertSeverity),
    [alert?.alertSeverity]
  )

  const showPinReviewed = useMemo(
    () => alert?.alertStatusID < 4 && !alert?.issueID && !alert?.incidentID,
    [alert?.alertStatusID, alert?.issueID, alert?.incidentID]
  )

  let lastAlertInteractionDate = useMemo(
    () =>
      alert?.alertStatusHistory?.length > 0
        ? alert?.alertStatusHistory[0]?.created
        : alert?.alertCreated
        ? alert?.alertCreated
        : '',
    [alert?.alertStatusHistory, alert?.alertCreated]
  )

  let alertToDB: AlertToDBModel = useMemo(() => {
    let alertToDB: AlertToDBModel = {
      alert_ExtId: alert?.Id,
      alert_Updated: alert?.updated,
      alert_URL: alert?.url,
      alert_Title: alert?.title,
      alert_Content: alert?.summary,
      alert_Location: alert?.location,
      alert_UrlImage: alert?.urlImage,
      alertID: alert?.alertID,
      inboxGUID: alert?.inboxGUID,
      issueID: alert?.issueID,
      inboxID: alert?.inboxID,
      rssID: alert?.rssID,
      severityLevel: alert?.severityLevel,
      alertSeverity: alertSeverity,
      action: '',
      issueTitle: '',
      issueDescription: '',
      alert_WKT: alert?.alert_WKT,
    }
    return alertToDB
  }, [
    alert?.Id,
    alert?.alertID,
    alert?.inboxID,
    alert?.issueID,
    alert?.location,
    alert?.summary,
    alert?.title,
    alert?.updated,
    alert?.url,
    alert?.urlImage,
    alert?.inboxGUID,
    alert?.rssID,
    alert?.severityLevel,
    alertSeverity,
    alert?.alert_WKT,
  ])

  const alertSummaryScrubbed = useMemo(() => {
    let newDescription = alert?.summary
    return newDescription
      ?.replaceAll(/<br[^>]*>/gi, '\n')
      .replaceAll(/(<([^>]+)>)/gi, '')
      .replaceAll('&nbsp;', ' ')
  }, [alert?.summary])

  const severityColors = useAppSelector(selectAlertSeverityColors)

  const severityColor = useMemo(() => {
    return getColorFromSeverity(alertSeverity, severityColors)
      ? {
          backgroundColor:
            getColorFromSeverity(alertSeverity, severityColors) || '',
          color: 'white',
          padding: '1px 2px',
        }
      : {}
  }, [severityColors, alertSeverity])

  useEffect(() => {
    scrollToTop()
  }, [alert?.Id])

  useEffect(() => {
    const getIncidentReport = async (): Promise<any> => {
      var customHeaders: any = []
      customHeaders.push({
        name: 'getaction',
        value: `incident_report`,
      })
      customHeaders.push({
        name: 'incidentid',
        value: `${alert?.incidentID}`,
      })
      customHeaders.push({ name: 'showimagesyn', value: `Y` })

      const result = await http<any>({
        path: '/alert',
        headers: customHeaders,
      })
      if (result.ok && result.body) {
        return result.body
      } else {
        return ''
      }
    }

    if (alert?.incidentID) {
      const doGetIReport = async () => {
        const myIReport = await getIncidentReport()
        setIncidentReport(myIReport)
      }

      doGetIReport()
    }
  }, [alert?.incidentID])

  useEffect(() => {
    setShowEntireHistory(false)
  }, [alert?.Id])

  const updateAlert = useCallback(
    (action: AlertUpdateType, IssueID?: number) => {
      var severityLevel = selectedSeverity
      const alertUpdate = {
        ...alertToDB,
        issueTitle: newIssueName || '',
        issueDescription: newIssueDescription || '',
        IssueID,
        severityLevel,
        action,
        alert_Updated: getLocalDate(alertToDB.alert_Updated),
      }
      switch (action) {
        case 'addToIssue':
          dispatch(addAlertToIssue.request(alertUpdate))
          break
        case 'newIssue':
          dispatch(createNewAlertIssue.request(alertUpdate))
          break
        case 'clonedIssue':
          dispatch(createClonedAlertIssue.request(alertUpdate))
          break
      }
    },
    [alertToDB, dispatch, newIssueDescription, newIssueName, selectedSeverity]
  )

  const getSeverity = useCallback(() => {
    const requestOptions: any = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        inboxID: alertToDB.inboxID,
        getAction: 'severity',
      },
    }
    fetch('/api/alert', requestOptions)
      .then(async (response) => {
        const data = await response.json()
        // check for error response
        if (!response.ok) {
          // get error message from body or default to response status
          const error = (data && data.message) || response.status
          return Promise.reject(error)
        }

        if (data.error) {
          //todo do a dispatch instead
          dispatch(addNotification({ message: data.error, type: 'error' }))
        } else {
          setSeverity(data)
        }
      })
      .catch((error) => {
        console.error('There was an error!', error)
      })
  }, [alertToDB.inboxID, dispatch])

  const toggleEmailPopup = useCallback(() => {
    setIsEmailPopupOpen((prev) => !prev)
  }, [])

  const toggleCreateIssue = useCallback(() => {
    setSelectedSeverity(undefined)
    setNewIssueName(alert?.title)
    setNewIssueDescription(alertSummaryScrubbed)
    setIsCreateIssueOpen((prev) => !prev)
  }, [alert?.title, alertSummaryScrubbed])

  const togglePopupIssueSelector = useCallback(() => {
    if (!isIssueSelectorOpen) {
      getSeverity()
    }
    setIsIssueSelectorOpen((prev) => !prev)
  }, [getSeverity, isIssueSelectorOpen])

  const handleNavigateToIssue = useCallback(
    (evt: MouseEvent) => {
      evt.preventDefault()
      if (alert?.issueID && alert?.issueID > 0) {
        history.push({ pathname: '/IssueDetail/' + alert?.issueID })
      }
      return false
    },
    [history, alert?.issueID]
  )
  const handleSeverityClick = useCallback((evt: any) => {
    setSelectedSeverity(evt.target.value)
  }, [])

  const handlePin = useCallback(
    (e) => {
      e.preventDefault()
      if (
        inboxInfo.process_type === 'C' ||
        (severityName === 'High' && inboxInfo.process_type !== 'I')
      ) {
        setAutomaticIssueWarning({
          active: true,
          confirmAction: () => {
            getSeverity()
            toggleCreateIssue()
          },
        })
      } else {
        dispatch(
          pinAlert.request({
            ...alertToDB,
            action: 'pin',
            alert_Updated: getLocalDate(alertToDB.alert_Updated),
          })
        )
      }
    },
    [
      alertToDB,
      dispatch,
      getSeverity,
      inboxInfo.process_type,
      severityName,
      toggleCreateIssue,
    ]
  )

  const handleButtonClick = useCallback(
    (evt: MouseEvent<SVGSVGElement>, action: AlertUpdateType) => {
      evt.preventDefault()
      if (action === 'share') {
        // open a pop-up to get the email address list to send to
        // then send a request to the database to send it.
        if (
          inboxInfo.process_type === 'C' ||
          (severityName === 'High' && inboxInfo.process_type !== 'I')
        ) {
          setAutomaticIssueWarning({
            active: true,
            confirmAction: toggleEmailPopup,
          })
        } else {
          toggleEmailPopup()
        }
      }
      if (action === 'newIssue') {
        getSeverity()
        toggleCreateIssue()
      }
      if (action === 'addToIssue') {
        togglePopupIssueSelector()
      }
      if (action === 'markReviewed') {
        dispatch(
          markReviewedAlert.request({
            ...alertToDB,
            action,
            alert_Updated: getLocalDate(alertToDB.alert_Updated),
          })
        )
      }
      if (action === 'markNotReviewed') {
        dispatch(
          markNotReviewedAlert.request({
            ...alertToDB,
            action,
            alert_Updated: getLocalDate(alertToDB.alert_Updated),
          })
        )
      }
      if (action === 'pin') {
        handlePin(evt)
      }
      if (action === 'unPin') {
        dispatch(
          unPinAlert.request({
            ...alertToDB,
            action,
            alert_Updated: getLocalDate(alertToDB.alert_Updated),
          })
        )
      }
    },
    [
      dispatch,
      alertToDB,
      toggleEmailPopup,
      getSeverity,
      togglePopupIssueSelector,
      toggleCreateIssue,
      inboxInfo,
      severityName,
      handlePin,
    ]
  )

  const handleIssueSelectorSubmit = useCallback(
    (selectedIssue: number) => {
      if (selectedIssue) updateAlert('addToIssue', selectedIssue)
      togglePopupIssueSelector()
    },
    [togglePopupIssueSelector, updateAlert]
  )

  const handleCreateNewIssue = useCallback(
    (evt: MouseEvent) => {
      evt.preventDefault()
      if (!selectedSeverity) {
        dispatch(
          addNotification({
            message: 'Severity for Issue was not selected.',
            type: 'error',
          })
        )
      } else if (!(newIssueName && newIssueDescription)) {
        dispatch(
          addNotification({
            message: 'Issue must have a Title and Description.',
            type: 'error',
          })
        )
      } else {
        updateAlert('newIssue')
        toggleCreateIssue()
      }
      return false
    },
    [
      selectedSeverity,
      updateAlert,
      toggleCreateIssue,
      dispatch,
      newIssueDescription,
      newIssueName,
    ]
  )

  const renderAttachments = useCallback(() => {
    var url = '/api/Alert/GetAttachment/'
    if (alert?.alert_Attachments) {
      let attJson: Array<{
        id: number
        FileName: string
        ContentType: string
        ContentLength: number
        IsVirusCheckedYN: 'N' | 'Y'
        created: string
      }> = JSON.parse(alert?.alert_Attachments)

      return (
        <div>
          <p>
            <b>Attachments:</b>
          </p>
          {attJson.map((item: { id: number; FileName: string }) => (
            <p className={cx('clearfix')} key={`attachment_${item.id}`}>
              <a
                id="readMore"
                rel="noreferrer"
                href={url + item.id}
                target="_blank"
              >
                {item.FileName}
              </a>
            </p>
          ))}
        </div>
      )
    } else return ''
  }, [alert?.alert_Attachments])

  const renderGoogleMap = useCallback(() => {
    if (alertToDB.alert_Location) {
      var arrLoc = alertToDB.alert_Location.split(/[\s,]+/) as string[]
      if (!isNaN(arrLoc[0] as any) && !isNaN(arrLoc[1] as any)) {
        var frameSRC =
          embedGoogleMapsSettings.APIUrl +
          'place?key=' +
          embedGoogleMapsSettings.APIKey +
          '&q=' +
          arrLoc[0] +
          ',' +
          arrLoc[1]
        return (
          <Grid item xs={12}>
            <iframe
              title="Google Map"
              width="400"
              height="200"
              frameBorder="0"
              scrolling="no"
              marginHeight={0}
              marginWidth={0}
              src={frameSRC}
            ></iframe>
          </Grid>
        )
      } else return ''
    } else return ''
  }, [alertToDB.alert_Location])

  const renderEmailPopup = useCallback(() => {
    return (
      <div>
        {isEmailPopupOpen && (
          <ShareViaEmailPopup
            handleClose={toggleEmailPopup}
            alertToDB={alertToDB}
          />
        )}
      </div>
    )
  }, [alertToDB, toggleEmailPopup, isEmailPopupOpen])

  const renderReviewedButton = useCallback(() => {
    if (alert?.alertStatusID === 2) {
      return (
        <Tooltip
          key={'notReviewed'}
          placement="top"
          title={'Mark as new alert'}
        >
          <span className={classes.iconWrapper}>
            <FontAwesomeIcon
              icon={faCheckSquare}
              name="notReviewed"
              size="lg"
              onClick={(e) => handleButtonClick(e, 'markNotReviewed')}
            />
          </span>
        </Tooltip>
      )
    } else {
      return (
        <Tooltip
          key={'reviewed'}
          placement="top"
          title={'Mark as Reviewed, no actions taken'}
        >
          <span className={classes.iconWrapper}>
            <FontAwesomeIcon
              icon={faSquare}
              name="reviewed"
              size="lg"
              onClick={(e) => handleButtonClick(e, 'markReviewed')}
            />
          </span>
        </Tooltip>
      )
    }
  }, [handleButtonClick, alert?.alertStatusID])

  const renderPinButton = useCallback(() => {
    if (alert?.alertStatusID === 3) {
      return (
        <Tooltip key={'unPin'} placement="top" title={'Unpin this Alert'}>
          <span className={classes.iconWrapper}>
            <FontAwesomeIcon
              icon={faSolidPinned} //update font awesome for recent icons
              name="unPin"
              size="lg"
              onClick={(e) => handleButtonClick(e, 'unPin')}
            />
          </span>
        </Tooltip>
      )
    } else {
      return (
        <Tooltip key={'pin'} placement="top" title={'Pin this Alert'}>
          <span className={classes.iconWrapper}>
            <FontAwesomeIcon
              icon={faOutlinedPinned} //update font awesome for recent icons
              name="pin"
              size="lg"
              onClick={(e) => handleButtonClick(e, 'pin')}
            />
          </span>
        </Tooltip>
      )
    }
  }, [alert?.alertStatusID, handleButtonClick])

  const renderShareButton = useCallback(() => {
    return (
      <Tooltip key={'share'} placement="top" title={'Share alert'}>
        <span className={classes.iconWrapper}>
          <FontAwesomeIcon
            icon={faShareSquare} //update font awesome for recent icons
            name="share"
            size="lg"
            onClick={(e) => handleButtonClick(e, 'share')}
          />
        </span>
      </Tooltip>
    )
  }, [handleButtonClick])

  const renderIssueButton = useCallback(() => {
    return alert?.issueID ? (
      <Tooltip title={'Go to issue'} key="go2issue" placement="top">
        <span className={classes.iconWrapper}>
          <FontAwesomeIcon
            icon={faReply}
            size="lg"
            onClick={handleNavigateToIssue}
          />
        </span>
      </Tooltip>
    ) : (
      <Tooltip title={'Create Issue'} key="newIssue" placement="top">
        <span className={classes.iconWrapper}>
          <IssuesNewIcon
            height="25px"
            width="25px"
            onClick={(e) => handleButtonClick(e, 'newIssue')}
          />
        </span>
      </Tooltip>
    )
  }, [handleNavigateToIssue, alert?.issueID, handleButtonClick])

  const renderIssueAppendButton = useCallback(() => {
    return alert?.issueID ? null : (
      <Tooltip title={'Append to Issue'} key="addToIssue" placement="top">
        <span className={classes.iconWrapper}>
          <IssuesAppendIcon
            height="25px"
            width="25px"
            onClick={(e) => handleButtonClick(e, 'addToIssue')}
          />
        </span>
      </Tooltip>
    )
  }, [alert?.issueID, handleButtonClick])

  const renderIncidentReport = useCallback(() => {
    return (
      <>
        <Spacer spaceParam={'large'} />
        <Button
          color="primary"
          onClick={() => setShowIncidentReport((prev) => !prev)}
        >
          {showIncidentReport ? 'Hide' : 'Show'} Incident Report
        </Button>
        <Spacer spaceParam={'large'} />
        {showIncidentReport && (
          <p
            style={{ display: 'inline-block', marginLeft: '.5rem' }}
            dangerouslySetInnerHTML={{
              __html:
                incidentReport && incidentReport.report
                  ? incidentReport.report
                  : '',
            }}
          />
        )}
      </>
    )
  }, [incidentReport, showIncidentReport])

  const handleNavigateIssueCreator = useCallback(() => {
    if (templateDisplay) {
      setTemplateDisplay(false)
      setNavigatedBack((prev) => !prev)
    } else {
      toggleCreateIssue()
    }
  }, [templateDisplay, toggleCreateIssue])

  const renderCreateIssue = useCallback(() => {
    return (
      <ResponsiveDialog
        open={isCreateIssueOpen}
        onClose={toggleCreateIssue}
        TransitionComponent={SlideLeft as any}
        style={{ maxWidth: '450px', minWidth: '400px', margin: '0 auto' }}
      >
        <Toolbar>
          <HeaderNavigation
            title={
              templateDisplay
                ? 'Complete and Submit Form'
                : 'Select Create Issue Form'
            }
            onNavClick={handleNavigateIssueCreator as any}
            canGoBack={templateDisplay}
          />
        </Toolbar>
        <DialogContent>
          <div>
            <AlertIssueTemplateList
              orgName={alert?.OrgName}
              orgId={alert?.OrgID}
              alertName={alert?.title}
              alertDescription={alert?.summary}
              handleDialogClose={toggleCreateIssue}
              toggleTemplateDisplay={(show: boolean) => {
                setTemplateDisplay(show)
              }}
              navigatedBack={navigatedBack}
              updateAlert={updateAlert}
              newIssueName={newIssueName}
              setNewIssueName={setNewIssueName}
              newDescription={newIssueDescription}
              setNewDescription={setNewIssueDescription}
            />
            {!templateDisplay && (
              <>
                <Spacer spaceParam={'large'} />
                <span
                  style={{
                    display: 'block',
                    fontWeight: '500',
                    fontSize: '22px',
                    color: colorDefinitions.iccBlack,
                  }}
                >
                  Create Without Template
                </span>
                <Spacer />
                <p
                  style={{
                    marginLeft: '0',
                    marginBottom: '0',
                    width: '100%',
                  }}
                >
                  Title for Issue:
                </p>
                <p style={{ marginTop: '3px' }}>
                  <input
                    type="text"
                    value={newIssueName}
                    onChange={(e) => setNewIssueName(e.target.value)}
                    style={{
                      width: '100%',
                      border: '1px solid #6e6e6e',
                      padding: '5px 8px',
                      margin: '0',
                    }}
                  />
                </p>
                <p
                  style={{
                    marginLeft: '0',
                    marginBottom: '0',
                    width: '100%',
                  }}
                >
                  Description of Issue:
                </p>
                <p style={{ marginTop: '3px' }}>
                  <textarea
                    value={newIssueDescription}
                    onChange={(e) => setNewIssueDescription(e.target.value)}
                    style={{
                      width: '100%',
                      border: '1px solid #6e6e6e',
                      padding: '5px 8px',
                      margin: '0',
                    }}
                  />
                </p>
                <p
                  style={{
                    marginLeft: '0',
                    marginBottom: '3px',
                    width: '100%',
                  }}
                >
                  Severity Level:
                </p>

                {severity.length > 0 ? (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      flexWrap: 'wrap',
                      justifyContent: 'flex-start',
                    }}
                  >
                    {severity.map((srv) => (
                      <div
                        key={'div_' + srv.LevelID}
                        style={{
                          backgroundColor: srv.LevelRGB,
                          margin: '5px 2px',
                          padding: '5px',
                          flexBasis: '45%',
                          maxWidth: '155px',
                          display: 'inline-block',
                          alignContent: 'center',
                          borderRadius: '7.5px',
                        }}
                      >
                        <input
                          type="radio"
                          name="icc_severity_button"
                          value={srv.LevelName}
                          onChange={handleSeverityClick}
                          style={{
                            marginLeft: '5px',
                            verticalAlign: 'center',
                            width: '1.25rem',
                            height: '1.25rem',
                          }}
                        />
                        <label
                          style={{
                            fontWeight: 'bold',
                            color: '#fff',
                            display: 'inline-block',
                            maxWidth: '115px',
                            marginLeft: '10px',
                            marginRight: '0',
                            marginBottom: '0',
                            marginTop: '0',
                            backgroundColor: 'transparent',
                          }}
                        >
                          {srv.LevelName}
                        </label>
                      </div>
                    ))}
                  </div>
                ) : (
                  <CircularProgress />
                )}
                <br />
                <p
                  style={{
                    textAlign: 'center',
                  }}
                >
                  <Button id="submit" onClick={handleCreateNewIssue}>
                    Submit Form
                  </Button>
                </p>
              </>
            )}
          </div>
        </DialogContent>
      </ResponsiveDialog>
    )
  }, [
    isCreateIssueOpen,
    toggleCreateIssue,
    templateDisplay,
    handleNavigateIssueCreator,
    alert?.OrgName,
    alert?.title,
    alert?.summary,
    navigatedBack,
    updateAlert,
    newIssueName,
    newIssueDescription,
    severity,
    handleCreateNewIssue,
    handleSeverityClick,
    alert?.OrgID,
  ])

  const PinOrAssessment = alert?.alertID ? (
    <div style={{ margin: '-16px' }}>
      <IssueDynamicForm
        IssueID={alert.issueID}
        alertID={alert.alertID}
        dfName="Assessment"
      />
    </div>
  ) : (
    <>
      <Button
        onClick={(e) => handlePin(e)}
        style={{ background: colorDefinitions.primaryControlDarker }}
      >
        Start Assessment
      </Button>
      <Spacer spaceParam={'large'} />
    </>
  )

  if (!alert) {
    return (
      <div>
        The alert source no longer provides the information for this Alert. We
        recommend pinning alerts to preserve the alert's information if it may
        be needed indefinitely.
      </div>
    )
  }

  return (
    <>
      <LoadingBackDrop
        text="Creating Alert..."
        open={isCreating ? true : false}
        className="position-absolute h-100 w-100"
        zIndex={99}
      />
      <Grid className={cx(classes.alertDetailCard)}>
        <Grid container spacing={1} direction="column">
          <Grid
            container
            spacing={3}
            direction="row"
            justifyContent="flex-start"
            alignContent="center"
            ref={topOfPageRef}
          >
            {showPinReviewed && <Grid item>{renderReviewedButton()}</Grid>}
            {showPinReviewed && <Grid item>{renderPinButton()}</Grid>}
            <Grid item>{renderShareButton()}</Grid>
            <Grid item>{renderIssueButton()}</Grid>
            <Grid item>{renderIssueAppendButton()}</Grid>
          </Grid>
          <Spacer spaceParam={'large'} />
          {alert?.alertStatus && (
            <span style={{ fontSize: '.8rem' }}>
              Last Interaction:{' '}
              {alert?.alertStatusID === 2 || alert?.alertStatusID === 1
                ? 'Marked as ' + alert?.alertStatus
                : alert?.alertStatus}{' '}
              on{' '}
              {
                <Moment format={appDateTimeFormat} local>
                  {getLocalDate(lastAlertInteractionDate)}
                </Moment>
              }
            </span>
          )}
          <span style={{ fontSize: '.8rem' }}>Org Name: {alert?.OrgName}</span>
          <span style={{ fontSize: '.8rem' }}>
            Inbox: {inboxInfo.inboxName}
          </span>
          <Grid item xs={12}>
            <hr />
          </Grid>
          <Grid item xs={12}>
            <h6>{alert?.title}</h6>
            <p style={{ fontSize: '.9rem' }}>
              <b>Updated:</b>{' '}
              <Moment format={appDateTimeFormat} local>
                {getLocalDate(alert?.updated)}
              </Moment>
            </p>
            {alertSeverity && (
              <p style={{ fontSize: '.9rem' }}>
                <b>Alert Severity:</b>&nbsp;
                <span style={severityColor}>{alertSeverity}</span>
              </p>
            )}

            {alert?.location ? (
              <p style={{ fontSize: '.9rem' }}>
                <b>Location:</b> {alert?.location}
              </p>
            ) : (
              ''
            )}
            <div
              style={{
                display: 'inline-flex',
                marginBottom: '1rem',
              }}
              className={cx('clearfix')}
            >
              {alert?.urlImage ? (
                <img
                  className={classes.alertContentImage}
                  src={alert?.urlImage}
                  alt=""
                />
              ) : (
                ''
              )}
              <p
                style={{
                  display: 'inline-block',
                  marginLeft: '.5rem',
                  fontSize: '.8rem',
                }}
                className={classes.imgContainer}
                dangerouslySetInnerHTML={{
                  __html: alert?.summary || '',
                }}
              />
            </div>
            {alert?.url ? (
              <p className={cx('clearfix')} style={{ fontSize: '.8rem' }}>
                <a
                  id="readMore"
                  href={alert?.url}
                  target="_blank"
                  rel="noreferrer"
                >
                  <FontAwesomeIcon
                    className={classes.rdsBlue}
                    icon={faExternalLinkAlt}
                  />{' '}
                  Read more ...
                </a>
              </p>
            ) : (
              ''
            )}
            {renderGoogleMap()}
            {alert?.incidentID && renderIncidentReport()}
            <br />
            {PinOrAssessment}
            <br />
            {renderAttachments()}
          </Grid>
        </Grid>
        <div style={{ width: '100%', borderBottom: 'solid 1px lightGrey' }} />
        <Spacer spaceParam={'large'} />
        {alert?.alertStatusID === 2 && (
          <>
            <div style={{ fontSize: '.8rem' }}>
              Reviewed By: {alert?.alertStatusHistory[0]?.createdBy}
            </div>
            <Spacer spaceParam={'large'} />
          </>
        )}
        <Spacer spaceParam={'large'} />
        {alert?.alertStatusHistory?.length > 0 && (
          <h6>History of Interactions</h6>
        )}
        {alert?.alertStatusHistory
          ?.filter((alert, index) => (showEntireHistory ? true : index < 10))
          .map((actionTaken, index) => {
            if (actionTaken.StatusID === 2 || actionTaken.StatusID === 1) {
              return (
                <div key={index} style={{ fontSize: '.8rem' }}>
                  Marked as {actionTaken.Status} by {actionTaken.createdBy}
                  ,&nbsp;
                  <Moment format={appDateTimeFormat} local>
                    {getLocalDate(actionTaken.created)}
                  </Moment>
                  <br />
                </div>
              )
            } else {
              return (
                <div key={index} style={{ fontSize: '.8rem' }}>
                  {actionTaken.Status} by {actionTaken.createdBy},&nbsp;
                  <Moment format={appDateTimeFormat} local>
                    {getLocalDate(actionTaken.created)}
                  </Moment>
                  <br />
                </div>
              )
            }
          })}
        {!showEntireHistory && alert?.alertStatusHistory?.length > 10 && (
          <div
            className={classes.showMore}
            onClick={() => setShowEntireHistory(true)}
          >
            ... show more
          </div>
        )}
      </Grid>
      {renderCreateIssue()}
      {renderEmailPopup()}
      {automaticIssueWarning.active && (
        <ConfirmDialog
          open={automaticIssueWarning.active}
          title={'Action Will Create a New Issue'}
          content={`Because your inbox is configured to automatically process an alert into an Issue, pinning or sharing will also create a new Issue. Do you wish to continue?`}
          confirm={() =>
            automaticIssueWarning.confirmAction
              ? automaticIssueWarning.confirmAction()
              : setAutomaticIssueWarning((prev) => ({
                  active: false,
                  confirmAction: null,
                }))
          }
          cancel={() =>
            setAutomaticIssueWarning((prev) => ({
              active: false,
              confirmAction: null,
            }))
          }
        />
      )}
      <AlertAppendIssueDialog
        isOpen={isIssueSelectorOpen}
        onCancel={togglePopupIssueSelector}
        onSubmit={handleIssueSelectorSubmit}
        inboxGUID={inboxInfo.inboxGUID}
        alertID={alert?.Id}
      />
    </>
  )
}
