// @ts-nocheck
// TODO: Typescript
import { addNotification } from '../_actions'
import {
  FETCH_CHANGE_LEVEL_FORM_CONTENT,
  SUBMIT_ISSUE_FORM,
} from './_constants'
import { push } from 'connected-react-router'
import { getIssuePath } from '../_utils/reduxUtils'

export default function formHub(storeAPI, action, _) {
  const { payload } = action
  let { error } = payload || {}
  error = error || ''
  switch (action.type) {
    case FETCH_CHANGE_LEVEL_FORM_CONTENT.FAILURE:
    case SUBMIT_ISSUE_FORM.FAILURE:
      if (!error && payload.source.submitType === 'create')
        storeAPI.dispatch(
          addNotification({
            message: 'Issue Create Failed',
          })
        )
      else storeAPI.dispatch(addNotification({ message: error }))
      break
    case SUBMIT_ISSUE_FORM.SUCCESS:
      // Let the client pick the issue id
      if (payload.source.submitType === 'create')
        storeAPI.dispatch(
          push(
            getIssuePath({
              ...payload.result,
              IssueTeamWorkspaceID: null,
            })
          )
        )
      break
    default:
      break
  }
}
