import React, { useState, useEffect, useCallback } from 'react'
import { login, logout, loginClearStatus } from './_actions'
import { Redirect, useHistory, useLocation } from 'react-router-dom'
import { Button } from '../Shared/Buttons'
import { TextField } from '../Shared/TextField'
import { prefixSelectorWithApp } from '../_utils/domUtils'
import { icoPost } from '../_utils/fetchUtils'
import { AuthMasterLayout, AuthMasterLayoutProps } from './AuthMasterLayout'
import {
  loginADFailure,
  loginAD,
  disableMSTeamsAutoLogin,
} from '../MicrosoftTeams/_actions'
import { teamsAuthenticate } from '../MicrosoftTeams/ADAuthService'
import { CircularProgress } from '@material-ui/core'
import {
  AD_SILIENT_FAILURE,
  AD_MSG_SILENT_FAILURE,
  AD_FAILED_TO_OPEN_WINDOW,
  AD_CANCELED_BY_USER,
} from '../MicrosoftTeams/_constants'
import classes from './LoginForm.module.scss'
import {
  selectIsLoginFailure,
  isUserAuthenticated,
  selectAuthPage,
  selectLoginFailureMessage,
  selectIsLoggingOn,
} from './_selectors'
import {
  selectIsMicrosoftTeams,
  selectIsMSTeamsAutoLoginEnabled,
  selectAzureADLoginCode,
  selectMSTeamsLoginHint,
} from '../MicrosoftTeams/_selectors'
import {
  selectIsAppInitialDataLoading,
  selectIsAppInitialDataLoaded,
  selectIsAzureADLoginRequest,
  selectIsFetchingRequestToken,
} from '../Shared/_requestStatusSelectors'
import { LoadingBackDrop } from '../Shared/LoadingBackdrop'
import { selectInitialView } from '../_rootConfigs/rootSelectors'
import { useAppDispatch, useAppSelector } from '../_utils/reactReduxHooks'
import { Tooltip } from '../Shared/Tooltip'
import { cx } from '../_utils/objectUtils'
import { Spacer } from '../Shared/Spacer'
import microsoftLogo from '../_images/microsoft-logo-no-text.svg'

const homePath = '/'

const infoMsg =
  'Please note: The In Case of Crisis User Login is where you can access any assigned playbooks or issues from your organization. If you do not see anything once logged in please contact your internal Administrator for In Case of Crisis. If you have any trouble logging in to the platform please contact support@incaseofcrisis.com.'

interface Props extends AuthMasterLayoutProps {
  id: any
  location: any
}

export const LoginForm = ({ id, location, ...rest }: Props) => {
  const [loggingIn, setLoggingIn] = useState(false)
  const { search } = useLocation<{ email?: string }>()
  const queryParameters = new URLSearchParams(search)
  const email = queryParameters.get('email')

  const [authType, setAuthType] = useState('')
  const [error, setError] = useState('')
  const [userEmail, setUserEmail] = useState(email || '')
  const [password, setPassword] = useState('')

  const isAuthenticated = useAppSelector(isUserAuthenticated)
  const isMSTeamsAutoLoginEnabled = useAppSelector(
    selectIsMSTeamsAutoLoginEnabled
  )
  const isAzureADLoginRequest = useAppSelector(selectIsAzureADLoginRequest)
  const redirectToReferrer = useAppSelector(selectAuthPage).redirectToReferrer
  const isLoginFailure = useAppSelector(selectIsLoginFailure)
  const loginFailureMessage = useAppSelector(selectLoginFailureMessage)
  const isLoggingOnReducer = useAppSelector(selectIsLoggingOn)
  const requestLoginType = (Em: any, onDone: any) => {
    icoPost('/api/CookieAuth/LoginType', { Em })
      .then((response) => response.json())
      .then((data) => onDone(data))
      .catch((_) =>
        onDone({
          error: 'Could not validate the email address',
        })
      )
  }
  const azureADLoginCode = useAppSelector(selectAzureADLoginCode)
  const isMicrosoftTeams = useAppSelector(selectIsMicrosoftTeams)
  const loginHint = useAppSelector(selectMSTeamsLoginHint)
  const isFetchingRequestToken = useAppSelector(selectIsFetchingRequestToken)
  const initialView = useAppSelector(selectInitialView)
  const isAppInitialDataLoading = useAppSelector(selectIsAppInitialDataLoading)
  const isAppInitialDataLoaded = useAppSelector(selectIsAppInitialDataLoaded)

  const history = useHistory()
  const dispatch = useAppDispatch()

  const handleTeamsLogin = useCallback(() => {
    dispatch(loginAD(undefined))
    teamsAuthenticate(
      {
        failureCallback: () => dispatch(loginADFailure(undefined, undefined)),
      },
      {
        prompt: azureADLoginCode === AD_SILIENT_FAILURE ? true : false,
        loginHint,
        isTeamsApp: isMicrosoftTeams,
      }
    )
  }, [azureADLoginCode, dispatch, isMicrosoftTeams, loginHint])

  const autoLoginMSTeams = useCallback(() => {
    if (isMSTeamsAutoLoginEnabled && !isAzureADLoginRequest) {
      handleTeamsLogin()
      disableMSTeamsAutoLogin()
    }
  }, [handleTeamsLogin, isAzureADLoginRequest, isMSTeamsAutoLoginEnabled])

  useEffect(() => {
    autoLoginMSTeams()
  }, [autoLoginMSTeams])

  const loginUser = () => {
    dispatch(loginClearStatus())
    dispatch(
      login({
        em: (userEmail || '').trim(),
        pw: (password || '').trim(),
      })
    )
  }

  const handleRequestLoginType = () => {
    if (loggingIn) return
    setLoggingIn(true)
    requestLoginType(
      userEmail,
      ({
        authType,
        error,
        loginURL,
      }: {
        authType: string
        error?: string
        loginURL?: Location
      }) => {
        if (!error) {
          if (loginURL) {
            window.location = loginURL
          } else {
            setLoggingIn(false)
            setAuthType(authType)
            setError('')
          }
        } else {
          setLoggingIn(false)
          setAuthType('')
          setError(error)
        }
      }
    )
  }

  const handleLoginKeyDown = (event: any) => {
    var enter = 13
    if (event.keyCode === enter) {
      const action = authType ? loginUser : handleRequestLoginType
      action()
    }
  }

  const handleSwitchUser = () => {
    dispatch(loginClearStatus())
    setUserEmail('')
    setAuthType('')
    setError('')
  }

  const handleForgotPasswordClick = () => {
    history.push({
      pathname: '/ForgotPassword',
      state: { userEmailAddress: userEmail },
    })
  }

  const handleCreateNewClick = () => {
    history.push({
      pathname: '/CreateAccount',
      state: { userEmailAddress: userEmail },
    })
  }

  let { from } = location.state || {
    from: { pathname: homePath },
  }
  if (!from) from = {}

  const initEmailCleanedUp = (email || '').trim().toLocaleLowerCase()

  if (from.pathname === homePath || !from.pathname) from.pathname = initialView
  if (redirectToReferrer) return <Redirect to={from} />
  if (
    isAppInitialDataLoaded &&
    isAuthenticated &&
    (userEmail === initEmailCleanedUp || !initEmailCleanedUp)
  )
    return <Redirect to={initialView || homePath} />

  let azureAuthError = ''

  if (azureADLoginCode) {
    azureAuthError =
      azureADLoginCode === AD_SILIENT_FAILURE ? AD_MSG_SILENT_FAILURE : ''
    //AD_MSG_POPUP_BLOCKED;
  }

  const hasLoginError =
    azureADLoginCode && azureADLoginCode !== AD_FAILED_TO_OPEN_WINDOW
      ? true
      : false
  const isAuthPopupBlocked =
    azureADLoginCode === AD_FAILED_TO_OPEN_WINDOW ? true : false

  if (isFetchingRequestToken || isAppInitialDataLoading)
    return (
      <LoadingBackDrop
        text={
          isAppInitialDataLoading ? 'Loading User Data...' : 'Loading Login...'
        }
        open={true}
      />
    )

  // Microsoft Teams only login
  if (isMicrosoftTeams) {
    return (
      <AuthMasterLayout
        {...rest}
        showLogo={true}
        showHomeLink={false}
        alertColor="danger"
        alertText={azureAuthError}
        showAlert={azureADLoginCode === AD_SILIENT_FAILURE ? true : false}
      >
        <div
          className={cx(classes.form, classes.formBackground)}
          style={{ padding: '2.5rem' }}
        >
          <h5>In Case of Crisis Teams Integration</h5>
          <Spacer spaceParam={'large'} />
          {hasLoginError || isAuthPopupBlocked || !isAzureADLoginRequest ? (
            <Button
              color="primary"
              onClick={handleTeamsLogin}
              capitalize={false}
            >
              Login with Microsoft
            </Button>
          ) : (
            <div>
              <CircularProgress
                color="primary"
                title="Signing in..."
                disableShrink={true}
              />
              <span
                style={{
                  position: 'relative',
                  top: -10,
                  left: 10,
                }}
              >
                Signing in...
              </span>
            </div>
          )}
        </div>
      </AuthMasterLayout>
    )
  }

  // Basic forms login and ms teams login
  return (
    <AuthMasterLayout
      {...rest}
      showLogo={true}
      showHomeLink={false}
      alertColor="danger"
      alertText={
        error ||
        loginFailureMessage ||
        azureAuthError ||
        'Bad login, check that the email and password are correct.'
      }
      showAlert={
        [AD_FAILED_TO_OPEN_WINDOW, AD_CANCELED_BY_USER].indexOf(
          azureADLoginCode
        ) === -1 &&
        (isLoginFailure || error || azureADLoginCode)
      }
    >
      <div className={cx(classes.form, classes.formBackground)}>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <div className={classes.tabHeaders}>User Login</div>
          <div
            className={cx(classes.tabHeaders, classes.unClickedTabHeader)}
            onClick={handleCreateNewClick}
          >
            Create Account
          </div>
        </div>
        <div className={classes.formContent}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'flex-start',
            }}
          >
            <h5>
              For users to access Playbooks and Issues Management features{' '}
            </h5>
            <Spacer />
            <Tooltip
              placement="right"
              title={infoMsg}
              style={{ fontSize: '1.25rem' }}
            />
          </div>
          <Spacer spaceParam={'large'} />
          <Spacer spaceParam={'large'} />

          {!authType && (
            <>
              <span style={{ textTransform: 'uppercase' }}>Email</span>
              <Spacer />
              <TextField
                type="text"
                name="email"
                id={prefixSelectorWithApp('client-email')}
                autoFocus={true}
                value={userEmail}
                onChange={(evt) => setUserEmail(evt.target.value)}
                onKeyDown={handleLoginKeyDown}
                disabled={isLoggingOnReducer || loggingIn}
                fullWidth
              />
            </>
          )}
          {authType && (
            <>
              <span style={{ textTransform: 'uppercase' }}>Password</span>
              <Spacer />
              <TextField
                type="password"
                name="password"
                id="password"
                autoFocus={true}
                value={password}
                onChange={(evt) => setPassword(evt.target.value)}
                onKeyDown={handleLoginKeyDown}
                disabled={isLoggingOnReducer || loggingIn}
                fullWidth
              />
            </>
          )}

          {authType && (
            <>
              <Spacer />
              <Button color="link" onClick={handleSwitchUser}>
                &#8592; Switch User
              </Button>
            </>
          )}
          <Spacer spaceParam={'large'} />
          <Spacer spaceParam={'large'} />
          <Button
            color="darker"
            onClick={authType ? loginUser : handleRequestLoginType}
            style={{ textTransform: 'uppercase' }}
            disabled={isLoggingOnReducer || loggingIn}
          >
            {authType ? 'Login' : 'Continue'}
          </Button>
          <Spacer />
          <div>
            <Button color="link" onClick={handleForgotPasswordClick}>
              <i className={classes.forgotPassword}>Forgot Password?</i>
            </Button>
          </div>
          <Spacer spaceParam={'large'} />
          <div
            style={{
              width: '206px',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <div
              style={{
                height: '0px',
                width: '100%',
                borderBottom: '1px solid grey',
              }}
            />
            <div style={{ padding: '0 1rem' }}>OR</div>
            <div
              style={{
                height: '0px',
                width: '100%',
                borderBottom: '1px solid grey',
              }}
            />
          </div>
          <Spacer spaceParam={'large'} />
          <Button
            color="darker"
            icon={
              <img
                src={microsoftLogo}
                alt="microsoft logo"
                style={{ width: '20px', marginRight: '12px' }}
              />
            }
            outline
            onClick={handleTeamsLogin}
            capitalize={false}
          >
            Login with Microsoft
          </Button>
          <Spacer spaceParam={'large'} />
          {isAuthenticated && (
            <div className="form-group titleLogo">
              <Button
                color="darker"
                outline
                onClick={() => dispatch(logout())}
                capitalize
              >
                Log out
              </Button>
            </div>
          )}
        </div>
      </div>
    </AuthMasterLayout>
  )
}
