// @ts-nocheck
// TODO: Typescript
import React from 'react'
import ForgotPasswordEmailSent from './ForgotPasswordEmailSent'
import { prefixSelectorWithApp } from '../_utils/domUtils'

/**
 * @extends {Component<Props, {}>}}
 */
const forgotPasswordEmailSentExport = (props) => (
  <ForgotPasswordEmailSent
    id={prefixSelectorWithApp('ForgotPasswordEmailSentPage')}
    {...props}
  />
)

export default forgotPasswordEmailSentExport
