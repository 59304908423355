// @ts-nocheck
// TODO: Typescript
import React from 'react'
import ResetAccountPassword from './ResetAccountPassword'
import { prefixSelectorWithApp } from '../_utils/domUtils'

/**
 * @extends {Component<Props, {}>}}
 */
const ResetAccountPasswordExport = (props) => (
  <ResetAccountPassword
    id={prefixSelectorWithApp('ResetAccountPasswordPage')}
    {...props}
  />
)

export default ResetAccountPasswordExport
