// @ts-nocheck
// TODO: Typescript
import React, { Component } from 'react'
import { connect } from 'react-redux'
import Autocomplete, {
  createFilterOptions,
} from '@material-ui/lab/Autocomplete'
import PropTypes from 'prop-types'
import { TextField } from '@material-ui/core'
import { selectIssueTagIds } from '../Issue/_selectors'
import { selectOrgTagsByOrgIds } from './_selectors'
import { selectIssueOrgIds } from '../_rootConfigs/rootSelectors'

const filter = createFilterOptions()

/**
 * @extends {Component<Props, {}>}}
 */
export class OrgTagAutocomplete extends Component {
  constructor(props) {
    super(props)
    this.handleTagChange = this.handleTagChange.bind(this)
  }

  handleTagChange(_, tag, reason) {
    const { onTagSelected, IssueID } = this.props
    if (reason === 'select-option' || reason === 'create-option') {
      if (typeof tag === 'string') onTagSelected({ TagName: tag, IssueID })
      else onTagSelected({ ...tag, IssueID })
    }
  }

  render() {
    const {
      disabled,
      readOnly,
      orgTags,
      autoFocus,
      open,
      fullWidth,
      className,
      onBlur,
      createTag,
    } = this.props
    let filterOptions
    let freeSolo = false
    if (createTag) {
      filterOptions = (options, params) => {
        const filtered = filter(options, params)

        // Suggest the creation of a new value
        if (params.inputValue !== '') {
          filtered.push({
            TagName: params.inputValue,
            inputValue: params.inputValue,
            title: `Add "${params.inputValue}"`,
          })
        }

        return filtered
      }

      freeSolo = true
    }

    return (
      <Autocomplete
        autoHighlight={true}
        className={className}
        onBlur={onBlur}
        open={open}
        openOnFocus={autoFocus}
        options={orgTags}
        getOptionLabel={(option) => {
          option = option || {}
          const tagName = option.TagName || ''
          const title = !option.TagID && tagName ? `Add "${tagName}"` : tagName
          return title
        }}
        disabled={disabled || readOnly}
        onChange={this.handleTagChange}
        freeSolo={freeSolo}
        filterOptions={filterOptions}
        value={{}}
        renderInput={(params) => (
          <TextField autoFocus={autoFocus} {...params} fullWidth={fullWidth} />
        )}
      />
    )
  }
}

OrgTagAutocomplete.propTypes = {
  orgTags: PropTypes.array,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  fullWidth: PropTypes.bool,
  onTagSelected: PropTypes.func,
  onTagRemoved: PropTypes.func,
  selectedTagIds: PropTypes.arrayOf(PropTypes.number),
  IssueID: PropTypes.number,
  autoFocus: PropTypes.bool,
}

OrgTagAutocomplete.defaultProps = {
  orgTags: [],
  disabled: false,
  readOnly: false,
  fullWidth: false,
  onTagSelected: () => {},
  onTagRemoved: () => {},
  selectedTagIds: [],
  autoFocus: false,
}

const mapStateToProps = (state, ownProps) => {
  const { IssueID } = ownProps

  if (!IssueID) return {}

  const selectedTagIds = selectIssueTagIds(state, IssueID)
  const orgIds = selectIssueOrgIds(state, IssueID)
  let orgTags = selectOrgTagsByOrgIds(state, orgIds)

  if (selectedTagIds && orgTags)
    orgTags = orgTags.filter((tag) => selectedTagIds.indexOf(tag.TagID) === -1)

  return {
    selectedTagIds,
    orgTags,
  }
}

/*const mapDisptachToProps = {
    onTagRemoved: (tag) => tag.IssueID === undefined ? OrgTagActions.delete(tag) : IssueTagActions.delete(tag),
    onTagSelected: (tag) => tag.IssueID === undefined ? OrgTagActions.add(tag) : IssueTagActions.add(tag)
};*/

export default connect(mapStateToProps, undefined)(OrgTagAutocomplete)
