import React, { ReactNode, Fragment } from 'react'
import { ViewHeader } from '../Shared/ViewHeader'
import { HeaderNavigation } from '../Shared/HeaderNavigation'
import {
  StretchLayout,
  StretchHeader,
  StretchBody,
} from '../Layouts/StretchLayout'
import { withMobileDialog, Dialog, DialogContent } from '@material-ui/core'
import { SlideLeft } from '../Shared/Transition'
import { cx } from '../_utils/objectUtils'
import { CSSProperties } from 'react'

const ResponsiveDialog = withMobileDialog()(Dialog)

const largeAbsoluteStyle: CSSProperties | undefined = {
  position: 'absolute',
  zIndex: 9,
  width: '100%',
  height: '100%',
  top: 0,
}

interface Props {
  open: boolean
  isDialog: boolean
  headerTitle: any
  onClose: any
  children?: ReactNode | ReactNode[]
  largeClassName?: string
  largeAbsolute?: boolean
  largeStyle?: CSSProperties | undefined
  dialogMaxWidth?: false | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | undefined
  canGoBack?: boolean
}

export const ContentViewer = ({
  open = false,
  isDialog = false,
  headerTitle = '',
  onClose = () => {},
  children,
  largeAbsolute = false,
  largeClassName,
  largeStyle,
  dialogMaxWidth = 'lg',
  canGoBack = false,
}: Props) => {
  const renderContent = () => {
    return (
      <StretchLayout>
        <StretchHeader>
          <ViewHeader>
            <HeaderNavigation
              canGoBack={canGoBack}
              onNavClick={onClose}
              title={headerTitle}
            />
          </ViewHeader>
        </StretchHeader>
        <StretchBody className="pl-3 pr-3">{children}</StretchBody>
      </StretchLayout>
    )
  }

  return (
    <Fragment>
      <ResponsiveDialog
        open={open && isDialog}
        className="p-0 h-100"
        TransitionComponent={SlideLeft}
        maxWidth={dialogMaxWidth}
      >
        <DialogContent className="p-0 h-100">{renderContent()}</DialogContent>
      </ResponsiveDialog>
      {
        // Non dialog. typically used for the desktop view
        !isDialog && open ? (
          <SlideLeft
            in={true}
            className={cx('h-100', largeClassName)}
            style={largeAbsolute ? largeAbsoluteStyle : largeStyle}
          >
            <div>{renderContent()}</div>
          </SlideLeft>
        ) : null
      }
    </Fragment>
  )
}
