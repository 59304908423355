import * as React from 'react'
import { CSSProperties } from 'react'
import {
  Backdrop,
  CircularProgress as CircleProgMaterial,
  CircularProgressProps,
} from '@material-ui/core'

interface Props {
  open?: boolean
  text?: string
  content?: any
  className?: string
  style?: CSSProperties
  zIndex?: number
  opacity?: number
  color?: 'inherit' | 'primary' | 'secondary'
  backgroundColor?: string
}

export const LoadingBackDrop = ({
  open = false,
  text,
  content,
  className,
  style,
  zIndex,
  opacity,
  color,
  backgroundColor,
}: Props) => (
  <Backdrop
    className={className}
    style={{ zIndex, opacity, backgroundColor, ...style }}
    open={open}
    transitionDuration={{ exit: 500 }}
  >
    <CircleProgMaterial color={color} disableShrink={true} />
    {content ? (
      content
    ) : text ? (
      <span style={{ paddingLeft: 15 }}>{text}</span>
    ) : null}
  </Backdrop>
)

LoadingBackDrop.defaultProps = {
  open: false,
  zIndex: 9999,
  opacity: 0.7,
  color: 'inherit',
}

interface MyProgressProps extends CircularProgressProps {
  open?: boolean
  position?: 'center'
  zIndex?: number
  text?: string
}
export const CircularProgress = ({
  color = 'primary',
  open = true,
  position = 'center',
  zIndex,
  className,
  text,
}: MyProgressProps) => (
  <LoadingBackDrop
    open={open}
    zIndex={zIndex}
    className={className || (position === 'center' ? 'position-absolute' : '')}
    opacity={1}
    color={color}
    backgroundColor="transparent"
    text={text}
  />
)
