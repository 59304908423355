import {
  faArrowDown,
  faArrowUp,
  faFile,
  faPlus,
  faTimes,
} from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  Button,
  Input,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core'
import React from 'react'
import Moment from 'react-moment'

import { useDispatch } from 'react-redux'
import { Table } from 'reactstrap'
import { useFetchTemplate } from '../HoldingStatement/_Hooks'
import { HoldingStatementTemplateModel } from '../HoldingStatement/_models'
import { Organization } from '../Issue/_reducer'
import { AdminConsoleDialog } from '../Shared/AdminConsoleDialog'
import { SearchInput } from '../Shared/SearchInput'
import { Spacer } from '../Shared/Spacer'
import { addNotification } from '../_actions'
import { appDateTimeFormat } from '../_constants'
import {
  createHoldingStatementTemplate,
  deleteHoldingStatementTemplate,
  updateHoldingStatementTemplate,
} from '../_utils/apiUtils'
import { getLocalDate } from '../_utils/dateUtils'
import { StyledTableRow } from './AdminConsolePage'
import classes from './AdminConsolePage.module.scss'
import { TiptapEditor } from '../Shared/IcoTipTapEditor'

type TableHeader = 'Title' | 'Creator' | 'Updated' | 'UpdatedBy'
type SortDirection = 'asc' | 'dec' | 'none'
export interface TableSort {
  header: TableHeader
  sortDirection: SortDirection
}

function createData(
  title: string,
  creator: string,
  updatedOn: string,
  updator: string
) {
  return { title, creator, updatedOn, updator }
}

export const AdminConsoleHoldingStatements = ({
  selectedOrg,
}: {
  selectedOrg: Organization
}) => {
  const [holdingStatementSearch, setHoldingStatementSearch] = React.useState('')
  const [holdingStatementInputContent, setHoldingStatementInputContent] =
    React.useState('')
  const [holdingStatementInputName, setHoldingStatementInputName] =
    React.useState('')
  const [selectedHoldingStatement, setSelectedHoldingStatement] =
    React.useState({} as HoldingStatementTemplateModel)
  const [addingHoldingStatementTemplate, setAddingHoldingStatementTemplate] =
    React.useState(false)
  const [holdingStatementSaveDialogOpen, setHoldingStatementSaveDialogOpen] =
    React.useState(false)
  const [
    holdingStatementDeleteDialogOpen,
    setHoldingStatementDeleteDialogOpen,
  ] = React.useState(false)
  const [holdingStatementSort, setHoldingStatementSort] =
    React.useState<TableSort>({
      header: 'Updated',
      sortDirection: 'dec',
    })

  const [needsRefresh, setNeedsRefresh] = React.useState(false)

  const dispatch = useDispatch()

  const setSortDirection = (header: TableHeader) => {
    if (header === holdingStatementSort.header) {
      if (holdingStatementSort.sortDirection === 'none') {
        setHoldingStatementSort({ header: header, sortDirection: 'dec' })
      } else if (holdingStatementSort.sortDirection === 'asc') {
        setHoldingStatementSort({ header: header, sortDirection: 'dec' })
      } else {
        setHoldingStatementSort({ header: header, sortDirection: 'asc' })
      }
    } else {
      setHoldingStatementSort({ header: header, sortDirection: 'dec' })
    }
  }

  const clearStateAndRefresh = () => {
    setAddingHoldingStatementTemplate(false)
    setHoldingStatementInputContent('')
    setHoldingStatementInputName('')
    setHoldingStatementSaveDialogOpen(false)
    setHoldingStatementDeleteDialogOpen(false)
    setSelectedHoldingStatement({} as HoldingStatementTemplateModel)
    setNeedsRefresh(true)
  }

  const sortHoldingStatements = (
    holdingStatementTemplates: HoldingStatementTemplateModel[] | undefined
  ) => {
    if (holdingStatementSort.sortDirection === 'none') return

    switch (holdingStatementSort.header) {
      case 'Title':
        if (holdingStatementSort.sortDirection === 'dec') {
          return holdingStatementTemplates?.sort((a, b) =>
            a.Title.localeCompare(b.Title)
          )
        } else if (holdingStatementSort.sortDirection === 'asc') {
          return holdingStatementTemplates?.sort(
            (a, b) => -1 * a.Title.localeCompare(b.Title)
          )
        }
        break

      case 'Updated':
        if (holdingStatementSort.sortDirection === 'asc') {
          return holdingStatementTemplates?.sort((a, b) =>
            a.Updated > b.Updated ? 1 : -1
          )
        } else if (holdingStatementSort.sortDirection === 'dec') {
          return holdingStatementTemplates?.sort((a, b) =>
            a.Updated > b.Updated ? -1 : 1
          )
        }
        break
    }
  }

  const handleHoldingStatementChange = React.useCallback(() => {
    const contentEmpty =
      holdingStatementInputContent.replace(/<(.|\n)*?>/g, '').trim().length ===
      0

    let errorMessage = ''

    if (contentEmpty || !holdingStatementInputName) {
      return 'Holding statements must both have a title and content to be saved'
    }
    if (
      (holdingStatementInputContent !== selectedHoldingStatement.Content &&
        !contentEmpty) ||
      (holdingStatementInputName !== selectedHoldingStatement.Title &&
        holdingStatementInputName !== '')
    ) {
      errorMessage = ''
    } else {
      errorMessage = 'Must change title or content to save holding statement'
    }

    if (errorMessage) {
      dispatch(
        addNotification({
          message: errorMessage,
          type: 'error',
        })
      )
      return
    }
    setHoldingStatementSaveDialogOpen(true)
  }, [
    dispatch,
    holdingStatementInputContent,
    holdingStatementInputName,
    selectedHoldingStatement.Content,
    selectedHoldingStatement.Title,
  ])

  React.useEffect(() => {
    setHoldingStatementInputName(selectedHoldingStatement.Title)
    setHoldingStatementInputContent(selectedHoldingStatement.Content || '')
  }, [selectedHoldingStatement])

  React.useEffect(() => {
    if (needsRefresh) {
      setNeedsRefresh(false)
    }
  }, [needsRefresh])

  const holdingTemplates = useFetchTemplate(parseInt(selectedOrg?.OrgID), {
    tracking: [needsRefresh],
  })

  const holdingStatement = holdingStatementInputContent
    ? holdingStatementInputContent
    : selectedHoldingStatement.Content

  const holdingStatementTemplatesSearch =
    holdingStatementSearch === ''
      ? holdingTemplates.data?.returnData?.map((template) => template)
      : holdingTemplates.data?.returnData?.filter((template) => {
          if (
            template.HoldingStatementTemplateID ===
            selectedHoldingStatement.HoldingStatementTemplateID
          ) {
            return template
          }
          return template.Title.toLowerCase().includes(
            holdingStatementSearch.toLowerCase()
          )
        })

  const holdingStatementTemplates =
    holdingStatementSort.sortDirection === 'none'
      ? holdingStatementTemplatesSearch
      : sortHoldingStatements(holdingStatementTemplatesSearch)

  const holdingStatementEditContainer = (
    <div className={classes.OrganizationHoldingStatementEditContainer}>
      <div>
        <div className={classes.OrganizationInnerContainerEditHeader}>
          Holding Statement Name:
          <FontAwesomeIcon
            icon={faTimes}
            style={{ cursor: 'pointer' }}
            onClick={() => {
              setSelectedHoldingStatement({} as HoldingStatementTemplateModel)
              setAddingHoldingStatementTemplate(false)
            }}
          />
        </div>

        <Input
          type="text"
          required={true}
          name="Holding Statement"
          inputProps={{
            maxLength: 255,
          }}
          style={{ minWidth: '200px', maxWidth: '400px' }}
          fullWidth
          className={classes.TagInput}
          value={holdingStatementInputName}
          onChange={(e) => setHoldingStatementInputName(e.target.value)}
        />
      </div>
      <Spacer />

      <TiptapEditor
        className={classes.OrganizationHoldingStatement}
        placeholder="Holding Statement Content..."
        value={holdingStatement || ''}
        onChange={setHoldingStatementInputContent}
      />
      <div className={classes.OrganizationTagButtonContainer}>
        {addingHoldingStatementTemplate ? null : (
          <Button
            onClick={() => setHoldingStatementDeleteDialogOpen(true)}
            className={classes.OrganizationDeleteButton}
            style={{ color: 'red' }}
          >
            {' '}
            Delete{' '}
          </Button>
        )}
        <Button
          onClick={() => {
            handleHoldingStatementChange()
          }}
          className={classes.OrganizationSaveButton}
          style={{ color: 'rgb(64, 148, 195)' }}
        >
          {' '}
          Save{' '}
        </Button>
      </div>
    </div>
  )

  const HoldingStatementDialogs = () => {
    return (
      <>
        <AdminConsoleDialog
          open={holdingStatementDeleteDialogOpen}
          content={
            'Are you sure you would like to delete this holding statement template?'
          }
          onConfirm={() => {
            deleteHoldingStatementTemplate(selectedHoldingStatement)
            clearStateAndRefresh()
          }}
          consoleRole="destructive"
          onCancel={() => setHoldingStatementDeleteDialogOpen(false)}
        />
        <AdminConsoleDialog
          open={holdingStatementSaveDialogOpen}
          content={
            'Are you sure you would like to save this holding statement template?'
          }
          onConfirm={() => {
            addingHoldingStatementTemplate
              ? createHoldingStatementTemplate(
                  selectedOrg.OrgID,
                  holdingStatementInputName,
                  holdingStatementInputContent
                )
              : updateHoldingStatementTemplate(
                  selectedHoldingStatement,
                  holdingStatementInputName,
                  holdingStatementInputContent
                )

            clearStateAndRefresh()
          }}
          onCancel={() => setHoldingStatementSaveDialogOpen(false)}
        />
      </>
    )
  }

  return (
    <>
      <HoldingStatementDialogs />
      <div className={classes.OrganizationManagementSection}>
        <div className={classes.OrganizationManagementSectionHeader}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              marginBottom: '10px',
            }}
          >
            <FontAwesomeIcon icon={faFile} size={'2x'} />{' '}
            &nbsp;&nbsp;&nbsp;&nbsp; <div>HOLDING STATEMENTS</div>
            <Spacer spaceParam={'large'} />
            <SearchInput
              id="holdingStatementSearchInput"
              name="SearchInput"
              placeholder="Title Search"
              value={holdingStatementSearch}
              applySearch={(value: any) => {
                setHoldingStatementSearch(value)
              }}
            />
            <Spacer />
          </div>

          <FontAwesomeIcon
            icon={faPlus}
            style={{
              cursor: 'pointer',
              float: 'right',
            }}
            onClick={() => {
              setAddingHoldingStatementTemplate(true)
              setHoldingStatementInputContent('')
              setHoldingStatementInputName('')
              setSelectedHoldingStatement({} as HoldingStatementTemplateModel)
            }}
          />
        </div>
        {holdingStatementTemplates ? (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column',
            }}
          >
            <Table sx={{ minWidth: 300 }} aria-label="custom table">
              <colgroup>
                <col style={{ width: '30%' }} />
                <col style={{ width: '20%' }} />
                <col style={{ width: '30%' }} />
                <col style={{ width: '20%' }} />
              </colgroup>
              <TableHead>
                <TableRow>
                  <TableCell
                    align="left"
                    style={{
                      cursor: 'pointer',
                      display: 'flex',
                      alignItems: 'center',
                      borderBottom: '0',
                    }}
                    onClick={() => setSortDirection('Title')}
                  >
                    Title
                    <Spacer />
                    {holdingStatementSort.header === 'Title' &&
                    holdingStatementSort.sortDirection === 'dec' ? (
                      <FontAwesomeIcon icon={faArrowDown} />
                    ) : (
                      holdingStatementSort.header === 'Title' && (
                        <FontAwesomeIcon icon={faArrowUp} />
                      )
                    )}
                  </TableCell>
                  <TableCell align="left" style={{ borderBottom: '0' }}>
                    Creator
                  </TableCell>
                  <TableCell
                    align="left"
                    style={{
                      cursor: 'pointer',
                      display: 'flex',
                      alignItems: 'center',
                      borderBottom: '0',
                    }}
                    onClick={() => setSortDirection('Updated')}
                  >
                    Updated
                    <Spacer />
                    {holdingStatementSort.header === 'Updated' &&
                    holdingStatementSort.sortDirection === 'asc' ? (
                      <FontAwesomeIcon icon={faArrowUp} />
                    ) : (
                      holdingStatementSort.header === 'Updated' && (
                        <FontAwesomeIcon icon={faArrowDown} />
                      )
                    )}
                  </TableCell>
                  <TableCell align="left" style={{ borderBottom: '0' }}>
                    Updated By
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {holdingStatementTemplates?.map((holdingStatementTemplate) => {
                  const createdNameOrEmail =
                    holdingStatementTemplate.CreatorName
                      ? holdingStatementTemplate.CreatorName
                      : holdingStatementTemplate.CreatorEmail

                  const updatedNameOrEmail =
                    holdingStatementTemplate.UpdaterName
                      ? holdingStatementTemplate.UpdaterName
                      : holdingStatementTemplate.UpdaterEmail
                  const holdingStatementRow = createData(
                    holdingStatementTemplate.Title,
                    createdNameOrEmail,
                    holdingStatementTemplate.Updated.toString(),
                    updatedNameOrEmail
                  )

                  const isSelected =
                    selectedHoldingStatement.HoldingStatementTemplateID ===
                    holdingStatementTemplate.HoldingStatementTemplateID

                  return (
                    <StyledTableRow
                      key={holdingStatementTemplate.HoldingStatementTemplateID}
                      onClick={() => {
                        setSelectedHoldingStatement(holdingStatementTemplate)
                        setAddingHoldingStatementTemplate(false)
                      }}
                      style={{
                        backgroundColor: isSelected ? 'rgb(64, 148, 195)' : '',
                      }}
                    >
                      <TableCell
                        component="th"
                        scope="row"
                        style={{
                          color: isSelected ? 'white' : '',
                        }}
                      >
                        {holdingStatementRow.title}
                      </TableCell>
                      <TableCell
                        style={{
                          color: isSelected ? 'white' : '',
                        }}
                      >
                        {holdingStatementRow.creator}
                      </TableCell>
                      <TableCell
                        style={{
                          color: isSelected ? 'white' : '',
                        }}
                      >
                        {
                          <Moment
                            local
                            format={appDateTimeFormat}
                            date={getLocalDate(holdingStatementRow.updatedOn)}
                          />
                        }
                      </TableCell>
                      <TableCell
                        style={{
                          color: isSelected ? 'white' : '',
                        }}
                      >
                        {holdingStatementRow.updator}
                      </TableCell>
                    </StyledTableRow>
                  )
                })}
              </TableBody>
            </Table>
          </div>
        ) : (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            There are no holding statement templates for this organization
            <Button
              onClick={() => {
                setAddingHoldingStatementTemplate(true)
                setHoldingStatementInputContent('')
                setHoldingStatementInputName('')
                setSelectedHoldingStatement({} as HoldingStatementTemplateModel)
              }}
            >
              Add a holding statement
            </Button>{' '}
          </div>
        )}

        {selectedHoldingStatement.HoldingStatementTemplateID ||
        addingHoldingStatementTemplate
          ? holdingStatementEditContainer
          : null}
      </div>
    </>
  )
}
