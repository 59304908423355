import React, { useState, useRef } from 'react'
import { CircularProgress, LoadingBackDrop } from '../Shared/LoadingBackdrop'
import { TreeView, TreeViewApi } from '../Shared/TreeView'
import { useIcoFetch, icoPut } from '../_utils/fetchUtils'
import { cx } from '../_utils/objectUtils'
import cssClasses from './StakeholderPlaybooksAndMembers.module.scss'
import {
  Dialog,
  withMobileDialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@material-ui/core'
import { Button } from '../Shared/Buttons'
import { SlideLeft } from '../Shared/Transition'
import { useIsMounted } from '../_utils/hooks'
import { AlertDialog } from '../Shared/AlertDialog'

interface Props {
  IssueStakeholderID: number
  Audience?: string
  mobile?: boolean
  onApi?: (api: TreeViewApi) => void
  onChange?: () => void
  className?: string
  classes?: { content: string }
  open?: boolean
  onClose?: () => void
  OrgName: string
  IssueID: number
}

const ResponsiveDialog = withMobileDialog()(Dialog)

export const StakeholderPlaybooks = ({
  IssueStakeholderID,
  Audience,
  mobile = false,
  className,
  open = false,
  onClose,
  classes,
  OrgName,
  IssueID,
}: Props) => {
  const [saveEnabled, setSaveEnabled] = useState(false)
  const [isSaving, setIsSaving] = useState(false)
  const [noMembersOpened, setNoMembersOpened] = useState(false)
  const isMounted = useIsMounted()
  const api = useRef<TreeViewApi>()
  const responsiveCSSClass = mobile
    ? cx(cssClasses.root, cssClasses.mobile)
    : cssClasses.root
  function handleSave() {
    submitPlaybooks()
  }

  function handleSaveAndEmail() {
    submitPlaybooks(true)
  }

  function submitPlaybooks(sendEmail: boolean = false) {
    setIsSaving(true)
    icoPut('/api/Stakeholders/Playbooks', {
      IssueStakeholderID,
      auth_PubID_list: api.current?.getSelected().join(','),
      sendEmail,
      IssueID,
    }).then(() => {
      if (isMounted()) {
        setIsSaving(false)
        onClose?.call(undefined)
      }
    })
  }

  function handleApi(treeViewApi: TreeViewApi) {
    api.current = treeViewApi
  }
  const stakeholderPlaybooksData = useIcoFetch(
    `/api/Stakeholders/${IssueStakeholderID}/Playbooks`,
    {
      required: [IssueStakeholderID],
      defaultData: [],
      onData: (result) => {
        const { data } = result
        setNoMembersOpened(!!data.noMembers)
      },
      tracking: [open],
    }
  )
  const playbooksRenderer = () => {
    if (
      !stakeholderPlaybooksData.data.PubTree ||
      !stakeholderPlaybooksData.data.PubTree[0] ||
      !stakeholderPlaybooksData.data.PubTree[0].OrgID
    ) {
      return (
        <div
          className={cssClasses.noPlaybooksMsg}
        >{`There are no playbooks in this organization (${OrgName}) for assignment.`}</div>
      )
    } else {
      return (
        <TreeView
          data={stakeholderPlaybooksData.data.PubTree[0]}
          onChange={() => setSaveEnabled(true)}
          onApi={handleApi}
          options={{
            parentsPath: 'suborgs',
            childrenPath: 'plans',
            childKey: 'PubID',
            parentKey: 'OrgID',
            isChildSelected: (child) => child.isSelected === 'Y',
          }}
          renderOptions={{
            parentRenderer: (parent) => parent.Orgname,
            childRenderer: (child) => child.PubTitle,
          }}
        />
      )
    }
  }

  return (
    <ResponsiveDialog
      className={className}
      fullWidth={true}
      maxWidth="md"
      open={open}
      onClose={onClose}
      TransitionComponent={SlideLeft as any}
    >
      <DialogTitle>Playbooks of Stakeholder Group</DialogTitle>
      <DialogContent className={classes?.content}>
        {stakeholderPlaybooksData.loading ? (
          <CircularProgress />
        ) : (
          <div className={responsiveCSSClass}>
            <div>
              <h6 className={cssClasses.rootHeader}>{Audience}</h6>
            </div>
            {playbooksRenderer()}
            <AlertDialog
              content="A Stakeholder must be added before a Playbook can be assigned."
              open={noMembersOpened}
              ok={onClose}
            />
          </div>
        )}
      </DialogContent>
      <DialogActions>
        <Button disabled={!saveEnabled} onClick={handleSave} color="primary">
          Save
        </Button>
        <Button
          disabled={!saveEnabled}
          onClick={handleSaveAndEmail}
          color="primary"
        >
          Save and Email
        </Button>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
      </DialogActions>
      <LoadingBackDrop
        text="Saving Playbooks..."
        open={isSaving}
        className="position-absolute h-100 w-100"
      />
    </ResponsiveDialog>
  )
}
