import React from 'react'
import { CircularProgress } from '../Shared/LoadingBackdrop'
//import moment from 'moment';
import classes from './Playbooks.module.scss'
import { http } from '../_utils/http'
import { PbEvItem, PbEventItemProps } from './PbEvItem'
import { cx } from '../_utils/objectUtils'

interface Props {
  pubGUID?: string
  onItemClick?(event: PbEventItemProps): void
  selected?: number
  onItemSelected?(event: PbEventItemProps): void
}

export const PbEvList = ({
  pubGUID,
  onItemClick,
  selected,
  onItemSelected,
}: Props) => {
  //var pbStr = '';
  //var jObj;

  //const { pubGUID } = useParams();
  //const pubGUID = this.props.pubGUID;
  const [Playbook, setPlaybook] = React.useState<any>('')
  const [PlaybookLoading, setPlaybookLoading] = React.useState(true)
  const [MfaPin, setMfaPin] = React.useState('')

  React.useEffect(() => {
    const getPlaybook = async (): Promise<any> => {
      setPlaybookLoading(true)
      const result = await http<any>({
        //path: `/playbook/${pubGUID}`,
        path: '/playbook/',
        headers: [{ name: 'PubGUID', value: `${pubGUID}` }],
      })
      if (result.ok && result.body) {
        //pbStr = result.body;
        return result.body
      } else {
        return ''
      }
    }
    let cancelled = false
    const doGetPlaybook = async () => {
      const Playbook = await getPlaybook()
      if (!cancelled) {
        setPlaybook(Playbook)
        setPlaybookLoading(false)
      }
    }
    doGetPlaybook()
    return () => {
      cancelled = true
    }
  }, [pubGUID])

  const requestPIN = async () => {
    var customHeaders: any = []
    customHeaders.push({ name: 'pubguid', value: pubGUID })
    customHeaders.push({ name: 'sendpin', value: 'Y' })

    //console.log("DRH DEBUG calling for custom playbook in requestPIN");
    var result = await http<any>({
      //path: `/playbook/${pubGUID}`,
      path: '/playbook/',
      headers: customHeaders,
    })
    //console.log( "DRH DEBUG back from calling for custom playbook in requestPIN");
    if (result.ok && result.body) {
      //pbStr = result.body;
      setPlaybook(result.body)
    } else {
      return ''
    }
  }

  const changePIN = (event: any) => {
    event?.preventDefault()
    if (event.target) {
      setMfaPin(event.target.value)
    }
  }

  const submitPIN = async () => {
    var customHeaders: any = []

    customHeaders.push({ name: 'pubguid', value: pubGUID })
    customHeaders.push({ name: 'submitpin', value: MfaPin })

    //console.log("DRH DEBUG calling for custom playbook in requestPIN");
    var result = await http<any>({
      //path: `/playbook/${pubGUID}`,
      path: '/playbook/',
      headers: customHeaders,
    })
    //console.log("DRH DEBUG back from calling for custom playbook in requestPIN");
    if (result.ok && result.body) {
      //pbStr = result.body;
      setPlaybook(result.body)
    } else {
      return ''
    }
  }

  React.useEffect(() => {
    if (
      onItemSelected &&
      !PlaybookLoading &&
      Playbook?.Playbook &&
      Playbook?.Playbook[0]
    )
      onItemSelected(
        Playbook?.Playbook[0]?.evt?.find(
          (e: PbEventItemProps) => e.EventID === selected
        )
      )
  }, [selected, PlaybookLoading])

  // If a bad pub guid is passed in return a blank pub guid
  if (!PlaybookLoading && !Playbook.Playbook) {
    if (Playbook.AuthError) {
      return <div>{Playbook.AuthError}</div>
    } else {
      return <div>The selected playbook does not exist.</div>
    }
  }
  if (PlaybookLoading) {
    return <CircularProgress />
  }
  var bnrDiv = null
  if (Playbook.Banner) {
    var bnr = Playbook.Banner
    if (bnr.Image) {
      var pimg = bnr.Image
      var bnrImg = (
        <img
          className={classes.imgBanner}
          src={'data:image/' + pimg.Format + ';base64,' + pimg.Graphic}
          alt="Banner"
        />
      )
      if (bnr.ClickAction && bnr.ClickAction.length > 0) {
        bnrDiv = (
          <a target="_blank" href={bnr.ClickAction}>
            {bnrImg}
          </a>
        )
      } else {
        bnrDiv = bnrImg
      }
    }
  }
  if (Playbook.Playbook[0].BannerText) {
    var brtxt = Playbook.Playbook[0].BannerText.replaceAll('\\n', '<br />')
    bnrDiv = (
      <div
        className={classes.txtBanner}
        dangerouslySetInnerHTML={{
          __html: brtxt || '',
        }}
      />
    )
  }
  if (
    !Playbook.hasMFApinSession &&
    Playbook.Playbook[0].AuthType &&
    Playbook.Playbook[0].AuthType.includes('pin')
  ) {
    if (Playbook.HasActiveSMS && Playbook.HasActiveSMS === 'Y') {
      if (Playbook.PINmessage) {
        return (
          <div className="p-2">
            <div className="d-flex justify-content-center align-items-center h-100 pt-2 pb-4">
              <p>
                <b>{Playbook.PINmessage}.</b>
              </p>
            </div>
            <div
              className={cx(
                'd-flex',
                'justify-content-center',
                'align-items-center',
                'h-100',
                'input-group',
                'pb-5'
              )}
            >
              <input
                type="text"
                className={cx(classes.width50, 'form-control')}
                onChange={changePIN}
                placeholder="Access PIN"
              />
              <button
                className={cx(classes.btnIcc, 'btn', 'px-4', 'py-2')}
                onClick={submitPIN}
              >
                Access
              </button>
            </div>
            <div className="d-flex justify-content-center align-items-center h-100 pb-4">
              <p style={{ fontSize: '85%' }}>
                If you have problems receiving the text message, please verify
                that you have entered the correct phone number in User Settings
                and that you have accepted the SMS/Text request from In Case of
                Crisis.
              </p>
            </div>
            <div className="d-flex justify-content-center align-items-center h-100">
              <button
                className={cx(
                  'btn',
                  'btn-link',
                  'px-5',
                  'py-2',
                  classes.noBoxShadow
                )}
                onClick={requestPIN}
              >
                Resend Access PIN
              </button>
            </div>
          </div>
        )
      } else
        return (
          <div className="p-2">
            <div className="d-flex justify-content-center align-items-center h-100 pb-4 pt-2">
              <h2>This content is PIN protected</h2>
            </div>
            <div className="d-flex justify-content-center align-items-center h-100 pb-4">
              <p>
                If you have added a mobile phone number to your User Settings,
                an Access PIN will be sent via SMS/Text.
              </p>
            </div>
            <div className="d-flex justify-content-center align-items-center h-100">
              <button
                className={cx('btn', 'px-5', 'py-2', classes.btnIcc)}
                onClick={requestPIN}
              >
                Send PIN
              </button>
            </div>
          </div>
        )
    } else
      return (
        <div className="p-2">
          <div className="d-flex justify-content-center align-items-center h-100 pt-2 pb-4">
            <h2>This content is PIN protected</h2>
          </div>
          <div className="d-flex justify-content-center align-items-center h-100 pt-2 pb-4">
            <p>
              To view this content, you must add a mobile phone number to your
              User Settings.
            </p>
          </div>
        </div>
      )
  }
  return (
    <>
      {bnrDiv}
      {/*<pre>{JSON.stringify(Playbook, null, 2)}</pre>*/}
      <div
        className={cx(
          'p-2',
          Playbook.Playbook[0].Layout === 'Grid' ? classes.gridViewLayout : ''
        )}
      >
        {Playbook?.Playbook[0]?.evt?.map((event: PbEventItemProps) => (
          <PbEvItem
            key={event.EventID}
            EventID={event.EventID}
            Image={event.Image}
            EventName={event.EventName}
            EventType={event.EventType}
            Layout={Playbook?.Playbook[0]?.Layout}
            onClick={onItemClick}
            selected={event.EventID === selected}
          />
        ))}
      </div>
    </>
  )
}
