import * as React from 'react'
import classes from './AddPlaybook.module.scss'
import Moment from 'react-moment'
import { cx } from '../_utils/objectUtils'
import {
  faLock,
  // faLockOpen,
  faPlusCircle,
  faRedo,
  faTimesCircle,
} from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

interface Props {
  OrgName: string
  PubGUID: string
  PubTitle: string
  Version: number
  AuthType: string
  Updated: Date
  IsPrivate: string
  Status: string
  ShowDeleteBtn: boolean
  isSelected: boolean
  onItemClick?(pb: PbAddItemProps): void
  onItemView?(pb: PbAddItemProps): void
}

export interface PbAddItemProps extends Props {}

const SecurityIcon = (AuthType: string) => {
  if (AuthType === 'password') {
    return <FontAwesomeIcon className={classes.rdsLightGray} icon={faLock} />
  }
  return '' // <FontAwesomeIcon className={classes.rdsLightGray} icon={faLockOpen} />;
}
const ActionIcon = (Status: string) => {
  if (Status === 'added') {
    return (
      <FontAwesomeIcon
        className={classes.rdsRed}
        icon={faTimesCircle}
        size="lg"
      />
    )
  }
  if (Status === 'available') {
    return (
      <FontAwesomeIcon
        className={classes.rdsGreen}
        icon={faPlusCircle}
        size="lg"
      />
    )
  }
  if (Status === 'badpass') {
    return (
      <>
        <FontAwesomeIcon
          className={classes.rdsYellow}
          icon={faRedo}
          size="lg"
        />
      </>
    )
  }
  return <></>
}

export const PbAddItem = ({
  OrgName,
  PubGUID,
  PubTitle,
  Version,
  AuthType,
  Updated,
  IsPrivate,
  Status,
  ShowDeleteBtn,
  isSelected,
  onItemClick = () => {},
  onItemView = () => {},
}: PbAddItemProps) => (
  <div
    className={cx(
      classes.icoAddItem,
      'Ico-Item-root',
      '',
      isSelected ? classes.icoAddedItemSelected : ''
    )}
  >
    {/* the above puts a blue line around already added */}
    <div className={cx(classes.clickBox, 'clearfix position-relative')}>
      <div
        className={cx('float-left', classes.addPlanSpec)}
        onClick={() => {
          onItemView({
            OrgName,
            PubGUID,
            PubTitle,
            Version,
            AuthType,
            Updated,
            IsPrivate,
            Status,
            ShowDeleteBtn,
            isSelected,
          })
        }}
      >
        <span style={{ fontWeight: 'bold' }}>{PubTitle}</span>
        <br />
        <span style={{ fontSize: '87.5%' }}>
          {OrgName}
          <br />
          v. {Version}
          &nbsp;-&nbsp;
          <Moment format="DD MMM YYYY" utc local>
            {Updated}
          </Moment>
        </span>
      </div>
      <div
        className={cx('float-right', classes.actionImg)}
        onClick={() => {
          onItemClick({
            OrgName,
            PubGUID,
            PubTitle,
            Version,
            AuthType,
            Updated,
            IsPrivate,
            Status,
            ShowDeleteBtn,
            isSelected,
          })
        }}
      >
        {ActionIcon(Status)}
      </div>
      <div className={cx('float-right', classes.planImg)}>
        {SecurityIcon(AuthType)}
      </div>
    </div>
  </div>
)
