import { ReducerAction } from '../_utils/reduxUtils'
import { BasePathPayload, SAVE_BASE_PATH_STATE } from './_constants'

export function saveBasePathState(
  basePath: string,
  path: string
): ReducerAction<BasePathPayload> {
  return {
    type: SAVE_BASE_PATH_STATE,
    payload: {
      basePath,
      path,
    },
  }
}
