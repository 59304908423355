// @ts-nocheck
// TODO: Typescript
import React, { useState, useRef, useEffect, useMemo } from 'react'
import { Document, Page, pdfjs } from 'react-pdf'
import classes from './PDFViewer.module.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMinus } from '@fortawesome/free-solid-svg-icons'
import { faFastBackward } from '@fortawesome/pro-solid-svg-icons'
import { faStepBackward, faStepForward } from '@fortawesome/pro-solid-svg-icons'
import 'react-pdf/dist/esm/Page/AnnotationLayer.css'
import 'react-pdf/dist/esm/Page/TextLayer.css'

import {
  faFastForward,
  faPlus,
  faSyncAlt,
} from '@fortawesome/pro-solid-svg-icons'

const pageInputDelay = 1000
interface Props {
  data?: any
  style?: any
  className?: string
}

export default function PDFViewer({ data, style, className }: Props) {
  pdfjs.GlobalWorkerOptions.workerSrc = `${process.env.PUBLIC_URL}/pdf.worker.min.mjs`

  const fileSlice = useMemo(() => data.slice(0), [data])
  const [numPages, setNumPages] = useState(null)
  const [pageNumber, setPageNumber] = useState(1)
  const [pdfScale, setPdfScale] = useState(1.0)
  const [pageInputNumber, setPageInputNumber] = useState(1)
  const [rotation, setRotation] = useState(0)
  const pageInputTimer = useRef(null)
  const isMounted = useRef(null)

  useEffect(() => {
    isMounted.current = true
    return function cleanup() {
      isMounted.current = false
    }
  })
  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages)
  }

  function changePage(offset) {
    setPageNumber((prevPageNumber) => prevPageNumber + offset)
    setPageInputNumber((prevPageNumber) => prevPageNumber + offset)
  }

  function lastPage() {
    setPageNumber(numPages)
    setPageInputNumber(numPages)
  }

  function previousPage() {
    changePage(-1)
  }

  function nextPage() {
    changePage(1)
  }

  function zoomIn() {
    var newscale = pdfScale * 1.5
    if (newscale < 4) {
      setPdfScale(newscale)
    }
  }

  function zoomOut() {
    var newscale = pdfScale * 0.75
    if (newscale > 0.25) {
      setPdfScale(newscale)
    }
  }

  function rotate() {
    var newrot = (rotation + 90) % 360
    setRotation(newrot)
  }

  function handlePageInput(evt, d) {
    clearTimeout(pageInputTimer.current)
    let { value } = evt.target
    const isBlank = value.trim() === ''
    if (isBlank || !isNaN(value)) {
      if (!isBlank) value = parseInt(value)

      if (value > 0 && value <= numPages) {
        setPageInputNumber(value)
        pageInputTimer.current = setTimeout(() => {
          if (isMounted.current) setPageNumber(value)
        }, pageInputDelay)
      } else if (isBlank) setPageInputNumber(value)
    }
  }

  return (
    <div className={classes.pdfContainer}>
      <div style={{ height: '90%', width: 'auto', overflow: 'auto' }}>
        <Document
          file={fileSlice}
          onLoadSuccess={onDocumentLoadSuccess}
          className={classes.pdfDocument}
        >
          <Page pageNumber={pageNumber} rotate={rotation} scale={pdfScale} />
        </Document>
      </div>

      <div className={classes.pageNumbers}>
        <button
          type="button"
          className="btn btn-primary"
          disabled={pageNumber <= 1}
          onClick={previousPage}
        >
          <FontAwesomeIcon icon={faFastBackward} title="First Page" />
        </button>
        <button
          type="button"
          className="btn btn-primary"
          disabled={pageNumber <= 1}
          onClick={previousPage}
        >
          <FontAwesomeIcon icon={faStepBackward} title="Previous Page" />
        </button>
        Page{' '}
        {!numPages ? (
          '--'
        ) : (
          <input
            type="number"
            min={1}
            max={numPages}
            value={pageInputNumber}
            onChange={handlePageInput}
          />
        )}{' '}
        of {numPages || '--'}
        <button
          type="button"
          className="btn btn-primary"
          disabled={pageNumber >= numPages}
          onClick={nextPage}
        >
          <FontAwesomeIcon icon={faStepForward} title="Next Page" />
        </button>
        <button
          type="button"
          className="btn btn-primary"
          disabled={pageNumber >= numPages}
          onClick={lastPage}
        >
          <FontAwesomeIcon icon={faFastForward} title="Last Page" />
        </button>
        <button type="button" className="btn btn-primary" onClick={zoomIn}>
          <FontAwesomeIcon icon={faPlus} title="Zoom In" />
        </button>
        <button type="button" className="btn btn-primary" onClick={zoomOut}>
          <FontAwesomeIcon icon={faMinus} title="Zoom Out" />
        </button>
        <button type="button" className="btn btn-primary" onClick={rotate}>
          <FontAwesomeIcon icon={faSyncAlt} title="Rotate" />
        </button>
      </div>
    </div>
  )
}
