import { FETCH_ACTIVE_POLL_LIST } from './_constants'

// ------ THESE ARE THE DATA TYPES COMING FROM THE CHECKIN -----------

export interface activePoll {
  PUR_GUID: string
  PollName: string
  ExpirationUTC: Date
  PollURL: string
}

export interface activePollList {
  Polls: [activePoll]
}

export interface activePollState {
  activePollList?: activePollList
}

export function activePollListReducer(
  state: activePollState = {},
  actions: any
) {
  const mapped: { [key: string]: Function } = {
    [FETCH_ACTIVE_POLL_LIST.SUCCESS]: () => {
      return {
        ...state,
        activePollList: actions.payload,
      }
    },
  }
  return (
    mapped[actions.type] ||
    function () {
      return state
    }
  )()
}

export const selectActivePollList = (state: activePollState) =>
  state.activePollList
