import { faSearch, faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ReactNode, useEffect, useRef, useState, ChangeEvent } from 'react'
import {
  Input,
  InputProps,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from 'reactstrap'
import { cx } from '../_utils/objectUtils'
import classes from './SearchInput.module.scss'

export interface SearchInputProps extends Omit<InputProps, 'size'> {
  inputGroupAddon?: ReactNode
  includeSearchIcon?: boolean
  inputId?: string
  onClear?: () => void
  changeDelay?: number
  classes?: { input?: string }
  size?: 'compact' | 'standard'
  enableOnEnter?: boolean
  applySearch: (value: string) => void
}
export const SearchInput = ({
  value = '',
  className,
  style,
  inputGroupAddon,
  includeSearchIcon,
  name,
  inputId,
  placeholder,
  applySearch,
  id,
  changeDelay = 0,
  classes: ownClasses = {},
  size = 'standard',
  enableOnEnter = false,
}: SearchInputProps) => {
  const isMounted = useRef(true)
  const timer = useRef(0)
  const [inputValue, setInputValue] = useState(value)
  useEffect(() => {
    return function cleanup() {
      isMounted.current = false
    }
  }, [])

  const onChange = (evt: ChangeEvent<HTMLInputElement>) => {
    setInputValue(evt.target.value)
    if (enableOnEnter) {
      return
    }
    if (changeDelay) {
      clearTimeout(timer.current)
      timer.current = setTimeout(() => {
        if (isMounted) applySearch?.call(undefined, evt.target.value)
      }, changeDelay) as any
    } else {
      applySearch?.call(undefined, evt.target.value)
    }
  }

  const finalInputId = inputId ? inputId : id ? id + '-input' : undefined

  return (
    <InputGroup
      id={id}
      style={style}
      className={cx(classes.root, className, value ? classes.hasValue : '')}
    >
      {!inputGroupAddon && includeSearchIcon && (
        <InputGroupAddon addonType="prepend">
          <InputGroupText htmlFor={finalInputId}>
            <FontAwesomeIcon
              className={classes.searchIcon}
              icon={faSearch}
              style={enableOnEnter ? { cursor: 'pointer' } : {}}
              size="lg"
              onClick={() => {
                if (enableOnEnter) {
                  applySearch?.call(undefined, inputValue)
                }
              }}
            />
          </InputGroupText>
        </InputGroupAddon>
      )}
      {inputGroupAddon}
      <Input
        addon
        name={name}
        value={inputValue}
        id={finalInputId}
        placeholder={placeholder}
        className={cx(
          classes.input,
          ownClasses.input,
          size === 'compact' ? classes.compact : ''
        )}
        type="text"
        onChange={onChange}
        onKeyDown={(keypress) => {
          if (enableOnEnter && keypress.code === 'Enter') {
            applySearch?.call(undefined, inputValue)
          }
        }}
      />
      <FontAwesomeIcon
        className={classes.removeTrigger}
        aria-label="Clear Text"
        icon={faTimesCircle}
        onClick={() => {
          setInputValue('')
          applySearch?.call(undefined, '')
        }}
      />
    </InputGroup>
  )
}
