import React from 'react'
import { RouteComponentProps } from 'react-router'
import { UserTaskItemQueryPanel } from '../Dashboard/UserDashboard/UserTaskItemQueryPanel'
import { MasterPageLayout } from '../Pages/MasterPageLayout'
import { PlaybookRouteParams } from '../Routing/RouteProps'

interface Props extends RouteComponentProps<PlaybookRouteParams> {}

export const MasterLayoutUserTaskItemQueryPanel = ({
  history,
  match,
  location,
}: Props) => {
  return (
    <MasterPageLayout
      history={history}
      match={match as any}
      location={location}
    >
      <div
        style={{
          backgroundColor: 'white',
          margin: '0px',
          padding: '0px 0px 10px 10px',
        }}
      >
        <UserTaskItemQueryPanel />
      </div>
    </MasterPageLayout>
  )
}
