// @ts-nocheck
// TODO: Typescript
import React, { Component } from 'react'
import { connect } from 'react-redux'
import IssueSummaryItem from './IssueSummaryItem'
import { PropTypes } from 'prop-types'
import { goToIssue } from '../_utils/reduxUtils'
import classes from './IssueSummaryFeed.module.scss'
import { cx } from '../_utils/objectUtils'
import IssueFilter from './IssueFilter'
import IssueCreator from './IssueCreator'
import {
  AutoSizer,
  List,
  CellMeasurer,
  CellMeasurerCache,
} from 'react-virtualized'
import { IssueFilterAdvancedTrigger } from './IssueFilterAdvancedTrigger'
import {
  StretchLayout,
  StretchHeader,
  StretchBody,
} from '../Layouts/StretchLayout'
import { IssuesNotFoundMessage } from '../ErrorMessages/IssuesNotFoundMessage'
import { prefixSelectorWithApp } from '../_utils/domUtils'
import { isInitIssuesComplete } from '../Shared/_requestStatusSelectors'
import { selectIssueStageTab, selectLocationIssueId } from './_selectors'
import {
  selectVisibleAssignedIssueIds,
  selectVisibleStagingIssueIds,
} from '../_rootConfigs/rootSelectors'
import { isMobileApp, selectUserInitialState } from '../_selectors'

// NOTE: As a general rule never call dispatch directly. Also with redux there typically isn't a need to use state directly
/**
 * @extends {Component<Props, {}>}}
 */
export class IssueSummaryFeed extends Component {
  constructor(props) {
    super(props)
    this.handleItemClick = this.handleItemClick.bind(this)

    this.firstLoad = true

    this.state = { width: window.innerWidth }

    this.summaryItemEls = {}
    this.renderSummaryItem = this.renderSummaryItem.bind(this)
    this.refreshCache = this.refreshCache.bind(this)
    this.handleWindowSizeChange = this.handleWindowSizeChange.bind(this)

    this.cache = new CellMeasurerCache({
      //Define a CellMeasurerCache --> Put the height and width you think are the best
      defaultHeight: props.defaultItemHeight,
      minHeight: props.minItemHeight,
      fixedWidth: true,
    })

    this.cacheReset = {
      delay: 200,
      timer: 0,
    }
  }

  handleWindowSizeChange() {
    this.setState({ width: window.innerWidth })
  }

  componentDidMount() {
    /*const { locationWorkspaceID, selected } = this.props;
        const itemId = selected || locationWorkspaceID;
        if (itemId) {
            const selectedItemEl = this.summaryItemEls[itemId];
            if (selectedItemEl)
                this.feedContainerEl.scrollTo(0, selectedItemEl.offsetTop);
        }*/

    this.goToRow()

    window.addEventListener('resize', this.refreshCache)
    window.addEventListener('resize', this.handleWindowSizeChange)
  }

  componentDidUpdate(prevProps) {
    // Need to refresh the cache if the data is reloaded
    if (prevProps.issueIds !== this.props.issueIds) {
      if (this.firstLoad) {
        this.firstLoad = false
        setTimeout(() => this.goToRow())
      } else this.refreshCache(false)
    } else if (this.state.startScrollPosition) {
      this.setState({
        startScrollPosition: null,
      })
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.refreshCache)
    window.removeEventListener('resize', this.handleWindowSizeChange)
  }

  goToRow() {
    const { props } = this
    let startScrollPosition = props.selected || props.locationIssueID
    this.setState({
      startScrollPosition: startScrollPosition
        ? props.issueIds.indexOf(startScrollPosition)
        : undefined,
    })
  }

  refreshCache(applyDelay = true) {
    clearTimeout(this.cacheReset.timer)
    let scope = this
    if (applyDelay) {
      this.cacheReset.timer = setTimeout(() => {
        scope.cache.clearAll() //Clear the cache if row heights are recompute to be sure there are no "blank spaces" (some row are erased)
        scope.virtualizedList && scope.virtualizedList.forceUpdateGrid() // We need to recompute the heights
      }, this.cacheReset.delay)
    } else {
      scope.cache.clearAll() //Clear the cache if row heights are recompute to be sure there are no "blank spaces" (some row are erased)
      scope.virtualizedList && scope.virtualizedList.forceUpdateGrid() // We need to recompute the heights
    }
  }

  /*renderHelpScreen() {
        const strText = "No issues found.\n\n Please, check your filter settings \n or create a new issue. ";
        return (
            <div className={classes.helpScreenContainer}>
                {//<FontAwesomeIcon style={{ position: 'absolute', right: 10, top: 10, color: "#186FAF" }} size="3x" icon={faHandPointUp} title="Issue Filter pointer" />
                }
                {strText}
                {//<FontAwesomeIcon style={{ position: 'absolute', right: 30, bottom: 100, color: "#186FAF" }} size="3x" icon={faHandPointDown} title="New Issue pointer"/>
                }
            </div>
        )
    }*/

  render() {
    const {
      issueIds,
      hasFilter,
      hasSearch,
      actions,
      actionsBottom,
      actionsRight,
      showActions,
      isInitIssuesComplete,
      className,
      id,
      canCreateIssue,
      issuesNotFound,
      showIssuesNotFound,
      header,
    } = this.props
    return (
      <div
        id={id}
        className={cx(
          'container-fluid position-relative h-100',
          classes.root,
          className
        )}
      >
        {canCreateIssue && (
          <IssueCreator
            addButtonID={prefixSelectorWithApp('createIssueTrigger')}
          />
        )}
        <div
          className={classes.scrollContainer}
          ref={(el) => (this.feedContainerEl = el)}
        >
          <StretchLayout>
            <StretchHeader>
              {header}
              {(showActions || hasSearch || hasFilter) && (
                <div className="mt-1 pr-2 pb-2 w-100">
                  <div
                    className={classes.actions}
                    style={{
                      width: '100%',
                      display: 'flex',
                      alignItems: 'flex-start',
                      flexDirection: 'row',
                    }}
                  >
                    {actions}
                    {hasFilter ? <IssueFilterAdvancedTrigger /> : null}
                    {actionsRight}
                  </div>
                  <div className="mt-1 d-flex flex-row align-items-center w-100">
                    {actionsBottom}
                    {hasSearch ? <IssueFilter /> : null}
                  </div>
                </div>
              )}
            </StretchHeader>
            <StretchBody className="overflow-hidden">
              {(() =>
                !issueIds.length &&
                isInitIssuesComplete &&
                showIssuesNotFound &&
                !issuesNotFound ? (
                  <div className={classes.issuesNotFoundContainer}>
                    <IssuesNotFoundMessage />
                  </div>
                ) : (
                  ''
                ))()}
              {!issueIds.length &&
                isInitIssuesComplete &&
                issuesNotFound &&
                issuesNotFound()}
              <AutoSizer>
                {({ height, width }) => (
                  <List
                    ref={(ref) => (this.virtualizedList = ref)}
                    width={width}
                    height={height}
                    rowGetter={({ index }) => issueIds[index]}
                    rowCount={issueIds.length}
                    rowHeight={({ index }) =>
                      this.cache.rowHeight({
                        index,
                      })
                    }
                    rowRenderer={this.renderSummaryItem}
                    scrollToIndex={
                      !this.state.startScrollPosition
                        ? undefined
                        : this.state.startScrollPosition
                    }
                  />
                )}
              </AutoSizer>
              {/*
                        <Row className="issueSummaryFeed">
                        <Col xs={12}>
                            {
                                issueIds.map((id) =>
                                    <IssueSummaryItem
                                        compact={compact}
                                        showIssueLevel={showIssueLevels}
                                        key={id}
                                        IssueID={id}
                                        itemRef={el => this.summaryItemEls[id] = el}
                                        selected={id === selected}
                                        onClick={this.handleItemClick}
                                        {...other}
                                    />
                                )
                            }
                        </Col>
                    </Row>

                        */}
            </StretchBody>
          </StretchLayout>
        </div>
      </div>
    )
  }

  handleItemClick(params) {
    let manualLocation = ''
    const isSinglePane = this.state.width <= 960

    if (!isSinglePane) {
      if (this.props.userSettings.PrefsDefaultView === 'IssuesActivityLog') {
        manualLocation = 'activitylog'
      } else {
        manualLocation = 'summary'
      }
    }

    if (this.props.history) {
      goToIssue(this.props.history, params, params.domain ? '' : manualLocation)
      return
    }

    if (this.props.onClick) this.props.onClick(params)
  }

  renderSummaryItem(context) {
    const {
      selected,
      issueIds,
      compact,
      showTags,
      showIssueLevels,
      showDescription,
      showActions,
      showResolved,
      issueItemNameLines,
    } = this.props
    const { style, index, parent } = context
    const id = issueIds[index]
    return (
      <CellMeasurer
        cache={this.cache}
        columnIndex={0}
        key={id}
        parent={parent}
        rowIndex={index}
      >
        <div style={{ paddingRight: 5, ...style }} key={id}>
          <IssueSummaryItem
            className={classes.issueSummaryItem}
            compact={compact}
            showTags={showTags}
            showIssueLevel={showIssueLevels}
            showDescription={showDescription}
            showActions={showActions}
            showResolved={showResolved}
            IssueID={id}
            itemRef={(el) => (this.summaryItemEls[id] = el)}
            selected={id === selected}
            onClick={this.handleItemClick}
            issueNameLines={issueItemNameLines}
          />
        </div>
      </CellMeasurer>
    )
  }
}

IssueSummaryFeed.propTypes = {
  issueIds: PropTypes.arrayOf(PropTypes.number),
  history: PropTypes.any,
  hasFilter: PropTypes.bool,
  hasSearch: PropTypes.bool,
  showTags: PropTypes.bool,
  showDescription: PropTypes.bool,
  showActions: PropTypes.bool,
  minItemHeight: PropTypes.number,
  defaultItemHeight: PropTypes.number,
  canCreateIssue: PropTypes.bool,
  showIssuesNotFound: PropTypes.bool,
  issuesNotFound: PropTypes.func,
  header: PropTypes.any,
  issueItemNameLines: PropTypes.number,
}

IssueSummaryFeed.defaultProps = {
  hasFilter: true,
  hasSearch: false,
  showTags: true,
  showIssueLevels: true,
  showDescription: true,
  showActions: true,
  showResolved: true,
  minItemHeight: 150,
  defaultItemHeight: 175,
  canCreateIssue: true,
  showIssuesNotFound: true,
  issueItemNameLines: 2,
}

const mapStateToProps = (state) => {
  const selectedStageType = selectIssueStageTab(state)

  return {
    issueIds:
      selectedStageType === 'assigned'
        ? selectVisibleAssignedIssueIds(state)
        : selectVisibleStagingIssueIds(state),
    isInitIssuesComplete: isInitIssuesComplete(state),
    locationIssueID: selectLocationIssueId(state),
    userSettings: selectUserInitialState(state),
    isMobile: isMobileApp(state),
  }
}

const mergeProps = (stateProps, _, ownProps) => ({
  ...stateProps,
  ...ownProps,
})

export default connect(mapStateToProps, undefined, mergeProps)(IssueSummaryFeed)
