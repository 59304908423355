// @ts-nocheck
// TODO: Typescript
import React from 'react'
import CreateAccountConfirm from './CreateAccountConfirm'
import { prefixSelectorWithApp } from '../_utils/domUtils'

/**
 * @extends {Component<Props, {}>}}
 */
const createAccountConfirmExport = (props) => (
  <CreateAccountConfirm
    id={prefixSelectorWithApp('CreateAccountConfirmPage')}
    {...props}
  />
)
export default createAccountConfirmExport
