// @ts-nocheck
// TODO: Typescript
import React, { Component } from 'react'
import classes from './ChatDetailList.module.scss'
import { ChatDetailItem } from './ChatDetailItem'
import PropTypes from 'prop-types'
import { cx } from '../_utils/objectUtils'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLock } from '@fortawesome/free-solid-svg-icons'
const scrollThreshold = 1000

/**
 * @extends {Component<Props, {}>}}
 * Renders a list of chats
 */
export class ChatDetailList extends Component {
  render() {
    const { height, listName, chatEntryIds, children, onClickThumbnail } =
      this.props
    var inlineStyle = {}

    if (height) {
      inlineStyle.height = height
    }
    this.scrollToBottom()
    return (
      <div>
        {listName && (
          <div className={classes.header}>
            <div
              ref={(el) => (this._header = el)}
              className={cx(classes.name, 'single-line-text')}
            >
              {listName}
            </div>
            <FontAwesomeIcon className={classes.lockIcon} icon={faLock} />
          </div>
        )}
        <div className={classes.viewport} ref={(el) => (this._viewPortEl = el)}>
          <ul className={classes.list} ref={(el) => (this._listEl = el)}>
            {children
              ? children
              : chatEntryIds.map((chatEntryId) => (
                  <ChatDetailItem
                    key={chatEntryId || 'emptyId'}
                    ChatEntryID={chatEntryId}
                    onClickThumbnail={onClickThumbnail}
                  />
                ))}
          </ul>
        </div>
      </div>
    )
  }

  componentDidMount() {
    this.updateListHeight()
  }

  componentDidUpdate(prevProps) {
    var height = this.getListHeight()
    this.updateListHeight()
    const { scrollToNew, items } = this.props
    if (scrollToNew && height) {
      if (
        prevProps.items.length < items.length &&
        height - this._viewPortEl.scrollTop < scrollThreshold
      ) {
        this.scrollToBottom()
      }
    }
  }

  getListHeight() {
    return this.props.height - ((this._header || {}).offsetHeight || 0)
  }

  updateListHeight() {
    this._viewPortEl.style.height = this.getListHeight() + 'px'
  }

  scrollTo(pos) {
    this._viewPortEl.scrollTop = pos
  }

  scrollToBottom() {
    if (this._listEl) this.scrollTo(this._listEl.clientHeight)
  }
}

ChatDetailList.propTypes = {
  scrollToNew: PropTypes.bool,
  onClickThumbnail: PropTypes.func,
}

ChatDetailList.defaultProps = {
  scrollToNew: true,
  chatEntryIds: [],
}
