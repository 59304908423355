// @ts-nocheck
// TODO: Typescript
import React, { Component, Fragment } from 'react'
import { FormList } from '../Form/FormList'
import {
  fetchUpdateIssueForms,
  fetchFormContent,
  submitIssueForm,
} from '../Form/_actions'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { FormContent } from '../Form/FormContent'
import { CircularProgress } from '@material-ui/core'
import { ContentViewer } from '../Shared/ContentViewer'
import { selectWorkspaceUpdateForms } from '../Workspace/_selectors'
import { selectCurrentFormContent } from '../Form/_selectors'
import {
  isFormContentLoading,
  isUpdateIssueFormsLoading,
} from '../Shared/_requestStatusSelectors'

/**
 * @extends {Component<Props, {}>}}
 */
export class IssueFillOutFormsViewer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedForm: null,
    }
    this.handleFormItemClick = this.handleFormItemClick.bind(this)
    this.handleFormClose = this.handleFormClose.bind(this)
    this.handleFormSubmit = this.handleFormSubmit.bind(this)
  }

  componentDidMount() {
    this.fetchForms()
  }

  componentDidUpdate(prevProps) {
    const { IssueTeamWorkspaceID } = this.props
    if (IssueTeamWorkspaceID !== prevProps.IssueTeamWorkspaceID)
      this.fetchForms()
  }

  fetchForms() {
    const { IssueID, IssueTeamWorkspaceID, fetchUpdateIssueForms } = this.props
    fetchUpdateIssueForms({ IssueID, IssueTeamWorkspaceID })
  }

  handleFormItemClick(selectedForm) {
    const { fetchFormContent } = this.props
    fetchFormContent(selectedForm)

    if (!this.state.selectedForm) {
      this.setState({
        selectedForm,
      })
    }
  }

  handleFormSubmit(formData) {
    const { submitIssueForm } = this.props
    submitIssueForm(formData)
    this.handleFormClose()
  }

  handleFormClose() {
    if (this.state.selectedForm) {
      this.setState({
        selectedForm: null,
      })
    }
  }

  renderProgress() {
    return (
      <div className="d-flex justify-content-center align-items-center h-100">
        <CircularProgress color="primary" disableShrink={true} />
      </div>
    )
  }

  render() {
    const {
      formItems,
      formContentDialog,
      isUpdateIssueFormsLoading,
      currentFormContent,
      isFormContentLoading,
      disabled,
      optHeader,
    } = this.props
    const { selectedForm } = this.state

    return (
      <Fragment>
        {optHeader}
        {selectedForm ? null : isUpdateIssueFormsLoading ? (
          this.renderProgress()
        ) : (
          <FormList
            formItems={formItems}
            onClick={this.handleFormItemClick}
            showOrgName={false}
            disabled={disabled}
          />
        )}
        <ContentViewer
          open={selectedForm ? true : false}
          onClose={this.handleFormClose}
          title="Fillout Form and Submit"
          isDialog={formContentDialog}
        >
          {isFormContentLoading ? (
            this.renderProgress()
          ) : (
            <FormContent
              content={currentFormContent}
              onSubmitClick={this.handleFormSubmit}
              readonly={disabled}
            />
          )}
        </ContentViewer>
      </Fragment>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  //console.log(state)
  return {
    formItems: selectWorkspaceUpdateForms(state, ownProps.IssueTeamWorkspaceID),
    currentFormContent: selectCurrentFormContent(state),
    isFormContentLoading: isFormContentLoading(state),
    isUpdateIssueFormsLoading: isUpdateIssueFormsLoading(
      state,
      ownProps.IssueTeamWorkspaceID
    ),
  }
}

const mapDispatchToProps = {
  fetchUpdateIssueForms: (entity) =>
    fetchUpdateIssueForms(entity, {
      icoRequestId: entity.IssueTeamWorkspaceID,
      IssueTeamWorkspaceID: entity.IssueTeamWorkspaceID,
    }),
  fetchFormContent: fetchFormContent,
  submitIssueForm: (formData) =>
    submitIssueForm(formData, { submitType: 'update' }),
}

IssueFillOutFormsViewer.propTypes = {
  IssueID: PropTypes.number,
  IssueTeamWorkspaceID: PropTypes.number,
  formContentDialog: PropTypes.bool,
  formItems: PropTypes.array,
  disabled: PropTypes.bool,
  optHeader: PropTypes.any,
}

IssueFillOutFormsViewer.defaultProps = {
  formContentDialog: false,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(IssueFillOutFormsViewer)
