import React, { Fragment, ReactNode, CSSProperties } from 'react'
import rdsLogo from '../_images/rds_logo_square_black.svg'
import {
  Alert,
  Button,
  Nav,
  NavItem,
  Navbar,
  NavbarBrand,
  NavLink,
} from 'reactstrap'
import {
  StretchBody,
  StretchFooter,
  StretchHeader,
  StretchLayout,
} from '../Layouts/StretchLayout'
import classes from './AuthMasterLayout.module.scss'
import { History } from 'history'
import { useAppSelector } from '../_utils/reactReduxHooks'
import { selectIsDevelopment, selectPortalSite } from '../Issue/_selectors'
import icc365 from '../_images/ICC_365.svg'

const homeLinkStyle: CSSProperties = {
  position: 'absolute',
  right: 20,
  top: 5,
}

export interface AuthMasterLayoutProps {
  children: ReactNode | ReactNode[]
  showAlert: boolean
  alertColor: string
  alertText?: string
  showLogo?: boolean
  showHomeLink?: boolean
  history: History
}

export function AuthMasterLayout({
  children,
  showAlert = false,
  alertColor,
  history,
  alertText,
  showHomeLink = true,
}: AuthMasterLayoutProps) {
  const portalSite = useAppSelector(selectPortalSite)
  const isDevEnv = useAppSelector(selectIsDevelopment)

  function handleGoToIssues() {
    history.push({
      pathname: '/',
    })
  }

  return (
    <StretchLayout className={classes.root}>
      <StretchHeader className={classes.header}>
        <img
          onClick={handleGoToIssues}
          src={icc365}
          alt="In Case of Crisis 365 logo"
          style={{ width: '225px', cursor: 'pointer' }}
        />
      </StretchHeader>
      <StretchBody>
        {showHomeLink && history && (
          <>
            <Button
              color="link"
              style={homeLinkStyle}
              onClick={handleGoToIssues}
            >
              Go to Issues
            </Button>
            <br />
          </>
        )}
        <div style={{ padding: '6rem' }}>
          {showAlert && (
            <Alert className="mt-1" color={alertColor}>
              {alertText}
            </Alert>
          )}
          {children}
        </div>
      </StretchBody>
      <StretchFooter>
        <footer>
          <Navbar expand="md" style={{ padding: '0' }}>
            <Nav navbar>
              {isDevEnv && (
                <NavItem>
                  <NavLink href="/swaggerDoc/index.html" target="_blank">
                    Swagger
                  </NavLink>
                </NavItem>
              )}
              <NavItem>
                <NavLink href={`${portalSite}/privacy`} target="_blank">
                  Privacy Policy
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink href={`${portalSite}/terms`} target="_blank">
                  Terms & Conditions
                </NavLink>
              </NavItem>
            </Nav>
          </Navbar>
        </footer>
        <footer className={classes.footerLight}>
          <Navbar expand="md" style={{ padding: '0' }}>
            <NavbarBrand
              href="https://www.rockdovesolutions.com"
              target="_blank"
              style={{ paddingLeft: '8px' }}
            >
              Copyright &copy; 2011-
              <span>{new Date().getFullYear()}</span>&nbsp;&nbsp;
              <img className="" src={rdsLogo} alt="Rockdove Solutions logo" />
              &nbsp;&nbsp; RockDove Solutions, Inc.
            </NavbarBrand>
          </Navbar>
        </footer>
      </StretchFooter>
    </StretchLayout>
  )
}
