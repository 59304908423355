import {
  ChatConstants,
  ChatDetailConstants,
  CREATE_DIRECT_MESSAGE,
  FETCH_DIRECT_MESSAGES,
  CREATE_OWNER_DIRECT_MESSAGE,
  DELETE_DIRECT_MESSAGE,
  UPDATE_CHAT_READ,
  FETCH_CHAT_UNREAD_COUNT,
  FETCH_LATEST_CHAT_DATES,
  INCREMENT_CHAT_UNREAD_COUNT,
  SET_IS_VIEWING_CHAT,
} from './_constants'
import { reducerCrud } from '../_utils/reduxUtils'
import {
  remapKeyValue,
  addKeyValueIfNotPresent,
  removeKeyFromObject,
} from '../_utils/objectUtils'
import { ChatEntryModel, ChatModel } from './_models'
import { IssueConstants } from '../Issue/_constants'
import { createReadReducers } from '../ActivityLog/_reducer'

export interface ChatState {
  chats: { [ChatID: number]: ChatModel }
  collabIdMap: { [IssueCollabID: number]: number }
  directMessages: { [ChatID: number]: ChatModel }
  recentlyCreatedDirectMessage?: ChatModel
}

const defaultChatState: ChatState = {
  chats: {},
  collabIdMap: {},
  directMessages: {},
}

export default function chatReducer(state = defaultChatState, action: any) {
  switch (action.type) {
    case ChatConstants.ADD.SUCCESS:
      return {
        ...state,
        chats: {
          ...state.chats,
          [action.payload.result.ChatID]: action.payload.result,
        },
        collabIdMap: {
          ...state.collabIdMap,
          [action.payload.result.IssueCollabID]: action.payload.result.ChatID,
        },
      }
    case ChatConstants.UPDATE_RANGE.SUCCESS:
      return {
        ...state,
        chats: reducerCrud.updateRange(state.chats, action, 'ChatID'),
        collabIdMap: addKeyValueIfNotPresent(
          state.collabIdMap,
          action.payload.result,
          'IssueCollabID',
          (result: ChatModel) => result.ChatID
        ),
      }
    case ChatConstants.DELETE_RANGE.SUCCESS:
      return {
        chats: reducerCrud.deleteIdRange(state.chats, action.payload.result),
        collabIdMap: reducerCrud.deleteIdRangeFromExtMap(
          state.chats,
          state.collabIdMap,
          'IssueCollabID',
          action.payload.result
        ),
      }
    case ChatConstants.INIT.SUCCESS:
    case ChatConstants.FETCH.SUCCESS:
      return {
        chats: action.payload.result,
        collabIdMap: remapKeyValue(
          action.payload.result,
          (result: ChatModel) => result.IssueCollabID,
          (result: ChatModel) => result.ChatID
        ),
      }
    case ChatDetailConstants.FETCH.SUCCESS:
      return {
        ...state,
        chats: reducerCrud.replaceChildren(
          state.chats,
          action,
          'ChatID',
          'chatentry'
        ),
      }
    case ChatDetailConstants.ADD.SUCCESS:
      return {
        ...state,
        chats: reducerCrud.addChild(
          state.chats,
          action,
          'ChatID',
          'ChatEntryID',
          'chatentry'
        ),
      }
    case FETCH_DIRECT_MESSAGES.SUCCESS:
      return {
        ...state,
        directMessages: {
          ...state.directMessages,
          ...action.payload.result,
        },
      }
    case CREATE_DIRECT_MESSAGE.REQUEST:
      return {
        ...state,
        recentlyCreatedDirectMessage: null,
      }
    case CREATE_OWNER_DIRECT_MESSAGE.SUCCESS:
      return {
        ...state,
        recentlyCreatedDirectMessage: action.payload.result.ChatID,
      }
    case CREATE_DIRECT_MESSAGE.SUCCESS:
      return {
        ...state,
        directMessages: {
          ...state.directMessages,
          [action.payload.result.ChatID]: action.payload.result,
        },
        chats: {
          ...state.chats,
          [action.payload.result.ChatID]: action.payload.result,
        },
      }
    case DELETE_DIRECT_MESSAGE.SUCCESS:
      return {
        ...state,
        directMessages: removeKeyFromObject(
          state.directMessages,
          action.payload.result.ChatID
        ),
        chats: removeKeyFromObject(state.chats, action.payload.result.ChatID),
      }
    case IssueConstants.FETCH.REQUEST:
      return {
        ...state,
        chats: {},
      }
    default:
      return state
  }
}

/*
 * CHAT SELECTORS
 */
export const selectChatsByIdMap = (state: ChatState) => (state || {}).chats
export const selectChatById = (state: ChatState, chatId: number) =>
  (selectChatsByIdMap(state) || {})[chatId]
export const doesChatExist = (state: ChatState, chatId: number) =>
  selectChatById(state, chatId) ? true : false
export const selectChatEntryIds = (state: ChatState, chatId: number) =>
  (selectChatById(state, chatId) || {}).chatentry
export const selectChatEntryIdsFromProps = (state: ChatState, props: any) =>
  selectChatEntryIds(state, props.ChatID || props.chatID)
export const selectChatsByIssueCollabIdMap = (state: ChatState) =>
  (state || {}).collabIdMap
export const selectChatIdByIssueCollab = (
  state: ChatState,
  issueCollabID: number
) => (selectChatsByIssueCollabIdMap(state) || {})[issueCollabID]

export const selectDirectMessageByIdMap = (state: ChatState) =>
  (state || {}).directMessages
export const selectDirectMessageById = (state: ChatState, dmId: number) =>
  (selectDirectMessageByIdMap(state) || {})[dmId]

export const selectCreatedDirectMessageMap = (state: ChatState) =>
  // These are needed for detecting when the direct message has been created.
  // @ts-ignore: I don't think I am using the state directMessagesCreating anywhere
  (state || {}).directMessagesCreating
export const selectCreatedDirectMessageId = (
  state: ChatState,
  icoRequestId: string | number
) => {
  if (icoRequestId)
    return (selectCreatedDirectMessageMap(state) || {})[icoRequestId]

  return state.recentlyCreatedDirectMessage
}
export const selectCreatedDirectMessage = (
  state: ChatState,
  icoRequestId: string | number
) =>
  selectDirectMessageById(
    state,
    selectCreatedDirectMessageId(state, icoRequestId)
  )

export interface ChatEntryState {
  [ChatEntryID: number]: ChatEntryModel
}

export function chatEntryReducer(state: ChatEntryState = {}, action: any) {
  switch (action.type) {
    case ChatDetailConstants.ADD.SUCCESS:
      const entry = action.payload.result || {}
      return {
        ...state,
        [entry.ChatEntryID]: entry,
      }
    case ChatDetailConstants.FETCH.SUCCESS:
      return {
        ...state,
        ...action.payload.result,
      }
    case ChatDetailConstants.DELETE_RANGE.SUCCESS:
      return reducerCrud.deleteIdRange(
        state,
        action.payload.result,
        'ChatEntryID'
      )
    default:
      return state
  }
}

/*
 * CHAT ENTRY SELECTORS
 */
export const selectChatEntryByIdMap = (state: ChatEntryState) => state
export const selectChatEntryById = (
  state: ChatEntryState,
  chatEntryID: number
) => (selectChatEntryByIdMap(state) || {})[chatEntryID]

/// Chat Unread reducers
const unreadReducers = createReadReducers(
  {
    fetchLatestDates: FETCH_LATEST_CHAT_DATES,
    updateRead: UPDATE_CHAT_READ,
    setIsViewing: SET_IS_VIEWING_CHAT,
    incrementUnreadCount: INCREMENT_CHAT_UNREAD_COUNT,
    fetchUnreadCount: FETCH_CHAT_UNREAD_COUNT,
  },
  {
    primaryID: 'ChatID',
    readReducer: (state, action) => ({
      [ChatDetailConstants.ADD.SUCCESS]: () => {
        const chatID = action.payload.source.ChatID
        const oldRead = state[chatID] || {}
        return {
          ...state,
          [chatID]: {
            ...oldRead,
            lastActivityDate: action.payload.result.CreatedUTC,
          },
        }
      },
    }),
  }
)

export const chatReadReducer = unreadReducers.read
export const chatUnreadCountReducer = unreadReducers.unreadCount
