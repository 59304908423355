// @ts-nocheck
// TODO: Typescript
import {
  FETCH_UPDATE_ISSUE_FORMS,
  FETCH_CREATE_ISSUE_FORMS,
  FETCH_FORM_CONTENT,
  SUBMIT_ISSUE_FORM,
  TeamReportConstants,
  FETCH_CHANGE_LEVEL_FORM_CONTENT,
  FETCH_OPEN_ISSUE_FORM_CONTENT,
  FETCH_RESOLVE_ISSUE_FORM_CONTENT,
} from './_constants'
import { reducerCrud } from '../_utils/reduxUtils'
import { addKeyValueIfNotPresent } from '../_utils/objectUtils'

/*
 * Create Issue Forms Reducer
 */
export function createIssueFormsReducer(state = [], action) {
  switch (action.type) {
    case FETCH_CREATE_ISSUE_FORMS.SUCCESS:
      return action.payload.result || []
    default:
      return state
  }
}

export const selectCreateIssueForms = (state) => state

/*
 * Update Issue Forms Reducer
 */
export function updateIssueFormsReducer(state = [], action) {
  switch (action.type) {
    case FETCH_UPDATE_ISSUE_FORMS.REQUEST:
      return []
    case FETCH_UPDATE_ISSUE_FORMS.SUCCESS:
      return action.payload.result || []
    default:
      return state
  }
}

export function afterSubmitIssueFormsReducer(state = [], action) {
  switch (action.type) {
    case SUBMIT_ISSUE_FORM.REQUEST:
      return []
    /*case SUBMIT_ISSUE_FORM.SUCCESS:
            return action.payload.result;*/
    default:
      return state
  }
}
export const selectAfterSubmitIssueForms = (state) => state

export function currentFormContentReducer(state = '', action) {
  switch (action.type) {
    case FETCH_FORM_CONTENT.REQUEST:
      return {
        ...state,
        formContent: '',
      }
    case FETCH_FORM_CONTENT.SUCCESS:
      return {
        ...state,
        formContent: action.payload.content,
      }
    case FETCH_CHANGE_LEVEL_FORM_CONTENT.REQUEST:
      return {
        ...state,
        changeLevel: '',
      }
    case FETCH_CHANGE_LEVEL_FORM_CONTENT.SUCCESS:
      return {
        ...state,
        changeLevel: action.payload.content,
      }
    case FETCH_OPEN_ISSUE_FORM_CONTENT.REQUEST:
      return {
        ...state,
        openIssue: '',
      }
    case FETCH_OPEN_ISSUE_FORM_CONTENT.SUCCESS:
      return {
        ...state,
        openIssue: action.payload.content,
      }
    case FETCH_RESOLVE_ISSUE_FORM_CONTENT.REQUEST:
      return {
        ...state,
        resolveIssue: '',
      }
    case FETCH_RESOLVE_ISSUE_FORM_CONTENT.SUCCESS:
      return {
        ...state,
        resolveIssue: action.payload.content,
      }
    default:
      return state
  }
}
export const selectCurrentFormContent = (state) => state.formContent
export const selectCurrentChangeLevelContent = (state) => state.changeLevel
export const selectCurrentOpenIssueContent = (state) => state.openIssue
export const selectCurrentResolveIssueContent = (state) => state.resolveIssue

export function teamReportReducer(
  state = { teamReports: {}, issueReportIdMap: {} },
  action
) {
  switch (action.type) {
    case TeamReportConstants.INIT.SUCCESS:
      let issueReportIdMap = {}
      for (let entityID in action.payload.result) {
        const entity = action.payload.result[entityID]
        issueReportIdMap[parseInt(entity.IssueReportID)] = entity.TeamReportID
      }

      return {
        teamReports: action.payload.result,
        issueReportIdMap,
      }
    case TeamReportConstants.UPDATE_RANGE.SUCCESS:
      return {
        ...state,
        teamReports: reducerCrud.updateRange(
          state.teamReports,
          action,
          'TeamReportID'
        ),
        issueReportIdMap: addKeyValueIfNotPresent(
          state.issueReportIdMap,
          action.payload.result,
          'IssueReportID',
          (entity) => entity.TeamReportID
        ),
      }
    case TeamReportConstants.DELETE_RANGE.SUCCESS:
      return {
        teamReports: reducerCrud.deleteIdRange(
          state.teamReports,
          action.payload.result
        ),
        issueReportIdMap: reducerCrud.deleteIdRangeFromExtMap(
          state.teamReports,
          state.issueReportIdMap,
          'IssueReportID',
          action.payload.result
        ),
      }
    default:
      return state
  }
}

export const selectTeamReportIDMap = (state) => state.issueReportIdMap
export const selectTeamReportById = (state, teamReportID) =>
  state.teamReports[teamReportID]
export const selectTeamReports = (state) => state.teamReports
export const selectTeamReportIssueReportIdMap = (state) =>
  (state || {}).issueReportIdMap
export const selectTeamReportIdByIssueReport = (state, issueReportID) =>
  (selectTeamReportIssueReportIdMap(state) || {})[issueReportID]
