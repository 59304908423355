// @ts-nocheck
// TODO: Typescript
import { CLEAR_REQUEST_SYNC } from './_constants'

export function clearRequestSync(type, source) {
  return {
    type: CLEAR_REQUEST_SYNC,
    payload: {
      type,
      source,
    },
  }
}
