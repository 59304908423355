// @ts-nocheck
// TODO: Typescript
import { generateTypes, generateCrudTypes } from '../_utils/reduxUtils'
export const FETCH_CREATE_ISSUE_FORMS = generateTypes(
  'CREATE_ISSUE_FORMS',
  'FETCH'
)
export const FETCH_UPDATE_ISSUE_FORMS = generateTypes(
  'UPDATE_ISSUE_FORMS',
  'FETCH'
)
export const FETCH_UPDATE_ISSUE_FORMS_COUNT = generateTypes(
  'UPDATE_ISSUE_FORMS_COUNT',
  'FETCH'
)
export const SUBMIT_ISSUE_FORM = generateTypes('SUBMIT_ISSUE_FORM', 'SUBMIT')
export const FETCH_FORM_CONTENT = generateTypes('FORM_CONTENT', 'FETCH')
export const FETCH_CHANGE_LEVEL_FORM_CONTENT = generateTypes(
  'CHANGE_LEVEL_FORM_CONTENT',
  'FETCH'
)
export const FETCH_OPEN_ISSUE_FORM_CONTENT = generateTypes(
  'OPEN_ISSUE_FORM_CONTENT',
  'FETCH'
)
export const FETCH_RESOLVE_ISSUE_FORM_CONTENT = generateTypes(
  'RESOLVE_ISSUE_FORM_CONTENT',
  'FETCH'
)
export const TeamReportConstants = generateCrudTypes('TEAM_REPORT')
