import { History } from 'history'
import React, { Fragment, useEffect, useState } from 'react'
import { Button } from 'reactstrap'
import { LoadingBackDrop } from '../Shared/LoadingBackdrop'
import { icoFetch } from '../_utils/fetchUtils'
import { getQuery } from '../_utils/urlUtils'
import { AuthMasterLayout } from './AuthMasterLayout'
import { Spacer } from '../Shared/Spacer'
import { cx } from '../_utils/objectUtils'
import classes from './LoginForm.module.scss'

interface Props {
  id: any
  location: any
  history: History
}

const siteName = 'In Case of Crisis'
export function CreateAccountCancel({
  id,
  location,
  history,
  ...otherProps
}: Props) {
  const [loading, setLoading] = useState(false)
  const [response, setResponse] = useState<{
    ErrorMessage?: string
    status?: string
    email?: string
  }>({})

  useEffect(() => {
    setLoading(true)
    const guid = getQuery(location).get('rr') || 'empty'
    icoFetch('/api/AppAccount/CancelEmail/' + guid)
      .then((response) => response.json())
      .then((data) => {
        setResponse(data)
      })
      .catch((_) =>
        setResponse({
          ErrorMessage: 'Error cancelling account creation for this email.',
        })
      )
      .finally(() => setLoading(false))
  }, [location])

  function handleGoToLogin() {
    history.push({
      pathname: '/login',
    })
  }

  if (loading) return <LoadingBackDrop open={true} text="Loading app..." />

  return (
    <AuthMasterLayout
      {...otherProps}
      history={history}
      showAlert={response.ErrorMessage ? true : false}
      alertColor={response.ErrorMessage ? 'danger' : 'success'}
      alertText={response.ErrorMessage}
      showHomeLink={false}
    >
      <div
        className={cx(
          classes.form,
          classes.formBackground,
          classes.formContent
        )}
      >
        <h5>Cancel Account Creation</h5>
        <Spacer spaceParam={'large'} />
        <Spacer spaceParam={'large'} />

        {response.status === 'SUCCESS' && (
          <>
            <p>
              Your email account creation with {siteName} was successfully
              cancelled. If you decide to create an account at a later time,
              please navigate to the login screen and click 'Create New Account'
              or contact your Plan Administrator for assistance.
            </p>
            <Spacer spaceParam={'large'} />
            <Spacer spaceParam={'large'} />

            <Button color="darker" onClick={handleGoToLogin}>
              Go to Login
            </Button>
          </>
        )}
      </div>
    </AuthMasterLayout>
  )
}

export default CreateAccountCancel
