import {
  CircularProgress,
  FormControlLabel,
  InputLabel,
  ListItem,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
} from '@material-ui/core'
import React, { Fragment } from 'react'
import classes from './IssueTemplateListing.module.scss'
import { Spacer } from '../Shared/Spacer'
import { http } from '../_utils/http'
import { cx } from '../_utils/objectUtils'

import { Button } from 'reactstrap'
import { icoPost } from '../_utils/fetchUtils'
import { MeetingDialog, ScheduledMeeting } from '../Conference/MeetingDialog'
import dayjs from 'dayjs'
import { TiptapEditor } from '../Shared/IcoTipTapEditor'

interface Props {
  panel: string
  handleDialogClose: () => void
  onTemplateClick: () => void
}

const rootStyle = {
  display: 'list-item',
  listStyle: 'none',
}

const hrStyle = {
  border: '4px solid var(--icc-blue)',
  borderRadius: '5px',
}

export const IssueTemplateListing = ({
  panel,
  handleDialogClose,
  onTemplateClick,
}: Props) => {
  const [templateIssueID, setTemplateIssueID] = React.useState<any>('') // which template user selected
  const [templateList, setTemplateList] = React.useState<any>('') // list of templates
  const [templateListLoading, setTemplateListLoading] = React.useState(true)

  const [templateValuesPicklist, setTemplateValuesPicklist] =
    React.useState<any>('')
  const [newIssueName, setNewIssueName] = React.useState('')
  const [newDescription, setNewDescription] = React.useState('')
  const [newLevelID, setNewLevelID] = React.useState('')
  const [issueCreating, setIssueCreating] = React.useState(false)
  const [selectedTemplate, setSelectedTemplate] = React.useState<
    any | undefined
  >()
  const [selectedOrg, setSelectedOrg] = React.useState<any | undefined>()

  const [meetingOpen, setMeetingOpen] = React.useState(false)
  const [currentMeeting, setCurrentMeeting] = React.useState<
    ScheduledMeeting | undefined
  >()

  const closeMeeting = () => {
    setMeetingOpen(false)
  }

  const getTemplateValuesPicklist =
    React.useCallback(async (): Promise<any> => {
      var customHeaders: any = []
      if (templateIssueID) {
        customHeaders.push({ name: 'issueid', value: `${templateIssueID}` })
      }
      const result = await http<any>({
        path: '/Issue/ClonePicklist/',
        headers: customHeaders,
      })
      if (result.ok && result.body) {
        return result.body
      } else {
        return ''
      }
    }, [templateIssueID])

  const getTemplateList = async (): Promise<any> => {
    const result = await http<any>({
      path: '/Issue/IssueTemplates/',
    })
    if (result.ok && result.body) {
      return result.body
    } else {
      return ''
    }
  }
  React.useEffect(() => {
    setNewIssueName(templateValuesPicklist.IssueName)
    setNewDescription(templateValuesPicklist.Description)
    // setNewLevelID(templateValuesPicklist.IssueLevelID)
  }, [templateValuesPicklist])

  React.useEffect(() => {
    setSelectedTemplate(undefined)
  }, [selectedOrg])

  React.useEffect(() => {
    const doGetTemplateValuesPicklist = async () => {
      const picklist = await getTemplateValuesPicklist()
      setTemplateValuesPicklist(picklist)
      setNewLevelID(picklist.IssueLevelID)
    }
    if (templateIssueID !== '') {
      doGetTemplateValuesPicklist()
    }
  }, [templateIssueID, getTemplateValuesPicklist])

  React.useEffect(() => {
    const doGetTemplatelist = async () => {
      const tpl = await getTemplateList()
      setTemplateList(tpl)
      setTemplateListLoading(false)
    }
    doGetTemplatelist()
  }, [])

  function handleNewIssueNameChange(evt: any) {
    setNewIssueName(evt.target.value)
  }
  // function handleNewDescriptionChange(evt: any) {
  //   setNewDescription(evt.target.value)
  // }
  function handleDescriptionChange(editor: any) {
    setNewDescription(editor)
  }
  function handleNewLevelID(evt: any) {
    setNewLevelID(evt.target.value)
  }
  function handleSaveButton() {
    setIssueCreating(true)
    const doCloneIssue = async () => {
      if (issueCreating) return
      const cloneResult = await cloneIssue()
      if (cloneResult) {
        //alert('it worked, need to close window now.')

        handleDialogClose()
        // onClose({}, 'userSaved')
      }
      setIssueCreating(false)
    }
    doCloneIssue()
  }

  const cloneIssue = async (): Promise<any> => {
    const pdata = {
      fromIssueID: templateIssueID.toString(),
      newIssueName: newIssueName,
      newIssueType: 'A',
      newLevelID: newLevelID,
      newDescription: newDescription,
      copyStakeholdersYN: 'Y',
      copyTaskListYN: 'Y',
      copyTaskAssignmentsYN: 'Y',
      copyHoldingStatementYN: 'Y',
      copyIssueTagsYN: 'Y',
      copyParticipantsYN: 'Y',
      copyArtifactsYN: 'Y',
      copyConferenceYN: 'Y',
      initialConference: currentMeeting ? JSON.stringify(currentMeeting) : null,
    }

    return await icoPost('/api/Issue/IssueClone', pdata)
      .then((res) => {
        return res.json()
      })
      .then((data) => {
        if (data.Status === 'Failure') return false
        else {
          return data
        }
      })
  }

  function includedIssueElements() {
    var retVal: string[] = []

    if (templateValuesPicklist.HasHoldingStatementYN === 'Y') {
      retVal.push('Holding Statement')
    }
    if (templateValuesPicklist.HasTasklistYN === 'Y') {
      retVal.push('Task Lists')
    }
    if (templateValuesPicklist.HasTasklistAssignmentYN === 'Y') {
      retVal.push('Task List Assignments')
    }
    if (templateValuesPicklist.HasIssueTagsYN === 'Y') {
      retVal.push('Tags')
    }
    if (templateValuesPicklist.HasArtifactYN === 'Y') {
      retVal.push('Attachments')
    }
    if (templateValuesPicklist.HasIssueStakeholdersYN === 'Y') {
      retVal.push('Stakeholders')
    }
    if (templateValuesPicklist.HasParticipantsYN === 'Y') {
      retVal.push('Participants')
    }
    if (templateValuesPicklist.HasConferenceYN === 'Y') {
      retVal.push('Meetings')
    }
    if (templateValuesPicklist.HasPoliciesYN === 'Y') {
      retVal.push('Policies')
    }
    if (retVal.length > 0) {
      return 'Include from Template: ' + retVal.join(', ')
    } else {
      return ''
    }
  }

  function renderInputForm() {
    return (
      <div>
        <span className={classes.title}>Create From Template</span>
        <span className={classes.titleSub}>
          <b>Selected Template:</b> {templateValuesPicklist.IssueName}
          <br />
          <b>Organization:</b> {templateValuesPicklist.OrgName}
        </span>
        <Spacer spaceParam={'large'} />
        <div>
          <label className={classes.inputLabel}>New Issue Name</label>
          <input
            className={classes.enabledInputs}
            style={{ marginBottom: 'none' }}
            type="text"
            maxLength={255}
            name="NewIssueName"
            id="NewIssueName"
            value={newIssueName}
            onChange={handleNewIssueNameChange}
          />
        </div>
        <div>
          <label className={classes.inputLabel}>New Issue Description</label>
          {/* <input
            className={classes.enabledInputs}
            style={{ marginBottom: 'none' }}
            type="text"
            maxLength={255}
            name="newDescription"
            id="newDescription"
            value={newDescription}
            onChange={handleNewDescriptionChange}
          />
        </div>
        <div> */}
          <TiptapEditor
            readOnly={false}
            placeholder="Description..."
            value={newDescription}
            onChange={handleDescriptionChange}
          />
        </div>
        {/*  <span className={classes.helpText}>
          This will be the new Issue Description.
        </span> */}
        <Spacer spaceParam={'large'} />
        <div>
          <label id="lblLevelID" className={classes.inputLabel}>
            New Issue Severity
          </label>
          <RadioGroup
            aria-labelledby="lblLevelID"
            name="newLevelID"
            id="newLevelID"
            value={newLevelID}
            onChange={handleNewLevelID}
            style={{ marginLeft: '1rem' }}
          >
            {templateValuesPicklist.IssueLevelPicklist.map(
              (prov: any, ndx: number) => (
                <FormControlLabel
                  value={prov.LevelID}
                  key={ndx}
                  control={
                    <Radio
                      className={classes.MuiIconButtonColorSecondary}
                      style={{
                        paddingTop: 0,
                        paddingBottom: 0,
                        paddingLeft: '20px',
                      }}
                    />
                  }
                  label={prov.LevelName}
                />
              )
            )}
          </RadioGroup>
        </div>
        <Spacer spaceParam={'large'} />{' '}
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button
            onClick={() => setMeetingOpen(true)}
            disabled={issueCreating}
            color="link"
          >
            Schedule Meeting
          </Button>
          {currentMeeting && (
            <div style={{ fontSize: '12px', color: 'grey' }}>
              <div> {currentMeeting.Link} </div>
              <div style={{ display: 'flex' }}>
                <div>
                  {dayjs(currentMeeting.startDate).format('MMM-DD-YYYY')}
                </div>
                <Spacer />
                <div>
                  {dayjs.utc(currentMeeting.startDate).local().format('h:mmA')}
                  &nbsp;-&nbsp;
                  {dayjs.utc(currentMeeting.endDate).local().format('h:mmA')}
                </div>
              </div>
            </div>
          )}
        </div>
        <Spacer />
        <div style={{ fontSize: '12px' }}>{includedIssueElements()}</div>
        <Spacer />
        <div>
          <Button
            className={cx('p-2', classes.btnIcc)}
            variant={'primary' as any}
            onClick={handleSaveButton}
            disabled={issueCreating}
          >
            Create New Issue
          </Button>
        </div>
        <Spacer spaceParam={'large'} />
        <MeetingDialog
          open={meetingOpen}
          currentMeeting={currentMeeting}
          onCancel={closeMeeting}
          onSubmit={(meeting: ScheduledMeeting) => setCurrentMeeting(meeting)}
          orgId={templateValuesPicklist.OrgID}
          orgName={templateValuesPicklist.OrgName}
        />
        {panel === 'formItems' && (
          <>
            <hr style={hrStyle} />
            <span className={classes.title}>Create Without Template</span>
          </>
        )}
      </div>
    )
  }

  function render() {
    if (templateListLoading) {
      return (
        <>
          <CircularProgress />
        </>
      )
    }
    if (templateList.length === 0) {
      return <> </>
    }

    let totalTemplates = 0
    templateList.forEach((org: any) => {
      org.itmpl.forEach((template: any) => {
        totalTemplates += 1
      })
    })

    if (panel === 'formItems') {
      return (
        <div>
          {/* <div style={{ fontSize: 'small' }}>Templates:</div> */}
          <span className={classes.title}>Create From Template</span>

          {/* {JSON.stringify(templateList, null, 2)} */}
          {totalTemplates >= 3 ? (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <InputLabel shrink>Organization</InputLabel>
              <Select
                value={selectedOrg ? selectedOrg : ''}
                name="Organization"
                onChange={(e) => setSelectedOrg(e.target.value)}
              >
                {templateList.map((template: any, index: number) => (
                  <MenuItem key={index.toString()} value={template}>
                    {template.OrgName}
                  </MenuItem>
                ))}
              </Select>
              <Spacer />
              {selectedOrg && (
                <>
                  <InputLabel shrink>Template</InputLabel>
                  <Select
                    value={selectedTemplate ? selectedTemplate : ''}
                    name="Organization"
                    onChange={(e: any) => {
                      setTemplateIssueID(e.target.value.TemplateIssueID)
                      setSelectedTemplate(e.target.value)
                    }}
                  >
                    {selectedOrg.itmpl.map((template: any, index: number) => (
                      <MenuItem key={index.toString()} value={template}>
                        {template.TemplateName}
                      </MenuItem>
                    ))}
                  </Select>
                </>
              )}
              <Spacer />
              {selectedTemplate && (
                <Button onClick={() => onTemplateClick()} color="link">
                  Activate Team
                </Button>
              )}
            </div>
          ) : (
            templateList.map((tlOrg: any, tlidx: number) => (
              <Fragment key={'frag' + tlidx.toString()}>
                {tlOrg.itmpl.map((tltmpl: any, tltmplidx: number) => (
                  <ListItem
                    style={rootStyle}
                    key={'tmplListKey' + tltmplidx.toString()}
                  >
                    <Button
                      // className={cx('p-2', classes.btnIcc)}
                      color="link"
                      onClick={() => {
                        onTemplateClick()
                        setTemplateIssueID(tltmpl.TemplateIssueID)
                      }}
                    >
                      {tltmpl.TemplateName}
                    </Button>
                    <Fragment>
                      <br />
                      <div style={{ fontSize: 'small' }}>{tlOrg.OrgName}</div>
                    </Fragment>
                  </ListItem>
                ))}
              </Fragment>
            ))
          )}

          <hr style={hrStyle} />
          <span className={classes.title}>Create Without Template</span>
        </div>
      )
    }

    if (templateValuesPicklist && panel === 'formTemplateContent') {
      return (
        <div>
          {renderInputForm()}
          {/* <div>
                DEBUG: templateValuesPicklist:
                {JSON.stringify(templateValuesPicklist, null, 2)}
              </div> */}
        </div>
      )
    } else return <></>
  }

  return render()
}
