import { faExclamationTriangle } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as React from 'react'
import { AutoFocus } from '../Shared/AutoFocus'
import { cx } from '../_utils/objectUtils'
import classes from './Playbooks.module.scss'

export interface PbEventItemProps {
  EventID: number
  Image?: { Format: string; Graphic: string }
  EventName?: string
  EventType?: string
  Layout?: string
  onClick?(event: PbEventItemProps): void
  selected?: boolean
}

export const PbEvItem = ({
  EventID,
  EventName,
  Image,
  EventType,
  Layout,
  onClick = () => {},
  selected,
}: PbEventItemProps) => {
  let evtimg
  if (Image) {
    if (Image.Format) {
      evtimg = (
        <img
          alt={EventName}
          src={'data:image/' + Image.Format + ';base64,' + Image.Graphic}
        />
      )
    }
  } else {
    if (EventName) {
      if (EventName === 'Alert')
        evtimg = (
          <FontAwesomeIcon
            className={classes.faAlert}
            icon={faExclamationTriangle}
          />
        )
    }
  }
  if (!(EventName && EventID)) return <i>Event Not Defined</i>
  else
    return (
      <>
        <div
          key={EventID}
          className={cx(
            Layout === 'Grid' ? classes.gridView : classes.eventPadding,
            'Ico-Item-root position-relative',
            selected ? 'Ico-selected' : ''
          )}
        >
          {selected && <AutoFocus />}
          <div
            className={cx('clearfix', classes.clickBox)}
            onClick={() => onClick({ EventID, EventName, Image })}
          >
            {Layout === 'Grid' ? (
              <div className={cx(classes.gridViewEvent)}>
                {evtimg}
                <br />
                <span className={cx(classes.fontBold)}>{EventName}</span>
              </div>
            ) : (
              <>
                <div className={cx('float-left', classes.eventImg)}>
                  {evtimg}
                </div>
                <div
                  className={cx(
                    'float-left',
                    classes.fontBold,
                    classes.planSpec
                  )}
                >
                  {EventName}
                </div>
              </>
            )}
          </div>
        </div>
      </>
    )
}
