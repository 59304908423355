import { faTimes } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import Moment from 'react-moment'
import ArtifactPreview from '../Upload/ArtifactPreview'
import classes from '../Chat/ChatDetailItem.module.scss'
import { appDateTimeFormat } from '../_constants'
import { getLocalDate } from '../_utils/dateUtils'
import { useAppSelector } from '../_utils/reactReduxHooks'
import { selectUserEmail } from '../Auth/_selectors'
import { AdminConsoleDialog } from '../Shared/AdminConsoleDialog'

export const TaskCommentItem = ({
  commentId,
  createdUtc,
  content,
  entryCreatedBy,
  artifactId,
  onClickThumbnail,
  deleteComment,
}: {
  commentId: string
  comment?: string
  createdUtc?: Date
  content?: string
  entryCreatedBy?: string
  artifactId?: string
  onClickThumbnail: () => void
  deleteComment: (commentId: string) => void
}) => {
  const [deleteCommentDialogOpen, setDeleteCommentDialogOpen] =
    React.useState(false)

  const artifactPreviewClasses = {
    artifactTile: classes.message,
  }

  function DateBlock() {
    return (
      <div className={classes.createdDate}>
        <Moment
          local
          format={appDateTimeFormat}
          date={getLocalDate(createdUtc)}
        />
      </div>
    )
  }

  const userEmail = useAppSelector((state) => selectUserEmail(state))

  return (
    <li
      data-private="lipsum"
      id={commentId}
      key={commentId}
      className={classes.root}
      style={{ borderBottom: '1px solid black' }}
    >
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        {!artifactId && (
          <div
            className={classes.message}
            dangerouslySetInnerHTML={{
              __html: content || '',
            }}
          />
        )}
        {!artifactId ? null : (
          <ArtifactPreview
            className={classes.artifactPreview}
            ArtifactID={artifactId}
            showCreatedUTC={false}
            onClickThumbnail={onClickThumbnail}
            hideTitle={false}
            border={false}
            classes={artifactPreviewClasses}
          />
        )}
        {userEmail === entryCreatedBy?.split(' ')[0] ? (
          <FontAwesomeIcon
            icon={faTimes}
            onClick={() => setDeleteCommentDialogOpen(true)}
            style={{ cursor: 'pointer' }}
          />
        ) : null}
      </div>

      <div className={classes.header}>
        <div className={classes.user}>{entryCreatedBy}</div>
        <DateBlock />
      </div>
      <AdminConsoleDialog
        open={deleteCommentDialogOpen}
        content={'Are you sure you would like to delete this comment?'}
        onConfirm={() => {
          deleteComment(commentId)
          setDeleteCommentDialogOpen(false)
        }}
        consoleRole="destructive"
        onCancel={() => setDeleteCommentDialogOpen(false)}
      />
    </li>
  )
}

export default TaskCommentItem
