// For selecting from the whole slice. Minimal code here. Just pass the appropriate slice
import * as priv from './_reducer'
import {
  bindSel,
  getInbox,
  getAlert,
  getAlertIDs,
  getAlertSearch,
} from '../_rootConfigs/rootReducerSlicers'
import { createSelector } from 'reselect'
import { AlertModel } from './_models'
import { RootState } from '../_store'

// Inbox
export const selectInboxes = bindSel(getInbox, priv.selectInboxes)
export const selectInboxByGUID = bindSel(getInbox, priv.selectInboxByGUID)
export const selectInboxByID = bindSel(getInbox, priv.selectInboxByID)
export const selectInboxIDtoGUIDMap = bindSel(
  getInbox,
  priv.selectInboxIDtoGUIDMap
)
export const selectInboxNamesWithGUIDs = bindSel(
  getInbox,
  priv.selectInboxNamesWithGUIDs
)
export const selectInboxIDsWithDefaultSeverity = bindSel(
  getInbox,
  priv.selectInboxIDsWithDefaultSeverity
)

export const selectInboxCurrentFeedGUID = bindSel(
  getInbox,
  priv.selectInboxCurrentFeedGUID
)
export const selectInboxIdMap = bindSel(getInbox, priv.selectInboxIdMap)

// Alerts
export const selectAlerts = createSelector(
  getAlert,
  getAlertIDs,
  (alerts, alertIDs) => {
    let alertArray: Array<AlertModel> = []
    alertIDs.forEach((alertID: string) => alertArray.push(alerts[alertID]))
    return alertArray
  }
)
export const selectAlertByID = bindSel(getAlert, priv.selectAlertByID)

export const selectAlertFilters = (State: RootState) =>
  State.issueApp.alertFilter

// Search

export const selectAlertSearch = bindSel(getAlertSearch, priv.selectAlertSearch)
