// @ts-nocheck
// TODO: Typescript

// A custom hook that builds on useLocation to parse
// the query string for you.
export function getQuery(location = window.location) {
  //return new URLSearchParams(useLocation().search);
  return new URLSearchParams((location || {}).search)
}

/**
 * @description - Parses a path string into an object
 */
export function parsePathVariables(pathString) {
  var result = {} as { [paramName: string]: string }

  if (pathString) {
    var tokens = pathString.split('/')
    for (var i = 0; i < tokens.length - 1; i++) {
      var curValue = tokens[i + 1]
      if (!isNaN(curValue)) curValue = parseInt(curValue)

      result[tokens[i]] = curValue
    }
  }

  return result
}

export function extractURLfromString(strWithURL) {
  var expression =
    /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi
  var regex = new RegExp(expression)
  return strWithURL.match(regex)
}

// Build query string from map of query parameter
export function toQueryString(queryParams) {
  let encodedQueryParams = []
  for (let key in queryParams) {
    encodedQueryParams.push(key + '=' + encodeURIComponent(queryParams[key]))
  }
  return encodedQueryParams.join('&')
}

const exportUrlUtil = {
  getQuery,
}
export default exportUrlUtil
