import React, { useEffect, useCallback, useState, useRef, useMemo } from 'react'
import { IssueDetailMenuItem } from './IssueDetailMenuItem'
import issueDetailMenuClasses from './IssueDetailMenu.module.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faPhoneAlt,
  faFileAlt,
  faClipboardList,
  faCommentDots,
  faUsers,
  faHome,
  faClipboard,
  faUserPlus,
  faListUl,
  faTrash,
  faBan,
  faExchangeAlt,
  faCircle,
  IconDefinition as IconDefinitionSolid,
  faRetweet,
  faFilePlus,
  faEllipsisV,
  faFileChartLine,
  faFileCheck,
  faCalendarLinesPen,
  faVideo,
  faClone,
  faShopLock,
  faBooks,
} from '@fortawesome/pro-light-svg-icons'
import { cx } from '../_utils/objectUtils'
import { IssueDetailMenuHeader } from './IssueDetailMenuHeader'
import Badge from '../Shared/Badge'
import {
  selectWorkspaceAllChatCounts,
  selectWorkspaceArtifactCount,
  selectWorkspaceChatIds,
  selectWorkspaceConferenceIds,
  selectWorkspaceTaskIds,
  selectWorkspaceUpdateFormCount,
} from '../Workspace/_selectors'
import {
  isIssueAdvisoryCreated,
  isUserIssueAdminister,
  selectWorkspaceNextConference,
  selectWorkspaceParticipantCount,
  selectWorkspaceRecentPastDueConference,
  isUserIssueApprover,
  selectIssueCurrentIssueLevel,
  selectTaskAllItemIds,
  selectWorkspaceDirectMessages,
} from '../_rootConfigs/rootSelectors'
import {
  hasNewIssueActivityLog,
  selectIssueAllStakeholderCount,
  selectIssueById,
  selectIssueTeamReportCount,
  selectIssueEvidenceLockerLinksCount,
} from './_selectors'
import { isAzureADLogin } from '../Auth/_selectors'
import { selectChatById, selectIsChatUnread } from '../Chat/_selectors'
import { selectIsTaskUnread } from '../Task/_selectors'

import { prefixSelectorWithApp } from '../_utils/domUtils'
import { AdvisoryMUI } from '../Stakeholder/Icons'
import { IconDefinition as IconDefinitionLight } from '@fortawesome/pro-light-svg-icons'
import { IconDefinition as IconDefinitionDuotone } from '@fortawesome/pro-duotone-svg-icons'
import { IconDefinition as IconDefinitionRegular } from '@fortawesome/pro-regular-svg-icons'
import { useAppDispatch, useAppSelector } from '../_utils/reactReduxHooks'
import { IssueDetailMenuType } from './IssueDetailMenuType'
import ChangeLevelFormContentDialog from '../Form/ChangeLevelFormContentDialog'
import ReopenIssueFormContentDialog from '../Form/ReopenIssueFormContentDialog'
import { ConfirmDialog } from '../Shared/ConfirmDialog'
import {
  fetchChangeLevelFormContent,
  fetchResolveIssueFormContent,
  fetchOpenIssueFormContent,
  fetchUpdateIssueFormsCount,
} from '../Form/_actions'
import { IssueActions } from './_actions'
import { Menu, Drawer, Tooltip, List, ListItem } from '@material-ui/core'
import { isMobileApp } from '../_selectors'
import { IssueCloneDialog } from './IssueCloneDialog'
import { IssueChangeModeDialog } from './IssueChangeModeDialog'
import { Spacer } from '../Shared/Spacer'
import { IssueChangeTemplateFlagDialog } from './IssueChangeTemplateFlagDialog'
import menuClasses from '../AppHeader.module.scss'
import { Button } from 'reactstrap'
import { getTaskListForTask } from '../_utils/apiUtils'
import { selectMSCalendarEventsCount } from '../MicrosoftTeams/_selectors'
import {
  fetchGraphCalendarEvents,
  setGraphCalendarEventsWorkspace,
} from '../MicrosoftTeams/_actions'
import { IssueResolveDialog } from './IssueResolveDialog'
import { useHistory } from 'react-router'

interface Props {
  IssueID: number
  IssueTeamWorkspaceID: number
  compact?: boolean
  classes?: any
  children?: React.ReactNodeArray
  itemSelected?(selected: string): void
  selected?: any
}

interface MenuElement {
  itemName?: IssueDetailMenuType
  menuComponent: {
    id?: string
    style?: any
    classNames?: Array<string>
    itemTitle: string | JSX.Element
    content?: JSX.Element
    extraContent?: JSX.Element
    icon?: JSX.Element
    count?: number
    description?: JSX.Element | ''
    hideMenuElement?: boolean
    onClick?: (any: any) => any
  }
  fontAwesomeIcon:
    | IconDefinitionSolid
    | IconDefinitionLight
    | IconDefinitionDuotone
    | IconDefinitionRegular
  itemLocations: Array<'big menu' | 'dropdown' | 'header'>
}

const dialogNames = {
  changeLevel: 'changeLevel',
  openIssue: 'openIssue',
  resolveIssue: 'resolveIssue',
  deleteIssue: 'deleteIssue',
}

function createDomID(selector: string) {
  return prefixSelectorWithApp('issueDetail-' + selector)
}

export const IssueDetailMenu = ({
  IssueID,
  IssueTeamWorkspaceID,
  itemSelected,
  classes,
  compact,
  selected,
}: Props) => {
  const [isResolved, setIsResolved] = useState(false)
  const [formDialog, setFormDialog] = useState('')
  const [issueMenuOpened, setIssueMenuOpened] = useState(false)
  const [cloneDialogOpened, setCloneDialogOpened] = useState(false)
  const [changeModeDialogOpened, setChangeModeDialogOpened] = useState(false)
  const [changeTemplateFlagDialogOpened, setChangeTemplateFlagDialogOpened] =
    useState(false)

  const [taskListCount, setTaskListCount] = useState<number>()
  const dispatch = useAppDispatch()

  const isMobile = useAppSelector((state) => isMobileApp(state))
  const nextConference = useAppSelector((state) =>
    selectWorkspaceNextConference(state, IssueTeamWorkspaceID)
  )
  const recentPastDueConference = useAppSelector((state) =>
    selectWorkspaceRecentPastDueConference(state, IssueTeamWorkspaceID)
  )

  const {
    IssueName,
    IssueResolved,
    Description,
    UserIsReadOnlyYN,
    UserIsExecutiveYN,
    briefingsCount,
    policiesCount,
    assessmentsCount,
    scribeNotesCount,
  } = useAppSelector((state) => selectIssueById(state, IssueID))

  const hasNewActivityLog = useAppSelector((state) =>
    hasNewIssueActivityLog(state, IssueID)
  )
  const chatIDs = useAppSelector((state) =>
    selectWorkspaceChatIds(state, IssueTeamWorkspaceID)
  )
  const taskIDs = useAppSelector((state) =>
    selectWorkspaceTaskIds(state, IssueTeamWorkspaceID)
  )

  const userIsAzureADLogin = useAppSelector(isAzureADLogin)

  const participantCount = useAppSelector((state) =>
    selectWorkspaceParticipantCount(state, IssueTeamWorkspaceID)
  )
  const artifactCount = useAppSelector((state) =>
    selectWorkspaceArtifactCount(state, IssueTeamWorkspaceID)
  )
  const linksCount = useAppSelector((state) =>
    selectIssueEvidenceLockerLinksCount(state, IssueID)
  )
  const stakeholderCount = useAppSelector((state) =>
    selectIssueAllStakeholderCount(state, IssueID)
  )
  const teamReportCount = useAppSelector((state) =>
    selectIssueTeamReportCount(state, IssueID)
  )

  const updateFormCount = useAppSelector((state) =>
    selectWorkspaceUpdateFormCount(state, IssueTeamWorkspaceID)
  )
  const chatCount: number = useAppSelector((state) =>
    selectWorkspaceAllChatCounts(state, IssueTeamWorkspaceID)
  )

  const directChatList = useAppSelector((state) =>
    selectWorkspaceDirectMessages(state, IssueTeamWorkspaceID)
  )

  const msTeamsCalendarEventsCount = useAppSelector((state) =>
    selectMSCalendarEventsCount(state)
  )

  const directChatUnread = useAppSelector((state) => {
    if (!directChatList || directChatList[0] === undefined) return
    for (const chat of directChatList) {
      let isChatUnread = selectIsChatUnread(state, chat.ChatID)
      if (isChatUnread) {
        return true
      }
    }
    return false
  })

  const publicChatUnread = useAppSelector((state) => {
    const chats = chatIDs?.filter(
      (id: number) => selectChatById(state, id) !== undefined
    )
    if (chats?.length > 1) {
      chats.sort()
    }

    return selectIsChatUnread(state, chats?.length > 0 ? chats[0] : {})
  })

  const isTaskUnread = useAppSelector((state) =>
    selectIsTaskUnread(state, taskIDs?.length > 0 ? taskIDs[0] : {})
  )

  const storeTaskList = useAppSelector((state) =>
    selectTaskAllItemIds(state, taskIDs?.length > 0 ? taskIDs[0] : {})
  )

  const conferenceIDList = useAppSelector((state) =>
    selectWorkspaceConferenceIds(state, IssueTeamWorkspaceID)
  )
  const hasAdvisory = useAppSelector((state) =>
    isIssueAdvisoryCreated(state, IssueID)
  )
  const { LevelName } =
    useAppSelector((state) => selectIssueCurrentIssueLevel(state, IssueID)) ||
    {}
  const isApprover = useAppSelector((state) =>
    isUserIssueApprover(state, IssueID)
  )
  const isAdmin = useAppSelector((state) =>
    isUserIssueAdminister(state, IssueID)
  )

  const issue = useAppSelector((state) =>
    selectIssueById(state, IssueID as any)
  )
  const isIssueNullUndefined = useMemo(
    () =>
      issue === null ||
      issue === undefined ||
      issue.Settings === null ||
      issue.Settings === undefined,
    [issue]
  )
  const evidenceLockerCustomName =
    issue?.Settings?.find((isv: any) => isv.SettingType === 'L')?.SettingYN ===
    'Y'

  const showProvideMoreInformation = isIssueNullUndefined
    ? 'Y'
    : issue.Settings.find((isv: any) => isv.SettingType === 'M')?.SettingYN
  const showConferences = isIssueNullUndefined
    ? 'Y'
    : issue.Settings.find((isv: any) => isv.SettingType === 'C')?.SettingYN

  const menuAnchor = useRef(null)
  const listItemHover = isMobile ? '' : issueDetailMenuClasses.listItem

  const history = useHistory()

  useEffect(() => {
    if (userIsAzureADLogin && UserIsExecutiveYN !== 'Y') {
      dispatch(setGraphCalendarEventsWorkspace(IssueTeamWorkspaceID))
      dispatch(fetchGraphCalendarEvents.request({ IssueTeamWorkspaceID }))
    }
  }, [userIsAzureADLogin, UserIsExecutiveYN, dispatch, IssueTeamWorkspaceID])

  useEffect(() => {
    function fetchFormsCount() {
      dispatch(
        fetchUpdateIssueFormsCount(
          {
            IssueID,
            IssueTeamWorkspaceID,
          },
          {
            icoRequestId: IssueTeamWorkspaceID,
            IssueTeamWorkspaceID: IssueTeamWorkspaceID,
          }
        )
      )
    }

    //This is all a bit of a mess but the way shaun is handling this code right now is not able to grab before clicking from what I can tell.
    //So I am retrieving it alone from the DB if its not populated in the store.

    if (taskIDs?.length > 0) {
      getTaskListForTask(taskIDs[0]).then((result) => {
        if (result.returnData?.length) {
          if (result.returnData[0]) {
            if (
              result.returnData[0].TaskItem?.length &&
              result.returnData[0].TaskItem[0].TaskItemID
            ) {
              const nonHeader = result.returnData[0].TaskItem?.filter(
                (taskItem: any) =>
                  taskItem.TaskItemHeaderYN === 'N' ||
                  !taskItem.TaskItemHeaderYN ||
                  taskItem.TaskItemHeaderYN === ' '
              )
              setTaskListCount(nonHeader?.length)
            } else {
              setTaskListCount(storeTaskList?.length)
            }
          }
        }
      })
    }

    fetchFormsCount()
  }, [IssueTeamWorkspaceID, IssueID, dispatch, taskIDs, storeTaskList])

  useEffect(() => {
    setIsResolved(IssueResolved ? true : false)
  }, [IssueResolved])

  // handlers
  const handleMenuItemClick = useCallback(
    (clickedItem: string) => {
      if (itemSelected) {
        itemSelected(clickedItem)
      }
    },
    [itemSelected]
  )

  const isDialogOpen = useCallback(
    (name: string) => {
      return formDialog === name
    },
    [formDialog]
  )

  const handleChangeLevelClick = useCallback(() => {
    if (formDialog !== dialogNames.changeLevel) {
      dispatch(fetchChangeLevelFormContent({ IssueTeamWorkspaceID }))
      setFormDialog(dialogNames.changeLevel)
    }
  }, [dispatch, IssueTeamWorkspaceID, formDialog])

  const handleDeleteClick = useCallback(() => {
    if (formDialog !== dialogNames.deleteIssue) {
      setFormDialog(dialogNames.deleteIssue)
    }
  }, [formDialog])

  const handleDeleteConfirmClick = useCallback(() => {
    setFormDialog('')
    dispatch(IssueActions.delete({ IssueID }))
    history.push('/Issues')
  }, [IssueID, dispatch, history])

  const handleResolveIssueClick = useCallback(() => {
    if (formDialog !== dialogNames.resolveIssue) {
      dispatch(fetchResolveIssueFormContent({ IssueTeamWorkspaceID }))
      setFormDialog(dialogNames.resolveIssue)
    }
  }, [IssueTeamWorkspaceID, dispatch, formDialog])

  const handleOpenIssueClick = useCallback(() => {
    if (formDialog !== dialogNames.openIssue) {
      dispatch(fetchOpenIssueFormContent({ IssueTeamWorkspaceID }))
      setFormDialog(dialogNames.openIssue)
    }
  }, [dispatch, IssueTeamWorkspaceID, formDialog])

  const handleIssueMenuClick = useCallback(
    (event) => {
      menuAnchor.current = event?.target
      setIssueMenuOpened(true)
    },
    [setIssueMenuOpened]
  )

  const handleClearFormDialog = useCallback(() => {
    setFormDialog('')
  }, [])

  const handleCloneIssueOpen = useCallback(() => {
    setCloneDialogOpened(true)
  }, [])

  const handleCloneIssueClose = useCallback(
    (_: any, reason: 'backdropClick' | 'escapeKeyDown' | 'userSaved') => {
      if (reason !== 'backdropClick') {
        setCloneDialogOpened(false)
        setIssueMenuOpened(false) // close the parent menu
      }
    },
    []
  )
  const handleChangeModeOpen = useCallback(() => {
    setChangeModeDialogOpened(true)
  }, [])
  const handleChangeModeClose = useCallback(
    (_: any, reason: 'backdropClick' | 'escapeKeyDown' | 'userSaved') => {
      if (reason !== 'backdropClick') {
        setChangeModeDialogOpened(false)
        setIssueMenuOpened(false) // close the parent menu
      }
    },
    []
  )
  const handleChangeTemplateFlagOpen = useCallback(() => {
    setChangeTemplateFlagDialogOpened(true)
  }, [])
  const handleChangeTemplateFlagClose = useCallback(
    (_: any, reason: 'backdropClick' | 'escapeKeyDown' | 'userSaved') => {
      if (reason !== 'backdropClick') {
        setChangeTemplateFlagDialogOpened(false)
        setIssueMenuOpened(false) // close the parent menu
      }
    },
    []
  )
  const showEvidenceLocker = isIssueNullUndefined
    ? 'Y'
    : issue.Settings.find((isv: any) => isv.SettingType === 'A')?.SettingYN
  const showHoldingStatements = isIssueNullUndefined
    ? 'Y'
    : issue.Settings.find((isv: any) => isv.SettingType === 'H')?.SettingYN
  const showNotifyStakeholders = isIssueNullUndefined
    ? 'Y'
    : issue.Settings.find((isv: any) => isv.SettingType === 'N')?.SettingYN
  const showScribeNotes = isIssueNullUndefined
    ? 'Y'
    : issue.Settings.find((isv: any) => isv.SettingType === 'B')?.SettingYN
  const showAssessments = isIssueNullUndefined
    ? 'Y'
    : issue.Settings.find((isv: any) => isv.SettingType === 'S')?.SettingYN
  const showExecutiveBriefing = isIssueNullUndefined
    ? 'Y'
    : issue.Settings.find((isv: any) => isv.SettingType === 'E')?.SettingYN
  const showIssuePolicies = isIssueNullUndefined
    ? 'Y'
    : issue.Settings.find((isv: any) => isv.SettingType === 'V')?.SettingYN

  // renderers

  const renderRecentConferenceInstructions = useCallback(() => {
    const conf: any = nextConference ? nextConference : recentPastDueConference

    if (conf) {
      return (
        <div className={issueDetailMenuClasses.divsecondary}>
          {conf.Phone ? (
            <p className={issueDetailMenuClasses.psecondary}>
              {'Phone: ' + conf.Phone}
            </p>
          ) : (
            ''
          )}
          {conf.Link ? (
            <p className={issueDetailMenuClasses.psecondary}>
              {'Link: ' + conf.Link}
            </p>
          ) : (
            ''
          )}
        </div>
      )
    } else return ''
  }, [nextConference, recentPastDueConference])
  const handleIssueMenuClose = useCallback(() => {
    setIssueMenuOpened(false)
    menuAnchor.current = null
  }, [])
  const renderMenu = useCallback(
    (input: {
      isMobile: boolean
      options: {
        open?: boolean
        onClose: () => void
        content: JSX.Element
        anchorEl: any
        disablePadding?: boolean
      }
    }) => {
      const { open, onClose, content, anchorEl, disablePadding } = input.options
      return input.isMobile ? (
        <Drawer
          anchor="right"
          open={open}
          onClose={onClose}
          className={cx(menuClasses.drawer, menuClasses.mobile)}
          style={{ maxWidth: '350px' }}
        >
          {content}
        </Drawer>
      ) : (
        <Menu
          open={open ? true : false}
          onClose={onClose}
          anchorEl={anchorEl}
          classes={{ paper: menuClasses.drawer }}
          keepMounted
          MenuListProps={{ disablePadding: disablePadding }}
          style={{ maxWidth: '360px' }}
        >
          <div>{content}</div>
        </Menu>
      )
    },
    []
  )

  let menuElements: Array<MenuElement> = [
    {
      menuComponent: {
        itemTitle: 'Change Severity',
        onClick: handleChangeLevelClick,
        hideMenuElement: !isApprover,
        style: { flexBasis: 1 },
      },
      fontAwesomeIcon: faRetweet,
      itemLocations: ['dropdown'],
    },
    {
      menuComponent: {
        itemTitle: !isApprover
          ? 'Must be an approver for this action'
          : isResolved
          ? 'Reopen Issue'
          : 'Resolve Issue',
        onClick: isResolved ? handleOpenIssueClick : handleResolveIssueClick,
        hideMenuElement: !isApprover,
        style: { flexBasis: 1 },
      },
      fontAwesomeIcon: isResolved ? faCircle : faBan,
      itemLocations: ['dropdown'],
    },
    {
      itemName: 'participants',
      menuComponent: {
        itemTitle: 'Participants',
        count: participantCount,
        hideMenuElement: UserIsExecutiveYN === 'Y',
      },
      fontAwesomeIcon: faUserPlus,
      itemLocations: ['header'],
    },
    {
      menuComponent: {
        itemTitle: 'Other Actions',
        onClick: handleIssueMenuClick,
        style: { flexBasis: 1 },
        hideMenuElement: !isApprover,
      },
      fontAwesomeIcon: faEllipsisV,
      itemLocations: ['header'],
    },
    {
      itemName: 'summary',
      menuComponent: {
        id: createDomID('menuItem-issueHeader'),
        classNames: [issueDetailMenuClasses.summaryItem],
        itemTitle: 'Issue: ' + IssueName,
        content: <IssueDetailMenuHeader IssueID={IssueID} />,
      },
      fontAwesomeIcon: faHome,
      itemLocations: ['big menu'],
    },
    {
      itemName: 'activitylog',
      menuComponent: {
        id: createDomID('menuItem-activityLog'),
        itemTitle: 'Activity Log',
        icon: (
          <Badge
            overlap="rectangular"
            color="primary"
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            invisible={!hasNewActivityLog}
            variant="dot"
          >
            <FontAwesomeIcon
              icon={faListUl}
              size="lg"
              className={issueDetailMenuClasses.icon}
            />
          </Badge>
        ),
        hideMenuElement: UserIsExecutiveYN === 'Y',
      },
      fontAwesomeIcon: faListUl,
      itemLocations: ['big menu'],
    },
    {
      itemName: 'execbrief',
      menuComponent: {
        itemTitle: 'Executive Briefing',
        id: createDomID('menuItem-execbrief'),
        count: briefingsCount,
        hideMenuElement:
          !(issue.UserIsExecutiveYN === 'Y' || issue.UserIsAdminYN === 'Y') ||
          showExecutiveBriefing === 'N',
      },
      fontAwesomeIcon: faFileChartLine,
      itemLocations: ['big menu'],
    },
    {
      itemName: 'assessments',
      menuComponent: {
        itemTitle: 'Assessments',
        id: createDomID('menuItem-assessments'),
        count: assessmentsCount,
        hideMenuElement:
          showAssessments === 'N' ||
          UserIsReadOnlyYN === 'Y' ||
          UserIsExecutiveYN === 'Y',
      },
      fontAwesomeIcon: faFileCheck,
      itemLocations: ['big menu'],
    },
    {
      itemName: evidenceLockerCustomName ? 'filesformslinks' : 'evidencelocker',
      menuComponent: {
        itemTitle: evidenceLockerCustomName
          ? 'Files, Forms and Links'
          : 'Evidence Locker',
        id: evidenceLockerCustomName
          ? createDomID('menuItem-filesformslinks')
          : createDomID('menuItem-evidencelocker'),
        count: artifactCount + teamReportCount + linksCount,
        hideMenuElement:
          UserIsExecutiveYN === 'Y' || showEvidenceLocker === 'N',
      },
      fontAwesomeIcon: faShopLock,
      itemLocations: ['big menu'],
    },
    {
      itemName: 'policies',
      menuComponent: {
        itemTitle: 'Policies',
        count: policiesCount,
        id: createDomID('menuItem-policies'),
        hideMenuElement: UserIsExecutiveYN === 'Y' || showIssuePolicies === 'N',
      },
      fontAwesomeIcon: faBooks,
      itemLocations: ['big menu'],
    },
    // {
    //   itemName: 'teamForms',
    //   menuComponent: {
    //     itemTitle: 'View Team Forms',
    //     id: createDomID('menuItem-teamForms'),
    //     hideMenuElement:
    //       showTeamFormListing === 'N' || UserIsExecutiveYN === 'Y',
    //   },
    //   fontAwesomeIcon: faClipboardUser,
    //   itemLocations: ['big menu'],
    // },
    {
      itemName: 'filloutforms',
      menuComponent: {
        id: createDomID('menuItem-provideInfo'),
        itemTitle: 'Forms',
        count: updateFormCount,
        hideMenuElement:
          UserIsReadOnlyYN === 'Y' ||
          showProvideMoreInformation === 'N' ||
          //updateFormCount === 0 ||    this was requeted on asana task https://app.asana.com/0/78008930886671/1208403681731710/f
          UserIsExecutiveYN === 'Y',
      },
      fontAwesomeIcon: faClipboard,
      itemLocations: ['big menu'],
    },
    {
      itemName: 'holdingstatement',
      menuComponent: {
        id: createDomID('menuItem-holdingStatement'),
        itemTitle: 'Holding Statement',
        hideMenuElement:
          UserIsExecutiveYN === 'Y' || showHoldingStatements === 'N',
      },
      fontAwesomeIcon: faFileAlt,
      itemLocations: ['big menu'],
    },
    {
      itemName: 'stakeholders',
      menuComponent: {
        id: createDomID('menuItem-stakeholder'),
        itemTitle: 'Notify Stakeholders',
        extraContent: hasAdvisory ? (
          <AdvisoryMUI
            className={cx(
              classes.advisoryIcon,
              compact ? issueDetailMenuClasses.compact : ''
            )}
          />
        ) : (
          <></>
        ),
        count: stakeholderCount,
        hideMenuElement:
          (!isAdmin && !isApprover) || showNotifyStakeholders === 'N',
      },
      fontAwesomeIcon: faUsers,
      itemLocations: ['big menu'],
    },
    {
      itemName: 'meetings',
      menuComponent: {
        id: createDomID('menuItem-meetings'),
        itemTitle: 'Meetings',
        description: renderRecentConferenceInstructions(),
        count: conferenceIDList?.length ? conferenceIDList?.length : undefined,
        hideMenuElement: showConferences === 'N' || UserIsExecutiveYN === 'Y',
      },
      fontAwesomeIcon: faVideo,
      itemLocations: ['big menu'],
    },

    {
      itemName: 'microsoftconference',
      menuComponent: {
        id: createDomID('menuItem-msTeamsConferences'),
        itemTitle: 'MS Teams Meetings',
        hideMenuElement: !userIsAzureADLogin || UserIsExecutiveYN === 'Y',
        count: msTeamsCalendarEventsCount,
      },
      fontAwesomeIcon: faPhoneAlt,
      itemLocations: ['big menu'],
    },
    {
      itemName: 'scribe',
      menuComponent: {
        itemTitle: 'Scribe Notes',
        id: createDomID('menuItem-scribe'),
        hideMenuElement: showScribeNotes === 'N' || UserIsExecutiveYN === 'Y',
        count: scribeNotesCount,
      },
      fontAwesomeIcon: faCalendarLinesPen,
      itemLocations: ['big menu'],
    },
    {
      itemName: 'tasks',
      menuComponent: {
        id: createDomID('menuItem-taskList'),
        itemTitle: 'Tasks',
        count: taskListCount,
        icon: (
          <Badge
            color="primary"
            overlap="rectangular"
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            invisible={!isTaskUnread}
            variant="dot"
            className={issueDetailMenuClasses.icon}
          >
            <FontAwesomeIcon
              icon={faClipboardList}
              size="lg"
              className={issueDetailMenuClasses.icon}
            />
          </Badge>
        ),
        hideMenuElement: UserIsExecutiveYN === 'Y',
      },
      fontAwesomeIcon: faClipboardList,
      itemLocations: ['big menu'],
    },
    {
      itemName: 'chats',
      menuComponent: {
        id: createDomID('menuItem-chat'),
        itemTitle: 'Chats',
        count: chatCount,
        icon: (
          <Badge
            color={directChatUnread ? 'secondary' : 'primary'}
            overlap="rectangular"
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            invisible={!publicChatUnread && !directChatUnread}
            variant="dot"
            className={issueDetailMenuClasses.icon}
          >
            <FontAwesomeIcon
              icon={faCommentDots}
              size="lg"
              className={issueDetailMenuClasses.icon}
            />
          </Badge>
        ),
        hideMenuElement: UserIsExecutiveYN === 'Y',
      },
      fontAwesomeIcon: faCommentDots,
      itemLocations: ['big menu'],
    },
    {
      menuComponent: {
        itemTitle: 'Change Mode',
        onClick: handleChangeModeOpen,
        hideMenuElement: !isAdmin,
        style: !isApprover
          ? { flexBasis: 1, disabled: true }
          : { flexBasis: 1 },
      },
      fontAwesomeIcon: faExchangeAlt,
      itemLocations: ['dropdown'],
    },
    {
      menuComponent: {
        itemTitle: 'Copy Issue',
        onClick: handleCloneIssueOpen,
        hideMenuElement: !isAdmin,
        style: !isApprover
          ? { flexBasis: 1, disabled: true }
          : { flexBasis: 1 },
      },
      fontAwesomeIcon: faClone,
      itemLocations: ['dropdown'],
    },
    {
      menuComponent: {
        itemTitle: 'Convert to Template',
        onClick: handleChangeTemplateFlagOpen,
        hideMenuElement: !isAdmin,
        style: !isApprover
          ? { flexBasis: 1, disabled: true }
          : { flexBasis: 1 },
      },
      fontAwesomeIcon: faFilePlus,
      itemLocations: ['dropdown'],
    },
    {
      menuComponent: {
        itemTitle: isApprover
          ? 'Delete Issue'
          : 'Must be an approver for this action',
        onClick: handleDeleteClick,
        hideMenuElement: !isApprover,
        icon: (
          <FontAwesomeIcon
            icon={faTrash}
            size="lg"
            className={cx(
              issueDetailMenuClasses.icon,
              issueDetailMenuClasses.danger
            )}
          />
        ),
        style: !isApprover
          ? {
              flexBasis: 1,
              borderTop: '1px lightgrey solid',
              paddingTop: '16px',
              marginTop: '8px',
              disabled: true,
            }
          : {
              flexBasis: 1,
              borderTop: '1px lightgrey solid',
              paddingTop: '16px',
              marginTop: '8px',
            },
      },
      fontAwesomeIcon: faTrash,
      itemLocations: ['dropdown'],
    },
  ]
  const headerElements = menuElements.filter((element) =>
    element.itemLocations.includes('header')
  )
  const bigMenuElements = menuElements.filter((element) =>
    element.itemLocations.includes('big menu')
  )
  const dropownElements = menuElements.filter((element) =>
    element.itemLocations.includes('dropdown')
  )

  return (
    <div className={issueDetailMenuClasses.root}>
      <List component="div" className={classes.issueMenuContainer}>
        <div
          className={classes.menuItem}
          key="idmKey1"
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
            padding: '16px',
            paddingTop: '8px',
          }}
        >
          <b>Issue: {IssueName}</b>
          <Spacer />
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
            }}
          >
            {headerElements.map((menuElement, melemIdx) => {
              if (menuElement.menuComponent.hideMenuElement) {
                return null
              }
              const handleClick = (e: any) => {
                if (menuElement.menuComponent.onClick) {
                  menuElement.menuComponent.onClick(e)
                } else {
                  handleMenuItemClick(
                    menuElement.itemName ? menuElement.itemName : ''
                  )
                }
              }
              if (menuElement.menuComponent.icon) {
                return (
                  <Tooltip
                    key={melemIdx}
                    placement="top"
                    title={menuElement.menuComponent.itemTitle}
                  >
                    <span
                      onClick={handleClick}
                      key={menuElement.itemName}
                      style={{ marginLeft: '8px' }}
                      className={listItemHover}
                    >
                      {menuElement.menuComponent.icon}
                    </span>
                  </Tooltip>
                )
              } else {
                return (
                  <Tooltip
                    key={melemIdx}
                    placement="top"
                    title={menuElement.menuComponent.itemTitle}
                  >
                    <span className={listItemHover}>
                      <FontAwesomeIcon
                        onClick={handleClick}
                        icon={
                          menuElement.menuComponent.icon
                            ? menuElement.menuComponent.icon
                            : menuElement.fontAwesomeIcon
                        }
                        size="lg"
                        style={{
                          flexBasis: '1',
                          cursor: 'pointer',
                          marginLeft: '12px',
                        }}
                        className={issueDetailMenuClasses.icon}
                        key={menuElement.itemName}
                      />
                    </span>
                  </Tooltip>
                )
              }
            })}
          </div>
        </div>

        {bigMenuElements.map((menuElement, idx) => {
          if (menuElement.menuComponent.hideMenuElement) {
            return null
          }

          return (
            <IssueDetailMenuItem
              id={
                menuElement.menuComponent.id
                  ? menuElement.menuComponent.id
                  : createDomID(`menuItem-${menuElement.itemName}`)
              }
              component="div"
              className={cx(
                classes.menuItem,
                menuElement.menuComponent.classNames
                  ? menuElement.menuComponent.classNames?.join(', ')
                  : ''
              )}
              style={menuElement.menuComponent.style}
              itemTitle={menuElement.menuComponent.itemTitle}
              content={menuElement.menuComponent.content}
              extraContent={menuElement.menuComponent.extraContent}
              description={menuElement.menuComponent.description}
              count={menuElement.menuComponent.count}
              key={idx}
              itemKey={menuElement.itemName ? menuElement.itemName : 'summary'}
              onClick={
                menuElement.menuComponent.onClick
                  ? menuElement.menuComponent.onClick
                  : handleMenuItemClick
              }
              compact={compact}
              icon={
                menuElement.menuComponent.icon ? (
                  menuElement.menuComponent.icon
                ) : (
                  <FontAwesomeIcon
                    icon={menuElement.fontAwesomeIcon}
                    size="lg"
                    className={issueDetailMenuClasses.icon}
                  />
                )
              }
              selected={selected === menuElement.itemName}
            />
          )
        })}
      </List>
      <ChangeLevelFormContentDialog
        open={isDialogOpen(dialogNames.changeLevel)}
        onClose={handleClearFormDialog}
      />
      <IssueResolveDialog
        IssueID={IssueID}
        open={isDialogOpen(dialogNames.resolveIssue)}
        onClose={handleClearFormDialog}
      />
      <ReopenIssueFormContentDialog
        open={isDialogOpen(dialogNames.openIssue)}
        fldval={[
          {
            FieldName: 'title-of-issue',
            Value: IssueName,
          },
          {
            FieldName: 'description-of-issue',
            Value: Description,
          },
          { FieldName: 'severity', Value: LevelName },
        ]}
        onClose={handleClearFormDialog}
      />
      <IssueCloneDialog
        IssueID={IssueID}
        open={cloneDialogOpened}
        onClose={handleCloneIssueClose}
      />
      <IssueChangeModeDialog
        IssueID={IssueID}
        open={changeModeDialogOpened}
        onClose={handleChangeModeClose}
      />
      <IssueChangeTemplateFlagDialog
        IssueID={IssueID}
        open={changeTemplateFlagDialogOpened}
        onClose={handleChangeTemplateFlagClose}
      />
      <IssueChangeTemplateFlagDialog
        IssueID={IssueID}
        open={changeTemplateFlagDialogOpened}
        onClose={handleChangeTemplateFlagClose}
      />
      <ConfirmDialog
        open={isDialogOpen(dialogNames.deleteIssue)}
        title="Are you sure you want to delete?"
        confirmText="Yes"
        cancelText="No"
        content="You are about to delete this issue. A deletion cannot be undone. Do you wish to continue?"
        confirm={handleDeleteConfirmClick}
        cancel={handleClearFormDialog}
      />
      {renderMenu({
        isMobile: isMobile,
        options: {
          open: issueMenuOpened,
          anchorEl: menuAnchor.current,
          onClose: handleIssueMenuClose,
          content: (
            <List
              className={issueDetailMenuClasses.root}
              style={{ padding: '12px', paddingTop: isMobile ? '6px' : '12px' }}
            >
              {isMobile && (
                <span className={issueDetailMenuClasses.reverseFlexRow}>
                  <Button
                    color="standard"
                    className={issueDetailMenuClasses.close}
                    aria-label="Close"
                    onClick={handleIssueMenuClose}
                  >
                    <span>×</span>
                  </Button>
                </span>
              )}
              {dropownElements.map((menuElement, melmIdx) => {
                if (menuElement.menuComponent.hideMenuElement) {
                  return null
                }
                const handleClick = (e: any) => {
                  if (menuElement.menuComponent.onClick) {
                    menuElement.menuComponent.onClick(e)
                  } else {
                    handleMenuItemClick(
                      menuElement.itemName ? menuElement.itemName : ''
                    )
                  }
                  handleIssueMenuClose()
                }
                if (menuElement.menuComponent.icon) {
                  return (
                    <ListItem
                      style={{
                        ...menuElement.menuComponent.style,
                        cursor: 'pointer',
                      }}
                      onClick={handleClick}
                      key={melmIdx}
                      className={listItemHover}
                    >
                      <div className={issueDetailMenuClasses.iconBox}>
                        {menuElement.menuComponent.icon}
                      </div>
                      <Spacer spaceParam="large" />
                      <span>{menuElement.menuComponent.itemTitle}</span>
                    </ListItem>
                  )
                } else {
                  return (
                    <ListItem
                      style={{
                        ...menuElement.menuComponent.style,
                        cursor: 'pointer',
                      }}
                      onClick={handleClick}
                      key={melmIdx}
                      className={listItemHover}
                    >
                      <div className={issueDetailMenuClasses.iconBox}>
                        <FontAwesomeIcon
                          className={issueDetailMenuClasses.icon}
                          icon={menuElement.fontAwesomeIcon}
                          size="lg"
                        />
                      </div>
                      <Spacer spaceParam="large" />
                      <span>{menuElement.menuComponent.itemTitle}</span>
                    </ListItem>
                  )
                }
              })}
            </List>
          ),
        },
      })}
    </div>
  )
}
