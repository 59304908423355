import React, { useCallback, useEffect, useRef } from 'react'
import 'bootstrap/dist/css/bootstrap.css'
import { Button } from '../Shared/Buttons'
import classes from './Alerts.module.scss'
import { Paper, Switch } from '@material-ui/core'
import { cx } from '../_utils/objectUtils'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons'
import { MouseEvent } from 'react'
import { ManageInbox } from './ManageInbox'
import { CircularProgress, LoadingBackDrop } from '../Shared/LoadingBackdrop'
import { Parens } from '../Shared/Parens'
import { AddButton } from '../Shared/Buttons'
import { Grid } from '@material-ui/core'
import { InboxModel } from './_models'
import { useDispatch } from 'react-redux'
import { alertFilterSave, fetchInboxes } from './_actions'
import { useAppSelector } from '../_utils/reactReduxHooks'
import { selectAlertFilters, selectInboxes } from './_selectors'
import {
  isInboxLoading,
  isUpdatingInbox,
  isDeletingInbox,
  isCreatingInbox,
} from '../Shared/_requestStatusSelectors'
import { AutoFocus } from '../Shared/AutoFocus'
import { useParams, useHistory } from 'react-router'
import { AlertsRouteParams } from '../Routing/RouteProps'
import { Spacer } from '../Shared/Spacer'
import { faDesktop } from '@fortawesome/pro-solid-svg-icons'
import { selectIsMicrosoftTeams } from '../MicrosoftTeams/_selectors'
import { isMobileApp } from '../_selectors'

export const InboxList = () => {
  const [createNewInboxToggled, setCreateNewInboxToggled] =
    React.useState(false)
  const { inboxGUID } = useParams<AlertsRouteParams>()
  const history = useHistory()
  const dispatch = useDispatch()
  const inboxes = useAppSelector(selectInboxes)
  const inboxesLoading = useAppSelector(isInboxLoading)
  const isCreating = useAppSelector(isCreatingInbox)
  const isDeleting = useAppSelector(isDeletingInbox)
  const isUpdating = useAppSelector(isUpdatingInbox)
  const isMsTeams = useAppSelector(selectIsMicrosoftTeams)
  const isMobile = useAppSelector(isMobileApp)
  const prevInboxesLoading = React.useRef(false)

  const alertFilters = useAppSelector(selectAlertFilters)
  const [showInactive, setShowInactive] = React.useState(
    alertFilters?.inactiveFilter
  )

  const shouldDispatchNewAlertFilter = useRef(false)

  useEffect(() => {
    dispatch(fetchInboxes.request())
  }, [dispatch])

  useEffect(() => {
    if (shouldDispatchNewAlertFilter.current) {
      dispatch(
        alertFilterSave({ ...alertFilters, inactiveFilter: showInactive })
      )
      shouldDispatchNewAlertFilter.current = false
    }
  }, [alertFilters, dispatch, showInactive])

  useEffect(() => {
    if (
      prevInboxesLoading.current &&
      !inboxesLoading &&
      !inboxGUID &&
      inboxes.length > 0 &&
      !isMsTeams &&
      !isMobile
    ) {
      history.push(`/Alert/Inbox/AllAlerts`)
    }
    prevInboxesLoading.current = inboxesLoading
  }, [inboxesLoading, history, inboxGUID, inboxes, isMsTeams, isMobile])

  const clickInbox = useCallback(
    (evt: MouseEvent, clickedInboxGUID: string) => {
      evt.preventDefault()
      if (inboxGUID !== clickedInboxGUID) {
        history.push(`/Alert/Inbox/${clickedInboxGUID}`)
      }
    },
    [history, inboxGUID]
  )
  const createInbox = useCallback((evt: MouseEvent) => {
    evt.preventDefault()
    setCreateNewInboxToggled(true)
  }, [])

  const closeCreateInbox = useCallback(() => {
    setCreateNewInboxToggled(false)
  }, [])

  const renderLogo = useCallback((inbox: InboxModel) => {
    const feedLogo =
      inbox.feeds[0]?.feedLogo?.length > 0
        ? inbox.feeds[0].feedLogo
        : 'https://upload.wikimedia.org/wikipedia/en/thumb/4/43/Feed-icon.svg/128px-Feed-icon.svg.png'
    return feedLogo || inbox.fileName ? (
      <div style={{ width: '65px' }}>
        <img
          aria-label="inboxLogo"
          className={classes.feedLogo}
          src={
            inbox.graphic ? 'data:image/png;base64,' + inbox.graphic : feedLogo
          }
          alt="Inbox logo"
          style={{ height: '25px' }}
        />
      </div>
    ) : (
      ''
    )
  }, [])

  const compiledInboxes = useCallback(() => {
    return (
      <>
        <Spacer spaceParam={'large'} />
        <div style={{ display: 'flex' }}>
          <span style={{ fontSize: '2rem', width: '100%', margin: '0 auto' }}>
            Inboxes
          </span>
          <div style={{ display: 'flex' }}>
            <div>Show Inactive </div>
            <Switch
              checked={showInactive}
              color="primary"
              onClick={() => {
                shouldDispatchNewAlertFilter.current = true
                setShowInactive((prev) => !prev)
              }}
              inputProps={{
                'aria-label': 'controlled',
              }}
            />
          </div>
        </div>

        <Spacer spaceParam={'large'} />
        <Spacer spaceParam={'large'} />
        <div
          className={classes.inboxItem}
          style={{
            fontSize: '1rem',
            display: 'flex',
            justifyContent: 'flex-start',
          }}
          onClick={(e) => clickInbox(e, 'AllAlerts')}
        >
          <div
            style={{ width: '65px', fontSize: '1rem' }}
            className={classes.iconWrapper}
          >
            <FontAwesomeIcon icon={faDesktop} />
          </div>
          <Spacer />
          <div
            className={cx(
              classes.inboxItemName,
              inboxGUID === 'AllAlerts' ? classes.inboxSelected : ''
            )}
          >
            All Alerts
          </div>
        </div>
        <Spacer />
        <div style={{ borderBottom: 'solid 1px lightgrey' }} />
      </>
    )
  }, [clickInbox, inboxGUID, showInactive])

  const renderAddInbox = useCallback(() => {
    return (
      <div
        className={cx(classes.clickBox)}
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
        }}
      >
        <h4>Configured Inboxes</h4>
        <Button
          id="createInbox"
          onClick={createInbox}
          className={cx(classes.btnClear, 'btn btn-clear')}
        >
          <FontAwesomeIcon
            className={cx(classes.btnCreateInbox, classes.feedItemIcon)}
            id="createInboxIcon"
            icon={faPlusCircle}
          />
        </Button>
      </div>
    )
  }, [createInbox])

  const renderInboxList = useCallback(
    () =>
      inboxes.map((inbox: InboxModel, index: number) => {
        if (!showInactive && inbox.IsActiveYN === 'N') return null

        const inboxSelected = inbox.inboxGUID === inboxGUID
        // const inactiveColors = { color: inboxSelected ? 'lightgray' : 'gray' }
        return (
          <React.Fragment key={index}>
            <div
              className={cx(classes.inboxItem)}
              style={{ display: 'flex', justifyContent: 'space-between' }}
              onClick={(e) => clickInbox(e, inbox.inboxGUID)}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  width: '100%',
                }}
              >
                {renderLogo(inbox)}
                <Spacer />
                <div
                  className={cx(
                    classes.inboxItemName,
                    inboxSelected
                      ? classes.inboxSelected
                      : inbox.IsActiveYN === 'N'
                      ? classes.inactiveColor
                      : ''
                  )}
                >
                  {inbox.inboxName}
                </div>
              </div>
            </div>
            {inbox.inboxGUID === inboxGUID && <AutoFocus />}
          </React.Fragment>
        )
      }),
    [inboxes, showInactive, renderLogo, inboxGUID, clickInbox]
  )

  return (
    <Paper className={cx('container-fluid', 'h-100', 'overflow-auto')}>
      <LoadingBackDrop
        text={isDeleting ? 'Deleting...' : 'Saving...'}
        open={isCreating || isUpdating || isDeleting ? true : false}
        className="position-absolute h-100 w-100"
        zIndex={99}
      />
      {inboxesLoading ? (
        <CircularProgress />
      ) : inboxes.length > 0 ? (
        <>
          {compiledInboxes()}
          <Spacer spaceParam="large" />
          {renderAddInbox()}
          {renderInboxList()}
        </>
      ) : (
        <>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <div className={cx(classes.noIssuesFound)}>
                <p>No Inboxes have been configured. </p>
                <p>
                  To set up a new Inbox for inbound alerts, select the plus sign{' '}
                  <Parens>
                    <AddButton onClick={createInbox} id={'0'} size="small" />
                  </Parens>{' '}
                  and provide the necessary information.
                </p>
                <p>
                  Note: You must have the Administrator Privilege to create and
                  edit an Inbox for your organization.
                </p>
              </div>
            </Grid>
          </Grid>
        </>
      )}
      {createNewInboxToggled && (
        <ManageInbox
          key="0"
          inboxGUID="0"
          onClose={closeCreateInbox}
          isDialogOpen={createNewInboxToggled}
        />
      )}
    </Paper>
  )
}
