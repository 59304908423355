import * as React from 'react'
import { http } from '../_utils/http'
import { CircularProgress } from '../Shared/LoadingBackdrop'
import { HiddenFormWithValues } from '../Playbook/HiddenFormWithValues'

interface Props {
  TaskGUID: string
  className?: string
  onDataLoaded?: (playbooks: any) => void
}
//export interface PbTaskDetailProps extends Props { };

interface EventParams {
  PubGUID?: string
  RefPubGUID?: string
  EventID?: number
  adg?: string
}

export const PbTaskDetail = ({ TaskGUID, className, onDataLoaded }: Props) => {
  const [playbooks, setPlaybooks] = React.useState<any>('')
  const [TaskDetailLoading, setTaskDetailLoading] = React.useState(true)

  const getPlaybooks = async (): Promise<any> => {
    const result = await http<any>({
      path: '/pbTask/',
    })
    if (result.ok && result.body) {
      return result.body
    } else {
      return ''
    }
  }

  React.useEffect(() => {
    let cancelled = false
    const doGetPlaybooks = async () => {
      const playbooks = await getPlaybooks()
      if (!cancelled) {
        setPlaybooks(playbooks)
        setTaskDetailLoading(false)

        if (onDataLoaded) onDataLoaded(playbooks)
      }
    }
    doGetPlaybooks()
    return () => {
      cancelled = true
    }
  }, [])

  function renderDetails() {
    //const Playbook = playbookList[playbookList.length - 1];
    if (TaskDetailLoading) return <CircularProgress />
    if (TaskGUID == null) {
      return <h2>Select a Checklist</h2>
    }
    var myTask = null
    if (playbooks.Tasklists && playbooks.Tasklists.length > 0) {
      for (var i = 0; i < playbooks.Tasklists.length; i++) {
        if (playbooks.Tasklists[i].TaskGUID === TaskGUID) {
          myTask = playbooks.Tasklists[i]
        }
      }
    }
    if (myTask) {
      var hfvc: EventParams = {}
      hfvc['PubGUID'] = myTask.PubGUID.toLowerCase()
      hfvc['RefPubGUID'] = myTask.RefPubGUID.toLowerCase()
      hfvc['EventID'] = myTask.EventID
      hfvc['adg'] = playbooks.AppDevGUID
      //setBackButton(true);
      return (
        <HiddenFormWithValues
          HostURL={myTask.hostURL}
          HostPage="TaskPlan"
          HdnVals={hfvc}
        />
      )
    }
    return (
      <>
        <h2>details go here</h2>
        <div>
          for {TaskGUID}
          <br />
          adg {playbooks.AppDevGUID}
          <hr />
          <pre>{JSON.stringify(myTask, null, 2)}</pre>
          <hr />
          <pre>{JSON.stringify(playbooks.Tasklists, null, 2)}</pre>
          <hr />
        </div>
      </>
    )
  }

  return <>{renderDetails()}</>
}
