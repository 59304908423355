// @ts-nocheck
// TODO: Typescript
import React from 'react'
import CreateAccountCancel from './CreateAccountCancel'
import { prefixSelectorWithApp } from '../_utils/domUtils'

/**
 * @extends {Component<Props, {}>}}
 */
const createAccountCancelExport = (props) => (
  <CreateAccountCancel
    id={prefixSelectorWithApp('CreateAccountCancelPage')}
    {...props}
  />
)

export default createAccountCancelExport
