import * as React from 'react'
import { useState, ReactNode, MouseEvent } from 'react'
import classes from './StretchLayout.module.scss'
import { cx } from '../_utils/objectUtils'

interface SectionProps {
  children: ReactNode | ReactNode[]
  className?: string
  id?: string
  itemRef?: (ref: HTMLDivElement) => void
  style?: React.CSSProperties
}

interface LayoutProps extends SectionProps {
  height?: number
  headerHeight?: number
  footerHeight?: number
  header?: ReactNode
  footer?: ReactNode
  body?: ReactNode
  border?: string
  hideBorderOnInactive?: boolean
  hideHeaderOnInactive?: boolean
  onClick?(event: MouseEvent): void
  fullScreen?: boolean
}

export function StretchLayout({
  height,
  headerHeight,
  footerHeight,
  header,
  footer,
  body,
  border,
  itemRef,
  children,
  hideBorderOnInactive = false,
  hideHeaderOnInactive = false,
  onClick = () => {},
  fullScreen = false,
  className,
  id,
}: LayoutProps) {
  const headerStyles = { height: 'auto' }
  const footerStyles = { height: 'auto' }
  const bodyStyles = { paddingTop: '0px', paddingBottom: '0px' }
  const [hasFocus, setFocus] = useState(false)

  // NOTE: Modern browsers should auto size the body based on the dynamic height of the header and footer.
  // Older browsers should have an explicitly set the header and footer height.

  if (headerHeight)
    bodyStyles.paddingTop = headerStyles.height = headerHeight + 'px'

  if (footerHeight)
    bodyStyles.paddingBottom = footerStyles.height = footerHeight + 'px'

  const rootClasses = cx(
    className,
    classes.root,
    border ? classes.border : '',
    hideBorderOnInactive ? classes.hideBorderOnInactive : '',
    hideHeaderOnInactive ? classes.hideHeaderOnInactive : '',
    fullScreen ? classes.fullScreen : '',
    hasFocus ? classes.hasFocus : ''
  )

  // Older browsers have to pass the header, footer, and body as separate attributes
  // Modern browsers can use the component style
  return header || footer || body ? (
    <div
      id={id}
      ref={itemRef}
      onFocus={() => setFocus(true)}
      onBlur={() => setFocus(false)}
      onClick={onClick}
      className={rootClasses}
      style={{ height: height || '100%' }}
    >
      <div className={classes.header} style={headerStyles}>
        {header || null}
      </div>
      <div className={classes.body} style={bodyStyles}>
        <div className={classes.content}>{body || null}</div>
      </div>
      <div className={classes.footer} style={footerStyles}>
        {footer || null}
      </div>
    </div>
  ) : (
    <div
      id={id}
      ref={itemRef}
      onClick={onClick}
      onFocus={() => setFocus(true)}
      onBlur={() => setFocus(false)}
      className={rootClasses}
      style={{ height: height || '100%' }}
    >
      {children}
    </div>
  )
}

StretchLayout.propTypes = {
  //id: PropTypes.string,
  /**
   * The total height of the layout. defaults to 100% of its parent
   */
  //height: PropTypes.any,
  /**
   * The height of the header. Should only be used for older browsers
   */
  //headerHeight: PropTypes.number,
  /**
   * The height of the footer. Should only be used for older browsers
   */
  //footerHeight: PropTypes.number,
  /*
   * Puts a border around the layout
   */
  //border: PropTypes.bool,
  //hideBorderOnInactive: PropTypes.bool,
  //hideHeaderOnInactive: PropTypes.bool,
  //fullScreen: PropTypes.bool
}

export const StretchHeader = ({
  children = null,
  className = '',
  itemRef,
  ...other
}: SectionProps) => (
  <div ref={itemRef} className={cx(classes.header, className)} {...other}>
    {children}
  </div>
)
export const StretchFooter = ({
  children = null,
  className = '',
  itemRef,
  ...other
}: SectionProps) => (
  <div ref={itemRef} className={cx(classes.footer, className)} {...other}>
    {children}
  </div>
)
export const StretchBody = ({
  children = null,
  className = '',
  itemRef,
  contentStyle = {},
  ...other
}: SectionProps & { contentStyle?: any }) => (
  <div ref={itemRef} className={cx(classes.body, className)} {...other}>
    <div className={classes.content} style={contentStyle}>
      {children}
    </div>
  </div>
)
