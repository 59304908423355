import React, { ReactNode, CSSProperties, useCallback, Fragment } from 'react'
import { IssueDetailMenuType } from './IssueDetailMenuType'
import { ListItem, Tooltip, ListItemText } from '@material-ui/core'
import { AutoFocus } from '../Shared/AutoFocus'
import { Spacer } from '../Shared/Spacer'
import classes from './IssueDetailMenuItem.module.scss'
import { cx } from '../_utils/objectUtils'
import { useAppSelector } from '../_utils/reactReduxHooks'
import { isMobileApp } from '../_selectors'

interface Props {
  id?: string
  compact?: boolean
  className?: string
  style?: CSSProperties
  component?: string
  itemTitle: string | JSX.Element
  content?: ReactNode
  extraContent?: ReactNode
  itemKey: IssueDetailMenuType
  count?: number
  countColor?: string
  icon: React.ReactElement
  onClick(selected: string): void
  selected: boolean
  description?: ReactNode
}

export const IssueDetailMenuItem = ({
  compact,
  itemKey,
  selected,
  description,
  count,
  itemTitle,
  icon,
  countColor,
  content,
  extraContent,
  style,
  id,
  onClick,
}: Props) => {
  const isMobile = useAppSelector(isMobileApp)
  const renderCount = useCallback(() => {
    const newCount = count && count > 100 ? '99+' : count
    return (
      <span
        className={cx(classes.count, compact ? classes.compactCount : '')}
        style={{
          color: countColor ? countColor : '#3E91C5',
        }}
      >
        {newCount}
      </span>
    )
  }, [count, countColor, compact])

  const renderListItem = useCallback(
    (Children) => {
      return (
        <ListItem
          id={id}
          className={cx(
            'position-relative',
            isMobile ? '' : classes.listItem,
            compact ? classes.compactListItem : '',
            classes.pointer,
            selected ? classes.selected : ''
          )}
          onClick={() => {
            onClick(itemKey)
          }}
          style={style}
        >
          {Children}
        </ListItem>
      )
    },
    [id, itemKey, onClick, selected, style, compact, isMobile]
  )

  return compact ? (
    <Tooltip title={itemTitle}>
      {renderListItem(
        <Fragment>
          {selected && <AutoFocus />}
          {icon}
          {extraContent}
          {renderCount()}
        </Fragment>
      )}
    </Tooltip>
  ) : (
    renderListItem(
      content || (
        <Fragment>
          {selected && <AutoFocus />}
          {icon}
          <Spacer />
          <ListItemText
            primary={itemTitle || ''}
            secondary={description}
            secondaryTypographyProps={{
              component: 'div',
            }}
          />
          {extraContent}
          {renderCount()}
        </Fragment>
      )
    )
  )
}
