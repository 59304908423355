import * as React from 'react'
import { useState, Fragment, MouseEvent, useEffect, useRef } from 'react'
import { connect } from 'react-redux'
import { HPanelLayout, HPanel } from '../Layouts/HPanelLayout'
import { MasterPageLayout } from './MasterPageLayout'
import { DesktopLayout, MobileLayout } from '../Layouts/ResponsiveHidden'
import { IssueDetailMenu } from '../Issue/IssueDetailMenu'
import { CircularProgress, LoadingBackDrop } from '../Shared/LoadingBackdrop'
import { Button } from 'reactstrap'
import { MobileViewLayout } from '../Layouts/MobileViewLayout'
import IssueDetailPageContent, {
  getIssueDetailTitle,
  IssueDetailPageContentToolbar,
  ContentApis,
} from './IssueDetailPageContent'
import { goToIssue, navigateIssueBack } from '../_utils/reduxUtils'
import { /*Redirect, */ RouteComponentProps } from 'react-router'
import { IssueRouteParams } from '../Routing/RouteProps'
import { fetchDirectMessages } from '../Chat/_actions'
import { IssueDetailMenuType } from '../Issue/IssueDetailMenuType'
import IssueSummaryFeed from '../Issue/IssueSummaryFeed'
import classes from './IssueDetailPage.module.scss'
import IssueTabFilter from '../Issue/IssueTabFilter'
import IssueFilterApplied from '../Issue/IssueFilterApplied'
import { cx, parseIntIfPresent } from '../_utils/objectUtils'
import { MainPageBackBtn } from '../Shared/Buttons'
import {
  changeIssueStageTab,
  fetchIssueDetails,
  issueFilterFetch,
  setDefaultIssue,
} from '../Issue/_actions'
import {
  isIssueBeingCreated,
  selectCurrentIssue,
  selectDefaultIssueId,
  selectIssueById,
  selectIssuePathId,
  selectIssuesCount,
  selectIssueStageTab,
} from '../Issue/_selectors'
import {
  canUserAccessWorkspace,
  isUserWorkspaceParticipant,
  selectCurrentIssueLevel,
  selectCurrentIssueWorkspaceId,
  selectUserWorkspaceParticipant,
  selectVisibleAssignedIssueIds,
  selectVisibleStagingIssueIds,
} from '../_rootConfigs/rootSelectors'
import {
  isDeletingIssue,
  isFetchDirectMessagesRequired,
  isFetchingIssueDetails,
  isFetchIssueDetailsRequired,
  isFetchIssuesComplete,
  isFetchIssuesLoading,
  isInitIssuesComplete,
  isIssueResolving,
} from '../Shared/_requestStatusSelectors'
import {
  selectWorkspaceById,
  selectWorkspacePathId,
} from '../Workspace/_selectors'
import { useFetchIssueDetails } from '../Workspace/_hooks'
import { ErrorBoundary } from '@rollbar/react'
import { useAppSelector } from '../_utils/reactReduxHooks'
import { selectUserInitialState } from '../_selectors'

interface Props extends RouteComponentProps<IssueRouteParams> {
  IssueID: number
  IssueTeamWorkspaceID: number
  isInitIssuesComplete: boolean
  isFetchIssuesComplete: boolean
  isFetchIssuesLoading: boolean
  isCreating: boolean
  isDeletingIssue: boolean
  isArchived: boolean
  hasWorkspaceAccess: boolean
  StagingEnteredGMT: Date
  StagingExitedGMT: Date
  fetchDirectMessages(params: any): void
  fetchIssueFilters(): void
  fetchIssueDetails: (IssueTeamWorkspaceID: number) => void
  isFetchDirectMessagesRequired(workspaceId: number): boolean
  isFetchIssueDetailsRequired: boolean
  isFetchingIssueDetails: boolean
  LevelRGB: string
  isIssueResolving: boolean
  isIssueResolved: boolean
  isUserWorkspaceParticipant: boolean
  setDefaultIssue(issue: number): void
  useDefaultIssue: boolean
  defaultIssue: number
  assignedIssueIds: number[]
  stagingIssueIds: number[]
  changeIssueStageTab: (tab: string) => void
  selectedStageType: string
  hasIssuePathId: boolean
  issuesCount: number
}

const ownClasses = {
  issueMenuContainer: 'aaaa',
  workspaceMenuContainer: 'bbbbbb',
}

type SelectPanelToOverFlow = { [selectedPanel: string]: string }
const overflowByPanel: SelectPanelToOverFlow = {
  tasks: 'hidden',
  activitylog: 'hidden',
}

const contentPanelClasses = {
  content: 'p-2 ',
}

const menuClasses = {
  content: classes.issueLevelBorder,
}

const mobileViewClasses = {
  content: classes.issueLevelBorder,
}

export const IssueDetailPage = ({
  history,
  IssueID,
  IssueTeamWorkspaceID,
  isInitIssuesComplete,
  isCreating,
  isDeletingIssue,
  isArchived,
  hasIssuePathId,
  hasWorkspaceAccess,
  location,
  match,
  setDefaultIssue,
  defaultIssue,
  useDefaultIssue,
  assignedIssueIds,
  stagingIssueIds,
  changeIssueStageTab,
  selectedStageType,
  fetchDirectMessages,
  isFetchDirectMessagesRequired,
  isFetchIssuesComplete,
  fetchIssueFilters,
  LevelRGB,
  fetchIssueDetails,
  isFetchIssueDetailsRequired,
  isFetchingIssueDetails,
  isFetchIssuesLoading,
  isIssueResolving,
  isIssueResolved,
  isUserWorkspaceParticipant,
  issuesCount,
  StagingEnteredGMT,
}: Props) => {
  const apis = useRef<ContentApis>({})
  const isLoadingData = isFetchingIssueDetails || isFetchIssuesLoading

  ////////////// State ////////////////////////
  const [isMenuExpanded, setMenuExpanded] = useState(true)
  //const [hasIssues, setHasIssues] = useState(true);

  /////////////// Effects /////////////////////////////////////
  /*useEffect(() => {
        if(issuesCount === 0)
            setHasIssues(false);
    }, []);*/

  const userSettings = useAppSelector((state) => selectUserInitialState(state))
  const issue = useAppSelector((state) =>
    selectIssueById(state, IssueID as any)
  )
  const evidenceLockerCustomName =
    issue?.Settings?.find((isv: any) => isv.SettingType === 'L')?.SettingYN ===
    'Y'
  const userRole = useAppSelector((state) =>
    selectUserWorkspaceParticipant(state, IssueTeamWorkspaceID)
  )

  const isExecutive = userRole?.Role === 'Executive'

  useEffect(() => {
    fetchDirectMessagesIfRequired()
  })

  useFetchIssueDetails(IssueTeamWorkspaceID)

  useEffect(() => {
    if (isExecutive && !isLoadingData) {
      history.push({
        pathname: `/IssueDetail/${IssueID}/${
          IssueTeamWorkspaceID || IssueID
        }/execbrief`,
      })
    }
  }, [isLoadingData, IssueTeamWorkspaceID, IssueID, history, isExecutive])

  useEffect(() => {
    // Sets a default IssueId if one isn't selected already
    // Also switches the list of issues by staging type if one type doesn't have issues
    if (useDefaultIssue && isInitIssuesComplete && !defaultIssue) {
      let issueIds: number[] = []
      let newSelectedType: string = selectedStageType
      if (selectedStageType === 'assigned') {
        issueIds = assignedIssueIds

        if (issueIds.length === 0 && stagingIssueIds.length > 0) {
          issueIds = stagingIssueIds
          newSelectedType = 'staging'
        }
      } else {
        issueIds = stagingIssueIds
        newSelectedType = 'staging'

        if (issueIds.length === 0 && assignedIssueIds.length > 0) {
          issueIds = assignedIssueIds
          newSelectedType = 'assigned'
        }
      }

      if (StagingEnteredGMT) newSelectedType = 'staging'

      if (issueIds.length > 0 && selectedStageType !== newSelectedType)
        changeIssueStageTab(newSelectedType)

      if (issueIds[0]) setDefaultIssue(issueIds[0])
    }
  }, [
    isInitIssuesComplete,
    assignedIssueIds,
    stagingIssueIds,
    StagingEnteredGMT,
    changeIssueStageTab,
    defaultIssue,
    selectedStageType,
    setDefaultIssue,
    useDefaultIssue,
  ])

  function fetchDirectMessagesIfRequired() {
    if (
      IssueTeamWorkspaceID &&
      isFetchDirectMessagesRequired(IssueTeamWorkspaceID)
    )
      fetchDirectMessages({ IssueTeamWorkspaceID })
  }

  /////////////////// Navigation //////////////////////////////////
  function goToSelectedPanel(domain: string) {
    history.push({
      pathname: `/IssueDetail/${IssueID}/${
        IssueTeamWorkspaceID || IssueID
      }/${domain}`,
    })
  }

  function handleBackClick(evt: MouseEvent) {
    evt.preventDefault()
    history.push({
      pathname: '/Issues',
      state: {},
    })
  }

  function handleNavigateBack() {
    navigateIssueBack(history, match.params)
  }

  function handleGoToIssue(params: IssueRouteParams) {
    goToIssue(history, params)
  }

  function handlePathChanged(params: IssueRouteParams) {
    handleGoToIssue({
      ...match.params,
      ...params,
    })
  }

  ////////////// Renderers ///////////////////////
  function renderError(message: string) {
    return (
      <Fragment>
        <Button
          color="link"
          style={{ paddingBottom: 10, cursor: 'pointer' }}
          onClick={handleBackClick}
        >
          Back to Issue Summary
        </Button>
        <div>{message}</div>
      </Fragment>
    )
  }

  function renderMobilePanel(selected?: IssueDetailMenuType) {
    if (selected)
      return (
        <ErrorBoundary>
          <IssueDetailPageContent
            isMobile={true}
            contentType={selected}
            onPathChanged={handlePathChanged}
            match={match}
            onApis={handleOnApis}
          />
        </ErrorBoundary>
      )

    return renderMenu(true)
  }

  ///////////// Callbacks //////////////
  function handleMenuToggleDone(isMenuExpanded: boolean) {
    setMenuExpanded(isMenuExpanded)
    apis.current.activityLog?.refresh()
  }

  function handleOnApis(newApis: ContentApis) {
    apis.current = newApis
  }

  ////////// Utility //////////////////////
  function getSelectedPanel(isMobile: boolean = false) {
    const params = match.params
    let panel = params?.domain
    let manualLocation = ''

    if (isExecutive) {
      manualLocation = 'execbrief'
    } else if (userSettings.PrefsDefaultView === 'IssuesActivityLog') {
      manualLocation = 'activitylog'
    } else {
      manualLocation = 'summary'
    }
    if (panel === 'filesformslinks' && !evidenceLockerCustomName) {
      panel = 'evidencelocker'
    } else if (panel === 'evidencelocker' && evidenceLockerCustomName) {
      panel = 'filesformslinks'
    }
    return (
      panel ? panel : !isMobile ? manualLocation : panel
    ) as IssueDetailMenuType
  }

  //////////// Main Renderer //////////////////////
  //const isEmpty = stagingIssueIds.length === 0 && assignedIssueIds.length === 0;

  /*if(!hasIssues)
        return <Redirect to={{ pathname: "/IssueDetail" }} />;*/

  if (!isInitIssuesComplete) return null

  if (isCreating)
    return <LoadingBackDrop text="Loading Issue Data..." open={true} />

  if (hasIssuePathId) {
    if (!IssueID || isArchived) return renderError('this issue does not exist')

    if (!IssueTeamWorkspaceID)
      return renderError('this workspace does not exist')

    if (!hasWorkspaceAccess && !isLoadingData)
      return renderError('this workspace does not exist')
  }

  if (isDeletingIssue)
    return <LoadingBackDrop text="Deleting Issue..." open={true} />

  function renderMenu(isMobile: boolean = false) {
    const selectedPanel = getSelectedPanel(isMobile)

    return (
      <IssueDetailMenu
        classes={{
          issueMenuContainer: ownClasses.issueMenuContainer,
          workspaceMenuContainer: ownClasses.workspaceMenuContainer,
        }}
        compact={!isMenuExpanded && !isMobile}
        IssueID={IssueID}
        IssueTeamWorkspaceID={IssueTeamWorkspaceID}
        itemSelected={goToSelectedPanel}
        selected={selectedPanel}
      />
    )
  }

  const selectedPanel = getSelectedPanel()
  const title = getIssueDetailTitle(selectedPanel)

  const selectedPanelMobile = getSelectedPanel(true)
  const titleMobile = getIssueDetailTitle(selectedPanelMobile)

  const issueLevelStyle = {
    borderTopColor: LevelRGB,
  }

  const disabled = isIssueResolved || !isUserWorkspaceParticipant
  const showIsResolvingLoader = isIssueResolving && !isCreating
  const issueReadonlyClass =
    isIssueResolved && !showIsResolvingLoader ? classes.issueReadonly : ''
  const workspaceReadonlyClass =
    (!isUserWorkspaceParticipant || issueReadonlyClass) &&
    !showIsResolvingLoader
      ? classes.workspaceReadonly
      : ''

  return (
    <MasterPageLayout history={history} location={location} match={match}>
      <LoadingBackDrop
        text="Issue Resolving"
        className="position-absolute"
        open={showIsResolvingLoader}
      />
      <DesktopLayout>
        <HPanelLayout>
          <HPanel header="Summary" overflowY="hidden" hasPaper={false}>
            <IssueSummaryFeed
              className={'pl-2'}
              compact={false}
              showIssueLevels={true}
              hasFilter={true}
              hasSearch={false}
              onClick={handleGoToIssue}
              selected={IssueID}
              history={history}
              match={match}
              header={<MainPageBackBtn onClick={handleBackClick} />}
              actions={<IssueFilterApplied />}
              actionsBottom={
                <IssueTabFilter className="w-100" scrollButtons="off" />
              }
            />
          </HPanel>
          <HPanel
            header="Detail"
            isCollapsible={true}
            onCollapseToggle={handleMenuToggleDone}
            styles={IssueID ? { content: issueLevelStyle } : undefined}
            classes={IssueID ? menuClasses : undefined}
            className={cx(issueReadonlyClass, workspaceReadonlyClass)}
          >
            {!isLoadingData && IssueID && renderMenu()}
            {isLoadingData && <CircularProgress />}
          </HPanel>
          <HPanel
            header={title}
            fillWidth={true}
            classes={contentPanelClasses}
            overflowY={overflowByPanel[selectedPanel] as any}
            className={cx(issueReadonlyClass, workspaceReadonlyClass)}
          >
            <ErrorBoundary>
              {!isLoadingData && IssueID && (
                <IssueDetailPageContent
                  isMobile={false}
                  contentType={selectedPanel}
                  onPathChanged={handlePathChanged}
                  match={match}
                  onApis={handleOnApis}
                />
              )}
              {isLoadingData && <CircularProgress />}
            </ErrorBoundary>
          </HPanel>
        </HPanelLayout>
      </DesktopLayout>
      <MobileLayout>
        <MobileViewLayout
          title={titleMobile}
          onNavClick={handleNavigateBack}
          classes={mobileViewClasses}
          styles={{ content: issueLevelStyle }}
          actions={
            <IssueDetailPageContentToolbar
              contentType={selectedPanelMobile}
              match={match}
              disabled={disabled}
            />
          }
        >
          {renderMobilePanel(selectedPanelMobile)}
        </MobileViewLayout>
      </MobileLayout>
    </MasterPageLayout>
  )
}

// TODO: Typescript fix any
const mapStateToProps = (state: any, ownProps: any) => {
  const selectedStageType = selectIssueStageTab(state)
  const issue = selectCurrentIssue(state, ownProps) || {}

  // @ts-ignore
  const issueLevel = selectCurrentIssueLevel(state, ownProps) || {}

  const {
    Description,
    IssueName,
    IssueCreated,
    IssueResolved,
    StagingEnteredGMT,
    StagingExitedGMT,
    isArchived,
  } = issue
  const { LevelRGB, LevelName } = issueLevel || {}

  let IssueID: number
  let IssueIDPath = selectIssuePathId(state, ownProps)
  let useDefaultIssue = true
  let hasIssuePathId = false

  // Verify that the issue path id exists
  if (IssueIDPath) {
    hasIssuePathId = true
    IssueID = selectIssueById(state, IssueIDPath)?.IssueID
    if (!IssueID) useDefaultIssue = false
  } // select a default issue id
  else IssueID = issue.IssueID

  let IssueTeamWorkspaceID: number
  let IssueTeamWorkspacePathID = selectWorkspacePathId(state, ownProps)

  // Verify that the issue team workspace path id exists
  if (IssueTeamWorkspacePathID)
    IssueTeamWorkspaceID = selectWorkspaceById(
      state,
      IssueTeamWorkspacePathID
    )?.IssueTeamWorkspaceID
  // select a default workspace id
  else IssueTeamWorkspaceID = selectCurrentIssueWorkspaceId(state, ownProps)

  return {
    StagingExitedGMT,
    StagingEnteredGMT,
    IssueTeamWorkspaceID,
    IssueID,
    isArchived,
    IssueResolved,
    IssueName,
    Description,
    IssueCreated,
    LevelRGB,
    LevelName,
    isInitIssuesComplete: isInitIssuesComplete(state),
    isFetchIssuesLoading: isFetchIssuesLoading(state),
    isFetchIssuesComplete: isFetchIssuesComplete(state),
    isCreating: isIssueBeingCreated(
      state,
      parseIntIfPresent(selectIssuePathId(state, ownProps)) as number
    ),
    isDeletingIssue: isDeletingIssue(state),
    hasWorkspaceAccess: canUserAccessWorkspace(state, IssueTeamWorkspaceID),
    isFetchDirectMessagesRequired: (IssueTeamWorkspaceID: number) =>
      isFetchDirectMessagesRequired(state, IssueTeamWorkspaceID),
    isFetchIssueDetailsRequired: isFetchIssueDetailsRequired(
      state,
      IssueTeamWorkspaceID
    ),
    isFetchingIssueDetails: isFetchingIssueDetails(state, IssueTeamWorkspaceID),
    isIssueResolving: isIssueResolving(state),
    isIssueResolved: IssueResolved ? true : false,
    isUserWorkspaceParticipant: isUserWorkspaceParticipant(
      state,
      IssueTeamWorkspaceID
    ),
    defaultIssue: selectDefaultIssueId(state),
    useDefaultIssue,
    selectedStageType,
    assignedIssueIds: selectVisibleAssignedIssueIds(state),
    stagingIssueIds: selectVisibleStagingIssueIds(state),
    hasIssuePathId,
    issuesCount: selectIssuesCount(state),
  }
}

const mapDispatchToProps = {
  fetchDirectMessages: (params: any) =>
    fetchDirectMessages.request(params, {
      icoRequestId: params.IssueTeamWorkspaceID,
    }),
  setDefaultIssue: setDefaultIssue,
  changeIssueStageTab: changeIssueStageTab,
  fetchIssueDetails: (IssueTeamWorkspaceID: number) =>
    fetchIssueDetails.request(
      { IssueTeamWorkspaceID },
      { icoRequestId: IssueTeamWorkspaceID, IssueTeamWorkspaceID }
    ),
  fetchIssueFilters: issueFilterFetch,
}

// @ts-expect-error
export default connect(mapStateToProps, mapDispatchToProps)(IssueDetailPage)
