// @ts-nocheck
// TODO: Typescript
import React, { Component } from 'react'
import {
  withMobileDialog,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@material-ui/core'
import { Button } from 'reactstrap'
import PropTypes from 'prop-types'
import UploadForm from './UploadForm'
import { connect } from 'react-redux'
import { upload } from './_actions'
import { UploadEntityTypeValues } from './_constants'
import { SlideLeft } from '../Shared/Transition'
import { getNewUploadRequestId } from './_sagas'

const ResponsiveDialog = withMobileDialog()(Dialog)

/**
 * @extends {Component<Props, {}>}}
 * The confirmation dialog used for sending a file to the server
 */
export class UploadDialog extends Component {
  constructor(props) {
    super(props)
    this.handleSave = this.handleSave.bind(this)
    this.handleCancel = this.handleCancel.bind(this)
  }

  componentDidUpdate(prevProps) {
    // Reset the dialog when it is closed
    if (prevProps.open !== this.props.open && !this.props.open)
      this.formData = null
  }

  handleSave() {
    const { formData } = this
    const { onSave, entity, file, uploadType } = this.props
    if (onSave) onSave({ ...entity, ...formData }, { file, uploadType })
    this.handleCancel()
  }

  handleCancel() {
    this.props.onCancel()
  }

  render() {
    const { open, file } = this.props
    return (
      <ResponsiveDialog
        open={open}
        onClose={this.handleCancel}
        TransitionComponent={SlideLeft}
      >
        <DialogTitle>Upload</DialogTitle>
        <DialogContent>
          <UploadForm
            onChange={(formData) => {
              this.formData = formData
            }}
            file={file}
          />
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={this.handleCancel} color="primary">
            Cancel
          </Button>
          <Button onClick={this.handleSave} color="primary">
            Save
          </Button>
        </DialogActions>
      </ResponsiveDialog>
    )
  }
}

UploadDialog.propTypes = {
  open: PropTypes.bool,
  /**
   * The entity that the upload should be attached to
   */
  entity: PropTypes.any,
  /**
   * The type of entity that the upload is being attached to
   */
  uploadType: PropTypes.oneOf(UploadEntityTypeValues),
  file: PropTypes.instanceOf(File),
  onCancel: PropTypes.func,
  onSave: PropTypes.func,
}

UploadDialog.defaultProps = {
  onCancel: () => {},
  onSave: () => {},
}

const mapDispatchToProps = {
  onSave: (entity, source) => {
    return upload(entity, {
      ...source,
      icoRequestId: getNewUploadRequestId(),
    })
  },
}

export default connect(undefined, mapDispatchToProps)(UploadDialog)
