// @ts-nocheck
// TODO: Typescript
import React from 'react'
import { LoginForm } from './LoginForm'
import { prefixSelectorWithApp } from '../_utils/domUtils'

const LoginFormExport = (props) => (
  <LoginForm id={prefixSelectorWithApp('LoginFormPage')} {...props} />
)
export default LoginFormExport
