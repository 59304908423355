import * as React from 'react'
import { ReactNode } from 'react'
import { Button, Dialog, DialogContent } from '@material-ui/core'
import classes from './AdminConsoleDialog.module.scss'
import { SlideLeft } from './Transition'
import { Spacer } from './Spacer'

/**
 * @extends {Component<Props, {}>}}
 * Reusable alert dialog
 */

type dialogRoles = 'destructive'
export const AdminConsoleDialog = ({
  open,
  content,
  header,
  onConfirm,
  onCancel,
  consoleRole,
}: {
  open: boolean
  content: ReactNode
  header?: ReactNode
  onConfirm: () => void
  onCancel: () => void
  consoleRole?: dialogRoles
}) => {
  return (
    <Dialog open={open} TransitionComponent={SlideLeft as any}>
      <div
        style={{
          display: 'flex',
          alignContent: 'center',
          justifyContent: 'center',
          marginTop: '1rem',
        }}
      >
        {header}
      </div>

      <DialogContent>{content}</DialogContent>
      <div className={classes.AdminConsoleDialogButtonContainer}>
        <Button
          style={{
            textTransform: `none`,
            color: `rgb(64, 148, 195)`,
            borderRadius: 'none',
          }}
          onClick={() => onCancel()}
        >
          {' '}
          Cancel
        </Button>
        <Spacer />
        <Button
          style={{
            backgroundColor:
              consoleRole === 'destructive'
                ? `rgb(188, 32, 46)`
                : `rgb(64, 148, 195)`,
            color: 'white',
            textTransform: `none`,
            borderRadius: 'none',
          }}
          onClick={() => onConfirm()}
        >
          {consoleRole === 'destructive' ? 'Delete' : 'Save'}
        </Button>
      </div>
    </Dialog>
  )
}
