// @ts-nocheck
// TODO: Typescript
import React, { Component } from 'react'
import { Button, InputGroup } from 'reactstrap'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import {
  fetchOrgIssueLevelsRequest,
  applyIssueFilter,
  issueFilterSave,
  issueFilterFetch,
} from './_actions'
import CheckBox from '../CustomMaterial/Checkbox'
import {
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Select,
  MenuItem,
  ListItemText,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import DateFnsUtils from '@date-io/moment'
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers'

import classes from './IssueFilterAdvanced.module.scss'
import Checkbox from '../CustomMaterial/Checkbox'
import { selectIssueFieldSortDir } from './_reducer'
import { RootState } from '../_store'
import {
  isOrgIssueLevelSelected,
  selectAllUnresolvedIssues,
  selectIssueFilterField,
  selectOrgIssueLevelNameById,
  selectOrgIssueLevels,
  selectOrgIssueTypes,
  selectUserOrgInfo,
} from './_selectors'
import { TagAutocomplete } from '../Shared/TagAutocomplete'
import { OrgTagOpt } from '../Org/OrgTagOpt'
import { selectUniqueOrgTagNames } from '../_rootConfigs/rootSelectors'
import OrgTag from '../Org/OrgTag'
import orgTagClassesScss from '../Org/OrgTagShared.module.scss'
import { Spacer } from '../Shared/Spacer'
import { addNotification } from '../_actions'

const orgTagClasses = {
  listbox: orgTagClassesScss.OrgTagOptList,
}

const severityStyle = {
  minWidth: 220,
  maxWidth: 500,
  width: '100%',
}

/**
 * @extends {Component<Props, {}>}}
 */
export class IssueFilterAdvanced extends Component {
  constructor(props) {
    super(props)
    this.state = {
      value: '',
      sortBy: '',
    }

    // Set the initial filter state
    const {
      IssueResolved,
      selectedSeverity,
      selectedType,
      selectedStartDate,
      selectedEndDate,
      selectedSortBy,
      selectedOrgID,
      selectedIssueTags,
      selectedOrgIdList,
    } = this.props

    this.state = {
      IssueResolved: IssueResolved,
      Severity: selectedSeverity || [],
      Type: selectedType,
      startDate: selectedStartDate,
      endDate: selectedEndDate,
      sortBy: selectedSortBy,
      OrgID: selectedOrgID,
      btnDisabled: true,
      tags: selectedIssueTags || [],
      isTagOpen: false,
      orgIdList: selectedOrgIdList || [],
    }
    this.handleFilterApply = this.handleFilterApply.bind(this)
    this.handleTagChange = this.handleTagChange.bind(this)
    this.handleTagOpen = this.handleTagOpen.bind(this)
    this.handleTagClose = this.handleTagClose.bind(this)
    this.handleOrgListItemClick = this.handleOrgListItemClick.bind(this)
    this.getOrgFromId = this.getOrgFromId.bind(this)
  }

  componentDidUpdate(prevProps) {
    const { severityItems, selectedSeverity } = this.props
    if (prevProps.severityItems !== severityItems) {
      this.setState({
        Severity: selectedSeverity,
      })
    }
  }

  componentDidMount() {
    const { fetchOrgIssueLevelsRequest } = this.props

    if (fetchOrgIssueLevelsRequest) fetchOrgIssueLevelsRequest(this.state.OrgID)

    if (this.state.orgIdList.length === 0)
      this.setState({
        orgIdList: this.props.availableOrgs?.map((org) => org.OrgID),
      })

    const { fetchIssueFilters } = this.props
    if (fetchIssueFilters) fetchIssueFilters()
  }

  render() {
    const useStyles = makeStyles({
      root: {
        '&:hover': {
          backgroundColor: 'transparent',
        },
      },
      icon: {
        borderRadius: '50%',
        width: 16,
        height: 16,
        boxShadow:
          'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
        backgroundColor: '#f5f8fa',
        backgroundImage:
          'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
        '$root.Mui-focusVisible &': {
          outline: '2px auto rgba(19,124,189,.6)',
          outlineOffset: 2,
        },
        'input:hover ~ &': {
          backgroundColor: '#ebf1f5',
          outlineOffset: 2,
        },
        'input:disabled ~ &': {
          boxShadow: 'none',
          background: 'rgba(206,217,224,.5)',
        },
      },
      checkedIcon: {
        backgroundColor: '#137cbd',
        backgroundImage:
          'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
        '&:before': {
          display: 'block',
          width: 16,
          height: 16,
          backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
          content: '""',
        },
        'input:hover ~ &': {
          backgroundColor: '#106ba3',
        },
      },
    })

    // Inspired by blueprintjs
    function StyledRadio(props) {
      const classes = useStyles()

      return (
        <Radio
          className={classes.root}
          disableRipple
          color="standard"
          checkedIcon={
            <span className={clsx(classes.icon, classes.checkedIcon)} />
          }
          icon={<span className={classes.icon} />}
          value=""
          {...props}
        />
      )
    }

    const {
      severityItems,
      typeItems,
      getLevelName,
      issueTags,
      selectedIssueTags,
    } = this.props
    return (
      <div className="w-100">
        <div className={classes.myDropDownContainer}>
          <FormLabel style={{ fontWeight: 900, height: 5 }}>SEVERITY</FormLabel>
          <Select
            onChange={this.handleFilterChange}
            multiple={true}
            style={severityStyle}
            value={this.state.Severity}
            renderValue={() =>
              this.state.Severity.map((id) => getLevelName(id)).join(', ')
            }
            name="Severity"
          >
            {severityItems.map((item) => {
              const selected = this.state.Severity.indexOf(item.Value) > -1
              return (
                <MenuItem
                  label="Select"
                  value={item.Value}
                  key={item.Value}
                  name={item.Descr}
                  selected={selected}
                >
                  <Checkbox checked={selected} />
                  <ListItemText primary={item.Descr} />
                </MenuItem>
              )
            })}
          </Select>
        </div>
        <div className={classes.myDropDownContainer}>
          <FormLabel style={{ fontWeight: 900, height: 5 }}>MODE</FormLabel>
          <Select
            onChange={this.handleFilterChange}
            multiple={true}
            style={severityStyle}
            value={this.state.Type}
            renderValue={() =>
              this.state.Type.map(
                (lookupType) =>
                  typeItems.find((type) => type.Value === lookupType).Descr
              ).join(', ')
            }
            name="Type"
          >
            {typeItems.map((type) => {
              const selected = this.state.Type.includes(type.Value)
              return (
                <MenuItem
                  label="Select"
                  value={type.Value}
                  key={type.Value}
                  name={type.Descr}
                  selected={selected}
                >
                  <Checkbox checked={selected} />
                  <ListItemText primary={type.Descr} />
                </MenuItem>
              )
            })}
          </Select>
        </div>
        <div className={classes.myDateContainer}>
          <MuiPickersUtilsProvider
            utils={DateFnsUtils}
            style={{ paddingTop: 5, marginBottom: 8 }}
          >
            <KeyboardDatePicker
              style={{ width: 220 }}
              clearable
              format="MM/DD/YYYY"
              margin="normal"
              label="Start date"
              onChange={(dateUtil, value) =>
                this.handleMyDateChange(dateUtil, 'startDate', value)
              }
              value={this.state.startDate ? this.state.startDate : null}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
              onKeyDown={(e) => {
                if (e.key !== 'Backspace') {
                  e.preventDefault()
                } else {
                  this.handleMyDateChange('', 'startDate', null)
                }
              }}
            />
          </MuiPickersUtilsProvider>
        </div>
        <div className={classes.myDateContainer}>
          <MuiPickersUtilsProvider
            utils={DateFnsUtils}
            style={{ paddingTop: 5, marginBottom: 8 }}
          >
            <KeyboardDatePicker
              style={{ width: 220 }}
              clearable
              format="MM/DD/YYYY"
              margin="normal"
              label="End date"
              onChange={(dateUtil, value) =>
                this.handleMyDateChange(dateUtil, 'endDate', value)
              }
              value={this.state.endDate ? this.state.endDate : null}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
              onKeyDown={(e) => {
                if (e.key !== 'Backspace') {
                  e.preventDefault()
                } else {
                  this.handleMyDateChange('', 'startDate', null)
                }
              }}
            />
          </MuiPickersUtilsProvider>
        </div>
        <InputGroup component="fieldset" style={{ paddingTop: 20 }}>
          <CheckBox
            style={{ paddingLeft: 0 }}
            name="IssueResolved"
            checked={this.state.IssueResolved ? true : false}
            onChange={this.handleFilterChange}
          />
          <span
            style={{
              position: 'relative',
              top: 12,
              cursor: 'pointer',
            }}
          >
            Include Resolved
          </span>
        </InputGroup>
        <div className="pt-3 pb-4">
          <TagAutocomplete
            open={this.state.isTagOpen}
            key="OrgTags"
            classes={orgTagClasses}
            defaultOpen={false}
            defaultSelected={selectedIssueTags}
            className={'w-100 position-relative'}
            disableCloseOnSelect={true}
            fullWidth={true}
            multiple={true}
            tags={issueTags}
            autoFocus={true}
            getKey={(opt) => opt.TagKey}
            placeholder="tags"
            onTagChanged={this.handleTagChange}
            onOpen={this.handleTagOpen}
            onClose={this.handleTagClose}
            renderOption={(opt) => <OrgTagOpt {...opt.t} />}
            renderTags={(tags, getTagProps) =>
              tags.map((tag, index) => {
                // override the index
                const tagProps = getTagProps({
                  ...tag,
                  index,
                })
                return <OrgTag {...tag.t} {...tagProps} allowDelete={true} />
              })
            }
            getOptionLabel={(opt) => opt.t.TagName}
          />
        </div>
        <div>
          <FormLabel style={{ fontWeight: 900, height: 5 }}>
            SELECTED ORGS
          </FormLabel>
        </div>

        <Select
          style={severityStyle}
          multiple
          value={this.state.orgIdList}
          renderValue={() =>
            this.state.orgIdList
              ?.filter((orgId) => this.getOrgFromId(orgId))
              ?.map((orgId) => this.getOrgFromId(orgId)?.OrgName)
              .join(', ')
          }
          name="orgIdList"
        >
          {this.props.availableOrgs?.map((org) => {
            const selected = this.state.orgIdList.indexOf(org.OrgID) > -1

            return (
              <MenuItem
                value={org.OrgID}
                key={org.OrgID}
                name={org.OrgName}
                selected={selected}
                onClick={() => this.handleOrgListItemClick(org.OrgID)}
              >
                <Checkbox checked={selected} />
                <ListItemText primary={org.OrgName} />
              </MenuItem>
            )
          })}
        </Select>
        <Spacer spaceParam={'large'} />
        <Spacer spaceParam={'large'} />
        <FormControl component="fieldset" style={{ paddingTop: 2 }}>
          <FormLabel style={{ fontWeight: 900 }}>SORT</FormLabel>
          <RadioGroup
            defaultValue={this.state.sortBy}
            aria-label="sort"
            name="sortBy"
            value={this.state.sortBy}
            onChange={this.handleFilterChange}
          >
            <FormControlLabel
              value="LevelSequence"
              control={<StyledRadio />}
              label="Severity"
            />
            <FormControlLabel
              value="IssueID"
              control={<StyledRadio />}
              label="Recent to oldest"
            />
            <FormControlLabel
              value="IssueName"
              control={<StyledRadio />}
              label="By name"
            />
            <FormControlLabel
              value="lastActivityDate"
              control={<StyledRadio />}
              label="Last Activity"
            />
            {/* <FormControlLabel value="activity" control={<StyledRadio />} label="Most recent activity" onChange={this.handleFilterChange} disabled /> */}
          </RadioGroup>
        </FormControl>

        <div>
          <Button
            disabled={this.state.btnDisabled}
            onClick={this.handleFilterApply}
            color="primary"
          >
            Apply
          </Button>
        </div>
      </div>
    )
  }

  getOrgFromId = (orgId: number) => {
    return this.props.availableOrgs.find((org) => org.OrgID === orgId)
  }

  handleOrgListItemClick = (orgId: number) => {
    const stateArray = [...this.state.orgIdList]
    var index = stateArray.indexOf(orgId)
    this.setState({
      btnDisabled: false,
    })
    if (index !== -1) {
      if (stateArray.length === 1) {
        this.props.addNotification({
          message: 'You must have at least one org selected',
        })
        return
      }

      stateArray.splice(index, 1)
      this.setState({ orgIdList: stateArray })
    } else {
      stateArray.push(orgId)
      this.setState({ orgIdList: stateArray })
    }
  }

  handleMyDateChange(component, name, value) {
    this.setState({
      [name]: value,
      btnDisabled: false,
    })
  }

  handleFilterChange = (event) => {
    let { name, value } = event.target

    if (name === 'IssueResolved') value = !this.state.IssueResolved

    if (name === 'sortBy') {
      this.setState({
        [name]: value,
        sortDir: selectIssueFieldSortDir[value],
      })
    } else {
      this.setState({
        [name]: value,
        sortDir: selectIssueFieldSortDir[value],
      })
    }

    this.setState({
      btnDisabled: false,
    })
  }

  handleTagChange(selected) {
    this.setState({
      tags: selected.map((t) => t.key),
      btnDisabled: false,
    })
  }

  handleTagOpen() {
    this.setState({ isTagOpen: true })
  }

  handleTagClose() {
    this.setState({ isTagOpen: false })
  }

  handleFilterApply = () => {
    const { onApply } = this.props

    if (onApply) {
      onApply(this.state)
      this.saveFilters()
    }
    if (typeof this.props.onChange === 'function') {
      this.props.onChange(false)
    }
  }

  saveFilters = () => {
    let { issueFilterSave, severityItems } = this.props
    severityItems = severityItems || []
    let saveParams = { ...this.state }

    let { Severity } = saveParams
    saveParams.HideSeverity = []
    Severity = Severity || []

    if (Severity.length !== 0) {
      severityItems.forEach((level) => {
        if (Severity.indexOf(level.Value) === -1)
          saveParams.HideSeverity.push(level.Value)
      })
    }

    // No longer save inclusive severity levels. We are now saving the exclusive severity levels.
    saveParams.Severity = undefined

    issueFilterSave(saveParams)
  }
}

IssueFilterAdvanced.propTypes = {
  /**
   * The start date of the issue
   */
  startDate: PropTypes.any,
  /**
   * The end date of the issue
   */
  endDate: PropTypes.any,
  selectedStatus: PropTypes.any,
  selectedSeverity: PropTypes.any,
  selectedType: PropTypes.any,
  /**
   * Status Items are equal to items in IssueType table.
   */
  statusItems: PropTypes.instanceOf(Array),
  /**
   * Severity Items are equal to items in OrgIssueLevel table.
   */
  severityItems: PropTypes.instanceOf(Array),
  /**
   * Type Items are equal to items in IssueType table.
   */
  typeItems: PropTypes.instanceOf(Array),
  IssueResolved: PropTypes.any,
  onChange: PropTypes.func,
  selectedIssueFilters: PropTypes.any,
}

IssueFilterAdvanced.defaultProps = {
  onChange: function () {},
  statusItems: [],
  severityItems: [],
  typeItems: [],
  startDate: '',
  endDate: '',
  IssueResolved: false,
  OrgID: 1,
  issueTags: [],
}

// TODO: Get rid of sample data (Data in store )
const statusItems = [
  { value: 1, name: 'Open_Sample' },
  { value: 3, name: 'Closed_Sample' },
]

// Get data from store (reducers)
const mapStateToProps = (state: RootState, _) => {
  const filter = selectIssueFilterField(state, [
    'IssueResolved',
    'StatusID',
    'Type',
    'startDate',
    'endDate',
    'sortBy',
    'tags',
    'orgIdList',
  ])

  const severityItems = selectOrgIssueLevels(state) || []
  let selectedSeverity = []
  severityItems.forEach((level) => {
    if (isOrgIssueLevelSelected(state, level.Value))
      selectedSeverity.push(level.Value)
  })

  const issueTags = selectUniqueOrgTagNames(state)

  const userOrgInfo: AccountOrganization = selectUserOrgInfo(state)

  const availableUnresolvedIssues = selectAllUnresolvedIssues(state)

  const availableUnresolvedIssuesList = availableUnresolvedIssues?.map(
    (issue) => {
      return { OrgID: issue.OrgID, OrgName: issue.issueOrgName }
    }
  )

  const uniqueIssuesUnresolvedList = [
    ...new Map(
      availableUnresolvedIssuesList?.map((issue) => [issue['OrgID'], issue])
    ).values(),
  ]

  if (!userOrgInfo.Orgs) return

  const userOrgList = [...userOrgInfo.Orgs]

  for (const unresolvedIssue of uniqueIssuesUnresolvedList) {
    if (
      unresolvedIssue.OrgID &&
      !userOrgList.find((orgItem) => orgItem.OrgID === unresolvedIssue.OrgID)
    ) {
      userOrgList.push(unresolvedIssue)
    }
  }

  const filteredOrgIdList = filter.orgIdList?.filter((orgId) =>
    userOrgList.find((org) => org.OrgID === orgId)
  )

  return {
    availableOrgs: userOrgList,
    statusItems,
    severityItems,
    getLevelName: (levelID: number) =>
      selectOrgIssueLevelNameById(state, levelID),
    typeItems: selectOrgIssueTypes(state),
    IssueResolved: filter.IssueResolved,
    selectedStatus: filter.StatusID,
    selectedSeverity, // select all by default (This is handled by the new exclusive logic)
    selectedType: filter.Type, // select all by default
    selectedStartDate: filter.startDate,
    selectedEndDate: filter.endDate,
    selectedSortBy: filter.sortBy,
    selectedOrgIdList: filteredOrgIdList, //it should be set from what ?????? 02.04.2020 NM: Ray postponed it to next releases,
    issueTags,
    selectedIssueTags: filter.tags,
  }
}

// Use the actions from the store
const mapDispatchToProps = {
  fetchOrgIssueLevelsRequest,
  onApply: applyIssueFilter,
  issueFilterSave: issueFilterSave,
  fetchIssueFilters: issueFilterFetch,
  addNotification: addNotification,
}

export default connect(mapStateToProps, mapDispatchToProps)(IssueFilterAdvanced)
