import * as React from 'react'
import { http } from '../../_utils/http'
import { CircularProgress } from '../../Shared/LoadingBackdrop'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faArrowDown,
  faArrowUp,
  faColumns,
} from '@fortawesome/pro-solid-svg-icons'
import classes from '../../Admin/AdminConsolePage.module.scss'
import Moment from 'react-moment'
import { addMinutesToDate } from '../../_utils/dateUtils'
import { Input, InputGroup, Table } from 'reactstrap'
import {
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  FormControl,
  InputLabel,
  Select,
  TextField,
  TableContainer,
  makeStyles,
} from '@material-ui/core'
import { isMobileApp } from '../../_selectors'

import moment from 'moment'
import Autocomplete from '@material-ui/lab/Autocomplete'
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers'
import DateFnsUtils from '@date-io/moment'
import { useAppSelector } from '../../_utils/reactReduxHooks'
import { AdminExportExcel } from '../../Admin/AdminExportExcel'
import OrgTag from '../../Org/OrgTag'
import { AdminIssueQueryTableSelector } from './AdminIssueQueryTableSelector'
import { Spacer } from '../../Shared/Spacer'
import {
  queryPanelIssueSettings,
  saveQueryPanelIssueSettings,
} from '../../_utils/apiUtils'

import CheckBox from '../../CustomMaterial/Checkbox'
import { useIsMounted } from '../../_utils/hooks'
import { IssueGeolocation, Organization } from '../../Issue/_reducer'

interface Props {
  OrgIDList: Organization[]
}

interface QIssue {
  IssueID: number
  IssueType: string
  IssueMode: string
  IssueCreated: Date
  IssueResolved: Date
  IssueName: string
  Description: string
  OrgName: string
  TrendID: number
  TrendName: string
  TrendRGB: string
  SeverityLevelID: number
  SeverityLevelName: string
  SeverityLevelRGB: string
  SeveritySequence: number
  tags: string
  Responsible: string
  GeoLocation: IssueGeolocation[]
}

interface QPickSeverity {
  SeverityLevelID: number
  SeverityLevelName: string
  SeverityLevelRGB: string
  SeveritySequence: number
}

interface QPickMode {
  IssueType: string
  IssueTypeDescr: string
}

interface QPickTags {
  Tag: string
}

interface QPickResponsible {
  Responsible: string
}

interface QPicklist {
  OrgID: number
  pickSeverity: [QPickSeverity]
  pickMode: [QPickMode]
  pickTags: [QPickTags]
  pickResponsible: [QPickResponsible]
  Issues: [QIssue]
}

type TableHeader = 'Issue' | 'Created' | 'Severity' | 'Resolved'
type SortDirection = 'asc' | 'dec' | 'none'
export type AdminQueryIssueTableOption =
  | 'Severity'
  | 'Trend'
  | 'Mode'
  | 'Tags'
  | 'Description'
  | 'Created'
  | 'Resolved'
  | 'Responsible'
  | 'Location'

export interface IssueSort {
  header: TableHeader
  sortDirection: SortDirection
}

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiInput-underline:after': {
      borderBottom: 'none', // Remove the underline when focused
    },
    '& .MuiInput-underline:before': {
      borderBottom: 'none', // Remove the underline when not focused
    },
  },
}))

export const AdminIssueQueryPanel = ({ OrgIDList }: Props) => {
  const muiClasses = useStyles()

  const [tableSelectorOpen, setTableSelectorOpen] = React.useState(false)

  const adminQueryTableOptions: AdminQueryIssueTableOption[] = [
    'Severity',
    'Trend',
    'Mode',
    'Tags',
    'Description',
    'Created',
    'Resolved',
    'Responsible',
    'Location',
  ]

  const defaultAdminQueryTableOptions: AdminQueryIssueTableOption[] = [
    'Severity',
    'Trend',
    'Mode',
    'Tags',
    'Description',
    'Created',
    'Resolved',
    'Responsible',
  ]

  const [adminQueryTableValues, setAdminQueryTableValues] = React.useState<
    AdminQueryIssueTableOption[]
  >(defaultAdminQueryTableOptions)

  const [adminQueryTableEditedValues, setAdminQueryTableEditedValues] =
    React.useState<AdminQueryIssueTableOption[]>(defaultAdminQueryTableOptions)

  const [isFetchingPicklist, setIsFetchingPickList] =
    React.useState<boolean>(false)
  const [pickListValues, setPickListValues] = React.useState<QPicklist>()
  const [issueSelected, setIssueSelected] = React.useState<QIssue>()
  const [severityID, setSeverityID] = React.useState<number>(-1)
  const [issueType, setIssueType] = React.useState<string>('--')
  const [responsible, setResponsible] = React.useState<string>('--')
  const [tags, setTags] = React.useState<string>('--')
  const [titleIncludes, setTitleIncludes] = React.useState<string>('')
  const [showResolved, setShowResolved] = React.useState<boolean>(true)
  const [issueCreatedDateEarliest, setIssueCreatedDateEarliest] =
    React.useState<Date>()
  const [issueCreatedDateLatest, setIssueCreatedDateLatest] =
    React.useState<Date>()
  const [issueSort, setIssueSort] = React.useState<IssueSort>({
    header: 'Created',
    sortDirection: 'dec',
  })
  const isMobile = useAppSelector((state) => isMobileApp(state))

  const isMounted = useIsMounted()

  React.useEffect(() => {
    const getPickListValues = async (): Promise<any> => {
      setIsFetchingPickList(true)

      let orgListRequest = ''

      OrgIDList.forEach((org) => (orgListRequest += `${org.OrgID}, `))

      var customHeaders: any = []
      customHeaders.push({ name: 'OrgIDList', value: `${orgListRequest}` })

      const result = await http<any>({
        path: '/Issue/IssueQueryPanelPicklist',
        headers: customHeaders,
      })
      if (result.ok && result.body) {
        setPickListValues(result.body)
      } else {
        setPickListValues(undefined)
      }
      setIsFetchingPickList(false)
    }
    getPickListValues()
  }, [OrgIDList])

  React.useEffect(() => {
    const getQueryPanelSettings = () => {
      queryPanelIssueSettings()
        .then((data) => {
          if (
            !isMounted() ||
            Object.keys(data.QueryPanelIssuesSettings).length < 1
          )
            return

          setAdminQueryTableValues(JSON.parse(data.QueryPanelIssuesSettings))
          setAdminQueryTableEditedValues(
            JSON.parse(data.QueryPanelIssuesSettings)
          )
        })
        .catch((e) => e)
    }

    getQueryPanelSettings()
  }, [isMounted])

  const addOrRemoveFromList = (selectionOption: AdminQueryIssueTableOption) => {
    const removeIndex = adminQueryTableEditedValues.findIndex((section) => {
      return section === selectionOption
    })

    if (removeIndex > -1) {
      removeQueryTableValue(removeIndex)
    } else {
      addQueryTableValue(selectionOption)
    }
  }

  const addQueryTableValue = (value: AdminQueryIssueTableOption) => {
    setAdminQueryTableEditedValues((values) => [...values, value])
  }

  const removeQueryTableValue = (removeIndex: number) => {
    const copyList = [...adminQueryTableEditedValues]

    copyList.splice(removeIndex, 1)
    setAdminQueryTableEditedValues(copyList)
  }

  const shouldDisplayColumn = (columnName: string) => {
    return adminQueryTableValues.find((column: string) => column === columnName)
  }

  function handleSeverityChange(evt: any) {
    let { value } = evt.target
    let intVal = parseInt(value)
    setSeverityID(intVal)
  }

  function handleIssueTypeChange(evt: any) {
    let { value } = evt.target
    setIssueType(value)
  }
  function handleResponsibleChange(evt: any) {
    let { value } = evt.target
    setResponsible(value)
  }
  function handleTagsChange(evt: any) {
    let { value } = evt.target
    setTags(value)
  }
  function handleTitleIncludesChange(evt: any) {
    let { value } = evt.target
    setTitleIncludes(value)
  }

  const setSortDirection = (header: TableHeader) => {
    if (header === issueSort.header) {
      if (issueSort.sortDirection === 'none') {
        setIssueSort({ header: header, sortDirection: 'dec' })
      } else if (issueSort.sortDirection === 'asc') {
        setIssueSort({ header: header, sortDirection: 'dec' })
      } else {
        setIssueSort({ header: header, sortDirection: 'asc' })
      }
    } else {
      setIssueSort({ header: header, sortDirection: 'asc' })
    }
  }

  const sortIssueList = (issueList: QIssue[] | undefined) => {
    if (issueSort.sortDirection === 'none') return

    switch (issueSort.header) {
      case 'Issue':
        if (issueSort.sortDirection === 'asc') {
          return issueList?.sort((a, b) =>
            a.IssueName.localeCompare(b.IssueName)
          )
        } else if (issueSort.sortDirection === 'dec') {
          return issueList?.sort(
            (a, b) => -1 * a.IssueName.localeCompare(b.IssueName)
          )
        }
        break

      case 'Severity':
        if (issueSort.sortDirection === 'dec') {
          return issueList?.sort((a, b) =>
            a.SeveritySequence < b.SeveritySequence ? 1 : -1
          )
        } else if (issueSort.sortDirection === 'asc') {
          return issueList?.sort((a, b) =>
            a.SeveritySequence > b.SeveritySequence ? 1 : -1
          )
        }
        break

      case 'Created':
        if (issueSort.sortDirection === 'dec') {
          return issueList?.sort((a, b) => {
            if (a.IssueCreated === b.IssueCreated) {
              return 0
            }
            return a.IssueCreated < b.IssueCreated ? 1 : -1
          })
        } else if (issueSort.sortDirection === 'asc') {
          return issueList?.sort((a, b) => {
            if (a.IssueCreated === b.IssueCreated) {
              return 0
            }
            return a.IssueCreated > b.IssueCreated ? 1 : -1
          })
        }
        break

      case 'Resolved':
        if (issueSort.sortDirection === 'dec') {
          return issueList?.sort((a, b) => {
            if (a.IssueResolved === b.IssueResolved) {
              return 0
            }
            return a.IssueResolved < b.IssueResolved ? 1 : -1
          })
        } else if (issueSort.sortDirection === 'asc') {
          return issueList?.sort((a, b) => {
            if (a.IssueResolved === b.IssueResolved) {
              return 0
            }
            return a.IssueResolved > b.IssueResolved ? 1 : -1
          })
        }
    }
  }

  const filterIssueList = (issueList: QIssue[] | undefined) => {
    var filtered: QIssue[] = []
    if (issueList) {
      for (var i = 0; i < issueList.length; i++) {
        let issue = issueList[i]
        var selected: boolean = true
        if (issueSelected && issueSelected.IssueID) {
          if (issueSelected.IssueID !== issue.IssueID) selected = false
        }
        if (severityID > 0) {
          if (issue.SeverityLevelID !== severityID) selected = false
        }
        if (issueType !== '--') {
          if (issue.IssueType !== issueType) selected = false
        }
        var icd = new Date(issue.IssueCreated)
        var icdb = addMinutesToDate(icd, 0 - 24 * 60)
        if (issueCreatedDateEarliest) {
          if (icd < issueCreatedDateEarliest) selected = false
        }
        if (issueCreatedDateLatest) {
          if (icdb > issueCreatedDateLatest) selected = false
        }
        if (responsible !== '--') {
          if (issue.Responsible !== responsible) selected = false
        }
        if (tags !== '--') {
          if (!(issue.tags && issue.tags.includes(tags))) selected = false
        }
        if (titleIncludes) {
          if (
            !(
              issue.IssueName &&
              issue.IssueName.toLowerCase().includes(
                titleIncludes.toLowerCase()
              )
            )
          )
            selected = false
        }
        if (!showResolved) {
          if (issue.IssueResolved) selected = false
        }
        if (selected) {
          filtered.push(issue)
        }
      }
      return filtered
    }
  }

  const issueList =
    issueSort.sortDirection === 'none'
      ? pickListValues?.Issues
      : sortIssueList(pickListValues?.Issues)

  const filteredIssueList = filterIssueList(issueList)

  //issueList: QIssue[]
  function excelExport() {
    if (!isMobile) {
      var excelFileName: string = 'IssueListing'
      if (excelFileName.length > 25) {
        excelFileName = excelFileName.substring(0, 25)
      }

      const severity = pickListValues?.pickSeverity.find(
        ({ SeverityLevelID, SeverityLevelName }) =>
          SeverityLevelID === severityID
      )

      var excelData: any[] = []
      const excelLegend: any[] = [
        {
          CreatedOn: new Date(),
          Issue: issueSelected?.IssueName,
          Severity: severity?.SeverityLevelName,
          Mode: issueType,
          StartDate: issueCreatedDateEarliest,
          EndDate: issueCreatedDateLatest,
          ShowResolved: showResolved ? 'Yes' : 'No',
          Responsible: responsible,
          Tag: tags,
          TitleIncludes: titleIncludes.toLowerCase(),
        },
      ]

      if (filteredIssueList)
        for (var i = 0; i < filteredIssueList.length; i++) {
          let issue = filteredIssueList[i]
          var dataRow: any = {}
          dataRow['Issue'] = issue.IssueName

          if (shouldDisplayColumn('Severity')) {
            dataRow['Severity'] = issue.SeverityLevelName
          }
          if (shouldDisplayColumn('Trend')) {
            dataRow['Trend'] = issue.TrendName
          }
          if (shouldDisplayColumn('Mode')) {
            dataRow['Mode'] = issue.SeverityLevelName
          }
          if (shouldDisplayColumn('Tags')) {
            dataRow['Tags'] = issue.tags
          }
          if (shouldDisplayColumn('Description')) {
            dataRow['Description'] = issue.Description
          }
          if (shouldDisplayColumn('Created')) {
            dataRow['Created'] = moment
              .utc(issue.IssueCreated)
              .local()
              .format('YYYY/MM/DD h:mm:ss A')
          }
          if (shouldDisplayColumn('Resolved')) {
            if (issue.IssueResolved) {
              dataRow['Resolved'] = moment
                .utc(issue.IssueResolved)
                .local()
                .format('YYYY/MM/DD h:mm:ss A')
            }
          }
          if (shouldDisplayColumn('Responsible')) {
            dataRow['Responsible'] = issue.Responsible
          }
          if (shouldDisplayColumn('Location')) {
            let concatLocation = ''
            issue?.GeoLocation?.forEach((location) => {
              concatLocation += `${location.Latitude}, ${location.Longitude}\n`
            })
            dataRow['Location'] = concatLocation
          }

          excelData.push(dataRow)
        }
      return (
        <>
          {!isMobile && excelData.length > 0 && (
            <AdminExportExcel
              excelLegend={excelLegend}
              excelData={excelData}
              fileName={excelFileName}
            />
          )}
        </>
      )
    }
  }

  function renderPanel() {
    if (isFetchingPicklist) return <CircularProgress />
    if (!pickListValues || !issueList || issueList.length < 1)
      return <div>There are no issues in this Organization</div>

    return (
      <>
        <div
          style={{
            display: 'flex',
            gridAutoRows: '1fr',
            gridTemplateColumns: '1fr 1fr 1fr',
            width: '100%',
            marginTop: '15px',
          }}
        >
          <div className="col-12 col-lg-4 mb-0">
            <div className={classes.queryControlItem}>
              <InputLabel style={{ fontSize: '12px' }}>Issue:</InputLabel>
              <FormControl className="form-control">
                <Autocomplete
                  className="form-control"
                  options={
                    issueList || [{ IssueName: 'no issues found' } as QIssue]
                  }
                  value={
                    issueSelected ||
                    ({ IssueID: -999, IssueName: '-- All Issues --' } as QIssue)
                  }
                  getOptionLabel={(issue) => {
                    return issue.IssueName
                  }}
                  getOptionSelected={(option) => !!option}
                  // getOptionSelected={(option: QIssue, value: QIssue) =>
                  //   option.IssueID === value.IssueID
                  // }
                  onChange={(event: any, newValue: any | null) => {
                    if (newValue && newValue.IssueName !== '-- All Issues --') {
                      setIssueSelected(newValue)
                    } else {
                      setIssueSelected(undefined)
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      key={params.id}
                      className={muiClasses.root}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          e.preventDefault()
                          e.stopPropagation()
                        }
                      }}
                      inputProps={{
                        ...params.inputProps,
                        onKeyDown: (e) => {
                          if (e.key === 'Enter') {
                            e.preventDefault()
                            e.stopPropagation()
                          }
                        },
                      }}
                    />
                  )}
                  autoComplete
                />
              </FormControl>
            </div>
            <div className={classes.queryControlItem}>
              <FormControl className="form-control">
                <InputLabel>Severity:</InputLabel>
                <Select
                  name="SeverityID"
                  native={true}
                  value={severityID}
                  onChange={handleSeverityChange}
                >
                  <option value="-1" key="severityUndf">
                    -- All Severity --
                  </option>
                  {pickListValues.pickSeverity.map(
                    ({ SeverityLevelID, SeverityLevelName }) => (
                      <option value={SeverityLevelID} key={SeverityLevelID}>
                        {SeverityLevelName}
                      </option>
                    )
                  )}
                </Select>
              </FormControl>
            </div>
            <div className={classes.queryControlItem}>
              <FormControl className="form-control">
                <InputLabel> Mode:</InputLabel>
                <Select
                  name="IssueTypeID"
                  native={true}
                  value={issueType}
                  onChange={handleIssueTypeChange}
                >
                  <option value="--" key="issueTypeUndf">
                    -- All Mode --
                  </option>
                  {pickListValues.pickMode.map(
                    ({ IssueType, IssueTypeDescr }) => (
                      <option value={IssueType} key={IssueType}>
                        {IssueTypeDescr}
                      </option>
                    )
                  )}
                </Select>
              </FormControl>
            </div>
          </div>
          <div className={'col-12 col-lg-4 mb-0'}>
            <div className={classes.queryControlItem}>
              <FormControl className="form-control">
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    clearable
                    format="MM/DD/YYYY"
                    label="Earliest Created Date"
                    maxDate={issueCreatedDateLatest}
                    onChange={(event: any, newValue: any | null) => {
                      if (newValue) {
                        let newDate = new Date(newValue)
                        setIssueCreatedDateEarliest(newDate)
                      } else {
                        setIssueCreatedDateEarliest(undefined)
                      }
                    }}
                    value={
                      issueCreatedDateEarliest ? issueCreatedDateEarliest : null
                    }
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                  />
                </MuiPickersUtilsProvider>
              </FormControl>
            </div>
            <div className={classes.queryControlItem}>
              <FormControl className="form-control">
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    clearable
                    format="MM/DD/YYYY"
                    label="Latest Created Date"
                    minDate={issueCreatedDateEarliest}
                    onChange={(event: any, newValue: any | null) => {
                      if (newValue) {
                        let newDate = new Date(newValue)
                        setIssueCreatedDateLatest(newDate)
                      } else {
                        setIssueCreatedDateLatest(undefined)
                      }
                    }}
                    value={
                      issueCreatedDateLatest ? issueCreatedDateLatest : null
                    }
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                  />
                </MuiPickersUtilsProvider>
              </FormControl>
            </div>
            <div
              className={classes.queryControlItem}
              style={{ paddingTop: 20 }}
            >
              <FormControl className="form-control">
                <InputGroup component="fieldset">
                  <CheckBox
                    checked={showResolved}
                    onChange={(event: any) => {
                      setShowResolved(event.target.checked)
                    }}
                  />
                  <span
                    style={{
                      position: 'relative',
                      top: 12,
                      cursor: 'pointer',
                      height: 5,
                      marginLeft: '-2px',
                    }}
                  >
                    Show Resolved Issues in List
                  </span>
                </InputGroup>
              </FormControl>
            </div>
          </div>

          <div className={'col-12 col-lg-4 mb-0'}>
            <div className={classes.queryControlItem}>
              <FormControl className="form-control">
                <InputLabel>Responsible:</InputLabel>
                <Select
                  name="Responsible"
                  native={true}
                  value={responsible}
                  onChange={handleResponsibleChange}
                >
                  <option value="--" key="issueRespUndf">
                    -- All Responsible --
                  </option>
                  {pickListValues.pickResponsible &&
                    pickListValues.pickResponsible.map(({ Responsible }) => (
                      <option value={Responsible} key={Responsible}>
                        {Responsible}
                      </option>
                    ))}
                </Select>
              </FormControl>
            </div>
            <div className={classes.queryControlItem}>
              <FormControl className="form-control">
                <InputLabel>Tags:</InputLabel>
                <Select
                  name="Tags"
                  native={true}
                  value={tags}
                  onChange={handleTagsChange}
                >
                  <option value="--" key="tagsUndf">
                    -- All Tags --
                  </option>
                  {pickListValues.pickTags &&
                    pickListValues.pickTags.map(({ Tag }) => (
                      <option value={Tag} key={Tag}>
                        {Tag}
                      </option>
                    ))}
                </Select>
              </FormControl>
            </div>
            <div className={classes.queryControlItem}>
              <InputLabel style={{ fontSize: '12px' }}>
                Title Includes:
              </InputLabel>
              <FormControl className="form-control">
                <Input
                  type="text"
                  name="TitleIncludes"
                  value={titleIncludes}
                  onChange={handleTitleIncludesChange}
                  placeholder="-- All --"
                />
              </FormControl>
            </div>
          </div>
        </div>
        <hr />
        {filteredIssueList && filteredIssueList.length > 0 && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',

              paddingRight: '10px',
            }}
          >
            <div>{filteredIssueList.length} issues match your query</div>
            <div style={{ display: 'flex' }}>
              <div
                onClick={() => setTableSelectorOpen(true)}
                style={{ color: '#3e91c5', cursor: 'pointer' }}
              >
                <FontAwesomeIcon icon={faColumns} /> Select Columns
              </div>
              <Spacer />
              <div>{excelExport()}</div>
            </div>
          </div>
        )}
        {filteredIssueList && filteredIssueList.length > 0 ? (
          <TableContainer style={{ width: '100%', marginTop: '10px' }}>
            <Table
              style={{ borderTop: '2px solid #000000', marginBottom: '100px' }}
            >
              <colgroup>
                <col style={{ width: '25%' }} />
                {shouldDisplayColumn('Severity') && (
                  <col style={{ width: '10%' }} />
                )}
                {shouldDisplayColumn('Trend') && (
                  <col style={{ width: '5%' }} />
                )}
                {shouldDisplayColumn('Mode') && <col style={{ width: '5%' }} />}
                {shouldDisplayColumn('Tags') && (
                  <col style={{ width: '10%' }} />
                )}
                {shouldDisplayColumn('Description') && (
                  <col style={{ width: '15%' }} />
                )}
                {shouldDisplayColumn('Created') && (
                  <col style={{ width: '15%' }} />
                )}
                {shouldDisplayColumn('Resolved') && (
                  <col style={{ width: '15%' }} />
                )}
                {shouldDisplayColumn('Responsible') && (
                  <col style={{ width: '15%' }} />
                )}
              </colgroup>
              <TableHead>
                <TableRow>
                  <TableCell onClick={() => setSortDirection('Issue')}>
                    Issue
                    {issueSort.header === 'Issue' &&
                    issueSort.sortDirection === 'dec' ? (
                      <FontAwesomeIcon icon={faArrowDown} />
                    ) : (
                      issueSort.header === 'Issue' && (
                        <FontAwesomeIcon icon={faArrowUp} />
                      )
                    )}
                  </TableCell>
                  {shouldDisplayColumn('Severity') && (
                    <TableCell onClick={() => setSortDirection('Severity')}>
                      Severity
                      {issueSort.header === 'Severity' &&
                      issueSort.sortDirection === 'dec' ? (
                        <FontAwesomeIcon icon={faArrowDown} />
                      ) : (
                        issueSort.header === 'Severity' && (
                          <FontAwesomeIcon icon={faArrowUp} />
                        )
                      )}
                    </TableCell>
                  )}
                  {shouldDisplayColumn('Trend') && (
                    <TableCell align="left">Trend</TableCell>
                  )}
                  {shouldDisplayColumn('Mode') && (
                    <TableCell align="left">Mode</TableCell>
                  )}
                  {shouldDisplayColumn('Tags') && (
                    <TableCell align="left">Tags</TableCell>
                  )}
                  {shouldDisplayColumn('Description') && (
                    <TableCell align="left">Description</TableCell>
                  )}
                  {shouldDisplayColumn('Created') && (
                    <TableCell onClick={() => setSortDirection('Created')}>
                      Created
                      {issueSort.header === 'Created' &&
                      issueSort.sortDirection === 'dec' ? (
                        <FontAwesomeIcon icon={faArrowDown} />
                      ) : (
                        issueSort.header === 'Created' && (
                          <FontAwesomeIcon icon={faArrowUp} />
                        )
                      )}
                    </TableCell>
                  )}
                  {shouldDisplayColumn('Resolved') && (
                    <TableCell onClick={() => setSortDirection('Resolved')}>
                      Resolved
                      {issueSort.header === 'Resolved' &&
                      issueSort.sortDirection === 'dec' ? (
                        <FontAwesomeIcon icon={faArrowDown} />
                      ) : (
                        issueSort.header === 'Resolved' && (
                          <FontAwesomeIcon icon={faArrowUp} />
                        )
                      )}
                    </TableCell>
                  )}
                  {shouldDisplayColumn('Responsible') && (
                    <TableCell align="left">Responsible</TableCell>
                  )}
                  {shouldDisplayColumn('Location') && (
                    <TableCell align="left">Location</TableCell>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredIssueList?.map((issue: QIssue, iqIdx: number) => {
                  return (
                    <TableRow
                      key={'filteredIssueList' + iqIdx.toString()}
                      style={{ position: 'relative' }}
                    >
                      <TableCell>{issue.IssueName}</TableCell>
                      {shouldDisplayColumn('Severity') && (
                        <TableCell>
                          <div className={classes.severity}>
                            <span
                              className={classes.severityColor}
                              style={{
                                backgroundColor: issue.SeverityLevelRGB,
                              }}
                            ></span>
                            <span>{issue.SeverityLevelName}</span>
                          </div>
                        </TableCell>
                      )}
                      {shouldDisplayColumn('Trend') && (
                        <TableCell>
                          <div
                            className={classes.trend}
                            style={{ color: issue.TrendRGB }}
                          >
                            {issue.TrendName}
                          </div>
                        </TableCell>
                      )}
                      {shouldDisplayColumn('Mode') && (
                        <TableCell>
                          <div>{issue.IssueMode}</div>
                        </TableCell>
                      )}
                      {shouldDisplayColumn('Tags') && (
                        <TableCell>
                          {issue.tags
                            ?.split(',')
                            ?.map((tag: string, tagIDX: number) => {
                              return <OrgTag TagName={tag} key={tagIDX} />
                            })}
                        </TableCell>
                      )}
                      {shouldDisplayColumn('Description') && (
                        <TableCell>
                          {issue.Description.length > 64
                            ? issue.Description.substring(0, 64) + ' ...'
                            : issue.Description}
                        </TableCell>
                      )}
                      {shouldDisplayColumn('Created') && (
                        <TableCell>
                          <Moment format="DD MMM YYYY" utc local>
                            {issue.IssueCreated}
                          </Moment>
                          <br />
                          <Moment utc local fromNow>
                            {issue.IssueCreated}
                          </Moment>
                        </TableCell>
                      )}
                      {shouldDisplayColumn('Resolved') && (
                        <TableCell>
                          {issue.IssueResolved && (
                            <>
                              <Moment format="DD MMM YYYY" utc local>
                                {issue.IssueResolved}
                              </Moment>
                              <br />
                              <Moment utc local fromNow>
                                {issue.IssueResolved}
                              </Moment>
                            </>
                          )}
                        </TableCell>
                      )}
                      {shouldDisplayColumn('Responsible') && (
                        <TableCell>{issue.Responsible}</TableCell>
                      )}
                      {shouldDisplayColumn('Location') && (
                        <TableCell>
                          {issue.GeoLocation?.map((location) => (
                            <div>
                              {location.Latitude}, {location.Longitude}
                            </div>
                          ))}
                        </TableCell>
                      )}
                      <div
                        style={{
                          position: 'absolute',
                          bottom: 0,
                          right: 0,
                          paddingRight: '10px',

                          fontSize: '12px',
                        }}
                      >
                        Organization: {issue.OrgName}
                      </div>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              fontSize: '20px',
            }}
          >
            {' '}
            There are no issues that match your query
          </div>
        )}
      </>
    )
  }
  return (
    <>
      {renderPanel()}

      <AdminIssueQueryTableSelector
        open={tableSelectorOpen}
        onClose={() => {
          setTableSelectorOpen(false)
          setAdminQueryTableEditedValues(adminQueryTableValues)
        }}
        onSave={() => {
          setTableSelectorOpen(false)
          setAdminQueryTableValues(adminQueryTableEditedValues)
          saveQueryPanelIssueSettings(
            JSON.stringify(adminQueryTableEditedValues)
          )
        }}
        onSelection={(selectionOption: AdminQueryIssueTableOption) => {
          addOrRemoveFromList(selectionOption)
        }}
        selectedOptions={adminQueryTableEditedValues}
        selectionList={adminQueryTableOptions}
      />
    </>
  )
}
