import {
  HoldingStatementConstants,
  UPDATE_RANGE_HOLDING_STATEMENTS,
  DELETE_RANGE_HOLDING_STATEMENTS,
} from './_constants'
import { reducerCrud } from '../_utils/reduxUtils'
import { HoldingStatementModel } from './_models'

interface HoldingStatementState {
  [HoldingStatementID: number]: HoldingStatementModel
}

export default function holdingStatementReducer(
  state: HoldingStatementState = {},
  action: any
) {
  switch (action.type) {
    case HoldingStatementConstants.FETCH.SUCCESS:
      return action.payload.result || {}
    case HoldingStatementConstants.APPROVE.SUCCESS: {
      const oldStatement = state[action.statement.HoldingStatementID]
      var approvedStatement = Object.assign({}, oldStatement, action.statement)
      return {
        ...state,
        [action.statement.HoldingStatementID]: approvedStatement,
      }
    }
    case HoldingStatementConstants.UPDATE.SUCCESS: {
      return {
        ...state,
        [action.statement.HoldingStatementID]: action.statement,
      }
    }
    case UPDATE_RANGE_HOLDING_STATEMENTS.SUCCESS:
      return reducerCrud.updateRange(state, action, 'HoldingStatementID')
    case DELETE_RANGE_HOLDING_STATEMENTS.SUCCESS:
      return reducerCrud.deleteIdRange(state, action.payload.result)

    default:
      return state
  }
}

export const selectHoldingStatementIdMap = (state: HoldingStatementState) =>
  state
export const selectHoldingStatementById = (
  state: HoldingStatementState,
  HoldingStatementID: number
) => selectHoldingStatementIdMap(state)[HoldingStatementID]
