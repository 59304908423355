// @ts-nocheck
// TODO: Typescript
import { generateCrudTypes, generateTypes } from '../_utils/reduxUtils'

export const ChatConstants = generateCrudTypes('CHAT')
export const ChatDetailConstants = generateCrudTypes('CHAT_DETAIL')
export const CREATE_DIRECT_MESSAGE = generateTypes('DIRECT_MESSAGE', 'CREATE')
export const CREATE_OWNER_DIRECT_MESSAGE = generateTypes(
  'OWNER_DIRECT_MESSAGE',
  'CREATE'
)
export const DELETE_DIRECT_MESSAGE = generateTypes('DIRECT_MESSAGE', 'DELETE')
export const FETCH_DIRECT_MESSAGES = generateTypes('DIRECT_MESSAGE', 'FETCH')

// Chat Read
export const UPDATE_CHAT_READ = generateTypes('UPDATE_CHAT_READ')
export const FETCH_CHAT_UNREAD_COUNT = generateTypes('FETCH_CHAT_UNREAD_COUNT')
export const INCREMENT_CHAT_UNREAD_COUNT = 'INCREMENT_UNREAD_CHAT_COUNT'
export const FETCH_LATEST_CHAT_DATES = generateTypes('FETCH_LATEST_CHAT_DATES')
export const SET_IS_VIEWING_CHAT = 'SET_IS_VIEWING_CHAT'
