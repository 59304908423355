import React, { Component } from 'react'
import { fetchInitialState } from './_actions'
import { connect } from 'react-redux'
import Push from 'push.js'
import { prefixSelectorWithApp } from './_utils/domUtils'
import { History } from 'history'
import {
  selectAuthPage,
  isUserAuthenticated,
  isUserAuthorized,
  isLoggingOut,
  selectRequestToken,
} from './Auth/_selectors'
import { RootState } from './_store'
import { CSSProperties } from 'react'
import { isMobileApp } from './_selectors'
import { isWebsocketDisconnected } from './_webSocket/_selectors'
import { LoadingBackDrop } from './Shared/LoadingBackdrop'

const appBlockerStyle: CSSProperties = {
  zIndex: 9999,
  position: 'absolute',
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  background: 'gray',
  opacity: 0.4,
}

interface Props {
  id?: string
  history: History
  isMobileApp?: boolean
  fetchInitialState(): void
  redirectToSSO: boolean
  ssoLogout?: boolean
  isUserAuthenticated?: boolean
  isUserAuthorized?: boolean
  isWebsocketDisconnected?: boolean
  credentials: { em: string; pw: string }
  isLoggingOut?: boolean
  csrfToken: string
}

class Startup extends Component<Props> {
  ssoFormLogin?: HTMLFormElement | null
  ssoFormOut?: HTMLFormElement | null
  componentDidMount() {
    const { isMobileApp } = this.props

    if (
      window.location.href.toLowerCase().indexOf('azuread/invalidtoken') === -1
    )
      this.props.fetchInitialState()

    if (!isMobileApp) Push.Permission.request(undefined, undefined)
  }

  componentDidUpdate() {
    if (this.props.redirectToSSO) this.ssoFormLogin?.submit()
    else if (this.props.ssoLogout) this.ssoFormOut?.submit()
  }

  render() {
    const {
      isWebsocketDisconnected,
      isUserAuthenticated,
      isUserAuthorized,
      isLoggingOut,
    } = this.props

    if (isLoggingOut)
      return (
        <>
          <LoadingBackDrop text="Logging out of account..." open={true} />
          <input
            type="hidden"
            id={prefixSelectorWithApp('csrfToken')}
            value={this.props.csrfToken}
          />
        </>
      )

    return (
      <div id={this.props.id}>
        {isWebsocketDisconnected && isUserAuthenticated && isUserAuthorized ? (
          <div style={appBlockerStyle} />
        ) : null}
        {this.props.children}
        <form
          method="POST"
          action="SSO/Login"
          ref={(el) => (this.ssoFormLogin = el)}
        >
          <input
            type="hidden"
            name="em"
            id={prefixSelectorWithApp('sso-em')}
            value={this.props.credentials?.em}
          />
          <input
            type="hidden"
            name="pw"
            id={prefixSelectorWithApp('sso-pw')}
            value={this.props.credentials?.pw}
          />
          <input
            type="hidden"
            id={prefixSelectorWithApp('csrfToken')}
            value={this.props.csrfToken}
          />
        </form>

        <form
          method="POST"
          action="SSO/LogOut"
          ref={(el) => (this.ssoFormOut = el)}
        >
          <input
            type="hidden"
            id={prefixSelectorWithApp('csrfToken-logout')}
            value={this.props.csrfToken}
          />
        </form>
      </div>
    )
  }
}

const mapStateToProps = (state: RootState) => {
  const { redirectToSSO, ssoLogout, credentials } = selectAuthPage(state)
  return {
    redirectToSSO,
    ssoLogout,
    credentials: credentials || {},
    isWebsocketDisconnected: isWebsocketDisconnected(state),
    isUserAuthenticated: isUserAuthenticated(state),
    isMobileApp: isMobileApp(state),
    isUserAuthorized: isUserAuthorized(state),
    isLoggingOut: isLoggingOut(state),
    csrfToken: selectRequestToken(state),
  }
}

const mapDispatchToProps = {
  fetchInitialState,
}

// @ts-expect-error
export default connect(mapStateToProps, mapDispatchToProps)(Startup)
