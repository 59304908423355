// @ts-nocheck
// TODO: Typescript
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { ParticipantActions, ParticipantTeamActions } from './_actions'
import { connect } from 'react-redux'
import { InputAndSubmitButton } from '../Shared/InputAndSubmitButton'
import { addNotification } from '../_actions'
import {
  Tooltip,
  InputLabel,
  Select,
  FormControl,
  ListItemText,
  MenuItem,
} from '@material-ui/core'
import { isEmail } from '../_utils/objectUtils'
import { Button } from 'reactstrap'
import classes from './ParticipantCreator.module.scss'
import inputSubmitClasses from '../Shared/InputAndSubmitButton.module.scss'
import { cx } from '../_utils/objectUtils'
import Checkbox from '../CustomMaterial/Checkbox'
import ConnectedItem from '../Shared/ConnectedItem'
import {
  canUserAdministerWorkspace,
  canUserApproveWorkspace,
  selectOrgIssueTeamById,
} from '../_rootConfigs/rootSelectors'
import {
  selectWorkspaceById,
  selectWorkspaceOrgIssueTeamIds,
} from '../Workspace/_selectors'
import { OrgUgisMembers } from '../Org/OrgUgisMembers'

const submitLabel = 'Invite'
/**
 * @extends {Component<Props, {}>}}
 */
export class ParticipantCreator extends Component {
  constructor(props) {
    super(props)

    this.state = {
      participantEmail: '', // Must state with a value or else a warning will appear
      inviteType: 'participant',
      IssueOrgTeam: [],
    }

    this.handleEmailChange = this.handleEmailChange.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleButton_onClick = this.handleButton_onClick.bind(this)
  }

  render() {
    const {
      orgIssueTeamIds,
      isAdmin,
      isApprover,
      formatSelectedOrgTeams,
      OrgID,
    } = this.props
    let { disabled } = this.props

    if (!disabled && !isAdmin && !isApprover) disabled = true

    const { inviteType, participantEmail, IssueOrgTeam } = this.state
    return (
      <Tooltip
        title="Must be an admin or approver for this action"
        disableHoverListener={isAdmin || isApprover}
        disableFocusListener={isAdmin || isApprover}
        disableTouchListener={isAdmin || isApprover}
      >
        <div>
          <div className={cx(classes.inputContainer, 'p-3')}>
            <div className={classes.inviteType}>
              <FormControl
                className={cx(
                  'form-control',
                  inputSubmitClasses.inputFormControl
                )}
              >
                <InputLabel>Invite Type</InputLabel>
                <Select
                  native={true}
                  disabled={disabled}
                  value={inviteType}
                  name="inviteType"
                  onChange={this.handleInputChange}
                >
                  <option value="participant">Participant</option>
                  <option value="team">Team</option>
                </Select>
              </FormControl>
            </div>
            {inviteType === 'participant' ? (
              <InputAndSubmitButton
                className={classes.orgUgisMembers}
                name="email"
                variant="material"
                inputLabel="Email Address"
                submitLabel={submitLabel}
                onSubmit={this.handleButton_onClick}
                disabled={disabled}
                input={
                  <OrgUgisMembers
                    inputLabel="Email Address"
                    OrgID={OrgID}
                    onChange={this.handleEmailChange}
                    inputValue={participantEmail}
                    onEnterPressed={this.handleButton_onClick}
                    value={participantEmail}
                    disabled={disabled}
                  />
                }
              />
            ) : (
              <div className={cx(inputSubmitClasses.root, 'input-group')}>
                <FormControl
                  className={cx(
                    'form-control',
                    inputSubmitClasses.inputFormControl
                  )}
                >
                  <InputLabel>Org Team</InputLabel>
                  <Select
                    name="IssueOrgTeam"
                    native={false}
                    multiple={true}
                    className={disabled ? inputSubmitClasses.disabled : ''}
                    onChange={this.handleInputChange}
                    disabled={disabled}
                    value={IssueOrgTeam}
                    placeholder="Select Teams"
                    renderValue={(selected) =>
                      formatSelectedOrgTeams(selected).join(', ')
                    }
                  >
                    {orgIssueTeamIds.map((id) => (
                      <MenuItem key={id} value={id} disabled={disabled}>
                        <Checkbox checked={IssueOrgTeam.indexOf(id) > -1} />
                        <ConnectedItem
                          selectItemFromStore={(state) =>
                            selectOrgIssueTeamById(state, id)
                          }
                        >
                          {(team) => <ListItemText primary={team.TeamName} />}
                        </ConnectedItem>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <div className="input-group-append">
                  <Button
                    className={cx(disabled ? inputSubmitClasses.disabled : '')}
                    color="primary"
                    onClick={this.handleButton_onClick}
                    disabled={disabled}
                  >
                    {submitLabel}
                  </Button>
                </div>
              </div>
            )}
          </div>
        </div>
      </Tooltip>
    )
  }

  handleInputChange({ target }) {
    const { name, value } = target

    if (name === 'inviteType') {
      this.setState({
        [name]: value,
        participantEmail: '',
        IssueOrgTeam: [],
      })
    } else {
      this.setState({
        [name]: value,
      })
    }
  }

  handleEmailChange = (participantEmail) => {
    this.setState({ participantEmail })
  }

  handleButton_onClick = () => {
    const { inviteType } = this.state
    let email_address = (this.state.participantEmail || '').trim()
    const { IssueTeamWorkspaceID } = this.props

    if (inviteType === 'participant') {
      if (isEmail(email_address)) {
        const { addParticipant } = this.props
        addParticipant({ email_address, IssueTeamWorkspaceID })
        this.handleEmailChange('')
      } else {
        const { onAddParticipantError } = this.props
        onAddParticipantError('Email is not valid')
      }
    } else {
      const { addParticipantTeam, onAssignTeamsRequested } = this.props
      addParticipantTeam(
        this.state.IssueOrgTeam.map((TeamID) => ({ TeamID })),
        { IssueTeamWorkspaceID }
      )
      onAssignTeamsRequested()
    }

    this.setState({
      participantEmail: '',
      IssueOrgTeam: [],
    })
  }
}

ParticipantCreator.propTypes = {
  addParticipant: PropTypes.func,
  onAddParticipantError: PropTypes.func,
  IssueTeamWorkspaceID: PropTypes.number,
  onAssignTeamsRequested: PropTypes.func,
}

ParticipantCreator.defaultProps = {
  addParticipant: function () {},
  onAddParticipantError: function () {},
  orgIssueTeamIds: [],
  onAssignTeamsRequested: () => {},
}

const mapStateToProps = (state, ownProps) => {
  const { IssueTeamWorkspaceID } = ownProps
  const wksp = selectWorkspaceById(state, IssueTeamWorkspaceID)
  const { OrgID } = wksp
  return {
    isAdmin: canUserAdministerWorkspace(state, IssueTeamWorkspaceID),
    isApprover: canUserApproveWorkspace(state, IssueTeamWorkspaceID),
    orgIssueTeamIds: selectWorkspaceOrgIssueTeamIds(
      state,
      ownProps.IssueTeamWorkspaceID
    ),
    OrgID,
    formatSelectedOrgTeams: (teamIds) =>
      (teamIds || []).map(
        (id) => (selectOrgIssueTeamById(state, id) || {}).TeamName
      ),
  }
}

const mapPropsToDispatch = {
  addParticipant: ParticipantActions.add,
  onAddParticipantError: (message) => addNotification({ message }),
  addParticipantTeam: ParticipantTeamActions.addRange,
}

export default connect(mapStateToProps, mapPropsToDispatch)(ParticipantCreator)
