// @ts-nocheck
// TODO: Typescript
import React, { Component } from 'react'
import { TaskDetailActions } from './_actions'
import { connect } from 'react-redux'
import { InputAndSubmitButton } from '../Shared/InputAndSubmitButton'
import { Switch } from '@material-ui/core'

/**
 * @extends {Component<Props, {}>}}
 */
class TaskListEditorToolbar extends Component {
  constructor(props) {
    super(props)
    this.state = {
      TaskItemText: '',
      TaskItemHeaderYN: 'N',
    }
    this.handleItemTextChange = this.handleItemTextChange.bind(this)
    this.handleTaskItemAdd = this.handleTaskItemAdd.bind(this)
    this.handleTaskItemHeaderClick = this.handleTaskItemHeaderClick.bind(this)
  }

  render() {
    const { TaskID, disabled } = this.props
    const { TaskItemText } = this.state

    if (!TaskID) return null

    const isHeader =
      this.state.TaskItemHeaderYN === 'Y' || TaskItemText.endsWith(':')

    return (
      <div style={{ padding: '.5rem' }}>
        <div
          style={{
            marginLeft: '5px',
            display: 'flex',
            justifyContent: 'end',
            alignItems: 'center',
          }}
        >
          <label style={{ padding: '0', margin: '0' }}>Task Item</label>
          <Switch
            checked={isHeader}
            onChange={this.handleTaskItemHeaderClick}
            inputProps={{
              'aria-label': 'controlled',
            }}
          />
          <label style={{ padding: '0', margin: '0' }}>Header</label>
        </div>

        <InputAndSubmitButton
          variant="material"
          value={TaskItemText}
          onChange={this.handleItemTextChange}
          onSubmit={this.handleTaskItemAdd}
          inputLabel={isHeader ? 'New Task Header' : 'New Task Item'}
          submitLabel="Add"
          disabled={disabled}
        />
      </div>
    )
  }

  handleItemTextChange(TaskItemText) {
    this.setState({
      TaskItemText,
    })
  }

  handleTaskItemHeaderClick() {
    this.setState((state) => {
      return { TaskItemHeaderYN: state.TaskItemHeaderYN === 'Y' ? 'N' : 'Y' }
    })
  }

  handleTaskItemAdd() {
    const { TaskItemText, TaskItemHeaderYN } = this.state
    const { TaskID, scrollRef } = this.props

    this.props.addTaskItem({
      TaskItemText,
      TaskID,
      TaskItemHeaderYN,
    })

    this.setState({
      TaskItemText: '',
      TaskItemHeaderYN: 'N',
    })
    setTimeout(() => scrollRef?.current?.scrollIntoView(), 200)
  }
}

const mapDispatchToProps = {
  addTaskItem: TaskDetailActions.add,
}

export default connect(undefined, mapDispatchToProps)(TaskListEditorToolbar)
