import * as React from 'react'
import { CSSProperties } from 'react'
import AppInfo from './AppInfo'
import { Button } from 'reactstrap'
import AppDialog from './AppDialog'

import {
  selectIsDevelopment,
  selectPortalSite,
  selectUserOrgInfo,
} from './Issue/_selectors'
import { useAppSelector } from './_utils/reactReduxHooks'
import openAdminIcon from './_images/openAdminIcon.svg'
import openAdminIconDisabled from './_images/openAdminIconDisabled.svg'
import { selectPortalUser } from './PortalUser/_selectors'
import { Tooltip } from '@material-ui/core'
import { useRef } from 'react'
import { selectUserEmail } from './_rootConfigs/rootSelectors'
import { AppDomain } from './_models'

interface Props {
  EmailAddress: string
  onCloseClicked: () => void // Don't make defaulted props optional or elese Typescript will think it can be possibly undefined
  onLogoutClicked?: () => void
  onSettingsClicked?: () => void
  hasInvalidMsTeamsContext: boolean
  domain: AppDomain
}

const leftConStyles: CSSProperties = {
  overflow: 'hidden',
  paddingRight: 8,
}

const adminIconStyle: CSSProperties = {
  width: 20,
}

export const AppAccountInfo = ({
  EmailAddress = '',
  onCloseClicked = () => {},
  hasInvalidMsTeamsContext = false,
  domain,
  onLogoutClicked,
  onSettingsClicked,
}: Props) => {
  const userOrgInfo: any =
    useAppSelector((state) => selectUserOrgInfo(state)) || []
  const orgNames = userOrgInfo.Orgs?.map((org: any) => org.OrgName)

  const portalSite: string = useAppSelector(selectPortalSite)

  const portalUser = useAppSelector(selectPortalUser) || {}
  const userEmail = useAppSelector(selectUserEmail)
  const adminForm = useRef<HTMLFormElement>(null)
  const isDevEnv = useAppSelector(selectIsDevelopment)

  function renderAdditionalOrgs() {
    let names = []
    for (let i = 0; i < orgNames.length; i++)
      names.push(<li key={orgNames[i]}>{orgNames[i]}</li>)

    return names
  }

  function handlePortalLogin(evt: React.MouseEvent<HTMLAnchorElement>) {
    evt.preventDefault()
    if (portalUser.UserID) adminForm.current?.submit()
  }

  return (
    <AppDialog onCloseClicked={onCloseClicked}>
      <div className="pb-3" style={/*useInfoConStyles*/ undefined}>
        <div style={leftConStyles}>
          <p className="mw-100 text-break">
            <span>
              Logged in as: <b>{EmailAddress}</b>
            </span>
          </p>
          {orgNames && orgNames.length > 0 && (
            <div className="mw-100 mb-3 text-break">
              <span>Organizations:</span>
              {orgNames.length > 1 && (
                <>
                  <br />

                  {<ul>{renderAdditionalOrgs()}</ul>}
                </>
              )}
            </div>
          )}
          <p className="mw-100 text-break">
            <Button color="link" onClick={onSettingsClicked}>
              User Settings
            </Button>
          </p>
        </div>
        <hr />
        <div style={/*rightConStyles*/ undefined}>
          <p className="mw-100 text-break">
            <Tooltip
              title="Portal account required"
              disableHoverListener={portalUser.UserID ? true : false}
            >
              <a
                aria-disabled={!portalUser.UserID ? 'true' : 'false'}
                href={`${portalSite}/MyPlans/login_icoc.aspx`}
                target="_blank"
                onClick={handlePortalLogin}
                rel="noreferrer"
              >
                Administrator Login{' '}
                <img
                  style={adminIconStyle}
                  src={
                    !portalUser.UserID ? openAdminIconDisabled : openAdminIcon
                  }
                  alt="Open admin logo"
                />
              </a>
            </Tooltip>
          </p>
          <Button color="link" onClick={onLogoutClicked}>
            Log Out
          </Button>
          {isDevEnv && (
            <p className="m-0 mt-3">
              <a href="/swaggerDoc/index.html" target="_blank">
                Swagger
              </a>
            </p>
          )}
          <form
            ref={adminForm}
            action={`${portalSite}/MyPlans/login_icoc.aspx`}
            method="post"
            target="_blank"
          >
            <input type="hidden" name="em" value={userEmail} />
          </form>
        </div>
      </div>
      {hasInvalidMsTeamsContext && (
        <p className="mt-1 alert alert-warning" role="alert">
          The current logged in account differs from your Microsoft account.
        </p>
      )}
      <hr />
      <AppInfo />
    </AppDialog>
  )
}
