// @ts-nocheck
// TODO: Typescript
import React, { Component } from 'react'
import { List } from '@material-ui/core'
import PropTypes from 'prop-types'
import ConferenceListItem from './ConferenceListItem'

/**
 * @extends {Component<Props, {}>}}
 */
export default class ConferenceList extends Component {
  
  render() {
    const {
      conferenceIds,
      IssueTeamWorkspaceID,
      onDeleteClicked,
      onOpenDialogClicked,
      onEditInfoClicked,
    } = this.props
    if (conferenceIds.length === 0) return <span>No Meetings</span>

    return (
      <List className="p-1">
        {conferenceIds.map((id) => (
          <ConferenceListItem
            // active={active === id ? true : false}
            key={id}
            ConferenceID={id}
            IssueTeamWorkspaceID={IssueTeamWorkspaceID}
            onDeleteConfirm={onDeleteClicked}
            onOpenDialog={onOpenDialogClicked}
            onEditInfo={onEditInfoClicked}
          />
        ))}
      </List>
    )
  }
}

ConferenceList.propTypes = {
  conferenceIds: PropTypes.array,
  IssueTeamWorkspaceID: PropTypes.number,
  active: PropTypes.number,
  onEditInfoClicked: PropTypes.func,
  onDeleteClicked: PropTypes.func,
  onOpenDialogClicked: PropTypes.func,
}

ConferenceList.defaultProps = {
  conferenceIds: [],
}
