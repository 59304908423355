import * as React from 'react'
import { Button } from 'reactstrap'
import { prefixWithApp } from '../_utils/reduxUtils'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'

export interface BreadCrumbProps {
  label: string
}

interface Props {
  crumbs: BreadCrumbProps[]
  onCrumbClick?(index: number): void
}

export const spacing = 'pr-1 pl-1'
export const BreadCrumbs = ({
  crumbs = [],
  onCrumbClick = () => {},
}: Props) => (
  <div className={prefixWithApp('BreadCrumbs_NDJUZE')}>
    {crumbs[0] && <h3>{crumbs[0].label}</h3>}
    {crumbs.map((c: BreadCrumbProps, index: number) => {
      if (index === 0 && crumbs.length > 1) {
        return (
          <Button
            key={index}
            color="link"
            className={spacing}
            onClick={() => {
              onCrumbClick(index)
            }}
          >
            <FontAwesomeIcon icon={faChevronLeft} />
          </Button>
        )
      } else if (index === 0 && crumbs.length === 1) return null

      let crumbEl = (
        <span key={index} className={spacing}>
          {c.label}
        </span>
      )

      if (crumbs.length > 1 && index < crumbs.length - 1)
        crumbEl = (
          <Button
            key={index}
            color="link"
            className={spacing}
            onClick={() => {
              onCrumbClick(index)
            }}
          >
            {c.label}
          </Button>
        )

      if (crumbs.length - (index + 1) > 0)
        return <span key={'withSlash' + index}>{crumbEl} /</span>

      return crumbEl
    })}
  </div>
)
