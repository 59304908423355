import {
  bindSel,
  getHoldingStatement,
} from '../_rootConfigs/rootReducerSlicers'
import * as priv from './_reducer'

export const selectHoldingStatementIdMap = bindSel(
  getHoldingStatement,
  priv.selectHoldingStatementIdMap
)
export const selectHoldingStatementById = bindSel(
  getHoldingStatement,
  priv.selectHoldingStatementById
)
