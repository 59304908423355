// @ts-nocheck
// TODO: Typescript
import { closeNotification, appLoaded, addNotification } from '../_actions'
import { sessionWarningClosed } from '../Auth/_actions'
import { AppConstants } from '../_constants'
import { LogoutConstants, LoginConstants } from '../Auth/_constants'
import { replace } from 'connected-react-router'
import LogRocket from 'logrocket'
import { selectUserEmail } from '../_rootConfigs/rootSelectors'
import { selectUserAppAcctId } from '../Auth/_selectors'
import { parseAsyncAction, requestStatus as req } from '../_utils/reduxUtils'
import { ErrorModel } from '../_models'

export default function createAppMiddleware() {
  return (storeAPI) => (next) => (action) => {
    let { initialState, delay } = action
    initialState = initialState || {}
    switch (action.type) {
      // When a new notification is added the current notification should be closed
      case AppConstants.ADD_NOTIFICATION:
        storeAPI.dispatch(closeNotification())
        break
      case AppConstants.FETCH_INIT_DELAY:
        setTimeout(() => {
          storeAPI.dispatch(
            appLoaded(initialState, initialState.isAuthenticated)
          )
        }, delay)
        break
      case AppConstants.FETCH_INIT.COMPLETE:
        // If the user is already authenticated, then close session warning on app load for all tabs
        if (initialState && initialState.isAuthenticated) {
          updateLogRocketIdentify(storeAPI, action)
          storeAPI.dispatch(sessionWarningClosed())
        }
        break
      case LogoutConstants.SUCCESSFUL:
        storeAPI.dispatch(replace('/'))
        //updateLogRocketIdentify(storeAPI, action, true);
        break
      case LoginConstants.SUCCESSFUL:
        updateLogRocketIdentify(storeAPI, action)
        break
      default:
        if (parseAsyncAction(action.type)?.status === req.FAILURE) {
          const error = (action.payload?.error ||
            action.payload?.ErrorMessage) as ErrorModel | string
          let notyObject: ErrorModel = {
            type: 'error',
            duration: 3000,
            hasCloseBtn: true,
          }

          if (typeof error == 'string') notyObject.message = error
          else {
            notyObject = {
              ...notyObject,
              ...error,
            }
          }
          if (error !== 'CancelledByUser') {
            storeAPI.dispatch(addNotification(notyObject))
          }
        }
    }

    return next(action)
  }
}

function updateLogRocketIdentify(storeAPI, action, isLoggedOut) {
  const state = storeAPI.getState()
  const email =
    action && !isLoggedOut
      ? action.initialState.user.EmailAddress
      : selectUserEmail(state) // Extra checks because the reducer state is not updated before the middleware
  const AppAcctId =
    action && !isLoggedOut
      ? action.initialState.user.AppAcctID
      : selectUserAppAcctId(state) // Extra checks because the reducer state is not updated before the middleware
  LogRocket.identify(isLoggedOut ? `${email} (Logged Out)` : email, {
    email,
    AppAcctId,
  })
}
