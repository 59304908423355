import * as React from 'react'
import classes from './Playbooks.module.scss'
import Moment from 'react-moment'
import { cx } from '../_utils/objectUtils'
import { faRedo } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useHistory } from 'react-router'

interface Props {
  PubGUID: string
  PubTitle: string
  Image: { Graphic: string; format: string }
  Version: string
  Updated: Date
  OrgName: string
  Keywords: string
  onClick?(pub: PbItemProps): void
  selected?: boolean
  AuthType: string
  PublicationTag: string
}
export interface PbItemProps extends Props {}

const updatePasswordBtnIfNeeded = (
  AuthType: string,
  navigate2AddPlaybooks: () => void
) => {
  //if (AuthType == "password" || AuthType == "none") {
  if (AuthType === 'badpass') {
    return (
      <div className={cx('float-right')}>
        <FontAwesomeIcon
          className={classes.rdsYellow}
          icon={faRedo}
          onClick={() => {
            //alert("go to the add playbook page to fix this");
            navigate2AddPlaybooks()
            // history.push({ '/AddPlaybook'});
          }}
        />
      </div>
    )
  }
  return <></>
}

export const PbItem = ({
  PubGUID,
  PubTitle,
  Image,
  Version,
  Updated,
  OrgName,
  Keywords,
  onClick = () => {},
  selected,
  AuthType,
  PublicationTag,
}: PbItemProps) => {
  const history = useHistory()
  const navigate2AddPlaybooks = () => {
    history.push('/AddPlaybook')
  }

  return (
    <div
      className={cx(
        classes.icoItem,
        'Ico-Item-root',
        selected ? 'Ico-selected' : ''
      )}
    >
      <div
        className={cx(classes.clickBox, 'clearfix position-relative')}
        onClick={() => {
          onClick({
            PubGUID,
            PubTitle,
            Image,
            Version,
            Updated,
            OrgName,
            Keywords,
            AuthType,
            PublicationTag,
          })
        }}
      >
        <div className={cx('float-left', classes.planImg)}>
          <img
            alt={PubTitle}
            src={'data:image/' + Image?.format + ';base64,' + Image?.Graphic}
          />
        </div>
        <div className={cx('float-left', classes.planSpec)}>
          <span style={{ fontWeight: 'bold' }}>{PubTitle}</span>
          <br />
          <span style={{ fontSize: '87.5%' }}>
            {OrgName}
            &nbsp;-&nbsp; v. {Version}
            &nbsp;-&nbsp;
            <Moment format="DD MMM YYYY" utc local>
              {Updated}
            </Moment>
          </span>
        </div>
        {updatePasswordBtnIfNeeded(AuthType, navigate2AddPlaybooks)}
      </div>
    </div>
  )
}
