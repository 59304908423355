import * as React from 'react'
import {
  Bar,
  BarChart,
  Cell,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from 'recharts'
import { DashboardPlaybookModel } from './DashboardModels'
import { useHistory } from 'react-router'

export const DashboardPlaybookCollaborationSection = ({
  playbooks,
}: {
  playbooks?: DashboardPlaybookModel[]
}) => {
  let totalSecureChats = 0
  let totalSharedChecklist = 0

  const history = useHistory()

  playbooks?.forEach((playbook) => {
    totalSecureChats += playbook.SecureChatCount
    totalSharedChecklist += playbook.SharedChecklistCount
  })
  const barData = [
    {
      name: 'Secure Chats ' + totalSecureChats.toString(),
      count: totalSecureChats,
      color: '#4372C4',
    },
    {
      name: 'Shared Checklist ' + totalSharedChecklist.toString(),
      count: totalSharedChecklist,
      color: '#548235',
    },
  ]

  const CustomizedAxisTick = (props: any) => {
    const { x, y, payload } = props

    return (
      <g transform={`translate(${x},${y})`}>
        <text
          x={0}
          y={0}
          dy={16}
          textAnchor="middle"
          fill="#3e91c5"
          style={{ cursor: 'pointer' }}
        >
          {payload.value}
        </text>
      </g>
    )
  }

  return (
    <div
      style={{
        position: 'relative',
        display: 'flex',
        width: '100%',
        height: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
      }}
    >
      {!totalSecureChats && !totalSharedChecklist ? (
        <div>No Playbook Collaboration</div>
      ) : (
        <ResponsiveContainer
          style={{ marginRight: '20px' }}
          width="100%"
          height="90%"
        >
          <BarChart data={barData}>
            <XAxis
              dataKey="name"
              tick={<CustomizedAxisTick />}
              onClick={(entry: any) => {
                const split = entry.value.replace(/\s\d+$/, '')
                if (split === 'Secure Chats') {
                  history.push('/PbChat')
                } else if (split === 'Shared Checklist') {
                  history.push('/PbTask')
                }
              }}
            />
            <YAxis type="number" allowDecimals={false} interval={1} />
            <Bar dataKey="count">
              {barData.map((data, index) => (
                <Cell key={index} fill={data.color} />
              ))}
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      )}
    </div>
  )
}
