export const ampersandCleanup = (input: string | undefined) => {
  return input === undefined ? input : input.split('&amp;').join('&')
}

export function titleNiceEnding(title: string) {
  let retChar = ''
  if (title && title.trim().length > 0) {
    let trimmedTitle = title.trim()
    retChar = ':'
    if (trimmedTitle.endsWith('?')) retChar = ''
    if (trimmedTitle.endsWith(':')) retChar = ''
  }
  return retChar
}
