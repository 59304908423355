// @ts-nocheck
// TODO: Typescript
import { WebSocketConstants } from './_constants'

export function connectionSuccessful() {
  return { type: WebSocketConstants.CONNECTION_SUCCESSFUL }
}

export function connectionFailure(retryTime) {
  return { type: WebSocketConstants.CONNECTION_FAILURE, retryTime }
}

export function connectionRetry(retryTime) {
  return { type: WebSocketConstants.CONNECTION_RETRY, retryTime }
}

export function connectionDisconnected(retryTime) {
  return { type: WebSocketConstants.CONNECTION_DISCONNECTED, retryTime }
}

export function connecting() {
  return { type: WebSocketConstants.CONNECTING }
}

export function connectionRetrySuccessful(retryTime) {
  return { type: WebSocketConstants.CONNECTION_RETRY_SUCCESSFUL, retryTime }
}

export function connectionRetryFailure(retryTime) {
  return { type: WebSocketConstants.CONNECTION_RETRY_FAILURE, retryTime }
}

export function forceDropConnection() {
  return { type: WebSocketConstants.FORCE_DROP_CONNECTION }
}

export function forceDropConnectionSuccessful() {
  return { type: WebSocketConstants.FORCE_DROP_CONNECTION_SUCCESSFUL }
}
