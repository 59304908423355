import React from 'react'
import { CircularProgress, Dialog, MenuItem } from '@material-ui/core'
import { Spacer } from '../Shared/Spacer'
import { orgMeetingLinks } from '../_utils/apiUtils'
import { OrgMeetingLink } from '../Admin/AdminConsoleMeetingLinks'

export interface MeetingLink {
  linkDescription: string
  link: string
}

export const MeetingLinkSelectDialog = ({
  onSelect,
  onCancel,
  open,
  orgId,
}: {
  onSelect: (meeting: MeetingLink) => void
  onCancel: () => void
  open: boolean
  orgId: number
}) => {
  const [fetchingMeetingLinks, setFetchingMeetingLinks] = React.useState(false)

  const [meetingLinks, setMeetingLinks] = React.useState<
    OrgMeetingLink[] | undefined
  >()
  React.useEffect(() => {
    if (!open) return
    setFetchingMeetingLinks(true)

    orgMeetingLinks(orgId).then((data) => {
      setMeetingLinks(data)
      setFetchingMeetingLinks(false)
    })
  }, [orgId, open])

  if (fetchingMeetingLinks) return <CircularProgress />

  return (
    <Dialog
      open={open}
      onClose={(_, reason) => reason === 'backdropClick' && onCancel()}
    >
      <div style={{ height: '400px', width: '550px' }}>
        <div
          style={{
            display: 'flex',
            justifyContent: ' center',
            alignItems: 'center',
            fontSize: '24px',
            borderBottom: '1px solid grey',
            height: '70px',
            position: 'sticky',
            top: 0,
            backgroundColor: 'white',
            zIndex: '2',
          }}
        >
          Select a Meeting Link
        </div>

        {meetingLinks?.map((link) => (
          <MenuItem
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              borderBottom: '1px solid grey',
            }}
            onClick={() => {
              onSelect({ link: link.Link, linkDescription: link.Description })
              onCancel()
            }}
          >
            <div>Link Description: {link.Description}</div>
            <Spacer />
            <div>Link: {link.Link}</div>
          </MenuItem>
        ))}
      </div>
    </Dialog>
  )
}
