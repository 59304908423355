// @ts-nocheck
// TODO: Typescript
import React, { Component } from 'react'
import { connect } from 'react-redux'
import {
  StretchLayout,
  StretchBody,
  StretchFooter,
} from '../Layouts/StretchLayout'
import ParticipantCreator from '../Participant/ParticipantCreator'
import PropTypes from 'prop-types'
import { ParticipantTable } from '../Participant/ParticipantTable'
import {
  selectWorkspaceAwaitingAcctParticipantIds,
  selectWorkspaceNonTeamParticipantIds,
  selectWorkspaceTeamParticipantIds,
} from '../_rootConfigs/rootSelectors'

const listHeaderStyle = {
  background: 'lightgray',
  fontWeight: 'bold',
  padding: 10,
}

class WorkspaceParticipantViewer extends Component {
  render() {
    const {
      onAssignTeamsRequested,
      teamParticipantIds,
      otherParticipantIds,
      awaitingAccountParticipantIds,
      IssueTeamWorkspaceID,
      disabled,
      className,
    } = this.props
    return (
      <StretchLayout className={className}>
        <StretchBody>
          {teamParticipantIds.length > 0 && (
            <div className="pb-3">
              <div style={listHeaderStyle}>Team Members</div>
              <ParticipantTable
                IssueTeamWorkspaceID={IssueTeamWorkspaceID}
                participantIds={teamParticipantIds}
                disabled={disabled}
              />
            </div>
          )}
          {otherParticipantIds.length > 0 && (
            <div className="pb-3">
              <ParticipantTable
                IssueTeamWorkspaceID={IssueTeamWorkspaceID}
                participantIds={otherParticipantIds}
                disabled={disabled}
              />
            </div>
          )}
          {awaitingAccountParticipantIds.length > 0 && (
            <div className="pb-3">
              <div style={listHeaderStyle}>Awaiting Account</div>
              <ParticipantTable
                IssueTeamWorkspaceID={IssueTeamWorkspaceID}
                participantIds={awaitingAccountParticipantIds}
                disabled={disabled}
              />
            </div>
          )}
        </StretchBody>
        <StretchFooter>
          <ParticipantCreator
            IssueTeamWorkspaceID={IssueTeamWorkspaceID}
            disabled={disabled}
            onAssignTeamsRequested={onAssignTeamsRequested}
          />
        </StretchFooter>
      </StretchLayout>
    )
  }
}

WorkspaceParticipantViewer.propTypes = {
  disabled: PropTypes.bool,
  onAssignTeamsRequested: PropTypes.func,
}

WorkspaceParticipantViewer.defaultProps = {
  teamParticipantIds: [],
  otherParticipantIds: [],
  awaitingAccountParticipantIds: [],
  onAssignTeamsRequested: () => {},
}

const mapStateToProps = (state, ownProps) => ({
  teamParticipantIds: selectWorkspaceTeamParticipantIds(
    state,
    ownProps.IssueTeamWorkspaceID
  ),
  otherParticipantIds: selectWorkspaceNonTeamParticipantIds(
    state,
    ownProps.IssueTeamWorkspaceID
  ),
  awaitingAccountParticipantIds: selectWorkspaceAwaitingAcctParticipantIds(
    state,
    ownProps.IssueTeamWorkspaceID
  ),
})

export default connect(mapStateToProps, undefined)(WorkspaceParticipantViewer)
