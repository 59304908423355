import { bindSel, getWorkspace } from '../_rootConfigs/rootReducerSlicers'
import * as priv from './_reducer'

export const selectWorkspacesByIdMap = bindSel(
  getWorkspace,
  priv.selectWorkspacesByIdMap
)
export const selectWorkspaceChatCount = bindSel(
  getWorkspace,
  priv.selectWorkspaceChatCount
)
export const selectWorkspaceTaskCount = bindSel(
  getWorkspace,
  priv.selectWorkspaceTaskCount
)
export const selectWorkspaceAllChatCounts = bindSel(
  getWorkspace,
  priv.selectWorkspaceAllChatCounts
)
export const selectWorkspaceParticipantCount = bindSel(
  getWorkspace,
  priv.selectWorkspaceParticipantCount
)
export const selectWorkspaceArtifactCount = bindSel(
  getWorkspace,
  priv.selectWorkspaceArtifactCount
)
export const selectWorkspaceById = bindSel(
  getWorkspace,
  priv.selectWorkspaceById
)
export const selectWorkspaceChatIds = bindSel(
  getWorkspace,
  priv.selectWorkspaceChatIds
)
export const selectWorkspaceTaskIds = bindSel(
  getWorkspace,
  priv.selectWorkspaceTaskIds
)
export const selectWorkspaceOrgIssueTeamIds = bindSel(
  getWorkspace,
  priv.selectWorkspaceOrgIssueTeamIds
)
export const selectWorkspaceParticipantIds = bindSel(
  getWorkspace,
  priv.selectWorkspaceParticipantIds
)
export const selectWorkspaceArtifactIds = bindSel(
  getWorkspace,
  priv.selectWorkspaceArtifactIds
)
export const selectWorkspaceUpdateForms = bindSel(
  getWorkspace,
  priv.selectWorkspaceUpdateForms
)
export const selectWorkspaceUpdateFormCount = bindSel(
  getWorkspace,
  priv.selectWorkspaceUpdateFormCount
)
export const selectWorkspaceDirectMessageIds = bindSel(
  getWorkspace,
  priv.selectWorkspaceDirectMessageIds
)
export const selectWorkspaceConferenceIds = bindSel(
  getWorkspace,
  priv.selectWorkspaceConferenceIds
)
export const selectWorkspacePathId = bindSel(
  getWorkspace,
  priv.selectWorkspacePathId
)
