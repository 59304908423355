import * as React from 'react'
import { HPanelLayout, HPanel } from '../Layouts/HPanelLayout'
import { MasterPageLayout } from './MasterPageLayout'
import { DesktopLayout, MobileLayout } from '../Layouts/ResponsiveHidden'
import MobileViewLayout from '../Layouts/MobileViewLayout'
import { RouteComponentProps } from 'react-router'
import { PbTaskRouteParams } from '../Routing/RouteProps'
import { cx } from '../_utils/objectUtils'
import classes from '../Playbook/Playbooks.module.scss'
import { PbTaskList } from '../PbTask/PbTaskList'
import { PbTaskProps } from '../PbTask/PbTask'
import { PbTaskDetail } from '../PbTask/PbTaskDetail'

interface Props extends RouteComponentProps<PbTaskRouteParams> {}

export const PbTaskPage = ({ history, location, match }: Props) => {
  function handlePbTaskClick(newTask: PbTaskProps, isMobile: boolean = false) {
    if (newTask.TaskGUID !== match.params.TaskGUID)
      navigate({ TaskGUID: newTask.TaskGUID })
  }

  function handleNavigateBack() {
    history.push({
      pathname: '/PbTask',
    })
  }

  function navigate(params: PbTaskRouteParams = {}) {
    if (params.TaskGUID === undefined) {
      history.push({ pathname: '/PbTask/PbTask' })
      return
    }

    history.push({
      pathname: `/PbTask/PbTask/${params?.TaskGUID}`,
    })
  }

  function handlePbTaskClickMobile(newPub: PbTaskProps) {
    handlePbTaskClick(newPub, true)
  }

  return (
    <MasterPageLayout
      history={history}
      match={match as any}
      location={location}
    >
      <DesktopLayout>
        <HPanelLayout>
          <HPanel
            header="Shared Checklists Listing"
            hasPaper={false}
            overflowY="hidden"
          >
            <PbTaskList
              className={classes.pbList}
              onItemClick={handlePbTaskClick}
              selected={match.params.TaskGUID}
            />
          </HPanel>

          <HPanel header="" fillWidth={true}>
            {match.params.TaskGUID && (
              <PbTaskDetail
                className={classes.pbEvDetail}
                TaskGUID={match.params.TaskGUID}
              />
            )}
          </HPanel>
        </HPanelLayout>
      </DesktopLayout>
      <MobileLayout>
        {match.params.TaskGUID ? (
          <MobileViewLayout
            title="Shared Checklist"
            onNavClick={handleNavigateBack}
          >
            <PbTaskDetail
              className={classes.pbEvDetail}
              TaskGUID={match.params.TaskGUID}
            />
          </MobileViewLayout>
        ) : (
          <div className={cx(classes.pbListContainer, classes.mobile)}>
            <PbTaskList
              hasPaper={false}
              className={classes.pbList}
              onItemClick={handlePbTaskClickMobile}
            />
          </div>
        )}
      </MobileLayout>
    </MasterPageLayout>
  )
}
