// @ts-nocheck
// TODO: Typescript
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { getArtifactLink } from './_reducer'
import { connect } from 'react-redux'
import { getArtifactId } from '../_schema/_schemas'
import { ArtifactContentViewer } from './ArtifactContentViewer'
import { getFilenameExt } from '../_utils/fileUtils'
import { selectArtifactById } from './_selectors'
import { selectArtifactMediaType } from '../_rootConfigs/rootSelectors'
import { DialogContent, Toolbar } from '@material-ui/core'
import { ResponsiveDialog } from '../Shared/ResponsiveDialog'
import { HeaderNavigation } from '../Shared/HeaderNavigation'

/**
 * @extends {Component<Props, {}>}}
 * Will render a lightbox
 */
export class ArtifactLightbox extends Component {
  constructor(props) {
    super(props)
    this.state = {
      photoIndex: props.startIndex || 0,
    }
  }

  componentDidMount() {
    this.currentArtifact = this.getArtifact(this.state.photoIndex)
  }

  componentDidUpdate(prevProps) {
    // Find the current artifact and go back to that spot
    // This is required when the underlying list is changed
    const { artifacts } = this.props
    if (prevProps.artifacts !== artifacts) {
      const photoIndex = artifacts.findIndex((art) =>
        this.artifactsEqual(art, this.currentArtifact)
      )
      this.setState({
        photoIndex,
      })
    } else this.currentArtifact = this.getArtifact(this.state.photoIndex)
  }

  getPreviousIndex() {
    const { photoIndex } = this.state
    const { artifacts } = this.props
    return (photoIndex + artifacts.length - 1) % artifacts.length
  }

  getNextIndex() {
    const { photoIndex } = this.state
    const { artifacts } = this.props
    return (photoIndex + 1) % artifacts.length
  }

  getCurrentIndex() {
    return this.state.photoIndex
  }

  getCurrent() {
    return this.getArtifact(this.state.photoIndex)
  }

  getArtifactComponent(index) {
    const { selectArtifactMediaTypeById } = this.props
    const artifact = this.getArtifact(index)
    const { ArtifactType, ExternalStorage } = artifact
    const mediaType = selectArtifactMediaTypeById(getArtifactId(artifact))
    const ext = getFilenameExt(artifact.ArtifactName).toLocaleLowerCase()

    if (!artifact) return null

    if (
      ArtifactContentViewer.isNormalLink({
        ArtifactExt: ext,
        mediaType,
        ExternalStorage,
      })
    ) {
      return (
        <img
          style={{ width: '100%', height: 'auto' }}
          src={getArtifactLink(artifact)}
          alt={getArtifactLink(artifact)}
        ></img>
      )
    }

    return (
      <ArtifactContentViewer
        className="ril__image"
        ArtifactType={ArtifactType}
        ArtifactExt={ext}
        mediaType={mediaType}
        downloadLink={getArtifactLink(artifact)}
        style={{ width: '70%', maxHeight: '70%' }}
        ExternalStorage={ExternalStorage}
      />
    )
  }

  getArtifactThumbnail(index) {
    const { selectArtifactMediaTypeById } = this.props
    const artifact = this.getArtifact(index)
    const mediaType = selectArtifactMediaTypeById(getArtifactId(artifact))
    if (!artifact) return null
    if (mediaType === 'image') return artifact.Thumbnail

    return artifact.Thumbnail
  }

  getArtifact(index) {
    const { selectArtifactById, artifacts } = this.props
    if (selectArtifactById) return selectArtifactById(artifacts[index])
    else return artifacts[index]
  }

  artifactsEqual(artA, artB) {
    // Id comparison
    const { selectArtifactById } = this.props
    if (!selectArtifactById) return artA === artB

    // Compare by object
    return getArtifactId(artA) === getArtifactId(artB)
  }

  render() {
    const { onCloseRequest } = this.props
    if (this.getCurrentIndex() === null) return null

    const currentArtifact = this.getCurrent() || {}
    const { ArtifactName } = currentArtifact

    const ext = getFilenameExt(ArtifactName).toLocaleLowerCase()

    return (
      <ResponsiveDialog open={!!currentArtifact} onClose={onCloseRequest}>
        <Toolbar>
          <HeaderNavigation
            title=""
            onNavClick={onCloseRequest as any}
            canGoBack={false}
          />
        </Toolbar>
        <DialogContent
          style={
            ext === 'pdf'
              ? { height: '100vh' }
              : {
                  minHeight: '200px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }
          }
        >
          {this.getArtifactComponent(this.getCurrentIndex())}
        </DialogContent>
      </ResponsiveDialog>
    )
  }
}

ArtifactLightbox.propTypes = {
  onCloseRequest: PropTypes.func.isRequired,
  artifacts: PropTypes.instanceOf(Array),
  startIndex: PropTypes.number,
}

ArtifactLightbox.defaultProps = {
  artifacts: [],
}

const mapStateToProps = (state) => ({
  selectArtifactById: (artifactId) => selectArtifactById(state, artifactId),
  selectArtifactMediaTypeById: (artifactId) =>
    selectArtifactMediaType(state, artifactId),
})

export default connect(mapStateToProps, undefined)(ArtifactLightbox)
