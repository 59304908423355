// @ts-nocheck
// TODO: Typescript
import React, { Component, Fragment } from 'react'
import { ChatDetailList } from './ChatDetailList'
import {
  ChatDetailActions,
  deleteDirectMessage,
  setIsViewingChat,
  updateChatRead,
} from './_actions'
import { connect } from 'react-redux'
import { addNotification, closeNotification } from '../_actions'
import { PropTypes } from 'prop-types'
import { ChatMessageEditor } from './ChatMessageEditor'
import {
  StretchLayout,
  StretchBody,
  StretchFooter,
  StretchHeader,
} from '../Layouts/StretchLayout'
import UploadDialog from '../Upload/UploadDialog'
import { UploadEntityTypes } from '../Upload/_constants'
import { ChatSearch } from './ChatSearch'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faSearch,
  faPlus,
  faCaretUp,
  faCaretDown,
} from '@fortawesome/free-solid-svg-icons'
import { ArtifactLightbox } from '../Upload/ArtifactLightbox'
import { getArtifactId } from '../_schema/_schemas'
import classes from './ChatDetailEditor.module.scss'
import { cx } from '../_utils/objectUtils'
import { TagAutocomplete } from '../Shared/TagAutocomplete'
import { ChatListMenu } from './ChatListMenu'
import { Button } from 'reactstrap'
import { CircularProgress as IcoCicularProgress } from '../Shared/LoadingBackdrop'
import { ChatDirectMessageItem } from './ChatDirectMessageItem'
import { CircularProgress, List } from '@material-ui/core'
import { ConfirmDialog } from '../Shared/ConfirmDialog'
import { ViewDialog } from '../Shared/ViewDialog'
import {
  doesChatExist,
  isChatRefreshReadRequired,
  selectChatById,
  selectChatEntries,
  selectChatEntryIds,
} from './_selectors'
import {
  selectArtifactMediaType,
  selectArtifactsByChatEntryIds,
} from '../_rootConfigs/rootSelectors'
import { isDeletingDirectMessage } from '../Shared/_requestStatusSelectors'

const chatSearchClasses = {
  header: classes.chatSearchHeader,
}

//const unreadDelayMins = 1;
//const unreadDelay = 1000 * 60 * unreadDelayMins;
const unreadDelaySecs = 30
const unreadDelay = 1000 * unreadDelaySecs

/**
 * @extends {Component<Props, {}>}}
 *
 */
export class ChatDetailEditor extends Component {
  constructor(props) {
    super(props)
    this.textInputEl = undefined
    this.sendBtnEl = undefined
    this.handleSend = this.handleSend.bind(this)
    this.sendOnEnter = this.sendOnEnter.bind(this)
    this.getListHeight = this.getListHeight.bind(this)
    this.handleSendContainerHeight = this.handleSendContainerHeight.bind(this)
    this.handleFileUploadChange = this.handleFileUploadChange.bind(this)
    this.closeUploadDialog = this.closeUploadDialog.bind(this)

    this.handleChatMenuToggle = this.handleChatMenuToggle.bind(this)
    this.handleChatMenuClose = this.handleChatMenuClose.bind(this)
    this.handleChatSearchBlur = this.handleChatSearchBlur.bind(this)
    this.handleCreateChatClicked = this.handleCreateChatClicked.bind(this)
    this.handleChatMenuOpen = this.handleChatMenuOpen.bind(this)
    this.handleReturnToChatList = this.handleReturnToChatList.bind(this)
    this.handleDirectMessageCancel = this.handleDirectMessageCancel.bind(this)
    this.handleCreateNewChat = this.handleCreateNewChat.bind(this)
    this.handleRecipientsChanged = this.handleRecipientsChanged.bind(this)
    this.handleChatAutoCompleteMenuClosed =
      this.handleChatAutoCompleteMenuClosed.bind(this)
    this.handleChatAutoCompleteMenuOpened =
      this.handleChatAutoCompleteMenuOpened.bind(this)
    this.handleOnChatDeleteClick = this.handleOnChatDeleteClick.bind(this)
    this.handleConfirmChatDelete = this.handleConfirmChatDelete.bind(this)
    this.handleCancelChatDelete = this.handleCancelChatDelete.bind(this)
    this.handleOnChatViewClick = this.handleOnChatViewClick.bind(this)
    this.handleCancelChatView = this.handleCancelChatView.bind(this)
    this.searchApi = undefined
    this._unreadTimer = undefined
    this._isMounted = false

    this.state = {
      sendContainerHeight: 0,
      uploadInfo: undefined, // Stores upload info from the file picker
      searchPane: true,
      lightboxStartIndex: null,
      isChatMenuOpened: false,
      isChatAutoCompleteOpened: false,
      isChatAutoCompleteMenuOpened: false,
      availableParticipants: [],
      chatToDelete: null,
      isDeleteDialogOpen: false,
      chatToView: null,
    }

    this.isChatAutoCompleteEmpty = false
    this.handleOnClickThumbnail = this.handleOnClickThumbnail.bind(this)
    this.handleLightboxClose = this.handleLightboxClose.bind(this)
  }

  componentDidMount() {
    this._isMounted = true
    this.convertPrivateChats()
    const { ChatID, updateChatRead, setIsViewingChat } = this.props

    setIsViewingChat(ChatID, true)

    updateChatRead(ChatID)
    this._unreadTimer = setInterval(() => {
      if (!this._isMounted) return
      const { isRefreshReadRequired } = this.props
      if (isRefreshReadRequired) updateChatRead(ChatID)
    }, unreadDelay)
  }

  componentDidUpdate(prevProps) {
    if (prevProps.directMessages !== this.props.directMessages)
      this.convertPrivateChats()
  }

  componentWillUnmount() {
    this._isMounted = false
    const { updateChatRead, ChatID, setIsViewingChat } = this.props

    setIsViewingChat(ChatID, false)
    updateChatRead(ChatID)
    clearTimeout(this._unreadTimer)
  }

  convertPrivateChats() {
    const { directMessages, participants } = this.props
    const dupDmSet = new Set()
    let newDirectMessages = []
    let availableParticipants = []
    for (let i = 0; i < directMessages.length; i++) {
      const curChat = directMessages[i]
      const participantCombined = curChat.Participants.map(
        (part) => part?.EmailAddress
      ).join(', ')
      const availableChat = {
        ChatKey: participantCombined,
        ChatName: participantCombined,
        ChatID: curChat.ChatID,
        count: curChat.Participants.length,
      }
      newDirectMessages.push(availableChat)
      availableParticipants.push(availableChat)
      dupDmSet.add(participantCombined)
    }

    ;(participants || []).forEach((part) => {
      // Only add the participant if they haven't been added as a direct message
      part = part || {}
      if (!dupDmSet.has(part.email_address))
        availableParticipants.push({
          ChatKey: part.email_address,
          ChatName: part.email_address,
          count: 1,
        })
    })

    this.setState({
      directMessages: newDirectMessages,
      availableParticipants,
    })
  }

  sendOnEnter(event) {
    var enter = 13
    if (event.keyCode === enter) {
      this.handleSend(event)
    }
  }

  handleCancelChatDelete() {
    this.setState({
      isDeleteDialogOpen: false /*,
            chatToDelete: null*/,
    })
  }

  handleConfirmChatDelete() {
    const { chatToDelete } = this.state
    const { sendChatDelete } = this.props
    sendChatDelete(chatToDelete)
    this.setState({
      isDeleteDialogOpen: false,
    })
  }

  handleOnChatDeleteClick(chatToDelete) {
    this.setState({
      chatToDelete,
      isDeleteDialogOpen: true,
      chatToView: null,
    })
  }

  handleOnChatViewClick(chatToView) {
    this.setState({
      chatToView,
      chatToDelete: null,
      isDeleteDialogOpen: false,
    })
  }

  handleCancelChatView() {
    this.setState({
      chatToView: null,
    })
  }

  handleChatAutoCompleteMenuOpened() {
    this.setState({
      isChatAutoCompleteMenuOpened: true,
      chatToDelete: null,
      chatToView: null,
      isDeleteDialogOpen: false,
    })
  }

  handleChatAutoCompleteMenuClosed() {
    this.setState({
      isChatAutoCompleteMenuOpened: false,
    })
  }

  handleChatSearchBlur() {
    const { isChatAutoCompleteOpened } = this.state
    const { ChatID, ChatName, hasRecipents, onChatSelected } = this.props

    if (isChatAutoCompleteOpened) onChatSelected({ ChatID, ChatName })

    // If the chat already exist when moving away from the search, close the entire chat menu
    if (ChatID && hasRecipents) this.handleChatMenuClose()
  }

  handleChatMenuClose() {
    this.setState({
      isChatMenuOpened: false,
      isChatAutoCompleteOpened: false,
    })
  }

  handleChatMenuToggle() {
    this.setState({
      isChatMenuOpened: !this.state.isChatMenuOpened,
      isChatAutoCompleteOpened: false,
      chatToDelete: null,
      chatToView: null,
    })
  }

  handleChatMenuOpen() {
    this.setState({
      isChatMenuOpened: true,
      isChatAutoCompleteOpened: false,
      chatToDelete: null,
      chatToView: null,
    })
  }

  handleReturnToChatList() {
    const { onReturnToChatList } = this.props
    this.handleChatMenuOpen()
    onReturnToChatList()
  }

  handleCreateNewChat() {
    const { onCreateChatClicked } = this.props
    this.setState({
      isChatMenuOpened: false,
      isChatAutoCompleteOpened: false,
      chatToDelete: null,
    })

    onCreateChatClicked()
  }

  handleDirectMessageCancel() {
    const { onCancelDirectMessage } = this.props
    onCancelDirectMessage()
    this.handleChatMenuClose()
  }

  handleRecipientsChanged(selected, selectedMap, isEmpty) {
    if (this.isChatAutoCompleteEmpty !== isEmpty) {
      this.isChatAutoCompleteEmpty = isEmpty
      if (isEmpty) this.handleChatAutoCompleteMenuClosed()
    }
    this.props.onRecipientsChanged(selected, selectedMap, isEmpty)
  }

  handleCreateChatClicked() {
    this.setState({
      isChatMenuOpened: false,
      isChatAutoCompleteOpened: true,
      chatToDelete: null,
      chatToView: null,
    })

    this.props.onRecipientsChanged([], {}, false)
  }

  handleSend(obj) {
    if (obj.error)
      this.props.onError({
        message: obj.error,
        type: 'error',
        duration: 2000,
      })
    else this.props.sendChatDetail(this.props.ChatID, obj.value)
  }

  handleSendContainerHeight(sendContainerHeight) {
    this.setState({
      sendContainerHeight,
    })
  }

  closeUploadDialog() {
    this.setState({
      uploadInfo: null,
    })
  }

  handleFileUploadChange(file) {
    const { ChatID, issueWorkspaceID } = this.props
    if (file) {
      this.setState({
        uploadInfo: {
          file,
          entity: {
            IssueTeamWorkspaceID: issueWorkspaceID,
            ChatID,
          },
        },
      })
    }
  }

  handleOnClickThumbnail(artifactID) {
    const { artifacts } = this.props
    const lightboxStartIndex = artifacts.findIndex(
      (curArtifact) => getArtifactId(curArtifact) === artifactID
    )
    this.setState({
      lightboxStartIndex,
    })
  }

  handleLightboxClose() {
    this.setState({
      lightboxStartIndex: null,
    })
  }

  getListHeight() {
    const rootHeight = this.props.height
    const { sendContainerHeight } = this.state
    const offset = 4
    return rootHeight - sendContainerHeight - offset
    //return (this._bodyEl || {}).offsetHeight || 0;
  }

  openSearch() {
    this.setState({
      searchPane: true,
      chatToDelete: null,
      chatToView: null,
    })
  }

  getRecipientSearchText() {
    const { canModifyDirectMessages } = this.props
    return canModifyDirectMessages
      ? 'Enter recipients to begin communcation'
      : 'Search private chats'
  }

  renderDirectMessage() {
    const {
      ChatID,
      hasRecipents,
      doesChatExist,
      disabled,
      disableCreate,
      isCreating,
      isFetchingChatEntries,
      canModifyDirectMessages,
    } = this.props
    const style = {
      maxWidth: 500,
      margin: 'auto',
      position: 'relative',
    }

    if (isCreating || isFetchingChatEntries) {
      //const text = isCreating ? 'Creating Chat...' : 'Fetching Messages...';
      //return <LoadingBackDrop className={classes.progress} text={text} open={true} />;
      return <IcoCicularProgress />
    } else if (this.state.isChatAutoCompleteOpened) {
      if (!hasRecipents) {
        return (
          <div style={style}>
            <div className="mb-2">{this.getRecipientSearchText()}</div>
            <span className="w-100 d-inline-block mb-2">
              <Button
                className="w-100"
                color="primary"
                onClick={this.handleReturnToChatList}
              >
                Return to Chat List
              </Button>
            </span>
            <span className="w-100 d-inline-block mb-2">
              <Button
                className="w-100"
                color="secondary"
                onClick={this.handleDirectMessageCancel}
              >
                Cancel
              </Button>
            </span>
          </div>
        )
      } else if (!ChatID) {
        if (!canModifyDirectMessages) {
          return (
            <div style={style}>
              You don't have permission to start a direct message
            </div>
          )
        }

        return (
          <div style={style}>
            {/*<div className="mb-2">Begin sending messages to start or click "Create Private Chat"</div >*/}
            <span className="w-100 d-inline-block mb-2">
              <Button
                className="w-100"
                color="primary"
                onClick={this.handleCreateNewChat}
                disabled={disabled || disableCreate}
              >
                Create Private Chat
              </Button>
            </span>
            <span className="w-100 d-inline-block mb-2">
              <Button
                className="w-100"
                color="secondary"
                onClick={this.handleDirectMessageCancel}
              >
                Cancel
              </Button>
            </span>
          </div>
        )
      }
    } else if (ChatID && !doesChatExist(ChatID)) {
      return <div style={style}>This chat no longer exists</div>
    }

    return null
  }

  renderParticipantList() {
    const selectedChat = this.state.chatToDelete || this.state.chatToView

    const { issueWorkspaceID } = this.props

    return (
      <StretchLayout>
        {this.state.chatToDelete && (
          <StretchHeader>
            Are you sure you want to delete this private chat?
            <br />
            <br />
            <h6>Chat Participants</h6>
          </StretchHeader>
        )}
        <StretchBody className={classes.participantList}>
          <List component="div">
            {((selectedChat || {}).ChatName || '')
              .split(',')
              .map((partEmail) => (
                <ChatDirectMessageItem
                  key={partEmail}
                  ChatName={partEmail}
                  className="pt-0 text-break"
                  issueWorkspaceID={issueWorkspaceID}
                />
              ))}
          </List>
        </StretchBody>
      </StretchLayout>
    )
  }

  render() {
    const {
      ChatID,
      ChatName,
      chatEntryIds,
      height,
      showChatName,
      selectArtifactMediaTypeById,
      sendBtnLabel,
      chatEntries,
      artifacts,
      className,
      teamChats,
      onChatSelected,
      isFetchingChatEntries,
      isCreating,
      canModifyDirectMessages,
      IssueName,
      issueWorkspaceID,
    } = this.props

    let { disabled } = this.props
    if (!ChatID || isFetchingChatEntries || isCreating) disabled = true

    const { directMessages, availableParticipants } = this.state

    const ownClasses = this.props.classes || {}
    //if (!ChatID) return null;
    const { uploadInfo, searchPane } = this.state
    const { entity, file } = uploadInfo || {}

    const directMessageComp = this.renderDirectMessage()
    if (searchPane) {
      return (
        <StretchLayout
          className={cx(
            classes.root,
            className,
            ownClasses.root,
            this.state.isChatAutoCompleteOpened
              ? classes.autocompleteOpened
              : ''
          )}
          itemRef={(el) => (this._rootEl = el)}
          height={height}
          border={true}
        >
          {!this.state.isChatAutoCompleteOpened && (
            <Fragment>
              <ChatListMenu
                open={this.state.isChatMenuOpened}
                onClose={this.handleChatMenuClose}
                onCreateClicked={this.handleCreateChatClicked}
                onDeleteChatClick={this.handleOnChatDeleteClick}
                onViewChatClick={this.handleOnChatViewClick}
                onChatSelected={onChatSelected}
                directMessages={directMessages}
                teamChats={teamChats}
                selectedChatId={ChatID}
                IssueName={IssueName}
                searchListIcon={canModifyDirectMessages ? faPlus : faSearch}
                issueWorkspaceID={issueWorkspaceID}
              />
              <ConfirmDialog
                open={
                  this.state
                    .isDeleteDialogOpen /*|| isDeletingDirectMessage(this.state.chatToDelete)*/
                }
                title="Delete Private Chat"
                /*content={isDeletingDirectMessage(this.state.chatToDelete) ?
                                    <LoadingBackDrop className={classes.progress} text="Deleting Pirivate Chat" open={true} /> 
                                    :
                                    this.renderParticipantList()
                                }*/
                content={this.renderParticipantList()}
                confirm={this.handleConfirmChatDelete}
                cancel={this.handleCancelChatDelete}
              />
              <ViewDialog
                open={this.state.chatToView ? true : false}
                title="Chat Participants"
                content={this.renderParticipantList()}
                onClose={this.handleCancelChatView}
              />
            </Fragment>
          )}
          <StretchBody
            className="overflow-hidden"
            itemRef={(el) => (this._bodyEl = el)}
          >
            <ChatSearch
              showSearch={directMessageComp ? false : true}
              classes={chatSearchClasses}
              chatEntries={chatEntries}
              onClickThumbnail={this.handleOnClickThumbnail}
              listName={
                isCreating ? (
                  <CircularProgress
                    color="inherit"
                    disableShrink={true}
                    size={20}
                  />
                ) : !showChatName ? (
                  ''
                ) : (
                  ChatName || ''
                )
              }
              height={this.getListHeight()}
              style={{ paddingRight: 0 }}
              onApi={this.props.onSearchApi}
              headerActions={
                <Fragment>
                  {/*<div className={cx(classes.settingsTrigger, this.state.isChatMenuOpened ? 'd-none' : '')}>
                                        <FontAwesomeIcon
                                            icon={faEdit}
                                        />
                                    </div>*/}
                  <div className={classes.menuTrigger}>
                    {/*<FontAwesomeIcon
                                            onClick={this.handleChatMenuToggle}
                                            className={this.state.isChatMenuOpened ? classes.menuOpened : ""}
                                            icon={faEllipsisH}
                                        />*/}
                    {this.state.isChatMenuOpened ? (
                      <FontAwesomeIcon
                        onClick={this.handleChatMenuToggle}
                        icon={faCaretUp}
                      />
                    ) : (
                      <FontAwesomeIcon
                        onClick={this.handleChatMenuToggle}
                        icon={faCaretDown}
                      />
                    )}
                  </div>
                </Fragment>
              }
              header={
                this.state.isChatAutoCompleteOpened ? (
                  <TagAutocomplete
                    defaultOpen={false}
                    open={this.state.isChatAutoCompleteMenuOpened}
                    className={cx(
                      classes.autocomplete,
                      'w-100 position-relative'
                    )}
                    disableCloseOnSelect={true}
                    fullWidth={true}
                    multiple={true}
                    tags={availableParticipants}
                    splitDelimited={true}
                    autoFocus={true}
                    getKey={(opt) => opt.ChatKey}
                    placeholder="Recipients"
                    onOpen={this.handleChatAutoCompleteMenuOpened}
                    onClose={this.handleChatAutoCompleteMenuClosed}
                    onTagChanged={this.handleRecipientsChanged}
                    onBlur={this.handleChatSearchBlur}
                    renderOption={(opt) => (
                      <div>
                        <ChatDirectMessageItem
                          ChatNameLineLimit={5}
                          className="p-0"
                          ChatID={opt.t.ChatID}
                          ChatName={opt.t.ChatName}
                          participantCount={opt.t.count}
                          issueWorkspaceID={issueWorkspaceID}
                        />
                      </div>
                    )}
                  />
                ) : null
              }
              messageBody={directMessageComp}
            />
            {this.state.lightboxStartIndex === null ? null : (
              <ArtifactLightbox
                artifacts={artifacts}
                startIndex={this.state.lightboxStartIndex}
                onCloseRequest={this.handleLightboxClose}
                selectArtifactMediaTypeById={selectArtifactMediaTypeById}
              />
            )}
          </StretchBody>
          <StretchFooter className={directMessageComp ? 'invisible' : ''}>
            <ChatMessageEditor
              classes={{
                root: 'w-100',
              }}
              onHeightChanged={this.handleSendContainerHeight}
              onSend={this.handleSend}
              sendBtnLabel={sendBtnLabel}
              placeholder="Enter message"
              onFileUploadChange={this.handleFileUploadChange}
              disabled={disabled}
            />
            <UploadDialog
              open={this.state.uploadInfo && !disabled ? true : false}
              entity={entity}
              file={file}
              onCancel={this.closeUploadDialog}
            />
          </StretchFooter>
        </StretchLayout>
      )
    } else {
      return (
        <StretchLayout
          className={cx(classes.root, className)}
          itemRef={(el) => (this._rootEl = el)}
          height={height}
          border={true}
        >
          <StretchBody
            className="overflow-hidden"
            itemRef={(el) => (this._bodyEl = el)}
          >
            <ChatDetailList
              chatEntryIds={chatEntryIds}
              onClickThumbnail={this.handleOnClickThumbnail}
              listName={!showChatName ? '' : ChatName || ''}
              height={this.getListHeight()}
            />
            <FontAwesomeIcon
              className={classes.searchIcon}
              onClick={this.openSearch}
              size="lg"
              icon={faSearch}
            />
            {this.state.lightboxStartIndex === null ? null : (
              <ArtifactLightbox
                artifacts={artifacts}
                startIndex={this.state.lightboxStartIndex}
                onCloseRequest={this.handleLightboxClose}
                selectArtifactMediaTypeById={selectArtifactMediaTypeById}
              />
            )}
          </StretchBody>
          <StretchFooter className={directMessageComp ? 'invisible' : ''}>
            <ChatMessageEditor
              classes={{
                root: 'w-100',
              }}
              onHeightChanged={this.handleSendContainerHeight}
              onSend={this.handleSend}
              sendBtnLabel={sendBtnLabel}
              placeholder="Enter message"
              onFileUploadChange={this.handleFileUploadChange}
              disabled={disabled}
            />
            <UploadDialog
              open={this.state.uploadInfo && !disabled ? true : false}
              entity={entity}
              file={file}
              uploadType={UploadEntityTypes.chat}
              onCancel={this.closeUploadDialog}
              disabled={disabled}
            />
          </StretchFooter>
        </StretchLayout>
      )
    }
  }
}

ChatDetailEditor.propTypes = {
  ChatID: PropTypes.number,
  ChatName: PropTypes.string,
  sendChatDetail: PropTypes.func,
  onSuccess: PropTypes.func,
  onError: PropTypes.func,
  chatEntries: PropTypes.array,
  sendInputLabel: PropTypes.string,
  hideChatName: PropTypes.bool,
  sendBtnLabel: PropTypes.string,
  showChatName: PropTypes.bool,
  /**
   * Total height of the editor
   */
  height: PropTypes.any,
  disabled: PropTypes.bool,
  directMessages: PropTypes.array,
  participants: PropTypes.array,
  teamChats: PropTypes.array,
  onCreateChatClicked: PropTypes.func,
  onRecipientsChanged: PropTypes.func,
  onCancelDirectMessage: PropTypes.func,
  hasRecipents: PropTypes.bool,
  onChatSelected: PropTypes.func,
  disableCreate: PropTypes.bool,
  isCreating: PropTypes.bool,
  isFetchingChatEntries: PropTypes.bool,
  canModifyDirectMessages: PropTypes.bool,
  onReturnToChatList: PropTypes.func,
  sendChatDelete: PropTypes.func,
  isRefreshReadRequired: PropTypes.bool,
}

ChatDetailEditor.defaultProps = {
  chatEntryIds: [],
  classes: {},
  showChatName: true,
  directMessages: [],
  participants: [],
  hasRecipents: false,
  teamChats: [],
  onCancelDirectMessage: () => {},
  disableCreate: false,
  isCreating: false,
  isFetchingChatEntries: false,
  canModifyDirectMessages: false,
  onReturnToChatList: () => {},
  sendChatDelete: () => {},
}

var mapDispatchToProps = {
  sendChatDetail: (ChatID, ChatEntryText) =>
    ChatDetailActions.add({ ChatID, ChatEntryText }),
  sendChatDelete: (chat) =>
    deleteDirectMessage.request(chat, { icoRequestId: chat.ChatID }),
  onSuccess: closeNotification,
  onError: (notification) => addNotification(notification),
  artifactIds: [],
  updateChatRead: (ChatID) => updateChatRead.request({ ChatID }),
  setIsViewingChat: setIsViewingChat,
}

const mapStateToProps = (state, ownProps) => {
  const chatEntryIds = selectChatEntryIds(state, ownProps.ChatID)
  const isRefreshReadRequired = isChatRefreshReadRequired(
    state,
    ownProps.ChatID
  )

  return {
    ChatName:
      ownProps.ChatName ||
      (selectChatById(state, ownProps.ChatID) || {}).ChatName,
    chatEntryIds,
    chatEntries: selectChatEntries(state, ownProps.ChatID),
    artifacts: selectArtifactsByChatEntryIds(state, chatEntryIds),
    selectArtifactMediaTypeById: (artifactId) =>
      selectArtifactMediaType(state, artifactId),
    doesChatExist: (chatId) => doesChatExist(state, chatId),
    isDeletingDirectMessage: (chat) =>
      isDeletingDirectMessage(state, (chat || {}).ChatID),
    isRefreshReadRequired,
    IssueName: ownProps.IssueName,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ChatDetailEditor)
