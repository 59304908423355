// @ts-nocheck
// TODO: Typescript
import React, { Component } from 'react'
import { AddButton } from '../Shared/Buttons'
import {
  withMobileDialog,
  Dialog,
  DialogContent,
  Toolbar,
  CircularProgress,
  InputLabel,
  Select,
  MenuItem,
} from '@material-ui/core'
import { SlideLeft } from '../Shared/Transition'
import classes from './IssueCreator.module.scss'
import minClasses from '../Shared/dialogMinimums.module.scss'
import { ScrollList } from '../Shared/ScrollList'
import { FormItem } from '../Form/FormItem'
import { connect } from 'react-redux'
import {
  fetchCreateIssueForms,
  fetchFormContent,
  submitIssueForm,
} from '../Form/_actions'
import { FormContent } from '../Form/FormContent'
import { HeaderNavigation } from '../Shared/HeaderNavigation'
import { cx } from '../_utils/objectUtils'
import PropTypes from 'prop-types'
import {
  selectCreateIssueForms,
  selectCurrentFormContent,
} from '../Form/_selectors'
import {
  isCreateIssueFormsComplete,
  isCreateIssueFormsLoading,
  isFormContentLoading,
  isIssueFormSubmitFailure,
} from '../Shared/_requestStatusSelectors'
import { addNotification } from '../_actions'
import { IssueTemplateListing } from './IssueTemplateListing'
import { Button } from 'reactstrap'
import { Spacer } from '../Shared/Spacer'
import { CreateNewIssueForm } from './CreateNewIssueForm'

const ResponsiveDialog = withMobileDialog()(Dialog)
/**
 * @extends {Component<Props, {}>}}
 */
export class IssueCreator extends Component {
  constructor(props) {
    super(props)
    this.state = {
      dialogContent: [],
      selectedOrg: {},
    }

    this.handleDialogClose = this.handleDialogClose.bind(this)
    this.handleOnAddClick = this.handleOnAddClick.bind(this)
    this.handleFormItemClick = this.handleFormItemClick.bind(this)
    this.handleNavigateBack = this.handleNavigateBack.bind(this)
    this.handleFormSubmit = this.handleFormSubmit.bind(this)
    this.handleSelectedForm = this.handleSelectedForm.bind(this)
    this.handleFormTemplateItemClick =
      this.handleFormTemplateItemClick.bind(this)
    this.prevFormContent = undefined
  }

  componentDidUpdate(prevProps) {
    const { isCreateIssueFormsComplete, createFormItems } = this.props
    // Decide if the form items should be show or if the form content should be shown
    // If the user only has issues for one organization, they can go right to the form instead of the form list
    if (
      isCreateIssueFormsComplete !== prevProps.isCreateIssueFormsComplete &&
      isCreateIssueFormsComplete &&
      this.state.dialogContent[0] === 'opening'
    ) {
      // if (createFormItems.length === 1)
      //   // Immediately click into the form content if one form is present
      //   this.handleFormItemClick(createFormItems[0], true)
      // else if (createFormItems.length > 1) {
      if (createFormItems.length > 0) {
        // Show multiple forms the user can pick from
        this.setState({
          dialogContent: ['formItems'],
        })

        if (Object.keys(this.state.selectedOrg).length === 0) {
          this.setState({
            selectedOrg: createFormItems[0],
          })
        }
      } else {
        // No from items found
        this.setState({
          dialogContent: ['noFormItems'],
        })
      }
    }
  }

  handleDialogClose() {
    if (this.state.dialogContent) {
      this.setState({
        dialogContent: [],
        selectedOrg: {},
      })
    }
  }

  handleOnAddClick() {
    if (this.state.dialogContent.length === 0)
      this.setState({ dialogContent: ['opening'] })
    this.props.fetchCreateIssueForms()
  }

  handleFormItemClick(formItem, destroyViews) {
    if (destroyViews) {
      this.setState({
        dialogContent: ['formContent'],
      })
    } else {
      this.setState({
        dialogContent: [...this.state.dialogContent, 'formContent'],
      })
    }
    const selectedOrg = this.state.selectedOrg
    this.props.fetchFormContent({
      TeamReportSpecID: selectedOrg.TeamReportSpecID,
      TeamReportContextGUID: selectedOrg.TeamReportContextGUID,
      ReportFormName: selectedOrg.ReportFormName,
      OrgName: selectedOrg.OrgName,
    })
  }

  handleFormTemplateItemClick(formItem, destroyViews) {
    this.setState({
      dialogContent: [...this.state.dialogContent, 'formTemplateContent'],
    })
  }

  handleNavigateBack() {
    const dialogContent = this.state.dialogContent
    this.setState({
      dialogContent: dialogContent.filter(
        (_, index) => index < dialogContent.length - 1
      ),
    })
  }

  getDialogTitle(selectedPanel) {
    if (selectedPanel === undefined) selectedPanel = this.getCurrentPanel()

    switch (selectedPanel) {
      case 'formItems':
        return 'Select Create Issue Form'
      case 'afterSubmitForm':

      case 'formContent':
        return 'Complete and Submit Form'
      case 'formTemplateContent':
        return 'Complete and Submit Form'
      default:
        return ''
    }
  }

  isDialogOpened() {
    return this.state.dialogContent.length > 0 ? true : false
  }

  canGoBack() {
    return this.state.dialogContent.length > 1
  }

  getCurrentPanel() {
    return this.state.dialogContent[this.state.dialogContent.length - 1]
  }

  handleFormSubmit() {
    this.pushNewView('afterSubmitForm', ['formContent', 'formItems'])
  }

  handleSelectedForm(org) {
    this.setState({
      selectedOrg: org,
    })
  }

  pushNewView(viewName, removeViews) {
    let { dialogContent } = this.state
    dialogContent = dialogContent || []
    let viewRemoved = false
    if (removeViews) {
      dialogContent = dialogContent.filter(
        (view) => removeViews.indexOf(view) === -1
      )
      viewRemoved = true
    }

    if (dialogContent[dialogContent.length - 1] !== viewName) {
      this.setState({
        dialogContent: [...dialogContent, viewName],
      })
    } else if (viewRemoved) {
      this.setState({
        dialogContent,
      })
    }
  }

  renderFormItems(formItems) {
    if (formItems.length <= 3) {
      return formItems.map((item) => (
        <FormItem
          key={item.TeamReportSpecID + ' ' + item.TeamID}
          onClick={(e) => {
            this.handleFormItemClick()
            this.handleSelectedForm(item)
          }}
          {...item}
        />
      ))
    }

    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <InputLabel shrink={true}>Organization</InputLabel>
        <Select
          value={this.state.selectedOrg}
          name="Organization"
          onChange={(e) => this.handleSelectedForm(e.target.value)}
        >
          {formItems.map((item) => {
            return (
              <MenuItem key={item.TeamReportSpecID} value={item}>
                {item.OrgName}
              </MenuItem>
            )
          })}
        </Select>
        <Spacer />
        <Button onClick={() => this.handleFormItemClick()} color="link">
          Activate Team
        </Button>
      </div>
    )
  }

  renderSelectedPanel(selectedPanel, prevValue) {
    const {
      createFormItems,
      currentFormContent,
      isCreateIssueFormsLoading,
      isFormContentLoading,
      isIssueFormSubmitFailure,
    } = this.props

    if (selectedPanel === undefined) selectedPanel = this.getCurrentPanel()

    switch (selectedPanel) {
      case 'formItems':
        return isCreateIssueFormsLoading ? (
          this.renderProgress()
        ) : (
          <ScrollList>{this.renderFormItems(createFormItems)}</ScrollList>
        )
      case 'afterSubmitForm':
        return isIssueFormSubmitFailure
          ? this.renderSelectedPanel('formContent', this.prevFormContent)
          : this.renderProgress('...Submitting form')
      case 'formContent':
        return (
          <CreateNewIssueForm
            selectedOrg={this.state.selectedOrg}
            handleFormSubmit={this.handleFormSubmit}
            handleDialogClose={this.handleDialogClose}
          />
        )

      case 'noFormItems':
        return 'Issue create forms not available.'
      case 'opening':
        return this.renderProgress()
      default:
        return <></>
    }
  }

  renderProgress(text) {
    return (
      <div
        className={cx(
          minClasses.progress,
          'd-flex justify-content-center align-items-center mh-100 mw-100'
        )}
      >
        <CircularProgress color="primary" disableShrink={true} />
        <div style={{ marginLeft: 10 }}>{text || ''}</div>
      </div>
    )
  }

  render() {
    const { addButtonID } = this.props
    return (
      <div className={classes.root}>
        <ResponsiveDialog
          className={minClasses.dialog}
          open={this.isDialogOpened()}
          onClose={this.handleDialogClose}
          TransitionComponent={SlideLeft}
        >
          <Toolbar>
            <HeaderNavigation
              title={this.getDialogTitle()}
              onNavClick={this.handleNavigateBack}
              canGoBack={this.canGoBack()}
            />
          </Toolbar>
          <DialogContent className={minClasses.dialogContent}>
            <IssueTemplateListing
              panel={this.getCurrentPanel()}
              handleDialogClose={this.handleDialogClose}
              onTemplateClick={this.handleFormTemplateItemClick}
            />
            {this.renderSelectedPanel()}
          </DialogContent>
        </ResponsiveDialog>
        <AddButton id={addButtonID} onClick={this.handleOnAddClick} />
      </div>
    )
  }
}

IssueCreator.propTypes = {
  addButtonID: PropTypes.string,
}

IssueCreator.defaultProps = {
  fetchCreateIssueForms: () => {},
  createFormItems: [],
  //afterSubmitFormItems: [],
  fetchFormContent: () => {},
  submitIssueForm: () => {},
  onAfterSubmit: () => {},
  closeAfterSubmit: false,
}

const mapStateToProps = (state) => ({
  createFormItems: selectCreateIssueForms(state),
  isCreateIssueFormsLoading: isCreateIssueFormsLoading(state),
  isCreateIssueFormsComplete: isCreateIssueFormsComplete(state),
  isIssueFormSubmitFailure: isIssueFormSubmitFailure(state, 'create'),
  //afterSubmitFormItems: selectAfterSubmitIssueForms(state),
  currentFormContent: selectCurrentFormContent(state),
  isFormContentLoading: isFormContentLoading(state),
})

const mapDispatchToProps = {
  fetchCreateIssueForms,
  fetchFormContent,
  submitIssueForm: (formData) =>
    submitIssueForm(formData, { submitType: 'create' }),
  onSubmitError: (message) => addNotification({ message }),
}

export default connect(mapStateToProps, mapDispatchToProps)(IssueCreator)
