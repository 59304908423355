// @ts-nocheck
// TODO: Typescript
import { addKeyValuePrefix } from '../_utils/objectUtils'

export const WebSocketConstants = {
  CONNECTION_SUCCESSFUL: 'CONNECTION_SUCCESSFUL',
  CONNECTING: 'CONNECTING',
  CONNECTION_FAILURE: 'CONNECTION_FAILURE',
  CONNECTION_RETRY: 'CONNECTION_RETRY',
  CONNECTION_START: 'CONNECTION_START',
  CONNECTION_DISCONNECTED: 'DISCONNECTED',
  CONNECTION_RETRY_SUCCESSFUL: 'CONNECTION_RETRY_SUCCESSFUL',
  CONNECTION_RETRY_FAILURE: 'CONNECTION_RETRY_FAILURE',
  DROP_CONNECTION: 'DROP_CONNECTION',
  FORCE_DROP_CONNECTION: 'FORCE_DROP_CONNECTION',
  FORCE_DROP_CONNECTION_SUCCESSFUL: 'FORCE_DROP_CONNECTION_SUCCESSFUL',
  UNAUTHORIZED: 'UNAUTHORIZED',
}
addKeyValuePrefix('WEBSOCKET', WebSocketConstants)
