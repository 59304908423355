// @ts-nocheck
// TODO: Typescript
import {
  INIT_MS_TEAMS,
  FETCH_MS_TEAMS_CONTEXT,
  FETCH_MS_TEAMS_SETTINGS,
  LOGIN_AD,
  FETCH_MS_TEAMS_CHANNEL_FOLDERIDS,
  DISABLE_MS_TEAMS_AUTO_LOGIN,
  FETCH_GRAPH_JOINED_TEAM_CHANNELS,
  FETCH_GRAPH_CALENDAR_EVENTS,
  SET_GRAPH_CALENDAR_EVENTS_WORKSPACE,
  ADD_GRAPH_CALENDAR_EVENT,
  REMOVE_GRAPH_CALENDAR_EVENT,
} from './_constants'
import { LogoutConstants } from '../Auth/_constants'
import { getDescComp, removeKeyFromObject } from '../_utils/objectUtils'
import { createSelector } from 'reselect'

const initialState = {
  isInTeams: false,
  autoLogin: false,
}
export default function microsoftTeamsReduer(state = initialState, action) {
  const { result } = action.payload || {}
  switch (action.type) {
    case INIT_MS_TEAMS.SUCCESS:
      return {
        ...state,
        isInTeams: true,
        autoLogin: state.autoLogin === false ? false : true,
      }
    case LogoutConstants.SUCCESSFUL:
    case DISABLE_MS_TEAMS_AUTO_LOGIN:
      return {
        ...state,
        autoLogin: false,
      }
    case FETCH_MS_TEAMS_CONTEXT.SUCCESS:
      return {
        ...state,
        context: action.payload.context,
      }
    case FETCH_MS_TEAMS_SETTINGS.SUCCESS:
      return {
        ...state,
        settings: action.payload.settings,
      }
    case LOGIN_AD.FAILURE:
      return {
        ...state,
        loginCode: action.payload.error,
      }
    case LOGIN_AD.REQUEST:
    case LOGIN_AD.SUCCESS:
      return {
        ...state,
        loginCode: '',
      }
    case FETCH_GRAPH_JOINED_TEAM_CHANNELS.SUCCESS:
      return {
        ...state,
        teamsList: result.result,
      }
    default:
      return state
  }
}

export const selectIsMicrosoftTeams = (state) => state.isInTeams as boolean
export const selectMSTeamsContext = (state) => state.context
export const selectMSTeamsLoginHint = (state) => (state.context || {}).loginHint
export const selectMSTeamsGroupId = (state) => (state.context || {}).groupId
export const selectMSTeamsChannelName = (state) =>
  (state.context || {}).channelName
export const selectMSTeamsChannelId = (state) => (state.context || {}).channelId
export const selectAzureADLoginCode = (state) => state.loginCode
export const selectMSTeamsChannelFolderId = (state) => state.channelFolderId
export const selectIsMSTeamsAutoLoginEnabled = (state) =>
  state.autoLogin && state.isInTeams
export const isMSTeamsAutoLoginDisabled = (state) =>
  !state.autoLogin || !state.isInTeams
export const selectJoinedMSTeamsIds = (state) => state.teamsList

export function msTeamsReducer(state = {}, action) {
  const { result } = action.payload || {}
  switch (action.type) {
    case FETCH_GRAPH_JOINED_TEAM_CHANNELS.SUCCESS:
      return result.entities.MSTeams
    default:
      return state
  }
}

export const selectJoinedMSTeamByIdMap = (state) => state
export const selectJoinedMSTeamById = (state, teamId) =>
  (selectJoinedMSTeamByIdMap(state) || {})[teamId]
export const selectMSTeamNameById = (state, teamId) =>
  (selectJoinedMSTeamById(state, teamId) || {}).DisplayName
export const selectMSChannelIdsByTeamId = (state, teamId) =>
  (selectJoinedMSTeamById(state, teamId) || {}).Channels

export function msChannelsReducer(state = {}, action) {
  const { result, params } = action.payload || {}
  switch (action.type) {
    case FETCH_GRAPH_JOINED_TEAM_CHANNELS.SUCCESS:
      return result.entities.MSChannels
    case FETCH_MS_TEAMS_CHANNEL_FOLDERIDS.SUCCESS:
      const channelId = params.channelId
      return {
        ...state,
        [channelId]: {
          ...state[channelId],
          folderId: result.channelFolderId,
          driveId: result.driveId,
        },
      }
    default:
      return state
  }
}

export const selectMSFolderIdByChannelId = (state, channelId) =>
  (selectJoinedMSChannelById(state, channelId) || {}).folderId
export const selectMSDriveIdByChannelId = (state, channelId) =>
  (selectJoinedMSChannelById(state, channelId) || {}).driveId
export const selectJoinedMSChannelByIdMap = (state) => state
export const selectJoinedMSChannelById = (state, channelId) =>
  (selectJoinedMSChannelByIdMap(state) || {})[channelId]
export const selectMSChannelNameById = (state, channelId) =>
  (selectJoinedMSChannelById(state, channelId) || {}).DisplayName
export const selectMSChannelGroupId = (state, channelId) => {
  const channel = selectJoinedMSChannelById(state, channelId)
  if (!channel || !channel.WebUrl) return ''

  const search = new URLSearchParams(channel.WebUrl.split('?')[1])

  return search.get('groupId')
}

export function msEventsReducer(
  state = { IssueTeamWorkspaceID: 0, events: {} },
  action
) {
  const { result, params } = action.payload || {}
  switch (action.type) {
    case SET_GRAPH_CALENDAR_EVENTS_WORKSPACE:
      return {
        IssueTeamWorkspaceID: action.payload.IssueTeamWorkspaceID,
        events: {},
      }
    case FETCH_GRAPH_CALENDAR_EVENTS.SUCCESS:
      if (params.IssueTeamWorkspaceID !== state.IssueTeamWorkspaceID)
        return state

      //const d;
      return {
        IssueTeamWorkspaceID: params.IssueTeamWorkspaceID,
        events: {
          ...state.events,
          ...result,
        },
      }
    case ADD_GRAPH_CALENDAR_EVENT.SUCCESS:
      return {
        events: {
          ...state.events,
          [action.payload.result.id]: action.payload.result,
        },
      }
    case REMOVE_GRAPH_CALENDAR_EVENT.SUCCESS:
      return {
        ...state,
        events: removeKeyFromObject(
          state.events,
          action.payload.result.ConferenceID
        ),
      }
    default:
      return state
  }
}

export const selectMSCalendarEventsByIdMap = (state) => state.events
export const selectMSCalendarEventsWorkspaceId = (state) =>
  state.IssueTeamWorkspaceID
export const selectMSCalendarEvents = createSelector(
  selectMSCalendarEventsByIdMap,
  (eventsMap) => {
    const list = Object.values(eventsMap || {})
    list?.sort(getDescComp((event) => event.start.dateTime))
    return list
  }
)
export const selectMSCalendarEventsCount = createSelector(
  selectMSCalendarEvents,
  (eventsList) => {
    return eventsList?.length || 0
  }
)
