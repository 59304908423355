import { StakeholderConstants } from './_constants'
import { removeKeyFromObject } from '../_utils/objectUtils'
import { reducerCrud } from '../_utils/reduxUtils'

export interface StakeholderFields {
  Audience: string
  responsibleParticipant: string
  msTeams_Channels?: string
}
export type Stakeholder = StakeholderFields & {
  Created: string
  Creator: number
  IssueStakeholderID: number
  lastNotified: string
  PubID: string | null
  advisory_started: null | string
  advisory_published_date: null | string
  advisory_valid_until: null | string
}

interface StakeholderSlice {
  currentSelectedStakeholderID: null | number
  stakeholders: { [id: number]: Stakeholder }
}

export default function stakeholderReducer(
  state: StakeholderSlice = {
    currentSelectedStakeholderID: null,
    stakeholders: {},
  },
  action: { type: string; payload: any }
) {
  const stakeholderKey = 'IssueStakeholderID'
  switch (action.type) {
    case StakeholderConstants.INIT.SUCCESS:
      return {
        ...state,
        stakeholders: reducerCrud.read(state.stakeholders, action),
      }
    case StakeholderConstants.ADD.SUCCESS:
      return {
        currentSelectedStakeholderID: action.payload.result[stakeholderKey],
        stakeholders: reducerCrud.create(
          state.stakeholders,
          action,
          stakeholderKey
        ),
      }
    case StakeholderConstants.UPDATE.SUCCESS:
      return {
        ...state,
        stakeholders: reducerCrud.update(
          state.stakeholders,
          action,
          stakeholderKey
        ),
      }
    case StakeholderConstants.DELETE.SUCCESS:
      return {
        currentSelectedStakeholderID: null,
        stakeholders: removeKeyFromObject(
          state.stakeholders,
          action.payload.result[stakeholderKey]
        ),
      }
    case StakeholderConstants.DELETE_RANGE.SUCCESS:
      return {
        currentSelectedStakeholderID: null,
        stakeholders: reducerCrud.deleteIdRange(
          state.stakeholders,
          action.payload.result
        ),
      }
    case StakeholderConstants.UPDATE_RANGE.SUCCESS:
      return {
        ...state,
        stakeholders: reducerCrud.updateRange(
          state.stakeholders,
          action,
          stakeholderKey
        ),
      }
    case StakeholderConstants.setCurrentStakeholderID:
      return {
        ...state,
        currentSelectedStakeholderID: action.payload.result,
      }
    case StakeholderConstants.setCurrentStakeholderLastNotified:
      let newStakeholders = { ...state.stakeholders }
      newStakeholders[
        state.currentSelectedStakeholderID
          ? state.currentSelectedStakeholderID
          : 0
      ].lastNotified = action.payload.result
      return {
        currentSelectedStakeholderID: state.currentSelectedStakeholderID,
        stakeholders: newStakeholders,
      }
    default:
      return state
  }
}

// STAKEHOLDER SELECTORS
export const selectCurrentStakeholderID = (state: StakeholderSlice) =>
  state.currentSelectedStakeholderID
export const selectStakeholderIdMap = (state: StakeholderSlice) =>
  state.stakeholders
export const selectStakeholderById = (
  state: StakeholderSlice,
  stakeholderID: number
) => (selectStakeholderIdMap(state) || {})[stakeholderID]
export const isStakeholderAdvisoryCreated = (
  state: StakeholderSlice,
  stakeholderID: number
) => (selectStakeholderById(state, stakeholderID)?.PubID ? true : false)
