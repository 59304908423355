// @ts-nocheck
// TODO: Typescript
import {
  updateHoldingStatementSuccess,
  approveHoldingStatementSuccess,
} from './_actions'
import { WebSocketConstants } from '../_webSocket/_constants'
import { HoldingStatementConstants } from './_constants'
import { objDatePropsToUTC } from '../_utils/dateUtils'
import { JsonParseSafe } from '../_utils/objectUtils'
import { selectHoldingStatementById } from './_selectors'
import { addNotification } from '../_actions'

export default function holdingStatementHub(storeAPI, action, hubConnection) {
  switch (action.type) {
    case WebSocketConstants.CONNECTION_RETRY_SUCCESSFUL:
    case WebSocketConstants.CONNECTION_SUCCESSFUL:
      hubConnection.on(
        'BroadcastHoldingStatementUpdate',
        addNewHoldingStatement
      )
      hubConnection.on(
        'BroadcastHoldingStatementApprove',
        addNewHoldingStatement
      )
      break
    case HoldingStatementConstants.UPDATE.REQUEST:
      hubConnection.invoke('SendHoldingStatementUpdate', action.statement)
      break
    case HoldingStatementConstants.APPROVE.REQUEST:
      hubConnection.invoke('SendHoldingStatementApprove', action.statement)
      break
    default:
      break
  }

  function addNewHoldingStatement(data, source, isCaller) {
    data = JsonParseSafe(data)
    if (!data.ErrorMessage) {
      var statement = (data.returnData || [])[0] || {}

      // old statement comparison required because the user belongs to multiple groups
      var oldStatement =
        selectHoldingStatementById(
          storeAPI.getState(),
          statement.HoldingStatementID
        ) || {}
      // Only approve if it has not been approved already
      if (statement.Approver !== undefined && !oldStatement.Approver) {
        objDatePropsToUTC(statement, 'Approved')
        storeAPI.dispatch(approveHoldingStatementSuccess(statement, source))
      } else if (
        statement.Creator !== undefined &&
        oldStatement.HoldingStatementID === undefined
      ) {
        objDatePropsToUTC(statement, 'Created')
        storeAPI.dispatch(updateHoldingStatementSuccess(statement, source))
      }
    } else if (isCaller)
      storeAPI.dispatch(addNotification({ message: data.ErrorMessage }))
  }
}
