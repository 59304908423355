// @ts-nocheck
// TODO: Typescript
import {
  LoginConstants,
  LogoutConstants,
  AuthConstants,
} from '../Auth/_constants'
import { unAuthenticated, unAuthorized } from '../Auth/_actions'
import { closeNotification, addNotification } from '../_actions'
import { WebSocketConstants } from '../_webSocket/_constants'
import * as webSocketActions from '../_webSocket/_actions'
import rootHub from '../_rootConfigs/rootHub'
import { HubConnectionBuilder, HubConnectionState } from '@microsoft/signalr'
import { IssueActions } from '../Issue/_actions'

export default function createSignalR(url) {
  var hubConnection
  const connectionRetryDelay = 5000
  var forcedDisconnect = false
  var currentStoreApi

  // Store the id of the setInterval that checks to see if a connection needs to be disconnected due to unauthorized access
  var authDisconnectInterval

  return (storeAPI) => (next) => (action) => {
    currentStoreApi = storeAPI

    switch (action.type) {
      case AuthConstants.USER_AUTEHNTICATED:
      case LoginConstants.SUCCESSFUL:
        if (!hubConnection) createHub()
        break
      case AuthConstants.USER_UNAUTHENTICATED:
      case LogoutConstants.SUCCESSFUL:
      case WebSocketConstants.FORCE_DROP_CONNECTION:
        if (hubConnection) {
          disconnectHubPerm()
        }
        break

      // For testing
      case WebSocketConstants.DROP_CONNECTION:
        disconnectHub()
        break

      case WebSocketConstants.CONNECTION_START:
        if (
          !hubConnection ||
          hubConnection.connectionState !== HubConnectionState.Connected
        ) {
          createHub()
        }
        break
      default:
        break
    }

    // If an action is not found try the other hubs
    if (rootHub) {
      rootHub.forEach(function (hub) {
        // hubs that have are set to connected only will be processed only if there is a connection. If not connected only process regardless of state.
        if (
          ((hub.connectedOnly === true || hub.connectedOnly === undefined) &&
            hubConnection &&
            hubConnection.connectionState === HubConnectionState.Connected) ||
          hub.connectedOnly === false
        ) {
          hub(storeAPI, action, hubConnection)
        }
      })
    }

    return next(action)
  }

  function createHub(isRetry) {
    hubConnection = new HubConnectionBuilder()
      .withUrl(url + (isRetry ? '?dontRenewAuth=true' : ''))
      .build()

    if (isRetry)
      currentStoreApi.dispatch(
        webSocketActions.connectionRetry(connectionRetryDelay)
      )
    else currentStoreApi.dispatch(webSocketActions.connecting())

    hubConnection
      .start()
      .then(() => {
        if (isRetry) {
          currentStoreApi.dispatch(
            webSocketActions.connectionRetrySuccessful(connectionRetryDelay)
          )
        } else {
          currentStoreApi.dispatch(webSocketActions.connectionSuccessful())
        }

        hubConnection.on('BroadcastError', (message) => {
          currentStoreApi.dispatch(
            addNotification({
              message,
              type: 'error',
              duration: 3000,
              hasCloseBtn: true,
            })
          )
        })

        hubConnection.on('BroadcastNotification', (noty) => {
          currentStoreApi.dispatch(addNotification(noty))
        })

        hubConnection.on('BroadcastUnAuthenticated', () => {
          currentStoreApi.dispatch(unAuthenticated())
        })
      })
      .catch((err) => {
        if (err.statusCode === 401 || err.statusCode === 403) {
          if (err.statusCode === 403) {
            currentStoreApi.dispatch(unAuthorized())
            currentStoreApi.dispatch(IssueActions.init())
          } else currentStoreApi.dispatch(unAuthenticated())

          currentStoreApi.dispatch(closeNotification())
        } else {
          if (isRetry)
            currentStoreApi.dispatch(
              webSocketActions.connectionFailure(connectionRetryDelay)
            )
          else
            currentStoreApi.dispatch(
              webSocketActions.connectionRetryFailure(connectionRetryDelay)
            )

          retryHubConnection()
        }
      })

    // Event to reconnect if the connection drops
    hubConnection.onclose(function () {
      hubConnection = null
      if (!forcedDisconnect) {
        currentStoreApi.dispatch(
          webSocketActions.connectionDisconnected(connectionRetryDelay)
        )
        retryHubConnection()
      } else {
        currentStoreApi.dispatch(
          webSocketActions.forceDropConnectionSuccessful()
        )
      }
      forcedDisconnect = false
    })
  }

  function retryHubConnection() {
    setTimeout(function () {
      createHub(true)
    }, connectionRetryDelay)
  }

  function disconnectHubPerm() {
    disconnectHub(true)
  }

  function disconnectHub(b) {
    forcedDisconnect = b
    hubConnection.stop()
    hubConnection = null

    if (authDisconnectInterval) clearInterval(authDisconnectInterval)
  }
}
