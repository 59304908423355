// @ts-nocheck
// TODO: Typescript
import { getFetchListener } from '../_utils/sagaUtils'
import { FETCH_ORG_UGIS_MEMBERS, OrgTagConstants } from './_constants'
import { takeLatest } from 'redux-saga/effects'
import { fetchOrgUgisMembers, OrgTagActions } from './_actions'
import { normalize } from 'normalizr'
import { OrgTagListSchema } from '../_schema/_schemas'

// The work sagas
const fetchOrgTags = getFetchListener('/api/Issue/OrgTag', {
  onLoading: OrgTagActions.fetchLoading,
  onFailure: OrgTagActions.fetchFailure,
  onSuccess: (json) => {
    json = json || {}
    const normalizedData = normalize(json, OrgTagListSchema)
    const mappedEntities = (normalizedData || {}).entities || {}
    return OrgTagActions.fetchSuccess(mappedEntities.OrgTag)
  },
})

const fetchUgisMembers = getFetchListener('/api/Org', {
  onLoading: fetchOrgUgisMembers.loading,
  onFailure: fetchOrgUgisMembers.failure,
  onSuccess: fetchOrgUgisMembers.success,
  formatData: false,
  appendPath: (action) => action.payload.params.OrgID + '/ugis',
})

// Watcher sagas
export const orgTagSagas = [
  takeLatest(OrgTagConstants.FETCH.REQUEST, fetchOrgTags),
  takeLatest(FETCH_ORG_UGIS_MEMBERS.REQUEST, fetchUgisMembers),
]
