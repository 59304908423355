import React from 'react'
import classes from './Playbooks.module.scss'

interface Props {
  altAction: string
}

export const AltAction = ({ altAction }: Props) => {
  const action = altAction.substring(0, altAction.indexOf(':'))
  const args = altAction.substring(altAction.indexOf(':') + 1, altAction.length)
  let retval
  switch (action) {
    case 'https':
      retval = (
        <>
          <a target="_blank" href={'https:' + args}>
            (If this page does not load, click this link to open in a separate
            tab)
          </a>
          <iframe
            title="my_iframe"
            id="my_iframe"
            name="my_iframe"
            src={args}
            className={classes.iframeStyle}
            frameBorder="none"
            allowFullScreen
          ></iframe>
        </>
      )
      break
    case 'http':
      retval = (
        <iframe
          title="my_iframe"
          id="my_iframe"
          name="my_iframe"
          src={args}
          className={classes.iframeStyle}
          frameBorder="none"
          allowFullScreen
        ></iframe>
      )
      break
    case 'mailto':
      retval = <a href={altAction}>Click to Send Email</a>
      break
    case 'sms':
      retval = <a href={altAction}>Click to Send Text Message</a>
      break
    case 'tel':
      retval = <a href={altAction}>Click to Make Telephone Call</a>
      break
    default:
      retval = (
        <p>
          Not yet ready to handle AltAction {action}
          <br />
          {altAction}
        </p>
      )
  }
  return retval
}
