// @ts-nocheck
// TODO: Typescript
import React, { Component } from 'react'
import { ConfirmDialog } from '../Shared/ConfirmDialog'
import { connect } from 'react-redux'
import { ConferenceActions } from './_actions'
import PropTypes from 'prop-types'
import { selectConferenceById } from './_selectors'

interface Props {
  ConferenceID: number
  open: boolean
  confirm: any
  cancel: any
}
export class ConferenceDeleteConfirmation extends Component<Props> {
  constructor(props) {
    super(props)
    this.state = {
      Scheduled: undefined,
    }
    this.handleConfirmation = this.handleConfirmation.bind(this)
  }

  handleConfirmation() {
    const { confirm, Scheduled, ConferenceID } = this.props

    if (confirm) confirm({ Scheduled, ConferenceID })
  }

  render() {
    const { open, cancel } = this.props

    return (
      <ConfirmDialog
        open={open}
        title="Meeting Cancelation"
        content={`You are about to delete this meeting, do you wish to continue?`}
        confirm={this.handleConfirmation}
        cancel={cancel}
      />
    )
  }
}

ConferenceDeleteConfirmation.propTypes = {
  ConferenceID: PropTypes.any,
  Conference: PropTypes.string,
  Scheduled: PropTypes.any,
  open: PropTypes.bool,
  confirm: PropTypes.func,
  cancel: PropTypes.func,
}

const mapStateToProps = (state, ownProps) => {
  const conference = selectConferenceById(state, ownProps.ConferenceID) || {}
  const { Scheduled } = conference
  return {
    Scheduled,
  }
}

const mapDispatchToProps = {
  confirm: ConferenceActions.delete,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConferenceDeleteConfirmation)
