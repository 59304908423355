import * as React from 'react'
import { Component, ReactNode } from 'react'
import { faArrowLeft, faTimes } from '@fortawesome/free-solid-svg-icons'
import headerClasses from './HeaderNavigation.module.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { cx } from '../_utils/objectUtils'

const classes: any = headerClasses

interface Props {
  icon?: ReactNode
  canGoBack: boolean
  title?: ReactNode
  actions?: ReactNode
  onNavClick?(event: React.MouseEvent): void
  classes?: { navIconClickArea?: string }
  hideNav?: boolean
}

/**
 * @extends {Component<Props, {}>}}
 */

export class HeaderNavigation extends Component<Props> {
  static defaultProps: { canGoBack: boolean }
  render() {
    const { icon, canGoBack, title, actions, onNavClick } = this.props

    const NavButton = onNavClick ? (
      <span>
        <span
          className={cx(
            classes.navIconClickArea,
            this.props.classes?.navIconClickArea
          )}
          onClick={onNavClick}
        />
        {icon ? (
          icon
        ) : (
          <FontAwesomeIcon
            className={classes.navIcon}
            icon={canGoBack ? faArrowLeft : faTimes}
          />
        )}
      </span>
    ) : null

    return (
      <div className={classes.root}>
        {NavButton}
        <h2 className={classes.title}>{title}</h2>
        <div className={classes.actions}>{actions}</div>
      </div>
    )
  }
}

HeaderNavigation.defaultProps = {
  canGoBack: true,
}
