import React from 'react'
import { FormContent } from '../Form/FormContent'
import { ContentViewer } from '../Shared/ContentViewer'
import Moment from 'react-moment'
import { appDateTimeFormat } from '../_constants'
import { getLocalDate } from '../_utils/dateUtils'
import { selectTeamReportById } from '../Form/_selectors'
import { IssueTeamReportImage } from './IssueTeamReportImage'
import { useAppSelector } from '../_utils/reactReduxHooks'
import { isMobileApp } from '../_selectors'

interface SubmittedFormsViewerProps {
  closeSelectedForm: () => void
  selectedForm: number | undefined
}

export const SubmittedFormsViewer = ({
  selectedForm,
  closeSelectedForm,
}: SubmittedFormsViewerProps) => {
  const teamReport = useAppSelector((state) =>
    selectTeamReportById(state, selectedForm)
  )
  const isMobile = useAppSelector(isMobileApp)

  return (
    <ContentViewer
      open={selectedForm ? true : false}
      onClose={closeSelectedForm}
      headerTitle="Submitted Form Content"
      isDialog={isMobile}
    >
      {teamReport && !teamReport.TeamReportID ? (
        <div>{`The report "${teamReport.ReportFormName}" no longer exists.`}</div>
      ) : (
        teamReport && (
          <div>
            <div
              style={{
                display: 'flex',
                fontSize: 12.8,
                justifyContent: 'space-between',
                flexWrap: 'wrap',
                wordBreak: 'break-all',
              }}
            >
              <div className="mr-1">
                <label>By Whom:&nbsp;</label>
                <span>{teamReport.FullName || teamReport.email_address}</span>
              </div>
              <div>
                <label>When:&nbsp;</label>
                <Moment
                  local
                  format={appDateTimeFormat}
                  date={getLocalDate(teamReport.SubmittedGMT)}
                />
              </div>
            </div>
            <FormContent
              className={''}
              content={teamReport.HTMLform.replaceAll("<textarea", "<div style='white-space:pre-wrap;' ")}
              readonly={true}
              fldval={teamReport.fldval}
            />
            {teamReport.images?.map((img: any) => {
              return (
                <div
                  style={{
                    fontSize: 10,
                  }}
                >
                  <IssueTeamReportImage
                    IssueID={img.IssueID}
                    TeamReportImageID={img.TeamReportImageID}
                  />
                  {img.image_name}
                </div>
              )
            })}
          </div>
        )
      )}
    </ContentViewer>
  )
}
