import * as React from 'react'
import Moment from 'react-moment'
import { appDateTimeFormat } from '../_constants'
import { getLocalDate } from '../_utils/dateUtils'
import { http } from '../_utils/http'
import { CircularProgress } from '../Shared/LoadingBackdrop'
import { useAppDispatch } from '../_utils/reactReduxHooks'
import { IssueActions } from '../Issue/_actions'
import { Table } from 'reactstrap'
import {
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button,
} from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes, faUser } from '@fortawesome/pro-solid-svg-icons'
import classes from './AdminConsolePage.module.scss'
import { Spacer } from '../Shared/Spacer'
import { StyledTableRow } from './AdminConsolePage'
import { AdminConsoleDialog } from '../Shared/AdminConsoleDialog'

interface Props {
  OrgID: string
}

interface OrphanedIssue {
  IssueID: number
  OrgID: number
  IssueTeamWorkspaceID: number
  IssueNameDescr: string
  IssueCreated: Date
  IssueMode: string
  IssueModeDescr: string
  LevelName: string
  TemplateFlagYN: string
  IsResolved: string
  AdminParticipants: number
  ApproverParticipants: number
  ReadOnlyParticipants: number
  ActiveParticipants: number
  InactiveParticipants: number
}

export const AdminConsoleOrphanedIssue = ({ OrgID }: Props) => {
  const [orphanedIssuesList, setOrphanedIssuesList] = React.useState<any>('')
  const [selectedIssue, setSelectedIssue] = React.useState<
    OrphanedIssue | undefined
  >()
  const [issueDeleteDialogOpen, setIssueDeleteDialogOpen] =
    React.useState(false)
  const [issueAdoptDialogOpen, setIssueAdoptDialogOpen] = React.useState(false)

  const clearState = () => {
    setIssueDeleteDialogOpen(false)
    setIssueAdoptDialogOpen(false)
    setSelectedIssue(undefined)
  }

  const getOrphanedIssuesList = React.useCallback(async (): Promise<any> => {
    var customHeaders: any = []

    customHeaders.push({ name: 'orgid', value: `${OrgID}` })
    customHeaders.push({
      name: 'includeresolvedyn',
      value: `${'N'}`,
    })

    const result = await http<any>({
      path: '/Issue/OrphanedIssuesList',
      headers: customHeaders,
    })
    if (result.ok && result.body) {
      setOrphanedIssuesList(result.body)
    } else {
      setOrphanedIssuesList('')
    }
  }, [OrgID])

  React.useEffect(() => {
    getOrphanedIssuesList()
  }, [getOrphanedIssuesList])

  async function adoptIssue(issueTeamWorkspaceID: number) {
    var customHeaders: any = []

    customHeaders.push({
      name: 'issueteamworkspaceid',
      value: `${issueTeamWorkspaceID}`,
    })

    const result = await http<any>({
      path: '/Issue/OrphanedIssueAdopt',
      headers: customHeaders,
    })
    if (result.ok && result.body) {
      return result.body
    } else {
      return ''
    }
  }
  const dispatch = useAppDispatch()

  async function handleAdoptIssue(issueTeamWorkspaceID: number) {
    await adoptIssue(issueTeamWorkspaceID)
    // now refresh listing
    getOrphanedIssuesList()
  }

  async function handleDeleteIssue(
    issueID: number,
    issueTeamWorkspaceID: number
  ) {
    await adoptIssue(issueTeamWorkspaceID)
    dispatch(IssueActions.delete({ issueID }))
    getOrphanedIssuesList()
  }

  const OrphanedIssuesDialog = () => {
    if (!selectedIssue) return <></>
    return (
      <>
        <AdminConsoleDialog
          open={issueDeleteDialogOpen}
          content={'Are you sure you would like to delete this Issue?'}
          onConfirm={() => {
            handleDeleteIssue(
              selectedIssue.IssueID,
              selectedIssue.IssueTeamWorkspaceID
            )
            clearState()
          }}
          consoleRole="destructive"
          onCancel={() => setIssueDeleteDialogOpen(false)}
        />
        <AdminConsoleDialog
          open={issueAdoptDialogOpen}
          content={'Are you sure you would like to adopt this Issue?'}
          onConfirm={() => {
            handleAdoptIssue(selectedIssue.IssueTeamWorkspaceID)
            clearState()
          }}
          onCancel={() => setIssueAdoptDialogOpen(false)}
        />
      </>
    )
  }

  const renderOrphanedIssuesEditContainer = () => {
    if (!selectedIssue) return
    return (
      <div className={classes.OrganizationHoldingStatementEditContainer}>
        <div>
          <div className={classes.OrphanedIssueEditContainerHeader}>
            {selectedIssue?.IssueNameDescr}
            <FontAwesomeIcon
              icon={faTimes}
              style={{ cursor: 'pointer' }}
              onClick={() => {
                setSelectedIssue(undefined)
              }}
            />
          </div>
        </div>

        <div>Level Name: {selectedIssue.LevelName}</div>
        <div>Is a template: {selectedIssue.TemplateFlagYN}</div>
        <div>Is resolved: {selectedIssue.IsResolved}</div>
        <div>Admin Participants: {selectedIssue.AdminParticipants}</div>
        <div>Approver Participants: {selectedIssue.ApproverParticipants}</div>
        <div className={classes.OrganizationTagButtonContainer}>
          {selectedIssue?.TemplateFlagYN === 'N' ? (
            <Button
              className={classes.OrganizationDeleteButton}
              style={{ color: 'red' }}
              onClick={() => setIssueDeleteDialogOpen(true)}
            >
              Delete Issue
            </Button>
          ) : null}
          <Spacer />
          <Button
            className={classes.OrganizationSaveButton}
            onClick={() => setIssueAdoptDialogOpen(true)}
            style={{ color: 'rgb(64, 148, 195)' }}
          >
            Add My Account as Admin
          </Button>
        </div>
      </div>
    )
  }

  function renderList() {
    if (!orphanedIssuesList) return <CircularProgress />

    if (!orphanedIssuesList.status) return <CircularProgress />

    if (orphanedIssuesList.status !== 'success')
      return <>{JSON.stringify(orphanedIssuesList, null, 2)}</>

    //  if (!orphanedIssuesList.orphanedIssues) return <>no orphaned issues found</>
    //  if (orphanedIssuesList.orphanedIssues.count === 0)
    //    return <>no orphaned issues found</>

    return (
      <>
        <OrphanedIssuesDialog />
        <div className={classes.OrganizationManagementSection}>
          <div className={classes.OrganizationManagementSectionHeader}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                marginBottom: '10px',
              }}
            >
              <FontAwesomeIcon icon={faUser} size={'2x'} />{' '}
              &nbsp;&nbsp;&nbsp;&nbsp; <div>ORPHANED ISSUES</div>
            </div>
          </div>
          <Table sx={{ minWidth: 300 }} aria-label="custom table">
            <colgroup>
              <col style={{ width: '30%' }} />
              <col style={{ width: '30%' }} />
              <col style={{ width: '20%' }} />
              <col style={{ width: '20%' }} />
            </colgroup>
            <TableHead>
              <TableRow>
                <TableCell>Issue</TableCell>
                <TableCell>Created</TableCell>
                <TableCell># of Admins</TableCell>
                <TableCell># of Approvers</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {orphanedIssuesList.orphanedIssues?.map(
                (orphanedIssue: OrphanedIssue, orpIdx: number) => {
                  const isSelected =
                    orphanedIssue.IssueID === selectedIssue?.IssueID
                  return (
                    <StyledTableRow
                      onClick={() => setSelectedIssue(orphanedIssue)}
                      style={{
                        cursor: 'pointer',
                        backgroundColor: isSelected ? 'rgb(64, 148, 195)' : '',
                      }}
                      key={orphanedIssue.IssueID}
                    >
                      <TableCell
                        style={{
                          color: isSelected ? 'white' : '',
                        }}
                      >
                        {orphanedIssue.IssueNameDescr}
                      </TableCell>
                      <TableCell
                        style={{
                          color: isSelected ? 'white' : '',
                        }}
                      >
                        <Moment format={appDateTimeFormat} local>
                          {getLocalDate(orphanedIssue.IssueCreated)}
                        </Moment>
                      </TableCell>

                      <TableCell
                        style={{
                          color: isSelected ? 'white' : '',
                        }}
                      >
                        {orphanedIssue.AdminParticipants}
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{
                          color: isSelected ? 'white' : '',
                        }}
                      >
                        {orphanedIssue.ApproverParticipants}
                      </TableCell>
                    </StyledTableRow>
                  )
                }
              )}
            </TableBody>
          </Table>
          {selectedIssue && renderOrphanedIssuesEditContainer()}
        </div>
      </>
    )
  }

  return <>{renderList()}</>
}
