// @ts-nocheck
// TODO: Typescript
import * as microsoftTeams from '@microsoft/teams-js'
import {
  initMSTeamsSuccess,
  FetchMSTeamsContextSuccess,
  disableMSTeamsAutoLogin,
  fetchGraphJoinedTeamChannels,
} from './_actions'
import { AppConstants } from '../_constants'
import { WebSocketConstants } from '../_webSocket/_constants'
import { logout } from '../Auth/_actions'
import { MS_GRAPH_REQUEST_FAILURE } from '../_utils/reduxUtils'
import { AZURE_LOGIN_PROVIDER } from '../Auth/_constants'
import { selectIsMicrosoftTeams } from './_selectors'

export default function createMSTeamsMiddleware() {
  let isFirstCreate = true
  microsoftTeams.initialize()
  return (storeAPI) => (next) => (action) => {
    const isTeams = selectIsMicrosoftTeams(storeAPI.getState())

    // Get the context at creation (don't create again if teams has already been initialized)
    if (isFirstCreate && !isTeams) {
      microsoftTeams.getContext(function (context) {
        dispatchContext(context)
        storeAPI.dispatch(initMSTeamsSuccess())
        isFirstCreate = false
      })
    }

    switch (action.type) {
      //case WebSocketConstants.CONNECTION_SUCCESSFUL:
      case WebSocketConstants.CONNECTION_RETRY_SUCCESSFUL:
      case AppConstants.FETCH_INIT.COMPLETE:
        let { initialState } = action
        initialState = initialState || {}
        storeAPI.dispatch(
          fetchGraphJoinedTeamChannels.request({
            isAzure: initialState.LoginProvider === AZURE_LOGIN_PROVIDER,
          })
        )
        if (action.type === AppConstants.FETCH_INIT.COMPLETE)
          storeAPI.dispatch(disableMSTeamsAutoLogin())

        microsoftTeams.getContext(function (context) {
          dispatchContext(context)
          isFirstCreate = false
        })
        break
      case MS_GRAPH_REQUEST_FAILURE:
        storeAPI.dispatch(logout())
        break
      default:
    }

    function dispatchContext(context) {
      // Context should be dispatched before init or else a login failure will occur
      if (context) {
        storeAPI.dispatch(FetchMSTeamsContextSuccess(context))
      }
    }

    return next(action)
  }
}
