import { useIcoFetch, UseIcoFetchOptions } from '../_utils/fetchUtils'
import { HoldingStatementTemplateModel } from './_models'

interface FetchTemplateResult {
  Status?: string
  StatusDescr?: string
  IssueID?: number
  UpdatedUTC?: Date
  Action?: string
  OrgID?: number
  returnData?: HoldingStatementTemplateModel[]
}
export function useFetchTemplate(orgID: number, options?: UseIcoFetchOptions) {
  return useIcoFetch<FetchTemplateResult>(
    `api/Organization/${orgID}/HoldingStatementTemplate`,
    {
      required: [orgID],
      ...options,
    }
  )
}
