// @ts-nocheck
// TODO: Typescript
import * as React from 'react'
import { Route, Switch, useLocation } from 'react-router'
import Layout from './Shared/Layout'
import AuthenticatedRoute from './Auth/AuthenticatedRoute'
import PageNotFound from './PageNotFound'

import { IssueSummaryFeedPage } from './Pages/IssueSummaryFeedPage'
import IssueDetailPage from './Pages/IssueDetailPage'
import { InvalidAccountPage } from './Pages/InvalidAccountPage'
import LoginFormPage from './Auth/LoginFormPage'
import { ReportsPage } from './Reports/ReportsPage'
import ForgotPasswordPage from './Auth/ForgotPasswordPage'
import ForgotPasswordEmailSentPage from './Auth/ForgotPasswordEmailSentPage'
import ResetAccountPasswordPage from './Auth/ResetAccountPasswordPage'
import MicrosoftTeamsSignIn from './Auth/MicrosoftTeamsSignIn'
import MicrosoftTeamsSignInResult from './Auth/MicrosoftTeamsSignInResult'
import ConfigurePage from './MicrosoftTeams/ConfigurePage'
import AzureADSuccess from './MicrosoftTeams/AzureADSuccess'
import MicrosoftErrorPage from './Pages/MicrosoftErrorPage'
import InvalidAzureTokenPage from './Pages/InvalidAzureTokenPage'
import { PlaybookPage } from './Pages/PlaybookPage'
import { AddPlaybookPage } from './Pages/AddPlaybookPage'
import { AlertPage } from './Pages/AlertPage copy'
import { PbChatPage } from './Pages/PbChatPage'
import { PbTaskPage } from './Pages/PbTaskPage'
import { InitialViewPage } from './Pages/InitialViewPage'
import CreateAccountEmailSentPage from './Auth/CreateAccountEmailSentPage'
import CreateAccountPage from './Auth/CreateAccountPage'
import CreateAccountConfirmPage from './Auth/CreateAccountConfirmPage'
import CreateAccountCancelPage from './Auth/CreateAccountCancelPage'
import { PrivacyPage } from './Pages/PrivacyPage'
import { useEffect } from 'react'
import { getPathSaveStateKey } from './Routing/_routeMatching'
import { useAppDispatch, useAppSelector } from './_utils/reactReduxHooks'
import { saveBasePathState } from './Routing/_actions'
import { useRollbarPerson } from '@rollbar/react'
import { selectUser } from './Auth/_selectors'
import { selectEnvironment } from './Issue/_selectors'
import { UserReportsPage } from './Pages/UserReportsPage'
import { AdminConsolePage } from './Admin/AdminConsolePage'
import { ActivePollsPage } from './Pages/ActivePollsPage'
import { DashboardPage } from './Dashboard/Shared/DashboardPage'
import { MasterLayoutUserTaskItemQueryPanel } from './Issue/MasterLayoutUserTaskItemQueryPanel'

import ReactGA from 'react-ga4'

//Initializing Google Analytics
ReactGA.initialize('G-B9VMJ8BC14')

// NOTE: The question mark after the path variable means that it is optional
const AppExport = () => {
  const location = useLocation()
  const dispatch = useAppDispatch()

  // Only log the user for dev and stage
  const user = useAppSelector((state) => {
    const env = selectEnvironment(state).toLocaleLowerCase()
    if (env !== 'production') return selectUser(state)
  })

  const rollbarUser = { id: user?.AppAcctID, email: user?.EmailAddress }

  useEffect(() => {
    const pathKey = getPathSaveStateKey(location.pathname)
    if (pathKey) dispatch(saveBasePathState(pathKey, location.pathname))
  }, [location.pathname, dispatch])

  useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: window.location.pathname })
  }, [])

  useRollbarPerson(rollbarUser)
  return (
    <Layout id="Ico_Issue_masterLayout">
      <Switch id="layoutViewContainer">
        {/* If Authenticated as Admin */}
        <AuthenticatedRoute
          awaitIssues={true}
          exact
          path="/Admin/AdminConsole"
          component={AdminConsolePage}
        />
        <AuthenticatedRoute
          awaitIssues={true}
          exact
          path="/Issues"
          component={IssueSummaryFeedPage}
        />
        <AuthenticatedRoute
          awaitIssues={true}
          exact
          path="/IssueDetail"
          component={IssueDetailPage}
          mobileComponent={IssueSummaryFeedPage}
        />
        <AuthenticatedRoute
          exact
          path="/"
          requireAuthorization={false}
          component={InitialViewPage}
        />
        <AuthenticatedRoute
          awaitIssues={true}
          path="/IssueDetail/:IssueID/:IssueTeamWorkspaceID?/:domain?/:domainId?/:domainItem?/:domainItemId?"
          component={IssueDetailPage}
        />
        <AuthenticatedRoute
          awaitIssues={true}
          path={'/Reports/:ReportName?/:IssueID?'}
          component={ReportsPage}
        />
        <AuthenticatedRoute
          requireAuthorization={false}
          exact
          path={['/Playbook/Playbooks/:PubGUID?/:EventID?', '/Playbook']}
          component={PlaybookPage}
        />
        <AuthenticatedRoute
          requireAuthorization={false}
          exact
          path={['/AddPlaybook']}
          component={AddPlaybookPage}
        />
        <AuthenticatedRoute
          requireAuthorization={false}
          exact
          path={['/PbChat/PbChat/:ChatGUID?', '/PbChat']}
          component={PbChatPage}
        />
        <AuthenticatedRoute
          requireAuthorization={false}
          exact
          path={['/PbTask/PbTask/:TaskGUID?', '/PbTask']}
          component={PbTaskPage}
        />
        <AuthenticatedRoute
          requireAuthorization={false}
          path={['/Alert']}
          component={AlertPage}
        />
        <AuthenticatedRoute
          exact
          path={['/Dashboard']}
          component={DashboardPage}
        />
        <AuthenticatedRoute
          exact
          path={['/UserTasksQueryPanel']}
          component={MasterLayoutUserTaskItemQueryPanel}
        />
        <AuthenticatedRoute
          requireAuthorization={false}
          exact
          path="/Privacy"
          component={PrivacyPage}
        />
        <AuthenticatedRoute
          path={'/UserReports/:UserReportsName?'}
          component={UserReportsPage}
        />
        <AuthenticatedRoute path={'/ActivePolls'} component={ActivePollsPage} />
        <Route path="/MicrosoftTeams/Configure" component={ConfigurePage} />
        <Route
          path="/AzureAD/InvalidAzureToken"
          component={() => <InvalidAzureTokenPage />}
        />
        <Route path="/AzureAD/Success" component={() => <AzureADSuccess />} />
        <AuthenticatedRoute
          path="/InvalidAccount"
          requireAuthorization={false}
          redirectOnAuthorized="/"
          component={InvalidAccountPage}
        />
        <Route path="/login" component={LoginFormPage} />
        <Route path="/ForgotPassword" component={ForgotPasswordPage} />
        <Route
          path="/ForgotPasswordEmailSent"
          component={ForgotPasswordEmailSentPage}
        />

        <Route
          path="/CreateAccountEmailSent"
          component={CreateAccountEmailSentPage}
        />
        <Route path="/CreateAccount" component={CreateAccountPage} />
        <Route path="/ConfirmEmail" component={CreateAccountConfirmPage} />
        <Route path="/CancelEmail" component={CreateAccountCancelPage} />
        {/*Microsoft Teams Azure AD signin*/}
        <Route path="/signin" component={MicrosoftTeamsSignIn} />
        <Route path="/signinResult" component={MicrosoftTeamsSignInResult} />
        <Route
          path="/ResetAccountPassword"
          component={ResetAccountPasswordPage}
        />
        <Route
          path="/Home/ErrorWithMessage"
          component={() => <MicrosoftErrorPage />}
        />
        <Route component={PageNotFound} />
      </Switch>
    </Layout>
  )
}

export default AppExport
