import chat, * as fromChat from '../Chat/_reducer'
import auth from '../Auth/_reducer'
import app, * as fromApp from '../_reducer'
import webSocket from '../_webSocket/_reducer'
import issue, * as fromIssue from '../Issue/_reducer'
import workspace from '../Workspace/_reducer'
import conference from '../Conference/_reducer'
import artifact from '../Upload/_reducer'
import participant, * as fromParticipant from '../Participant/_reducer'
import holdingStatement from '../HoldingStatement/_reducer'
import task, * as fromTask from '../Task/_reducer'
import issueLevel from '../IssueLevel/_reducer'
import requestStatus, * as fromRequestStatus from '../Shared/_requestStatusReducer'
import stakeholder from '../Stakeholder/_reducer'
import * as fromIssueForms from '../Form/_reducer'
import orgTag, * as fromOrg from '../Org/_reducer'
import msTeams, * as fromMsTeams from '../MicrosoftTeams/_reducer'
import {
  alertReducer,
  alertsSortedIDsReducer,
  inboxReducer,
  alertSearchReducer,
} from '../Alert/_reducer'
import portalUser from '../PortalUser/_reducer'
import publicPlaybookApp from '../AddPlaybook/_reducer'
import playbookApp from '../Playbook/_reducer'
import * as fromPlaybook from '../Playbook/_reducer'
import * as fromActivePoll from '../ActivePoll/_reducer'
import appAccount from '../AppAccount/_reducer'
import basePath from '../Routing/_reducer'

// All the reducers
export const reducers = {
  inbox: inboxReducer,
  alert: alertReducer,
  alertSearch: alertSearchReducer,
  alertIDs: alertsSortedIDsReducer,
  chat,
  chatEntry: fromChat.chatEntryReducer,
  chatUnreadCount: fromChat.chatUnreadCountReducer,
  chatRead: fromChat.chatReadReducer,
  auth,
  app,
  orgTag,
  orgUgisMembers: fromOrg.orgUgisMembers,
  msTeams,
  msTeamsEvent: fromMsTeams.msEventsReducer,
  msTeamsChannel: fromMsTeams.msChannelsReducer,
  msTeamsTeam: fromMsTeams.msTeamsReducer,
  appTypeMenu: fromApp.appTypeMenuReducer,
  issue,
  workspace,
  workspaceIssue: fromIssue.workspaceIssueReducer,
  conference,
  artifact,
  participant,
  participantStatus: fromParticipant.participantStatusReducer,
  orgIssueTeam: fromParticipant.orgIssueTeamReducer,
  stakeholder,
  issueApp: fromIssue.issueAppReducer,
  issueLatestLog: fromIssue.issueLatestLogReducer,
  issueActivityLog: fromIssue.issueActivityLogReducer,
  taskItem: fromTask.taskItemReducer,
  taskComment: fromTask.taskCommentReducer,
  holdingStatement,
  issueLevel,
  task,
  taskUnreadCount: fromTask.taskUnreadCountReducer,
  taskRead: fromTask.taskReadReducer,
  webSocket,
  afterSubmitIssueForms: fromIssueForms.afterSubmitIssueFormsReducer,
  updateIssueForms: fromIssueForms.updateIssueFormsReducer,
  createIssueForms: fromIssueForms.createIssueFormsReducer,
  currentFormContent: fromIssueForms.currentFormContentReducer,
  teamReport: fromIssueForms.teamReportReducer,
  requestStatus,
  requestSync: fromRequestStatus.requestSyncReducer,
  portalUser,
  publicPlaybookApp,
  playbookApp,
  playbookList: fromPlaybook.playbookReducer,
  activePollList: fromActivePoll.activePollListReducer,
  appAccount,
  basePath,
  evidenceLockerLinks: fromIssue.evidenceLockerLinkReducer,
  geolocations: fromIssue.GeolocationReducer,
}
