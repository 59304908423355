// @ts-nocheck
// TODO: Typescript
import React, { Component } from 'react'
import { connect } from 'react-redux'
import classes from './ReportsView.module.scss'
import IssueSummaryFeed from '../Issue/IssueSummaryFeed'
import { cx, isObjectEqualTo } from '../_utils/objectUtils'
import PropTypes from 'prop-types'
import {
  Paper,
  Select,
  CircularProgress,
  FormControl,
  InputLabel,
} from '@material-ui/core'
import { icoFetch, icoPost } from '../_utils/fetchUtils'
import { Button } from 'reactstrap'
import { prefixSelectorWithApp, sanitizePortalHTML } from '../_utils/domUtils'
import { saveAppHeaderTypePath } from '../_actions'
import { HPanelLayout, HPanel } from '../Layouts/HPanelLayout'
import {
  selectReportIssueIds,
  selectReportResolvedIssueIds,
} from '../_rootConfigs/rootSelectors'
import { isMobileApp } from '../_selectors'
import { selectRequestToken } from '../Auth/_selectors'
import { AdvisoryMUI } from '../Stakeholder/Icons'
import { Spacer } from '../Shared/Spacer'

// Fields that must be set inorder for the report to be requested
const requiredReportFields = {
  AfterAction: ['IssueID'],
  StakeholderInfluencer: ['IssueID'],
}

const reportID = prefixSelectorWithApp('report-content-viewer')
/**
 * @extends {Component<Props, {}>}}
 */
class ReportsView extends Component {
  constructor(props) {
    super(props)

    // Determine if the report view will control the report parameters or if it will be controlled from the outside
    if (props.useLocalState) {
      this.state = {
        ReportName: props.ReportName || props.initialReportName || '',
        IssueID: props.IssueID || props.initialIssueID,
        isLoading: false,
        reportHtml: '',
        isReportEmpty: false,
        localFields: {},
      }
    } else {
      this.state = {
        isLoading: false,
        ReportName: props.ReportName || props.initialReportName || '',
        reportHtml: '',
        isReportEmpty: false,
        IssueID: null,
        localFields: {},
      }
    }

    this.handleReportSelection = this.handleReportSelection.bind(this)
    this.handleIssueSelection = this.handleIssueSelection.bind(this)
    this.handleExcelExport = this.handleExcelExport.bind(this)
    this.handlePDFExport = this.handlePDFExport.bind(this)
    this.handleOnRedirect = this.handleOnRedirect.bind(this)
  }

  componentDidMount() {
    const reportFields = this.getReportFields()
    if (this.isRequiredParamsPresent(reportFields))
      this.handleReportFetch(reportFields)
  }

  componentDidUpdate(prevProps) {
    if (!this.props.useLocalState) {
      const reportFields = this.getReportFields()

      if (
        !isObjectEqualTo(prevProps, reportFields, ['IssueID', 'ReportName'])
      ) {
        const iframeBody = this.reportResultBody.contentDocument.body
        iframeBody.innerHTML = ''

        this.setState({
          reportHtml: '<div class="p-1">Select an issue</div>',
        })

        if (this.isRequiredParamsPresent(reportFields))
          this.handleReportFetch(reportFields)
      }
    }
  }

  handleOnRedirect() {
    const { onRedirect, saveAppHeaderTypePath, history } = this.props
    const { IssueID, ReportName } = this.state
    const { IssueTeamWorkspaceID } = this.state.localFields

    saveAppHeaderTypePath({
      type: 'reports',
      path: history.location.pathname,
    })

    if ((ReportName || '').toLocaleLowerCase() === 'stakeholderinfluencer')
      onRedirect({
        IssueID,
        IssueTeamWorkspaceID,
        domain: 'stakeholders',
      })
  }

  handlePDFExport() {
    this.exportForm.submit()
    /*
    const { reportHtml, ReportName } = this.state;
        const { onPDFExportClick } = this.props;
        onPDFExportClick({ html: reportHtml, name: ReportName }, blob => {
            var url = window.URL.createObjectURL(blob);
            this.exportLink.href = url;
            this.exportLink.download = ReportName + ".pdf";
            this.exportLink.click();
        });
    */
  }

  handleExcelExport() {
    this.exportLink.download = this.state.ReportName + '.xls'
    this.exportLink.href = `data:application/vnd.ms-excel;charset=utf-8,${encodeURIComponent(
      this.getExportHtml()
    )}`
    this.exportLink.click()
    /*if (!this.reportResultBody.contentDocument)
            return;
        const reportContainer = this.reportResultBody.contentDocument.getElementById('ico-workbench-report-container');

        if (!reportContainer)
            return;

        const excludeTags = ['STYLE', 'SCRIPT'];
        let exportHTML = ['<div>'];
        const children = reportContainer.children || [];
        for (let i = 0; i < children.length; i++) {
            const child = children[i];
            // Add to the html if the tag is added
            if (excludeTags.indexOf(child.tagName) === -1)
                exportHTML.push(child.outerHTML);
        }
        exportHTML.push('</div>');

        this.exportLink.download = this.state.ReportName + '.xls';
        this.exportLink.href = `data:application/vnd.ms-excel,${encodeURIComponent(exportHTML.join(''))}`;
        this.exportLink.click();*/
  }

  getExportHtml() {
    return `<!DOCTYPE html>
            <html lang="en">
                <head>
                    <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
                    <style>
                        .col-advisory-created {width: 180px !important; }
                    </style>
                </head>
                <body>
                    ${this.state.reportHtml}
                </body>
            </html>
        `
  }

  handleReportSelection(evt) {
    const { value } = evt.target
    this.setState({ reportHtml: '', isReportEmpty: false })
    this.updateReportFields({ ReportName: value })
  }

  handleIssueSelection({ IssueID, IssueName, IssueTeamWorkspaceID }) {
    this.updateReportFields({
      IssueID,
      IssueName,
      IssueTeamWorkspaceID,
    })
  }

  handleReportFetch(reportFields) {
    const iframeBody = this.reportResultBody.contentDocument.body
    iframeBody.innerHTML = ''

    this.setState({
      ...reportFields,
      isLoading: true,
      reportHtml: '',
      error: '',
      isReportEmpty: false,
    })

    const { ReportName, IssueID } = reportFields
    const reportMinWidth = {
      issuestracker: 1200,
    }

    const reportNameLower = (ReportName || '').toLocaleLowerCase()
    icoFetch(`WorkBenchReport/${ReportName}/${IssueID || ''}`)
      .then((response) => response.text())
      .then((reportHtml) => {
        // Script tag must be readded in order for the html to execute the JavaScript
        iframeBody.innerHTML = reportHtml
        const reportScript =
          this.reportResultBody.contentDocument.getElementById(
            'ico-workbench-report-script'
          ).innerHTML
        const reportScriptTag =
          this.reportResultBody.contentDocument.createElement('script')
        reportScriptTag.innerHTML = reportScript
        iframeBody.innerHTML = ''
        iframeBody.appendChild(reportScriptTag)

        const reportInnerID = reportID + '-inner'
        reportHtml = this.reportResultBody.contentDocument.getElementById(
          'ico-workbench-report-container'
        ).textContent

        var parser = new DOMParser()
        var reportDocument = parser.parseFromString(reportHtml, 'text/html')
        const isReportEmpty =
          reportDocument.getElementById('ico-workbench-report-is-empty')
            .value === 'true'

        const tableCells = reportDocument.querySelectorAll('td') || []

        for (var i = 0; i < tableCells.length; i++) {
          // Vertical align must be set for excel to show the proper format
          if (!tableCells[i].style.verticalAlign)
            tableCells[i].style.verticalAlign = 'middle'
        }

        /*const mainReport = reportDocument.getElementById(reportInnerID).children[0];
                if (mainReport) {
                    mainReport.style.width = '100%';
                    mainReport.style.minWidth = '900px';
                }*/

        // Wrap in the mainReport Container
        reportHtml = [
          `<div id="${reportInnerID}" style="min-width: ${
            reportMinWidth[reportNameLower] || 900
          }px; width: 100%;">`,
          sanitizePortalHTML(reportDocument.body.outerHTML).__html,
          `<style>#${reportInnerID} > *:first-child{width: 100%;}</style>`,
          '</div>',
        ].join('')

        // Doesn't send svg to the pdf or excel
        /*// Transform Advisory Created Yes/No columns to the advisory icon
                if (reportNameLower === 'stakeholderinfluencer') {
                    reportHtml = this.getReportWithIcons(reportHtml);
                }*/

        reportDocument = null

        iframeBody.innerHTML = ''

        this.setState({
          isLoading: false,
          reportHtml,
          isReportEmpty,
        })
      })
      .catch((error) => {
        this.setState({
          isLoading: false,
          error,
          reportHtml: '',
          isReportEmpty: true,
        })
      })
  }

  getReportFields() {
    if (!this.props.useLocalState) {
      const { ReportName, IssueID } = this.props
      return {
        ReportName,
        IssueID,
      }
    }

    return {
      ReportName: this.state.ReportName,
      IssueID: this.state.IssueID,
    }
  }

  getReportWithIcons(reportHtml: string) {
    var parser = new DOMParser()
    var reportDocument = parser.parseFromString(reportHtml, 'text/html')

    const advisoryIconHtml = this.advisoryCreatedIcon.innerHTML
    const advisoryCells =
      reportDocument.getElementsByClassName('col-advisory-created-data') || []
    for (let i = 0; i < advisoryCells.length; i++) {
      const cell = advisoryCells[i]
      if (cell.innerHTML === 'Yes') {
        cell.innerHTML = advisoryIconHtml
      } else cell.innerHTML = ''
    }

    return reportDocument.body.outerHTML
  }

  updateReportFields(newReportFields) {
    const { useLocalState, onParamsChanged } = this.props

    let reportFields = {
      ReportName: newReportFields.ReportName,
      IssueID: newReportFields.IssueID || null,
    }

    const localFields = {
      IssueName: newReportFields.IssueName || null,
      IssueTeamWorkspaceID: newReportFields.IssueTeamWorkspaceID || null,
    }

    const currentState = useLocalState ? this.state : this.props
    reportFields.ReportName = reportFields.ReportName || currentState.ReportName

    if (useLocalState) {
      this.setState({
        localFields,
        ...reportFields,
      })
    } else {
      this.setState({
        localFields,
      })
    }

    onParamsChanged(reportFields)
    if (this.isRequiredParamsPresent(reportFields)) {
      if (useLocalState) this.handleReportFetch(reportFields)
    }
  }

  isRequiredParamsPresent(reportFields) {
    const currentRequiredFields = requiredReportFields[reportFields.ReportName]
    let hasAllFields = reportFields.ReportName ? true : false
    if (currentRequiredFields) {
      for (let i = 0; i < currentRequiredFields.length && hasAllFields; i++)
        hasAllFields = reportFields[currentRequiredFields[i]] ? true : false
    }

    return hasAllFields
  }

  renderEmptyReportMessage() {
    let { ReportName } = this.state
    const { IssueName } = this.state
    ReportName = (ReportName || '').toLocaleLowerCase()

    switch (ReportName) {
      case 'stakeholderinfluencer':
        return (
          <div className="p-2">
            {' '}
            The Issue {IssueName ? `"${IssueName}"` : ''} does not have any
            Stakeholders.
            <Button
              className={cx(classes.stakeholderClickHere, 'ml-1', 'mr-1')}
              color="link"
              onClick={this.handleOnRedirect}
            >
              Please click here
            </Button>
            or return to the Issues Detail and enter Stakeholders of this
            particular Issue.
          </div>
        )
      case 'issuestracker':
        return (
          <div className="p-2">
            No accessible issues found. Either you are not assigned to any
            issues or you are not an approver on any issues your are assigned
            to.
          </div>
        )
    }

    return null
  }

  renderReportSelection(header?: any) {
    let { issueIds, resolvedIssueIds } = this.props
    const { ReportName, IssueID } = this.getReportFields()
    issueIds = ReportName === 'AfterAction' ? resolvedIssueIds : issueIds

    return (
      <HPanel
        header={header || 'Report Selection'}
        classes={{ content: 'overflow-auto' }}
        overflowY="hidden"
        hasPaper={false}
      >
        <div className={classes.reportSelectorContainer}>
          <Paper className={classes.reportSelector}>
            <FormControl className="w-100">
              {!ReportName ? (
                <InputLabel className={classes.reportSelectLabel}>
                  Select Report
                </InputLabel>
              ) : null}
              <Select
                name="reportSelector"
                className="w-100"
                native={true}
                onChange={this.handleReportSelection}
                value={ReportName}
              >
                {!ReportName ? <option value=""></option> : null}
                <option value="IssuesTracker">Executive Tracker</option>
                <option value="StakeholderInfluencer">Stakeholder</option>
                <option value="AfterAction">After Action</option>
              </Select>
            </FormControl>
          </Paper>
        </div>
        {ReportName &&
          (requiredReportFields[ReportName] || []).indexOf('IssueID') > -1 && (
            <IssueSummaryFeed
              className={cx(classes.issueSummaryFeed, 'pl-2')}
              compact={true}
              showDescription={false}
              showIssueLevels={true}
              showResolved={true}
              showResolvedLevelColor={true}
              showTags={false}
              hasFilter={false}
              hasSearch={false}
              canCreateIssue={false}
              showActions={false}
              minItemHeight={115}
              defaultItemHeight={115}
              selected={IssueID}
              locationIssueID={IssueID}
              onClick={this.handleIssueSelection}
              issueIds={issueIds}
              showIssuesNotFound={false}
              issueItemNameLines={1}
            />
          )}
      </HPanel>
    )
  }

  render() {
    let { isMobileApp, requestToken } = this.props

    return (
      <div id={prefixSelectorWithApp('ReportsView')} className={classes.root}>
        {/*Allows me to get the html for using in the Advisory Created column*/}
        <span ref={(el) => (this.advisoryCreatedIcon = el)} className="d-none">
          <AdvisoryMUI />
        </span>
        <HPanelLayout>
          {/*<MobileLayout>
                        <Drawer open={false}>
                            {this.renderReportSelection(<>Report Selection <FontAwesomeIcon className="float-right" icon={faTimes} /></>)}
                        </Drawer>
                    </MobileLayout>
                    <DesktopLayout>
                        {this.renderReportSelection()}
                    </DesktopLayout>*/}
          {this.renderReportSelection()}
          <HPanel
            header="Report Result"
            classes={{
              headerExtras: classes.exportBtns,
            }}
            headerExtras={
              !isMobileApp && (
                <div className="d-flex">
                  <Button
                    disabled={
                      !this.state.reportHtml || this.state.isReportEmpty
                    }
                    name="export-btn-excel"
                    className="btn btn-secondary"
                    onClick={this.handleExcelExport}
                  >
                    Excel
                  </Button>
                  <Spacer />
                  <Button
                    disabled={
                      !this.state.reportHtml || this.state.isReportEmpty
                    }
                    name="export-btn-pdf"
                    className="btn btn-secondary"
                    onClick={this.handlePDFExport}
                  >
                    PDF
                  </Button>
                  <a
                    className="d-none"
                    target="_blank"
                    rel="noopener noreferrer"
                    ref={(el) => (this.exportLink = el)}
                  ></a>
                  <form
                    method="post"
                    action="api/Export/PDFForm"
                    encType="multipart/form-data"
                    ref={(el) => (this.exportForm = el)}
                  >
                    <input
                      type="hidden"
                      name="html"
                      value={window.btoa(
                        encodeURIComponent(this.state.reportHtml)
                      )}
                    />
                    <input
                      type="hidden"
                      name="name"
                      value={this.state.ReportName}
                    />
                    <input
                      type="hidden"
                      name="X-CSRF-TOKEN"
                      value={requestToken}
                    />
                  </form>
                </div>
              )
            }
            fillWidth={true}
            hasPaper={true}
          >
            <div
              className={cx(
                classes.loadingContainer,
                !this.state.isLoading ? 'd-none' : ''
              )}
            >
              <CircularProgress disableShrink={true} />
            </div>
            <div
              className={cx(
                classes.reportResultBody,
                this.state.isLoading ? 'd-none' : ''
              )}
            >
              <iframe
                className="d-none"
                ref={(el) => (this.reportResultBody = el)}
              />
              <div
                id={reportID}
                className={classes.reportContentViewer}
                style={{
                  background: 'white',
                  overflow: 'auto',
                }}
              >
                <div
                  dangerouslySetInnerHTML={{
                    __html: this.state.reportHtml,
                  }}
                />
                {this.state.isReportEmpty && this.renderEmptyReportMessage()}
              </div>
            </div>
          </HPanel>
        </HPanelLayout>
      </div>
    )
  }
}

ReportsView.propTypes = {
  isUserWorkspaceParticipant: PropTypes.bool,
  isIssueResolved: PropTypes.bool,
  initialReportName: PropTypes.string,
  initialIssueID: PropTypes.number,
  onParamsChanged: PropTypes.func,
  useLocalState: PropTypes.bool,
  onPDFExportClick: PropTypes.func,
  onRedirect: PropTypes.func,
}

ReportsView.defaultProps = {
  useLocalState: true,
  onRedirect: () => {},
}

const mapStateToProps = (state, _) => {
  return {
    resolvedIssueIds: selectReportResolvedIssueIds(state),
    issueIds: selectReportIssueIds(state),
    onPDFExportClick: (data, onSuccess, onFailure = () => {}) => {
      icoPost('api/Export/PDF', data)
        .then((response) => response.blob())
        .then(onSuccess)
        .catch(onFailure)
    },
    isMobileApp: isMobileApp(state),
    requestToken: selectRequestToken(state),
  }
}

const mapDispatchToProps = {
  saveAppHeaderTypePath: saveAppHeaderTypePath,
}

export default connect(mapStateToProps, mapDispatchToProps)(ReportsView)
