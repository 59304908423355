import { faPlus, faTag, faTimes } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Input } from '@material-ui/core'
import React from 'react'
import { useDispatch } from 'react-redux'
import { Organization } from '../Issue/_reducer'
import { OrgTag } from '../Org/_models'
import { AdminConsoleDialog } from '../Shared/AdminConsoleDialog'
import { addNotification } from '../_actions'
import { createTag, deleteTag, updateTag } from '../_utils/apiUtils'
import classes from './AdminConsolePage.module.scss'

export const AdminConsoleTag = ({
  selectedOrg,
}: {
  selectedOrg: Organization
}) => {
  const [selectedTag, setSelectedTag] = React.useState({} as OrgTag)
  const [addingTag, setAddingTag] = React.useState(false)
  const [tagInputName, setTagInputName] = React.useState('')
  const [tagSaveDialogOpen, setTagSaveDialogOpen] = React.useState(false)
  const [tagDeleteDialogOpen, setTagDeleteDialogOpen] = React.useState(false)

  const dispatch = useDispatch()

  const handleTagChange = React.useCallback(() => {
    let errorMessage = ''
    if (tagInputName === selectedTag.TagName || tagInputName === '') {
      errorMessage = 'Tag has not changed or is empty and cannot be submitted'
    }
    if (errorMessage) {
      dispatch(
        addNotification({
          message: errorMessage,
          type: 'error',
        })
      )
      return
    }
    setTagSaveDialogOpen(true)
  }, [dispatch, selectedTag.TagName, tagInputName])

  React.useEffect(() => {
    setTagInputName(selectedTag.TagName || '')
  }, [selectedTag])

  const orgTagNames =
    selectedOrg?.OrgIssueTags?.map((tag) => (
      <div
        key={tag.TagID}
        className={classes.OrganizationTag}
        onClick={() => {
          if (selectedTag.TagID === tag.TagID) return
          setSelectedTag(tag)
          setAddingTag(false)
        }}
      >
        <div className={classes.OrganizationTagText} key={tag.TagID}>
          {tag.TagName}
        </div>
      </div>
    )) || []

  orgTagNames.push(
    <div key="newTagAdd">
      <div
        className={classes.OrganizationTag}
        onClick={() => {
          setAddingTag(true)
          setTagInputName('')
          setSelectedTag({} as OrgTag)
        }}
      >
        <FontAwesomeIcon icon={faPlus} />
      </div>
    </div>
  )

  const TagManagementDialogs = () => {
    return (
      <>
        <AdminConsoleDialog
          open={tagSaveDialogOpen}
          content={
            'Are you sure you would like to save this tag? (Saving/Updating a tag will also affect any issue with the tag attached to it)'
          }
          onConfirm={() => {
            addingTag
              ? createTag(tagInputName, selectedOrg.OrgID)
              : updateTag(selectedTag, tagInputName)
            setTagSaveDialogOpen(false)
          }}
          onCancel={() => setTagSaveDialogOpen(false)}
        />
        <AdminConsoleDialog
          open={tagDeleteDialogOpen}
          content={
            'Are you sure you would like to delete this tag? (Deleting a tag will also affect any issue with the tag attached to it)'
          }
          consoleRole="destructive"
          onConfirm={() => {
            deleteTag(selectedTag)
            setTagDeleteDialogOpen(false)
          }}
          onCancel={() => {
            setTagDeleteDialogOpen(false)
          }}
        />
      </>
    )
  }

  return (
    <>
      <TagManagementDialogs />
      <div className={classes.OrganizationManagementSection}>
        <div className={classes.OrganizationManagementSectionHeader}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <FontAwesomeIcon icon={faTag} size={'2x'} />{' '}
            &nbsp;&nbsp;&nbsp;&nbsp; <div>TAGS</div>
          </div>
        </div>
        <div className={classes.OrganizationInnerManagementSection}>
          <div className={classes.OrganizationTagContainer}>
            {orgTagNames?.length > 0
              ? orgTagNames
              : 'There are no tags for this organization'}
          </div>
          {selectedTag?.TagID || addingTag ? (
            <div className={classes.OrganizationTagEditorContainer}>
              <div className={classes.OrganizationInnerContainerEditHeader}>
                Tag Name:
                <FontAwesomeIcon
                  icon={faTimes}
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    setSelectedTag({} as OrgTag)
                    setAddingTag(false)
                  }}
                />
              </div>
              <Input
                type="text"
                required={true}
                name="Tag"
                inputProps={{
                  maxLength: 255,
                }}
                style={{ width: '100%' }}
                value={
                  tagInputName || tagInputName === ''
                    ? tagInputName
                    : selectedTag.TagName
                }
                onChange={(e) => setTagInputName(e.target.value)}
              />
              <div className={classes.OrganizationTagButtonContainer}>
                {addingTag ? null : (
                  <Button
                    className={classes.OrganizationDeleteButton}
                    onClick={() => setTagDeleteDialogOpen(true)}
                    style={{ color: 'red' }}
                  >
                    Delete
                  </Button>
                )}
                <Button
                  onClick={() => {
                    handleTagChange()
                  }}
                  style={{ color: 'rgb(64, 148, 195)' }}
                >
                  Save
                </Button>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </>
  )
}
