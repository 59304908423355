import * as React from 'react'
import { useEffect, useState, useRef, ReactNode } from 'react'
import Checkbox from '../CustomMaterial/Checkbox'
import {
  TreeNode,
  TreeViewApi as ApiCache,
  TreeViewCache,
  TreeViewCacheOptions,
  getSelectedType,
} from './TreeViewCache'
import classes from './TreeView.module.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMinusSquare, faPlusSquare } from '@fortawesome/pro-light-svg-icons'
import { useForceUpdate } from '../_utils/hooks'

interface Props {
  data: any
  options: TreeViewCacheOptions
  renderOptions?: RenderOptions
  onChange?: () => void
  onApi?: (api: TreeViewApi) => void
  firstOpen?: boolean
}

interface RenderOptions {
  parentRenderer?: (parentData: any) => ReactNode
  childRenderer?: (childData: any) => ReactNode
}

export interface TreeViewApi {
  getSelected: () => string[]
}

export const TreeView = ({
  data,
  options,
  renderOptions,
  onChange,
  onApi,
  firstOpen = true,
}: Props) => {
  const [api, setApi] = useState<ApiCache>()
  const [expanded, setExpanded] = useState<{ [key: string]: boolean }>({})
  const apiWrapper = useRef<ApiCache>()
  // Props that are set on mount and cannot be reset
  const staticProps = useRef<{ options: TreeViewCacheOptions }>({ options })

  // call your hook here
  const forceUpdate = useForceUpdate()

  useEffect(() => {
    onApi?.call(undefined, {
      getSelected: () =>
        !apiWrapper.current ? [] : apiWrapper.current.getSelected(),
    })
  }, [onApi])

  useEffect(() => {
    const newApi = TreeViewCache(data, staticProps.current.options)
    setApi(newApi)
    apiWrapper.current = newApi
    /*const top = newApi.getTop();
        if (top && staticProps.current.firstOpen) {
            handleCollapseToggle(top.parentKey);
        }*/
  }, [data])

  function handleCollapseToggle(key: string, defaultExpanded: boolean) {
    if (isNodeExpanded(key, defaultExpanded)) {
      setExpanded({
        ...expanded,
        [key]: false,
      })
    } else {
      setExpanded({
        ...expanded,
        [key]: true,
      })
    }
  }

  function isNodeExpanded(
    tree: TreeNode | string,
    defaultExpanded: boolean = false
  ) {
    const expandedByKey =
      expanded[typeof tree === 'object' ? tree.parentKey : tree]
    return expandedByKey || (expandedByKey === undefined && defaultExpanded)
  }

  function handleToggleAll(parent: TreeNode) {
    const selectedType = getSelectedType(parent)
    api?.setSelectedParent(parent, selectedType === 'all' ? false : true)
    forceUpdate()
    onChange?.call(undefined)
  }

  function toggleSelected(key: string) {
    api?.setSelected(key, !api?.isChildSelected(key))
    forceUpdate()
    onChange?.call(undefined)
  }

  function RenderTreeView(
    treeApi: ApiCache,
    cacheOptions: TreeViewCacheOptions,
    tree?: TreeNode,
    defaultExpanded: boolean = false
  ) {
    if (!tree) return null

    const selectedType = getSelectedType(tree)
    const isExpanded = isNodeExpanded(tree, defaultExpanded)
    const expandIcon = isExpanded ? faMinusSquare : faPlusSquare
    const { parentRenderer, childRenderer } = renderOptions || {}
    return (
      <div key={tree.parentKey} className={classes.treeItemGroup}>
        <FontAwesomeIcon
          icon={expandIcon}
          onClick={() => handleCollapseToggle(tree.parentKey, defaultExpanded)}
        />
        <Checkbox
          onClick={() => handleToggleAll(tree)}
          checked={selectedType === 'all' ? true : false}
          indeterminate={selectedType === 'partial' ? true : false}
        />
        <span className={classes.treeItemGroupLabel}>
          {parentRenderer ? parentRenderer(tree.data) : tree.parentKey}
        </span>
        {isExpanded && tree?.children && tree?.children.length > 0 && (
          <div className={classes.treeItemSubGroup}>
            {tree.children.map((c) => {
              const childKey = c.data[cacheOptions.childKey]
              const isSelected = treeApi.isChildSelected(childKey)
              return (
                <div key={childKey} className={classes.treeItem}>
                  <Checkbox
                    checked={isSelected}
                    onClick={() => toggleSelected(childKey)}
                  />
                  <span className={classes.treeItemLabel}>
                    {childRenderer ? childRenderer(c.data) : childKey}
                  </span>
                </div>
              )
            })}
          </div>
        )}
        {isExpanded &&
          tree?.childParents &&
          tree?.childParents?.length > 0 &&
          tree.childParents.map((childParent) =>
            RenderTreeView(treeApi, cacheOptions, childParent)
          )}
      </div>
    )
  }

  if (!api) return null

  return (
    <div className={classes.root}>
      {RenderTreeView(api, options, api.getTop(), firstOpen)}
    </div>
  )
}
