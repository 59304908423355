import { getFetchListener } from '../_utils/sagaUtils'
import {
  FETCH_INBOXES,
  FETCH_ALERTS,
  ALERT_FILTER,
  ALERT_FILTER_SAVE,
  ALERT_SEVERITY_COLORS,
} from './_constants'
import { takeLatest, call, put } from 'redux-saga/effects'
import {
  fetchInboxes,
  fetchAlerts,
  alertFilterSaveFailure,
  alertFilterSuccess,
  alertFilterSaving,
  alertFilterSaveSuccess,
  alertSeverityColorsSuccess,
} from './_actions'
import { getCleanArray } from '../_utils/objectUtils'
import { normalize } from 'normalizr'
import {
  transformEntities,
  inboxListSchema,
  alertListSchema,
} from '../_schema/_schemas'
import { AlertsReqResult, InboxModel } from './_models'
import { DefaultPayload } from '../_utils/reduxUtils'
import { icoPost } from '../_utils/fetchUtils'

// The Saga that does the actual work
const fetchInboxesHeaders = { getAction: 'inboxes' }
const fetchInboxesSaga = getFetchListener('api/alert', {
  request: {
    headers: fetchInboxesHeaders,
  },
  formatData: false,
  onLoading: fetchInboxes.loading,
  onFailure: fetchInboxes.failure,
  onSuccess: (inboxes: InboxModel[]) => {
    inboxes = inboxes || []
    inboxes = getCleanArray(inboxes)
    transformEntities(
      inboxes,
      'created',
      'lastAlertCreated',
      'searchScheduleTime',
      'searchLastDate'
    )
    // Clean the feeds array
    inboxes.forEach((inbox) => (inbox.feeds = getCleanArray(inbox.feeds)))
    // Normalize
    let normalizedData = normalize(inboxes, inboxListSchema)
    // return action with entities(lookup table) and results(id array) as the payload
    return [
      fetchInboxes.success(
        {
          ids: normalizedData.result,
          idMap: normalizedData.entities.inboxes,
        },
        fetchInboxesHeaders
      ),
    ]
  },
})

const fetchAlertSaga = getFetchListener('api/alert', {
  getHeaders: (action) => ({
    getAction: 'alerts',
    inboxID: action.payload.params.inboxID,
    statusID: action.payload.params.statusID,
    feedGUID: action.payload.params.feedGUID,
  }),
  formatData: false,
  onLoading: fetchAlerts.loading,
  onFailure: fetchAlerts.failure,
  onSuccess: (result: AlertsReqResult[], _: any, params: any) => {
    if (result && result.length > 0) {
      let newAlerts =
        result.length === 1 && result[0].items
          ? result[0].items
          : result.length > 1
            ? result
            : []
      newAlerts = getCleanArray(newAlerts)
      transformEntities(newAlerts, 'alertCreated', 'updated')
      let normalizedData = normalize(newAlerts, alertListSchema)
      return fetchAlerts.success(
        {
          ids: normalizedData.result,
          idMap: normalizedData.entities.alerts,
          count: newAlerts.length,
        },
        params,
        { inboxGUID: result[0]?.inboxGUID ? result[0]?.inboxGUID : '0' }
      )
    } else return fetchAlerts.success({ ids: [], idMap: {}, count: 0 }, params)
  },
})

// TODO need to type Generator accept type
const alertFilterSave = function* (action: {
  type: string
  payload: DefaultPayload
}): any {
  try {
    const url = '/api/Alert/FilterSave'
    yield put(alertFilterSaving())
    const response = yield call(icoPost, url, action.payload.params)
    const json = yield call([response, 'json']) || []
    if (json.errorMessage) {
      yield put(alertFilterSaveFailure(json.errorMessage))
    } else {
      let returnData = json.returnData || {}
      yield put(alertFilterSaveSuccess(returnData))
    }
  } catch (ex: any) {
    yield put(alertFilterSaveFailure(ex.error))
  }
}

const fetchAlertFilters = getFetchListener('/api/Alert/Filters', {
  onSuccess: (json) => {
    return alertFilterSuccess(json)
  },
})
const fetchAlertSeverityColors = getFetchListener('/api/Alert/SeverityColors', {
  onSuccess: (json) => {
    return alertSeverityColorsSuccess(json)
  },
})

// Watcher sagas
export const alertSagas = [
  takeLatest(FETCH_INBOXES.REQUEST, fetchInboxesSaga),
  takeLatest(FETCH_ALERTS.REQUEST, fetchAlertSaga),
  takeLatest(ALERT_FILTER_SAVE.REQUEST, alertFilterSave),
  takeLatest(ALERT_FILTER.FETCH, fetchAlertFilters),
  takeLatest(ALERT_SEVERITY_COLORS.FETCH, fetchAlertSeverityColors),
]
