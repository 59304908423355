import React, { ReactNode, useState } from 'react'
import {
  StretchLayout,
  StretchHeader,
  StretchFooter,
} from '../Layouts/StretchLayout'
import { ArtifactUpload } from '../Upload/ArtifactUpload'
import { EvidenceLockerList } from './EvidenceLockerList'
import { SubmittedFormsViewer } from './SubmittedFormsViewer'
import { cx } from '../_utils/objectUtils'
import { EvidenceLockerAddLink } from './EvidenceLockerAddLink'
import { Button } from '../Shared/Buttons'
import { Spacer } from '../Shared/Spacer'

export interface ListType {
  type: 'Attachment' | 'form' | 'link'
  id: string | number
}

interface Props {
  IssueTeamWorkspaceID: number
  IssueID: number
  header?: ReactNode
  disabled?: boolean
  className?: string
}

export const EvidenceLocker = ({
  IssueTeamWorkspaceID,
  header,
  disabled,
  className,
  IssueID,
}: Props) => {
  const [selectedItem, setSelectedItem] = useState<ListType>()
  const [isOpenAddLink, setIsOpenAddLink] = useState(false)

  const RenderList = (
    <>
      <EvidenceLockerList
        IssueTeamWorkspaceID={IssueTeamWorkspaceID}
        IssueID={IssueID}
        canEdit
        setSelectedItem={setSelectedItem}
        selectedItem={selectedItem}
      />
      <StretchFooter>
        <div className={cx('d-flex justify-content-end p-3')}>
          <Button onClick={() => setIsOpenAddLink(true)}>Add Link</Button>
          <Spacer />
          <ArtifactUpload
            IssueTeamWorkspaceID={IssueTeamWorkspaceID}
            disabled={disabled}
          />
        </div>
      </StretchFooter>
      <EvidenceLockerAddLink
        IssueTeamWorkspaceID={IssueTeamWorkspaceID}
        IssueID={IssueID}
        isOpen={isOpenAddLink}
        onCancel={() => setIsOpenAddLink(false)}
      />
    </>
  )

  return IssueTeamWorkspaceID ? (
    <StretchLayout className={className}>
      {header && <StretchHeader>{header}</StretchHeader>}
      {selectedItem?.type === 'form' ? (
        <SubmittedFormsViewer
          closeSelectedForm={() => setSelectedItem(undefined)}
          selectedForm={
            typeof selectedItem.id === 'number' ? selectedItem.id : undefined
          }
        />
      ) : (
        RenderList
      )}
    </StretchLayout>
  ) : null
}
