import React from 'react'

interface IProps {
  spaceParam?: 'tiny' | 'small' | 'medium' | 'large' | number
}

export const Spacer = ({ spaceParam = 'medium' }: IProps) => {
  let size: string
  switch (spaceParam) {
    case 'tiny':
      size = '1px'
      break
    case 'small':
      size = '4px'
      break
    case 'medium':
      size = '8px'
      break
    case 'large':
      size = '16px'
      break
    default:
      size = `${spaceParam}px`
      break
  }

  return (
    <span
      style={{
        display: 'block',
        alignSelf: 'center',
        justifySelf: 'center',
        height: size,
        minHeight: size,
        width: size,
        minWidth: size,
        border: 'none',
        margin: 'none',
        padding: 'none',
      }}
      aria-hidden={true}
    />
  )
}
