import { Drawer, Switch } from '@material-ui/core'
import * as React from 'react'
import { Button } from '../../Shared/Buttons'
import { Spacer } from '../../Shared/Spacer'
import { AdminQueryIssueTableOption } from './AdminIssueQueryPanel'

export const AdminIssueQueryTableSelector = ({
  open,
  onClose,
  onSave,
  onSelection,
  selectedOptions,
  selectionList,
}: {
  open: boolean
  onClose: () => void
  onSave: () => void
  onSelection: (selectionOption: AdminQueryIssueTableOption) => void
  selectedOptions: AdminQueryIssueTableOption[]
  selectionList: AdminQueryIssueTableOption[]
}) => {
  const isOptionSelected = (option: AdminQueryIssueTableOption) => {
    return (
      selectedOptions?.filter((selectedOption) => selectedOption === option)
        .length > 0
    )
  }

  const [width, setWidth] = React.useState<number>(window.innerWidth)

  function handleWindowSizeChange() {
    setWidth(window.innerWidth)
  }

  React.useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange)
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange)
    }
  }, [])

  const isMobile = width <= 960

  return (
    <Drawer anchor="right" open={open} onClose={onClose}>
      <div
        style={{
          width: isMobile ? '100%' : '40rem',
          height: '100%',
          backgroundColor: '#f2f2f2',
        }}
      >
        <div
          className="DashboardSectionSelectionHeader"
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            height: '70px',
            alignItems: 'center',
            background: 'white',
            padding: '20px',
            boxShadow: '0 3px 10px rgba(0, 0, 0, 0.2)',
            position: 'sticky',
            top: '0',
            zIndex: 50,
          }}
        >
          <div style={{ fontSize: '18px' }}>Select Columns</div>
          <div style={{ display: 'flex' }}>
            <Button onClick={() => onClose()}> Cancel</Button>
            <Spacer />
            <Button onClick={() => onSave()}> Save </Button>
          </div>
        </div>
        <div
          className="DashboardSectionSelectionList"
          style={{
            margin: '1rem',
            border: '1px solid lightgrey',
            borderRadius: '2px',
            backgroundColor: 'white',
            boxShadow: '0 3px 10px rgba(0, 0, 0, 0.2)',
          }}
        >
          {selectionList.map((option, index) => {
            return (
              <div
                key={index}
                className="DashboardListSelection"
                style={{
                  width: '100%',
                  height: '70px',
                  borderRadius: '5px',
                  borderBottom: '1px solid #f2f2f2',
                  display: 'flex',
                  justifyContent: 'space-between',
                  padding: '10px',
                }}
              >
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      fontSize: '16px',
                    }}
                  >
                    {option}
                  </div>
                </div>
                <div style={{ minWidth: '30px' }}>
                  <Switch
                    color="primary"
                    checked={isOptionSelected(option)}
                    onClick={() => onSelection(option)}
                  />
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </Drawer>
  )
}
