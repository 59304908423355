// @ts-nocheck
// TODO: Typescript
import {
  ChatDetailConstants,
  CREATE_DIRECT_MESSAGE,
  DELETE_DIRECT_MESSAGE,
  UPDATE_CHAT_READ,
} from './_constants'
import {
  ChatDetailActions,
  createDirectMessage,
  createOwnerDirectMessage,
  deleteDirectMessage,
  incrementChatUnreadCount,
  fetchLatestChatDates,
  updateChatRead,
} from './_actions'
import { WebSocketConstants } from '../_webSocket/_constants'
import { objDatePropsToUTC } from '../_utils/dateUtils'
import Push from 'push.js'
import { getValueFromKey, JsonParseSafe } from '../_utils/objectUtils'
import { getArtifactId } from '../_schema/_schemas'
import { ArtifactActions } from '../Upload/_actions'
import { batch } from 'react-redux'
import { addNotification } from '../_actions'
import { selectUserEmail } from '../_rootConfigs/rootSelectors'
import { isViewingChat } from './_selectors'
import { stripHtml } from '../_utils/domUtils'
import { WorkspaceConstants } from '../Workspace/_constants'

export default function chatHub(storeAPI, action, hubConnection) {
  switch (action.type) {
    case WebSocketConstants.CONNECTION_RETRY_SUCCESSFUL:
    case WebSocketConstants.CONNECTION_SUCCESSFUL:
      // NOTE: Need to think about how insertion of chats will happen while the user awaits a new list
      hubConnection.on('BroadcastChatDetailItem', (data, source) => {
        data = JsonParseSafe(data)
        if (!data.ErrorMessage) {
          const ChatID =
            getValueFromKey(data, 'returnData.ChatID') || (source || {}).ChatID
          const email = source?.email
          const appUserEmail = selectUserEmail(
            storeAPI.getState()
          ).toLocaleLowerCase()
          const entry = getValueFromKey(data, 'returnData.chatentry.0')
          objDatePropsToUTC(entry, 'CreatedUTC')
          var upload = entry.upload
          entry.upload = undefined
          const isCaller = email.toLocaleLowerCase() === appUserEmail

          batch(() => {
            if (upload) {
              entry.upload = getArtifactId(upload)
              entry.Content = upload.ArtifactDescription
              objDatePropsToUTC(upload, 'CreatedUTC')
              storeAPI.dispatch(
                ArtifactActions.addSuccess(upload, {
                  ...source,
                  ChatID,
                  icoRequestId: entry.upload,
                })
              )
            }

            try {
              storeAPI.dispatch(
                ChatDetailActions.addSuccess(entry, {
                  ...source,
                  ChatID,
                })
              )
            } catch (ex) {
              console.log(ex)
            }

            if (!isCaller)
              storeAPI.dispatch(
                incrementChatUnreadCount(ChatID, entry.CreatedUTC)
              )
            else
              storeAPI.dispatch(
                updateChatRead.success(entry.CreatedUTC, { ChatID })
              )
          })

          if (
            Push.Permission.has() &&
            !isCaller &&
            !isViewingChat(storeAPI.getState(), ChatID)
          ) {
            Push.create('New Chat Detail entry: ', {
              body: stripHtml(entry.Content),
              //icon: '/icon.png',
              timeout: 4000,
              onClick: function () {
                window.focus()
                this.close()
              },
            })
          }
        }
      })

      hubConnection.on('BroadcastChatItem', (data, source, isCaller) => {
        data = JsonParseSafe(data)
        let chat = data.returnData
        source = {
          ...source,
          IssueTeamWorkspaceID: data.IssueTeamWorkspaceID,
        }
        // remove the user from the participant list
        const userEmail = selectUserEmail(
          storeAPI.getState()
        ).toLocaleLowerCase()
        chat.Participants = chat.Participants.filter(
          (p) => p.EmailAddress.toLocaleLowerCase() !== userEmail
        )

        if (isCaller)
          storeAPI.dispatch(
            createOwnerDirectMessage.success(chat, undefined, source)
          )

        storeAPI.dispatch(createDirectMessage.success(chat, undefined, source))
      })

      hubConnection.on('BroadcastChatDelete', (data, source, isCaller) => {
        data = JsonParseSafe(data)
        let chat = data.returnData

        if (data.Status === 'Success')
          storeAPI.dispatch(
            deleteDirectMessage.success(chat, undefined, source)
          )
        else if (isCaller)
          storeAPI.dispatch(
            deleteDirectMessage.failure(chat, undefined, source)
          )
      })

      hubConnection.on('BroadcastChatReadUpdate', (data) => {
        objDatePropsToUTC(data.returnData, 'LastRead')
        storeAPI.dispatch(
          updateChatRead.success(data.returnData.LastRead, {
            ChatID: data.ChatID,
          })
        )
      })

      break
    case UPDATE_CHAT_READ.REQUEST:
      hubConnection
        .invoke('SendChatReadUpdate', action.payload.params)
        .catch((err) => console.error(err.toString()))
      break
    case ChatDetailConstants.ADD.REQUEST:
      hubConnection
        .invoke('SendChatDetailItem', action.payload.result)
        .catch((err) => console.error(err.toString()))
      break
    /*case ChatConstants.ADD.REQUEST:
            hubConnection.invoke("SendChatItem", action.payload.result);
            break;*/
    case CREATE_DIRECT_MESSAGE.REQUEST:
      storeAPI.dispatch(
        createOwnerDirectMessage.request(
          action.payload.params,
          action.payload.source
        )
      )
      hubConnection.invoke(
        'SendChatItem',
        action.payload.params,
        action.payload.source
      )
      break
    case DELETE_DIRECT_MESSAGE.REQUEST:
      hubConnection.invoke(
        'SendChatDelete',
        action.payload.params,
        action.payload.source
      )
      break
    case ChatDetailConstants.FETCH.FAILURE:
      storeAPI.dispatch(
        addNotification({
          message: action.payload.ErrorMessage,
        })
      )
      break
    case WorkspaceConstants.INIT.SUCCESS: // Moved here by the init was happening after the dates were received
      const fetchLatestDates = fetchLatestChatDates.request()
      fetchLatestDates.dontRenewAuth = true
      storeAPI.dispatch(fetchLatestDates)
      break
    default:
      break
  }
}

//chatHub.connectedOnly = true;
