// @ts-nocheck
// TODO: Typescript
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { LoginForm } from '../Auth/LoginForm'
import * as microsoftTeams from '@microsoft/teams-js'
import { isUserAuthenticated } from '../Auth/_selectors'

/**
 * @extends {Component<Props, {}>}}
 * Ateam report items
 */
export class Configure extends Component {
  componentDidMount() {
    if (this.props.isAuthenticated) {
      microsoftTeams.settings.setValidityState(true)

      //Specify what happens when the user clicks on "Save"
      microsoftTeams.settings.registerOnSaveHandler((saveEvent) => {
        microsoftTeams.settings.setSettings({
          //Important when referencing your connector from somewhere else within Teams
          entityId: 'IcoIssueWeb',
          //This name will be shown to channel admins under "configured connectors" - Use something that helps admins to identify this specific connection
          configName: 'IcoIssueWebConfig',
          //The URL to the configuration page for further config changes
          contentUrl: window.location.origin,
        })

        //You have to notify Teams that the saving process was successful, otherwise the save operation will time out and display an error
        saveEvent.notifySuccess()
      })
    }
  }

  render() {
    const { isAuthenticated } = this.props
    if (!isAuthenticated) return <LoginForm {...this.props} />
    else
      return (
        <div>
          You are signed in. Click save to continue to issues. (Add
          configurations here if needed)
        </div>
      )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    isAuthenticated: isUserAuthenticated(state),
  }
}

export default connect(mapStateToProps, undefined)(Configure)
