// @ts-nocheck
// TODO: Typescript
import React, { Component } from 'react'
import { AuthMasterLayout } from './AuthMasterLayout'
import { connect } from 'react-redux'
import { selectDnrEmail } from '../Issue/_selectors'
import { Spacer } from '../Shared/Spacer'
import { cx } from '../_utils/objectUtils'
import classes from './LoginForm.module.scss'

const addressStyle = { color: '#0033CC' }

/**
 * @extends {Component<Props, {}>}}
 */
class CreateAccountEmailSent extends Component {
  render() {
    const { dnrEmail, history } = this.props
    const email = history?.location?.state?.email || ''

    return (
      <AuthMasterLayout {...this.props} showHomeLink={true} showAlert={false}>
        <div
          className={cx(
            classes.form,
            classes.formBackground,
            classes.formContent
          )}
        >
          <h5>Check Your Email Account</h5>
          <Spacer spaceParam={'large'} />
          <Spacer spaceParam={'large'} />

          <p>
            If no account with the specified email already exists, a
            confirmation email will be sent to
            <span style={addressStyle}> {email} </span>
            with instructions on how to complete the set-up process.
          </p>
          <p>
            If you do not see it, please check your account, and please add
            <span style={addressStyle}> {dnrEmail} </span>
            to your expected senders list.
          </p>
        </div>
      </AuthMasterLayout>
    )
  }
}

const mapStateToProps = (state) => ({
  dnrEmail: selectDnrEmail(state),
})

export default connect(mapStateToProps)(CreateAccountEmailSent)
