// @ts-nocheck
// TODO: Typescript

import moment from 'moment'

export function dateUTCbuilder(jsonDate) {
  if (!jsonDate) return

  let date: Date | string
  // convert dates so they work the same across all browsers:  Safari, Chrome, Edge
  if (typeof jsonDate === 'string') {
    if (jsonDate.endsWith('Z')) date = new Date(jsonDate)
    else {
      date = new Date(jsonDate + '-00:00')
    }
  } else date = jsonDate

  date = new Date(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds()
  )

  return date
}

export function objMapDatePropsToUTC(objMap, ...dateKeys) {
  for (var key in objMap) {
    rowDatePropsToUTC(objMap[key], dateKeys)
  }

  return objMap
}

export function getTimezoneOffset() {
  return new Date().getTimezoneOffset()
}

export function getTodayUTC() {
  const today = new Date()
  return addMinutesToDate(new Date(), today.getTimezoneOffset())
}

export function getLocalDate(date) {
  var dt = new Date(date)
  var ofst = dt.getTimezoneOffset()
  return addMinutesToDate(dt, 0 - ofst)
}

export function getUTCDate(date) {
  var dt = new Date(date)
  var ofst = dt.getTimezoneOffset()
  return addMinutesToDate(dt, 0 + ofst)
}

export function addMinutesToDate(date, minutes) {
  return new Date(date?.getTime() + minutes * 60000)
}

export function addDaysToDate(d, days) {
  var clonedDate = new Date(d.getTime())

  if (!days) return clonedDate

  clonedDate.setDate(clonedDate.getDate() + days)
  return clonedDate
}

export function getTomrrowUTCDate() {
  let tomorrow = addDaysToDate(getTodayUTC(), 1)
  return clearDateTime(tomorrow)
}

export function clearDateTime(d) {
  d.setHours(0)
  d.setMinutes(0)
  d.setSeconds(0)
  d.setMilliseconds(0)
  return d
}

export function getClearedDateTime(d) {
  d = new Date(d.getTime())
  d.setHours(0)
  d.setMinutes(0)
  d.setSeconds(0)
  d.setMilliseconds(0)
  return d
}

const dayNames = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
]

export function getDayNameFromDate(d) {
  return d ? dayNames[d.getDay()] : ''
}

export function getNumericalDayFromDate(d) {
  return d ? moment(d).format('Do') : ''
}

export function compareDates(a, b) {
  const aValue = a ? a.getTime() : 0
  const bValue = b ? b.getTime() : 0

  if (aValue < bValue) return -1
  if (aValue > bValue) return 1

  return 0
}

export function objDatePropsToUTC(obj, ...dateKeys) {
  if (obj instanceof Array) {
    obj.forEach((row) => {
      rowDatePropsToUTC(row, dateKeys)
    })
    return obj
  } else {
    return rowDatePropsToUTC(obj, dateKeys)
  }
}

export function objDatePropsToLocal(obj, ...dateKeys) {
  if (obj instanceof Array) {
    obj.forEach((row) => {
      rowDatePropsToLocal(row, dateKeys)
    })
    return obj
  } else {
    return rowDatePropsToLocal(obj, dateKeys)
  }
}

function rowDatePropsToUTC(row, dateKeys, value, isLocal = false) {
  if (row) {
    dateKeys.forEach((key) => {
      if (row.hasOwnProperty(key)) {
        if (row[key]) {
          let conDate = dateUTCbuilder(value || row[key])
          if (isLocal) conDate = getLocalDate(conDate)

          row[key] = conDate
        }
      }
    })
  }

  return row
}

function rowDatePropsToLocal(row, dateKeys, value) {
  return rowDatePropsToUTC(row, dateKeys, value, true)
}

export function diff_minutes(dt2, dt1) {
  var diff = (dt2 - dt1) / 1000
  diff /= 60
  return Math.round(diff)
}

export function diff_weeks(dt2, dt1) {
  var diff = (dt2.getTime() - dt1.getTime()) / 1000
  diff /= 60 * 60 * 24 * 7
  return Math.round(diff)
}
