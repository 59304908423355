// @ts-nocheck
// TODO: Typescript
import React, { useState } from 'react'
import { prefixSelectorWithApp } from '../_utils/domUtils'
import { AuthMasterLayout } from './AuthMasterLayout'
import { Redirect } from 'react-router-dom'
import { Button } from 'reactstrap'
import { TextField } from '../Shared/TextField'
import { icoPost } from '../_utils/fetchUtils'
import { cx, isEmail } from '../_utils/objectUtils'
import classes from './LoginForm.module.scss'
import { Spacer } from '../Shared/Spacer'

/**
 * @extends {Component<Props, {}>}}
 */
const ForgotPasswordPageExport = (props) => {
  const { userEmailAddress } = (props.location || {}).state || {}
  const [email, setEmail] = useState(userEmailAddress || '')
  const [error, setError] = useState('')
  const [isEmailSubmitted, setIsEmailSubmitted] = useState(false)

  const handleSubmitClick = () => {
    setError('')
    const cleanedEmail = (email || '').trim()
    if (cleanedEmail && !isEmail(cleanedEmail)) {
      setError('Invalid email entered')
    } else if (cleanedEmail) {
      icoPost('/api/Password/NewRequest', { email: cleanedEmail })
        .then((response) => response.json())
        .then((data) => {
          if (!data.ErrorMessage) setIsEmailSubmitted(true)
          else setError(data.ErrorMessage)
        })
        .catch(() => {
          setError('Password reset email not sent.')
        })
    } else setError("User's email is required")
  }

  const handleEmailKeyDown = (event) => {
    var enter = 13
    if (event.keyCode === enter) {
      handleSubmitClick()
    }
  }

  const handleEmailChange = (evt) => setEmail(evt.target.value)

  if (isEmailSubmitted)
    return (
      <Redirect
        to={{
          pathname: '/ForgotPasswordEmailSent',
          state: { userEmailAddress: email },
        }}
      />
    )

  return (
    <AuthMasterLayout
      {...props}
      id={prefixSelectorWithApp('ForgotPasswordPage')}
      showAlert={error ? true : false}
      alertText={error}
      alertColor="danger"
    >
      <div
        className={cx(
          classes.form,
          classes.formBackground,
          classes.formContent
        )}
      >
        <h5>Request Password Reset</h5>
        <Spacer spaceParam={'large'} />
        <Spacer spaceParam={'large'} />

        <TextField
          label="Email Address"
          type="text"
          name="em"
          id={prefixSelectorWithApp('client-em')}
          autoFocus={true}
          value={email}
          onChange={handleEmailChange}
          onKeyDown={handleEmailKeyDown}
        />
        <Spacer spaceParam={'large'} />
        <Spacer spaceParam={'large'} />
        <Button color="darker" onClick={handleSubmitClick}>
          Send Password Reset Email
        </Button>
      </div>
    </AuthMasterLayout>
  )
}

export default ForgotPasswordPageExport
