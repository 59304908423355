import React, { useState } from 'react'
import { IssueDynamicForm } from './IssueDynamicForm'
import { IssueDynamicFormsList } from './IssueDynamicFormList'

interface Props {
  IssueID: number
}

export const IssueScribeFormsViewer = ({ IssueID }: Props) => {
  const [lastSaveSubmit, setLastSaveSubmit] = useState('')

  return (
    <>
      <IssueDynamicForm
        IssueID={IssueID}
        dfName="Scribe Notes"
        setLastSaveSubmit={setLastSaveSubmit}
      />
      <IssueDynamicFormsList
        IssueID={IssueID}
        dfName="Scribe Notes"
        lastSaveSubmit={lastSaveSubmit}
      />
    </>
  )
}
