import {
  ParticipantConstants,
  FETCH_PARTICIPANTS_STATUS,
  MARK_PARTICIPANT_AS_OFFLINE,
  MARK_PARTICIPANT_AS_ONLINE,
  OrgIssueTeamConstants,
} from './_constants'

import { IssueConstants } from '../Issue/_constants'
import { createEntityReducer } from '../_schema/_reducer'
import {
  ParticipantsState,
  ParticipantStatusState,
} from '../Conference/_models'

export const participantsReducer = createEntityReducer<ParticipantsState>(
  'ParticipantID',
  {
    crudTypes: ParticipantConstants,
    reducer: (state, action) => ({
      [IssueConstants.FETCH.REQUEST]: () => state,
    }),
  }
)
export default participantsReducer

export const selectParticipantsIdMap = (state: ParticipantsState) => state
export const selectParticipantById = (
  state: ParticipantsState,
  participantID: number
) => selectParticipantsIdMap(state)[participantID]

export const selectParticipantJoinedTeamNames = (
  state: ParticipantsState,
  participantID: number
) => {
  const part = selectParticipantById(state, participantID) || {}
  const teams = part.Teams || []
  let teamsBuilder: Array<any> = []
  teams.forEach((team) => teamsBuilder.push(team.TeamName))
  teamsBuilder.sort()
  return teamsBuilder.join(', ')
}

export function participantStatusReducer(
  state: ParticipantStatusState = {},
  action: any
) {
  const { result } = action.payload || {}
  switch (action.type) {
    case FETCH_PARTICIPANTS_STATUS.SUCCESS:
      return action.payload.result || {}
    case MARK_PARTICIPANT_AS_OFFLINE:
      if (result instanceof Array) {
        let newOffline: { [id: string]: any } = {}
        result.forEach(
          (e) =>
            (newOffline[formatEmail(e.emailAddress || e.EmailAddress)] =
              'offline')
        )
        return {
          ...state,
          ...newOffline,
        }
      }

      return {
        ...state,
        [formatEmail(result.emailAddress || result.EmailAddress)]: 'offline',
      }
    case MARK_PARTICIPANT_AS_ONLINE:
      if (result instanceof Array) {
        let newOnline: { [id: string]: any } = {}
        result.forEach(
          (e) =>
            (newOnline[formatEmail(e.emailAddress || e.EmailAddress)] =
              'online')
        )
        return {
          ...state,
          ...newOnline,
        }
      }

      return {
        ...state,
        [formatEmail(result.emailAddress || result.EmailAddress)]: 'online',
      }
    default:
      return state
  }
}

function formatEmail(email: string | undefined) {
  return (email || '').toLocaleLowerCase().trim()
}

export const selectParticipantStatus = (
  state: ParticipantStatusState,
  email: string
) => (state || {})[email]
export const isParticipantOnline = (
  state: ParticipantStatusState,
  email: string
) => selectParticipantStatus(state, email) === 'online'
export const isParticipantOffline = (
  state: ParticipantStatusState,
  email: string
) => selectParticipantStatus(state, email) === 'offline'

export const orgIssueTeamReducer = createEntityReducer('TeamID', {
  crudTypes: OrgIssueTeamConstants,
})

export const selectOrgIssueTeamsIdMap = (state: any) => state
export const selectOrgIssueTeamById = (state: any, teamID: number | string) =>
  (selectOrgIssueTeamsIdMap(state) || {})[teamID]
