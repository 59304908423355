import { useEffect, useRef, useState, useCallback } from 'react'

export function useIsMounted() {
  const isMounted = useRef(false)
  useEffect(() => {
    isMounted.current = true
    return () => {
      isMounted.current = false
    }
  }, [])

  return useCallback(() => isMounted.current, [])
}

export function useForceUpdate() {
  const [value, setValue] = useState(false) // boolean state
  return () => setValue(!value) // update the state to force render
}

export function useInterval(callback: Function, delay?: number) {
  const savedCallback = useRef<Function>()
  const timerId = useRef<number>()

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current?.call(undefined)
    }
    if (delay !== null) {
      clearInterval(timerId.current)
      timerId.current = setInterval(tick, delay) as any
      return () => clearInterval(timerId.current)
    }
  }, [delay])
}

export function useBookmarkFixCallback() {
  const prevBody = useRef<HTMLElement>()
  const curBody = useRef<HTMLElement>()
  const handleAnchorBookmark = useRef((evt: MouseEvent) => {
    if (!evt.target || !curBody.current) return

    const anchor = getAnchor(evt.target as HTMLElement)
    if (anchor && anchor.tagName === 'A' && anchor.hash.startsWith('#')) {
      evt.preventDefault()

      const namedEl = curBody.current.querySelector(
        '[name="' + decodeURI(anchor.hash.replace('#', '')) + '"]'
      )

      if (namedEl) {
        namedEl.scrollIntoView({ behavior: 'smooth' })
      }
    }
  })

  return useCallback((body: HTMLElement) => {
    curBody.current = body
    if (prevBody.current)
      prevBody.current.removeEventListener(
        'click',
        handleAnchorBookmark.current
      )

    body?.addEventListener('click', handleAnchorBookmark.current)
    prevBody.current = body
  }, [])
}

interface WebLinkFixOptions {
  openPlan?: (href: string) => void
  openEvent?: (href: string) => void
}

export function useWebLinkFixCallback(options?: WebLinkFixOptions) {
  const iFrame = useRef(document.createElement('iframe', {}))
  const prevBody = useRef<HTMLElement>()
  const curBody = useRef<HTMLElement>()
  options = options || {}
  const { openPlan, openEvent } = options

  const handleLinks = useRef((evt: MouseEvent) => {
    const anchor = getAnchor(evt.target as HTMLElement)
    let { hash } = anchor || {}
    const isBookmark = hash && hash.startsWith('#') ? true : false
    if (anchor && anchor.href && !isBookmark) {
      if (anchor.protocol?.startsWith('http')) {
        evt.preventDefault()
        iFrame.current.style.display = 'block'
        let href = anchor.href
        if (!href.startsWith('https')) href = href.replace('http', 'https')

        //iFrame.current.src = href;
        window.open(href, '_blank')
      } else if (
        anchor.protocol === 'iba-icoc-corp' ||
        anchor.protocol === 'iba-icoc-corp:'
      ) {
        //console.log('found iba-icoc-corp link');
        evt.preventDefault()
        if (anchor.href.startsWith('iba-icoc-corp://openplan')) {
          openPlan?.call(undefined, anchor.href)
          // console.log('need to open another plan ' + anchor.href);
        }
        if (anchor.href.startsWith('iba-icoc-corp://openevent')) {
          openEvent?.call(undefined, anchor.href)
          //console.log('need to open another event ' + anchor.href);
        }
      }
    }
  })

  return useCallback((body: HTMLElement) => {
    curBody.current = body

    iFrame.current.style.position = 'absolute'
    iFrame.current.style.height = '100%'
    iFrame.current.style.width = '100%'
    iFrame.current.style.zIndex = '999'
    iFrame.current.style.display = 'none'

    if (prevBody.current) {
      prevBody.current.removeEventListener('click', handleLinks.current)
      //prevBody.current.removeChild(iFrame.current);
    }

    if (body) {
      body.addEventListener('click', handleLinks.current)
      //body.appendChild(iFrame.current);
    }

    prevBody.current = body
  }, [])
}

function getAnchor(target: HTMLElement) {
  if (target.tagName === 'A') return target as HTMLAnchorElement

  // check for descendants
  //let anchor = target.querySelector('a');

  // check for parent
  /*if (!anchor)
        anchor = target.parentElement as HTMLAnchorElement;*/
  return target.parentElement as HTMLAnchorElement

  /*if (!anchor)
        return;

    return anchor;*/
}
