import { bindSel, getApp } from './_rootConfigs/rootReducerSlicers'
import * as fromApp from './_reducer'

export const isMobileApp = bindSel(getApp, fromApp.isMobileApp)
export const selectPartRoles = bindSel(getApp, fromApp.selectPartRoles)
export const selectPartRoleNameById = bindSel(
  getApp,
  fromApp.selectPartRoleNameById
)
export const selectMotivations = bindSel(getApp, fromApp.selectMotivations)
export const selectMotivationNameById = bindSel(
  getApp,
  fromApp.selectMotivationNameById
)
export const selectMotivationColorById = bindSel(
  getApp,
  fromApp.selectMotivationColorById
)
export const selectTrends = bindSel(getApp, fromApp.selectTrends)
export const selectTrendNameById = bindSel(getApp, fromApp.selectTrendNameById)
export const selectTrendColorById = bindSel(
  getApp,
  fromApp.selectTrendColorById
)
export const selectSmsCountryCodes = bindSel(
  getApp,
  fromApp.selectSmsCountryCodes
)
export const selectCountryByAbbr = bindSel(getApp, fromApp.selectCountryByAbbr)
export const selectIssuesLogCategories = bindSel(
  getApp,
  fromApp.selectIssuesLogCategories
)
export const selectAppHeaderTypePath = bindSel(
  getApp,
  fromApp.selectAppHeaderTypePath
)
export const isAudioExtension = bindSel(getApp, fromApp.isAudioExtension)
export const isVideoExtension = bindSel(getApp, fromApp.isVideoExtension)
export const isApplicationExtension = bindSel(
  getApp,
  fromApp.isApplicationExtension
)
export const isTextExtension = bindSel(getApp, fromApp.isTextExtension)
export const isImageExtension = bindSel(getApp, fromApp.isImageExtension)
export const selectMediaTypeByExtension = bindSel(
  getApp,
  fromApp.selectMediaTypeByExtension
)
export const selectUserFilters = bindSel(getApp, fromApp.selectUserFilters)
export const selectUserInitialState = bindSel(
  getApp,
  fromApp.selectUserInitialState
)
export const selectNotyMsgQueue = bindSel(getApp, fromApp.selectNotyMsgQueue)
export const isNotyOpened = bindSel(getApp, fromApp.isNotyOpened)
export const selectNotyDefaultType = bindSel(
  getApp,
  fromApp.selectNotyDefaultType
)
