import {
  bindSel,
  getPlaybookApp,
  getPlaybookList,
} from '../_rootConfigs/rootReducerSlicers'
import * as priv from './_reducer'

export const selectPlaybookSearchValue = bindSel(
  getPlaybookApp,
  priv.selectPlaybookSearchValue
)
export const selectPlaybookList = bindSel(
  getPlaybookList,
  priv.selectPlaybookList
)
