import { ParticipantConstants } from '../Participant/_constants'
import { reducerCrud } from '../_utils/reduxUtils'
import { FETCH_APP_ACCOUNTS, UPDATE_APP_ACCOUNT } from './_constants'
import { AppAccountModel } from './_models'

interface AppAccountState {
  [AppAccountID: number]: AppAccountModel
}

export default function appAccountReducer(
  state: AppAccountState = {},
  action: any
) {
  switch (action.type) {
    case ParticipantConstants.INIT.SUCCESS:
    case ParticipantConstants.FETCH.SUCCESS:
      let appAccounts: AppAccountState = {}
      for (var key in action.payload.result) {
        var p = action.payload.result[key]
        if (p.AppAcctID) {
          appAccounts[p.AppAcctID] = { ...p }
        }
      }

      return {
        ...state,
        ...appAccounts,
      }
    case ParticipantConstants.ADD.SUCCESS:
      if (!action.payload.result.AppAcctID) return state

      var participant = action.payload.result
      var oldAppAcct = state[participant.AppAcctID] || {}
      var newAppAcct = { ...participant }
      return {
        ...state,
        [participant.AppAcctID]: {
          ...oldAppAcct,
          ...newAppAcct,
        },
      }
    case ParticipantConstants.UPDATE_RANGE.SUCCESS:
      return reducerCrud.updateRange(state, action, 'AppAcctID')
    case UPDATE_APP_ACCOUNT.SUCCESS:
      return {
        ...state,
        [action.payload.result?.AppAcctID]: {
          ...state[action.payload.result?.AppAcctID],
          ...action.payload.result,
        },
      }
    case FETCH_APP_ACCOUNTS.SUCCESS:
      let appAccountsFromFetch = action.payload.result || {}

      for (let key in appAccountsFromFetch) {
        if (appAccountsFromFetch[key].RespAppAcctID)
          appAccountsFromFetch[key].AppAcctID =
            appAccountsFromFetch[key].RespAppAcctID
      }

      return {
        ...state,
        ...appAccountsFromFetch,
      }
    default:
      return state
  }
}

export const selectAppAccountEmail = (
  state: AppAccountState,
  appAcctID: number
) => state[appAcctID]?.email_address
export const selectAppAccountFullname = (
  state: AppAccountState,
  appAcctID: number
) => state[appAcctID]?.FullName
export const selectAppAccountDisplayName = (
  state: AppAccountState,
  appAcctID: number
) => {
  let email = selectAppAccountEmail(state, appAcctID)
  const fullname = selectAppAccountFullname(state, appAcctID)

  /*if (fullname)
        return `${fullname} (${email})`;*/

  if (fullname) return fullname

  if (!email) return

  email = email.substr(0, email.indexOf('@'))
  let emailTokens = email.split('.')
  emailTokens = emailTokens.map((t) => t[0].toLocaleUpperCase() + t.slice(1))

  return emailTokens.join(' ')
}
