import { HubConnection } from '@microsoft/signalr'
import { Store } from 'redux'
import { AppConstants } from '../_constants'
import { setRequestToken } from '../_utils/fetchUtils'
import { FETCH_REQUEST_TOKEN } from './_constants'

export default function authHub(
  storeAPI: Store,
  action: any,
  hubConnection: HubConnection
) {
  switch (action.type) {
    case FETCH_REQUEST_TOKEN.SUCCESS:
      setRequestToken(action.payload.result)
      break
    case AppConstants.FETCH_INIT.COMPLETE:
      setRequestToken(action.initialState.csrf)
      break
    default:
      break
  }
}

// connectedOnly false signifies that this hub middleware should be ran even when the hub is disconnected
authHub.connectedOnly = false
