import * as React from 'react'
import {
  DialogContent,
  DialogProps,
  FormControlLabel,
  Radio,
  RadioGroup,
  Toolbar,
} from '@material-ui/core'
import { MouseEvent, ChangeEvent } from 'react'
import { HeaderNavigation } from '../Shared/HeaderNavigation'
import { ResponsiveDialog } from '../Shared/ResponsiveDialog'
import classes from './TaskCsvDialog.module.scss'
import { useAppDispatch } from '../_utils/reactReduxHooks'
import { addNotification } from '../_actions'
import { SlideLeft } from '../Shared/Transition'
import { FilePickerAndSubmitButton } from '../Upload/FilePickerAndSubmitButton'
import { getFilenameExt } from '../_utils/fileUtils'
import { uploadCsvTaskItems } from './_actions'

import { parseBool } from '../_utils/objectUtils'
import Checkbox from '../CustomMaterial/Checkbox'

interface Props extends DialogProps {
  TaskID: number
  onClose: (event: MouseEvent<Element>) => void
}

enum UploadAction {
  Overwrite = 'true',
  Append = 'false',
}

export const TaskUploadCsvDialog = ({ TaskID, onClose, ...other }: Props) => {
  const dispatch = useAppDispatch()
  const [uploadAction, setUploadAction] = React.useState(UploadAction.Overwrite)

  const [includeAssignments, setIncludeAssignments] = React.useState(true)

  const toggleIncludeAssignments = () => {
    setIncludeAssignments((includeAssignments) => !includeAssignments)
  }

  function handleSubmit(file: File) {
    if (getFilenameExt(file.name)?.toLocaleLowerCase() !== 'csv')
      dispatch(addNotification({ message: 'The file must be a csv' }))
    else {
      dispatch(
        uploadCsvTaskItems(TaskID, file, {
          ReplaceRows: parseBool(uploadAction),
          IncludeAssignments: includeAssignments,
        })
      )
      onClose({} as any)
    }
  }

  function handleUploadActionChange(event: ChangeEvent<HTMLInputElement>) {
    setUploadAction(event.target.value as UploadAction)
  }

  return (
    <ResponsiveDialog TransitionComponent={SlideLeft as any} {...other}>
      <Toolbar>
        <HeaderNavigation
          title="Upload Task List"
          onNavClick={onClose}
          canGoBack={false}
        />
      </Toolbar>
      <DialogContent className={classes.content}>
        <div className={classes.contentInner}>
          <RadioGroup
            className={classes.section}
            onChange={handleUploadActionChange}
            value={uploadAction}
          >
            <FormControlLabel
              value={UploadAction.Overwrite}
              control={<Radio color="primary" />}
              label="Overwrite current list"
            />
            <FormControlLabel
              value={UploadAction.Append}
              control={<Radio color="primary" />}
              label="Append to current list"
            />
          </RadioGroup>
          <div style={{ marginBottom: '20px', borderTop: '1px solid black' }}>
            <div>
              <Checkbox
                style={{ marginLeft: '-12px' }}
                checked={includeAssignments}
                name={'includeAssignments'}
                onChange={toggleIncludeAssignments}
              />
              Include Assignments
            </div>
            {includeAssignments && (
              <div style={{ fontSize: '12px', color: 'red' }}>
                Assignments will only be included for those team members in your
                Issue Participant list.
              </div>
            )}
          </div>
          <FilePickerAndSubmitButton
            submitText="Upload Task"
            onSubmit={handleSubmit}
            accept="text/csv"
          />
        </div>
      </DialogContent>
    </ResponsiveDialog>
  )
}
