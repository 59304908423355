import React, { useEffect } from 'react'
import { Prompt } from 'react-router-dom'

interface Props {
  hasUnsavedChanges: boolean
  warningMessage?: string
}

export const UnsavedChangesPrompt = ({
  hasUnsavedChanges,
  warningMessage = 'You have unsaved changes, are you sure you want to leave?',
}: Props) => {
  useEffect(() => {
    const onBeforeUnload = (e: BeforeUnloadEvent) => {
      if (hasUnsavedChanges) {
        e.preventDefault()
        e.returnValue = ''
      }
    }
    window.addEventListener('beforeunload', onBeforeUnload)

    return () => {
      window.removeEventListener('beforeunload', onBeforeUnload)
    }
  }, [hasUnsavedChanges])

  return <Prompt when={hasUnsavedChanges} message={warningMessage} />
}
