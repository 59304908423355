import { AppConstants, SAVE_APP_HEADER_TYPE_PATHS } from './_constants'
import { LoginConstants } from './Auth/_constants'

// TODO: Typescript type the state
/*interface State {
    allowedUploadExtsSet
    roles
    motivations
    trends
    smsCountryCodes
    notification
}*/

const defaultNotificationType = 'info'
const initialState = {
  isLoaded: false,
  notification: {
    isOpened: false,
    defaultType: defaultNotificationType,
    messageQueue: [
      /*{
                message: '',
                type: defaultNotificationType,
                duration: 0,
                hasCloseBtn: falseap
            }*/
    ],
  } /*,
    notification: {
        message: '',
        type: defaultNotificationType,
        duration: 0,
        isOpened: false,
        hasCloseBtn: false

    }*/,
  initialState: { selectedChatIds: [] },
}

// TODO: Typescript change any
export default function app(state = initialState, action: any) {
  switch (action.type) {
    case AppConstants.FETCH_INIT.REQUEST:
      return Object.assign({}, state, {
        isLoaded: false,
      })

    case LoginConstants.SUCCESSFUL:
    case AppConstants.FETCH_INIT.COMPLETE:
      let rolesMap: { [key: number]: string } = {}
      const initialState = action.initialState || {}
      // TODO: Typescript change any
      ;(initialState.roles || []).forEach((r: any) => {
        rolesMap[r.Value] = r.Descr
      })

      let motivationsMap: { [key: number]: any } = {}
      // TODO: Typescript change any
      ;(initialState.motivations || []).forEach((m: any) => {
        motivationsMap[m.MotivationID] = m
      })

      let trendsMap: { [key: number]: any } = {}
      // TODO: Typescript change any
      ;(initialState.trends || []).forEach((t: any) => {
        trendsMap[t.TrendID] = t
      })

      let userFilters = initialState.filters || {}

      // Allowed uploads
      let allowedUploadExts = initialState.allowedUploadExts || {}
      let allowedUploadExtsSet: { [key: string]: Set<string> } = {}
      for (let fileType in allowedUploadExts) {
        const lowerFileType = fileType.toLowerCase()
        allowedUploadExtsSet[lowerFileType] = new Set()
        allowedUploadExts[fileType].forEach((ext: string) =>
          allowedUploadExtsSet[lowerFileType].add(ext)
        )
      }

      // CountryAbbr to Country
      let countryAbbrToCountryMap: { [key: string]: any } = {}
      // TODO: Typescript change any
      ;(initialState.smsCountryCodes || []).forEach((c: any) => {
        countryAbbrToCountryMap[c.CountryAbbr] = c
      })
      return Object.assign({}, state, {
        isLoaded: true,
        initialState: action.initialState,
        rolesMap,
        motivationsMap,
        trendsMap,
        allowedUploadExtsSet,
        countryAbbrToCountryMap,
        userFilters,
        issuesLogCategories: initialState.issuesLogCategories,
      })

    // Handle the the notification queue
    case AppConstants.ADD_NOTIFICATION:
      return createNotification(action.notification)

    // Close snackbar
    case AppConstants.CLOSE_NOTIFICATION:
      if (
        state.notification.isOpened &&
        state.notification.messageQueue.length > 0
      ) {
        return closeNotification()
      }
      return state

    case AppConstants.DISPLAY_CURRENT_NOTIFICATION:
      return Object.assign({}, state, {
        notification: Object.assign({}, state.notification, {
          isOpened: true,
        }),
      })

    default:
      return state
  }

  function closeNotification() {
    var messageQueue = state.notification.messageQueue
    messageQueue =
      messageQueue.length > 0 ? messageQueue.slice(1) : messageQueue

    return Object.assign({}, state, {
      notification: Object.assign({}, state.notification, {
        isOpened: false,
        messageQueue,
      }),
    })
  }

  // TODO: Typescript create message interface
  function createNotification(newMessage: { type: string; duration: number }) {
    if (newMessage.duration === undefined) newMessage.duration = 0

    if (!newMessage.type) newMessage.type = defaultNotificationType

    return Object.assign({}, state, {
      notification: Object.assign({}, state.notification, {
        isOpened: true,
        messageQueue: [...state.notification.messageQueue, newMessage],
      }),
    })
  }
}

// TODO: Typescript any for all states
export const isMobileApp = (state: any) =>
  (state.initialState || {}).isMobileApp
export const selectPartRoles = (state: any) => (state.initialState || {}).roles
export const selectPartRoleNameById = (state: any, roleId: number) =>
  (state.rolesMap || {})[roleId]
export const selectMotivations = (state: any) =>
  (state.initialState || {}).motivations
export const selectMotivationById = (state: any, motivationId: number) =>
  (state.motivationsMap || {})[motivationId]
export const selectMotivationNameById = (state: any, motivationId: number) =>
  (selectMotivationById(state, motivationId) || {}).MotivationName
export const selectMotivationColorById = (state: any, motivationId: number) =>
  (selectMotivationById(state, motivationId) || {}).MotivationRGB
export const selectTrends = (state: any) => (state.initialState || {}).trends
export const selectTrendById = (state: any, trendId: number) =>
  (state.trendsMap || {})[trendId]
export const selectTrendNameById = (state: any, trendId: number) =>
  (selectTrendById(state, trendId) || {}).TrendName
export const selectTrendColorById = (state: any, trendId: number) =>
  (selectTrendById(state, trendId) || {}).TrendRGB
export const selectSmsCountryCodes = (state: any) =>
  state.initialState.smsCountryCodes
export const selectUserFilters = (state: any) => state.initialState.filters
export const selectUserInitialState = (state: any) => state.initialState
export const selectIssuesLogCategories = (state: any) =>
  (state.initialState || {}).issuesLogCategories
export const selectCountryByAbbr = (state: any, abbr: string) =>
  (state.countryAbbrToCountryMap || {})[(abbr || '').toLocaleUpperCase()]
export const isExtensionByType = (state: any, type: string, ext: string) => {
  if (!ext || !type) return false

  if (!state.allowedUploadExtsSet) return false

  type = type.toLocaleLowerCase()
  const typeSet = state.allowedUploadExtsSet[type]

  if (!typeSet) return false

  return typeSet.has(ext.toLocaleLowerCase())
}
export const isAudioExtension = (state: string, ext: string) =>
  isExtensionByType(state, 'audio', ext)
export const isVideoExtension = (state: string, ext: string) =>
  isExtensionByType(state, 'video', ext)
export const isApplicationExtension = (state: string, ext: string) =>
  isExtensionByType(state, 'application', ext)
export const isTextExtension = (state: string, ext: string) =>
  isExtensionByType(state, 'text', ext)
export const isImageExtension = (state: string, ext: string) =>
  isExtensionByType(state, 'image', ext)
export const selectMediaTypeByExtension = (state: any, ext: string) => {
  if (!state.allowedUploadExtsSet) return ''

  if (!ext) return ''

  ext = ext.toLocaleLowerCase()
  for (let type in state.allowedUploadExtsSet) {
    if (state.allowedUploadExtsSet[type].has(ext)) return type
  }

  return ''
}

// TODO: Typescript fix any
export function appTypeMenuReducer(state = {}, action: any) {
  switch (action.type) {
    case SAVE_APP_HEADER_TYPE_PATHS:
      const { type, path } = action.payload
      return {
        ...state,
        [type]: {
          path,
          state: action.payload.state,
        },
      }

    default:
      return state
  }
}

// TODO: Typescript fix any
export const selectAppHeaderTypePath = (state: any, type: string): string =>
  (state[type] || {}).path
export const selectNotyMsgQueue = (state: any) =>
  state.notification?.messageQueue
export const isNotyOpened = (state: any) => state.notification?.isOpened
export const selectNotyDefaultType = (state: any) =>
  state.notification?.defaultType
