// @ts-nocheck
// TODO: Typescript
import React, { useRef, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import {
  Snackbar,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from '@material-ui/core'
import SnackbarContent from '../CustomMaterial/SnackbarContent'
import { displayCurrentNotification, closeNotification } from '../_actions'
import {
  logout,
  refreshAuthentication,
  sessionWarningClosed,
  sessionWarningOpened,
} from '../Auth/_actions'
import styles from './Layout.module.scss'
import KeepAlive from '../Auth/KeepAlive'
import { useInterval } from '../_utils/hooks'
import {
  isNotyOpened,
  selectNotyDefaultType,
  selectNotyMsgQueue,
} from '../_selectors'
import {
  isSessionWarningOpen as isSessionWarningOpenSel,
  isUserAuthenticated,
  selectAuthExpiration,
  selectAuthRefreshDate,
  selectTokenManagement,
} from '../Auth/_selectors'

const vertical = 'top'
const horizontal = 'center'
const checkSessionDelay = 10000
const millisPerMin = 60000
const secsInMin = 60
const Layout = (props) => {
  const [remaingWarningTime, setRemainingWarningTime] = useState('')
  const warningTimePassed = useRef(0)
  let { tokenManagement } = props
  tokenManagement = tokenManagement || {}
  const sessionWarningTime = tokenManagement.ClientExpirationWarning
  const showSessionWarning = () => {
    if (props.isSessionWarningOpen) return

    props.sessionWarningOpened()
  }

  const refreshSession = () => {
    props.refreshAuthentication()
    props.sessionWarningClosed()
  }

  const handleDialogClose = (_, reason) => {
    // Prevent closing the dialog if the backdrop is clicked
    if (reason === 'backdropClick') return

    if (!props.isSessionWarningOpen) return

    props.sessionWarningClosed()
  }

  const handleDialogNo = () => {
    if (!props.isSessionWarningOpen) return

    props.sessionWarningClosed()
    props.logout()
  }

  useInterval(function () {
    const { isAuthenticated, expiration, logout } = props
    // Check for expiration only if the client side expiration is set
    //console.log('tokenManagement.ClientExpiration: ' + tokenManagement.ClientExpiration);
    if (tokenManagement.ClientExpiration) {
      if (isAuthenticated) {
        var now = new Date()
        if (now >= expiration) {
          logout()
          handleDialogClose()
        } else if (sessionWarningTime) {
          // Warn the user about expiration if it is set
          var minsUntilExpired = (expiration - now) / millisPerMin

          //console.log('minsUntilExpired: ' + minsUntilExpired);
          if (minsUntilExpired <= sessionWarningTime && isAuthenticated)
            showSessionWarning()
        }
      }
      //console.log('sessionWarningTime: ' + sessionWarningTime);
      //console.log('sessionWarningTime * millisPerMin: ' + (sessionWarningTime * millisPerMin));
      const minutesLeft =
        (sessionWarningTime * millisPerMin - warningTimePassed.current) /
        millisPerMin
      //console.log('minutesLeft: ' + minutesLeft);
      //console.log('warningTimePassed.current % millisPerMin: ' + (warningTimePassed.current % millisPerMin));
      // Only update every minute checkSessionDelay should be incrementing warningTimePassed by 10 seconds
      if (warningTimePassed.current % millisPerMin === 0 && minutesLeft > 1) {
        //console.log('setting ' + `${Math.round(minutesLeft)} minutes`);
        //console.log('warningTimePassed.current ' + warningTimePassed.current);
        setRemainingWarningTime(`${Math.round(minutesLeft)} minutes`)
      } else if (minutesLeft <= 1)
        setRemainingWarningTime(
          `${Math.round(minutesLeft * secsInMin)} seconds`
        )

      //console.log('warningTimePassed.current: ' + warningTimePassed.current);
      warningTimePassed.current += checkSessionDelay
    }
  }, checkSessionDelay)

  // Reset the passing time whenever the authentication is refreshed
  useEffect(() => {
    warningTimePassed.current = 0
  }, [props.refreshDate])

  // Need this logic because I am pushing actions across browser tabs
  useEffect(() => {
    if (props.isSessionWarningOpen) {
      warningTimePassed.current = 0
      setRemainingWarningTime('')
    }
  }, [props.isSessionWarningOpen])

  return (
    <div id={props.id}>
      <Dialog
        open={props.isSessionWarningOpen}
        onClose={handleDialogClose}
        disableEscapeKeyDown={true}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{'Continue Session?'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Session will timeout in {remaingWarningTime}. Would you like to
            continue session?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogNo} color="primary">
            No
          </Button>
          <Button onClick={refreshSession} color="primary" autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={props.isOpened}
        onClose={(event, reason) => {
          if (reason === 'clickaway') return
          props.closeNotification()
        }}
        TransitionProps={{
          onExited: props.displayCurrentNotification,
        }}
        autoHideDuration={props.duration > 0 ? props.duration : null}
        className={styles.fullWidthSnackbar}
      >
        <SnackbarContent
          onClose={
            props.hasCloseBtn
              ? (event, reason) => {
                  if (reason === 'clickaway') return
                  props.closeNotification()
                }
              : undefined
          }
          variant={props.variant}
          message={props.message}
        ></SnackbarContent>
      </Snackbar>

      <div id="Ico_Issue_rootBody">{props.children}</div>
    </div>
  )
}

const mapStateToProps = (state) => {
  const expiration = selectAuthExpiration(state)
  const refreshDate = selectAuthRefreshDate(state)
  const isAuthenticated = isUserAuthenticated(state)
  const tokenManagement = selectTokenManagement(state)
  const isSessionWarningOpen = isSessionWarningOpenSel(state)

  var messageQueue = selectNotyMsgQueue(state)
  if (messageQueue.length > 0) {
    return {
      isOpened: isNotyOpened(state),
      duration: messageQueue[0].duration,
      hasCloseBtn: messageQueue[0].hasCloseBtn,
      variant: messageQueue[0].type,
      message: messageQueue[0].message,
      isSessionWarningOpen,
      expiration,
      refreshDate,
      isAuthenticated,
      tokenManagement,
    }
  }

  return {
    variant: selectNotyDefaultType(state),
    message: '',
    duration: 0,
    isOpened: false,
    hasCloseBtn: undefined,
    isSessionWarningOpen,
    expiration,
    refreshDate,
    isAuthenticated,
    tokenManagement,
  }
}

const mapDispatchToProps = {
  closeNotification: closeNotification,
  displayCurrentNotification: displayCurrentNotification,
  logout,
  refreshAuthentication,
  sessionWarningOpened,
  sessionWarningClosed,
}

export default connect(mapStateToProps, mapDispatchToProps)(KeepAlive(Layout))
