import {
  Dialog,
  DialogContent,
  Toolbar,
  withMobileDialog,
} from '@material-ui/core'
import { SlideLeft } from '../Shared/Transition'
import { HeaderNavigation } from '../Shared/HeaderNavigation'
import { useAppSelector } from '../_utils/reactReduxHooks'
import { IssueDynamicForm } from './IssueDynamicForm'
import { isMobileApp } from '../_selectors'

interface Props {
  IssueID: number
  open: boolean
  onClose: (
    event: {},
    reason: 'backdropClick' | 'escapeKeyDown' | 'userSaved'
  ) => void
}

const ResponsiveDialog = withMobileDialog()(Dialog)

export const IssueResolveDialog = ({ IssueID, open, onClose }: Props) => {
  const isMobile = useAppSelector(isMobileApp)

  function render() {
    return (
      <div style={{ width: isMobile ? '100%' : '700px' }}>
        <IssueDynamicForm
          dfName={'Resolve Issue'}
          IssueID={IssueID}
          startOpen={true}
          onClose={onClose}
          showDateInHeader={false}
        />
      </div>
    )
  }

  return (
    <ResponsiveDialog
      open={open}
      onClose={onClose}
      TransitionComponent={SlideLeft as any}
      maxWidth={false}
    >
      <Toolbar>
        <HeaderNavigation
          title=""
          onNavClick={onClose as any}
          canGoBack={false}
        />
      </Toolbar>
      <DialogContent>{render()}</DialogContent>
    </ResponsiveDialog>
  )
}
