// @ts-nocheck
// TODO: Typescript
import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { Container } from 'reactstrap'
import { TaskDetailActions } from '../Task/_actions'
import TaskListEditor from '../Task/TaskListEditor'
import TaskListEditorToolbar from '../Task/TaskListEditorToolbar'
import PropTypes from 'prop-types'
import TaskItemDetail from '../Task/TaskItemDetail'
import {
  StretchLayout,
  StretchHeader,
  StretchBody,
  StretchFooter,
} from '../Layouts/StretchLayout'
import classes from './WorkspaceViewer.module.scss'
import { RouteParameters } from '../_utils/reduxUtils'
import { MobileLayout, DesktopLayout } from '../Layouts/ResponsiveHidden'
import TaskItemToolbar from '../Task/TaskItemToolbar'

import {
  selectWorkspaceById,
  selectWorkspaceParticipantIds,
  selectWorkspaceTaskIds,
} from './_selectors'
import { selectTaskItemById } from '../Task/_selectors'
import { isFetchTaskItemsRequired } from '../Shared/_requestStatusSelectors'
import { TaskListToolbar } from '../Task/TaskListToolbar'
import {
  TaskListFilterStatus,
  TaskListFilterType,
} from '../Task/TaskListFilter'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/pro-solid-svg-icons'
import { Spacer } from '../Shared/Spacer'
import { selectParticipantById } from '../_rootConfigs/rootSelectors'

// Should probably be moved to issue
/**
 * @extends {Component<Props, {}>}}
 */
class WorkspaceTaskViewer extends Component {
  constructor(props) {
    super(props)
    this.state = { value: '' }
    this.state = {
      filter: {
        status: TaskListFilterStatus.All,
        assignedTo: '',
        orgRoleId: undefined,
      },
      openTaskDetail: false,
    }

    this.scrollRef = React.createRef()
    this.handleTaskItemClick = this.handleTaskItemClick.bind(this)
    this.isFirstUpdate = true
  }

  componentDidMount() {
    const { isFetchTaskItemsRequired } = this.props
    const TaskID = this.getTaskId()

    if (TaskID !== undefined && isFetchTaskItemsRequired(TaskID)) {
      this.props.fetchTaskDetail({ TaskID })
    }

    this.goToTaskDetail()
  }

  componentDidUpdate() {
    const { isFetchTaskItemsRequired } = this.props

    const TaskID = this.getTaskId()

    if (TaskID !== undefined && isFetchTaskItemsRequired(TaskID)) {
      this.props.fetchTaskDetail({ TaskID })
    }
    //this.isFirstUpdate = false;
  }

  goToTaskDetail(domainItemId) {
    const { pathParams } = this.props
    domainItemId = domainItemId || pathParams.domainItemId
    if (domainItemId) {
      if (!this.state.openTaskDetail) {
        this.setState({
          openTaskDetail: true,
        })
      }
    }
  }

  handleFilterChange = (filter: TaskListFilterType) => {
    this.setState({
      ...this.state,
      filter,
    })
  }

  renderTaskDetail = () => {
    const TaskID = this.getTaskId()
    const TaskItemID = this.getTaskItemId()
    const {
      disabled,
      participantIds,
      onPathChanged,
      domainId,
      IssueTeamWorkspaceID,
      OrgID,
    } = this.props
    return (
      <StretchLayout>
        <StretchHeader>
          <h6
            style={{
              paddingLeft: 15,
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <div style={{ display: 'flex' }}>
              <FontAwesomeIcon
                icon={faArrowLeft}
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  this.setState({
                    openTaskDetail: false,
                  })
                  onPathChanged({
                    domainId,
                    domainItem: undefined,
                    domainItemId: undefined,
                  })
                }}
              />
              <Spacer />
              Task Detail
            </div>
            <TaskItemToolbar
              style={{ float: 'right' }}
              TaskID={TaskID}
              TaskItemID={TaskItemID}
              readOnly={disabled}
              OrgID={OrgID}
            />
          </h6>
        </StretchHeader>
        <StretchBody>
          <TaskItemDetail
            TaskID={TaskID}
            OrgID={OrgID}
            TaskItemID={TaskItemID}
            participantIds={participantIds}
            disabled={disabled}
            IssueTeamWorkspaceID={IssueTeamWorkspaceID}
          />
        </StretchBody>
      </StretchLayout>
    )
  }

  renderTaskList = () => {
    const TaskID = this.getTaskId()
    const TaskItemID = this.getTaskItemId()
    const { IssueTeamWorkspaceID, disabled, participants, OrgID } = this.props
    return (
      <StretchLayout>
        <StretchHeader>
          <h6 style={{ paddingLeft: 15 }}>
            Task Items
            <TaskListToolbar
              IssueTeamWorkspaceID={IssueTeamWorkspaceID}
              TaskID={TaskID}
              onFilterChange={this.handleFilterChange}
              filterValue={this.state.filter}
              participants={participants}
              OrgID={OrgID}
            />
          </h6>
        </StretchHeader>
        <StretchBody>
          <TaskListEditor
            TaskID={TaskID}
            onTaskItemClick={this.handleTaskItemClick}
            selected={TaskItemID}
            filter={this.state.filter}
            readOnly={disabled}
            OrgID={OrgID}
          />
          <div ref={this.scrollRef} />
        </StretchBody>
        <StretchFooter>
          <TaskListEditorToolbar
            TaskID={TaskID}
            disabled={disabled}
            scrollRef={this.scrollRef}
          />
        </StretchFooter>
      </StretchLayout>
    )
  }

  render() {
    const props = this.props
    const { taskIds, isTaskItemIDValid } = props
    const TaskItemID = this.getTaskItemId()
    const detailOpen =
      this.state.openTaskDetail && isTaskItemIDValid(TaskItemID)
    if (taskIds.length === 0) return <span>No tasks</span>
    return (
      <Fragment>
        <DesktopLayout>
          {detailOpen ? this.renderTaskDetail() : this.renderTaskList()}
        </DesktopLayout>
        <MobileLayout>{this.renderMobileView()}</MobileLayout>
      </Fragment>
    )
  }

  renderMobileView() {
    const props = this.props
    const {
      participantIds,
      participants,
      isTaskItemIDValid,
      disabled,
      className,
      IssueTeamWorkspaceID,
      OrgID,
    } = props
    // Should default to the first task
    const TaskID = this.getTaskId()
    const TaskItemID = this.getTaskItemId()
    //const TaskFilter = [
    //    { value: 0, key: 'All' },
    //    { value: 1, key: 'My Tasks' },
    //    { value: 2, key: 'Completed' },
    //    { value: 3, key: 'Incompleted' }
    //];

    // If a task item from a secure list is selected, render the item's details
    if (TaskItemID && isTaskItemIDValid(TaskItemID)) {
      return (
        <TaskItemDetail
          className={className}
          mobile={true}
          TaskID={TaskID}
          TaskItemID={TaskItemID}
          participantIds={participantIds}
          disabled={disabled}
          OrgID={OrgID}
        />
      )
    }
    // Otherwise render the secure task list selection list and the current selected list
    return (
      <StretchLayout className={className}>
        <StretchHeader className={classes.selectionListCompact}>
          <Container fluid={true}>
            <TaskListToolbar
              filterClassName={classes.selectionListCompact}
              IssueTeamWorkspaceID={IssueTeamWorkspaceID}
              TaskID={TaskID}
              OrgID={OrgID}
              onFilterChange={this.handleFilterChange}
              filterValue={this.state.filter}
              participants={participants}
            />
          </Container>
        </StretchHeader>
        <StretchBody>
          <TaskListEditor
            TaskID={TaskID}
            OrgID={OrgID}
            onTaskItemClick={this.handleTaskItemClick}
            selected={TaskItemID}
            filter={this.state.filter}
            readOnly={disabled}
          />
        </StretchBody>
        <StretchFooter>
          <TaskListEditorToolbar TaskID={TaskID} disabled={disabled} />
        </StretchFooter>
      </StretchLayout>
    )
  }

  handleTaskItemClick(domainItemId) {
    const { onPathChanged } = this.props

    if (this.getTaskItemId() !== domainItemId) {
      onPathChanged({
        domainId: this.getTaskId(),
        domainItem: 'taskItem',
        domainItemId,
      })
    }

    this.goToTaskDetail(domainItemId)
  }

  getTaskId() {
    const { pathParams, taskIds } = this.props
    return pathParams.domainId !== undefined
      ? parseInt(pathParams.domainId)
      : taskIds[0]
  }

  getTaskItemId() {
    const { pathParams } = this.props
    return pathParams.domainItemId !== undefined
      ? parseInt(pathParams.domainItemId)
      : undefined
  }
}

WorkspaceTaskViewer.propTypes = {
  taskIds: PropTypes.arrayOf(PropTypes.number),
  OrgID: PropTypes.number,
  IssueID: PropTypes.number,
  onPathChanged: PropTypes.func,
  pathParams: PropTypes.shape(RouteParameters),
  fetchTaskDetail: PropTypes.func,
  isTaskItemIDValid: PropTypes.func,
  disabled: PropTypes.bool,
}

WorkspaceTaskViewer.defaultProps = {
  taskIds: [],
}

const mapStateToProps = (state, ownProps) => {
  const { IssueTeamWorkspaceID, OrgID } = selectWorkspaceById(
    state,
    ownProps.IssueTeamWorkspaceID
  )

  const participantIds = selectWorkspaceParticipantIds(
    state,
    IssueTeamWorkspaceID
  )

  return {
    taskIds: selectWorkspaceTaskIds(state, ownProps.IssueTeamWorkspaceID),
    OrgID,
    IssueID: IssueTeamWorkspaceID,
    participantIds: participantIds,
    participants: participantIds
      ? participantIds.map((id) => selectParticipantById(state, id) || {})
      : undefined,
    isTaskItemIDValid: (taskItemID) =>
      selectTaskItemById(state, taskItemID) ? true : false,
    isFetchTaskItemsRequired: (taskID) =>
      isFetchTaskItemsRequired(state, taskID),
    IssueTeamWorkspaceID,
  }
}

const mapDispatchToProps = {
  // Fetch whenever a task is selected
  fetchTaskDetail: (entity) =>
    TaskDetailActions.fetch(entity, { icoRequestId: entity.TaskID }),
}

export default connect(mapStateToProps, mapDispatchToProps)(WorkspaceTaskViewer)
