import React, { useCallback, useEffect, useState } from 'react'
import ActivityLog, { FetchLogProps } from '../Shared/ActivityLog'
import { connect } from 'react-redux'
import {
  changeIssueActivityLogFilter,
  fetchIssueActivityLog,
  markReadIssueActivityLog,
} from './_actions'
import {
  hasNewIssueActivityLog,
  isIssueActivityLogLoaded,
  selectIssueActivityLog,
  selectIssueActivityLogCategory,
} from './_selectors'
import { selectIssuesLogCategories } from '../_selectors'
import { useRef } from 'react'

interface Props {
  IssueID: number
  items: []
  fetchIssueActivityLog(params: any): void
  pageSize: number
  isDoneLoading: boolean
  api: any
  markReadIssueActivityLog: (issueID: number) => void
  hasNewActivityLog: boolean
  filterOptions: any[]
  changeFilter: (category: string[]) => void
  selectedFilter: { category: string[] }
}

export function IssueActivityLog({
  IssueID,
  items,
  fetchIssueActivityLog,
  pageSize,
  isDoneLoading,
  api,
  markReadIssueActivityLog,
  hasNewActivityLog,
  filterOptions,
  changeFilter,
  selectedFilter,
}: Props) {
  const [lastLoaded, setLastLoaded] = useState<number | undefined>()
  const isFirstLoad = useRef(true)

  const fetchData = useCallback(
    ({ lastLoadedRow, offset, pageSize }: FetchLogProps) => {
      setLastLoaded(lastLoadedRow)
      fetchIssueActivityLog({
        IssueID,
        offset,
        pageSize,
        category: selectedFilter?.category,
      })
    },
    [IssueID, fetchIssueActivityLog, selectedFilter?.category]
  )

  const loadInitialData = useCallback(() => {
    // Load the first set of rows (Always reload small sets)
    if (!isDoneLoading) {
      fetchData({ offset: 0, pageSize })
      setLastLoaded(pageSize - 1)
    } else setLastLoaded(items.length - 1)
  }, [fetchData, isDoneLoading, items.length, pageSize])

  useEffect(() => {
    if (!isFirstLoad.current) loadInitialData()
  }, [selectedFilter, loadInitialData])

  useEffect(() => {
    isFirstLoad.current = false
    loadInitialData()
  }, [IssueID, loadInitialData])

  useEffect(() => {
    if (hasNewActivityLog) markReadIssueActivityLog(IssueID)
  }, [IssueID, markReadIssueActivityLog, hasNewActivityLog])

  return (
    <ActivityLog
      fetchData={fetchData}
      lastLoadedRow={lastLoaded}
      onLastLoadedRowChange={setLastLoaded}
      pageSize={pageSize}
      items={items}
      loadingBuffer={20}
      loadingDone={isDoneLoading}
      api={api}
      filterOptions={filterOptions}
      filterItemValue={(item) => item.CategoryName}
      filterItemLabel={(item) => item.CategoryName}
      onFilterChange={changeFilter}
      filterValue={selectedFilter?.category || []}
      filterShowEmpty={true}
      IssueID={IssueID}
    />
  )
}

const mapStateToProps = (state: any, { IssueID }: { IssueID: number }) => ({
  items: selectIssueActivityLog(state, IssueID),
  isDoneLoading: isIssueActivityLogLoaded(
    state,
    IssueID,
    selectIssueActivityLogCategory(state)
  ),
  hasNewActivityLog: hasNewIssueActivityLog(state, IssueID),
  filterOptions: selectIssuesLogCategories(state),
  selectedFilter: selectIssueActivityLogCategory(state),
})

const mapDispatchToProps = {
  fetchIssueActivityLog: (params: any) =>
    fetchIssueActivityLog.request(params, {
      icoRequestId: params.IssueID,
    }),
  markReadIssueActivityLog: (issueID: number) =>
    markReadIssueActivityLog.request({ issueID }, {}),
  changeFilter: (category: string) =>
    changeIssueActivityLogFilter.request({ category }),
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(IssueActivityLog as any)
