// @ts-nocheck
// TODO: Typescript
import PropTypes from 'prop-types'
import React, { Component, Fragment } from 'react'
import { ListItem, List, Slide } from '@material-ui/core'
import { cx } from '../_utils/objectUtils'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faUsers } from '@fortawesome/free-solid-svg-icons'
import classes from './ChatListMenu.module.scss'
import { ChatDirectMessageItem } from './ChatDirectMessageItem'

/**
 * @extends {Component<Props, {}>}}
 * Contains the list of all available chats
 */
export class ChatListMenu extends Component {
  constructor(props) {
    super(props)

    this.handleChatSelected = this.handleChatSelected.bind(this)
  }

  handleChatSelected(chat, isDirect) {
    const { onChatSelected, onClose } = this.props
    onChatSelected(chat, isDirect)
    onClose()
  }

  renderTeamChats() {
    let { teamChats, selectedChatId, IssueName } = this.props

    if (!(teamChats instanceof Array)) teamChats = [teamChats]

    if (teamChats.length === 1) {
      return (
        <>
          <ListItem className={classes.itemHeader} component="div">
            <FontAwesomeIcon className="mr-1" icon={faUsers} /> Team Chats
          </ListItem>
          <ListItem
            className="Ico-MuiListItem-root"
            component="div"
            key={teamChats[0].ChatID}
            onClick={() => this.handleChatSelected(teamChats[0], false)}
            selected={selectedChatId === teamChats[0].ChatID}
          >
            <FontAwesomeIcon
              className={cx(classes.teamChatIcon, 'mr-2 Ico-MuiListItem-root')}
              icon={faUsers}
            />{' '}
            {IssueName || teamChats[0].ChatName}
          </ListItem>
        </>
      )
    } else if (teamChats.length === 0) return null
    return (
      <Fragment>
        <ListItem className={classes.itemHeader} component="div">
          <FontAwesomeIcon className="mr-1" icon={faUsers} /> Team Chats
        </ListItem>
        <hr className="mt-0" />
        {teamChats.map((chat) => {
          if (!chat.ChatGUID) return false
          return (
            <ListItem
              className="Ico-MuiListItem-root"
              component="div"
              key={chat.ChatID}
              selected={selectedChatId === chat.ChatID}
              onClick={() => this.handleChatSelected(chat, false)}
            >
              {chat.ChatName}
            </ListItem>
          )
        })}
      </Fragment>
    )
  }

  renderDirectMessages() {
    const {
      directMessages,
      onCreateClicked,
      selectedChatId,
      searchListIcon,
      onDeleteChatClick,
      onViewChatClick,
      issueWorkspaceID,
    } = this.props

    return (
      <Fragment>
        <ListItem component="div" className={classes.itemHeader}>
          <span className={classes.itemHeaderTitle}>Private Chats</span>
          <FontAwesomeIcon
            className={classes.addChatIcon}
            icon={searchListIcon}
            onClick={onCreateClicked}
          />
        </ListItem>
        <hr className="mt-0" />
        {directMessages.map((chat) => (
          <ChatDirectMessageItem
            className="Ico-MuiListItem-root"
            ChatNameLineLimit={5}
            onDeleteClick={
              onDeleteChatClick
                ? () => {
                    onDeleteChatClick(chat)
                  }
                : undefined
            }
            onViewClick={
              onViewChatClick
                ? () => {
                    onViewChatClick(chat)
                  }
                : undefined
            }
            key={chat.ChatID || chat.ChatName}
            ChatID={chat.ChatID}
            ChatName={chat.ChatName}
            onClick={(clickedChat) =>
              this.handleChatSelected(clickedChat, true)
            }
            selected={selectedChatId === chat.ChatID}
            participantCount={chat.count}
            maxParticipantCount={9}
            classes={classes}
            issueWorkspaceID={issueWorkspaceID}
          />
        ))}
      </Fragment>
    )
  }

  render() {
    const { className, onClose, open } = this.props
    return (
      <Slide
        className={cx(classes.root, className)}
        onExit={onClose}
        direction="down"
        in={open}
      >
        <div className="p-1">
          <List className={classes.chatList} component="div">
            {this.renderTeamChats()}
            {this.renderDirectMessages()}
          </List>
        </div>
      </Slide>
    )
  }
}

/*
 * PropType validation
 */
ChatListMenu.propTypes = {
  teamChats: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  directMessages: PropTypes.array,
  className: PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  anchorEl: PropTypes.any,
  onCreateClicked: PropTypes.func,
  onChatSelected: PropTypes.func,
  selectedChatId: PropTypes.any,
  searchListIcon: PropTypes.any,
  onDeleteChatClick: PropTypes.func,
  onViewChatClick: PropTypes.func,
  IssueName: PropTypes.string,
  issueWorkspaceID: PropTypes.number,
}

ChatListMenu.defaultProps = {
  teamChats: [],
  directMessages: [],
  open: false,
  onClose: () => {},
  onChatSelected: () => {},
  onCreateClicked: () => {},
  searchListIcon: faPlus,
}
