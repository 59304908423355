import fetch from '../fetch'
import { useEffect, useRef, useState } from 'react'
import { prefixSelectorWithApp } from './domUtils'
import { logger } from './logging'

export const csrfName = 'X-CSRF-TOKEN'

export function getCookie(cname: string) {
  var name = cname + '='
  var decodedCookie = decodeURIComponent(document.cookie)
  var ca = decodedCookie.split(';')
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i]
    while (c.charAt(0) === ' ') {
      c = c.substring(1)
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length)
    }
  }
  return ''
}

export function getRequestToken() {
  return (
    document.getElementById(
      prefixSelectorWithApp('csrfToken')
    ) as HTMLInputElement
  )?.value
  //return getCookie(csrfName);
}

export function setRequestToken(token: string) {
  const tokenHolder = document.getElementById(
    prefixSelectorWithApp('csrfToken')
  ) as HTMLInputElement
  if (tokenHolder) tokenHolder.value = token
}

function getDefaultPostInit(request?: RequestInit, data?: any): RequestInit {
  let postInit = getDefaultRequestInit(request, data, true)
  postInit.method = 'POST'

  return postInit
}

function getDefaultPutInit(request?: RequestInit, data?: any): RequestInit {
  let putInit = getDefaultRequestInit(request, data, true)
  putInit.method = 'PUT'

  return putInit
}

function getDefaultDeleteInit(request?: RequestInit, data?: any) {
  let deleteInit = getDefaultRequestInit(request, data, true)
  deleteInit.method = 'DELETE'

  return deleteInit
}

function getDefaultRequestInit(
  request?: RequestInit,
  data?: any,
  sendRequestToken?: boolean
): RequestInit {
  request = request || {}
  let baseHeaders: { [key: string]: string } = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  }

  if (sendRequestToken) {
    let token = getRequestToken()
    if (token) baseHeaders[csrfName] = token
  }

  return {
    mode: 'cors', // no-cors, cors, *same-origin
    credentials: 'same-origin', // include, *same-origin, omit
    redirect: 'follow', // manual, *follow, error
    referrer: 'no-referrer', // no-referrer, *client
    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
    method: 'GET',
    ...request,
    headers: {
      ...baseHeaders,
      ...request.headers,
    },
    body: request.body || (data ? JSON.stringify(data) : undefined),
  }
}

// Does a post/put and includes auth headers and other custom request options
export function icoPost(
  url: RequestInfo,
  data: any,
  requestOptions?: RequestInit,
  dontRenewAuth: boolean = false
) {
  return icoRequestHelper(
    getDefaultPostInit(requestOptions, data),
    url,
    dontRenewAuth
  )
}

export function icoPut(
  url: RequestInfo,
  data: any,
  requestOptions?: RequestInit,
  dontRenewAuth: boolean = false
) {
  return icoRequestHelper(
    getDefaultPutInit(requestOptions, data),
    url,
    dontRenewAuth
  )
}

export function icoDelete(
  url: RequestInfo,
  data?: any,
  requestOptions?: RequestInit,
  dontRenewAuth: boolean = false
) {
  return icoRequestHelper(
    getDefaultDeleteInit(requestOptions, data),
    url,
    dontRenewAuth
  )
}

function icoRequestHelper(
  requestOptions: RequestInit,
  url: RequestInfo,
  data: any,
  dontRenewAuth: boolean = false
): Promise<Response> {
  var request = requestOptions
  var customHeaders: { dontRenewAuth?: boolean; csrfRetry: boolean } = {
    csrfRetry: true,
  }

  if (dontRenewAuth) {
    customHeaders.dontRenewAuth = true
  }

  if ((request.headers as any)?.csrfRetry !== undefined)
    customHeaders.csrfRetry = (request.headers as any)?.csrfRetry

  /*if (data) {
        request = Object.assign({}, request, {
            //body: JSON.stringify(data), // Handled in getDefaultRequestInit
            headers: Object.assign({}, request.headers, customHeaders)
        });
    }*/

  request = Object.assign({}, request, {
    //body: JSON.stringify(data), // Handled in getDefaultRequestInit
    headers: Object.assign({}, request.headers, customHeaders),
  })
  return fetch(url, request).then((response) => {
    if (!response.ok) {
      let errorMessage = response.statusText

      if (!errorMessage) errorMessage = 'HTTP status code: ' + response.status

      if (response.status === 400) {
        const requestHeaders = request?.headers || ({} as any)

        // Try to get a new token if a failure occurs
        if (requestHeaders.csrfRetry) {
          logger.warning(
            'Refetching csrf token due to invalid token for ' + url
          )
          return icoFetch('/api/CookieAuth/RequestToken')
            .then((response) => {
              if (response.ok) return response.json()
              else throw tokenFetchError(response, url)
            })
            .then((jsonToken) => {
              setRequestToken(jsonToken.returnData)

              // Retry the request with the new token
              ;((request.headers as any) || {}).csrfRetry = false
              ;((request.headers as any) || {})[csrfName] = getRequestToken()
              return icoRequestHelper(request, url, undefined)
            })
            .catch(() => {
              throw tokenFetchError(response, url)
            })
        } else {
          if (!requestHeaders[csrfName])
            errorMessage += ' Antiforgery token might be missing'
          else
            errorMessage += ' bad request or possible invalid antiforgery token'
        }
      }

      let err = new Error(errorMessage) as any
      err.response = response
      err.status = response.status
      throw err
    }
    return response
  })
}

function tokenFetchError(response: Response, url: RequestInfo) {
  let err = new Error(
    'Request ' +
      url +
      ' failure ' +
      ' unable to get a new valid antiforgery token'
  ) as any
  err.response = response
  err.status = response.status

  return err
}

// Does a fetch and includes auth headers and other custom request options
export function icoFetch(
  url: RequestInfo,
  request?: RequestInit,
  dontRenewAuth?: boolean
) {
  let defaultRequest = getDefaultRequestInit()

  if (dontRenewAuth) {
    // @ts-ignore
    defaultRequest.headers['dontRenewAuth'] = true
  }

  request = Object.assign({}, defaultRequest, request)
  request.headers = Object.assign({}, defaultRequest.headers, request.headers)

  return fetch(url, request)
}

export interface UseIcoFetchOptions<T = any> {
  dontRenewAuth?: boolean
  defaultErrorMessage?: string
  tracking?: any[]
  required?: any[]
  defaultData?: any
  request?: RequestInit
  onData?: (data: T) => void
  formatData?: (data: T) => T
  shouldFetch?: (tracking: any) => boolean
}

export interface UseIcoFetchResult<T> {
  loading?: boolean
  data?: T
  error?: string | null
}

export function useIcoFetch<T = any>(
  url: RequestInfo,
  options?: UseIcoFetchOptions
) {
  return useIcoFetchHelper<T>(
    () => icoFetch(url, options?.request, options?.dontRenewAuth),
    options
  )
}

export function useIcoPut<T = any>(
  url: RequestInfo,
  data: any,
  options?: UseIcoFetchOptions
) {
  return useIcoFetchHelper<T>(
    () => icoPut(url, data, options?.request, options?.dontRenewAuth),
    options
  )
}

function useIcoFetchHelper<T>(
  fetchCallback: () => Promise<any>,
  options?: UseIcoFetchOptions
) {
  const [data, setData] = useState<UseIcoFetchResult<T>>({
    loading: true,
    data: options?.defaultData,
  })

  const isMounted = useRef(true)
  const latestRequestId = useRef<string>()

  function handleSetData(data: UseIcoFetchResult<T>) {
    if (!isMounted.current) return
    if (options?.formatData)
      setData({
        data: options.formatData(data.data),
      })
    else setData(data)

    options?.onData?.call(undefined, data)
  }

  function updateData(data: T) {
    setData({
      data,
    })
  }

  const required = options?.required || []
  const tracking = [...(options?.tracking || []), ...required]
  const shouldFetch = options?.shouldFetch ? options?.shouldFetch : () => true

  useEffect(() => {
    isMounted.current = true
    // Don't run fetch unless all required parameters are present
    let isAllPresent = true
    for (let i = 0; i < required.length && isAllPresent; i++)
      isAllPresent = required[i] ? true : false

    const fetchData = async () => {
      latestRequestId.current = new Date().toString()
      const currentRequestId = latestRequestId.current

      handleSetData({
        loading: true,
        error: null,
        data: options?.defaultData,
      })

      const data = await fetchCallback()
        .then((res: Response) => {
          const contentType = res.headers.get('content-type') || ''

          if (currentRequestId !== latestRequestId.current) return res

          if (res.ok) {
            if (contentType.indexOf('application/json') !== -1)
              return res.json()
            else return res.text()
          }
        })
        .catch((error) => {
          if (currentRequestId !== latestRequestId.current) return

          handleSetData({
            error: options?.defaultErrorMessage || error,
            loading: false,
            data: options?.defaultData,
          })
        })

      if (isMounted.current && currentRequestId === latestRequestId.current) {
        handleSetData({
          data: data || options?.defaultData,
          loading: false,
        })
      }
    }

    if (isMounted.current && isAllPresent && shouldFetch([...tracking]))
      fetchData()

    return function cleanup() {
      isMounted.current = false
    }
  }, tracking)

  return {
    data: data?.data,
    error: data?.error,
    loading: data?.loading,
    setData: updateData,
  }
}

const exportIcoActions = {
  icoPost,
  icoFetch,
}
export default exportIcoActions
