// @ts-nocheck
// TODO: Typescript
import { UploadConstants, ArtifactConstants } from './_constants'
import { generateCrudActions } from '../_utils/reduxUtils'

export function upload(entity, source) {
  return {
    type: UploadConstants.REQUEST,
    payload: {
      result: entity,
      source,
    },
  }
}

export function uploadLoading(entity, source) {
  return {
    type: UploadConstants.LOADING,
    payload: {
      result: entity,
      source,
    },
  }
}

export function uploadSuccess(entity, source) {
  return {
    type: UploadConstants.SUCCESS,
    payload: {
      result: entity,
      source,
    },
  }
}

export function uploadFailure(ErrorMessage, entity, source) {
  return {
    type: UploadConstants.FAILURE,
    payload: {
      ErrorMessage,
      result: entity,
      source,
    },
  }
}

export const ArtifactActions = generateCrudActions(ArtifactConstants.PREFIX)
