import { useEffect } from 'react'
import { fetchIssueDetails } from '../Issue/_actions'
import { selectWorkspaceIssueId } from '../Issue/_selectors'
import {
  isFetchIssueDetailsRequired,
  isFetchIssuesLoading,
  isInitIssuesComplete,
} from '../Shared/_requestStatusSelectors'
import { useAppDispatch, useAppSelector } from '../_utils/reactReduxHooks'

export function useFetchIssueDetails(IssueTeamWorkspaceID: number) {
  const isFetchDetailsRequired = useAppSelector((state) =>
    isFetchIssueDetailsRequired(state, IssueTeamWorkspaceID)
  )
  const isIssuesComplete = useAppSelector(isInitIssuesComplete)
  const isFetchingIssues = useAppSelector(isFetchIssuesLoading)
  const hasIssue = useAppSelector((state) =>
    selectWorkspaceIssueId(state, IssueTeamWorkspaceID)
  )
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (
      hasIssue &&
      IssueTeamWorkspaceID &&
      isFetchDetailsRequired &&
      isIssuesComplete &&
      !isFetchingIssues
    )
      dispatch(
        fetchIssueDetails.request(
          { IssueTeamWorkspaceID },
          {
            icoRequestId: IssueTeamWorkspaceID,
            IssueTeamWorkspaceID,
          }
        )
      )
  }, [
    IssueTeamWorkspaceID,
    isIssuesComplete,
    isFetchDetailsRequired,
    isFetchingIssues,
    hasIssue,
    dispatch,
  ])
}
