// @ts-nocheck
// TODO: Typescript
import React, { Component } from 'react'
import { logout } from '../Auth/_actions'
import { connect } from 'react-redux'
import { disableMSTeamsAutoLogin } from '../MicrosoftTeams/_actions'

/*
 * @extends {Component<Props, {}>}}
 */
class InvalidAzureTokenPage extends Component {
  componentDidMount() {
    this.props.logout()
    this.props.disableMSTeamsAutoLogin()
  }

  render() {
    return <div>Authenitcation expired redirecting to login.</div>
  }
}

const mapDispatchToProps = {
  logout,
  disableMSTeamsAutoLogin: disableMSTeamsAutoLogin,
}

export default connect(undefined, mapDispatchToProps)(InvalidAzureTokenPage)
