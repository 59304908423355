import React from 'react'
import { SearchInput } from '../Shared/SearchInput'
import { prefixSelectorWithApp } from '../_utils/domUtils'
import { useAppDispatch, useAppSelector } from '../_utils/reactReduxHooks'
import { alertSearch } from './_actions'
import { selectAlertSearch } from './_selectors'
interface props {
  className: string
}
export function AlertSearch({ className }: props) {
  const storedValue = useAppSelector(selectAlertSearch)
  const dispatch = useAppDispatch()

  function applySearch(value: any) {
    dispatch(alertSearch(value))
  }

  return (
    <SearchInput
      className={className}
      id={prefixSelectorWithApp('global-alertSearch')}
      name="alertFilter"
      placeholder="Search Alerts by Title or Description"
      value={storedValue}
      applySearch={applySearch}
      enableOnEnter
      includeSearchIcon={true}
    />
  )
}
