import * as React from 'react'
import { http } from '../_utils/http'
import { cx } from '../_utils/objectUtils'
import { Paper } from '@material-ui/core'
import { CircularProgress } from '../Shared/LoadingBackdrop'
import { PbAddItem, PbAddItemProps } from './PbAddItem'
import { AddPubPasswordPopup } from './AddPubPasswordPopup'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classes from './AddPlaybook.module.scss'
import { faSearch, faTimesCircle } from '@fortawesome/pro-solid-svg-icons'
import { Dispatch } from 'react'
//import { dirxml } from "console";
import { useAppSelector } from '../_utils/reactReduxHooks'
import { selectPublicPlaybookSearchValue } from './_selectors'
import { PbTreeOrg, PbTreeOrgProps, PbTreePubProps } from './PbTreeOrg'

interface Props {
  className?: string
  hasPaper?: boolean
  setPreviewPubGUID: Dispatch<any>
  setPreviewPubTitle: Dispatch<any>
}

export const PbAddPubPlan = ({
  className,
  hasPaper = true,
  setPreviewPubGUID,
  setPreviewPubTitle,
}: Props) => {
  const [addPubList, setAddPubList] = React.useState<any>('')
  const [addPubTree, setAddPubTree] = React.useState<any>('')
  const [gettingPubList, setGettingPubList] = React.useState<boolean>(false)
  const [pvPubGUID, setPvPubGUID] = React.useState<string>('')
  const [isOpenPasswordPoupup, setIsOpenPasswordPopup] =
    React.useState<any>(false)
  const [needPasswordApb, setNeedPasswordApb] = React.useState<PbAddItemProps>()
  const [keyword, setKeyword] = React.useState<any>('')
  const ppSearchValue = useAppSelector(
    selectPublicPlaybookSearchValue
  )?.toLowerCase()

  var addedPubGUIDs: string[] = []

  const togglePasswordPopup = () => {
    setIsOpenPasswordPopup(!isOpenPasswordPoupup)
    return false
  }

  // console.log("value of ppSearchValue is now " + ppSearchValue);

  const getAddPubList = async (): Promise<any> => {
    var customHeaders: any = []

    //customHeaders.push({ name: "keyword", value: `${keyword}` });
    if (ppSearchValue != null) {
      if (ppSearchValue.length > 0) {
        customHeaders.push({
          name: 'keyword',
          value: `${ppSearchValue}`,
        })
      }
    }
    const result = await http<any>({
      path: '/playbook/AddPlaybookList',
      headers: customHeaders,
    })
    if (result.ok && result.body) {
      return result.body
    } else {
      return ''
    }
  }

  const getAddPubTree = async (): Promise<any> => {
    /*
    var customHeaders: any = [];

    //customHeaders.push({ name: "keyword", value: `${keyword}` });
    if (ppSearchValue != null) {
      if (ppSearchValue.length > 0) {
        customHeaders.push({ name: "keyword", value: `${ppSearchValue}` });
      }
    }
    */
    const result = await http<any>({
      path: '/playbook/AddPlaybookTree',
      // headers: customHeaders,
    })
    if (result.ok && result.body) {
      return result.body
    } else {
      return ''
    }
  }

  async function doDeleteAddedPub(PubGUID: string): Promise<any> {
    if (PubGUID) {
      var customHeaders: any = []
      customHeaders.push({ name: 'pubguid', value: `${PubGUID}` })
      const result = await http<any>({
        path: '/playbook/DeletePlaybook',
        headers: customHeaders,
      })
      if (result.ok && result.body! === 'success') doGetAddPubList() // update the listing
      return result
    }
  }
  async function doAddPub(PubGUID: string, Password: string): Promise<any> {
    if (PubGUID) {
      var customHeaders: any = []
      customHeaders.push({ name: 'pubguid', value: `${PubGUID}` })
      customHeaders.push({ name: 'password', value: `${Password}` })
      const result = await http<any>({
        path: '/playbook/PbAddPlaybook',
        headers: customHeaders,
      })
      if (result.ok && result.body! === 'success') doGetAddPubList() // update the listing
      return result
    }
  }

  function renderPasswordPopup() {
    return (
      <div>
        {isOpenPasswordPoupup && needPasswordApb && (
          <AddPubPasswordPopup
            OrgName={needPasswordApb.OrgName}
            PubGUID={needPasswordApb.PubGUID}
            PubTitle={needPasswordApb.PubTitle}
            Version={needPasswordApb.Version}
            AuthType={needPasswordApb.AuthType}
            Updated={needPasswordApb.Updated}
            IsPrivate={needPasswordApb.IsPrivate}
            Status={needPasswordApb.Status}
            ShowDeleteBtn={needPasswordApb.ShowDeleteBtn}
            handleClose={togglePasswordPopup}
            handlePasswordSubmit={handlePasswordSubmit}
            handlePlaybookRemove={handlePlaybookRemove}
          />
        )}
      </div>
    )
  }

  function onItemView(apb: PbAddItemProps) {
    // alert("on item view");
    if (apb.Status === 'added') {
      setPvPubGUID(apb.PubGUID)
      setPreviewPubTitle(apb.PubTitle)
      setPreviewPubGUID(apb.PubGUID)
    } else if (apb.Status === 'available') {
      if (apb.AuthType === 'password') {
        setPvPubGUID(apb.PubGUID)
        setPreviewPubTitle(apb.PubTitle)
        setPreviewPubGUID('')
      } else {
        setPvPubGUID(apb.PubGUID)
        setPreviewPubTitle(apb.PubTitle)
        setPreviewPubGUID(apb.PubGUID)
      }
    } else {
      setPvPubGUID(apb.PubGUID)
      setPreviewPubTitle(apb.PubTitle)
      setPreviewPubGUID('')
    }
  }

  function onTvItemView(tpb: PbTreePubProps) {
    if (tpb.AuthType === 'password') {
      setPvPubGUID(tpb.PubGUID)
      setPreviewPubTitle(tpb.PubTitle)
      setPreviewPubGUID('')
    } else {
      setPvPubGUID(tpb.PubGUID)
      setPreviewPubTitle(tpb.PubTitle)
      setPreviewPubGUID(tpb.PubGUID)
    }
  }

  function onTvItemClicked(tpb: PbTreePubProps, OrgName: string) {
    if (tpb.AuthType === 'password') {
      // ask for a password
      //alert("DRH DEBUG: still need to develop this");

      var apb: PbAddItemProps = {
        OrgName: OrgName,
        PubGUID: tpb.PubGUID,
        PubTitle: tpb.PubTitle,
        Version: tpb.Version,
        AuthType: tpb.AuthType,
        Updated: tpb.Updated,
        IsPrivate: '',
        Status: '',
        ShowDeleteBtn: false,
        isSelected: false,
      }

      setNeedPasswordApb(apb)
      togglePasswordPopup()
      // alert("this feature not yet implemented, talk to David Hill");
    } else {
      //console.log("gonna call doAddPub for " + tpb.PubGUID);
      doAddPub(tpb.PubGUID, '')
    }
  }

  function onItemClicked(apb: PbAddItemProps) {
    // alert("on item clicked");
    if (apb.Status === 'added') {
      // the user wants to delete this
      //console.log("pub previously added now needs to be deleted " + apb.PubGUID);
      doDeleteAddedPub(apb.PubGUID)
    }
    if (apb.Status === 'available') {
      // the user wants to add this
      if (apb.AuthType === 'password') {
        // ask for a password
        setNeedPasswordApb(apb)
        togglePasswordPopup()
        // alert("this feature not yet implemented, talk to David Hill");
      } else {
        //console.log("gonna call doAddPub for " + apb.PubGUID);
        doAddPub(apb.PubGUID, '')
      }
    }
    if (apb.Status === 'badpass') {
      // ask for a password
      apb.ShowDeleteBtn = true
      setNeedPasswordApb(apb)
      togglePasswordPopup()
    }
  }

  function handlePasswordSubmit(password: string) {
    togglePasswordPopup()
    doGetAddPubList()
  }

  function handlePlaybookRemove(pubGUID: string) {
    doDeleteAddedPub(pubGUID)
    togglePasswordPopup()
    doGetAddPubList()
  }
  /*
  async function updateClicked(
    PubGUID: string,
    Password: string
  ): Promise<any> {
    //alert("need to delete this PubGUID " + PubGUID);
    if (PubGUID) {
      var customHeaders: any = [];
      customHeaders.push({ name: "PubGUID", value: `${PubGUID}` });
      const result = await http<any>({
        path: "/playbook/UpdatePlaybook",
        headers: customHeaders,
      });
      console.log("on Add Playbook screen in updateClicked returned");
      console.log(result);
      return result;
    }
    // customHeaders
  }
*/

  React.useEffect(() => {
    //console.log("in useEffect, gonnat run doGetAddPubList");
    //console.log(gettingPubList);
    //console.log("drh debug addPubList is ");
    //console.log(addPubList);
    // if (addPubList === "") {
    setGettingPubList(true)
    //console.log("starting doGetAddPubList");
    doGetAddPubList()
    doGetAddPubTree()
    //console.log("ending doGetAddPubList");
    setGettingPubList(false)
    // }
    //console.log("addPubList is");
    //console.log(addPubList);
  }, [ppSearchValue])

  async function doGetAddPubTree() {
    if (!addPubTree) {
      // only get this if it is not already there
      const newPubTree = await getAddPubTree()
      //console.log(newPubTree);
      setAddPubTree(newPubTree)
    }
  }

  async function doGetAddPubList() {
    const newPubList = await getAddPubList()
    setAddPubList(newPubList)
  }

  function renderAvailable() {
    if (addPubList.Available) {
      if (addPubList.Available.length > 0) {
        var avCount = addPubList.Available.length
        var avLabel =
          'Your search returned ' + avCount.toString() + ' available playbook'
        if (avCount > 1) {
          avLabel += 's'
        }
        return (
          <div className={cx(classes.pad20)}>
            <h2>Available</h2>
            {avLabel}
            {addPubList.Available.map((pb: PbAddItemProps) => (
              <PbAddItem
                key={pb.PubGUID}
                {...pb}
                isSelected={pb.PubGUID === pvPubGUID ? true : false}
                onItemClick={onItemClicked}
                onItemView={onItemView}
              />
            ))}
          </div>
        )
      }
    }
    return <></>
  }

  function renderPbTreeOrg() {
    if (addPubList.Available) {
      if (addPubList.Available.length > 0) {
        return <></>
        // return <>There are {addPubList.Available.length} Available</>;
      }
    }

    if (addPubTree.AvailTree) {
      return (
        <div className={cx(classes.pad20)}>
          <h2>Available</h2>
          {addPubTree.AvailTree.map((aat: PbTreeOrgProps) => (
            <>
              <PbTreeOrg
                key={aat.OrgID}
                OrgID={aat.OrgID}
                ParentOrgID={aat.ParentOrgID}
                OrgName={aat.OrgName}
                pbks={aat.pbks}
                subs={aat.subs}
                //startOpened={true}
                addedPubGUIDs={addedPubGUIDs}
                onTvItemClick={onTvItemClicked}
                onTvItemView={onTvItemView}
              />
            </>
          ))}
        </div>
      )
    }
    return <></>
  }

  function renderAlreadyAdded() {
    if (addedPubGUIDs)
      if (addedPubGUIDs.length > 0) {
        addedPubGUIDs.splice(0, addedPubGUIDs.length)
      }
    if (addPubList)
      if (addPubList.Added)
        for (let i = 0; i < addPubList.Added.length; i++) {
          addedPubGUIDs.push(addPubList.Added[i].PubGUID)
        }

    if (addPubList.Added) {
      if (addPubList.Added.length > 0) {
        return (
          <div className={cx(classes.pad20)}>
            <h2>Included</h2>
            {addPubList.Added.map((pb: PbAddItemProps) => (
              <PbAddItem
                key={pb.PubGUID}
                {...pb}
                isSelected={pb.PubGUID === pvPubGUID ? true : false}
                onItemClick={onItemClicked}
                onItemView={onItemView}
              />
            ))}
          </div>
        )
      }
    }
    return <></>
  }

  function renderNeedUpdating() {
    if (addPubList.BadPass) {
      if (addPubList.BadPass.length > 0) {
        return (
          <div className={cx(classes.pad20)}>
            <h2 className="pad40">Password Update Required</h2>
            {addPubList.BadPass.map((pb: PbAddItemProps) => (
              <PbAddItem
                key={pb.PubGUID}
                {...pb}
                isSelected={pb.PubGUID === pvPubGUID ? true : false}
                onItemClick={onItemClicked}
                onItemView={onItemView}
              />
            ))}
          </div>
        )
      }
    }
    return <></>
  }

  function searchByKeyword(evt: any) {
    //console.log("starting search, using keyword " + keyword);
    doGetAddPubList()
  }
  function clearKeyword(evt: any) {
    setKeyword('')
    //console.log("just cleared  state keyword");
    doGetAddPubList()
  }

  function handleKeywordChange(evt: any) {
    setKeyword(evt.target.value)
    //console.log('just set state keyword to "' + keyword + "'");
    // doGetAddPubList();
  }

  function renderList() {
    if (!addPubList) return <CircularProgress />

    return (
      <>
        {/*         <div className="form-group">
          <label className="form-label">Search Keyword:</label>
          <input
            className="form-control"
            type="text"
            name="keyword"
            id="keyword"
            value={keyword}
            onChange={handleKeywordChange}
          />
          <FontAwesomeIcon
            className={classes.rdsBlue}
            icon={faSearch}
            onClick={searchByKeyword}
          />
          <FontAwesomeIcon
            className={classes.rdsBlue}
            icon={faTimesCircle}
            onClick={clearKeyword}
          />
        </div>
 */}{' '}
        {renderNeedUpdating()}
        {renderAlreadyAdded()}
        {renderPbTreeOrg()}
        {renderAvailable()}
        {renderPasswordPopup()}
      </>
    )
  }

  if (!hasPaper)
    return (
      <div className={cx('container-fluid', className, 'p-2')}>
        {renderList()}
      </div>
    )

  return (
    <Paper className={cx('container-fluid', className, 'p-2')}>
      {renderList()}
    </Paper>
  )
}
