import React, { useEffect, useState } from 'react'
import { ResponsiveDialog } from '../Shared/ResponsiveDialog'
import { SlideLeft } from '../Shared/Transition'
import { DialogContent, TextField, Toolbar } from '@material-ui/core'
import { HeaderNavigation } from '../Shared/HeaderNavigation'
import { Button } from '../Shared/Buttons'
import { useAppSelector } from '../_utils/reactReduxHooks'

import { selectIssuesByInboxGUID } from '../_rootConfigs/rootSelectors'
import { selectIssueCurrentIssueLevel } from '../_rootConfigs/rootSelectors'
import { getStore } from '../_storeManager'
import { Autocomplete } from '@material-ui/lab'
import { isMobileApp } from '../_selectors'
import { IssueDetailMenuHeader } from '../Issue/IssueDetailMenuHeader'
import { Spacer } from '../Shared/Spacer'

interface Props {
  isOpen: boolean
  onCancel: () => void
  onSubmit: (selectedIssue: number) => void
  inboxGUID: string
  alertID?: string
}

interface AbreviatedIssue {
  IssueID: number
  IssueName: string
}

export const AlertAppendIssueDialog = ({
  isOpen,
  onCancel,
  inboxGUID,
  onSubmit,
  alertID,
}: Props) => {
  const [selectedIssue, setSelectedIssue] = useState<AbreviatedIssue | null>(
    null
  )
  const state = getStore().getState()
  const isMobile = useAppSelector(isMobileApp)

  const issues = useAppSelector((state) => {
    return selectIssuesByInboxGUID(state, inboxGUID)
  })

  useEffect(() => {
    setSelectedIssue(null)
  }, [alertID, inboxGUID])

  const renderIssuesList = () => (
    <div style={{ width: isMobile ? '100%' : '500px' }}>
      {issues.length > 0 ? (
        <Autocomplete
          options={
            [
              ...issues.filter(
                (issue: AbreviatedIssue) =>
                  issue.IssueName && issue.IssueName.length > 0
              ),
            ] as Array<AbreviatedIssue>
          }
          value={selectedIssue || null}
          getOptionLabel={(issue: AbreviatedIssue) => {
            return issue.IssueName
          }}
          renderOption={(option) => (
            <div>
              <div
                style={{
                  boxShadow: `-6px 0px 0px ${
                    selectIssueCurrentIssueLevel(state, option.IssueID)
                      ?.LevelRGB
                  }`,
                  padding: '4px',
                  minHeight: '1rem',
                }}
              >
                {option.IssueName}
              </div>
            </div>
          )}
          getOptionSelected={(
            option: AbreviatedIssue,
            value: AbreviatedIssue
          ) => option.IssueID === value.IssueID}
          onChange={(event: any, newValue: AbreviatedIssue | null) => {
            if (newValue) {
              setSelectedIssue(newValue)
            } else {
              setSelectedIssue(null)
            }
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="standard"
              key={params.id}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  e.preventDefault()
                  e.stopPropagation()
                }
              }}
              inputProps={{
                ...params.inputProps,
                onKeyDown: (e) => {
                  if (e.key === 'Enter') {
                    e.preventDefault()
                    e.stopPropagation()
                  }
                },
              }}
            />
          )}
          autoComplete
          openOnFocus
        />
      ) : (
        <div>No issues were found for this inbox's Organization</div>
      )}

      <Spacer />
      {selectedIssue && !!selectedIssue.IssueID && (
        <>
          <IssueDetailMenuHeader IssueID={selectedIssue.IssueID} />
          <Spacer />
        </>
      )}
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        <Button
          name="submit"
          disabled={!selectedIssue?.IssueID}
          onClick={() => {
            if (selectedIssue?.IssueID) {
              onSubmit(selectedIssue?.IssueID)
            }
          }}
        >
          Submit
        </Button>
      </div>
    </div>
  )

  return (
    <ResponsiveDialog
      open={isOpen}
      onClose={onCancel}
      TransitionComponent={SlideLeft as any}
      maxWidth={false}
    >
      <Toolbar>
        <HeaderNavigation
          title={'Append to Issue'}
          onNavClick={onCancel as any}
          canGoBack={false}
        />
      </Toolbar>
      <DialogContent>{renderIssuesList()}</DialogContent>
    </ResponsiveDialog>
  )
}
