import {
  TaskConstants,
  TaskDetailConstants,
  TaskCommentConstants,
  FETCH_LATEST_TASK_DATES,
  UPDATE_TASK_READ,
  SET_IS_VIEWING_TASK,
  INCREMENT_TASK_UNREAD_COUNT,
  FETCH_TASK_UNREAD_COUNT,
} from './_constants'
import {
  removeKeyFromObject,
  reorderItem,
  remapKeyValue,
  addKeyValueIfNotPresent,
} from '../_utils/objectUtils'
import { reducerCrud } from '../_utils/reduxUtils'
import { TaskItemState, TaskModel } from './_models'
import { IssueConstants } from '../Issue/_constants'
import { createReadReducers } from '../ActivityLog/_reducer'
import { createEntityReducer, selectEntityByNumId } from '../_schema/_reducer'

export interface TaskState {
  tasks: { [TaskID: number]: TaskModel }
  collabIdMap: { [IssueCollabID: number]: number }
}

export const defaultTaskState: TaskState = {
  tasks: {},
  collabIdMap: {},
}

export default function taskReducer(state = defaultTaskState, action: any) {
  /*
   * NOTE: Replaced switch with a map because of the following reasons:
   * 1. Easier to scope variables
   * 2. Debuggers properly see the values of let and const declarations
   * 3. Most coding communities recommend maps or polymorphism over switches
   */
  const cases = {
    [TaskConstants.INIT.SUCCESS]: () => ({
      tasks: action.payload.result,
      collabIdMap: remapKeyValue(
        action.payload.result,
        (entity: TaskModel) => entity.IssueCollabID,
        (entity: TaskModel) => entity.TaskID
      ),
    }),
    [TaskConstants.ADD.SUCCESS]: () => {
      const newTask = action.payload.result
      return {
        ...state,
        tasks: {
          ...state.tasks,
          [newTask.TaskID]: {
            ...state.tasks[newTask.TaskID],
            ...newTask,
          },
        },
        collabIdMap: {
          ...state.collabIdMap,
          [newTask.IssueCollabID]: newTask.IssueCollabID,
        },
      }
    },
    [TaskConstants.UPDATE.SUCCESS]: () => {
      const updatedTask = action.payload.result
      return {
        ...state,
        tasks: {
          ...state.tasks,
          [updatedTask.TaskID]: {
            ...state.tasks[updatedTask.TaskID],
            ...updatedTask,
          },
        },
      }
    },
    [TaskConstants.UPDATE_RANGE.SUCCESS]: () => ({
      ...state,
      tasks: reducerCrud.updateRange(state.tasks, action, 'TaskID'),
      collabIdMap: addKeyValueIfNotPresent(
        state.collabIdMap,
        action.payload.result,
        'IssueCollabID',
        (entity: TaskModel) => entity.TaskID
      ),
    }),
    [TaskConstants.DELETE.SUCCESS]: () => {
      const currentTask = state.tasks[action.payload.result.TaskID]
      return {
        ...state,
        tasks: removeKeyFromObject(state.tasks, action.payload.result.TaskID),
        collabIdMap: removeKeyFromObject(
          state.collabIdMap,
          (currentTask || {}).IssueCollabID
        ),
      }
    },
    [TaskConstants.DELETE_RANGE.SUCCESS]: () => ({
      tasks: reducerCrud.deleteIdRange(state.tasks, action.payload.result),
      collabIdMap: reducerCrud.deleteIdRangeFromExtMap(
        state.tasks,
        state.collabIdMap,
        'IssueCollabID',
        action.payload.result
      ),
    }),
    [TaskDetailConstants.FETCH.SUCCESS]: () => {
      const { TaskID, TaskItem } = action.payload.source
      const task = (state.tasks || {})[TaskID]
      return {
        ...state,
        tasks: {
          ...state.tasks,
          [TaskID]: {
            ...task,
            TaskItem,
          },
        },
      }
    },
    [TaskDetailConstants.ADD.SUCCESS]: () => {
      const { TaskItemID } = action.payload.result
      const { TaskID } = action.payload.source
      const task = (state.tasks || {})[TaskID]

      return {
        ...state,
        tasks: {
          ...state.tasks,
          [TaskID]: {
            ...task,
            TaskItem: (task.TaskItem || []).concat(TaskItemID),
          },
        },
      }
    },
    [TaskDetailConstants.DELETE.SUCCESS]: () => {
      const { TaskID } = action.payload.source
      const { TaskItemID } = action.payload.result
      const task = (state.tasks || {})[TaskID]

      return {
        ...state,
        tasks: {
          ...state.tasks,
          [TaskID]: {
            ...task,
            TaskItem: (task.TaskItem || []).filter((id) => id !== TaskItemID),
          },
        },
      }
    },
    [TaskDetailConstants.REORDER.SUCCESS]: () => {
      const { result } = action.payload
      const { source } = action.payload
      const { TaskItemID, Sequence } = result
      let { TaskID } = result
      TaskID = TaskID !== undefined ? TaskID : source.TaskID
      const task = (state.tasks || {})[TaskID]

      if (!task) return state

      // Finally fixed: I thought the destructuring was not geting the TaskID because of
      // The browser debbuger, but really I forgot to do state.tasks instead of state
      const curTaskItems = (state.tasks[TaskID].TaskItem || []).slice(0)

      return {
        ...state,
        tasks: {
          ...state.tasks,
          [TaskID]: {
            ...task,
            TaskItem: reorderItem(curTaskItems, TaskItemID, Sequence),
          },
        },
      }
    },
    [IssueConstants.FETCH.REQUEST]: () => ({
      ...state,
      tasks: {},
    }),
  }

  return (cases[action.type] || (() => state))()
}

// TASK SELECTORS
export const selectTaskIdMap = (state: TaskState) => (state || {}).tasks
export const selectTaskById = (state: TaskState, taskID: number) =>
  selectTaskIdMap(state)[taskID]
export const selectTaskAllItemIds = (state: TaskState, taskID: number) =>
  (selectTaskById(state, taskID) || {}).TaskItem
export const selectTasksByIssueCollabIdMap = (state: TaskState) =>
  (state || {}).collabIdMap
export const selectTaskIdByIssueCollab = (
  state: TaskState,
  issueCollabID: number
) => (selectTasksByIssueCollabIdMap(state) || {})[issueCollabID]

// TaskItem REDUCER
export const taskItemReducer = createEntityReducer('TaskItemID', {
  crudTypes: TaskDetailConstants,
  primaryFetchMerge: true,
  children: [
    {
      crudTypes: TaskCommentConstants,
      foreignKey: 'CommentID',
      parentArray: 'comment',
    },
  ],
})

// TASK ITEM SELECTORS
export const selectTaskItemArtifactId = (
  state: TaskItemState,
  taskItemID: number
) => 'Collab' + (selectEntityByNumId(state, taskItemID) || {}).ArtifactID
export const selectTaskItemCommentIds = (
  state: TaskItemState,
  taskItemID: number
) => (selectEntityByNumId(state, taskItemID) || []).comment

export const taskCommentReducer = createEntityReducer('CommentID', {
  crudTypes: TaskCommentConstants,
})

// TASK ITEM COMMENT SELECTORS

/// Task Unread reducers
const unreadReducers = createReadReducers(
  {
    fetchLatestDates: FETCH_LATEST_TASK_DATES,
    updateRead: UPDATE_TASK_READ,
    setIsViewing: SET_IS_VIEWING_TASK,
    incrementUnreadCount: INCREMENT_TASK_UNREAD_COUNT,
    fetchUnreadCount: FETCH_TASK_UNREAD_COUNT,
  },
  {
    primaryID: 'TaskID',
  }
)

export const taskReadReducer = unreadReducers.read
export const taskUnreadCountReducer = unreadReducers.unreadCount
