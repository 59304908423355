// @ts-nocheck
// TODO: Typescript
import { makeActionCreator } from '../_utils/reduxUtils'
import {
  HoldingStatementConstants,
  UPDATE_RANGE_HOLDING_STATEMENTS,
  DELETE_RANGE_HOLDING_STATEMENTS,
} from './_constants'

export const approveHoldingStatementSuccess = makeActionCreator(
  HoldingStatementConstants.APPROVE.SUCCESS,
  'statement',
  'source'
)
export const updateHoldingStatementSuccess = makeActionCreator(
  HoldingStatementConstants.UPDATE.SUCCESS,
  'statement',
  'source'
)
export const updateHoldingStatement = makeActionCreator(
  HoldingStatementConstants.UPDATE.REQUEST,
  'statement',
  'source'
)
export const approveHoldingStatement = makeActionCreator(
  HoldingStatementConstants.APPROVE.REQUEST,
  'statement',
  'source'
)
export const fetchHoldingStatements = function (result, source) {
  return {
    type: HoldingStatementConstants.FETCH.SUCCESS,
    payload: { result, source },
  }
}

export function udpateRangeHoldingStatementsSuccess(result, source) {
  return {
    type: UPDATE_RANGE_HOLDING_STATEMENTS.SUCCESS,
    payload: {
      result,
      source,
    },
  }
}

export function deleteRangeHoldingStatementsSuccess(result, source) {
  return {
    type: DELETE_RANGE_HOLDING_STATEMENTS.SUCCESS,
    payload: {
      result,
      source,
    },
  }
}
