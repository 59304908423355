import React from 'react'
import classes from './IssueTemplateListing.module.scss'
import { Spacer } from '../Shared/Spacer'
import {
  CircularProgress,
  Dialog,
  DialogContent,
  FormControlLabel,
  Radio,
  RadioGroup,
  withMobileDialog,
} from '@material-ui/core'
import { cx } from '../_utils/objectUtils'
import { SlideLeft } from '../Shared/Transition'
import { HeaderNavigation } from '../Shared/HeaderNavigation'
import { useAppSelector } from '../_utils/reactReduxHooks'
import { selectIssueById } from './_selectors'
import { http } from '../_utils/http'
import { Button } from '../Shared/Buttons'

interface Props {
  IssueID: number
  open: boolean
  onClose: (
    event: {},
    reason: 'backdropClick' | 'escapeKeyDown' | 'userSaved'
  ) => void
}

interface Issue {
  IssueName: string
  IssueCreated: string
  IssueType: string
  Description: string
  lastActivityDate: string
}

const ResponsiveDialog = withMobileDialog()(Dialog)

export const IssueChangeTemplateFlagDialog = ({
  IssueID,
  open,
  onClose,
}: Props) => {
  const [picklist, setPicklist] = React.useState<any>('')
  const [picklistLoading, setPicklistLoading] = React.useState(true)
  const [TemplateFlagYN, setTemplateFlagYN] = React.useState('')

  const getPicklist = React.useCallback(async (): Promise<any> => {
    var customHeaders: any = []
    if (IssueID) {
      customHeaders.push({ name: 'issueid', value: `${IssueID}` })
    }
    const result = await http<any>({
      path: '/Issue/ClonePicklist/',
      headers: customHeaders,
    })
    if (result.ok && result.body) {
      return result.body
    } else {
      return ''
    }
  }, [IssueID])

  const changeTemplateFlag = async (): Promise<any> => {
    var customHeaders: any = []
    if (IssueID) {
      customHeaders.push({ name: 'issueid', value: `${IssueID}` })
    }
    if (TemplateFlagYN) {
      customHeaders.push({ name: 'templateflagyn', value: `${TemplateFlagYN}` })
    }
    const result = await http<any>({
      path: '/Issue/ChangeTemplateFlag/',
      headers: customHeaders,
    })
    if (result.ok && result.body) {
      return result.body
    } else {
      return ''
    }
  }

  const issue: Issue = useAppSelector((state) =>
    selectIssueById(state, IssueID)
  )

  React.useEffect(() => {
    if (IssueID > 0 && open === true) {
      //alert('useEffect ' + IssueID + ' ' + open)
      const doGetPicklist = async () => {
        const picklist = await getPicklist()
        setPicklist(picklist)
        setTemplateFlagYN(picklist.TemplateFlagYN)
        setPicklistLoading(false)
      }
      doGetPicklist()
    }
  }, [IssueID, open, getPicklist])

  function handleTemplateFlagYN(evt: any) {
    setTemplateFlagYN(evt.target.value)
  }

  function handleSaveButton() {
    const doChangeTemplateFlag = async () => {
      const changedResult = await changeTemplateFlag()
      if (changedResult) {
        // alert(JSON.stringify(changedResult, null, 2))
        if (changedResult.error) {
          alert(changedResult.error)
        } else {
          onClose({}, 'userSaved')
        }
      }
    }
    doChangeTemplateFlag()
  }
  function closeDialog() {
    onClose({}, 'escapeKeyDown')
  }

  function render() {
    if (picklistLoading) {
      return (
        <>
          <CircularProgress />
        </>
      )
    }

    if (picklist.Error) {
      return (
        <div>
          <label>{picklist.Error}</label>
        </div>
      )
    }

    if (picklist.TemplateFlagYN === 'N' && picklist.IssueType !== 'D') {
      return (
        <>
          <div>
            <span className={classes.title}>Cannot set as a Template</span>
            <Spacer spaceParam={'large'} />
          </div>
          <div>
            <label>
              The Issue Mode must be set to 'Design' in order to use as a
              Template.
            </label>
          </div>
          <Spacer spaceParam={'large'} />
          <div className={cx(classes.floatRight)}>
            <Button
              className={cx('p-2', 'px-3', classes.btnIcc)}
              variant={'primary' as any}
              onClick={closeDialog}
            >
              OK
            </Button>
          </div>
          <Spacer spaceParam={'large'} />
        </>
      )
    }

    return (
      <div>
        <HeaderNavigation onNavClick={closeDialog} canGoBack={false} />
        <span className={classes.title}>Change Template Flag</span>
        <Spacer spaceParam={'large'} />
        <div>
          <label className={classes.inputLabel}>Issue Name</label>
          <input
            className={classes.enabledInputs}
            style={{ marginBottom: 'none' }}
            type="text"
            maxLength={255}
            name="IssueName"
            id="IssueName"
            value={issue.IssueName}
            disabled={true}
          />
        </div>

        <Spacer spaceParam={'large'} />
        <div>
          <label id="lblIssueType" className={classes.inputLabel}>
            Is Template?
          </label>

          <RadioGroup
            aria-labelledby="lblIssueType"
            name="TemplateFlagYN"
            id="TemplateFlagYN"
            value={TemplateFlagYN}
            onChange={handleTemplateFlagYN}
            style={{ marginLeft: '1rem' }}
          >
            <FormControlLabel
              value={'Y'}
              control={
                <Radio
                  className={classes.MuiIconButtonColorSecondary}
                  style={{
                    paddingTop: 0,
                    paddingBottom: 0,
                    paddingLeft: '20px',
                  }}
                />
              }
              label={'Is A Template'}
            />
            <FormControlLabel
              value={'N'}
              control={
                <Radio
                  className={classes.MuiIconButtonColorSecondary}
                  style={{
                    paddingTop: 0,
                    paddingBottom: 0,
                    paddingLeft: '20px',
                  }}
                />
              }
              label={'Not a Template'}
            />
          </RadioGroup>
        </div>
        <Spacer spaceParam={'large'} />
        <div>
          <>
            <Button
              className={cx('p-2', 'mr-2', classes.btnIcc)}
              variant={'primary' as any}
              onClick={handleSaveButton}
            >
              Change
            </Button>
          </>
        </div>
        <Spacer spaceParam={'large'} />
        {/* <pre>{JSON.stringify(picklist, null, 2)}</pre> */}
      </div>
    )
  }

  return (
    <ResponsiveDialog
      open={open}
      onClose={onClose}
      TransitionComponent={SlideLeft as any}
    >
      <DialogContent>{render()}</DialogContent>
    </ResponsiveDialog>
  )
}
