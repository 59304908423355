import { bindSel, getStakeholder } from '../_rootConfigs/rootReducerSlicers'
import * as priv from './_reducer'

export const isStakeholderAdvisoryCreated = bindSel(
  getStakeholder,
  priv.isStakeholderAdvisoryCreated
)
export const selectCurrentStakeholderID = bindSel(
  getStakeholder,
  priv.selectCurrentStakeholderID
)
