import { getFetchListener } from '../_utils/sagaUtils'
import {
  TaskDetailConstants,
  TaskCommentConstants,
  FETCH_LATEST_TASK_DATES,
  FETCH_TASK_UNREAD_COUNT,
} from './_constants'
import { takeLatest } from 'redux-saga/effects'
import {
  TaskDetailActions,
  TaskCommentActions,
  fetchLatestTaskDates,
  fetchTaskUnreadCount,
} from './_actions'
import { normalize } from 'normalizr'
import {
  collabTaskItemListSchema,
  transformEntities,
  collabTaskItemCommentListSchema,
  getArtifactId,
  workspaceListSchema,
  icoNormalize,
} from '../_schema/_schemas'
import { getCleanArray } from '../_utils/objectUtils'
import { ArtifactActions } from '../Upload/_actions'
import { parsePathVariables } from '../_utils/urlUtils'
import { objMapDatePropsToUTC } from '../_utils/dateUtils'
import { WorkspaceActions } from '../Workspace/_actions'

// The Saga that does the actual work
const fetchTaskDetail = getFetchListener('api/Task', {
  onLoading: (result, params, source) =>
    TaskDetailActions.fetchLoading(result, { ...source, ...params }),
  onFailure: (error, result, params, source) =>
    TaskDetailActions.fetchFailure(error, result, {
      ...params,
      ...source,
    }),
  onSuccess: (json, params, source) => {
    json = json || []
    const task = json[0] || {}
    const TaskID = task.TaskID || parseInt(source.pathVariables)

    // Sql empty array fix
    if (
      task.TaskItem &&
      task.TaskItem.length === 1 &&
      task.TaskItem[0].TaskItemID === undefined
    ) {
      task.TaskItem = []
    }

    json = normalize(task.TaskItem, collabTaskItemListSchema)
    transformEntities(
      json.entities.TaskItem,
      'CreatedUTC',
      'CompletedUTC',
      'DueByUTC'
    )

    // return action with entities(lookup table) and results(id array) as the payload
    return TaskDetailActions.fetchSuccess(json.entities.TaskItem || {}, {
      ...source,
      TaskID,
      TaskItem: json.result,
    })
  },
  appendPath: function (action) {
    return action.payload.result.TaskID
  },
})

const fetchTaskItemComments = getFetchListener('api/Task', {
  onLoading: TaskCommentActions.fetchLoading,
  onFailure: TaskCommentActions.fetchFailure,
  onSuccess: (json, params, source) => {
    json = json || []
    const taskItem = json[0] || {}

    // Get the taskItemID from the path if it is not returned from the server
    const pathVars = parsePathVariables(source.pathVariables)
    const TaskItemID = taskItem.TaskItemID || pathVars.TaskItem

    // Fix for SQL Empty array
    taskItem.comment = getCleanArray(taskItem.comment)
    transformEntities(taskItem.comment, 'CreatedUTC')

    let artifactIds = []

    // Convert upload to a single object
    for (var i = 0; i < taskItem.comment.length; i++) {
      var curComment = taskItem.comment[i]
      curComment.upload = getCleanArray(curComment.upload)
      if (curComment.upload && curComment.upload[0]) {
        curComment.upload = curComment.upload[0]
        curComment.upload.CreatedUTC = curComment.CreatedUTC
        artifactIds.push(getArtifactId(curComment.upload))
      } else curComment.upload = undefined
    }

    const normResult = icoNormalize(
      taskItem.comment,
      collabTaskItemCommentListSchema
    )

    //const { pathVariables } = source;
    return [
      TaskCommentActions.fetchSuccess(normResult.entities.comment || {}, {
        TaskItemID,
        comment: normResult.result /*, pathVariables*/,
      }),
      ArtifactActions.fetchSuccess(normResult.entities.Artifacts || {}, {
        icoRequestId: artifactIds,
      }),
    ]
  },
  appendPath: function (action) {
    const { TaskID, TaskItemID } = action.payload.result
    return `${TaskID}/TaskItem/${TaskItemID}/Comment`
  },
})

const requestLatestTaskDates = getFetchListener('/api/Task/LatestDates', {
  formatData: false,
  onLoading: (result, _, source) =>
    fetchLatestTaskDates.loading(result, undefined, source),
  onFailure: fetchLatestTaskDates.failure,
  onSuccess: (result, params) => {
    result = getCleanArray(result)

    let normalizedData = normalize(result, workspaceListSchema)

    // Fix the dates for the chats
    objMapDatePropsToUTC(
      normalizedData.entities?.CollabTask,
      'lastActivityDate',
      'LastRead'
    )

    return [
      fetchLatestTaskDates.success(normalizedData.entities?.CollabTask, params),
      WorkspaceActions.updateRangeSuccess(normalizedData.entities?.wksp as any),
    ]
  },
})

const requestTaskUnreadCount = getFetchListener('/api/Task/', {
  onLoading: (result, _, source) =>
    fetchTaskUnreadCount.loading(result, undefined, source),
  onFailure: fetchTaskUnreadCount.failure,
  onSuccess: fetchTaskUnreadCount.success,
  appendPath: function (action) {
    return action.payload.params.TaskID + '/UnreadCount'
  },
})

// Watcher sagas
export const taskSagas = [
  // takeLatest - will cancel previous fetch request and only run the latest one.
  // This is a listener. the yield all is called in the store. The logic is similar to reducers combine logic
  takeLatest(TaskDetailConstants.FETCH.REQUEST, fetchTaskDetail),
  takeLatest(TaskCommentConstants.FETCH.REQUEST, fetchTaskItemComments),
  takeLatest(FETCH_LATEST_TASK_DATES.REQUEST, requestLatestTaskDates),
  takeLatest(FETCH_TASK_UNREAD_COUNT.REQUEST, requestTaskUnreadCount),
]
