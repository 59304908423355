import { faTimes } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  DialogContent,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@material-ui/core'
import React, { ChangeEvent } from 'react'
import { useDispatch } from 'react-redux'
import Checkbox from '../CustomMaterial/Checkbox'
import { Button } from '../Shared/Buttons'
import { ResponsiveDialog } from '../Shared/ResponsiveDialog'
import { Spacer } from '../Shared/Spacer'
import { SlideLeft } from '../Shared/Transition'
import { addNotification } from '../_actions'
import { importTaskListTemplate } from '../_utils/apiUtils'
import { cx } from '../_utils/objectUtils'
import { TaskTemplate } from './TaskListToolbar'
import classes from './TaskTemplateSelectionDialog.module.scss'

const TaskTemplateSelectionDialog = ({
  open,
  taskTemplateList,
  taskId,
  orgId,
  onClose,
}: {
  open: boolean
  taskId: number
  orgId: number
  taskTemplateList: { status: string; templates: TaskTemplate[] }
  onClose: () => void
}) => {
  const [uploadAction, setUploadAction] = React.useState('O')
  const [selectedTemplate, setSelectedTemplate] = React.useState<TaskTemplate>()
  const [includeAssignments, setIncludeAssignments] = React.useState(true)

  function handleUploadActionChange(event: ChangeEvent<HTMLInputElement>) {
    setUploadAction(event.target.value)
  }

  const toggleIncludeAssignments = () => {
    setIncludeAssignments((includeAssignments) => !includeAssignments)
  }

  const closeDialog = () => {
    onClose()
    setSelectedTemplate(undefined)
    setUploadAction('O')
  }

  const dispatch = useDispatch()

  const alphabetizedTaskTemplateList = taskTemplateList?.templates?.sort(
    (a, b) => a.template_name.localeCompare(b.template_name)
  )

  const taskTemplateSelectionContent =
    taskTemplateList.status === 'SUCCESS' && taskTemplateList.templates ? (
      <div className={classes.taskTemplateList}>
        {alphabetizedTaskTemplateList.map((template) => {
          const templateSelected = template.id === selectedTemplate?.id
          return (
            <div
              key={template.id}
              className={cx(
                templateSelected ? classes.templateListItemSelected : '',
                classes.templateListItem
              )}
              style={{
                backgroundColor: templateSelected ? 'var(--icc-blue)' : '',
              }}
              onClick={() => {
                if (template.id === selectedTemplate?.id) {
                  setSelectedTemplate(undefined)
                } else {
                  setSelectedTemplate(template)
                }
              }}
            >
              {template.template_name}
            </div>
          )
        })}
      </div>
    ) : (
      <div> No Task Templates for this org</div>
    )

  return (
    <ResponsiveDialog
      onClose={(event, reason) => {
        if ((reason = 'backdropClick')) {
          closeDialog()
        }
      }}
      open={open}
      TransitionComponent={SlideLeft as any}
    >
      <DialogContent className={classes.dialogContent}>
        <div className={classes.header}>
          <div className={classes.headerBack} onClick={closeDialog}>
            <FontAwesomeIcon icon={faTimes} />
          </div>
          <Spacer />
          Select your Template
        </div>
        <Spacer />
        <RadioGroup
          className={classes.appendOverwriteSection}
          onChange={handleUploadActionChange}
          value={uploadAction}
        >
          <FormControlLabel
            value={'O'}
            control={<Radio color="primary" />}
            label="Overwrite current list"
          />
          <FormControlLabel
            value={'A'}
            control={<Radio color="primary" />}
            label="Append to current list"
          />
        </RadioGroup>
        <div>
          <div>
            <Checkbox
              style={{ marginLeft: '-12px' }}
              checked={includeAssignments}
              name={'includeAssignments'}
              onChange={toggleIncludeAssignments}
            />
            Include Assignments
          </div>
          {includeAssignments && (
            <div style={{ fontSize: '12px', color: 'red' }}>
              Assignments will only be included for those team members in your
              Issue Participant list.
            </div>
          )}
        </div>
        <Spacer />
        Available Templates:
        {taskTemplateSelectionContent}
        <Spacer />
        <Spacer />
        <div className={classes.submitButtonContainer}>
          <Button
            onClick={() => {
              if (selectedTemplate?.id) {
                importTaskListTemplate(
                  taskId,
                  orgId,
                  selectedTemplate.id,
                  uploadAction,
                  includeAssignments ? 'Y' : 'N'
                ).then((status) => {
                  if (status === 'FAILED') {
                    dispatch(
                      addNotification({
                        type: 'error',
                        message: 'Task List was not successfully loaded',
                      })
                    )
                  } else if (status === 'SUCCESS') {
                    dispatch(
                      addNotification({
                        type: 'success',
                        message: 'Task List was successfully loaded',
                      })
                    )
                  }
                })
              }
              closeDialog()
            }}
            disabled={!selectedTemplate}
          >
            {' '}
            Submit{' '}
          </Button>
        </div>
      </DialogContent>
    </ResponsiveDialog>
  )
}

export default TaskTemplateSelectionDialog
