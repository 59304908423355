import { faTimes } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { DialogContent, Input } from '@material-ui/core'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { Button } from '../Shared/Buttons'
import { ResponsiveDialog } from '../Shared/ResponsiveDialog'
import { Spacer } from '../Shared/Spacer'
import { SlideLeft } from '../Shared/Transition'
import { addNotification } from '../_actions'
import { createTaskListTemplate } from '../_utils/apiUtils'
import classes from '../Task/TaskTemplateUploadDialog.module.scss'

const AdminConsoleTaskTemplateAddDialog = ({
  open,
  orgId,
  onClose,
}: {
  open: boolean
  orgId: number
  onClose: () => void
}) => {
  const closeDialog = () => {
    onClose()
    setTemplateTitle('')
  }
  const [templateTitle, setTemplateTitle] = useState('')

  const dispatch = useDispatch()
  return (
    <ResponsiveDialog
      onClose={(event, reason) => {
        if ((reason = 'backdropClick')) {
          closeDialog()
        }
      }}
      open={open}
      TransitionComponent={SlideLeft as any}
    >
      <DialogContent className={classes.dialogContent}>
        <div className={classes.header}>
          <FontAwesomeIcon
            icon={faTimes}
            style={{ cursor: 'pointer' }}
            onClick={() => {
              closeDialog()
            }}
          />
          <Spacer />
          Template Name:
          <Spacer />
        </div>
        <div className={classes.inputAndSubmit}>
          <Input
            value={templateTitle}
            placeholder="Template Title"
            onChange={(event) => setTemplateTitle(event.target.value)}
          />
          <Spacer />

          <Button
            onClick={() => {
              createTaskListTemplate(orgId, templateTitle).then((status) => {
                if (status === 'FAILED') {
                  dispatch(
                    addNotification({
                      type: 'error',
                      message: 'Task List was not created',
                    })
                  )
                } else if (status === 'SUCCESS') {
                  dispatch(
                    addNotification({
                      type: 'success',
                      message: 'Task Template was created',
                    })
                  )
                }
              })
              closeDialog()
            }}
          >
            Submit
          </Button>
        </div>
      </DialogContent>
    </ResponsiveDialog>
  )
}

export default AdminConsoleTaskTemplateAddDialog
