// @ts-nocheck
// TODO: Typescript

import {
  isAsyncAction,
  parseAsyncAction,
  requestStatus as req,
} from '../_utils/reduxUtils'
import {
  FETCH_ISSUE_DETAILS,
  IssueConstants,
  RESOLVE_ISSUE,
} from '../Issue/_constants'
import { AppConstants } from '../_constants'
import { TaskDetailConstants, TaskCommentConstants } from '../Task/_constants'
import { UploadConstants, ArtifactConstants } from '../Upload/_constants'
import { createSelector } from 'reselect'
import {
  FETCH_CREATE_ISSUE_FORMS,
  FETCH_UPDATE_ISSUE_FORMS,
  FETCH_FORM_CONTENT,
  SUBMIT_ISSUE_FORM,
  FETCH_CHANGE_LEVEL_FORM_CONTENT,
  FETCH_OPEN_ISSUE_FORM_CONTENT,
  FETCH_RESOLVE_ISSUE_FORM_CONTENT,
} from '../Form/_constants'
import { WebSocketConstants } from '../_webSocket/_constants'
import {
  AuthConstants,
  FETCH_REQUEST_TOKEN,
  LogoutConstants,
} from '../Auth/_constants'
import { CLEAR_REQUEST_STATUS, CLEAR_REQUEST_SYNC } from './_constants'
import { StakeholderConstants } from '../Stakeholder/_constants'
import { ParticipantTeamConstants } from '../Participant/_constants'
import { LOGIN_AD } from '../MicrosoftTeams/_constants'
import {
  CREATE_DIRECT_MESSAGE,
  CREATE_OWNER_DIRECT_MESSAGE,
  ChatDetailConstants,
  FETCH_DIRECT_MESSAGES,
  DELETE_DIRECT_MESSAGE,
} from '../Chat/_constants'
import {
  ADD_INBOX,
  PIN_ALERT,
  ADD_ALERT_TO_ISSUE,
  CREATE_NEW_ALERT_ISSUE,
  DELETE_INBOX,
  MARK_REVIEWED_ALERT,
  FETCH_INBOXES,
  FETCH_ALERTS,
  UPDATE_INBOX,
} from '../Alert/_constants'
import { FETCH_ORG_UGIS_MEMBERS } from '../Org/_constants'

export interface RequestStatusState {}

export default function requestStatusReducer(
  state: RequestStatusState = {},
  action: any
) {
  if (
    action.type === AuthConstants.USER_UNAUTHENTICATED ||
    action.type === LogoutConstants.SUCCESSFUL
  )
    return { [CLEAR_REQUEST_STATUS]: true }
  if (isAsyncAction(action.type)) {
    const typeObj = parseAsyncAction(action.type)
    let { source, params } = action.payload || {}
    let icoRequestId = getIcoRequestID(source, params)
    source = source || {}
    params = params || {}
    // Track the request by action with request id to account for separate request instances
    if (icoRequestId) {
      if (icoRequestId instanceof Array) {
        let allRequestIds = {}
        icoRequestId.forEach((id) => {
          const oldState = (state[typeObj.action] || {})[icoRequestId] || {}
          allRequestIds[id] = {
            status: typeObj.status,
            progress: source.progress,
            oldStatuses: {
              ...oldState.oldStatuses,
              [typeObj.status]: true,
            },
          }
        })

        return {
          ...state,
          [typeObj.action]: {
            ...state[typeObj.action],
            status: null,
            progress: null,
            ...allRequestIds,
          },
        }
      } else {
        const oldState = (state[typeObj.action] || {})[icoRequestId] || {}
        return {
          ...state,
          [typeObj.action]: {
            ...state[typeObj.action],
            status: null,
            progress: null,
            [icoRequestId]: {
              status: typeObj.status,
              progress: source.progress,
              oldStatuses: {
                ...oldState.oldStatuses,
                [typeObj.status]: true,
              },
            },
          },
        }
      }
    }

    // Track action without separate action instances based on id
    const oldState = state[typeObj.action] || {}
    return {
      ...state,
      [typeObj.action]: {
        status: typeObj.status,
        progress: source.progress,
        oldStatuses: {
          ...oldState.oldStatuses,
          [typeObj.status]: true,
        },
      },
    }
  }

  return state
}

export const selectIsAzureADLoginFailure = createIsFailureSelector(
  LOGIN_AD.ACTION
)
export const selectIsAzureADLoginRequest = createIsRequestSelector(
  LOGIN_AD.ACTION
)
export const isInitIssuesLoading = createIsLoadingSelector(
  IssueConstants.INIT.ACTION
)
export const isInitIssuesComplete = createIsCompleteSelector(
  IssueConstants.INIT.ACTION
)
export const isInitIssuesSuccess = createIsCompleteSelector(
  IssueConstants.INIT.ACTION
)
export const isFetchIssuesLoading = createIsLoadingReqSelector(
  IssueConstants.FETCH.ACTION
)
export const isFetchIssuesComplete = createIsCompleteSelector(
  IssueConstants.FETCH.ACTION
)
export const isAppLoaded = createIsCompleteSelector([
  AppConstants.FETCH_INIT.ACTION,
  IssueConstants.INIT.ACTION,
])
export const isAppLoading = createIsLoadingSelector(
  [AppConstants.FETCH_INIT.ACTION, IssueConstants.INIT.ACTION],
  'or'
)
export const selectIsAppInitialDataLoaded = createIsCompleteSelector([
  AppConstants.FETCH_INIT.ACTION,
])
export const isAppInitialDataSuccess = createIsSuccessSelector([
  AppConstants.FETCH_INIT.ACTION,
])
export const selectIsAppInitialDataLoading = createIsLoadingSelector([
  AppConstants.FETCH_INIT.ACTION,
])
export const isFetchTaskItemsLoading = createIsLoadingSelector(
  TaskDetailConstants.FETCH.ACTION
)
export const isFetchTaskItemsFailure = createIsFailureSelector(
  TaskDetailConstants.FETCH.ACTION
)
export const isFetchTaskItemsPreviouslyLoaded =
  createIsPreviouslyLoadedSelector(TaskDetailConstants.FETCH.ACTION)
export const selectUploadsInProgress = createAllLoadingActionSelector(
  UploadConstants.ACTION
)
export const selectUploadStatus = createActionStatusSelector(
  UploadConstants.ACTION
)
export const isArtifactDomainInitialized = createIsSuccessSelector(
  ArtifactConstants.INIT.ACTION
)
export const isArtifactContentLoading = createIsLoadingSelector(
  [ArtifactConstants.FETCH.ACTION, ArtifactConstants.ADD.ACTION],
  'or'
)
export const isArtifactContentLoaded = createIsSuccessSelector(
  [ArtifactConstants.FETCH.ACTION, ArtifactConstants.ADD.ACTION],
  'or'
)
export const isRequestStatusCleared = (state) => state[CLEAR_REQUEST_STATUS]
export const isUploadSuccess = createSelector(
  selectUploadStatus,
  (status) => (status || {}).status === req.SUCCESS
)
export const selectUploadTotalProgress = createSelector(
  selectUploadStatus,
  (status) => (status || {}).progress
)
export const isUploadInProgress = createSelector(
  selectUploadStatus,
  (status) => (status || {}).status === req.LOADING
)
export const isFetchTaskCommentsLoading = createIsLoadingSelector(
  TaskCommentConstants.FETCH.ACTION
)
export const isFetchTaskCommentsComplete = createIsLoadingSelector(
  TaskCommentConstants.FETCH.ACTION
)
export const isFetchingChatEntries = createIsLoadingSelector(
  ChatDetailConstants.FETCH.ACTION
)

export const isAddingTeamParticipants = createIsRequestSelector(
  ParticipantTeamConstants.ADD_RANGE.ACTION
)
export const isAddingTeamParticipantsSuccess = createIsSuccessSelector(
  ParticipantTeamConstants.ADD_RANGE.ACTION
)
export const isAddingTeamParticipantsComplete = createIsCompleteSelector(
  ParticipantTeamConstants.ADD_RANGE.ACTION
)

export const isCreateIssueFormsLoading = createIsLoadingSelector(
  FETCH_CREATE_ISSUE_FORMS.ACTION
)
export const isCreateIssueFormsComplete = createIsCompleteSelector(
  FETCH_CREATE_ISSUE_FORMS.ACTION
)
export const isCreateIssueFormsSuccess = createIsSuccessSelector(
  FETCH_CREATE_ISSUE_FORMS.ACTION
)
export const isUpdateIssueFormsLoading = createIsLoadingSelector(
  FETCH_UPDATE_ISSUE_FORMS.ACTION
)
export const isUpdateIssueFormsFailure = createIsFailureSelector(
  FETCH_UPDATE_ISSUE_FORMS.ACTION
)
export const isFormContentLoading = createIsLoadingSelector(
  FETCH_FORM_CONTENT.ACTION
)
export const isChangeLevelFormContentLoading = createIsLoadingSelector(
  FETCH_CHANGE_LEVEL_FORM_CONTENT.ACTION
)
export const isChangeLevelFormContentFailure = createIsFailureSelector(
  FETCH_CHANGE_LEVEL_FORM_CONTENT.ACTION
)
export const isOpenIssueFormContentLoading = createIsLoadingSelector(
  FETCH_OPEN_ISSUE_FORM_CONTENT.ACTION
)
export const isOpenIssueFormContentFailure = createIsFailureSelector(
  FETCH_OPEN_ISSUE_FORM_CONTENT.ACTION
)
export const isResolveIssueFormContentLoading = createIsLoadingSelector(
  FETCH_RESOLVE_ISSUE_FORM_CONTENT.ACTION
)
export const isResolveIssueFormContentFailure = createIsFailureSelector(
  FETCH_RESOLVE_ISSUE_FORM_CONTENT.ACTION
)
export const isIssueFormSubmitting = createIsLoadingSelector(
  SUBMIT_ISSUE_FORM.ACTION
)
export const isIssueFormSubmitFailure = createIsFailureSelector(
  SUBMIT_ISSUE_FORM.ACTION
)
export const isIssueResolving = createIsLoadingSelector(RESOLVE_ISSUE.ACTION)
export const isIssueResolvingComplete = createIsCompleteSelector(
  RESOLVE_ISSUE.ACTION
)
export const isFetchingIssueDetails = createIsLoadingReqSelector(
  FETCH_ISSUE_DETAILS.ACTION
)
export const isFetchingIssueDetailsComplete = createIsCompleteSelector(
  FETCH_ISSUE_DETAILS.ACTION
)

export const isCreatingStakeholder = createIsRequestSelector(
  StakeholderConstants.ADD.ACTION
)
export const isCreateStakeholderComplete = createIsCompleteSelector(
  StakeholderConstants.ADD.ACTION
)
export const isCreateStakeholderFailure = createIsFailureSelector(
  StakeholderConstants.ADD.ACTION
)
export const isUpdatingStakeholder = createIsRequestSelector(
  StakeholderConstants.UPDATE.ACTION
)
export const isUpdateStakeholderComplete = createIsCompleteSelector(
  StakeholderConstants.UPDATE.ACTION
)
export const isUpdateStakeholderFailure = createIsFailureSelector(
  StakeholderConstants.UPDATE.ACTION
)

export const isDeletingIssue = createIsRequestSelector(
  IssueConstants.DELETE.ACTION
)
export const isDeletingIssueComplete = createIsCompleteSelector(
  IssueConstants.DELETE.ACTION
)

// Direct Messages
export const isFetchingDirectMessages = createIsLoadingSelector(
  FETCH_DIRECT_MESSAGES.ACTION
)
export const isCreatingDirectMessage = createIsRequestSelector(
  CREATE_DIRECT_MESSAGE.ACTION
)
export const isCreatingDirectMessageSuccess = createIsSuccessSelector(
  CREATE_DIRECT_MESSAGE.ACTION
)
export const isCreatingOwnerDirectMessage = createIsRequestSelector(
  CREATE_OWNER_DIRECT_MESSAGE.ACTION
)
export const isCreatingOwnerDirectMessageSuccess = createIsSuccessSelector(
  CREATE_OWNER_DIRECT_MESSAGE.ACTION
)
export const isDeletingDirectMessage = createIsRequestSelector(
  DELETE_DIRECT_MESSAGE.ACTION
)
export const isDeletingDirectMessageSuccess = createIsSuccessSelector(
  DELETE_DIRECT_MESSAGE.ACTION
)

// Inboxes
export const isInboxLoading = createIsLoadingSelector(FETCH_INBOXES.ACTION)
export const isInboxFetchComplete = createIsCompleteSelector(
  FETCH_INBOXES.ACTION
)
export const isDeletingInbox = createIsRequestSelector(DELETE_INBOX.ACTION)
export const isUpdatingInbox = createIsRequestSelector(UPDATE_INBOX.ACTION)
export const isCreatingInbox = createIsRequestSelector(ADD_INBOX.ACTION)
export const isAlertsLoading = createIsLoadingSelector(FETCH_ALERTS.ACTION)

export const isCreatingAlert = createIsRequestSelector(PIN_ALERT.ACTION)
export const isAddingAlertToIssue = createIsRequestSelector(
  ADD_ALERT_TO_ISSUE.ACTION
)
export const isCreatingNewAlertIssue = createIsRequestSelector(
  CREATE_NEW_ALERT_ISSUE.ACTION
)
export const isDeletingAlert = createIsRequestSelector(
  MARK_REVIEWED_ALERT.ACTION
)

// Request Token
export const selectIsFetchingRequestToken = createIsLoadingSelector(
  FETCH_REQUEST_TOKEN.ACTION
)

// Org
export const fetchingOrgUgisMembers = createIsLoadingReqSelector(
  FETCH_ORG_UGIS_MEMBERS.ACTION
)

export function requestSyncReducer(state = {}, action) {
  let { type, payload } = action || {}
  let isSynced = true
  // Similar structure to requestStatusReducer.
  // This reducer determines if a request should be made again (meaning connection to the server was lost)

  // Reset flags after a user loses and reestablishes connection
  // Also reset if the user logs out or is unauthenticated
  if (
    type === WebSocketConstants.CONNECTION_RETRY_SUCCESSFUL ||
    type === AuthConstants.USER_UNAUTHENTICATED ||
    type === LogoutConstants.SUCCESSFUL
  )
    return {}

  if (type === CLEAR_REQUEST_SYNC) {
    type = payload.type
    isSynced = false
  }

  let allTypes = type || []
  if (typeof type === 'string') allTypes = [type]

  let newState = {}
  let asyncActionCount = 0
  allTypes.forEach((type) => {
    if (isAsyncAction(type)) {
      asyncActionCount++
      const typeObj = parseAsyncAction(type)
      // The request needs to be made again if a failure occurs TODO: Account for permission error vs network error
      /*if (typeObj.status === req.FAILURE)
                isSynced = false;*/

      let { source, params } = payload || {}
      source = source || {}
      params = params || {}

      let icoRequestId = getIcoRequestID(source, params)

      // Track the request by action with request id to account for separate request instances
      if (icoRequestId) {
        if (icoRequestId instanceof Array) {
          let allRequestIds = {}
          icoRequestId.forEach((id) => {
            allRequestIds[id] = isSynced
          })

          newState[typeObj.action] = {
            ...state[typeObj.action],
            ...allRequestIds,
          }
        } else {
          newState[typeObj.action] = {
            ...state[typeObj.action],
            [icoRequestId]: isSynced,
          }
        }
      } else {
        // Track action without separate action instances based on id
        newState[typeObj.action] = isSynced
      }
    }
  })

  if (asyncActionCount > 0) {
    return {
      ...state,
      ...newState,
    }
  }

  return state
}

export const fetchTaskItemsSync = createRequestSync(
  TaskDetailConstants.FETCH.ACTION
)
export const fetchDirectMessagesSync = createRequestSync(
  FETCH_DIRECT_MESSAGES.ACTION
)
export const fetchChatEntriesInSync = createRequestSync(
  ChatDetailConstants.FETCH.ACTION
)
export const fetchArtifactContentSync = createRequestSync([
  ArtifactConstants.FETCH.ACTION,
  ArtifactConstants.ADD.ACTION,
])
export const fetchIssueDetailsSync = createRequestSync(
  FETCH_ISSUE_DETAILS.ACTION
)

/******Helper Functions *************/

/**
 * @param {array | string} actions - The list of actions to track
 * @param {array} statuses - The statuses to OR
 */
function createIsStatusSelector(actions, statuses, op) {
  if (typeof actions === 'string') actions = [actions]

  op = op || 'and'

  return function (state: RequestStatusState, icoRequestId?: string | number) {
    // OR loop for actions
    if (op.toLocaleLowerCase() === 'or') {
      for (let i = 0; i < actions.length; i++) {
        // A status is found
        if (
          statuses.indexOf(getStatus(state, actions[i], icoRequestId).status) >
          -1
        )
          return true
      }

      // no statues found
      return false
    } else {
      // AND Loop
      for (let i = 0; i < actions.length; i++) {
        // Not one of the statuses
        if (
          statuses.indexOf(
            getStatus(state, actions[i], icoRequestId).status
          ) === -1
        )
          return false
      }

      // Has all statuses
      return true
    }
  }
}

/**
 * Gets all of the request based on a current action and status
 * @param {string} action
 * @param {array} statuses
 */
function createAllActionStatusSelector(action, statuses) {
  return (state) => {
    const allRequest = state[action] || {}
    let result = []
    for (let key in allRequest) {
      let curRequest = allRequest[key]
      if (statuses.indexOf(curRequest.status) > -1) result.push(allRequest[key])
    }

    result.sort((a, b) => {
      return a.icoRequestId - b.icoRequestId
    })

    return result
  }
}

export function createActionStatusSelector(action) {
  return (state, icoRequestId) => {
    return getStatus(state, action, icoRequestId)
  }
}

/**
 * @description Gets all of the request that are currently loading
 */
export function createAllLoadingActionSelector(action) {
  return createAllActionStatusSelector(action, [req.LOADING])
}

export function createIsPreviouslyLoadedSelector(action) {
  return (state, icoRequestId) => {
    const actionStatus = getStatus(state, action, icoRequestId)
    return (actionStatus.oldStatuses || {})[req.SUCCESS]
  }
}

export function createIsLoadingSelector(actions, op?) {
  return createIsStatusSelector(actions, [req.LOADING], op)
}

export function createIsLoadingReqSelector(actions) {
  return createIsStatusSelector(actions, [req.LOADING, req.REQUEST], 'or')
}

export function createIsRequestSelector(actions, op?) {
  return createIsStatusSelector(actions, [req.REQUEST], op)
}

export function createIsCompleteSelector(actions, op?) {
  return createIsStatusSelector(
    actions,
    [req.COMPLETE, req.SUCCESS, req.FAILURE],
    op || 'or'
  )
}

export function createIsFailureSelector(actions, op?) {
  return createIsStatusSelector(actions, [req.FAILURE], op)
}

export function createIsSuccessSelector(actions, op?) {
  return createIsStatusSelector(actions, [req.SUCCESS], op)
}

function getActionStatusObject(state, actionType, icoRequestId) {
  let statusResult = state[actionType]
  if (statusResult && statusResult[icoRequestId])
    statusResult = statusResult[icoRequestId]

  return statusResult
}

function getStatus(state, actionType, icoRequestId) {
  let statusResult
  let status
  let progress
  let result
  let oldStatuses
  if (icoRequestId === undefined) {
    statusResult = getActionStatusObject(state, actionType) || {}
    oldStatuses = statusResult.oldStatuses
    if (statusResult.status) {
      // Get the status directly because the request isn't mapped by requestId
      status = statusResult.status
      progress = statusResult.progress
    } else {
      // Otherwise search the whole map and do an aggregate
      let total = 0
      let failureCount = 0
      let successCount = 0
      let loadingCount = 0
      progress = 0
      for (let key in statusResult) {
        let curStatus = statusResult[key]
        if (curStatus) {
          total++
          if (curStatus.status === req.LOADING) {
            status = req.LOADING
            loadingCount++
            progress += curStatus.progress || 0
          } else if (curStatus.status === req.FAILURE) {
            failureCount++
          } else if (curStatus.status === req.SUCCESS) {
            successCount++
          }
        }
      }

      if (total) {
        if (successCount === total) status = req.SUCCESS
        else if (failureCount === total) status = req.FAILURE
        else progress /= loadingCount
      }
    }
  } else {
    // Get the status by icoRequestId
    statusResult = getActionStatusObject(state, actionType, icoRequestId) || {}
    status = statusResult.status
    progress = statusResult.progress
    result = statusResult.result
    oldStatuses = statusResult.oldStatuses
  }

  return {
    status,
    progress,
    result,
    icoRequestId,
    oldStatuses,
  }
}

function isInSync(state, actionTypes, icoRequestId, op) {
  if (typeof actionTypes === 'string') actionTypes = [actionTypes]

  op = (op ? op : 'or').toLocaleLowerCase()

  if (op === 'or') {
    for (let i = 0; i < actionTypes.length; i++) {
      let result = state[actionTypes[i]]

      if (typeof result === 'object') result = result[icoRequestId]

      if (result === true) return true
    }

    return false
  } else {
    for (let i = 0; i < actionTypes.length; i++) {
      let result = state[actionTypes[i]]

      if (typeof result === 'object') result = result[icoRequestId]

      if (result === false) return false
    }

    return true
  }
}

export function createRequestSync(actions, op) {
  // Makes sense to put the in sync selector and in sync status clearing in the same place
  // Typically clearSync would go in _actions separately, but this is easier to maintain
  return {
    isInSync: (state, icoRequestId) => {
      return isInSync(state, actions, icoRequestId, op)
    },
    clearSync: (icoRequestId) => {
      return {
        type: CLEAR_REQUEST_SYNC,
        payload: {
          type: actions,
          source: {
            icoRequestId,
          },
        },
        source: {
          icoRequestId,
        },
      }
    },
  }
}

function getIcoRequestID(source: any, params: any) {
  source = source || {}
  params = params || {}

  let icoRequestId: number | string | (number | string)[] = ''

  if (source.icoRequestId !== undefined) icoRequestId = source.icoRequestId
  else if (params.icoRequestId !== undefined) icoRequestId = params.icoRequestId
  else if (source.icoRequestID !== undefined) icoRequestId = source.icoRequestID
  else if (params.icoRequestID !== undefined) icoRequestId = params.icoRequestID

  return icoRequestId
}
