import * as React from 'react'
import { DashboardAlertInboxModel } from './DashboardModels'

export const DashboardAlertsSection = ({
  alertInboxes,
}: {
  alertInboxes?: DashboardAlertInboxModel[]
}) => {
  const totalInboxes = alertInboxes?.length
  let totalActiveAlerts = 0
  let totalInactiveAlerts = 0
  let totalIssuesCreatedFromAlerts = 0

  alertInboxes?.forEach((inbox) => {
    if (inbox.IsActiveYN === 'Y') {
      totalActiveAlerts += 1
    } else {
      totalInactiveAlerts += 1
    }
    totalIssuesCreatedFromAlerts += inbox.IssuesCreatedFromAlerts
  })

  return (
    <div
      style={{
        display: 'flex',
        width: '100%',
        height: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '20px',
      }}
    >
      <div
        style={{
          display: 'flex',
          width: '100%',
          height: '100%',
          justifyContent: 'space-around',
          flexDirection: 'column',
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          <div>Inboxes</div>
          <div>{totalInboxes && totalInboxes > 0 ? totalInboxes : 0}</div>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          <div>Active Inboxes</div>
          <div>
            {totalActiveAlerts && totalActiveAlerts > 0 ? totalActiveAlerts : 0}
          </div>
        </div>

        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          <div>Inactive Inboxes</div>
          <div>
            {totalInactiveAlerts && totalInactiveAlerts > 0
              ? totalInactiveAlerts
              : 0}
          </div>
        </div>

        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          <div>Issues Created from Alerts</div>
          <div>{totalIssuesCreatedFromAlerts}</div>
        </div>
      </div>
    </div>
  )
}
