import React, { useState } from 'react'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core'
import { StakeholderListItem } from './StakeholderListItem'
import StakeholderDeleteConfirmation from './StakeholderDeleteConfirmation'
import classes from './StakeholderList.module.scss'
import { StretchBody } from '../Layouts/StretchLayout'
import { StakeholderCreateAndEditDialog } from './StakeholderCreateAndEditDialog'

interface Props {
  stakeholderIds: Array<number>
  mobile: boolean
  workspaceID: number
  IssueID: number
  openNotifications: (stakeholderID: number) => void
}

export const StakeholderList = ({
  stakeholderIds = [],
  mobile = false,
  workspaceID,
  IssueID,
  openNotifications,
}: Props) => {
  const [stakeholderToDelete, setStakeholderToDelete] = useState<null | number>(
    null
  )
  const [stakeholderToEdit, setStakeholderToEdit] = useState<number>()
  return (
    <>
      <StretchBody className="overflow-auto">
        <TableContainer className={classes.root} style={{ maxHeight: '100vh' }}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell className={classes.nameColumn}>Name</TableCell>
                <TableCell className={classes.responsibleColumn}>
                  Responsible
                </TableCell>
                <TableCell className={classes.lastNotifiedColumn}>
                  Last Notified
                </TableCell>
                <TableCell className={classes.actionsColumn}>&nbsp;</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {stakeholderIds.map((id) => (
                <StakeholderListItem
                  key={id}
                  StakeholderID={id}
                  IssueID={IssueID}
                  onDeleteClick={(stakeholderSelected: number) =>
                    setStakeholderToDelete(stakeholderSelected)
                  }
                  onEditClick={(stakeholderSelected: number) =>
                    setStakeholderToEdit(stakeholderSelected)
                  }
                  openNotifications={openNotifications}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </StretchBody>

      {stakeholderToDelete && (
        <StakeholderDeleteConfirmation
          open={stakeholderToDelete ? true : false}
          IssueStakeholderID={stakeholderToDelete}
          cancel={() => setStakeholderToDelete(null)}
        />
      )}
      {stakeholderToEdit && (
        <StakeholderCreateAndEditDialog
          isOpen={stakeholderToEdit ? true : false}
          IssueID={IssueID}
          StakeholderID={stakeholderToEdit}
          onCancel={() => setStakeholderToEdit(undefined)}
          workspaceID={workspaceID}
        />
      )}
    </>
  )
}
