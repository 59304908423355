// @ts-nocheck
// TODO: Typescript
import React, { Component, Fragment } from 'react'
import { AddButton } from '../Shared/Buttons'
import { IssueFilterAdvancedTrigger } from '../Issue/IssueFilterAdvancedTrigger'
import { Parens } from '../Shared/Parens'
import { ErrorMessageBase } from './ErrorMessageBase'
import PropTypes from 'prop-types'

/**
 * @extends {Component<Props, {}>}}
 */
export class IssuesNotFoundMessage extends Component {
  render() {
    const { className } = this.props
    return (
      <ErrorMessageBase
        className={className}
        primary={
          <Fragment>
            <div>No issues found.</div>
            <p>
              Please check your filter{' '}
              <Parens>
                <IssueFilterAdvancedTrigger disabled={true} />
              </Parens>{' '}
              settings above or create{' '}
              <Parens>
                <AddButton size="small" />
              </Parens>{' '}
              a new issue below.
            </p>
          </Fragment>
        }
      />
    )
  }
}

IssuesNotFoundMessage.propTypes = {
  className: PropTypes.string,
}
