import {
  faExclamationTriangle,
  faRight,
  faUser,
} from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as React from 'react'
import { Spacer } from '../../Shared/Spacer'
import { useHistory } from 'react-router'
import classes from './DashboardReportsAndActivity.module.scss'

export const DashboardReportsAndActivity = () => {
  const history = useHistory()

  return (
    <div
      style={{
        height: '100%',
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        color: '#3e91c5',
      }}
    >
      <div
        className={classes.clickableSection}
        onClick={() => history.push('/Reports/StakeholderInfluencer')}
      >
        <FontAwesomeIcon
          size={'2x'}
          icon={faUser}
          style={{ color: '#3e91c5' }}
        />
        <Spacer />
        <div style={{ fontSize: '16px' }}>Stakeholder</div>
      </div>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <div
          style={{
            borderLeft: '1px solid lightGrey',
            height: '80%',
            display: 'flex',
            alignItems: 'center',
          }}
        />
      </div>
      <div
        className={classes.clickableSection}
        onClick={() => history.push('/Reports/AfterAction')}
      >
        <FontAwesomeIcon
          size={'2x'}
          icon={faRight}
          style={{ color: '#3e91c5' }}
        />
        <Spacer />
        <div style={{ fontSize: '16px' }}>After Action</div>
      </div>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <div
          style={{
            borderLeft: '1px solid lightGrey',
            height: '80%',
            display: 'flex',
            alignItems: 'center',
          }}
        />
      </div>
      <div
        className={classes.clickableSection}
        onClick={() => history.push('/UserReports/ReportsFormsSent')}
      >
        <FontAwesomeIcon
          icon={faExclamationTriangle}
          size={'2x'}
          style={{ color: '#3e91c5' }}
        />
        <Spacer />
        <div style={{ fontSize: '16px' }}>User</div>
      </div>
    </div>
  )
}
