// @ts-nocheck
// TODO: Typescript
import React, { Component } from 'react'
import { UploadThumbnail } from './UploadThumbnail'
import { icoFetch } from '../_utils/fetchUtils'
import { Paper, CircularProgress } from '@material-ui/core'
import { cx } from '../_utils/objectUtils'
import classes from './ArtifactContentViewer.module.scss'
import { LazyLog } from 'react-lazylog'
import { getDriveItemContent } from '../MicrosoftTeams/GraphService'
import { getBase64Src } from '../_utils/fileUtils'
import PDFViewer from '../Shared/PDFViewer'

// Text based extensions that need to be loaded in a custom way
const textBasedExts = ['json', 'svg']
const loadingStyle = { width: 80, height: 80, background: 'transparent' }

export class ArtifactContentViewer extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isLoading: !this.isNormalLink() && props.downloadLink ? true : false,
    }
    this._compMounted = false
  }

  componentDidMount() {
    this._compMounted = true
    const { downloadLink } = this.props
    let { ArtifactExt, mediaType, ExternalStorage } = this.props
    mediaType = (mediaType || '').toLocaleLowerCase()
    ArtifactExt = (ArtifactExt || '').toLocaleLowerCase()
    // Use the regular download link. No additional logic is required
    if (this.isNormalLink() || !downloadLink) return

    if (this.isTextBased()) {
      icoFetch(downloadLink)
        .then((response) => {
          return response.text()
        })
        .then((loadedContent) => {
          if (!this._compMounted) return

          this.setState({
            loadedContent,
            isLoading: false,
          })
        })
        .catch((ex) => {
          if (!this._compMounted) return

          this.setState({ isLoading: false })
        })
    } else if (
      ExternalStorage === 'MicrosoftTeams' &&
      (this.isAllowedExternalMedia() || this.isTextBased())
    ) {
      getDriveItemContent(downloadLink)
        .then((loadedContent) => {
          if (!this._compMounted) return
          this.setState({
            loadedContent: getBase64Src({
              type: mediaType + '/' + ArtifactExt,
              content: loadedContent,
            }),
            isLoading: false,
          })
        })
        .catch((err) => {
          const errorJson = err.body ? JSON.parse(err.body) : {}
          const { message } = errorJson
          if (!this._compMounted) return

          this.setState({
            isLoading: false,
            error: message,
          })
        })
    } else if (ExternalStorage !== 'MicrosoftTeams') {
      // Binary data
      var oReq = new XMLHttpRequest()
      oReq.open('GET', downloadLink, true)
      oReq.responseType = 'arraybuffer'

      oReq.onload = (oEvent) => {
        if (!this._compMounted) return
        if (oReq.responseType === 'arraybuffer') {
          var arrayBuffer = oReq.response
          this.setState({
            //loadedContent: getBase64Src({ type: mediaType + '/' + ArtifactExt, content: arrayBufferToBase64(arrayBuffer) }),
            loadedContent: arrayBuffer,
            isLoading: false,
          })
        }
      }

      oReq.onerror = (ex) => {
        console.log(ex)
      }

      oReq.send(null)
    }
  }

  componentWillUnmount() {
    this._compMounted = false
  }

  isTextBased() {
    return ArtifactContentViewer.isTextBased(this.props)
  }

  isNormalLink() {
    return ArtifactContentViewer.isNormalLink(this.props)
  }

  // The image has to be loaded in a custom way
  isCustomImage() {
    let { mediaType, ExternalStorage } = this.props
    mediaType = (mediaType || '').toLocaleLowerCase()
    return ExternalStorage === 'MicrosoftTeams' && mediaType === 'image'
  }

  isAllowedExternalMedia() {
    let { mediaType } = this.props
    mediaType = (mediaType || '').toLocaleLowerCase()
    return ['image', 'audio', 'video'].indexOf(mediaType) > -1
  }

  render() {
    // Doesn't render how it should
    if (this.isNormalLink())
      return (
        <img
          style={{ width: '100%', height: 'auto' }}
          src={this.props.downloadLink}
          alt={this.props.downloadLink}
        ></img>
      )

    const { ArtifactType, style, className } = this.props
    let { mediaType, ArtifactExt, downloadLink } = this.props
    let result
    mediaType = (mediaType || '').toLocaleLowerCase()
    ArtifactExt = (ArtifactExt || '').toLocaleLowerCase()
    downloadLink = this.state.loadedContent || downloadLink
    if (this.state.isLoading)
      return (
        <Paper
          elevation={0}
          className={cx(classes.textViewer, className, classes.loading)}
          style={loadingStyle}
        >
          <CircularProgress disableShrink={true} />
        </Paper>
      )

    if (this.state.error) {
      result = (
        <div className="ril__errorContainer">
          <div>{this.state.error}</div>
        </div>
      )
    } else if (mediaType === 'audio') {
      result = downloadLink ? (
        <audio
          ref={(el) => setStreamContent(el, downloadLink, ArtifactType)}
          className={className}
          controls
          style={style}
        >
          {!(downloadLink instanceof ArrayBuffer) && (
            <source src={downloadLink} type={ArtifactType} />
          )}
          <span className={className}>
            <UploadThumbnail
              type={mediaType}
              ext={ArtifactExt}
              ArtifactType={ArtifactType}
            />
            <div>Audio playback not available.</div>
          </span>
        </audio>
      ) : null
    } else if (mediaType === 'video') {
      result = downloadLink ? (
        <video
          ref={(el) => setStreamContent(el, downloadLink, ArtifactType)}
          className={className}
          controls
          style={style}
        >
          {!(downloadLink instanceof ArrayBuffer) && (
            <source src={downloadLink} type={ArtifactType} />
          )}
          <span className={className}>
            <UploadThumbnail
              type={mediaType}
              ext={ArtifactExt}
              ArtifactType={ArtifactType}
            />
            <div>Video playback not available.</div>
          </span>
        </video>
      ) : null
    } else if (this.isCustomImage())
      result = downloadLink ? (
        <img
          className={className}
          src={downloadLink}
          style={style}
          alt="From Microsoft"
        />
      ) : null
    else if (
      mediaType === 'text' ||
      ArtifactExt === 'json' ||
      ArtifactExt === 'svg'
    ) {
      result = downloadLink ? (
        <Paper className={cx(classes.textViewer, className)} style={style}>
          {this.state.loadedContent && (
            <LazyLog text={this.state.loadedContent} />
          )}
        </Paper>
      ) : null
    } else if (ArtifactExt === 'pdf') {
      return (
        <PDFViewer
          className={classes.pdfViewer}
          data={this.state.loadedContent}
        />
      )
    } else {
      result = (
        <div className={cx(classes.unsupported, className)}>
          <UploadThumbnail ext={ArtifactExt} />
          <div>File not available for preview.</div>
        </div>
      )
    }

    return result
  }
}

ArtifactContentViewer.isTextBased = ({ ArtifactExt, mediaType }) => {
  mediaType = (mediaType || '').toLocaleLowerCase()
  return textBasedExts.indexOf(ArtifactExt) > -1 || mediaType === 'text'
}

ArtifactContentViewer.isNormalLink = ({
  ArtifactExt,
  mediaType,
  ExternalStorage,
}) => {
  mediaType = (mediaType || '').toLocaleLowerCase()
  ArtifactExt = (ArtifactExt || '').toLocaleLowerCase()
  return (
    mediaType === 'image' &&
    !ArtifactContentViewer.isTextBased({ ArtifactExt, mediaType }) &&
    ExternalStorage !== 'MicrosoftTeams'
  )
}

function setStreamContent(
  el: HTMLVideoElement,
  content: ArrayBuffer | string | MediaStream,
  type: string
) {
  if (!el) return

  let result: MediaStream | Blob
  if (content instanceof ArrayBuffer) {
    result = new Blob([content], {
      type,
    })
  } else if (content instanceof MediaStream) {
    result = content
  }

  // If the stream is present try to set it to the video
  if (result) {
    if ('srcObject' in el && !result instanceof Blob) {
      el.srcObject = result
    } else {
      // Avoid using this in new browsers, as it is going away.
      el.src = URL.createObjectURL(result)
    }
  }
}
