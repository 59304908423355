// @ts-nocheck
// TODO: Typescript
import React, { Component } from 'react'
import { InvalidAccountMessage } from '../ErrorMessages/InvalidAccountMessage'
import { MasterPageLayout } from './MasterPageLayout'

/**
 * @extends {Component<Props, {}>}}
 */
export class InvalidAccountPage extends Component {
  render() {
    return (
      <MasterPageLayout history={this.props.history}>
        <InvalidAccountMessage className="p-2" page={true} />
      </MasterPageLayout>
    )
  }
}
