// @ts-nocheck
// TODO: Typescript
import { generateActions } from '../_utils/reduxUtils'
import {
  LoginConstants,
  LogoutConstants,
  AuthConstants,
  FETCH_REQUEST_TOKEN,
} from './_constants'

export const fetchRequestToken = generateActions(FETCH_REQUEST_TOKEN)

export function login(credentials) {
  return { type: LoginConstants.LOGIN, credentials }
}

export function loginLoading() {
  return { type: LoginConstants.LOADING }
}

export function loginSuccess(initialState) {
  return { type: LoginConstants.SUCCESSFUL, initialState }
}

export function loginSSO(credentials) {
  return { type: LoginConstants.LOGIN_SSO, credentials }
}

export function logoutSSO(initialState) {
  return { type: LogoutConstants.LOGOUT_SSO, initialState }
}

export function loginFailure(error) {
  return {
    type: LoginConstants.FAILURE,
    payload: {
      error,
    },
  }
}

export function redirectFromUnauthorized() {
  return { type: AuthConstants.REDIRECT_FROM_UNAUTHORIZED }
}

export function loginClearStatus() {
  return {
    type: LoginConstants.CLEAR,
    payload: {
      error: '',
    },
  }
}

export function logout() {
  return { type: LogoutConstants.LOGOUT }
}

export function logoutLoading() {
  return { type: LogoutConstants.LOADING }
}

export function logoutSuccess() {
  return { type: LogoutConstants.SUCCESSFUL }
}

// User is signed in but not authorized to use it
export function unAuthorized() {
  return { type: AuthConstants.USER_UNAUTHORIZED }
}

export function unAuthenticated() {
  return { type: AuthConstants.USER_UNAUTHENTICATED }
}

export function authenticated() {
  return { type: AuthConstants.USER_AUTEHNTICATED }
}

export function refreshAuthentication() {
  return { type: AuthConstants.REFRESH_AUTHENTICATION }
}

export function refreshAuthenticationSuccessful() {
  return { type: AuthConstants.REFRESH_AUTHENTICATION_SUCCESSFUL }
}

export function sessionWarningOpened() {
  return { type: AuthConstants.SESSION_WARNING_OPENED }
}

export function sessionWarningClosed() {
  return { type: AuthConstants.SESSION_WARNING_CLOSED }
}
