//export const server = 'http://localhost:16919'; // use this for windows development
//export const server = 'https://localhost:5001'; // use this for mac development
export const server = ''
export const webAPIUrl = `${server}/api`
export const googleApiKey = `AIzaSyBUj0RewuADyP6viCfHyfezUXH0q-BGYPY`
export const embedGoogleMapsSettings = {
  APIKey: googleApiKey,
  APIUrl: 'https://www.google.com/maps/embed/v1/',
}
// old authenticator - keep in case we go back?
// export const authSettings = {
//   domain: 'dev-vcnjddq4.us.auth0.com',
//   client_id: 'uIgaH0OGz54mTkTuS9mHcB84qYtcac8C',
//   redirect_uri: window.location.origin + '/signin-callback',
//   scope: 'openid profile QandAAPI email',
//   audience: 'https://qanda',
// }
