// @ts-nocheck
// TODO: Typescript
import { buffers, eventChannel, END } from 'redux-saga'
import {
  getRequestToken,
  icoFetch,
  setRequestToken,
} from '../_utils/fetchUtils'
import { logger } from '../_utils/logging'
import { JsonParseSafe } from '../_utils/objectUtils'

export default function createFileUploadChannel(url, file, otherData) {
  return eventChannel((emitter) => {
    const uploadResult = doUpload(emitter, url, file, otherData, true)

    return uploadResult
  }, buffers.sliding(2))
}

function doUpload(emitter, url, file, otherData, retry = false) {
  const xhr = new XMLHttpRequest()
  const { icoRequestId } = (otherData || {}).source || {}

  const onProgress = (event) => {
    if (event.lengthComputable) {
      const progress = Math.round((event.loaded / event.total) * 100)
      emitter({ progress, icoRequestId })
    }
  }

  const onFailure = (response) => {
    emitter({
      err: new Error((response || {}).ErrorMessage || 'Upload failed'),
      icoRequestId,
    })
    emitter(END)
  }

  xhr.upload.addEventListener('progress', onProgress)
  xhr.upload.addEventListener('error', onFailure)
  xhr.upload.addEventListener('abort', onFailure)
  xhr.onreadystatechange = () => {
    const { readyState, status } = xhr
    if (readyState === 4) {
      if (status === 200) {
        emitter({ success: true, icoRequestId })
        emitter(END)
      } else {
        if (status === 400) {
          if (retry) {
            logger.warning('Fetching csrf token for upload')
            icoFetch('/api/CookieAuth/RequestToken')
              .then((response) => {
                if (response.ok) return response.json()
              })
              .then((jsonToken) => {
                setRequestToken(jsonToken.returnData)

                // Retry the request with the new token
                doUpload(emitter, url, file, otherData, false)
                return jsonToken
              })
          } else
            logger.error(
              'Cannot upload file. The antiforgery token is either missing or invalid.'
            )
        }

        if (!retry && status === 400) {
          const result = JsonParseSafe(xhr.responseText)
          onFailure(
            typeof result === 'string' ? { ErrorMessage: result } : result
          )
        }
      }
    }
  }

  const formData = new FormData()
  if (file) formData.append('file', file)

  formData.append('data', JSON.stringify(otherData))

  xhr.open('POST', url, true)
  xhr.setRequestHeader('X-CSRF-TOKEN', getRequestToken())
  xhr.send(formData)

  return () => {
    xhr.upload.removeEventListener('progress', onProgress)
    xhr.upload.removeEventListener('error', onFailure)
    xhr.upload.removeEventListener('abort', onFailure)
    xhr.onreadystatechange = null
    xhr.abort()
  }
}
