import dayjs from 'dayjs'
import React, { useCallback, useEffect, useState } from 'react'
import { MeetingDialog, ScheduledMeeting } from '../Conference/MeetingDialog'
import { Button } from '../Shared/Buttons'
import { CircularProgress } from '../Shared/LoadingBackdrop'
import { Spacer } from '../Shared/Spacer'
import { addNotification } from '../_actions'
import { icoPost } from '../_utils/fetchUtils'
import { useAppDispatch } from '../_utils/reactReduxHooks'
import colorDefinitions from '../ThemeProvider/ColorDefinitions.module.scss'

export const CreateNewIssueForm = ({
  selectedOrg,
  handleFormSubmit,
  handleDialogClose,
}: {
  selectedOrg: any
  handleFormSubmit: any
  handleDialogClose: any
}) => {
  const [severityList, setSeverityList] = useState<
    Array<{
      LevelID: number
      LevelSequence: number
      LevelName: string
      LevelRGB: string
    }>
  >([])

  const dispatch = useAppDispatch()

  const [currentMeeting, setCurrentMeeting] = React.useState<
    ScheduledMeeting | undefined
  >()
  const [selectedSeverity, setSelectedSeverity] = useState<string>()
  const [newIssueName, setNewIssueName] = useState<string>()
  const [newIssueDescription, setNewIssueDescription] = useState<string>()
  const [meetingOpen, setMeetingOpen] = React.useState(false)

  const closeMeeting = () => {
    setMeetingOpen(false)
  }

  const handleSeverityClick = useCallback((evt: any) => {
    setSelectedSeverity(evt.target.value)
  }, [])

  const createIssue = useCallback(async (): Promise<any> => {
    const pdata = {
      OrgID: selectedOrg.OrgID,
      IssueName: newIssueName,
      Description: newIssueDescription,
      severityLevel: selectedSeverity,
      initialConferenceJSON: currentMeeting
        ? JSON.stringify(currentMeeting)
        : null,
    }

    return await icoPost('/api/Issue/IssueCreate', pdata)
  }, [
    currentMeeting,
    newIssueDescription,
    newIssueName,
    selectedOrg.OrgID,
    selectedSeverity,
  ])

  useEffect(() => {
    icoPost(`api/Issue/Severities`, { orgId: selectedOrg.OrgID })
      .then(async (response) => {
        const data = await response.json()

        // check for error response
        if (!response.ok) {
          // get error message from body or default to response status
          const error = (data && data.message) || response.status
          return Promise.reject(error)
        }

        if (data.error) {
          //todo do a dispatch instead
          dispatch(addNotification({ message: data.error, type: 'error' }))
        } else {
          setSeverityList(data)
        }
      })
      .catch((error) => {
        console.error('There was an error!', error)
      })
  }, [selectedOrg.OrgID, dispatch])

  const handleCreateNewIssue = useCallback(async () => {
    if (!selectedSeverity) {
      dispatch(
        addNotification({
          message: 'Severity for Issue was not selected.',
          type: 'error',
        })
      )
    } else if (!(newIssueName && newIssueDescription)) {
      dispatch(
        addNotification({
          message: 'Issue must have a Title and Description.',
          type: 'error',
        })
      )
    } else {
      handleFormSubmit()
      await createIssue()
      handleDialogClose()
    }
    return false
  }, [
    selectedSeverity,
    createIssue,
    dispatch,
    newIssueDescription,
    newIssueName,
    handleDialogClose,
    handleFormSubmit,
  ])

  return (
    <>
      <Spacer spaceParam={'large'} />
      <span
        style={{
          display: 'block',
          fontWeight: '500',
          fontSize: '22px',
          color: colorDefinitions.iccBlack,
        }}
      >
        Activate Team
      </span>
      <Spacer />
      <p
        style={{
          marginLeft: '0',
          marginBottom: '0',
          width: '100%',
        }}
      >
        Title for Issue:
      </p>
      <p style={{ marginTop: '3px' }}>
        <input
          type="text"
          value={newIssueName}
          onChange={(e) => setNewIssueName(e.target.value)}
          style={{
            width: '100%',
            border: '1px solid #6e6e6e',
            padding: '5px 8px',
            margin: '0',
          }}
        />
      </p>
      <p
        style={{
          marginLeft: '0',
          marginBottom: '0',
          width: '100%',
        }}
      >
        Description of Issue:
      </p>
      <p style={{ marginTop: '3px' }}>
        <textarea
          value={newIssueDescription}
          onChange={(e) => setNewIssueDescription(e.target.value)}
          style={{
            width: '100%',
            border: '1px solid #6e6e6e',
            padding: '5px 8px',
            margin: '0',
          }}
        />
      </p>
      <p
        style={{
          marginLeft: '0',
          marginBottom: '3px',
          width: '100%',
        }}
      >
        Severity Level:
      </p>
      {severityList.length <= 0 && <CircularProgress />}
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          justifyContent: 'flex-start',
        }}
      >
        {severityList.map((srv) => (
          <div
            key={'div_' + srv.LevelID}
            style={{
              backgroundColor: srv.LevelRGB,
              margin: '5px 2px',
              padding: '5px',
              flexBasis: '45%',
              maxWidth: '155px',
              display: 'inline-block',
              alignContent: 'center',
              borderRadius: '7.5px',
            }}
          >
            <input
              type="radio"
              name="icc_severity_button"
              value={srv.LevelName}
              onChange={handleSeverityClick}
              style={{
                marginLeft: '5px',
                verticalAlign: 'center',
                width: '1.25rem',
                height: '1.25rem',
              }}
            />
            <label
              style={{
                fontWeight: 'bold',
                color: '#fff',
                display: 'inline-block',
                maxWidth: '115px',
                marginLeft: '10px',
                marginRight: '0',
                marginBottom: '0',
                marginTop: '0',
                backgroundColor: 'transparent',
              }}
            >
              {srv.LevelName}
            </label>
          </div>
        ))}
      </div>

      <br />
      <Button onClick={() => setMeetingOpen(true)} color="link">
        Schedule Meeting
      </Button>
      {currentMeeting && (
        <div style={{ fontSize: '12px', color: 'grey' }}>
          <div> {currentMeeting.Link} </div>
          <div style={{ display: 'flex' }}>
            <div>{dayjs(currentMeeting.startDate).format('MMM-DD-YYYY')}</div>
            <Spacer />
            <div>
              {dayjs.utc(currentMeeting.startDate).local().format('h:mmA')}
              &nbsp;-&nbsp;
              {dayjs.utc(currentMeeting.endDate).local().format('h:mmA')}
            </div>
          </div>
        </div>
      )}
      <Spacer />
      <p
        style={{
          textAlign: 'center',
        }}
      >
        <Button id="submit" onClick={handleCreateNewIssue}>
          Activate Team
        </Button>
      </p>
      <MeetingDialog
        open={meetingOpen}
        currentMeeting={currentMeeting}
        onCancel={closeMeeting}
        onSubmit={(meeting: ScheduledMeeting) => setCurrentMeeting(meeting)}
        orgId={selectedOrg.OrgID}
        orgName={selectedOrg.OrgName}
      />
    </>
  )
}
