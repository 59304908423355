// @ts-nocheck
// TODO: Typescript
import React from 'react'
import classes from './Parens.module.scss'
import { cx } from '../_utils/objectUtils'

export const Parens = ({
  children,
  id = undefined,
  className = undefined,
  ...other
}) => (
  <span {...other} className={cx(classes.root, className)}>
    {' '}
    {children}
  </span>
)
