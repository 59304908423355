import { Fragment } from 'react'
import * as React from 'react'
import { DenormalizedOrgTag } from './_models'
import OrgTag from './OrgTag'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classes from './OrgTagShared.module.scss'
import { alertIcon } from './_constants'

export function OrgTagOpt({
  TagName,
  TagType,
  TagTypePkGuid,
}: DenormalizedOrgTag) {
  function getTagSubName() {
    if (TagType === 'inbox') return 'Alert'
  }

  let subName = getTagSubName()

  return (
    <Fragment>
      <span>
        {TagName}
        {TagType === 'alert' && (
          <Fragment>
            <FontAwesomeIcon
              className={classes.AlertInfoIcon}
              icon={alertIcon}
            />
          </Fragment>
        )}
      </span>
      {subName && (
        <OrgTag
          TagType={TagType}
          TagTypePkGuid={TagTypePkGuid}
          TagName={subName}
          useTagName={true}
        />
      )}
    </Fragment>
  )
}
