import React, { useState, useEffect, Fragment } from 'react'
import { ConferenceInstructionDialog } from '../Conference/ConferenceInstructionDialog'
import { ContentViewer } from '../Shared/ContentViewer'
import { ConferenceAdd } from '../Conference/ConferenceAdd'
import {
  StretchLayout,
  StretchBody,
  StretchFooter,
} from '../Layouts/StretchLayout'
import { Button } from 'reactstrap'
import { List } from '@material-ui/core'
import { ConferenceListItem } from '../Conference/ConferenceListItem'
import {
  setGraphCalendarEventsWorkspace,
  fetchGraphCalendarEvents,
} from '../MicrosoftTeams/_actions'
import { selectMSCalendarEvents } from '../MicrosoftTeams/_selectors'
import { useAppDispatch, useAppSelector } from '../_utils/reactReduxHooks'

interface Props {
  IssueTeamWorkspaceID: number
  IssueID: number
  disabled: boolean
  mobile: boolean
}

export const WorkspaceMsTeamsConferenceViewer = ({
  IssueTeamWorkspaceID,
  IssueID,
  disabled,
  mobile = false,
}: Props) => {
  const [instructionDialog, setInstructionDialog] = useState<any>()
  const [conferenceDialog, setConferenceDialog] = useState(false)
  const dispatch = useAppDispatch()
  const events = useAppSelector(selectMSCalendarEvents)

  useEffect(() => {
    dispatch(setGraphCalendarEventsWorkspace(IssueTeamWorkspaceID))
    dispatch(fetchGraphCalendarEvents.request({ IssueTeamWorkspaceID }))
  }, [IssueTeamWorkspaceID, dispatch])

  const handleCloseInstructionDialog = () => {
    if (disabled) return

    setInstructionDialog(null)
  }

  const handleCreateClick = () => {
    setConferenceDialog(true)
  }

  const handleCloseConferenceDialog = () => {
    setConferenceDialog(false)
  }

  return (
    <Fragment>
      <ContentViewer
        largeAbsolute={true}
        largeClassName="bg-white"
        headerTitle={'Create Meeting'}
        open={conferenceDialog}
        onClose={handleCloseConferenceDialog}
        isDialog={mobile}
      >
        <ConferenceAdd
          IssueTeamWorkspaceID={IssueTeamWorkspaceID}
          IssueID={IssueID}
          disabled={disabled}
          onSubmit={handleCloseConferenceDialog}
          mobile={mobile}
          isMsTeamsApp={true}
        />
      </ContentViewer>
      <StretchLayout>
        <StretchBody>
          <List className="p-1">
            {events.map((evt: any, index: number) => (
              <ConferenceListItem
                key={evt.id}
                ConferenceID={evt.id}
                localizeDates
                isMobileApp={mobile}
                IssueTeamWorkspaceID={IssueTeamWorkspaceID}
                onOpenDialog={() => setInstructionDialog(evt)}
                onClick={() => setInstructionDialog(evt)}
                disabled={false}
                active={false}
                Scheduled={evt?.start?.dateTime}
                ScheduledEnd={evt?.end?.dateTime}
                Instructions={evt?.body?.content}
                Link={(evt.onlineMeeting || {}).joinUrl}
                msTeamsInfo={
                  evt.recurrence
                    ? formatRecurrenceInfo(evt.recurrence)
                    : undefined
                }
                Title={evt.subject}
                isMsTeams
              />
            ))}
          </List>
          <ConferenceInstructionDialog
            ConferenceID={0}
            Instructions={instructionDialog?.body?.content}
            Scheduled={instructionDialog?.start?.dateTime}
            open={!!instructionDialog}
            cancel={handleCloseInstructionDialog}
            confirm={handleCloseInstructionDialog}
            sanitizeInstructions={false}
          />
        </StretchBody>
        <StretchFooter className="p-3">
          <Button
            onClick={handleCreateClick}
            color="primary"
            disabled={disabled}
          >
            Add
          </Button>
        </StretchFooter>
      </StretchLayout>
    </Fragment>
  )
}

interface recurrence {
  pattern?: {
    dayOfMonth: number
    daysOfWeek: Array<
      | 'sunday'
      | 'monday'
      | 'tuesday'
      | 'wednesday'
      | 'thursday'
      | 'friday'
      | 'saturday'
    >
    month: 6
    type: string
  }
  range?: {
    startDate: string
    type: 'noEnd'
    '@odata.type': 'microsoft.graph.recurrenceRange'
  }
  '@odata.type': 'microsoft.graph.patternedRecurrence'
}
const months = [
  '',
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]
const formatRecurrenceInfo = (recurrence: recurrence) => {
  let information = ''
  let recurs = ''
  if (recurrence.pattern) {
    recurs = recurrence.pattern.type
    let dayAsStringLastDigit = recurrence.pattern.dayOfMonth
      .toString()
      .charAt(recurrence.pattern.dayOfMonth.toString().length - 1)
    let dayOfMonthString = recurrence.pattern.dayOfMonth.toString()
    if (dayAsStringLastDigit === '1') {
      dayOfMonthString += 'st'
    } else if (dayAsStringLastDigit === '2') {
      dayOfMonthString += 'nd'
    } else if (dayAsStringLastDigit === '3') {
      dayOfMonthString += 'rd'
    } else {
      dayOfMonthString += 'th'
    }
    switch (recurs) {
      case 'daily':
        information = 'Repeats daily.'
        break
      case 'weekly':
        information =
          'Repeats weekly on ' + recurrence.pattern.daysOfWeek.join(', ') + '.'
        break
      case 'absoluteMonthly':
        information = 'Repeats monthly on the ' + dayOfMonthString + '.'
        break
      case 'absoluteYearly':
        information = `Repeats yearly on ${
          months[recurrence.pattern.month]
        } the ${dayOfMonthString}.`
        break
    }
  }
  return information
}
