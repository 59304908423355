import React, { useCallback, useEffect, useState } from 'react'
import {
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core'
import {
  StretchBody,
  StretchHeader,
  StretchLayout,
} from '../Layouts/StretchLayout'
import classes from './StakeholderNotificationResults.module.scss'
import { Spacer } from '../Shared/Spacer'
import { icoFetch } from '../_utils/fetchUtils'
import { useIsMounted } from 'usehooks-ts'
import { CircularProgress } from '../Shared/LoadingBackdrop'
import { Cell, Label, Pie, PieChart, ResponsiveContainer } from 'recharts'
import { appDateTimeFormat } from '../_constants'
import dayjs from 'dayjs'
import { getLocalDate } from '../_utils/dateUtils'
import { DetermineChannels } from './StakeholderNotificationResults'
import { Button } from '../Shared/Buttons'
import { cx } from '../_utils/objectUtils'
import useDimensions from 'react-cool-dimensions'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRefresh } from '@fortawesome/pro-light-svg-icons'

type NotificationStatus = 'successful' | 'delivered' | 'bounced' | string

const legendMappings = [
  { status: 'delivered', color: '#167b3d' },
  { status: 'delivery', color: '#167b3d' },

  { status: 'success', color: '#167b3d' },
  { status: 'successful', color: '#167b3d' },
  { status: 'completed', color: '#167b3d' },
  { status: 'pending', color: '#1cb9d5' },
  { status: 'initiated', color: '#1cb9d5' },
  { status: 'in progress', color: '#1cb9d5' },
  { status: 'queued', color: '#1cb9d5' },
  { status: 'bounced', color: '#ebe00d' },
  { status: 'deferred', color: '#bc93a4' },
  { status: 'rejected', color: '#be386c' },
  { status: 'failed', color: '#c20a0a' },
  { status: 'expired', color: '#c20a0a' },
  { status: 'invalid', color: '#c20a0a' },
  { status: 'error', color: '#c20a0a' },
]
const statusMappings = [
  {
    status: 'delivered',
    lookupStatus: 'delivered_to_handset',
  },
  {
    status: 'invalid',
    lookupStatus: 'undeliverable_rejected_operator',
  },
  { status: 'delivered', lookupStatus: 'delivery' },
]

interface RecipientDetails {
  recipient?: string
  phone?: string
  FullName: string
  status: NotificationStatus
}

interface MessageDetail {
  mq_id: number
  CreatedUTC: string
  CreatedBy: string
  message_subject: string
  message_txt: string
  sendEmailYN: 'Y' | 'N'
  sendSmsYN: 'Y' | 'N'
  sendPushYN: 'Y' | 'N'
  sendTTSYN: 'Y' | 'N'
  ExtChannel_list: string
  group_list: string
  email_list: string
  phone_list: string
  details: {
    emailDetails?: Array<RecipientDetails>
    smsDetails?: Array<RecipientDetails>
    pushDetails?: Array<RecipientDetails>
    msTeamsDetails?: Array<RecipientDetails>
    extGroupsDetails?: Array<RecipientDetails>
    extScenariousDetails?: Array<RecipientDetails>
    ttsDetails?: Array<RecipientDetails>
  }
}

interface Props {
  isOpen: boolean
  onCancel: () => void
  mq_id: number
}

export const StakeholderNotificationResultsDetails = ({
  isOpen,
  onCancel,
  mq_id,
}: Props) => {
  const [messageDetail, setMessageDetail] = useState<MessageDetail>()
  const [isLoadingDetail, setIsLoadingDetail] = useState<boolean>(false)
  const [recipientsDetails, setRecipientsDetails] =
    useState<Array<RecipientDetails>>()
  const [filterValue, setFilterValue] = useState<Array<string>>([])
  const isMounted = useIsMounted()

  const { observe, width } = useDimensions({
    onResize: ({ observe, unobserve, width, height, entry }) => {
      // Triggered whenever the size of the target is changed...
      observe() // To re-start observing the current target element
    },
  })

  const getMessageDetail = useCallback(() => {
    setIsLoadingDetail(true)

    icoFetch(`api/Stakeholders/NotificationDetails/${mq_id}`)
      .then((res) => res.json())
      .then((data) => {
        if (data.Status !== 'Failure' && isMounted()) {
          setMessageDetail(data)
        }
      })
      .finally(() => {
        if (isMounted()) setIsLoadingDetail(false)
      })
  }, [mq_id, isMounted])

  useEffect(() => {
    getMessageDetail()
  }, [getMessageDetail])

  const renderHeader = (
    <div>
      {messageDetail && (
        <>
          <h6>{messageDetail?.message_subject}</h6>

          <div
            style={{
              border: '1px lightgrey solid',
              padding: '.8rem',
            }}
          >
            <div>{messageDetail?.message_txt}</div>
            <Spacer spaceParam={'large'} />
            {messageDetail?.group_list && (
              <div>Sent To Groups: {messageDetail?.group_list}</div>
            )}
            {messageDetail?.ExtChannel_list && (
              <div>Sent To Channels: {messageDetail?.ExtChannel_list}</div>
            )}
            <div>
              Sent by {messageDetail?.CreatedBy},&nbsp;
              {dayjs(getLocalDate(messageDetail?.CreatedUTC))
                .local()
                .format(appDateTimeFormat)}
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
              }}
            >
              <div>Using {DetermineChannels(messageDetail)}</div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  cursor: 'pointer',
                }}
                onClick={() => getMessageDetail()}
              >
                <FontAwesomeIcon icon={faRefresh} />
                <Spacer spaceParam={'small'} />
                <Button color="link">Refresh</Button>
              </div>
            </div>
          </div>
          {recipientsDetails && (
            <>
              <Spacer spaceParam={'large'} />
              <Button
                color="primary"
                outline
                onClick={() => {
                  setRecipientsDetails(undefined)
                  setFilterValue([])
                }}
              >
                Back To Details
              </Button>
              <Spacer />
              <FormControl className="form-control" style={{ height: '70px' }}>
                <InputLabel shrink>Status Filter</InputLabel>
                <Select
                  label="Status Filter"
                  input={<OutlinedInput label="Status" />}
                  multiple={true}
                  displayEmpty={true}
                  value={filterValue || []}
                  renderValue={() => filterValue.join(', ')}
                  onChange={(evt: any) => {
                    evt.preventDefault()
                    setFilterValue(evt.target.value)
                  }}
                  style={{ width: '100%', textTransform: 'capitalize' }}
                >
                  {aggregateDataByStatus(recipientsDetails).map(
                    (
                      status: { status: string; color: string; count: number },
                      id: number
                    ) => {
                      return (
                        <MenuItem
                          value={status.status}
                          key={id}
                          selected={filterValue?.includes(status.status)}
                        >
                          <Checkbox
                            color="primary"
                            checked={filterValue?.includes(status.status)}
                          />
                          <ListItemText
                            primary={status.status}
                            style={{ textTransform: 'capitalize' }}
                          />
                        </MenuItem>
                      )
                    }
                  )}
                </Select>
              </FormControl>
            </>
          )}
        </>
      )}
    </div>
  )

  const renderDetail = (
    details: Array<RecipientDetails> | undefined,
    type: string
  ) => (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-start',
          width: '100%',
          cursor: 'pointer',
          paddingBottom: '0.8rem',
        }}
        onClick={() => {
          if (details) {
            setRecipientsDetails(details)
            setFilterValue(
              aggregateDataByStatus(details).map((legend) => legend.status)
            )
          }
        }}
      >
        {!details ? (
          <div
            style={{
              height: '150px',
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'row',
              position: 'relative',
              padding: '1rem',
            }}
          >
            <h2 style={{ position: 'absolute', top: '0', left: '0' }}>
              {type}&nbsp;&nbsp;
              {DetermineChannels({
                sendEmailYN: type === 'Email' ? 'Y' : 'N',
                sendSmsYN: type === 'SMS' ? 'Y' : 'N',
                sendPushYN: type === 'Push' ? 'Y' : 'N',
                ExtChannel_list: type === 'MSTeams' ? 'MSTeams' : undefined,
              })}
            </h2>
            No recipients found for notification method&nbsp;
            {dayjs().diff(
              dayjs(getLocalDate(messageDetail?.CreatedUTC)).local(),
              'minute'
            ) < 15
              ? '(This was recently sent, you may need to wait a few minutes)'
              : ''}
          </div>
        ) : (
          <>
            <div
              style={{
                height: '150px',
                width: '150px',
                display: 'flex',
                alignItems: 'flexStart',
                justifyContent: 'flexStart',
                flexDirection: 'row',
                position: 'relative',
              }}
            >
              <h2
                style={{
                  position: 'absolute',
                  top: '0',
                  left: '0',
                  marginBottom: 'none',
                  whiteSpace: 'nowrap',
                  overflowX: 'visible',
                }}
              >
                {type}&nbsp;&nbsp;
                {DetermineChannels({
                  sendEmailYN: type === 'Email' ? 'Y' : 'N',
                  sendSmsYN: type === 'SMS' ? 'Y' : 'N',
                  sendPushYN: type === 'Push' ? 'Y' : 'N',
                  sendTTSYN: type === 'TTS' ? 'Y' : 'N',
                  ExtChannel_list: type === 'MSTeams' ? 'MSTeams' : undefined,
                })}
              </h2>
              <ResponsiveContainer
                height={125}
                width={125}
                style={{ paddingTop: '0.8rem', marginLeft: '-21px' }}
              >
                <PieChart>
                  <Pie
                    nameKey="name"
                    dataKey="count"
                    cx={'50%'}
                    cy={'50%'}
                    data={aggregateDataByStatus(details)}
                    fill="#82ca9d"
                    innerRadius="50%"
                  >
                    <Label
                      style={{
                        fontWeight: '500',
                        fontSize: '24px',
                      }}
                      value={details.length}
                      position="center"
                      fill="black"
                    />
                    {aggregateDataByStatus(details).map((data, index) => (
                      <Cell key={index} fill={data.color} />
                    ))}
                  </Pie>
                  {/* <Tooltip /> */}
                </PieChart>
              </ResponsiveContainer>
            </div>
            <Spacer />
            <div
              style={{
                width: '250px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
              }}
            >
              {aggregateDataByStatus(details).map((legend, index) => {
                return (
                  <div
                    className={classes.legend}
                    key={index}
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      cursor: 'pointer',
                      width: '100%',
                    }}
                    onClick={(e) => {
                      e.stopPropagation()
                      setRecipientsDetails(details)
                      setFilterValue([legend.status])
                    }}
                  >
                    <span
                      className={classes.legendColor}
                      style={{ backgroundColor: legend.color }}
                    ></span>
                    <span
                      style={{
                        textTransform: 'capitalize',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        overflowX: 'hidden',
                      }}
                    >
                      {legend.status}&nbsp;{'(' + legend.count + ')'}
                    </span>
                  </div>
                )
              })}
            </div>
            {width > 600 && (
              <>
                <Spacer />
                <TableContainer
                  style={{
                    maxHeight: '125px',
                    overflow: 'hidden',
                  }}
                  className={cx(classes.root)}
                >
                  <Table stickyHeader>
                    <TableHead>
                      <TableRow>
                        <TableCell className={classes.emailColumn}>
                          Sent To
                        </TableCell>
                        <TableCell className={classes.statusColumn}>
                          Status
                        </TableCell>
                        <TableCell className={classes.recipientColumn}>
                          Full Name
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {details.slice(0, 2).map((recipient) => {
                        return (
                          <TableRow key={recipient.recipient}>
                            <TableCell className={classes.emailColumn}>
                              {recipient.recipient}
                            </TableCell>
                            <TableCell
                              className={classes.statusColumn}
                              style={{ textTransform: 'capitalize' }}
                            >
                              {recipient.status}
                            </TableCell>
                            <TableCell className={classes.recipientColumn}>
                              {recipient.FullName}
                            </TableCell>
                          </TableRow>
                        )
                      })}
                    </TableBody>
                  </Table>
                  {details.length > 2 && (
                    <Button
                      color="link"
                      style={{ width: '100%' }}
                      onClick={(e) => {
                        e.stopPropagation()
                        setFilterValue(
                          aggregateDataByStatus(details).map(
                            (legend) => legend.status
                          )
                        )
                        setRecipientsDetails(details)
                      }}
                    >
                      see more results...
                    </Button>
                  )}
                </TableContainer>
              </>
            )}
          </>
        )}
      </div>
    </>
  )

  const renderContent = recipientsDetails ? (
    <TableContainer className={classes.root} style={{ maxHeight: '40vh' }}>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell className={classes.emailColumn}>Sent To</TableCell>
            <TableCell className={classes.statusColumn}>Status</TableCell>
            <TableCell className={classes.recipientColumn}>Full Name</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {recipientsDetails
            .filter((recipient) => filterValue.includes(recipient.status))
            .map((recipient) => {
              return (
                <TableRow key={recipient.recipient}>
                  <TableCell className={classes.emailColumn}>
                    {recipient.recipient}
                  </TableCell>
                  <TableCell
                    className={classes.statusColumn}
                    style={{ textTransform: 'capitalize' }}
                  >
                    {recipient.status}
                  </TableCell>
                  <TableCell className={classes.recipientColumn}>
                    {recipient.FullName}
                  </TableCell>
                </TableRow>
              )
            })}
        </TableBody>
      </Table>
    </TableContainer>
  ) : (
    <div ref={observe}>
      {messageDetail?.sendEmailYN === 'Y' &&
        renderDetail(mapStatuses(messageDetail?.details.emailDetails), 'Email')}
      {messageDetail?.sendSmsYN === 'Y' &&
        renderDetail(mapStatuses(messageDetail?.details.smsDetails), 'SMS')}
      {messageDetail?.sendPushYN === 'Y' &&
        renderDetail(mapStatuses(messageDetail?.details.pushDetails), 'Push')}
      {messageDetail?.sendTTSYN === 'Y' &&
        renderDetail(mapStatuses(messageDetail?.details.ttsDetails), 'TTS')}
      {messageDetail?.ExtChannel_list &&
        renderDetail(
          mapStatuses(messageDetail?.details.msTeamsDetails),
          'MSTeams'
        )}
      {messageDetail?.details.extGroupsDetails &&
        messageDetail?.details.extGroupsDetails.length > 0 &&
        renderDetail(
          mapStatuses(messageDetail?.details.extGroupsDetails),
          'External Groups'
        )}
      {messageDetail?.details.extScenariousDetails &&
        messageDetail?.details.extScenariousDetails.length > 0 &&
        renderDetail(
          mapStatuses(messageDetail?.details.extScenariousDetails),
          'External Scenarios'
        )}
    </div>
  )

  return (
    <StretchLayout>
      <StretchHeader>{renderHeader}</StretchHeader>
      <Spacer spaceParam={'large'} />
      <StretchBody>
        {isLoadingDetail ? <CircularProgress /> : renderContent}
      </StretchBody>
    </StretchLayout>
  )
}

function mapStatuses(recipientDetails?: RecipientDetails[]) {
  return (recipientDetails = recipientDetails?.map((recipient) => ({
    ...recipient,
    status:
      statusMappings.find(
        (mapping) => mapping.lookupStatus === recipient.status.toLowerCase()
      )?.status || recipient.status.toLowerCase(),
  })))
}

function aggregateDataByStatus(recipientDetails: RecipientDetails[]) {
  let dataArray: Array<{ status: string; color: string; count: number }> = []

  recipientDetails.forEach((recipient) => {
    let indexOfStatus: number = dataArray.findIndex(
      (status) => status.status === recipient.status
    )
    if (indexOfStatus > -1) {
      dataArray[indexOfStatus].count += 1
    } else {
      dataArray.push({
        status: recipient.status,
        color:
          legendMappings.find((legend) => {
            return legend.status === recipient.status
          })?.color || '#8a8a8a',
        count: 1,
      })
    }
  })

  return dataArray
}
