// @ts-nocheck
// TODO: Typescript
import React from 'react'
import { ListItem } from '@material-ui/core'
import { CountLabel } from '../Shared/CountLabel'
import ParticipantOnlineStatus from '../Participant/ParticipantOnlineStatus'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import { MultiLineText } from '../Shared/MultiLineText'
import classes from './ChatDirectMessageItem.module.scss'
import { cx } from '../_utils/objectUtils'
import { faEye } from '@fortawesome/free-regular-svg-icons'
import { updateChatRead } from './_actions'
import { useAppDispatch, useAppSelector } from '../_utils/reactReduxHooks'
import { selectWorkspaceDirectMessages } from '../_rootConfigs/rootSelectors'
import { selectIsChatUnread } from './_selectors'
const itemStyle = {
  wordBreak: 'break-all',
}

interface Props {
  ChatID: number
  ChatName: string
  selected: bool
  participantCount: number
  onClick: () => void
  onDeleteClick: () => void
  onViewClick: () => void
  className: any
  ChatNameLineLimit: number
  updateChatRead: () => void
  issueWorkspaceID: number
}

export const ChatDirectMessageItem = ({
  ChatID,
  ChatName = '',
  selected = false,
  participantCount = '',
  maxParticipantCount = 9,
  onClick = () => {},
  onDeleteClick,
  onViewClick,
  ChatNameLineLimit,
  className,
  issueWorkspaceID,
}: Props) => {
  const dispatch = useAppDispatch()

  const handleOnDeleteClick = (evt) => {
    if (onDeleteClick) {
      evt.stopPropagation()
      onDeleteClick()
    }
  }

  const handleOnViewClick = (evt) => {
    if (onViewClick) {
      evt.stopPropagation()
      onViewClick()
    }
  }

  const handleChatSelected = () => {
    dispatch(updateChatRead.request({ ChatID }))
    onClick({ ChatID, ChatName, participantCount })
  }

  const directChatList = useAppSelector((state) =>
    selectWorkspaceDirectMessages(state, issueWorkspaceID)
  )

  let unreadChatIdList = []

  useAppSelector((state) => {
    if (!directChatList) return
    for (const chat of directChatList) {
      let isChatUnread = selectIsChatUnread(state, chat.ChatID)
      if (isChatUnread) {
        unreadChatIdList.push(chat)
      }
    }
  })

  const ownClasses = classes

  const chatItemUnread = unreadChatIdList?.findIndex(
    (chat) => chat.ChatID === ChatID
  )

  return (
    <ListItem
      className={cx(classes.root, className)}
      style={itemStyle}
      component="div"
      key={ChatID || ChatName}
      onClick={handleChatSelected}
      selected={selected}
    >
      <span className={classes.statusIcon}>
        {participantCount > 1 ? (
          <CountLabel
            className={ownClasses.countLabel}
            count={participantCount}
            max={maxParticipantCount}
          />
        ) : (
          <ParticipantOnlineStatus EmailAddress={ChatName} />
        )}
      </span>
      {ChatNameLineLimit ? (
        <MultiLineText className={classes.chatName} lines={ChatNameLineLimit}>
          {chatItemUnread !== -1 ? <b>{ChatName}</b> : <div>{ChatName}</div>}
        </MultiLineText>
      ) : (
        <span className={classes.chatName}>{ChatName}</span>
      )}
      {(onDeleteClick || onViewClick) && (
        <span className={classes.actionsContainer}>
          {onViewClick && (
            <FontAwesomeIcon
              className={classes.viewIcon}
              icon={faEye}
              onClick={handleOnViewClick}
            />
          )}
          {onDeleteClick && (
            <FontAwesomeIcon
              className={classes.deleteIcon}
              icon={faTrash}
              onClick={handleOnDeleteClick}
            />
          )}
        </span>
      )}
    </ListItem>
  )
}
