import React, { Fragment, useEffect, useState } from 'react'
import {
  faBuilding,
  faClipboardList,
  faCommentDots,
  faCommentAlt,
  faCheckCircle,
} from '@fortawesome/pro-solid-svg-icons'
import issuesIcon from '../_images/Issues-Icon-04.svg'
import playbooksIcon from '../_images/playbooks.svg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment'
import Moment from 'react-moment'
import { Redirect } from 'react-router'
import { selectIssueById } from '../Issue/_selectors'
import { CircularProgress } from '../Shared/LoadingBackdrop'
import { Spacer } from '../Shared/Spacer'
import { appDateFormat } from '../_constants'
import { selectIssueDefaultWorkspaceId } from '../_rootConfigs/rootSelectors'
import { objDatePropsToLocal } from '../_utils/dateUtils'
import { useIcoFetch } from '../_utils/fetchUtils'
import { cx } from '../_utils/objectUtils'
import { useAppSelector } from '../_utils/reactReduxHooks'
import classes from './ActivityLogRecentSummary.module.scss'

interface Props {
  show?: boolean
  onItemClick?: () => void
  days?: number
  sortType?: ActivityLogSummarySortType
}

interface Chat {
  ChatName: string
  latestChatEntryUTC: Date
  ChatGUID: string
}

interface Summary {
  issues: {
    itemlist: Issue[]
  }
  assignments: {
    itemList: IssueTask[]
  }
  issueChats: {
    itemList: IssueChat[]
  }
  playbooks: {
    itemList: Playbook[]
  }
  playbookChats: {
    itemList: PlaybookChat[]
  }
  playbookChecklists: {
    itemList: PlaybookTask[]
  }
}

type SummaryItem =
  | Issue
  | IssueTask
  | IssueChat
  | Playbook
  | PlaybookChat
  | PlaybookTask

enum LogCategory {
  Issue = 'ISSUE',
  IssueTask = 'ISSUETASK',
  IssueChat = 'ISSUECHAT',
  Playbook = 'PLAYBOOK',
  PlaybookChat = 'PLAYBOOKCHAT',
  PlaybookTask = 'PLAYBOOKTASK',
}

interface SharedSummaryItemProp {
  logCategory: LogCategory
}

interface Task extends SharedSummaryItemProp {
  TaskName: string
  TaskGUID: string
  TaskItemID: number
  Content?: string
  DueByUTC?: Date
}

interface Issue extends SharedSummaryItemProp {
  IssueID?: number
  IssueName: string
  IssueCreated?: Date
  lastActivityDate: Date
  OrgName?: string
}

interface IssueTask extends Issue, Task {}

interface IssueChat extends Issue, Chat {}

interface Playbook extends SharedSummaryItemProp {
  PubID: number
  PubGUID: string
  OrgID: number
  OrgName: string
  PubTitle: string
  Version: number
  IsLibraryFlag: string
  Updated: Date
}

interface PlaybookChat extends Chat, SharedSummaryItemProp {
  PubTitle: string
  EventName?: string
  OrgName: string
}

interface PlaybookTask extends Task, SharedSummaryItemProp {
  PubTitle: string
  EventName?: string
  OrgName: string
}

export enum ActivityLogSummarySortType {
  Category,
  LastUpdated,
}

const compareDatesOfUpdatedSummaryItems = (
  firstSummaryItem: SummaryItem,
  secondSummaryItem: SummaryItem
) => {
  const firstDate = moment(getTimeOfSummaryItem(firstSummaryItem))
  const secondDate = moment(getTimeOfSummaryItem(secondSummaryItem))
  if (firstDate > secondDate) {
    return -1
  } else {
    return 1
  }
}

const getTimeOfSummaryItem = (item: SummaryItem) => {
  switch (item.logCategory) {
    case LogCategory.Issue: {
      const value = item as Issue
      return value.lastActivityDate
    }
    case LogCategory.IssueChat: {
      const value = item as IssueChat
      return value.latestChatEntryUTC
    }
    case LogCategory.IssueTask: {
      const value = item as IssueTask
      return value.lastActivityDate
    }
    case LogCategory.Playbook: {
      const value = item as Playbook
      return value.Updated
    }
    case LogCategory.PlaybookChat: {
      const value = item as PlaybookChat
      return value.latestChatEntryUTC
    }
    case LogCategory.PlaybookTask: {
      const value = item as PlaybookTask
      return value.DueByUTC
    }
  }
}

const getCategoryDescription = (category: LogCategory) => {
  switch (category) {
    case LogCategory.Issue:
      return 'Issues with Activity'
    case LogCategory.IssueChat:
      return 'Issue Chats'
    case LogCategory.IssueTask:
      return 'My Issue Tasks'
    case LogCategory.Playbook:
      return 'Playbooks Updated'
    case LogCategory.PlaybookChat:
      return 'Playbook Secure Chats'
    case LogCategory.PlaybookTask:
      return 'My Playbook Checklists'
    default:
      return ''
  }
}

const getIconForLogCategory = (logCategory?: LogCategory) => {
  switch (logCategory) {
    case LogCategory.Issue:
      return <img src={issuesIcon} alt={'issue'} />
    case LogCategory.IssueChat:
      return <FontAwesomeIcon icon={faCommentDots} />
    case LogCategory.IssueTask:
      return <FontAwesomeIcon icon={faClipboardList} />
    case LogCategory.Playbook:
      return <img src={playbooksIcon} alt={'playbook'} />
    case LogCategory.PlaybookChat:
      return <FontAwesomeIcon icon={faCommentAlt} />
    case LogCategory.PlaybookTask:
      return <FontAwesomeIcon icon={faCheckCircle} />

    default:
      return issuesIcon
  }
}

const sortSummaryDataByLastUpdated = (data: Summary) => {
  const lastUpdatedList: SummaryItem[] = []
  Object.entries(data).forEach(([key, value]) => {
    if (
      key === 'issues' ||
      key === 'assignments' ||
      key === 'issueChats' ||
      key === 'playbooks' ||
      key === 'playbookChats' ||
      key === 'playbookChecklists'
    ) {
      let assignedValue: SummaryItem[] = []
      //This needs to come back standard from API
      if (value['itemList']) {
        assignedValue = value['itemList']
      } else {
        assignedValue = value['itemlist']
      }
      assignedValue?.forEach((valueItem: SummaryItem) => {
        lastUpdatedList.push(valueItem)
      })
    }
  })
  return lastUpdatedList.sort(compareDatesOfUpdatedSummaryItems)
}

const sortSummaryDataByType = (
  data: Summary,
  type: ActivityLogSummarySortType
) => {
  switch (type) {
    case ActivityLogSummarySortType.Category:
      return data
    case ActivityLogSummarySortType.LastUpdated:
      return sortSummaryDataByLastUpdated(data)

    default:
      return data
  }
}

export const ActivityLogRecentSummary = ({
  show,
  onItemClick,
  days = 1,
  sortType = ActivityLogSummarySortType.Category,
}: Props) => {
  const [activityLogEmpty, setActivityLogEmpty] = useState(true)
  const result = useIcoFetch<Summary>(
    `api/ActivityLog/RecentSummary?days=${days}`,
    {
      tracking: [show],
      shouldFetch: (tracking) => tracking[0] === true,
      formatData: (data: Summary) => {
        data = data || {}
        data.issues?.itemlist?.forEach((issue) => {
          objDatePropsToLocal(issue, 'lastActivityDate')
          issue['logCategory'] = LogCategory.Issue
          if (activityLogEmpty) setActivityLogEmpty(false)
        })
        data.assignments?.itemList?.forEach((assignment) => {
          objDatePropsToLocal(assignment, 'lastActivityDate', 'DueByUTC')
          assignment['logCategory'] = LogCategory.IssueTask
          if (activityLogEmpty) setActivityLogEmpty(false)
        })
        data.issueChats?.itemList?.forEach((chat) => {
          objDatePropsToLocal(chat, 'lastActivityDate', 'latestChatEntryUTC')
          chat['logCategory'] = LogCategory.IssueChat
          if (activityLogEmpty) setActivityLogEmpty(false)
        })
        data.playbookChats?.itemList?.forEach((playbookChat) => {
          objDatePropsToLocal(playbookChat, 'latestChatEntryUTC')
          playbookChat['logCategory'] = LogCategory.PlaybookChat
          if (activityLogEmpty) setActivityLogEmpty(false)
        })
        data.playbookChecklists?.itemList?.forEach((playbookChecklist) => {
          objDatePropsToLocal(playbookChecklist, 'DueByUTC')
          playbookChecklist['logCategory'] = LogCategory.PlaybookTask
          if (activityLogEmpty) setActivityLogEmpty(false)
        })
        data.playbooks?.itemList?.forEach((playbook) => {
          objDatePropsToLocal(playbook, 'Updated')
          playbook['logCategory'] = LogCategory.Playbook
          if (activityLogEmpty) setActivityLogEmpty(false)
        })
        return data
      },
    }
  )

  const renderNoActivitySummary = () => {
    return (
      <div className={classes.noActivitySummaryBody}>
        There are no notifications for your {days} Day Summary
      </div>
    )
  }

  const renderActivityLogSummary = (data: Summary) => {
    const sortedData = sortSummaryDataByType(data, sortType)

    if (activityLogEmpty) {
      return renderNoActivitySummary()
    }

    switch (sortType) {
      case ActivityLogSummarySortType.Category:
        return renderCategoryActivityLog(sortedData as Summary)
      case ActivityLogSummarySortType.LastUpdated:
        return renderLastUpdatedActivityLog(sortedData as SummaryItem[])
      default:
        return renderCategoryActivityLog(sortedData as Summary)
    }
  }

  const renderLastUpdatedActivityLog = (
    updatedAndSortedData: SummaryItem[]
  ) => {
    const logItems = updatedAndSortedData.map((summaryItem) => {
      const logCategory = summaryItem.logCategory

      if (logCategory === LogCategory.Issue) {
        const issue = summaryItem as Issue
        return (
          <IssueLogItem
            key={issue.IssueID}
            {...issue}
            onClick={onItemClick}
            logCategory={logCategory}
            sortType={sortType}
          />
        )
      } else if (logCategory === LogCategory.IssueTask) {
        const issueTask = summaryItem as IssueTask
        return (
          <IssueTaskLogItem
            key={issueTask.TaskItemID}
            {...issueTask}
            onClick={onItemClick}
            logCategory={logCategory}
            sortType={sortType}
          />
        )
      } else if (logCategory === LogCategory.IssueChat) {
        const issueChat = summaryItem as IssueChat
        return (
          <IssueChatLogItem
            //Not sure what a better key would be, added ICLI as in IssueChatLogItem, otherwise will have the same ID as Issues.
            //maybe need individual GUID for everything?
            key={issueChat.IssueID + 'ICLI'}
            {...issueChat}
            onClick={onItemClick}
            logCategory={logCategory}
            sortType={sortType}
          />
        )
      } else if (logCategory === LogCategory.Playbook) {
        const playbook = summaryItem as Playbook
        return (
          <PlaybookLogItem
            key={playbook.PubID}
            {...playbook}
            onClick={onItemClick}
            logCategory={logCategory}
            sortType={sortType}
          />
        )
      } else if (logCategory === LogCategory.PlaybookChat) {
        const playbookChat = summaryItem as PlaybookChat
        return (
          <PlaybookChatLogItem
            key={playbookChat.ChatGUID}
            {...playbookChat}
            onClick={onItemClick}
            logCategory={logCategory}
            sortType={sortType}
          />
        )
      } else if (logCategory === LogCategory.PlaybookTask) {
        const playbookTask = summaryItem as PlaybookTask
        return (
          <PlaybookTaskLogItem
            key={playbookTask.TaskItemID}
            {...playbookTask}
            onClick={onItemClick}
            logCategory={logCategory}
            sortType={sortType}
          />
        )
      }
      return <></>
    })
    return <>{logItems}</>
  }

  const renderCategoryActivityLog = (data: Summary) => {
    return (
      <>
        <Log
          header={getCategoryDescription(LogCategory.Issue)}
          shouldRender={data.issues?.itemlist?.length > 0}
        >
          {data.issues?.itemlist?.map((issue) => (
            <IssueLogItem
              key={issue.IssueID}
              {...issue}
              onClick={onItemClick}
              sortType={sortType}
            />
          ))}
        </Log>

        <Log
          header={getCategoryDescription(LogCategory.IssueChat)}
          shouldRender={data.issueChats?.itemList?.length > 0}
        >
          {data.issueChats?.itemList?.map((chat) => (
            <IssueChatLogItem
              key={chat.IssueID}
              {...chat}
              onClick={onItemClick}
              sortType={sortType}
            />
          ))}
        </Log>

        <Log
          header={getCategoryDescription(LogCategory.IssueTask)}
          shouldRender={data.assignments?.itemList?.length > 0}
        >
          {data.assignments?.itemList?.map((task) => (
            <IssueTaskLogItem
              key={task.TaskItemID}
              {...task}
              onClick={onItemClick}
              sortType={sortType}
            />
          ))}
        </Log>

        <Log
          header={getCategoryDescription(LogCategory.Playbook)}
          shouldRender={data.playbooks?.itemList?.length > 0}
        >
          {data.playbooks?.itemList?.map((playbook) => (
            <PlaybookLogItem
              key={playbook.PubID}
              {...playbook}
              onClick={onItemClick}
              sortType={sortType}
            />
          ))}
        </Log>

        <Log
          header={getCategoryDescription(LogCategory.PlaybookChat)}
          shouldRender={data.playbookChats?.itemList?.length > 0}
        >
          {data.playbookChats?.itemList?.map((chat) => (
            <PlaybookChatLogItem
              key={chat.ChatGUID}
              {...chat}
              onClick={onItemClick}
              sortType={sortType}
            />
          ))}
        </Log>

        <Log
          header={getCategoryDescription(LogCategory.PlaybookTask)}
          shouldRender={data.playbookChecklists?.itemList?.length > 0}
        >
          {data.playbookChecklists?.itemList?.map((task) => (
            <PlaybookTaskLogItem
              key={task.TaskItemID}
              {...task}
              onClick={onItemClick}
              sortType={sortType}
            />
          ))}
        </Log>
      </>
    )
  }

  if (result.loading)
    return (
      <div className={classes.loadingContainer}>
        <CircularProgress position="center" />
      </div>
    )

  if (!result.data) return null

  return renderActivityLogSummary(result.data)
}

interface SharedLogItemProps {
  onClick?: () => void
  logCategory?: LogCategory
  sortType?: ActivityLogSummarySortType
}

export const IssueLogItem = ({
  IssueName,
  lastActivityDate,
  IssueID,
  onClick,
  logCategory,
  sortType,
}: Issue & SharedLogItemProps) => {
  const issue = useAppSelector((state) =>
    selectIssueById(state, IssueID as any)
  )

  return (
    <LogItem
      header={IssueName}
      date={lastActivityDate}
      urlPath={issue ? `/IssueDetail/${IssueID}` : undefined}
      onClick={onClick}
      category={logCategory}
      sortType={sortType}
    />
  )
}

export const IssueChatLogItem = ({
  IssueName,
  ChatName,
  OrgName,
  latestChatEntryUTC,
  IssueID,
  onClick,
  logCategory,
  sortType,
}: IssueChat & SharedLogItemProps) => {
  const workspaceID = useAppSelector((state) =>
    selectIssueDefaultWorkspaceId(state, IssueID)
  )
  const issue = useAppSelector((state) =>
    selectIssueById(state, IssueID as any)
  )

  return (
    <LogItem
      header={IssueName}
      subHeader={ChatName}
      date={latestChatEntryUTC}
      extraInfo={
        <Fragment>
          <FontAwesomeIcon icon={faBuilding} /> {OrgName}
        </Fragment>
      }
      urlPath={
        issue ? `/IssueDetail/${IssueID}/${workspaceID}/chats` : undefined
      }
      onClick={onClick}
      category={logCategory}
      sortType={sortType}
    />
  )
}

export const IssueTaskLogItem = ({
  IssueName,
  TaskName,
  Content,
  IssueID,
  DueByUTC,
  onClick,
  logCategory,
  sortType,
}: IssueTask & SharedLogItemProps) => {
  const workspaceID = useAppSelector((state) =>
    selectIssueDefaultWorkspaceId(state, IssueID)
  )
  const issue = useAppSelector((state) =>
    selectIssueById(state, IssueID as any)
  )
  return (
    <LogItem
      header={IssueName}
      subHeader={TaskName}
      content={Content}
      dateLabel="Due Date"
      date={DueByUTC}
      urlPath={
        issue ? `/IssueDetail/${IssueID}/${workspaceID}/tasks` : undefined
      }
      onClick={onClick}
      category={logCategory}
      sortType={sortType}
    />
  )
}

export const PlaybookLogItem = ({
  PubTitle,
  OrgName,
  PubGUID,
  onClick,
  logCategory,
  sortType,
  Updated,
}: Playbook & SharedLogItemProps) => (
  <LogItem
    header={PubTitle}
    extraInfo={
      <Fragment>
        <FontAwesomeIcon icon={faBuilding} /> {OrgName}
      </Fragment>
    }
    urlPath={`/Playbook/Playbooks/${PubGUID}`}
    onClick={onClick}
    category={logCategory}
    sortType={sortType}
    date={Updated}
  />
)

export const PlaybookChatLogItem = ({
  PubTitle,
  ChatName,
  latestChatEntryUTC,
  ChatGUID,
  onClick,
  logCategory,
  sortType,
}: PlaybookChat & SharedLogItemProps) => (
  <LogItem
    header={PubTitle}
    subHeader={ChatName}
    date={latestChatEntryUTC}
    urlPath={`/PbChat/PbChat/${ChatGUID}`}
    onClick={onClick}
    category={logCategory}
    sortType={sortType}
  />
)

export const PlaybookTaskLogItem = ({
  PubTitle,
  EventName,
  Content,
  DueByUTC,
  TaskGUID,
  onClick,
  logCategory,
  sortType,
}: PlaybookTask & SharedLogItemProps) => (
  <LogItem
    header={PubTitle}
    subHeader={EventName}
    content={Content}
    dateLabel="Due Date"
    date={DueByUTC}
    urlPath={`/PbTask/PbTask/${TaskGUID}`}
    onClick={onClick}
    category={logCategory}
    sortType={sortType}
  />
)

interface LogItemProps {
  header: JSX.Element | string
  subHeader?: JSX.Element | string
  content?: JSX.Element | string
  date?: Date
  dateLabel?: string
  extraInfo?: JSX.Element | string
  urlPath?: string
  onClick?: () => void
  category?: LogCategory
}

const LogItem = ({
  header,
  subHeader,
  content,
  date,
  extraInfo,
  urlPath,
  dateLabel,
  onClick,
  category,
  sortType,
}: LogItemProps & Props) => {
  const [isClicked, setIsClicked] = useState(false)
  useEffect(() => {
    if (isClicked) setIsClicked(false)
  }, [isClicked])

  if (isClicked && urlPath) return <Redirect to={urlPath} />

  const categoryString =
    sortType === ActivityLogSummarySortType.LastUpdated && category
      ? `Category: ${getCategoryDescription(category)}`
      : ''

  const additionalDateData =
    sortType === ActivityLogSummarySortType.LastUpdated && category ? (
      <>
        <Moment className={classes.dateTime} local fromNow>
          {date}
        </Moment>
      </>
    ) : undefined

  const iconSection = sortType === ActivityLogSummarySortType.LastUpdated && (
    <>
      <div className={classes.iconSection}>
        {getIconForLogCategory(category)}
      </div>
      <Spacer />
    </>
  )

  const extraDetails = sortType === ActivityLogSummarySortType.Category && (
    <>
      {subHeader && <div className={classes.subHeaderSection}>{subHeader}</div>}
      {content && <div className={classes.content}>{content}</div>}
      {extraInfo && <div className={classes.extraInfo}>{extraInfo}</div>}
    </>
  )

  return (
    <div
      className={cx(classes.logItem, urlPath ? classes.clickable : '')}
      onClick={() => {
        setIsClicked(true)
        onClick?.call(undefined)
      }}
    >
      {iconSection}
      <div className={classes.contentSection}>
        <div className={classes.leftSection}>
          <div className={classes.headerContainer}>
            <div className={classes.headerText}>{header}</div>
            <div className={classes.categoryLabel}> {categoryString}</div>
          </div>
          {extraDetails}
        </div>
        <div className={classes.rightSection}>
          {date && (
            <div className={classes.dateContainer}>
              {dateLabel && (
                <div className={classes.dateLabel}>{dateLabel}</div>
              )}
              <Moment local format={appDateFormat}>
                {date}
              </Moment>
              {additionalDateData}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

function Log({
  header,
  children,
  shouldRender = false,
}: {
  header: string
  children?: JSX.Element | JSX.Element[]
  shouldRender: boolean
}) {
  if (!shouldRender) return null

  return (
    <div className={classes.log}>
      <h5>{header}</h5>
      <div>{children}</div>
    </div>
  )
}
