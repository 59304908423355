import {
  bindSel,
  bindSelRet,
  getConference,
} from '../_rootConfigs/rootReducerSlicers'
import { selectEntityByNumId, selectEntityIdMap } from '../_schema/_reducer'
import { ConferenceModel, ConferenceState } from './_models'
import * as priv from './_reducer'

export const selectConferenceById = bindSelRet(
  getConference,
  selectEntityByNumId
)<ConferenceModel>()
export const selectConferencesIdMap = bindSelRet(
  getConference,
  selectEntityIdMap
)<ConferenceState>()
export const selectConferenceStart = bindSel(
  getConference,
  priv.selectConferenceStart
)
export const selectConferenceEnd = bindSel(
  getConference,
  priv.selectConferenceEnd
)
