// @ts-nocheck
// TODO: Typescript
import React, { Component } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { PropTypes } from 'prop-types'
import { InputGroup, InputGroupAddon, InputGroupText, Input } from 'reactstrap'
import { ChatDetailItem } from './ChatDetailItem'
import classes from './ChatSearch.module.scss'
import { cx } from '../_utils/objectUtils'

export class ChatSearch extends Component {
  constructor(props) {
    super(props)
    this.state = { value: '' }
    this.state = {
      foundEntries: [],
      txtSearch: '',
      className: 'classes.root',
      isChatMenuOpened: false,
      isChatAutoCompleteOpened: false,
    }
    this.applySearch = this.applySearch.bind(this)
    this.itemSelected = this.itemSelected.bind(this)
  }

  componentDidMount() {
    this.updateListHeight()
    if (this.props.onApi) {
      this.props.onApi({
        clearSearch: () => {
          this.setState({
            foundEntries: [],
            txtSearch: '',
          })
        },
      })
    }
  }

  componentDidUpdate(prevProps) {
    this.updateListHeight()
    var selected = this.state.selected
    const { chatEntries } = this.props
    const maxID = Math.max(...chatEntries.map((entry) => entry.ChatEntryID))

    // if new entry was added go to it.
    if (chatEntries.length - prevProps.chatEntries.length > 0) {
      selected = maxID
    }

    var elmnt = document.getElementById(selected)
    if (elmnt === null && chatEntries.length > 0) {
      elmnt = document.getElementById(maxID)
    }

    if (elmnt !== null) elmnt.scrollIntoView()
  }

  applySearch(evt) {
    if (evt.target.name === 'runSearch') {
      const txtSearch = evt.target.value
      const chatEntries = this.props.chatEntries
      let foundEntries = []
      if (txtSearch.length > 0) {
        for (let i = 0; i < chatEntries.length; i++) {
          let cleanContent = (chatEntries[i].Content || '').replace(
            /(<([^>]+)>)/gi,
            ''
          )
          if (
            cleanContent.toLowerCase().indexOf(txtSearch.toLowerCase()) !== -1
          ) {
            foundEntries.push(chatEntries[i])
          }
        }
      }

      this.setState({
        foundEntries: foundEntries,
        txtSearch: txtSearch,
      })
    }
  }

  render() {
    const { props } = this
    const ownClasses = props.classes
    const {
      height,
      listName,
      chatEntries,
      children,
      search,
      onClickThumbnail,
      headerActions,
      onHeaderClick,
      showHeader,
      header,
      beforeBody,
      messageBody,
      showSearch,
    } = props
    var inlineStyle = {}
    var items =
      this.state.txtSearch.length > 0 ? this.state.foundEntries : chatEntries
    if (height) {
      inlineStyle.height = height
    }
    const className =
      this.state.txtSearch.length > 0
        ? classes.viewportSearch
        : classes.viewport
    return (
      <div>
        <div ref={(el) => (this._header = el)}>
          {showHeader && (
            <div
              className={cx(
                classes.header,
                ownClasses.header,
                'w-100',
                onHeaderClick ? classes.withClick : ''
              )}
              onClick={onHeaderClick}
            >
              {!header ? (
                <div className={cx(classes.headerInner, 'w-100')}>
                  <div className={cx(classes.name, 'single-line-text w-100')}>
                    {listName}
                  </div>
                  <div>{headerActions}</div>
                </div>
              ) : (
                header
              )}
            </div>
          )}
          {showSearch && (
            <InputGroup>
              <InputGroupAddon addonType="prepend">
                <InputGroupText
                  className={classes.searchIconContainer}
                  htmlFor="txtSearch"
                >
                  <FontAwesomeIcon icon={faSearch} size="lg" />
                </InputGroupText>
              </InputGroupAddon>
              <Input
                addon
                name="runSearch"
                defaultValue={search.entry}
                id="txtSearch"
                placeholder="Search Chat by comments"
                className={cx(classes.searchInput, 'form-control')}
                type="text"
                onChange={this.applySearch}
                style={{ width: 'auto' }}
              />
            </InputGroup>
          )}
        </div>
        {beforeBody}
        <div className={className} ref={(el) => (this._viewPortEl = el)}>
          {messageBody ? (
            messageBody
          ) : (
            <ul className={classes.list} ref={(el) => (this._listEl = el)}>
              {children
                ? children
                : items.map((entry) => (
                    <ChatDetailItem
                      key={entry.ChatEntryID || 'emptyId'}
                      {...entry}
                      ArtifactID={entry.upload}
                      onChange={this.itemSelected}
                      backGRDColor={entry.backgroundColor || 'fff'}
                      isSearchResult={
                        this.state.txtSearch.length > 0 ? true : false
                      }
                      onClickThumbnail={onClickThumbnail}
                    />
                  ))}
            </ul>
          )}
        </div>
      </div>
    )
  }

  itemSelected(ChatEntryID) {
    let foundEntries = this.props.chatEntries
    for (let i = 0; i < foundEntries.length; i++) {
      if (foundEntries[i].ChatEntryID === ChatEntryID) {
        foundEntries[i].backgroundColor = '#e2e2e2'
      } else {
        foundEntries[i].backgroundColor = '#fff'
      }
    }
    document.getElementById('txtSearch').value = ''
    this.setState({
      foundEntries: foundEntries,
      txtSearch: '',
      selected: ChatEntryID,
    })
  }

  getListHeight() {
    return this.props.height - ((this._header || {}).offsetHeight || 0)
  }

  updateListHeight() {
    this._viewPortEl.style.height = this.getListHeight() + 'px'
  }
}

ChatSearch.propTypes = {
  scrollToNew: PropTypes.bool,
  headerActions: PropTypes.any,
  onHeaderClick: PropTypes.func,
  showHeader: PropTypes.bool,
  header: PropTypes.any,
  beforeBody: PropTypes.any,
  messageBody: PropTypes.any,
  showSearch: PropTypes.bool,
  classes: PropTypes.object,
}

ChatSearch.defaultProps = {
  scrollToNew: true,
  classes: {},
  items: [],
  search: {},
  showSearch: true,
  showHeader: true /*,
    fetchDirectMessages: (issueTeamWorkspaceId) => {
        icoFetch(`/api/IssueTeamWorkspaces/${issueTeamWorkspaceId}/Chats`)
            .then(response => response.text())
            .then((data) => {
            });
    }*/,
}
