// @ts-nocheck
// TODO: Typescript
import React, { Component } from 'react'
import { ConfirmDialog } from '../Shared/ConfirmDialog'
import { connect } from 'react-redux'
import { StakeholderActions } from './_actions'
import PropTypes from 'prop-types'
import { selectStakeholderById } from '../_rootConfigs/rootSelectors'

/**
 * @extends {Component<Props, {}>}}
 * Reusable confirm dialog
 */
export class StakeholderDeleteConfirmation extends Component {
  constructor(props) {
    super(props)
    this.state = {
      Audience: undefined,
    }
    this.handleConfirmation = this.handleConfirmation.bind(this)
  }

  handleConfirmation() {
    const { confirm, Audience, IssueStakeholderID } = this.props

    if (confirm) confirm({ Audience, IssueStakeholderID })
  }

  render() {
    const { open, cancel, Audience } = this.props
    return (
      <ConfirmDialog
        open={open}
        title="Stakeholder Delete"
        content={`You are about to delete "${Audience}", do you wish to continue?`}
        confirm={this.handleConfirmation}
        cancel={cancel}
      />
    )
  }
}

StakeholderDeleteConfirmation.propTypes = {
  IssueStakeholderID: PropTypes.number,
  Stakeholder: PropTypes.string,
  open: PropTypes.bool,
  confirm: PropTypes.func,
  cancel: PropTypes.func,
}

const mapStateToProps = (state, ownProps) => {
  const stakeholder =
    selectStakeholderById(state, ownProps.IssueStakeholderID) || {}
  const { Audience } = stakeholder
  return {
    Audience,
  }
}

const mapDispatchToProps = {
  confirm: StakeholderActions.delete,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StakeholderDeleteConfirmation)
