import React from 'react'
import { Redirect } from 'react-router'
import { isUserAuthorized } from '../Auth/_selectors'
import { selectInitialView } from '../_rootConfigs/rootSelectors'
import { useAppSelector } from '../_utils/reactReduxHooks'

export const InitialViewPage = () => {
  const initialView = useAppSelector(selectInitialView)
  const isAuthorized = useAppSelector(isUserAuthorized)

  if (initialView) return <Redirect to={initialView} />

  if (!isAuthorized) return <Redirect to="/InvalidAccount" />

  return <Redirect to="/Dashboard" />
}
