/*
 * NOTE: This is using local virtualization. Future improvements could use
 * server side pagination. In addition to pagination, fetching the data only
 * once could improve performance as well. Right now it is fetched when ever
 * the component is rendered.
 */
import {
  AutocompleteChangeReason,
  AutocompleteCloseReason,
} from '@material-ui/lab'
import React, { ChangeEvent, KeyboardEvent, useEffect, useState } from 'react'
import { AutoCompleteVirtualized } from '../Shared/AutoCompleteVirtualized'
import { fetchingOrgUgisMembers } from '../Shared/_requestStatusSelectors'
import { useAppDispatch, useAppSelector } from '../_utils/reactReduxHooks'
import { fetchOrgUgisMembers } from './_actions'
import { UgisMember } from './_models'
import { selectUgisMembersByOrg } from './_selectors'

interface Props {
  OrgID: number
  className?: string
  placeholder?: string
  inputLabel?: string
  onChange?: (value: string) => void
  inputValue?: string
  value?: string
  onEnterPressed?: () => void
  onClose?: (evt: ChangeEvent<{}>, reason: AutocompleteCloseReason) => void
  disabled?: boolean
}

export const OrgUgisMembers = ({
  OrgID,
  className,
  placeholder,
  inputLabel,
  onChange,
  inputValue,
  onEnterPressed,
  value,
  disabled,
}: Props) => {
  const rows =
    useAppSelector((state) => selectUgisMembersByOrg(state, OrgID)) || []
  const [open, setOpen] = useState(false)
  const isLoading = useAppSelector(fetchingOrgUgisMembers)
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(fetchOrgUgisMembers.request({ OrgID }))
  }, [OrgID, dispatch])

  function handleChange(
    evt: ChangeEvent<{}>,
    newValue: { email_lcase: string },
    reason: AutocompleteChangeReason
  ) {
    if (reason === 'create-option') return

    const email = newValue?.email_lcase || ''
    onChange?.call(undefined, email)
  }

  function handleInputChange(evt: ChangeEvent<HTMLInputElement>) {
    if (!evt) return

    const value = evt.target.value || ''
    onChange?.call(undefined, value)
  }

  function handleInputKeyUp(evt: KeyboardEvent<HTMLInputElement>) {
    var enter = 13
    if (evt.keyCode !== enter || value?.trim() === '') return

    onEnterPressed?.call(undefined)
  }

  function handleClose() {
    setOpen(false)
  }

  function handleOpen() {
    setOpen(true)
  }

  return (
    <AutoCompleteVirtualized
      open={open}
      onOpen={handleOpen}
      onClose={handleClose}
      className={className}
      getValue={(row: UgisMember) => row.email_lcase}
      isLoading={isLoading}
      rows={rows}
      placeholder={placeholder}
      inputLabel={inputLabel}
      clearOnBlur={false}
      freeSolo={true}
      onInputChange={handleInputChange as any}
      onChange={handleChange as any}
      inputValue={inputValue}
      onInputKeyup={handleInputKeyUp}
      value={value}
      disabled={disabled}
      autoComplete={true}
    />
  )
}
