// @ts-nocheck
// TODO: Typescript
import { addKeyValuePrefix } from '../_utils/objectUtils'
import { generateTypes } from '../_utils/reduxUtils'
export const LoginConstants = {
  LOGIN: 'LOGIN',
  LOADING: 'LOADING',
  LOGIN_SSO: 'SSO',
  SUCCESSFUL: 'SUCCESSFUL',
  FAILURE: 'FAILURE',
  CLEAR: 'CLEAR',
}
addKeyValuePrefix('LOGIN', LoginConstants)

export const LogoutConstants = {
  LOGOUT: 'LOGOUT',
  LOADING: 'LOADING',
  SUCCESSFUL: 'SUCCESSFUL',
  FAILURE: 'FAILURE',
  LOGOUT_SSO: 'SSO',
}
addKeyValuePrefix('LOGOUT', LogoutConstants)

export const AuthConstants = {
  USER_UNAUTHENTICATED: 'USER_UNAUTHENTICATED',
  USER_AUTEHNTICATED: 'USER_AUTHENTICATED',
  REFRESH_AUTHENTICATION: 'REFRESH_AUTHENTICATION',
  REFRESH_AUTHENTICATION_SUCCESSFUL: 'REFRESH_AUTHENTICATION_SUCCESSFUL',
  SESSION_WARNING_OPENED: 'SESSION_WARNING_OPENED',
  SESSION_WARNING_CLOSED: 'SESSION_WARNING_CLOSED',
  USER_UNAUTHORIZED: 'USER_UNAUTHORIZED',
  REDIRECT_FROM_UNAUTHORIZED: 'REDIRECT_FROM_UNAUTHORIZED',
}
addKeyValuePrefix('AUTH', AuthConstants)

export const AZURE_LOGIN_PROVIDER = 'AzureADOpenID'

export const FETCH_REQUEST_TOKEN = generateTypes('FETCH_REQUEST_TOKEN')
