import * as React from 'react'
import { http } from '../_utils/http'
import { CircularProgress } from '../Shared/LoadingBackdrop'

interface Props {
  IssueID: number
  TeamReportImageID: number
}

export const IssueTeamReportImage = ({ IssueID, TeamReportImageID }: Props) => {
  const [imgInfo, setImgInfo] = React.useState<any>('')
  const [isFetchingImgInfo, setIsFetchingImgInfo] =
    React.useState<boolean>(false)
  const getImgInfo = React.useCallback(async (): Promise<any> => {
    if (isFetchingImgInfo) {
      return
    }
    setIsFetchingImgInfo(true)
    setImgInfo('')
    var customHeaders: any = []
    customHeaders.push({ name: 'issueid', value: IssueID })
    customHeaders.push({
      name: 'teamreportimageid',
      value: TeamReportImageID,
    })
    const result = await http<any>({
      path: '/Issue/TeamReportImage',
      headers: customHeaders,
    })
    if (result.ok && result.body) {
      setImgInfo(result.body)
    } else {
      setImgInfo('')
    }
    setIsFetchingImgInfo(false)
  }, [IssueID, TeamReportImageID, isFetchingImgInfo])
  React.useEffect(() => {
    if (TeamReportImageID > 0 && IssueID > 0) {
      getImgInfo()
    }
  }, [IssueID, TeamReportImageID, getImgInfo])

  function renderImage() {
    if (isFetchingImgInfo) return <CircularProgress />
    if (!imgInfo) return <CircularProgress />
    if (imgInfo === null) return <CircularProgress />
    if (!imgInfo.image_type) return <div>image type not found</div>
    if (imgInfo.image_type === null) return <div>image type not found</div>
    if (!imgInfo.image_graphic)
      return <div>image type {imgInfo.image_type} not supported here</div>
    if (imgInfo.image_graphic === null)
      return <div>image type {imgInfo.image_type} not supported here</div>
    return (
      <div>
        <img
          alt={imgInfo.image_name}
          src={
            'data:' + imgInfo.image_type + ';base64,' + imgInfo.image_graphic
          }
        />
      </div>
    )
  }
  return <>{renderImage()}</>
}
