// @ts-nocheck
// TODO: Typescript
import { generateTypes } from '../_utils/reduxUtils'

const prefix = 'GRAPH'

// AD Token Constants
export const FETCH_GRAPH_ACCESS_TOKEN = generateTypes(
  prefix,
  'FETCH_ACCESS_TOKEN'
)
export const LOGIN_AD = generateTypes('AZURE_AD', 'LOGIN')
export const LOGOUT_AD = generateTypes('AZURE_AD', 'LOGOUT')
export const AD_SILIENT_FAILURE = 'AADSTS50058'
export const AD_FAILED_TO_OPEN_WINDOW = 'FailedToOpenWindow'
export const AD_CANCELED_BY_USER = 'CancelledByUser'
export const AD_MSG_SILENT_FAILURE =
  'Previous Azure session is expired. Please login again'
export const AD_MSG_POPUP_BLOCKED =
  'Auto login failed. This is usually caused by a popup blocker.'
export const ENABLE_MS_TEAMS_AUTO_LOGIN = 'ENABLE_MS_TEAMS_AUTO_LOGIN'
export const DISABLE_MS_TEAMS_AUTO_LOGIN = 'DISABLE_MS_TEAMS_AUTO_LOGIN'

// Graph Constants
export const FETCH_GRAPH_USER_DETAILS = generateTypes(
  prefix,
  'FETCH_USER_DETAILS'
)
export const FETCH_GRAPH_JOINED_TEAM_CHANNELS = generateTypes(
  prefix,
  'FETCH_JOINED_TEAM_CHANNELS'
)
export const FETCH_MS_TEAMS_CHANNEL_FOLDERIDS = generateTypes(
  prefix,
  'FETCH_CHANNEL_FOLDERIDS'
)
export const FETCH_GRAPH_CALENDAR_EVENTS = generateTypes(
  prefix,
  'FETCH_CALENDAR_EVENTS'
)
export const CLEAR_GRAPH_CALENDAR_EVENTS = 'CLEAR_GRAPH_CALENDAR_EVENTS'
export const SET_GRAPH_CALENDAR_EVENTS_WORKSPACE =
  'SET_GRAPH_CALENDAR_EVENTS_WORKSPACE'
export const ADD_GRAPH_CALENDAR_EVENT = generateTypes(
  prefix,
  'ADD_CALENDAR_EVENT'
)
export const REMOVE_GRAPH_CALENDAR_EVENT = generateTypes(
  prefix,
  'REMOVE_CALENDAR_EVENT'
)

// Microsoft Teams SDK
const msTeamsPrefix = 'MS_TEAMS'
export const INIT_MS_TEAMS = generateTypes(msTeamsPrefix, 'INIT')
export const FETCH_MS_TEAMS_CONTEXT = generateTypes(
  msTeamsPrefix,
  'FETCH_CONTEXT'
)
export const FETCH_MS_TEAMS_SETTINGS = generateTypes(
  msTeamsPrefix,
  'FETCH_SETTINGS'
)
