import React, {
  ChangeEvent,
  KeyboardEvent,
  useState,
  CSSProperties,
  useEffect,
} from 'react'
import { AuthMasterLayout } from './AuthMasterLayout'
import { Button } from '../Shared/Buttons'
import { prefixSelectorWithApp } from '../_utils/domUtils'
import { History } from 'history'
import {
  CircularProgress,
  Toolbar,
  DialogContent,
  TextField,
} from '@material-ui/core'
import classes from './LoginForm.module.scss'
import setClasses from '../AppUserSettings.module.scss'
import tfClasses from '../Shared/TextField.module.scss'
import { cx } from '../_utils/objectUtils'
import { Spacer } from '../Shared/Spacer'
import { ResponsiveDialog } from '../Shared/ResponsiveDialog'
import { SlideLeft } from '../Shared/Transition'
import { HeaderNavigation } from '../Shared/HeaderNavigation'
import { Autocomplete } from '@material-ui/lab'
import { useIcoFetch } from '../_utils/fetchUtils'
import { useAppDispatch } from '../_utils/reactReduxHooks'
import { addNotification } from '../_actions'

const passwordRulesStyle: CSSProperties = {
  width: '80%',
  clear: 'both',
}
const listStyle: CSSProperties = { textAlign: 'left', marginBottom: 0 }

export interface SubmitFields {
  name: string
  phone: string
  countrycode: string
  email: string
  password: string
  confirmPassword: string
}

interface CountryEntry {
  CountryAbbr: string
  CountryCode: string
  CCDescr: string
}

interface Props {
  headerText?: string
  createAccount?: boolean
  submitBtnText: string
  onSubmit: (fields: SubmitFields) => void
  submitEnabled: boolean
  submitted?: boolean
  history: History
  email?: string
  enableEmail?: boolean
  onEmailChange?: (email: string) => void
  error?: string
  success?: string
  loading?: boolean
  loadingText?: string
}

export function EmailPwForm({
  createAccount = true,
  headerText,
  onSubmit = () => {},
  submitBtnText,
  submitEnabled = true,
  email,
  history,
  submitted = false,
  enableEmail = false,
  onEmailChange,
  success,
  loading = false,
  loadingText = 'Submitting...',
  error,
  ...otherProps
}: Props) {
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [changesMade, setChangesMade] = useState(false)
  const [emailChangesMade, setEmailChangesMade] = useState(false)

  const [mobilePhone, setMobilePhone] = useState('')
  const [mobilePhoneValError, setMobilePhoneValError] = React.useState(false)
  const [name, setName] = useState('')
  const [showDialog, setShowDialog] = useState(false)
  const [countryAbbrev, setCountryAbbrev] = useState('')
  const [countryAbbrevValError, setCountryAbbrevValError] = useState(false)
  const [countryCode, setCountryCode] = useState('')

  const dispatch = useAppDispatch()

  useEffect(() => {
    if (error) {
      dispatch(
        addNotification({
          type: 'error',
          message: error,
        })
      )
    }
  }, [error, dispatch])

  const handleOpenDialog = () => {
    setShowDialog(true)
  }

  const handleCloseDialog = () => {
    setShowDialog(false)
  }

  function handleKeyDown(event: KeyboardEvent<HTMLInputElement>) {
    if (event.key === 'Enter' && submitEnabled) {
      handlePasswordSubmit()
    }
  }

  function handlePasswordSubmit() {
    const fields = {
      name: (name || '').trim(),
      phone: (mobilePhone || '').trim(),
      email: (email || '').trim(),
      password: (password || '').trim(),
      countrycode: (countryAbbrev || '').trim(),
      confirmPassword: (confirmPassword || '').trim(),
    }

    if (!fields.email) {
      dispatch(
        addNotification({ type: 'error', message: 'Email cannot be blank.' })
      )
      return
    }

    if (!fields.password) {
      dispatch(
        addNotification({ type: 'error', message: 'Password cannot be blank.' })
      )
      return
    }

    if (!fields.confirmPassword) {
      dispatch(
        addNotification({
          type: 'error',
          message: 'Confirm password cannot be blank.',
        })
      )
      return
    }

    if (fields.password !== fields.confirmPassword) {
      dispatch(
        addNotification({
          type: 'error',
          message: 'Password fields do not match.',
        })
      )
      return
    }

    onSubmit(fields)
  }

  function handleGoToLogin() {
    history.push({
      pathname: '/login',
    })
  }

  function handleOnChange(evt: ChangeEvent<HTMLInputElement>) {
    const { name, value } = evt.target
    setChangesMade(true)
    if (name === 'password') setPassword(value)
    else if (name === 'confirmPassword') {
      setConfirmPassword(value)
    } else if (name === 'name') setName(value)
    else if (name === 'email' && enableEmail) {
      setEmailChangesMade(true)
      onEmailChange?.call(null, value)
    }
  }

  function handlePhoneChange(evt: ChangeEvent<HTMLInputElement>) {
    setMobilePhone(evt.target.value)
    let mobilePhoneRegex =
      /^\+?\d{1,4}?[-.\s]?\(?\d{1,3}?\)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}$/
    let hasInput = evt.target.value.length > 0
    let isValidNumber = mobilePhoneRegex.test(evt.target.value) || !hasInput
    if (hasInput && countryAbbrev.length === 0) {
      setCountryAbbrevValError(true)
    } else {
      setCountryAbbrevValError(false)
    }
    if (!isValidNumber) {
      setMobilePhoneValError(true)
    } else {
      setMobilePhoneValError(false)
    }
  }
  function handleCountryCodeChange(evt: any, ent: any) {
    let selCountry: CountryEntry = ent
    if (ent) {
      setCountryAbbrev(selCountry.CountryAbbr)
      setCountryCode(selCountry.CountryCode)
    } else {
      setCountryAbbrev('')
      setCountryCode('')
    }
    setCountryAbbrevValError(false)
  }

  const countryList = useIcoFetch<Array<CountryEntry>>(
    `/api/User/GetCountryCodes`
  )
  function renderDialog() {
    return (
      <div style={passwordRulesStyle}>
        Passwords must meet the following requirements:
        <ul style={listStyle}>
          <li>Between 8 and 40 characters</li>
          <li>Have at least one Uppercase</li>
          <li>Have at least one Lowercase</li>
          <li>Have at least one Number</li>
          <li>Have at least one Symbol</li>
        </ul>
        <br />
      </div>
    )
  }

  function render() {
    return (
      <AuthMasterLayout
        {...otherProps}
        history={history}
        showAlert={false}
        alertColor=""
        showHomeLink={false}
      >
        <div className={cx(classes.form, classes.formBackground)}>
          {createAccount && (
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <div
                className={cx(classes.tabHeaders, classes.unClickedTabHeader)}
                onClick={handleGoToLogin}
              >
                User Login
              </div>
              <div className={classes.tabHeaders}>Create Account</div>
            </div>
          )}
          <div className={classes.formContent}>
            {!createAccount && (
              <>
                <h5>{headerText}</h5>
                <Spacer spaceParam={'large'} />
                <Spacer spaceParam={'large'} />
              </>
            )}
            <span>Name</span>
            <Spacer />
            <TextField
              type="text"
              name="name"
              className={tfClasses.textfield}
              onChange={handleOnChange}
              disabled={loading}
              onKeyDown={handleKeyDown}
              id={prefixSelectorWithApp('client-name')}
              value={name || ''}
              autoFocus
              fullWidth
            />
            <Spacer spaceParam={'large'} />
            <Spacer spaceParam={'large'} />

            <span>
              Email <span className={cx(classes.requiredIcon)}>*</span>
            </span>
            <Spacer />
            <TextField
              type="email"
              name="email"
              className={tfClasses.textfield}
              onChange={handleOnChange}
              disabled={!enableEmail || loading}
              onKeyDown={handleKeyDown}
              autoComplete="UserName"
              id={prefixSelectorWithApp('client-email')}
              value={email || ''}
              error={(emailChangesMade || submitted) && !email}
              fullWidth
            />
            <Spacer spaceParam={'large'} />
            <Spacer spaceParam={'large'} />

            <span>Mobile Phone Country</span>
            <div id="Country-Code-Select">
              <div
                style={
                  countryAbbrevValError
                    ? {
                        boxShadow: '0 0 1px 1px red',
                        border: '1px transparent solid',
                      }
                    : {}
                }
              >
                <Autocomplete
                  id="combo-box-country"
                  options={countryList.data || []}
                  getOptionLabel={(option) => option.CCDescr || ''}
                  getOptionSelected={(option) =>
                    option.CountryCode === countryCode
                  }
                  onChange={handleCountryCodeChange}
                  renderInput={(params) => (
                    <TextField
                      name={'combo-box-country'}
                      className={tfClasses.textfield}
                      {...params}
                    />
                  )}
                  value={countryList.data?.find(
                    (country) => country.CountryCode === countryCode
                  )}
                />
              </div>
            </div>
            <Spacer spaceParam={'large'} />
            <Spacer spaceParam={'large'} />

            <span>Mobile Phone Number</span>
            <Spacer />
            <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
              <span className={setClasses.countryBox} id="spanCountryCode">
                {'+' + countryCode}
              </span>
              <input
                type="tel"
                name="MobilePhone"
                id="MobilePhone"
                className={cx(setClasses.enabledInputs, tfClasses.textfield)}
                style={
                  mobilePhoneValError
                    ? {
                        boxShadow: '0 0 1px 1px red',
                        border: '1px transparent solid',
                      }
                    : {}
                }
                value={mobilePhone || ''}
                onChange={handlePhoneChange}
                color="blue"
              />
            </div>
            <Spacer spaceParam={'large'} />
            <p className={classes.helpPhone}>
              Enter your phone number and reply to the opt in text from In Case
              of Crisis to receive text messages from your organization as well
              as providing an alternative login method in case of single-sign-on
              verification issues.
            </p>
            <Spacer spaceParam={'large'} />
            <Spacer spaceParam={'large'} />

            <span>
              Password <span className={cx(classes.requiredIcon)}>*</span>{' '}
              <span className={cx(classes.pwRules)} onClick={handleOpenDialog}>
                (Password Rules)
              </span>
            </span>
            <Spacer />
            <TextField
              type="password"
              name="password"
              className={tfClasses.textfield}
              id={prefixSelectorWithApp('client-password')}
              autoFocus={!enableEmail}
              onKeyDown={handleKeyDown}
              onChange={handleOnChange}
              disabled={loading}
              value={password}
              error={changesMade && submitted && !password.trim()}
              fullWidth
            />
            <Spacer spaceParam={'large'} />
            <Spacer spaceParam={'large'} />

            <span>
              Confirm Password{' '}
              <span className={cx(classes.requiredIcon)}>*</span>
            </span>
            <Spacer />
            <TextField
              type="password"
              name="confirmPassword"
              className={tfClasses.textfield}
              id={prefixSelectorWithApp('client-confirmPassword')}
              onKeyDown={handleKeyDown}
              onChange={handleOnChange}
              disabled={loading}
              value={confirmPassword}
              error={changesMade && submitted && !confirmPassword}
              fullWidth
            />
            <Spacer spaceParam={'large'} />
            <Spacer spaceParam={'large'} />

            <div>
              {submitEnabled
                ? !loading && (
                    <Button
                      color="darker"
                      onClick={handlePasswordSubmit}
                      style={{ textTransform: 'uppercase' }}
                    >
                      {submitBtnText}
                    </Button>
                  )
                : !loading && (
                    <Button
                      color="darker"
                      onClick={handleGoToLogin}
                      style={{ textTransform: 'uppercase' }}
                    >
                      Go to Login
                    </Button>
                  )}
              {loading && (
                <Button
                  color="darker"
                  disabled={true}
                  style={{ textTransform: 'uppercase' }}
                >
                  <CircularProgress
                    color="inherit"
                    size={25}
                    className="mr-1"
                    disableShrink={true}
                  />
                  {loadingText}
                </Button>
              )}
            </div>
            <Spacer spaceParam={'large'} />
          </div>
        </div>
        {showDialog && (
          <ResponsiveDialog
            open={showDialog}
            onClose={handleCloseDialog}
            TransitionComponent={SlideLeft as any}
          >
            <Toolbar>
              <HeaderNavigation
                title="Password Rules"
                onNavClick={handleCloseDialog as any}
                canGoBack={false}
              />
            </Toolbar>
            <DialogContent id="dialogPwRules">{renderDialog()}</DialogContent>
          </ResponsiveDialog>
        )}
      </AuthMasterLayout>
    )
  }

  return render()
}
