import { generateActions } from '../_utils/reduxUtils'
import {
  ADD_INBOX,
  PIN_ALERT,
  UNPIN_ALERT,
  ADD_ALERT_TO_ISSUE,
  CREATE_NEW_ALERT_ISSUE,
  DELETE_INBOX,
  MARK_REVIEWED_ALERT,
  MARK_NOT_REVIEWED_ALERT,
  FETCH_INBOXES,
  FETCH_ALERTS,
  SET_INBOX_ACTIVE,
  UPDATE_INBOX,
  SHARE_ALERT,
  ALERT_FILTER,
  ALERT_FILTER_SAVE,
  ALERT_SEVERITY_COLORS,
  CREATE_CLONED_ALERT_ISSUE,
  ALERT_SEARCH_VALUE,
} from './_constants'
import { AlertFilter, SeverityColor } from './_models'

// Alerts

export function alertFilterSave(filter: AlertFilter) {
  return { type: ALERT_FILTER_SAVE.REQUEST, payload: { params: filter } }
}

export function alertFilterSaving() {
  return { type: ALERT_FILTER_SAVE.LOADING }
}

export function alertFilterSaveSuccess(filter: AlertFilter) {
  return { type: ALERT_FILTER_SAVE.SUCCESS, payload: { result: filter } }
}

export function alertFilterSaveFailure(error: string) {
  return { type: ALERT_FILTER_SAVE.FAILURE, payload: { error: error } }
}

export function alertFilterSuccess(filters: AlertFilter) {
  return { type: ALERT_FILTER.SUCCESSFUL, payload: { result: filters } }
}

export function alertFilterFetch() {
  return { type: ALERT_FILTER.FETCH }
}
export function alertSeverityColorsSuccess(
  severityColors: Array<SeverityColor>
) {
  return {
    type: ALERT_SEVERITY_COLORS.SUCCESSFUL,
    payload: { result: severityColors },
  }
}
export function alertSeverityColorsFetch() {
  return { type: ALERT_SEVERITY_COLORS.FETCH }
}
export const fetchAlerts = generateActions(FETCH_ALERTS)
export const markReviewedAlert = generateActions(MARK_REVIEWED_ALERT, 'alertID')
export const markNotReviewedAlert = generateActions(
  MARK_NOT_REVIEWED_ALERT,
  'alertID'
)
export const pinAlert = generateActions(PIN_ALERT, 'Id')
export const unPinAlert = generateActions(UNPIN_ALERT, 'Id')
export const shareAlert = generateActions(SHARE_ALERT)
export const createNewAlertIssue = generateActions(
  CREATE_NEW_ALERT_ISSUE,
  'alertID'
)
export const createClonedAlertIssue = generateActions(CREATE_CLONED_ALERT_ISSUE)
export const addAlertToIssue = generateActions(ADD_ALERT_TO_ISSUE, 'alertID')

// Inbox
export const fetchInboxes = generateActions(FETCH_INBOXES)
export const addInbox = generateActions(ADD_INBOX)
export const deleteInbox = generateActions(DELETE_INBOX, 'inboxGUID')
export const updateInbox = generateActions(UPDATE_INBOX, 'inboxGUID')
export const setInboxActive = generateActions(SET_INBOX_ACTIVE)

export function alertSearch(searchValue: string) {
  return { type: ALERT_SEARCH_VALUE, payload: { searchValue } }
}
