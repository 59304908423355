import {
  callAction,
  ReducerAction,
  ReducerActionMap,
} from '../_utils/reduxUtils'
import { BasePathPayload, SAVE_BASE_PATH_STATE } from './_constants'

interface BasePathState {
  [basePath: string]: string
}

export default function basePathReducer(
  state: BasePathState = {},
  action: ReducerAction<BasePathPayload>
) {
  const mapped: ReducerActionMap = {
    [SAVE_BASE_PATH_STATE]: () => ({
      ...state,
      [action.payload.basePath]: action.payload.path,
    }),
  }

  return callAction(mapped, state, action)
}

export const selectBasePathState = (state: BasePathState, basePath: string) => {
  let finalPath = state[basePath.trim().toLocaleLowerCase()] || basePath
  if (finalPath.length > 0 && finalPath[0] !== '/') finalPath = '/' + finalPath

  return finalPath
}
