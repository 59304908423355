import {
  bindSel,
  getRequestStatus,
  getRequestSync,
  getOrgUgisMembers,
} from '../_rootConfigs/rootReducerSlicers'
import { RootState } from '../_store'
import * as priv from './_requestStatusReducer'

export const selectIsAzureADLoginFailure = bindSel(
  getRequestStatus,
  priv.selectIsAzureADLoginFailure
)
export const selectIsAzureADLoginRequest = bindSel(
  getRequestStatus,
  priv.selectIsAzureADLoginRequest
)
export const isInitIssuesLoading = bindSel(
  getRequestStatus,
  priv.isInitIssuesLoading
)
export const isInitIssuesComplete = bindSel(
  getRequestStatus,
  priv.isInitIssuesComplete
)
export const isInitIssuesSuccess = bindSel(
  getRequestStatus,
  priv.isInitIssuesSuccess
)
export const isFetchIssuesLoading = bindSel(
  getRequestStatus,
  priv.isFetchIssuesLoading
)
export const isFetchIssuesComplete = bindSel(
  getRequestStatus,
  priv.isFetchIssuesComplete
)
export const isAppLoading = bindSel(getRequestStatus, priv.isAppLoading)
export const isAppLoaded = bindSel(getRequestStatus, priv.isAppLoaded)
export const selectIsAppInitialDataLoaded = bindSel(
  getRequestStatus,
  priv.selectIsAppInitialDataLoaded
)
export const selectIsAppInitialDataLoading = bindSel(
  getRequestStatus,
  priv.selectIsAppInitialDataLoading
)
export const isAppInitialDataSuccess = bindSel(
  getRequestStatus,
  priv.isAppInitialDataSuccess
)
export const isFetchTaskItemsLoading = bindSel(
  getRequestStatus,
  priv.isFetchTaskItemsLoading
)
export const isFetchTaskItemsPreviouslyLoaded = bindSel(
  getRequestStatus,
  priv.isFetchTaskItemsPreviouslyLoaded
)
export const isFetchTaskItemsFailure = bindSel(
  getRequestStatus,
  priv.isFetchTaskItemsFailure
)
export const isFetchTaskItemsInSync = bindSel(
  getRequestSync,
  priv.fetchTaskItemsSync.isInSync
)
export const isFetchTaskItemsRequired = (
  state: RootState,
  icoRequestId: number | string
) =>
  /*isFetchTaskItemsFailure(state, icoRequestId) ||*/ !isFetchTaskItemsInSync(
    state,
    icoRequestId
  ) && !isFetchTaskItemsLoading(state, icoRequestId)
export const isFetchTaskCommentsLoading = bindSel(
  getRequestStatus,
  priv.isFetchTaskCommentsLoading
)
export const isFetchTaskCommentsComplete = bindSel(
  getRequestStatus,
  priv.isFetchTaskCommentsComplete
)
export const isUploadInProgress = bindSel(
  getRequestStatus,
  priv.isUploadInProgress
)
export const selectUploadTotalProgress = bindSel(
  getRequestStatus,
  priv.selectUploadTotalProgress
)
export const isUploadSuccess = bindSel(getRequestStatus, priv.isUploadSuccess)
export const isCreateIssueFormsLoading = bindSel(
  getRequestStatus,
  priv.isCreateIssueFormsLoading
)
export const isCreateIssueFormsSuccess = bindSel(
  getRequestStatus,
  priv.isCreateIssueFormsSuccess
)
export const isCreateIssueFormsComplete = bindSel(
  getRequestStatus,
  priv.isCreateIssueFormsComplete
)
export const isUpdateIssueFormsLoading = bindSel(
  getRequestStatus,
  priv.isUpdateIssueFormsLoading
)
export const isUpdateIssueFormsFailure = bindSel(
  getRequestStatus,
  priv.isUpdateIssueFormsFailure
)
export const isFormContentLoading = bindSel(
  getRequestStatus,
  priv.isFormContentLoading
)
export const isIssueFormSubmitting = bindSel(
  getRequestStatus,
  priv.isIssueFormSubmitting
)
export const isIssueFormSubmitFailure = bindSel(
  getRequestStatus,
  priv.isIssueFormSubmitFailure
)
export const isRequestStatusCleared = bindSel(
  getRequestStatus,
  priv.isRequestStatusCleared
)
export const isChangeLevelFormContentLoading = bindSel(
  getRequestStatus,
  priv.isChangeLevelFormContentLoading
)
export const isChangeLevelFormContentFailure = bindSel(
  getRequestStatus,
  priv.isChangeLevelFormContentFailure
)
export const isOpenIssueFormContentLoading = bindSel(
  getRequestStatus,
  priv.isChangeLevelFormContentLoading
)
export const isOpenIssueFormContentFailure = bindSel(
  getRequestStatus,
  priv.isOpenIssueFormContentFailure
)
export const isResolveIssueFormContentLoading = bindSel(
  getRequestStatus,
  priv.isResolveIssueFormContentLoading
)
export const isResolveIssueFormContentFailure = bindSel(
  getRequestStatus,
  priv.isResolveIssueFormContentFailure
)
export const isIssueResolving = bindSel(getRequestStatus, priv.isIssueResolving)
export const isIssueResolvingComplete = bindSel(
  getRequestStatus,
  priv.isIssueResolvingComplete
)
export const isCreatingStakeholder = bindSel(
  getRequestStatus,
  priv.isCreatingStakeholder
)
export const isCreateStakeholderComplete = bindSel(
  getRequestStatus,
  priv.isCreateStakeholderComplete
)
export const isCreateStakeholderFailure = bindSel(
  getRequestStatus,
  priv.isCreateStakeholderFailure
)
export const isUpdatingStakeholder = bindSel(
  getRequestStatus,
  priv.isUpdatingStakeholder
)
export const isUpdateStakeholderComplete = bindSel(
  getRequestStatus,
  priv.isUpdateStakeholderComplete
)
export const isUpdateStakeholderFailure = bindSel(
  getRequestStatus,
  priv.isUpdateStakeholderFailure
)
export const isAddingTeamParticipants = bindSel(
  getRequestStatus,
  priv.isAddingTeamParticipants
)
export const isAddingTeamParticipantsSuccess = bindSel(
  getRequestStatus,
  priv.isAddingTeamParticipantsSuccess
)
export const isAddingTeamParticipantsComplete = bindSel(
  getRequestStatus,
  priv.isAddingTeamParticipantsComplete
)
export const isDeletingIssue = bindSel(getRequestStatus, priv.isDeletingIssue)
export const isDeletingIssueComplete = bindSel(
  getRequestStatus,
  priv.isDeletingIssueComplete
)
export const isCreatingDirectMessage = bindSel(
  getRequestStatus,
  priv.isCreatingDirectMessage
)
export const isCreatingDirectMessageSuccess = bindSel(
  getRequestStatus,
  priv.isCreatingDirectMessageSuccess
)
export const isCreatingOwnerDirectMessage = bindSel(
  getRequestStatus,
  priv.isCreatingOwnerDirectMessage
)
export const isCreatingOwnerDirectMessageSuccess = bindSel(
  getRequestStatus,
  priv.isCreatingOwnerDirectMessageSuccess
)
export const isDeletingDirectMessage = bindSel(
  getRequestStatus,
  priv.isDeletingDirectMessage
)
export const isDeletingDirectMessageSuccess = bindSel(
  getRequestStatus,
  priv.isDeletingDirectMessageSuccess
)
export const isFetchingChatEntries = bindSel(
  getRequestStatus,
  priv.isFetchingChatEntries
)
export const isFetchChatEntriesInSync = bindSel(
  getRequestSync,
  priv.fetchChatEntriesInSync.isInSync
)
export const isFetchChatEntriesRequired = (
  state: RootState,
  icoRequestId: number | string
) =>
  !isFetchChatEntriesInSync(state, icoRequestId) &&
  !isFetchingChatEntries(state, icoRequestId)
export const isFetchDirectMessagesInSync = bindSel(
  getRequestSync,
  priv.fetchDirectMessagesSync.isInSync
)
export const isFetchingDirectMessages = bindSel(
  getRequestStatus,
  priv.isFetchingDirectMessages
)
export const isFetchDirectMessagesRequired = (
  state: RootState,
  icoRequestId: number | string
) =>
  !isFetchDirectMessagesInSync(state, icoRequestId) &&
  !isFetchingDirectMessages(state, icoRequestId)
export const isFetchIssueDetailsSync = bindSel(
  getRequestSync,
  priv.fetchIssueDetailsSync.isInSync
)
export const isFetchingIssueDetails = bindSel(
  getRequestStatus,
  priv.isFetchingIssueDetails
)
export const isFetchingIssueDetailsComplete = bindSel(
  getRequestStatus,
  priv.isFetchingIssueDetailsComplete
)
export const isFetchIssueDetailsRequired = (
  state: RootState,
  icoRequestId: number | string
) =>
  !isFetchIssueDetailsSync(state, icoRequestId) &&
  !isFetchingIssueDetails(state, icoRequestId)

export const isArtifactContentLoaded = bindSel(
  getRequestStatus,
  priv.isArtifactContentLoaded
)
export const isArtifactContentLoading = bindSel(
  getRequestStatus,
  priv.isArtifactContentLoading
)
export const isArtifactDomainInitialized = bindSel(
  getRequestStatus,
  priv.isArtifactDomainInitialized
)

// Inboxes
export const isInboxLoading = bindSel(getRequestStatus, priv.isInboxLoading)
export const isInboxFetchComplete = bindSel(
  getRequestStatus,
  priv.isInboxFetchComplete
)

export const isAlertsLoading = bindSel(getRequestStatus, priv.isAlertsLoading)

export const isCreatingAlert = bindSel(getRequestStatus, priv.isCreatingAlert)
export const isAddingAlertToIssue = bindSel(
  getRequestStatus,
  priv.isAddingAlertToIssue
)
export const isCreatingNewAlertIssue = bindSel(
  getRequestStatus,
  priv.isCreatingNewAlertIssue
)
export const isDeletingAlert = bindSel(getRequestStatus, priv.isDeletingAlert)

export const isDeletingInbox = bindSel(getRequestStatus, priv.isDeletingInbox)
export const isUpdatingInbox = bindSel(getRequestStatus, priv.isUpdatingInbox)
export const isCreatingInbox = bindSel(getRequestStatus, priv.isCreatingInbox)

// Request Token
export const selectIsFetchingRequestToken = bindSel(
  getRequestStatus,
  priv.selectIsFetchingRequestToken
)

// Org
export const fetchingOrgUgisMembers = bindSel(
  getOrgUgisMembers,
  priv.fetchingOrgUgisMembers
)
