import * as React from 'react'
import { Component } from 'react'
import classes from './OrgTagContainer.module.scss'
import OrgTag from './OrgTag'
import { cx } from '../_utils/objectUtils'

interface Props {
  tagIds: number[]
  onDelete?: (tag: any) => void
  className?: string
}

/**
 * @extends {Component<Props, {}>}}
 */
export class OrgTagContainer extends Component<Props> {
  static defaultProps: { tagIds: number[] }
  render() {
    const { tagIds, onDelete, className } = this.props
    return (
      <div className={cx(classes.root, className)}>
        {tagIds.map((id) => (
          <OrgTag
            className={classes.orgTag}
            key={id}
            TagID={id}
            onDelete={onDelete}
          />
        ))}
      </div>
    )
  }
}

OrgTagContainer.defaultProps = {
  tagIds: [],
}

export default OrgTagContainer
