// @ts-nocheck
// TODO: Typescript
/**
 * @description Transforms File objects to base64
 * @param {File|File[]} files
 * @param {Function} onDone
 * @param {boolean} multiFile
 */
export function filesToBase64(files, onDone, multiFile) {
  if (!files) throw new Error('The variable files cannot be undefined')

  onDone = onDone || function () {}
  var loadedFiles = []
  // Convert the single file to an array
  if (!(files instanceof Array)) files = [files]

  var fileCount = files.length

  // Limit to single/multi file upload
  if (fileCount > 0) {
    fileCount = multiFile ? fileCount : 1
  }

  // Loop through all of the files
  for (var i = 0; i < fileCount; i++) {
    var file = files[i]

    // Create the file reader, declare what to do after the file is read and read the file
    var reader = new FileReader()

    // Must scope the image element when in a for loop
    reader.onload = (function (processedFile) {
      // The onload function to return
      return function (e) {
        var type = processedFile.type
        // Split the type from the content
        var contentIndex = 1
        var imageBase = e.target.result.split(';')[contentIndex]

        if (imageBase) {
          var content = imageBase.replace('base64,', '')
          loadedFiles.push({ content: content, type: type })
        }

        onDone(loadedFiles)
      }
    })(file)

    reader.readAsDataURL(file)
  }
}

export function getBase64Src({ type, content }) {
  return 'data:' + type + ';base64,' + content
}

export function getFilenameExt(filename) {
  if (!filename) return ''

  const tokens = filename.split('.')
  if (tokens.length === 1) return ''

  return tokens.pop()
}

export function arrayBufferToBase64(buffer) {
  var binary = ''
  var bytes = new Uint8Array(buffer)
  var len = bytes.byteLength
  for (var i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i])
  }
  return window.btoa(binary)
}

export function base64ToArrayBuffer(base64: any) {
  const binaryString = window.atob(base64)
  const len = binaryString.length
  const bytes = new Uint8Array(len)

  for (let i = 0; i < len; i++) {
    bytes[i] = binaryString.charCodeAt(i)
  }

  return bytes.buffer
}
