// @ts-nocheck
// TODO: Typescript
import React, { Component } from 'react'
import {
  Dialog,
  withMobileDialog,
  Toolbar,
  DialogContent,
  CircularProgress,
} from '@material-ui/core'
import { SlideLeft } from '../Shared/Transition'
import { FormContent } from './FormContent'
import { HeaderNavigation } from '../Shared/HeaderNavigation'
import minClasses from '../Shared/dialogMinimums.module.scss'
import { cx } from '../_utils/objectUtils'
import PropTypes from 'prop-types'

const ResponsiveDialog = withMobileDialog()(Dialog)
/**
 * @extends {Component<Props, {}>}}
 */
export class FormContentDialog extends Component {
  constructor(props) {
    super(props)
    this.handleFormSubmit = this.handleFormSubmit.bind(this)
  }

  handleFormSubmit(formData) {
    const { submitForm, onClose } = this.props
    submitForm(formData)
    onClose()
  }

  renderProgress() {
    return (
      <div
        className={cx(
          minClasses.progress,
          'd-flex justify-content-center align-items-center h-100'
        )}
      >
        <CircularProgress color="primary" disableShrink={true} />
      </div>
    )
  }

  renderFormContent() {
    const { content, isLoadingContent, fldval } = this.props

    if (isLoadingContent) return this.renderProgress()

    return (
      <FormContent
        content={content}
        onSubmitClick={this.handleFormSubmit}
        fldval={fldval}
      />
    )
  }

  render() {
    const { open, onClose, isFetchFormContentFailure } = this.props
    return (
      <ResponsiveDialog
        className={minClasses.dialog}
        open={open && !isFetchFormContentFailure}
        onClose={onClose}
        TransitionComponent={SlideLeft}
      >
        <Toolbar>
          <HeaderNavigation onNavClick={onClose} canGoBack={false} />
        </Toolbar>
        <DialogContent className={minClasses.dialogContent}>
          {this.renderFormContent()}
        </DialogContent>
      </ResponsiveDialog>
    )
  }
}

FormContentDialog.propTypes = {
  content: PropTypes.any,
  isLoadingContent: PropTypes.bool,
  isFetchFormContentFailure: PropTypes.bool,
  /***
   * @description - The submit callback that passes back the form data
   ***/
  submitForm: PropTypes.func,
  fldval: PropTypes.any,
}
