// @ts-nocheck
// TODO: Typescript
import { ParticipantConstants, ParticipantTeamConstants } from './_constants'
import {
  ParticipantActions,
  markParticipantAsOnline,
  markParticipantAsOffline,
  ParticipantTeamActions,
} from './_actions'
import { WebSocketConstants } from '../_webSocket/_constants'
import { JsonParseSafe } from '../_utils/objectUtils'
import { addNotification } from '../_actions'
import {
  TaskActions,
  TaskDetailActions,
  TaskCommentActions,
} from '../Task/_actions'
import { batch } from 'react-redux'
import { ChatActions, ChatDetailActions } from '../Chat/_actions'
import { ConferenceActions } from '../Conference/_actions'
import { ArtifactActions } from '../Upload/_actions'
import { WorkspaceActions } from '../Workspace/_actions'
import { clearRequestSync } from '../Shared/_actions'
import { TaskDetailConstants } from '../Task/_constants'
import { IssueActions } from '../Issue/_actions'
import { IssueLevelActions } from '../IssueLevel/_actions'
import { TeamReportActions } from '../Form/_actions'
import { StakeholderActions } from '../Stakeholder/_actions'
import {
  fetchArtifactContentSync,
  fetchIssueDetailsSync,
} from '../Shared/_requestStatusReducer'
import {
  selectIssueStakeholderIds,
  selectTaskAllItemIds,
  selectUserEmail,
} from '../_rootConfigs/rootSelectors'
import { selectWorkspaceById } from '../Workspace/_selectors'
import { selectChatEntries } from '../Chat/_selectors'
import { selectTaskItemCommentIds } from '../Task/_selectors'
import {
  selectIssueById,
  selectIssuesAllLevelIds,
  selectIssueTeamReportIds,
} from '../Issue/_selectors'

export default function participantHub(storeAPI, action, hubConnection) {
  switch (action.type) {
    case WebSocketConstants.CONNECTION_RETRY_SUCCESSFUL:
    case WebSocketConstants.CONNECTION_SUCCESSFUL:
      // Participant
      hubConnection.on('BroadcastParticipantAdd', (data, source) => {
        addParticipant(data, source, true)
      })

      hubConnection.on('BroadcastParticipantAddOthers', (data, source) => {
        addParticipant(data, source, false)
      })
      function addParticipant(data, source, isCaller) {
        data = JsonParseSafe(data)
        var msg = ''
        var msgType = ''
        let { returnData } = data

        if (data.Status === undefined) {
          let participant = (returnData || {})[0]
          if (participant) {
            participant.IssueID = undefined
            participant.IssueTeamWorkspaceID = undefined
            msg = 'Participant was added successfully.'
            msgType = 'success'
            storeAPI.dispatch(
              ParticipantActions.addSuccess(participant, source)
            )
          }
        } else {
          if (data.ErrorMessage) {
            msgType = 'error'
            msg = data.ErrorMessage
          } else {
            switch (data.Status) {
              case -1:
                msg = 'User must be a valid In Case of Crisis User.'
                msgType = 'warning'
                break
              case 0:
                msg = 'Participant already exists in the list.'
                msgType = 'info'
                break
              default:
                msgType = 'error'
                msg = 'Sorry, something went wrong in database'
            }
          }
        }

        if (isCaller)
          storeAPI.dispatch(
            addNotification({
              message: msg,
              type: msgType,
              duration: 3000,
              hasCloseBtn: false,
            })
          )
      }

      hubConnection.on(
        'BroadcastParticipantUpdate',
        (data, source, requestData) => {
          data = JsonParseSafe(data)
          if (!data.ErrorMessage) {
            storeAPI.dispatch(
              ParticipantActions.updateSuccess(data.returnData, source)
            )
          } else
            storeAPI.dispatch(
              ParticipantActions.updateFailure(
                data.ErrorMessage,
                requestData,
                source
              )
            )
        }
      )

      hubConnection.on(
        'BroadcastParticipantRemove',
        (data, source, isCaller) => {
          const state = storeAPI.getState()
          data = JsonParseSafe(data)
          if (!data.ErrorMessage) {
            const userEmail = selectUserEmail(state)
            let participant = data.returnData || {}
            const { IssueID, IssueTeamWorkspaceID } = participant || {}
            participant.IssueID = undefined
            participant.IssueTeamWorkspaceID = undefined

            const finalSource = {
              ...source,
              IssueTeamWorkspaceID,
              IssueID,
            }

            // If the removed participant is the user, remove all dependencies
            if (userEmail === participant.email_address) {
              const workspace = selectWorkspaceById(state, IssueTeamWorkspaceID)
              batch(() => {
                storeAPI.dispatch(fetchIssueDetailsSync.clearSync())
                storeAPI.dispatch(
                  ParticipantActions.deleteRangeSuccess(
                    workspace.Participants,
                    finalSource
                  )
                )
                storeAPI.dispatch(
                  ConferenceActions.deleteRangeSuccess(
                    workspace.ConfSched,
                    finalSource
                  )
                )
                const chats = workspace.CollabChat || []
                chats.forEach((chatID) => {
                  const chatEntryIds = selectChatEntries(state, chatID)
                  storeAPI.dispatch(
                    ChatDetailActions.deleteRangeSuccess(chatEntryIds)
                  )
                })
                const tasks = workspace.CollabTask || []
                tasks.forEach((taskID) => {
                  const taskItems = selectTaskAllItemIds(state, taskID) || []
                  storeAPI.dispatch(
                    TaskDetailActions.deleteRangeSuccess(taskItems, finalSource)
                  )
                  taskItems.forEach((itemID) => {
                    const comments = selectTaskItemCommentIds(state, itemID)
                    storeAPI.dispatch(
                      TaskCommentActions.deleteRangeSuccess(
                        comments,
                        finalSource
                      )
                    )
                  })
                })
                try {
                  storeAPI.dispatch(
                    ChatActions.deleteRangeSuccess(chats, finalSource)
                  )
                } catch (ex) {
                  console.log(ex)
                }

                // Tasks clear data and request syncing
                if (tasks && tasks.length) {
                  storeAPI.dispatch(
                    TaskActions.deleteRangeSuccess(tasks, finalSource)
                  )
                  storeAPI.dispatch(
                    clearRequestSync(TaskDetailConstants.FETCH.SUCCESS, {
                      icoRequestId: tasks,
                    })
                  )
                }

                // Artifacts clear data and request syncing
                if (workspace.Artifacts && workspace.Artifacts.length) {
                  storeAPI.dispatch(
                    ArtifactActions.deleteRangeSuccess(
                      workspace.Artifacts,
                      finalSource
                    )
                  )
                  storeAPI.dispatch(
                    fetchArtifactContentSync.clearSync(workspace.Artifacts)
                  )
                }

                // Delete the workspace from the issue and the whole issue if all workspaces are gone
                const issue = selectIssueById(state, IssueID) || {}
                const issueWksp = issue.wksp || []

                // Delete the workspace
                storeAPI.dispatch(
                  WorkspaceActions.deleteSuccess(workspace, { IssueID })
                )

                // Delete the whole issue
                if (issue && issueWksp.length <= 1) {
                  storeAPI.dispatch(
                    IssueActions.deleteSuccess(issue, {
                      removeUser: true,
                      isArchived: false,
                    })
                  )
                  // Delete all dependencies
                  storeAPI.dispatch(
                    IssueLevelActions.deleteRangeSuccess(
                      selectIssuesAllLevelIds(state, IssueID),
                      finalSource
                    )
                  )
                  storeAPI.dispatch(
                    TeamReportActions.deleteRangeSuccess(
                      selectIssueTeamReportIds(state, IssueID),
                      finalSource
                    )
                  )
                  storeAPI.dispatch(
                    StakeholderActions.deleteRangeSuccess(
                      selectIssueStakeholderIds(state, IssueID),
                      finalSource
                    )
                  )
                  // TODO : Delete holding statements
                }
              })
            } else
              storeAPI.dispatch(
                ParticipantActions.deleteSuccess(participant, finalSource)
              )
          } else if (isCaller) {
            // The caller's request failed
            storeAPI.dispatch(
              ParticipantActions.deleteFailure(
                data.ErrorMessage,
                undefined,
                source
              )
            )
          }
        }
      )

      hubConnection.on('BroadcastParticipantConnected', (data) => {
        storeAPI.dispatch(markParticipantAsOnline(data))
      })

      hubConnection.on('BroadcastParticipantDisconnected', (data) => {
        storeAPI.dispatch(markParticipantAsOffline(data))
      })

      hubConnection.on('BroadcastParticipantAddRange', (data, source) => {
        addTeamParticipants(data, source)
      })

      hubConnection.on('BroadcastParticipantTeamAddFailure', () =>
        ParticipantTeamActions.addRangeFailure()
      )

      function addTeamParticipants(data, source) {
        data = JsonParseSafe(data)
        let { returnData } = data

        let participants = returnData || {}
        if (participants && participants[0] && participants[0].ParticipantID) {
          storeAPI.dispatch(
            ParticipantTeamActions.addRangeSuccess(participants, source)
          )
          storeAPI.dispatch(
            ParticipantActions.addRangeSuccess(participants, source)
          )
        }
      }

      break
    case ParticipantConstants.ADD.REQUEST:
      hubConnection.invoke('SendParticipantAdd', action.payload.result)
      break
    case ParticipantConstants.UPDATE.REQUEST:
      hubConnection.invoke(
        'SendParticipantUpdate',
        action.payload.result,
        (action.payload.source || {}).action
      )
      break
    case ParticipantConstants.DELETE.REQUEST:
      hubConnection.invoke('SendParticipantRemove', action.payload.result)
      break
    case ParticipantConstants.DELETE.FAILURE:
    case ParticipantConstants.UPDATE.FAILURE:
      storeAPI.dispatch(
        addNotification({
          message: action.payload.ErrorMessage,
        })
      )
      break

    case ParticipantTeamConstants.ADD_RANGE.REQUEST:
      hubConnection.invoke(
        'SendParticipantTeamAdd',
        action.payload.result,
        action.payload.source
      )
      break
    default:
      break
  }
}
