// @ts-nocheck
// TODO: Typescript
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { changeIssueStageTab } from './_actions'
import { Tabs, Tab } from '@material-ui/core'
import tabsClasses from '../tabs.override.module.scss'
import { cx } from '../_utils/objectUtils'
import { selectIssueStageTab } from './_selectors'
import {
  selectVisibleAssignedIssueIds,
  selectVisibleStagingIssueIds,
} from '../_rootConfigs/rootSelectors'
const rootStyle = {
  padding: 10,
  paddingTop: 0,
}

interface Props {
  className?: string
  scrollButtons?: 'on' | 'off' | 'desktop' | 'auto'
}

export class IssueTabFilter extends Component<Props> {
  constructor(props) {
    super(props)
    this.handleTabChange = this.handleTabChange.bind(this)
  }

  handleTabChange(_, selectedTab) {
    const { changeIssueStageTab } = this.props
    changeIssueStageTab(selectedTab)
  }

  render() {
    const {
      selectedStageType,
      assignedIssuesCount,
      stagingIssuesCount,
      className,
      scrollButtons,
      mobile,
    } = this.props
    return (
      <Tabs
        variant={'fullWidth'}
        scrollButtons={scrollButtons}
        value={selectedStageType}
        indicatorColor="primary"
        textColor="primary"
        onChange={this.handleTabChange}
        className={cx(
          className,
          tabsClasses.root,
          mobile ? tabsClasses.mobile : ''
        )}
        style={rootStyle}
      >
        <Tab
          label={`Assigned (${
            assignedIssuesCount > 99 ? '99+' : assignedIssuesCount
          })`}
          value="assigned"
        />
        <Tab
          label={`Staging (${
            stagingIssuesCount > 99 ? '99+' : stagingIssuesCount
          })`}
          value="staging"
        />
      </Tabs>
    )
  }
}

const mapStateToProps = (state) => {
  const selectedStageType = selectIssueStageTab(state)
  const assignedIssueIds = selectVisibleAssignedIssueIds(state)
  const stageIssueIds = selectVisibleStagingIssueIds(state)
  return {
    selectedStageType,
    issueIds:
      selectedStageType === 'assigned' ? assignedIssueIds : stageIssueIds,
    assignedIssuesCount: (assignedIssueIds || []).length,
    stagingIssuesCount: (stageIssueIds || []).length,
  }
}

const mapDispatchToProps = {
  changeIssueStageTab,
}

export default connect(mapStateToProps, mapDispatchToProps)(IssueTabFilter)
