import { useRef, useEffect, MutableRefObject, useCallback } from 'react'
// @ts-ignore
import { CellMeasurerCache } from 'react-virtualized'

const resizeFn: (() => void)[] = []

// Useful hook for implementing the react-virtualized cell measure while implementing extra logic for window resize
interface CellMeasurerCacheOptions {
  defaultHeight?: number
  minHeight?: number
  listRef?: MutableRefObject<any>
  test: (test: number) => void
  api?: (api: CellMeasureCacheApi) => void
}

interface CellMeasureCacheApi {
  refresh: () => void
}

export function useCellMeasureCache(
  items: any[],
  { defaultHeight, minHeight, listRef, api }: CellMeasurerCacheOptions
) {
  //Define a CellMeasurerCache --> Put the height and width you think are the best
  const cache = useRef(
    new CellMeasurerCache({
      defaultHeight,
      minHeight,
      listRef,
      fixedWidth: true,
    })
  )

  const cacheReset = useRef({
    delay: 200,
    timer: 0,
  })

  const refreshCache = useCallback(
    (applyDelay = true) => {
      clearTimeout(cacheReset.current.timer)
      if (applyDelay) {
        cacheReset.current.timer = setTimeout(() => {
          cache.current.clearAll() //Clear the cache if row heights are recompute to be sure there are no "blank spaces" (some row are erased)
          listRef?.current?.forceUpdateGrid() // We need to recompute the heights
        }, cacheReset.current.delay) as any
      } else {
        cache.current.clearAll() //Clear the cache if row heights are recompute to be sure there are no "blank spaces" (some row are erased)
        listRef?.current?.forceUpdateGrid() // We need to recompute the heights
      }
    },
    [listRef]
  )

  useEffect(() => {
    if (api) {
      api({
        refresh: () => refreshCache(false),
      })
    }
    const refreshCacheWindow = () => refreshCache() as any
    addResizeListener(refreshCacheWindow)

    return function cleanup() {
      removeResizeListener(refreshCacheWindow)
      if (api) api({ refresh: () => {} })
    }
  }, [api, refreshCache])

  useEffect(() => {
    refreshCache(false)
  }, [items, refreshCache])

  return cache
}

function addResizeListener(resizeFunc: () => void) {
  resizeFn.push(resizeFunc)
}

function removeResizeListener(resizeFunc: () => void) {
  const index = resizeFn.indexOf(resizeFunc)
  if (index !== -1) resizeFn.splice(index, 1)
}

window.addEventListener('resize', () => {
  resizeFn.forEach((func) => func())
})
