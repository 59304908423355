import * as React from 'react'
import { Component, Fragment, SyntheticEvent } from 'react'
import { AppBar, Toolbar, Menu, Drawer } from '@material-ui/core'
import IssueFilter from './Issue/IssueFilter'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faBars,
  faExclamationTriangle,
  faUser,
  faBell,
  faCodeBranch,
} from '@fortawesome/pro-solid-svg-icons'
import classes from './AppHeader.module.scss'
import { cx } from './_utils/objectUtils'
import { DesktopLayout, MobileLayout } from './Layouts/ResponsiveHidden'
import { logout } from './Auth/_actions'
import { connect } from 'react-redux'
import { saveAppHeaderTypePath } from './_actions'
import { ThemeContext } from './ThemeProvider/ThemeProvider'
import { AppAccountInfo } from './AppAccountInfo'
import { AppNav } from './Routing/AppNav'
import { RouteComponentProps } from 'react-router-dom'
import { AllRouteParams } from './Routing/RouteProps'
import {
  selectEnvironment,
  selectGitBranch,
  selectGitName,
  selectGitEmail,
  selectUserFullName,
} from './Issue/_selectors'
import {
  hasInvalidMsTeamsContext,
  selectUserEmail,
} from './_rootConfigs/rootSelectors'
import { isMobileApp, selectAppHeaderTypePath } from './_selectors'
import { isUserAuthorized } from './Auth/_selectors'
import { AppUserSettings } from './AppUserSettings'
import { AppSMSPrompt } from './AppSMSPrompt'
import { fetchPortalUser } from './PortalUser/_actions'
import { PublicPlaybookSearch } from './AddPlaybook/PublicPlaybookSearch'
import { PlaybookSearch } from './Playbook/PlaybookSearch'
import { AlertSearch } from './Alert/AlertSearch'
import {
  ActivityLogRecentSummary,
  ActivityLogSummarySortType,
} from './ActivityLog/ActivityLogRecentSummary'
import AppDialog from './AppDialog'
import { prefixSelectorWithApp } from './_utils/domUtils'
import {
  PathAppSection,
  getLocationFromRouteProps,
  getPathAppSection,
  isIssuePath,
  isReportPath,
  isPlaybookPath,
  isAddPlaybookPath,
  isAlertPath,
} from './Routing/_routeMatching'
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab'
import { ActivePollDialog } from './ActivePoll/ActivePollDialog'

interface Props {
  routeProps?: RouteComponentProps<AllRouteParams>
  //saveAppHeaderTypePath(headerType: AppHeaderType): any;
  //getAppHeaderTypePath(type: string): string;
  isMobileApp: boolean
  hasInvalidMsTeamsContext: boolean
  // TODO: Typescript fix any
  logout(): any
  OrgNames: string[]
  Email: string
  FullName: string
  isAuthorized: boolean
  isMobile?: boolean
  fetchPortalUser: () => void
  environment: string
  gitBranch: string
  gitName: string
  gitEmail: string
}

interface State {
  menuOpened: boolean
  orgMenuOpened: boolean
  navMenuOpened: boolean
  settingsOpened: boolean
  summaryOpened: boolean
  summaryLayout: ActivityLogSummarySortType
  debugOpened: boolean
}

/*does not exist on type 'IntrinsicAttributes & IntrinsicClassAttributes: This means that the component that
is receiving props must be typed.*/

export class AppHeader extends Component<Props, State> {
  // [x: string]: any; // look up, Index signature for property
  // this must be named contextType!
  static contextType = ThemeContext
  // never type means just that. The variable or return turn type never occurs. Use case for never I don't know. This was generated by the refactor tool.
  static defaultProps: Props = {
    logout: () => {},
    FullName: '',
    Email: '',
    OrgNames: [],
    hasInvalidMsTeamsContext: false,
    isMobile: false,
    isMobileApp: false,
    isAuthorized: false,
    fetchPortalUser: () => {},
    environment: '',
    gitBranch: 'origin/main',
    gitName: '',
    gitEmail: '',
  }

  // !: equals definite assignemt
  menuAnchorEl!: Element | undefined | null
  summaryAnchorEl!: Element | undefined | null
  orgMenuAnchorEl: Element | undefined | null
  navMenuAnchorEl: Element | undefined | null
  debugAnchorEl: Element | undefined | null

  constructor(props: Props) {
    super(props)
    this.state = {
      menuOpened: false,
      orgMenuOpened: false,
      navMenuOpened: false,
      settingsOpened: false,
      summaryOpened: false,
      debugOpened: false,
      summaryLayout: ActivityLogSummarySortType.LastUpdated,
    }
    this.handleMenuOpen = this.handleMenuOpen.bind(this)
    this.handleCloseSummary = this.handleCloseSummary.bind(this)
    this.handleOpenSummary = this.handleOpenSummary.bind(this)
    this.handleMenuClose = this.handleMenuClose.bind(this)
    this.handleSettingsOpen = this.handleSettingsOpen.bind(this)
    this.handleSettingsClose = this.handleSettingsClose.bind(this)
    this.handleNavMenuOpen = this.handleNavMenuOpen.bind(this)
    this.handleNavMenuClose = this.handleNavMenuClose.bind(this)
    this.handleOrgMenuOpen = this.handleOrgMenuOpen.bind(this)
    this.handleOrgMenuClose = this.handleOrgMenuClose.bind(this)
    this.handleLogOut = this.handleLogOut.bind(this)
    this.handleLayoutSwap = this.handleLayoutSwap.bind(this)
    this.handleDebugClick = this.handleDebugClick.bind(this)
    //this.handleMenuChange = this.handleMenuChange.bind(this);
  }

  /*handleMenuChange(name: string, prevName: string) {
        const { history, saveAppHeaderTypePath, getAppHeaderTypePath } = this.props;
        if (!history)
            return;

        saveAppHeaderTypePath({
            type: prevName,
            path: history.location.pathname
        });

        // Get the previous path of the tab if the user has been to the tab previously
        let pathname = getAppHeaderTypePath(name);

        // Get the default path if a previous path doesn't exist
        if (!pathname) {
            switch (name) {
                case 'home':
                    pathname = '/IssueManagement';
                    break;
                case 'reports':
                    pathname = '/Reports';
                    break;
                default:
                    pathname = '/';
                    break;
            }
        }

        // navigate to path
        history.push({
            pathname
        });
    }*/

  handleSettingsClose(
    event: {},
    reason: 'backdropClick' | 'escapeKeyDown' | 'userSaved'
  ) {
    if (reason !== 'backdropClick') {
      this.setState({
        settingsOpened: false,
      })
    }
  }

  handleSettingsOpen() {
    this.setState({
      settingsOpened: true,
    })
  }

  handleOpenSummary(evt: SyntheticEvent) {
    this.summaryAnchorEl = evt.target as Element
    if (!this.state.summaryOpened) {
      this.setState({
        summaryOpened: true,
      })
    }
  }

  handleCloseSummary() {
    if (this.state.summaryOpened) {
      this.setState({
        summaryOpened: false,
      })
      this.summaryAnchorEl = null
    }
  }

  handleMenuOpen(evt: SyntheticEvent) {
    // evt.target is not always an element. evt.target returns an EventTarget which is an abstraction of element
    this.menuAnchorEl = evt.target as Element
    if (!this.state.menuOpened) {
      this.props.fetchPortalUser()
      this.setState({
        menuOpened: true,
      })
    }
  }

  handleNavMenuClose() {
    if (this.state.navMenuOpened) {
      this.setState({
        navMenuOpened: false,
      })
      this.navMenuAnchorEl = null
    }
  }

  handleNavMenuOpen(evt: SyntheticEvent) {
    // evt.target is not always an element. evt.target returns an EventTarget which is an abstraction of element
    this.navMenuAnchorEl = evt.target as Element
    if (!this.state.navMenuOpened) {
      this.setState({
        navMenuOpened: true,
      })
    }
  }

  handleMenuClose() {
    if (this.state.menuOpened) {
      this.setState({
        menuOpened: false,
      })
      this.menuAnchorEl = null
    }
  }

  handleOrgMenuOpen(evt: SyntheticEvent) {
    this.orgMenuAnchorEl = evt.target as Element
    if (!this.state.orgMenuOpened) {
      this.setState({
        orgMenuOpened: true,
      })
    }
  }

  handleOrgMenuClose() {
    if (this.state.orgMenuOpened) {
      this.setState({
        orgMenuOpened: false,
      })
      this.orgMenuAnchorEl = null
    }
  }
  handleDebugClick(evt?: SyntheticEvent) {
    if (this.state.debugOpened) {
      this.setState({
        debugOpened: false,
      })
      this.orgMenuAnchorEl = null
    } else {
      this.debugAnchorEl = evt ? (evt.target as Element) : null
      this.setState({
        debugOpened: true,
      })
    }
  }

  handleLogOut() {
    this.handleMenuClose()
    this.props.logout()
  }

  handleLayoutSwap(layout: ActivityLogSummarySortType) {
    if (this.state.summaryLayout === layout) return
    this.setState({
      summaryLayout: layout,
    })
  }

  //////////////// Sub components ///////////////////
  renderToolbarActions(isMobile: boolean = false) {
    const { hasInvalidMsTeamsContext, isMobileApp } = this.props
    const logDays = 7
    const isJenkinsBuildGitFeatureBranch =
      this.props.environment.toLocaleLowerCase() === 'development' &&
      this.props.gitBranch !== 'origin/main'
        ? true
        : false
    const layoutButtonStyle = {
      width: 80,
      height: 45,
      fontSize: '4px',
      textTransform: 'none !important' as any,
    }

    const buttonGroup = (
      <div>
        Sort By:
        <ToggleButtonGroup exclusive>
          <ToggleButton
            onClick={() =>
              this.handleLayoutSwap(ActivityLogSummarySortType.LastUpdated)
            }
            selected={
              this.state.summaryLayout ===
              ActivityLogSummarySortType.LastUpdated
            }
            style={layoutButtonStyle}
            value={'left'}
          >
            Updated
          </ToggleButton>

          <ToggleButton
            onClick={() =>
              this.handleLayoutSwap(ActivityLogSummarySortType.Category)
            }
            selected={
              this.state.summaryLayout === ActivityLogSummarySortType.Category
            }
            style={layoutButtonStyle}
            value={'right'}
          >
            Category
          </ToggleButton>
        </ToggleButtonGroup>
      </div>
    )

    return (
      <div className={classes.userContainer}>
        <div className={classes.userIconContainer}>
          {isJenkinsBuildGitFeatureBranch && (
            <FontAwesomeIcon
              icon={faCodeBranch}
              className={cx(classes.debugIcon)}
              onClick={this.handleDebugClick}
            />
          )}

          {!isMobile && (
            <FontAwesomeIcon
              id={prefixSelectorWithApp('recent-summary-icon')}
              className={classes.summaryIcon}
              icon={faBell}
              onClick={this.handleOpenSummary}
            />
          )}
          {!isMobileApp && (
            <FontAwesomeIcon
              id={prefixSelectorWithApp('account-settings-icon')}
              className={classes.userIcon}
              icon={faUser}
              onClick={this.handleMenuOpen}
            />
          )}
          {hasInvalidMsTeamsContext && (
            <FontAwesomeIcon
              className={classes.accountWarning}
              icon={faExclamationTriangle}
            />
          )}
        </div>
        {/*AppAccountInfo*/}
        {isJenkinsBuildGitFeatureBranch &&
          this.renderMenu(isMobile, {
            open: this.state.debugOpened,
            onClose: this.handleDebugClick,
            anchorEl: this.debugAnchorEl,
            disablePadding: true,
            content: (
              <AppDialog
                onCloseClicked={this.handleDebugClick}
                fixedHeader
                header={
                  <h4 className={classes.logHeader}>
                    <div
                      className={classes.logHeaderTitle}
                    >{`Jenkins Dev Build Details`}</div>
                  </h4>
                }
              >
                <div>
                  <p>Branch: {this.props.gitBranch}</p>
                  <p>Built By: {this.props.gitName}</p>
                  <p>Built By (email): {this.props.gitEmail}</p>
                </div>
              </AppDialog>
            ),
          })}
        {!isMobileApp &&
          this.renderMenu(isMobile, {
            open: this.state.menuOpened,
            onClose: this.handleMenuClose,
            anchorEl: this.menuAnchorEl,
            content: this.renderAppAccountInfo(),
          })}
        {/*Recent Summary*/}
        {this.renderMenu(isMobile, {
          open: this.state.summaryOpened,
          onClose: this.handleCloseSummary,
          anchorEl: this.summaryAnchorEl,
          disablePadding: true,
          content: (
            <AppDialog
              onCloseClicked={this.handleCloseSummary}
              fixedHeader
              header={
                <h4 className={classes.logHeader}>
                  <div
                    className={classes.logHeaderTitle}
                  >{`${logDays} Day Activity Summary`}</div>
                  <div className={classes.logHeaderSort}>{buttonGroup}</div>
                </h4>
              }
            >
              <ActivityLogRecentSummary
                days={logDays}
                show={this.state.summaryOpened}
                onItemClick={this.handleCloseSummary}
                sortType={this.state.summaryLayout}
              />
            </AppDialog>
          ),
        })}
      </div>
    )
  }

  // A helper to work content in a menu
  renderMenu(
    isMobile: boolean,
    options: {
      open?: boolean
      onClose: () => void
      content: JSX.Element
      anchorEl: any
      disablePadding?: boolean
    }
  ) {
    const { open, onClose, content, anchorEl, disablePadding } = options
    return isMobile ? (
      <Drawer
        anchor="right"
        open={open}
        onClose={onClose}
        className={cx(classes.drawer, classes.mobile)}
      >
        {content}
      </Drawer>
    ) : (
      <Menu
        open={open ? true : false}
        onClose={onClose}
        anchorEl={anchorEl}
        classes={{ paper: classes.drawer }}
        keepMounted
        MenuListProps={{ disablePadding: disablePadding }}
      >
        <div>{content}</div>
      </Menu>
    )
  }

  NavMenu: React.FC<{ isMobile?: boolean }> = ({ isMobile = false }) => {
    return (
      <>
        {!isMobile && (
          <>
            <FontAwesomeIcon
              id={prefixSelectorWithApp('main-hamburger-nav')}
              className={classes.hamburger}
              icon={faBars}
              onClick={this.handleNavMenuOpen}
            />
            <Drawer
              anchor="left"
              open={this.state.navMenuOpened}
              onClose={this.handleNavMenuClose}
              className={cx(classes.drawer, isMobile ? classes.mobile : '')}
            >
              <AppNav
                onCloseClicked={this.handleNavMenuClose}
                location={this.getLocation()}
              />
            </Drawer>
          </>
        )}
      </>
    )
  }

  getSection(): PathAppSection {
    return getPathAppSection(this.getLocation())
  }

  getTitle() {
    return this.getSection().title
  }

  getDomain() {
    return this.getSection().domain
  }

  getId() {
    return this.getSection().id
  }

  getLocation() {
    return getLocationFromRouteProps(this.props.routeProps)
  }

  renderAppAccountInfo() {
    const { Email, hasInvalidMsTeamsContext } = this.props

    return (
      <AppAccountInfo
        EmailAddress={Email}
        onCloseClicked={this.handleMenuClose}
        onLogoutClicked={this.handleLogOut}
        onSettingsClicked={this.handleSettingsOpen}
        hasInvalidMsTeamsContext={hasInvalidMsTeamsContext}
        domain={this.getDomain()}
      />
    )
  }

  render() {
    const { isMobileApp, isAuthorized } = this.props

    /*if (isMobileApp)
            return null;*/

    return (
      <Fragment>
        <DesktopLayout>
          <AppBar position="static" className={classes.root}>
            <Toolbar className={classes.toolbar}>
              <this.NavMenu />
              <h1 className={classes.appName}>{this.getTitle()}</h1>
              {isAuthorized &&
                (isIssuePath(this.getLocation()) ||
                  isReportPath(this.getLocation())) && (
                  <IssueFilter className={classes.filter} />
                )}
              {isAuthorized && isPlaybookPath(this.getLocation()) && (
                <PlaybookSearch className={classes.filter} />
              )}

              {isAuthorized && isAddPlaybookPath(this.getLocation()) && (
                <PublicPlaybookSearch className={classes.filter} />
              )}
              {isAuthorized && isAlertPath(this.getLocation()) && (
                <AlertSearch className={classes.filter} />
              )}
              {<div className={classes.addSpace}></div>}
              {this.renderToolbarActions()}
            </Toolbar>
          </AppBar>
        </DesktopLayout>
        <MobileLayout>
          <div className={cx(classes.root, classes.mobile)}>
            {!isMobileApp && <this.NavMenu isMobile={isMobileApp} />}
            {this.renderToolbarActions(true)}
          </div>
        </MobileLayout>
        <AppUserSettings
          open={this.state.settingsOpened}
          onClose={this.handleSettingsClose}
        />
        <AppSMSPrompt openSettings={this.handleSettingsOpen} />
        {!isMobileApp && <ActivePollDialog />}
      </Fragment>
    )
  }
}

const mapStateToProps = (state: any) => ({
  FullName: selectUserFullName(state),
  Email: selectUserEmail(state),
  getAppHeaderTypePath: (type: string) => selectAppHeaderTypePath(state, type),
  isMobileApp: isMobileApp(state),
  isAuthorized: isUserAuthorized(state),
  hasInvalidMsTeamsContext: hasInvalidMsTeamsContext(state),
  gitBranch: selectGitBranch(state),
  gitName: selectGitName(state),
  gitEmail: selectGitEmail(state),
  environment: selectEnvironment(state),
})

const mapDispatchToProps = {
  logout,
  saveAppHeaderTypePath: saveAppHeaderTypePath,
  fetchPortalUser: fetchPortalUser.request,
}

export default connect(mapStateToProps, mapDispatchToProps)(AppHeader)
